import { returnUpdatedHeaders } from "../../components/shared/helpers/returnUpdatedHeaders"
import { apiSurveyURL, subscriptionKey } from "../services"

export const convertUploadedFileToTripleS = async (endpoint, body, token) => {
  let response
  try {
    response = await fetch(apiSurveyURL + endpoint, {
      method: 'POST',
      headers: returnUpdatedHeaders(token, subscriptionKey),
      body: JSON.stringify(body)
    })
  } catch (error) {
    return {
      errMsg: 'Something went wrong. Please try again!',
      loadingStatus: false,
      uploadStatus: ''
    }
  }
  if (response && response.status === 202) {
    const responseJson = response.json()
    return responseJson
  } else {
    return {
      errMsg: 'Something went wrong. Please try again!',
      loadingStatus: false,
      uploadStatus: ''
    }
  }
}

export const getConvertStatus = async (endpoint, token) => {
  let response
  try {
    response = await fetch(endpoint, {
      method: 'GET',
      headers: returnUpdatedHeaders(token, subscriptionKey)
    })
  } catch (error) {
    return {
      errMsg: 'Something went wrong. Please try again!',
      loadingStatus: false,
      uploadStatus: ''
    }
  }
  if (response && (response.status === 202 || response.status === 200)) {
    const responseJson = response.json()
    return responseJson
  } else {
    return {
      errMsg: 'Something went wrong. Please try again!',
      loadingStatus: false,
      uploadStatus: ''
    }
  }
}

export const getImportStatus = async (endpoint, token) => {
  let response
  try {
    response = await fetch(endpoint, {
      method: 'GET',
      headers: returnUpdatedHeaders(token, subscriptionKey)
    })
  } catch (error) {
    return {
      errMsg: 'Something went wrong. Please try again!',
      loadingStatus: false,
      uploadStatus: ''
    }
  }
  if (response && (response.status === 202 || response.status === 200)) {
    const responseJson = response.json()
    return responseJson
  } else {
    return {
      errMsg: 'Something went wrong. Please try again!',
      loadingStatus: false,
      uploadStatus: ''
    }
  }
}

export const getDatasetValue = async (endpoint, token) => {
  try {
    return fetch(apiSurveyURL + endpoint, {
      method: 'GET',
      headers: returnUpdatedHeaders(token, subscriptionKey)
    })
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          return {
            errMsg: 'Something went wrong. Please try again!',
            loadingStatus: false,
            uploadStatus: ''
          }
        }
      })
      .then(data => {
        return data
      })
      .catch(error => {
        console.log(error);
        return error;
      })
  } catch (error) {
    console.log(error)
    return error
  }
}

export const sendUpdatedDatasetValue = async (endpoint, body, token) => {
  let response
  try {
    response = await fetch(apiSurveyURL + endpoint, {
      method: 'POST',
      headers: returnUpdatedHeaders(token, subscriptionKey),
      body: JSON.stringify(body)
    })
  } catch (error) {
    return {
      errMsg: 'Something went wrong. Please try again!',
      loadingStatus: false,
      uploadStatus: ''
    }
  }
  if (response && response.status === 200) {
    return response
  } else {
    return {
      errMsg: 'Something went wrong. Please try again!',
      loadingStatus: false,
      uploadStatus: ''
    }
  }
}

export const finalizeImport = async (endpoint, body, token) => {
  let response
  try {
    response = await fetch(apiSurveyURL + endpoint, {
      method: 'POST',
      headers: returnUpdatedHeaders(token, subscriptionKey),
      body: JSON.stringify(body)
    })
  } catch (error) {
    return {
      errMsg: 'Something went wrong. Please try again!',
      loadingStatus: false,
      uploadStatus: ''
    }
  }
  if (response && response.status === 202) {
    const responseJson = response.json()
    return responseJson
  } else {
    return {
      errMsg: 'Something went wrong. Please try again!',
      loadingStatus: false,
      uploadStatus: ''
    }
  }
}

export const getFinalizeImportStatus = async (endpoint, token) => {
  let response
  try {
    response = await fetch(endpoint, {
      method: 'GET',
      headers: returnUpdatedHeaders(token, subscriptionKey)
    })
  } catch (error) {
    return {
      errMsg: 'Something went wrong. Please try again!',
      loadingStatus: false,
      uploadStatus: ''
    }
  }
  if (response && (response.status === 202 || response.status === 200)) {
    const responseJson = response.json()
    return responseJson
  } else {
    return {
      errMsg: 'Something went wrong. Please try again!',
      loadingStatus: false,
      uploadStatus: ''
    }
  }
}