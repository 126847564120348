import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { RootState } from '../../../../store/reducers/rootReducer';
import { ActiveUsersTabContent } from './ActiveUsersTabContent/ActiveUsersTabContent';
import { InvitedUsersTabContent } from './InvitedUsersTabContent/InvitedUsersTabContent';
import { AdminUsersTableSkeleton } from './AdminUsersTableSkeleton/AdminUsersTableSkeleton';
import { fetchGetJson as getUsers } from '../../../../services/services';

type Props = {
  usersSelectedTab: string;
}

export const Users = ({ usersSelectedTab }: Props) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const users = useSelector((state: RootState) => (state.userStateReducer.userData));
  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    if (!didMount) {
      setDidMount(true)
      getUsers('users', token)
        .then((res: TODO) => {
          if (!res.message && !res.error) {
            dispatch({ type: 'SET_USER_DATA', payload: res.filter((item: TODO) => !item.isApiUser) });
          } else {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.message || res.error } })
          }
        })
    }
  }, [didMount, token, dispatch])

  return (
    <div className="d-flex flex-column p-3 overflow-auto">
      <div className="d-flex flex-column">
        {
          users === null ?
            <AdminUsersTableSkeleton />
            :
            usersSelectedTab === 'Active' ?
              <ActiveUsersTabContent
                users={users}
                updateUsers={() => setDidMount(false)}
              />
              :
              <InvitedUsersTabContent users={users} />
        }
      </div>
    </div>
  )
}