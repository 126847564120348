export function onDragStart(result, secondColumnIndex, theState, removeItem) {
  // Get the item and it's id from the second column so it can be removed from the 'secondColumnIndex'
  if (result.source.droppableId === 'scndColumn') {
    if (secondColumnIndex.includes('\\' + theState.state.scndColumn[result.source.index].title + '.A')) {
      return {
        ...theState,
        removeItem: {
          id: result.source.index,
          item: '\\' + theState.state.scndColumn[result.source.index].title + '.A'
        }
      }
    } else {
      return {...theState}
    }
  } else {
    return {...theState}
  }
}