import { onDragEnd } from "../../../components/Analysis/Analyze/components/shared/helpers/onDragEnd/onDragEnd";
import { clearQuestions } from "../../../components/Analysis/Analyze/components/shared/helpers/clearQuestions/clearQuestions";
import { clearQuestionColumns } from '../../../components/Analysis/Analyze/components/shared/helpers/clearQuestionColumns/clearQuestionColumns'
import { onSelectQuestion } from "../../../components/Analysis/Analyze/components/shared/helpers/onSelectQuestion/onSelectQuestion";
import { onDragStart } from "../../../components/Analysis/Analyze/components/shared/helpers/onDragStart/onDragStart";
import { onCheckQuestion } from "../../../components/Analysis/Analyze/components/shared/helpers/onCheckQuestion/onCheckQuestion";
import { clearQuestionRows } from "../../../components/Analysis/Analyze/components/shared/helpers/clearQuestionRows/clearQuestionRows";
import { clearQuestionChecked } from "../../../components/Analysis/Analyze/components/shared/helpers/clearQuestionChecked/clearQuestionChecked";

const initialState = {
  theState: {
    state: {
      optionId: [],
      selectedFilterOrUniverseData: [],
      initialOptionsData: {
        initialFilterData: [],
        initialUniverseData: [],
        initialQuestionOptions: {},
        initialNewQuestionOptions: {},
        initialSelectedFilterOrUniverseData: []
      },
      initialAndUpdateOptionsValues: {
        initialOptionsValues: {},
        updateOptionsValues: {}
      },
      firstColumn: [],
      scndColumn: []
    },
    source: {},
    destination: {},
    removeItem: { 'id': null, 'item': null },
    questions: { value: '' },
    titles: [],
    zoomLevel: 1,
    undoQuestionOptions: [],
    redoQuestionOptions: []
  }
};

function stateReducer(state = initialState.theState, action) {
  switch (action.type) {
    case 'UPDATE_INITIAL_STATE':
      return {
        ...state,
        state: {
          ...state.state,
          ...action.payload
        }
      }
    case 'SET_INITIAL_STATE':
      return {
        ...state,
        state: {
          ...state.state,
          firstColumn: [],
          scndColumn: [],
          activeQuestion: {},
          filterData: [],
          universeData: [],
          columns: [],
          secondColumnIndex: [],
          data: {},
          secondColumns: { value: '' },
          displayTable: null,
          checkedNum: [],
          selectedQuestionSource: null,
          loadSession: false
        },
        source: {},
        destination: {},
        removeItem: { 'id': null, 'item': null },
        questions: { value: '' },
        titles: []
      }
    case 'XT_PASTE_QUESTIONS':
      return {
        ...state,
        state: {
          ...state.state,
          firstColumn: action.payload.firstColumn,
          scndColumn: action.payload.scndColumn,
          updateTable: true
        }
      }
    case 'UPDATE_XT_UNDO_QUESTION_OPTIONS':
      return {
        ...state,
        undoQuestionOptions: action.payload
      }
    case 'XT_UNDO_LAST_CHANGE':
      return {
        ...state,
        state: {
          ...state.state,
          questionOptions: action.payload.questionOptions,
          newQuestionOptions: action.payload.newQuestionOptions,
          updateTable: true
        },
        undoQuestionOptions: action.payload.undoQuestionOptions,
        redoQuestionOptions: action.payload.redoQuestionOptions
      }
    case 'XT_REDO_LAST_CHANGE':
      return {
        ...state,
        state: {
          ...state.state,
          questionOptions: action.payload.questionOptions,
          newQuestionOptions: action.payload.newQuestionOptions,
          updateTable: true
        },
        undoQuestionOptions: action.payload.undoQuestionOptions,
        redoQuestionOptions: action.payload.redoQuestionOptions
      }
    case 'SET_INITIAL_XT_OPTIONS':
      return {
        ...state,
        state: {
          ...state.state,
          questionOptions: action.payload.questionOptions,
          newQuestionOptions: action.payload.newQuestionOptions,
          defaultQuestionOptions: action.payload.questionOptions,
          defaultNewQuestionOptions: action.payload.newQuestionOptions
        }
      }
    case 'ON_DRAG_START':
      return onDragStart(
        action.payload.result,
        state.state.secondColumnIndex,
        state,
        state.removeItem)
    case 'ON_DRAG_END':
      return onDragEnd(
        action.payload.result,
        state,
        state.source,
        state.destination,
        state.removeItem,
        state.state.secondColumnIndex,
        state.state.columns,
        action.payload.theData,
        action.payload.rangeItems)
    case 'SELECT_QUESTION':
      return onSelectQuestion(state, action.payload.id);
    case 'CLEAR_QUESTIONS':
      return clearQuestions(state, action.payload)
    case 'CLEAR_QUESTION_COLUMNS':
      return clearQuestionColumns(state)
    case 'CLEAR_QUESTION_ROWS':
      return clearQuestionRows(state)
    case 'ON_CHECK_QUESTION':
      return onCheckQuestion(action.payload.id, action.payload.source, action.payload.itemId, state, state.state.checkedNum)
    case 'CLEAR_QUESTION_CHECKED':
      return clearQuestionChecked(state)
    case 'SET_TABLE_STATE':
      const stateCopy = { ...state.state }
      return {
        ...state,
        state: {
          ...stateCopy,
          displayTable: action.payload.value,
          updateTable: false
        }
      }
    case 'SET_UPDATE_TABLE_STATE':
      const copyState = { ...state.state }
      return {
        ...state,
        state: {
          ...copyState,
          updateTable: action.payload.status
        }
      }
    case 'EDIT_QUESTION':
      const updatedState = { ...state.state }
      return {
        ...state,
        state: {
          ...updatedState,
          firstColumn: action.payload.firstColumn,
          scndColumn: action.payload.scndColumn,
          updateTable: true
        }
      }
    case 'SET_QUESTION_OPTIONS':
      const copiedState = { ...state.state }
      return {
        ...state,
        state: {
          ...copiedState,
          updateTable: true,
          questionOptions: action.payload.values,
          newQuestionOptions: action.payload.newValues
        }
      }
    case 'SET_CHECKED_QUESTION_OPTIONS':
      const oldState = { ...state.state }
      return {
        ...state,
        state: {
          ...oldState,
          updateTable: true,
          firstColumn: action.payload
        }
      }
    case 'CLEAR_QUESTION_OPTIONS':
      const optionState = { ...state.state };
      const firstColumn = [...state.state.firstColumn];
      firstColumn.forEach(el => { el.options = {} });

      return {
        ...state,
        state: {
          ...optionState,
          updateTable: action.payload.clearSession ? false : true,
          questionOptions: action.payload.values,
          newQuestionOptions: action.payload.options,
          selectedFilterOrUniverseData: [],
          filterData: [],
          universeData: []
        }
      }
    case 'CLEAR_SELECTED_QUESTION_OPTIONS':
      const firstColumnCopy = [...state.state.firstColumn]
      const selectedFirstColumnQuestions = firstColumnCopy.filter(el => el.selected)
      selectedFirstColumnQuestions.forEach(el => { el.options = {} })
      return {
        ...state,
        state: {
          ...state.state,
          updateTable: true,
          firstColumn: firstColumnCopy
        }
      }
    case 'SET_FILTER_DATA':
      const filterState = { ...state.state }
      return {
        ...state,
        state: {
          ...filterState,
          updateTable: true,
          filterData: action.payload.data
        }
      }
    case 'SET_UNIVERSE_DATA':
      const universeState = { ...state.state }
      return {
        ...state,
        state: {
          ...universeState,
          updateTable: true,
          universeData: action.payload.data
        }
      }
    case 'SET_OPTION':
      const optionId = [...state.state.optionId]
      if (action.payload.data) {
        return {
          ...state,
          state: {
            ...state.state,
            optionId: [...optionId, action.payload.data]
          }
        }
      }
      return {
        ...state,
        state: {
          ...state.state,
          optionId: []
        }
      }
    case 'ADD_HIERARCHY':
      const hierarchyState = { ...state.state }
      return {
        ...state,
        state: {
          ...hierarchyState,
          updateTable: true,
          [action.payload.source]: action.payload.column,
        }
      }
    case 'EDIT_HIERARCHY':
      const editHierarchyState = { ...state.state }
      return {
        ...state,
        state: {
          ...editHierarchyState,
          updateTable: true,
          firstColumn: action.payload.firstColumn,
          scndColumn: action.payload.scndColumn
        }
      }
    case 'SET_INITIAL_OPTIONS_DATA':
      return {
        ...state,
        state: {
          ...state.state,
          initialOptionsData: {
            initialFilterData: action.payload.initialFilterData,
            initialUniverseData: action.payload.initialUniverseData,
            initialQuestionOptions: action.payload.initialQuestionOptions,
            initialNewQuestionOptions: action.payload.initialNewQuestionOptions,
            initialSelectedFilterOrUniverseData: action.payload.initialSelectedFilterOrUniverseData
          }
        }
      }
    case 'SET_CURRENT_OPTIONS_DATA':
      return {
        ...state,
        state: {
          ...state.state,
          filterData: action.payload.filterData,
          universeData: action.payload.universeData,
          questionOptions: action.payload.questionOptions,
          newQuestionOptions: action.payload.newQuestionOptions,
          selectedFilterOrUniverseData: action.payload.selectedFilterOrUniverseData,
          firstColumn: action.payload.firstColumn,
        }
      }
    case 'SET_INITIAL_OPTIONS_VALUES':
      return {
        ...state,
        state: {
          ...state.state,
          initialAndUpdateOptionsValues: {
            initialOptionsValues: action.payload.initialAndUpdateOptionsValues.initialOptionsValues,
            updateOptionsValues: action.payload.initialAndUpdateOptionsValues.updateOptionsValues
          }
        }
      }
    case 'SET_ZOOM_LEVEL':
      return {
        ...state,
        zoomLevel: action.payload
      }
    case 'SET_SELECTED_DATA':
      return {
        ...state,
        state: {
          ...state.state,
          selectedFilterOrUniverseData: action.payload.data
        }
      }
    case 'XT_DISABLE_SELECTED_QUESTIONS':
      return {
        ...state,
        state: {
          ...state.state,
          firstColumn: action.payload.firstColumn,
          scndColumn: action.payload.scndColumn,
          checkedNum: []
        }
      }
    case 'XT_ENABLE_SELECTED_QUESTIONS':
      return {
        ...state,
        state: {
          ...state.state,
          firstColumn: action.payload.firstColumn,
          scndColumn: action.payload.scndColumn,
          checkedNum: []
        }
      }
    case 'XT_UPDATE_ROWS':
      return {
        ...state,
        state: {
          ...state.state,
          firstColumn: action.payload,
        }
      }
    case 'XT_UPDATE_COLUMNS':
      return {
        ...state,
        state: {
          ...state.state,
          scndColumn: action.payload,
        }
      }
    case 'XT_UPDATE_CHECKED_NUM':
      return {
        ...state,
        state: {
          ...state.state,
          checkedNum: action.payload,
        }
      }
    case 'UPDATE_TABLE':
      return {
        ...state,
        state: {
          ...state.state,
          updateTable: action.payload
        }
      }

    default:
      return { ...state };
  }
}

export default stateReducer;