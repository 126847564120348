import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import type { RootState } from "../../../store/reducers/rootReducer";
import type { EmailProvider } from "../../../interfaces/emailProviderInterfaces/emailProviderInterfaces";
import { fetchGetJson as getEmailProviders } from "../../../services/services";

export const useFetchEmailProviders = (customStateUpdate?: (res: EmailProvider[]) => void) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { shouldUpdateEmailProviders } = useSelector((theState: RootState) => (theState.adminReducer));
  const [didMount, setDidMount] = useState(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (shouldUpdateEmailProviders || !didMount) {
      setDidMount(true)
      dispatch({ type: 'UPDATE_EMAIL_PROVIDERS', payload: false })
      getEmailProviders("emailproviders", token)
        .then((res: TODO) => {
          if (res && !res.message && !res.error) {
            if (typeof customStateUpdate === 'function') {
              customStateUpdate(res);
            } else {
              dispatch({ type: 'SET_EMAIL_PROVIDERS', payload: res })
            }
          } else {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.message || res.error } })
          }
        })
    }
  }, [dispatch, shouldUpdateEmailProviders, didMount, token])
}