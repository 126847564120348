export default (data, checkedQuestions, initialData) => {
  const firstElIndex = data.indexOf(checkedQuestions[0])
  const firstInitialElIndex = initialData.indexOf(checkedQuestions[0])
  const mergedValues = []
  checkedQuestions.forEach(el => {
    if (Array.isArray(el.values.value)) {
      el.values.value.forEach(val => {
        mergedValues.push(val)
      })
    } else {
      mergedValues.push(el.values.value)
    }
  })
  const mergedMultiChoiceQuestion = {
    "id": data[firstElIndex].id + '.1',
    "title": data[firstElIndex].title.length < 60 ? data[firstElIndex].title : data[firstElIndex].title.substring(0, 60) + '...',
    "values": {
      "value": mergedValues.map((el, id) => {
        return {
          "@code": id + 1,
          "text": el.text
        }
      })
    },
    "selected": false,
    "checked": false,
    "type": 'multichoiceNmopen',
    "order": checkedQuestions[0].order ? checkedQuestions[0].order : '0',
    "merged": true
  }
  data.splice(firstElIndex, 0, mergedMultiChoiceQuestion);
  checkedQuestions.forEach(el => {
    data = data.filter(val => JSON.stringify(val) !== JSON.stringify(el))
  })
  initialData.splice(firstInitialElIndex, 0, mergedMultiChoiceQuestion);
  checkedQuestions.forEach(el => {
    initialData = initialData.filter(val => JSON.stringify(val) !== JSON.stringify(el))
  })
  mergedMultiChoiceQuestion.mergedQuestions = checkedQuestions;
  return {
    data: data,
    initialData: initialData,
    element: mergedMultiChoiceQuestion
  }
}