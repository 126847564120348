import { useEffect, useState } from 'react';
import Editor from '@monaco-editor/react';
import { useDispatch, useSelector } from 'react-redux';

import type { RootState } from '../../../../../../../../../../store/reducers/rootReducer';
import { FullHeightSpinner } from '../../../../../../../../../shared/FullHeightSpinner/FullHeightSpinner';
import type { AdvancedOptionChanges } from '../../../../../../../../../../interfaces/surveyInterfaces/themeTypes';
import { fetchGetRes } from '../../../../../../../../../../services/services';

interface Props {
  unsavedChanges: AdvancedOptionChanges
  setUnsavedChanges: (unsaved: AdvancedOptionChanges) => void
}

export const AdvancedOptionsJavascript = ({ unsavedChanges, setUnsavedChanges }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const [didGetJS, setDidGetJS] = useState(false);
  const { theData, themeId, themeJs } = useSelector((theState: RootState) => (theState.surveyInitialDataReducer))

  useEffect(() => {
    if (!didGetJS) {
      fetchGetRes(theData.dcV2 === 2 ? `su/themes/${themeId}/js` : `themes/${themeId}/js`, token)
        .then((res: TODO) => {
          if (res.status === 200) { return res.text() } return res.json()
        })
        .then((res: TODO) => {
          setDidGetJS(true)
          if (res && !res.error && !res.message) {
            dispatch({ type: 'SET_THEME_JS', payload: res.toString() })
          } else {
            if (res.error && res.error !== 'Theme has no javascript') {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
            }
          }
        })
    }
  }, [dispatch, themeId, token, didGetJS, theData.dcV2])

  const handleEditorChange = (value: TODO) => {
    if (!unsavedChanges.js) { setUnsavedChanges({ ...unsavedChanges, js: true }) }
    dispatch({ type: 'SET_THEME_JS', payload: value })
  }

  if (didGetJS) {
    return (
      <Editor
        onChange={handleEditorChange}
        defaultValue={themeJs}
        options={{
          autoIndent: 'full',
          tabSize: 4,
          formatOnPaste: true,
          formatOnType: true,
        }}
        theme={'vs-dark'}
        defaultLanguage="javascript"
      />
    )
  }
  return (
    <FullHeightSpinner />
  )
}