import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

export default function ErrorModal({ handleClose, onFixValidationErrors }: { handleClose: () => void, onFixValidationErrors: () => void }) {
  return (
    <Dialog title="Validation errors" onClose={handleClose} width={550} className="validation-error-modal">
      <div className="d-flex flex-column p-3 w-100 h-100">
        <div className="alert alert-survey d-flex align-items-center shadow-sm my-3">
          <i className="fas fa-info-circle pr-3"></i>
          <span>Validation failed. Please fix all errors and retry</span>
        </div>
      </div>
      <DialogActionsBar>
        <Button className="btn btn-secondary" onClick={handleClose}>Cancel</Button>
        <Button className="btn btn-primary" onClick={() => { handleClose(); onFixValidationErrors(); }}>Fix errors</Button>
      </DialogActionsBar>
    </Dialog>
  )
}