export const renameUserTypeRoles = (type: string) => {
  switch (type) {
    case "admin":
      return "Advanced"
    case "owner":
      return "Administrator"
    case "regular":
      return "Standard"
    default:
      return ""
  }
};

export const revertUserTypeRoles = (type: string) => {
  switch (type) {
    case "Advanced":
      return "admin"
    case "Administrator":
      return "owner"
    case "Standard":
      return "regular"
    default:
      return ""
  }
};