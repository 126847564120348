import { useRef, useEffect } from "react";

type UseKeyboardShortcut = (shortcutKeys: string[], callback: () => void) => void;

// The useEffects, ref and timeout are used to prevent re-renders and multiple calls to the callback function
export const useKeyboardShortcut: UseKeyboardShortcut = (shortcutKeys, callback) => {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handleShortcut = (event: KeyboardEvent): void => {
      const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
      const isCtrlOrCmd = (event.ctrlKey && !isMac) || (event.metaKey && isMac);
      const shouldIgnoreShortcut = document.activeElement?.nodeName === 'INPUT' || document.activeElement?.nodeName === 'TEXTAREA' || document.activeElement?.classList.contains('ql-editor');
      const didUserClickCtrlAndProvidedKey = isCtrlOrCmd && shortcutKeys.includes(event.key.toLowerCase());
      const didUserOnlyClickTheProvidedDeleteKey = event.key === 'Delete' && shortcutKeys.includes('delete');

      if (didUserClickCtrlAndProvidedKey || didUserOnlyClickTheProvidedDeleteKey) {
        if (!shouldIgnoreShortcut) {
          event.preventDefault();

          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }

          timeoutRef.current = setTimeout(() => {
            callbackRef.current();
          }, 100);
        }
      }
    };

    document.addEventListener('keydown', handleShortcut);

    return () => {
      document.removeEventListener('keydown', handleShortcut);
    };
  }, [shortcutKeys]);
};