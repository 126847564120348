import { BaseDropDownList } from "../../../shared/Inputs";
import type { TabTitles, UserSelectedTab } from '../../../../interfaces/adminInterfaces/adminInterfaces';
import { returnAdminButtonText } from '../../../shared/helpers/returnAdminButtonText/returnAdminButtonText';

interface Props {
  title: TabTitles
  selectAction: (e: { target: { id: string } }) => void
  setUsersSelectedTab: (usersSelectedTab: UserSelectedTab) => void
}

export const AdminHeader = ({ title, selectAction, setUsersSelectedTab }: Props) => {
  const tabs: UserSelectedTab[] = ['Active', 'Invited'];
  const actionText = returnAdminButtonText(title);

  return (
    <div className={"d-flex flex-wrap px-4 py-5 justify-content-between align-items-center border-bottom"}>
      <div className="d-flex align-items-center gap-lg">
        <div className="d-flex flex-column justify-content-center">
          <h2 className={"heading-dashboard strong"}>{title}</h2>
        </div>
      </div>
      <div className="d-flex gap-md align-items-center">
        {
          title === "Users" &&
          <BaseDropDownList
            className="w-10"
            filterable={false}
            data={tabs}
            defaultValue={'Active'}
            onChange={(e: { target: { value: UserSelectedTab } }) => setUsersSelectedTab(e.target.value)}
          />
        }
        {
          title !== "Tags" && title !== "Audit Logs" && title !== "Customizations" && title !== "Email Templates" &&
          <button
            type='button'
            className="btn btn-primary"
            onClick={() => selectAction({ target: { id: actionText } })}>
            {actionText}
          </button>
        }
      </div>
    </div>
  )
}