import { returnUpdatedHeaders } from "../../components/shared/helpers/returnUpdatedHeaders"
import { apiSurveyURL, subscriptionKey } from "../services"

export const exportDataset = (url, token, body) => {
  try {
    return fetch(apiSurveyURL + url, {
      method: 'POST',
      headers: returnUpdatedHeaders(token, subscriptionKey, 'application/json'),
      body: JSON.stringify(body)
    })
      .then(response => {
        if (response.status === 200) {
          return response.blob()
        } else {
          return response.json()
        }
      })
      .then(data => data)
      .catch(error => {
        console.log(error);
        return error;
      })
  } catch (error) {
    console.log(error)
    return error
  }
}