import React from 'react'

export default (props) => {
  return (
    <div>
      {props && props.theData &&
        <span className="d-flex align-items-center" title={props.theData.selectedItem.elements.filter(el => el.type === 'subq')[props.index].disabled ? "This element is disabled" : undefined}>
          {props.theData.optionsType && props.theData.optionsType.type === 'subq' && (props.selected === props.index && props.theData.optionsType.subQuesIndex === props.index) && props.theData.displayOptions === true ?
            <div className="edit-dot pulse mr-2" /> : null}
          <span className="mb-0">Question {props.index + 1}</span>
        </span>}
    </div>
  )
}