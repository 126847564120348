import { useEffect, useState } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import usePrevious from '../../../../shared/customHooks/usePrevious';
import { CreateAPIKeyContent } from './CreateAPIKeyContent/CreateAPIKeyContent';
import { CreatedAPIKeyContent } from './CreatedAPIKeyContent/CreatedAPIKeyContent';
import { InProgressOverlay } from '../../../../shared/InProgressOverlay/InProgressOverlay';
import type { APIKey, CreateAPIKeyFormData } from '../../../../../interfaces/adminInterfaces/adminInterfaces';
import { apiKeySubmitHandler } from '../../../../Analysis/Analyze/components/shared/helpers/apiKeySubmitHandler/apiKeySubmitHandler';
import { revertUserTypeRoles, renameUserTypeRoles } from '../../../../shared/helpers/renameUserTypeRoles/renameUserTypeRoles';

interface Props {
  token: string
  apiKey: APIKey
  onHide: () => void
  updateData: () => void
}

export const CreateAPIKeyModal = ({ token, apiKey, onHide, updateData }: Props) => {
  const prevApiKey = usePrevious(apiKey)
  const [formData, setFormData] = useState<CreateAPIKeyFormData>({ name: '', userType: '', clientId: '', clientSecret: '' })
  const [isCreated, setIsCreated] = useState<boolean>(false)
  const [isCreating, setIsCreating] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    if (apiKey && apiKey !== prevApiKey) {
      setFormData({
        ...formData,
        name: apiKey.firstName,
        userType: renameUserTypeRoles(apiKey.role)
      })
    }
  }, [apiKey, formData, prevApiKey])

  const onChangeHandler = (e: TODO) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const onSubmitHandler = (e: TODO) => {
    e.preventDefault()
    setIsCreating(true)
    const body = { name: formData.name, role: revertUserTypeRoles(formData.userType) }
    apiKeySubmitHandler(formData, apiKey, token, body,
      (msg) => setErrorMessage(msg),
      onHide,
      updateData,
      (val) => setIsCreating(val),
      (val) => setIsCreated(val),
      (data) => setFormData(data))
  }

  return (
    <Dialog
      width={600}
      onClose={onHide}
      title={"Create API Key"}
      contentStyle={{ padding: '0px' }}>
      <form onSubmit={onSubmitHandler}>
        <div className="px-5 py-3">
          {
            isCreated ?
              <CreatedAPIKeyContent
                formData={formData} />
              :
              <CreateAPIKeyContent
                formData={formData}
                errorMessage={errorMessage}
                onChangeHandler={onChangeHandler} />
          }
        </div>

        <DialogActionsBar>
          <button type="button" className="k-button k-button-solid-base" onClick={onHide}>{isCreated ? 'Close' : 'Cancel'}</button>
          {
            isCreated === false &&
            <button disabled={isCreating} type="submit" className="k-button k-button-solid-primary">
              {
                isCreating ?
                  <InProgressOverlay
                    message=""
                    className=""
                    type="button"
                    theme="light" />
                  :
                  <span>{apiKey ? 'Update' : 'Create'}</span>
              }
            </button>
          }
        </DialogActionsBar>
      </form>
    </Dialog >
  )
}