type Questions = {
  id: string
  title: string
  type: string
  groups: { code: string, merged?: boolean }[]
  subGroups: { code: string, merged?: boolean }[]
}[];

type CopiedEditsData = {
  selectedQuestionType: string
  groups: { code: string, merged?: boolean }[]
  subGroups: { code: string, merged?: boolean }[]
}

export const returnCompatibleQuestionsForPasteEdits = (questions: Questions, copiedEditsData: CopiedEditsData) => {
  const { selectedQuestionType, groups, subGroups } = copiedEditsData;
  const compatibleQuestions = [];
  const filteredGroupsFromCopiedEditsData = groups.filter(group => group.merged !== true);
  const filteredSubGroupsFromCopiedEditsData = subGroups.filter(subGroup => subGroup.merged !== true);

  for (const question of questions) {
    const filteredGroupsFromQuestion = question.groups.filter(group => group.merged !== true);
    const filteredSubGroupsFromQuestion = question.subGroups.filter(subGroup => subGroup.merged !== true);
    if (question.type === selectedQuestionType) {
      if (selectedQuestionType === 'n' || selectedQuestionType === 'm') {
        if (filteredGroupsFromQuestion.length === filteredGroupsFromCopiedEditsData.length) {
          const isCompatible = filteredGroupsFromQuestion.every((group, index) => {
            return group.code === filteredGroupsFromCopiedEditsData[index].code;
          });
          if (isCompatible) {
            compatibleQuestions.push(question);
          }
        }
      } else if (selectedQuestionType === 'rn' || selectedQuestionType === 'rm') {
        if (filteredSubGroupsFromQuestion.length === filteredSubGroupsFromCopiedEditsData.length) {
          const isCompatible = filteredSubGroupsFromQuestion.every((subGroup, index) => {
            return subGroup.code === filteredSubGroupsFromCopiedEditsData[index].code;
          });
          if (isCompatible) {
            compatibleQuestions.push(question);
          }
        }
      } else {
        if (filteredGroupsFromQuestion.length === filteredGroupsFromCopiedEditsData.length) {
          compatibleQuestions.push(question);
        }
      }
    }
  }

  return compatibleQuestions;
};