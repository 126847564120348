export const selectFileManagerFile = (item: any, checkedFiles: any[]) => {
  const checkedFilesCopy = [...checkedFiles]
  if (item) {
    if (checkedFilesCopy.length > 0) {
      const matchedFile = checkedFilesCopy.find(file => file.id === item.id)
      const matchedFileIndex = checkedFilesCopy.indexOf(matchedFile)
      if (matchedFile) {
        checkedFilesCopy.splice(matchedFileIndex, 1)
      } else {
        checkedFilesCopy.push(item)
      }
    } else {
      checkedFilesCopy.push(item)
    }
  }

  return checkedFilesCopy
}