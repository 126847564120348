export default (ques) => {
  let buttonIcon = null;

  switch (true) {
    case ques.multilist:
      buttonIcon = "fas fa-check-square";
      break;
    case ques.multigrid:
      buttonIcon = "fas fa-table";
      break;
    case ques.merged:
      buttonIcon = "fas fa-check-square";
      break;
    case ques.type === 'quantity':
      buttonIcon = "quantity strong";
      break;
    case ques.type === 'multiple':
      buttonIcon = "far fa-check-square";
      break;
    case ques.type === "character":
      buttonIcon = "fas fa-font-case";
      break;
    default:
      buttonIcon = "far fa-dot-circle";
  }

  return buttonIcon;
}
