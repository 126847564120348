import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ContainerClient } from '@azure/storage-blob';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import usePrevious from '../../../../../../shared/customHooks/usePrevious';
import type { RootState } from '../../../../../../../store/reducers/rootReducer';
import { ErrorMessage } from '../../../../../../shared/ErrorMessage/ErrorMessage';
import { fetchGetJson, fetchPostJson, fetchPostResOrJson } from '../../../../../../../services/services';

interface Props {
  handleClose: () => void
}

export const SurveyDesignImportTranslationModal = ({ handleClose }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const params: { name: string, survey: string } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [translationFile, setTranslationFile] = useState<TODO>(null)
  const [excelFileUploaded, setExcelFileUploaded] = useState<null | boolean>(null);
  const [excelErrorMessage, setExcelErrorMessage] = useState('')
  const { shouldUpdatelanguages, theData } = useSelector((theState: RootState) => theState.surveyInitialDataReducer)
  const prevShouldUpdateLanguages = usePrevious(shouldUpdatelanguages)

  useEffect(() => {
    if (shouldUpdatelanguages !== prevShouldUpdateLanguages) {
      if (shouldUpdatelanguages === 'Completed') {
        localStorage.removeItem('translationImportId')
        dispatch({ type: 'SHOULD_UPDATE_LANGUAGES', payload: "not started" });
        fetchGetJson(`su/projects/${params.name}/surveys/${params.survey}/index/etag`, token)
          .then((res: TODO) => {
            if (res && !res.error && !res.message) {
              const updatedOriginalData = JSON.parse(JSON.stringify(theData.originalData))
              updatedOriginalData._etag = res.etag
              dispatch({ type: 'UPDATE_SURVEY_DATA', payload: { data: JSON.parse(JSON.stringify(theData.data)), originalData: updatedOriginalData } })
              dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The translation file has been imported successfully' } })
              setExcelFileUploaded(null)
              handleClose()
            } else {
              setIsLoading(false)
              setErrorMessage(res.error)
            }
          })
      } else if (shouldUpdatelanguages === 'Failure') {
        localStorage.removeItem('translationImportId')
        dispatch({ type: 'SHOULD_UPDATE_LANGUAGES', payload: "not started" });
        setIsLoading(false)
        setErrorMessage('Something went wrong. Please try again.')
      }
    }
  }, [shouldUpdatelanguages, prevShouldUpdateLanguages])

  const onChangeHandler = (e: TODO) => {
    setTranslationFile(e.target.files)
    if (e.target.files && e.target.files.length > 0) {
      const extension = e.target.files[0].name.split('.').pop()
      if (extension === 'xlsx' || extension === 'xls') {
        setExcelFileUploaded(true);
      } else {
        setExcelFileUploaded(false)
        setExcelErrorMessage("Not a supported file. Please upload an Excel file");
      }
    }
  }

  const onImportTranslationHandler = async () => {
    if (translationFile?.[0] && excelFileUploaded) {
      setIsLoading(true)
      const body = { fileName: translationFile[0].name }
      const translationTokenRes = await fetchPostJson(`su/projects/${params.name}/surveys/${params.survey}/translation-token`, token, body)
      if (translationTokenRes && !translationTokenRes.error && !translationTokenRes.message) {
        const containerClient = new ContainerClient(translationTokenRes.sasUri);
        const promises = [];
        for (const file of translationFile) {
          const blobName = translationTokenRes.blob.name;
          const blockBlobClient = containerClient.getBlockBlobClient(blobName);
          promises.push(blockBlobClient.uploadBrowserData(file));
        }
        const resp = await Promise.all(promises);
        if (resp) {
          const importTranslationBody = { fileName: translationTokenRes.blob.name }
          const importTranslationRes = await fetchPostResOrJson(`su/projects/${params.name}/surveys/${params.survey}/translation-import`, token, importTranslationBody)
          if (importTranslationRes.status === 202) {
            const importTranslationData = await importTranslationRes.json()
            if (importTranslationData?.inboxItemId) {
              localStorage.setItem('translationImportId', importTranslationData.inboxItemId)
              dispatch({ type: 'SHOULD_UPDATE_LANGUAGES', payload: 'Initialized' });
            }
            dispatch({ type: 'SHOULD_UPDATE_LANGUAGES', payload: "Initialized" });
            handleClose()
          } else {
            setIsLoading(false)
            translationTokenRes(importTranslationRes.error)
          }
        }
      } else {
        setIsLoading(false)
        setErrorMessage(translationTokenRes.error)
      }
    }
  }

  return (
    <Dialog
      closeIcon={!isLoading}
      onClose={handleClose}
      title={"Import language translation"}
      width={600}
      className="d-flex flex-column w-100">
      <div className="p-4">
        <div className="input-group mb-3">
          <div className="custom-file">
            <input onChange={onChangeHandler} type="file" className="custom-file-input" accept=".xlsx, .xls" />
            <label className="custom-file-label">
              {translationFile?.[0] ? translationFile[0].name : 'Choose file'}
            </label>
          </div>
        </div>
        {
          excelFileUploaded === false &&
          <div className="mt-4">
            <ErrorMessage
              type="alert"
              errorMessage={excelErrorMessage} />
          </div>
        }
        {
          errorMessage &&
          <div className="mt-4">
            <ErrorMessage
              type="alert"
              errorMessage={errorMessage} />
          </div>
        }
      </div>
      <DialogActionsBar>
        <button type='button' disabled={isLoading} className="k-button btn btn-secondary" onClick={handleClose}>Cancel</button>
        <button type='button' disabled={!translationFile || !translationFile[0] || isLoading || !excelFileUploaded} className="k-button btn btn-primary" onClick={onImportTranslationHandler}>
          {
            isLoading &&
            <div className="spinner-border spinner-border-sm mr-2" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          }
          <span>Import</span>
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}
