import { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Icon } from '../../../shared/Icon/Icon';
import { Avatar } from '../../../shared/Avatar/Avatar';
import { formatDate } from '../../../shared/DateUtils/DateUtils';
import type { RootState } from '../../../../store/reducers/rootReducer';
import { useFetchSMSProviders } from '../../../shared/customHooks/useFetchSMSProviders';
import { DeleteSMSProviderModal } from './DeleteSMSProviderModal/DeleteSMSProviderModal';
import type { EmailProvider } from '../../../../interfaces/emailProviderInterfaces/emailProviderInterfaces';
import { AdminEmailProvidersTableSkeleton } from '../EmailTabContent/AdminEmailProvidersTableSkeleton/AdminEmailProvidersTableSkeleton';
import { fetchGetJson } from '../../../../services/services';

export const SMSTabContent = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { showDeleteSMSProviderModal, SMSProviders } = useSelector((theState: RootState) => theState.adminReducer);

  useFetchSMSProviders();

  const getASingleProvider = (providerType: string, id: string) => {
    if (providerType === "twilio-sms") {
      fetchGetJson(`smsproviders/${id}`, token)
        .then((res: TODO) => {
          if (res.error || res.message) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } })
          } else {
            dispatch({ type: 'UPDATE_SHOW_TWILIO_SMS_PROVIDERS_MODAL', payload: { smsProvider: res, show: true } })
          }
        })
    }
  }

  return (
    <Fragment>
      {
        showDeleteSMSProviderModal.show &&
        <DeleteSMSProviderModal
          SMSProviders={SMSProviders}
          updateSMSProviders={(data) => dispatch({ type: 'SET_SMS_PROVIDERS', payload: data })}
          SMSProvider={showDeleteSMSProviderModal.emailProvider} />
      }
      <div className="px-3 mt-5">
        {
          SMSProviders !== null ?
            SMSProviders.length > 0 ?
              <table className="table table-striped admin-table mt-3">
                <thead>
                  <tr>
                    <th scope='col' />
                    <th scope="col">Provider name</th>
                    <th scope="col">Description</th>
                    <th scope="col">Date created</th>
                    <th scope="col">Created by</th>
                    <th className='text-center' scope="col">Provider type</th>
                    <th className='text-center' scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {
                    SMSProviders.map((SMSProvider: EmailProvider) => (
                      <tr key={SMSProvider.id}>
                        <td className='admin-avatar-cell' style={{ width: '60px' }}>
                          <Avatar
                            color={"primary"}
                            class="avatar-sm"
                            icon={`fas ${SMSProvider.providerType === 'twilio-sms' ? 'fa-paper-plane' : 'fa-comment'} text-white`}
                          />
                        </td>
                        <th scope='row'>{SMSProvider.name}</th>
                        <td>
                          <p style={{ maxWidth: '400px' }} className='m-0 text-truncate'>{SMSProvider.description}</p>
                        </td>
                        <td>{formatDate(SMSProvider.createdUtc, "DATE_MED")}</td>
                        <td>{SMSProvider.createdByName}</td>
                        <td>
                          <div className="d-flex justify-content-center">
                            <span className="badge badge-pill badge-primary text-white text-uppercase">{SMSProvider.providerType}</span>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex justify-content-center gap-lg admin-table-actions">
                            <button
                              disabled={SMSProvider.providerType !== 'twilio-sms'}
                              type="button"
                              className="btn text-dark p-0"
                              onClick={() => getASingleProvider(SMSProvider.providerType, SMSProvider.id)}>
                              <Icon className='pe-none' type='edit' />
                            </button>
                            <button
                              type="button"
                              className="btn text-dark p-0"
                              onClick={() => dispatch({ type: 'UPDATE_SHOW_DELETE_SMS_PROVIDERS_MODAL', payload: { emailProvider: SMSProvider, show: true } })}>
                              <Icon className='pe-none' type='delete-alt' />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
              :
              <div className="d-flex flex-column gap-lg text-disabled text-center h5 pt-10 w-100">
                <i className="fal fa-2x fa-folder-open" />
                <p className='m-0'>No SMS providers have been found</p>
              </div>
            :
            <AdminEmailProvidersTableSkeleton />
        }
      </div>
    </Fragment>
  )
}