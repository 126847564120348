import ctrlShiftHelper from '../ctrlShiftHelper/ctrlShiftHelper';

export default (e, firstColumnItem, onSelectQuestion, index, onCheck, itemId, scndColumnItem, customQues, question, rangeItems, theData, dispatch) => {

  if (firstColumnItem) {
    onSelectQuestion(e, index);
  }
  if (e.ctrlKey) {
    if (firstColumnItem) {
      onCheck(e, index, 'firstColumn', itemId)
    } else if (scndColumnItem) {
      onCheck(e, index, 'scndColumn', itemId)
    } else if (customQues) {
      onCheck(itemId)
    } else if (question) {
      onCheck(itemId)
    }
  } else if (e.metaKey) {
    if (firstColumnItem) {
      onCheck(e, index, 'firstColumn', itemId)
    } else if (scndColumnItem) {
      onCheck(e, index, 'scndColumn', itemId)
    } else if (customQues) {
      onCheck(itemId)
    } else if (question) {
      onCheck(itemId)
    }
  } else if (e.shiftKey) {
    let data = null;
    if (question) {
      if (itemId) {
        data = ctrlShiftHelper(itemId, null, "question", onCheck, index, rangeItems, theData).data;
      }
    } else if (customQues) {
      data = ctrlShiftHelper(itemId, null, "customQuestion", onCheck, index, rangeItems, theData).data;
    } else if (firstColumnItem) {
      data = ctrlShiftHelper(itemId, e, "firstItem", onCheck, index, rangeItems, theData).data;
    } else if (scndColumnItem) {
      data = ctrlShiftHelper(itemId, e, "scndItem", onCheck, index, rangeItems, theData).data;
    }
    dispatch(data);
  }
}
