import { PanelCollectTabContent } from './PanelCollectTabContent/PanelCollectTabContent';
import { PanelDesignTabContent } from './PanelDesignTabContent/PanelDesignTabContent';
import { PanelOverviewTabContent } from './PanelOverviewTabContent/PanelOverviewTabContent';
import { PanelPreviewTabContent } from './PanelPreviewTabContent/PanelPreviewTabContent';
import { PanelSubmitTabContent } from './PanelSubmitTabContent/PanelSubmitTabContent';

interface Props {
  tabType: string
  token: string
  onReviewAndSubmit: () => void
}

export const PanelTabContent = ({ tabType, token, onReviewAndSubmit }: Props) => {
  if (tabType === 'overview') {
    return (
      <PanelOverviewTabContent />
    )
  } else if (tabType === 'design') {
    return (
      <PanelDesignTabContent
        token={token}
        onReviewAndSubmit={onReviewAndSubmit} />
    )
  } else if (tabType === 'preview') {
    return (
      <PanelPreviewTabContent />
    )
  }
  else if (tabType === 'submit') {
    return (
      <PanelSubmitTabContent
        token={token} />
    )
  } else if (tabType === 'collect') {
    return (
      <PanelCollectTabContent />
    )
  } else {
    return (
      <div></div>
    )
  }
}