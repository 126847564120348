import { fetchPostJson as sendTestEmail } from "../../../../services/services"

export const sendTestEmailHandler = (emailProviderType: string, dispatch: any, formData: any, token: string, setIsInProgress: (val: boolean) => void, setSendTestEmailMessage: (val: boolean) => void) => {
  setIsInProgress(true)
  let body = null;
  if (emailProviderType === 'smtp') {
    body = {
      "smtpPort": formData.port,
      "smtpRelay": formData.relay,
      "smtpEnableSsl": formData.SSL,
      "smtpUsername": formData.username,
      "smtpPassword": formData.password
    }
  } else if (emailProviderType === 'sendgrid') {
    body = {
      "apiKey": formData.apiKey
    }
  }

  sendTestEmail(`emailproviders/${emailProviderType}/test`, token, body)
    .then((res: any) => {
      setIsInProgress(false)
      if (res && res.error) {
        dispatch({
          type: 'UPDATE_EMAIL_PROVIDERS_ERROR_MESSAGE', payload: `${res.error} Please check your ${emailProviderType.toUpperCase()} settings`
        })
      } else {
        setSendTestEmailMessage(true)
      }
    })
}