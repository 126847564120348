import { type FormEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import type { RootState } from '../../../../../store/reducers/rootReducer';
import { SubAccountUsersTable } from '../SubAccountUsersTable/SubAccountUsersTable';
import { InProgressOverlay } from '../../../../shared/InProgressOverlay/InProgressOverlay';
import { useFetchSubAccountUsers } from '../../../../shared/customHooks/useFetchSubAccountUsers';
import type { RemoveConfirmModal, SubAccountUser } from '../../../../../interfaces/userInterface/userInterface';
import RemoveInvitedConfirmModal from '../../../../shared/ShareInviteModal/InvitePeopleContent/RemoveInvitedConfirmModal/RemoveInvitedConfirmModal';
import { fetchDelete as deleteSubAccountUser, fetchPostResOrJson as inviteUserAsSubAccount } from '../../../../../services/services';

interface Props {
  onHide: () => void
  reloadSubAccounts: () => void
}

export const SubAccountsInviteUsersModal = ({ onHide, reloadSubAccounts }: Props) => {
  const [data, setData] = useState<SubAccountUser[] | null>(null)
  const [email, setEmail] = useState('')
  const [inviteError, setInviteError] = useState('')
  const [removeInviteError, setRemoveInviteError] = useState('')
  const [shouldFetchUsers, setShouldFetchUsers] = useState(true)
  const [invitePersonLoading, setInvitePersonLoading] = useState(false)
  const [removePersonLoading, setRemovePersonLoading] = useState(false)
  const [removeConfirmModal, setRemoveConfirmModal] = useState<RemoveConfirmModal>({ show: false, element: null })
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const subAccountId = useSelector((theState: RootState) => theState.adminReducer).showSubAccountInviteUsersModal.subAccount;

  useFetchSubAccountUsers(shouldFetchUsers, subAccountId, token, (data) => setData(data), (msg) => setInviteError(msg), (val) => setShouldFetchUsers(val))

  const onInviteUserHandler = (e: FormEvent) => {
    e.preventDefault()
    const body = { email: email }
    setInvitePersonLoading(true)
    inviteUserAsSubAccount(`sub-accounts/${subAccountId}/users`, token, body)
      .then((res: TODO) => {
        if (res) {
          setInvitePersonLoading(false)
          if (res.message || res.error) {
            setInviteError(res.message ? res.message : res.error)
          } else {
            setShouldFetchUsers(true)
            setEmail("")
          }
        }
      })
  }

  const onRemoveInvitedUser = () => {
    const userId = removeConfirmModal.element?.id
    if (userId) {
      setRemovePersonLoading(true)
      deleteSubAccountUser(`sub-accounts/${subAccountId}/users/${userId}`, token)
        .then((res: TODO) => {
          if (res) {
            setRemovePersonLoading(false)
            if (res.status === 200) {
              setShouldFetchUsers(true)
              setRemoveConfirmModal({ show: false, element: null })
            } else {
              setRemoveInviteError('Something went wrong, please try again!')
            }
          }
        })
    }
  }

  const onCloseHandler = () => {
    onHide()
    reloadSubAccounts()
  }

  return (
    <Dialog
      width={800}
      height={'40%'}
      onClose={onHide}
      title={"Invite users"}
      contentStyle={{ padding: '0px' }}>
      <div className="px-5 py-3">
        {
          removeConfirmModal.show &&
          <RemoveInvitedConfirmModal
            removeInviteError={removeInviteError}
            isLoading={removePersonLoading}
            element={removeConfirmModal.element}
            onRemovePersonHandler={onRemoveInvitedUser}
            onHide={() => setRemoveConfirmModal({ ...removeConfirmModal, show: false })} />
        }
        <form className="d-flex align-items-center justify-content-between flex-grow-1 pt-2 pb-4" onSubmit={onInviteUserHandler}>
          <Input
            disabled={invitePersonLoading}
            required={true}
            type="email"
            className="input-group"
            placeholder="Email address"
            onChange={(e) => setEmail(e.value)}
            value={email} />
          <Button
            type="submit"
            disabled={invitePersonLoading}
            className="btn btn-success ml-4 px-5">
            {
              invitePersonLoading ?
                <InProgressOverlay
                  message=""
                  className=""
                  type="button"
                  theme="light" />
                :
                <span>Invite</span>
            }
          </Button>
        </form>
        {
          inviteError &&
          <div className="alert alert-danger text-center mb-4" role="alert">
            <span>{inviteError}</span>
          </div>
        }
        <SubAccountUsersTable
          data={data}
          removeConfirmModal={removeConfirmModal}
          setRemoveConfirmModal={(data) => setRemoveConfirmModal(data)} />
      </div>

      <DialogActionsBar>
        <button type='button' className="k-button k-button-solid-base" onClick={onCloseHandler}>Close</button>
      </DialogActionsBar>
    </Dialog>
  )
}