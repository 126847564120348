import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import React from 'react'
import { useDispatch } from 'react-redux';
import { fetchDelete } from '../../../../../../../services/services';

function DeleteFolderModal({ setDeleteModal, library, token, setShouldMount, libraryScope, deleteModal, setFoldersClicked, setSelectedRow, setSelectedElementType }) {

  const dispatch = useDispatch();

  const closeDialog = () => {
    setDeleteModal({
      show: false,
      id: "",
      name: "",
      pid: null
    })
  };

  const save = () => {
    if (library === 'personal') {
      fetchDelete(`/libraries/${libraryScope}/user/${deleteModal.id}`, token)
        .then(res => {
          if (res.error || res.message) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
          }
        })
        .then(() => {
          setShouldMount(true)
          setFoldersClicked([])
          setSelectedRow()
          setSelectedElementType()
        })
      setDeleteModal({
        show: false,
        id: "",
        name: "",
        pid: null
      })
    } else if (library === 'global') {
      fetchDelete(`/libraries/${libraryScope}/customer/${deleteModal.id}`, token)
        .then(res => {
          if (res.error || res.message) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
          }
        })
        .then(() => {
          setShouldMount(true)
          setFoldersClicked([])
          setSelectedRow()
          setSelectedElementType()
        })
      setDeleteModal({
        show: false,
        id: "",
        name: "",
        pid: null
      })
    }
  };

  return (
    <Dialog width="400px" height="auto" className="import-dialog library-sub-dialog no-wrap" title={`Delete folder`} onClose={closeDialog}>
      <div className="d-flex flex-row justify-content-center align-items-center p-4">
        Are you sure you want to permanently delete the folder?
      </div>
      <DialogActionsBar>
        <button className="k-button btn btn-secondary mr-2" onClick={closeDialog}>
          Cancel
        </button>
        <button onClick={save} className="k-button k-button-solid-primary text-white">
          Delete
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}

export default DeleteFolderModal