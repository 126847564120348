import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useFilter } from '../../customHooks/useFilter';

export const BaseDropDownList = ({ filterable = true, ...props }) => {
  const { data, filterChange } = useFilter(props.data);

  return (
    <DropDownList
      {...props}
      filterable={filterable}
      onFilterChange={filterable ? (props.onFilterChange ? props.onFilterChange : filterChange) : undefined}
      popupSettings={(props.popupSettings?.appendTo) ? props.popupSettings : undefined}
      data={data}
    />
  );
};

