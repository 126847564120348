import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Switch } from '@progress/kendo-react-inputs';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import type { RootState } from '../../../../../../../../store/reducers/rootReducer';
import { ErrorMessage } from '../../../../../../../shared/ErrorMessage/ErrorMessage';
import { fetchPutResOrJson } from '../../../../../../../../services/services';

interface Props {
  themeData: TODO
  onHide: () => void
}

export default function UpdateGalleryThemeModal({ onHide, themeData }: Props) {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const params: { name: string, survey: string } = useParams()
  const [errorMessage, setErrorMessage] = useState('')
  const [saveLatestSettings, setSaveLatestSettings] = useState(themeData.hasBeenEditedAsCss ? false : true)
  const [isUpdatingTheme, setIsUpdatingTheme] = useState(false)

  const onUpdateTheme = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    setIsUpdatingTheme(true)
    if (saveLatestSettings) {
      const body = { themeSettings: themeData.themeSettings }
      fetchPutResOrJson(`/themes/${themeData.id}`, token, body) // Save latest settings to theme
        .then((res: TODO) => {
          if (res.status === 200) {
            fetchPutResOrJson(`su/projects/${params.name}/surveys/${params.survey}/savethemetobase`, token) // Update the original gallery theme
              .then((res: TODO) => {
                setIsUpdatingTheme(false)
                if (res.status === 200) {
                  onHide()
                  dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "The gallery theme has been updated successfuly." } })
                } else if (res && (res.error || res.message)) {
                  setErrorMessage(res.error ? res.error : res.message)
                }
              })
          } else if (res && (res.error || res.message)) {
            setIsUpdatingTheme(false)
            setErrorMessage(res.error ? res.error : res.message)
          }
        })
    } else {
      // Update the original gallery theme
      fetchPutResOrJson(`su/projects/${params.name}/surveys/${params.survey}/savethemetobase`, token)
        .then((res: TODO) => {
          setIsUpdatingTheme(false)
          if (res.status === 200) {
            onHide()
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "The gallery theme has been updated successfuly." } })
          } else if (res && (res.error || res.message)) {
            setErrorMessage(res.error ? res.error : res.message)
          }
        })
    }
  }

  return (
    <Dialog title="Update gallery theme" onClose={onHide} width={430}>
      <form onSubmit={onUpdateTheme}>
        <div className="p-4">
          {
            themeData.hasBeenEditedAsCss ?
              <p className='m-0'>Are you sure you would like to update the gallery theme?</p>
              :
              <div className="d-flex justify-content-between align-items-center">
                <span className='mr-3'>Save theme before updating</span>
                <Switch onChange={(e) => setSaveLatestSettings(e.value)} checked={saveLatestSettings} size="small" />
              </div>
          }
          {
            errorMessage &&
            <div className="mt-3">
              <ErrorMessage
                errorMessage={errorMessage}
                type="alert"
              />
            </div>
          }
        </div>
        <DialogActionsBar>
          <button type="button" className="k-button btn btn-secondary" onClick={onHide}>Cancel</button>
          <button type="submit" disabled={isUpdatingTheme} className="k-button btn btn-primary">
            {
              isUpdatingTheme &&
              <div className="spinner-border spinner-border-sm mr-2" role="status" />
            }
            <span>Update</span>
          </button>
        </DialogActionsBar>
      </form>
    </Dialog>
  )
}