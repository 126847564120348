import { useSelector } from 'react-redux';

import { ActionButtons } from '../../helpers/ActionButtons/ActionButtons';
import { RootState } from '../../../../../../store/reducers/rootReducer';

interface Props {
  step: string
  onNextStep: (type: string) => void
  onReviewAndSubmit: () => void
}

export const Filters = ({ step, onNextStep, onReviewAndSubmit }: Props) => {
  const { panelData } = useSelector((theState: RootState) => theState.panelStateReducer);

  return (
    <div className="d-flex flex-column flex-grow-1 container-fluid p-0">
      <div className="row no-gutters d-flex flex-column border-bottom align-items-center h-100" />
      <ActionButtons
        step={step}
        onNextStep={onNextStep}
        onReviewAndSubmit={onReviewAndSubmit}
        targetInterviews={panelData.sampleSpecification.targetInterviews}
      />
    </div>
  )
}

