import React from 'react';
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartValueAxisItem,
  ChartValueAxis,
  ChartArea,
} from '@progress/kendo-react-charts';
import returnLabelContent from '../../../../../../shared/helpers/returnLabelContent/returnLabelContent';
import returnChartScaleData from '../../../../../../shared/helpers/returnChartScaleData/returnChartScaleData';

export default ({ data, zoomLevel, additionalSettings }) => {
  const categories = data[0].categories
  const series = []

  data[0].series.forEach((el) => {
    series.push(el)
  })

  const values = returnChartScaleData(data[0].scale, data[0].isstacked, data[0].type)

  return (
    <React.Fragment>
      <span className="table-chart-title" style={{ "fontSize": `${((zoomLevel) * 0.875).toString()}rem` }}>{data[0].title}</span>
      <Chart transitions={false} style={{ "minHeight": `${additionalSettings?.chartHeight ? "unset" : 800 * (zoomLevel)}px` }} >
        {additionalSettings?.chartHeight && <ChartArea height={additionalSettings.chartHeight} />}
        <ChartLegend position="right" orientation="vertical" labels={{ font: `${zoomLevel}rem sans-serif` }} />
        <ChartValueAxis>
          <ChartValueAxisItem min={values.min} max={values.max} majorUnit={values.scale} labels={{ font: `${zoomLevel}rem sans-serif` }} />
        </ChartValueAxis>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem categories={categories} labels={{ font: `${zoomLevel}rem sans-serif` }} />
        </ChartCategoryAxis>
        <ChartSeries>
          {
            series.map((item, key) => (
              <ChartSeriesItem
                gap={additionalSettings?.chartBarSize ? additionalSettings.chartBarSize : key === 0 ? 4 : null}
                spacing={additionalSettings?.chartBarSize ? null : key === 0 ? 2 : null}
                type="bar"
                stack={data[0].isstacked}
                key={key}
                data={item.data}
                name={series.length < 50 ? item.name : null}
                tooltip={{ visible: data[0].hidepctsign ? false : true }}
                labels={{ visible: data[0].hidevalues ? false : true, content: (e) => returnLabelContent(e, data[0].hidepctsign), font: `${zoomLevel}rem sans-serif` }} />
            ))
          }
        </ChartSeries>
      </ Chart>
    </React.Fragment >
  )
}