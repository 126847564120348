import disableOptionByPlan from "../disableOptionByPlan/disableOptionByPlan"

export default (data, user, groups) => {
  const initialBooleanOptions = ['QtCatValColPercent', 'QtPctSignFilt', 'QtPctSignHori', 'QtPctSignHoriSum', 'QtPctSignVert', 'QtPctSignVertSum', 'QtQuaValAverage']
  const initialDropdownValuesFirst = ['QtDecimalAccumulated', 'QtDecimalAverage', 'QtDecimalQuantDeriv', 'QtQno']
  const initialDropdownValuesSecond = ['QtCategoricalBase', 'QtQuantitativeBase']
  const initialDropdownValuesZero = ['QtDecimalPercentage', 'QtDecimalPopulation', 'QtDecimalSymbol']
  let list = {}
  data.forEach(option => {
    if (option.type === "Boolean") {
      if (initialBooleanOptions.includes(option.id)) {
        let disabled = disableOptionByPlan(option, user, groups)
        if (disabled === false) {
          list[option.id] = true
        }
        else {
          list[option.id] = false
        }
      }
      else {
        list[option.id] = false
      }
    }
    else if (option.type === "String") {
      list[option.id] = ''
    }
    else if (option.type === "Int32" || option.type === "Double") {
      list[option.id] = 0
    }
    else if (option.id === 'QtSelectedWeight') {
      list[option.id] = 'None'
    }
    else {
      if (initialDropdownValuesFirst.includes(option.id)) {
        let disabled = disableOptionByPlan(option, user, groups)
        if (disabled === false) {
          list[option.id] = 1
        }
        else {
          list[option.id] = 0
        }
      }
      else if (initialDropdownValuesSecond.includes(option.id)) {
        let disabled = disableOptionByPlan(option, user, groups)
        if (disabled === false) {
          list[option.id] = 2
        }
        else {
          list[option.id] = 0
        }

      }
      else if (initialDropdownValuesZero.includes(option.id)) {
        list[option.id] = 0
      }
      else {
        list[option.id] = 0
      }
    }
  })
  return list
}