import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ThemeCssEditor from '../../../ThemeCssEditor/ThemeCssEditor';
import type { RootState } from '../../../../../../../../../../store/reducers/rootReducer';
import { FullHeightSpinner } from '../../../../../../../../../shared/FullHeightSpinner/FullHeightSpinner';
import type { AdvancedOptionChanges } from '../../../../../../../../../../interfaces/surveyInterfaces/themeTypes';
import { getThemeCss } from '../../../../../../../../../../services/services';


type Props = {
  unsavedChanges: AdvancedOptionChanges
  setUnsavedChanges: (unsaved: AdvancedOptionChanges) => void
}

export const AdvancedOptionsCustomCSS = ({ unsavedChanges, setUnsavedChanges }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const [didGetCss, setDidGetCss] = useState(false);
  const { theData, themeId, themeCss } = useSelector((theState: RootState) => (theState.surveyInitialDataReducer))

  useEffect(() => {
    if (!didGetCss) {
      getThemeCss(theData.dcV2 === 2 ? `su/themes/${themeId}/css` : `themes/${themeId}/css`, token)
        .then((res: TODO) => {
          if (res) {
            setDidGetCss(true)
            dispatch({ type: 'SET_THEME_CSS', payload: res.toString() })
          } else {
            setDidGetCss(true)
          }
        })
    }
  }, [dispatch, themeId, token, didGetCss, theData.dcV2])

  const onCssChange = (value: string) => {
    if (!unsavedChanges.css) { setUnsavedChanges({ ...unsavedChanges, css: true }) }
    dispatch({ type: 'SET_THEME_CSS', payload: value })
  }

  if (didGetCss) {
    return (
      <ThemeCssEditor css={themeCss} onCssChange={onCssChange} />
    )
  }
  return (
    <FullHeightSpinner />
  )
}