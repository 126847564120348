import type { SubAccountFormData } from "../../../../interfaces/adminInterfaces/adminInterfaces"
import { fetchPostJson as createSubAccount } from "../../../../services/services"

export const createSubAccountSubmitHandler = (formData: SubAccountFormData, token: string, dispatch: TODO,) => {
  const body = {
    name: formData.subAccountName,
    description: formData.subAccountDescription
  }
  createSubAccount("sub-accounts", token, body)
    .then((res: TODO) => {
      if (res && (res.message || res.error)) {
        dispatch({ type: 'UPDATE_SUB_ACCOUNTS_ERROR_MESSAGE', payload: res.message ? res.message : res.error })
      } else {
        dispatch({ type: 'UPDATE_SUB_ACCOUNTS_ERROR_MESSAGE', payload: null })
        dispatch({ type: 'UPDATE_SUB_ACCOUNTS', payload: true })
        dispatch({ type: 'UPDATE_SHOW_SUB_ACCOUNTS_PROVIDERS_MODAL', payload: { subAccount: null, show: false } })
      }
    })
}