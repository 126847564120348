import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Input } from '@progress/kendo-react-inputs';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';

import { Icon } from '../../../shared/Icon/Icon';
import { ErrorMessage } from '../../../shared/ErrorMessage/ErrorMessage';
import ErrorModal from '../SurveyPublishTabContent/ErrorModal/ErrorModal';
import { FullHeightSpinner } from '../../../shared/FullHeightSpinner/FullHeightSpinner';
import { InProgressOverlay } from '../../../shared/InProgressOverlay/InProgressOverlay';
import { returnBrowser } from '../../../Analysis/Analyze/components/shared/helpers/returnBrowser/returnBrowser';
import { returnValidationErrors, returnSelectedElementData, returnSurveyDesignData } from '../SurveyDesignTabContent/helpers/returnDataHelpers/returnDataHelpers';
import { fetchPostJson as getInternalLink, fetchPostJson as getExternalLink, fetchGetJson as getExternalLinkInfo } from '../../../../services/services'

const deviceItems = [{ text: 'Desktop', id: 0, icon: 'device-desktop' }, { text: 'Mobile', id: 2, icon: 'device-mobile' }]

export const SurveyPreviewTabContent = ({ token, onFixValidationErrors }) => {
  const params = useParams();
  const { theData } = useSelector(theState => (theState.surveyInitialDataReducer));
  const [previewType, setPreviewType] = useState(0)
  const [updateLink, setUpdateLink] = useState(false)
  const [errorModal, setErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [loading, setLoading] = useState(true)
  const [previewUrl, setPreviewUrl] = useState(null)
  const [externalUrl, setExternalUrl] = useState(null)
  const [lastModified, setLastModified] = useState(null)
  const [validationErrors, setValidationErrors] = useState([])

  const dispatch = useDispatch();

  useEffect(() => {
    if (previewUrl === null && lastModified === null) {
      getInternalLink(`su/projects/${params.name}/surveys/${params.survey}/test/internal`, token)
        .then(res => {
          if (res?.error) {
            setErrorMessage(res.message ? res.message : res.error)
          } else {
            if (res) {
              if (res.errors) {
                setPreviewUrl('')
                const errors = returnValidationErrors(res)
                setErrorModal(true)
                setValidationErrors(res.errors)
                dispatch({
                  type: 'UPDATE_SURVEY_VALIDATION_ERRORS',
                  payload: {
                    errors: errors,
                    originalItem: theData.originalSelectedItem ? theData.originalSelectedItem : null,
                    selectedItem: theData.selectedItem ? returnSelectedElementData(theData.selectedItem, theData.data.find(el => el.index === theData.selectedItem.section).elements.find(el => el.selected === true), errors, theData.data) : null,
                    data: returnSurveyDesignData(theData.originalData, theData.selectedItem ? theData.selectedItem.id : null, errors)
                  }
                })
                dispatch({ type: 'TOGGLE_DISABLED_QUESTIONS', payload: false });
              } else {
                setPreviewUrl(res.url)
              }
            }
          }
        })
      getExternalLinkInfo(`su/projects/${params.name}/surveys/${params.survey}/external-test`, token)
        .then(res => {
          if (res?.url) {
            setLastModified(`${res.lastModifiedUtc.slice(0, 10)} ${res.lastModifiedUtc.slice(11, 19)}`)
            setExternalUrl(res.url)
          } else {
            setLastModified(null)
          }
        })
    }
  }, [previewUrl, lastModified, params, theData, token, dispatch])

  const onDeviceItemClick = (e) => {
    setPreviewType(e.item.id)
    setLoading(true)
  }

  const copyLink = () => {
    const copyText = document.getElementById("externalLinkInput")
    copyText.select()
    copyText.setSelectionRange(0, 99999)
    document.execCommand("copy")
    dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "Link has been copied to clipboard" } })
  }

  const onCreateExternalLink = () => {
    getExternalLink(`su/projects/${params.name}/surveys/${params.survey}/test/external`, token)
      .then(res => {
        if (res?.error) {
          setErrorMessage(res.message ? res.message : res.error)
        } else {
          if (res?.url) {
            setExternalUrl(res.url)
            getExternalLinkInfo(`su/projects/${params.name}/surveys/${params.survey}/external-test`, token)
              .then(res => {
                if (res?.error) {
                  setErrorMessage(res.message ? res.message : res.error)
                } else if (res?.url) {
                  setLastModified(`${res.lastModifiedUtc.slice(0, 10)} ${res.lastModifiedUtc.slice(11, 19)}`)
                  setUpdateLink(false)
                  dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Link has been updated to latest version' } })
                }
              })
          }
        }
      })
  }

  return (
    <div className="flex-grow-1 d-flex flex-column">
      {errorModal &&
        <ErrorModal type="preview" handleClose={() => setErrorModal(false)} validationErrors={validationErrors} onFixValidationErrors={onFixValidationErrors} />
      }
      {
        errorMessage &&
        <ErrorMessage
          type="modal"
          errorMessage={errorMessage}
          onHide={() => setErrorMessage(null)} />
      }
      <div className="container-fluid h-100 p-0">{
        previewUrl === null ?
          <FullHeightSpinner /> :
          <div className="row no-gutters flex-grow-1 h-100 w-100 overflow-hidden survey-main">
            <div className="d-flex flex-column h-100 w-100 p-0">
              <div className="card-header d-flex justify-content-between px-3 align-items-center h-56 bg-white rounded-0">
                <div className="d-flex align-items-center">
                  <div className="input-group mr-2 preview-link">
                    <Input value={externalUrl !== null ? externalUrl : 'Please create link'}
                      className="form-control h-32"
                      readOnly
                      id="externalLinkInput">
                    </Input>
                    <span>This link can be shared for testing. By clicking
                      {lastModified !== null ? " 'Update link'" : " 'Create link'"}, the latest version of the survey becomes available.
                      {lastModified !== null && <div className='mt-2'> Link last updated: {lastModified}</div>}
                    </span>
                    <div className="input-group-append">
                      <button type='button' className="btn btn-primary h-32" disabled={externalUrl === null} onClick={() => copyLink()}>Copy link</button>
                    </div>
                  </div>
                  <div className="d-flex flex-row">
                    <button
                      type='button'
                      className="btn btn-secondary mr-3"
                      disabled={previewUrl === ""}
                      onClick={() => { setUpdateLink(true); onCreateExternalLink() }}>
                      {externalUrl !== null ? "Update link" : "Create link"}
                    </button>
                    {
                      <div className='d-flex align-items-center'>
                        <div
                          style={{ visibility: updateLink ? 'visible' : 'hidden' }}
                          className="spinner-border spinner-border-sm text-disabled" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    }
                  </div>
                </div>

                <div className="select-device">
                  {
                    deviceItems.map((item, key) => (
                      <Tooltip key={key} anchorElement="target" position="top">
                        <button
                          key={key}
                          title={item.text}
                          type="button"
                          onClick={() => onDeviceItemClick({ item: item })}
                          className={`btn select-device-item ${previewType === item.id ? 'active' : ''}`}><Icon type={item.icon} className='pe-none' /></button>
                      </Tooltip>
                    ))
                  }
                </div>
              </div>
              {previewUrl !== '' &&
                <div className="h-100 w-100 d-flex justify-content-center p-4" style={{ backgroundColor: "rgb(243, 244, 244)" }}>
                  {returnBrowser() === 'Safari' &&
                    <div className='card-body d-flex flex-column h-100 bg-white align-items-center justify-content-center answer-layout p-0'>
                      <div className="d-flex align-items-center alert alert-warning w-100 preview-warning shadow alert-dismissible fade show" >
                        <div className="w-100">
                          <p className="m-0">Preview can't load correctly due to an issue with browser cookies. Try enabeling cookies in your browser and cross-site tracking for better experience or <a className="text-primary" href={previewUrl} target="_blank" rel="noopener noreferrer"> open preview in a new tab</a>.</p>
                        </div>
                        <button type="button" data-dismiss="alert" aria-label="Close" className="btn btn-icon btn-transparent shadow-none px-1">
                          <span aria-hidden="true"><Icon type="close" /></span>
                        </button>
                      </div>
                    </div>}
                  {previewType === 0 &&
                    <div className="card-body d-flex flex-column h-100 bg-white align-items-center justify-content-center answer-layout p-0">
                      <div className="w-100 h-100 bg-preview">
                        <iframe onLoad={() => setLoading(false)} title="Survey Preview" src={previewUrl} width={"100%"}
                          height={"100%"} className={`${loading ? 'd-none' : null} border-0`} />
                        {loading &&
                          <InProgressOverlay theme="primary" type="fullscreen" />}
                      </div>
                    </div>
                  }
                  {previewType === 2 &&
                    <div className="d-flex flex-column h-100 bg-white align-items-center justify-content-center answer-layout p-0">
                      <div className="d-flex align-items-center h-100 themes-mobile-preview p-0">
                        <div className="device mobile survey-element-preview card h-100 bg-preview">
                          <iframe onLoad={() => setLoading(false)} title="Survey Preview" src={`${previewUrl}&rs_m=1`}
                            width={"100%"} height={"100%"} className={`${loading ? 'd-none' : null} border-0`} />
                          {loading &&
                            <InProgressOverlay theme="primary" type="fullscreen" />}
                        </div>
                      </div>
                    </div>
                  }
                </div>}
            </div>
          </div>}
      </div>
    </div>
  )
}