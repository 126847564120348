export default function returnUpdatedActionButtons(data, updatedActionButtons, checkedQuestions, multiChoice) {
  const multiChoiceButton = updatedActionButtons.find(el => el.id === 'multiChoice')
  const multiGridButton = updatedActionButtons.find(el => el.id === 'multiGrid')
  const numericListButton = updatedActionButtons.find(el => el.id === 'numericList')
  const openListButton = updatedActionButtons.find(el => el.id === 'openList')
  const enableDisableButton = updatedActionButtons.find(el => el.id === 'enable/disable')
  const undoButton = updatedActionButtons.find(el => el.id === 'undo')
  const undoAutoMerged = updatedActionButtons.find(el => el.id === 'undoAutoMerged')
  const weightButton = updatedActionButtons.find(el => el.id === 'weight')
  const idButton = updatedActionButtons.find(el => el.id === 'id')

  if (checkedQuestions.length > 1) {  // --- Logic for MULTIPLE questions selected ---
    weightButton.disabled = true
    idButton.disabled = true
    if (checkedQuestions.some(el => el.disabled)) {
      multiChoiceButton.disabled = true;
      multiGridButton.disabled = true;
      numericListButton.disabled = true;
    } else {
      if (checkedQuestions.every(el => el.type === 'character')) {
        openListButton.disabled = false
      } else {
        openListButton.disabled = true
      }
      if (checkedQuestions.some(ques => (ques.multigrid || ques.multilist || ques.nmopen) && !ques.mergedQuestions)) { // This is logic to check if question is auto-merged
        undoAutoMerged.disabled = false
      } else {
        undoAutoMerged.disabled = true
      }
      if (checkedQuestions.some(ques => ques.merged)) {
        undoButton.disabled = true // Change this to false after we implement the logic to deconstruct multiple merged questions
      } else {
        undoButton.disabled = true
      }
      if (checkedQuestions.filter(el => el.variable).length === checkedQuestions.length) {
        let numberOfIdenticalVariables = 0
        checkedQuestions.forEach(el => {
          el.variable.forEach((vrbl, index) => {
            if (checkedQuestions[0].variable[index] && checkedQuestions[0].variable[index].values && JSON.stringify(vrbl.values.value) === JSON.stringify(checkedQuestions[0].variable[index].values.value)) {
              numberOfIdenticalVariables++;
            }
          })
        })
        if (numberOfIdenticalVariables / checkedQuestions[0].variable.length === checkedQuestions.length) {
          multiGridButton.disabled = false;
        }
      } else {
        if (checkedQuestions.filter(el => !el.values?.value).length === checkedQuestions.length && checkedQuestions.every(el => el.type === "quantity")) {
          multiChoiceButton.disabled = true;
          multiGridButton.disabled = true;
          numericListButton.disabled = false;
        }
        else if (checkedQuestions.some(el => !el.values?.value)) {
          multiChoiceButton.disabled = true;
          multiGridButton.disabled = true;
          numericListButton.disabled = true;
        } else if (checkedQuestions.some(el => el.values?.value.length === 1)) {
          multiChoiceButton.disabled = false;
          multiGridButton.disabled = true;
          numericListButton.disabled = true;
        } else {
          const [{ values: firstValues }, ...rest] = checkedQuestions;
          const areAllValueTextsEqual = rest.every(({ values }) =>
            values.value.length === firstValues.value.length &&
            values.value.every(({ text }, index) => text === firstValues.value[index].text)
          );
          if (areAllValueTextsEqual) {
            multiChoiceButton.disabled = false;
            multiGridButton.disabled = false;
            numericListButton.disabled = true;
          } else {
            multiChoiceButton.disabled = false;
            multiGridButton.disabled = true;
            numericListButton.disabled = true;
          }
          multiChoice = true;
        }
      }
    }
  } else if (checkedQuestions.length >= 1) {  // --- Logic for a single question selected ---
    enableDisableButton.disabled = false
    openListButton.disabled = true
    if (checkedQuestions.some(el => el.disabled)) {
      multiChoiceButton.disabled = true;
      multiGridButton.disabled = true;
      numericListButton.disabled = true;
    } else {
      if (checkedQuestions.length === 1) {
        if ((checkedQuestions[0].multigrid || checkedQuestions[0].multilist || checkedQuestions[0].nmopen) && !checkedQuestions[0].mergedQuestions) {
          undoAutoMerged.disabled = false
        } else {
          undoAutoMerged.disabled = true
        }
        if (checkedQuestions[0].merged) {
          undoButton.disabled = false
        } else {
          undoButton.disabled = true
        }
        if (checkedQuestions[0].type !== 'quantity') {
          weightButton.disabled = true
        } else {
          weightButton.disabled = false
        }
        if (checkedQuestions[0].type === 'character') {
          const matchedElement = data.find(el => el['@miextsss:isrspid'])
          if (matchedElement) {
            if (matchedElement.id === checkedQuestions[0].id) {
              idButton.disabled = false
            } else {
              idButton.disabled = true
            }
          } else {
            idButton.disabled = false
          }
        } else {
          idButton.disabled = true
        }
      }
      if (checkedQuestions.some(el => !el.values?.value)) {
        multiChoiceButton.disabled = true;
        multiGridButton.disabled = true;
        numericListButton.disabled = true;
      } else if (checkedQuestions.some(el => el.merged)) {
        multiChoiceButton.disabled = true;
        multiGridButton.disabled = true;
        numericListButton.disabled = true;
      } else {
        if (multiChoice) {
          multiChoiceButton.disabled = false;
          multiGridButton.disabled = false;
          numericListButton.disabled = true;
        } else if (checkedQuestions.some(el => el.values?.value && el.values?.value.length === 1)) {
          multiChoiceButton.disabled = true;
          multiGridButton.disabled = true;
          numericListButton.disabled = true;
        } else {
          multiChoiceButton.disabled = true;
          multiGridButton.disabled = false;
          numericListButton.disabled = true;
        }
      }
    }
  } else {  // --- Logic no questions selected ---
    multiChoiceButton.disabled = true;
    multiGridButton.disabled = true;
    numericListButton.disabled = true;
    openListButton.disabled = true;
    enableDisableButton.disabled = true;
    undoButton.disabled = true;
    undoAutoMerged.disabled = true;
    weightButton.disabled = true;
    idButton.disabled = true;
  }

  return updatedActionButtons
}