export default (data, originalData, checkedQuestions, initialData) => {
  const updatedData = [...data]
  const firstElIndex = updatedData.findIndex(el => el.id === checkedQuestions[0].id)
  const firstInitialElIndex = initialData.findIndex(el => el.id === checkedQuestions[0].id)
  let initialMergedQuestions = []
  updatedData.splice(firstElIndex, 1)
  initialData.splice(firstInitialElIndex, 1)

  originalData.forEach(el => {
    for (let i = 0; i < checkedQuestions[0].mergedQuestions.length; i++) {
      const val = checkedQuestions[0].mergedQuestions[i];
      if (el.id === val.id) {
        initialMergedQuestions.push(el);
        break;
      } else if (val.id.includes(el.id)) {
        if (el.variable) {
          el.variable.forEach(element => {
            if (element["@miextsss:qno"] === val.id) {
              initialMergedQuestions.push(val)
            }
          })
        }
      }
    }
  })

  initialMergedQuestions.forEach(el => {
    updatedData.splice(el.order, 0, el)
  })
  initialMergedQuestions.forEach(el => {
    initialData.splice(el.order, 0, el)
  })

  return {
    data: updatedData,
    initialData: initialData
  }
}

export const undoAutoMergedQuestions = (data, originalFormatData, checkedQuestions) => {
  if (checkedQuestions.length > 0) {
    const updatedQuesList = [...data]
    const updatedOriginalFormatData = JSON.parse(JSON.stringify(originalFormatData))

    const deconstructQuestion = (chkQues, quesType) => {
      if (chkQues.variable) {
        // Deconstruct and replace in fronend questions list (props.data)
        chkQues.variable.sort((a, b) => b.position["@start"] - a.position["@start"]) // Reorder variables so they appear in correct order on frontend
        chkQues.variable.forEach(singleVar => {
          const updatedValues = singleVar.values.value.map(el => { return { ...el, text: el["miextsss:otxt"] ? el["miextsss:otxt"] : el.text } })
          if (chkQues.type === "dichotomyb") {
            updatedValues.forEach(singleVal => { singleVal["@miextsss:excluded"] = false; singleVal["@miextsss:code"] = singleVal["@code"] })
          }

          const deconstructedQues = {
            "@ident": singleVar["@ident"],
            id: singleVar.name,
            index: singleVar.position["@start"] - 1,
            order: singleVar.position["@start"] - 1,
            position: singleVar.position,
            type: singleVar["@type"],
            values: { value: [...updatedValues] },
            title: singleVar.label.text,
            undoAutoMerged: true,
            oldParentOrder: chkQues.order
          };

          // Add the deconstructed question to the frontend questions list (props.data)
          updatedQuesList.splice(data.findIndex(el => el.id === chkQues.id), 0, deconstructedQues)
        })

        // Deconstruct and replace in backend questions list (props.originalFormatData)
        const quesBackendEquivalent = updatedOriginalFormatData.sss.survey.record[`${quesType}`].find(el => el["@miextsss:qno"] === chkQues.id)
        quesBackendEquivalent.variable.forEach(item => {
          const deconstructedQues = {
            ...item,
            "@miextsss:docorder": (item.position["@start"] - 1).toString()
          }
          delete deconstructedQues["@miextsss:qno"]
          delete deconstructedQues["@miextsss:excluded"]
          if (chkQues.type !== "dichotomyb") {
            deconstructedQues.values.value.forEach(el => { delete el["@miextsss:code"]; delete el["@miextsss:excluded"] })
          }
          // Add the deconstructed question to the backend questions list (props.originalFormatData)
          updatedOriginalFormatData.sss.survey.record.variable.splice(deconstructedQues.position["@start"], 0, deconstructedQues)
        })

      } else if (chkQues["miextsss:multilist"]) {
        // Deconstruct and replace in fronend questions list (props.data)
        chkQues["miextsss:multilist"].reverse(); // Reorder grids so they appear in correct order on frontend
        chkQues["miextsss:multilist"].forEach(multilist => {
          multilist.variable.sort((a, b) => b.position["@start"] - a.position["@start"]) // Reorder variables so they appear in correct order on frontend
          multilist.variable.forEach(singleVar => {
            const updatedValues = singleVar.values.value
            if (chkQues.type === "dichotomyb") {
              updatedValues.forEach(singleVal => { singleVal["@miextsss:excluded"] = false; singleVal["@miextsss:code"] = singleVal["@code"] })
            }
            const deconstructedQues = {
              "@ident": singleVar["@ident"],
              checked: false,
              selected: false,
              id: singleVar.name,
              index: singleVar.position["@start"] - 1,
              order: singleVar.position["@start"] - 1,
              position: singleVar.position,
              type: singleVar["@type"],
              values: { value: [...updatedValues] },
              title: singleVar.label.text,
              undoAutoMerged: true,
            };

            // Add the deconstructed question to the frontend questions list (props.data)
            updatedQuesList.splice(data.findIndex(el => el.id === chkQues.id), 0, deconstructedQues)
          })
        })

        // Deconstruct and replace in backend questions list (props.originalFormatData)
        const quesBackendEquivalent = updatedOriginalFormatData.sss.survey.record[`${quesType}`].find(el => el["@miextsss:qno"] === chkQues.id)

        quesBackendEquivalent["miextsss:multilist"].forEach(mListQues => {
          mListQues.variable.forEach(item => {
            const deconstructedQues = {
              ...item,
              "@miextsss:docorder": (item.position["@start"] - 1).toString()
            }
            delete deconstructedQues["@miextsss:qno"]
            delete deconstructedQues["@miextsss:excluded"]
            if (chkQues.type !== "dichotomyb") {
              deconstructedQues.values.value.forEach(el => { delete el["@miextsss:code"]; delete el["@miextsss:excluded"] })
            }
            // Add the deconstructed question to the backend questions list (props.originalFormatData)
            updatedOriginalFormatData.sss.survey.record.variable.splice(deconstructedQues.position["@start"], 0, deconstructedQues)
          })
        })
      }
    }

    const removeQuestion = (chkQues, quesType) => {
      // Remove the original auto-merged question from frontend questions list (props.data)
      let quesFrontendIndex = null
      if (quesType === "miextsss:grid") {
        // A case like this might be needed for other question types as well
        quesFrontendIndex = updatedQuesList.findIndex(el => el.id === chkQues.id && el.multigrid === true)
      } else {
        quesFrontendIndex = updatedQuesList.findIndex(el => el.id === chkQues.id)
      }
      if (Number.isInteger(quesFrontendIndex)) {
        updatedQuesList.splice(quesFrontendIndex, 1)
      }
      // Remove the original auto-merged question from backend questions list (props.originalFormatData)
      const quesBackendIndex = updatedOriginalFormatData.sss.survey.record[`${quesType}`].findIndex(el => el["@miextsss:qno"] === chkQues.id)
      updatedOriginalFormatData.sss.survey.record[`${quesType}`].splice(quesBackendIndex, 1)
    }

    checkedQuestions.forEach(chkQues => {
      if (chkQues.multigrid === true && !chkQues.mergedQuestions) {
        deconstructQuestion(chkQues, "miextsss:grid")
        removeQuestion(chkQues, "miextsss:grid")

      } else if (chkQues.multilist === true && !chkQues.mergedQuestions) {
        deconstructQuestion(chkQues, "miextsss:multilist")
        removeQuestion(chkQues, "miextsss:multilist")

      } else if (chkQues.nmopen === true && !chkQues.mergedQuestions) {

        // WARNING: Not yet implemented, need a dataset with nmopen questions to implement.

      } else if (chkQues.numericlist === true && !chkQues.mergedQuestions) {

        // WARNING: Not yet implemented, need a dataset with numericlist questions to implement.

      }
    })

    return { data: updatedQuesList, originalFormatData: updatedOriginalFormatData }

  } else {
    return { data: data, originalFormatData: originalFormatData }
  }
}