import { useState, useEffect, useCallback, FormEvent, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Input } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { BaseDropDownList, BaseMultiSelect } from '../../../shared/Inputs';
import {
  fetchGetJson as getLegasyDatabases,
  fetchGetJson as getLegasyDatabaseProjects,
  fetchGetJson as getLegasyProjectDatasets,
  fetchPostJson as postDatasetImport,
} from '../../../../services/services';

type SelectedOptions = {
  database: string | null;
  project: { projectId: string; name: string } | null;
  qifDocs: { qifID: string; name: string; description: string | null; xmlDocumentId: string; isaId: string }[];
}

type Project = {
  name: string;
  projectId: string;
  description: string;
}

type Dataset = {
  name: string
  qifID: string
  isaId: string
  legacyName: string
  xmlDocumentId: string
  description: string | null
}
interface Props {
  handleClose: () => void;
  projectDocuments?: { name: string }[];
  token: string;
  projectId: string;
}

export const ImportLegasyProjectsModal = ({ handleClose, projectDocuments, token, projectId }: Props) => {
  const dispatch = useDispatch();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [databases, setDatabases] = useState<string[]>([]);
  const [loadingDatabases, setLoadingDatabases] = useState(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [datasets, setDatasets] = useState<Dataset[]>([]);
  const [loadingDatasets, setLoadingDatasets] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>({ database: null, project: null, qifDocs: [] });

  const onSubmitHandler = async (e: FormEvent) => {
    e.preventDefault();
    setDisableSubmit(true);
    try {
      const datesetImportRes = await postDatasetImport(
        'dm/import/dataset',
        token,
        selectedOptions.qifDocs.map((qifDoc) => ({
          qifDocId: qifDoc.qifID,
          name: qifDoc.name.trim(),
          description: qifDoc.description ? qifDoc.description.trim() : '',
          xmlDocumentId: qifDoc.xmlDocumentId,
          isaDocumentId: qifDoc.isaId,
          databaseName: selectedOptions.database,
          wpProjectId: projectId
        }))
      );
      if (datesetImportRes && datesetImportRes.status === 400) {
        dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The dataset with same name exist!' } });
      } else {
        datesetImportRes.queuedDatasets.length &&
          dispatch({
            type: 'SHOW_ACTION_NOTIFICATION',
            payload: { msg: `The datasets: ${datesetImportRes.queuedDatasets.map((val: { name: string }) => val.name)} have been queued for copy.` },
          });
        datesetImportRes.failedDatasets.length &&
          dispatch({
            type: 'SHOW_ACTION_NOTIFICATION',
            payload: { msg: `The datasets: ${datesetImportRes.failedDatasets.map((val: { name: string }) => val.name)} have failed to be queued for copy.` },
          });
        handleClose();
      }
    } finally {
      setDisableSubmit(false);
    }
  };

  useEffect(() => {
    async function fetchDatabases() {
      try {
        setLoadingDatabases(true);
        const databases = await getLegasyDatabases('dm/databases', token);
        if (databases && !databases.error && !databases.message) {
          setDatabases(databases);
        }
      } finally {
        setLoadingDatabases(false);
      }
    }
    token && fetchDatabases();
  }, [token]);

  const onDatasetsChangeHandler = useCallback((index: number, e: TODO) => {
    const { name, value } = e.target;
    setSelectedOptions((prevData) => ({
      ...prevData,
      qifDocs: prevData.qifDocs.map((qifDoc, idx) => (idx === index ? { ...qifDoc, [name]: value } : qifDoc)),
    }));
  }, []);

  const onDatasetChangeHandler = async (e: TODO) => {
    const { name, value } = e.target;
    if (name === 'database') {
      setSelectedOptions((prevData) => ({ ...prevData, [name]: value, project: null, qifDocs: [] }));
    } else if (name === 'project') {
      setSelectedOptions((prevData) => ({ ...prevData, [name]: value, qifDocs: [] }));
    } else if (name === 'qifDocs') {
      setSelectedOptions((prevData) => ({ ...prevData, [name]: value.filter((val: { qifID: string }) => val.qifID) }));
    } else {
      setSelectedOptions((prevData) => ({ ...prevData, [name]: value }));
    }
    if (name === 'database' && value) {
      setProjects([]);
      setDatasets([]);
      try {
        setLoadingProjects(true);
        const projects = await getLegasyDatabaseProjects(`dm/${value}/projects`, token);
        if (projects && !projects.error) {
          setProjects(projects);
        }
      } finally {
        setLoadingProjects(false);
      }
    }
    if (name === 'project' && value) {
      setDatasets([]);
      try {
        setLoadingDatasets(true);
        const datasets = await getLegasyProjectDatasets(`dm/${selectedOptions.database}/projects/${value.projectId}/qifDocs`, token);
        if (datasets && !datasets.error) {
          setDatasets(datasets.map((dataset: { name: string }) => ({ ...dataset, legacyName: dataset.name })));
        }
      } finally {
        setLoadingDatasets(false);
      }
    }
  };

  return (
    <Dialog width={800} onClose={handleClose} title={'Import from legacy platform'} className="legacy-import-modal d-flex flex-column">
      <form onSubmit={onSubmitHandler}>
        <fieldset className="p-4">
          <div className="d-flex flex-column gap-lg">
            <div>
              <BaseDropDownList
                data={databases}
                name={'database'}
                value={selectedOptions.database}
                onChange={onDatasetChangeHandler}
                style={{ width: '100%' }}
                className="w-100 bg-white border border-secondary"
                defaultItem={'Select a database...'}
                loading={loadingDatabases}
                validityStyles={false}
                required
              />
              {selectedOptions.database && !loadingProjects && !projects.length && (
                <Error className={'ml-1'}>Selected database does not contain any projects!</Error>
              )}
            </div>
            <div>
              <BaseDropDownList
                data={projects}
                name={'project'}
                textField={'name'}
                dataItemKey={'projectId'}
                onChange={onDatasetChangeHandler}
                style={{ width: '100%' }}
                className="w-100 bg-white border border-secondary"
                defaultItem={{ name: 'Select a project...' }}
                disabled={!selectedOptions.database}
                loading={loadingProjects}
                value={selectedOptions.project}
                validityStyles={false}
                required
              />
              {selectedOptions.project && !loadingDatasets && !datasets.length && (
                <Error className={'ml-1'}>Selected project does not contain any datasets!</Error>
              )}
            </div>
            <div>
              <BaseMultiSelect
                data={datasets}
                name={'qifDocs'}
                textField={'legacyName'}
                dataItemKey={'qifID'}
                onChange={onDatasetChangeHandler}
                style={{ width: '100%' }}
                className="w-100 bg-white border border-secondary"
                placeholder={'Select datasets...'}
                value={selectedOptions.qifDocs}
                disabled={!selectedOptions.project}
                loading={loadingDatasets}
                validityStyles={false}
                required
              />
            </div>
          </div>
        </fieldset>

        {
          selectedOptions.qifDocs.length > 0 &&
          <Fragment>
            <p className='text-muted mx-4 mb-2'>Selected datasets:</p>
            <div style={{ maxHeight: '300px', overflowY: 'scroll' }} className="d-flex flex-column gap-md mx-4 mb-4">
              {
                selectedOptions.qifDocs.map((qifDoc: { name: string, description: string | null }, index) => (
                  <div key={index} style={{ borderRadius: '0.75rem' }} className="border border-secondary shadow-sm bg-lightest p-2">
                    <div className="d-flex flex-column gap-lg">
                      <div className="d-flex align-items-center gap-md">
                        <div style={{ width: '40%' }} className="d-flex flex-column">
                          <Input
                            onChange={(e) => onDatasetsChangeHandler(index, e)}
                            value={qifDoc.name || ''}
                            className="w-100"
                            placeholder='Name'
                            name={'name'}
                            disabled={!selectedOptions.qifDocs.length}
                            validityStyles={false}
                            required
                            valid={
                              !!(qifDoc.name && !projectDocuments?.some((document) => document?.name.toLowerCase().trim() === qifDoc.name.toLowerCase().trim()))
                            }
                            validationMessage={'Please enter a valid value'}
                          />
                          {!!(
                            qifDoc.name && projectDocuments?.some((document) => document?.name.toLowerCase().trim() === qifDoc.name.toLowerCase().trim())
                          ) && <Error className={'ml-2'}>There is existing document with same name</Error>}
                        </div>
                        <div style={{ width: '60%' }} className="d-flex flex-column">
                          <Input
                            name='description'
                            onChange={(e) => onDatasetsChangeHandler(index, e)}
                            value={qifDoc.description || ''}
                            className="w-100"
                            placeholder={'Description'}
                            disabled={!selectedOptions.qifDocs.length}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </Fragment>
        }

        <DialogActionsBar>
          <button type="button" className="k-button btn btn-secondary" onClick={handleClose}>
            Cancel
          </button>
          <button type="submit" disabled={disableSubmit} className="k-button btn btn-primary">
            Import
          </button>
        </DialogActionsBar>
      </form>
    </Dialog>
  );
};