import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DashboardCard } from '../../DashboardCard/DashboardCard';
import type { RootState } from '../../../../store/reducers/rootReducer';
import type { DashboardCards } from '../../../../interfaces/backofficeInterfaces/backofficeInterfaces';
import { fetchGetJson } from '../../../../services/services';

export const Dashboard = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [dashboardData, setDashboardData] = useState<DashboardCards[]>([]);

  useEffect(() => {
    fetchGetJson("sta/statistics/backoffice/platformusage", token)
      .then((res: TODO) => {
        if (res && !res.error && !res.message) {
          setDashboardData(res)
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
        }
      })
  }, [dispatch, token])

  return (
    <div className="card-container px-5 pb-5">
      {
        dashboardData.map((card: DashboardCards) =>
          <DashboardCard
            key={card.name}
            totalCount={card.totalCount} dif={card.dif}
            name={card.name === "Total Customers" ? "Total Organisations" : card.name}
          />
        )
      }
    </div>
  )
}