import { type FormEvent, useState, Fragment } from 'react';
import auth0 from 'auth0-js';

import Logo from '../../assets/img/walr_black.png';
import { ErrorMessage } from '../shared/ErrorMessage/ErrorMessage';
import { apiUrl } from '../../services/services';

interface FormData {
  email: string
  code: string
}

export const SubAccountLogin = () => {
  const [loginStep, setLoginStep] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [formData, setFormData] = useState<FormData>({ email: '', code: '' })

  const onChangeHandler = (e: TODO) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const webAuth = new auth0.WebAuth({
    domain: import.meta.env.VITE_APP_AUTH0_DOMAIN,
    clientID: import.meta.env.VITE_APP_AUTH0_CLIENT_ID,
    responseType: 'token',
    redirectUri: window.location.origin,
    audience: apiUrl
  });

  const onSubmitHandler = (e: FormEvent) => {
    e.preventDefault()
    setIsLoading(true)
    if (loginStep === 1) {
      webAuth.passwordlessStart({
        connection: 'email',
        send: 'code',
        email: formData.email
      }, (err: TODO) => {
        setIsLoading(false)
        if (err) {
          if (err.description === "Public signup is disabled") {
            setErrorMessage("The email you entered is not recognized")
          } else {
            setErrorMessage(err.description)
          }
        } else {
          setLoginStep(2)
          setErrorMessage("")
        }
      });
    } else {
      webAuth.passwordlessLogin({
        connection: 'email',
        email: formData.email,
        verificationCode: formData.code
      }, (err: TODO, _res: TODO) => {
        setIsLoading(false)
        if (err) {
          setErrorMessage(err.description)
        }
      });
    }
  }

  return (
    <div className="d-flex flex-column vh-100 w-100">
      <div className="container flex-grow-1">
        <div className="row justify-content-center h-100">
          <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
            <div className="login-card border shadow-lg w-100">
              <form className='d-flex flex-column gap-xl' onSubmit={onSubmitHandler}>
                <div className="d-flex justify-content-center">
                  <img className='walr-logo' style={{ height: '3rem', width: 'auto' }} src={Logo} alt="" />
                </div>
                <h4 className='text-center font-weight-bold m-0'>Log in</h4>
                {
                  loginStep === 1 ?
                    <input
                      type="email"
                      name='email'
                      autoFocus={true}
                      required={true}
                      placeholder='Email address'
                      value={formData.email}
                      className='form-control'
                      onChange={onChangeHandler}
                    />
                    :
                    <Fragment>
                      <p className="m-0 text-muted text-center">You should recieve an email shortly with a verification code. <br />Please check your spam folder if you have not received it within a few minutes.</p>
                      <input
                        type="text"
                        name='code'
                        required={true}
                        placeholder='Login code'
                        value={formData.code}
                        className='form-control'
                        onChange={onChangeHandler}
                      />
                    </Fragment>
                }
                {
                  errorMessage &&
                  <ErrorMessage
                    type="alert"
                    errorMessage={errorMessage}
                    onHide={() => setErrorMessage('')}
                  />
                }
                <button
                  type="submit"
                  style={{ maxHeight: 'unset', height: '100%', fontSize: 'unset', fontWeight: 'normal' }}
                  disabled={isLoading}
                  className="btn btn-dark">
                  {
                    isLoading ?
                      <div className="spinner-border spinner-border-sm text-light" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      :
                      <span>{loginStep === 1 ? 'Continue' : 'Log in'}</span>
                  }
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}