const initialState = {
  toggleView: true,
  minimize: false,
  zoomLevels: {
    topLineZoom: 1,
    crossTableZoom: 1,
    chartZoom: 1,
    digZoom: 1,
    qTableZoom: 1,
    dataZoom: 1,
  },
  isLegacyCustomer: null,
  compactView: {
    compactDataset: false,
    compactRows: false,
    compactColumns: false,
  }
};

function userSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_IS_LEGACY_CUSTOMER":
      return {
        ...state,
        isLegacyCustomer: action.payload,
      };
    case "UPDATE_TOGGLE_VIEW":
      return {
        ...state,
        toggleView: action.payload,
      };
    case "UPDATE_USER_SETTINGS":
      return action.payload;
    case "UPDATE_TOGGLE_MINIMIZE":
      return {
        ...state,
        minimize: !state.minimize
      }
    case "UPDATE_ZOOM_LEVEL":
      return {
        ...state,
        zoomLevels: {
          ...state.zoomLevels,
          [action.payload.zoomType]: action.payload.value
        },
      };
      case "UPDATE_COMPACT_VIEW":
      return {
        ...state,
        compactView: {
          ...state.compactView,
          [action.payload.compactType]: action.payload.value
        },
      };
    default:
      return state;
  }
}

export default userSettingsReducer;
