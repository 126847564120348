import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

interface Props {
  toggleDialog: () => void;
  onConfirmCancel: () => void;
  onConfirmRemove: () => void;
  type: string;
}

export default function ConfirmCancelModal({ toggleDialog, onConfirmCancel, onConfirmRemove, type }: Props) {
  const handleClick = () => {
    if (type === "remove") {
      onConfirmRemove();
    }
    if (type === "cancel") {
      onConfirmCancel();
    }
  }

  const dialogText = `Are you sure you want to ${type === "remove" ? "remove this task from the list?" : "cancel this task?"}`;

  return (
    <Dialog title={"Please confirm"} onClose={toggleDialog}>
      <p style={{
        margin: "2rem",
        textAlign: "center"
      }}>{dialogText}</p>
      <DialogActionsBar>
        <button className="k-button k-button-solid-base" onClick={toggleDialog}>No</button>
        <button className="k-button bg-primary text-white" onClick={handleClick}>Yes</button>
      </DialogActionsBar>
    </Dialog >
  )
}
