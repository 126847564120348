import React, { useState } from 'react'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { BaseDropDownList } from '../../../../../shared/Inputs'
import { returnItemRender, returnValueRender } from '../../../../../Analysis/Analyze/components/shared/helpers/FilterBuilderHelpers/FilterBuilderHelpers'


export default ({ handleClose, data, onPipeText }) => {

  const [openQuestionList, setOpenQuestionList] = useState(false)
  const [selectedQuestion, setSelectedQuestion] = useState(data[0])

  const itemRender = (itemProps, itemData) => returnItemRender(itemProps, itemData, onClickQuestion);

  const valueRender = (props) => {
    return (
      returnValueRender(props, data)
    )
  }

  const onClickQuestion = (question, props) => {
    setSelectedQuestion(data[props.key])
    setOpenQuestionList(false)
  }

  return (
    <Dialog title="Pipe text" width="30%" height="30%" onClose={handleClose}>
      <div className="p-4 d-flex flex-column align-items-start">
        <span className="h6">Select question</span>
        {data !== null ?
          <BaseDropDownList
            onOpen={() => setOpenQuestionList(true)}
            onClose={() => setOpenQuestionList(false)}
            className="form-control h-100 filter-dropdown"
            popupSettings={{ className: 'survey-dropdown' }}
            data={data}
            textField="text"
            itemRender={itemRender}
            value={selectedQuestion}
            valueRender={valueRender}
            opened={openQuestionList}
          />
          : null}
      </div>
      <DialogActionsBar>
        <button className="k-button btn btn-secondary" onClick={handleClose}>Cancel</button>
        <button className="k-button btn btn-primary"
          onClick={() => onPipeText(selectedQuestion.index ? `{{\\${selectedQuestion.qno}.${selectedQuestion.index + 1}}}` :
            `{{\\${selectedQuestion.text}}}`)}>Save</button>
      </DialogActionsBar>
    </Dialog >
  )
}