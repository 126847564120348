export const analysisTypes = [
  {
    "id": "topline",
    "name": "Topline"
  },
  {
    "id": "crossTable",
    "name": "Cross Table"
  },
  {
    "id": "chart",
    "name": "Chart"
  },
  {
    "id": "dig",
    "name": "Dig"
  },
  {
    "id": "qTable",
    "name": "Qtable"
  },
  {
    "id": "data",
    "name": "Data"
  },
  {
    "id": "script",
    "name": "Script"
  },
  {
    "id": "report",
    "name": "Report"
  },
]

export const analysisActions = [
  {
    "id": "exportToWord",
    "name": "Export to Word"
  },
  {
    "id": "exportToExcel",
    "name": "Export to Excel"
  },
  {
    "id": "exportToPpt",
    "name": "Export to PPT"
  },
  {
    "id": "createWeightSet",
    "name": "Create Weight Set"
  },
  {
    "id": "createQuestions",
    "name": "Create Questions"
  }
]

export const projectActions = [
  {
    "id": "exportDataset",
    "name": "Export dataset"
  },
  {
    "id": "deleteDataset",
    "name": "Delete dataset"
  },
  {
    "id": "editDataset",
    "name": "Edit dataset"
  },
]