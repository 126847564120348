import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@progress/kendo-react-buttons';
import { ProgressBar } from '@progress/kendo-react-progressbars';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';

import ResponsesTable from './ResponsesTable/ResponsesTable';
import { BaseDropDownList } from "../../../../../../shared/Inputs";
import CombineFilterData from './CombineFilterData/CombineFilterData';
import returnEvaluateFilterBody from '../../../shared/helpers/returnEvaluateFilterBody/returnEvaluateFilterBody';
import { returnValueRender, returnItemRender, returnResponseSelectionChange, returnHeaderSelectionChange, checkResponseValues, returnSelectedQuestionData, returnQuestionsData, returnCombineDataExpression, returnCombineQuestionText, checkSelectedQuestionType, returnAdvancedOptions, returnAdvancedOptionExpression, visualAdvancedHandler, returnEvalFilterUrl } from '../../../shared/helpers/FilterBuilderHelpers/FilterBuilderHelpers';
import { fetchPostJson as evaluateFilter } from '../../../../../../../services/services';

const FilterBuilder = ({ handleClose, filterData, manualFilterExpression, userData, datasetId, onSaveFilterOptions, combineFilterData, option, token, type, analysisType, workflowProjectId, questionData, changeQuestion, errorMessage }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isLoadingEvalFilter, setIsLoadingEvalFilter] = useState(false);
  const [data, setData] = useState({
    questions: null,
    filterData: null,
    selectedQuestion: null,
    openQuestionList: false,
    selectedQuestionData: null,
    didSelectedResponseUpdate: false,
    responsesTotalNumber: 0,
    responsesTotalPercentage: 0,
    negateFilter: false,
    responseValue: null,
    responseInterval: null,
    advancedOptions: null
  })

  const [combineData, setCombineData] = useState({
    didUpdate: false,
    data: visualAdvancedHandler(combineFilterData, manualFilterExpression) === "visual" ? combineFilterData : [],
    expression: visualAdvancedHandler(combineFilterData, manualFilterExpression) === "visual" ? manualFilterExpression : "",
    totalNumber: 0,
    totalPercentage: 0,
    questionText: ''
  })

  const [selectionFilterExpression, setSelectionFilterExpression] = useState(null)
  const [advancedOption, setAdvancedOption] = useState(null)
  const [advancedOptionBetween, setAdvancedOptionBetween] = useState({ first: 1, second: 2, all: 0 })
  const [searchFilteredQuestions, setSearchFilteredQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState([])
  const [advancedEditData, setAdvancedEditData] = useState({ mode: visualAdvancedHandler(combineFilterData, manualFilterExpression), expression: visualAdvancedHandler(combineFilterData, manualFilterExpression) === "advanced" ? manualFilterExpression : "" })
  const [advancedEvaluateError, setAdvancedEvaluateError] = useState("");
  const [evaluateLoading, setEvaluateLoading] = useState(false);

  useEffect(() => {
    const data = filterData
    const questions = returnQuestionsData(data)
    const evaluationFilterUrl = returnEvalFilterUrl(type === "survey" ? "surveys" : analysisType, `${history.location.pathname.split('/')[2]}`, datasetId, workflowProjectId)

    if (type !== "survey") {
      if (advancedEditData.mode === "advanced") {
        // Evaluation and calculation on load for Advanced mode if there is an existing definition 
        setEvaluateLoading(true)
        const advancedExpression = { expression: advancedEditData.expression }

        evaluateFilter(evaluationFilterUrl, token, advancedExpression)
          .then(res => {
            if (res && (res.message || res.error)) {
              setAdvancedEvaluateError(res.message ? res.message : res.error)
              setEvaluateLoading(false)
            } else {
              setCombineData({ ...combineData, totalPercentage: Number.parseInt(res.percentage.replace("%", "")), totalNumber: res.cases })
              setEvaluateLoading(false)
            }
          })
      } else if (advancedEditData.mode === "visual") {
        // Evaluation and calculation on load for Visual mode if there is an existing definition 
        setEvaluateLoading(true)
        const body = { expression: returnCombineDataExpression(combineFilterData) }

        evaluateFilter(evaluationFilterUrl, token, body)
          .then(res => {
            if (res && (res.message || res.error)) {
              setAdvancedEvaluateError(res.message ? res.message : res.error)
              setEvaluateLoading(false)
            } else {
              setCombineData({ ...combineData, totalPercentage: Number.parseInt(res.percentage.replace("%", "")), totalNumber: res.cases })
              setEvaluateLoading(false)
            }
          })
      }
    }

    setData({
      ...data,
      questions: questions,
      filterData: data,
      selectedQuestion: questions[0],
      selectedQuestionData: returnSelectedQuestionData(questions[0].id, questions, data, questions[0].subqIndex),
      negateFilter: false,
      responseValue: null,
      responseInterval: null,
      responsesTotalNumber: 0,
      responsesTotalPercentage: 0,
      advancedOptions: returnAdvancedOptions(questions[0].type)
    })
    setSearchFilteredQuestions([...questions])
    setAdvancedOptionBetween({ ...advancedOptionBetween, all: data.questions.find(el => el.texts[data.defaultLanguage] === questions[0].ques).rows.map(row => Object.assign({ ...row, selected: false })).length })
    if (questionData) {
      setSelectedQuestion(questionData[0])
    }
  }, [])

  useEffect(() => {
    if (data.didSelectedResponseUpdate) {
      const questionType = checkSelectedQuestionType(data.selectedQuestion, data.filterData)
      if ((questionType === 'numeric' && data.responseValue !== null) || questionType !== 'numeric') {
        const body = returnEvaluateFilterBody(data.negateFilter, data.selectedQuestionData, data.filterData, data.selectedQuestion, data.responseInterval, data.responseValue)
        if (body) {
          if (type !== "survey") {
            if (Object.keys(body).length) {
              setIsLoadingEvalFilter(true)
              const evaluationFilterUrl = returnEvalFilterUrl(type === "survey" ? "surveys" : analysisType, `${history.location.pathname.split('/')[2]}`, datasetId, workflowProjectId)

              evaluateFilter(evaluationFilterUrl, token, body)
                .then(res => {
                  setIsLoadingEvalFilter(false)
                  if (res && (res.message || res.error)) {
                    dispatch({ type: 'UPDATE_ERROR_MESSAGE', payload: { msg: res.message ? res.message : res.error, type: 'modal' } })
                  } else {
                    setData({
                      ...data,
                      responsesTotalNumber: res.cases,
                      responsesTotalPercentage: res.percentage,
                      didSelectedResponseUpdate: false
                    })
                  }
                })
              setSelectionFilterExpression(body.expression)
            }
          }
          else {
            let expression = body.expression
            if (advancedOption) {
              expression = returnAdvancedOptionExpression(body.expression, advancedOption, advancedOptionBetween, data.selectedQuestionData)
            }
            setSelectionFilterExpression(expression)
            setData({ ...data, didSelectedResponseUpdate: false })
          }
        } else {
          setSelectionFilterExpression(null)
        }
      }
    }
  }, [data])

  useEffect(() => {
    if (combineData.didUpdate) {
      const list = combineData.data
      if (selectionFilterExpression !== null) {
        if (!combineData.data.find(el => el.ques === selectionFilterExpression)) {
          list.push({ ques: selectionFilterExpression, operators: ['AND', 'OR'], nestingStart: ['', '(', '(('], nestingEnd: ['', ')', '))'], operator: 'AND', operatorStart: '', operatorEnd: '', text: combineData.questionText })
        }
      }
      const expression = returnCombineDataExpression(list)
      if (type !== "survey") {
        const body = { expression: expression }
        const evaluationFilterUrl = returnEvalFilterUrl(type === "survey" ? "surveys" : analysisType, `${history.location.pathname.split('/')[2]}`, datasetId, workflowProjectId)
        setIsLoadingEvalFilter(true)
        evaluateFilter(evaluationFilterUrl, token, body)
          .then(res => {
            setIsLoadingEvalFilter(false)
            let totalNumber
            let percentage
            if (res.error) {
              totalNumber = 0
              percentage = 0
            }
            else {
              totalNumber = res.cases
              percentage = Number.parseInt((res.percentage).slice(0, -1))
            }
            setCombineData({
              ...combineData,
              data: list,
              expression: expression,
              totalNumber: totalNumber,
              totalPercentage: percentage,
              questionText: '',
              didUpdate: false
            })
          })
      }
      else {
        setCombineData({
          ...combineData,
          data: list,
          expression: expression,
          questionText: '',
          didUpdate: false
        })
      }
    }
  }, [combineData.didUpdate])

  const evaluateAndSaveExpression = (evalType, incommingExpression) => {
    setEvaluateLoading(true)
    const body = { expression: incommingExpression }
    const evaluationFilterUrl = returnEvalFilterUrl(type === "survey" ? "surveys" : analysisType, `${history.location.pathname.split('/')[2]}`, datasetId, workflowProjectId)

    evaluateFilter(evaluationFilterUrl, token, body)
      .then(res => {
        if (res && (res.message || res.error)) {
          setAdvancedEvaluateError(res.message ? res.message : res.error)
          setEvaluateLoading(false)
        } else {
          if (advancedEditData.mode === "visual" || evalType === "addToAdvanced" || evalType === "evaluate") {
            setCombineData({ didUpdate: false, data: [], expression: "", totalPercentage: Number.parseInt(res.percentage.replace("%", "")), totalNumber: res.cases })
          }
          setEvaluateLoading(false)
          if (evalType && evalType === "basicSave") {
            onSaveFilterOptions(combineData.data, advancedEditData.expression, option);
          }
        }
      })
  }

  const itemRender = (props, itemData) => returnItemRender(props, itemData);

  const valueRender = (props, value) => returnValueRender(props, data.questions, value)

  const onClickQuestion = (question, subqIndex) => {
    const selectedData = returnSelectedQuestionData(question, data.questions, data.filterData, subqIndex)
    const options = returnAdvancedOptions(data.questions.find(el => el.id === question && el.subqIndex === subqIndex).type)

    setData({
      ...data,
      selectedQuestion: data.questions.find(el => el.id === question && el.subqIndex === subqIndex),
      openQuestionList: false,
      selectedQuestionData: selectedData,
      negateFilter: false,
      responsesTotalPercentage: 0,
      responsesTotalNumber: 0,
      responseValue: null,
      responseInterval: null,
      advancedOptions: options
    })

    setAdvancedOptionBetween({ first: 1, second: 2, all: selectedData.length })
    setSelectionFilterExpression(null)
    setAdvancedOption(null)
  }

  const responseSelectionChange = (e) => {
    const list = returnResponseSelectionChange(e, data.selectedQuestionData)
    setData({ ...data, selectedQuestionData: list, didSelectedResponseUpdate: true });
  }

  const headerSelectionChange = (e) => {
    const list = returnHeaderSelectionChange(e, data.selectedQuestionData)
    setData({ ...data, selectedQuestionData: list, didSelectedResponseUpdate: true })
  }

  const onNegateFilter = (value) => {
    setData({ ...data, didSelectedResponseUpdate: true, negateFilter: !value })
  }

  const onSetResponseValues = (values) => {
    const value = checkResponseValues(values, data.responseValue, 'responseValue')
    const interval = checkResponseValues(values, data.responseInterval, 'responseInterval')

    setData({ ...data, responseValue: value, responseInterval: interval, didSelectedResponseUpdate: true })
  }

  const onAddFilter = (e) => {
    const text = returnCombineQuestionText(data.selectedQuestion, data.filterData, data.responseInterval, data.responseValue, data.selectedQuestionData)
    switch (e.target.name) {
      case "addToAdvanced": {
        const updatedAdvancedData = `${!advancedEditData.expression ? selectionFilterExpression : advancedEditData.expression + ((e.shiftKey ? "|" : "&") + selectionFilterExpression)}`;
        setAdvancedEditData({ mode: "advanced", expression: updatedAdvancedData });
        if (type !== "survey") {
          evaluateAndSaveExpression("addToAdvanced", updatedAdvancedData);
        } else {
          setCombineData({ didUpdate: false, data: [], expression: "", totalNumber: 0, totalPercentage: 0, questionText: "" })
        }
        break;
      }
      case "addToVisual":
        setAdvancedEditData({ mode: "visual", expression: "" })
        setCombineData({ ...combineData, didUpdate: true, questionText: text })
        break;
      default:
        break;
    }
  }

  const advancedButtonsHandler = (e) => {
    switch (e.target.name) {
      case "editInAdvanced":
        setAdvancedEditData({ mode: "advanced", expression: combineData.expression })
        if (type !== "survey") {
          evaluateAndSaveExpression("editInAdvanced", combineData.expression);
        } else {
          setCombineData({ didUpdate: false, data: [], expression: "", totalNumber: 0, totalPercentage: 0, questionText: "" })
        }
        break;
      case "clear":
        setAdvancedEditData({ mode: "clean", expression: "" })
        setCombineData({ didUpdate: false, data: [], expression: "", totalNumber: 0, totalPercentage: 0, questionText: "" })
        break;
      case "evaluate":
        evaluateAndSaveExpression("evaluate", advancedEditData.expression);
        break;
      default:
        break;
    }
  }

  const onOperatorClick = (operator, question) => {
    const list = combineData.data
    if (combineData.data.find(el => el.ques === question)) {
      list.find(el => el.ques === question).operator = operator
      setCombineData({ data: list, didUpdate: true })
    }
  }

  const onNestingStartClick = (operator, question) => {
    const list = combineData.data
    if (combineData.data.find(el => el.ques === question)) {
      list.find(el => el.ques === question).operatorStart = operator
      setCombineData({ data: list, didUpdate: true })
    }
  }

  const onNestingEndClick = (operator, question) => {
    const list = combineData.data
    if (combineData.data.find(el => el.ques === question)) {
      list.find(el => el.ques === question).operatorEnd = operator
      setCombineData({ data: list, didUpdate: true })
    }
  }

  const onRemoveFilterQuestion = (question) => {
    const updatedCombineData = JSON.parse(JSON.stringify(combineData.data))
    updatedCombineData.splice(updatedCombineData.findIndex(el => el.ques === question), 1)

    setCombineData({
      ...combineData,
      data: updatedCombineData,
      didUpdate: false
    })
  }

  const onAddQuestion = () => {
    onSaveFilterOptions(combineData.data, combineData.expression, option)
    setCombineData({
      didUpdate: false,
      data: [],
      expression: '',
      totalNumber: 0,
      totalPercentage: 0,
      questionText: ''
    })
  }

  const onSaveSurveyFilter = () => {
    if (combineData.expression === '' && combineData.data.length !== 0) {
      const list = combineData.data
      if (selectionFilterExpression !== null) {
        if (!combineData.data.find(el => el.ques === selectionFilterExpression)) {
          list.push({ ques: selectionFilterExpression, operators: ['AND', 'OR'], nestingStart: ['', '(', '(('], nestingEnd: ['', ')', '))'], operator: 'AND', operatorStart: '', operatorEnd: '', text: combineData.questionText })
        }
      }
      const expression = returnCombineDataExpression(list)
      onSaveFilterOptions(expression)
    } else {
      if (advancedEditData.mode !== "advanced") {
        onSaveFilterOptions(combineData.expression)
      } else {
        onSaveFilterOptions(advancedEditData.expression)
      }
    }
  }

  const dropdownFilterHandler = (event) => {
    const searchedQuestions = [];
    const oldQuestions = [...data.questions];
    if (event.filter.value) {
      oldQuestions.forEach(question => {
        if (question.qno) {
          if (question.qno.toLowerCase().includes(event.filter.value.toLowerCase())) {
            searchedQuestions.push(question);
          }
        } else {
          if (question.text.toLowerCase().includes(event.filter.value.toLowerCase())) {
            searchedQuestions.push(question);
          }
        }
      });
      setSearchFilteredQuestions([...searchedQuestions]);
    } else {
      setSearchFilteredQuestions([...data.questions]);
    }
  }

  const renderSelectedQuestion = (li, data) => {
    const questionDataError = errorMessage?.map((el, key) => {
      return {
        ...el,
        textColor: el.success ? "inherit" : "red",
        uId: el.uId
      }
    })
    const questionIndex = errorMessage && questionDataError.findIndex((el) => el.uId === data.dataItem.uId)
    const itemChildren = (
      errorMessage ?
        <span style={{ color: questionDataError[questionIndex].textColor }}>{li.props.children}</span>
        :
        <span style={{ color: "inherit" }}>{li.props.children}</span>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  const changeSelectedQuestion = (event) => {
    setSelectedQuestion(event.target.value)
    changeQuestion(event.target.value)
  }

  return (
    <div className="h-100 d-flex flex-column">
      <div className="filter-builder-content row no-gutters">
        {
          isLoadingEvalFilter &&
          <div style={{ zIndex: '1', position: 'absolute', top: '0', left: '0', background: 'rgba(255,255,255,0.95)' }} className="overlay d-flex flex-column align-items-center justify-content-center w-100 h-100">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <h5 className='mt-4'>Evaluating filter...</h5>
          </div>
        }
        <div className={`${type === "basic" || type === "survey" ? 'col-md-6 d-flex flex-column p-4 h-100' : 'col-md-12'}`}>
          <div className={`card filter-builder-card ${type === "basic" || type === "survey" ? 'mb-3' : 'filter-card'}`}>
            {questionData && questionData.length > 1 ?
              <React.Fragment>
                <div className="card-header d-flex align-items-center">
                  {/* <Avatar value="1" class="avatar-sm" color="primary" /> */}
                  <h6 className="m-0">Selected question</h6>
                </div>
                <div className="card-body">
                  <BaseDropDownList
                    className="form-control h-100 filter-dropdown"
                    data={questionData}
                    itemRender={renderSelectedQuestion}
                    textField="text"
                    valid={errorMessage && !errorMessage}
                    value={selectedQuestion}
                    onChange={changeSelectedQuestion}
                  />
                </div>
              </React.Fragment>
              : null}
            <div className="card-header filter-builder-header d-flex align-items-center">
              {/* <Avatar value={questionData && questionData.length > 1 ? "2" : "1"} class="avatar-sm" color="primary" /> */}
              <h6 className="m-0">Select question</h6>
            </div>
            <div className="card-body d-flex flex-column p-0">
              <div className='inner p-4'>
                {(data.questions !== null && searchFilteredQuestions !== null) ?
                  <BaseDropDownList
                    className="form-control h-100 filter-dropdown"
                    data={searchFilteredQuestions}
                    textField="id"
                    onChange={(e) => onClickQuestion(e.value.id, e.value.subqIndex)}
                    itemRender={itemRender}
                    value={data.selectedQuestion}
                    valueRender={valueRender}
                    popupSettings={{ className: "drop-down-height" }}
                    filterable={true}
                    onFilterChange={dropdownFilterHandler}
                  />
                  : null}
              </div>
            </div>
          </div>
          <div className={`card filter-builder-card ${type === "basic" || type === "survey" ? 'mb-0 flex-grow-1 overflow-hidden' : 'filter-card'}`} style={{ border: "1px !important" }}>
            <div className="card-header filter-builder-header d-flex align-items-center">
              {/* <Avatar value={questionData && questionData.length > 1 ? "3" : "2"} class="avatar-sm" color="primary" /> */}
              <h6 className="m-0">Select responses and add filter</h6>
            </div>
            <div className="card-body overflow-auto p-0">
              <ResponsesTable
                filterData={data.filterData}
                selectedQuestionData={data.selectedQuestionData}
                responseSelectionChange={responseSelectionChange}
                selectedQuestion={data.selectedQuestion}
                headerSelectionChange={headerSelectionChange}
                negateFilter={data.negateFilter}
                onNegateFilter={onNegateFilter}
                onSetResponseValues={onSetResponseValues}
                responseValue={data.responseValue}
                responseInterval={data.responseInterval}
                filterType={type}
              />
            </div>
            <div className="card-footer d-flex align-items-center px-3">
              <div className="w-75 mr-2 d-flex gap-md">
                <button
                  type='button'
                  className="btn btn-primary d-flex"
                  disabled={(!selectionFilterExpression || advancedEditData.expression)}
                  onClick={e => onAddFilter({ target: { name: 'addToVisual' } })}>
                  <i className="fas fa-plus-circle mr-2" />
                  <span>Add to Builder</span>
                </button>
                {type !== "question" &&
                  <button
                    type='button'
                    title={`Click to add to advanced with the "&" operator\nHold shift and click to add to advanced with the "|" operator`}
                    className="btn btn-primary d-flex"
                    disabled={(!selectionFilterExpression || combineData.data.length > 0)}
                    onClick={e => onAddFilter({ target: { name: 'addToAdvanced' } })}>
                    <i className="far fa-plus-circle mr-2" />
                    <span>Add to Advanced Builder</span>
                  </button>
                }
              </div>
              <div className="d-flex justify-content-end flex-grow-1">
                <span className="m-0 mr-3 medium">Total </span>
                <span className="m-0 mr-3 medium">{data.responsesTotalNumber}</span>
                <span className="m-0 medium">{data.responsesTotalPercentage}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={`${type === "basic" || type === "survey" ? 'col-md-6 d-flex flex-column pl-0 py-4 pr-4 h-100' : 'col-md-12'}`}>

          {/* Commented out instead of deleted, because it might be needed in future */}
          {/* {type === "survey" ?
            <div className={`card ${type === "basic" ? 'mb-3 flex-grow-1 overflow-hidden' : 'filter-card'}`}>
              <div className=" card-header d-flex align-items-center">
                <Avatar value="3" class="avatar-sm" color="primary" />
                <h2 className="m-0 h5">Advanced</h2>
              </div>
              <div className="card-body overflow-auto d-flex flex-column">
                <div className="d-flex flex-wrap mb-3">
                  {
                    data.advancedOptions && data.advancedOptions.map((item, key) => (
                      <div key={key} className={`d-flex align-items-start col-md-6 mb-2 ${!selectionFilterExpression && item.id !== 'sync' ? "text-disabled" : "text-primary"}`}>
                        <i className={`mr-2 p-1 ${advancedOption && advancedOption.id === item.id ? "fas fa-dot-circle text-primary" : "far fa-circle"}
                        ${selectionFilterExpression && "cursor-pointer"}`}
                          onClick={() => {
                            setAdvancedOption(item)
                            setData({ ...data, didSelectedResponseUpdate: true })
                          }} />
                        {item.id !== 'between' ?
                          <span>{item.text}</span>
                          :
                          <div className="d-flex flex-column flex-wrap">
                            <span className="mb-1 mr-1">Between</span>
                            <div className="d-flex align-items-center mb-1 mr-1">
                              <NumericTextBox width={80} min={1} max={data.selectedQuestionData.length}
                                value={advancedOptionBetween.first}
                                disabled={!(selectionFilterExpression && advancedOption && advancedOption.id === 'between')}
                                onChange={(e) => setAdvancedOptionBetween({ ...advancedOptionBetween, first: e.value, second: e.value + 1 })} />
                              <span className="mx-1 ">and</span>
                              <NumericTextBox width={80} min={advancedOptionBetween.first + 1} max={data.selectedQuestionData.length}
                                value={advancedOptionBetween.second}
                                disabled={!(selectionFilterExpression && advancedOption && advancedOption.id === 'between')}
                                onChange={(e) => setAdvancedOptionBetween({ ...advancedOptionBetween, second: e.value })} />
                            </div>
                            <span>of the selected</span>
                          </div>}
                      </div>
                    ))}
                </div>
                <Button icon="fa fas fa-eraser"
                  className="btn btn-secondary d-flex align-self-start"
                  disabled={!advancedOption}
                  onClick={() => setAdvancedOption(null)}>Clear options</Button>
              </div>
              <div className="card-footer d-flex align-items-center">
                <div className="w-75 mr-2">
                  <Button
                    className="btn btn-success d-flex"
                    icon="fas fa fa-plus-circle"
                    disabled={!selectionFilterExpression}
                    name="addToVisual"
                    onClick={e => onAddFilter(e)}>
                    Add to Visual
                  </Button>
                </div>
              </div>
            </div> : null} */}

          <div className={`card filter-builder-card ${type === "basic" || type === "survey" ? 'mb-3 flex-grow-1 flex-shrink-0 overflow-hidden' : 'filter-card'}`}>
            <div className=" card-header filter-builder-header d-flex align-items-center">
              {/* <Avatar value={!(questionData && questionData.length > 1) ? "3" : "4"} class="avatar-sm" color="primary" /> */}
              {/* Combine Section's Title has been Changed to "Visual", all references to Combine are pointing to this section */}
              <h6 className="m-0">Visual</h6>
            </div>
            <div className="card-body p-3 overflow-auto" style={{ backgroundColor: `${advancedEditData.expression ? "rgba(239, 239, 239, 0.3)" : "white"}` }}>
              {advancedEditData.expression ?
                <span className="text-muted">Visual section is disabled, please clear the Advanced section to enable it</span>
                :
                <CombineFilterData
                  data={combineData.data}
                  onOperatorClick={onOperatorClick}
                  onRemoveFilterQuestion={onRemoveFilterQuestion}
                  onNestingStartClick={onNestingStartClick}
                  onNestingEndClick={onNestingEndClick}
                  filterType={type}
                />
              }
            </div>
            {(type === "basic" || type === "survey") &&
              <div className="card-footer d-flex align-items-center justify-content-between px-3">
                <Button
                  className="btn btn-primary d-flex mr-1"
                  icon="fas fa fa-plus-circle"
                  disabled={combineData.data.length < 1}
                  onClick={() => advancedButtonsHandler({ target: { name: "editInAdvanced" } })}>
                  Edit in Advanced Builder
                </Button>
                <Button
                  className="btn btn-muted d-flex"
                  icon="fas fa fa-times-circle"
                  disabled={combineData.data.length < 1}
                  onClick={() => advancedButtonsHandler({ target: { name: "clear" } })}>
                  Clear
                </Button>
              </div>
            }
          </div>
          {(type === "basic" || type === "survey") ?
            <React.Fragment>
              {/* Advanced starts */}
              {(type === "basic" || type === "survey") &&
                <div className={`card filter-builder-card overflow-hidden ${type === "basic" ? 'mb-3' : type === "survey" ? "mb-0" : 'filter-card'}`}>
                  <div className="card-header filter-builder-header d-flex align-items-center justify-content-between pr-3">
                    <div className="d-flex align-items-center">
                      {/* <Avatar value="4" class="avatar-sm" color="primary" /> */}
                      <h6 className="m-0">Advanced</h6>
                    </div>
                    <div className="d-flex">
                      {type !== "survey" &&
                        <Button
                          className="btn btn-muted d-flex"
                          icon="fas fas fa-calculator"
                          disabled={!advancedEditData.expression}
                          title="Evalutate advanced expression and recaluclate totals"
                          onClick={() => advancedButtonsHandler({ target: { name: "evaluate" } })}>
                          Recalculate Total
                        </Button>
                      }
                      <Button
                        className="btn btn-muted d-flex"
                        icon="fas fa fa-times-circle"
                        disabled={!advancedEditData.expression}
                        onClick={() => advancedButtonsHandler({ target: { name: "clear" } })}>
                        Clear
                      </Button>
                    </div>
                  </div>
                  <div className="card-body d-flex flex-column p-0">
                    <textarea
                      className={`border-0 p-3 ${!advancedEvaluateError ? "text-muted" : "text-danger"}`}
                      rows="3"
                      spellCheck={false}
                      value={advancedEditData.expression}
                      placeholder={combineData.data.length > 0 ? "Advanced section is disabled, please clear the Visual section to enable it" : undefined}
                      disabled={combineData.data.length > 0}
                      onChange={e => {
                        setAdvancedEvaluateError("")
                        if (e.target.value === "") {
                          setAdvancedEditData({ mode: "visual", expression: e.target.value })
                        } else {
                          setAdvancedEditData({ mode: "advanced", expression: e.target.value })
                        }
                      }} />
                  </div>
                </div>
              }
              {/* Advanced ends ^^^ */}
              {/* View Total starts */}
              {type !== "survey" &&
                <div className={`card ${type === "basic" ? 'mb-0' : 'filter-card'}`}>
                  <div className="card-header d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      {/* <Avatar value={((questionData && questionData.length > 1) || type === "basic") ? "5" : "4"} class="avatar-sm" color="primary" /> */}
                      <h6 className="m-0">View total</h6>
                    </div>
                    <div>
                      {evaluateLoading &&
                        <div className="spinner-border text-primary" role="status">
                          <span className="sr-only" />
                        </div>
                      }
                    </div>
                  </div>
                  {advancedEvaluateError ?
                    <div className="card-body d-flex alert-danger align-items-center">
                      <i className="fas fa fa-times text-danger p-1 mr-1 cursor-pointer" onClick={() => setAdvancedEvaluateError("")} />
                      <span>{advancedEvaluateError}</span>
                    </div>
                    :
                    <React.Fragment>
                      <div className="card-body d-flex p-3 gap-md">
                        <div className="d-flex w-75 align-items-center">
                          <h6 className="m-0">Percent:</h6>
                          <ProgressBar className="flex-grow-1 ml-3" value={combineData.totalPercentage} />
                        </div>
                        <div className="d-flex w-25 justify-content-around align-items-center">
                          <h6 className="m-0">Number of cases:</h6>
                          <h6 className="m-0"> {combineData.totalNumber}</h6>
                        </div>
                      </div>
                    </React.Fragment>
                  }
                </div>
              }
              {/* View Total ends ^^^ */}
            </React.Fragment>
            : null}
        </div>
      </div>
      {
        type === "basic" ?
          <DialogActionsBar>
            <Button className="btn btn-secondary" onClick={() => { setCombineData({ didUpdate: false, data: [], expression: '', totalNumber: 0, totalPercentage: 0, questionText: '' }); handleClose() }}>Cancel</Button>
            <Button className="btn btn-primary"
              disabled={advancedEvaluateError}
              onClick={(e) => {
                if (advancedEditData.mode === "advanced") {
                  evaluateAndSaveExpression("basicSave", advancedEditData.expression);
                } else {
                  onSaveFilterOptions(combineData.data, combineData.expression, option);
                }
              }}
              title={`${advancedEditData.mode === "visual" ? "The definition from Visual section will be used" : advancedEditData.mode === "advanced" ? "The definition from Advanced section will be used" : "No definition will be set"}`} >Save</Button>
          </DialogActionsBar>
          : type === "survey" ?
            <div className="filter-builder-buttons">
              <DialogActionsBar>
                <Button className="btn btn-secondary" onClick={() => handleClose()}>Cancel</Button>
                <Button className="btn btn-primary" onClick={() => onSaveSurveyFilter()}>Save filter</Button>
              </DialogActionsBar>
            </div>
            :
            <div className="d-flex px-3 py-2 justify-content-between flex-shrink-0">
              <Button className="btn btn-danger d-flex" onClick={() => setCombineData({ didUpdate: false, data: [], expression: '', totalNumber: 0, totalPercentage: 0, questionText: '' })}>Clear filter</Button>
              <Button disabled={combineData.data.length === 0 ? true : false} className="btn btn-primary" onClick={onAddQuestion}>Add as row</Button>
            </div>
      }
    </div>
  );
};

export default FilterBuilder;