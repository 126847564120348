import { Fragment, type ReactNode, useState } from "react";
import { Icon } from '../../shared/Icon/Icon';

interface Props {
  onHide: () => void,
  addClasses?: string,
  header?: { show: boolean, title: string | number, }
  width?: string,
  children: ReactNode,
  shouldOverflow?: boolean
}

export const SideBar = ({ children, onHide, addClasses, header, width, shouldOverflow }: Props) => {
  const [appear, setAppear] = useState<boolean>(true);

  const onHideHandler = () => {
    if (appear === true) {
      setAppear(prevState => !prevState);
      setTimeout(() => onHide(), 400);
    } else {
      setAppear(prevState => !prevState)
    }
  }

  return (
    <Fragment>
      {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
      <div id="sideBar-overlay" className={`${appear ? "sideBar-bg-appear" : "sideBar-bg-disappear"}`} onClick={onHideHandler} />
      <div
        className={`${addClasses} ${shouldOverflow ? 'overflow-auto' : 'overflow-hidden'} sideBar-container ${appear ? "sideBar-appear" : "sideBar-disappear"}`}
        style={{ "width": `${width ? width : "480px"}` }}>
        {header?.show &&
          <div className="d-flex justify-content-between align-items-center p-3 h-56 border-bottom">
            <span className='m-0 pl-1 stronger'>{header?.title ? header.title : null}</span>
            <button type="button" className="btn btn-icon btn-transparent p-05" onClick={onHideHandler}>
              <Icon type="close" />
            </button>
          </div>
        }
        {children}
      </div>
    </Fragment >
  );
}