export const returnUpdatedSortDirection = (sortType: { sortBy: string, direction: string }, sortBy: string) => {
  let updatedDirection = ''
  let updatedDirectionIcon = ''

  if (sortType.sortBy !== sortBy) {
    updatedDirection = 'desc'
    updatedDirectionIcon = 'desc'
  } else {
    if (sortType.direction === 'asc') {
      updatedDirectionIcon = 'desc'
      updatedDirection = 'desc'
    } else if (sortType.direction === 'desc') {
      updatedDirectionIcon = 'asc'
      updatedDirection = 'asc'
    }
  }

  return {
    updatedDirection: updatedDirection,
    updatedDirectionIcon: updatedDirectionIcon
  }
}