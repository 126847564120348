import findCommonTextInTitles from "../findCommonTextInTitles/findCommonTextInTitles";

export default (data) => {
  let questionValues = [];
  const singleCategoryQuestionValues = []
  const twoCategoryQuestionValues = []
  const multiCategoryQuestionValues = []
  let uniqueValues = [];
  const dichotomyAValues = [];
  let equalNumberOfCategories = 0;
  const dataCopy = JSON.parse(JSON.stringify(data))
  const equalCategories = [];
  let areAllCategoriesEqual = false;
  let dichotomyB = null;

  data.forEach((el, id) => {
    if (el.values.value && Array.isArray(el.values.value) && el.values.value.length >= 2) {
      equalNumberOfCategories++;
    }
  })

  data.forEach((el, id) => {
    if (Array.isArray(el.values.value)) {
      if (equalNumberOfCategories >= 2 && equalNumberOfCategories === data.length) {
        el.values.value.forEach((val, index) => {
          delete dataCopy[id].values.value[index].$;
        })
        equalCategories.push(dataCopy[id].values.value)

        el.values.value.forEach(val => {
          twoCategoryQuestionValues.push(val)
        })
      }
      else if (equalNumberOfCategories === 1 && equalNumberOfCategories === data.length) {
        el.values.value.forEach(val => {
          multiCategoryQuestionValues.push(val)
        })
      } else {
        el.values.value.forEach(val => {
          singleCategoryQuestionValues.push(val)
        })
      }
    } else {
      multiCategoryQuestionValues.push(el.values.value)
    }
  })

  equalCategories.forEach(el => {
    areAllCategoriesEqual = equalCategories.every((val, i, arr) => val.text === arr[0].text)
  })

  if (twoCategoryQuestionValues.length > 0) {
    questionValues = twoCategoryQuestionValues
  } else if (multiCategoryQuestionValues.length > 0) {
    questionValues = multiCategoryQuestionValues
  } else if (singleCategoryQuestionValues.length > 0) {
    questionValues = singleCategoryQuestionValues
  }

  // SET UNIQUE VALUES DEPENDING ON THE MULTICHOICE SUBTYPES
  const map = new Map();
  if (questionValues === twoCategoryQuestionValues && areAllCategoriesEqual) {
    uniqueValues = findCommonTextInTitles(data.map(el => { return { title: el.title } })).updatedRowTitles.map(el => { return { text: el } })
    for (const item of questionValues) {
      if (!map.has(item.text)) {
        map.set(item.text, true);
        dichotomyAValues.push({
          text: item.text
        });
      }
    }
  } else if (questionValues === twoCategoryQuestionValues && !areAllCategoriesEqual) {
    const duplicates = questionValues.filter(o => {
      if (uniqueValues.find(i => i.text === o.text)) {
        return true
      }
      uniqueValues.push(o)
      return false;
    })
    if (duplicates.length > 0) {
      dichotomyB = duplicates;
      uniqueValues.forEach((el, id) => {
        if (el.text === dichotomyB[0].text) {
          uniqueValues.splice(id, 1)
        }
      })
    }
  }
  else if (questionValues === multiCategoryQuestionValues) {
    for (const item of questionValues) {
      if (!map.has(item.text)) {
        map.set(item.text, true);
        uniqueValues.push({
          '@code': item['@code'],
          text: item.text
        });
      }
    }
  } else if (questionValues === singleCategoryQuestionValues) {
    data.forEach(el => {
      uniqueValues.push({
        '@code': el.values.value[0]['@code'],
        text: el.values.value[0].text
      });
    })
  }

  return {
    dichotomyAValues: dichotomyAValues,
    dichotomyBValue: dichotomyB,
    uniqueValues: uniqueValues.filter(el => el.text !== "Not answered")
  }
}