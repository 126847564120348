export const returnQuestionTypeText = (type: string) => {
  switch (type) {
    case 'DefaultQuesF':
      return 'Numeric (F)';
    case 'DefaultQuesH':
      return 'Hour (H)';
    case 'DefaultQuesM':
      return 'Multiple (M)';
    case 'DefaultQuesN':
      return 'Single (N)';
    case 'DefaultQuesOpen':
      return 'Open';
    case 'DefaultQuesQuota':
      return 'Quota';
    case 'DefaultQuesRm':
      return 'Multiple grid (Rm)';
    case 'DefaultQuesRn':
      return 'Single grid (Rn)';
    default:
      return 'Select a type...';
  }
}