import React from 'react'
import { v4 as uuid } from 'uuid';

import { Icon } from '../../../../../../shared/Icon/Icon';
import { returnQuestionIcon } from '../returnQuestionIcon/returnQuestionIcon'

export const returnQuestionsData = (data) => {
  const questions = []
  if (data) {
    data.questions.forEach(element => {
      if ((element.type === 'f' || element.type === 's' || element.type === 'l') && element.rows.length > 1) {
        element.rows.forEach((row, key) => {
          questions.push({ id: uuid(), text: row.texts[data.defaultLanguage], subqIndex: element.subqIndex, ques: element.texts[data.defaultLanguage], qno: element.qno, type: element.type, index: key })
        })
      }
      else if (element.type === 'rn' || element.type === 'rm') {
        element.rows.forEach((row, key) => {
          questions.push({ id: uuid(), text: row.texts[data.defaultLanguage], subqIndex: element.subqIndex, ques: element.texts[data.defaultLanguage], qno: element.qno, type: element.type, index: key })
        })
      }
      else {
        questions.push({ id: element.id ? element.id : element.questionId, subqIndex: element.subqIndex, text: element.qno, ques: element.texts[data.defaultLanguage], type: element.type })
      }
    })
  }
  return questions
}

export const returnValueRender = (props, questions, question) => {
  if (question) {
    const icon = returnQuestionIcon(question.type)
    const children =
      <div className="d-flex py-2 pr-3 align-items-center select-question" >
        <Icon type={icon} className="question-icon" />
        <div className="d-flex flex-column w-100 ml-3">
          {question.qno ?
            <div className="d-flex w-100 flex-row">
              <span className="badge badge-pill badge-light-blue text-white small strong">
                {question.qno.slice(0, -2)}
              </span>
              <span className="strong text-truncate">
                {question.text}
              </span>
            </div>
            :
            <span className="strong text-truncate">{question.text.slice(0, -2)} </span>
          }
          <span className="text-truncate">{question.ques}</span>
        </div>
      </div>

    return React.cloneElement(props, { ...props.props, }, children);
  } return null
}

export const returnItemRender = (props, questionData) => {
  // Remove iteration and sending dropdown data instead send the item that itemRender function gives as second argument
  const { dataItem: question } = questionData;
  if (question) {
    const icon = returnQuestionIcon(question.type)
    const children =
      <div className="d-flex py-2 px-3 align-items-center ">
        <Icon type={icon} className="question-icon" />
        <div className="d-flex flex-column mx-3">
          {question.qno ?
            <div>
              <span className="badge badge-pill badge-light-blue text-white mr-2 align-self-start">
                {question.qno.slice(0, -2)}
              </span>
              <span className="strong">
                {question.text}
              </span>
            </div>
            :
            <span className="strong">{question.text.slice(0, -2)} </span>
          }
          <span>{question.ques}</span>

        </div>
      </div>

    return React.cloneElement(props, { ...props.props, }, children);
  } return null
}

export const returnResponseSelectionChange = (e, selectedQuestionData) => {
  const list = selectedQuestionData.map(item => {
    if (item.code === e.dataItem.code) {
      item.selected = !e.dataItem.selected;
    }
    return item;
  })
  return (
    list
  )
}

export const returnHeaderSelectionChange = (e, selectedQuestionData) => {
  const checked = e.value;
  const list = selectedQuestionData.map(item => {
    item.selected = checked;
    return item;
  });
  return (
    list
  )
}

export const returnSelectedResponsesList = (selectedQuestionData) => {
  const list = []
  if (selectedQuestionData && typeof selectedQuestionData[0].code === 'number') {
    selectedQuestionData.forEach(row => {
      if (row.selected) {
        list.push(row.code)
      }
    })
  }
  return list;
}

export const checkResponseValues = (values, valueState, valueName) => {
  let value = valueState
  if (values[valueName]) {
    value = values[valueName]
  }
  return (
    value
  )
}

export const returnSelectedQuestionData = (question, questions, filterData, subqIndex) => {
  let selectedData = []
  if (questions.find(el => el.id === question && el.subqIndex === subqIndex).qno) {
    const qno = questions.find(el => el.id === question && el.subqIndex === subqIndex).qno
    const mainQuestion = filterData.questions.find(el => el.qno === qno)

    if (mainQuestion.type === 'f') {
      const selectedNumericQuestion = mainQuestion.rows.find(row => row.texts[filterData.defaultLanguage] === question)
      selectedData.push(Object.assign({ ...selectedNumericQuestion, selected: false }))
    }
    else {
      selectedData = mainQuestion.columns.map(column => Object.assign({ ...column, selected: false }))
    }
  }
  else {
    selectedData = filterData.questions.find(el => el[`${el.id ? 'id' : 'questionId'}`] === question).rows.map(row => Object.assign({ ...row, selected: false }))
  }
  return selectedData
}

export const checkSelectedQuestionType = (selectedQuestion, filterData) => {
  let type = ''

  if (selectedQuestion) {
    if (selectedQuestion.qno) {
      if (filterData.questions.find(ques => ques.qno === selectedQuestion.qno).type === 'f' ||
        filterData.questions.find(ques => ques.qno === selectedQuestion.qno).type === 's' ||
        filterData.questions.find(ques => ques.qno === selectedQuestion.qno).type === 'l' ||
        filterData.questions.find(ques => ques.qno === selectedQuestion.qno).type === 'd') {
        type = 'numeric'
      }
    }
    else {
      if (filterData.questions.find(ques => ques.qno === selectedQuestion.text).type === 'f' ||
        filterData.questions.find(ques => ques.qno === selectedQuestion.text).type === 's' ||
        filterData.questions.find(ques => ques.qno === selectedQuestion.text).type === 'l' ||
        filterData.questions.find(ques => ques.qno === selectedQuestion.text).type === 'd') {
        type = 'numeric'
      }
    }
  }
  return type
}

export const returnCombineDataExpression = (data) => {
  let expression = ''
  if (data.length === 1) {
    expression = data[0].ques
  }
  else {
    data.forEach((el, key) => {
      if (key === 0) {
        expression += `${el.operatorStart}${el.ques}${el.operatorEnd}`
      }
      else {
        if (el.operator === 'AND') {
          expression += `&${el.operatorStart}${el.ques}${el.operatorEnd}`
        }
        else {
          expression += `|${el.operatorStart}${el.ques}${el.operatorEnd}`
        }
      }
    })
  }
  return expression
}

export const returnCombineQuestionText = (selectedQuestion, filterData, responseInterval, responseValue, selectedQuestionData) => {
  let text
  let type
  if (selectedQuestion.qno) {
    type = filterData.questions.find(el => el.qno === selectedQuestion.qno).type
  } else {
    type = filterData.questions.find(el => el.qno === selectedQuestion.text).type
  }
  if (type === 'f') {
    if (responseInterval === null) {
      if (selectedQuestion.qno) {
        text = `${selectedQuestion.ques} ${(selectedQuestion.qno).slice(0, -2)} : ${selectedQuestion.text} = ${responseValue}`
      }
      else {
        text = `${selectedQuestion.ques} ${(selectedQuestion.text).slice(0, -2)} = ${responseValue}`
      }
    }
    else {
      if (selectedQuestion.qno) {
        text = `${selectedQuestion.ques} ${(selectedQuestion.qno).slice(0, -2)} : ${selectedQuestion.text} = ${responseValue} : ${responseInterval}`
      }
      else {
        text = `${selectedQuestion.ques} ${(selectedQuestion.text).slice(0, -2)} = ${responseValue} : ${responseInterval}`
      }
    }
  }
  else {
    const answers = []
    selectedQuestionData.forEach(el => {
      if (el.selected === true) {
        answers.push(el.texts[filterData.defaultLanguage])
      }
    })
    if (type === 'rn' || type === 'rm') {
      text = `${selectedQuestion.ques} ${selectedQuestion.text} = ${answers.join(' OR ')} `
    }
    else {
      text = `${selectedQuestion.ques} = ${answers.join(' OR ')} `
    }
  }
  return text
}

export const returnTableCases = (selectedQuestion, filterData, props) => {
  let responseCases = 0
  if (selectedQuestion.qno) {
    const qno = selectedQuestion.qno
    responseCases = filterData.questions.find(el => el.qno === qno).rows.find(row => row.texts[filterData.defaultLanguage] === selectedQuestion.text).cases[props.dataItem.code - 1]
  } else {
    responseCases = filterData.questions.find(el => el.qno === selectedQuestion.text).cases[props.dataItem.code - 1]
  }
  return responseCases
}

export const returnFilterDescription = (data) => {
  const desc = []
  let text
  data.forEach((el, key) => {
    if (key === 0) {
      if (el.ques.charAt(0) === '!') {
        text = `${el.operatorStart} NOT ${el.text}${el.operatorEnd}`
      }
      else {
        text = `${el.operatorStart}${el.text}${el.operatorEnd}`
      }
    }
    else {
      if (el.ques.charAt(0) === '!') {
        text = `${el.operator} ${el.operatorStart} NOT ${el.text}${el.operatorEnd}`
      }
      else {
        text = `${el.operator} ${el.operatorStart}${el.text}${el.operatorEnd}`
      }
    }
    desc.push(text)
  })
  return desc.join(' ')
}

export const returnAdvancedOptions = (questionType) => {
  let options = [{ id: 'asked', text: 'Asked' }, { id: 'notAsked', text: 'Not asked' }, { id: 'answered', text: 'Answered' }, { id: 'notAnswered', text: 'Not answered' }, { id: 'none', text: 'None of the selected' }, { id: 'all', text: 'All of the selected' }, { id: 'sync', text: 'Synchronous' }, { id: 'between', text: '' }]

  if (questionType === 'f' || questionType === 's' || questionType === 'l') {
    options = options.filter(el => el.id === 'asked' || el.id === 'notAsked' || el.id === 'answered' || el.id === 'notAnswered')

  }
  else if (questionType !== 'rn' && questionType !== 'rm') {
    options = options.filter(el => el.id !== 'between' && el.id !== 'all')
  }

  return options
}

export const returnAdvancedOptionExpression = (expression, advancedOption, advancedOptionBetween, selectedQuestionData) => {
  let advancedExpression = ''
  const list = returnSelectedResponsesList(selectedQuestionData)
  if (advancedOption.id === 'asked') {
    advancedExpression = `${expression};?`
  } else if (advancedOption.id === 'notAsked') {
    advancedExpression = `${expression};!?`
  } else if (advancedOption.id === 'answered') {
    advancedExpression = `${expression};*`
  } else if (advancedOption.id === 'notAnswered') {
    advancedExpression = `${expression};-`
  } else if (advancedOption.id === 'none') {
    advancedExpression = `${expression}#0`
  } else if (advancedOption.id === 'all') {
    advancedExpression = `${expression}#${advancedOptionBetween.all}`
  } else if (advancedOption.id === 'sync') {
    advancedExpression = list.length > 0 ? `${expression};%` : `${expression}%`
  } else if (advancedOption.id === 'between') {
    advancedExpression = `${expression}#${advancedOptionBetween.first}:${advancedOptionBetween.second}`
  }

  return advancedExpression
}

export const visualAdvancedHandler = (combineFilterData, manualFilterExpression) => {
  // If combineFilterData equals manualFilterExpression, it means definition can be viewed in Visual mode (and false is returned),
  // else it can be open only in Advanced mode (and true is returned)
  if (combineFilterData && manualFilterExpression) {
    if (returnCombineDataExpression(combineFilterData) === manualFilterExpression) {
      return "visual"
    }
    return "advanced"
  }
  if (combineFilterData && !manualFilterExpression) {
    return "clean"
  }
}

export const returnEvalFilterUrl = (analysisType, projectId, datasetId, workflowProjectId) => {
  switch (analysisType) {
    case "surveys":
      return `projects/${projectId}/analysis/surveys/${datasetId}/eval-filter`;
    case "workflowDataset":
      return `an/projects/${workflowProjectId}/analysis/${datasetId}/eval-filter`;
    case "workflowSurvey":
      return `projects/${workflowProjectId}/analysis/surveys/${datasetId}/eval-filter`;
    default:
      return `an/projects/${projectId}/analysis/${datasetId}/eval-filter`;
  }
}