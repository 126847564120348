import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import React from 'react'

function NewFolderModal({ setNewFolderModal, folderName, setFolderName, addFolder, setSelectedElementType }) {

  const closeDialog = () => {
    setNewFolderModal(false)
    setFolderName()
  };

  const onAddClick = () => {
    addFolder()
    setSelectedElementType()
  };

  return (
    <Dialog width="400px" height="auto" className="import-dialog library-sub-dialog no-wrap" title={`New folder`} onClose={closeDialog}>
      <div className="d-flex flex-row justify-content-center align-items-center p-4">
        <input placeholder="Enter folder name" className="form-control" value={folderName} onChange={(e) => setFolderName(e.target.value)} />
      </div>
      <DialogActionsBar><button className="k-button btn-secondary mr-2" onClick={closeDialog}>Cancel</button>
        <button onClick={onAddClick} className="k-button k-button-solid-primary text-white">Save</button>
      </DialogActionsBar>
    </Dialog>
  )
}

export default NewFolderModal