interface Props {
  errorMessage: string
  className?: string
}

export const AnswerLayoutValidationErrorMessage = ({ errorMessage, className }: Props) => {
  return (
    <div className="d-flex align-items-center h-100">
      <i title={errorMessage} className={`fas fa-s fa-exclamation-triangle text-danger ${className}`}></i>
    </div>
  )
}