import { useDispatch } from 'react-redux';

import { Avatar } from '../../../../shared/Avatar/Avatar';
import type { APIKey } from '../../../../../interfaces/adminInterfaces/adminInterfaces';
import { renameUserTypeRoles } from '../../../../shared/helpers/renameUserTypeRoles/renameUserTypeRoles';

interface Props {
  apiKey: APIKey;
}

export const APIKeyCard = ({ apiKey }: Props) => {
  const dispatch = useDispatch();

  return (
    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
      <div style={{ cursor: 'default' }} className="h-100 project-card card border-0">
        <div className="d-flex flex-column justify-content-between h-100">
          <div className="d-flex flex-column justify-content-between p-3 gap-md h-100">
            <div className="d-flex justify-content-between align-items-center gap-md">
              <div className="d-flex flex-column text-truncate">
                <p className="m-0 text-dark h6 strong text-truncate">{apiKey.firstName}</p>
                <span className="text-muted small text-truncate">{apiKey.createdUtc.substr(0, 10)}</span>
                <div>
                  <span className="badge badge-pill badge-light text-capitalize mt-2">{renameUserTypeRoles(apiKey.role)}</span>
                </div>
              </div>
              <Avatar
                color={"light"}
                rounded="large"
                class="avatar-sm m-0"
                icon={'far fa-user-lock text-primary'} />
            </div>
          </div>

          <div className="project-card-buttons d-flex border-top">
            <button onClick={() => dispatch({ type: 'UPDATE_SHOW_API_KEYS_MODAL', payload: { show: true, apiKey: apiKey } })} type="button" className="btn btn-block text-dark border-right">
              <i className="far fa-edit pr-2" />
              <span>Edit</span>
            </button>
            <button onClick={() => dispatch({ type: 'UPDATE_SHOW_REMOVE_API_KEYS_MODAL', payload: { show: true, apiKey: apiKey } })} type="button" className="btn btn-block text-dark m-0">
              <i className="far fa-trash-alt pr-2" />
              <span>Delete</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}