import { useEffect } from "react"
import { fetchGetJson as getSubAccountUsers } from "../../../services/services"

export const useFetchSubAccountUsers = (shouldFetchUsers: boolean, subAccountId: string, token: string, setData: (data: any) => void, setInviteError: (msg: string) => void, setShouldFetchUsers: (val: boolean) => void) => {
  useEffect(() => {
    if (shouldFetchUsers) {
      setShouldFetchUsers(false)
      getSubAccountUsers(`sub-accounts/${subAccountId}/users`, token)
        .then((res: any) => {
          if (res && Array.isArray(res)) {
            setData(res)
          } else {
            setInviteError('Something went wrong, please try again!')
          }
        })
    }
  }, [subAccountId, token, shouldFetchUsers, setData, setInviteError, setShouldFetchUsers])
}