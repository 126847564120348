import React, { type FC, useEffect } from 'react'
import { MultiSelect, type MultiSelectFilterChangeEvent } from '@progress/kendo-react-dropdowns'
import { filterBy } from "@progress/kendo-data-query";

import type { ExclusionsFormComponent, GKProjectToExclude } from '../../../../interfaces/gateKeeperInterfaces/gateKeeperInterfaces';

const EXC_STATUS: string[] = ["Complete", "ScreenOut", "QuotaOut", "BadQuality", "DropOff"];

export const ExclusionsForm: FC<ExclusionsFormComponent> = ({ onChangeHandler, excData, error, gkProjectsToExclude }: ExclusionsFormComponent) => {
  const [projectNames, setProjectNames] = React.useState<GKProjectToExclude[]>([]);

  useEffect(() => {
    setProjectNames(gkProjectsToExclude.map(item => item))
  }, [gkProjectsToExclude])

  const filterChange = (event: MultiSelectFilterChangeEvent) => {
    if (gkProjectsToExclude) {
      setProjectNames(filterBy(gkProjectsToExclude, event.filter));
    }
  }
  return (
    <div className="details-input d-flex flex-column p-2 border rounded m-3">
      <div className='row row-col-2 px-3'>
        <div className="details-input d-flex flex-column w-50 ">
          <label className='p-0 m-0 strong text-primary'>Exclusion status</label>
          <MultiSelect
            name='respondentStatuses'
            data={EXC_STATUS}
            onChange={(e) => onChangeHandler(e)}
            value={excData?.respondentStatuses}
            placeholder="Multi-select ..."
          />
          {error?.respondentStatuses &&
            <div className="text-danger">
              <small>This field is required.</small>
            </div>}
        </div>
        <div className="details-input d-flex flex-column w-50 ">
          <label className='p-0 m-0 strong text-primary'>Exclusion duration</label>
          <div className="input-group">
            <input
              className='form-control'
              type="number"
              placeholder='0'
              min={1}
              name='exclusionDurationDays'
              value={excData ? excData?.exclusionDurationDays : ""}
              onChange={(e) => onChangeHandler(e)} />
            <div className="input-group-append">
              <span aria-disabled="true" className="input-group-text py-1 time-span">days</span>
            </div>
          </div>
          {error?.exclusionDurationDays &&
            <div className="text-danger">
              <small>This field is required.</small>
            </div>}
        </div>
      </div>
      <div className="details-input d-flex flex-column ">
        <label className='p-0 m-0 strong text-primary'>Surveys to exclude</label>
        <MultiSelect
          data={projectNames}
          name='gatekeeperProjectIds'
          filterable={true}
          onFilterChange={filterChange}
          onChange={(e) => onChangeHandler(e)}
          value={excData ? excData.gatekeeperProjectIds || excData?.gatekeeperProjects : []}
          textField="name"
          dataItemKey="id"
          placeholder="Please select ..."
        />
        {error?.gatekeeperProjectIds &&
          <div className="text-danger">
            <small>This field is required.</small>
          </div>}
      </div>
    </div>
  )
}