import { Fragment } from 'react';

import type { SubAccount } from '../../../../../../interfaces/adminInterfaces/adminInterfaces';

interface Props {
  subAccount?: SubAccount | null
  handleClose: () => void
}

export const EditSubAccountModalActionBars = ({ subAccount, handleClose }: Props) => {
  return (
    <Fragment>
      <button
        type="button"
        className="k-button btn btn-secondary"
        onClick={handleClose}>Cancel
      </button>
      <button
        type="submit"
        className="k-button btn btn-primary">
        {subAccount ? 'Update' : 'Create'}
      </button>
    </Fragment>
  )
}