import React from 'react';

import { returnRecipientStatusBadge } from '../helpers';
import { Avatar } from '../../../../../shared/Avatar/Avatar';
import { DropdownButton } from '../../../../../shared/DropdownButton/DropdownButton';
import { formatDate } from '../../../../../shared/DateUtils/DateUtils';

export default ({ data, openItem, type, onDropdownItemClick }) => {
  return (
    <div className="d-flex flex-column overflow-auto audience-list">
      {
        data && data.length > 0 ?
          <table className="table table-hover cursor-pointer m-0">
            <thead>
              <tr>
                <th className='audience-desc'>Type</th>
                <th className='audience-desc'>Created by</th>
                <th className='audience-desc'>Date</th>
                <th className='audience-desc'>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                data?.map((dataItem, key) => (
                  <tr key={key} onClick={(e) => {
                    if (e.target.id !== "dropdown-button-content") {
                      openItem(dataItem.id, type)
                    }
                  }}
                  >
                    <td className="text-left" style={{ verticalAlign: 'middle' }}>
                      <div className="d-flex align-items-center">
                        <Avatar icon={dataItem.type === "email-campaign" ? "fas fa-envelope" : dataItem.type === "sms-campaign" ? "fas fa-comment-dots" : dataItem.type === "link-campaign" ? "fas fa-link" : "fas fa-address-book"}
                          class="avatar-lg" />
                        <div className="ml-1">
                          <h6 className="text-primary m-0 strong"> {dataItem.name} {dataItem.completes ? <div className="d-flex flex-column"><h5 className="">( {dataItem.completes} responses</h5>)</div> : null}</h6>
                          <span className='audience-desc'>{dataItem.description}</span>
                        </div>
                      </div>
                    </td>
                    <td className="text-left" style={{ verticalAlign: 'middle' }}>
                      <div className="d-flex align-items-center justify-content-start">
                        <span className='audience-author-date'>{dataItem.tag}{dataItem.createdByName}</span>
                      </div>
                    </td>
                    <td className="text-left" style={{ verticalAlign: 'middle' }}>
                      <div className="d-flex align-items-center justify-content-start">
                        <span className='audience-author-date'>{formatDate(dataItem.createdUtc, "DATETIME_MED")}</span>
                      </div>
                    </td>
                    <td className="text-left" style={{ verticalAlign: 'middle' }}>
                      <div className="d-flex align-items-center justify-content-start">
                        {dataItem.status && dataItem.type === 'recipient-list' ?
                          <h5 className="mb-0">
                            <span className={`badge ${returnRecipientStatusBadge(dataItem.status).color}`}>
                              {returnRecipientStatusBadge(dataItem.status).text.toString()}
                            </span>
                          </h5> :
                          <h5 className="mb-0">
                            <span className={`badge ${!dataItem.disabled ? "badge-success" : "badge-light"}`}>
                              {!dataItem.disabled ? "Active" : "Inactive"}
                            </span>
                          </h5>}
                      </div>
                    </td>
                    <td className="text-left" style={{ verticalAlign: 'middle' }}>
                      <div className="d-flex justify-content-end">
                        <DropdownButton
                          icon="more-horizontal"
                          className="px-1 btn-transparent"
                          hideChevron={true}
                          items={[{ text: 'Open' }, { text: 'Delete' }]}
                          onItemClick={(e) => onDropdownItemClick(e, dataItem, type)}
                        />
                      </div>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          :
          <h5 className="text-center text-disabled py-5 my-5">No records found</h5>
      }
    </div>
  )
}