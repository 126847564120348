import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import CampaignEditor from './CampaignEditor/CampaignEditor';
import CollectionGrid from './helpers/CollectionGrid/CollectionGrid';
import { ErrorMessage } from '../../../shared/ErrorMessage/ErrorMessage';
import RecipientListEditor from './RecipientListEditor/RecipientListEditor';
import { FullHeightSpinner } from '../../../shared/FullHeightSpinner/FullHeightSpinner';
import { CreateAudienceModal } from './helpers/CreateAudienceModal/CreateAudienceModal';
import { BaseDeleteModal } from "../../../shared/Modals/BaseDeleteModal/BaseDeleteModal";
import {
  fetchGetJson as getSurvey,
  fetchDeleteJson as deleteCampaigns,
  fetchDeleteJson as deleteRecipientList,
  fetchGetJsonOrEmptyArray as getSurveyCampaigns,
  fetchGetJsonOrEmptyArray as getSurveyRecipients,
} from '../../../../services/services';

export const SurveyCollectTabContent = ({ token }) => {
  const params = useParams();
  const [disableCampaign, setDisableCampaign] = useState(false)
  const [tabSelected, setTabSelected] = useState(0)
  const [campaigns, setCampaigns] = useState(null)
  const [recipientLists, setRecipientLists] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [editMode, setEditMode] = useState({ show: false, type: null, id: null, campaignType: null })
  const [deleteModal, setDeleteModal] = useState(null)
  const [showCreateAudienceModal, setShowCreateAudienceModal] = useState(false)

  const tabs = [{ text: "Invites", id: "campaigns" }, { text: "Respondents", id: "recipients" }]

  useEffect(() => {
    if (campaigns === null && recipientLists === null) {
      getSurveyCampaigns(`/projects/${params.name}/surveys/${params.survey}/campaigns`, token)
        .then(res => {
          if (res && res.error) {
            setErrorMessage(res.message ? res.message : res.error)
          } else if (res) {
            setCampaigns(res)
          }
        })
      getSurveyRecipients(`/projects/${params.name}/surveys/${params.survey}/recipients`, token)
        .then(res => {
          if (res && res.error) {
            setErrorMessage(res.message ? res.message : res.error)
          } else if (res) {
            setRecipientLists(res)
          }
        })
      getSurvey(`su/projects/${params.name}/surveys/${params.survey}`, token)
        .then(res => {
          if (res) {
            if (res.publishedVersions === null || res.publishedVersions.length === 0)
              setDisableCampaign(true)
          }
        })
    }
  }, [campaigns, recipientLists])

  const openItem = (id, type) => {
    if (type === "campaigns") {
      setEditMode({ show: true, type: "campaign", id: id, campaignType: null })
    } else if (type === 'recipients') {
      setEditMode({ show: true, type: "recipients", id: id, campaignType: null })
    }
  }

  const onDeleteCampaign = async (projectId, id) => {
    try {
      await deleteCampaigns(`projects/${projectId}/Campaigns/${id}`, token);
      setCampaigns((prevCampaigns) => prevCampaigns.filter((campaign) => campaign.id !== id));
      editMode.show && setEditMode({ show: false, type: null, id: null, campaignType: null })
      setDeleteModal(null)
    } catch { }
  };

  const onDeleteInEditMode = (dataItem) => {
    const { projectId, id, name } = dataItem;
    setDeleteModal({
      onSubmit: onDeleteCampaign.bind(null, projectId, id),
      onCancel: () => setDeleteModal(null),
      error: {},
      title: 'Delete audience',
      modalText: `Are you sure you want to delete audience "${name}"?`,
    });
  }

  const onDeleteRecipientList = async (projectId, surveyId, id) => {
    try {
      await deleteRecipientList(`projects/${projectId}/surveys/${surveyId}/recipients/${id}`, token);
      setRecipientLists((prevRecepinets) => prevRecepinets.filter((recepient) => recepient.id !== id));
      setDeleteModal(null)
    } catch { }
  };

  const onDropdownItemClick = async (e, dataItem, type) => {
    const { projectId, surveyId, id, name } = dataItem;
    if (e.item.text === 'Open') {
      openItem(id, type);
    } else if (e.item.text === 'Delete') {
      if (tabSelected === 0) {
        setDeleteModal({
          onSubmit: onDeleteCampaign.bind(null, projectId, id),
          onCancel: () => setDeleteModal(null),
          error: {},
          title: 'Delete audience',
          modalText: `Are you sure you want to delete audience "${name}"?`,
        });
      } else if (tabSelected === 1) {
        setDeleteModal({
          onSubmit: onDeleteRecipientList.bind(null, projectId, surveyId, id),
          onCancel: () => setDeleteModal(null),
          error: {},
          title: 'Delete respondent list?',
          modalText: `Are you sure you want to delete respondent list "${name}"?`,
        });
      }
    }
  };

  return (
    <div className="survey-collect survey-audience">
      {deleteModal && <BaseDeleteModal {...deleteModal} />}
      {
        errorMessage &&
        <ErrorMessage
          type="modal"
          errorMessage={errorMessage}
          onHide={() => setErrorMessage(null)} />
      }
      {
        showCreateAudienceModal &&
        <CreateAudienceModal
          disableCampaign={disableCampaign}
          handleClose={() => setShowCreateAudienceModal(false)}
          updateRecipientList={(res) => setEditMode({ show: true, type: "recipients", id: res.id })}
          updateCampaignList={(res) => setEditMode({ show: true, type: "campaign", id: res.id, campaignType: res.type })}
        />
      }
      {
        !editMode.show ?
          <div className="h-100 w-100 d-flex p-4" style={{ backgroundColor: "rgb(243, 244, 244)" }}>
            <div className="card-body d-flex flex-column h-100 p-0 bg-white align-items-center justify-content-center answer-layout">
              {campaigns === null && recipientLists === null ?
                <div className="h-100">
                  <FullHeightSpinner />
                </div>
                :
                <div className='d-flex flex-column h-100 w-100'>
                  <div className='d-flex survey-main overflow-hidden bg-white flex-column w-100 mb-5'>
                    <div className="d-flex justify-content-between align-items-center flex-row p-4 h-56 border-bottom">
                      <ul className="nav nav-pills content-tabs flex-grow-1">
                        {
                          tabs.map((tab, key) => (
                            <li
                              key={key}
                              type="button"
                              onClick={() => setTabSelected(key)}
                              className={`nav-link ${tabSelected === key && 'active'}`}>
                              <span>{tab.text}</span>
                            </li>
                          ))
                        }
                      </ul>
                      <button className="btn btn-primary h-32 d-flex align-items-center ml-2" onClick={() => setShowCreateAudienceModal(true)}>
                        <span>New audience</span>
                      </button>
                    </div>
                    <CollectionGrid
                      openItem={openItem}
                      type={tabs[tabSelected].id}
                      onDropdownItemClick={onDropdownItemClick}
                      data={tabs[tabSelected].id === 'campaigns' ? campaigns : recipientLists}
                    />
                  </div>
                </div>
              }
            </div>
          </div>
          :
          editMode.type === "campaign" ?
            <CampaignEditor
              onDeleteInEditMode={onDeleteInEditMode}
              closeCampaignEditor={() => {
                setEditMode({ show: false, type: null, id: null, campaignType: null });
                setCampaigns(null)
                setRecipientLists(null)
              }}
              id={editMode.id}
              token={token}
              projectId={params.name}
              surveyId={params.survey}
              type={campaigns.filter(el => el.id === editMode.id).length !== 0 ? campaigns.find(el => el.id === editMode.id).type : editMode.campaignType}
            />
            :
            editMode.type === "recipients" ?
              <RecipientListEditor
                closeRecipientListEditor={() => {
                  setEditMode({ show: false, type: null, id: null, campaignType: null });
                  setCampaigns(null)
                  setRecipientLists(null)
                  setTabSelected(1)
                }}
                id={editMode.id}
                token={token}
                projectId={params.name}
                surveyId={params.survey}
              />
              :
              campaigns === null || recipientLists === null ?
                <FullHeightSpinner />
                : null
      }
    </div >
  )
}