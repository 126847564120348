import { useSelector } from 'react-redux';

import type { RootState } from '../../store/reducers/rootReducer';
import FileManager from '../shared/FileManager/FileManager';

export const Files = () => {
  document.title = "Files – Walr";
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);

  return (
    <section className='main files-container'>
      <div className='d-flex flex-column w-100 files'>
        <div className="d-flex justify-content-center flex-column w-100 px-6 pt-5 pb-2">
          <h1 className="stronger h4">File library</h1>
        </div>
        <FileManager
          token={token}
          type="public"
          displayType="page"
        />
      </div>
    </section>
  )
}