import { returnMultigridVariablePropertyName } from "../../returnMultigridVariablePropertyName/returnMultigridVariablePropertyName"

export const returnUpdatedMultigridData = (result: any, newData: any) => {
  if (Array.isArray(result.sss.survey.record["miextsss:grid"])) {
    result.sss.survey.record["miextsss:grid"].forEach((ques, id) => {
      const valuesProperty = returnMultigridVariablePropertyName(ques)
      newData.push({
        "index": id,
        "multigrid": true,
        "type": ques['@type'] ? ques['@type'] : ques['@miextsss:type'] ? ques["@miextsss:type"] : "",
        "id": ques["@miextsss:qno"],
        "title": ques["miextsss:label"].text,
        "order": Number(ques["@miextsss:docorder"]),
        [valuesProperty]: Array.isArray(ques[valuesProperty]) ? ques[valuesProperty] : [ques[valuesProperty]],
        "selected": false,
        "checked": false,
        "@ident": ques["@ident"],
        "position": ques.position ? ques.position : "",
        "disabled": ques["@miextsss:excluded"] === "true" ? true : false
      })
    })
  } else {
    const ques = result.sss.survey.record["miextsss:grid"]
    const valuesProperty = returnMultigridVariablePropertyName(ques)
    const values = [];
    if (ques[valuesProperty]) {
      values.push(ques[valuesProperty])
    }

    newData.push({
      "index": 0,
      "multigrid": true,
      "type": ques['@type'] ? ques['@type'] : ques['@miextsss:type'] ? ques["@miextsss:type"] : "",
      "id": ques["@miextsss:qno"],
      "title": ques["miextsss:label"].text,
      "order": Number(ques["@miextsss:docorder"]),
      [valuesProperty]: Array.isArray(ques[valuesProperty]) ? ques[valuesProperty] : [ques[valuesProperty]],
      "selected": false,
      "checked": false,
      "@ident": ques["@ident"],
      "position": ques.position ? ques.position : "",
      "disabled": ques["@miextsss:excluded"] === "true" ? true : false
    })
  }
  return newData;
}