import { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';

import { formatDate } from '../../../../shared/DateUtils/DateUtils';
import type { RootState } from '../../../../../store/reducers/rootReducer';
import type { AdminUser } from '../../../../../interfaces/userInterface/userInterface';
import { FullHeightSpinner } from '../../../../shared/FullHeightSpinner/FullHeightSpinner';
import { renameUserTypeRoles } from '../../../../shared/helpers/renameUserTypeRoles/renameUserTypeRoles';
import { fetchGetJson as getInvitations, fetchDelete as deleteInvitation, fetchPost } from '../../../../../services/services';

type Invitation = {
  id: string;
  email: string;
  userRole: string;
  invitedById: string;
  invitedDateUtc: string;
  registrationLink: string;
  invitationSent: boolean;
}

type Props = {
  users: AdminUser[];
}

export const InvitedUsersTabContent = ({ users }: Props) => {
  const dispatch = useDispatch()
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { shouldUpdateInvitedUsers } = useSelector((theState: RootState) => theState.adminReducer);
  const [didMount, setDidMount] = useState(true)
  const [invitations, setInvitations] = useState<Invitation[] | null>(null)
  const [isResending, setIsResending] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  useEffect(() => {
    if (didMount || shouldUpdateInvitedUsers) {
      setDidMount(false)
      getInvitations('invitations', token)
        .then((res: TODO) => {
          dispatch({ type: 'UPDATE_INVITED_USERS', payload: false })
          if (!res.message && !res.error) {
            setInvitations(res);
          } else {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.message ? res.message : res.error } })
          }
        })
    }
  }, [didMount, shouldUpdateInvitedUsers, token, dispatch])

  const onDeleteInvitation = (invitation: Invitation) => {
    if (invitation.id) {
      setIsDeleting(true)
      deleteInvitation(`invitations/${invitation.id}`, token)
        .then((res: TODO) => {
          setIsDeleting(false)
          if (res && res.status === 200) {
            if (invitations !== null) {
              const updatedInvitations = [...invitations].filter(item => item.id !== invitation.id)
              setInvitations(updatedInvitations)
              dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The invitation has been deleted successfully.' } })
            }
          } else return res.json();
        })
        .then((res: TODO) => {
          if (res.error || res.message) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error || res.message } })
          }
        })
    }
  }

  const onResendInvite = (invitation: Invitation) => {
    if (invitation.id) {
      setIsResending(true)
      fetchPost(`invitations/${invitation.id}/resend`, token)
        .then((res: TODO) => {
          setIsResending(false)
          if (res && res.status === 200) {
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The invitation has been resent successfully.' } })
          } else return res.json();
        })
        .then((res: TODO) => {
          if (res.error || res.message) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error || res.message } })
          }
        })
    }
  }

  const itemClick = (action: string, dataItem: Invitation) => {
    switch (action) {
      case "Copy Link":
        navigator.clipboard.writeText(dataItem.registrationLink);
        dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The invitation link has been copied to clipboard.' } });
        break;
      case "Resend Invite":
        onResendInvite(dataItem)
        break;
      case "Delete":
        onDeleteInvitation(dataItem)
        break;
      default:
        break;
    }
  }

  const returnInvitedByName = (invitedById: string) => {
    const user = invitedById ? users.find(user => user.id === invitedById) : null;
    return user ? `${user.firstName} ${user.lastName}` : 'Unknown user';
  }

  return (
    <Fragment>
      {
        invitations !== null ?
          invitations.length > 0 ?
            <table className="table table-striped admin-table mt-3">
              <thead>
                <tr>
                  <th scope="col">Email</th>
                  <th scope="col">Invited by</th>
                  <th scope="col">Invited date</th>
                  <th className='text-center' scope="col">User type</th>
                  <th className='text-center' scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  invitations.map((invitation) => (
                    <tr key={invitation.id}>
                      <th scope='row'>{invitation.email}</th>
                      <td>{returnInvitedByName(invitation.invitedById)}</td>
                      <td>{formatDate(invitation.invitedDateUtc, "DATE_MED")}</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          <span className={`badge badge-pill badge-primary ${invitation.userRole !== 'regular' ? 'text-walr' : ''} text-capitalize`}>{renameUserTypeRoles(invitation.userRole)}</span>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          <Tooltip openDelay={100} position='auto' anchorElement={'target'}>
                            <button title="Copy invite link" onClick={() => itemClick('Copy Link', invitation)} type="button" className="btn text-dark px-2">
                              <i className="far fa-lg fa-link pe-none" />
                            </button>
                          </Tooltip>
                          <Tooltip openDelay={100} position='auto' anchorElement={'target'}>
                            <button disabled={isResending} title="Resend invite" onClick={() => itemClick('Resend Invite', invitation)} type="button" className="btn text-dark px-2">
                              {
                                isResending ?
                                  <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                  :
                                  <i className="far fa-lg fa-reply-all pe-none" />
                              }
                            </button>
                          </Tooltip>
                          <Tooltip openDelay={100} position='auto' anchorElement={'target'}>
                            <button disabled={isDeleting} title="Delete invite" onClick={() => itemClick('Delete', invitation)} type="button" className="btn text-dark px-2">
                              {
                                isDeleting ?
                                  <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                  :
                                  <i className="far fa-lg fa-trash pe-none" />
                              }
                            </button>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            :
            <div className="d-flex flex-column gap-lg text-disabled text-center h5 pt-10">
              <i className="fal fa-2x fa-folder-open" />
              <p className='m-0'>No invitations have been found</p>
            </div>
          :
          <div className="mt-10 pt-10 w-100">
            <FullHeightSpinner />
          </div>
      }
    </Fragment>
  )
}