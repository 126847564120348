const initialState = {
  theState: {
    data: [],
    result: [],
    searchValue: '',
  }
};

function analysisScriptingStateReducer(state = initialState.theState, action) {
  switch (action.type) {
    case 'SET_INITIAL_ANALYSIS_SCRIPTING_DATA':
      return {
        ...state,
        data: [],
        result: []
      }
    case 'LOAD_ANALYSIS_SCRIPTING_STATE':
      return {
        ...state,
        ...action.payload
      }
    case 'UPDATE_ANALYSIS_SCRIPTING_DATA':
      return {
        ...state,
        data: action.payload
      }
    case 'UPDATE_ANALYSIS_SCRIPTING_RESULT':
      return {
        ...state,
        result: action.payload
      }
    case 'UPDATE_ANALYSIS_SCRIPTING_SEARCH_VALUE':
      return {
        ...state,
        searchValue: action.payload
      }
    default:
      return { ...state };
  }
}

export default analysisScriptingStateReducer;