import React from 'react';
import { Avatar } from '../../../../../../shared/Avatar/Avatar';
import returnDisabledTab from '../../../shared/helpers/returnDisabledTab/returnDisabledTab';

export default ({ title, user, icon }) => {

	const isTabDisabled = returnDisabledTab(title, user)

	return (
		<span className={`d-flex align-items-center ${isTabDisabled ? 'disabled-tab' : null}`}>
			{icon ? <Avatar icon={icon} class='avatar-sm mr-3' color='primary'></Avatar> : null}
			<h6 className="mb-0">{title}</h6>
		</span>
	)
}