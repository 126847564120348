import { useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { RadioButton, Checkbox, NumericTextBox } from '@progress/kendo-react-inputs';

import { BaseDropDownList } from "../../../../../shared/Inputs";

interface Props {
  token: string
  handleClose: () => void
  runWorkflow: () => void
}

export const RunButtonModal = ({ handleClose, runWorkflow }: Props) => {
  const [date, setDate] = useState<Date | null>(null)
  const [scheduledTime, setScheduledTime] = useState(false)
  const [isRepeatChecked, setIsRepeatChecked] = useState<boolean>(false)
  const [repeatNumber, setRepeatNumber] = useState<number | null>(null)
  // const [selectedDropDownItem, setSelectedDropDownItem] = useState<string | null>(null)
  const dropDownData = ['hours', 'days', 'months']

  const onSendClick = () => {
    // if (scheduledTime) {
    //   if (date) {
    //     const utcDate = new Date(date)
    //     const body = {
    //       "year": utcDate.getUTCFullYear(),
    //       "month": utcDate.getUTCMonth() + 1,
    //       "day": utcDate.getUTCDate(),
    //       "hour": utcDate.getUTCHours(),
    //       "minute": utcDate.getUTCMinutes()
    //     }
    //     let repeat;
    //     if (isRepeatChecked && repeatNumber && selectedDropDownItem) {
    //       repeat = {
    //         "repeat": true,
    //         "repeatNumber": repeatNumber,
    //         "selectedDropDownItem": selectedDropDownItem
    //       }
    //     }
    //   }
    // } else {
    runWorkflow();
    // }
  }

  return (
    <Dialog title="Run workflow" onClose={handleClose} width="30%" style={{ top: '-130px' }}>
      <div className="p-3 d-flex flex-column">
        <div className="d-flex flex-column m-auto">
          <div className="d-flex  align-items-center mb-3">
            <RadioButton className="mr-3 mt-1"
              onChange={() => setScheduledTime(false)}
              checked={!scheduledTime}
            />
            <div className="d-flex flex-column">
              <span className="mb-1 strong">Now</span>
            </div>
          </div>
          <div className="d-flex align-items-center mb-3">
            <div className="d-flex flex-column justify-content-between">
              <div className="d-flex flex-column mb-3">
                <div className="d-flex flex-row">
                  <RadioButton className="mr-3 mt-1"
                    disabled
                    onChange={() => setScheduledTime(true)}
                    checked={scheduledTime}
                  />
                  <span className="mb-1 strong">Schedule time</span>
                </div>
                <DateTimePicker name="date"
                  format={{
                    skeleton: "yMMMdEHmSs"
                  }}
                  required
                  disabled={!scheduledTime}
                  onChange={(e) => setDate(e.value)} />
              </div>
              <div className="d-flex flex-column">
                <div className="d-flex flex-row">
                  <Checkbox disabled className="mr-3" onChange={e => setIsRepeatChecked(e.value)} value={isRepeatChecked} />
                  <span className="mb-1 strong">repeat every</span>
                </div>
                <NumericTextBox
                  className="mb-3"
                  disabled
                  min={1}
                  value={repeatNumber}
                  onChange={(event) => setRepeatNumber(event.value)}
                />
                {scheduledTime &&
                  <BaseDropDownList
                    className="mr-2"
                    data={dropDownData}
                  // onChange={e => setSelectedDropDownItem(e.target.value)}
                  />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialogActionsBar>
        <Button className="btn btn-secondary" onClick={handleClose}>Cancel</Button>
        <Button className="btn btn-primary d-flex" onClick={() => onSendClick()} disabled={scheduledTime && !date}>
          Send
        </Button>
      </DialogActionsBar>
    </Dialog>
  )
}