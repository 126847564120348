import { Button } from "@progress/kendo-react-buttons"

interface Props {
  step: string
  onNextStep: (type: string) => void
  onReviewAndSubmit: () => void
  targetInterviews: number | null
}

export const ActionButtons = ({ step, onNextStep, onReviewAndSubmit, targetInterviews }: Props) => {

  const onUpdateSpecification = (type: string) => {
    if (type === 'next' || type === 'back') {
      onNextStep(type)
    }
    else {
      onReviewAndSubmit()
    }
  }

  return (
    <div className="row no-gutters d-flex justify-content-between p-3">
      <div className="col-md-2">
        <Button className="btn btn-primary w-100" disabled={targetInterviews === 0 || targetInterviews === null} onClick={() => onUpdateSpecification('submit')}>Review + Submit</Button>
      </div>
      <div className="col-md-4">
        <Button className="btn btn-secondary w-50" disabled={step === 'General'} onClick={() => onUpdateSpecification('back')}>Back</Button>
        <Button className="btn btn-primary w-50" onClick={() => onUpdateSpecification('next')} disabled={targetInterviews === 0 || targetInterviews === null || step === 'Advanced'}>Next</Button>
      </div>
    </div>
  )
}