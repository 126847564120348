import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { fetchDelete as deleteSession } from '../../../../../../../services/services';

export default ({ onHide, projectId, datasetId, sessionId, token, allDocuments, setAllDocuments }) => {
  const dispatch = useDispatch()
  const { selectedSession } = useSelector(theState => (theState.breadcrumbStateReducer));

  const onDeleteSessionHandler = () => {
    if (datasetId && sessionId) {
      deleteSession(`projects/${projectId}/sessions/${datasetId}/${sessionId}`, token)
        .then(res => {
          if (res.status === 204) {
            const updatedAllDocuments = JSON.parse(JSON.stringify(allDocuments))
            updatedAllDocuments.forEach(document => {
              if (document.sessions && document.sessions.length > 0) {
                document.sessions.forEach(session => {
                  if (session.id === sessionId) {
                    const indexOfSession = document.sessions.indexOf(session)
                    document.sessions.splice(indexOfSession, 1)
                    setAllDocuments(updatedAllDocuments)
                    if (selectedSession.id === sessionId) {
                      dispatch({ type: 'UPDATE_SELECTED_SESSION', payload: { name: '', id: '', lastSaved: '' } })
                    }
                    onHide()
                  }
                })
              }
            })
          }
        })
    }
  }

  return (
    <Dialog minWidth={500} title={`Delete session`} onClose={onHide} className="import-dialog">
      <div className="text-center p-4">
        <p className="m-0">Are you sure you want to delete this session ?</p>
      </div>
      <DialogActionsBar>
        <button className="k-button k-button-solid-base" onClick={onHide}>No</button>
        <button className="k-button btn-danger" onClick={onDeleteSessionHandler}>Yes</button>
      </DialogActionsBar>
    </Dialog >
  )
}