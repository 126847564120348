import type { DashboardCards } from '../../../interfaces/backofficeInterfaces/backofficeInterfaces';

export const DashboardCard = ({ name, totalCount, dif }: DashboardCards) => {
  return (
    <div className="dashboard-card-wrapper dashboard-card">
      <p className="card-main-title">{name}</p>
      <div className="d-flex flex-column data-container">
        <div className="statistic-data-wrapper">
          <h1>{totalCount}</h1>
          <p className={`${dif === 0 ? "default" : dif < 0 ? "negative" : "positive"}`} >{dif}</p>
        </div>
        <p>In the last month</p>
      </div>
    </div>
  )
}