import React from 'react';
import Editor from "@monaco-editor/react";

export default ({ css, onCssChange }) => {
	return (
		<div className="h-100 overflow-hidden">
			{typeof css === 'string' ?
				<Editor
					defaultLanguage="css"
					defaultValue=""
					value={css}
					onChange={(value) => onCssChange(value)}
					theme={`vs-dark`}
					options={{
						minimap: { enabled: false, },
						lineNumbers: 'off',
						glyphMargin: false,
						folding: false,
						overviewRulerLanes: 0,
						hideCursorInOverviewRuler: true,
						overviewRulerBorder: false,
					}}
				/> : null}
		</div>
	)
}