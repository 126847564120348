export default (title, user) => {
  let disabled = false
  const analyzeTabs = ['Topline', 'Cross Table', 'Chart', 'Q Table', 'Dig', 'Data', 'Create new weight', 'Existing weights']
  if (!analyzeTabs.includes(title)) {
    disabled = false
  }
  else {
    if (user.plan === 'basic') {
      if (title === 'Topline') {
        disabled = false
      }
      else { disabled = true }
    }
    if (user.plan === 'essential_monthly' || user.plan === 'essential_yearly') {
      if (title !== 'Topline' && title !== 'Cross Table') {
        disabled = true
      }
      else { return false }
    }
    if (user.plan === 'professional_monthly' || user.plan === 'professional_yearly') {
      disabled = false
    }
    if (user.isSubAccount) {
      disabled = false
    }
  }
  return (
    disabled
  )
}
