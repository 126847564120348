import type React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from '../../shared/Icon/Icon';
import { Skeleton } from '../../shared/Skeleton/Skeleton';
import type { RootState } from '../../../store/reducers/rootReducer';
import { returnCountDataText, returnCountDataType } from '../dashboardHelpers/dashboardHelpers';
import type { DashboardCountItemType, RecentData } from '../../../interfaces/dashboardDataInterfaces/dashboardDataInterfaces';
import { fetchGetJson as getAccountItems } from '../../../services/services';

export const dashboardCountCards: DashboardCountItemType[] = ["project", "dataset", "survey", "file"];

export const DashboardCountData: React.FC = () => {
  const dispatch = useDispatch()
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { dashboardCountData, dashboardCountDataLoaded }: RecentData = useSelector((theState: TODO) => (theState.recentDataReducer))
  const [isLoading, setIsLoading] = useState<boolean>(dashboardCountDataLoaded === true ? false : true)
  const [error, setError] = useState(false)

  useEffect(() => {
    getAccountItems("sta/statistics/accountitems", token)
      .then((res: TODO) => {
        setIsLoading(false)
        if (res && !res.error && !res.message) {
          if (Array.isArray(res)) {
            dispatch({ type: 'SET_DASHBOARD_COUNT_DATA', payload: res })
          } else setError(true)
        } else setError(true)
      });
  }, [dispatch, token])

  return (
    <section className="d-flex info gap-xl flex-lg-row flex-column">
      {
        dashboardCountData.filter((el) => dashboardCountCards.includes(el.type))
          .map((el, key: number) => (
            <article key={key} className="stats w-25">
              {
                isLoading ?
                  <div className="d-flex flex-column gap-md">
                    <div className='d-flex justify-content-between align-items-center'>
                      <Skeleton style={{ width: '100px' }} />
                      <Skeleton shape="circle" style={{ width: '36px', height: '36px', top: '16px', right: '24px' }} />
                    </div>

                    <Skeleton style={{ width: '50px', height: '30px' }} />
                  </div>
                  :
                  <div className="d-flex flex-column gap-md">
                    <div className='d-flex justify-content-between align-items-center'>
                      <p className='m-0 text-capitalize'>{returnCountDataText(el.type)}</p>
                      <Icon type={returnCountDataType(el.type)} className='stats-icon' />
                    </div>

                    <p className='m-0 stats-count'>
                      {
                        error ?
                          <i className="fal fa-sm fa-exclamation-circle" />
                          :
                          el.count
                      }
                    </p>
                  </div>
              }
            </article>
          ))}
    </section>
  )
}