import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import type { RootState } from '../../../../../store/reducers/rootReducer';
import { ErrorMessage } from '../../../../shared/ErrorMessage/ErrorMessage';
import { fetchPatchJson } from '../../../../../services/services';

interface Props {
  customQuestion: TODO
  onHide: () => void
  updateData: () => void
}

export const PublishCustomQuestionModal = ({ customQuestion, onHide, updateData }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { showOverview } = useSelector((theState: RootState) => theState.surveyCustomQuestionsStateReducer)

  const publishText = customQuestion.published ? 'Unpublish' : 'Publish'

  const onSubmitHandler = (e: TODO) => {
    e.preventDefault()
    setIsLoading(true)
    fetchPatchJson(`su/customquestions/${customQuestion.id}`, token, { published: !customQuestion.published })
      .then((data: TODO) => data.json())
      .then((res: TODO) => {
        setIsLoading(false)
        if (res && !res.error && !res.message) {
          if (showOverview) {
            dispatch({ type: 'UPDATE_SHOW_OVERVIEW', payload: { show: true, customQuestion: res } })
          }
          updateData()
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: `The custom question has been ${publishText.toLocaleLowerCase()}ed successfuly.` } })
          onHide()
        } else {
          setErrorMessage(res.error ? res.error : res.message)
        }
      })
  }

  return (
    <Dialog title={`${publishText} custom question`} onClose={onHide}>
      <form onSubmit={onSubmitHandler}>
        <div className="p-4">
          <p className='m-0'>Are you sure you would like to {publishText.toLocaleLowerCase()} this custom question?</p>
          {
            errorMessage &&
            <div className="mt-3">
              <ErrorMessage
                errorMessage={errorMessage}
                type="alert"
              />
            </div>
          }
        </div>
        <DialogActionsBar>
          <button type="button" className="k-button k-button k-button-solid-base" onClick={onHide}>Close</button>
          <button disabled={isLoading} type="submit" className="k-button k-button k-button-solid-primary">
            {isLoading &&
              <div className="spinner-border spinner-border-sm mr-2" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            }
            <span>{publishText}</span>
          </button>
        </DialogActionsBar>
      </form >
    </Dialog>
  )
}