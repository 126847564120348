import { Tooltip } from '@progress/kendo-react-tooltip';

import type { User } from '../../../../../../interfaces/userInterface/userInterface';
import UpgradePlanTooltip from '../../../../../shared/UpgradePlanTooltip/UpgradePlanTooltip';

type Prop = {
  item: {
    icon: string
    action: string
    extension: string
    actionName: string
  }
}

interface Props {
  user: User;
  props: Prop;
  showExportModal?: TODO
  showWizardModal?: TODO
  tab: string;
  state: { firstColumn: unknown[] };
  reportState?: { questions: unknown[] };
  disabled?: boolean;
}

export const ExportActionItem = ({ user, props, showExportModal, showWizardModal, tab, state, reportState, disabled }: Props) => {
  if (props.item.action === "weight") {
    return (
      <div className="d-flex p-1" onClick={showWizardModal}>
        <span><i className={`${props.item.icon} mr-2 mr-3`} />{props.item.actionName}</span>
      </div>
    )
  }
  if (user.plan === 'basic' && !user.isSubAccount) {
    return (
      <Tooltip position="left" anchorElement="target" content={() => <UpgradePlanTooltip />}>
        <div className="p-1">
          <span title={user.plan}
            className="text-disabled">
            <i className={`${props.item.icon} mr-2 mr-3`} />
            {props.item.actionName}
          </span>
        </div>
      </Tooltip>
    )
  }
  if (user.plan !== 'professional_yearly' && user.plan !== 'professional_monthly' && !user.isSubAccount) {
    if (props.item.action === 'word' || props.item.action === 'ppt') {
      return (
        <Tooltip position="left" anchorElement="target" content={() => <UpgradePlanTooltip />}>
          <div className="p-1">
            <span title={user.plan || ""}
              className="text-disabled">
              <i className={`${props.item.icon} mr-2 mr-3`} />
              {props.item.actionName}
            </span>
          </div>
        </Tooltip>
      )
    }
    return (
      <div onClick={() => showExportModal()} className="p-1">
        <span><i className={`${props.item.icon} mr-2`} />{props.item.actionName}</span>
      </div>
    )
  }
  if (tab === 'g' && props.item.action === 'word') {
    return (
      <div className="dropdown-item pl-2 pr-4 py-0 cursor-pointer rounded-0">
        <span
          className="text-disabled">
          <i className={`${props.item.icon} m-1 mr-3`} />
          {props.item.actionName}
        </span>
      </div>
    )
  }
  if (tab === 'report' && typeof reportState?.questions?.length === 'number' && reportState?.questions?.length > 0 && disabled === false) {
    return (
      <div onClick={showExportModal} className="p-1">
        <span><i className={`${props.item.icon} mr-2`} />{props.item.actionName}</span>
      </div>
    )
  }
  if ((tab !== 'script' && state.firstColumn && state.firstColumn.length <= 0) || disabled) {
    return (
      <div className={"dropdown-item pl-2 pr-4 py-0 cursor-pointer rounded-0"}>
        <span className="text-disabled">
          <i className={`${props.item.icon} m-1 mr-3`} />
          {props.item.actionName}
        </span>
      </div>)
  }
  if (tab === "topline") {
    return (
      <div onClick={() => showExportModal(props)} className="dropdown-item pl-2 pr-4 py-0 cursor-pointer rounded-0">
        <span><i className={`${props.item.icon} m-1 mr-3`} />{props.item.actionName}</span>
      </div>
    )
  }
  return (
    <div onClick={() => showExportModal(props)} className="dropdown-item pl-2 pr-4 py-0 cursor-pointer rounded-0">
      <span><i className={`${props.item.icon} m-1 mr-3`} />{props.item.actionName}</span>
    </div>
  )
}