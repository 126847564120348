import React from 'react';
import { Switch } from '@progress/kendo-react-inputs';

export default ({ element, onChangeInputHandler, formData, functionalityType }) => {
  return (
    <tr>
      <td>{element.name}</td>
      <td>
        <Switch
          defaultChecked={false}
          onChange={(e) => onChangeInputHandler(e, 'functionalitySwitch', functionalityType, element.id)}
          checked={formData[functionalityType][Object.keys(formData[functionalityType]).find(formType => formType === element.id)]}
          size="small"
        />
      </td>
    </tr>
  )
}