import { v4 as uuid } from 'uuid';

import type { Screening } from "../../../../interfaces/auddineceAccessInterfaces/batchOverviewTypes"
import type { Question, Target } from "../types/audienceTargetingTypes"

export const updateSelectedTarget = (question: Question, screenings: Screening[] | undefined | null) => {
  if (screenings && question) {
    const targetingItem = screenings?.filter((item) => item.questionId === question.id)[0];

    const updatedTargets = targetingItem?.answers.map((item) => {
      return {
        id: uuid(),
        targetCompletes: 0,
        answers: [item]
      }
    }) as unknown as Target[]

    const newSelectedTarget = {
      name: targetingItem.name,
      text: targetingItem.text,
      localizedText: question.localizedText,
      questionId: targetingItem.questionId,
      targets: updatedTargets
    }

    return newSelectedTarget
  }

  return null
}