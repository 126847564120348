export default (tabSelected, tabs) => {
  const tabSelectedId = tabs[tabSelected].id
  if (tabSelectedId === 'Topline') {
    return {
      onDragStartAction: 'TOPLINE_ON_DRAG_START'
    }
  } else if (tabSelectedId === 'Crosstab') {
    return {
      onDragStartAction: 'ON_DRAG_START'
    }
  } else if (tabSelectedId === 'Qt') {
    return {
      onDragStartAction: 'QTABLE_ON_DRAG_START'
    }
  } else if (tabSelectedId === 'Data') {
    return {
      onDragStartAction: 'RAW_DATA_ON_DRAG_START'
    }
  } else if (tabSelectedId === 'Dig') {
    return {
      onDragStartAction: 'DIG_ON_DRAG_START',
      displayTableAction: 'DISPLAY_TABLE'
    }
  } else if (tabSelectedId === 'Chart') {
    return {
      onDragStartAction: 'CHART_ON_DRAG_START',
      displayTableAction: 'DISPLAY_TABLE'
    }
  } else if (tabSelectedId === 'Report') {
    return {
      onDragStartAction: 'REPORT_ON_DRAG_START',
      displayTableAction: 'DISPLAY_TABLE'
    }
  }
  else {
    return null
  }
}