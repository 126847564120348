import type { FileManagerState } from "../../../../interfaces/fileManagerInterfaces/fileManagerInterfaces"

export const filterExpandedFileManagerItems = (expandedFilter: { type: string, filter: string }, type: string, expandedState: FileManagerState) => {
  // Update filter direction
  let updatedFilter = null

  if (expandedFilter.type === type) {
    if (expandedFilter.filter === 'asc') {
      updatedFilter = 'desc'
    } else {
      updatedFilter = 'asc'
    }
  } else {
    updatedFilter = 'desc'
  }

  // Filter data
  const updatedExpandedState = JSON.parse(JSON.stringify(expandedState))

  if (type === 'date') {
    if (updatedFilter === 'asc') {
      updatedExpandedState.data[0].contents.sort(function compare(a: TODO, b: TODO) {
        const dateA = new Date(a.dateModified);
        const dateB = new Date(b.dateModified);
        return (dateA < dateB) ? -1 : (dateA > dateB) ? 1 : 0;
      });
    } else if (updatedFilter === 'desc') {
      updatedExpandedState.data[0].contents.sort(function compare(a: TODO, b: TODO) {
        const dateA = new Date(a.dateModified);
        const dateB = new Date(b.dateModified);
        return (dateA > dateB) ? -1 : (dateA < dateB) ? 1 : 0;
      });
    }
  } else if (type === 'document') {
    const newContents: TODO = []
    // if current directory only consists of files (sort them by extension name)
    if (updatedExpandedState.data[0].contents.filter((item: TODO) => !item.isDirectory).length === updatedExpandedState.data[0].contents.length) {
      if (updatedFilter === 'asc') {
        updatedExpandedState.data[0].contents.filter((item: TODO) => item.extension).sort((a: TODO, b: TODO) => {
          const textA = a.extension
          const textB = b.extension
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }).forEach((item: TODO) => newContents.push(item))
      } else if (updatedFilter === 'desc') {
        updatedExpandedState.data[0].contents.filter((item: TODO) => item.extension).sort((a: TODO, b: TODO) => {
          const textA = a.extension
          const textB = b.extension
          return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
        }).forEach((item: TODO) => newContents.push(item))
      }
    } else {
      if (updatedFilter === 'asc') {
        updatedExpandedState.data[0].contents.filter((item: TODO) => item.isDirectory).forEach((item: TODO) => newContents.push(item))
        updatedExpandedState.data[0].contents.filter((item: TODO) => item.extension).sort((a: TODO, b: TODO) => {
          const textA = a.extension
          const textB = b.extension
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }).forEach((item: TODO) => newContents.push(item))
      } else if (updatedFilter === 'desc') {
        updatedExpandedState.data[0].contents.filter((item: TODO) => item.extension).sort((a: TODO, b: TODO) => {
          const textA = a.extension
          const textB = b.extension
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }).forEach((item: TODO) => newContents.push(item))
        updatedExpandedState.data[0].contents.filter((item: TODO) => item.isDirectory).forEach((item: TODO) => newContents.push(item))
      }
    }
    updatedExpandedState.data[0].contents = newContents
  } else {
    const filterType = type === 'title' ? 'displayName' : 'size'
    if (updatedFilter === 'asc') {
      updatedExpandedState.data[0].contents.sort((a: TODO, b: TODO) => {
        const textA = filterType === 'displayName' ? a[filterType].toUpperCase() : a[filterType]
        const textB = filterType === 'displayName' ? b[filterType].toUpperCase() : b[filterType]
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    } else if (updatedFilter === 'desc') {
      updatedExpandedState.data[0].contents.sort((a: TODO, b: TODO) => {
        const textA = filterType === 'displayName' ? a[filterType].toUpperCase() : a[filterType]
        const textB = filterType === 'displayName' ? b[filterType].toUpperCase() : b[filterType]
        return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
      });
    }
  }

  return {
    updatedFilter: updatedFilter,
    updatedExpandedState: updatedExpandedState
  }
}