import React, { useEffect, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { useSelector } from 'react-redux';

import { ErrorMessage } from '../../../../../shared/ErrorMessage/ErrorMessage';

export default function AdvancedRotationModal({ handleClose, rows, saveAdvancedRotation }) {
  const { theData } = useSelector(theState => theState.surveyInitialDataReducer);
  const [updateRows, setUpdateRows] = useState(null)
  const [error, setError] = useState(false)
  const codes = [{ value: "*", descr: "Continue the same randomisation order. This code is only relevant when a randomisation is active and all * codes must appear first." }, { value: "N", descr: "The rows shall appear in the same sequence as defined in the question." }, { value: "R", descr: "Random. The rows shall appear in random order." }, { value: "S", descr: "Sequential. The rows shall appear sequentially, but the first box is drawn by random, wrapping around until all boxes are applied.", }, { value: "A", descr: "Alternate. The rows should appear in the question sequence or in reversed order, each of them having the same probability." }]

  useEffect(() => {
    if (updateRows === null) {
      const update = rows.filter((item) => item.type !== "rc").map((el) => ({
        id: el.id,
        rot: el.attributes?.rot ? el.attributes.rot : '',
        rotsync: el.attributes?.rotsync ? el.attributes.rotsync : 0,
        rowTexts: el.elements.map((element) => element.textValues)
      }))
      setUpdateRows(update)
    }
  }, [rows, updateRows])

  const handleChange = (value, key, type) => {
    const changeRows = updateRows
    changeRows[key][type] = value
    setUpdateRows(changeRows)
    setError(false)
  }

  const validateAdvancedRotation = () => {
    const errorList = []
    updateRows.forEach((row, key) => {
      const rot = row.rot.toLowerCase().split("")
      if (rot.filter(el => el !== "*" && el !== "a" && el !== "n" && el !== "r" && el !== "s").length > 0) {
        errorList.push(key)
      }
    })

    errorList.length > 0 ? setError(true) : saveAdvancedRotation(updateRows)
  }

  return (
    <Dialog title="Advanced row randomisation" width="50%" height="80%" onClose={handleClose}>
      <div className="d-flex flex-column gap-xl p-4 h-100 overflow-auto" >
        <div className="d-flex flex-column alert alert-survey shadow-sm m-0 gap-sm">
          <h6 className="h6 m-0">Randomisation codes</h6>
          {codes.map((el) => (
            <div className="d-flex align-items-center gap-lg" key={el.value}>
              <p className="strong m-0">{el.value}</p>
              <p className='m-0'>{el.descr}</p>
            </div>
          ))}
        </div>

        <div className="d-flex flex-column gap-md">
          <div className="d-flex align-items-center">
            <p className="strong m-0 w-50">Rows</p>
            <div className="d-flex align-items-center w-50 gap-md">
              <p className="strong m-0 w-50">Randomisation code</p>
              <p className="strong m-0 w-50">Synchronize</p>
            </div>
          </div>

          {updateRows?.map((row, key) => (
            <div key={row.id} className="d-flex align-items-center w-100 gap-md">
              <p className='w-50 m-0 line-clamp-1 advanced-rotation-modal-rows-text'>
                {row?.rowTexts?.map((rowText) => rowText[theData.editingLanguage]?.replace(/(<([^>]+)>)/gi, ' '))}
              </p>

              <div className="d-flex align-items-center w-50 gap-md">
                <Input
                  className="w-50"
                  defaultValue={row.rot}
                  onChange={(e) => handleChange(e.target.value, key, 'rot')}
                />

                <NumericTextBox
                  className="w-50"
                  onChange={(e) => handleChange(e.value, key, 'rotsync')}
                  defaultValue={row.rotsync}
                />
              </div>
            </div>
          ))
          }
          {error &&
            <ErrorMessage type="alert" errorMessage="Randomisation code should only contain code characters" />
          }
        </div>
      </div>
      <DialogActionsBar>
        <Button className="btn btn-secondary" onClick={handleClose}>Cancel</Button>
        <Button className="btn btn-primary" onClick={() => validateAdvancedRotation()}>Save</Button>
      </DialogActionsBar>
    </Dialog >
  )
}