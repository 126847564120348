import { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Icon } from '../../../../shared/Icon/Icon';
import type { RootState } from '../../../../../store/reducers/rootReducer';
import { DropdownButton } from '../../../../shared/DropdownButton/DropdownButton';
import { SurveyCustomQuestionsOverviewTabContentDropdown } from './SurveyCustomQuestionsOverviewTabContentDropdown';
import { SurveyCustomQuestionsOverviewTabs } from './SurveyCustomQuestionsOverviewTabs/SurveyCustomQuestionsOverviewTabs';
import { returnSupportedTypesValue } from '../../../../shared/helpers/returnSupportedTypesValue/returnSupportedTypesValue';
import { fetchPatchJson } from '../../../../../services/services';

import jsBeatify from 'js-beautify';

const beautify = jsBeatify.js;
const beautifyCSS = jsBeatify.css;

interface Props {
  tags: string[]
}

interface Body {
  js: string
  css: string
  name: string
  icon: string
  iconColor: string
  description: string
  published: boolean
  isInternal: boolean
  tags: string[]
  jsUrls: string[]
  cssUrls: string[]
  supportedTypes: string[]
  props: { [key: string]: TODO } | TODO[]
  defaultElement: { [key: string]: TODO }
  customerAccess: { name: string, id: string }[] | null
  dataCollectionVersion: 1 | 2
}

interface FormData {
  name: string
  description: string
  tags: string[]
  icon: string
  iconColor: string
  published: boolean
  isInternal: boolean
  customerAccess: { name: string, id: string }[] | null
  supportedTypes: { text: string, id: string }[]
  dataCollectionVersion: 1 | 2
}

const tabs = ['Overview', 'Javascript', 'CSS', 'Properties', 'Default']

export const SurveyCustomQuestionsOverviewTabContent = ({ tags }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const { editorValues, showOverview } = useSelector((theState: RootState) => theState.surveyCustomQuestionsStateReducer)
  const [selectedTab, setSelectedTab] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState<FormData>({ name: '', description: '', tags: [], icon: '', iconColor: '', published: false, isInternal: false, customerAccess: [], supportedTypes: [], dataCollectionVersion: 1 })

  useEffect(() => {
    if (showOverview.customQuestion) {
      setFormData({
        name: showOverview.customQuestion.name,
        description: showOverview.customQuestion.description ? showOverview.customQuestion.description : '',
        tags: showOverview.customQuestion.tags ? showOverview.customQuestion.tags : [],
        icon: showOverview.customQuestion.icon ? showOverview.customQuestion.icon : 'ic-carousel',
        iconColor: showOverview.customQuestion.iconColor ? showOverview.customQuestion.iconColor : '',
        published: showOverview.customQuestion.published ? showOverview.customQuestion.published : false,
        isInternal: showOverview.customQuestion.isInternal ? showOverview.customQuestion.isInternal : false,
        customerAccess: showOverview.customQuestion.customerAccess ? showOverview.customQuestion.customerAccess : [],
        supportedTypes: showOverview.customQuestion.supportedTypes ? returnSupportedTypesValue(showOverview.customQuestion.supportedTypes) : [],
        dataCollectionVersion: showOverview.customQuestion.dataCollectionVersion ? showOverview.customQuestion.dataCollectionVersion : 1
      })
    }
  }, [showOverview.customQuestion])

  useEffect(() => {
    dispatch({
      type: 'UPDATE_EDITOR_VALUES', payload: {
        ...editorValues,
        js: beautify(showOverview.customQuestion.js, { indent_size: 4, space_in_empty_paren: true }),
        css: beautifyCSS(showOverview.customQuestion.css, { indent_size: 4 }),
        references: {
          js: showOverview.customQuestion.jsUrls,
          css: showOverview.customQuestion.cssUrls
        },
        tags: JSON.stringify(showOverview.customQuestion.tags, null, 4),
        defaultElement: JSON.stringify(showOverview.customQuestion.defaultElement, null, 4),
        questionSettings: JSON.stringify(showOverview.customQuestion.props, null, 4),
      }
    })
  }, [])

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const isValidJson = (jsonString: string) => {
    try {
      JSON.parse(jsonString)
      return true
    } catch (_error) {
      return false
    }
  }

  const onSaveHandler = async () => {
    if (!isValidJson(editorValues.questionSettings)) {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'The properties value contains invalid JSON.' } })
    } else if (!isValidJson(editorValues.defaultElement)) {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'The default element value contains invalid JSON.' } })
    } else {
      setIsLoading(true)
      const body: Body = {
        name: formData.name,
        description: formData.description,
        tags: formData.tags,
        icon: formData.icon,
        iconColor: formData.iconColor,
        published: formData.published,
        isInternal: formData.isInternal,
        customerAccess: formData.customerAccess,
        supportedTypes: Array.isArray(formData.supportedTypes) && formData.supportedTypes?.length > 0 ? formData.supportedTypes?.map((item) => item.id) : ['*'],
        js: editorValues.js,
        css: editorValues.css,
        props: JSON.parse(editorValues.questionSettings),
        defaultElement: JSON.parse(editorValues.defaultElement),
        jsUrls: editorValues.references.js,
        cssUrls: editorValues.references.css,
        dataCollectionVersion: formData.dataCollectionVersion
      }

      fetchPatchJson(`su/customquestions/${showOverview.customQuestion.id}`, token, body)
        .then((res: TODO) => res.json())
        .then((res: TODO) => {
          setIsLoading(false)
          if (res && !res.error && !res.message) {
            dispatch({ type: 'UPDATE_SHOW_OVERVIEW', payload: { show: true, customQuestion: res } })
            setFormData({ ...formData, published: res.published, isInternal: res.isInternal, customerAccess: res.customerAccess, supportedTypes: returnSupportedTypesValue(res.supportedTypes), tags: res.tags, icon: res.icon, iconColor: res.iconColor, name: res.name, description: res.description })
            dispatch({ type: 'UPDATE_EDITOR_VALUES', payload: { ...editorValues, js: beautify(res.js, { indent_size: 4, space_in_empty_paren: true }), css: beautifyCSS(res.css, { indent_size: 4 }), references: { js: res.jsUrls, css: res.cssUrls }, tags: JSON.stringify(res.tags, null, 4), defaultElement: JSON.stringify(res.defaultElement, null, 4), questionSettings: JSON.stringify(res.props, null, 4) } })
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The custom question has been updated successfuly.' } })
          } else {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
          }
        })
    }
  }

  const onBackToCustomQuestionsHandler = () => {
    dispatch({ type: "UPDATE_CUSTOM_QUESTIONS_DATA", payload: true })
    dispatch({ type: 'UPDATE_SHOW_OVERVIEW', payload: { show: false, customQuestion: null } })
  };

  const moreDropdownItemClick = (action: string, customQues: TODO) => {
    if (action === 'Delete') {
      dispatch({ type: 'UPDATE_SHOW_DELETE_CUSTOM_QUESTION_MODAL', payload: { show: true, customQuestion: customQues } })
    } else if (action === 'Duplicate') {
      dispatch({ type: 'UPDATE_SHOW_CLONE_CUSTOM_QUESTION_MODAL', payload: { show: true, customQuestion: customQues } })
    } else if (action === 'Publish' || action === 'Unpublish') {
      dispatch({ type: 'UPDATE_SHOW_PUBLISH_CUSTOM_QUESTION_MODAL', payload: { show: true, customQuestion: customQues } })
    }
  }

  const customQuestionRenderMoreDropdownItem = ({ text, disabled, icon }: { text: string, disabled: boolean, icon: "help-external" | "copy" }, customQues: { name: string }) => {
    if (text === 'Delete') {
      return (
        <Fragment>
          <div
            style={{ height: '1px' }}
            className="w-100 my-1 bg-light" />
          <button
            type="button"
            className="dropdown-item text-danger px-3"
            disabled={disabled}
            onClick={() => moreDropdownItemClick(text, customQues)}>
            <span>{text}</span>
          </button>
        </Fragment>
      )
    }
    if (text === 'Header') {
      return (
        <div style={{ cursor: 'default' }} id='header-item' className='d-flex flex-column'>
          <strong className='px-4 pb-2 pt-1 medium strong'>{customQues.name}</strong>
          <div
            style={{ height: '1px' }}
            className="w-100 my-1 bg-light"
          />
        </div>
      )
    }
    return (
      <button
        type="button"
        className="dropdown-item px-3"
        disabled={disabled}
        onClick={() => moreDropdownItemClick(text, customQues)}>
        <Icon type={icon} className='mr-2' />
        <span>{text}</span>
      </button>
    )
  }

  return (
    <div style={{ backgroundColor: '#f3f4f4' }} className='custom-questions-container w-100 h-100 flex-grow-1 p-4 '>
      <div className="d-flex flex-column w-100 h-100 overflow-hidden bg-white answer-layout">
        <div className="d-flex justify-content-between p-4">
          <div className="d-flex align-items-center">
            <button type='button' onClick={onBackToCustomQuestionsHandler} className="btn btn-shadow p-0 mr-3">
              <Icon type="chevron-left" />
            </button>
            <h5 className='mr-3 mb-0 stronger'>{showOverview.customQuestion.name}</h5>
            <span className={`badge d-flex align-items-center h-24 ${showOverview.customQuestion.published ? 'badge-success-light' : 'badge-light'}`}>{showOverview.customQuestion.published ? 'Published' : 'Draft'}</span>
          </div>
          <div className="d-flex align-items-center justify-content-end">
            <DropdownButton
              text='More'
              className='btn-secondary mr-2 icon-r'
              renderItem={(data) => customQuestionRenderMoreDropdownItem(data, showOverview.customQuestion)}
              items={[{ text: 'Header' }, { text: showOverview.customQuestion.published ? 'Unpublish' : 'Publish', icon: 'help-external' }, { text: 'Duplicate', icon: 'copy' }, { text: 'Delete' }]}
            />
            <button type='button' disabled={isLoading} onClick={onSaveHandler} className="btn btn-primary">
              {isLoading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" />}
              <span>Save changes</span>
            </button>
          </div>
        </div>

        <div>
          <ul className="nav nav-pills content-tabs tabs-48 flex-grow-1 w-100 border-bottom border-top">
            {
              tabs.map((tab, key) => (
                <li onClick={() => setSelectedTab(key)} key={key} className={`nav-item d-flex align-items-center ${key === 0 ? 'ml-3' : ''}`}>
                  <span style={{ display: "inline-block" }} className={`nav-link text-left w-100 ${key === selectedTab && 'active'}`}>{tab}</span>
                </li>
              ))
            }
          </ul>
        </div>

        <div style={{ flex: '1 1 auto', height: '0px' }} className="row no-gutters overflow-auto">
          <div className="col-md-6 col-sm-6 p-5">
            <SurveyCustomQuestionsOverviewTabs
              tags={tags}
              formData={formData}
              onChangeHandler={onChangeHandler}
              selectedTab={selectedTab}
            />
          </div>

          <div className="col-md-6 col-sm-6">
            <SurveyCustomQuestionsOverviewTabContentDropdown />
          </div>
        </div>
      </div>
    </div>
  )
}