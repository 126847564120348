import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';

import { parseJwt } from '../../../shared/helpers/decodeJWT/decodeJWT';
import backofficeUserDropdownData from './backofficeUserDropdownData.json';
import { DropdownButton } from '../../../shared/DropdownButton/DropdownButton';
import { renameUserTypeRoles } from '../../../shared/helpers/renameUserTypeRoles/renameUserTypeRoles';
import { BackofficeEditUserModal } from './BackofficeEditUserModal/BackofficeEditUserModal';
import { DeleteUserConfirmationModal } from '../DeleteUserConfirmationModal/DeleteUserConfirmationModal';
import type { IEditUserModalState, IPropsUserList } from '../../../../interfaces/backofficeInterfaces/backofficeInterfaces';
import type { RootState } from '../../../../store/reducers/rootReducer';
import { rsAppUrl } from '../../../../services/services';

export const BackofficeUserList = ({ customerID, editOrAddModalOpen, closeMainModal, setEditOrAddModalOpen }: IPropsUserList) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const parsedToken = parseJwt(token)
  const canImpersonateUser = parsedToken[`${rsAppUrl}/supportagent`]
  const isBackofficeAdmin = parsedToken[`${import.meta.env.VITE_APP_AUTH0_NAMESPACE}/backofficeadmin`]
  const isBackofficeOwner = parsedToken[`${import.meta.env.VITE_APP_AUTH0_NAMESPACE}/backofficeowner`]

  const [editUserModalState, setEditUserModalState] = useState<IEditUserModalState>({
    showModal: false,
    userObject: {
      firstName: "",
      lastName: "",
      id: "",
      email: ""
    },
    country: editOrAddModalOpen.body.country,
    customerID: editOrAddModalOpen.body.customerID
  });
  const [showDeleteUserConfirmationModal, setShowDeleteUserConfirmationModal] = useState(false);

  const returnUpdatedDropdownItems = (userId: string) => {
    const updatedButtonItems: { text: string, disabled?: boolean, disabledMsg: string }[] = JSON.parse(JSON.stringify(backofficeUserDropdownData))

    if (localStorage.getItem('ImpersonateUID') && userId === localStorage.getItem('ImpersonateUID')) {
      updatedButtonItems[1].text = "Log out"
    } else {
      if (!canImpersonateUser) {
        updatedButtonItems[1].disabled = true
      }
    }
    if (!isBackofficeAdmin && !isBackofficeOwner) {
      updatedButtonItems[0].disabled = true
    }
    if (!isBackofficeOwner) {
      updatedButtonItems[2].disabled = true
    }

    return updatedButtonItems
  }

  const onClickActionButton = (e: TODO, user: TODO) => {
    if (e.item.text === "Delete user") {
      setEditUserModalState({ ...editUserModalState, showModal: false, userObject: user })
      setShowDeleteUserConfirmationModal(true)
    } else if (e.item.text === "Edit user") {
      setEditUserModalState({ ...editUserModalState, showModal: true, userObject: user })
    } else if (e.item.text === "Log in as user") {
      localStorage.setItem('ImpersonateCID', customerID)
      localStorage.setItem('ImpersonateUID', user.id)
      localStorage.setItem('ImpersonateName', `${user.firstName} ${user.lastName}`)
      window.location.replace(window.location.origin)
    } else if (e.item.text === 'Log out') {
      localStorage.removeItem('ImpersonateCID')
      localStorage.removeItem('ImpersonateUID')
      localStorage.removeItem('ImpersonateName')
      window.location.replace(window.location.origin)
    }
  }

  const closeModal = () => {
    setEditUserModalState({ ...editUserModalState, showModal: false })
  }

  const showDeleteUserConfirmationModalHandler = () => {
    setShowDeleteUserConfirmationModal(false)
  }

  const renderItem = (item: TODO, user: TODO) => {
    return (
      <React.Fragment>
        {
          item.text === 'Delete user' &&
          <div style={{ height: '1px' }} className="w-100 my-1 bg-light" />
        }
        <Tooltip openDelay={100} position='left' anchorElement={'target'}>
          <span title={item.disabled ? item.disabledMsg : ""}>
            <button
              type="button"
              className={`btn dropdown-item px-4 py-1 ${item.disabled ? 'pe-none' : ''} ${item.text === 'Delete user' ? 'text-danger' : ''}`}
              disabled={item.disabled}
              onClick={() => onClickActionButton({ item: item }, user)}>
              <span>{item.text}</span>
            </button>
          </span>
        </Tooltip>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {editUserModalState.showModal &&
        <BackofficeEditUserModal
          closeModal={closeModal}
          closeMainModal={closeMainModal}
          editUserModalState={editUserModalState}
          showDeleteUserConfirmationModal={showDeleteUserConfirmationModal}
          setShowDeleteUserConfirmationModal={setShowDeleteUserConfirmationModal}
          showDeleteUserConfirmationModalHandler={showDeleteUserConfirmationModalHandler}
        />}
      {showDeleteUserConfirmationModal &&
        <DeleteUserConfirmationModal
          handleClose={showDeleteUserConfirmationModalHandler}
          editOrAddModalOpen={editOrAddModalOpen}
          setEditOrAddModalOpen={setEditOrAddModalOpen}
          editUserModalState={editUserModalState}
          isBackofficeOwner={isBackofficeOwner} />
      }
      <table className="table border">
        <thead>
          <tr>
            <th>Name</th>
            <th>User Type</th>
            <th>Email</th>
            {
              (canImpersonateUser || localStorage.getItem('ImpersonateUID')) &&
              <th>Actions</th>
            }
          </tr>
        </thead>
        <tbody>
          {editOrAddModalOpen.body?.users.map((user: { id: string, firstName: string, lastName: string, role: string, email: string }) => {
            return (
              <tr style={{ cursor: "pointer" }} key={user.id}>
                <td style={{ verticalAlign: "middle" }} className={"text-left w-50"}>
                  {user.firstName} {user.lastName}
                </td>
                <td style={{ verticalAlign: "middle" }} className={`text-left ${returnUpdatedDropdownItems(user.id).length > 0 ? 'w-25' : 'w-50'}`}>
                  <span className="text-capitalize p-1">{renameUserTypeRoles(user.role)}</span>
                </td>
                <td style={{ verticalAlign: "middle" }} className={"text-left w-50"}>
                  {user.email}
                </td>
                <td>
                  <DropdownButton
                    hideChevron={true}
                    icon="more-horizontal"
                    renderItem={(e) => renderItem(e, user)}
                    items={returnUpdatedDropdownItems(user.id)}
                    onItemClick={(e) => onClickActionButton(e, user)} className="btn btn-transparent shadow-none px-1 mr-1"
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </React.Fragment>
  )
}