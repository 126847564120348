import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';

import type { RootState } from '../../../store/reducers/rootReducer';
import { ManageTarget } from './ManageTarget/ManageTarget';
import { TagretingQuota } from './Quota/Quota'
import { TagretingScreening } from './Screening/Screening';
import { QuotaModal } from './Quota/Modal/QuotaModal';
import { CustomTargetingModal } from './Screening/CustomTargetingModal/CustomTargetingModal';

export const BatchTargeting = () => {
  const [showCustomAudienceModal, setShowCustomAudienceModal] = useState<{ show: boolean, editMode: boolean }>({ show: false, editMode: false })
  const [showQuotaModal, setShowQuotaModal] = useState(false);

  const batchData = useSelector((state: RootState) => state.batchDataReducer);

  return (
    <Fragment>
      {showCustomAudienceModal.show &&
        <CustomTargetingModal
          customAudienceModalValues={batchData}
          handleModalVisibility={setShowCustomAudienceModal}
          editMode={showCustomAudienceModal.editMode}
        />
      }

      {
        showQuotaModal && <QuotaModal onHide={setShowQuotaModal} />
      }

      <div className='board-container d-flex justify-content-center flex-column w-100 gap-md'>
        {
          batchData?.targeting?.screenings ?
            <Fragment>
              <TagretingScreening setShowCustomAudienceModal={setShowCustomAudienceModal} />
              <TagretingQuota setShowQuotaModal={setShowQuotaModal} />
            </Fragment>
            :
            <ManageTarget setShowCustomAudienceModal={setShowCustomAudienceModal} />
        }
      </div>
    </Fragment>
  )
}