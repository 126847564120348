import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import type { RootState } from '../../../../../../../../../../../store/reducers/rootReducer';
import { ErrorMessage } from '../../../../../../../../../../shared/ErrorMessage/ErrorMessage';
import { returnValidJson } from '../../../../../../../helpers/returnValidJson/returnValidJson';
import { populateCodeMapData } from '../../../../../../../helpers/populateCodeMapData/populateCodeMapData';
import type { SetAnswerSorting, SetCategorySorting } from '../../../../../../../../../../../interfaces/workflowInterfaces/toolInterfaces';
import { fetchPatchJson } from '../../../../../../../../../../../services/services';

interface Code {
  code: number
  type: "Created" | "Existing"
}

interface Props {
  fetchData: {
    toolId: string
    projectId: string
    codeMapId: string
    workflowId: string
  }
  codeFrameJson: string
  conflictCodes: Code[]
  onHide: () => void
  onHideParent: () => void
  setCategorySorting: SetCategorySorting
  setAnswerSorting: SetAnswerSorting
}

const proceedMethods = [
  { mode: 1, text: "Reassign the existing codes" },
  { mode: 4, text: "Reassign the new codes" },
  { mode: 2, text: "Overwrite duplicated codes (Overwrite definitions)" },
  { mode: 3, text: "Overwrite duplicated target codes (Append definitions)" },
  { mode: 5, text: "Ignore the duplicated codes" }
]

export const ConflictingCodesModal = ({ onHide, onHideParent, setCategorySorting, setAnswerSorting, conflictCodes, fetchData, codeFrameJson }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch();
  const { projectId, workflowId, toolId, codeMapId } = fetchData;
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState(proceedMethods[0].mode);

  const onImportHandler = () => {
    const validCodeFrameJson = returnValidJson(codeFrameJson);
    if (validCodeFrameJson) {
      setIsLoading(true);
      fetchPatchJson(`projects/${projectId}/workflows/${workflowId}/tools/${toolId}/code-maps/${codeMapId}/codeframe-exchange?mode=${selectedMethod}`, token, JSON.parse(codeFrameJson))
        .then((res: TODO) => res.json())
        .then((resData: TODO) => {
          setIsLoading(false);
          if (resData?.error || resData?.message) {
            setErrorMessage(resData.message || resData.error);
          } else {
            populateCodeMapData(resData, setCategorySorting, setAnswerSorting, dispatch);
            onHide();
            onHideParent();
          }
        })
    } else {
      setErrorMessage('Please enter a valid JSON code frame to proceed')
    }
  }

  return (
    <Dialog width={600} title="Conflicting codes" onClose={onHide}>
      <p className='mb-2'>The following codes are already part of the codeframe:</p>
      <div className="d-flex flex-wrap gap-sm mb-5">
        {
          conflictCodes.map((code: Code, key: number) => {
            return (
              <span
                key={key}
                className="rounded border border-secondary m-0 py-1 px-3">
                <span>{code.code}</span>
              </span>
            )
          })
        }
      </div>
      <div className="d-flex flex-column gap-md">
        {
          proceedMethods.map((method) => {
            return (
              <div
                role='button'
                key={method.mode}
                style={{ outline: 'none' }}
                onClick={() => setSelectedMethod(method.mode)}
                className={`list-group-item-action border rounded py-2 px-4 ${selectedMethod === method.mode ? 'bg-primary text-white border-primary' : 'shadow-sm border-secondary cursor-pointer'}`}>
                <div style={{ gap: '4rem' }} className="d-flex align-items-center justify-content-between">
                  <span>{method.text}</span>
                  <i className={`fas fa-check ${selectedMethod !== method.mode ? 'invisible' : ''}`} />
                </div>
              </div>
            )
          })
        }
      </div>
      {
        errorMessage &&
        <div className='mt-3'>
          <ErrorMessage type="alert" errorMessage={errorMessage} />
        </div>
      }
      <DialogActionsBar>
        <button type='button' className="btn btn-secondary mr-2" onClick={onHide}>Cancel</button>
        <button type='button' className="btn btn-primary" disabled={isLoading} onClick={onImportHandler}>
          {
            isLoading &&
            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" />
          }
          <span>Proceed</span>
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}