import React from 'react';
import { DropDownButton } from '@progress/kendo-react-buttons';
import { EditorUtils } from '@progress/kendo-react-editor';

export const dropDownItemRender = (props) => {
  return (
    <div onClick={(e) => props.item.itemDisabled ? e.stopPropagation() : null} >
      <div data-toggle="tooltip" data-placement="top" title={`${props.item.itemDisabled === true ? "Publish survey in order to access campaign options" : ''}`} className={`d-flex align-items-center ${props.item.itemDisabled ? `text-secondary` : ''}  `}>
        <i className={`fa-xs pr-3 ${props.item.icon}`}></i>
        <span>{props.item.text}</span>
      </div>
    </div>
  )
}

export const templateItemRender = (props, onTemplateChange) => {
  if (props.item.id === "create") {
    return (
      <div className="d-flex align-items-center px-3 py-1" >
        <i className="fas fa-plus mr-3"></i>
        <span>{props.item.text}</span>
      </div>
    )
  } else if (props.item.id === 'title') {
    return (
      <div className="d-flex border-bottom p-1">
        <span>{props.item.text}</span>
      </div>
    )
  } else return (
    <div className="d-flex px-3 py-1" onClick={() => onTemplateChange(props.item.id)}>
      <span>{props.item.text}</span>
    </div>
  )
}

export const createInsertTextTool = settings =>
  props => {
    const { view } = props;
    const nodeType = view && view.state.schema.nodes[settings.nodeType];
    const canInsert = view && EditorUtils.canInsert(view.state, nodeType);
    const items = [{ text: "Survey link", id: "surveyLink" }, { text: "Opt-out link", id: "optOutLink" }]
    return (
      <DropDownButton
        onItemClick={(props) => EditorUtils.insertNode(view, view.state.schema.text(`{${props.item.id}}`))}
        disabled={!canInsert}
        items={items}
        textField="text"
        icon="link-horizontal"
        buttonClass="btn btn-success"
        onMouseDown={e => e.preventDefault()}
        onPointerDown={e => e.preventDefault()}
        {...settings.props}
      />
    )
  }

export const InsertLinkTool = createInsertTextTool({
  nodeType: 'text',
  text: '{{link}}',
  props: {
    title: 'Insert link'
  }
})

export const returnUpdatedRecipientsRows = (originalRows, data) => {
  let rows = originalRows.rows
  let body = originalRows
  rows.forEach((el, key) => {
    if (data[key].isDeleted === true) {
      el["isDeleted"] = true
    }
    delete data[key]["isDeleted"]
    delete data[key]["customId"]
    el["v"] = Object.values(data[key])
  })
  body = { ...body, rows: rows }
  return body
}

export const returnEditingRows = (res) => {
  let editingRows = []
  res.rows.forEach((el, key) => {
    let row = { customId: el.i, "isDeleted": false }
    el.v.forEach((el, key) => {
      row = { ...row, [res.headers[key]]: el }
    })
    editingRows.push(row)

  })
  return editingRows
}

export const returnRecipientStatusBadge = (status) => {
  let color = 'badge-light'
  let text = ''
  if (status === "Created") {
    text = 'Created'
    color = 'badge-primary'
  } else if (status === "TableCreated") {
    text = 'Respondent file uploaded'
    color = 'badge-info'
  } else if (status === "TableEdited") {
    text = 'Respondent file edited'
    color = 'badge-info'
  } else if (status === "Imported") {
    text = 'Respondents imported'
    color = 'badge-success'
  } else if (status === "Expired") {
    text = 'Respondents data expired'
    color = 'badge-light'
  } else if (status === "Processing") {
    text = 'Processing respondent file'
    color = 'badge-info'
  }
  return { color, text }
}

export const checkCampaignUpdates = (campaign) => {
  let updated = true
  if (campaign.type === 'email-campaign') {
    if ((campaign.bodyHtml === null || campaign.bodyHtml === '') && (campaign.emailProviderId === null || campaign.emailProviderId === '')
      && (campaign.fromEmailAddress === null || campaign.fromEmailAddress === '') && (campaign.recipientLists === null || (campaign.recipientLists !== null && campaign.recipientLists.length === 0))
      && (campaign.subject === null || campaign.subject === '')) {
      updated = false
    } else {
      updated = true
    }
  } else {
    if ((campaign.text === null || campaign.text === '') && (campaign.smsProviderId === null || campaign.smsProviderId === '')
      && (campaign.from === null || campaign.from === '') && (campaign.recipientLists === null || (campaign.recipientLists !== null && campaign.recipientLists.length === 0))) {
      updated = false
    } else {
      updated = true
    }
  }
  return updated
}

export const returnComposeBody = (composeData, type) => {
  let body
  if (type === 'email-campaign') {
    body = {
      "bodyHtml": composeData.emailBody,
      "bodyText": "",
      "subject": composeData.subject,
      "emailProviderId": composeData.emailProvider,
      "recipientLists": composeData.to.map(el => el.id),
      "fromEmailAddress": composeData.from,
      "isRawHtml": composeData.isRawHtml
    }
  } else {
    if (composeData.smsProvider.providerType === "twilio-sms") {
      body = {
        "text": composeData.text,
        "smsProviderId": composeData.smsProvider.id,
        "recipientLists": composeData.to.map(el => el.id),
      }
    } else {
      body = {
        "text": composeData.text,
        "smsProviderId": composeData.smsProvider,
        "recipientLists": composeData.to.map(el => el.id),
        "from": composeData.from
      }
    }
  }
  return body
}

export const returnSendOutSettingsBody = (showValidation, settings, subset) => {
  const body = {
    "useCampaignValidation": showValidation,
    "rejectCampaignIdList": settings.validateCampaigns.length > 0 ? settings.validateCampaigns.map(el => el.id) : null,
    "sendTarget": subset ? "Subset" : "All",
    "sendSubset": {
      "completed": settings.sendTo.includes("completed"),
      "started": settings.sendTo.includes("started"),
      "notStarted": settings.sendTo.includes("notStarted")
    }
  }
  return body
}

export const returnComposeStateData = (campaign, recipientsData, providers) => {
  let composeData
  if (campaign.type === 'email-campaign') {
    composeData = {
      to: campaign.recipientLists ? recipientsData.filter(el => campaign.recipientLists.includes(el.id) ? true : false) : [],
      from: campaign.fromEmailAddress ? campaign.fromEmailAddress : "",
      subject: campaign.subject ? campaign.subject : "",
      emailProvider: campaign.emailProviderId ? campaign.emailProviderId : providers[0] && providers[0].id ? providers[0].id : null,
      emailBody: campaign.bodyHtml ? campaign.bodyHtml : "",
      isRawHtml: campaign.isRawHtml
    }
  } else {
    composeData = {
      to: campaign.recipientLists ? recipientsData.filter(el => campaign.recipientLists.includes(el.id) ? true : false) : [],
      from: campaign.from ? campaign.from : "",
      text: campaign.text ? campaign.text : "",
      smsProvider: campaign.smsProviderId ? campaign.smsProviderId : providers[0].id,
    }
  }
  return composeData
}

export const returnSmsCount = (text) => {
  const link = '{surveyLink}'
  let textLength = text.length
  let characters = 0
  let messages = 0
  if (text.includes(link)) {
    const linkCount = (text.match(/{surveyLink}/g) || []).length;
    textLength = text.replace(/{surveyLink}/g, '').length + (linkCount * 44)
  }
  if (textLength <= 160) {
    characters = textLength
    messages = 0
  } else {
    characters = textLength % 160
    messages = Math.floor(textLength / 160)
  }
  return { characters, messages }
}