import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input, type InputChangeEvent } from '@progress/kendo-react-inputs';

import type { RootState } from '../../../../../store/reducers/rootReducer';
import { ErrorMessage } from '../../../../shared/ErrorMessage/ErrorMessage';
import type { EmailProvider } from '../../../../../interfaces/emailProviderInterfaces/emailProviderInterfaces';
import { fetchDelete as deleteSMSProvider } from '../../../../../services/services';

interface Props {
  SMSProvider: EmailProvider | null
  SMSProviders: EmailProvider[] | null
  updateSMSProviders: (data: EmailProvider[] | null) => void
}

export const DeleteSMSProviderModal = ({ SMSProvider, SMSProviders, updateSMSProviders }: Props) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [SMSProviderName, setSMSProviderName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => dispatch({ type: 'UPDATE_SHOW_DELETE_SMS_PROVIDERS_MODAL', payload: { show: false, emailProvider: null } })

  const onDeleteHandler = () => {
    if (SMSProvider) {
      setIsLoading(true);
      deleteSMSProvider(`smsproviders/${SMSProvider.id}`, token)
        .then((res: TODO) => {
          setIsLoading(false);
          if (res.status === 204) {
            if (Array.isArray(SMSProviders) && SMSProviders.length > 0) {
              const updatedSMSProviders = [...SMSProviders]
              const matchedElement = updatedSMSProviders.find(provider => provider.id === SMSProvider.id)
              if (matchedElement) {
                const removeProviderIndex = updatedSMSProviders.indexOf(matchedElement)
                updatedSMSProviders.splice(removeProviderIndex, 1)
                updateSMSProviders(updatedSMSProviders)
                handleClose()
              }
            }
          } else {
            setErrorMessage('Something went wrong, please try again!')
          }
        })
    }
  }

  return (
    <Dialog
      title={`Delete "${SMSProvider?.name}"`}
      onClose={handleClose}>
      <div className="p-4">
        <p>
          Deleting an SMS provider will remove everything related to the SMS provider, including SMS settings. <br />
          Confirm the deletion by typing the SMS provider <strong>name</strong> in the input field below.
        </p>
        <Input
          className="w-100"
          value={SMSProviderName}
          onChange={(e: InputChangeEvent) => setSMSProviderName(e.value)}
        />
        {
          errorMessage &&
          <div className="mt-4">
            <ErrorMessage
              type="alert"
              errorMessage={errorMessage} />
          </div>
        }
      </div>

      <DialogActionsBar>
        <button
          type='button'
          className="k-button btn btn-secondary"
          onClick={handleClose}>Cancel
        </button>
        <button
          type='button'
          className="k-button btn-danger"
          disabled={SMSProviderName !== SMSProvider?.name || isLoading}
          onClick={onDeleteHandler}>Delete
        </button>
      </DialogActionsBar>
    </Dialog >
  )
}