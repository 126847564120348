import type { CSSProperties } from "react"

interface Props {
  style?: CSSProperties
  shape?: "circle"
  className?: string
}

export const Skeleton = ({ shape, className, style }: Props) => {
  return (
    <div style={style ? style : undefined} className={`skeleton ${shape ? `skeleton-${shape}` : ''} ${className ? className : ''}`} />
  )
}