import { Fragment, type ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from '../../../../shared/Icon/Icon';
import { SurveyComments } from './SurveyComments/SurveyComments';
import usePrevious from '../../../../shared/customHooks/usePrevious';
import { useDebounce } from '../../../../shared/customHooks/useDebounce';
import type { RootState } from '../../../../../store/reducers/rootReducer';
import { SurveyOptionsHeader } from './SurveyOptionsHeader/SurveyOptionsHeader';
import { useKeyboardShortcut } from '../../../../shared/customHooks/useKeyboardShortcut';
import { SurveyAdditionalOptions } from './SurveyAdditionalOptions/SurveyAdditionalOptions';
import { returnSurveyOptionsByType } from '../helpers/returnSurveyOptionsByType/returnSurveyOptionsByType';
import { fetchGetJson as getSurveyThemes, fetchGetJson as getSurveyStructure, fetchGetJson } from '../../../../../services/services';

interface Props {
  showDeleteElementModal: () => void
}

export const NewSurveyOptions = ({ showDeleteElementModal }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const wrapperRef = useRef(null)
  const params: { name: string, survey: string } = useParams()
  const [shouldFetchThemes, setShouldFetchThemes] = useState<boolean>(true)
  const { theData, selectedElement, themes, emailProviders } = useSelector((theState: RootState) => (theState.surveyInitialDataReducer))

  const prevSelectedElement: TODO = usePrevious(selectedElement)
  const prevSelectedItem: TODO = usePrevious(theData.selectedItem)
  const saveWithDebounce = useDebounce(() => dispatch({ type: 'SAVE_QUESTION' }), 1000);

  let optionsContent = null

  useEffect(() => {
    if (themes === null && shouldFetchThemes) {
      setShouldFetchThemes(false)
      getSurveyThemes('themes', token)
        .then((res: TODO) => {
          if (res && !res.error && !res.message) {
            dispatch({ type: 'SET_THEMES', payload: res })
          }
        })
    }
  }, [shouldFetchThemes, themes, dispatch, token])

  useEffect(() => {
    if (selectedElement && selectedElement?.id !== prevSelectedElement?.id) {
      dispatch({ type: 'SURVEY_SET_OPTIONS_TYPE', payload: { type: { type: selectedElement.type } } })
    }
  }, [selectedElement?.id, prevSelectedElement?.id])

  const getEmailProviders = useCallback(() => {
    if (emailProviders.data.length === 0) {
      dispatch({ type: 'SET_EMAIL_PROVIDERS', payload: { loading: true, data: [] } })
      fetchGetJson("emailproviders", token)
        .then(((res: TODO) => {
          if (res && !res.error && !res.message) {
            dispatch({ type: 'SET_EMAIL_PROVIDERS', payload: { loading: false, data: res.filter((el: { providerType: string }) => el.providerType === "sendgrid") } })
          } else {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
          }
        }))
    }
  }, [dispatch, emailProviders.data.length, token])

  useEffect(() => {
    if (theData.selectedItem && theData.selectedItem?.id !== prevSelectedItem?.id) {
      dispatch({ type: 'SURVEY_SET_OPTIONS_TYPE', payload: { type: { type: theData.selectedItem.type } } })
      if (theData.selectedItem?.type === 'info' && theData.selectedItem?.attributes?.mailprovider?.length > 0 && emailProviders.data.length === 0) {
        getEmailProviders()
      }
    }
  }, [theData.selectedItem?.id, prevSelectedItem?.id])

  const handleDeleteQuestionsShortcut = useCallback(() => {
    if (theData.selectedItem.published) {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'A published question can only be disabled.' } })
    } else {
      dispatch({ type: 'SET_SELECTED_ELEMENT', payload: theData.selectedItem })
      showDeleteElementModal();
    }
  }, [theData.selectedItem, dispatch, showDeleteElementModal]);

  useKeyboardShortcut(['delete'], handleDeleteQuestionsShortcut);

  const onValueChange = (e: TODO, type: string, option: { id: string }) => {
    const newValue = type === 'css' ? e.value?.id : type === 'string' ? e.value : type === "checkbox" ? e.target?.checked.toString() : e.value.text
    const updatedSelectedElement = JSON.parse(JSON.stringify(selectedElement))
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    updatedSelectedElement.attributes = { ...updatedSelectedElement.attributes, [option.id]: newValue }
    if (option.id === 'miext:disabled') {
      updatedSelectedElement.disabled = newValue === 'true'
      updatedSelectedElement.isDisabled = newValue === 'true'
    }
    dispatch({ type: 'SET_SELECTED_ELEMENT', payload: updatedSelectedElement })

    if (selectedElement.type === 'subq') {
      updatedSelectedItem.elements.filter((el: TODO) => el.type === 'subq')[theData.selectedSubquestion].attributes = { ...updatedSelectedElement.attributes, [option.id]: newValue }
      dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    } else if (selectedElement.type === 'r' || selectedElement.type === "other") {
      const updatedSelectedElementAttributes = updatedSelectedElement.attributes ? updatedSelectedElement.attributes : {}
      const findSelectedElement = updatedSelectedItem.elements.find((el: TODO) => el.type === 'rgroup').elements.find((el: TODO) => el.id === selectedElement.id)
      findSelectedElement.attributes = { ...updatedSelectedElementAttributes, [option.id]: newValue }
      if (option.id === 'miext:disabled') {
        findSelectedElement.disabled = newValue === 'true'
      }
      dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    } else if (selectedElement.type === 'h') {
      const updatedSelectedElementAttributes = updatedSelectedElement.attributes ? updatedSelectedElement.attributes : {}
      const findSelectedElement = updatedSelectedItem.elements.filter((el: TODO) => el.type === 'subq')[theData.selectedSubquestion].elements.find((el: TODO) => el.type === 'hgroup').elements.find((el: TODO) => el.id === selectedElement.id)
      findSelectedElement.attributes = { ...updatedSelectedElementAttributes, [option.id]: newValue }
      if (option.id === 'miext:disabled') {
        findSelectedElement.disabled = newValue === 'true'
      }
      dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    } else {
      // If element type is ques/quota/quotastop/info/goto
      if (option.id === 'quota' && type === 'quotaStop') {
        updatedSelectedItem.attributes = { ...updatedSelectedElement.attributes, [option.id]: e.value.referenceDisplayLabel }
        updatedSelectedItem.referenceQuotas.setQuota = e.value
      } else {
        updatedSelectedItem.attributes = { ...updatedSelectedElement.attributes, [option.id]: newValue }
      }
      if (option.id === 'miext:disabled') {
        updatedSelectedItem.disabled = newValue === 'true'
        updatedSelectedItem.isDisabled = newValue === 'true'
      }
      dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem });

      const updatedData = JSON.parse(JSON.stringify(theData));
      let foundIndexEl: TODO = null;

      updatedData.data.some((section: TODO) => {
        return section.elements.some((element: TODO) => {
          if (element.id === updatedSelectedItem.id) {
            foundIndexEl = element;
            return true;
          }
          return false;
        });
      });

      if (foundIndexEl) {
        foundIndexEl.isDisabled = newValue === 'true';
        dispatch({ type: 'SEARCH_SURVEY_DATA', payload: updatedData.data })
      }
    }
    saveWithDebounce()
  }

  const showFilterBuilder = (option: string, optionName?: string, previousItemLabel?: string) => {
    const element: { metadata?: { filterData: unknown }, elements: unknown } = JSON.parse(JSON.stringify(theData.originalSelectedItem))
    getSurveyStructure(`su/projects/${params.name}/surveys/${params.survey}/structure`, token)
      .then((res: { error?: string, message?: string, questions: unknown[] }) => {
        if (res.error || res.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message ? res.message : "Something went wrong" } })
        } else {
          if (option === "if" && optionName === "Condition") {
            // This block removes all items that come after the selected goto element 
            const reversedItemList = JSON.parse(JSON.stringify(res.questions)).reverse();
            let previousItemReversedIndex: number = res.questions.length;
            if (previousItemLabel) {
              previousItemReversedIndex = res.questions.length - reversedItemList.findIndex((el: { qno: string }) => el.qno.includes(previousItemLabel));
            }
            const updatedItemList = res.questions.slice(0, previousItemReversedIndex);
            res.questions = updatedItemList;
          }

          dispatch({
            type: 'SURVEY_DISPLAY_FILTER_BUILDER', payload: {
              display: true,
              filterData: res,
              filterBuilderOptionId: option,
              combineFilterData: element.metadata?.filterData ? element.metadata.filterData : []
            }
          })
        }
      })
  }

  let options: null | ReactNode[] = null
  if (selectedElement && Object.keys(selectedElement).length > 0 && selectedElement.type !== 'quota') {
    if (theData?.elementTypes.length > 0 && themes?.length >= 0) {
      const values = selectedElement.attributes ? selectedElement.attributes : {};
      const listItemsDropdown = undefined;
      const listItemValue = undefined;
      const isSectionOptionsDialogOpen = false;
      const loopType = undefined;
      const updateLoopType = () => { };
      options = returnSurveyOptionsByType(theData, selectedElement, values, onValueChange, showFilterBuilder, themes, listItemsDropdown, listItemValue, emailProviders, getEmailProviders, isSectionOptionsDialogOpen, loopType, updateLoopType)
    }
  } else if (selectedElement && Object.keys(selectedElement).length > 0 && selectedElement.type === 'quota') {
    options = [];
  }

  // Section questions
  if (selectedElement && options) {
    optionsContent =
      <Fragment>
        <div>
          <SurveyOptionsHeader
            theData={theData}
            selectedElement={selectedElement}
            subquestion={theData.selectedItem.type === 'ques' ? theData.selectedItem.elements.filter((el: { type: string }) => el.type === 'subq')[theData.selectedSubquestion] : null}
          />
        </div>
        {theData.isSurveyComments && (
          <div className='d-flex flex-column gap-md px-4 pt-4 pb-2 h-100 overflow-auto'>
            <SurveyComments />
          </div>
        )}
        {!theData.isSurveyComments && selectedElement.type !== 'quota' && selectedElement.type !== 'rc' && (
          <div className='d-flex flex-column gap-md px-4 pt-4 pb-2 h-100 overflow-auto'>
            <SurveyAdditionalOptions
              theData={theData}
              selectedElement={selectedElement}
              onValueChange={onValueChange}
            />
            {
              options ?
                <form className="d-flex flex-column gap-md">
                  {options.map((option) => option)}
                </form>
                : null
            }
          </div>
        )}
      </Fragment>
  }

  return (
    <div
      ref={wrapperRef}
      className='col-lg-3 survey-content bg-white border-left d-flex flex-column overflow-hidden'>
      {optionsContent}
      {
        !theData.isSurveyComments && selectedElement && selectedElement.type !== 'quota' && selectedElement.type !== "r" && selectedElement.type !== "other" && selectedElement.type !== "h" && selectedElement.type !== "rc" && selectedElement.published === false &&
        <div className={`p-2 border-top d-flex ${selectedElement.published === false ? 'justify-content-between' : 'justify-content-end'}`}>
          <button
            type='button'
            className='btn btn-icon btn-transparent danger text-danger pl-1 pr-2'
            disabled={selectedElement.type === 'subq' && theData.selectedItem?.elements?.filter((el: { type: string }) => el.type === 'subq')?.length === 1}
            onClick={() => showDeleteElementModal()}>
            <Icon className='fill-danger' type="delete-alt" />
            <span className='ml-1'>Delete {theData?.elementTypes.length > 0 ? theData.elementTypes.find((el: TODO) => el.id === selectedElement.type).name.toLowerCase() : ''}</span>
          </button>
        </div>
      }
    </div>
  )
}