import React from 'react';
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend
} from '@progress/kendo-react-charts';
import returnLabelContent from '../../../../../../shared/helpers/returnLabelContent/returnLabelContent';

export default ({ data, zoomLevel }) => {
  const categories = data[0].categories
  const chartData = []

  data[0].series.forEach((el) => {
    let list = []
    el.data.forEach((value, key) => {
      list.push({ category: categories[key], value: value })
    })
    chartData.push({ name: el.name, series: list })
  })

  return (
    <React.Fragment>
      <span className="table-chart-title" style={{ "fontSize": `${((zoomLevel) * 0.875).toString()}rem` }}>{data[0].title}</span>
      {chartData.map((item, key) => (
        <Chart key={key} transitions={false} style={{ "minHeight": `${600 * (zoomLevel)}px` }} >
          <ChartTitle text={item.name} font={`${zoomLevel}rem sans-serif`} />
          <ChartLegend position="bottom" labels={{ font: `${zoomLevel}rem sans-serif` }} />
          <ChartCategoryAxis >
            <ChartCategoryAxisItem categories={categories} />
          </ChartCategoryAxis>
          <ChartSeries>
            <ChartSeriesItem
              type="pie"
              data={item.series}
              field="value"
              categoryField="category"
              tooltip={{ visible: data[0].hidepctsign ? false : true }}
              labels={{ visible: data[0].hidevalues ? false : true, content: (e) => returnLabelContent(e, data[0].hidepctsign), font: `${zoomLevel}rem sans-serif` }} />
          </ChartSeries>
        </ Chart>
      ))}

    </React.Fragment>
  )
}