import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { APIKeyCard } from './APIKeyCard/APIKeyCard';
import type { RootState } from '../../../../store/reducers/rootReducer';
import { CreateAPIKeyModal } from './CreateAPIKeyModal/CreateAPIKeyModal';
import { RemoveAPIKeyModal } from './RemoveAPIKeyModal/RemoveAPIKeyModal';
import { AdminCardsSkeleton } from '../AdminCardsSkeleton/AdminCardsSkeleton';
import type { AdminUser } from '../../../../interfaces/userInterface/userInterface';
import type { APIKey } from '../../../../interfaces/adminInterfaces/adminInterfaces';
import { fetchGetJson as getUsers } from '../../../../services/services';

export const APIKeysTabContent = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { showRemoveAPIKeyModal, showCreateAPIKeysModal, apiKeys } = useSelector((theState: RootState) => theState.adminReducer);
  const [shouldFetchAPIKeys, setShouldFetchAPIKeys] = useState(true);

  useEffect(() => {
    if (shouldFetchAPIKeys) {
      setShouldFetchAPIKeys(false);
      getUsers("users", token)
        .then((res: TODO) => {
          if (!res.error && !res.message) {
            dispatch({ type: 'SET_API_KEYS', payload: res.filter((user: AdminUser) => user.isApiUser) })
          } else {
            dispatch({ type: 'SET_API_KEYS', payload: [] })
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
          }
        })
    }
  }, [token, shouldFetchAPIKeys, dispatch])

  return (
    <Fragment>
      {
        showCreateAPIKeysModal.show &&
        <CreateAPIKeyModal
          token={token}
          apiKey={showCreateAPIKeysModal.apiKey}
          updateData={() => setShouldFetchAPIKeys(true)}
          onHide={() => dispatch({ type: 'UPDATE_SHOW_API_KEYS_MODAL', payload: { show: false, apiKey: null } })} />
      }
      {
        showRemoveAPIKeyModal.show &&
        <RemoveAPIKeyModal
          apiKeys={apiKeys}
          updateData={(data) => dispatch({ type: 'SET_API_KEYS', payload: data })}
          onHide={() => dispatch({ type: 'UPDATE_SHOW_REMOVE_API_KEYS_MODAL', payload: { show: false, apiKey: null } })} />
      }
      <div className="container-fluid py-5">
        <div style={{ rowGap: '1.5rem' }} className="row">
          {
            apiKeys !== null ?
              apiKeys.length > 0 ?
                apiKeys.map((apiKey: APIKey) => (
                  <Fragment key={apiKey.id}>
                    <APIKeyCard apiKey={apiKey} />
                  </Fragment>
                ))
                :
                <div className="d-flex flex-column gap-lg text-disabled text-center h5 pt-10 w-100">
                  <i className="fal fa-2x fa-folder-open" />
                  <p className='m-0'>No API Keys have been found</p>
                </div>
              :
              <AdminCardsSkeleton />
          }
        </div>
      </div>
    </Fragment>
  )
}