import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';

import { BaseMultiSelect } from '../../../shared/Inputs';
import { DropdownButton } from '../../../shared/DropdownButton/DropdownButton';
import type { ToolInfo } from '../../../../interfaces/workflowInterfaces/toolInterfaces';
import { workflowTools } from '../AddNewToolModal/workflowTools';
import type { RootState } from '../../../../store/reducers/rootReducer';

type Tags = {
  id: string;
  name: string;
}

interface Props {
  handleUpdateToolData: (type: string, id: string) => void
  editMode: boolean
  tags: Tags[]
  toolData: ToolInfo
}

export const WorkflowToolGeneralTabContent = ({ handleUpdateToolData, editMode, tags, toolData }: Props) => {
  const [hasEditedName, setHasEditedName] = useState(false);
  const { workflowData } = useSelector((theState: RootState) => theState.workflowStateReducer);

  const listNoDataRender = () => {
    return (
      <h5 className="text-muted text-center">
        <span className="k-icon k-i-warning my-3" style={{ fontSize: '2.5em' }} />
        <br />
        <span>No tags created yet</span>
      </h5>
    );
  }

  const autoPopulateName = (toolType: string, value: string, text: string) => {
    if (!hasEditedName) {
      handleUpdateToolData(toolType, value);
      handleUpdateToolData('name', `${text} (${workflowData.data.toolReferences.length + 1})`)
    } else {
      handleUpdateToolData(toolType, value)
    }
  }

  return (
    <Fragment>
      <span className="mb-2">Type</span>
      <div className="d-flex flex-column survey-option">
        <DropdownButton
          disabled={!!editMode}
          items={workflowTools}
          icon={workflowTools.find(tool => editMode ? workflowData.selectedTool && tool.id === workflowData.selectedTool.toolType : tool.id === toolData.toolType)?.icon || undefined}
          className="mb-3 w-100"
          text={workflowTools.find(tool => editMode ? workflowData.selectedTool && tool.id === workflowData.selectedTool.toolType : tool.id === toolData.toolType)?.text || 'Select tool type'}
          onItemClick={(e) => autoPopulateName('toolType', e.item.id, e.item.text)}
        />
      </div>

      <span className="mb-2">Name</span>

      <input placeholder="Enter tool name" className="form-control mb-3" value={editMode ? workflowData.selectedTool?.name : toolData.name} onChange={(e) => { setHasEditedName(e.target.value !== ""); handleUpdateToolData('name', e.target.value) }} />

      <span className="mb-2">Tags</span>
      <BaseMultiSelect
        data={tags ? tags : []}
        value={editMode ? workflowData.selectedTool?.tags ? workflowData.selectedTool.tags : [] : toolData.tags ? toolData.tags : []}
        textField="name"
        dataItemKey="id"
        allowCustom={true}
        className="mb-3"
        listNoDataRender={listNoDataRender}
        onChange={(e: { target: { value: string; }; }) => handleUpdateToolData('tags', e.target.value)}
      />

      <span className="mb-2">Description</span>

      <textarea
        rows={3}
        name="description"
        className="form-control w-100 mb-3"
        placeholder="Enter tool description"
        value={editMode ? workflowData.selectedTool?.description : toolData.description}
        onChange={(e) => handleUpdateToolData('description', e.target.value)}
      />
    </Fragment>
  )
}