import { type FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { InputChangeEvent } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Stepper, type StepperChangeEvent } from '@progress/kendo-react-layout';

import usePrevious from '../../../../shared/customHooks/usePrevious';
import type { RootState } from '../../../../../store/reducers/rootReducer';
import { ErrorMessage } from '../../../../shared/ErrorMessage/ErrorMessage';
import { EmailProviderModalContent } from '../EmailProviderModalContent/EmailProviderModalContent';
import { EmailProvidersActionBars } from '../EditEmailProviderModal/EmailProvidersActionBars/EmailProvidersActionBars';
import type { TwilioProvider, TwilioSmsProviderFormData } from '../../../../../interfaces/emailProviderInterfaces/emailProviderInterfaces';
import { fetchPostJson, fetchPutJson } from '../../../../../services/services';

interface Props {
  handleClose: () => void
  smsProvider: TwilioProvider
}
const items = [{ label: 'General settings' }, { label: 'SMS' }];

export const EditTwilioSmsProviderModalContent = ({ handleClose, smsProvider }: Props) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { emailProvidersErrorMessage } = useSelector((theState: RootState) => theState.adminReducer);
  const [stepperValue, setStepperValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<TwilioSmsProviderFormData>({ providerName: '', providerDescription: '', accountSid: '', serviceSid: '', apiKeySid: '', apiKeyToken: '' })
  const prevSmsProvider = usePrevious(smsProvider);

  useEffect(() => {
    if (smsProvider && smsProvider !== prevSmsProvider) {
      setFormData({
        ...formData,
        providerName: smsProvider.name,
        providerDescription: smsProvider.description,
        accountSid: smsProvider.accountSid,
        serviceSid: smsProvider.serviceSid,
        apiKeySid: smsProvider.apiKeySid,
        apiKeyToken: smsProvider.apiKeyToken
      })
    }
  }, [formData, smsProvider, prevSmsProvider])

  const onSubmitHandler = (e: FormEvent) => {
    e.preventDefault()
    if (stepperValue === 0) {
      setStepperValue(stepperValue + 1)
    } else {
      setIsLoading(true);
      const body = {
        name: formData.providerName,
        description: formData.providerDescription,
        accountSid: formData.accountSid,
        serviceSid: formData.serviceSid,
        apiKeySid: formData.apiKeySid,
        apiKeyToken: formData.apiKeyToken
      }
      if (smsProvider?.id) {
        fetchPutJson(`smsproviders/${smsProvider.id}`, token, body)
          .then((res: TODO) => {
            setIsLoading(false);
            if (res.error || res.message) {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } })
            } else {
              dispatch({ type: 'UPDATE_SMS_PROVIDERS', payload: true })
              handleClose()
            }
          })
      } else {
        fetchPostJson("smsproviders/twilio", token, body)
          .then((res: TODO) => {
            setIsLoading(false);
            if (res.error || res.message) {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } })
            } else {
              dispatch({ type: 'UPDATE_SMS_PROVIDERS', payload: true })
              handleClose()
            }
          })
      }
    }
  }

  const onChangeHandler = (e: InputChangeEvent, name: string) => {
    setFormData({
      ...formData,
      [name]: e.value
    })
  }

  const handleChange = (e: StepperChangeEvent) => {
    if (formData.providerName) {
      setStepperValue(e.value);
    }
  };

  return (
    <Dialog
      width="40%"
      title={smsProvider ? 'Edit Twilio SMS provider' : 'Create Twilio SMS provider'}
      className="email-provider-modal"
      onClose={handleClose}>
      <form onSubmit={onSubmitHandler} className="k-form d-flex flex-column h-100">
        <div className="px-4 pt-4 d-flex flex-column" style={{ flex: '1 1 auto' }}>
          <div className="pb-3">
            <Stepper value={stepperValue} onChange={handleChange} items={items} />
          </div>

          <EmailProviderModalContent
            tags={null}
            isTwilio={true}
            formData={formData}
            providerType={"twilio"}
            emailProvider={smsProvider}
            stepperValue={stepperValue}
            onChangeHandler={onChangeHandler}
          />

          {
            emailProvidersErrorMessage &&
            <div className="mt-4">
              <ErrorMessage
                type="alert"
                errorMessage={emailProvidersErrorMessage} />
            </div>
          }
        </div>

        <DialogActionsBar>
          <EmailProvidersActionBars
            isLoading={isLoading}
            formData={formData}
            isInProgress={null}
            stepperValue={stepperValue}
            emailProvider={smsProvider}
            handleClose={handleClose}
            setStepperValue={(val) => setStepperValue(val)} />
        </DialogActionsBar>
      </form>
    </Dialog>
  )
}
