import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input } from '@progress/kendo-react-inputs';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { PanelBar, PanelBarItem, TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { useAuth0 } from '@auth0/auth0-react';

import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import UpgradePlanTooltip from '../UpgradePlanTooltip/UpgradePlanTooltip';
import { QuickImportFileInput } from './QuickImportFileInput/QuickImportFileInput';
import ImportTypeSwitch from '../ImportModal/ImportTypeSwitch/ImportTypeSwitch';
import TabTitle from '../../Analysis/Analyze/components/dashboard/main/TabTitle/TabTitle';
import { onUploadHandler } from '../helpers/project/quickImportHelpers/quickImportHelpers';
import { returnAcceptedFileExtension } from '../helpers/project/returnAcceptedFileType/returnAcceptedFileType';

export const QuickImport = ({ onAdvancedImportOptionsChangeHandler, automaticMergeOptions, showCollapse, onUpdateCollapse, selectedTab, setSelectedTab, tabsData, formData, setFormData, caseFile, setCaseFile, dataMetaFile, setDataMetaFile, updateData, onHide, projectDetails, isAdvancedImportModalShown, projectDocuments }) => {
  const { user } = useAuth0();
  const { token } = useSelector((state) => state.tokenStateReducer);
  const dispatch = useDispatch();
  const { quickImportStatusData } = useSelector(state => state.quickImportStatusReducer);

  const onChangeHandler = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const onChangeTabHandler = (e) => {
    setSelectedTab(e.selected)
    setDataMetaFile(null)
    setCaseFile(null)
    setFormData({
      ...formData,
      name: '',
      description: '',
      fileType: tabsData[e.selected].title
    })
  }

  const onInputChangeHandler = (e) => {
    const existingDatasets = projectDocuments.map(el => el.name)
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const fileName = selectedFile.name.substr(0, selectedFile.name.lastIndexOf("."));
      const existingDatasetsVersion = projectDocuments.filter(el => el.name === fileName).length
      if (formData.fileType === 'Triple S') {
        if (e.target.name === 'dataMetaFile') {
          setDataMetaFile({ file: selectedFile, fileName: selectedFile.name, extension: returnAcceptedFileExtension(formData.fileType) })
        } else {
          setCaseFile({ file: selectedFile, fileName: selectedFile.name, extension: returnAcceptedFileExtension(formData.fileType) })
        }
      } else {
        setDataMetaFile({ file: selectedFile, fileName: selectedFile.name, extension: returnAcceptedFileExtension(formData.fileType) })
      }
      setFormData({
        ...formData,
        name: selectedFile && existingDatasets.includes(fileName) ? `${fileName}(${existingDatasetsVersion})` : fileName
      })
    } else {
      setDataMetaFile(dataMetaFile ? { ...dataMetaFile } : null)
      setCaseFile(caseFile ? { ...caseFile } : null)
      setFormData({ ...formData })
    }
  }

  const cancelImportHandler = () => {
    onHide()
    window.location.reload()
  }

  return (
    <Fragment>
      <TabStrip selected={selectedTab} onSelect={onChangeTabHandler} className="analyze-tabs">
        {
          tabsData.map((tab) => (
            <TabStripTab key={tab.title} title={<TabTitle icon={tab.icon} title={tab.title} user={user} />}>
              <div className="p-3">
                <Fragment>
                  <QuickImportFileInput
                    selectedFileType={formData.fileType}
                    onInputChangeHandler={onInputChangeHandler}
                    dataMetaFile={dataMetaFile}
                    caseFile={caseFile}
                  />
                  {
                    quickImportStatusData.uploadErrorMessage &&
                    <div className="alert alert-danger" role="alert">
                      <span>Please select one of the following valid file types:</span>
                      <ul className="m-0">
                        <li>Spss (.sav)</li>
                        <li>Excel (.xls or .xlsx)</li>
                        <li>Triple S (.sss and .csv)</li>
                        <li>Miprox (.miprox)</li>
                      </ul>
                    </div>
                  }
                  <Input
                    name="name"
                    className="w-100 mb-3"
                    placeholder="Name"
                    required={true}
                    value={formData.name}
                    valid={!projectDocuments.map(el => el.name).includes(formData.name)}
                    onChange={onChangeHandler}
                  />
                  {projectDocuments.map(el => el.name).includes(formData.name) &&
                    <div className="mt-3">
                      <ErrorMessage
                        type="alert"
                        errorMessage={"A dataset with this name already exists"} />
                    </div>
                  }
                  <Input
                    className="w-100"
                    name="description"
                    placeholder="Description"
                    value={formData.description}
                    onChange={onChangeHandler}
                  />
                  {
                    quickImportStatusData.importErrorMessage &&
                    <div className="mt-3">
                      <ErrorMessage
                        type="alert"
                        errorMessage={quickImportStatusData.importErrorMessage} />
                    </div>
                  }
                </Fragment>
              </div>
            </TabStripTab>
          ))
        }
      </TabStrip>

      <div className="accordion p-3" id="accordionExample">
        {
          user.plan === 'basic' ?
            <Tooltip parentTitle={true} position="bottom" anchorElement="pointer" content={(props) => <UpgradePlanTooltip />}>
              <div title={user.plan}>
                <PanelBar>
                  <PanelBarItem disabled title="Advanced import" />
                </PanelBar>
              </div>
            </Tooltip>
            :
            <PanelBar onSelect={onUpdateCollapse}>
              <PanelBarItem disabled={selectedTab === 3} title="Advanced import" expanded={showCollapse}>
                <div className="d-flex flex-column gap-lg py-2 px-3">
                  <ImportTypeSwitch user={user} isAdvancedImportModalShown={isAdvancedImportModalShown} automaticMergeOptions={automaticMergeOptions} onAdvancedImportOptionsChangeHandler={onAdvancedImportOptionsChangeHandler} selectedTab={selectedTab} />
                </div>
              </PanelBarItem>
            </PanelBar>
        }
      </div>

      <DialogActionsBar>
        <button
          type='button'
          className="k-button k-button-solid-base"
          onClick={quickImportStatusData.isInProgress ? cancelImportHandler : onHide}
        >
          Cancel
        </button>
        {
          quickImportStatusData.isInProgress ?
            <button type='button' className="k-button k-button-solid-base" onClick={onHide}>Close</button>
            :
            <button
              type='button'
              disabled={quickImportStatusData.isInProgress || projectDocuments.map(el => el.name).includes(formData.name)}
              className="k-button k-button-solid-primary"
              onClick={() => onUploadHandler(dispatch, quickImportStatusData, formData, dataMetaFile, caseFile, token, updateData, projectDetails)}
            >
              Import
            </button>
        }
      </DialogActionsBar>
    </Fragment>
  )
}