import React from 'react';

const DialogTitle = () => {
  return (
    <div className="text-primary d-flex align-items-center">
      <h6 className="m-0 strong">Options</h6>
    </div>
  );
};

export default DialogTitle;
