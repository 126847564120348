import { Icon } from '../../shared/Icon/Icon'

interface Props {
  numberOfSelectedProjects: number,
  handleMassExport: Function
}

export const MassActionPanel = ({ numberOfSelectedProjects, handleMassExport }: Props) => {
  return (
    <div className='gk-action-panel'>
      <div className='regular strong text-white'>{numberOfSelectedProjects} selected</div>
      <div className='d-flex'>
        <button onClick={() => handleMassExport()} className="btn btn-transparent primary btn-icon icon-l" type="button">
          <Icon type="share-arrow" />
          <span>Export</span>
        </button>
      </div>
      {/*
      // TODO: Enable when functinality done
      <button onClick={() => console.log("CLICK")} className="btn btn-trasparent mr-2 strong border text-white" type="button">
        <Icon type="copy" className="submenu" fill='white' />
        <span>Clone {numberOfSelectedProjects} projects</span>
      </button>

      <button onClick={() => console.log("CLICK")} className="btn btn-trasparent mr-2 strong border text-white" type="button">
        <Icon type="share-arrow" className="submenu" fill='white' />
        <span>Export</span>
      </button>
      <button onClick={() => console.log("CLICK")} className="btn btn-trasparent mr-2 strong border text-white" type="button">
        <Icon type="delete" className="submenu" fill='white' />
      </button> */}
    </div>
  )
}