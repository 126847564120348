export default (data, checkedQuestions, action) => {
  const updatedData = [...data]
  if (checkedQuestions.length === 1) {
    if (checkedQuestions[0].type === 'quantity') {
      if (action !== 'none') {
        updatedData[updatedData.indexOf(checkedQuestions[0])]['@miextsss:isweight'] = true
        updatedData[updatedData.indexOf(checkedQuestions[0])]['@miextsss:weighttype'] = action
      } else {
        updatedData[updatedData.indexOf(checkedQuestions[0])]['@miextsss:isweight'] = null
        updatedData[updatedData.indexOf(checkedQuestions[0])]['@miextsss:weighttype'] = null
      }
    }
  }

  return updatedData
}