import { useState, useEffect, useRef } from 'react'
import { Switch } from '@progress/kendo-react-inputs';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { Icon } from '../../../../../../../shared/Icon/Icon';
import type { RootState } from '../../../../../../../../store/reducers/rootReducer';
import { MatchToolActions } from '../../../../MatchToolActions/MatchToolActions';
import { DropdownButton } from '../../../../../../../shared/DropdownButton/DropdownButton';
import { FullHeightSpinner } from '../../../../../../../shared/FullHeightSpinner/FullHeightSpinner';
import { fetchPostJson as createMatchMap } from '../../../../../../../../services/services';

interface Props {
  handleEditTool: () => void
}

interface Status {
  type: 'Error' | 'Ok' | 'Warning',
  msgs: [],
  qno: string
  miextId: string
}

interface QuesMap {
  qno: string
  miextId: string
  isDisabled: boolean
  textValues?: []
  type?: string
  code?: string
}

interface CheckedObject {
  A: { [key: string]: boolean },
  B: { [key: string]: boolean }
}

const reorder = (list: [], startIndex: number, endIndex: number, replace: boolean, tab: string, multiSelectData: {}) => {
  if (multiSelectData) {
    const result = Array.from(list).filter((item: QuesMap) => !Object.keys(multiSelectData).includes(item.miextId));
    const removed = Array.from(list).filter((item: QuesMap) => Object.keys(multiSelectData).includes(item.miextId));
    if (replace) {
      result.splice(startIndex, 0, ...Object.keys(multiSelectData).map(() => (tab === 'quesMaps' ? {
        "code": null,
        "miextId": null,
        "isDisabled": false,
        "isEmpty": true,
        "textValues": null
      } as never : {
        ...(tab === 'subqMaps' && { "colMaps": [] }),
        ...(tab === 'subqMaps' && { "type": null }),
        "miextId": null,
        "isDisabled": false,
        "isEmpty": true,
        "textValues": null,
        ...(tab === 'rowMaps' && { "code": null })
      } as never)));
    }
    result.splice(endIndex, 0, ...removed);
    return result;
  }
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  if (replace) {
    result.splice(startIndex, 0, tab === 'quesMaps' ? {
      "code": null,
      "miextId": null,
      "isDisabled": false,
      "isEmpty": true,
      "textValues": null
    } as never : {
      ...(tab === 'subqMaps' && { "colMaps": [] }),
      ...(tab === 'subqMaps' && { "type": null }),
      "miextId": null,
      "isDisabled": false,
      "isEmpty": true,
      "textValues": null,
      ...(tab === 'rowMaps' && { "code": null })
    } as never);
  }
  result.splice(endIndex, 0, removed);
  return result;
};

const statusBgColor = { "Error": 'bg-do-fail', "Ok": 'bg-do-match', "Warning": 'bg-do-warning' }
const statusTextColor = { "Error": 'text-do-fail', "Ok": 'text-do-match', "Warning": 'text-do-warning' }

export const MatchToolTabContent = ({ handleEditTool }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const { workflowData } = useSelector((theState: RootState) => theState.workflowStateReducer);
  const [didMount, setDidMount] = useState(true);
  const [isFullMove, setIsFullMove] = useState(false);
  const [tabSelected, setTabSelected] = useState<"quesMaps" | "subqMaps" | "colMaps" | "rowMaps">('quesMaps')
  const [selectedRow, setSelectedRow] = useState<number | null>(null)
  const [selectedSubqRow, setSelectedSubqRow] = useState<number | null>(null)
  const [checkedQuestions, setCheckedQuestions] = useState<CheckedObject>({ A: {}, B: {} })
  const [checkedSubqMaps, setCheckedSubqMaps] = useState<CheckedObject>({ A: {}, B: {} })
  const [checkedColMaps, setCheckedColMaps] = useState<CheckedObject>({ A: {}, B: {} })
  const [checkedRowMaps, setCheckedRowMaps] = useState<CheckedObject>({ A: {}, B: {} })
  const [autoMatchType, setAutoMatchType] = useState('Labels and texts')
  const [validateMap, setValidateMap] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const matchData = workflowData?.selectedTool?.matchMap ? workflowData.selectedTool.matchMap : {}
  const isActionButtonDisabled =
    (tabSelected === 'quesMaps' && (!Object.keys(checkedQuestions.A).length && !Object.keys(checkedQuestions.B).length)) ||
    (tabSelected === 'subqMaps' && (!Object.keys(checkedSubqMaps.A).length && !Object.keys(checkedSubqMaps.B).length)) ||
    (tabSelected === 'colMaps' && (!Object.keys(checkedColMaps.A).length && !Object.keys(checkedColMaps.B).length)) ||
    (tabSelected === 'rowMaps' && (!Object.keys(checkedRowMaps.A).length && !Object.keys(checkedRowMaps.B).length))
  const mapsData = {
    subqMapsA: (typeof selectedRow === 'number') && matchData.quesMapsA ? matchData.quesMapsA[selectedRow].subqMaps : [],
    subqMapsB: (typeof selectedRow === 'number') && matchData.quesMapsB ? matchData.quesMapsB[selectedRow].subqMaps : [],
    colMapsA: (typeof selectedRow === 'number') && (typeof selectedSubqRow === 'number') && matchData.quesMapsA ? matchData.quesMapsA[selectedRow].subqMaps[selectedSubqRow]?.colMaps : [],
    colMapsB: (typeof selectedRow === 'number') && (typeof selectedSubqRow === 'number') && matchData.quesMapsB ? matchData.quesMapsB[selectedRow].subqMaps[selectedSubqRow]?.colMaps : [],
    rowMapsA: (typeof selectedRow === 'number') && matchData.quesMapsA ? matchData.quesMapsA[selectedRow].rowMaps : [],
    rowMapsB: (typeof selectedRow === 'number') && matchData.quesMapsB ? matchData.quesMapsB[selectedRow].rowMaps : [],
    quesMapsA: matchData.quesMapsA,
    quesMapsB: matchData.quesMapsB
  }
  const { statuses } = matchData;

  const selectedRowRef = useRef<null | HTMLDivElement>(null);
  const rowHeaderRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (didMount && workflowData.selectedTool.inputA.datasetId && workflowData.selectedTool.inputB.datasetId && !Object.keys(matchData).length) {
      setDidMount(false)
      setShowSpinner(true)
      const body = {
        "datasetA": { datasetId: workflowData.selectedTool.inputA.datasetId, isSurveyDataset: workflowData.selectedTool.inputA.inputIsSurveyDataset },
        "datasetB": { datasetId: workflowData.selectedTool.inputB.datasetId, isSurveyDataset: workflowData.selectedTool.inputB.inputIsSurveyDataset }
      };
      createMatchMap(`projects/${workflowData.selectedTool.projectId}/workflows/helpers/match-map`, token, body)
        .then((res: any) => {
          if (res && !res.error && !res.message) {
            dispatch({ type: 'UPDATE_TOOL', payload: { ...workflowData.selectedTool, matchMap: res } })
          } else {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `An error has occured: ${res.error ? res.error : res.message}` } })
          }
          setShowSpinner(false)
        })
    }

    if (validateMap) {
      createMatchMap(`projects/${workflowData.selectedTool.projectId}/workflows/helpers/validate-map`, token, matchData)
        .then((res: any) => {
          if (res && !res.error && !res.message) {
            dispatch({ type: 'UPDATE_TOOL', payload: { ...workflowData.selectedTool, matchMap: res } })
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Match tool data has been updated.' } })
          } else {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `An error has occured: ${res.error ? res.error : res.message}` } })
          }
        })
      setValidateMap(false)
    }

    if (Object.keys(matchData).length && !didMount && workflowData.selectedTool.inputA.datasetId && workflowData.selectedTool.inputB.datasetId) {
      setDidMount(true)
    }
  }, [didMount, dispatch, token, workflowData.selectedTool.inputA, workflowData.selectedTool.inputB, workflowData.selectedTool.projectId, matchData, validateMap, workflowData.selectedTool])

  useEffect(() => {
    if (tabSelected === 'quesMaps' && selectedRowRef && selectedRowRef.current) {
      selectedRowRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    } else if (rowHeaderRef?.current) {
      rowHeaderRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }, [tabSelected])

  const setMatchData = (data: any) => {
    dispatch({ type: 'UPDATE_TOOL', payload: { ...workflowData.selectedTool, matchMap: data } })
  }

  const handleSelectMatchType = async (id: string, text: string) => {
    setAutoMatchType(text)
    setSelectedRow(null)
    const res: any = await createMatchMap(`projects/${workflowData.selectedTool.projectId}/workflows/helpers/auto-match?type=${id}`, token, matchData)
    if (res && !res.error && !res.message) {
      setMatchData(res)
      dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Match tool data has been updated.' } })
    } else {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `An error has occured: ${res.error ? res.error : res.message}` } })
    }
  }

  const handleCheckAll = (group: 'A' | 'B', value: boolean) => {
    switch (tabSelected) {
      case "quesMaps":
        setCheckedQuestions((prevState: CheckedObject) => ({ ...prevState, [group]: value ? Object.assign({}, ...matchData[`quesMaps${group}`].map((item: { miextId: string; }) => ({ [item.miextId]: value }))) : {} }))
        break
      case "subqMaps":
        setCheckedSubqMaps((prevState: CheckedObject) => ({ ...prevState, [group]: value ? Object.assign({}, ...mapsData[`subqMaps${group}`].map((item: { miextId: string; }) => ({ [item.miextId]: value }))) : {} }))
        break
      case "colMaps":
        setCheckedColMaps((prevState: CheckedObject) => ({ ...prevState, [group]: value ? Object.assign({}, ...mapsData[`colMaps${group}`].map((item: { miextId: string; }) => ({ [item.miextId]: value }))) : {} }))
        break
      case "rowMaps":
        setCheckedRowMaps((prevState: CheckedObject) => ({ ...prevState, [group]: value ? Object.assign({}, ...mapsData[`rowMaps${group}`].map((item: { miextId: string; }) => ({ [item.miextId]: value }))) : {} }))
        break
      default:
        break
    }
  }

  const handleCheckQuestion = (e: any, data: any, group: 'A' | 'B', isChecked?: boolean) => {
    const checked = e.target.checked;
    (async () => {
      switch (tabSelected) {
        case "quesMaps":
          setCheckedQuestions((prevState: CheckedObject) => {
            if (prevState[group][data.miextId] && !isChecked) {
              const newData = prevState[group]
              delete newData[data.miextId]
              return ({
                ...prevState,
                [group]: newData
              })
            }
            return ({
              ...prevState,
              [group]: { ...prevState[group], [data.miextId]: checked }
            })
          })
          break
        case "subqMaps":
          setCheckedSubqMaps((prevState: CheckedObject) => {
            if (prevState[group][data.miextId] && !isChecked) {
              const newData = prevState[group]
              delete newData[data.miextId]
              return ({
                ...prevState,
                [group]: newData
              })
            }
            return ({
              ...prevState,
              [group]: { ...prevState[group], [data.miextId]: checked }
            })
          })
          break
        case "colMaps":
          setCheckedColMaps((prevState: CheckedObject) => {
            if (prevState[group][data.miextId] && !isChecked) {
              const newData = prevState[group]
              delete newData[data.miextId]
              return ({
                ...prevState,
                [group]: newData
              })
            }
            return ({
              ...prevState,
              [group]: { ...prevState[group], [data.miextId]: checked }
            })
          })
          break
        case "rowMaps":
          setCheckedRowMaps((prevState: CheckedObject) => {
            if (prevState[group][data.miextId] && !isChecked) {
              const newData = prevState[group]
              delete newData[data.miextId]
              return ({
                ...prevState,
                [group]: newData
              })
            }
            return ({
              ...prevState,
              [group]: { ...prevState[group], [data.miextId]: checked }
            })
          })
          break
        default:
          break
      }
    })()
  }

  const handleDisableMaps = () => {
    const groups = ['A', 'B']
    for (const group of groups) {
      if (tabSelected === 'quesMaps') {
        const newQuesMaps = matchData[`quesMaps${group}`]
        for (const value of Object.keys(checkedQuestions[group as keyof CheckedObject])) {
          const quesMap = newQuesMaps.find((item: QuesMap) => item.miextId === value)
          if (quesMap) {
            quesMap.isDisabled = !quesMap.isDisabled
          }
        }
        setMatchData({ ...matchData, [`quesMaps${group}`]: newQuesMaps })
      } else {
        const newMatchData = matchData
        const checkedIds = tabSelected === 'subqMaps' ?
          Object.keys(checkedSubqMaps[group as keyof CheckedObject]) : tabSelected === 'colMaps' ?
            Object.keys(checkedColMaps[group as keyof CheckedObject]) : Object.keys(checkedRowMaps[group as keyof CheckedObject]);
        if (tabSelected === 'colMaps') {
          newMatchData[`quesMaps${group}`][selectedRow as number].subqMaps[selectedSubqRow as number].colMaps.forEach((item: QuesMap) => {
            for (const id of checkedIds) {
              if (item.miextId === id) {
                item.isDisabled = !item.isDisabled
              }
            }
          });
        } else {
          newMatchData[`quesMaps${group}`][selectedRow as number][tabSelected].forEach((item: QuesMap) => {
            for (const id of checkedIds) {
              if (item.miextId === id) {
                item.isDisabled = !item.isDisabled
              }
            }
          });
        }
        setMatchData(newMatchData)
      }
    }
    setValidateMap(true)
  }

  const handleFilterCheckedItems = (group: 'A' | 'B', miextId: string) => {
    if (tabSelected === 'quesMaps') {
      if (group === 'A') {
        return checkedQuestions.A[miextId]
      } if (group === 'B') {
        return checkedQuestions.B[miextId]
      }
    } else if (tabSelected === 'subqMaps') {
      if (group === 'A') {
        return checkedSubqMaps.A[miextId]
      } if (group === 'B') {
        return checkedSubqMaps.B[miextId]
      }
    } else if (tabSelected === 'colMaps') {
      if (group === 'A') {
        return checkedColMaps.A[miextId]
      } if (group === 'B') {
        return checkedColMaps.B[miextId]
      }
    } else if (tabSelected === 'rowMaps') {
      if (group === 'A') {
        return checkedRowMaps.A[miextId]
      } if (group === 'B') {
        return checkedRowMaps.B[miextId]
      }
    }
  }

  const onDragEnd = (result: TODO, group: 'A' | 'B') => {
    if (!result.destination) {
      return;
    }
    const multiSelectData = (tabSelected === 'quesMaps' && Object.keys(checkedQuestions[group]).length > 1 && checkedQuestions[group]) ||
      (tabSelected === 'subqMaps' && Object.keys(checkedSubqMaps[group]).length > 1 && checkedSubqMaps[group]) ||
      (tabSelected === 'colMaps' && Object.keys(checkedColMaps[group]).length > 1 && checkedColMaps[group]) ||
      (tabSelected === 'rowMaps' && Object.keys(checkedRowMaps[group]).length > 1 && checkedRowMaps[group])
    const items = reorder(
      tabSelected === 'quesMaps' ? matchData[`quesMaps${group}`] :
        tabSelected === 'colMaps' ? matchData[`quesMaps${group}`][selectedRow as number].subqMaps[selectedSubqRow as number].colMaps :
          matchData[`quesMaps${group}`][selectedRow as number][tabSelected],
      result.source.index,
      result.destination.index,
      isFullMove,
      tabSelected,
      multiSelectData
    );
    const updatedData = matchData[`quesMaps${group}`].map((item: QuesMap, idx: number) => {
      if (tabSelected === 'colMaps') {
        matchData[`quesMaps${group}`][selectedRow as number].subqMaps[selectedSubqRow as number].colMaps = items
      }
      if (tabSelected !== 'colMaps' && idx === selectedRow) {
        return { ...matchData[`quesMaps${group}`][selectedRow], [tabSelected]: items }
      }
      return item
    })
    if (tabSelected === 'quesMaps') {
      setMatchData({ ...matchData, [`quesMaps${group}`]: items })
    } else {
      setMatchData({ ...matchData, [`quesMaps${group}`]: updatedData })
    }
    setValidateMap(true)
  }

  const handleSelectRow = (rowNumber: number) => {
    if (tabSelected === 'quesMaps') {
      if (rowNumber === selectedRow) {
        setSelectedRow(null)
        setSelectedSubqRow(null)
      } else {
        setSelectedRow(rowNumber)
        setSelectedSubqRow(0)
      }
    } else if (tabSelected === 'subqMaps') {
      if (rowNumber === selectedSubqRow) {
        setSelectedSubqRow(null)
      } else {
        setSelectedSubqRow(rowNumber)
      }
    } else {
      return;
    }
  }

  const handleSetRange = (event: TODO, id: string, group: 'A' | 'B') => {
    event.stopPropagation();
    if (event.shiftKey && event.target.checked) {
      if (tabSelected === 'quesMaps') {
        const keysArr = Object.keys(checkedQuestions[group])
        if (keysArr.length) {
          const quesMapsData = mapsData[`${tabSelected}${group}`]
          const start = quesMapsData.findIndex((item: QuesMap) => item.miextId === keysArr[keysArr.length - 1])
          const end = quesMapsData.findIndex((item: QuesMap) => item.miextId === id)
          if (start < end) {
            const data = quesMapsData.filter((id: string) => id > start && id < end)
            data.forEach((element: QuesMap) => {
              handleCheckQuestion(event, element, group, true)
            });
          } else if (start > end) {
            const data = quesMapsData.filter((id: string) => id < start && id > end)
            data.forEach((element: QuesMap) => {
              handleCheckQuestion(event, element, group, true)
            });
          }
        }
      } else if (tabSelected === 'subqMaps') {
        const keysArr = Object.keys(checkedSubqMaps[group])
        if (keysArr.length) {
          const subqMapsData = mapsData[`quesMaps${group}`][selectedRow as number].subqMaps
          const start = subqMapsData.findIndex((item: QuesMap) => item.miextId === keysArr[keysArr.length - 1])
          const end = subqMapsData.findIndex((item: QuesMap) => item.miextId === id)
          if (start < end) {
            const data = subqMapsData.filter((id: string) => id > start && id < end)
            data.forEach((element: QuesMap) => {
              handleCheckQuestion(event, element, group, true)
            });
          } else if (start > end) {
            const data = subqMapsData.filter((id: string) => id < start && id > end)
            data.forEach((element: QuesMap) => {
              handleCheckQuestion(event, element, group, true)
            });
          }
        }
      } else if (tabSelected === 'colMaps') {
        const keysArr = Object.keys(checkedColMaps[group])
        if (keysArr.length) {
          const colMapsData = mapsData[`quesMaps${group}`][selectedRow as number].subqMaps[selectedSubqRow as number].colMaps
          const start = colMapsData.findIndex((item: QuesMap) => item.miextId === keysArr[keysArr.length - 1])
          const end = colMapsData.findIndex((item: QuesMap) => item.miextId === id)
          if (start < end) {
            const data = colMapsData.filter((id: string) => id > start && id < end)
            data.forEach((element: QuesMap) => {
              handleCheckQuestion(event, element, group, true)
            });
          } else if (start > end) {
            const data = colMapsData.filter((id: string) => id < start && id > end)
            data.forEach((element: QuesMap) => {
              handleCheckQuestion(event, element, group, true)
            });
          }
        }
      } else if (tabSelected === 'rowMaps') {
        const keysArr = Object.keys(checkedRowMaps[group])
        if (keysArr.length) {
          const rowMapsData = mapsData[`quesMaps${group}`][selectedRow as number].rowMaps
          const start = rowMapsData.findIndex((item: QuesMap) => item.miextId === keysArr[keysArr.length - 1])
          const end = rowMapsData.findIndex((item: QuesMap) => item.miextId === id)
          if (start < end) {
            const data = rowMapsData.filter((id: string) => id > start && id < end)
            data.forEach((element: QuesMap) => {
              handleCheckQuestion(event, element, group, true)
            });
          } else if (start > end) {
            const data = rowMapsData.filter((id: string) => id < start && id > end)
            data.forEach((element: QuesMap) => {
              handleCheckQuestion(event, element, group, true)
            });
          }
        }
      }
    }
  }

  const addNewRow = () => {
    const updatedData = (group: string) => {
      const updatedMatchData = JSON.parse(JSON.stringify(matchData))
      let newEmptyRow: TODO = { "type": null, "code": null, "miextId": null, "isDisabled": false, "isEmpty": true, "textValues": null }

      if (tabSelected === 'colMaps') {
        updatedMatchData[`quesMaps${group}`][selectedRow as number].subqMaps[selectedSubqRow as number].colMaps.push(newEmptyRow)
      } else if (tabSelected === 'quesMaps') {
        newEmptyRow = { ...newEmptyRow, "subqMaps": [], "rowMaps": [], qno: null, id: null }
        updatedMatchData[`quesMaps${group}`].push(newEmptyRow)
      } else {
        if (tabSelected === 'subqMaps') { newEmptyRow.colMaps = [] }
        updatedMatchData[`quesMaps${group}`][selectedRow as number][tabSelected].push(newEmptyRow)
      }
      return updatedMatchData[`quesMaps${group}`]
    }

    setMatchData({ ...matchData, quesMapsA: updatedData('A'), quesMapsB: updatedData('B') })
  }

  const isEmptyRow = (element: TODO, tab: string, index: number) => {
    if (tab === 'quesMaps') {
      return !!(element.isEmpty && matchData.quesMapsB[index].isEmpty)
    } if (tab === 'colMaps') {
      return !!(element.isEmpty && matchData.quesMapsB[selectedRow as number].subqMaps[selectedSubqRow as number][tab][index].isEmpty)
    }
    return !!(element.isEmpty && matchData.quesMapsB[selectedRow as number][tab][index].isEmpty)
  }

  const onDeleteEmptyRow = (e: TODO, tab: string, index: number) => {
    e.stopPropagation()
    const updatedMatchData = JSON.parse(JSON.stringify(matchData))
    if (tab === 'quesMaps') {
      updatedMatchData.quesMapsA = updatedMatchData.quesMapsA.filter((id: number) => id !== index)
      updatedMatchData.quesMapsB = updatedMatchData.quesMapsB.filter((id: number) => id !== index)
    } else {
      if (tab === 'colMaps') {
        updatedMatchData.quesMapsA[selectedRow as number].subqMaps[selectedSubqRow as number][tab] = updatedMatchData.quesMapsA[selectedRow as number].subqMaps[selectedSubqRow as number][tab].filter((id: number) => id !== index)
        updatedMatchData.quesMapsB[selectedRow as number].subqMaps[selectedSubqRow as number][tab] = updatedMatchData.quesMapsB[selectedRow as number].subqMaps[selectedSubqRow as number][tab].filter((id: number) => id !== index)
      } else {
        updatedMatchData.quesMapsA[selectedRow as number][tab] = updatedMatchData.quesMapsA[selectedRow as number][tab].filter((id: number) => id !== index)
        updatedMatchData.quesMapsB[selectedRow as number][tab] = updatedMatchData.quesMapsB[selectedRow as number][tab].filter((id: number) => id !== index)
      }
    }
    setMatchData(updatedMatchData)
  }

  const onDeleteAllEmptyRows = () => {
    const updatedMatchData = JSON.parse(JSON.stringify(matchData))
    const emptyRows: number[] = []

    if (tabSelected === 'quesMaps') {
      for (let index = 0; index < updatedMatchData.quesMapsA.length; index++) {
        if (updatedMatchData.quesMapsA[index].isEmpty && updatedMatchData.quesMapsB[index].isEmpty) {
          emptyRows.push(index)
        }
      }
    } else {
      if (typeof selectedRow === 'number') {
        if (tabSelected === 'colMaps' && typeof selectedSubqRow === 'number') {
          for (let index = 0; index < updatedMatchData.quesMapsA[selectedRow].subqMaps[selectedSubqRow][tabSelected].length; index++) {
            if (updatedMatchData.quesMapsA[selectedRow].subqMaps[selectedSubqRow][tabSelected][index].isEmpty && updatedMatchData.quesMapsB[selectedRow].subqMaps[selectedSubqRow][tabSelected][index].isEmpty) {
              emptyRows.push(index)
            }
          }
        } else {
          for (let index = 0; index < updatedMatchData.quesMapsA[selectedRow][tabSelected].length; index++) {
            if (updatedMatchData.quesMapsA[selectedRow][tabSelected][index].isEmpty && updatedMatchData.quesMapsB[selectedRow][tabSelected][index].isEmpty) {
              emptyRows.push(index)
            }
          }
        }
      }
    }
    if (emptyRows.length > 0) {
      if (tabSelected === 'quesMaps') {
        updatedMatchData.quesMapsA = updatedMatchData.quesMapsA.filter((index: number) => !emptyRows.includes(index))
        updatedMatchData.quesMapsB = updatedMatchData.quesMapsB.filter((index: number) => !emptyRows.includes(index))
      } else {
        if (typeof selectedRow === 'number') {
          if (tabSelected === 'colMaps' && typeof selectedSubqRow === 'number') {
            updatedMatchData.quesMapsA[selectedRow].subqMaps[selectedSubqRow][tabSelected] = updatedMatchData.quesMapsA[selectedRow as number].subqMaps[selectedSubqRow][tabSelected].filter((index: number) => !emptyRows.includes(index))
            updatedMatchData.quesMapsB[selectedRow].subqMaps[selectedSubqRow][tabSelected] = updatedMatchData.quesMapsB[selectedRow as number].subqMaps[selectedSubqRow][tabSelected].filter((index: number) => !emptyRows.includes(index))
          } else {
            updatedMatchData.quesMapsA[selectedRow][tabSelected] = updatedMatchData.quesMapsA[selectedRow as number][tabSelected].filter((index: number) => !emptyRows.includes(index))
            updatedMatchData.quesMapsB[selectedRow][tabSelected] = updatedMatchData.quesMapsB[selectedRow as number][tabSelected].filter((index: number) => !emptyRows.includes(index))
          }
        }
      }
    } else {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'No empty rows found' } })
    }

    setMatchData(updatedMatchData)
  }

  const onOptionsDropdownItemClick = ({ item }: { item: { text: string } }) => {
    if (item.text === 'Add empty row') {
      addNewRow()
    } else if (item.text === 'Remove all empty rows') {
      onDeleteAllEmptyRows()
    }
  }

  const orderData: TODO =
    tabSelected === 'quesMaps' ?
      matchData.quesMapsA
      : tabSelected === 'colMaps' ?
        matchData.quesMapsA[selectedRow as number].subqMaps[selectedSubqRow as number][tabSelected]
        :
        matchData.quesMapsA[selectedRow as number][tabSelected]

  return (
    <>
      <div className="d-flex align-items-center workflow-tool-header h-48 border-bottom">
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="mb-0 strong mr-2">{workflowData.selectedTool.toolType} tool</h6>
            {workflowData.selectedTool.toolType === 'Match' && <MatchToolActions autoMatchType={autoMatchType} handleDisableMaps={handleDisableMaps} disabled={isActionButtonDisabled} handleSelectMatchType={handleSelectMatchType} />}
            <DropdownButton
              text={'Options'}
              className='btn-transparent icon-r'
              items={[{ text: 'Add empty row' }, { text: 'Remove all empty rows' }]}
              onItemClick={onOptionsDropdownItemClick}
            />
          </div>
          <div className='d-flex align-items-center'>
            <Switch id="isFullMoveInput" checked={isFullMove} onChange={(e) => setIsFullMove(e.value)} size="small" />
            <label className="m-0 ml-2 medium strong" htmlFor="isFullMoveInput">Full move</label>
            <button type='button' onClick={handleEditTool} className="btn btn-transparent pl-1 pr-2 ml-2" ><Icon type="dashboard-edit" className='mr-1 fill-primary' />Edit tool</button>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column h-100 workflow-tool-match" >
        <div className="d-flex flex-column h-100 border-top">
          <ul className="nav nav-pills content-tabs w-100 pl-2 border-bottom" style={{ zIndex: 10 }}>
            <li onClick={() => setTabSelected('quesMaps')} className="nav-item">
              <span className={`px-2 nav-link h-48 ${tabSelected === 'quesMaps' && 'active'}`}>Questions &#40;{(statuses?.length) || 0}&#41;</span>
            </li>
            <li onClick={() => setTabSelected('subqMaps')} className="nav-item" style={(!mapsData.subqMapsA || !mapsData.subqMapsA.length) || (!mapsData.subqMapsB || !mapsData.subqMapsB.length) ? { pointerEvents: 'none', opacity: '50%' } : undefined} >
              <span className={`px-3 nav-link ${tabSelected === 'subqMaps' && 'active'}`} >Subquestions</span>
            </li>
            <li onClick={() => setTabSelected('colMaps')} className="nav-item" style={(!mapsData.colMapsA || !mapsData.colMapsA.length) || (!mapsData.colMapsB || !mapsData.colMapsB.length) ? { pointerEvents: 'none', opacity: '50%' } : undefined}>
              <span className={`px-3 nav-link ${tabSelected === 'colMaps' && 'active'}`} >Columns</span>
            </li>
            <li onClick={() => setTabSelected('rowMaps')} className="nav-item" style={(!mapsData.rowMapsA || !mapsData.rowMapsA.length) || (!mapsData.rowMapsB || !mapsData.rowMapsB.length) ? { pointerEvents: 'none', opacity: '50%' } : undefined}>
              <span className={`px-3 nav-link ${tabSelected === 'rowMaps' && 'active'}`} >Rows</span>
            </li>
          </ul>
          {showSpinner ? <FullHeightSpinner /> : Object.keys(matchData).length ?
            <div className='overflow-auto' style={{ height: 'calc(100vh - 153px)' }}>
              <div className="row no-gutters sticky-top bg-white header" style={{ zIndex: 1 }}>
                <div className='order' />
                <div className='col d-flex align-items-center py-3 border-right'>
                  <input type="checkbox" className="k-checkbox checkbox-all" onChange={(e) => handleCheckAll('A', e.currentTarget.checked)} defaultChecked={false} />
                  <h6 className="m-0 pl-3 strong">Primary questions</h6>
                </div>
                {tabSelected === 'quesMaps' && <div className='col-2 border-right' />}
                <div className='col d-flex align-items-center py-3'>
                  <input type="checkbox" className="k-checkbox checkbox-all" onChange={(e) => handleCheckAll('B', e.currentTarget.checked)} defaultChecked={false} />
                  <h6 className="m-0 pl-3 strong">Secondary questions</h6>
                </div>
              </div>
              <div className="row no-gutters h-100">
                <div className='order'>
                  {
                    orderData?.map((el: QuesMap, key: number) => (
                      <div key={key} onClick={() => handleSelectRow(key)} style={{ height: '48px' }} className={`justify-content-center d-flex align-items-center border-right border-bottom ${(tabSelected === 'quesMaps' && selectedRow === key) || (tabSelected === 'subqMaps' && selectedSubqRow === key) ? 'row-selected' : ''}`}>
                        {
                          isEmptyRow(el, tabSelected, key) ?
                            <div className='d-flex justify-content-center align-items-center w-48 h-48 empty-row' onClick={(e) => onDeleteEmptyRow(e, tabSelected, key)}>
                              <span className='key'>{key + 1}</span>
                              <button type='button' className='btn btn-transparent p-05'>
                                <Icon type="delete-alt" />
                              </button>
                            </div>
                            :
                            <span>{key + 1}</span>
                        }
                      </div>
                    ))
                  }
                </div>
                <DragDropContext onDragStart={() => tabSelected === 'quesMaps' ? setSelectedRow(null) : tabSelected === 'subqMaps' ? setSelectedSubqRow(null) : {}} onDragEnd={(result) => onDragEnd(result, 'A')}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div ref={provided.innerRef} className='col'>
                        {mapsData[`${tabSelected}A`] && mapsData[`${tabSelected}A`].length && mapsData[`${tabSelected}A`].map((data: QuesMap, key: number) => (
                          <Draggable key={key} draggableId={key.toString()} index={key}>
                            {(provided, snapshot) => {
                              const style = {
                                ...provided.draggableProps.style,
                                height: 48
                              };
                              return (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  onClick={() => handleSelectRow(key)} key={key} className={`d-flex align-items-center border-bottom match-row ${data.isDisabled ? 'disabled' : ''} ${snapshot.isDragging ? 'dragging' : ''} ${(tabSelected === 'quesMaps' && selectedRow === key) || (tabSelected === 'subqMaps' && selectedSubqRow === key) ? 'row-selected' : ''}`} style={style}>
                                  <input type="checkbox" className="k-checkbox" checked={handleFilterCheckedItems('A', data.miextId) || false} onClick={(event) => handleSetRange(event, data.miextId, 'A')} onChange={(e) => handleCheckQuestion(e, data, 'A')} />
                                  <div ref={tabSelected === 'quesMaps' && key === selectedRow ? selectedRowRef : key === 0 ? rowHeaderRef : null} className="d-flex flex-column align-items-start">
                                    <h6 className={"m-0 pl-3"}>{data.qno || Object.values(data.textValues || {})[0]}</h6>
                                    <span className="text-muted m-0 pl-3">
                                      {tabSelected === 'quesMaps' ? Object.values(data.textValues || {})[0] : data.type ? `type: ${data.type}` : data.code ? `code: ${data.code}` : ''}
                                    </span>
                                  </div>
                                </div>
                              )
                            }}
                          </Draggable>
                        ))}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                {
                  tabSelected === 'quesMaps' &&
                  <div className='col-2'>
                    {statuses?.length && statuses.map((data: Status, key: number) => (
                      <div key={key} style={{ height: '48px' }} className={`${statusBgColor[data.type]} ${statusTextColor[data.type]} justify-content-center d-flex flex-column align-items-center border-bottom status ${(tabSelected === 'quesMaps' && selectedRow === key) ? 'row-selected' : ''}`}>{data.msgs && data.msgs.length ? data.msgs.map((item: string, idx: number) => <span key={idx}>{item}</span>) : null}</div>
                    ))}
                  </div>
                }
                <DragDropContext onDragStart={() => tabSelected === 'quesMaps' ? setSelectedRow(null) : tabSelected === 'subqMaps' ? setSelectedSubqRow(null) : {}} onDragEnd={(result) => onDragEnd(result, 'B')}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div ref={provided.innerRef} className='col'>
                        {mapsData[`${tabSelected}B`] && mapsData[`${tabSelected}B`].length && mapsData[`${tabSelected}B`].map((data: QuesMap, key: number) => (
                          <Draggable key={key} draggableId={key.toString()} index={key}>
                            {(provided, snapshot) => {
                              const style = {
                                ...provided.draggableProps.style,
                                height: 48
                              };
                              return (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  onClick={() => handleSelectRow(key)} key={key} className={`d-flex align-items-center border-bottom match-row ${data.isDisabled ? 'disabled' : ''} ${snapshot.isDragging ? 'dragging' : ''} ${(tabSelected === 'quesMaps' && selectedRow === key) || (tabSelected === 'subqMaps' && selectedSubqRow === key) ? 'row-selected' : ''}`} style={style}>
                                  <input type="checkbox" className="k-checkbox" checked={handleFilterCheckedItems('B', data.miextId) || false} onClick={(event) => handleSetRange(event, data.miextId, 'B')} onChange={(e) => handleCheckQuestion(e, data, 'B')} />
                                  <div className="d-flex flex-column align-items-start">
                                    <h6 className={`m-0 pl-3 ${data.isDisabled ? 'text-muted' : ''}`}>{data.qno || Object.values(data.textValues || {})[0]}</h6>
                                    <span className="text-muted m-0 pl-3">
                                      {tabSelected === 'quesMaps' ? Object.values(data.textValues || {})[0] : data.type ? `type: ${data.type}` : data.code ? `code: ${data.code}` : ''}
                                    </span>
                                  </div>
                                </div>
                              )
                            }}
                          </Draggable>
                        ))}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
            :
            <div className="h-100 d-flex flex-column align-items-center justify-content-center empty">
              <Icon type="match" className='fill-muted' />
              <span className="droppable-text h4 strong">Add dataset to run match workflow</span>
            </div>}
        </div>
      </div>
    </>
  )
}