import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { ErrorMessage } from '../../../../../shared/ErrorMessage/ErrorMessage';
import { fetchPutResOrJson, getThemeCss } from '../../../../../../services/services';

export default function CssThemeEditAlert({ advanced, handleClose, setThemeData, themeData, onUpdateThemeSettings, setShouldUpdatePreview }) {
  const dispatch = useDispatch()
  const { token } = useSelector((state) => state.tokenStateReducer);
  const [isUpdating, setIsUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { theData, themeId } = useSelector(theState => (theState.surveyInitialDataReducer));

  const returnValidJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return null;
    }
    return JSON.parse(str);
  }

  const handleContinue = () => {
    setIsUpdating(true)
    if (themeData.hasBeenEditedAsCss) {
      onUpdateThemeSettings(true)
    } else {
      if (theData.dcV2 === 1) {
        getThemeCss(`themes/${themeId}/css`, token)
          .then((res) => {
            const resJson = returnValidJson(res)
            if (resJson && (resJson.error || resJson.message)) {
              setIsUpdating(false)
              setErrorMessage(resJson.error ? resJson.error : resJson.message)
            } else {
              let body = {}
              if (res) { body = { css: res.toString() } } else { body = { css: "" } }
              fetchPutResOrJson(`themes/${themeData.id}`, token, body)
                .then(data => {
                  if (data.status === 200) {
                    dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Theme settings updated successfully' } })
                    setThemeData({ ...themeData, hasBeenEditedAsCss: !themeData.hasBeenEditedAsCss })
                    setShouldUpdatePreview(true)
                    handleClose()
                  } else if (data.error || data.message) {
                    setIsUpdating(false)
                    setErrorMessage(data.error ? data.error : data.message)
                  }
                })
            }
          })
      }
    }
  }

  return (
    <Dialog title={advanced ? "Customise CSS" : "Standard customisation"} onClose={handleClose} width={500} className="import-dialog">
      <div className="d-flex flex-column p-4">
        {advanced ?
          <p className='m-0'>Theme settings are <strong>not available</strong> when editing the theme's CSS. If you decide to go back to standard customisation theme settings will be reset to their default values.</p>
          :
          <p className='m-0'>Custom CSS is <strong>not available</strong> in standard theme customisation.<br />
            Going back to standard customisation will reset theme settings to their default values.</p>
        }
        {advanced ?
          <span className='mt-3'>Are you sure you want to proceed with CSS customisation</span>
          :
          <span className='mt-3'>Are you sure you want to go back to standard customisation?</span>}
        {
          errorMessage &&
          <div className="mt-3">
            <ErrorMessage type="alert" errorMessage={errorMessage} />
          </div>
        }
      </div>
      <DialogActionsBar>
        <button type='button' className="k-button btn btn-secondary" onClick={handleClose}>Cancel</button>
        <button type='button' disabled={isUpdating} className="k-button btn btn-primary" onClick={handleContinue}>
          {isUpdating && <span className="spinner-border spinner-border-sm mr-2" />}
          <span>Continue</span>
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}