import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Switch } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import type { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';

import { BaseDropDownList } from '../../../../../../shared/Inputs';
import type { RootState } from '../../../../../../../store/reducers/rootReducer';
import { fetchPostResOrJson as queueExportDocument } from '../../../../../../../services/services';

type Language = {
  code: string;
  isDefault: boolean;
  name: string;
}

interface Props {
  handleClose: () => void;
  languages: Language[]
}

export const SurveyDesignExportWordModal = ({ handleClose, languages }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const params: { name: string, survey: string } = useParams()
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0])
  const [exportWordBody, setExportWordBody] = useState({ includeLogic: false, includeDisabledElements: false })

  const updatedLanguages: TODO[] = []
  languages.forEach((item) => {
    updatedLanguages.push({
      name: `${item.name} (${item.code})`,
      code: item.code
    })
  })

  const handleExportWord = (e: TODO) => {
    const { name, value } = e.target;
    setExportWordBody({
      ...exportWordBody,
      [name]: value
    })
  }

  const onUpdateLanguageHandle = (event: DropDownListChangeEvent) => {
    setSelectedLanguage(event.target.value)
  }

  const exportWordDocument = () => {
    const body = {
      language: selectedLanguage.code,
      includeLogic: exportWordBody.includeLogic,
      includeDisabledElements: exportWordBody.includeDisabledElements,
      includedQuestionTypes: ["f", "h", "n", "m", "rn", "rm", "info"]
    }
    queueExportDocument(`/projects/${params.name}/surveys/${params.survey}/word`, token, body)
      .then((res: TODO) => {
        if (res.status === 202) {
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The document has been queued for export' } })
          handleClose()
        } else if (res.status === 500) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "Something went wrong" } })
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
        }
      })
  }

  return (
    <Dialog
      onClose={handleClose}
      title={"Export Survey to Word"}
      width={600}
      className="d-flex flex-column w-100">
      <div className="p-5">
        <div className='d-flex flex-column gap-lg'>
          <BaseDropDownList
            data={[{ name: "", code: "" }].concat(updatedLanguages)}
            value={selectedLanguage}
            label="Select a language"
            className="w-100"
            textField="name"
            dataItemKey="code"
            onChange={onUpdateLanguageHandle}
          />
          <div className='d-flex align-items-center justify-content-between'>
            <label htmlFor="includeDisabledElements" className='mb-0'>Include Disabled Elements</label>
            <Switch
              name="includeDisabledElements"
              onChange={handleExportWord}
              checked={exportWordBody.includeDisabledElements}
              size="small"
            />
          </div>
          <div className='d-flex align-items-center justify-content-between'>
            <label htmlFor="includeLogic" className='mb-0'>Include Logic</label>
            <Switch
              name="includeLogic"
              onChange={handleExportWord}
              checked={exportWordBody.includeLogic}
              size="small"
            />
          </div>
        </div>
      </div>

      <DialogActionsBar>
        <button type='button' className="k-button btn btn-secondary" onClick={handleClose}>
          Cancel
        </button>
        <button type='button' className="k-button btn btn-primary" onClick={exportWordDocument}>
          Export
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}
