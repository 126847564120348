import { Review } from '../../NewSurveyOptions/SurveyComments/types/surveyCommentsTypes';

export const surveyReviewsData = ({ surveyReviews, selectedItemReview, updatedSelectedItemReview }: { surveyReviews: Review[], selectedItemReview: Review, updatedSelectedItemReview: Review }) => {
  const findCurrentSurveyReview = surveyReviews.find((item) => item.questionId === selectedItemReview.questionId);

  const updatedCurrentSurveyReview: Review = { ...findCurrentSurveyReview, ...updatedSelectedItemReview };

  const updatedSurveyReviews = surveyReviews.map(item => item.questionId === updatedCurrentSurveyReview.questionId ? updatedCurrentSurveyReview : item);

  return updatedSurveyReviews
}