import { v4 as uuid } from 'uuid';

export const returnOnSelectionChange = (e, type, id, questions) => {
  let data = []
  if (type === 'definitions') {
    data = questions.map(item => {
      if (item.id === e.dataItem.id) {
        item.selected = !e.dataItem.selected
      }
      return item
    });
  }
  else if (type === 'categories') {
    let question = questions.find(el => el.id === id)
    let categories = []
    if (question.type !== 'rm' && question.type !== 'rn') {
      categories = question.rows.map(item => {
        if (item.id === e.dataItem.id) {
          item.selected = !e.dataItem.selected
        }
        return item
      })
      data = questions.map(el => {
        if (el.id === id) {
          el.rows = categories
        }
        return el
      })
    }
    else {
      categories = question.columns.map(item => {
        if (item.id === e.dataItem.id) {
          item.selected = !e.dataItem.selected
        }
        return item
      })
      data = questions.map(el => {
        if (el.id === id) {
          el.columns = categories
        }
        return el
      })
    }
  }
  else if (type === 'responseRows') {
    let question = questions.find(el => el.id === id)
    let categories = []
    categories = question.rows.map(item => {
      if (item.id === e.dataItem.id) {
        item.selected = !e.dataItem.selected
      }
      return item
    })
    data = questions.map(el => {
      if (el.id === id) {
        el.rows = categories
      }
      return el
    })
  }
  return (data)
}

export const returnOnHeaderChange = (e, type, id, questions) => {
  const checked = e.syntheticEvent.target.checked;
  let data = []
  if (type === 'definitions') {
    data = questions.map(item => {
      item.selected = checked
      return item
    });
  }
  else if (type === 'categories') {
    let question = questions.find(el => el.id === id)
    let categories = []
    if (question.type !== 'rm' && question.type !== 'rn') {
      categories = question.rows.map(row => {
        row.selected = checked
        return row
      })
      data = questions.map(el => {
        if (el.id === id) {
          el.rows = categories
        }
        return el
      })
    }
    else {
      categories = question.columns.map(col => {
        col.selected = checked
        return col
      })
      data = questions.map(el => {
        if (el.id === id) {
          el.columns = categories
        }
        return el
      })
    }
  }
  else if (type === 'responseRows') {
    let question = questions.find(el => el.id === id)
    let categories = []
    categories = question.rows.map(row => {
      row.selected = checked
      return row
    })
    data = questions.map(el => {
      if (el.id === id) {
        el.rows = categories
      }
      return el
    })
  }
  return (data)
}

export const returnSelectedDefinitions = (data) => {
  let questions = []
  data.forEach(el => {
    if (el.selected === true) {
      if (el.rows.length > 0) {
        let list = el.rows.map(row => ({ ...row, selected: false, active: true }))
        el.rows = list
      }
      if (el.columns && el.columns.length > 0) {
        let list = el.columns.map(col => ({ ...col, selected: false, active: true }))
        el.columns = list
      }
      questions.push(el)
    }
  })
  return questions
}

export const returnOnSwitchChange = (item, type, id, questions) => {
  let data = []
  if (type === 'categories') {
    let question = questions.find(el => el.id === id)
    let categories = []
    if (question.type !== 'rm' && question.type !== 'rn') {
      categories = question.rows.map(el => {
        if (el.id === item.id) {
          el.active = !item.active
        }
        return el
      })
      data = questions.map(el => {
        if (el.id === id) {
          el.rows = categories
        }
        return el
      })
    }
    else {
      categories = question.columns.map(el => {
        if (el.id === item.id) {
          el.active = !item.active
        }
        return el
      })
      data = questions.map(el => {
        if (el.id === id) {
          el.columns = categories
        }
        return el
      })
    }

  }
  else if (type === 'responseRows') {
    let question = questions.find(el => el.id === id)
    let categories = []
    categories = question.rows.map(el => {
      if (el.id === item.id) {
        el.active = !item.active
      }
      return el
    })
    data = questions.map(el => {
      if (el.id === id) {
        el.rows = categories
      }
      return el
    })

  }
  return (data)
}

export const returnAllEnabled = (id, type, questions) => {
  let data = []
  if (type === 'categories') {
    let question = questions.find(el => el.id === id)
    let categories = []
    if (question.type !== 'rm' && question.type !== 'rn') {
      categories = question.rows.map(row => {
        row.active = true
        return row
      })
      data = questions.map(el => {
        if (el.id === id) {
          el.rows = categories
        }
        return el
      })
    }
    else {
      categories = question.columns.map(col => {
        col.active = true
        return col
      })
      data = questions.map(el => {
        if (el.id === id) {
          el.columns = categories
        }
        return el
      })
    }
  }
  else if (type === 'responseRows') {
    let question = questions.find(el => el.id === id)
    let categories = []
    categories = question.rows.map(row => {
      row.active = true
      return row
    })
    data = questions.map(el => {
      if (el.id === id) {
        el.rows = categories
      }
      return el
    })
  }
  return (data)
}

export const returnAllDisabled = (id, type, questions) => {
  let data = []
  if (type === 'categories') {
    let question = questions.find(el => el.id === id)
    let categories = []
    if (question.type !== 'rm' && question.type !== 'rn') {
      categories = question.rows.map(row => {
        row.active = false
        return row
      })
      data = questions.map(el => {
        if (el.id === id) {
          el.rows = categories
        }
        return el
      })
    }
    else {
      categories = question.columns.map(col => {
        col.active = false
        return col
      })
      data = questions.map(el => {
        if (el.id === id) {
          el.columns = categories
        }
        return el
      })
    }
  }
  else if (type === 'responseRows') {
    let question = questions.find(el => el.id === id)
    let categories = []
    categories = question.rows.map(row => {
      row.active = false
      return row
    })
    data = questions.map(el => {
      if (el.id === id) {
        el.rows = categories
      }
      return el
    })
  }
  return (data)
}

export const returnMergedQuestions = (id, type, questions) => {
  let data = []
  let mergedQuestion = {
    isMerged: true,
    selected: false,
    active: true,
    code: '',
    id: uuid(),
    texts: { en: '' }
  }
  if (type === 'categories') {
    let question = questions.find(el => el.id === id)
    let categories = []
    if (question.type !== 'rm' && question.type !== 'rn') {
      categories = question.rows.map(el => {
        if (el.selected === true) {
          el.active = false
          el.selected = false
          if (mergedQuestion.code === '' && mergedQuestion.texts.en === '') {
            mergedQuestion = { ...mergedQuestion, code: el.code, texts: { en: el.texts.en } }
          }
          else {
            mergedQuestion = { ...mergedQuestion, code: `${mergedQuestion.code};${el.code}`, texts: { en: `${mergedQuestion.texts.en} & ${el.texts.en}` } }
          }
        }
        return el
      })
      data = questions.map(el => {
        if (el.id === id) {
          categories.unshift(mergedQuestion)
          el.rows = categories
        }
        return el
      })
    }
    else {
      categories = question.columns.map(el => {
        if (el.selected === true) {
          el.active = false
          el.selected = false
          if (mergedQuestion.code === '' && mergedQuestion.texts.en === '') {
            mergedQuestion = { ...mergedQuestion, code: el.code, texts: { en: el.texts.en } }
          }
          else {
            mergedQuestion = { ...mergedQuestion, code: `${mergedQuestion.code};${el.code}`, texts: { en: `${mergedQuestion.texts.en} & ${el.texts.en}` } }
          }
        }
        return el
      })
      data = questions.map(el => {
        if (el.id === id) {
          categories.unshift(mergedQuestion)
          el.columns = categories
        }
        return el
      })
    }
  }
  else if (type === 'responseRows') {
    let question = questions.find(el => el.id === id)
    let categories = []
    categories = question.rows.map(el => {
      if (el.selected === true) {
        el.active = false
        el.selected = false
        if (mergedQuestion.code === '' && mergedQuestion.texts.en === '') {
          mergedQuestion = { ...mergedQuestion, code: el.position, texts: { en: el.texts.en } }
        }
        else {
          mergedQuestion = { ...mergedQuestion, code: `${mergedQuestion.code};${el.position}`, texts: { en: `${mergedQuestion.texts.en} & ${el.texts.en}` } }
        }
      }
      return el
    })
    data = questions.map(el => {
      if (el.id === id) {
        categories.unshift(mergedQuestion)
        el.rows = categories
      }
      return el
    })
  }
  return (data)
}

export const returnOnInsertColumn = (id, type, questions) => {
  let data = []
  let newQuestion = {
    selected: false,
    active: true,
    code: '-',
    id: uuid(),
    texts: { en: 'No answer' }
  }
  if (type === 'categories') {
    let question = questions.find(el => el.id === id)
    let categories = []
    if (question.type !== 'rm' && question.type !== 'rn') {
      categories = question.rows
      data = questions.map(el => {
        if (el.id === id) {
          categories.unshift(newQuestion)
          el.rows = categories
        }
        return el
      })
    }
    else {
      categories = question.columns
      data = questions.map(el => {
        if (el.id === id) {
          categories.unshift(newQuestion)
          el.columns = categories
        }
        return el
      })
    }

  }
  else if (type === 'responseRows') {
    let question = questions.find(el => el.id === id)
    let categories = []
    categories = question.rows
    data = questions.map(el => {
      if (el.id === id) {
        categories.unshift(newQuestion)
        el.rows = categories
      }
      return el
    })
  }
  return (data)
}

export const returnOnDeleteColumn = (id, type, questions) => {
  let data = []
  if (type === 'categories') {
    let question = questions.find(el => el.id === id)
    let categories = []
    if (question.type !== 'rm' && question.type !== 'rn') {
      categories = question.rows
      let index = categories.findIndex(el => el.code === "-")
      data = questions.map(el => {
        if (el.id === id) {
          categories.splice(index, 1)
          el.rows = categories
        }
        return el
      })
    }
    else {
      categories = question.columns
      let index = categories.findIndex(el => el.code === "-")
      data = questions.map(el => {
        if (el.id === id) {
          categories.splice(index, 1)
          el.columns = categories
        }
        return el
      })
    }
  }
  else if (type === 'responseRows') {
    let question = questions.find(el => el.id === id)
    let categories = []
    categories = question.rows
    let index = categories.findIndex(el => el.code === "-")
    data = questions.map(el => {
      if (el.id === id) {
        categories.splice(index, 1)
        el.rows = categories
      }
      return el
    })
  }
  return (data)
}

export const returnWeightDefinitionBody = (questions, type) => {
  let list = []
  questions.forEach(el => {
    let question = {
      "id": el.qno.slice(0, -2),
      "subqIndex": 0,
      "isDisabled": false
    }
    if (el.rows) {
      let list = []
      el.rows.forEach(el => {
        if (el.code) {
          list.push({ "code": el.code, "isDisabled": !el.active })
        }
        else {
          list.push({ "code": el.position, "isDisabled": !el.active })
        }
      })
      question = { ...question, "rows": list }
    }
    if (el.columns) {
      let list = []
      el.columns.forEach(el => {
        list.push({ "code": el.code, "isDisabled": !el.active })
      })
      question = { ...question, "columns": list }
    }
    list.push(question)
  })

  let body = {
    "isRimWeighting": type === 'cell' ? false : true,
    "questions": list
  }

  return body
}

export const validateWeightId = (id, weightIds) => {
  let message = ''
  if (id === '') {
    message = 'Please enter Weight name'
  }
  else if (weightIds.includes(id)) {
    message = 'Another weight with this Id already exists. Please try another one.'
  }
  else if (!id.match(/^[a-z0-9]+$/i)) {
    message = 'Please match the requested weight Id format.'
  }
  else { message = '' }

  return message
}

export const returnWeightBody = (responseData, responseGridData) => {
  let body = responseData
  body.rims.forEach(rim => {
    rim.rimCells.forEach(el => {
      responseGridData.forEach(item => {
        if (el.expression === item.expression) {
          el.population = item.population
        }
      })
    })
  })
  body = { ...body, population: body.population * 1 }
  return body
}

export const returnResponseGridData = (data) => {
  let responseData = []
  data.rims.forEach(el =>
    el.rimCells.forEach(item =>
      responseData.push({ ...item, inEdit: true }))
  )
  return responseData
}
