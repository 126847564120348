import { useParams } from "react-router-dom";
import { useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs"
import { useDispatch, useSelector } from "react-redux";

import type { RootState } from "../../../../../store/reducers/rootReducer";
import { setBatch } from "../../../../../store/reducers/batchReducer/batchReducer";
import type { TargetingResponseType } from "../../types/audienceTargetingTypes";
import { fetchPutJsonData } from "../../../../../services/services";

type Props = {
  setShowDeleteModal: (show: boolean) => void;
  questionId: string | number;
  setQuestionId: (item: string | number) => void;
}

export const DeleteQuotaModal = ({ setShowDeleteModal, questionId, setQuestionId }: Props) => {
  const batchData = useSelector((state: RootState) => state.batchDataReducer);
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);

  const dispatch = useDispatch();
  const params: { projectId: string, audienceId: string, sampleId: string, batchId: string } = useParams();
  const { projectId, sampleId, audienceId, batchId } = params;

  const [isLoading, setIsLoading] = useState(false);

  const handleOnclose = () => {
    setQuestionId("");
    setShowDeleteModal(false);
  }

  const handleDeleteQuota = () => {
    setIsLoading(true);
    const updatedData = batchData.targeting?.quotas?.filter((item) => item.questionId !== questionId);

    const targetingData = {
      targetingRequest: {
        screenings: batchData.targeting?.screenings ? batchData.targeting.screenings : [],
        quotas: updatedData,
        existingId: null,
        type: 1,
      },
      country: batchData.market.country,
      language: batchData.market.language,
      etag: batchData.etag
    }

    fetchPutJsonData<TargetingResponseType>({ url: `au/a/projects/${projectId}/samples/${sampleId}/audiences/${audienceId}/batches/${batchId}/targeting`, token: token, body: targetingData, baseUrl: "audience" })
      .then((res) => {
        dispatch(setBatch({ ...batchData, targeting: res.targetingResponse, etag: res.etag }));
        dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Quota removed' } });
        setIsLoading(false);
        handleOnclose();
      })
      .catch((error) => {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error.errorMessage } });
        setIsLoading(false);
      })
  }

  return (
    <Dialog width={500} title="Delete quota" onClose={handleOnclose}>
      <div className="d-flex flex-column justify-content-between gap-md px-2">
        <p className="m-0 pt-2 px-2">
          Are you sure you want to delete the quota?
        </p>

        <DialogActionsBar>
          <button type='button' className="btn btn-secondary" onClick={handleOnclose}>Cancel</button>
          <button
            type='submit'
            className="btn btn-primary"
            onClick={handleDeleteQuota}
            disabled={isLoading}
          >
            {
              isLoading &&
              <span className="spinner-border spinner-border-sm mr-2" />
            }
            Delete
          </button>
        </DialogActionsBar>
      </div>
    </Dialog>
  )
}