import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { ErrorMessage } from '../../ErrorMessage/ErrorMessage';
import { fetchDeleteWithBody } from '../../../../services/services';
import { clearFileManager, updateCheckedFiles, updateFileManagerExpandedState, updateShowDeleteConfirmationModal } from '../../../../store/reducers/fileManagerStateReducer/fileManagerStateReducer';
import { RootState } from '../../../../store/reducers/rootReducer';

interface Props {
  type: string
  token: string
  updateData: () => void
}

export const FileManagerDeleteConfirmationModal = ({ updateData, type, token }: Props) => {
  const dispatch = useDispatch()
  const { expandedState, checkedFiles } = useSelector((state: RootState) => state.fileManagerStateReducer);
  const [errorMessage, setErrorMessage] = useState('')

  const onHide = () => dispatch(updateShowDeleteConfirmationModal(false))

  const onDeleteDirectoryHandler = () => {
    if (checkedFiles.length > 0) {
      const body = {
        files: checkedFiles.filter(item => !item.isDirectory).map(item => item.blobName),
        directories: checkedFiles.filter(item => item.isDirectory).map(item => item.directoryReference)
      }
      fetchDeleteWithBody(`files/${type}`, token, body)
        .then((res: any) => {
          if (res.status === 204) {
            if (expandedState.data && expandedState.data[0] && expandedState.data[0].contents && checkedFiles.length === expandedState.data[0].contents.length) {
              dispatch(updateFileManagerExpandedState({ ...expandedState, data: [], selected: null }))
              dispatch(clearFileManager())
            }
            dispatch(updateFileManagerExpandedState({ ...expandedState, selected: null }))
            dispatch(updateCheckedFiles([]))
            updateData()
            onHide()
          } else {
            setErrorMessage('Something went wrong, please try again.')
          }
        })
    }
  }

  return (
    <Dialog minWidth={500} title={`Delete`} onClose={onHide} className="import-dialog">
      <div className="p-4">
        <p className={`${errorMessage ? 'mb-4' : 'm-0'}`}>Are you sure you want to delete the selected items?</p>
        {
          errorMessage &&
          <ErrorMessage
            type={"alert"}
            errorMessage={errorMessage}
          />
        }
      </div>
      <DialogActionsBar>
        <button className="k-button btn btn-secondary" onClick={onHide}>Cancel</button>
        <button className="k-button btn btn-primary" onClick={onDeleteDirectoryHandler}>Delete</button>
      </DialogActionsBar>
    </Dialog >
  )
}