import { Fragment, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';

import usePrevious from '../../customHooks/usePrevious';
import { timeSince } from '../../helpers/timeSince/timeSince';
import type { RootState } from '../../../../store/reducers/rootReducer';

export const BreadcrumbSessionItem = () => {
  const dispatch = useDispatch();
  const tooltip: TODO = useRef(null);
  const { selectedSession } = useSelector((theState: RootState) => (theState.breadcrumbStateReducer));
  const prevSelectedSession: TODO = usePrevious(selectedSession);

  useEffect(() => {
    if (selectedSession.lastSaved !== prevSelectedSession?.lastSaved) {
      if (timeSince(new Date(selectedSession.lastSaved)).includes('minutes')) {
        setInterval(() => {
          dispatch({ type: 'UPDATE_SELECTED_SESSION_LAST_SAVED', payload: { lastSaved: selectedSession.lastSaved } })
        }, 60000)
      }
    }
  }, [dispatch, selectedSession, prevSelectedSession])

  return (
    <Fragment>
      <span style={{ cursor: 'default' }}>{selectedSession.name}</span>
      <Tooltip
        ref={tooltip}
        anchorElement="target"
        position="right"
      />
      <i
        title={`Last saved: ${timeSince(new Date(selectedSession.lastSaved))}`}
        className="fas fa-info-circle ml-2"
        onFocus={(event) => tooltip.current?.handleFocus(event)}
        onMouseOver={(event) => tooltip.current?.handleMouseOver(event)}
        onMouseOut={(event) => tooltip.current?.handleMouseOut(event)}
        onBlur={(event) => tooltip.current?.handleBlur(event)}
      />
    </Fragment>
  )
}