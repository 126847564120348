import type { IconType } from "../../../../interfaces/iconInterfaces/iconInterfaces"

const defaultWidth: number = 24
const defaultHeight: number = 24

export const returnIconSize = (icon: IconType, size: string | undefined) => {
  const defaultIconWidth = icon?.width ? icon.width : defaultWidth
  const defaultIconHeight = icon?.height ? icon.height : defaultHeight

  switch (size) {
    case 'sm':
      return {
        width: defaultIconWidth / 1.5,
        height: defaultIconHeight / 1.5
      }
    case 'lg':
      return {
        width: defaultIconWidth * 1.5,
        height: defaultIconHeight * 1.5
      }
    case 'xl':
      return {
        width: defaultIconWidth * 2,
        height: defaultIconHeight * 2
      }
    default:
      return {
        width: defaultIconWidth,
        height: defaultIconHeight
      }
  }
}