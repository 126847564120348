import React from 'react';

import { analysisTypes, analysisActions, /* projectActions */ } from '../shareProjectAnalysisSwitchData';
import ShareProjectFunctionalitySwitch from '../ShareProjectFunctionalitySwitch/ShareProjectFunctionalitySwitch';
import ShareProjectAccessControl from '../../ShareProjectAccessControl/ShareProjectAccessControl';

export default ({ formData, onChangeInputHandler, selectedTab, isShared }) => {
  if (selectedTab === 0) {
    return (
      <table className="table table-striped table-bordered">
        <tbody>
          {
            analysisTypes.map((type, key) => (
              <React.Fragment key={key}>
                <ShareProjectFunctionalitySwitch
                  element={type}
                  formData={formData}
                  onChangeInputHandler={onChangeInputHandler}
                  functionalityType={'analysisTypes'} />
              </React.Fragment>
            ))
          }
        </tbody>
      </table>
    )
  } else if (selectedTab === 1) {
    return (
      <table className="table table-striped table-bordered">
        <tbody>
          {
            analysisActions.map((type, key) => (
              <React.Fragment key={key}>
                <ShareProjectFunctionalitySwitch
                  element={type}
                  formData={formData}
                  onChangeInputHandler={onChangeInputHandler}
                  functionalityType={'analysisActions'} />
              </React.Fragment>
            ))
          }
        </tbody>
      </table>
    )
  }
  // else if (selectedTab === 2) {
  //   return (
  //     <table className="table table-striped table-bordered">
  //       <tbody>
  //         {
  //           projectActions.map((type, key) => (
  //             <React.Fragment key={key}>
  //               <ShareProjectFunctionalitySwitch
  //                 element={type}
  //                 formData={formData}
  //                 onChangeInputHandler={onChangeInputHandler}
  //                 functionalityType={'projectActions'} />
  //             </React.Fragment>
  //           ))
  //         }
  //       </tbody>
  //     </table>
  //   )
  // } 
  else if (selectedTab === 2) {
    return (
      <ShareProjectAccessControl
        isShared={isShared}
        formData={formData}
        onChangeInputHandler={onChangeInputHandler}
      />
    )
  }
}