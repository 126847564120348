import returnDefineRangeData from '../returnDefineRangeData/returnDefineRangeData';

export default (itemId, e, questionType, onCheck, index, rangeItems, data) => {
  let updateRangeItems = rangeItems
  if (data.filter(el => el.selected === true).length === 1 && updateRangeItems.length === 0) {
    updateRangeItems.push({ index: data.findIndex(el => el.selected === true), id: data.find(el => el.selected === true).id })
  }
  else if (data.filter(el => el.selected === true).length > 1 && updateRangeItems.length === 0) {
    let lastId = data.filter(el => el.selected === true)[data.filter(el => el.selected === true).length - 1].id
    updateRangeItems.push({ index: data.findIndex(el => el.id === lastId), id: lastId })
  }
  if (questionType === "question") {
    onCheck(itemId)
  } else if (questionType === "customQuestion") {
    onCheck(itemId)
  } else if (questionType === "firstItem") {
    onCheck(e, index, 'firstColumn', itemId)
  } else if (questionType === "secondItem") {
    onCheck(e, index, 'scndColumn', itemId)
  }
  const rangeData = returnDefineRangeData(updateRangeItems, data, itemId)

  return rangeData;
}
