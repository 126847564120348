import disableOptionGroupByPlan from "../disableOptionGroupByPlan/disableOptionGroupByPlan"

export default (groups, user) => {
  let groupList = []
  groups.forEach(el => {
    let disabled = disableOptionGroupByPlan(el.text, user, groups)
    groupList.push({ ...el, disabled: disabled, selected: false })
  })
  groupList.sort(function (a, b) { return a.disabled - b.disabled });

  groupList.forEach((el, key) => {
    if (key === 0) {
      el.selected = true
      delete el.disabled
    }
    else { delete el.disabled }
  })
  return groupList
}