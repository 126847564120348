import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from '../../../shared/Icon/Icon';
import { formatDate } from '../../../shared/DateUtils/DateUtils';
import type { RootState } from '../../../../store/reducers/rootReducer';
import { DropdownButton } from '../../../shared/DropdownButton/DropdownButton';
import { PublishCustomQuestionModal } from './PublishCustomQuestionModal/PublishCustomQuestionModal';
import { CloneSurveyCustomQuestionModal } from './CloneSurveyCustomQuestionModal/CloneSurveyCustomQuestionModal';
import { DeleteSurveyCustomQuestionModal } from './DeleteSurveyCustomQuestionModal/DeleteSurveyCustomQuestionModal';
import { CreateSurveyCustomQuestionModal } from './CreateSurveyCustomQuestionModal/CreateSurveyCustomQuestionModal';
import { returnSupportedTypesValue } from '../../../shared/helpers/returnSupportedTypesValue/returnSupportedTypesValue';
import { SurveyCustomQuestionsOverviewTabContent } from './SurveyCustomQuestionsOverviewTabContent/SurveyCustomQuestionsOverviewTabContent';
import { fetchGetJson } from '../../../../services/services';

export const SurveyCustomTabContent: React.FC = () => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const [allCustomers, setAllCustomers] = React.useState<TODO[]>([]);
  const {
    sort,
    tags,
    statusFilter,
    tagsFilter,
    customersFilter,
    dcVersionFilter,
    searchValue,
    showOverview,
    customQuestions,
    shouldUpdateData,
    showDeleteCustomQuestionModal,
    showCreateCustomQuestionModal,
    showCloneCustomQuestionModal,
    showPublishCustomQuestionModal
  } = useSelector((theState: RootState) => theState.surveyCustomQuestionsStateReducer)

  useEffect(() => {
    if (shouldUpdateData) {
      dispatch({ type: 'UPDATE_CUSTOM_QUESTIONS_DATA', payload: false })
      fetchGetJson("su/customquestions?all=true", token)
        .then((res: TODO) => {
          if (res && !res.error && !res.message) {
            const updatedTags = extractUniqueTags(res)
            setAllCustomers(extractUniqueCustomers(res))
            dispatch({ type: 'UPDATE_CUSTOM_QUESTIONS', payload: res })
            dispatch({ type: 'UPDATE_CUSTOM_QUESTION_TAGS', payload: updatedTags })
          } else {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
          }
        })
    }
  }, [shouldUpdateData, token, dispatch])

  const updateData = () => {
    dispatch({ type: 'UPDATE_CUSTOM_QUESTIONS_DATA', payload: true })
  }

  const moreDropdownItemClick = (e: TODO, action: string, customQues: TODO) => {
    e.stopPropagation();
    if (action === 'Delete') {
      dispatch({ type: 'UPDATE_SHOW_DELETE_CUSTOM_QUESTION_MODAL', payload: { show: true, customQuestion: customQues } })
    } else if (action === 'Duplicate') {
      dispatch({ type: 'UPDATE_SHOW_CLONE_CUSTOM_QUESTION_MODAL', payload: { show: true, customQuestion: customQues } })
    } else if (action === 'Publish' || action === 'Unpublish') {
      dispatch({ type: 'UPDATE_SHOW_PUBLISH_CUSTOM_QUESTION_MODAL', payload: { show: true, customQuestion: customQues } })
    }
  }

  const customQuestionRenderMoreDropdownItem = ({ text, disabled, icon }: TODO, customQues: TODO) => {
    if (text === 'Delete') {
      return (
        <React.Fragment>
          <div
            style={{ height: '1px' }}
            className="w-100 my-1 bg-light" />
          <button
            type="button"
            className="dropdown-item text-danger px-3"
            disabled={disabled}
            onClick={(e) => moreDropdownItemClick(e, text, customQues)}>
            <span>{text}</span>
          </button>
        </React.Fragment>
      )
    }
    if (text === 'Header') {
      return (
        <div style={{ cursor: 'default' }} id='header-item' className='d-flex flex-column'>
          <strong className='px-4 pb-2 pt-1 medium strong'>{customQues.name}</strong>
          <div
            style={{ height: '1px' }}
            className="w-100 my-1 bg-light"
          />
        </div>
      )
    }
    return (
      <button
        type="button"
        className="dropdown-item px-3"
        disabled={disabled}
        onClick={(e) => moreDropdownItemClick(e, text, customQues)}>
        <Icon type={icon} className='mr-2' />
        <span>{text}</span>
      </button>
    )
  }

  const onSortHandler = (field: string) => {
    if (sort.field === field) {
      dispatch({ type: 'UPDATE_SORT', payload: { field, dir: sort.dir === 'asc' ? 'desc' : 'asc' } })
    } else {
      dispatch({ type: 'UPDATE_SORT', payload: { field, dir: 'asc' } })
    }
  }

  const statusDropdownItemRender = (item: { text: string }) => {
    if (item.text === 'Header') {
      return (
        <div id='header-item' className='d-flex flex-column'>
          <span className='px-4 pb-2 pt-1 medium strong'>Status</span>
          <div
            style={{ height: '1px' }}
            className="w-100 my-1 bg-light"
          />
        </div>
      )
    }
    return (
      <button type='button' onClick={() => dispatch({ type: 'UPDATE_STATUS_FILTER', payload: item.text })} className="dropdown-item px-3 d-flex align-items-center">
        <input onChange={() => dispatch({ type: 'UPDATE_STATUS_FILTER', payload: item.text })} checked={statusFilter === item.text} type="radio" className='mr-2' />
        <span>{item.text}</span>
      </button>
    )
  }

  const dcVersionDropdownItemRender = (item: { text: string }) => {
    if (item.text === 'Header') {
      return (
        <div id='header-item' className='d-flex flex-column'>
          <span className='px-4 pb-2 pt-1 medium strong'>DC Version</span>
          <div
            style={{ height: '1px' }}
            className="w-100 my-1 bg-light"
          />
        </div>
      )
    }
    return (
      <button type='button' onClick={() => dispatch({ type: 'UPDATE_DC_VERSION_FILTER', payload: item.text })} className="dropdown-item px-3 d-flex align-items-center">
        <input onChange={() => dispatch({ type: 'UPDATE_DC_VERSION_FILTER', payload: item.text })} checked={dcVersionFilter === item.text} type="radio" className='mr-2' />
        <span>{item.text}</span>
      </button>
    )
  }

  const onUpdateFilterTags = (tag: string) => {
    if (tagsFilter.includes(tag)) {
      dispatch({ type: 'UPDATE_TAGS_FILTER', payload: tagsFilter.filter((el: string) => el !== tag) })
    } else {
      dispatch({ type: 'UPDATE_TAGS_FILTER', payload: [...tagsFilter, tag] })
    }
  }

  const onUpdateFilterCustomers = (customer: string) => {
    if (customersFilter.includes(customer)) {
      dispatch({ type: 'UPDATE_CUSTOMERS_FILTER', payload: customersFilter.filter((el: string) => el !== customer) })
    } else {
      dispatch({ type: 'UPDATE_CUSTOMERS_FILTER', payload: [...customersFilter, customer] })
    }
  }

  const customerAccessDropdownItemRender = (item: { text: string }) => {
    if (item.text === 'Header') {
      return (
        <div id='header-item' className='d-flex flex-column'>
          <span className='px-4 pb-2 pt-1 medium strong'>Customer access</span>
          <div
            style={{ height: '1px' }}
            className="w-100 my-1 bg-light"
          />
        </div>
      )
    }
    return (
      <div className="d-flex flex-wrap px-3">
        {
          allCustomers?.map((customer: string, key: number) => (
            <div key={key} onClick={() => onUpdateFilterCustomers(customer)} className={`d-flex align-items-center rounded cursor-pointer mx-1 my-1 ${customersFilter.includes(customer) ? 'filter-selected' : 'filter-default'}`}>
              {customersFilter.includes(customer) ? <Icon type='checkmark' className='mr-1' /> : null}
              <span className={`medium ${customersFilter.includes(customer) ? 'text-analyze' : 'text-primary'}`}>{customer}</span>
            </div>
          ))
        }
      </div>
    )
  }

  const tagsDropdownItemRender = (item: { text: string }) => {
    if (item.text === 'Header') {
      return (
        <div id='header-item' className='d-flex flex-column'>
          <span className='px-4 pb-2 pt-1 medium strong'>Tags</span>
          <div
            style={{ height: '1px' }}
            className="w-100 my-1 bg-light"
          />
        </div>
      )
    }
    return (
      <div className="d-flex flex-wrap px-3">
        {
          tags?.map((tag: TODO, key: number) => (
            <div key={key} onClick={() => onUpdateFilterTags(tag)} className={`d-flex align-items-center rounded cursor-pointer mx-1 my-1 ${tagsFilter.includes(tag) ? 'filter-selected' : 'filter-default'}`}>
              {tagsFilter.includes(tag) ? <Icon type='checkmark' className='mr-1' /> : null}
              <span className={`medium ${tagsFilter.includes(tag) ? 'text-analyze' : 'text-primary'}`}>{tag}</span>
            </div>
          ))
        }
      </div>
    )
  }

  const sortObjectByStringDateBoolean = (field: string, dir: string) => {
    if (dir === 'asc') {
      return (a: TODO, b: TODO) => {
        if (field !== 'published' && field !== 'dataCollectionVersion') {
          return a[field]?.localeCompare(b[field])
        }
        return a[field] - b[field];
      }
    }
    return (a: TODO, b: TODO) => {
      if (field !== 'published' && field !== 'dataCollectionVersion') {
        return b[field]?.localeCompare(a[field])
      }
      return b[field] - a[field];
    }
  }

  const SortingArrow = ({ field, text }: { field: string, text: string }) => {
    return (
      <div className="d-flex align-items-center">
        <span className='mr-0'>{text}</span>
        {
          sort.field === field ?
            sort.dir === 'asc' ?
              <div style={{ transform: 'rotate(180deg)' }}>
                <Icon type="chevron-mini-down-alt" />
              </div>
              :
              <div>
                <Icon type="chevron-mini-down-alt" />
              </div>
            : null
        }
      </div>
    )
  }

  const extractUniqueCustomers = (customQuestions: { customerAccess: { name: string }[] }[]): string[] => {
    // Get all customers from all custom questions and only keep unique ones
    const uniqueCustomers: Set<string> = new Set();

    customQuestions.forEach((ques) => {
      ques.customerAccess?.forEach((customer) => {
        uniqueCustomers.add(customer.name);
      });
    });

    return Array.from(uniqueCustomers);
  };

  const extractUniqueTags = (customQuestions: { tags: string[] }[]): string[] => {
    // Get all tags from all custom questions and only keep unique ones
    const uniqueTags: Set<string> = new Set();

    customQuestions.forEach((ques) => {
      ques.tags.forEach((tag) => {
        uniqueTags.add(tag);
      });
    });

    return Array.from(uniqueTags);
  };

  const headerItemElement = document.querySelectorAll('#header-item')
  if (headerItemElement) {
    headerItemElement.forEach((element) => {
      element.addEventListener("click", (e) => e.stopPropagation());
    })
  }

  const onRowClickHandler = (e: TODO, el: TODO) => {
    if (e.target.id !== 'dropdown-button-content') {
      dispatch({ type: 'UPDATE_SHOW_OVERVIEW', payload: { show: true, customQuestion: el } })
    }
  }

  return (
    <React.Fragment>
      {
        showPublishCustomQuestionModal.show &&
        <PublishCustomQuestionModal
          customQuestion={showPublishCustomQuestionModal.customQuestion}
          updateData={updateData}
          onHide={() => dispatch({ type: 'UPDATE_SHOW_PUBLISH_CUSTOM_QUESTION_MODAL', payload: { show: false, customQuestion: null } })} />
      }
      {
        showCreateCustomQuestionModal.show &&
        <CreateSurveyCustomQuestionModal
          customQuestion={showCreateCustomQuestionModal.customQuestion}
          updateData={updateData}
          tags={tags}
          onHide={() => dispatch({ type: 'UPDATE_SHOW_CREATE_CUSTOM_QUESTION_MODAL', payload: { show: false, customQuestion: null } })} />
      }
      {
        showDeleteCustomQuestionModal.show &&
        <DeleteSurveyCustomQuestionModal
          customQuestion={showDeleteCustomQuestionModal.customQuestion}
          updateData={updateData}
          handleClose={() => dispatch({ type: 'UPDATE_SHOW_DELETE_CUSTOM_QUESTION_MODAL', payload: { show: false, customQuestion: null } })} />
      }
      {
        showCloneCustomQuestionModal.show &&
        <CloneSurveyCustomQuestionModal
          updateData={updateData}
          customQuestion={showCloneCustomQuestionModal.customQuestion}
          onHide={() => dispatch({ type: 'UPDATE_SHOW_CLONE_CUSTOM_QUESTION_MODAL', payload: { show: false, customQuestion: null } })} />
      }
      {
        showOverview.show ?
          <SurveyCustomQuestionsOverviewTabContent tags={tags} />
          :
          <div style={{ backgroundColor: '#f3f4f4' }} className='custom-questions-container w-100 h-100 flex-grow-1 p-4 '>
            <div className="d-flex flex-column w-100 h-100 overflow-hidden bg-white p-0 answer-layout">
              <div className="d-flex flex-column justify-content-between p-4 border-bottom">
                <div className='d-flex h-32 align-items-center mt-1 mb-2'>
                  <h5 className="stronger">Custom questions</h5>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <button type='button' className="btn btn-primary btn-icon icon-l mr-2 pl-2" onClick={() => dispatch({ type: 'UPDATE_SHOW_CREATE_CUSTOM_QUESTION_MODAL', payload: { show: true, customQuestion: null } })}>
                      <Icon type='add' className='mr-1 fill-white' />
                      <span>Create new</span>
                    </button>
                    <div className='h-100 mx-1 bg-light' style={{ width: '1px' }} />
                    <DropdownButton
                      hideChevron={true}
                      text="DC Version"
                      icon="ic-tags"
                      direction='left'
                      className='btn-secondary icon-l mx-2'
                      items={[{ text: 'Header' }, { text: 'All' }, { text: 'V1' }, { text: 'V2' }]}
                      renderItem={dcVersionDropdownItemRender}
                    />
                    {
                      dcVersionFilter !== 'All' &&
                      <button type='button' onClick={() => dispatch({ type: 'UPDATE_DC_VERSION_FILTER', payload: "All" })} className="btn btn-secondary border-0 py-1 px-2 mr-2">
                        <Icon type='ic-tags' className='mr-2' />
                        <span>{dcVersionFilter}</span>
                        <Icon type='close' className='ml-2' />
                      </button>
                    }
                    <DropdownButton
                      hideChevron={true}
                      text="Status"
                      icon="ic-status"
                      direction='left'
                      className='btn-secondary icon-l mr-2'
                      items={[{ text: 'Header' }, { text: 'All' }, { text: 'Published' }, { text: 'Draft' }]}
                      renderItem={statusDropdownItemRender}
                    />
                    {
                      statusFilter !== 'All' &&
                      <button type='button' onClick={() => dispatch({ type: 'UPDATE_STATUS_FILTER', payload: "All" })} className="btn btn-secondary border-0 py-1 px-2 mr-2">
                        <Icon type='ic-status' className='mr-2' />
                        <span>{statusFilter}</span>
                        <Icon type='close' className='ml-2' />
                      </button>
                    }
                    <DropdownButton
                      hideChevron={true}
                      text="Tags"
                      icon='ic-tags'
                      direction='left'
                      className='btn-secondary icon-l py-1 mr-2'
                      items={[{ text: 'Header' }, { text: 'Tags' }]}
                      renderItem={tagsDropdownItemRender}
                    />
                    {
                      tagsFilter.length > 0 && tagsFilter.map((tag: string, key: number) => (
                        <button type='button' key={key} onClick={() => dispatch({ type: 'UPDATE_TAGS_FILTER', payload: customersFilter.filter((el: string) => el !== tag) })} className="btn btn-secondary border-0 py-1 px-2 mr-2">
                          <Icon type='ic-tags' className='mr-1' />
                          <span>{tag}</span>
                          <Icon type='close' className='ml-1' />
                        </button>
                      ))
                    }
                    <DropdownButton
                      hideChevron={true}
                      text='Customers'
                      icon='ic-tags'
                      direction='left'
                      className='btn-secondary icon-l mr-2'
                      items={[{ text: 'Header' }, { text: 'Customer access' }]}
                      renderItem={customerAccessDropdownItemRender}
                    />
                    {
                      customersFilter.length > 0 && customersFilter.map((customer: string, key: number) => (
                        <button type='button' key={key} onClick={() => dispatch({ type: 'UPDATE_CUSTOMERS_FILTER', payload: customersFilter.filter((el: string) => el !== customer) })} className="btn btn-secondary border-0 py-1 px-2 mr-2">
                          <Icon type='ic-tags' className='mr-1' />
                          <span>{customer}</span>
                          <Icon type='close' className='ml-1' />
                        </button>
                      ))
                    }
                  </div>
                  <div className="d-flex">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control search-input"
                      style={{ width: '16rem' }}
                      value={searchValue}
                      onChange={(e) => dispatch({ type: 'UPDATE_SEARCH_VALUE', payload: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div style={{ flex: '1 1 auto', height: '0px' }} className="overflow-auto px-4">
                <table className="table table-hover custom-questions-table mb-0">
                  <thead style={{ userSelect: 'none' }}>
                    <tr>
                      <th onClick={() => onSortHandler('name')} className='cursor-pointer' scope="col">
                        <SortingArrow field='name' text='Name' />
                      </th>
                      <th scope='col'>Customers</th>
                      <th scope="col">Supported types</th>
                      <th onClick={() => onSortHandler('createdByName')} className='cursor-pointer' scope="col">
                        <SortingArrow field='createdByName' text='Created by' />
                      </th>
                      <th onClick={() => onSortHandler('createdUtc')} className='cursor-pointer' scope="col">
                        <SortingArrow field='createdUtc' text='Date' />
                      </th>
                      <th scope="col">Tags</th>
                      <th onClick={() => onSortHandler('dataCollectionVersion')} className='cursor-pointer' scope="col">
                        <SortingArrow field='dataCollectionVersion' text='DC Version' />
                      </th>
                      <th onClick={() => onSortHandler('published')} className='cursor-pointer' scope="col">
                        <SortingArrow field='published' text='Status' />
                      </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {
                      customQuestions?.sort((a: TODO, b: TODO) => sortObjectByStringDateBoolean(sort.field, sort.dir)(a, b))
                        .filter((el: { name: string }) => el.name.toLowerCase().includes(searchValue.toLowerCase()))
                        .filter((el: { published: boolean }) => statusFilter === 'All' ? true : el.published === (statusFilter === 'Published'))
                        .filter((el: { dataCollectionVersion: number }) => dcVersionFilter === 'All' ? true : el.dataCollectionVersion === (dcVersionFilter === 'V1' ? 1 : 2))
                        .filter((el: { tags: string[] }) => tagsFilter.length === 0 ? true : tagsFilter.every((tag: string) => el.tags.includes(tag)))
                        .filter((el: { customerAccess: string[] }) => customersFilter.length === 0 ? true : customersFilter.every((customer: string) => el.customerAccess?.map((el: TODO) => el.name).includes(customer)))
                        .map((el: TODO, i: number) => (
                          <tr onClick={(e) => onRowClickHandler(e, el)} className='cursor-pointer' key={i}>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className={`mr-2 bg-custom-questions-${el.iconColor ? el.iconColor : 'grey'}`} style={{ borderRadius: '0.375rem', padding: '0.125rem' }}>
                                  <Icon type={el.icon ? el.icon : 'ic-carousel'} />
                                </div>
                                <span className='strong text-primary'>{el.name}</span>
                              </div>
                            </td>
                            <td>
                              {
                                el.customerAccess?.map((customer: { name: string }, key: number) => (
                                  <div key={key} className='badge badge-light mr-1 my-1'>
                                    <span>{customer.name}</span>
                                  </div>
                                ))}
                            </td>
                            <td>
                              {
                                returnSupportedTypesValue(el.supportedTypes).map((type, key) => (
                                  <div key={key} className='badge badge-light mr-1 my-1'>
                                    <span>{type.text}</span>
                                  </div>
                                ))
                              }
                            </td>
                            <td>{el.createdByName}</td>
                            <td>{formatDate(el.createdUtc, "DATE_MED")}</td>
                            <td>
                              {
                                el.tags.map((tag: string, key: number) => (
                                  <div key={key} className={"d-inline-flex badge badge-light px-2 mr-1 my-1"}>
                                    <span>{tag}</span>
                                  </div>
                                )
                                )
                              }
                            </td>
                            <td>V{el.dataCollectionVersion} </td>
                            <td>
                              <span className={`badge ${el.published ? 'badge-success-light' : 'badge-light'}`}>{el.published ? 'Published' : 'Draft'}</span>
                            </td>
                            <td>
                              <div className='w-100 d-flex justify-content-end'>
                                <DropdownButton
                                  hideChevron={true}
                                  className='p-0 btn-transparent more'
                                  renderItem={(data) => customQuestionRenderMoreDropdownItem(data, el)}
                                  items={[{ text: 'Header' }, { text: el.published ? 'Unpublish' : 'Publish', icon: 'help-external' }, { text: 'Duplicate', icon: 'copy' }, { text: 'Delete' }]}
                                />
                              </div>
                            </td>
                          </tr>
                        ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      }
    </React.Fragment>
  )
}