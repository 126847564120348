import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog } from '@progress/kendo-react-dialogs'
import { Tooltip } from '@progress/kendo-react-tooltip'

import { Icon } from '../../shared/Icon/Icon'
import type { RootState } from '../../../store/reducers/rootReducer'
import type { ReconciliationGkData, Summary } from '../../../interfaces/gateKeeperInterfaces/gateKeeperInterfaces'
import { fetchGetJson } from '../../../services/services'

interface Props {
  reconciliationData: ReconciliationGkData | undefined
  setReconciliationData: Function
  onHide: Function
}

export const GKOverviewReconciliationModal = ({ onHide, reconciliationData, setReconciliationData }: Props) => {
  const [searchInput, setSearchInput] = useState<string>("")
  const [didMount, setDidMount] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [fetchSearchData, setFetchSearchFetchData] = useState<boolean>(false)
  const [loadMoreToken, setLoadMoreToken] = useState<string | null>("")
  const dispatch = useDispatch()
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);

  useEffect(() => {
    if (!didMount) {
      setDidMount(true)
      setIsLoading(true)
      fetchGetJson(`au/r?pageSize=${15}`, token)
        .then((res: TODO) => {
          setIsLoading(false)
          if (res) {
            if (res.continuationToken) {
              setLoadMoreToken(res.continuationToken)
            }
            if (Array.isArray(res.items)) {
              setReconciliationData(res)
            }
          } else {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.details ? res.error : res.message } })
          }
        }).catch((res: TODO) => {
          setIsLoading(false)
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.status !== 200 ? `Error get Reconciliations: ${res.detail}` : res.message } });
        })
    }
  }, [didMount, dispatch, token, setReconciliationData])

  useEffect(() => {
    if (searchInput !== "") {
      const fetchDataTimer = setTimeout(() => {
        setFetchSearchFetchData(true)
      }, 1000)

      return () => clearTimeout(fetchDataTimer)
    }
    setDidMount(false)
  }, [searchInput])

  const handleExport = (id: string) => {
    setIsLoading(true)
    fetchGetJson(`au/r/${id}/export`, token)
      .then(() => {
        const reconItem = reconciliationData?.items.find(item => item.id === id)
        if (reconItem) {
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The reconciliation has been queued for download' } })
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "Error export" } })
        }
        setIsLoading(false)
      }).catch((res: TODO) => {
        setIsLoading(false)
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `Export file: ${res.detail}` || res.message } });
      })
  }

  useEffect(() => {
    if (fetchSearchData) {
      setIsLoading(true)
      setFetchSearchFetchData(false)
      fetchGetJson(`au/r?name=${searchInput}`, token).then((res: TODO) => {
        if (res.error || res.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
        } else {
          setReconciliationData(res)
          setLoadMoreToken(res.continuationToken)
        }
        setIsLoading(false)
      })
    }
  }, [token, dispatch, searchInput, fetchSearchData, setReconciliationData])

  const handleLoadMore = () => {
    setIsLoading(true)
    // setFetchSearchFetchData(false)
    fetchGetJson(`au/r?continuationToken=${loadMoreToken}&pageSize=${10}`, token).then((res: TODO) => {
      if (res && !res.error && !res.message) {
        if (res.continuationToken) {
          setLoadMoreToken(res.continuationToken)
        } else {
          setLoadMoreToken("")
        }
        if (Array.isArray(res.items)) {
          const dataCopy = reconciliationData ? reconciliationData.items : []
          setReconciliationData({ ...reconciliationData, items: [...dataCopy, ...res.items] })
        }
      } else {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
      }
      setIsLoading(false)
    })

  }

  return (
    <Dialog width={"80%"} height={"80%"} contentStyle={{ padding: 0 }} className='reconcile-modal' title={"Reconciliation overview"} onClose={() => onHide("Reconciliation Overview")}>
      <div className='board-container reconcile-container d-flex justify-content-center flex-column w-100 h-100' >
        <div className='d-flex justify-content-between px-4 pt-4 pb-2' >
          <div className="input-group w-100 h-32">
            <input
              type="text"
              value={searchInput}
              className="form-control search-input w-100"
              placeholder={"Search"}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
        </div>
        <div className='board-content-container reconcile-content d-flex justify-content-center align-items-center h-100 overflow-y' >
          <div className={"table target-table mb-0 pb-0 h-100"} >
            {reconciliationData ?
              (reconciliationData.items.length < 1 ?
                <div className='text-center h-100 d-flex align-items-center justify-content-center' >
                  <p>No Data Found</p>
                </div> :
                <div className="accordion w-100" id="accordionExample">
                  <div className="accordion-item">
                    <table className="mb-0 w-100 ">
                      <thead>
                        <tr className='text-muted bg-white px-3 py-3 m-0 position-sticky'>
                          <th className='text-muted normal px-4'>Name</th>
                          <th className='text-muted normal px-4' >Created by</th>
                          <th className='text-muted normal px-4' >Created date</th>
                          <th />
                          <th />
                          <th />
                          <th />
                        </tr>
                      </thead>
                      {reconciliationData.items.map((item: TODO, index: number) => (
                        <React.Fragment key={index}>
                          <tbody >
                            <tr key={index} id={`heading${index}`} data-toggle="collapse" data-target={`#main${index}`} aria-expanded="false" aria-controls="main" className='cursor-pointer'>
                              <td className='text-left text-primary px-4 py-2 bg-white strong border-top-0 border-bottom' width={"25%"} >{item.name}</td>
                              <td className='text-left text-primary px-4 py-2 bg-white border-top-0 border-bottom' width={"20%"}>{item.createdByName}</td>
                              <td className='text-left text-primary px-4 py-2 bg-white border-top-0 border-bottom' width={"20%"} >{item.createdUtc.slice(0, 10)}</td>
                              <td className='text-left px-4 py-2 bg-white border-top-0 border-bottom' >
                                <span className="text-nowrap alert py-1 px-2 m-0 mr-4 span-default small" >{item.ignoredCount} terminates</span>
                              </td>
                              <td className='text-left px-4 py-2 bg-white border-top-0 border-bottom'>
                                <span className="text-nowrap alert py-1 px-2 m-0 span-success small">{item.successCount} accepted completes</span>
                              </td>
                              <td className='bg-white py-2 border-top-0 border-bottom'>
                                <Tooltip anchorElement="target" position="top">
                                  <button type='button' onClick={() => handleExport(item.id)} className='btn p-05 m-0 btn-transparent' title="Export">
                                    <Icon type='export' className="pe-none" />
                                  </button>
                                </Tooltip>
                              </td>
                              <td className='bg-white py-2 border-top-0 border-bottom'>
                                <div className='d-flex w-100 justify-content-end'>
                                  <button type='button' className='btn btn-transparent p-05'>
                                    <Icon type="chevron-mini-down-alt" />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tbody
                            id={`main${index}`}
                            className="accordion-collapse collapse"
                            aria-labelledby={`heading${index}`}
                            data-parent="#accordionExample">
                            <tr style={{ width: "100%" }} className='w-100'>
                              <th className='text-muted normal px-4 border-top-0 border-bottom' >Supplier</th>
                              <th className='text-muted normal px-4 border-top-0 border-bottom'>Reconciled to term</th>
                              <th className='text-muted normal px-4 border-top-0 border-bottom' >Total accepted completes</th>
                              <th className='border-top-0 border-bottom' />
                              <th className='border-top-0 border-bottom' />
                              <th className='border-top-0 border-bottom' />
                              <th className='border-top-0 border-bottom' />
                            </tr>
                            {item.supplierSummary.length < 1 ?
                              <tr style={{ width: "100%" }} key={index} className='medium border-top border-bottom w-100'>
                                <td className='text-center text-primary' colSpan={7} >No Supplier Found</td>
                              </tr> :
                              item.supplierSummary?.map((item: Summary, index: number) => (
                                <tr style={{ width: "100%" }} key={index} className='medium border-top border-bottom w-100'>
                                  <td className='text-left text-primary px-4 py-3 border-top-0 border-bottom' width={"25%"} >{item.platform ? item.platform : "-"}</td>
                                  <td className='text-left text-primary px-4 py-3 border-top-0 border-bottom' width={"20%"}>{item.terminatedCount}</td>
                                  <td className='text-left text-primary px-4 py-3 border-top-0 border-bottom' width={"20%"} >{item.completedCount}</td>
                                  <td className='py-3 border-top-0 border-bottom' />
                                  <td className='py-3 border-top-0 border-bottom' />
                                  <td className='py-3 border-top-0 border-bottom' />
                                  <td className='py-3 border-top-0 border-bottom' />
                                </tr>
                              ))}
                          </tbody>
                        </React.Fragment>
                      ))}
                    </table>
                  </div>
                </div>

              ) :
              <>
                {
                  isLoading ?
                    <div className='h-75 d-flex align-items-center justify-content-center'>
                      <span className="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true" />
                    </div>
                    :
                    <div className='h-75 text-muted d-flex align-items-center justify-content-center'>No reconciled IDs found</div>
                }
              </>
            }
          </div>
        </div>
        {loadMoreToken &&
          <div className="d-flex flex-grow-1 p-4 justify-content-start">
            <button type='button' onClick={() => handleLoadMore()} className="btn btn-icon icon-l btn-shadow">
              <Icon type="refresh" className='mr-05' />
              Load more entries
            </button>
          </div>
        }
      </div>
    </Dialog >
  )
}