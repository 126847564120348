import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import type { RootState } from "../../../store/reducers/rootReducer";
import { fetchGetJson as getSubAccounts } from "../../../services/services";

export const useFetchSubAccountsData = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { shouldUpdateSubAccounts } = useSelector((theState: RootState) => (theState.adminReducer));
  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    if (shouldUpdateSubAccounts || !didMount) {
      setDidMount(true)
      dispatch({ type: 'UPDATE_SUB_ACCOUNTS', payload: false })
      getSubAccounts("sub-accounts", token)
        .then((res: TODO) => {
          if (res && !res.message && !res.error) {
            dispatch({ type: 'SET_SUB_ACCOUNTS', payload: res })
          } else {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.message || res.error } })
          }
        })
    }
  }, [dispatch, shouldUpdateSubAccounts, didMount, token])
}