import { Advanced } from './Advanced/Advanced';
import { Dimensions } from './Dimensions/Dimensions';
import { Filters } from './Filters/Filters';
import { General } from './General/General';
import { Replicates } from './Replicates/Replicates';

interface Props {
  step: string,
  token: string,
  onNextStep: (type: string) => void
  onReviewAndSubmit: () => void
}

export const PanelDesignSteps = ({ step, token, onNextStep, onReviewAndSubmit }: Props) => {
  if (step === 'General') {
    return (
      <General step={step} onNextStep={onNextStep} onReviewAndSubmit={onReviewAndSubmit} />
    )
  } else if (step === 'Dimensions') {
    return (
      <Dimensions step={step} onNextStep={onNextStep} onReviewAndSubmit={onReviewAndSubmit} token={token} />
    )
  } else if (step === 'Filters') {
    return (
      <Filters step={step} onNextStep={onNextStep} onReviewAndSubmit={onReviewAndSubmit} />
    )
  } else if (step === 'Replicates') {
    return (
      <Replicates step={step} onNextStep={onNextStep} onReviewAndSubmit={onReviewAndSubmit} />
    )
  } else if (step === 'Advanced') {
    return (
      <Advanced step={step} onNextStep={onNextStep} onReviewAndSubmit={onReviewAndSubmit} />
    )
  } else {
    return (
      <div></div>
    )
  }
}