import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Switch } from '@progress/kendo-react-inputs';

interface Props {
  closeModal: () => void
  saveModal: () => void
  addUserInputValue: string
  handleAddUserInputChange: (e: any) => void
  error: {
    show: boolean,
    message: string
  }
  isAdmin: boolean
  setIsAdmin: (e: any) => void
}

export const AddUserModal = ({ closeModal, saveModal, addUserInputValue, handleAddUserInputChange, error, isAdmin, setIsAdmin }: Props) => {
  return (
    <Dialog width="500px" height="240px" className="import-dialog no-wrap" title="Add user" onClose={() => closeModal()}>
      <div className="d-flex flex-column p-4">
        <div className="d-flex flex-row justify-content-center align-items-center mb-2">
          <label className='w-50'>Email address</label>
          <input type="email" className="form-control w-50" value={addUserInputValue} onChange={(e) => handleAddUserInputChange(e)} />
        </div>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <label className='w-50'>Make user an advanced user</label>
          <div className='w-50'>
            <Switch checked={isAdmin} onChange={(e) => setIsAdmin(e.target.value)} name="isAdmin" size="small" />
          </div>
        </div>
      </div>
      {error.show && <small className="form-text p-2 mx-2 text-danger">{error.message}</small>}
      <DialogActionsBar>
        <button className="k-button btn btn-secondary" onClick={() => closeModal()}>
          Cancel
        </button>
        <button onClick={() => saveModal()} className="k-button k-button-solid-primary text-white">
          Save
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}
