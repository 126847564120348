import type { Tab } from "../../../../interfaces/adminInterfaces/adminInterfaces";
import type { User } from "../../../../interfaces/userInterface/userInterface";

export const returnTabs = (user: User) => {
  let tabs: Tab[] = [];

  if (user.role === "owner" || user.role === "admin") {
    tabs = [
      { title: "Users", icon: "fa fas fa-user", id: "Users" },
      { title: "Groups", icon: "fa fas fa-users", id: "Groups" },
      { title: "Tags", icon: "fa fas fa-tags", id: "Tags" },
      { title: "Email", icon: "fa fas fa-envelope", id: "Email" },
      { title: "SMS", icon: "fa fas fa-sms", id: "SMS" },
      { title: "Audit Logs", icon: "fa fas fa-th-list", id: "AuditLogs" },
      { title: "Email Templates", icon: "fa fas fa-mail-bulk", id: "EmailTemplates" },
      { title: "Customizations", icon: "fa fas fa-wrench", id: "Customizations" },
    ];
  }

  if (user.canCreateSubAccounts) {
    tabs.push({ title: "Sub Accounts", icon: "fa fas fa-user-circle", id: "SubAccounts" });
    tabs.push({ title: "API Keys", icon: "fa fas fa-key", id: "APIKeys" });
  } else {
    tabs.push({ title: "API Keys", icon: "fa fas fa-key", id: "APIKeys" });
  }

  return tabs;
};
