import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { BaseDropDownList } from "../../shared/Inputs";
import { Tooltip } from '@progress/kendo-react-tooltip';

import { Icon } from "../../shared/Icon/Icon";
import { returnTabTitle } from "../pinboardHelpers";
import SidebarPagesContent from "./SidebarPagesContent/SidebarPagesContent";
import SidebarFilterContent from "./SidebarFilterContent/SidebarFilterContent";
import SidebarSettingsContent from "./SidebarSettingsContent/SidebarSettingsContent";
import type { PinboardPage } from "../../../interfaces/pinboardInterfaces/pinboardInterfaces";
import SidebarPageLayoutContent from "./SidebarPageLayoutContent/SidebarPageLayoutContent";
import { returnQuestionIcon } from "../../Analysis/Analyze/components/shared/helpers/returnQuestionIcon/returnQuestionIcon";

import { fetchGetJson } from "../../../services/services";

interface Props {
  token: string
  searchState: { value: string, type: string }
  setSearchState: (obj: { value: string, type: string }) => void
  loadingNewObject: boolean
  savingLayout: boolean
  showSidebar: { show: boolean, type: string }
  setShowSidebar: (obj: { show: boolean, type: string }) => void
  analysisData: TODO
  setAnalysisData: TODO
  currentProjectId: string
  dragItem: (e: TODO, data: TODO) => void
  setLoadingPinboard: (value: boolean) => void
}

const PinboardSidebarContents = ({ token, searchState, setSearchState, loadingNewObject, savingLayout, showSidebar, setShowSidebar, analysisData, setAnalysisData, currentProjectId, dragItem, setLoadingPinboard, }: Props) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState<TODO>({ title: "", content: "", url: "", selectedProject: null, selectedDataset: null, selectedSession: null, selectedQuestion: null })
  const [pageLayout, setPageLayout] = useState<PinboardPage>()
  const [draggingItem, setDraggingItem] = useState<{ isDragging: boolean, type: string }>({ isDragging: false, type: "" });

  const closeButton = <Tooltip openDelay={100} position='bottom' anchorElement={'target'}><button type="button" className="btn btn-transparent p-1" onClick={() => setShowSidebar({ show: false, type: showSidebar.type })} title="Close"><Icon type="close" className="pe-none" /></button></Tooltip>

  const updateFormData = (e: TODO) => {
    const updatedFormData = { ...formData }

    if (e.target.name === "selectedProject") {
      updatedFormData.selectedProject = e.value
      updatedFormData.selectedDataset = null
      updatedFormData.selectedSession = null
      setFormData(updatedFormData)
      getDatasets(e.value.id);
    } else if (e.target.name === "selectedDataset") {
      updatedFormData.selectedDataset = e.value
      updatedFormData.selectedSession = null
      setFormData(updatedFormData)
      getSessions(formData.selectedProject.id, e.value.id);
    } else {
      updatedFormData[e.target.name] = e.target.value
      setFormData(updatedFormData)
    }
  }

  const getProjects = () => {
    fetchGetJson("/projects", token)
      .then((res: TODO) => {
        if (res.error || res.message) {
          dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: res.error ? res.error : res.message } });
        } else {
          if (Array.isArray(res)) {
            fetchGetJson(`pb/projects/${currentProjectId}/documents/datasets`, token)
              .then((result: TODO) => {
                if (result.error || result.message) {
                  dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: result.error ? result.error : result.message } });
                  setAnalysisData({ ...analysisData, projects: res, projectsLoaded: true, datasetsLoaded: false })
                  return
                }
                if (Array.isArray(result)) {
                  const updatedFormData = { ...formData }
                  updatedFormData.selectedProject = res.find(el => el.id === currentProjectId)
                  setAnalysisData({ ...analysisData, projects: res, projectsLoaded: true, datasets: result, datasetsLoaded: true, sessions: [] })
                  setFormData(updatedFormData)
                  return
                }
              })
          }
        }
      })
  }

  const getDatasets = (projectId: string) => {
    // fetchGetJson(`projects/${projectId}/documents`, token)
    fetchGetJson(`pb/projects/${projectId}/documents/datasets`, token)
      .then((res: TODO) => {
        if (res.error || res.message) {
          dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: res.error ? res.error : res.message } });
        } else {
          if (Array.isArray(res)) {
            setAnalysisData({ ...analysisData, datasets: res, datasetsLoaded: true, sessions: [] })
          }
        }
      })
  }

  const getSessions = (projectId: string, datasetId: string) => {
    fetchGetJson(`projects/${projectId}/sessions/${datasetId}`, token)
      .then((res: TODO) => {
        if (res.error || res.message) {
          dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: res.error ? res.error : res.message } });
        } else {
          if (Array.isArray(res)) {
            setAnalysisData({ ...analysisData, sessions: res })
          }
        }
      })
  }

  const getSessionData = (projectId: string, datasetId: string, sessionId: string) => {
    fetchGetJson(`projects/${projectId}/sessions/${datasetId}/${sessionId}/data`, token)
      .then((res: TODO) => {
        if (res.error || res.message) {
          dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: res.error ? res.error : res.message } });
        } else {
          const updatedSessionData: TODO[] = [];
          for (const tab in res) {
            if (res[tab].firstColumn?.length > 0 && tab !== "dataState") {
              const extraTabData = returnTabTitle(tab);
              updatedSessionData.push({
                ...extraTabData,
                questions: res[tab].firstColumn
              })
            }
          }
          setAnalysisData({ ...analysisData, sessionData: updatedSessionData, backendSessionData: res })
        }
      })
  }

  // const searchRender = (type: string) => {
  //   return (
  //     <div>
  //       <i className="far fa-search text-muted sidebar-input-icon" />
  //       <input
  //         type="text"
  //         className="sidebar-input sidebar-search"
  //         value={searchState.value}
  //         onChange={(e) => setSearchState({ value: e.target.value, type: type })}
  //         placeholder={`Search ${type === "add" ? "elements" : type === "pages" ? "pages" : "filter"}`}
  //       />
  //     </div>
  //   )
  // }

  const openSessionHandler = (session: TODO) => {
    setFormData({ ...formData, selectedSession: session })
    getSessionData(formData.selectedProject.id, formData.selectedDataset.id, session.id);
    setShowSidebar({ show: true, type: "openSession" })
  }

  const dragItemHandler = (e: TODO, type: string, analysisItem?: TODO) => {
    if (type !== "analysis") {
      setDraggingItem({ isDragging: true, type: type });
      dragItem(e, { type: type })
      setTimeout(() => { setDraggingItem({ isDragging: false, type: type }) }, 1)
    } else {
      setDraggingItem({ isDragging: true, type: type });
      dragItem(e, { type: "analysis", data: analysisItem })
      setTimeout(() => { setDraggingItem({ isDragging: false, type: type }) }, 1)
    }
  }

  switch (showSidebar.type) {
    case "add":
      return (
        <Fragment>
          <div className="header border-bottom flex-shrink-0">
            <span className="ml-1">Add element</span>
            {closeButton}
          </div>
          <div className="d-flex flex-column h-100 overflow-auto pt-3">
            <div className="sidebar-element" onClick={() => setShowSidebar({ show: true, type: "addAnalysis" })}>
              <div>
                <Icon type="pie-chart" className="mr-2" />
                <span>Analysis object</span>
              </div>
              <Icon type="chevron-right" />
            </div>
            <div draggable={!loadingNewObject && !savingLayout} unselectable="on" onDragStart={e => dragItemHandler(e, "image")}
              title={loadingNewObject || savingLayout ? "Cannot create a new object loading a previous object or updating the grid layout" : ""}
              className={`sidebar-element dragging ${loadingNewObject || savingLayout ? "draggable-disabled" : ""} ${draggingItem.isDragging && draggingItem.type === "image" ? "dragging-item cursor-pointer" : ""}`}>
              <div>
                <Icon type="image-outline" className="mr-2" />
                <span>Image</span>
              </div>
              <div className="handle">
                <Icon type="draggable" />
              </div>
            </div>
            <div draggable={!loadingNewObject && !savingLayout} unselectable="on" onDragStart={e => dragItemHandler(e, "text")}
              title={loadingNewObject || savingLayout ? "Cannot create a new object loading a previous object or updating the grid layout" : ""}
              className={`sidebar-element dragging ${loadingNewObject || savingLayout ? "draggable-disabled" : ""} ${draggingItem.isDragging && draggingItem.type === "text" ? "dragging-item cursor-pointer" : ""}`}>
              <div>
                <Icon type="text" className="mr-2" />
                <span>Text</span>
              </div>
              <div className="handle">
                <Icon type="draggable" />
              </div>
            </div>
          </div>
        </Fragment >
      )
    case "addAnalysis":
      if (!analysisData.projectsLoaded) {
        getProjects();
      }
      return (
        <Fragment>
          <div className="header border-bottom flex-shrink-0">
            <div className="d-flex align-items-center">
              <button type="button" className="btn btn-transparent p-1" onClick={() => setShowSidebar({ show: true, type: "add" })}><Icon type="chevron-left" /></button>
              <span>Analysis object</span>
            </div>
            {closeButton}
          </div>
          <div className="flex-shrink-0 border-bottom pb-3">
            <span>Project</span>
            <BaseDropDownList
              data={analysisData.projects}
              textField="name"
              name="selectedProject"
              dataItemKey="id"
              value={formData.selectedProject}
              onChange={updateFormData}
              className="mb-3 mt-1"
              style={{ width: '100%' }}
              filterable={true} />
            <span>Dataset</span>
            <BaseDropDownList
              data={analysisData.datasets}
              textField="name"
              name="selectedDataset"
              dataItemKey="id"
              disabled={!formData.selectedProject}
              value={formData.selectedDataset}
              onChange={updateFormData}
              className="mt-1"
              style={{ width: '100%' }}
              filterable={true} />
          </div>
          <div className="d-flex flex-column h-100 overflow-auto">
            <div className="mb-2">Sessions</div>
            {analysisData.sessions.length > 0 ?
              analysisData.sessions.map((session: TODO, index: number) => (
                <div className="session-element" onClick={() => openSessionHandler(session)} key={index}>
                  <div>
                    <div className="text-primary mb-1"><strong>{session.name}</strong></div>
                    <div className="text-primary small">{session.description}</div>
                    <div className="text-muted small">{new Date(session.createdUtc).toLocaleString()}</div>
                    {/* <div className="text-muted small">{session.createdByName}</div> */}
                  </div>
                  <div className="d-flex flex-column text-muted">
                    <Icon type="dashboard-file" />
                  </div>
                </div>
              ))
              :
              <div className="no-sessions text-muted">No sessions found</div>
            }
          </div>
        </Fragment >
      )
    case "openSession":
      return (
        <Fragment>
          <div className="header border-bottom flex-shrink-0">
            <div className="d-flex align-items-center">
              <button type="button" className="btn btn-transparent p-1" onClick={() => setShowSidebar({ show: true, type: "addAnalysis" })}><Icon type="chevron-left" /></button>
              <span>{formData.selectedSession.name}</span>
            </div>
            {closeButton}
          </div>
          <div className="d-flex flex-column h-100 overflow-auto">
            {analysisData.sessionData.map((analysisTab: TODO, index: number) => {
              return (
                <div key={index}>
                  <div className="mb-2 mt-1">{analysisTab.title}</div>
                  {analysisTab.questions.map((question: TODO, quesIndex: number) => {
                    const quesIcon = returnQuestionIcon(question.type)
                    return (
                      <div draggable={!loadingNewObject && !savingLayout} unselectable="on"
                        onDragStart={e => dragItem(e, { type: "analysis", data: { ...formData, selectedQuestion: { ...question, analysisType: analysisTab } } })}
                        title={loadingNewObject || savingLayout ? "Cannot create a new object loading a previous object or updating the grid layout" : ""}
                        className={`sidebar-element dragging question  ${loadingNewObject || savingLayout ? "draggable-disabled" : ""} ${draggingItem.isDragging && draggingItem.type === "analysis" ? "dragging-item" : ""}`} key={quesIndex}>
                        <div>
                          {/* @ts-ignore */}
                          <div className="question-icon mr-2">
                            <Icon type={quesIcon} />
                          </div>
                          <span>{question.title}</span>
                        </div>
                        <div>
                          <Icon type="more" style={{ transform: "translate(80%, 0)" }} />
                          <Icon type="more" />
                        </div>
                      </div>
                    )
                  })}
                </div >
              )
            })}
          </div>
        </Fragment >
      );
    case "pages":
      return (
        <SidebarPagesContent
          token={token}
          // searchRender={searchRender}
          setLoadingPinboard={setLoadingPinboard}
          hideSidebar={() => setShowSidebar({ show: false, type: showSidebar.type })}
          openPageOptions={(page) => { setShowSidebar({ show: true, type: "pageOptions" }); setPageLayout(page) }}
        />
      )
    case "pageOptions":
      return (
        <SidebarPageLayoutContent
          token={token}
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
          pinboardPage={pageLayout}
        />
      )
    case "filter":
      return (
        <SidebarFilterContent
          token={token}
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
          searchState={searchState}
          setSearchState={setSearchState} />
      )
    case "settings":
      return (
        <SidebarSettingsContent
          token={token}
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar} />
      )
    default:
      return <></>;
  }
}

export default PinboardSidebarContents;