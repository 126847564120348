import { Skeleton } from '../../../../shared/Skeleton/Skeleton';

export const AdminUsersTableSkeleton = () => {
  return (
    <table className="table table-striped admin-table mt-3">
      <thead>
        <tr>
          <th scope='col' />
          <th scope="col">Name</th>
          <th scope="col">Email</th>
          <th scope="col">Date created</th>
          <th className='text-center' scope="col">User type</th>
          <th className='text-center' scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        {
          [...Array(11).keys()].map((tableRow) => (
            <tr key={tableRow}>
              <td style={{ width: '60px' }}>
                <Skeleton shape='circle' className='w-36 h-36' />
              </td>
              <th scope='row'>
                <Skeleton className='h-24' style={{ width: '200px' }} />
              </th>
              <td><Skeleton className='h-24' style={{ width: '300px' }} /></td>
              <td><Skeleton className='h-24' style={{ maxWidth: '150px' }} /></td>
              <td>
                <div className="d-flex justify-content-center">
                  <Skeleton className='h-24' style={{ maxWidth: '150px' }} />
                </div>
              </td>
              <td>
                <div className="d-flex justify-content-center gap-lg admin-table-actions">
                  <Skeleton className='h-32 w-32' />
                  <Skeleton className='h-32 w-32' />
                </div>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  )
}