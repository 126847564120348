import { type FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { InputChangeEvent } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Stepper, type StepperChangeEvent } from '@progress/kendo-react-layout';

import { useFetchTags } from '../../../../shared/customHooks/useFetchTags';
import type { RootState } from '../../../../../store/reducers/rootReducer';
import { ErrorMessage } from '../../../../shared/ErrorMessage/ErrorMessage';
import { EmailProviderModalContent } from '../EmailProviderModalContent/EmailProviderModalContent';
import { sendTestEmailHandler } from '../../../../shared/helpers/sendTestEmailHandler/sendTestEmailHandler';
import type { EmailProvider } from '../../../../../interfaces/emailProviderInterfaces/emailProviderInterfaces';
import { useUpdateEmailProvidersFormData } from '../../../../shared/customHooks/useUpdateEmailProvidersFormData';
import { EmailProvidersActionBars } from '../EditEmailProviderModal/EmailProvidersActionBars/EmailProvidersActionBars';
import { fetchPostJson as createSendgridProvider, fetchPutJson as updateSendgridProvider } from '../../../../../services/services';

const items = [{ label: 'General settings' }, { label: 'SendGrid' }];

interface Props {
  emailProvider?: EmailProvider | null
  handleClose: () => void
}

type SendGridProviderFormData = {
  providerName: string
  providerDescription: string
  tags: { name: string }[] | null
  apiKey: string
}

export const EditSendgridProviderModal = ({ handleClose, emailProvider }: Props) => {
  const dispatch = useDispatch()
  const [stepperValue, setStepperValue] = useState(0);
  const [isInProgress, setIsInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sendTestEmailMessage, setSendTestEmailMessage] = useState(false)
  const [formData, setFormData] = useState<SendGridProviderFormData>({ providerName: '', providerDescription: '', tags: [], apiKey: '' })
  const { emailProvidersErrorMessage } = useSelector((theState: RootState) => theState.adminReducer);
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const tags = useFetchTags(token)

  useUpdateEmailProvidersFormData(token, (val) => setFormData(val), emailProvider)

  const onChangeHandler = (e: InputChangeEvent, name: string) => {
    setFormData({
      ...formData,
      [name]: e.target.value
    })
  }

  const handleChange = (e: StepperChangeEvent) => {
    if (formData.providerName) {
      setStepperValue(e.value);
    }
  };

  const onSubmitHandler = (e: FormEvent) => {
    e.preventDefault()
    if (stepperValue === 0) {
      setStepperValue(stepperValue + 1)
    } else {
      if (emailProvider) {
        const body = {
          tags: formData.tags,
          name: formData.providerName,
          description: formData.providerDescription,
          apiKey: formData.apiKey
        }
        setIsLoading(true);
        updateSendgridProvider(`emailproviders/${emailProvider.id}`, token, body)
          .then((res: TODO) => {
            setIsLoading(false);
            if (res && (!res.error || !res.message)) {
              dispatch({ type: 'UPDATE_EMAIL_PROVIDERS', payload: true })
              dispatch({ type: 'UPDATE_SHOW_SENDGRID_PROVIDERS_MODAL', payload: { emailProvider: null, show: false } })
            } else {
              dispatch({ type: 'UPDATE_EMAIL_PROVIDERS_ERROR_MESSAGE', payload: 'Something went wrong, please try again!' })
            }
          })
      } else {
        const body = {
          tags: formData.tags,
          name: formData.providerName,
          description: formData.providerDescription,
          apiKey: formData.apiKey
        }
        setIsLoading(true);
        createSendgridProvider("emailproviders/sendgrid", token, body)
          .then((res: TODO) => {
            setIsLoading(false);
            if (res && (!res.error || !res.message)) {
              dispatch({ type: 'UPDATE_EMAIL_PROVIDERS', payload: true })
              dispatch({ type: 'UPDATE_SHOW_SENDGRID_PROVIDERS_MODAL', payload: { emailProvider: null, show: false } })
            } else {
              dispatch({ type: 'UPDATE_EMAIL_PROVIDERS_ERROR_MESSAGE', payload: 'Something went wrong, please try again!' })
            }
          })
      }
    }
  }

  const onSendTestEmailHandler = () => {
    sendTestEmailHandler('sendgrid', dispatch, formData, token, (val: boolean) => setIsInProgress(val), (val: boolean) => setSendTestEmailMessage(val))
  }

  return (
    <Dialog
      width="40%"
      title={emailProvider ? 'Edit email provider' : 'Create email provider'}
      className="email-provider-modal"
      onClose={handleClose}>
      <form onSubmit={onSubmitHandler} className="k-form d-flex flex-column h-100">
        <div className="px-4 pt-4 d-flex flex-column" style={{ flex: '1 1 auto' }}>
          <div className="pb-3">
            <Stepper value={stepperValue} onChange={handleChange} items={items} />
          </div>

          <EmailProviderModalContent
            tags={tags}
            formData={formData}
            providerType={"sendgrid"}
            emailProvider={emailProvider}
            stepperValue={stepperValue}
            onChangeHandler={onChangeHandler}
          />

          {
            emailProvidersErrorMessage &&
            <div className="mt-4">
              <ErrorMessage
                type="alert"
                errorMessage={emailProvidersErrorMessage} />
            </div>
          }
          {
            sendTestEmailMessage &&
            <div className="alert alert-success alert-dismissible fade show mt-4" role="alert">
              <span>The test email has been sent successfully!</span>
              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          }
        </div>

        <DialogActionsBar>
          <EmailProvidersActionBars
            formData={formData}
            isLoading={isLoading}
            isInProgress={isInProgress}
            stepperValue={stepperValue}
            emailProvider={emailProvider}
            handleClose={handleClose}
            onSendTestEmailHandler={onSendTestEmailHandler}
            setStepperValue={(val) => setStepperValue(val)} />
        </DialogActionsBar>
      </form>
    </Dialog>
  )
}