import { CommentStatus } from "./types/surveyCommentsTypes";

export const EmptySurveyComments = ({ commentStatus }: { commentStatus: CommentStatus }) => {
  let comment: string = "";

  switch (commentStatus) {
    case CommentStatus.RESOLVED:
      comment = "No resolved questions"
      break;
    case CommentStatus.REJECTED:
      comment = "No rejected questions"
      break;
    default:
      comment = "No comments on this question"
      break;
  }

  return (
    <div className="d-flex flex-column gap-md my-auto">
      <i className="far fa-comment-minus text-muted text-center survey-comments-empty-icon"></i>

      <p className="text-muted large text-center m-0">
        {comment}
      </p>
    </div>
  )
}