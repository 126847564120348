import returnExportBody from "./returnExportBody/returnExportBody"
import { exportDataset } from "../../../../../../../services/analyzeToplineServices/analyzeToplineServices"
import { updateEditedTextField } from "../resetEditedText/resetEditedText"

export default function returnActionsData(item, state, datasetId, history, selectedTab, options, exportOptions, token, dispatch, datasetName, formData, shouldOverwrite, editorValue, datasetType, onHide, updatedLanguage, projectType, defaultLanguage, exportBody, projectId) {
  dispatch({ type: 'START_EXPORT_PROGRESS' })

  let exportUrl = `an/projects/${projectId ? projectId : history.location.pathname.split('/')[2]}/analysis/${datasetId}/export`
  if (datasetType === 'surveys') {
    exportUrl = `an/projects/${projectId ? projectId : history.location.pathname.split('/')[2]}/analysis/surveys/${datasetId}/export`
  }
  let body = returnExportBody(selectedTab, selectedTab === "report" && updatedLanguage === defaultLanguage ? updateEditedTextField(state.columnData) : state, options, exportOptions, item.action, formData, false, updatedLanguage, defaultLanguage, projectType, exportBody)
  body.saveOptions = { ...body.saveOptions, overwriteIfExists: shouldOverwrite }
  if (selectedTab === 'script' || selectedTab === 'analysisScripting') {
    exportUrl = `an/projects/${projectId ? projectId : history.location.pathname.split('/')[2]}/analysis/${datasetId}/script/export`
    if (datasetType === 'surveys') {
      exportUrl = `an/projects/${projectId ? projectId : history.location.pathname.split('/')[2]}/analysis/surveys/${datasetId}/script/export`
    }
    body = {
      type: item.action,
      options: exportOptions,
      adoc: editorValue
    }
  }

  if (formData.fileName && formData.saveToProject) {
    exportDataset(exportUrl, token, body)
      .then(res => {
        if (res) {
          if (res.statusCode === 409) {
            dispatch({ type: 'EXPORT_SET_ERROR_MESSAGE', payload: res.error ? res.error : res.message })
            dispatch({ type: 'DISPLAY_OVERWRITE_MODAL', payload: true })
          } else {
            onHide()
            dispatch({ type: 'SHOW_PENDING_NOTIFICATION', payload: { msg: 'Processing...' } })
          }
        }
      })
  } else {
    exportDataset(exportUrl, token, body)
      .then(res => {
        if (res.error || res.message) {
          dispatch({ type: 'EXPORT_SET_ERROR_MESSAGE', payload: res.error ? res.error : res.message })
        } else if (res.id) {
          onHide()
          dispatch({ type: 'SHOW_PENDING_NOTIFICATION', payload: { msg: 'Processing...' } })
        } else {
          const reader = new FileReader();
          const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const link = document.createElement("a");
            link.href = reader.result;
            link.download = `${datasetName}_${selectedTab}.${item.extension}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            dispatch({ type: 'FINISH_EXPORT_PROGRESS' })
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The file has been exported successfully' } })
          }
        }
      })
  }
}
