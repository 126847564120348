import { useEffect, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { Icon } from '../../../../../../shared/Icon/Icon';
import { SurveyFilterBuilder } from '../../../SurveyFilterBuilder/SurveyFilterBuilder';
import { DropdownButton } from '../../../../../../shared/DropdownButton/DropdownButton';
import SurveyQuillEditor from '../../../../../../shared/SurveyQuillEditor/SurveyQuillEditor';
import { returnSurveyDesignData } from '../../../helpers/returnDataHelpers/returnDataHelpers';
import { SurveyDesignPasteListModal } from './SurveyDesignPasteListModal/SurveyDesignPasteListModal';
import { SurveyDesignFilterQuestionsModalView } from './SurveyDesignFilterQuestionsModalView/SurveyDesignFilterQuestionsModalView';
import { SurveyDesignFillInQuestionsModalView } from './SurveyDesignFillInQuestionsModalView/SurveyDesignFillInQuestionsModalView';
import LanguagesList from '../../../../../../shared/LanguagesList/LanguagesList.json';
import type { RootState } from '../../../../../../../store/reducers/rootReducer';
import { returnReversedIndex } from '../../../../../../shared/SurveyQuillEditor/SurveyQuillEditorHelpers';
import { fetchGetJson, fetchPutJson } from '../../../../../../../services/services';

interface IProps {
  onClose: () => void
}

export const SurveyDesignListModalView = ({ onClose }: IProps) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch();
  const [openFilterBuilder, setOpenFilterBuilder] = useState(false);
  const [localLogicData, setLocalLogicData] = useState(null);
  const [listKeyClicked, setListKeyClicked] = useState(0);
  const [openAutoFilterQuestion, setOpenAutoFilterQuestion] = useState(false);
  const [openFillInQuestion, setOpenFillInQuestion] = useState(false);
  const [listItem, setListItem] = useState();
  const [itemSelectedKey, setItemSelectedKey] = useState(0)
  const [listsSortDirection, setListsSortDirection] = useState('')
  const [showPasteListModal, setShowPasteListModal] = useState(false)
  const { theData } = useSelector((theState: RootState) => theState.surveyInitialDataReducer);
  const { data } = useSelector((theState: RootState) => theState.breadcrumbStateReducer);
  const [listData, setListData] = useState(JSON.parse(JSON.stringify(theData.originalData.lists)));
  const skipValues = [{ text: 'None', id: '' }, { text: 'Next question', id: 'nextques' }, { text: 'Next section', id: 'nextsection' }, { text: 'Exit', id: 'exit' }]

  const autoFilterQuestion = theData?.allOptions ? theData?.allOptions.filter((option: TODO) => option.id.includes('filter') && option.id !== 'filter') : [];
  const autoFillInQuestion = theData?.allOptions ? theData?.allOptions.filter((option: TODO) => option.id.includes('afill') && option.id !== 'afill') : [];

  const addRow = () => {
    const updatedLists = [...listData]
    const selectedList = updatedLists[theData.selectedListIndex]
    const codes: [number] = [0]
    selectedList.elements.map((el: TODO) => {
      return codes.push(Number.parseInt(el.attributes.code))
    })
    const bigestCode = Math.max(...codes)
    const newLObject = {
      type: "l",
      attributes: {
        code: bigestCode + 1,
      },
      elements: [{
        attributes: {},
        textValues: { [theData.editingLanguage]: '' },
        type: "text"
      },
      ]
    }
    updatedLists[theData.selectedListIndex].elements.push(newLObject);

    setListData(updatedLists);
  };

  const filtersFromAToZ = Array.from({ length: 26 }, (_, i) => `filter${String.fromCharCode(97 + i)}`)
  const fillInsFromAToZ = Array.from({ length: 26 }, (_, i) => `afill${String.fromCharCode(97 + i)}`)

  const handleInputChange = (e: TODO, key: number, name: string) => {
    const updatedListData = [...listData]
    switch (name) {
      case "name":
        updatedListData[theData.selectedListIndex].elements[key].elements.find((el: TODO) => el.type === "text").textValues[theData.editingLanguage] = e
        break;
      case "code":
        updatedListData[theData.selectedListIndex].elements[key].attributes.code = e.target.value
        break;
      case 'filter':
        updatedListData[theData.selectedListIndex].elements[key].attributes.filter = e.target.value
        break;
      case "tag":
        updatedListData[theData.selectedListIndex].elements[key].attributes.tag = e.target.value
        break;
      case "rotsync": {
        updatedListData[theData.selectedListIndex].elements[key].attributes.rotsync = e.target.value.toString()
        break;
      }
    }
    setListData(updatedListData);
  };

  const handleCheckBoxChange = (key: number, name: string) => {
    const updatedLists = [...listData];
    switch (name) {
      case "exclusive":
        if (updatedLists[theData.selectedListIndex].elements[key].attributes.exclusive === "yes") {
          updatedLists[theData.selectedListIndex].elements[key].attributes.exclusive = "no"
        } else {
          updatedLists[theData.selectedListIndex].elements[key].attributes.exclusive = "yes"
        }
        break
      case "rot":
        if (updatedLists[theData.selectedListIndex].elements[key].attributes.rot === "n") {
          updatedLists[theData.selectedListIndex].elements[key].attributes.rot = ""
        } else {
          updatedLists[theData.selectedListIndex].elements[key].attributes.rot = "n"
        }
        break
      case "isOpen":
        if (updatedLists[theData.selectedListIndex].elements[key].attributes.isOpen === "true" || updatedLists[theData.selectedListIndex].elements[key].attributes.isOpen === true) {
          updatedLists[theData.selectedListIndex].elements[key].attributes.isOpen = false
        } else {
          updatedLists[theData.selectedListIndex].elements[key].attributes.isOpen = true
        }
    }
    setListData(updatedLists);
  };

  const handleDropDownChange = (e: TODO, key: number) => {
    const updatedLists = [...listData];
    updatedLists[theData.selectedListIndex].elements[key].attributes.skip = e.item.id
    setListData(updatedLists);
  }

  const onSaveList = () => {
    const selectedList = listData[theData.selectedListIndex]
    const codes: [number] = [0]
    selectedList.elements.map((el: TODO) => {
      return codes.push(Number.parseInt(el.attributes.code))
    })
    const resultToReturn = codes.some((element, index) => {
      return codes.indexOf(element) !== index
    });

    if (resultToReturn) {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'Some of the rows do not have unique code values' } })
    } else {
      const body = {
        ...theData.originalData,
        lists: listData
      }

      fetchPutJson(`su/projects/${data.projectId}/surveys/${data.documentId}/index`, token, body)
        .then((res: TODO) => {
          if (res.error || res.message) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
          } else {
            res.languages.forEach((lang: TODO) => { // Add language info, a compensation for backend
              const langInfo = LanguagesList.find(el => el.code === lang.code);
              if (langInfo) { lang.name = langInfo.name; lang.native = langInfo.native; }
            })
            const found = theData.selectedItem ? theData.data[theData.selectedItem.section].elements.find((el: TODO) => el.selected === true) : null //Compmensation so that the CSS for slected element stays the same
            const originalData = res;
            const data = returnSurveyDesignData(res);
            if (found) {
              data[theData.selectedItem.section].elements.find((x: { id: string }) => x.id === found.id).selected = true //Compmensation so that the CSS for slected element stays the same
            }
            dispatch({ type: 'UPDATE_SURVEY_DATA', payload: { data: data, originalData: originalData } });
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "The list has been updated" } });
            onClose()
          }
        })
    }
  };

  const deleteListItem = (key: number) => {
    const updatedLists = [...listData];
    updatedLists[theData.selectedListIndex].elements.splice(key, 1)
    setListData(updatedLists);
  };

  const disableListItem = (key: number) => {
    const updatedLists = [...listData];
    if (updatedLists[theData.selectedListIndex].elements[key].attributes['miext:disabled'] === "true") {
      updatedLists[theData.selectedListIndex].elements[key].attributes['miext:disabled'] = "false";
    } else {
      updatedLists[theData.selectedListIndex].elements[key].attributes['miext:disabled'] = "true";
    }
    setListData(updatedLists);
  }

  useEffect(() => {
    fetchGetJson(`su/projects/${theData.originalData.projectId}/surveys/${theData.originalData.surveyId}/structure`, token)
      .then((res: TODO) => {
        if (res.error || res.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
        } else {
          setLocalLogicData(res)
        }
      })
  }, [token, dispatch, theData.originalData.projectId, theData.originalData.surveyId])

  const showFilterBuilder = () => {
    dispatch({
      type: 'SURVEY_DISPLAY_FILTER_BUILDER', payload: {
        display: false, filterData: localLogicData,
        combineFilterData: []
      }
    })
  }

  const saveFilterData = (expression: string) => {
    const updatedLists = [...listData];
    updatedLists[theData.selectedListIndex].elements[listKeyClicked].attributes.filter = expression
    setListData(updatedLists);
  }

  const sortListItemsByCode = () => {
    if (listsSortDirection === 'Ascending order') {
      setListsSortDirection('')
    } else if (listsSortDirection === 'Descending order') {
      setListsSortDirection('Ascending order')
      const updatedLists = [...listData];
      const selectedList = updatedLists[theData.selectedListIndex]
      const newOrder = selectedList.elements.sort((a: TODO, b: TODO) => Number.parseFloat(a.attributes.code) - Number.parseFloat(b.attributes.code));
      selectedList.elements = newOrder
      setListData(updatedLists);
    } else {
      setListsSortDirection('Descending order')
      const updatedLists = [...listData];
      const selectedList = updatedLists[theData.selectedListIndex]
      const newOrder = selectedList.elements.sort((a: TODO, b: TODO) => Number.parseFloat(b.attributes.code) - Number.parseFloat(a.attributes.code));
      selectedList.elements = newOrder
      setListData(updatedLists);
    }
  }

  return (
    <Dialog width="95%" height="95%" title={theData.originalData.lists[theData.selectedListIndex].attributes.id} onClose={onClose}>
      {
        showPasteListModal &&
        <SurveyDesignPasteListModal onClose={() => setShowPasteListModal(false)} lists={listData} setListData={setListData} />
      }
      {openFilterBuilder &&
        <SurveyFilterBuilder
          handleClose={() => {
            setOpenFilterBuilder(false);
            setListKeyClicked(0);
            dispatch({ type: 'SURVEY_DISPLAY_FILTER_BUILDER', payload: { display: false, filterData: [], combineFilterData: [] } });
          }}
          filterData={theData.filterData}
          surveyId={theData.data.documentId}
          combineFilterData={theData.combineFilterData}
          option='filter'
          token={token}
          type='lists'
          onSaveFilter={saveFilterData}
          manualFilterExpression={undefined}
          onSaveTextFilter={undefined}
          selectedTab={undefined}
        />}
      {
        openAutoFilterQuestion &&
        <SurveyDesignFilterQuestionsModalView
          onClose={() => setOpenAutoFilterQuestion(false)}
          items={autoFilterQuestion}
          listItem={listItem}
          itemSelectedKey={itemSelectedKey}
          showFilterBuilder={showFilterBuilder}
          setOpenFilterBuilder={setOpenFilterBuilder}
        />
      }

      {
        openFillInQuestion &&
        <SurveyDesignFillInQuestionsModalView
          onClose={() => setOpenFillInQuestion(false)}
          itemSelectedKey={itemSelectedKey}
          items={autoFillInQuestion}
          listItem={listItem}
        />
      }
      <div className="d-flex flex-column h-100">
        <div className="table-responsive h-100 advanced-dialog">
          <table className="table table-sm answer-layout-table mb-0">
            <thead className='table-header'>
              <tr>
                <td className="pl-4" style={{ minWidth: '500px' }}><span>Name</span></td>
                <td className="pl-4 cursor-pointer" style={{ userSelect: 'none' }}>
                  <div onClick={sortListItemsByCode} className="d-flex align-items-center">
                    <span>Code</span>
                    <i className={`far ml-2 ${listsSortDirection === 'Ascending order' ? 'fa-arrow-up' : 'fa-arrow-down'} ${!listsSortDirection ? 'd-none' : ''}`} />
                  </div>
                </td>
                <td className="icon">
                  <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
                    <span className='icon' title='Keep position'><Icon type="answer-layout-keep-position" /></span>
                  </Tooltip>
                </td>
                <td className="icon">
                  <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
                    <span className='icon' title='Exclusive'><Icon type="answer-layout-exclusive" /></span>
                  </Tooltip>
                </td>
                <td className="icon">
                  <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
                    <span className='icon' title='Other'><Icon type="answer-layout-other" />
                    </span>
                  </Tooltip>
                </td>
                <td style={{ minWidth: '180px' }}><span>Skip to</span></td>
                <td><span>Filter</span></td>
                <td style={{ minWidth: '180px' }}><span>Custom attribute</span></td>
                <td><span>Filter (Questions)</span></td>
                <td><span>Fill in (Questions)</span></td>
                <td><span>Sync randomisation</span></td>
                <td />
              </tr>
            </thead>
            <tbody>
              {listData[theData.selectedListIndex].elements.map((list: TODO, key: number) => {
                return (
                  <tr key={key}>
                    <td className='w-100'>
                      <div className='list-group-item p-0'>
                        {!theData.htmlMode ?
                          <SurveyQuillEditor
                            token={token}
                            value={list.elements.find((el: TODO) => el.type === "text").textValues[theData.editingLanguage]}
                            setValue={(e) => handleInputChange(e, key, "name")}
                            orderNumber={returnReversedIndex(listData[theData.selectedListIndex].elements.length, key)}
                            selectedItemId={theData.selectedItem}
                            placeholder="Enter text..." />
                          :
                          <input className="form-control border-0 shadow-none text-primary ml-fix"
                            value={list.elements.find((el: TODO) => el.type === "text").textValues[theData.editingLanguage]}
                            onChange={(e) => handleInputChange(e.target.value, key, "name")}
                            placeholder="Enter text..." />
                        }
                      </div>
                    </td>
                    <td id='code'>
                      <input style={{ maxWidth: '20ch', width: list.attributes?.code?.length > 5 ? `${list.attributes?.code?.length + 1}ch` : '6ch' }} type="number" className="form-control text-center text-primary mx-auto" value={list.attributes.code} onChange={(e) => handleInputChange(e, key, "code")} />
                    </td>
                    <td id='checkbox'>
                      <input type="checkbox" checked={list.attributes.rot === "n"} onChange={() => handleCheckBoxChange(key, "rot")} />
                    </td>
                    <td id='checkbox'>
                      <input type="checkbox" checked={list.attributes.exclusive === "yes"} onChange={() => handleCheckBoxChange(key, "exclusive")} />
                    </td>
                    <td id='checkbox'>
                      <input type="checkbox" checked={!!(list.attributes.isOpen === true || list.attributes.isOpen === "true")} onChange={() => handleCheckBoxChange(key, "isOpen")} />
                    </td>
                    <td id='input' style={{ minWidth: '180px' }}>
                      <DropdownButton
                        items={skipValues}
                        text={list.attributes.skip ? skipValues.find(el => el.id === list.attributes.skip)?.text : "None"}
                        parentClass='d-flex flex-column form-control p-0 skipto'
                        onItemClick={(e) => handleDropDownChange(e, key)}
                      />
                    </td>
                    <td id='input' style={{ minWidth: '180px' }}>
                      <div className="form-group mb-0 row-filter">
                        <div className="d-flex align-items-center input-group">
                          <input className="form-control border-0" value={list.attributes.filter || ""} onChange={(e) => handleInputChange(e, key, "filter")} />
                          <div>
                            <button
                              type='button'
                              className="btn btn-icon shadow-none dropdown-toggle btn-transparent p-0 mr-2 ml-2"
                              onClick={() => {
                                showFilterBuilder()
                                setOpenFilterBuilder(true)
                                setListKeyClicked(key)
                              }}>
                              <Icon type="dashboard-edit" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td id='input' style={{ minWidth: '180px' }}>
                      <input className="form-control shadow-none text-primary" value={list.attributes.tag || ""} onChange={(e) => handleInputChange(e, key, "tag")} />
                    </td>
                    <td id='input' style={{ minWidth: '180px' }}>
                      <div className="d-flex flex-row no-wrap align-items-center">
                        <span className="text-left px-3 truncate">
                          {filtersFromAToZ.map(filter => {
                            if (list.attributes[filter] && typeof list.attributes[filter] === 'string') {
                              return (
                                `${list.attributes[filter]} `
                              )
                            }
                            return null
                          })}
                        </span>
                        <div>
                          <button
                            type='button'
                            className="btn btn-icon shadow-none dropdown-toggle btn-transparent p-0 mr-2 ml-2"
                            onClick={() => {
                              setListItem(list)
                              setItemSelectedKey(key)
                              setOpenAutoFilterQuestion(true)
                            }
                            }>
                            <Icon type="dashboard-edit" />
                          </button>
                        </div>
                      </div>
                    </td>
                    <td id='input' style={{ minWidth: '180px' }}>
                      <div className="d-flex flex-row no-wrap align-items-center">
                        <span className="text-left px-3 truncate">
                          {fillInsFromAToZ.map(fillIn => {
                            if (list.attributes[fillIn] && typeof list.attributes[fillIn] === 'string') {
                              return (
                                `${list.attributes[fillIn]} `
                              )
                            }
                            return null
                          })}
                        </span>
                        <div>
                          <button
                            type='button'
                            className="btn btn-icon shadow-none dropdown-toggle btn-transparent p-0 mr-2 ml-2"
                            onClick={() => {
                              setListItem(list)
                              setItemSelectedKey(key)
                              setOpenFillInQuestion(true)
                            }
                            }>
                            <Icon type="dashboard-edit" />
                          </button>
                        </div>
                      </div>
                    </td>
                    <td id='input' style={{ minWidth: '180px' }}>
                      <input className="form-control border-0 remove-arrows-from-input" type="number" value={list.attributes?.rotsync || ""} onChange={(e) => handleInputChange(e, key, "rotsync")} />
                    </td>
                    <td>
                      <div className='statement-list-group-item'>
                        {
                          list.attributes['miext:id'] ?
                            <button
                              type='button'
                              className="p-2 btn border-0 delete"
                              onClick={() => disableListItem(key)}>
                              <Icon type={list.attributes['miext:disabled'] === "true" ? "survey-list-hide" : "survey-list-show"} />
                            </button>
                            :
                            <button
                              type='button'
                              className="p-2 btn border-0 delete"
                              onClick={() => deleteListItem(key)}>
                              <Icon type="delete-alt" />
                            </button>
                        }
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <div className="d-flex flex-grow-1 p-3">
            <button type='button' onClick={addRow} className="btn btn-shadow strong mr-2">Add new row</button>
            <button type='button' onClick={() => setShowPasteListModal(true)} className="btn btn-shadow strong">Paste list</button>
          </div>
        </div>
      </div>
      <DialogActionsBar>
        <Button className="btn btn-secondary" onClick={onClose}>Cancel</Button>
        <Button className="btn btn-primary" onClick={onSaveList}>Save</Button>
      </DialogActionsBar>
    </Dialog>
  )
}
