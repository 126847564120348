export const moveJsxElement = (div: any) => {
  let mousePosition;
  let offset = [0, 0];
  let isDown: boolean = false;

  div.addEventListener('mousedown', function (e: any) {
    isDown = true;
    offset = [
      div.offsetLeft - e.clientX,
      div.offsetTop - e.clientY
    ];
  }, true);

  document.addEventListener('mouseup', function () {
    isDown = false;
  }, true);

  document.addEventListener('mousemove', function (event) {
    // event.preventDefault();
    if (isDown) {
      mousePosition = {
        x: event.clientX,
        y: event.clientY
      };
      div.style.left = (mousePosition.x + offset[0]) + 'px';
      div.style.top = (mousePosition.y + offset[1]) + 'px';
    }
  }, true);
}