import disableOptionByPlan from "../disableOptionByPlan/disableOptionByPlan"

export default (options, user, groups, data) => {
  let list = {}
  if (options && groups && groups.length !== 0) {
    Object.keys(options).forEach((key) => {
      let disabled = disableOptionByPlan(data.find(el => el.id === key), user, groups)
      if (disabled === false) {
        list = {
          ...list, [key]: options[key]
        }
      }
    })
  }

  return list
}