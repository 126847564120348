import { returnUpdatedHeaders } from "../components/shared/helpers/returnUpdatedHeaders"

export const qtUrl: string = import.meta.env.VITE_APP_QT_URL // https://www.quenchtec.com
export const rsUrl: string = import.meta.env.VITE_APP_RS_URL // https://www.researchstudio.net
export const rsAppUrl: string = import.meta.env.VITE_APP_AUTH0_NAMESPACE //https://app.researchstudio.net
export const rsHelpUrl: string = import.meta.env.VITE_APP_RS_HELP_URL // https://help.researchstudio.net
export const rsSurveyUrl: string = import.meta.env.VITE_APP_RS_APP_SURVEY_URL // https://survey.researchstudio.io
export const apiUrl: string = import.meta.env.VITE_APP_RS_API_URL // https://api.researchstudio.net
export const apiSurveyURL: string = import.meta.env.VITE_APP_RS_SURVEY_API_URL // https://api.researchstudio.net/survey/v2/
export const apiPanelURL: string = import.meta.env.VITE_APP_RS_PANEL_API_URL // https://api.researchstudio.net/panel/v1/
export const subscriptionKey: string = import.meta.env.VITE_APP_API_SUBSCRIPTION_KEY // 8e05042b3bb4447db95e05a7812a4e29
export const apiAudienceURL: string = import.meta.env.VITE_APP_RS_AUDIENCE_API_URL

type baseUrl = "panel" | "audience" | "default";

type FetchTypeNoBody = { url: string, token: string, baseUrl?: baseUrl }
type FetchTypeWithBody = { url: string, token: string, body: unknown, baseUrl?: baseUrl }

const baseUrlObject = {
  "panel": apiPanelURL,
  "audience": apiAudienceURL,
  "default": apiSurveyURL
}

export const fetchGetRes: any = (url: string, token: string, isPanel?: boolean, isAudience?: boolean) => {
  try {
    return fetch((isPanel ? apiPanelURL : isAudience ? apiAudienceURL : apiSurveyURL) + url, {
      method: 'GET',
      headers: returnUpdatedHeaders(token, subscriptionKey)
    })
      .then(data => data)
      .catch(error => {
        console.log(error);
        return error;
      })
  } catch (error) {
    console.log(error)
    return error
  }
}

/**
 * Make a JSON GET request
 * @param {Url, token, baseUrl}
 * @param url Request endpoint
 * @param token Auth token
 * @param baseUrl different base urls depending on .env variables default value is default(surveyAPI)
 * @Returns If error the string message else Json data
 */
export const fetchGetJsonData = async <T>({ url, token, baseUrl = "default" }: FetchTypeNoBody): Promise<T> => {
  const response = await fetch(baseUrlObject[baseUrl] + url, {
    method: 'GET',
    headers: returnUpdatedHeaders(token, subscriptionKey, 'application/json')
  })

  const data = await response.json();

  if (!response.ok) {
    if (data.message || data.error) {
      return (Promise.reject((data.message || data.error)));
    }
    return (Promise.reject(data));
  }

  return data as T;
};

/**
 * Make a JSON POST request
 * @param {Url, token, baseUrl}
 * @param url Request endpoint
 * @param token Auth token
 * @param baseUrl different base urls depending on .env variables default value is default(surveyAPI)
 * @Returns If error the string message else Json data
 */
export const fetchPostJsonData = async <T>({ url, token, baseUrl = "default", body }: FetchTypeWithBody): Promise<T> => {
  const response = await fetch(baseUrlObject[baseUrl] + url, {
    method: 'POST',
    headers: returnUpdatedHeaders(token, subscriptionKey, 'application/json'),
    body: JSON.stringify(body)
  })

  const data = await response.json();

  if (!response.ok) {
    if (data.message || data.error) {
      return (Promise.reject(data.message || data.error));
    }
    return (Promise.reject(data));
  }

  return data as T;
};

/**
 * Make a JSON PUT request
 * @param {Url, token, baseUrl}
 * @param url Request endpoint
 * @param token Auth token
 * @param baseUrl different base urls depending on .env variables default value is default(surveyAPI)
 * @Returns If error the string message else Json data
 */
export const fetchPutJsonData = async <T>({ url, token, baseUrl = "default", body }: FetchTypeWithBody): Promise<T> => {
  const response = await fetch(baseUrlObject[baseUrl] + url, {
    method: 'PUT',
    headers: returnUpdatedHeaders(token, subscriptionKey, 'application/json'),
    body: JSON.stringify(body)
  })

  const data = await response.json();

  if (!response.ok) {
    if (data.message || data.error) {
      return (Promise.reject(data.message || data.error));
    }
    return (Promise.reject(data));
  }

  return data as T;
};

/**
 * Make a Delete request
 * @param {Url, token, baseUrl}
 * @param url Request endpoint
 * @param token Auth token
 * @param baseUrl different base urls depending on .env variables default value is default(surveyAPI)
 * @Returns If error the string message else Json data
 */
export const fetchDeleteNoJson = async <T>({ url, token, baseUrl = "default" }: FetchTypeNoBody): Promise<T> => {
  const response = await fetch(baseUrlObject[baseUrl] + url, {
    method: 'DELETE',
    headers: returnUpdatedHeaders(token, subscriptionKey, 'application/json')
  })

  if (response.status === 204) {
    return response as T;
  }

  const data = await response.json();

  if (!response.ok) {
    if (data.message || data.error) {
      return (Promise.reject((data.message || data.error)));
    }
    return (Promise.reject(data));
  }

  return data as T;
};

/**
 * Make a JSON PATCH request
 * @param url Request endpoint
 * @param token Auth token
 * @param isPanel is panel optional
 * @Returns If error the string message else Json data
 */
export const fetchPatchJsonData = async <T>({ url, token, baseUrl = "default", body }: FetchTypeWithBody): Promise<T> => {
  const response = await fetch(baseUrlObject[baseUrl] + url, {
    method: 'PATCH',
    headers: returnUpdatedHeaders(token, subscriptionKey, 'application/json'),
    body: JSON.stringify(body)
  })

  const data = await response.json();

  if (!response.ok) {
    if (data.message || data.error) {
      return (Promise.reject(data.message || data.error));
    }
    return (Promise.reject(data));
  }

  return data as T;
};

export const fetchGetJson: any = (url: string, token: string, isPanel?: boolean, isAudience?: boolean) => {
  try {
    return fetch((isPanel ? apiPanelURL : isAudience ? apiAudienceURL : apiSurveyURL) + url, {
      method: 'GET',
      headers: returnUpdatedHeaders(token, subscriptionKey, 'application/json')
    })
      .then(response => response.json())
      .then(data => data)
      .catch(error => {
        console.log(error);
        return error;
      })
  } catch (error) {
    console.log(error)
    return error
  }
}

export const fetchPost: any = (url: string, token: string, body: any, isPanel?: boolean) => {
  try {
    return fetch((isPanel ? apiPanelURL : apiSurveyURL) + url, {
      method: 'POST',
      headers: returnUpdatedHeaders(token, subscriptionKey, 'application/json'),
      body: JSON.stringify(body)
    })
      .then(res => res)
      .catch(error => {
        console.log(error);
        return error
      })
  } catch (error) {
    console.log(error)
    return error
  }
}

export const fetchPostJson: any = (url: string, token: string, body: any, isPanel?: boolean) => {
  try {
    return fetch((isPanel ? apiPanelURL : apiSurveyURL) + url, {
      method: 'POST',
      headers: returnUpdatedHeaders(token, subscriptionKey, 'application/json'),
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(data => data)
      .catch(error => {
        console.log(error);
        return error;
      })
  } catch (error) {
    console.log(error)
    return error
  }
}

export const fetchPostResOrJson: any = (url: string, token: string, body: any, isPanel?: boolean, isAudience?: boolean) => {
  try {
    return fetch((isPanel ? apiPanelURL : isAudience ? apiAudienceURL : apiSurveyURL) + url, {
      method: 'POST',
      headers: returnUpdatedHeaders(token, subscriptionKey, 'application/json'),
      body: JSON.stringify(body)
    })
      .then(response => {
        if (response.status === 200 || response.status === 201 || response.status === 202) {
          return response
        } else {
          return response.json()
        }
      })
      .then(data => data)
      .catch(error => {
        console.log(error);
        return error;
      })
  } catch (error) {
    console.log(error)
    return error
  }
}

export const fetchDeleteWithBody: any = (url: string, token: string, body: any, isPanel?: boolean) => {
  try {
    return fetch((isPanel ? apiPanelURL : apiSurveyURL) + url, {
      method: 'DELETE',
      headers: returnUpdatedHeaders(token, subscriptionKey, 'application/json'),
      body: JSON.stringify(body)
    })
      .then(response => response)
      .catch(error => {
        console.log(error);
        return error;
      })
  } catch (error) {
    console.log(error)
    return error
  }
}

export const fetchDelete: any = (url: string, token: string, isPanel?: any, body?: any) => {
  try {
    return fetch((isPanel ? apiPanelURL : apiSurveyURL) + url, {
      method: 'DELETE',
      headers: returnUpdatedHeaders(token, subscriptionKey, body ? 'application/json' : undefined),
      body: JSON.stringify(body)
    })
      .then(response => response)
      .catch(error => {
        console.log(error);
        return error;
      })
  } catch (error) {
    console.log(error)
    return error
  }
}

export const fetchDeleteJson: any = (url: string, token: string, isPanel?: boolean, isAudience?: boolean) => {
  try {
    return fetch((isPanel ? apiPanelURL : isAudience ? apiAudienceURL : apiSurveyURL) + url, {
      method: 'DELETE',
      headers: returnUpdatedHeaders(token, subscriptionKey, 'application/json')
    })
      .then(response => {
        if (response.status === 204 || response.status === 200) {
          return response
        }
        return response.json()
      })
      .then(data => data)
      .catch(error => {
        console.log(error);
        return error;
      })
  } catch (error) {
    console.log(error)
    return error
  }
}

export const fetchPutResOrJson: any = (url: string, token: string, body: any, isPanel?: boolean, isAudience?: boolean) => {
  try {
    return fetch((isPanel ? apiPanelURL : isAudience ? apiAudienceURL : apiSurveyURL) + url, {
      method: 'PUT',
      headers: returnUpdatedHeaders(token, subscriptionKey, 'application/json'),
      body: JSON.stringify(body)
    })
      .then(response => {
        if (response.status === 200 || response.status === 202) {
          return response
        } else {
          return response.json()
        }
      })
      .then(data => data)
      .catch(error => {
        console.log(error);
        return error;
      })
  } catch (error) {
    console.log(error)
    return error
  }
}

export const fetchPutJson: any = (url: string, token: string, body: any, isPanel?: boolean, isAudience?: boolean) => {
  try {
    return fetch((isPanel ? apiPanelURL : isAudience ? apiAudienceURL : apiSurveyURL) + url, {
      method: 'PUT',
      headers: returnUpdatedHeaders(token, subscriptionKey, 'application/json'),
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .catch(error => {
        console.log(error);
        return error;
      })
  } catch (error) {
    console.log(error)
    return error
  }
}

export const fetchPutRes: any = (url: string, token: string, body: any, isPanel?: boolean) => {
  try {
    return fetch((isPanel ? apiPanelURL : apiSurveyURL) + url, {
      method: 'PUT',
      headers: returnUpdatedHeaders(token, subscriptionKey, 'application/json'),
      body: JSON.stringify(body)
    })
      .then(response => response)
      .catch(error => {
        console.log(error);
        return error;
      })
  } catch (error) {
    console.log(error)
    return error
  }
}

export const fetchGetJsonOrEmptyArray: any = (url: string, token: string, isPanel?: boolean) => {
  try {
    return fetch((isPanel ? apiPanelURL : apiSurveyURL) + url, {
      method: 'GET',
      headers: returnUpdatedHeaders(token, subscriptionKey, 'application/json')
    })
      .then(response => response.status === 200 ? response.json() : [])
      .then(data => {
        return data
      })
      .catch(error => {
        console.log(error);
        return error
      })
  } catch (error) {
    console.log(error)
    return error
  }
}

export const fetchGetBlob: any = (url: string, token: string, isPanel?: boolean, isAudience?: boolean) => {
  try {
    return fetch((isPanel ? apiPanelURL : isAudience ? apiAudienceURL : apiSurveyURL) + url, {
      method: 'GET',
      headers: returnUpdatedHeaders(token, subscriptionKey)
    })
      .then(response => {
        if (response.status === 200) {
          return response.blob()
        }
        return response.json()
      })
      .catch(error => {
        console.log(error);
        return error
      })
  } catch (error) {
    console.log(error)
    return error
  }
}

export const removeUserFromSharedProject: any = (url: string, token: string, body: any, isPanel?: boolean) => {
  try {
    return fetch((isPanel ? apiPanelURL : apiSurveyURL) + url, {
      method: 'DELETE',
      headers: returnUpdatedHeaders(token, subscriptionKey, 'application/json'),
      body: JSON.stringify(body)
    })
      .then(res => res)
      .catch(error => {
        console.log(error);
        return error;
      })
  } catch (error) {
    console.log(error)
    return error
  }
}

export const getThemeCss: any = (url: string, token: string, isPanel?: boolean) => {
  try {
    return fetch((isPanel ? apiPanelURL : apiSurveyURL) + url, {
      method: 'GET',
      headers: returnUpdatedHeaders(token, subscriptionKey, 'text/css charset=utf-8')
    })
      .then(response => response.text())
      .catch(error => {
        console.log(error);
        return error
      })
  } catch (error) {
    console.log(error)
    return error
  }
}

export const fetchPatch: any = (url: string, token: string, body: any, isPanel?: boolean) => {
  try {
    return fetch((isPanel ? apiPanelURL : apiSurveyURL) + url, {
      method: 'PATCH',
      headers: returnUpdatedHeaders(token, subscriptionKey, 'application/json'),
      body: JSON.stringify(body)
    })
      .then(res => res)
      .catch(error => {
        console.log(error);
        return error
      })
  } catch (error) {
    console.log(error)
    return error
  }
}

export const fetchPatchJson: any = (url: string, token: string, body: any, isPanel?: boolean) => {
  try {
    return fetch((isPanel ? apiPanelURL : apiSurveyURL) + url, {
      method: 'PATCH',
      headers: returnUpdatedHeaders(token, subscriptionKey, 'application/json'),
      body: JSON.stringify(body)
    })
      .then(res => res)
      .catch(error => {
        console.log(error);
        return error
      })
  } catch (error) {
    console.log(error)
    return error
  }
}

export const fetchPatchResOrJson: any = (url: string, token: string, body: any, isPanel?: boolean) => {
  try {
    return fetch((isPanel ? apiPanelURL : apiSurveyURL) + url, {
      method: 'PATCH',
      headers: returnUpdatedHeaders(token, subscriptionKey, 'application/json'),
      body: JSON.stringify(body)
    })
      .then(response => {
        if (response.status === 200 || response.status === 201 || response.status === 202) {
          return response
        } else {
          return response.json()
        }
      })
      .then(data => data)
      .catch(error => {
        console.log(error);
        return error;
      })
  } catch (error) {
    console.log(error)
    return error
  }
}

export const fetchGetPreviewDashboard: any = (shareLink: string, pass?: string, isQuotas?: boolean, extended?: boolean, quotaId?: string) => {
  try {
    const headers: any = { "Content-Type": "application/json" }
    const url = !isQuotas ? `${apiPanelURL}su/public?s=${shareLink}` : extended ? `${apiPanelURL}su/public/quota-overview/extended?s=${shareLink}&q=${quotaId}` : `${apiPanelURL}su/public/quota-overview?s=${shareLink}`
    if (pass) {
      headers[`so-sec-${shareLink}`] = pass
    }
    return fetch(url, {
      method: "GET",
      headers: headers
    })
      .then(response => response.json())
      .then(data => data)
      .catch(error => {
        return error;
      })
  } catch (error) {
    return error
  }
}

export const fetchPostFile: any = (url: string, token: string, file: FormData, isPanel?: boolean, isAudience?: boolean) => {
  try {
    return fetch((isPanel ? apiPanelURL : isAudience ? apiAudienceURL : apiSurveyURL) + url, {
      method: 'POST',
      headers: returnUpdatedHeaders(token, subscriptionKey),
      body: file
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          return response
        }
      })
      .then(data => data)
      .catch(error => {
        console.log(error);
        return error;
      })
  } catch (error) {
    console.log(error)
    return error
  }
}