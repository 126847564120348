import type { ReconciliationData } from '../../../interfaces/reconciliationsInterfaces/gateKeeperInterfaces'

interface Props {
  reconciliationData: ReconciliationData,
  submitReconcile: () => void,
  discardReconcile: () => void
}

export const ReconcilePreview = ({ reconciliationData, submitReconcile, discardReconcile }: Props) => {
  return (
    <div className='d-flex w-100 justify-content-between border-top p-4'>
      <div className='d-flex align-items-center'>{reconciliationData?.respondentCount} WalrID(s) ready for reconciliation</div>
      <div className='d-flex'>
        <button className="btn btn-secondary mr-2" type="button" id="submitReconcile" onClick={() => discardReconcile()}>
          Discard
        </button>
        <button className="btn btn-primary" type="button" id="submitReconcile" onClick={() => submitReconcile()}>
          Submit reconciliation
        </button>
      </div>
    </div>
  )
} 