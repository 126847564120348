import { Icon } from '../../Icon/Icon';
import type { IconsType } from '../../../../interfaces/iconInterfaces/iconInterfaces';

const iconTypes: IconsType[] = ["ic-carousel", "ic-clickwords", "ic-datetime", "ic-duration", "ic-gabor-granger", "ic-general", "ic-goto", "ic-hotspot", "ic-implicit-testing", "ic-information", "ic-multigrid", "ic-multi", "ic-numeric", "ic-open-ended", "ic-quota-stop", "ic-quota", "ic-ranking", "ic-section", "single-grid", "single", "ic-sliders", "ic-star-rating", "ic-swipe-cards", "ic-video-rating", "ic-vimeo", "ic-wistia", "ic-youtube"];

export const renderCustomQuestionIconItem = (onChangeHandler: (data: TODO) => void) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className='pt-1 pb-2 medium strong'>Select icon</div>
        </div>
      </div>
      <div className="row">
        <div className="w-100 my-1 bg-light" style={{ height: '1px' }} />
      </div>
      <div className="row p-2">
        {iconTypes.map((el, i) => {
          return (
            <div key={i} className="p-1 m-05 cursor-pointer select-icon" onClick={() => { onChangeHandler({ target: { name: 'icon', value: el } }) }}>
              <Icon type={el} />
            </div>
          )
        })}
      </div>
    </div>
  )
}