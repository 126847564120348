import { z } from "zod";
import { ZodIsRequiredErrorMessage } from "../ZodCustomErrorMessages";

const targetTypes = ["Completes", "Prescreens"] as const;
const currencyTypes = ["GBP", "USD", "AUD", "EUR"] as const;
const audienceTargeting = ["Custom", "None", "Existing", "NatRep"] as const;

const answersSchema = z.array(z.object({
  id: z.string().nullable(),
  text: z.string().nullable().optional(),
  localizedText: z.string().nullable().optional(),
  start: z.number().nullable(),
  end: z.number().nullable()
}))

const quotasSchema = z.array(z.object({
  questionId: z.string().nullable(),
  name: z.string(),
  text: z.string(),
  localizedText: z.string().nullable(),
  targets: z.array(z.object({
    id: z.string().nullable(),
    targetCompletes: z.number(),
    answers: answersSchema
  }))
}));

const screeningsSchema = z.array(z.object({
  questionId: z.string(),
  name: z.string().optional(),
  text: z.string().optional(),
  localizedText: z.string().nullable().optional(),
  isInclude: z.boolean(),
  answers: answersSchema
}))

// screening: z.any().nullable(), // Previous data values moved into targeting
// quotas: z.any().nullable(), // Previous data values moved into targeting

export const batchSchema = z.object({
  displayName: z.string().min(1, ZodIsRequiredErrorMessage("Name")),
  quantity: z.number().min(1, ZodIsRequiredErrorMessage("Target completes *")),
  incidenceRate: z.number().min(1, ZodIsRequiredErrorMessage("Target incidence rate *")).max(101, "IncidenceRate must be greater than 0 but less than 101"),
  lengthOfInterview: z.number().min(1, ZodIsRequiredErrorMessage("Target completion LOI *")),
  launchDate: z.string().min(1, ZodIsRequiredErrorMessage("Start date")),
  targetEndDate: z.string().min(1, ZodIsRequiredErrorMessage("End date")).optional(),
  completeAsap: z.boolean(),
  targetType: z.enum(targetTypes, ZodIsRequiredErrorMessage("Target type")),
  cpi: z.number().positive(ZodIsRequiredErrorMessage("Sample CPI")),
  clientCPI: z.number().positive().or(z.literal(0)),
  currency: z.enum(currencyTypes, ZodIsRequiredErrorMessage("Currency")),
  language: z.string(),
  country: z.string(),
  targeting: z.object(
    {
      type: z.enum(audienceTargeting),
      existingId: z.string().nullable(),
      screenings: screeningsSchema.nullable(),
      quotas: quotasSchema.nullable()
    }
  ).nullable(),
})
