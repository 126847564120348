import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Button, DropDownButton } from '@progress/kendo-react-buttons';
import { Window, WindowActionsBar, type WindowActionsEvent } from '@progress/kendo-react-dialogs';
import { Editor, type EditorChangeEvent, EditorUtils, ProseMirror, type EditorMountEvent } from '@progress/kendo-react-editor';

import type { RootState } from '../../../../../store/reducers/rootReducer';
import { returnSurveyElementTextChange } from '../helpers/returnDataHelpers/returnDataHelpers';
import { fetchPutJson as updateSurveyElement } from '../../../../../services/services';

const items = [{ text: "Single question", id: "DefaultQuesN" }, { text: "Single grid question", id: "DefaultQuesRn" }, { text: "Multi question", id: "DefaultQuesM" }, { text: "Multi grid question", id: "DefaultQuesRm" }, { text: "Open-ended question", id: "DefaultQuesOpen" }, { text: "Numeric question", id: "DefaultQuesF" }, { text: "Duration question", id: "DefaultQuesH" }]

type Props = {
  handleClose: () => void
  updateElements: (id: string) => void
  addSurveyElement: (id: string, type: string, source: string) => void
}

export const PastePad = ({ handleClose, updateElements, addSurveyElement }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { theData } = useSelector((theState: RootState) => theState.surveyInitialDataReducer)
  const [removeContent, setRemoveContent] = useState(false)
  const [wrapContent, setWrapContent] = useState(false)
  const [windowStyleProps, setWindowStyleProps] = useState({
    height: '100%',
    width: '40%',
    top: '0',
    left: '60%'
  })

  const dispatch = useDispatch()
  const editorRef: TODO = useRef()

  const { pasteCleanup, sanitize, removeAttribute } = EditorUtils;

  const pasteSettings = {
    convertMsLists: true,
    attributes: {
      class: removeAttribute,
      style: removeAttribute,
      '*': removeAttribute,
    }
  };

  const updateSelectedElement = (originalItem: TODO) => {
    dispatch({ type: 'LOAD_SURVEY_ELEMENT_DATA', payload: 'Updating' })
    const body = originalItem
    updateSurveyElement(`su/projects/${theData.originalSelectedItem.projectId}/surveys/${theData.originalSelectedItem.surveyId}/elements/${theData.originalSelectedItem.id}`, token, body)
      .then((res: TODO) => {
        if (res.indexElement && res.definition) {
          updateElements(theData.originalSelectedItem.id)
        }
      })
  }

  const pasteSelection = (type: string) => {
    const selection: TODO = []
    editorRef.current.view.state.selection.content().content.content.forEach(((node: TODO) => {
      if (editorRef.current.view.state.selection.ranges.length === editorRef.current.view.state.selection.content().content.content) {
        selection.push(node.textContent)
      }
      else {
        node.content.forEach((node: TODO) => {
          selection.push(node.textContent)
        })
      }
    })
    )
    if (selection.length !== 0) {
      let text = ''
      selection.forEach((el: TODO) => {
        text += `<p>${el}</p>`
      })
      if (type === 'stext') {
        if (theData.selectedItem.type === 'ques') {
          const editingElement = {
            content: theData.selectedItem.elements.filter((el: TODO) => el.type === 'subq')[theData.selectedSubquestion].elements.find((el: TODO) => el.type === 'stext').elements.find((el: TODO) => el.type === 'text').textValues[theData.editingLanguage],
            type: type,
            subQuesIndex: theData.selectedSubquestion,
            index: 0
          }
          const data = `${editingElement.content} ${text}`
          const updatedOriginalItem = returnSurveyElementTextChange(theData.originalSelectedItem, editingElement, data, theData.editingLanguage, theData.originalData.languages.map((el: TODO) => el.code))
          updateSelectedElement(updatedOriginalItem)
        } else {
          const values = {}
          const originalItem = theData.originalSelectedItem
          originalItem.elements.find((el: TODO) => el.type === 'text').textValues = { ...values, [theData.editingLanguage]: text }
          updateSelectedElement(originalItem)
        }
      }
      if (type === 'rows' || type === 'columns' || type === 'open-rows') {
        const originalItem = theData.originalSelectedItem
        const values: TODO = {}
        theData.originalData.languages.forEach((el: TODO) => { if (el.code !== theData.editingLanguage) { values[el.code] = "" } })
        if (type === 'rows') {
          const existingRowCodes = originalItem.elements.find((el: TODO) => el.type === 'rgroup').elements.map((el: TODO) => Number(el.attributes.code))
          const maxRowCode = existingRowCodes.length > 0 ? Math.max(...existingRowCodes) : 0
          if (wrapContent) {
            originalItem.elements.find((el: TODO) => el.type === 'rgroup').elements.push({ type: 'r', attributes: { code: maxRowCode + 1 }, metadata: null, elements: [{ type: 'text', attributes: {}, elements: [], textValues: { ...values, [theData.editingLanguage]: text }, metadata: null }] })
          } else {
            selection.forEach((item: TODO, key: number) => {
              originalItem.elements.find((el: TODO) => el.type === 'rgroup').elements.push({ type: 'r', attributes: { code: maxRowCode + (key + 1) }, metadata: null, elements: [{ type: 'text', attributes: {}, elements: [], textValues: { ...values, [theData.editingLanguage]: item }, metadata: null }] })
            })
          }
        } else if (type === 'columns') {
          const existingColumnCodes = originalItem.elements.filter((el: TODO) => el.type === 'subq')[theData.selectedSubquestion].elements.find((el: TODO) => el.type === 'hgroup').elements.map((el: TODO) => Number(el.attributes.code))
          const maxColumnCode = existingColumnCodes.length > 0 ? Math.max(...existingColumnCodes) : 0
          if (wrapContent) {
            originalItem.elements.filter((el: TODO) => el.type === 'subq')[theData.selectedSubquestion].elements.find((el: TODO) => el.type === 'hgroup').elements.push({ type: 'h', attributes: { code: maxColumnCode + 1 }, metadata: null, elements: [{ type: 'text', attributes: {}, elements: [], textValues: { ...values, [theData.editingLanguage]: text }, metadata: null }] })
          } else {
            selection.forEach((item: TODO, key: number) => {
              originalItem.elements.filter((el: TODO) => el.type === 'subq')[theData.selectedSubquestion].elements.find((el: TODO) => el.type === 'hgroup').elements.push({ type: 'h', attributes: { code: maxColumnCode + (key + 1) }, metadata: null, elements: [{ type: 'text', attributes: {}, elements: [], textValues: { ...values, [theData.editingLanguage]: item }, metadata: null }] })
            })
          }
        } else if (type === 'open-rows') {
          const existingRowCodes = originalItem.elements.find((el: TODO) => el.type === 'rgroup').elements.map((el: TODO) => Number(el.attributes.code))
          const maxRowCode = existingRowCodes.length > 0 ? Math.max(...existingRowCodes) : 0
          if (wrapContent) {
            originalItem.elements.find((el: TODO) => el.type === 'rgroup').elements.push({ type: 'other', attributes: { code: maxRowCode + 1 }, metadata: null, elements: [{ type: 'text', attributes: {}, elements: [], textValues: { ...values, [theData.editingLanguage]: text }, metadata: null }] })
          } else {
            selection.forEach((item: TODO, key: number) => {
              originalItem.elements.find((el: TODO) => el.type === 'rgroup').elements.push({ type: 'other', attributes: { code: maxRowCode + (key + 1) }, metadata: null, elements: [{ type: 'text', attributes: {}, elements: [], textValues: { ...values, [theData.editingLanguage]: item }, metadata: null }] })
            })
          }
        }
        updateSelectedElement(originalItem)
      }
      if (removeContent) {
        EditorUtils.setHtml(editorRef.current.view, EditorUtils.getHtml(editorRef.current.view.state.apply(editorRef.current.view.state.tr.deleteSelection())))
      }
    }
  }

  const createView = (event: EditorMountEvent) => {
    const { dom, viewProps } = event;
    const state = ProseMirror.EditorState.create({
      doc: viewProps.state.doc,
      plugins: [ProseMirror.keymap({
        'Mod-u': () => true
      }), ...viewProps.state.plugins]
    });
    return new ProseMirror.EditorView({
      mount: dom
    }, {
      ...viewProps,
      state
    });
  };

  const onChange = (e: EditorChangeEvent) => {
    dispatch({ type: 'UPDATE_SURVEY_PASTE_PAD', payload: e.value })
  }

  const onStageChangeHandler = (e: WindowActionsEvent) => {
    switch (e.state) {
      case 'FULLSCREEN':
        setWindowStyleProps({ height: '100%', width: '100%', top: '0', left: '0' })
        break;
      case 'DEFAULT':
        setWindowStyleProps({ height: '100%', width: '40%', top: '0', left: '60%' })
        break;
      default:
        break;
    }
  }

  return (
    <Window title={"Paste pad"} onClose={handleClose} onStageChange={(e) => onStageChangeHandler(e)} style={{ ...windowStyleProps, borderTopRightRadius: 0, borderBottomRightRadius: 0 }} overlayStyle={{ opacity: '0.2' }} minimizeButton={() => null} resizable={false} draggable={false} modal={true} >
      <div className="d-flex flex-column h-100">
        <div className="d-flex justify-content-between align-items-center pb-3">
          <div className="d-flex align-items-center gap-md">
            <DropDownButton
              text="New question"
              icon="fas fa fa-fw fa-plus"
              buttonClass="btn btn-primary"
              items={items}
              onItemClick={(props) => addSurveyElement(props.item.id, props.item.text, 'pastePad')} />
            <Tooltip openDelay={100} position='auto' anchorElement={'target'}>
              <Button
                className="btn btn-secondary"
                icon="fas fa fa-fw fa-heading pe-none"
                title={theData.selectedItem && theData.selectedItem.type === 'ques' ? "Question text" : "Info text"}
                disabled={!theData.selectedItem}
                onClick={() => pasteSelection('stext')}
              />
            </Tooltip>
            {(theData.selectedItem && theData.selectedItem.type !== 'info') || !theData.selectedItem ?
              <div className="d-flex align-items-center gap-md">
                <Tooltip openDelay={100} position='auto' anchorElement={'target'}>
                  <Button
                    className="btn btn-secondary"
                    icon="fas fa fa-fw fa-grip-lines pe-none"
                    title="Paste rows"
                    disabled={!theData.selectedItem}
                    onClick={() => pasteSelection('rows')}
                  />
                </Tooltip>
                <Tooltip openDelay={100} position='auto' anchorElement={'target'}>
                  <Button
                    className="btn btn-secondary"
                    icon="fas fa fa-fw fa-edit pe-none"
                    title="Paste open rows"
                    disabled={!theData.selectedItem || (theData.selectedItem && (theData.selectedItem.elements[theData.selectedSubquestion].attributes.type !== "m" && theData.selectedItem.elements[theData.selectedSubquestion].attributes.type !== "n"))}
                    onClick={() => pasteSelection('open-rows')}
                  />
                </Tooltip>
                <Tooltip openDelay={100} position='auto' anchorElement={'target'}>
                  <Button
                    className="btn btn-secondary"
                    icon="fas fa fa-fw fa-grip-lines-vertical pe-none"
                    title="Paste columns"
                    disabled={!theData.selectedItem || (theData.selectedItem && (theData.selectedItem.elements[theData.selectedSubquestion].attributes.type !== "rm" && theData.selectedItem.elements[theData.selectedSubquestion].attributes.type !== "rn"))}
                    onClick={() => pasteSelection('columns')}
                  />
                </Tooltip>
              </div> : null}
          </div>
          <div className="d-flex align-items-center gap-md">
            <Tooltip openDelay={100} position='auto' anchorElement={'target'}>
              <Button
                className={`btn ${removeContent ? "btn-primary" : "btn-white"}`}
                icon="fas fa fa-fw fa-remove-format pe-none"
                title="Remove text after pasting"
                disabled={!theData.selectedItem}
                onClick={() => setRemoveContent(!removeContent)}
              />
            </Tooltip>
            <Tooltip openDelay={100} position='auto' anchorElement={'target'}>
              <Button
                className={`btn ${wrapContent ? "btn-primary" : "btn-white"}`}
                icon="fas fa fa-fw fa-level-down-alt pe-none"
                title="Wrap text"
                disabled={!theData.selectedItem}
                onClick={() => setWrapContent(!wrapContent)}
              />
            </Tooltip>
          </div>
        </div>
        <div className="d-flex flex-column h-100 overflow-auto flex-grow-1">
          <Editor
            ref={editorRef}
            onChange={onChange}
            value={theData.pastePad}
            contentStyle={{ height: '100%', width: '100%' }}
            className="w-100 h-100 k-textarea"
            onMount={(event: EditorMountEvent) => createView(event)}
            onPasteHtml={(e) => pasteCleanup(sanitize(e.pastedHtml), pasteSettings)}
          />
        </div>
      </div>
      <WindowActionsBar>
        <button type='button' className="k-button btn-secondary rounded" onClick={handleClose}>Close</button>
      </WindowActionsBar>
    </Window>
  )
}