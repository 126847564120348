export const returnUpdatedVariableData = (result: any, newData: any) => {
  if (Array.isArray(result.sss.survey.record.variable)) {
    result.sss.survey.record.variable.forEach((ques: any, id: number) => {
      newData.push({
        "index": id,
        "id": ques.name,
        "code": ques["@miextsss:code"] ? ques["@miextsss:code"] : "",
        "@miextsss:qno": ques["@miextsss:qno"] ? ques["@miextsss:qno"] : ques.name,
        "type": ques['@type'] ? ques['@type'] : ques['@miextsss:type'] ? ques["@miextsss:type"] : "",
        "values": ques.values ? ques.values : [],
        "order": Number(ques["@miextsss:docorder"]),
        "title": ques.label.text['#text'] ? ques.label.text['#text'] : ques.label.text,
        "selected": false,
        "checked": false,
        "@ident": ques["@ident"],
        "@miextsss:type": ques["@miextsss:type"] ? ques["@miextsss:type"] : "",
        "position": ques.position ? ques.position : "",
        "disabled": ques["@miextsss:excluded"] === "true" ? true : false
      })
    })
  } else {
    const ques = result.sss.survey.record.variable
    newData.push({
      "index": 0,
      "id": ques.name,
      "code": ques["@miextsss:code"] ? ques["@miextsss:code"] : "",
      "@miextsss:qno": ques["@miextsss:qno"] ? ques["@miextsss:qno"] : ques.name,
      "type": ques['@type'] ? ques['@type'] : ques['@miextsss:type'] ? ques["@miextsss:type"] : "",
      "values": ques.values ? ques.values : [],
      "order": Number(ques["@miextsss:docorder"]),
      "title": ques.label.text['#text'] ? ques.label.text['#text'] : ques.label.text,
      "selected": false,
      "checked": false,
      "@ident": ques["@ident"],
      "position": ques.position ? ques.position : "",
      "disabled": ques["@miextsss:excluded"] === "true" ? true : false
    })
  }
  return newData
}