type User = {
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  isApiUser?: boolean;
}

export const returnUserDisplayName = (user: User) => {
  if (user.email) {
    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName} (${user.email})`;
    }
    if (user.firstName) {
      return `${user.firstName} (${user.email})`;
    }
    return user.email;
  }
  if (user.isApiUser) {
    return `${user.firstName} (API Key)`;
  }
}