import { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Form } from '@progress/kendo-react-form';
import { Stepper } from '@progress/kendo-react-layout';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { AddProjectContent } from '../AddProjectModal/AddProjectContent/AddProjectContent';
import { ErrorMessage } from '../../shared/ErrorMessage/ErrorMessage';
import ProjectActionButtons from '../../shared/ProjectActionButtons/ProjectActionButtons';
import returnOnChangeProjectData from '../../shared/helpers/returnOnChangeProjectData/returnOnChangeProjectData';
import type { RootState } from '../../../store/reducers/rootReducer';
import type { ProjectDetailsObject } from '../../../interfaces/projectDetailsInterfaces/projectDetailsInterfaces';
import FileManager from '../../shared/FileManager/FileManager';
import {
  fetchGetJson as getAllGroups,
  fetchGetJson as getProjectById,
  fetchPutResOrJson as updateProject,
  fetchGetJsonOrEmptyArray as getTags,
  rsAppUrl
} from '../../../services/services';

const items = [{ label: 'General settings' }, { label: 'Access control' }, { label: 'Branding' }];

type Props = {
  token: string;
  onHide: () => void;
  projectId: string;
  userData: TODO;
}

type GroupsAndUsers = {
  name: string;
  id: string;
  type: string;
}

type FormData = {
  projectName: string,
  description: string,
  iconUrl: string,
  accessControl: { list: GroupsAndUsers[] },
  tags: string[],
  radio: number;
  iconBackgroundColor: string;
}

export default function EditProjectModal({ token, onHide, projectId, userData }: Props) {
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const { newProjects } = useSelector((state: RootState) => state.projectsReducer);
  const [formData, setFormData] = useState<FormData>({ projectName: '', description: '', iconUrl: '', accessControl: { list: [] }, tags: [], radio: 1, iconBackgroundColor: "" })
  const [errorMessage, setErrorMessage] = useState(null)
  const [showFileManager, setShowFileManager] = useState(false)
  const [stepperValue, setStepperValue] = useState(0);
  const [tags, setTags] = useState([])
  const [list, setList] = useState<GroupsAndUsers[]>([])
  const [isLoadingAccessData, setIsLoadingAccessData] = useState(false)
  const userId = user?.[`${rsAppUrl}/userid`];

  const handleChange = (e: TODO) => {
    setStepperValue(e.value);
  };

  useEffect(() => {
    setIsLoadingAccessData(true)
    getTags("tags", token)
      .then((res: TODO) => {
        if (res) {
          setTags(res)
        }
      })
    getAllGroups("groups", token)
      .then((groups: TODO) => {
        if (groups?.length) {
          if (userData.role === 'regular') {
            setIsLoadingAccessData(false)
            const updatedGroups = groups.map((group: TODO) => { return { name: group.name, id: group.id, type: 'group' } })
            setList(updatedGroups)
          } else {
            getAllGroups("users", token)
              .then((users: TODO) => {
                setIsLoadingAccessData(false)
                if (users && !users.error && !users.message) {
                  const userId = user ? user[`${rsAppUrl}/userid`] : '';
                  const updatedGroups: GroupsAndUsers[] = groups.map((group: TODO) => { return { name: group.name, id: group.id, type: 'group' } })
                  const updatedUsers: GroupsAndUsers[] = users.filter((userInfo: TODO) => userId !== userInfo.id).map((user: TODO) => { return { name: user.email, id: user.id, type: 'user' } })
                  setList([...updatedGroups, ...updatedUsers])
                } else {
                  dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: users.message ? users.message : users.error } })
                }
              })
          }
        } else {
          setIsLoadingAccessData(false)
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: groups.message ? groups.message : groups.error } })
        }
      })
  }, [dispatch, token, userData.role, userId])

  const onChangeHandler = (e: TODO, name: string) => {
    const updatedFormData = returnOnChangeProjectData(name, formData, e)
    setFormData(updatedFormData)
  }

  useEffect(() => {
    getProjectById(`projects/${projectId}/extended`, token)
      .then((project: TODO) => {
        if (project && (project.message || project.error)) {
          dispatch({ type: 'UPDATE_ERROR_MESSAGE', payload: { msg: project.message ? project.message : project.error, type: 'modal' } })
        } else {
          const updatedList = [...formData.accessControl.list]
          project.accessUsers?.forEach((user: TODO) => { updatedList.push({ name: user.name, id: user.id, type: 'user' }) })
          project.accessGroups?.forEach((group: TODO) => { updatedList.push({ name: group.name, id: group.id, type: 'group' }) })
          setFormData({
            projectName: project.name,
            description: project.description,
            iconUrl: project.iconUrl ? project.iconUrl : '',
            tags: project.tags,
            radio: project.accessControl.accessType,
            iconBackgroundColor: project.iconBackgroundColor ? project.iconBackgroundColor : '',
            accessControl: {
              list: updatedList
            }
          })
        }
      })
  }, [projectId, token, dispatch])

  const onSubmitHandler = (e: TODO) => {
    e.preventDefault()
    const body = {
      name: formData.projectName,
      description: formData.description,
      iconUrl: formData.iconUrl || "",
      tags: formData.tags,
      iconBackgroundColor: formData.iconBackgroundColor,
      accessControl: {
        accessType: formData.radio,
        userIdAccessList: formData.accessControl.list.filter(el => el.type === 'user').map(user => user.id),
        groupIdAccessList: formData.accessControl.list.filter(el => el.type === 'group').map(group => group.id)
      }
    }

    updateProject(`projects/${projectId}`, token, body)
      .then(async (res: TODO) => {
        if (res && res.status === 200) {
          const projectResponse = await res.json() as unknown as ProjectDetailsObject;

          const updatedData = newProjects.map((item: ProjectDetailsObject) => (item.id === projectId ? { ...projectResponse } : item));

          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The project has been updated successfully' } })
          if (projectResponse) {
            dispatch({ type: "UPDATE_PROJECTS_DATA", payload: updatedData })
          }

          onHide()
        } else if (res && (res.message || res.error)) {
          setErrorMessage(res.message ? res.message : res.error)
        }
      })
  }

  return (
    <Dialog width={600} height={500} className="import-dialog" title={"Edit project"} onClose={onHide}>
      {
        showFileManager &&
        <FileManager
          displayType="modal"
          type={'public'}
          onSelect={(value) => setFormData({ ...formData, iconUrl: value })}
          onHide={() => setShowFileManager(false)}
          token={token}
        />
      }

      <Form
        onSubmit={onSubmitHandler}
        render={(_formRenderProps) => (
          <Fragment>
            <div className="px-4 py-4">
              <div className="pb-4">
                <Stepper value={stepperValue} onChange={handleChange} items={items} />
              </div>

              <AddProjectContent
                userData={userData}
                stepperValue={stepperValue}
                tags={tags}
                list={list}
                setList={setList}
                formData={formData}
                isLoadingAccessData={isLoadingAccessData}
                onChangeHandler={onChangeHandler}
                setShowFileManager={() => setShowFileManager(true)} />

              {
                errorMessage &&
                <ErrorMessage
                  type="alert"
                  errorMessage={errorMessage} />
              }
            </div>
          </Fragment>
        )}>
      </Form>
      <DialogActionsBar>
        <ProjectActionButtons
          submitAction={'Update'}
          onHide={onHide}
          stepperValue={stepperValue}
          steps={items.length}
          handleChange={handleChange}
          onSubmitHandler={onSubmitHandler}
          formRef={undefined}
          isLoading={undefined}
          key={undefined}
        />
      </DialogActionsBar>
    </Dialog >
  )
}
