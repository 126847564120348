import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchGetJson as getSystemLanguages } from '../../../services/services';
import type { RootState } from '../../../store/reducers/rootReducer';

export const useFetchSystemLanguages = (systemLanguages: TODO) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const [didMount, setDidMount] = useState<boolean>(false)

  useEffect(() => {
    if (systemLanguages === null && !didMount) {
      setDidMount(true)
      getSystemLanguages("resources/languages", token)
        .then((res: TODO) => {
          if (res && !res.error && !res.message) {
            dispatch({ type: "SET_SYSTEM_LANGUAGES", payload: res })
          }
        })
    }
  }, [token, dispatch, didMount, systemLanguages])
}