import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'

import type { DeletePromptComponent } from '../../../interfaces/gateKeeperInterfaces/gateKeeperInterfaces'

export const DeleteProptModal = ({ onHide, handleDelete, item }: DeletePromptComponent) => {

  return (
    <Dialog height={"250px"} width={"400px"} className='gk-new-project' title={"Delete Project"} onClose={() => onHide("DeletePrompt")}>
      <p className='text-primary p-3'  >
        This action will delete project - <strong>{item.displayName}</strong>.
        <br />
        <br />
        Do you want to proceed?
      </p>
      <DialogActionsBar>
        <div className='d-flex'>
          <button
            type='submit'
            className="btn btn-primary mr-2"
            onClick={() => onHide("DeletePrompt")}
          >
            Cancel
          </button>
          <button
            type='button'
            className="btn btn-primary"
            onClick={() => handleDelete(item)}
          >
            Proceed
          </button>
        </div>
      </DialogActionsBar>
    </Dialog>
  )
}