import { Fragment } from 'react';
import { useDispatch } from 'react-redux';

import { Icon } from '../../Icon/Icon';

interface Props {
  msg: string
  showSpinner?: boolean
  showCloseBtn?: boolean,
  itemLink?: string,
}

export const EtagErrorNotificationContent = ({ msg }: Props) => {
  const dispatch = useDispatch();

  return (
    <div className="d-flex align-items-center">
      <i className="fa fa-exclamation-triangle mr-3"></i>
      <span>{msg}</span>
      <button
        onClick={() => window.location.reload()}
        className="btn btn-transparent text-white shadow-none link mx-2 py-0">
        <strong>Reload</strong>
      </button>
      <span onClick={() => dispatch({ type: "CLOSE_ETAG_ERROR_NOTIFICATION" })}>
        <Icon type={"close"} className="ml-0 t-close cursor-pointer" fill="white" />
      </span>
    </div>
  )
}

// The permanent notification, with a spinner, that does not go away until clicked (called by 'SHOW_PENDING_NOTIFICATION')
export const PendingNotificationContent = ({ msg, showSpinner, showCloseBtn, itemLink }: Props) => {
  const dispatch = useDispatch();

  return (
    <div className="d-flex align-items-center">
      {showSpinner &&
        <div className="spinner-border spinner-border-sm text-light mr-2" role="status">
          <span className="sr-only"></span>
        </div>
      }
      <span>{msg}</span>
      {itemLink &&
        <Fragment>
          <div className="pl-3"></div>
          <a href={itemLink} className="px-3 text-white link" style={{ borderColor: "rgb(100,100,100) !important" }}>Download</a>
        </Fragment>
      }
      {showCloseBtn &&
        <span onClick={() => dispatch({ type: "CLOSE_PENDING_NOTIFICATION" })}>
          <Icon type={"close"} className="t-close cursor-pointer" fill="white" />
        </span>
      }
    </div >
  )
}