import React from 'react';
import { useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { RootState } from '../../../../../../store/reducers/rootReducer';

interface IProps {
  findItemAndSetAsClicked: (validationError: any) => void
  handleClose: () => void
}

export const ErrorLog: React.FC<IProps> = ({ findItemAndSetAsClicked, handleClose }) => {
  const { theData } = useSelector((theState: RootState) => theState.surveyInitialDataReducer)

  return (
    <Dialog width="700px" height="600px" title="Error log" onClose={handleClose}>
      <div className="d-flex flex-column p-3 w-100">
        <ul className="list-group">
          {
            theData.validationErrors.map((validationError: any, key: number) => (
              <li onClick={() => findItemAndSetAsClicked(validationError)} key={key} className="list-group-item text-analyze">{validationError.description}</li>
            ))
          }
        </ul>
      </div>
      <DialogActionsBar>
        <button className="btn btn-secondary" onClick={handleClose}>Close</button>
      </DialogActionsBar>
    </Dialog>
  )
}
