import React, { Fragment } from 'react';
import { returnAcceptedFileType } from '../../helpers/project/returnAcceptedFileType/returnAcceptedFileType';

export const QuickImportFileInput = ({ selectedFileType, onInputChangeHandler, dataMetaFile, caseFile }) => {
  const acceptedDataMetaFileType = returnAcceptedFileType(selectedFileType)

  return (
    <Fragment>
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text">{selectedFileType === 'Triple S' ? 'Meta file' : 'Data file'}</span>
        </div>
        <div className="custom-file">
          <input
            onChange={onInputChangeHandler}
            name="dataMetaFile"
            accept={acceptedDataMetaFileType}
            type="file"
            placeholder="Choose file"
            className="custom-file-input"
            id="inputGroupFile01"
          />
          <label
            className="custom-file-label"
            htmlFor="inputGroupFile01">{dataMetaFile ? dataMetaFile.fileName : 'Choose file'}</label>
        </div>
      </div>

      {
        selectedFileType === 'Triple S' &&
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text">Case file</span>
          </div>
          <div className="custom-file">
            <input
              name="caseFile"
              onChange={onInputChangeHandler}
              accept=".csv, .asc"
              type="file"
              placeholder="Choose file"
              className="custom-file-input"
              id="inputGroupFile01"
            />
            <label className="custom-file-label" htmlFor="inputGroupFile01">{caseFile ? caseFile.fileName : 'Choose file'}</label>
          </div>
        </div>
      }
    </Fragment>
  )
}