const isCommonSubstring = (substring, strings, numStrings) => {
  for (let k = 1; k < numStrings; k++) {
    if (!strings[k].title.includes(substring)) {
      return false;
    }
  }
  return true;
}

const longestCommonSubstring = (inputStrings) => {
  if (inputStrings.length <= 1) {
    return inputStrings.length === 1 ? inputStrings[0].title : '';
  }

  const numStrings = inputStrings.length;
  const firstStringLength = inputStrings[0].title.length;
  let longestCommonSub = '';

  for (let i = 0; i < firstStringLength; i++) {
    for (let j = i + 1; j <= firstStringLength; j++) {
      const sub = inputStrings[0].title.slice(i, j);
      if (isCommonSubstring(sub, inputStrings, numStrings)) {
        longestCommonSub = sub.length > longestCommonSub.length ? sub : longestCommonSub;
      }
    }
  }

  return longestCommonSub;
}

const findUncommonTextInTitles = (titleObjects) => {
  const longestCommonSub = longestCommonSubstring(titleObjects);
  if (longestCommonSub === '') {
    // Handle the case where there is no common substring
    return titleObjects.map(titleObj => ({ ...titleObj, commonText: titleObj.title }));
  }
  const titlesWithCommonText = titleObjects.map(({ title }) => title.replace(longestCommonSub, '').trim() !== "" && title.replace(longestCommonSub, '').trim().length > 1 ? title.replace(longestCommonSub, '').trim() : title);
  return titlesWithCommonText;
}

export default (checkedQuestions) => {
  let updatedRowTitles = []
  let updatedTitle = ''

  updatedTitle = longestCommonSubstring(checkedQuestions);
  updatedRowTitles = findUncommonTextInTitles(checkedQuestions);

  return {
    updatedRowTitles: updatedRowTitles,
    updatedTitle: updatedTitle
  }
}