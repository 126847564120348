import React, { useEffect, useState } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { ContainerClient } from '@azure/storage-blob';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { BaseMultiSelect } from "../../shared/Inputs";
import { InProgressOverlay } from '../InProgressOverlay/InProgressOverlay';
import { fetchPost as saveFileToProject, fetchGetJsonOrEmptyArray as getTags, fetchPostJson as getFileManagerUploadToken } from '../../../services/services';

const listNoDataRender = () => {
  return (
    <h5 className="text-muted text-center">
      <span className="k-icon k-i-warning my-3" style={{ fontSize: '2.5em' }} />
      <br />
      <span>No tags created yet</span>
    </h5>
  );
}

export default ({ onHide, token, projectId, updateData }) => {
  const [tags, setTags] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState({ name: '', description: '', tags: [], files: null, extension: null })

  useEffect(() => {
    getTags(`tags`, token)
      .then(res => {
        if (res) {
          setTags(res)
        }
      })
  }, [token])

  const onChangeHandler = (e, name) => {
    const updatedExtension = e.target.files && e.target.files.length > 0 ? `.${e.target.files[0].name.split('.')[e.target.files.length]}` : null
    setFormData({
      ...formData,
      [name]: name === 'files' ? e.target.files : e.target.value,
      extension: name === 'files' ? updatedExtension : formData.extension
    })
  }

  const onSubmitHandler = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    const filesArr = Array.from(formData.files).map((file) => file)
    const fileNames = Array.from(formData.files).map(({ name }) => name)

    const body = { "fileNames": fileNames }
    const uploadToken = await getFileManagerUploadToken(`projects/${projectId}/files/token`, token, body)
    if (uploadToken && !uploadToken.message && !uploadToken.error) {
      const containerClient = new ContainerClient(uploadToken.sasUri);
      const promises = [];
      filesArr.forEach((file, id) => {
        const blobName = uploadToken.blobs[id].name
        const blockBlobClient = containerClient.getBlockBlobClient(blobName);
        const blobOptions = {
          blobHTTPHeaders: { blobContentType: uploadToken.blobs[id].contentType }
        };
        promises.push(blockBlobClient.uploadBrowserData(file, blobOptions));
      })
      const resp = await Promise.all(promises);
      if (resp) {
        const body = {
          files: filesArr.map(file => {
            return {
              "overwriteIfExists": true,
              "name": uploadToken.blobs[0].name,
              "saveAsName": `${formData.name}${formData.extension}`,
              "description": formData.description,
              "tags": formData.tags
            }
          })
        }
        saveFileToProject(`projects/${projectId}/files`, token, body)
          .then(res => {
            if (res.status === 201) {
              updateData()
              setIsLoading(false)
              onHide()
            } else {
              setIsLoading(false)
            }
          })

      } else {
        setIsLoading(false)
      }
    } else {
      setIsLoading(false)
    }
  }
  return (
    <Dialog minWidth={500} title={"Add file to project"} onClose={onHide} className="import-dialog">
      {
        isLoading &&
        <InProgressOverlay type="overlay" theme="primary" message="Uploading your file..." />
      }
      <form onSubmit={onSubmitHandler}>
        <div className="p-3">
          <label>Select a file:</label>
          <div className="input-group mb-3">
            <div className="custom-file">
              <input required onChange={(e) => onChangeHandler(e, 'files')} type="file" className="custom-file-input"></input>
              <label className="custom-file-label">
                {formData.files && formData.files.length > 0 ? formData.files[0].name : 'Choose file'}
              </label>
            </div>
          </div>
          <label>Name</label>
          <Input
            required
            onChange={e => onChangeHandler(e, 'name')}
            value={formData.name}
            placeholder="Enter file name"
            className="w-100 mb-3" />
          <div className="form-group">
            <label>Description</label>
            <textarea
              rows="3"
              className="form-control"
              placeholder="Enter file description"
              value={formData.description}
              onChange={e => onChangeHandler(e, 'description')} />
          </div>
          <div className="form-group">
            <label>Tags</label>
            <BaseMultiSelect
              data={tags}
              value={formData.tags}
              textField="name"
              dataItemKey="id"
              allowCustom={true}
              listNoDataRender={listNoDataRender}
              onChange={(e) => onChangeHandler(e, 'tags')} />
          </div>
        </div>
        <DialogActionsBar>
          <button className="k-button btn btn-secondary" onClick={onHide}>Cancel</button>
          <button type="submit" className="k-button btn btn-primary">Add</button>
        </DialogActionsBar>
      </form>
    </Dialog >
  )
}