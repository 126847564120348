
import { SurveyDesignTabContent } from './SurveyDesignTabContent/SurveyDesignTabContent';
import { SurveyPreviewTabContent } from './SurveyPreviewTabContent/SurveyPreviewTabContent';
import { SurveyCollectTabContent } from './SurveyCollectTabContent/SurveyCollectTabContent';
import { SurveyNewOverviewTabContent } from './SurveyNewOverviewTabContent/SurveyNewOverviewTabContent';
import type { User } from '../../../interfaces/userInterface/userInterface';

type Props = {
  tabType: 'overview' | 'design' | 'test' | 'collect'
  user: User;
  token: string;
  onFixValidationErrors: () => void;
}

export const SurveyTabContent = ({ tabType, user, token, onFixValidationErrors }: Props) => {
  switch (tabType) {
    case "overview":
      return <SurveyNewOverviewTabContent />
    case "design":
      return <SurveyDesignTabContent
        user={user}
        token={token}
      />
    case "test":
      return <SurveyPreviewTabContent
        token={token}
        onFixValidationErrors={onFixValidationErrors}
      />
    case "collect":
      return <SurveyCollectTabContent
        token={token}
      />
    default:
      return null;
  }
}