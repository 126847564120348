import { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { Icon } from '../../../../shared/Icon/Icon';
import type { RootState } from '../../../../../store/reducers/rootReducer';
import { ErrorMessage } from '../../../../shared/ErrorMessage/ErrorMessage';
import { DropdownButton } from '../../../../shared/DropdownButton/DropdownButton';
import { returnQuestionTypeText } from '../../../../shared/helpers/returnQuestionTypeText/returnQuestionTypeText';
import { renderCustomQuestionIconItem } from '../../../../shared/helpers/renderCustomQuestionIconItem/renderCustomQuestionIconItem';
import { renderCustomQuestionIconColorItem } from '../../../../shared/helpers/renderCustomQuestionIconColorItem/renderCustomQuestionIconColorItem';
import { fetchPostJson as createCustomQuestion, fetchPatchJson as updateCustomQuestion } from '../../../../../services/services';

interface Props {
  tags: TODO
  customQuestion: TODO
  onHide: () => void
  updateData: () => void
}

const questionTypes = [
  { text: 'Numeric (F)', id: 'DefaultQuesF' },
  { text: 'Hour (H)', id: 'DefaultQuesH' },
  { text: 'Multiple (M)', id: 'DefaultQuesM' },
  { text: 'Single (N)', id: 'DefaultQuesN' },
  { text: 'Multiple grid (Rm)', id: 'DefaultQuesRm' },
  { text: 'Single grid (Rn)', id: 'DefaultQuesRn' },
  { text: 'Open', id: 'DefaultQuesOpen' },
  { text: 'Quota', id: 'DefaultQuesQuota' },
]

export const CreateSurveyCustomQuestionModal = ({ customQuestion, tags, onHide, updateData }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [formData, setFormData] = useState<TODO>({ name: '', description: '', tags: [], questionType: 'DefaultQuesF', icon: 'ic-carousel', iconColor: '', dataCollectionVersion: 1 })
  const { tabSelected } = useSelector((theState: RootState) => theState.surveyCustomQuestionsStateReducer)

  useEffect(() => {
    if (customQuestion) {
      setFormData({
        name: customQuestion.name,
        description: customQuestion.description ? customQuestion.description : '',
        tags: customQuestion.tags ? customQuestion.tags : [],
        questionType: '',
        dataCollectionVersion: customQuestion.dataCollectionVersion ? customQuestion.dataCollectionVersion : 1,
      })
    }
  }, [customQuestion])

  const onSubmitHandler = (e: TODO) => {
    e.preventDefault()
    setIsLoading(true)
    if (customQuestion === null) {
      const body = { name: formData.name, description: formData.description, tags: formData.tags, templateId: formData.questionType, icon: formData.icon, iconColor: formData.iconColor, dataCollectionVersion: formData.dataCollectionVersion }
      createCustomQuestion("su/customquestions", token, body)
        .then((res: TODO) => {
          setIsLoading(false)
          if (res && !res.error && !res.message) {
            if (tabSelected === 1) {
              dispatch({ type: 'UPDATE_SELECTED_TAB', payload: 0 })
            }
            updateData()
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "The custom question has been created successfuly!" } })
            onHide()
          } else {
            setErrorMessage(res.error ? res.error : res.message)
          }
        })
    } else {
      const body = { name: formData.name, description: formData.description, tags: formData.tags, icon: formData.icon, iconColor: formData.iconColor, dataCollectionVersion: formData.dataCollectionVersion }
      updateCustomQuestion(`su/customquestions/${customQuestion.id}`, token, body)
        .then((data: TODO) => data.json())
        .then((res: TODO) => {
          setIsLoading(false)
          if (res && !res.error && !res.message) {
            updateData()
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "The custom question has been updated successfuly!" } })
            onHide()
          } else {
            setErrorMessage(res.error ? res.error : res.message)
          }
        })
    }
  }

  const onChangeHandler = (e: TODO) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  return (
    <Dialog width={600} title={customQuestion ? "Edit custom question" : "Create a custom question"} onClose={onHide}>
      <form onSubmit={onSubmitHandler}>
        <div className="p-4">
          {
            <Fragment>
              <label className='mb-1 medium strong'>Name</label>
              <input type="text" onChange={onChangeHandler} value={formData.name} required name="name" className="form-control mb-2" />
              <label className='mb-1'>Description</label>
              <textarea onChange={onChangeHandler} name="description" className='form-control mb-2' rows={4} />
              <div className="d-flex mb-2">
                {
                  customQuestion === null &&
                  <div className='mr-2 flex-grow-1'>
                    <label className='mb-1'>Question type</label>
                    <DropdownButton
                      textField='text'
                      className='btn-block'
                      parentClass='form-control p-0'
                      items={questionTypes}
                      text={returnQuestionTypeText(formData.questionType)}
                      onItemClick={(e) => onChangeHandler({ target: { name: 'questionType', value: e.item.id } })}
                    />
                  </div>
                }
                <div className='mr-2 d-flex flex-column'>
                  <label className='mb-1'>Color</label>
                  <DropdownButton
                    items={[{ text: '' }]}
                    className='btn-block py-0 px-2'
                    renderMainButton={() => (
                      <div className={`custom-question-icon-color create bg-custom-questions-${formData.iconColor ? formData.iconColor : 'grey'}`} />
                    )}
                    renderItem={() => renderCustomQuestionIconColorItem(onChangeHandler)}
                    parentClass='icons-dropdown form-control p-0'
                  />
                </div>
                <div className='mr-2'>
                  <label className='mb-1'>Icon</label>
                  <DropdownButton
                    items={[{ text: '' }]}
                    className='btn-block'
                    renderMainButton={() => (
                      <Icon type={formData.icon} />
                    )}
                    parentClass='icons-dropdown form-control p-0'
                    renderItem={() => renderCustomQuestionIconItem(onChangeHandler)}
                  />
                </div>
              </div>
              <label className='mb-1'>Tags</label>
              <MultiSelect
                name="tags"
                filterable={true}
                onChange={onChangeHandler}
                className="w-100 mb-2"
                allowCustom={true}
                value={formData.tags}
                data={tags ? tags : []}
              />
              <label>DC Version:</label>
              <div className='d-flex'>
                <div className="list-group list-group-horizontal">
                  <button
                    type="button"
                    onClick={() => setFormData({ ...formData, dataCollectionVersion: 1 })}
                    className={`list-group-item list-group-item-action py-1 px-8 ${formData.dataCollectionVersion === 1 ? 'active' : ''}`}>V1
                  </button>
                  <button
                    type="button"
                    onClick={() => setFormData({ ...formData, dataCollectionVersion: 2 })}
                    className={`list-group-item list-group-item-action py-1 px-8 ${formData.dataCollectionVersion === 2 ? 'active' : ''}`}>V2
                  </button>
                </div>
              </div>
              {
                errorMessage &&
                <div className="mt-3">
                  <ErrorMessage
                    errorMessage={errorMessage}
                    type="alert"
                  />
                </div>
              }
            </Fragment>
          }
        </div>
        <DialogActionsBar>
          <button type="button" className="k-button k-button-solid-base" onClick={onHide}>Cancel</button>
          <button disabled={isLoading} type="submit" className="k-button k-button-solid-primary">
            {isLoading && <span className="spinner-border spinner-border-sm mr-2" />}
            <span>{customQuestion === null ? 'Create' : 'Update'}</span>
          </button>
        </DialogActionsBar>
      </form >
    </Dialog>
  )
}