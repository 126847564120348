import React, { useState } from 'react';
import { Avatar as AvatarKendo } from '@progress/kendo-react-layout';

import { returnAvatarContent } from '../helpers/returnAvatarContent/returnAvatarContent';

export const Avatar = (props) => {
  const [errorImage, setErrorImage] = useState(false)

  const iconSize = props.class ? props.class.includes('avatar-xl') ? 'fa-2x' : '' : '';

  const avatarData = returnAvatarContent(props.icon, iconSize, errorImage, props.image, props.value, () => setErrorImage(true))

  return (
    <AvatarKendo
      style={{ backgroundColor: `${props.backgroundColor}` }}
      id="avatar"
      rounded={props.rounded ? props.rounded : "full"}
      size={props.size ? props.size : "large"}
      type={avatarData.avatarType}
      className={`${props.color ? `bg-${props.color}` : ''} ${props.class ? props.class : ''} ${props.iconColor ? props.iconColor : ''}`}>
      {avatarData.avatarContent}
    </AvatarKendo>
  );
};
