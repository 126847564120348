
import disableOptionByPlan from "../disableOptionByPlan/disableOptionByPlan"

export default (data, user, groups) => {
  const initialBooleanOptions = ['DigChi2D', 'DigSize', 'DigNVert', 'DigDescription', 'DigAddress']
  const initialDropdownValuesFirst = ['DigDecimalChi2', 'DecimalPlaces.One', 'DigSortRollSign']
  const initialDoubleValues = ['DigLimitChi2']
  let list = {}
  data.forEach(option => {
    if (option.type === "Boolean") {
      if (initialBooleanOptions.includes(option.id)) {
        let disabled = disableOptionByPlan(option, user, groups)
        if (disabled === false) {
          list[option.id] = true
        } else {
          list[option.id] = false
        }
      } else {
        list[option.id] = false
      }
    } else if (option.type === "String") {
      if (option.id === 'PctSign') {
        let disabled = disableOptionByPlan(option, user, groups)
        if (disabled === false) {
          list[option.id] = "hori vert nhori nvert size chi2d"
        } else {
          list[option.id] = ''
        }
      }
      list[option.id] = ''
    } else if (option.type === "Int32" || option.type === "Double") {
      if (initialDoubleValues.includes(option.id)) {
        let disabled = disableOptionByPlan(option, user, groups)
        if (disabled === false) {
          list[option.id] = 4
        } else {
          list[option.id] = 0
        }
      } else {
        list[option.id] = 0
      }
    } else if (option.id === 'SelectedWeight') {
      list[option.id] = 'None'
    } else {
      if (initialDropdownValuesFirst.includes(option.id)) {
        let disabled = disableOptionByPlan(option, user, groups)
        if (disabled === false) {
          list[option.id] = 1
        } else {
          list[option.id] = 0
        }
      } else {
        list[option.id] = 0
      }
    }
  })
  return list
}