import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import type { RootState } from "../../../../../../../store/reducers/rootReducer";
import { setReport, updateCurrentReportFolder, updateLoading, updateTableData } from "../../../../../../../store/reducers/analysisReportsStateReducer/analysisReportsStateReducer";
import { DropdownButton } from "../../../../../../shared/DropdownButton/DropdownButton";
import { type ReportsActionsType, returnActionItems } from "./returnActionItems";
import { DeleteModal } from "./Modals/DeleteModal";
import { Icon } from "../../../../../../shared/Icon/Icon";
import { fetchGetJson } from "../../../../../../../services/services";
import { FullHeightSpinner } from "../../../../../../shared/FullHeightSpinner/FullHeightSpinner";
import { EditReportsModal } from "./Modals/EditReportsModal";

export type Report = {
  id: string;
  fileURL: string;
  name: string;
  type: ReportTableTypes;
}

export type ReportFolder = {
  datasetId: string;
  id: string;
  name: string;
  reports: Report[]
}

const reportTableTypes = ["g", "xt", "topline", "qt", "data", "data", "dig"] as const
export type ReportTableTypes = (typeof reportTableTypes)[number];

export const ReportsList = () => {
  const reportsData = useSelector((theState: RootState) => theState.analysisReportsReducer);
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);

  const params: { name: string, dataset: string } = useParams();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [expandItem, setExpandItem] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditReportsModal, setShowEditReportsModal] = useState(false);
  const [deleteData, setDeleteData] = useState<{ type: "reportFolder" | "report", reportFolder: ReportFolder, report?: Report } | null>(null);
  const [editData, setEditData] = useState<{ type: "reportFolder" | "report", reportFolder: ReportFolder, report?: Report } | null>(null);
  const [reportFolders, setReportFolders] = useState<ReportFolder[] | null>(null);

  const reportTypesItem = {
    "g": "Chart",
    "xt": "Cross table",
    "topline": "Topline",
    "qt": "Q table",
    "data": "Data",
    "dig": "Dig"
  }

  useEffect(() => {
    fetchGetJson(`an/projects/${params.name}/analysis/${params.dataset}/reportfolders`, token)
      .then((res: TODO) => {
        if (res.error || res.message) {
          setIsLoading(false);
        } else {
          setReportFolders(res);
          setIsLoading(false);
        }
      })
  }, [token, params]);

  const handleSelectReport = (reportFolder: ReportFolder, report: Report) => {
    dispatch(setReport({}));
    dispatch(updateTableData(""));
    dispatch(updateLoading(true));

    fetchGetJson(`an/projects/${params.name}/analysis/${params.dataset}/reportfolders/${reportFolder.id}/reportSnapshots/${report.id}`, token)
      .then((res: TODO) => {
        if (!res.error || !res.message) {
          dispatch(setReport(res));
          dispatch(updateCurrentReportFolder(reportFolder.id));
          dispatch(updateLoading(false));
        }
      })
  }


  const actionItems = returnActionItems();

  const actionItemsClick = (actionType: ReportsActionsType, type: "reportFolder" | "report", reportFolder: ReportFolder, report?: Report) => {

    switch (actionType) {
      case "delete":
        setDeleteData({ type, reportFolder, report })
        setShowDeleteModal(true);
        break;
      case "edit":
        setEditData({ type, reportFolder, report })
        setShowEditReportsModal(true);
        break;
    }
  }

  const expandRow = (event: TODO, type: "reportFolder", reportFolder: ReportFolder) => {
    if (event.target.id === reportFolder?.id && type === "reportFolder") {
      setExpandItem(expandItem === reportFolder?.id ? "" : reportFolder?.id)
    }
  }

  return (
    <Fragment>
      {
        showEditReportsModal && reportFolders &&
        <EditReportsModal setShowEditReportsModal={setShowEditReportsModal} editData={editData} setEditData={setEditData} setReportFolders={setReportFolders} reportFolders={reportFolders} />
      }

      {
        showDeleteModal && reportFolders &&
        <DeleteModal deleteData={deleteData} setDeleteData={setDeleteData} setShowDeleteModal={setShowDeleteModal} setReportFolders={setReportFolders} reportFolders={reportFolders} />
      }

      <div className="d-flex flex-column overflow-auto py-2 h-100">
        {isLoading ?
          <FullHeightSpinner /> :
          reportFolders?.length ?
            reportFolders?.map((reportFolder) =>
              <Fragment key={reportFolder.id}>
                {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
                <div
                  id={reportFolder.id}
                  className={`d-flex p-2 border-bottom mb-1 justify-content-between align-items-center ${expandItem === reportFolder.id ? "reports-active-container" : ""}`}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => expandRow(e, "reportFolder", reportFolder)}
                >
                  <div className="d-flex align-items-center gap-md question-text pe-none">
                    <div className="rounded bg-secondary p-1">
                      <Icon type="library-folder" />
                    </div>
                    <p className="m-0">{reportFolder.name}</p>
                  </div>
                  <div>
                    <DropdownButton
                      items={actionItems}
                      className="btn btn-transparent"
                      textField="name"
                      hideChevron={true}
                      onItemClick={(e) => {
                        actionItemsClick(e.item.id, "reportFolder", reportFolder)
                      }}
                    />
                  </div>
                </div>

                <div className="d-flex flex-column gap-md mb-1 px-1">
                  {
                    reportFolder.reports.length > 0 && expandItem === reportFolder.id && reportFolder.reports.map((report) =>
                      <div key={report.id} className={`d-flex justify-content-between align-items-center reports-accordion-item w-100 text-left ${reportsData.report.id === report.id ? "reports-active-row" : ""}`}>
                        {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
                        <div className="d-flex flex-column question-text w-75" onClick={() => handleSelectReport(reportFolder, report)} style={{ cursor: "pointer" }}>
                          <p className="m-0">{report.name}</p>
                          <p className="m-0 small font-italic">{reportTypesItem[report.type]}</p>
                        </div>

                        <div>
                          <DropdownButton
                            items={actionItems}
                            className="btn btn-transparent"
                            textField="name"
                            hideChevron={true}
                            onItemClick={(e) => {
                              actionItemsClick(e.item.id, "report", reportFolder, report)
                            }}
                          />
                        </div>
                      </div>
                    )
                  }
                </div>
              </Fragment>
            )
            :
            <div className="d-flex flex-column align-items-center pt-5">
              <p className="m-0">No saved folders on dataset</p>
            </div>

        }
      </div>
    </Fragment>
  )
}