import type { Tool } from "../../../../interfaces/workflowInterfaces/toolInterfaces";

export const workflowTools: Tool[] = [
  {
    text: "Append",
    icon: "append",
    id: "Append"
  },
  {
    text: "Advanced",
    icon: "advanced",
    id: "CleanAndDefine"
  },
  {
    text: "Match",
    icon: "match",
    id: "Match"
  },
  {
    text: "Coding",
    icon: "coding",
    id: "Coding"
  }
]