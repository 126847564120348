import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { ErrorMessage } from '../../ErrorMessage/ErrorMessage';
import { RootState } from '../../../../store/reducers/rootReducer';
import { fetchDeleteWithBody as deleteFileManagerRootDirectory } from '../../../../services/services';
import { clearFileManager, updateCheckedFiles, updateDeleteRootDirectoryModal, updateFileManagerExpandedState } from '../../../../store/reducers/fileManagerStateReducer/fileManagerStateReducer';

interface Props {
  type: string
  token: string
  updateData: () => void
}

export const FileManagerDeleteDirectoryModal = ({ type, token, updateData }: Props) => {
  const dispatch = useDispatch()
  const { deleteRootDirectoryModal, expandedState } = useSelector((state: RootState) => state.fileManagerStateReducer);
  const [errorMessage, setErrorMessage] = useState<string>('')
  const onHide = () => dispatch(updateDeleteRootDirectoryModal({ show: false, directory: null }))

  const onDeleteDirectoryHandler = () => {
    if (deleteRootDirectoryModal.directory?.directoryReference) {
      const body = {
        files: [],
        directories: [deleteRootDirectoryModal.directory?.directoryReference]
      }
      deleteFileManagerRootDirectory(`files/${type}`, token, body)
        .then((res: any) => {
          if (res.status === 204) {
            dispatch(updateFileManagerExpandedState({ ...expandedState, data: [] }))
            dispatch(clearFileManager())
            dispatch(updateCheckedFiles([]))
            updateData()
            onHide()
          } else {
            setErrorMessage('Something went wrong, please try again.')
          }
        })
    }
  }

  return (
    <Dialog minWidth={500} title={`Delete ${deleteRootDirectoryModal.directory?.displayName}`} onClose={onHide} className="import-dialog">
      <div className="p-4">
        <p className={`${errorMessage ? 'mb-4' : 'm-0'}`}>Are you sure you want to delete this directory and all of its contents?</p>
        {
          errorMessage &&
          <ErrorMessage
            type={"alert"}
            errorMessage={errorMessage}
          />
        }
      </div>
      <DialogActionsBar>
        <button className="k-button btn btn-secondary" onClick={onHide}>Cancel</button>
        <button className="k-button btn btn-primary" onClick={onDeleteDirectoryHandler}>Delete</button>
      </DialogActionsBar>
    </Dialog >
  )
}