export function clearQuestionRows(theState, payload) {
  if (theState.state.firstColumn) {
    if (theState.state.firstColumn.length > 0) {
      return {
        ...theState,
        state: {
          ...theState.state,
          firstColumn: [],
          displayTable: null,
          updateTable: payload?.clearSession ? false : true,
          reducedTable: false
        }
      }
    } else {
      return { ...theState }
    }
  } else {
    return { ...theState }
  }
}