import React from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Field, FormElement } from '@progress/kendo-react-form';

import { BaseMultiSelect } from '../../../shared/Inputs';

export const AddProjectGeneralSettings = ({ formData, tags, value, onChangeHandler }) => {
  const listNoDataRender = () => {
    return (
      <h5 className="text-muted text-center">
        <span className="k-icon k-i-warning my-3" style={{ fontSize: '2.5em' }} />
        <br />
        <span>No tags created yet</span>
      </h5>
    );
  }

  return (
    <FormElement>
      <fieldset className={'k-form-fieldset'}>
        <div className="mb-2">
          <Input value={formData.projectName} onChange={(e) => onChangeHandler(e, 'projectName')} label={'Project name'} name='projectName' id="projectName" />
        </div>
        <div className="mb-2">
          <Input value={formData.description} onChange={(e) => onChangeHandler(e, 'description')} label={'Description'} name='description' id="description" />
        </div>
        <div className="mb-2">
          <BaseMultiSelect
            data={tags}
            value={value}
            label="Tags"
            textField="name"
            dataItemKey="id"
            allowCustom={true}
            listNoDataRender={listNoDataRender}
            onChange={(e) => onChangeHandler(e, 'tags')} />
        </div>
      </fieldset>
    </FormElement>
  )
}