
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { FileManagerContent } from './FileManagerContent/FileManagerContent';
import { returnUpdatedActionButtons } from '../helpers/returnUpdatedActionButtons/returnUpdatedActionButtons';
import type { RootState } from '../../../store/reducers/rootReducer';
import type { FileManagerItem } from '../../../interfaces/fileManagerInterfaces/fileManagerInterfaces';
import { clearFileManager, updateActionButtons, updateActiveType, updateFileManagerExpandedState, updateFileManagerState, updateIsLoading } from '../../../store/reducers/fileManagerStateReducer/fileManagerStateReducer';
import { useUpdateFileManagerBreadCrumb } from '../customHooks/useUpdateFileManagerBreadCrumb';
import { fetchGetJson as getDirectoryListing } from '../../../services/services';

interface Props {
  token: string
  type: string
  displayType: string
  onHide?: () => void
  onSelect?: (item: string) => void
}

export default function FileManager({ token, onHide, onSelect, type, displayType }: Props) {
  const dispatch = useDispatch()
  const { state, expandedState, activeType, checkedFiles, actionButtons, selectCreatedFolder } = useSelector((state: RootState) => state.fileManagerStateReducer);

  const [didMount, setDidMount] = useState(false)
  const [didUpdateType, setDidUpdateType] = useState(false)

  const findDeep = useCallback((data: FileManagerItem[], displayName: string, matchedItems: FileManagerItem[]): boolean => {
    if (data) {
      return data.some((e: FileManagerItem) => {
        if (e.displayName === displayName) {
          matchedItems.push(e)
          return true
        }
        if (e.contents) return findDeep(e.contents, displayName, matchedItems)
        return false
      })
    }
    return false
  }, [])

  const updateData = () => {
    setDidMount(false)
    setDidUpdateType(true)
  }

  useEffect(() => {
    if (!didUpdateType) {
      if (activeType === '' || type !== activeType) {
        dispatch(clearFileManager())
        dispatch(updateActiveType(type))
      }
      setDidUpdateType(true)
    }
  }, [type, activeType, didUpdateType, dispatch])

  useEffect(() => {
    if (!didMount && didUpdateType) {
      setDidMount(true)
      getDirectoryListing(`files/${type}`, token)
        .then((res: TODO) => {
          if (res.contents) {
            dispatch(updateIsLoading(false))
            const fileManagerDirectories = res.contents.filter((item: TODO) => item.isDirectory)
            let selected = null
            if (selectCreatedFolder) {
              selected = fileManagerDirectories.find((item: FileManagerItem) => item.displayName === selectCreatedFolder)
            } else {
              selected = fileManagerDirectories[0]
            }
            const matchedItems: FileManagerItem[] = []
            if (selectCreatedFolder) {
              dispatch(updateFileManagerExpandedState({ ...expandedState, data: [selected] }))
            } else {
              if (expandedState.data[0]) {
                const newData = expandedState.data[0].id.split('/').filter(item => item !== "")
                const lastItem = newData.pop()
                findDeep(fileManagerDirectories, lastItem!, matchedItems)
                dispatch(updateFileManagerExpandedState({ ...expandedState, data: matchedItems }))
              } else {
                dispatch(updateFileManagerExpandedState({ ...expandedState, data: [selected] }))
              }
            }
            const updatedActionButtons = returnUpdatedActionButtons([...actionButtons], 'state', checkedFiles)

            dispatch(updateActionButtons(updatedActionButtons))
            dispatch(updateFileManagerState({ ...state, data: fileManagerDirectories, selected: selectCreatedFolder ? selected : state.selected ? state.selected : selected }))
          }
        })
    }
  }, [didMount, didUpdateType])

  useUpdateFileManagerBreadCrumb(findDeep)

  const onSelectHandler = () => {
    if (checkedFiles && checkedFiles.length === 1) {
      const selectedElement = checkedFiles[0]
      onSelect?.(type === 'public' ? selectedElement.uri ? selectedElement.uri : '' : selectedElement.id ? selectedElement.id : '')
      onHide?.()
    }
  }

  if (displayType === 'modal') {
    return (
      <Dialog width={"80vw"} height={"90vh"} className="import-dialog filemanager-dialog" title={"Select a template file"} onClose={onHide}>
        <FileManagerContent
          type={type}
          token={token}
          updateData={updateData} />

        <DialogActionsBar>
          <button type='button' className="k-button k-button-solid-base" onClick={onHide}>Close</button>
          <button
            type='button'
            disabled={checkedFiles && checkedFiles.length === 1 && !checkedFiles[0].isDirectory ? false : true}
            className="k-button k-button-solid-primary"
            onClick={onSelectHandler}>Select</button>
        </DialogActionsBar>
      </Dialog >
    );
  } if (displayType === "fullscreen") {
    return (
      <div className="d-flex flex-column h-100 justify-content-between">
        <FileManagerContent
          type={type}
          token={token}
          updateData={updateData}
          displayType={displayType} />
        <div className="d-flex w-100 flex-shrink-0 cta">
          <button type='button' className="btn btn-secondary w-50 justify-content-center rounded-0" onClick={onHide}>Close</button>
          <button type='button' disabled={checkedFiles && checkedFiles.length === 1 && !checkedFiles[0].isDirectory ? false : true} className="btn btn-primary justify-content-center w-50 rounded-0" onClick={onSelectHandler}>Select</button>
        </div>
      </div>
    )
  }
  return (
    <FileManagerContent
      type={type}
      token={token}
      updateData={updateData} />
  )
}