import React, { useState } from 'react'
import Editor from "@monaco-editor/react";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { Button } from '@progress/kendo-react-buttons';


export default ({ handleClose, type, value, updateScript }) => {
  const [content, setContent] = useState(value)

  return (
    <Dialog title="Edit script" onClose={handleClose} width="700px">
      <div className="p-4 d-flex flex-column overflow-hidden" style={{ 'height': '50vh' }}>
        <Editor
          defaultLanguage={type}
          defaultValue=""
          value={value}
          theme={type === 'html' ? 'light' : 'vs-dark'}
          onChange={(value) => setContent(value)}
        />
      </div>
      <DialogActionsBar>
        <Button className="btn btn-secondary" onClick={handleClose}>Cancel</Button>
        <Button className="btn btn-primary" onClick={() => updateScript(content)}>Update script</Button>
      </DialogActionsBar>
    </Dialog>
  )
}