import { useState, type FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'

import type { RootState } from '../../../store/reducers/rootReducer';
import { type ValidationError, handleZodValidation } from '../../shared/Zod/handleZodValidation';
import { reconcileSchema } from '../../shared/Zod/ZodSchemas/reconcileSchema';
import type { ReconciliationDataSlim } from '../../../interfaces/reconciliationsInterfaces/gateKeeperInterfaces';
import { fetchPostResOrJson } from '../../../services/services';
import { useParams } from 'react-router-dom';


type Props = {
  setReconciliationDataSlim?: (data: ReconciliationDataSlim) => void;
  setShowNewReconcileModal: (show: boolean) => void;
  type: "audiences" | "audienceOverview";
  projectId?: string;
}

type FormDataType = {
  respondentIds: string;
  type: "Append" | "Overwrite";
  projectId: string;
}

export const ReconcileModal = ({ setShowNewReconcileModal, type, setReconciliationDataSlim, projectId }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<ValidationError<typeof reconcileSchema>>();
  const params: { name: string } = useParams();

  const dispatch = useDispatch();

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    setErrors({});
    event.preventDefault();
    const formData = Object.fromEntries(new FormData(event.currentTarget)) as unknown as FormDataType;

    const formatedFormData = {
      respondentIds: formData.respondentIds.length > 0 ? formData.respondentIds.split('\n') : [],
      type: formData.type,
      projectId: projectId ? projectId : params.name
    }

    handleZodValidation({
      onError: setErrors,
      data: formatedFormData,
      onSuccess: () => {
        setIsLoading(true);
        fetchPostResOrJson("au/r/reconcile", token, formatedFormData)
          .then(async (res: TODO) => {
            if (type === "audiences") {
              dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "Reconcile has been added to the queue" } })
            }

            if (type === "audienceOverview") {
              try {
                setIsLoading(false);
                const body = await res.json();
                setReconciliationDataSlim?.(body);
                setShowNewReconcileModal(false);
              } catch (err) {
                dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res[0].errorMessage } })
                setIsLoading(false);
              }
            }
          })
      },
      schema: reconcileSchema,
    });

  };

  return (
    <Dialog width={"600px"} className='reconcile-modal' contentStyle={{ padding: 0, fontSize: 14 }} title={"New reconciliation"} onClose={() => setShowNewReconcileModal(false)}>
      <form onSubmit={(e) => onSubmit(e)} className='d-flex flex-column gap-md p-4'>
        <p className='m-0'>
          Walr IDs
        </p>

        <p className='m-0 text-muted'>
          Upload all the accept Walr IDs, any IDs not included will be removed.
        </p>

        <div className="details-input d-flex flex-column justify-content-center px-0">
          <div className='d-flex gap-md'>
            <div className="d-flex gap-md">
              <input className='cursor-pointer' id="Append" type="radio" name="type" value="Append" />
              <label className="d-flex align-items-center cursor-pointer m-0 strong medium" htmlFor="Append">Append to previous reconciliation</label>
            </div>

            <div className="d-flex gap-md">
              <input className='cursor-pointer' id="Overwrite" type="radio" name="type" value="Overwrite" />
              <label className="d-flex align-items-center cursor-pointer m-0 strong medium" htmlFor="Overwrite">Overwrite previous reconciliation</label>
            </div>
          </div>

          {
            errors?.type &&
            <div className="text-danger mt-1">
              <small>{errors.type}</small>
            </div>
          }
        </div>

        <textarea
          className='form-control'
          id="reconcileModal"
          name="respondentIds"
          placeholder='Add IDs one per line'
          rows={5}
          cols={50}
        />

        {
          errors?.respondentIds &&
          <div className="text-danger">
            <small>{errors.respondentIds}</small>
          </div>
        }

        <DialogActionsBar>
          <button
            type='button'
            className="btn btn-secondary mr-2"
            onClick={() => setShowNewReconcileModal(false)}
          >
            Cancel
          </button>

          <button
            type='submit'
            className="btn btn-primary"
            disabled={isLoading}
          >
            Submit reconciliation
            {
              isLoading &&
              <span className="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true" />
            }
          </button>
        </DialogActionsBar>
      </form>
    </Dialog >
  )
}