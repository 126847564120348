import { type FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import type { MultiSelectChangeEvent } from '@progress/kendo-react-dropdowns';

import { BaseMultiSelect } from '../../../../../shared/Inputs';
import type { RootState } from '../../../../../../store/reducers/rootReducer';
import { ErrorMessage } from '../../../../../shared/ErrorMessage/ErrorMessage';
import { fetchPostResOrJson as createInvitations } from '../../../../../../services/services';

type Props = {
  handleClose: () => void
  updateInvitedUsers: () => void
}

export const InviteUserModal = ({ handleClose, updateInvitedUsers }: Props) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [formData, setFormData] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const emailRegex = new RegExp(/\S+@\S+\.\S+/);

  const onChangeHandler = (e: MultiSelectChangeEvent) => {
    const emails = [...e.value];
    const lastEmail = emails[emails.length - 1]

    if (lastEmail) {
      if (!emailRegex.test(lastEmail)) {
        dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Please enter valid email.' } })
      } else {
        emails.pop();
        const sameEmail = emails.find(email => email === lastEmail)
        if (!sameEmail) {
          emails.push(lastEmail)
        }

        const updatedData = [...emails]
        setFormData(updatedData)
      }
    } else {
      setFormData([])
    }
  }

  const listNoDataRender = () => {
    return (
      <div className="d-flex flex-column gap-md text-disabled text-center h5 py-3">
        <i className="fal fa-2x fa-folder-open" />
        <p className='m-0'>No emails added yet</p>
      </div>
    );
  }

  const handleSendInvite = (e: FormEvent) => {
    setIsLoading(true);
    e.preventDefault()
    const body = formData.map(el => {
      const obj = { email: el }
      return obj;
    })
    createInvitations('customer/invite', token, body)
      .then((res: TODO) => {
        setIsLoading(false);
        if (res?.error || res?.message) {
          setErrorMessage(res.error ? res.error : res.message);
        } else {
          updateInvitedUsers()
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The invitation(s) have been sent successfully' } })
          handleClose()
        }
      })
  }

  return (
    <Dialog
      title="Invite new users"
      onClose={handleClose}
      className="d-flex flex-column import-dialog"
      width="40%">
      <form onSubmit={handleSendInvite}>
        <div className="d-flex flex-column gap-md py-4 px-3">
          <span>Email</span>
          <div className="d-flex flex-row w-100">
            <BaseMultiSelect
              value={formData}
              allowCustom={true}
              dataItemKey="email"
              placeholder="Enter email"
              onChange={(e: MultiSelectChangeEvent) => onChangeHandler(e)}
              listNoDataRender={listNoDataRender} />
          </div>
          {errorMessage &&
            <ErrorMessage
              type="alert"
              onHide={() => setErrorMessage("")}
              errorMessage={errorMessage} />
          }
        </div>
        <DialogActionsBar>
          <button
            type="button"
            className="k-button btn btn-secondary"
            onClick={handleClose}>Cancel
          </button>
          <button
            type="submit"
            disabled={isLoading}
            className="k-button btn btn-primary">Send Invite
          </button>
        </DialogActionsBar>
      </form>
    </Dialog>
  )
}