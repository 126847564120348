import React from 'react';

import CheckBox from './CheckBox/CheckBox';
import ExpandedMultiChoiceView from './ExpandedMultiChoiceView';
import { Avatar } from '../../../../../shared/Avatar/Avatar';
import setButtonIcon from '../helpers/importDataHelpers/setButtonIcon/setButtonIcon';
import setButtonStyle from '../helpers/importDataHelpers/setButtonStyle/setButtonStyle';

export default ({ ques, id, measure, toggleQuestion, onCheckQuestion, style, onExpandHandler, originalIndex }) => {
  let buttonStyle = setButtonStyle(ques),
    buttonIcon = setButtonIcon(ques);

  return (
    <div style={style}>
      <div
        title={ques.title.length > 60 ? ques.title : null}
        id={ques.merged ? 'question-button-merged' : 'question-button'}
        onLoad={measure}
        key={id}
        onClick={() => toggleQuestion(ques.id)}
        style={{ transition: 'all .1s ease-in-out', cursor: "pointer" }}
        className={buttonStyle}>
        <div className="d-flex flex-grow-1 list-group-item-question-content gap-lg">
          {
            ques.merged ?
              <span onClick={() => onExpandHandler(id)}>
                <i className={`fas ${ques.expanded ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
              </span>
              : null
          }
          {buttonIcon.includes('quantity') ?
            <Avatar class="avatar-md" value="1" color="analyze" />
            :
            <Avatar
              icon={buttonIcon}
              color={ques.merged && !ques.checked ? "white" : "analyze"}
              class={`avatar-md ${ques.merged && !ques.checked ? 'text-analyze' : ''}`}
            />
          }
          {
            ques["@miextsss:isweight"] &&
            <i className="fas fa-weight-hanging"></i>
          }
          <div style={{ maxWidth: '85%' }} className="d-flex flex-column">
            <strong>{ques.id}</strong>
            <span className="question-text">{ques.title}</span>
          </div>
        </div>
        <CheckBox
          className=""
          checkBoxAction={onCheckQuestion}
          checkBoxState={ques.checked}
          originalIndex={originalIndex}
        />
      </div>
      <ExpandedMultiChoiceView
        ques={ques}
        expand={ques.expanded}
        toggleQuestion={toggleQuestion}
        buttonIcon={buttonIcon}
      />
    </div>
  )
}