import { useEffect } from "react"

import type { User } from "../../../interfaces/userInterface/userInterface"
import sortOptionsGroups from "../../Analysis/Analyze/components/shared/helpers/sortOptionGroups/sortOptionsGroups"

export const useUpdateAnalysisOptionsData = (user: User, tabSelected: number, optionsData: TODO, activeTabs: TODO, setIsFinished: (val: boolean) => void, setOptionsData: (val: TODO) => void) => {
  useEffect(() => {
    if (tabSelected === 6 || tabSelected === 7 || tabSelected === 8) {
      setIsFinished(true)
    } else {
      if (optionsData.shouldUpdateOptions && optionsData.allOptions && optionsData.allOptions.find((el: TODO) => el.analysisType === activeTabs[tabSelected]?.id)) {
        const groups = sortOptionsGroups(optionsData.allOptions.find((el: TODO) => el.analysisType === activeTabs[tabSelected].id).groups, user)
        const data = optionsData.allOptions.find((el: TODO) => el.analysisType === activeTabs[tabSelected].id).props
        setOptionsData({
          ...optionsData,
          groups: groups,
          data: data,
          selectedGroup: groups.find(group => group.selected === true).text,
          shouldUpdateOptions: false
        })
        setIsFinished(true)
      }
    }
  }, [tabSelected, optionsData.shouldUpdateOptions, optionsData.allOptions, activeTabs])
}