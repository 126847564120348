import type { SubAccount, SubAccountFormData } from "../../../../interfaces/adminInterfaces/adminInterfaces"
import { fetchPatchJson as updateSubAccount } from "../../../../services/services"

export const updateSubAccountSubmitHandler = (formData: SubAccountFormData, token: string, dispatch: TODO, subAccount: SubAccount) => {
  const body = {
    name: formData.subAccountName,
    description: formData.subAccountDescription
  }

  updateSubAccount(`sub-accounts/${subAccount.id}`, token, body)
    .then((res: TODO) => {
      if (res && (res.message || res.error)) {
        dispatch({ type: 'UPDATE_SUB_ACCOUNTS_ERROR_MESSAGE', payload: res.message ? res.message : res.error })
      } else {
        dispatch({ type: 'UPDATE_SUB_ACCOUNTS_ERROR_MESSAGE', payload: null })
        dispatch({ type: 'UPDATE_SUB_ACCOUNTS', payload: true })
        dispatch({ type: 'UPDATE_SHOW_SUB_ACCOUNTS_PROVIDERS_MODAL', payload: { subAccount: null, show: false } })
      }
    })
}