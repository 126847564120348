import { useState, useEffect, Fragment, type FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { RootState } from '../../../../../../store/reducers/rootReducer';

interface Props {
  shareDetails: TODO
}

export const ShareProjectAccessTabContent = ({ shareDetails }: Props) => {
  const dispatch = useDispatch()
  const [user, setUser] = useState('')
  const { regularAccounts, invalidRegularUsers } = useSelector((theState: RootState) => theState.projectExternalShareStateReducer);

  useEffect(() => {
    const sharedRegularAccounts = shareDetails.shareUsers.filter((shareUser: TODO) => !shareUser.isSubAccountUser)
    if (sharedRegularAccounts.length > 0) {
      dispatch({ type: 'SET_SHARE_PROJECT_REGULAR_ACCOUNTS', payload: sharedRegularAccounts.map((item: TODO) => item.email) })
    }
  }, [dispatch, shareDetails])

  const onDeleteUserHandler = (selectedAccount: string) => {
    const updatedRegularAccounts = [...regularAccounts]
    updatedRegularAccounts.splice(updatedRegularAccounts.indexOf(selectedAccount), 1)
    dispatch({ type: 'SET_SHARE_PROJECT_REGULAR_ACCOUNTS', payload: updatedRegularAccounts })
  }

  const onAddUserHandler = (e: FormEvent) => {
    e.preventDefault()
    if (!regularAccounts.includes(user)) {
      setUser('')
      dispatch({ type: 'SET_SHARE_PROJECT_REGULAR_ACCOUNTS', payload: [...regularAccounts, user] })
    } else {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'The user has already been added to the list' } })
    }
  }

  const returnStatus = (account: string) => {
    return shareDetails.shareUsers.find((item: TODO) => item.email === account).status;
  }

  const returnStatusColor = (account: string) => {
    const activeAccount = shareDetails.shareUsers.find((item: TODO) => item.email === account);
    switch (activeAccount.status) {
      case 'pending':
        return 'primary';
      case 'accepted':
        return 'success';
      default:
        return 'danger';
    }
  }

  return (
    <Fragment>
      <div className="d-flex justify-content-center align-items-center gap-md my-3">
        <i className="fas fa-info-circle text-muted" />
        <p className="text-muted m-0">Regular accounts are users that have full access to Walr Platform</p>
      </div>

      <form onSubmit={onAddUserHandler}>
        <div className="input-group mb-3">
          <input
            required
            value={user}
            type="email"
            className="form-control"
            placeholder="Email address"
            onChange={(e) => setUser(e.target.value)}
          />
          <div className="input-group-append">
            <button className="btn btn-primary px-3" type="submit">
              <span>Add</span>
            </button>
          </div>
        </div>
      </form>

      {
        regularAccounts.length > 0 ?
          <table className="table table-sm table-striped table-bordered">
            <thead className="text-center">
              <tr>
                <th scope="col">Email</th>
                <th scope="col">Status</th>
                <th scope="col" className="w-25" />
              </tr>
            </thead>
            <tbody>
              {
                regularAccounts.map((account: string) => (
                  <tr key={account}>
                    <td
                      style={{ verticalAlign: 'middle' }}>
                      <span>{account}</span>
                      {
                        invalidRegularUsers.find((user: TODO) => user.id === account) &&
                        <i className="fas fa-exclamation-circle text-danger ml-3" />
                      }
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      {
                        shareDetails.shareUsers.find((item: TODO) => item.email === account) &&
                        <span className={`badge badge-pill badge-${returnStatusColor(account)} text-capitalize align-middle py-1 px-2`}>{returnStatus(account)}</span>
                      }
                    </td>
                    {
                      !shareDetails.shareUsers.find((item: TODO) => item.email === account) ?
                        <td style={{ verticalAlign: 'middle' }}>
                          <button type='button' className="btn btn-transparent" onClick={() => onDeleteUserHandler(account)}>
                            <i className="far fa-lg fa-trash-alt text-danger" />
                          </button>
                        </td>
                        :
                        <td />
                    }
                  </tr>
                ))
              }
            </tbody>
          </table>
          :
          <p className="text-center text-disabled my-5 h5">No accounts have been added</p>
      }
    </Fragment>
  )
}