import { Skeleton } from "../../../shared/Skeleton/Skeleton"

export const AdminCardsSkeleton = () => {
  return (
    [...Array(16).keys()].map((item) => (
      <div key={item} className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div style={{ cursor: 'default' }} className="h-100 project-card card border-0">
          <div className="d-flex flex-column justify-content-between h-100">
            <div className="d-flex flex-column justify-content-between p-3 gap-md h-100">
              <div className="d-flex justify-content-between align-items-center gap-md">
                <div className="d-flex flex-column flex-grow-1 gap-sm ">
                  <Skeleton className="h-10 w-50" />
                  <Skeleton className="h-10 w-75" />
                </div>
                <Skeleton className="h-36 w-36" />
              </div>
              <Skeleton className="h-10 w-50" />
            </div>

            <div className="project-card-buttons d-flex border-top">
              <button type="button" className="btn btn-block text-dark border-right">
                <Skeleton className="h-10 w-48" />
              </button>
              <button type="button" className="btn btn-block text-dark border-right">
                <Skeleton className="h-10 w-48" />
              </button>
            </div>
          </div>
        </div>
      </div>
    ))
  )
}