const initialState = {
  panelData: {
    panelData: {
      sampleData: null,
      sampleSpecification: null,
      sampleSpecHasChanges: false,
    },
  },
};

function panelStateReducer(state = initialState.panelData, action) {
  switch (action.type) {
    case "SET_PANEL_INITIAL_DATA":
      return {
        ...state,
        panelData: {
          sampleData: action.payload,
          sampleSpecification: null,
          sampleSpecHasChanges: false,
          variables: null,
          originalVariables: null,
          variablesFirstColumn: [],
          variablesScndColumn: [],
        },
      };
    case "SET_SAMPLE_SPECIFICATION_DATA":
      const copyState = { ...state.panelData };
      return {
        ...state,
        panelData: {
          ...copyState,
          sampleSpecification: action.payload,
          sampleSpecHasChanges: false,
        },
      };
    case "UPDATE_SAMPLE_SPECIFICATION_DATA":
      const copy = { ...state.panelData };
      return {
        ...state,
        panelData: {
          ...copy,
          sampleSpecification: action.payload,
          sampleSpecHasChanges: true,
        },
      };
    case "SET_SAMPLE_VARIABLES":
      const variables = { ...state.panelData };
      return {
        ...state,
        panelData: {
          ...variables,
          variables: action.payload,
          originalVariables: action.payload,
        },
      };
    case "UPDATE_SAMPLE_VARIABLES_COLUMNS":
      const copyVariables = { ...state.panelData };
      return {
        ...state,
        panelData: {
          ...copyVariables,
          variablesFirstColumn: action.payload.variablesFirstColumn,
          variablesScndColumn: action.payload.variablesScndColumn,
        },
      };
    case "SEARCH_SAMPLE_VARIABLES":
      const searchCopy = { ...state.panelData };
      return {
        ...state,
        panelData: {
          ...searchCopy,
          variables: action.payload,
        },
      };
    default:
      return state;
  }
}
export default panelStateReducer;
