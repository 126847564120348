import type React from 'react'
import { type FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'

import { Icon } from '../../shared/Icon/Icon'
import { fetchPostResOrJson } from '../../../services/services'
import type { Recontact, RecontactCompontent, ValidationResponse } from '../../../interfaces/gateKeeperInterfaces/gateKeeperInterfaces'
import { ViewAllModal } from './ViewAllModal/ViewAllModal'
import type { RootState } from '../../../store/reducers/rootReducer'

// CONST
const COMPONENT_NAME = "Recontact"
const RECONTACT_TYPE_SINGLE = "single"
const RECONTACT_TYPE_BATCH_URL = "batchUrls"

export const RecontactModal: FC<RecontactCompontent> = ({ onHide, showCreateNewProject, setShowCreateNewProject, setFormData, formData }) => {
  const [recontactData, setRecontactData] = useState<Recontact>({ type: RECONTACT_TYPE_SINGLE, recontactIds: [] })
  const [validationResponse, setValidationResponse] = useState<ValidationResponse>({ isValidaton: false, invalidRespondentCount: 0, invalidRespondents: [], validRespondentCount: 0 })
  const [isEmptyField, setIsEmptyField] = useState<boolean>(false)
  const [viewAllModal, showViewAllModal] = useState<boolean>(false)
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const arr: TODO = []
    arr.push(e.target.value.split('\n'))
    setRecontactData({ ...recontactData, [e.target.name]: e.target.name === "recontactIds" && e.target.value !== "" ? e.target.value.split(/\n/) : e.target.value })
  }

  const handleInputValidation = (obj: Recontact): boolean => {
    if (obj.recontactIds.length === 0) {
      setIsEmptyField(true)
      return true
    }
    setIsEmptyField(false)
    return false
  }

  const handleSumbit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!handleInputValidation(recontactData)) {
      fetchPostResOrJson("co/gk/respondents/recontacts/validate", token, recontactData.recontactIds)
        .then(async (res: TODO) => {
          if (res.ok) {
            const response = await res.json()
            setValidationResponse({ isValidaton: res.ok, ...response })
          } else {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.detail } })
          }
        })
    }
  }

  const onContinue = (type: string) => {
    setFormData({
      ...formData,
      recontactIds: recontactData.recontactIds
    })
    if (type === RECONTACT_TYPE_SINGLE) {
      onHide(COMPONENT_NAME)
      setShowCreateNewProject({ ...showCreateNewProject, createNewProject: true, isRecontact: true })
    } else {
      onHide(COMPONENT_NAME)
      setShowCreateNewProject({ ...showCreateNewProject, batchUrls: true })
    }
  }

  const setViewAllModalTitle = (invalidEnries: number): string => {
    if (invalidEnries === 1) {
      return `${invalidEnries} invalid entrie`
    }
    return `${invalidEnries} invalid entries`
  }

  return (
    <>
      <Dialog width={"40%"} className='recontact-modal' contentStyle={{ padding: 0, fontSize: 14 }} title={COMPONENT_NAME} onClose={() => onHide(COMPONENT_NAME)}>
        <form onSubmit={(e) => handleSumbit(e)} >
          <div className='pt-3 px-4' >
            <p className='p-0 m-0 text-start text-muted'><label className='medium m-0' htmlFor="recontactModal">Please upload all Walr IDs you wish to recontact.</label></p>
            <div className="d-flex flex-column justify-content-center w-100 py-3">
              <textarea
                className='w-100 form-control'
                id="recontactModal"
                name="recontactIds"
                disabled={validationResponse.isValidaton}
                placeholder='Add IDs one per line'
                rows={15}
                cols={50}
                onChange={(e) => handleChange(e)}>
              </textarea>
              {isEmptyField &&
                <div className="text-danger">
                  <small>This field is required.</small>
                </div>}
            </div>
            {/* VALIDATIONS INFO */}
            {validationResponse.isValidaton &&
              <div className="validation-recontact border d-flex flex-column justify-content-center w-100 mb-3 p-2 px-0">
                <p className='m-0'>
                  <span>
                    <Icon className='pe-none' fill="green" type="checkmark" />
                  </span>
                  {validationResponse.validRespondentCount} valid entries
                </p>
                <p className='m-0'>
                  <span>
                    <Icon type="info-icon" fill='red' />
                  </span>
                  {setViewAllModalTitle(validationResponse.invalidRespondentCount)}
                  <span style={{ display: `${validationResponse.invalidRespondentCount === 0 && "none"}` }} onClick={() => validationResponse.invalidRespondentCount !== 0 && showViewAllModal(true)} className={"viewAll strong"}> View all</span>
                </p>
              </div>
            }
            {/* RADIO BUTTONS */}
            <div className="details-input d-flex flex-column justify-content-center w-100 mb-3 px-0">
              <div className='d-flex flex-row align-items-center'>
                <div className="form-check mt-0 form-check-inline cursor-pointer">
                  <input className='cursor-pointer' id="single" type="radio" name="type" checked={recontactData.type === "single"} onChange={(e) => handleChange(e)} value={RECONTACT_TYPE_SINGLE}
                  />
                  <label className="d-flex align-items-center cursor-pointer m-0 pl-2 medium" htmlFor="Single">Single entry link</label>
                </div>
                <div className="form-check mt-0 form-check-inline cursor-pointer">
                  <input className='cursor-pointer' id="batchUrls" type="radio" checked={recontactData.type === "batchUrls"} onChange={(e) => handleChange(e)} name="type" value={RECONTACT_TYPE_BATCH_URL}
                  />
                  <label className="d-flex align-items-center cursor-pointer m-0 pl-2 medium" htmlFor="BatchUrls">Batch URLs</label>
                </div>
              </div>
            </div>
          </div>
          <DialogActionsBar>
            <div className='d-flex'>
              <button type='button' className="btn btn-secondary mr-2" onClick={() => onHide(COMPONENT_NAME)}>Cancel</button>
              {!validationResponse.isValidaton ?
                <button
                  type='submit' className="btn btn-primary">Validate</button>
                :
                <button
                  type='button'
                  onClick={() => onContinue(recontactData.type)}
                  className="btn btn-primary">Continue</button>
              }
            </div>
          </DialogActionsBar>
        </form>
      </Dialog >
      {viewAllModal &&
        <ViewAllModal
          showViewAllModal={showViewAllModal}
          validationResponse={validationResponse}
        />
      }
    </>
  )
}