import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Dialog } from "@progress/kendo-react-dialogs";
import { v4 as uuid } from 'uuid';

import { BaseDropDownList } from "../Inputs";
import { returnQuestionsData } from "../../Analysis/Analyze/components/shared/helpers/FilterBuilderHelpers/FilterBuilderHelpers";
import { referenceItemsRender, referenceValueRender, referencesFilterHandler } from "./SurveyQuillReferenceHelpers";
import FileManager from "../FileManager/FileManager";
import { fetchGetJson } from "../../../services/services";

interface Props {
  token: string
  value: string | undefined
  setValue: (value: string) => void
  readOnly?: boolean
  classes?: string
  orderNumber?: number
  selectedItemId: string
  placeholder?: string
}

export default function SurveyAnswersQuillEditor({ value, setValue, readOnly, classes, selectedItemId, placeholder, token }: Props) {
  const params: TODO = useParams();
  const dispatch = useDispatch();
  const quillRef = useRef<TODO>(null);
  const uniqueId: TODO = useRef(`custom-toolbar${uuid()}`).current;

  const [imageModal, setImageModal] = useState<{ show: boolean, value: string, range: TODO }>({ show: false, value: "", range: null });
  const shouldSaveAdvancedQuestion = useSelector((theState: TODO) => (theState.surveyInitialDataReducer)).shouldSaveAdvancedQuestion;
  const refModalDefaultValues = { show: false, value: "", range: null, dropdownOpen: false }
  const [referenceModal, setReferenceModal] = useState<{ show: boolean, value: TODO, range: TODO, dropdownOpen: boolean }>(refModalDefaultValues);
  const [referableQuestions, setReferableQuestions] = useState<TODO>([]);
  const [filteredRefQuestions, setFilteredRefQuestions] = useState<TODO>([]);
  const [showFileManager, setShowFileManager] = useState<boolean>(false);
  const [hasFocus, setHasFocus] = useState(false);

  useEffect(() => {
    // Compensation for a bug in library
    if (quillRef) {
      quillRef.current.getEditor().root.dataset.placeholder = placeholder || "";
      quillRef.current.editor.theme.tooltip.show();
      quillRef.current.getEditor().focus();
    }
  }, [placeholder])

  const imageUrlHandler = (value: string) => {
    if (value && imageModal.range) {
      quillRef.current.getEditor().insertEmbed(imageModal.range.index, "image", value, "user")
      setImageModal({ ...imageModal, show: false })
    }
  }

  const addReferenceHandler = () => {
    let textToAdd = "";

    if (referenceModal.value.hasOwnProperty("qno")) {
      if (!referenceModal.value.hasOwnProperty("index")) {
        textToAdd = `{{\\${referenceModal.value.qno}}}`
      } else {
        textToAdd = `{{\\${referenceModal.value.qno}.${(referenceModal.value.index + 1)}}}`
      }
    } else {
      if (!referenceModal.value.hasOwnProperty("index")) {
        textToAdd = `{{\\${referenceModal.value.text}}}`
      } else {
        textToAdd = `{{\\${referenceModal.value.text}.${(referenceModal.value.index + 1)}}}`
      }
    }

    quillRef.current.getEditor().insertText(referenceModal.range.index, textToAdd, "user")
    setReferenceModal({ ...referenceModal, show: false, dropdownOpen: false });
  }

  const quesRefHandler = useCallback((range: { index: number, length: number }) => {
    fetchGetJson(`su/projects/${params.name}/surveys/${params.survey}/structure`, token)
      .then((res: TODO) => {
        if (res.error || res.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
        } else {
          res.questions.length = res.questions.findIndex((el: TODO) => el.questionId === selectedItemId)
          const reorganizedQuestions = returnQuestionsData(res)

          setReferenceModal({ show: true, value: "", range: range, dropdownOpen: false })
          setReferableQuestions(reorganizedQuestions)
          setFilteredRefQuestions(reorganizedQuestions)
        }
      })
  }, [dispatch, params, selectedItemId, token])

  const modules = useMemo(() => ({
    toolbar: {
      container: `#${uniqueId}`,
      handlers: {
        image: () => {
          if (!quillRef.current) return
          const editor = quillRef.current.getEditor()
          const range = editor.getSelection()
          setImageModal({ show: true, value: "", range: range });
        },
        quesRef: () => {
          if (!quillRef.current) return
          const editor = quillRef.current.getEditor()
          const range = editor.getSelection()
          quesRefHandler(range)
        }
      }
    }
  }), [uniqueId, quesRefHandler]);

  return (
    <Fragment>
      {imageModal.show &&
        <Dialog title={"Add an image"} minWidth={"35%"} onClose={() => setImageModal({ ...imageModal, show: false, value: "" })} style={{ zIndex: 10000 }}>
          <div className="p-4">
            <input
              id={`${value}url`}
              type="url"
              className="k-textbox w-100"
              value={imageModal.value}
              onChange={(e) => setImageModal({ ...imageModal, value: e.target.value })}
              placeholder="Image URL..."
            />
          </div>
          <div className="p-4 d-flex flex-fill justify-content-between border-top">
            <button
              type="button"
              className="btn btn-shadow mr-1"
              onClick={() => { setShowFileManager(true); setImageModal({ ...imageModal, show: false, value: "" }) }}
              title="Load an image from the file manager">
              File manager
            </button>
            <div className="d-flex">
              <button type="button" className="btn btn-secondary mr-2" onClick={() => setImageModal({ ...imageModal, show: false, value: "" })}>Cancel</button>
              <button type="button" className="btn btn-primary" disabled={!imageModal.value} onClick={() => imageUrlHandler(imageModal.value)}>Save</button>
            </div>
          </div>
        </Dialog>}
      {showFileManager &&
        <FileManager
          token={token}
          type={'public'}
          displayType="modal"
          onHide={() => setShowFileManager(false)}
          onSelect={(value) => imageUrlHandler(value)}
        />
      }
      {referenceModal.show &&
        <Dialog title="Add a reference" onClose={() => setReferenceModal(refModalDefaultValues)} width={"50%"} style={{ zIndex: 10000 }}>
          <div className="p-4">
            <div className="mb-2 text-left">Select question</div>
            <BaseDropDownList
              className="form-control h-100 filter-dropdown"
              style={{ minHeight: "58px" }}
              // onOpen={() => setReferenceModal({ ...referenceModal, dropdownOpen: true })}
              // onClose={() => setReferenceModal({ ...referenceModal, dropdownOpen: false })}
              data={filteredRefQuestions}
              textField="id"
              itemRender={(_props: TODO, item: TODO) => referenceItemsRender(item.dataItem, (e) => { setReferenceModal({ ...referenceModal, value: e, dropdownOpen: false }) })}
              value={referenceModal.value}
              valueRender={() => referenceValueRender(referenceModal.value)}
              popupSettings={{ className: "drop-down-height" }}
              // opened={referenceModal.dropdownOpen}
              filterable={true}
              onFilterChange={(e: TODO) => referencesFilterHandler(e, referableQuestions, setFilteredRefQuestions)}
            />
          </div>
          <div className="px-3 pt-3 d-flex flex-fill justify-content-end border-top">
            <button type="button" className="btn btn-secondary mr-2" onClick={() => setReferenceModal(refModalDefaultValues)}>Cancel</button>
            <button type="button" className="btn btn-primary" disabled={!referenceModal.value} onClick={() => addReferenceHandler()}>Add Reference</button>
          </div>
        </Dialog>
      }
      <div className={`survey-quill ${hasFocus ? "show-quill-tooltip" : ""}`}>
        <div id={uniqueId} onClick={e => e.stopPropagation()}>
          {/* Custom toolbar HTML */}
          <span className="ql-formats">
            <button type="button" title="Bold" className="ql-bold" />
            <button type="button" title="Italic" className="ql-italic" />
            <button type="button" title="Underline" className="ql-underline" />
          </span>
          <span className="ql-formats">
            <button type="button" title="Ordered list" className="ql-list" value="ordered" />
            <button type="button" title="Bullet list" className="ql-list" value="bullet" />
          </span>
          <span className="ql-formats">
            <select id={`${value}_textColor`} title="Text color" className="ql-color" />
            <select id={`${value}_textBgColor`} title="Text background color" className="ql-background" />
          </span>
          <span className="ql-formats">
            <button type="button" title="Link" className="ql-link" />
            <button type="button" title="Image" className="ql-image" />
            <button type="button" title="Embed video" className="ql-video" />
          </span>
          <span className="ql-formats">
            <button type="button" title="Reference" className="ql-quesRef"><i style={{ fontSize: "1rem" }} className="fa far fa-code-branch" /></button>
            <button type="button" title="Clear formating for selection" className="ql-clean" />
          </span>
        </div>

        <ReactQuill
          theme="bubble"
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
          ref={quillRef}
          className={`${classes ? classes : ""}`}
          placeholder={placeholder}
          value={value}
          readOnly={readOnly ? readOnly : false}
          onChange={(content, _delta, source, _editor) => {
            if (value !== content) {
              if (source === 'user') {
                setValue(content);
              } else if (source === 'api') {
                if (shouldSaveAdvancedQuestion) {
                  setValue(content);
                }
              }
            }
          }}
          modules={modules}
          formats={[
            "bold",
            "italic",
            "underline",
            "color",
            "background",
            "list",
            "bullet",
            "link",
            "image",
            "video",
          ]}
        />
      </div>
    </Fragment>
  )
}