import { useRef, useState } from 'react';

import { AddNewElementDefaultTab } from './AddNewElementDefaultTab/AddNewElementDefaultTab';
import { AddNewElementAdvancedTab } from './AddNewElementAdvancedTab/AddNewElementAdvancedTab';
import { useOutsideClick } from '../../../../../shared/customHooks/useOutsideClick';

type Props = {
  handleClose: () => void;
  setLibraryModal: (show: boolean) => void;
  onAddNewElement: () => void;
}

export const AddNewElementModal = ({ handleClose, setLibraryModal, onAddNewElement }: Props) => {
  const [inputValue, setInputValue] = useState("");
  const ref = useRef(null);
  useOutsideClick(ref, handleClose);

  const handleOpenLibrary = () => {
    handleClose()
    setLibraryModal(true)
  };

  return (
    <div className="d-flex flex-column align-items-end add-new-element-sidebar">
      <div ref={ref} className="d-flex flex-column justify-content-between h-100 w-100">
        <div className="w-100">
          <div className="w-100 d-flex justify-content-center p-3">
            <input
              type="text"
              className="form-control w-100 add-new-element-input-field"
              placeholder="Search elements"
              aria-label="Search elements"
              aria-describedby="basic-addon2"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>

          <div className="w-100 d-flex flex-column elements-list">
            <AddNewElementDefaultTab onAddNewElement={onAddNewElement} inputValue={inputValue} />

            <AddNewElementAdvancedTab onAddNewElement={onAddNewElement} inputValue={inputValue} />
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center border-top" >
          <button type='button' onClick={() => handleOpenLibrary()} className="btn btn-shadow m-3 w-100 border-0 justify-content-center">Open library</button>
        </div>
      </div>
    </div>
  );
};

