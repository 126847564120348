import React, { useEffect, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Input, Switch } from '@progress/kendo-react-inputs';

import { FullHeightSpinner } from '../../../../../../shared/FullHeightSpinner/FullHeightSpinner';
import { fetchPutJson as updateCampaignPrevalidation } from '../../../../../../../services/services';

export default ({ token, campaign, updateCampaign }) => {
  const [didMount, setDidMount] = useState(true)
  const [isUpdating, setIsUpdating] = useState(false)
  const [state, setState] = useState({ preValidate: null, preValidationUrl: null })

  useEffect(() => {
    if (didMount) {
      setState({ preValidate: campaign.preValidate, preValidationUrl: campaign.preValidationUrl })
      setDidMount(false)
    }
  }, [campaign, didMount])

  const onUpdate = () => {
    const body = {
      "preValidate": state.preValidate,
      "preValidationUrl": state.preValidationUrl,
    }
    updateCampaignPrevalidation(`/projects/${campaign.projectId}/surveys/${campaign.surveyId}/campaigns/${campaign.id}/pre-validation`, token, body)
      .then(res => {
        if (res) {
          updateCampaign()
          setIsUpdating(false)
        }
      })
  }

  return (
    <div className="d-flex flex-column h-100 w-100 overflow-hidden">
      {didMount ?
        <FullHeightSpinner />
        :
        <div>
          <div className="d-flex align-items-center justify-content-between border-bottom p-4 h-64">
            <div className="d-flex align-items-center">
              <Switch checked={state.preValidate} onChange={() => setState({ ...state, preValidate: !state.preValidate })} size="small" />
              <span className="ml-2 mb-0">Activate pre-validation</span>
            </div>
          </div>
          {
            state.preValidate &&
            <div className="d-flex flex-column p-4">
              <div className="input-group">
                <Input
                  onChange={(e) => setState({ ...state, preValidationUrl: e.target.value })}
                  value={state.preValidationUrl ? state.preValidationUrl : ''}
                  className="form-control"
                  placeholder='Pre validation url' />
              </div>
              {/* <span className="mt-2 audience-desc">Pre validation help can be found in our documentation</span> */}
            </div>
          }
        </div>
      }
      {
        state.preValidate &&
        <div className="d-flex justify-content-start px-4">
          <div className="d-flex align-items-center">
            <Button className="btn btn-primary btn-min" onClick={() => { setIsUpdating(true); onUpdate() }}>
              {isUpdating ?
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                :
                <span>Update</span>
              }
            </Button>
          </div>
        </div>
      }
    </div>
  )
}