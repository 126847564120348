import { useState } from 'react';
import { Switch } from '@progress/kendo-react-inputs';
import { filterBy } from '@progress/kendo-data-query';
import { useDispatch, useSelector } from 'react-redux';
import { MultiSelect, type MultiSelectFilterChangeEvent } from '@progress/kendo-react-dropdowns';

import { Icon } from '../../../../../../shared/Icon/Icon';
import type { RootState } from '../../../../../../../store/reducers/rootReducer';
import { DropdownButton } from '../../../../../../shared/DropdownButton/DropdownButton';
import { renderCustomQuestionIconItem } from '../../../../../../shared/helpers/renderCustomQuestionIconItem/renderCustomQuestionIconItem';
import { renderCustomQuestionIconColorItem } from '../../../../../../shared/helpers/renderCustomQuestionIconColorItem/renderCustomQuestionIconColorItem';
import { fetchGetJson } from '../../../../../../../services/services';

interface Props {
  tags: string[]
  formData: TODO
  onChangeHandler: (e: TODO) => void
}

const supportedTypes = [
  { text: "Single (N)", id: "n" },
  { text: "Multiple (M)", id: "m" },
  { text: "Hour (H)", id: "h" },
  { text: "Numeric (F)", id: "f" },
  { text: "Single grid (Rn)", id: "rn" },
  { text: "Multiple grid (Rm)", id: "rm" },
  { text: "Open", id: "open" },
  { text: "Quota", id: "quota" }
]

export const SurveyCustomQuestionsAdvancedOverviewTabContent = ({ tags, formData, onChangeHandler }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const [originalCustomers, setOriginalCustomers] = useState<TODO[]>([]);
  const { customQuestionCustomers } = useSelector((theState: RootState) => theState.surveyCustomQuestionsStateReducer)

  const getCustomQuestionCustomers = () => {
    if (customQuestionCustomers.length === 0) {
      setIsLoading(true)
      fetchGetJson("su/customquestioncustomers", token)
        .then((res: TODO) => {
          setIsLoading(false)
          if (res.error || res.message) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
          } else {
            setOriginalCustomers(JSON.parse(JSON.stringify(res)))
            dispatch({ type: 'UPDATE_CUSTOM_QUESTION_CUSTOMERS', payload: res })
          }
        })
    }
  }

  const onFilterCustomers = (event: MultiSelectFilterChangeEvent) => {
    dispatch({ type: 'UPDATE_CUSTOM_QUESTION_CUSTOMERS', payload: filterBy(originalCustomers.slice(), event.filter) })
  }

  return (
    <form>
      <label className="medium strong mb-1">Name</label>
      <input onChange={onChangeHandler} name="name" value={formData.name} type="text" className="form-control mb-2" />

      <label className="medium strong mb-1">Description</label>
      <textarea onChange={onChangeHandler} name="description" value={formData.description} className='form-control mb-2' rows={3} />

      <div className="d-flex justify-content-start mb-2">
        <div className='mr-2 d-flex flex-column'>
          <label className="medium strong mb-1">Color</label>
          <DropdownButton
            menuPosition='left'
            className='icon-r'
            items={[{ text: '' }]}
            parentClass='icons-dropdown form-control p-0'
            renderItem={() => renderCustomQuestionIconColorItem(onChangeHandler)}
            renderMainButton={() => (
              <div className={`custom-question-icon-color create bg-custom-questions-${formData.iconColor ? formData.iconColor : 'grey'}`} />
            )}
          />
        </div>
        <div className='mr-2 d-flex flex-column'>
          <label className="medium strong mb-1">Icon</label>
          <DropdownButton
            items={[{ text: '' }]}
            className='icon-r'
            renderMainButton={() => (
              <Icon type={formData.icon} />
            )}
            parentClass='icons-dropdown form-control p-0'
            renderItem={() => renderCustomQuestionIconItem(onChangeHandler)}
          />
        </div>
        <div className='d-flex flex-column w-100'>
          <label className="medium strong mb-1">Tags</label>
          <MultiSelect
            name="tags"
            onChange={onChangeHandler}
            className="w-100 mb-2"
            allowCustom={true}
            value={formData.tags}
            data={tags ? tags : []}
          />
        </div>
      </div>

      <label className="medium strong mb-1">Customer access</label>
      <MultiSelect
        filterable={true}
        textField="name"
        dataItemKey="id"
        name="customerAccess"
        className="w-100 mb-2"
        loading={isLoading}
        onChange={onChangeHandler}
        disabled={formData.isInternal}
        value={formData.customerAccess}
        onFilterChange={onFilterCustomers}
        onOpen={getCustomQuestionCustomers}
        data={customQuestionCustomers ? customQuestionCustomers : []}
      />

      <label className="medium strong mb-1">Supported types</label>
      <MultiSelect
        textField='text'
        dataItemKey='id'
        name="supportedTypes"
        className="w-100 mb-4"
        onChange={onChangeHandler}
        value={formData.supportedTypes}
        data={supportedTypes ? supportedTypes : []}
      />

      <label className='medium strong mb-1'>DC Version:</label>
      <div className='d-flex mb-4'>
        <div className="list-group list-group-horizontal">
          <button
            type="button"
            onClick={() => onChangeHandler({ target: { name: 'dataCollectionVersion', value: 1 } })}
            className={`list-group-item list-group-item-action py-1 px-8 ${formData.dataCollectionVersion === 1 ? 'active' : ''}`}>V1
          </button>
          <button
            type="button"
            onClick={() => onChangeHandler({ target: { name: 'dataCollectionVersion', value: 2 } })}
            className={`list-group-item list-group-item-action py-1 px-8 ${formData.dataCollectionVersion === 2 ? 'active' : ''}`}>V2
          </button>
        </div>
      </div>

      <div className="d-flex align-items-center mb-3">
        <Switch name="isInternal" onChange={onChangeHandler} checked={formData.isInternal} size="small" />
        <label className="medium strong ml-3 mb-0">Internal access</label>
      </div>

      <div className="d-flex align-items-center">
        <Switch name="published" onChange={onChangeHandler} checked={formData.published} size="small" />
        <label className="medium strong ml-3 mb-0">Publish</label>
      </div>
    </form>
  )
}