import { useState } from 'react';
import Editor from "@monaco-editor/react";
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { Icon } from '../../../../shared/Icon/Icon';
import type { RootState } from '../../../../../store/reducers/rootReducer';

type Props = {
  handleClose: () => void
}

export const SurveyScript = ({ handleClose }: Props) => {
  const dispatch = useDispatch()
  const { theData } = useSelector((theState: RootState) => theState.surveyInitialDataReducer)
  const [selectedItem, setSelectedItem] = useState(JSON.parse(JSON.stringify(theData.selectedItem)))
  const [selectedSubquestion, setSelectedSubquestion] = useState(0);
  const [enableActionButtons, setEnableActionButtons] = useState(false);
  const [editorTheme, setEditorTheme] = useState<"vs-dark" | "vs-light">("vs-dark");

  const onUpdateScript = (shouldCloseModal: boolean) => {
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: selectedItem })
    dispatch({ type: 'SAVE_QUESTION' })
    if (shouldCloseModal) {
      handleClose();
    }
    dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Javascript has been saved successfully' } })
  }

  const setScript = (value: TODO) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(selectedItem))
    if (selectedItem.type === 'info' || selectedItem.type === 'quotastop') {
      updatedSelectedItem.script = value
    } else {
      updatedSelectedItem.elements.filter((el: { type: string }) => el.type === 'subq')[selectedSubquestion].script = value
    }

    setSelectedItem(updatedSelectedItem)
  }

  const returnScriptValue = () => {
    let value = ''
    if (selectedItem.type === 'info' || selectedItem.type === 'quotastop') {
      value = selectedItem.script ? selectedItem.script : ''
    } else {
      value = selectedItem.elements.filter((el: { type: string }) => el.type === 'subq')[selectedSubquestion].script || '';
    }
    return value
  }

  const changeTheme = () => {
    editorTheme === "vs-light" ? setEditorTheme("vs-dark") : setEditorTheme("vs-light");
  }

  return (
    <Dialog title={"Insert Javascript"} onClose={handleClose} height="90%" width="90%">
      <div className="d-flex flex-column overflow-hidden h-100">
        {
          selectedItem.elements.filter((el: TODO) => el.type === 'subq').length > 1 &&
          <div>
            <div className="list-group list-group-horizontal">
              {
                selectedItem.elements.filter((el: TODO) => el.type === 'subq').map((el: { id: string }, key: number) =>
                  <button
                    key={el.id}
                    type="button"
                    style={{ whiteSpace: 'nowrap', outline: 'none' }}
                    onClick={() => setSelectedSubquestion(key)}
                    className={`w-auto list-group-item list-group-item-action border-0 p-3 rounded-0 ${selectedSubquestion === key ? 'bg-lightest' : ''}`}>
                    Question {key + 1}
                  </button>
                )
              }
            </div>
          </div>
        }
        <Editor
          defaultLanguage='javascript'
          value={returnScriptValue()}
          theme={editorTheme}
          onChange={(value) => { setScript(value); !enableActionButtons && setEnableActionButtons(true) }}
          options={{
            minimap: { enabled: false, },
            lineNumbers: 'off',
            glyphMargin: false,
            folding: false,
            overviewRulerLanes: 0,
            hideCursorInOverviewRuler: true,
            overviewRulerBorder: false,
          }}
        />
      </div>
      <DialogActionsBar>
        <div className='d-flex justify-content-between align-items-center w-100'>
          <button type='button' className="btn btn-transparent btn-icon icon-l" onClick={changeTheme}>
            <Icon type="editor-theme" className="pe-none mr-1" />
            Theme
          </button>
          <div className='d-flex'>
            <button type='button' className="k-button btn-secondary" onClick={handleClose}>Close</button>
            <button disabled={!enableActionButtons} type='button' className="k-button btn-primary" onClick={() => onUpdateScript(false)}>Save</button>
            <button disabled={!enableActionButtons} type='button' className="k-button btn-primary" onClick={() => onUpdateScript(true)}>Save & Close</button>
          </div>
        </div>
      </DialogActionsBar>
    </Dialog>
  )
}