export const timeSince = (date) => {
  const year = date.getFullYear()
  const dayText = date.toString().slice(0, 3)
  const hours = date.getHours() <= 9 ? '0' + date.getHours() : date.getHours()
  const minutes = date.getMinutes() <= 9 ? '0' + date.getMinutes() : date.getMinutes()
  const seconds = Math.floor((new Date() - date) / 1000);
  let interval = seconds / 31536000;

  // if (interval > 1) {
  //   if (Math.floor(interval) === 1) {
  //     return Math.floor(interval) + " year ago";
  //   } else {
  //     return Math.floor(interval) + " years ago";
  //   }
  // }
  // interval = seconds / 2592000;
  // if (interval > 1) {
  //   if (Math.floor(interval) === 1) {
  //     return Math.floor(interval) + " month ago";
  //   } else {
  //     return Math.floor(interval) + " months ago";
  //   }
  // }
  interval = seconds / 86400;
  if (interval > 1) {
    if (Math.floor(interval) === 1) {
      return `Yesterday at ${hours}:${minutes}`
    } else {
      if (interval < 7) {
        return `${dayText} at ${hours}:${minutes}`
      } else {
        if (year === new Date().getFullYear()) {
          return date.toLocaleString("en-GB", { dateStyle: "medium", timeStyle: "short" }).toString().slice(0, 6)
        } else {
          return date.toLocaleString("en-GB", { dateStyle: "medium", timeStyle: "short" }).toString().slice(0, 11)
        }
      }
    }
  }
  interval = seconds / 3600;
  if (interval > 1) {
    if (Math.floor(interval) === 1) {
      return Math.floor(interval) + " hour ago";
    } else {
      return Math.floor(interval) + " hours ago";
    }
  }
  interval = seconds / 60;
  if (interval > 1) {
    if (Math.floor(interval) === 1) {
      return Math.floor(interval) + " minute ago";
    } else {
      return Math.floor(interval) + " minutes ago";
    }
  }

  return Math.floor(seconds) + " seconds ago";
}