import { useSelector } from "react-redux";

import type { RootState } from "../../../../store/reducers/rootReducer";


type Props = {
  setShowCustomAudienceModal: ({ show, editMode }: { show: boolean, editMode: boolean }) => void;
}

export const TagretingScreening = ({ setShowCustomAudienceModal }: Props) => {
  const { targeting } = useSelector((state: RootState) => state.batchDataReducer);

  return (
    <article className='nav-board d-flex flex-column bg-white answer-layout p-5 gap-md'>
      <div className='d-flex justify-content-between'>
        <p className="innerboard-header d-flex align-items-center stronger h-32 m-0">Targeting</p>
        <button type='button' className='btn btn-primary' onClick={() => setShowCustomAudienceModal({ show: true, editMode: true })}>
          {targeting?.screenings?.length === 0 || targeting?.screenings === null ? "Add targeting" : "Edit targeting"}
        </button>
      </div>

      {targeting?.screenings?.length === 0 || targeting?.screenings === null ?
        <div className='board-content-container' >
          <table className="table target-table border mb-0">
            <tbody>
              <tr>
                <td className='d-flex justify-content-center p-4 w-100 m-0 text-muted'>No targeting</td>
              </tr>
            </tbody>
          </table>
        </div>
        :
        <div className='board-content-container' >
          <table className="table target-table border mb-0">
            <thead >
              <tr>
                <th className='text-muted font-weight-normal' scope="col">Question</th>
                <th className='text-muted font-weight-normal' scope="col">Answer options</th>
              </tr>
            </thead>
            <tbody>
              {targeting?.screenings?.map((screening, index) =>
              (
                <tr key={screening.questionId ? screening.questionId : index}>
                  <th scope="row" className="w-50">
                    <div className='d-flex align-items-center gap-md'>
                      <p className='text-indigo-dark strong p-0 m-0' >{screening.name}</p>
                      <p className='regular strong text-primary p-0 m-0' >{screening.text}</p>
                    </div>
                  </th>
                  <td className='text-left align-middle p-3'>
                    <div className='d-flex flex-row flex-wrap gap-sm'>
                      {screening.answers.map((answer, index) => (
                        <p key={answer.id ? answer.id : index} className='text-nowrap text-truncate small strong badge m-0'>{answer.text ? answer.text : `${answer.start} to ${answer.end}`}</p>
                      ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      }
    </article>
  )
}