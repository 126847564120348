import type React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import type { RootState } from '../../../../../../../../store/reducers/rootReducer';
import { returnSurveyDesignData } from '../../../../helpers/returnDataHelpers/returnDataHelpers';
import { fetchPutJson } from '../../../../../../../../services/services';

interface IProps {
  items: { id: string }[]
  listItem: TODO
  itemSelectedKey: number
  onClose: () => void
  showFilterBuilder?: () => void
}

export const SurveyDesignFillInQuestionsModalView: React.FC<IProps> = ({ onClose, itemSelectedKey, items, listItem }) => {
  const stringArray = items ? items.map((el) => { return el.id }) : []
  const { theData } = useSelector((theState: RootState) => (theState.surveyInitialDataReducer));
  const { data } = useSelector((theState: RootState) => (theState.breadcrumbStateReducer));
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch();

  const inputFilterChange = (e: TODO, _key: number, name: string) => {
    const lists = theData.originalData.lists
    lists[theData.selectedListIndex].elements[itemSelectedKey].attributes[name] = e.target.value
    dispatch({ type: 'ADD_LIST', payload: lists });
  };

  const onSaveList = () => {
    fetchPutJson(`su/projects/${data.projectId}/surveys/${data.documentId}/index`, token, theData.originalData)
      .then((res: TODO) => {
        if (res.error || res.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
        } else {
          const originalData = res;
          const data = returnSurveyDesignData(res);
          dispatch({ type: 'UPDATE_SURVEY_DATA', payload: { data: data, originalData: originalData } });
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "Filters have been updated" } });
          onClose()
        }
      })
  };

  return (
    <Dialog width="70%" height="60%" title="Edit question fill in" onClose={onClose}>
      <div className="table-responsive h-100 advanced-dialog">
        <table className="table table-sm answer-layout-table mb-0">
          <thead className='table-header'>
            <tr>
              <td style={{ width: '40%' }}><span>Description</span></td>
              <td><span>Filter</span></td>
            </tr>
          </thead>
          <tbody>
            {items?.map((item: TODO, key: number) => {
              return (
                <tr key={key}>
                  <td id='input' className='text-left'><span className='px-3'>{item.name}</span></td>
                  <td id='input'>
                    <div className="d-flex flex-row no-wrap align-items-center">
                      <input name={stringArray[key]} className="form-control shadow-none text-primary" value={listItem.attributes[stringArray[key]]} onChange={(e) => inputFilterChange(e, key, stringArray[key])} />
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <DialogActionsBar>
        <Button className="btn btn-secondary" onClick={onClose}>Cancel</Button>
        <Button className="btn btn-primary" onClick={onSaveList}>Save</Button>
      </DialogActionsBar>
    </Dialog>
  )
}