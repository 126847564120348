import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../store/reducers/rootReducer';

import { Icon } from '../../../../../../../shared/Icon/Icon';
import { DropdownButton } from '../../../../../../../shared/DropdownButton/DropdownButton';

interface Props {
  settings: any
  settingsValues: any
  onChangeSettingsValues: (value: string | null, id: string) => void
  onShowFileManager: (id: string) => void
  setCustomSettingValues: (value: any) => void
}

export const AdvancedSettingsTabContent = ({ settings, settingsValues, onChangeSettingsValues, onShowFileManager, setCustomSettingValues }: Props) => {
  const { theData } = useSelector((theState: RootState) => (theState.surveyInitialDataReducer))

  if (settings && settingsValues) {
    return (
      <div className='d-flex flex-column w-50'>
        <div className="form-group" >
          <label className='mb-1' htmlFor="">Extra CSS reference</label>
          <div className="input-group">
            <input
              type="text"
              className="form-control h-32"
              placeholder="Select a file"
              name='css'
              style={{ maxHeight: 'unset' }}
              value={settingsValues.css ? settingsValues.css : ""}
              onChange={(e) => setCustomSettingValues(e)}
            />
            <div className="input-group-append">
              <button className="btn btn-outline-secondary p-1" type="button" onClick={() => onShowFileManager("css")}>
                <Icon type="add" />
              </button>
              <button className="btn btn-outline-secondary p-1" type="button" onClick={() => onChangeSettingsValues(null, "css")}>
                <Icon type="delete-alt" />
              </button>
            </div>
          </div>
        </div>

        <div className="form-group" >
          <label className='mb-1' htmlFor="">Extra JavaScript reference</label>
          <div className="input-group">
            <input
              type="text"
              className="form-control h-32"
              placeholder="Select a file"
              name='js'
              style={{ maxHeight: 'unset' }}
              value={settingsValues.js ? settingsValues.js : ""}
              onChange={(e) => setCustomSettingValues(e)}
            />
            <div className="input-group-append">
              <button className="btn btn-outline-secondary p-1" type="button" onClick={() => onShowFileManager("js")}>
                <Icon type="add" />
              </button>
              <button className="btn btn-outline-secondary p-1" type="button" onClick={() => onChangeSettingsValues(null, "js")}>
                <Icon type="delete-alt" />
              </button>
            </div>
          </div>
        </div>

        <p className='mb-1'>Reopen questionnaire</p>
        <div className="d-flex flex-column survey-option">
          <DropdownButton
            className='btn-block'
            onItemClick={(e) => onChangeSettingsValues(e.item.name, 'reopen')}
            items={theData.dropdownValues.find((el: any) => el.name === "reopenType").enumValues}
            text={theData.dropdownValues.find((el: any) => el.name === "reopenType").enumValues.filter((el: any) => el.name === settingsValues.reopen)[0].text}
          />
        </div>
      </div>
    )
  } else {
    return null
  }
}