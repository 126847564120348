import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Input } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { ErrorMessage } from '../../../../../shared/ErrorMessage/ErrorMessage';
import { fetchPostJson as createNewSession, fetchPost as updateSession } from '../../../../../../services/services';

export default function SaveSessionModal({ onHide, selectedTab }) {
  const { token } = useSelector((state) => state.tokenStateReducer);
  const params = useParams();
  const dispatch = useDispatch()
  const toplineState = useSelector(theState => (theState.toplineStateReducer)).state
  const xtState = useSelector(theState => (theState.stateReducer)).state
  const chartState = useSelector(theState => (theState.chartStateReducer)).state
  const digState = useSelector(theState => (theState.digStateReducer)).state
  const dataState = useSelector(theState => (theState.rawDataStateReducer)).state
  const qTableState = useSelector(theState => (theState.qTableStateReducer)).state
  const reportState = useSelector(theState => (theState.reportStateReducer))
  const analysisScriptingState = useSelector(theState => theState.analysisScriptingStateReducer)
  const [formData, setFormData] = useState({ name: '', description: '' })
  const [errorMessage, setErrorMessage] = useState('')

  const datasetId = params.dataset;
  const projectId = params.name;

  const onChangeHandler = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const saveSessionHandler = (e) => {
    e.preventDefault()
    if (datasetId) {
      const body = {
        name: formData.name,
        description: formData.description
      }
      createNewSession(`projects/${projectId}/sessions/${datasetId}`, token, body)
        .then(newSession => {
          if (newSession.error) {
            setErrorMessage(newSession.error)
          } else {
            const body = { selectedTab: selectedTab, toplineState: toplineState, xtState: xtState, chartState: chartState, dataState: dataState, digState: digState, qTableState: qTableState, reportState: reportState, analysisScriptingState: { data: analysisScriptingState.data } }
            updateSession(`projects/${projectId}/sessions/${datasetId}/${newSession.id}/data`, token, body)
              .then(updateRes => {
                if (updateRes) {
                  const body = { selectedTab: selectedTab, toplineState: toplineState, xtState: xtState, chartState: chartState, dataState: dataState, digState: digState, qTableState: qTableState, reportState: reportState, analysisScriptingState: { data: analysisScriptingState.data } }
                  updateSession(`workingsessions/${datasetId}`, token, body)
                    .then(res => {
                      if (res) {
                        dispatch({ type: 'UPDATE_SELECTED_SESSION', payload: { name: newSession.name, id: newSession.id, lastSaved: new Date() } })
                        onHide()
                      }
                    })
                }
              })
          }
        })
    }
  }

  return (
    <Dialog minWidth={500} title={"Create a new session"} onClose={onHide} className="import-dialog">
      <form onSubmit={saveSessionHandler}>

        <div className="p-4">
          <div className="form-group">
            <label htmlFor="sessionName">Name</label>
            <Input
              required
              name="name"
              className="form-control"
              placeholder="Enter session name"
              value={formData.name}
              onChange={onChangeHandler} />
          </div>
          <div className="form-group">
            <label htmlFor="sessionDescription">Description</label>
            <textarea
              rows="3"
              name="description"
              className="form-control"
              placeholder="Enter session description"
              value={formData.description}
              onChange={onChangeHandler} />
          </div>
          {
            errorMessage &&
            <ErrorMessage
              type={'alert'}
              errorMessage={errorMessage} />
          }
        </div>

        <DialogActionsBar>
          <button type="button" className="k-button k-button-solid-base" onClick={onHide}>Close</button>
          <button type='submit' className="k-button k-button-solid-primary">Submit</button>
        </DialogActionsBar>
      </form>
    </Dialog >
  )
}