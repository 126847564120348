import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import Editor from '@monaco-editor/react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import type { RootState } from '../../../../../../store/reducers/rootReducer';
import { fetchPatchJson } from '../../../../../../services/services';

interface Props {
  scriptElement: { name: string, description: string, xml: string, id: string } | null;
  onHide: () => void;
  onAutoSaveHandler: (currentState: TODO) => void;
  showAnalysisScripting: (xml: string, id: string) => void
}

const validateXML = (xmlString: string): boolean => {
  try {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "text/xml");
    const errors = xmlDoc.getElementsByTagName("parsererror");
    return errors.length === 0;
  } catch (error) {
    return false;
  }
}

const formatXml = (xml: string) => {
  let formatted = '';
  let indent = '';
  xml.split(/>\s*</).forEach(function (node) {
    if (node.match(/^\/\w/)) indent = indent.substring('\t'.length);
    formatted += indent + '<' + node + '>\r\n';
    if (node.match(/^<?\w[^>]*[^/]$/)) indent += '\t';
  });
  return formatted.substring(1, formatted.length - 3)
}

export const AddScriptModal = ({ scriptElement, onHide, onAutoSaveHandler, showAnalysisScripting }: Props) => {
  const dispatch = useDispatch();
  const [didMount, setDidMount] = useState(false)
  const [formData, setFormData] = useState({ name: '', description: '', xml: '', id: uuid() })
  const [isRemovingSatTags, setIsRemovingSatTags] = useState(false)
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const analysisScriptingState = useSelector((theState: RootState) => ({ ...theState.analysisScriptingStateReducer }))

  useEffect(() => {
    if (!didMount && scriptElement) {
      setDidMount(true)
      setFormData({ ...scriptElement, xml: formatXml(scriptElement.xml) })
    }
  }, [didMount, scriptElement])

  const removeSatTags = () => {
    setIsRemovingSatTags(true)
    const body = { definition: formData.xml }
    fetchPatchJson("analysis/helpers/adoc/remove-sat", token, body)
      .then((res: TODO) => !res.ok ? res.json() : res.text())
      .then((data: TODO) => {
        setIsRemovingSatTags(false)
        if (data.error || data.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: data.error ? data.error : data.message } });
        } else {
          setFormData({ ...formData, xml: formatXml(data) })
        }
      })
  }

  const onSubmitHandler = () => {
    if (!scriptElement && analysisScriptingState.data.some((e: TODO) => e.name.toLowerCase() === formData.name.toLowerCase())) {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "An item with that name already exists" } })
    } else {
      if (validateXML(formData.xml)) {
        if (scriptElement) {
          dispatch({ type: 'UPDATE_ANALYSIS_SCRIPTING_DATA', payload: analysisScriptingState.data.map((e: TODO) => e.id === scriptElement.id ? formData : e) })
          onAutoSaveHandler({ data: analysisScriptingState.data.map((e: TODO) => e.id === scriptElement.id ? formData : e) })
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "The script has been updated successfully" } })
        } else {
          dispatch({ type: 'UPDATE_ANALYSIS_SCRIPTING_DATA', payload: [...analysisScriptingState.data, formData] })
          onAutoSaveHandler({ data: [...analysisScriptingState.data, formData] })
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "The script has been created successfully" } })
        }
        showAnalysisScripting(formData.xml, formData.id)
        onHide()
      } else {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "The XML you entered is not valid" } })
      }
    }
  }

  return (
    <Dialog width={'80%'} height={'80%'} title={scriptElement ? `Edit '${scriptElement.name}'` : "Create new script"} onClose={onHide} className="import-dialog">
      <div className="d-flex flex-column gap-md p-4 h-100">
        <div className="d-flex gap-md">
          <div className="d-flex flex-column w-50">
            <label className='mb-1' htmlFor="scriptingName">Name</label>
            <input id='scriptingName' onChange={(e) => setFormData({ ...formData, name: e.target.value })} value={formData.name} type="text" className="form-control" />
          </div>
          <div className="d-flex flex-column w-50">
            <label className='mb-1' htmlFor="scriptingDescription">Description</label>
            <input id='scriptingDescription' onChange={(e) => setFormData({ ...formData, description: e.target.value })} value={formData.description} type="text" className="form-control" />
          </div>
        </div>

        <div style={{ flex: '1 1 auto' }} className='d-flex flex-column'>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <p className='mb-0 ml-1'>Enter XML:</p>
            <div className="d-flex align-items-center gap-sm">
              <button type='button' className="btn btn-transparent" onClick={() => setFormData({ ...formData, xml: formatXml(formData.xml) })}>
                <i className="fas fa-sparkles mr-2" />
                Beautify code
              </button>
              <button
                type='button'
                disabled={isRemovingSatTags}
                className={"btn btn-transparent"}
                onClick={removeSatTags}>
                <i className="fas fa-trash-alt mr-2" />
                Remove &lt;sat&gt; tags
              </button>
            </div>
          </div>
          <Editor
            theme={'vs-dark'}
            language="xml"
            value={formData.xml}
            onChange={(xml) => xml ? setFormData({ ...formData, xml: xml }) : null}
            options={{
              minimap: { enabled: false, },
              glyphMargin: false,
              folding: false,
              overviewRulerLanes: 0,
              hideCursorInOverviewRuler: true,
              overviewRulerBorder: false,
              scrollbar: { horizontal: 'visible' }
            }}
          />
        </div>
      </div>

      <DialogActionsBar>
        <button type="button" className="k-button btn-secondary" onClick={onHide}>Close</button>
        <button onClick={onSubmitHandler} disabled={!formData.name || !formData.xml} type='submit' className="k-button btn-primary">
          {scriptElement ? 'Update' : 'Create'}
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}