import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Editor from "@monaco-editor/react";

import { RootState } from '../../../../../../../../../store/reducers/rootReducer';
import { InProgressOverlay } from '../../../../../../../../shared/InProgressOverlay/InProgressOverlay';
import { FullHeightSpinner } from '../../../../../../../../shared/FullHeightSpinner/FullHeightSpinner';
import { Icon } from '../../../../../../../../shared/Icon/Icon';

interface Props {
  handleEditTool: () => void
}

export const EditTabContent = ({ handleEditTool }: Props) => {
  const { workflowData } = useSelector((theState: RootState) => (theState.workflowStateReducer));
  const [editorTheme, setEditorTheme] = useState("vs-dark");
  const dispatch = useDispatch()

  const handleEditorChange = (value: any) => {
    dispatch({ type: 'UPDATE_TOOL', payload: { ...workflowData.selectedTool, definition: value } })
  }

  const changeTheme = (event: any) => {
    event.preventDefault();
    if (editorTheme === "vs-light") {
      setEditorTheme("vs-dark");
    } else {
      setEditorTheme("vs-light");
    }
  }

  return (
    workflowData.loadingTool && workflowData.loadingToolType === 'load' ?
      <FullHeightSpinner />
      : workflowData.selectedTool ?
        <div className="d-flex flex-column h-100">
          {workflowData.loadingTool && workflowData.loadingToolType === 'save' &&
            <InProgressOverlay type="overlay" theme="primary" message='Saving' />
          }
          <div className="d-flex flex-column h-100 p-0 workflow-tool-advanced" >
            <div className="cta-position d-flex flex-row">
              <button className="btn btn-transparent btn-icon icon-l"
                onClick={changeTheme} ><Icon type="editor-theme" className='mr-1 fill-primary' />Theme</button>
              <button onClick={handleEditTool} className="btn btn-transparent btn-icon icon-l"
              ><Icon type="dashboard-edit" className='mr-1 fill-primary' />Edit tool</button>
            </div>
            <div style={{ 'height': 'calc(100% - 42px)' }} className="overflow-hidden">
              <Editor
                theme={editorTheme}
                defaultLanguage="xml"
                defaultValue={workflowData.selectedTool.definition}
                onChange={handleEditorChange}
                options={{
                  minimap: { enabled: false, },
                  lineNumbers: 'off',
                  glyphMargin: false,
                  folding: false,
                  overviewRulerLanes: 0,
                  hideCursorInOverviewRuler: true,
                  overviewRulerBorder: false,
                }}
              />
            </div>
          </div>
        </div>
        :
        null
  )
}