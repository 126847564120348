import { useDispatch } from 'react-redux';
import type { CreateAPIKeyFormData } from '../../../../../../interfaces/adminInterfaces/adminInterfaces';

interface Props {
  formData: CreateAPIKeyFormData
}

export const CreatedAPIKeyContent = ({ formData }: Props) => {
  const dispatch = useDispatch()

  const onCopyHandler = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The text has been copied to clipboard.' } })
    });
  }

  return (
    <div>
      <p>API Key successfuly created!</p>
      <p>
        <strong>
          Please note down the Client ID and Client Secret. <br />
          Once this window is closed, you won't be able to see them again.
        </strong>
      </p>
      <span>Client ID</span>
      <div className="input-group mb-3">
        <input readOnly type="text" className="form-control" value={formData.clientId} />
        <div className="input-group-append">
          <button onClick={() => onCopyHandler(formData.clientId)} className="btn btn-outline-secondary" type="button">
            <i className="far fa-copy text-muted" />
          </button>
        </div>
      </div>
      <span>Client Secret</span>
      <div className="input-group mb-3">
        <textarea readOnly className="form-control" value={formData.clientSecret} />
        <div className="input-group-append">
          <button onClick={() => onCopyHandler(formData.clientSecret)} className="btn btn-outline-secondary" type="button">
            <i className="far fa-copy text-muted" />
          </button>
        </div>
      </div>
    </div>
  )
}