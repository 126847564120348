import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@progress/kendo-react-buttons';
import { Step, Stepper } from '@progress/kendo-react-layout';

import { fetchGetJson as getSampleSpecification, fetchPutJson as updateSampleSpecification } from '../../../../services/services'
import { RootState } from '../../../../store/reducers/rootReducer';
import { PanelDesignSteps } from './PanelDesignSteps/PanelDesignSteps';
import { FullHeightSpinner } from '../../../shared/FullHeightSpinner/FullHeightSpinner';

const stepperItems = [{ label: "General", icon: "fas fa-bullseye" }, { label: "Dimensions", icon: "fas fa-layer-group" }, { label: "Filters", icon: "fas fa-filter" }, { label: "Replicates", icon: "fas fa-tasks" }, { label: "Advanced", icon: "fas fa-cog" }]


interface Props {
  token: string
  onReviewAndSubmit: () => void
}

export const PanelDesignTabContent = ({ token, onReviewAndSubmit }: Props) => {
  const { panelData } = useSelector((theState: RootState) => theState.panelStateReducer);
  const [stepperValue, setStepperValue] = useState<number>(0)

  const dispatch = useDispatch();

  useEffect(() => {
    if (panelData.sampleSpecification === null) {
      getSampleSpecification(`projects/${panelData.sampleData.projectId}/samples/${panelData.sampleData.id}/specification`, token)
        .then((res: any) => {
          if (res && (res.message || res.error)) {
            dispatch({ type: 'UPDATE_ERROR_MESSAGE', payload: { msg: res.message ? res.message : res.error, type: 'modal' } })
          } else {
            dispatch({ type: 'SET_SAMPLE_SPECIFICATION_DATA', payload: res })
          }
        })
    }
  }, [panelData, token, dispatch])

  const onNextStep = (type: string) => {
    if (panelData.sampleSpecHasChanges) {
      const body = panelData.sampleSpecification
      updateSampleSpecification(`projects/${panelData.sampleData.projectId}/samples/${panelData.sampleData.id}/specification`, token, body)
        .then((res: any) => {
          dispatch({ type: 'SET_SAMPLE_SPECIFICATION_DATA', payload: res })
          setStepperValue(type === 'next' ? stepperValue + 1 : stepperValue - 1)
        })
    } else {
      setStepperValue(type === 'next' ? stepperValue + 1 : stepperValue - 1)
    }

  }

  const handleSelect = (e: any) => {
    if (panelData.sampleSpecification.targetInterviews !== null && panelData.sampleSpecification.targetInterviews !== 0) {
      setStepperValue(e.value)
    }
  }

  const customStepItem = (props: any) => {
    const isDisabled = panelData.sampleSpecification && panelData.sampleSpecification.targetInterviews !== null && panelData.sampleSpecification.targetInterviews !== 0
    return (
      <Step label={props.label} current={stepperItems[stepperValue].label === props.label} index={props.index} disabled={!isDisabled}>
        <div className={`custom-step k-step-label ${stepperItems[stepperValue].label === props.label ? 'bg-primary text-white border-0' : 'border border-primary text-primary'}`}>
          <i className={`${props.icon} mr-2`} />
          <span>{props.label}</span>
        </div>
      </Step>
    )
  }

  return (
    <div className="flex-grow-1 d-flex flex-column h-100">
      <div className="bg-white border-right border-bottom p-3">
        <Stepper
          items={stepperItems}
          value={stepperValue}
          onChange={handleSelect}
          orientation="horizontal"
          mode={'labels'}
          item={customStepItem}
        />
      </div>
      {panelData.sampleSpecification ?
        <div className="d-flex bg-white flex-column p-0 panel-designer">
          <div className="d-flex justify-content-end align-items-center">
            <div className="d-flex align-items-center">
              {panelData.sampleData.isSubmitNow &&
                <h5 className="mb-0">
                  <span className="badge bg-success text-white">
                    SAMPLE SUBMITTED
                  </span></h5>}
              <Button className="d-flex mx-3 my-2" title="Save changes" iconClass="fa far fa-save" disabled={panelData.sampleData.isSubmitNow} />
            </div>
          </div>
          <PanelDesignSteps step={stepperItems[stepperValue].label} onNextStep={onNextStep} onReviewAndSubmit={onReviewAndSubmit} token={token} />
        </div>
        :
        <FullHeightSpinner />
      }
    </div>
  )
}

