const initialState = {
  groups: null,
  tagDefinitions: null,
  emailProviders: null,
  SMSProviders: null,
  subAccounts: null,
  apiKeys: null,
  showCreateGroup: false,
  shouldUpdateGroups: false,
  shouldUpdateNavbarData: true,
  emailProvidersErrorMessage: null,
  subAccountsErrorMessage: null,
  shouldUpdateEmailProviders: false,
  shouldUpdateSmsProviders: false,
  shouldUpdateSubAccounts: false,
  showEmailProviderTypeModal: false,
  showSMSProviderTypeModal: false,
  showInviteUserModal: false,
  shouldUpdateInvitedUsers: false,
  showCreateAPIKeysModal: {
    show: false,
    apiKey: null
  },
  showRemoveAPIKeyModal: {
    show: false,
    apiKey: null
  },
  showEmailProvidersModal: {
    show: false,
    emailProvider: null
  },
  showSendGridProvidersModal: {
    show: false,
    emailProvider: null
  },
  showTwilioSmsProvidersModal: {
    show: false,
    smsProvider: null
  },
  showDeleteEmailProviderModal: {
    show: false,
    emailProvider: null
  },
  showDeleteSMSProviderModal: {
    show: false,
    emailProvider: null
  },
  showSubAccountModal: {
    show: false,
    subAccount: null
  },
  showSubAccountInviteUsersModal: {
    show: false,
    subAccount: null
  }
};

function adminReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_API_KEYS':
      return {
        ...state,
        apiKeys: action.payload
      };
    case 'SET_SUB_ACCOUNTS':
      return {
        ...state,
        subAccounts: action.payload
      };
    case 'SET_SMS_PROVIDERS':
      return {
        ...state,
        SMSProviders: action.payload
      };
    case 'SET_EMAIL_PROVIDERS':
      return {
        ...state,
        emailProviders: action.payload
      };
    case 'UPDATE_TAG_DEFINITIONS':
      return {
        ...state,
        tagDefinitions: action.payload
      };
    case 'UPDATE_GROUPS':
      return {
        ...state,
        groups: action.payload
      };
    case 'UPDATE_SHOW_ADD_GROUP_MODAL':
      return {
        ...state,
        showCreateGroup: action.payload
      };
    case 'SHOULD_UPDATE_GROUPS':
      return {
        ...state,
        shouldUpdateGroups: action.payload
      };
    case 'SET_SHOULD_UPDATE_NAVBAR_DATA':
      return {
        ...state,
        shouldUpdateNavbarData: action.payload
      }
    case 'UPDATE_EMAIL_PROVIDERS':
      return {
        ...state,
        shouldUpdateEmailProviders: action.payload
      };
    case 'UPDATE_SMS_PROVIDERS':
      return {
        ...state,
        shouldUpdateSmsProviders: action.payload
      };
    case 'UPDATE_SUB_ACCOUNTS':
      return {
        ...state,
        shouldUpdateSubAccounts: action.payload
      };
    case 'UPDATE_INVITED_USERS':
      return {
        ...state,
        shouldUpdateInvitedUsers: action.payload
      };
    case 'UPDATE_SHOW_EMAIL_PROVIDERS_MODAL':
      return {
        ...state,
        showEmailProvidersModal: {
          show: action.payload.show,
          emailProvider: action.payload.emailProvider
        }
      };
    case 'UPDATE_SHOW_SENDGRID_PROVIDERS_MODAL':
      return {
        ...state,
        showSendGridProvidersModal: {
          show: action.payload.show,
          emailProvider: action.payload.emailProvider
        }
      };
    case 'UPDATE_SHOW_TWILIO_SMS_PROVIDERS_MODAL':
      return {
        ...state,
        showTwilioSmsProvidersModal: {
          show: action.payload.show,
          smsProvider: action.payload.smsProvider
        }
      };
    case 'UPDATE_SHOW_SUB_ACCOUNTS_PROVIDERS_MODAL':
      return {
        ...state,
        showSubAccountModal: {
          show: action.payload.show,
          subAccount: action.payload.subAccount
        }
      };
    case 'UPDATE_SHOW_DELETE_EMAIL_PROVIDERS_MODAL':
      return {
        ...state,
        showDeleteEmailProviderModal: {
          show: action.payload.show,
          emailProvider: action.payload.emailProvider
        }
      };
    case 'UPDATE_SHOW_DELETE_SMS_PROVIDERS_MODAL':
      return {
        ...state,
        showDeleteSMSProviderModal: {
          show: action.payload.show,
          emailProvider: action.payload.emailProvider
        }
      };
    case 'UPDATE_SHOW_SUB_ACCOUNTS_INVITE_USERS_MODAL':
      return {
        ...state,
        showSubAccountInviteUsersModal: {
          show: action.payload.show,
          subAccount: action.payload.subAccount
        }
      };
    case 'UPDATE_SHOW_API_KEYS_MODAL':
      return {
        ...state,
        showCreateAPIKeysModal: {
          show: action.payload.show,
          apiKey: action.payload.apiKey
        }
      };
    case 'UPDATE_SHOW_REMOVE_API_KEYS_MODAL':
      return {
        ...state,
        showRemoveAPIKeyModal: {
          show: action.payload.show,
          apiKey: action.payload.apiKey
        }
      };
    case 'UPDATE_SHOW_EMAIL_PROVIDER_TYPE_MODAL':
      return {
        ...state,
        showEmailProviderTypeModal: action.payload
      }
    case 'UPDATE_SHOW_SMS_PROVIDER_TYPE_MODAL':
      return {
        ...state,
        showSMSProviderTypeModal: action.payload
      }
    case 'UPDATE_SHOW_INVITE_USER_MODAL':
      return {
        ...state,
        showInviteUserModal: action.payload
      };
    case 'UPDATE_EMAIL_PROVIDERS_ERROR_MESSAGE':
      return {
        ...state,
        emailProvidersErrorMessage: action.payload
      };
    case 'UPDATE_SUB_ACCOUNTS_ERROR_MESSAGE':
      return {
        ...state,
        subAccountsErrorMessage: action.payload
      };
    default:
      return state;
  }
}

export default adminReducer;