const initialState = {
  theState: {
    state: {
      xml: '',
      theme: ''
    }
  }
};

function scriptStateReducer(state = initialState.theState, action) {
  switch (action.type) {
    case 'UPDATE_INITIAL_SCRIPT_STATE':
      return {
        ...state,
        state: action.payload
      }
    case 'SCRIPT_SET_INITIAL_STATE':
      return {
        ...state,
        state: {
          xml: "<job></job>",
          theme: "vs-light"
        }
      }
    default:
      return { ...state };
  }

}

export default scriptStateReducer;