import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

interface Props {
  value: string | undefined
  setValue?: (value: string) => void
  readOnly?: boolean
  toolbar?: string[] | {}[]
}

const QuillEditor = ({ value, readOnly, setValue, toolbar }: Props) => {
  const modules = toolbar ? { toolbar: [...toolbar] } : undefined

  return (<ReactQuill
    theme={`${readOnly ? "bubble" : "snow"}`}
    value={value} readOnly={readOnly ? readOnly : false}
    onChange={setValue}
    modules={modules}
  />)
}

export default QuillEditor;