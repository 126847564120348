export default (rangeItems, data) => {
  let items = []
  if (rangeItems) {
    if (rangeItems.length === 2) {
      if (rangeItems[0].index > rangeItems[1].index) {
        items = data.slice(rangeItems[1].index, rangeItems[0].index + 1)
      }
      else {
        items = data.slice(rangeItems[0].index, rangeItems[1].index + 1)
      }
    }
  }
  return items
}