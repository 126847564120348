import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import React from 'react'
import { useDispatch } from 'react-redux';
import { fetchPutJson } from '../../../../../../../services/services';

function RenameFolderModal({ setRenameModal, library, token, setShouldMount, libraryScope, renameModal, directoryElements, setFoldersClicked, setSelectedRow, setSelectedElementType }) {

  const dispatch = useDispatch();

  const closeDialog = () => {
    setRenameModal({
      show: false,
      id: "",
      name: "",
      pid: null
    })
  };

  const onSaveHandler = () => {
    const body = {
      name: renameModal.name,
      parentFolderId: renameModal.pid
    }

    if (library === 'personal') {
      if (directoryElements?.some(el => el.n === body.name)) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'An element with that name already exists' } })
      } else {
        fetchPutJson(`/libraries/${libraryScope}/user/${renameModal.id}`, token, body)
          .then(res => {
            if (res.error || res.message) {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
            }
          })
          .then(() => {
            setShouldMount(true)
            setFoldersClicked([])
            setSelectedRow()
            setSelectedElementType()
          })
        setRenameModal({
          show: false,
          id: "",
          name: "",
          pid: null
        })
      }
    } else if (library === 'global') {
      if (directoryElements?.some(el => el.n === body.name)) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'An element with that name already exists' } })
      } else {
        fetchPutJson(`/libraries/${libraryScope}/customer/${renameModal.id}`, token, body)
          .then(res => {
            if (res.error || res.message) {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
            }
          })
          .then(() => {
            setShouldMount(true)
            setFoldersClicked([])
            setSelectedRow()
            setSelectedElementType()
          })
        setRenameModal({
          show: false,
          id: "",
          name: "",
          pid: null
        })
      }
    }
  };

  return (
    <Dialog width="400px" height="auto" className="import-dialog library-sub-dialog no-wrap" title={`Rename folder`} onClose={closeDialog}>
      <div className="d-flex flex-row justify-content-center align-items-center p-4">
        <input className="form-control" value={renameModal.name} onChange={(e) => setRenameModal({ ...renameModal, name: e.target.value })} />
      </div>
      <DialogActionsBar>
        <button className="k-button btn-secondary mr-2" onClick={closeDialog}>Cancel</button>
        <button onClick={onSaveHandler} className="k-button k-button-solid-primary text-white">Save</button>
      </DialogActionsBar>
    </Dialog>
  )
}

export default RenameFolderModal