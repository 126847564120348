import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';

import { Icon } from '../../../Icon/Icon';
import { RootState } from '../../../../../store/reducers/rootReducer';
import { returnFileIcon } from '../../../helpers/returnFileIcon/returnFileIcon';
import { returnUpdatedActionButtons } from '../../../helpers/returnUpdatedActionButtons/returnUpdatedActionButtons';
import { filterExpandedFileManagerItems } from '../../../helpers/filterExpandedFileManagerItems/filterExpandedFileManagerItems';
import { FileManagerExpandedScreenSortDetails } from '../FileManagerExpandedScreenSortDetails/FileManagerExpandedScreenSortDetails';
import { updateActionButtons, updateCheckedFiles, updateExpandedFilter as updateExpandedFilterState, updateFileManagerExpandedState } from '../../../../../store/reducers/fileManagerStateReducer/fileManagerStateReducer';

import LibraryFolderIcon from '../../../../../assets/img/FileLibrary/file-library-folder.svg';

interface Props {
  expandedState: any
  expandedDisplayType: string
  onDoubleClickRowHandler: (item: any) => void
  selectRow: (item: any, stateType: string) => void
}

export const FileManagerExpandedScreenDetails = ({ expandedState, expandedDisplayType, onDoubleClickRowHandler, selectRow }: Props) => {
  const dispatch = useDispatch()
  const { checkedFiles, actionButtons, expandedFilter } = useSelector((theState: RootState) => theState.fileManagerStateReducer);

  const updateExpandedFilter = (type: string) => {
    if (expandedState.data && expandedState.data[0] && expandedState.data[0].contents.length > 0) {
      const updatedFilterData = filterExpandedFileManagerItems(expandedFilter, type, expandedState)
      dispatch(updateExpandedFilterState({ type: type, filter: updatedFilterData.updatedFilter }))
      dispatch(updateFileManagerExpandedState(updatedFilterData.updatedExpandedState))
    }
  }

  const onSelectAllHandler = (e: { target: { checked: boolean } }) => {
    let updatedActionButtons = null
    let updatedCheckedFiles = []
    if (e.target.checked) {
      updatedCheckedFiles = expandedState.data[0].contents
    }
    updatedActionButtons = returnUpdatedActionButtons([...actionButtons], 'expandedState', updatedCheckedFiles)
    dispatch(updateCheckedFiles(updatedCheckedFiles))
    dispatch(updateActionButtons(updatedActionButtons))
  }

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  useEffect(() => {
    return () => {
      dispatch(updateCheckedFiles([]))
    }
  }, [dispatch]);

  return (
    <>
      <FileManagerExpandedScreenSortDetails
        expandedState={expandedState}
        expandedDisplayType={expandedDisplayType} />
      <div className='file-library-table overflow-auto'>
        <table>
          <thead className="table-header">
            <tr>
              <th className="mr-3">
                <input
                  aria-label='Select all'
                  type="checkbox"
                  checked={checkedFiles.length > 0 && expandedState.data[0] && checkedFiles.length === expandedState.data[0].contents.length ? true : false}
                  onChange={onSelectAllHandler}
                />
              </th>
              <th className='w-75' onClick={() => updateExpandedFilter('title')}>
                <div className='text-nowrap d-flex align-items-center'>
                  <span className='medium strong cursor-pointer'>Title</span>
                  <span className={`${expandedFilter.type === 'title' ? '' : 'invisible'}`}><Icon type={expandedFilter.filter === 'asc' ? 'chevron-mini-up' : 'chevron-mini-down'} /></span>
                </div>
              </th>
              <th className="w-10 text-nowrap" onClick={() => updateExpandedFilter('document')}>
                <div className='text-nowrap d-flex align-items-center'>
                  <span className='medium strong cursor-pointer'>Type</span>
                  <span className={`${expandedFilter.type === 'document' ? '' : 'invisible'}`}><Icon type={expandedFilter.filter === 'asc' ? 'chevron-mini-up' : 'chevron-mini-down'} /></span>
                </div>
              </th>
              <th className="w-10 text-nowrap" onClick={() => updateExpandedFilter('size')}>
                <div className='text-nowrap d-flex align-items-center'>
                  <span className='medium strong cursor-pointer'>Size</span>
                  <span className={`${expandedFilter.type === 'size' ? '' : 'invisible'}`}><Icon type={expandedFilter.filter === 'asc' ? 'chevron-mini-up' : 'chevron-mini-down'} /></span>
                </div>
              </th>
              <th className="w-25 text-nowrap" onClick={() => updateExpandedFilter('date')}>
                <div className='text-nowrap d-flex align-items-center pr-5'>
                  <span className='medium strong cursor-pointer'>Date modified</span>
                  <span className={`${expandedFilter.type === 'date' ? '' : 'invisible'}`}><Icon type={expandedFilter.filter === 'asc' ? 'chevron-mini-up' : 'chevron-mini-down'} /></span>
                </div>
              </th>
            </tr>
          </thead>

          <tbody className='table-body overflow-auto'>
            {
              expandedState.data.length > 0 && expandedState.data[0] && expandedState.data[0].contents && expandedState.data[0].contents.filter((el: any) => el.displayName !== '_empty.tmp').map((item: any, key: number) => {
                const fileIcon = returnFileIcon(item)
                const updatedDateFormat = item.dateModified ? new Date(item.dateModified).toString().substr(3, 18) : ''
                return (
                  <tr className={`body-row cursor-pointer ${checkedFiles.find((file: any) => file.id === item.id) ? 'selected-row' : ''}`} key={key}>
                    <td className="pl-4">
                      <input
                        id={item.id}
                        aria-label={item.id}
                        type="checkbox"
                        onChange={() => selectRow({ dataItem: item }, 'expandedState')}
                        onClick={(e) => { e.stopPropagation() }}
                        checked={checkedFiles.find((file: any) => file.id === item.id) ? true : false}
                      />
                    </td>
                    <td className="w-75 text-truncate" onDoubleClick={() => onDoubleClickRowHandler(item)}>
                      <div className='text-nowrap d-flex align-items-center' style={{ height: "28px" }} >
                        <img src={item.isDirectory ? LibraryFolderIcon : fileIcon.icon} alt={item.isDirectory ? 'Folder' : fileIcon.alt} className='mr-2 pe-none' />
                        <span>{item.displayName}</span>
                        {
                          item.isDirectory ? '' :
                            <Tooltip anchorElement="target" position="top">
                              <button title="Open new in tab" className='btn btn-transparent p-05 ml-2 external' onClick={() => openInNewTab(item.uri)}><Icon type="share-arrow" className='pe-none' /></button>
                            </Tooltip>
                        }
                      </div>
                    </td>
                    <td className="w-10 text-muted text-nowrap " onDoubleClick={() => onDoubleClickRowHandler(item)}>{item.isDirectory ? 'Folder' : fileIcon.alt}</td>
                    <td className="w-10 text-muted text-nowrap " onDoubleClick={() => onDoubleClickRowHandler(item)}>{item.sizeString}</td>
                    <td className="w-25 text-muted text-nowrap pr-5" onDoubleClick={() => onDoubleClickRowHandler(item)}>{updatedDateFormat}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </>
  )
}