import { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import { BaseDropDownList } from '../../../../../shared/Inputs';
import { FullHeightSpinner } from "../../../../../shared/FullHeightSpinner/FullHeightSpinner";
import type { ToolInput } from "../../../../../../interfaces/workflowInterfaces/toolInterfaces";
import type { ProjectDocument } from "../../../../../../interfaces/projectDetailsInterfaces/projectDetailsInterfaces";
import { fetchGetJson as getProjectsData, fetchGetJsonOrEmptyArray as getProjectDocuments } from "../../../../../../services/services";

interface Props {
  handleClose: () => void
  token: string
  input: ToolInput | TODO
  outputId: string | undefined
  onSaveInput: (inputData: TODO, projectValue: string | undefined, documentValue: string | undefined) => void
  type: string | null
  currentProjectId: string
}

export const InputModal = ({ handleClose, token, input, outputId, onSaveInput, type, currentProjectId }: Props) => {
  const [projects, setProjects] = useState<[]>([])
  const [loadingDocuments, setLoadingDocuments] = useState(false)
  const [documents, setDocuments] = useState<ProjectDocument[]>([])
  const [projectValue, setProjectValue] = useState<string | undefined>('')
  const [documentValue, setDocumentValue] = useState<string | undefined>('')
  const [inputData, setInputData] = useState<ToolInput>({ projectId: input.projectId, datasetId: input.datasetId, preFilter: input.preFilter, inputIsSurveyDataset: input.inputIsSurveyDataset, fromPreviousTool: input.fromPreviousTool, metadata: input.metadata })

  useEffect(() => {
    getProjectsData("projects", token)
      .then((res: TODO) => {
        setProjects(res)
        if (type === 'output') {
          if (outputId) {
            setProjectValue(res.find((el: TODO) => el.id === outputId))
          } else if (currentProjectId) {
            const currentProject = res.find((project: { id: string; }) => project.id === currentProjectId);
            setProjectValue(currentProject);
            setInputData({ ...inputData, projectId: currentProject.id })
          }
          setDocumentValue(undefined)
        }
        if (type === 'input') {
          if (input.projectId) {
            setProjectValue(res.find((el: TODO) => el.id === input.projectId))
          } else if (currentProjectId) {
            const currentProject = res.find((project: { id: string; }) => project.id === currentProjectId);
            setProjectValue(currentProject);
            setInputData({ ...inputData, projectId: currentProject.id })
          }
          getProjectDocuments(`projects/${input.projectId ? input.projectId : currentProjectId ? currentProjectId : res[0].id}/documents`, token)
            .then((res: TODO) => {
              const surveys = res.surveys ? res.surveys.filter((survey: { publishedVersions: string | TODO[]; }) => survey.publishedVersions && survey.publishedVersions.length) : []
              const docs = [...res.datasets, ...res.datasetsv2, ...surveys]
              setDocuments(docs)
              setDocumentValue(input.datasetId ? docs.find((el: TODO) => el.id === input.datasetId) : undefined)
            })
        }
      })
  }, [])

  const onProjectChange = (e: TODO) => {
    if (type === 'input') {
      getProjectDocuments(`projects/${e.value.id}/documents`, token)
        .then((res: TODO) => {
          const surveys = res.surveys ? res.surveys.filter((survey: { publishedVersions: string | TODO[]; }) => survey.publishedVersions?.length) : []
          setDocuments([...res.datasets, ...res.datasetsv2, ...surveys])
          setInputData({ ...inputData, projectId: e.value.id })
          setProjectValue(e.value)
          setLoadingDocuments(false)
          setDocumentValue(undefined)
        })
    } else {
      setInputData({ ...inputData, projectId: e.value.id })
      setProjectValue(e.value)
      setLoadingDocuments(false)
      setDocumentValue(undefined)
    }
  }

  const onDocumentChange = (e: TODO) => {
    setDocumentValue(e.value)
    setInputData({ ...inputData, datasetId: e.value.id, inputIsSurveyDataset: e.value.type === 'survey' ? true : false, preFilter: null, metadata: null })
  }

  return (
    <Dialog title={`Select ${type === 'input' ? 'input' : 'output'}`} onClose={handleClose} height="40%" width="500px">
      {(projectValue === '' || documentValue === '') ?
        <FullHeightSpinner /> :
        <div className="d-flex flex-column p-3">
          <span className="mb-2">Select project</span>
          <BaseDropDownList
            data={projects}
            textField="name"
            className="w-100 mb-3"
            defaultValue={projectValue}
            onChange={(e: TODO) => { setLoadingDocuments(true); onProjectChange(e) }}
          />
          {type === 'input' &&
            <div className="d-flex flex-column">
              <span>Select dataset/survey</span>
              {loadingDocuments ?
                <span className="text-primary">Loading documents...</span> :
                <BaseDropDownList
                  data={documents}
                  textField="name"
                  className="w-100 mb-3"
                  defaultValue={documentValue}
                  onChange={(e: TODO) => onDocumentChange(e)}
                  disabled={!inputData.projectId}
                />}
            </div>
          }
        </div>}

      <DialogActionsBar>
        <Button className="btn btn-secondary" onClick={handleClose}>Cancel</Button>
        {
          type === 'input' ?
            <Button
              className="btn btn-primary"
              disabled={!projectValue || !documentValue}
              onClick={() => onSaveInput(inputData, projectValue, documentValue)}>Save
            </Button>
            :
            <Button
              className="btn btn-primary"
              disabled={!projectValue}
              onClick={() => onSaveInput(inputData, projectValue, documentValue)}>Save
            </Button>
        }
      </DialogActionsBar>
    </Dialog>
  )
}