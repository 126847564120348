export default (toggleSearch, toggleReplace, multiSelect) => {
  const questionControlsButtons = [
    {
      onToggleControlAction: 'replace',
      toggleElement: toggleReplace,
      buttonText: 'Replace',
      buttonIcon: 'fa fa-fw far fa-sync'
    },
    {
      onToggleControlAction: 'multiSelect',
      toggleElement: multiSelect,
      buttonText: 'Define range',
      buttonIcon: 'fa fa-fw far fa-tasks'
    },
  ]
  return questionControlsButtons
}