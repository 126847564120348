import { Skeleton } from "../../../shared/Skeleton/Skeleton"

export const ProjectsTableSkeleton = () => {
  return (
    [...Array(20).keys()].map((item) => (
      <tr className='cursor-pointer project-table-item' key={item} style={{ height: "57px" }}>
        <td />
        <td className='d-flex gap-lg align-items-center'>
          <Skeleton className="h-32 w-32" />
          <Skeleton className="h-24" />
        </td>

        <td>
          <Skeleton className="h-24" />
        </td>

        <td><Skeleton className="h-24" /></td>

        <td><Skeleton className="h-24" /></td>
        <td><Skeleton className="h-24" /></td>

        <td>
          <Skeleton className="h-24" />
        </td>

        <td>
          <Skeleton className="h-24 w-40" />
        </td>
      </tr>
    ))
  )
}