import type { ExportDocumentFormData } from "../../../../interfaces/projectDetailsInterfaces/projectDetailsInterfaces"

export const returnExportDocumentBody = (selectedTab: number, formData: ExportDocumentFormData) => {
  let body = null
  if (selectedTab === 0) {
    body = {
      type: "sav",
      options: {
        language: formData.language,
        createLog: formData.includeLogin,
        use01MultiValues: formData.useMultiValues,
        useQuesIdsAsLabels: formData.useIdAsLabel,
        spssExportFormat: formData.formatType === "Default" ? 0 : formData.formatType === "Dapresy" ? 1 : 2
      }
    }
  } else if (selectedTab === 1) {
    body = {
      type: "sss",
      options: {
        language: formData.language,
        createLog: formData.includeLogin,
        useQuesIdsAsLabels: formData.useIdAsLabel
      }
    }
  } else if (selectedTab === 2) {
    body = {
      type: "miprox"
    }
  }

  return body
}