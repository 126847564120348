import { type FormEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'

import { BaseDropDownList } from "../../../../../shared/Inputs";
import type { RootState } from '../../../../../../store/reducers/rootReducer';
import { ErrorMessage } from '../../../../../shared/ErrorMessage/ErrorMessage';
import type { AdminUser } from '../../../../../../interfaces/userInterface/userInterface';
import { renameUserTypeRoles, revertUserTypeRoles } from '../../../../../shared/helpers/renameUserTypeRoles/renameUserTypeRoles';
import { fetchPutJson as updateUser } from '../../../../../../services/services';

type Props = {
  activeUser: AdminUser | null;
  handleClose: () => void;
  updateUsers: () => void;
}

export const EditUserModal = ({ activeUser, handleClose, updateUsers }: Props) => {
  const dispatch = useDispatch()
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [updatedUser, setUpdatedUser] = useState({ firstName: activeUser?.firstName, lastName: activeUser?.lastName, role: renameUserTypeRoles(activeUser?.role || ""), groups: activeUser?.groups })

  const userRoles = ["Administrator", "Advanced"] as const;

  const onUpdateUser = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true);
    const updateData: TODO = { ...updatedUser }
    if (updateData.groups) {
      updateData.groups = updateData.groups.map((a: TODO) => a.id);
    }
    const body = { ...updateData, role: revertUserTypeRoles(updateData.role) }
    if (updatedUser.firstName !== '' && activeUser) {
      updateUser(`users/${activeUser.id}`, token, body)
        .then((res: TODO) => {
          setIsLoading(false);
          if (res.error || res.message) {
            setErrorMessage(res.error || res.message)
          } else {
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The user has been updated successfully' } })
            updateUsers()
            handleClose()
          }
        })
    }
  }

  return (
    <Dialog title="Edit user"
      onClose={handleClose}
      className="import-modal"
      width={600}>
      <form onSubmit={(e) => onUpdateUser(e)}>
        <div className="d-flex flex-column gap-lg p-4">
          <div className="d-flex flex-column">
            <p className="h6 mb-2">First name</p>
            <input
              required
              name="userName"
              className="form-control" value={updatedUser.firstName} onChange={e => setUpdatedUser({ ...updatedUser, firstName: e.target.value })}
            />
          </div>

          <div className="d-flex flex-column">
            <p className="h6 mb-2">Last name</p>
            <input
              name="userLastName"
              className="form-control" value={updatedUser.lastName} onChange={e => setUpdatedUser({ ...updatedUser, lastName: e.target.value })}
            />
          </div>

          <div className="d-flex flex-column">
            <p className="h6 mb-2">User Type</p>
            <BaseDropDownList
              data={userRoles}
              value={updatedUser.role}
              filterable={false}
              className="d-flex text-capitalize"
              onChange={(e: TODO) => setUpdatedUser({ ...updatedUser, role: e.target.value })}
            />
          </div>

          {errorMessage &&
            <ErrorMessage
              type="alert"
              errorMessage={errorMessage} />
          }
        </div>
        <DialogActionsBar>
          <button type="button" className="k-button btn btn-secondary" onClick={handleClose}>Cancel</button>
          <button type="submit" disabled={isLoading} className="k-button btn btn-primary">Update</button>
        </DialogActionsBar>
      </form>
    </Dialog>
  )
}