import { Fragment } from 'react';
import { Input } from '@progress/kendo-react-inputs';

import { BaseMultiSelect } from '../../../../../shared/Inputs';
import type { ProjectTag } from '../../../../../../interfaces/projectDetailsInterfaces/projectDetailsInterfaces';
import type { EmailProviderFormData } from '../../../../../../interfaces/emailProviderInterfaces/emailProviderInterfaces';

interface Props {
  tags: ProjectTag[] | null
  formData: EmailProviderFormData
  onChangeHandler: (e: TODO, name: string) => void
  isTwilio?: boolean
}

const listNoDataRender = () => {
  return (
    <p className="text-muted text-center h5">
      <span className="k-icon k-i-warning my-3" style={{ fontSize: '2.5em' }} />
      <br />
      <span>No tags created yet</span>
    </p>
  );
}

export const EmailProvidersGeneralSettings = ({ formData, tags, onChangeHandler, isTwilio }: Props) => {
  return (
    <Fragment>
      <div className="mb-2">
        <Input
          required
          label={'Provider name'}
          value={formData.providerName}
          onChange={(e) => onChangeHandler(e, 'providerName')} />
      </div>
      <div className="mb-2">
        <Input
          label={'Provider description'}
          type='text'
          value={formData.providerDescription ? formData.providerDescription : ""}
          onChange={(e) => onChangeHandler(e, 'providerDescription')} />
      </div>
      {!isTwilio &&
        <div className="mb-2">
          <BaseMultiSelect
            data={tags ? tags : []}
            value={formData.tags ? formData.tags : []}
            label="Tags"
            textField="name"
            dataItemKey="id"
            allowCustom={true}
            listNoDataRender={listNoDataRender}
            onChange={(e: TODO) => onChangeHandler(e, 'tags')} />
        </div>}
    </Fragment>
  )
}