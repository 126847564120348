import React from 'react';
import setButtonIcon from '../helpers/importDataHelpers/setButtonIcon/setButtonIcon';

export default ({ ques, expand, toggleQuestion }) => {
  const buttonIcon = []
  if (ques.mergedQuestions) {
    ques.mergedQuestions.forEach(el => {
      buttonIcon.push(setButtonIcon(el))
    })
  }

  return (
    <React.Fragment>
      {
        ques.mergedQuestions && expand &&
        <ul className="list-group list-group-flush bg-light">
          {
            ques.mergedQuestions.map((val, id) => (
              <li
                key={id}
                onClick={() => toggleQuestion(val.id)}
                className="d-flex list-group-item list-group-item-question bg-light gap-lg px-4">
                <span className="question-icon circle-icon circle-icon-sm">
                  <i className={buttonIcon[id]}>
                    {buttonIcon[id].includes('quantity') ? 1 : ''}
                  </i>
                </span>
                <strong>{val.id}</strong>
                <span>{val.title.length < 60 ? val.title : val.title.substring(0, 60) + '...'}</span>
              </li>
            ))
          }
        </ul>
      }
    </React.Fragment>
  );
}