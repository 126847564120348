import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import type { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";

import { BaseDropDownList } from '../../../../../../shared/Inputs';
import type { RootState } from '../../../../../../../store/reducers/rootReducer';
import { updateLanguagesList } from "../../../../../../shared/LanguagesList/updateLanguagesList";
import { fetchPatchJson, fetchGetJson as getSurveyIndex } from '../../../../../../../services/services';

interface Props {
  languages: TODO[]
  systemLanguages: TODO[]
  handleClose: () => void
  updateLanguages: () => void
}

interface Language {
  code: string
  name: string
}

export const SurveyDesignAddLanguageModal = ({ languages, systemLanguages, handleClose, updateLanguages }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const params: { name: string, survey: string } = useParams()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedSystemLanguage, setSelectedSystemLanguage] = useState<Language | null>(null)
  const [selectedSurveyLanguage, setSelectedSurveyLanguage] = useState<Language | null>(languages[0])

  const updatedLanguages: TODO[] = []
  languages.forEach((item) => {
    updatedLanguages.push({
      name: `${item.name} (${item.code})`,
      code: item.code
    })
  })

  const onChangeSystemLanguageHandler = (event: DropDownListChangeEvent) => {
    setSelectedSystemLanguage(event.target.value)
  };

  const onChangeSurveyLanguageHandler = (event: DropDownListChangeEvent) => {
    setSelectedSurveyLanguage(event.target.value)
  };

  const onAddLanguageHandler = () => {
    const body = { code: selectedSystemLanguage?.code, copyFromCode: selectedSurveyLanguage?.code }
    setIsLoading(true)
    //@ts-ignore - Suppress warning until services.js gets updated with TypeScript
    fetchPatchJson(`su/projects/${params.name}/surveys/${params.survey}/add-language`, token, body)
      .then((res: TODO) => {
        if (res && !res.error && !res.message) {
          //@ts-ignore - Suppress warning until services.js gets updated with TypeScript
          getSurveyIndex(`su/projects/${params.name}/surveys/${params.survey}/index`, token)
            .then((res: TODO) => {
              if (res && !res.error) {
                const enhancedLanguagesList = updateLanguagesList([...res.languages]) // Add language info, a compensation for backend
                dispatch({ type: 'UPDATE_SURVEY_LANGUAGES_DATA', payload: enhancedLanguagesList })
                dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: `${selectedSystemLanguage?.name} (${selectedSystemLanguage?.code}) has been added successfuly!` } })
                updateLanguages()
                handleClose()
              }
            })
        } else {
          setIsLoading(false)
        }
      })
  }

  return (
    <Dialog
      onClose={handleClose}
      title={"Add a new language"}
      width={600}
      className="d-flex flex-column w-100">
      <div className="p-4">
        <p className="m-0">
          Select a language to add
        </p>
        <BaseDropDownList
          data={systemLanguages ? systemLanguages : []}
          textField="name"
          dataItemKey="code"
          style={{ width: '100%' }}
          className="mb-3"
          value={selectedSystemLanguage}
          popupSettings={{ className: 'survey-dropdown' }}
          onChange={onChangeSystemLanguageHandler}
        />
        <BaseDropDownList
          data={[{ name: "", code: "" }].concat(updatedLanguages)}
          textField="name"
          dataItemKey="code"
          style={{ width: '100%' }}
          className="mb-3"
          label="Copy texts from (optional)"
          value={selectedSurveyLanguage}
          onChange={onChangeSurveyLanguageHandler}
          popupSettings={{ className: 'survey-dropdown' }}
        />

      </div>
      <DialogActionsBar>
        <button type='button' className="k-button btn btn-secondary " onClick={handleClose}>Cancel</button>
        <button type='button' disabled={!selectedSystemLanguage || isLoading} className="k-button btn btn-primary" onClick={onAddLanguageHandler}>
          {
            isLoading &&
            <div className="spinner-border spinner-border-sm mr-2" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          }
          <span>Add</span>
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}