import type { APIKey, CreateAPIKeyFormData } from "../../../../../../../interfaces/adminInterfaces/adminInterfaces"
import { fetchPostJson as createApiKey, fetchPutJson as updateApiKey } from "../../../../../../../services/services"

export const apiKeySubmitHandler = (formData: CreateAPIKeyFormData, apiKey: APIKey, token: string, body: { name: string, role: string }, setErrorMessage: (msg: string) => void, onHide: () => void, updateData: () => void, setIsCreating: (val: boolean) => void, setIsCreated: (val: boolean) => void, setFormData: (data: CreateAPIKeyFormData) => void) => {
  if (apiKey) {
    updateApiKey(`apikeys/${apiKey.id}`, token, body)
      .then((res: TODO) => {
        if (res.error) {
          setErrorMessage(res.error)
        } else {
          onHide()
          updateData()
        }
      })
  } else {
    createApiKey("apikeys", token, body)
      .then((res: TODO) => {
        setIsCreating(false)
        if (res.error) {
          setErrorMessage(res.error)
        } else {
          setIsCreated(true)
          setFormData({
            ...formData,
            clientId: res.clientId,
            clientSecret: res.clientSecret
          })
        }
      })
  }
}