import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ColorPicker } from "@progress/kendo-react-inputs";
import { Tooltip } from '@progress/kendo-react-tooltip';

import { Icon } from "../../../shared/Icon/Icon";
import PinboardPageModal from "../../PinboardPageModal/PinboardPageModal";
import { FullHeightSpinner } from "../../../shared/FullHeightSpinner/FullHeightSpinner";
import type { PinboardPage } from "../../../../interfaces/pinboardInterfaces/pinboardInterfaces";

import { fetchDeleteJson, fetchGetJson, fetchPatchResOrJson } from "../../../../services/services";

interface Props {
  token: string
  showSidebar: { show: boolean, type: string }
  setShowSidebar: (obj: { show: boolean, type: string }) => void
  pinboardPage: PinboardPage | undefined
}

const SidebarPageLayoutContent = ({ token, showSidebar, setShowSidebar, pinboardPage, }: Props) => {
  const dispatch = useDispatch();
  const pinboardData = useSelector((theState: TODO) => theState.pinboardStateReducer)

  const [didMount, setDidMount] = useState<boolean>(true);
  const [pageData, setPageData] = useState<PinboardPage>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [formData, setFormData] = useState({ backgroundColor: "", backgroundImage: "", title: "" });
  const [initialFormData, setInitialFormData] = useState({ backgroundColor: "", backgroundImage: "", title: "" });

  useEffect(() => {
    setDidMount(false)
    if (didMount) {
      setIsLoading(true)
      fetchGetJson(`pb/projects/${pinboardData.projectId}/pinboards/${pinboardData.pinboardId}/pages/${pinboardPage?.id}`, token)
        .then((res: TODO) => {
          if (res.error || res.message) {
            dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: res.error ? res.error : res.message } })
          } else {
            setPageData(res);
            const updatedFormData = {
              backgroundColor: res.pageStyle?.backgroundColor ? res.pageStyle.backgroundColor : "",
              backgroundImage: res.pageStyle?.backgroundImage ? res.pageStyle.backgroundImage.slice(4, res.pageStyle.backgroundImage.length - 1) : "",
              title: res.title ? res.title : "",
            }
            setFormData(updatedFormData)
            setInitialFormData(JSON.parse(JSON.stringify(updatedFormData)))
            setIsLoading(false);
          }
        })
    }
  }, [didMount, dispatch, pinboardData.pinboardId, pinboardData.projectId, pinboardPage, token])

  const confirmDeleteHandler = () => {
    fetchDeleteJson(`pb/projects/${pinboardData.projectId}/pinboards/${pinboardData.pinboardId}/pages/${pinboardPage?.id}`, token,)
      .then((res: TODO) => {
        if (res.error || res.message) {
          dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: res.error ? res.error : res.message } })
        } else {
          return res.json()
            .then((pinboardRes: TODO) => {
              const updatedPages = [...pinboardData.pages]

              if (pinboardPage?.parentPageId === null) {
                const pageIndex = updatedPages.findIndex(el => el.id === pinboardPage?.id)
                updatedPages.splice(pageIndex, 1)
              } else {
                const parentIndex = updatedPages.findIndex(el => el.id === pinboardPage?.parentPageId)
                const pageIndex = updatedPages[parentIndex].subpages.findIndex((el: TODO) => el.id === pinboardPage?.id)
                updatedPages[parentIndex].subpages.splice(pageIndex, 1)
              }

              if (pinboardData.activePage?.id === pinboardPage?.id) {
                const pinboardPayload = { ...pinboardData, _etag: pinboardRes._etag, pages: updatedPages }
                dispatch({ type: "SET_PINBOARD_DATA", payload: { ...pinboardPayload } })
                if (updatedPages.length > 0) {
                  fetchGetJson(`pb/projects/${pinboardData.projectId}/pinboards/${pinboardData.pinboardId}/pages/${updatedPages[0].id}`, token)
                    .then((pageRes: TODO) => {
                      if (pageRes.error || pageRes.message) {
                        dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: pageRes.error ? pageRes.error : pageRes.message } })
                      } else {
                        dispatch({ type: "SET_ACTIVE_PINBOARD_PAGE", payload: { page: pageRes, reload: true } })
                      }
                    })
                }
              } else {
                const pinboardPayload = { ...pinboardData, _etag: pinboardRes._etag, pages: updatedPages }
                dispatch({ type: "SET_PINBOARD_DATA", payload: { ...pinboardPayload } })
              }
              setShowSidebar({ show: true, type: "pages" })
              setShowDeleteModal(false)
            })
        }
      })
  }

  const saveHandler = () => {
    if (pageData) {
      const body = {
        title: formData.title,
        parentPageId: pageData.parentPageId,
        pageStyle: {
          backgroundColor: formData.backgroundColor,
          backgroundImage: `url(${formData.backgroundImage})`,
        },
        pageEtag: pageData._etag
      }

      fetchPatchResOrJson(`pb/projects/${pinboardData.projectId}/pinboards/${pinboardData.pinboardId}/pages/${pinboardPage?.id}`, token, body)
        .then((res: TODO) => {
          if (res.error || res.message) {
            dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: res.error ? res.error : res.message } })
          } else {
            res.json().then((result: TODO) => {

              const updatedPages = [...pinboardData.pages]

              setPageData({ ...pageData, _etag: result.page._etag })
              setInitialFormData(JSON.parse(JSON.stringify(formData)))

              if (pinboardData.activePage.id === pageData.id) {
                dispatch({ type: "SET_ACTIVE_PINBOARD_PAGE", payload: { page: result.page, reload: false } })
              }

              if (pageData.parentPageId === null) {
                const pageIndex = updatedPages.findIndex(el => el.id === pageData.id)
                updatedPages[pageIndex].title = formData.title
                dispatch({ type: "UPDATE_PINBOARD_PAGES", payload: updatedPages })
              } else {
                const parentIndex = updatedPages.findIndex(el => el.id === pageData.parentPageId)
                const pageIndex = updatedPages[parentIndex].subpages.findIndex((el: TODO) => el.id === pageData.id)
                updatedPages[parentIndex].subpages[pageIndex].title = formData.title
                dispatch({ type: "UPDATE_PINBOARD_PAGES", payload: updatedPages })
              }

              if (result.pinboard?._etag) {
                // This exists because if the title is updated, then we get pinboard in the result with
                // a new pinboard _etag, which we need to update in our frontend state
                dispatch({ type: "UPDATE_PINBOARD_ETAG", payload: result.pinboard._etag })
              }

            })
          }
        })
    }
  }

  return (
    <Fragment>
      {showDeleteModal &&
        <PinboardPageModal
          onHide={() => setShowDeleteModal(false)}
          onConfirm={confirmDeleteHandler}
          type={"Delete"}
          originalTitle={pageData?.title}
        />
      }
      <div className="header border-bottom flex-shrink-0">
        <div className="d-flex align-items-center">
          <button type="button" className="btn btn-transparent p-1" onClick={() => setShowSidebar({ show: true, type: "pages" })}><Icon type="chevron-left" /></button>
          <span>Edit board</span>
        </div>
        <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
          <button type="button" className="btn btn-transparent p-1" onClick={() => setShowSidebar({ show: false, type: showSidebar.type })} title="Close"><Icon type="close" className="pe-none" /></button>
        </Tooltip>
      </div>
      <div className="page-layout p-0">
        {!isLoading ?
          <Fragment>
            <section>
              <div className="flex-column align-items-start">
                <span className="mb-2">Title</span>
                <input type="text" className="sidebar-input form-control" placeholder="Enter board title" value={formData.title} onChange={(e) => setFormData({ ...formData, title: e.target.value })} />
              </div>
            </section>
            <hr className="w-100 m-0" />
            <section>
              <div className="strong mb-3">Background</div>
              <div className="mb-3">
                <span>Background color</span>
                <div className="color-picker">
                  <ColorPicker view="gradient" fillMode="flat" rounded="full" gradientSettings={{ opacity: false, }} value={formData.backgroundColor} onChange={(e) => setFormData({ ...formData, backgroundColor: e.value })} />
                </div>
              </div>
              <div className="flex-column align-items-start">
                <span className="mb-2">Background image</span>
                <input type="text" className="sidebar-input form-control" placeholder="http://" value={formData.backgroundImage} onChange={(e) => setFormData({ ...formData, backgroundImage: e.target.value })} />
              </div>
            </section>
            <hr className="w-100 m-0" />
            <section>
              <div>
                <button type="button" className="btn shadow-none text-danger d-flex align-items-center p-0" onClick={() => setShowDeleteModal(true)}><Icon type="delete-alt" className="fill-danger mr-1" />Delete board</button>
              </div>
            </section>
          </Fragment>
          :
          <FullHeightSpinner />
        }
      </div>
      <footer className="d-flex self-align-end flex-shrink-0">
        <button type="button" className="btn btn-secondary w-50 justify-content-center rounded-0 p-2" onClick={() => setFormData({ backgroundColor: "", backgroundImage: "", title: formData.title })}>Restore default</button>
        <button type="button" className="btn btn-primary w-50 justify-content-center rounded-0 p-2" disabled={isLoading || JSON.stringify(formData) === JSON.stringify(initialFormData)} onClick={saveHandler}>Save</button>
      </footer>
    </Fragment>
  )
}

export default SidebarPageLayoutContent;