import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Editor, EditorTools, EditorUtils, ProseMirror } from "@progress/kendo-react-editor";
import { v4 as uuid } from 'uuid';

import ScriptModal from "../../../helpers/ScriptModal/ScriptModal";
import PipingModal from "../../../helpers/PipingModal/PipingModal";
import { ErrorMessage } from "../../../../../../shared/ErrorMessage/ErrorMessage";
import { ImageUploadModal } from "../../../helpers/ImageUploadModal/ImageUploadModal";
import { returnQuestionsData } from "../../../../../../Analysis/Analyze/components/shared/helpers/FilterBuilderHelpers/FilterBuilderHelpers";
import { fetchGetJson as getSurveyStructure } from "../../../../../../../services/services";

export default ({ token, content, type, index, subQuesIndex, onEditInline, onInlineChange, disabled, editingElement, onUpdateElementTextData, getChanges, parentType, parentIndex }) => {
  const { theData } = useSelector(theState => (theState.surveyInitialDataReducer))
  const [state, setState] = useState({ view: undefined, showTools: false })
  const [value, setValue] = useState(content)
  const [errorMessage, setErrorMessage] = useState(null)
  const [imageSelected, setImageSelected] = useState({ selected: false, attributes: null })
  const [pipingModal, setPipingModal] = useState({ show: false, editingElement: null, data: null, ref: null })
  const [imageUploadModal, setImageUploadModal] = useState({ show: false, ref: null, editingElement: null })
  const [scriptModal, setScriptModal] = useState({ show: false, ref: null, editingElement: null })
  const { Bold, Italic, Underline } = EditorTools;
  const dispatch = useDispatch()
  const activeRef = useRef()

  useEffect(() => {
    if (activeRef.current !== null && state.view !== undefined && state.view !== null) {
      dispatch({ type: 'SURVEY_SET_LAST_EDITING_CONTENT', payload: EditorUtils.getHtml(activeRef.current.view.state) })
    }
  }, [state, dispatch])

  useEffect(() => {
    if (content !== value) {
      setValue(content)
    }
  }, [value, content])

  useEffect(() => {
    if (state.view && state.view.lastSelectedViewDesc && state.view.lastSelectedViewDesc.node.type.name === "image") {
      setImageSelected({ selected: true, attributes: state.view.lastSelectedViewDesc.node.attrs })
    } else {
      setImageSelected({ selected: false, attributes: null })
    }
  }, [state])

  const selectionToolsPlugin = () => {
    let plugin = new ProseMirror.Plugin({
      key: new ProseMirror.PluginKey("selection-tools"),
      view: () => ({
        update: (view, _prevState) => {
          setState({ ...state, view: view, showTools: view.focused });
        }
      })
    })
    return plugin
  }

  const placeholder = (emptyMessage) => {
    let placeholder = new ProseMirror.Plugin({
      key: new ProseMirror.PluginKey('placeholder'),
      props: {
        decorations: (state) => {
          const { doc } = state;
          const empty = doc.textContent === '' && doc.childCount <= 1 && doc.content.size <= 2;

          if (!empty) {
            return ProseMirror.DecorationSet.empty;
          }

          const decorations = [];
          const decAttrs = { class: 'placeholder', 'data-placeholder': emptyMessage };

          doc.descendants((node, pos) => {
            decorations.push(ProseMirror.Decoration.node(pos, pos + node.nodeSize, decAttrs));
          });

          return ProseMirror.DecorationSet.create(doc, decorations);
        }
      }
    })
    return placeholder
  }

  const styles =
    `p.placeholder:first-child:before {
        content: attr(data-placeholder);
        float: left;
        color: rgb(0, 0, 0, 0.5);
        cursor: text;
        opacity:90% !important;
        height: 0;
        font-style: italic;
    }`;

  const onMount = (event) => {
    const state = event.viewProps.state
    const plugins = [...state.plugins, selectionToolsPlugin(), placeholder(`Enter${type === 'stext' ? ' question ' : ' '}text...`)]
    const documnt = event.dom.ownerDocument;
    if (documnt.querySelector('style')) {
      documnt.querySelector('style').appendChild(documnt.createTextNode(styles))
    }
    return new ProseMirror.EditorView(
      { mount: event.dom },
      {
        ...event.viewProps,
        state: ProseMirror.EditorState.create({ schema: state.schema, doc: state.doc, plugins }),
      }
    )
  }

  const checkFirstQuestion = () => {
    let first = false
    if (theData.selectedItem.section === theData.data[0].index) {
      let questionData = theData.data[0].elements.filter(el => el.type === 'ques')
      if (questionData.findIndex(el => el.id === theData.selectedItem.id) === 0) {
        first = true
      } else if (theData.data[0].elements.findIndex(el => el.id === theData.selectedItem.id) === 0) {
        first = true
      }
      else { first = false }
    }
    return first
  }

  const getEditorContent = () => {
    if (activeRef.current !== undefined) {
      setValue(EditorUtils.getHtml(activeRef.current.view.state))
      onInlineChange(EditorUtils.getHtml(activeRef.current.view.state));
    }
  }

  const onOpenPipingModal = (editingElement, ref) => {
    getSurveyStructure(`su/projects/${theData.originalSelectedItem.projectId}/surveys/${theData.originalSelectedItem.surveyId}/structure`, token)
      .then(res => {
        if (res && res.error) {
          setErrorMessage(res.message ? res.message : res.error)
        } else if (res) {
          let data = { ...res, questions: [] }
          res.questions.forEach((el, key) => {
            let index
            if (editingElement.type === 'info') {
              let item = null
              let lastItem = null
              theData.data.forEach(section => {
                section.elements.forEach(sectionEl => {
                  if (sectionEl.id === theData.selectedItem.id) {
                    lastItem = item
                  } else if (sectionEl.type === 'ques') {
                    item = sectionEl
                  }
                })
              })
              index = res.questions.findIndex(el => el.qno.slice(0, -2) === lastItem.label) + 1
            }
            else {
              index = res.questions.findIndex(el => el.qno.slice(0, -2) === theData.selectedItem.label)
            }
            if (key < index) {
              data.questions.push(el)
            }
          })
          const questions = returnQuestionsData(data)
          setPipingModal({ show: true, editingElement: editingElement, data: questions, ref: ref })
        }
      })
  }

  const onPipeText = (data) => {
    EditorUtils.insertNode(pipingModal.ref.view, pipingModal.ref.view.state.schema.text(data))
    onUpdateElementTextData(EditorUtils.getHtml(pipingModal.ref.view.state), pipingModal.editingElement)
    setPipingModal({ show: false, editingElement: editingElement, data: null, ref: null })
  }

  const onImageUpload = (settings) => {
    let id = uuid()
    const data = `${EditorUtils.getHtml(imageUploadModal.ref.view.state)} <img src="${settings.url}"  ${settings.width !== null && settings.width !== 0 && `width="${settings.width}"`} ${settings.height !== null && settings.height !== 0 && `height="${settings.height}"`} alt="img" id=${id} />`
    EditorUtils.setHtml(imageUploadModal.ref.view, data)
    onUpdateElementTextData(data, imageUploadModal.editingElement, parentType, parentIndex)
    setImageUploadModal({ show: false, ref: null, editingElement: null })
  }

  const onImageEdit = (settings) => {
    let html = EditorUtils.getHtml(imageUploadModal.ref.view.state)
    let doc = new DOMParser().parseFromString(html, "text/html")
    doc.getElementById(imageSelected.attributes.id).src = settings.url
    if (settings.height !== null && settings.height !== 0) {
      doc.getElementById(imageSelected.attributes.id).height = settings.height
    }
    if (settings.width !== null && settings.width !== 0) {
      doc.getElementById(imageSelected.attributes.id).width = settings.width
    }
    const data = doc.body.innerHTML
    EditorUtils.setHtml(imageUploadModal.ref.view, data)
    onUpdateElementTextData(data, imageUploadModal.editingElement, parentType, parentIndex)
    setImageUploadModal({ show: false, ref: null, editingElement: null })
    setImageSelected({ selected: false, attributes: null })
  }

  const updateScript = (content) => {
    EditorUtils.setHtml(scriptModal.ref.view, content)
    onUpdateElementTextData(EditorUtils.getHtml(scriptModal.ref.view.state), scriptModal.editingElement)
    setScriptModal({ show: false, ref: null, editingElement: null })
  }


  return (
    <div className="d-flex w-100 position-relative" style={{ "pointerEvents": disabled ? "none" : "all" }}>
      {
        errorMessage &&
        <ErrorMessage
          type="modal"
          errorMessage={errorMessage}
          onHide={() => setErrorMessage(null)} />
      }
      {
        pipingModal.show &&
        <PipingModal
          handleClose={() => setPipingModal({ show: false, editingElement: null, data: null, ref: null })}
          data={pipingModal.data}
          onPipeText={onPipeText}
        />
      }
      {imageUploadModal.show &&
        <ImageUploadModal
          handleClose={() => setImageUploadModal({ show: false, ref: null, editingElement: null })}
          onImageUpload={onImageUpload}
          onImageEdit={onImageEdit}
          image={imageSelected.attributes}
          token={token}
        />
      }{
        scriptModal.show &&
        <ScriptModal
          handleClose={() => setScriptModal({ show: false, ref: null, editingElement: null })}
          type="html"
          value={EditorUtils.getHtml(scriptModal.ref.view.state)}
          updateScript={updateScript}
        />
      }
      {/* <Editor ref={type !== 'text' ? editingElement && editingElement.type === type && editingElement.subQuesIndex === subQuesIndex && editingElement.index === index ? activeRef : null :
        editingElement.index === index ? activeRef : null} */} {/* Unsure what this logic was for, so it's commented instead of deleted */}
      <Editor
        ref={activeRef}
        className={`inline-editor w-100 question-text ${state.showTools ? '' : null}`}
        contentStyle={{ height: "fit-content" }}
        defaultContent={value}
        defaultEditMode="div"
        onMount={(e) => onMount(e)}
        onFocus={() => { onEditInline(content, type, subQuesIndex, index); setState({ ...state, showTools: true }) }}
        onBlur={() => { getEditorContent(); setState({ ...state, showTools: false }); }}
      />
      {
        state.showTools &&
        <span
          className={`bg-white border shadow-sm ${type === 'stext' && theData.selectedItem.elements.filter(el => el.type === 'subq').length > 1 ? 'custom-tooltip' : 'custom-tooltip'}`}>
          <ButtonGroup className="btn-group" tabIndex={-1}>
            <Bold view={state.view} className="btn btn-sm btn-outline-primary border-0" tabIndex={-1} />
            <Italic view={state.view} className="btn btn-sm btn-outline-primary border-0" tabIndex={-1} />
            <Underline view={state.view} className="btn btn-sm mr-2 btn-outline-primary border-0" tabIndex={-1} />
            <Button className="btn btn-outline-primary btn-sm border-0 k-button k-button-icon"
              icon="k-icon k-i-html"
              tabIndex={-1}
              onMouseDown={(e) => { e.preventDefault(); setScriptModal({ show: true, editingElement: editingElement, ref: activeRef.current }) }}>
            </Button>
            <button className={`btn btn-sm border-0 k-button k-button-icon ${imageSelected.selected ? "btn-primary" : " btn-outline-primary "}`} tabIndex="-1"
              onMouseDown={(e) => { e.preventDefault(); setImageUploadModal({ show: true, editingElement: editingElement, ref: activeRef.current }) }}>
              <i className="fas fa-image p-0"></i>
            </button>
            {!checkFirstQuestion() ?
              <button className="btn btn-outline-primary btn-sm border-0 k-button k-button-icon" tabIndex="-1"
                onMouseDown={(e) => { e.preventDefault(); onOpenPipingModal(editingElement, activeRef.current) }}>
                <i className="fas fa-code-branch p-0"></i>
              </button> : null}
          </ButtonGroup>
        </span>
      }
    </div >
  )
}
