export const updateEditedTextField = (body) => {
  const copy = JSON.parse(JSON.stringify(body));

  const updateField = (copy) => {
    const field = "editedText";
    if (copy.hasOwnProperty(field)) {
      copy[field] = null;
    }
    for (let x in copy) {
      if (typeof copy[x] === "object" && copy[x] !== null) {
        if (Array.isArray(copy[x])) {
          copy[x].forEach(prop => updateField(prop))
        } else {
          updateField(copy[x])
        }
      }
    }
  };

  updateField(copy)
  return copy
}


