const initialState = {
  subAccounts: [],
  regularAccounts: [],
  invalidSubAccountUsers: [],
  invalidRegularUsers: [],
  usersTabSelected: 0,
  showDeleteUserModal: {
    show: false,
    account: '',
    type: ''
  }
};

function projectExternalShareStateReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_SHARE_PROJECT_SUB_ACCOUNTS':
      return {
        ...state,
        subAccounts: action.payload
      };
    case 'SET_SHARE_PROJECT_REGULAR_ACCOUNTS':
      return {
        ...state,
        regularAccounts: action.payload
      };
    case 'RESET_PROJECT_SHARING_ACCOUNTS':
      return {
        ...state,
        subAccounts: [],
        regularAccounts: []
      }
    case 'UPDATE_SHOW_DELETE_SHARED_USERS_MODAL':
      return {
        ...state,
        showDeleteUserModal: action.payload
      }
    case 'UPDATE_INVALID_SHARED_USERS':
      return {
        ...state,
        invalidRegularUsers: action.payload.invalidRegularUsers,
        invalidSubAccountUsers: action.payload.invalidSubAccountUsers
      }
    case 'UPDATE_USERS_TAB_SELECTED':
      return {
        ...state,
        usersTabSelected: action.payload,
      }
    default:
      return state;
  }
}

export default projectExternalShareStateReducer;