import { Link } from 'react-router-dom';

import { Icon } from '../../../shared/Icon/Icon';
import knowledgeBaseData from '../knowledgeBaseData.json';
import type { Type } from '../../../../interfaces/iconInterfaces/iconInterfaces';

type knowledgeBaseItem = {
  title: string;
  description: string;
  icon: Type;
  url: string;
}

export const DashboardKnowledgeBase = () => {
  const knowledgeBaseDataArray = knowledgeBaseData as knowledgeBaseItem[];

  return (
    <div className="col-lg-12 help-bottom">
      <div className="dashboard-card help gap-xxl">
        <p className="title m-0 h5">
          <strong>Knowledge base</strong>
        </p>
        <div className="row">
          {
            knowledgeBaseDataArray.map((item, key) => (
              <Link
                key={key}
                to={{ pathname: item.url }}
                target="_blank"
                className="col-lg-3 d-flex flex-column gap-md"
                rel="noopener noreferrer">
                <div
                  style={{ height: "48px", width: "48px" }}
                  className="rounded-circle bg-light d-flex justify-content-center align-items-center text-primary">
                  <Icon type={item.icon} />
                </div>
                <h5 className="sub m-0">
                  <strong>{item.title}</strong>
                </h5>
                <p className="text-muted m-0">{item.description}</p>
              </Link>
            )
            )}
        </div>
      </div>
    </div>
  )
}