import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@progress/kendo-react-buttons';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { Icon } from '../../../../../../shared/Icon/Icon';
import { checkCampaignUpdates } from '../../../helpers/helpers';
import { ErrorMessage } from '../../../../../../shared/ErrorMessage/ErrorMessage';
import { FullHeightSpinner } from '../../../../../../shared/FullHeightSpinner/FullHeightSpinner';
import { CreateAudienceModal } from '../../../helpers/CreateAudienceModal/CreateAudienceModal';
import { fetchPutJson as updateCampaignStatus, fetchPutResOrJson as cancelScheduledCampaign } from '../../../../../../../services/services'

export default ({ token, campaign, updateCampaign, onComposeClick, updateCampaignState, onDeleteInEditMode }) => {
  const dispatch = useDispatch();
  const [campaignData, setCampaignData] = useState(campaign)
  const [editModal, setEditModal] = useState(false)
  const [localSendoutDate, setLocalSendoutDate] = useState(null)
  const [sentEmail, setSentEmail] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [error, setError] = useState({ show: false, message: '' })
  const [didMount, setDidMount] = useState(true)

  useEffect(() => {
    if (didMount) {
      if (campaign) {
        setDidMount(false)
        setCampaignData(campaign)
        if (campaign?.sendoutUtc) {
          const currentDate = new Date()
          const localDate = new Date(campaign.sendoutUtc)
          if (currentDate.toISOString() > localDate.toISOString() && campaign.isProcessed) {
            setSentEmail(true)
          }
          setLocalSendoutDate(`${localDate.toString().slice(0, 24)}`)
        }
      }
    }
  }, [didMount, localSendoutDate, campaign])

  const copyLink = () => {
    var copyText = document.getElementById("externalLinkInput")
    copyText.select()
    copyText.setSelectionRange(0, 99999)
    document.execCommand("copy")
    dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "Link has been copied to clipboard" } })
  }

  const onChangeCampaignStatus = (value) => {
    const body = { disabled: value }
    updateCampaignStatus(`projects/${campaignData.projectId}/surveys/${campaignData.surveyId}/campaigns/${campaignData.id}`, token, body)
      .then(res => {
        if (res && res.disabled === value) {
          setCampaignData(res)
          updateCampaign()
        }
      })
  }

  const onCancelScheduledEmail = () => {
    cancelScheduledCampaign(`projects/${campaignData.projectId}/surveys/${campaignData.surveyId}/campaigns/${campaign.type === "email-campaign" ? "email" : "sms"}/${campaignData.id}/cancel`, token)
      .then(res => {
        if (res && res.status === 200) {
          setError({ ...error, show: false })
          updateCampaignState()
        } else {
          setError({ show: true, message: res.message ? res.message : res.error })
        }
      })
  }

  return (
    <div className="d-flex flex-column flex-grow-1">
      {cancelModal &&
        <Dialog title="Cancel scheduled campaign" onClose={() => setCancelModal(false)} >
          <div className="p-4">
            <div className="alert alert-light-blue shadow-sm mb-3 d-flex p-3 align-items-center">
              <i className="fas fa-info-circle mr-3" />
              <span>By clicking <strong>cancel</strong>, the scheduled sending time will be canceled.</span>
            </div>
            {error.show &&
              <ErrorMessage type="alert" errorMessage={error.message} />}
          </div>
          <DialogActionsBar>
            <Button className="btn btn-danger" onClick={() => onCancelScheduledEmail()}>Cancel</Button>
          </DialogActionsBar>
        </Dialog>
      }
      {
        editModal &&
        <CreateAudienceModal
          editType={campaign.type}
          updateData={() => updateCampaign()}
          handleClose={() => setEditModal(false)}
          editFormData={{ name: campaignData.name, description: campaignData.description, tags: campaignData.tags, id: campaignData.id }}
        />
      }
      {!campaignData ?
        <FullHeightSpinner /> :
        <div className="d-flex flex-column h-100">
          <div className="d-flex align-items-center justify-content-between border-bottom p-4 h-64">
            <div className="d-flex align-items-center">
              <Switch checked={!campaignData.disabled} onChange={(e) => onChangeCampaignStatus(!e.value)} size="small" />
              <span className="mb-0 ml-2">Active</span>
            </div>
            <div className="d-flex align-items-center">
              <button
                onClick={() => setEditModal(true)}
                className="btn btn-secondary btn-icon icon-l">
                <Icon type="dashboard-edit" className='mr-05' />
                Edit
              </button>
              <button
                onClick={onDeleteInEditMode.bind(null, campaign)}
                className="btn btn-secondary btn-icon icon-l ml-2">
                <Icon type="delete-alt" className='mr-05' />
                Delete
              </button>
            </div>
          </div>
          {campaignData.type === "link-campaign" &&
            <div className="d-flex p-4">
              <div className="input-group">
                <Input value={campaignData.url}
                  className="form-control" readOnly
                  id="externalLinkInput">
                </Input>
                <div className="input-group-append">
                  <Button className="btn btn-primary" onClick={() => copyLink()}>Copy link</Button>
                </div>
              </div>
            </div>}
          {campaignData.type !== 'link-campaign' && !checkCampaignUpdates(campaignData) ?
            <div className="d-flex edit-section align-items-center justify-content-center flex-grow-1">
              <div className="d-flex flex-column align-items-center">
                <i className="fas fa-envelope fa-4x mb-3 droppable-text" />
                <h5 className="mb-4">
                  There is no {campaignData.type === 'email-campaign' ? 'email body' : 'SMS-text'} or send settings in this invite.
                </h5>
                <Button className="btn btn-primary" onClick={() => onComposeClick()}>
                  Compose {campaignData.type === 'email-campaign' ? 'email' : 'SMS'}
                </Button>
              </div>
            </div>
            : campaignData.type !== 'link-campaign' ?
              <div className="d-flex edit-section flex-grow-1 flex-column p-4">
                <div className="w-100 align-self-start">
                  <div className="p-4 d-flex justify-content-between survey-main border align-items-center">
                    <div className="d-flex flex-column w-25">

                      <h6 className="mb-1">Status</h6>
                      {campaign.isScheduled && !sentEmail ?
                        <h6 className="mb-0 text-analyze">
                          Scheduled
                        </h6> : campaignData.isScheduled && sentEmail ?
                          <h6 className="mb-0 text-success">
                            Sent
                          </h6>
                          :
                          <h6 className="mb-0 text-analyze">
                            Edited
                          </h6>}
                    </div>
                    {campaignData.subject &&
                      <div className="d-flex flex-column w-25">
                        <h6 className="mb-1">Email subject</h6>
                        <h6 className="mb-0 audience-desc">{campaignData.subject}</h6>
                      </div>}
                    {localSendoutDate &&
                      <div className="d-flex flex-column w-25">
                        <h6 className="mb-1">Send time</h6>
                        <h6 className="mb-0 audience-desc">{localSendoutDate} </h6>
                      </div>}
                    {campaignData.sampleCampaignSettings &&
                      <div className="d-flex flex-column w-25">
                        <h6 className="mb-1">Send to</h6>
                        <h6 className="mb-0 audience-desc">
                          {campaignData.sampleCampaignSettings.sendTarget} from chosen respondent lists
                        </h6>
                      </div>
                    }
                    {campaign.isScheduled && !sentEmail ?
                      <Button className="btn btn-danger" onClick={() => setCancelModal(true)}>Cancel</Button>
                      : !campaign.isScheduled ?
                        <Button className="btn btn-primary" onClick={() => onComposeClick()}>Continue editing</Button>
                        : null
                    }
                  </div>
                </div>
              </div> : null}
        </div>
      }
    </div>
  )
}