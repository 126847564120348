import { useEffect } from "react"
import { useDispatch } from "react-redux";

import usePrevious from "./usePrevious"
import { fetchGetJson as getEmailProvider } from "../../../services/services"
import type { EmailProvider, EmailProviderResponse } from "../../../interfaces/emailProviderInterfaces/emailProviderInterfaces"

export const useUpdateEmailProvidersFormData = (token: string, setFormData: (formData: TODO) => void, emailProvider?: EmailProvider | null) => {
  const dispatch = useDispatch();
  const prevEmailProvider = usePrevious(emailProvider)

  useEffect(() => {
    if (emailProvider && emailProvider !== prevEmailProvider) {
      getEmailProvider(`emailproviders/${emailProvider.id}`, token)
        .then((res: EmailProviderResponse) => {
          if (res && !res.error && !res.message) {
            let updatedFormData = null
            if (res.providerType === 'smtp') {
              updatedFormData = {
                password: '',
                newPassword: '',
                confirmNewPassword: '',
                tags: res.tags,
                relay: res.smtpRelay,
                providerName: res.name,
                SSL: res.smtpEnableSsl,
                username: res.smtpUsername,
                port: res.smtpPort.toString(),
                providerDescription: res.description
              }
            } else if (res.providerType === 'sendgrid') {
              updatedFormData = {
                tags: res.tags,
                providerName: res.name,
                providerDescription: res.description
              }
            } else {
              updatedFormData = { providerName: '', providerDescription: '', tags: [], relay: '', port: '', username: '', password: '', newPassword: '', confirmNewPassword: '', SSL: false }
            }
            setFormData(updatedFormData)
          } else {
            if (res.error || res.message) {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `Error fetching email provider data: ${res.error || res.message}` } })
            }
          }
        })
    }
  }, [token, emailProvider, prevEmailProvider, setFormData, dispatch])
}