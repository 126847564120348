import { useState } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import type { RootState } from '../../../../../store/reducers/rootReducer';
import { ErrorMessage } from '../../../../shared/ErrorMessage/ErrorMessage';
import { fetchDeleteJson as deleteGroup } from '../../../../../services/services';
import type { Group } from '../../../../../interfaces/adminInterfaces/adminInterfaces';

type Props = {
  groups: Group[] | null;
  group?: Group | null;
  handleClose: () => void;
  updateGroups: (data: Group[]) => void;
}

export const DeleteGroupModal = ({ handleClose, group, groups, updateGroups }: Props) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [errorMessage, setErrorMessage] = useState("")
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = () => {
    if (group?.id) {
      setIsLoading(true);
      deleteGroup(`groups/${group.id}`, token)
        .then((res: TODO) => {
          setIsLoading(false);
          if (res && res.status === 204) {
            if (groups !== null) {
              const updatedGroups = [...groups]
              const matchedGroup = updatedGroups.find(item => item.id === group.id)
              if (matchedGroup) {
                updatedGroups.splice(updatedGroups.indexOf(matchedGroup), 1)
                dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The group has been deleted successfully' } })
                updateGroups(updatedGroups)
                handleClose()
              }
            }
          } else if (res && (res.message || res.error)) {
            setErrorMessage(res.message ? res.message : res.error)
          }
        })
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      title="Delete group"
      className="d-flex flex-column w-100"
      width="30%">
      <div className="p-3">
        <p>
          Confirm the deletion of the group by typing the group <strong>name</strong> in the input field
        </p>
        <Input
          required
          onChange={e => setName(typeof e.target.value === "string" ? e.target.value : "")}
          value={name}
          placeholder="Enter group name"
          name="groupName"
          className="w-100" />
        {
          errorMessage &&
          <div className="mt-4">
            <ErrorMessage
              type="alert"
              errorMessage={errorMessage} />
          </div>
        }
      </div>
      <DialogActionsBar>
        <button type='button' className="k-button btn btn-secondary" onClick={handleClose}>Cancel</button>
        <button type='button' className="k-button btn-danger" onClick={onSubmitHandler} disabled={group?.name !== name || isLoading}>Delete</button>
      </DialogActionsBar>
    </Dialog>
  )
}
