import type { QuestionHierarchy } from '../../../../../../../interfaces/analysisInterfaces/analysisInterfaces';

interface Props {
  hierarchy: QuestionHierarchy[]
  checkboxChecked: boolean
}

export const DraggableItemHierarchyItems = ({ hierarchy, checkboxChecked }: Props) => {
  if (hierarchy) {
    if (hierarchy.length > 3) {
      return (
        <div className="d-flex flex-wrap">
          <span className={`badge badge-pill mr-1 ${checkboxChecked ? 'alert-light text-analyze' : 'badge-secondary text-muted'}`} style={{ border: "1px solid rgb(235,235,235)" }}>
            {hierarchy[0].id}
          </span>
          <span className={`badge badge-pill mr-1 ${checkboxChecked ? 'alert-light text-analyze' : 'badge-secondary text-muted'}`} style={{ border: "1px solid rgb(235,235,235)" }}>
            {hierarchy[1].id}
          </span>
          <i className={`fas fa-ellipsis-h pt-1 mt-2 ${!checkboxChecked && 'text-primary'}`} />
        </div>
      )
    }
    return (
      <div className="d-flex flex-wrap">
        {
          hierarchy.map((item, key) => (
            <span key={key} className={`badge badge-pill mr-1 ${checkboxChecked ? 'alert-light text-analyze' : 'badge-secondary text-muted'}`} style={{ border: "1px solid rgb(235,235,235)" }}>
              {item.id}
            </span>
          ))
        }
      </div>
    )
  }
  return null
}