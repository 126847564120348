import type { Dispatch, UnknownAction } from "redux"
import type { PinboardPage } from "../../interfaces/pinboardInterfaces/pinboardInterfaces"
import { apiPanelURL, fetchDelete } from "../../services/services"

export const GridItemDefaultOptions = {
  title: "",
  showTitle: true,
  backgroundColor: "#FFFFFF",
  borderColor: "#C0CED3",
  textColor: "#172732",
  borderRadius: 8,
  borderWidth: 1,
  padding: 16,
  boxShadow: true
}

export const QuillToolbar = [
  [{ header: [1, 2, 3, 4, false] }],
  ["bold", "italic", "underline"],
  [{ list: "ordered" }, { list: "bullet" }],
  ["link", "image"],
  [{ color: [] }, { background: [] }],
  [{ align: [] }, "clean"]
]

export const PinboardAppMenuItems: TODO = [
  {
    "title": "Go to Project",
    "action": "goToProject"
  },
  {
    "title": "separator",
    "action": "separator"
  },
  {
    "title": "Edit",
    "action": "editPinboard"
  },
  {
    "title": "Export",
    "action": "exportPinboard"
  },
  {
    "title": "separator",
    "action": "separator"
  },
  {
    "title": "Recent projects",
    "action": "recentProjects",
    "expanded": false,
    "subItems": []
  },
  {
    "title": "Recent activity",
    "action": "recentActivity",
    "expanded": false,
    "subItems": []
  },
  {
    "title": "separator",
    "action": "separator"
  },
  {
    "title": "Go to Home",
    "action": "goToHome"
  },
  {
    "title": "separator",
    "action": "separator"
  },
  {
    "title": "Delete",
    "action": "delete"
  }
]

export const returnTabTitle = (tab: string) => {
  switch (tab) {
    case "xtState":
      return { title: "Cross Table", frontendType: "crosstab", backendType: "xt", stateName: "xtState" }
    case "toplineState":
      return { title: "Topline", frontendType: "topline", backendType: "topline", stateName: "toplineState" }
    case "chartState":
      return { title: "Chart", frontendType: "chart", backendType: "g", stateName: "chartState" }
    case "dataState":
      return { title: "Data", frontendType: "rawData", backendType: "data", stateName: "dataState" }
    case "digState":
      return { title: "Dig", frontendType: "dig", backendType: "dig", stateName: "digState" }
    case "qTableState":
      return { title: "Q Table", frontendType: "qtable", backendType: "qtable", stateName: "qTableState" }
    default:
      return { title: "", frontendType: "", backendType: "", stateName: "" }
  }
}

export const returnAnalysisType = (table: string) => {
  if (table.includes("divTopline")) {
    return "topline"
  }
  if (table.includes("divXt")) {
    return "crosstab"
  }
  if (table.includes("divDig")) {
    return "digData"
  }
  return "crosstab"
}

export const reorganizePinboardPages = (pages: TODO) => {
  // Splits parentPages and subpages, and then pushes the subpages in the correct parent page
  // Adds expanded: boolean and subpages: [] to each parentPage

  const parentPages = pages.filter((page: PinboardPage) => !page.parentPageId)
  parentPages.map((page: PinboardPage) => {
    page.expanded = false; page.subpages = []
    return page
  })

  const subpages = pages.filter((page: PinboardPage) => page.parentPageId)
  if (subpages?.length > 0) {
    subpages.forEach((subpage: PinboardPage) => {
      if (pages.some((el: TODO) => el.id === subpage.parentPageId)) {
        const parentIndex = parentPages.findIndex((el: PinboardPage) => el.id === subpage.parentPageId)
        if (parentPages[parentIndex].subpages) {
          parentPages[parentIndex].subpages?.push(subpage);
        } else {
          parentPages[parentIndex].subpages = [];
          parentPages[parentIndex].subpages?.push(subpage);
        }
      }
    })
  }

  return parentPages
}

export const returnUpdatedLayoutGrid = (pageObjects: TODO, positions: TODO) => {
  const updatedLayoutGrid = pageObjects.map((pageObject: TODO) => {
    const layoutPosition = positions[pageObject.id]

    return {
      i: pageObject.id,
      w: layoutPosition.w,
      h: layoutPosition.h,
      x: layoutPosition.x,
      y: layoutPosition.y,
    }
  })
  return updatedLayoutGrid
}

export const returnUpdatedDataGrid = (pageObjects: TODO) => {
  const updatedDataGrid = pageObjects.map((pageObject: TODO) => {
    const pageObjectStyle = pageObject.style ? pageObject.style : { ...GridItemDefaultOptions }
    delete pageObjectStyle.title

    if (pageObject.type === "text") {
      return {
        i: pageObject.id,
        options: { ...pageObjectStyle, title: pageObject.title ? pageObject.title : "" },
        type: pageObject.type,
        data: { html: pageObject.textObject.html }
      }
    } if (pageObject.type === "image") {
      return {
        i: pageObject.id,
        options: { ...pageObjectStyle, title: pageObject.title ? pageObject.title : "" },
        type: pageObject.type,
        data: { url: pageObject.imageObject.url },
      }
    }
    if (pageObject.analysisObject.analysisType === "html-react-2") {
      return {
        i: pageObject.id,
        options: { ...pageObjectStyle, title: pageObject.title ? pageObject.title : "" },
        type: pageObject.type,
        data: { analysisType: "table", jsx: undefined },
      }
    }
    return {
      i: pageObject.id,
      options: {
        ...pageObjectStyle,
        title: pageObject.title ? pageObject.title : "",
        chartStyle: pageObject.analysisObject.chartStyle ? pageObject.analysisObject.chartStyle : null,
      },
      type: pageObject.type,
      data: { analysisType: "chart", chartJson: undefined },
    }
  })

  return updatedDataGrid
}

export const returnMenuPages = (pages: TODO) => {
  const pagesList: { title: string, action: string, type?: string }[] = []

  pages.forEach((page: PinboardPage, pageIndex: number) => {
    if (page.subpages.length === 0) {
      if (pageIndex < pages.length - 1) {
        pagesList.push({
          "title": page.title,
          "action": page.id,
          "type": "parentPage"
        }, {
          "title": "separator",
          "action": "separator"
        })
      } else {
        pagesList.push({
          "title": page.title,
          "action": page.id,
          "type": "parentPage"
        })
      }
    } else {
      pagesList.push({
        "title": page.title,
        "action": page.id,
        "type": "parentPage"
      })
      page.subpages.forEach((subpage, subpageIndex) => {
        if (subpageIndex < page.subpages.length - 1) {
          pagesList.push({
            "title": subpage.title,
            "action": subpage.id,
            "type": "subpage"
          })
        } else {
          pagesList.push({
            "title": subpage.title,
            "action": subpage.id,
            "type": "subpage"
          }, {
            "title": "separator",
            "action": "separator"
          })
        }
      })
    }
  })

  return pagesList
}

export const fetchGetPreviewPinboard: TODO = (shareLink: string, pass: string, page?: string) => {
  try {
    const headers: TODO = { "Content-Type": "application/json" }
    const url = !page ? `${apiPanelURL}pb/public?s=${shareLink}` : `${apiPanelURL}pb/public/page?s=${shareLink}&p=${page}`
    if (pass) {
      headers[`pb-sec-${shareLink}`] = pass
    }
    return fetch(url, {
      method: "GET",
      headers: headers
    })
      .then(response => response.json())
      .then(data => data)
      .catch(error => {
        return error;
      })
  } catch (error) {
    return error
  }
}

export const confirmDeletePinboard = (
  currentProjectId: string,
  currentPinboardId: string,
  token: string,
  dispatch: Dispatch<UnknownAction>,
  setShowDeletePinboardModal: (value: boolean) => void,
  history: TODO
) => {
  fetchDelete(`pb/projects/${currentProjectId}/pinboards/${currentPinboardId}`, token)
    .then((res: TODO) => {
      if (res.ok) {
        dispatch({ type: "SHOW_ACTION_NOTIFICATION", payload: { msg: "The pinboard has been deleted successfully" } })
        setShowDeletePinboardModal(false)
        history.push({ pathname: `/${history.location.pathname.split("/")[1]}/${currentProjectId}` })
      } else {
        dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: "Could not delete pinboard" } })
      }
    })
}