import findCommonTextInTitles from "../findCommonTextInTitles/findCommonTextInTitles";

export default (updatedData, checkedData, uniqueValues, activeTitleIndex, dichotomyB, dichotomyA, initialData, updatedQuestionTitle) => {
  const firstElIndex = updatedData.findIndex(el => el['@ident'] === checkedData[0]['@ident'])
  const firstInitialElIndex = initialData.findIndex(el => el['@ident'] === checkedData[0]['@ident'])
  const updatedID = checkedData[0].id.substring(0, checkedData[0].id.indexOf('.'));
  const commonTitles = findCommonTextInTitles(checkedData)
  if (dichotomyB) {
    uniqueValues.forEach((el, id) => {
      if (JSON.stringify(el) === JSON.stringify(dichotomyB[0])) {
        uniqueValues.splice(id, 1)
      }
    })
  }
  if (uniqueValues.filter(el => uniqueValues[0].text === el.text).length === checkedData.length) {
    uniqueValues.forEach((el, id) => {
      el.text = commonTitles.updatedRowTitles[id]
    })
  }
  const mergedMultiChoiceQuestion = {
    "id": updatedID ? updatedID : updatedData[firstElIndex].id + '.1',
    "title": updatedQuestionTitle,
    "values": {
      "value": uniqueValues.map((el, id) => {
        return {
          "@miextsss:code": String(id + 1),
          "@code": el['@code'],
          "text": el.text
        }
      })
    },
    "selected": false,
    "checked": false,
    "merged": true,
    "type": Array.isArray(dichotomyB) && dichotomyB.length > 0 ? 'dichotomyb' : Array.isArray(dichotomyA) && dichotomyA.length > 0 ? 'dichotomya' : 'multilist',
    "order": checkedData[0].oldParentOrder ? checkedData[0].oldParentOrder : checkedData[0].order ? checkedData[0].order : '0',
    "dichotomyBValue": dichotomyB ? dichotomyB : null
  }
  initialData.splice(firstInitialElIndex, 0, mergedMultiChoiceQuestion);
  checkedData.forEach(el => {
    initialData = initialData.filter(val => val['@ident'] !== el['@ident'])
  })
  updatedData.splice(firstElIndex, 0, mergedMultiChoiceQuestion);
  checkedData.forEach(el => {
    updatedData = updatedData.filter(val => val['@ident'] !== el['@ident'])
  })

  if (Array.isArray(dichotomyA) && dichotomyA.length > 0) {
    checkedData.forEach(el => {
      if (Array.isArray(el.values.value)) {
        el.values.value.forEach((val, index) => {
          if (index === activeTitleIndex) {
            val['@miextsss:excluded'] = 'false'
          } else {
            val['@miextsss:excluded'] = 'true'
          }
        })
      }
    })
  } else {
    checkedData.forEach((el) => {
      if (Array.isArray(el.values.value)) {
        el.values.value.forEach((val) => {
          if (!uniqueValues.includes(val)) {
            val['@miextsss:excluded'] = 'true'
          }
        })
      }
    })
  }

  checkedData.forEach((el, elIndex) => {
    if (Array.isArray(el.values.value)) {
      el.values.value.forEach((val, valIndex) => {
        mergedMultiChoiceQuestion.values.value.forEach(mergedVal => {
          if (JSON.stringify(val.text) === JSON.stringify(mergedVal.text)) {
            val['@code'] = mergedVal['@code']
            val['@miextsss:code'] = mergedVal['@miextsss:code']
          } else {
            if (!val['@miextsss:code']) {
              val['@miextsss:code'] = val['@code']
            } else {
              if (Array.isArray(dichotomyA) && dichotomyA.length > 0) {
                if (valIndex === activeTitleIndex) {
                  val['@miextsss:code'] = String(elIndex + 1)
                }
              }
            }
          }
        })
      })
    } else {
      const val = el.values.value
      mergedMultiChoiceQuestion.values.value.forEach(mergedVal => {
        if (JSON.stringify(val.text) === JSON.stringify(mergedVal.text)) {
          val['@code'] = mergedVal['@code']
          val['@miextsss:code'] = mergedVal['@miextsss:code']
        } else {
          if (!val['@miextsss:code']) {
            val['@miextsss:code'] = val['@code']
          }
        }
      })
    }
  })

  if (Array.isArray(dichotomyA) && dichotomyA.length > 0) {
    checkedData.forEach(el => {
      el.values.value[activeTitleIndex].text = el.title
    })
  }
  checkedData.forEach((el, id) => {
    el.title = commonTitles.updatedRowTitles[id]
  })
  mergedMultiChoiceQuestion.mergedQuestions = checkedData;
  return {
    data: updatedData,
    initialData: initialData,
    element: mergedMultiChoiceQuestion
  }
}