import { Input } from '@progress/kendo-react-inputs';
import { Drawer, DrawerContent } from '@progress/kendo-react-layout';

import UpgradePlanMessage from '../../../../../../../shared/UpgradePlanMessage/UpgradePlanMessage';
import { returnSelectedGroup } from '../../../../shared/helpers/optionsDialoghelpers/optionsDialogHelpers';

interface Props {
  firstColumn: any
  onSearchOptions: any
  state: any
  optionGroups: any
  selectGroup: any
  customItem: any
  disableGroup: any
  user: any
  options: any
  openOptionBatchingModal: (saveMode: boolean) => void;
  tab?: "crosstab" | "topline" | "chart" | "dig" | "qt" | "data";
}

export const OptionsDialogContent = ({ firstColumn, onSearchOptions, state, optionGroups, selectGroup, customItem, disableGroup, user, options, openOptionBatchingModal, tab }: Props) => {
  return (
    <div className='d-flex flex-column h-100'>
      {
        (tab === "data" || tab === "topline" || tab === "dig") &&
        <div className="alert alert-analyze mx-3 mt-3 mb-0 d-flex align-items-center gap-md" role="alert">
          <i className="fas fa-info-circle"></i>
          <p className='m-0'>
            Please be aware that options will apply to all questions not selected question(s)
          </p>
        </div>
      }
      {
        firstColumn &&
        <div className="alert alert-analyze mx-3 mt-3 mb-0" role="alert">
          <i className="fas fa-info-circle pr-3"></i>
          <span>{firstColumn.find((el: any) => el.selected) ? "These options will only apply to selected question(s)." : "These options will apply to all questions apart from the question(s) with specific settings."}</span>
        </div>
      }
      <div className="d-flex">
        <div className={"input-group border-bottom p-3"}>
          <Input id="search" placeholder="Search" type="text" className="form-control h-32" onChange={(e) => onSearchOptions(e)} />
        </div>
        <div className={"d-flex w-25 border-bottom pl-0 py-3 pr-3"}>
          <button className="k-button btn btn-outline-secondary text-primary w-50 border-right-0 left-corners-round" onClick={() => openOptionBatchingModal(false)} title="Load a previously saved batch of option values">Load</button>
          <button className="k-button btn btn-outline-secondary text-primary w-50 right-corners-round" onClick={() => openOptionBatchingModal(true)} title="Save current option values as a batch to be reused later">Save</button>
        </div>
      </div>
      {state.selectedGroup ?
        <Drawer
          items={optionGroups.map(
            (item: any) => ({ ...item, selected: item.text === returnSelectedGroup(state.selectedGroup, state.optionGroups) }))}
          expanded={state.expandedOptions}
          onSelect={selectGroup}
          mode="push" position="start"
          width={360}
          className="h-100 mb-auto groups-drawer border border-bottom-0"
          item={customItem}>
          <DrawerContent className="h-100 d-flex flex-column">
            {disableGroup ?
              <UpgradePlanMessage user={user} />
              : null}
            <div className={`p-4 h-100 ${disableGroup ? 'blur' : ""}`}>
              {options.map((option: any) => option)}
            </div>
          </DrawerContent>
        </Drawer> :
        <div className="p-4">
          {options.length !== 0 ?
            options.map((option: any) => option)
            :
            <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
              <p className='text-muted m-0 h6'>
                We couldn't find any options matching '{state.searchValue}'
              </p>
            </div>
          }
        </div>}
    </div>
  )
}