import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Switch } from '@progress/kendo-react-inputs';

import { SideBar } from '../../../../../shared/SideBar/SideBar';
import { BaseDropDownList } from '../../../../../shared/Inputs';
import { TransactionsLog } from '../TransactionsLog/TransactionsLog';
import type { IPropsSidebar } from '../../../../../../interfaces/importedRecipientListinterfaces/importedRecipientListinterfaces';

export const ImportedRecipientSidebar = ({ setOpenSidebar, elementClicked, handleUpdateRespondents, updateRespondents, theKeys, selectedRespondents }: IPropsSidebar) => {
  const dispatch = useDispatch()
  const [selected, setSelected] = useState("edit")

  const onChangeHandler = (e: TODO) => {
    handleUpdateRespondents(e)
  }

  const copySurveyLink = (shortId: string) => {
    if (shortId) {
      navigator.clipboard.writeText(shortId)
      dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "The ID has been copied to clipboard successfully" } });
    } else {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "Something went wrong. Please try again" } });
    }
  }

  const statusData = ["Not started", "Started"]

  return (
    <SideBar onHide={() => setOpenSidebar(false)} header={{ show: true, title: "Respondent" }} width={'400px'} >
      <div>
        <ul className="nav nav-pills flex-nowrap content-tabs border-bottom pl-4 overflow-auto">
          <li className="nav-item" onClick={() => setSelected("edit")}>
            <span className={`nav-link ${selected === "edit" ? "active" : ""}`}>Edit respondents</span>
          </li>
          {selectedRespondents.length > 1 ? null :
            <li className="nav-item" onClick={() => setSelected("log")}>
              <span className={`nav-link ${selected === "log" ? "active" : ""}`}>Transactions log</span>
            </li>}
        </ul>
      </div>
      <div className='p-5 h-100' style={{ maxHeight: "90vh", overflowY: "scroll" }}>
        {selected === "edit" ?
          <Fragment>
            <div className='form-group'>
              {theKeys.map((el: TODO, key: TODO) => {
                const capitalLetter = el.charAt(0).toUpperCase() + el.slice(1);
                return (
                  <div key={key}>
                    <div className='mt-2'>
                      <label>{capitalLetter}</label>
                    </div>
                    <div className='input-group'>
                      <input className="form-control" type="text" name={el} value={elementClicked.v[el] || ""} onChange={(e) => onChangeHandler(e)} />
                    </div>
                  </div>
                )
              })}
              <div className='mt-2'>
                <label>Status</label>
              </div>
              <div className='input-group'>
                <BaseDropDownList
                  className="w-100"
                  data={statusData}
                  value={statusData[0]}
                  disabled={true}
                />
              </div>
              <div className='mt-4'>
                <Switch name="isDisabled" onChange={(e) => onChangeHandler(e)} value={elementClicked.status} checked={elementClicked.isDisabled} size="small" />
                <span className="ml-3">Disable Respondent</span>
              </div>
              <div className='mt-3'>
                <Switch disabled={true} name="optOut" value={elementClicked.optOut} checked={elementClicked.optOut} size="small" />
                <span className="ml-3">Opted out</span>
              </div>
              {selectedRespondents.length > 1 ? null :
                <Fragment>
                  <hr className='mt-4' />
                  <div className='mt-3'>
                    <label>ID</label>
                  </div>
                  <div>
                    <button type='button' title="Copy ID to clipboard" className="form-control d-flex justify-content-between align-items-center" style={{ "border": "2px solid white", "backgroundColor": "#F9FAFA" }} onClick={() => copySurveyLink(elementClicked.shortId)}>
                      <span>{elementClicked.shortId}</span>
                      <i className="fas fa-md fa-clipboard" />
                    </button>
                  </div>
                </Fragment>}
              <hr className='mt-4' />
              <div className='mt-4'>
                <button type='button' className='btn btn-outline-danger'>
                  <i className="far fa-trash-alt mr-2" />
                  <span>Delete respondent</span>
                </button>
              </div>
              <div className='d-flex justify-content-end mt-4'>
                <button type='button' className='btn btn-secondary mr-2' onClick={() => setOpenSidebar(false)}>Cancel</button>
                <button type='button' className='btn btn-primary' onClick={updateRespondents}>Save respondent</button>
              </div>
            </div>
          </Fragment>
          :
          <TransactionsLog selectedTransaction={elementClicked} />
        }
      </div>
    </SideBar>
  );
};