import React, { useState, useEffect } from 'react';
import { Switch } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'

import findCommonTextInTitles from '../helpers/importDataHelpers/findCommonTextInTitles/findCommonTextInTitles';
import multichoiceSubtypeRecognition from '../helpers/importDataHelpers/multichoiceSubtypeRecognition/multichoiceSubtypeRecognition';

export default ({ data, onCheckedChangedHandler, onMergeMultiChoiceSubmit, onSubmitReplaceHandler, dichotomyB, ...props }) => {
  const [activeTitleIndex, setActiveTitleIndex] = useState(0)
  const [questionTitle, setQuestionTitle] = useState(data[activeTitleIndex] ? data[activeTitleIndex].title : '')
  const [findReplaceData, setFindReplaceData] = useState({ find: '', replace: '', isCaseSensitive: true })

  useEffect(() => {
    setQuestionTitle(findCommonTextInTitles(data).updatedTitle)
  }, [])

  if (data.length > 0) {
    const updatedData = multichoiceSubtypeRecognition(data)

    const onSelectHandler = (e) => {
      setActiveTitleIndex(Number(e.target.value))
    }

    const onChangeTitleHandler = (e) => {
      setQuestionTitle(e.target.value)
    }

    const onChangeFindReplaceHandler = (e) => {
      setFindReplaceData({
        ...findReplaceData,
        [e.target.name]: e.target.name === 'isCaseSensitive' ? e.value : e.target.value
      })
    }

    const handleClose = () => {
      props.onHide();
    }

    return (
      <Dialog
        {...props}
        minWidth={"800px"}
        title={"Create Multi Choice"}
        className={['multichoice-modal import-dialog']}
        onClose={handleClose}>
        <div className="d-flex flex-column gap-lg p-3">
          <div className="form-group mb-0">
            <label htmlFor="questionTitle">Question title</label>
            <textarea
              onChange={(e) => onChangeTitleHandler(e)}
              className="form-control"
              value={questionTitle}>
            </textarea>
          </div>
          <div className="form-group mb-0">
            <label htmlFor="questionTitle">Categories</label>
            {
              updatedData.dichotomyAValues.length > 0 ?
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Select which answer means category selected:</label>
                  <select onChange={onSelectHandler} className="form-control" id="exampleFormControlSelect1">
                    {
                      updatedData.dichotomyAValues.map((val, id) => (
                        <option value={id} key={id}>{val.text}</option>
                      ))
                    }
                  </select>
                </div>
                : null
            }
            {
              updatedData.uniqueValues ?
                <ul className="list-group">
                  {
                    updatedData.uniqueValues.map((el, id) => (
                      <li key={id} className="list-group-item p-1">
                        <div>
                          <label className="sr-only" />
                          <input
                            onChange={(e) => onCheckedChangedHandler(e, id, 1, updatedData.dichotomyAValues.length > 0 ? 'question' : 'questionValue')}
                            type="text"
                            className="form-control no-border"
                            value={el.text ? el.text['#text'] ? el.text['#text'] : el.text : ''}
                          />
                        </div>
                      </li>
                    ))
                  }
                </ul>
                : null
            }
          </div>
        </div>

        <form className="p-3 border-top d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center gap-lg">
            <input onChange={onChangeFindReplaceHandler} placeholder='Find' className="form-control" name="find" />
            <input onChange={onChangeFindReplaceHandler} placeholder='Replace' className="form-control" name="replace" />
            <div className="d-flex align-items-center">
              <label style={{ whiteSpace: 'nowrap' }} className='m-0' htmlFor="isCaseSensitive">Case sensitive: </label>
              <Switch className="ml-2 w-100" name="isCaseSensitive" onChange={onChangeFindReplaceHandler} checked={findReplaceData.isCaseSensitive} size="small" />
            </div>
          </div>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => onSubmitReplaceHandler(findReplaceData.find, findReplaceData.replace, updatedData.dichotomyAValues.length > 0, findReplaceData.isCaseSensitive)}>Replace all
          </button>
        </form>
        <DialogActionsBar>
          <button type='button' className="k-button btn btn-secondary" onClick={handleClose}>Cancel</button>
          <button
            type='button'
            className="k-button btn btn-primary"
            onClick={() => onMergeMultiChoiceSubmit(data, updatedData.uniqueValues, activeTitleIndex, updatedData.dichotomyBValue, updatedData.dichotomyAValues, questionTitle)}>
            <span>Save</span>
          </button>
        </DialogActionsBar>
      </ Dialog>
    );
  }
  return null
}
