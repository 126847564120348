import React from 'react';
import { v4 as uuid } from 'uuid';

import { returnHighestCodeValue } from '../returnHighestCodeValue/returnHighestCodeValue';

export const returnChangeTypeValues = (values, type, published) => {
  let typeValues = values
  if (published === true) {
    typeValues = values.filter(el => el.id === type)
    if (type === 'n') {
      typeValues = [...typeValues, ...values.filter(el => el.id === 'm')]
    } else if (type === 'm') {
      typeValues = [...typeValues, ...values.filter(el => el.id === 'n')]
    } else if (type === 'rn') {
      typeValues = [...typeValues, ...values.filter(el => el.id === 'rm')]
    } else if (type === 'rm') {
      typeValues = [...typeValues, ...values.filter(el => el.id === 'rn')]
    }
  }
  return typeValues
}

export const returnCopyOptionsData = (selectedElement, originalItem, theData) => {
  let data = []
  if (selectedElement) {
    data = [{ text: `Select ${theData.elementTypes?.find(el => el.id === selectedElement.type)?.name.toLowerCase()} ...`, id: 'none' }]
    if (selectedElement.type === 'r') {
      originalItem.elements.find(el => el.type === 'rgroup').elements.forEach((el, key) => {
        if (key !== selectedElement.index) {
          data.push({ text: `Row ${key + 1}`, id: key })
        }
      })
    }
    else if (selectedElement.type === 'h') {
      originalItem.elements.filter(el => el.type === 'subq')[theData.selectedSubquestion].elements.find(el => el.type === 'hgroup').elements.forEach((el, key) => {
        if (key !== selectedElement.index) {
          data.push({ text: `Column ${key + 1}`, id: key })
        }
      })
    }
    else if (selectedElement.type === 'subq') {
      originalItem.elements.filter(el => el.type === 'subq').forEach((el, key) => {
        if (key !== theData.selectedSubquestion) {
          data.push({ text: `Question ${key + 1}`, id: key })
        }
      })
    }
  }
  return data
}

export const returnElementOptions = (data, disable, parentType, parentIndex) => {
  const items = []
  let index = -1
  let rowsDisabled = false
  if (data.attributes && data.attributes['miext:disabled'] === "true") {
    rowsDisabled = true
  }
  else if (data.elements.filter(el => el.type === 'subq').length > 0) {
    if (data.elements.filter(el => el.type === 'subq').length !== data.elements.filter(el => el.type === 'subq' && 'miext:disabled' in el.attributes).length) {
      rowsDisabled = false
    }
    else if (data.elements.filter(el => el.type === 'subq').length !== data.elements.filter(el => el.type === 'subq' && el.attributes['miext:disabled'] === "true").length) {
      rowsDisabled = false
    }
    else {
      rowsDisabled = true
    }
  }
  data.elements.forEach((item, key) => {
    if (data.elements.length > 1) {
      if (key === 0) {
        if (item.type === data.elements[key + 1].type || item.type === "other" || data.elements[key + 1].type === 'other') {
          index++
        }
        else index = -1
      }
      else if (key === data.elements.length - 1) {
        if (item.type === data.elements[key - 1].type || item.type === "other" || data.elements[key - 1].type === "other") {
          index++
        }
        else index = -1
      }
      else {
        if (item.type === data.elements[key + 1].type || item.type === data.elements[key - 1].type || item.type === "other") {
          index++
        }
        else index = -1
      }
    }
    if (item.elements && item.elements.length === 1 && !item.elements[0].type === 'stext') {
      items.push({
        id: uuid(),
        attributes: item.attributes,
        type: item.type,
        parentType: parentType,
        parentIndex: parentIndex,
        index: index === -1 ? null : index,
        published: item.attributes?.['miext:id'] ? true : false,
        isDisabled: item.attributes?.['miext:disabled'] && item.attributes['miext:disabled'] === "true" ? true : false,
        isParentDisabled: disable
      })
    }
    else if (item.elements && item.elements.length > 0) {
      items.push({
        id: uuid(),
        type: item.type,
        parentType: parentType,
        parentIndex: parentIndex,
        attributes: item.attributes,
        items: item.type === 'rgroup' ? returnElementOptions(item, rowsDisabled, item.type, index === -1 ? null : index)
          : disable === true ? returnElementOptions(item, disable, item.type, index === -1 ? null : index)
            : returnElementOptions(item, item.attributes?.['miext:disabled'] && item.attributes['miext:disabled'] === "true" ? true : false, item.type, index === -1 ? null : index),
        expanded: true,
        index: index === -1 ? null : index,
        published: item.attributes?.['miext:id'] ? true : false,
        isDisabled: item.attributes?.['miext:disabled'] && item.attributes['miext:disabled'] === "true" ? true : false,
        isParentDisabled: item.type === 'rgroup' ? rowsDisabled : disable
      })
    }
    else {
      items.push({
        id: uuid(),
        type: item.type,
        parentType: parentType,
        attributes: item.attributes,
        parentIndex: parentIndex,
        index: index === -1 ? null : index,
        published: item.attributes?.['miext:id'] ? true : false,
        isDisabled: item.attributes?.['miext:disabled'] && item.attributes['miext:disabled'] === "true" ? true : false,
        isParentDisabled: disable
      })
    }
  })
  return items
}

export const returnElementOptionsChange = (data, hierarchy, selectedElementValues, filterData) => {
  const elements = []
  if (hierarchy.length !== 1) {
    data.elements.forEach((el, key) => {
      if (key.toString() === hierarchy[0]) {
        elements.push({ ...el, elements: returnElementOptionsChange(el, hierarchy.splice(1), selectedElementValues, filterData) })
      }
      else elements.push(el)
    })
  }
  else if (hierarchy.length === 1) {
    data.elements.forEach((el, key) => {
      if (key.toString() === hierarchy[0]) {
        elements.push({
          ...el,
          attributes: selectedElementValues,
          metadata: filterData && filterData.length !== 0 ? { 'filterData': filterData } : null
        })
      }
      else { elements.push(el) }
    })
  }
  return elements
}

export const returnOnAddElementData = (originalData, selectedItem, newElement, type, sectionIndex) => {
  const data = originalData
  if (newElement?.type === 'section') {
    data.elements.push(newElement)
  }
  else if (selectedItem) {
    const index = data.elements[selectedItem.section].elements.findIndex(item => item.referenceId === selectedItem.id)
    data.elements[selectedItem.section].elements.splice(index + 1, 0, newElement)
  }
  else {
    data.elements[sectionIndex].elements.push(newElement)
  }
  return data
}

export const returnOnDeleteElement = (type, subQuesIndex, index, parentType, parentIndex, theData, selectedItem) => {
  const originalItem = selectedItem ? theData.selectedItem : theData.originalSelectedItem

  if (type === 'r') {
    originalItem.elements.find(el => el.type === 'rgroup').elements.splice(index, 1)
  } else if (type === 'h') {
    originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.find(el => el.type === 'hgroup').elements.splice(index, 1)
  } else if (type === 'subq') {
    const elements = originalItem.elements.filter(el => el.type !== 'subq')
    const subquestions = originalItem.elements.filter(el => el.type === 'subq')
    subquestions.splice(subQuesIndex, 1)
    originalItem.elements = [...elements, ...subquestions]
  }

  return originalItem
}

export const returnOnReorderColumns = (columnSource, columnDestination, index, theData) => {
  const originalItem = theData.selectedItem
  originalItem.elements.filter(el => el.type === 'subq')[index].elements.find(el => el.type === 'hgroup').elements =
    (originalItem.elements.filter(el => el.type === 'subq')[index].elements.find(el => el.type === 'hgroup').elements.splice(columnDestination, 0,
      ...originalItem.elements.filter(el => el.type === 'subq')[index].elements.find(el => el.type === 'hgroup').elements.splice(columnSource, 1)),
      originalItem.elements.filter(el => el.type === 'subq')[index].elements.find(el => el.type === 'hgroup').elements)

  return originalItem
}

export const returnOnReorderQuestionData = (elementSource, elementDestination, sectionSource, sectionDestination, theData) => {
  const data = theData.data
  const originalData = theData.originalData

  if (sectionSource === sectionDestination) {
    data[sectionSource].elements = (data[sectionSource].elements.splice(elementDestination, 0, ...data[sectionSource].elements.splice(elementSource, 1)), data[sectionSource].elements)
    originalData.elements[data[sectionSource].index].elements = (originalData.elements[data[sectionSource].index].elements.splice(elementDestination, 0, ...originalData.elements[data[sectionSource].index].elements.splice(elementSource, 1)), originalData.elements[data[sectionSource].index].elements)
  }
  else {
    data[sectionDestination].elements = (data[sectionDestination].elements.splice(elementDestination, 0, data[sectionSource].elements[elementSource]), data[sectionDestination].elements)
    data[sectionSource].elements = (data[sectionSource].elements.splice(elementSource, 1), data[sectionSource].elements)

    originalData.elements[data[sectionDestination].index].elements = (originalData.elements[data[sectionDestination].index].elements.splice(elementDestination, 0, originalData.elements[data[sectionSource].index].elements[elementSource]), originalData.elements[data[sectionDestination].index].elements)
    originalData.elements[data[sectionSource].index].elements = (originalData.elements[data[sectionSource].index].elements.splice(elementSource, 1), originalData.elements[data[sectionSource].index].elements)

  }

  return { data, originalData }
}

export const returnOnReorderRows = (rowSource, rowDestination, theData) => {
  const originalItem = theData.selectedItem
  originalItem.elements.find(el => el.type === 'rgroup').elements =
    (originalItem.elements.find(el => el.type === 'rgroup').elements.splice(rowDestination, 0,
      ...originalItem.elements.find(el => el.type === 'rgroup').elements.splice(rowSource, 1)),
      originalItem.elements.find(el => el.type === 'rgroup').elements)

  return originalItem
}

export const returnOnReorderSectionData = (sectionSource, sectionDestination, theData) => {
  let data = theData.data
  const originalData = theData.originalData
  const removedSections = originalData.elements.filter(el => el.type !== 'section')
  originalData.elements = originalData.elements.filter(el => el.type === 'section')
  originalData.elements = (originalData.elements.splice(sectionDestination, 0, ...originalData.elements.splice(sectionSource, 1)), originalData.elements)
  removedSections.forEach(item => originalData.elements.push(item))
  data = (data.splice(sectionDestination, 0, ...data.splice(sectionSource, 1)), data)

  return { data, originalData }
}

export const returnSelectedElementData = (res, item, errors, data) => {
  let selectedItem = null
  if (res.type !== 'quota-document') {
    if (res.type === 'info' || res.type === "quotastop") {
      item.script = res.script ? res.script : "";
      item.style = res.style ? res.style : "";
    }
    selectedItem = {
      ...item,
      errorMessage: '',
      disabled: res.attributes?.['miext:disabled'] && res.attributes['miext:disabled'] === "true" ? true : false,
      published: res.attributes?.['miext:id'] ? true : false,
      elements: returnValidatedElementsError(res.elements),
      attributes: res.attributes,
      tags: res.tags,
    }
    if (res.type === "quotastop") {
      selectedItem.referenceQuotas = res.referenceQuotas;
    }
  }
  if (res.type === 'quota-document') {
    selectedItem = {
      ...item,
      errorMessage: '',
      maximumMultiSelect: res.maximumMultiSelect,
      method: res.method,
      quotaCells: res.quotaCells,
      disabled: res.disabled,
      published: res.published,
      quotaTable: res.quotaTable !== null ? { ...res.quotaTable, show: true } : { show: false }, // Adds show value which is used in QuotaTable.tsx and we dont get that from back-end. Sets it to true to make the table view be the default view. If we get null from back-end, it sets it to false
      // attributes: res.attributes,
      // tags: res.tags,
    }
  }
  if (errors.length > 0 && res.type !== "quota-document") {
    errors.forEach(error => {
      if (error.location.length > 1 && Number.parseInt(error.location[0].index) === selectedItem.section + 1 && error.location[1].type === selectedItem.type) {
        const item = data[selectedItem.section].elements.find(el => el.id === selectedItem.id)
        const index = data[selectedItem.section].elements.filter(el => el.type === selectedItem.type).indexOf(item)
        if (index === Number.parseInt(error.location[1].index) - 1) {
          if (error.location.length === 2) {
            selectedItem = { ...selectedItem, errorMessage: error.description }
          } else {
            const loc = error.location.slice(2)
            selectedItem = { ...selectedItem, elements: returnElementError(selectedItem, loc, error.description) }
          }
        }
      }
    })
  }
  return selectedItem
}

const returnElementError = (data, locations, errorMessage) => {
  const elements = []
  if (locations.length !== 1) {
    data.elements.forEach((el, key) => {
      const item = data.elements.filter(el => el.type === locations[0].type)[parseInt(locations[0].index) - 1]
      const index = data.elements.indexOf(item)
      if (key === index) {
        elements.push({ ...el, elements: returnElementError(el, locations.splice(1), errorMessage) })
      }
      else elements.push(el)
    })
  }
  else if (locations.length === 1) {
    data.elements.forEach((el, key) => {
      const item = data.elements.filter(el => el.type === locations[0].type)[parseInt(locations[0].index) - 1]
      const index = data.elements.indexOf(item)
      if (key === index) {
        elements.push({
          ...el,
          errorMessage: errorMessage
        })
      }
      else { elements.push(el) }
    })
  }
  return elements
}

export const returnValidatedElementsError = (elements) => {
  const updatedElements = []
  elements.forEach(el => {
    if (el.elements && el.elements.length > 0) {
      updatedElements.push({
        ...el, "errorMessage": '',
        id: uuid(),
        disabled: el.attributes?.['miext:disabled'] && el.attributes['miext:disabled'] === "true" ? true : false,
        published: el.attributes?.['miext:id'] ? true : false,
        elements: returnValidatedElementsError(el.elements)
      })
    } else {
      updatedElements.push({
        ...el,
        id: uuid(),
        "errorMessage": '',
        disabled: el.attributes?.['miext:disabled'] && el.attributes['miext:disabled'] === "true" ? true : false,
        published: el.attributes?.['miext:id'] ? true : false,
      })
    }
  })
  return updatedElements
}

export const returnSurveyDesignData = (originalData, id, errors) => {
  const defaultLanguage = originalData.languages.find(el => el.isDefault)?.code
  const data = []
  let index = 1
  originalData.elements.forEach((el, key) => {
    const elements = []
    if (el.type === 'section') {
      el.elements.forEach(item => {
        if (item.type === 'ques') {
          elements.push({
            selected: id && item.referenceId === id ? true : false,
            id: item.referenceId,
            isDisabled: item.referenceDisabled ? item.referenceDisabled : false,
            section: key,
            type: item.type,
            label: item.referenceDisplayLabel,
            text: item.referenceDisplayTexts?.[defaultLanguage] ? item.referenceDisplayTexts[defaultLanguage] : '',
            surveyId: originalData.surveyId,
            projectId: originalData.projectId,
            errors: 0,
            referenceQuesTypes: item.referenceQuesTypes,
            expanded: false,
            hasLogic: item.hasLogic ? item.hasLogic : false
          })
        } else if (item.type === 'quota') {
          elements.push({
            selected: id && (item.referenceId === id || item.referenceQuotaId === id) ? true : false,
            id: item.referenceId,
            isDisabled: item.referenceDisabled ? item.referenceDisabled : false,
            section: key,
            type: item.type,
            label: item.referenceDisplayLabel,
            text: item.referenceDisplayTexts?.[defaultLanguage] ? item.referenceDisplayTexts[defaultLanguage] : '',
            disabled: item.disabled,
            published: item.published,
            surveyId: originalData.surveyId,
            projectId: originalData.projectId,
            quotaId: item.referenceQuotaId,
            errors: 0,
            hasLogic: item.hasLogic ? item.hasLogic : false
          })
        }
        else if (item.type === 'info' || item.type === 'goto' || item.type === 'quotastop') {
          elements.push({
            selected: id && item.referenceId === id ? true : false,
            section: key,
            isDisabled: item.referenceDisabled ? item.referenceDisabled : false,
            label: item.referenceDisplayLabel ? item.referenceDisplayLabel : item.type === 'info' ? 'Information' : item.type === 'goto' ? 'Goto element' : 'Quota stop',
            text: item.referenceDisplayTexts?.[defaultLanguage] ? item.referenceDisplayTexts[defaultLanguage] : '',
            type: item.type,
            id: item.referenceId,
            surveyId: originalData.surveyId,
            projectId: originalData.projectId,
            errors: 0,
            hasLogic: item.hasLogic ? item.hasLogic : false
          })
        }
      })
      data.push({ index: key, displayIndex: index, elements: elements, expand: true, type: el.type, errorMessage: "" })
      index++
    }
  })
  if (errors) {
    errors.forEach(el => {
      if (el.location) {
        if (el.location.length === 1) {
          data[Number.parseInt(el.location[0].index) - 1].errorMessage = el.description
        } else if (el.location.length > 1) {
          const errors = data[el.location[0].index - 1].elements.filter(item => item.type === el.location[1].type)[Number.parseInt(el.location[1].index) - 1]
          if (errors) errors.errors += 1
        }
      }
    })

  }

  return data
}

export const returnSurveyElementTextChange = (originalSelectedItem, editingElement, data, editingLanguage, languages, referenceQuotas, parentType, parentIndex) => {
  const originalItem = originalSelectedItem

  if (editingElement.type === 'label') {
    originalItem.attributes.id = data
  }
  else if (parentType === 'row' || editingElement.type === 'r' || editingElement.type === 'other') {
    if (originalItem.elements.find(el => el.type === 'rgroup').elements[parentIndex].elements.filter(el => el.type === 'text').length > 1) {
      originalItem.elements.find(el => el.type === 'rgroup').elements[parentIndex].elements.filter(el => el.type === 'text')[editingElement.index].textValues[editingLanguage] = data
    } else {
      originalItem.elements.find(el => el.type === 'rgroup').elements[parentIndex].elements.find(el => el.type === 'text').textValues[editingLanguage] = data
    }
  }
  else if (parentType === 'column' || editingElement.type === 'h') {
    if (originalItem.elements.filter(el => el.type === 'subq')[editingElement.subQuesIndex].elements.find(el => el.type === 'hgroup').elements[parentIndex].elements.filter(el => el.type === "text").length > 1) {
      originalItem.elements.filter(el => el.type === 'subq')[editingElement.subQuesIndex].elements.find(el => el.type === 'hgroup').elements[parentIndex].elements.filter(el => el.type === "text")[editingElement.index].textValues[editingLanguage] = data
    } else {
      originalItem.elements.filter(el => el.type === 'subq')[editingElement.subQuesIndex].elements.find(el => el.type === 'hgroup').elements[parentIndex].elements.find(el => el.type === "text").textValues[editingLanguage] = data
    }
  }
  else if (parentType === 'subq' || editingElement.type === 'stext') {
    originalItem.elements.filter(el => el.type === 'subq')[editingElement.subQuesIndex].elements.find(el => el.type === 'stext').elements.filter(el => el.type === 'text')[editingElement.index].textValues[editingLanguage] = data
  } else if (editingElement.type === 'info') {
    originalItem.elements[editingElement.index].textValues[editingLanguage] = data
    // Condition to add refrenceQuotas to the object if there are any
    if (originalSelectedItem.type === "quotastop" && referenceQuotas) {
      originalItem.referenceQuotas = { ...referenceQuotas }
    }
  } else if (editingElement.type === 'qtext') {
    if (originalItem.elements.find(el => el.type === 'qtext')) {
      originalItem.elements.find(el => el.type === 'qtext').elements.find(el => el.type === 'text').textValues[editingLanguage] = data
    } else {
      const values = {}
      languages.forEach(el => {
        if (el !== editingLanguage) { values[el] = "" }
      })
      originalItem.elements.push({ type: 'qtext', elements: [{ type: 'text', attributes: {}, elements: [], textValues: { ...values, [editingLanguage]: data } }], attributes: {} })
    }
  } else if (editingElement.type === 'say') {
    originalItem.elements.find(el => el.type === 'say').elements.find(el => el.type === 'text').textValues[editingLanguage] = data
  }
  return originalItem
}

export const returnSurveySearchData = (e, theData) => {
  const updatedData = theData.oldData.map(el => ({
    ...el,
    elements: el.elements.filter(item => (item.text?.toLowerCase().includes(e.target.value.toLowerCase())) ||
      (item.label?.toLowerCase().includes(e.target.value.toLowerCase())))
  }))
    .filter(el => el.elements.length > 0)

  return updatedData

}

export const returnElementActionData = (item, disableOpenRow) => {
  let items
  if (item.type === 'ques') {
    if (item.qtext) {
      items = [{ text: `${item.published ? 'Disable' : 'Delete'}`, id: item.published ? "disable" : "delete" }]
    } else {
      items = [{ text: `${item.published ? 'Disable' : 'Delete'}`, id: item.published ? "disable" : "delete" }]
    }
  }
  else if (item.type === 'info' || item.type === 'quota' || item.type === 'quotastop') {
    items = [{ text: 'Delete', id: "delete" }]
  }
  else if (item.type === 'info-text') {
    items = [{ text: "Add text element", id: "text", disabled: false }]
  }
  else if (item.type === 'r' || item.type === 'other') {
    items = [{ text: "Add text element", id: "text", disabled: false }, { text: "Add row before", id: "before", disabled: false }, { text: "Add row after", id: "after", disabled: false }, { text: "Add open row", id: "other", disabled: disableOpenRow }, { text: `${item.published ? 'Disable' : 'Delete'}`, id: item.published ? "disable" : "delete" }]
  }
  else if (item.type === 'subq') {
    items = [{ text: "Add text element", id: "text", disabled: false }, { text: `${item.published ? 'Disable' : 'Delete'}`, id: item.published ? "disable" : "delete" }]
  } else {
    items = item.type === 'h' ? [{ text: "Add text element", id: "text", disabled: false }, { text: "Add column before", id: "before", disabled: false }, { text: "Add column after", id: "after", disabled: false }, { text: `${item.published ? 'Disable' : 'Delete'}`, id: item.published ? "disable" : "delete" }] : []
  }
  return items
}

export const returnOnElementHandleAction = (originalItem, e, subQuesIndex, index, type, languages) => {
  const values = {}
  languages.forEach(el => values[el] = "")
  if (type === 'column') {
    if (e.item.id === 'text') {
      originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.find(el =>
        el.type === 'hgroup').elements[index].elements.push({ type: "text", attributes: {}, textValues: values, metadata: null })
    }
    else if (e.item.id === 'before' || e.item.id === 'after') {
      const newIndex = e.item.id === 'before' && index === 0 ? 0 : e.item.id === 'before' ? index : index + 1
      originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.find(el => el.type === 'hgroup').elements.splice(newIndex, 0, ({ type: 'h', attributes: {}, metadata: null, elements: [{ type: 'text', attributes: {}, elements: [], textValues: values, metadata: null }] }))
    }
    else if (e.item.id === 'disable') {
      originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.find(el => el.type === 'hgroup').elements[index].attributes =
      {
        ...originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.find(el => el.type === 'hgroup').elements[index].attributes,
        'miext:disabled': "true"
      }
    }
  }
  else if (type === 'row') {
    if (e.item.id === 'text') {
      originalItem.elements.find(el => el.type === 'rgroup').elements[index].elements.push({ type: "text", attributes: {}, textValues: values, metadata: null })
    } else if (e.item.id === 'before' || e.item.id === 'after') {
      const newIndex = e.item.id === 'before' && index === 0 ? 0 : e.item.id === 'before' ? index : index + 1
      originalItem.elements.find(el => el.type === 'rgroup').elements.splice(newIndex, 0, ({ type: 'r', attributes: {}, metadata: null, elements: [{ type: 'text', attributes: {}, elements: [], textValues: values, metadata: null }] }))
    }
    else if (e.item.id === 'other') {
      originalItem.elements.find(el => el.type === 'rgroup').elements.push({
        type: 'other',
        attributes: {},
        metadata: null,
        elements: [{ type: 'text', attributes: {}, elements: [], textValues: values, metadata: null }]
      })
    }
    else if (e.item.id === 'disable') {
      originalItem.elements.find(el => el.type === 'rgroup').elements[index].attributes =
      {
        ...originalItem.elements.find(el => el.type === 'rgroup').elements[index].attributes,
        'miext:disabled': "true"
      }
    }
  } else if (type === 'subq') {
    if (e.item.id !== 'disable' && e.item.id !== 'delete' && e.item.id !== 'enable') {
      originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.find(el => el.type === 'stext').elements.push(
        { type: "text", attributes: {}, textValues: values, metadata: null })
    } else if (e.item.id === 'disable') {
      originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].attributes =
      {
        ...originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].attributes,
        'miext:disabled': "true"
      }
    }
  }
  else if (type === 'info') {
    if (e.item.id === 'text') {
      originalItem.elements.push({
        type: "text", attributes: {}, textValues: values, metadata: null
      })
    }
  }

  return originalItem
}

export const returnOnAdvancedEditData = (originalItem, type, subQuesIndex, content, editingLanguage, languages) => {
  const items = [...content]
  if (type === 'r') {
    items.forEach((item, key) => {
      if (originalItem.elements.find(el => el.type === 'rgroup').elements[key]) {
        if (originalItem.elements.find(el => el.type === 'rgroup').elements[key].type === 'r' || originalItem.elements.find(el => el.type === 'rgroup').elements[key].type === 'other') {
          originalItem.elements.find(el => el.type === 'rgroup').elements[key].elements.find(el => el.type === 'text').textValues[editingLanguage] = item
        }
      } else {
        const values = {}
        languages.forEach(el => { if (el !== editingLanguage) { values[el] = "" } })
        const newRow = { type: 'r', attributes: {}, metadata: null, elements: [{ type: 'text', attributes: {}, elements: [], textValues: { ...values, [editingLanguage]: item }, metadata: null }] }
        if (originalItem.elements.filter((el) => el.type === "subq")[subQuesIndex].attributes.type === 'n' || originalItem.elements.filter((el) => el.type === "subq")[subQuesIndex].attributes.type === 'm') {
          newRow.attributes.code = returnHighestCodeValue(originalItem.elements.find((el) => el.type === "rgroup").elements)
        }
        originalItem.elements.find(el => el.type === 'rgroup').elements.push(newRow)
      }
    })
    if (originalItem.elements.find(el => el.type === 'rgroup').elements.length > items.length) {
      originalItem.elements.find(el => el.type === 'rgroup').elements.splice(items.length)
    }
  } else if (type === 'h') {
    items.forEach((item, key) => {
      if (originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.find(el => el.type === 'hgroup').elements[key]) {
        if (originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.find(el => el.type === 'hgroup').elements[key].type === 'h') {
          originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.find(el => el.type === 'hgroup').elements[key].elements.find(el => el.type === 'text').textValues[editingLanguage] = item
        }
      }
      else {
        const values = {}
        languages.forEach(el => { if (el !== editingLanguage) { values[el] = "" } })
        const newColumn = { type: 'h', attributes: {}, metadata: null, elements: [{ type: 'text', attributes: {}, elements: [], textValues: { ...values, [editingLanguage]: item }, metadata: null }] }
        if (originalItem.elements.filter((el) => el.type === "subq")[subQuesIndex].attributes.type === 'rn' || originalItem.elements.filter((el) => el.type === "subq")[subQuesIndex].attributes.type === 'rm') {
          newColumn.attributes.code = returnHighestCodeValue(originalItem.elements.filter((el) => el.type === "subq")[subQuesIndex].elements.find((el) => el.type === "hgroup").elements)
        }
        originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.find(el => el.type === 'hgroup').elements.push(newColumn)
      }
    })
    if (originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.find(el => el.type === 'hgroup').elements.length > items.length) {
      originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.find(el => el.type === 'hgroup').elements.splice(items.length)
    }
  }
  return originalItem
}

export const returnMultipleTexts = (subQuesIndex, index, type, originalItem) => {
  let elements = []
  if (type === 'subq') {
    elements = originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.find(el => el.type === 'stext').elements.filter(el => el.type === 'text')
  } else if (type === 'row') {
    elements = originalItem.elements.find(el => el.type === 'rgroup').elements[index].elements.filter(el => el.type === 'text')
  } else if (type === 'column') {
    elements = originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.find(el => el.type === 'hgroup').elements[index].elements.filter(el => el.type === "text")
  } else if (type === 'info') {
    elements = originalItem.elements.filter(el => el.type === 'text')
  }
  return elements
}

export const returnMultipleTextElementsChange = (originalItem, elements, subQuesIndex, index, type) => {
  const updatedOriginalItem = originalItem
  if (type === 'subq') {
    updatedOriginalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.find(el => el.type === 'stext').elements = [
      ...updatedOriginalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.find(el => el.type === 'stext').elements.filter(el => el.type !== 'text'),
      ...elements
    ]
  } else if (type === 'row') {
    updatedOriginalItem.elements.find(el => el.type === 'rgroup').elements[index].elements = [
      ...updatedOriginalItem.elements.find(el => el.type === 'rgroup').elements[index].elements.filter(el => el.type !== 'text'),
      ...elements
    ]
  } else if (type === 'column') {
    updatedOriginalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.find(el => el.type === 'hgroup').elements[index].elements = [
      ...updatedOriginalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.find(el => el.type === 'hgroup').elements[index].elements.filter(el => el.type !== "text"),
      ...elements
    ]
  }
  else if (type === 'info') {
    updatedOriginalItem.elements = elements
  }
  return updatedOriginalItem
}

export const returnVisualisationDropdownData = (visualisationSettings, type) => {
  const data = [{ name: "No visualisation", id: "none" }]
  visualisationSettings?.forEach((item => {
    if (item.questionSupport && item.questionSupport.filter(el => el.type === type).length !== 0) {
      data.push(item)
    }
  }))
  if (data.length === 1 && visualisationSettings?.length > 0) {
    data.push(visualisationSettings[0])
  }
  return data
}

export const returnVisualTypeChange = (originalItem, id, subQuesIndex) => {
  const updatedOriginalItem = originalItem
  if (id !== "none") {
    updatedOriginalItem.elements.filter(el => el.type === 'subq')[subQuesIndex]["visual"] = { "type": id, "props": [] }
  } else {
    if (updatedOriginalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].visual) {
      delete updatedOriginalItem.elements.filter(el => el.type === 'subq')[subQuesIndex]["visual"]
    }
  }
  return updatedOriginalItem
}

export const returnVisualSettingsChange = (originalItem, values, subQuesIndex) => {
  const updatedOriginalItem = originalItem
  updatedOriginalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].visual.props = [...values]

  return updatedOriginalItem
}

export const typeValueRender = (props) => {
  if (props.props.children === 'Single grid') {
    return (
      <div className="d-flex align-items-center w-100" >
        <div className="dropdown-type-avatar">
          <i className="fas fa-list-ul fa-sm" />
        </div>
        <span>{props.props.children}</span>
      </div>
    )
  }
  if (props.props.children === 'Single') {
    return (
      <div className="d-flex align-items-center w-100">
        <div className="dropdown-type-avatar">
          <i className="fas fa-dot-circle fa-sm" />
        </div>
        <span>{props.props.children}</span>
      </div>
    )
  }
  if (props.props.children === 'Multi') {
    return (
      <div className="d-flex align-items-center w-100">
        <div className="dropdown-type-avatar">
          <i className="fas fa-check-square fa-sm" />
        </div>
        <span>{props.props.children}</span>
      </div>
    )
  }
  if (props.props.children === 'Multi grid') {
    return (
      <div className="d-flex align-items-center w-100">
        <div className="dropdown-type-avatar fa-sm">
          <i className="fas fa-list" />
        </div>
        <span>{props.props.children}</span>
      </div>
    )
  }
  if (props.props.children === 'Duration') {
    return (
      <li className="k-item d-flex align-items-center w-100">
        <div className="dropdown-type-avatar">
          <span className="k-icon k-i-group-collection" />
        </div>
        <span>{props.props.children}</span>
      </li>
    )
  }
  if (props.props.children === 'Numeric') {
    return (
      <li className="k-item d-flex align-items-center w-100">
        <div className="dropdown-type-avatar">
          <span className="k-icon k-i-parameter-integer" />
        </div>
        <span>{props.props.children}</span>
      </li>
    )
  }
  return (
    <div className="d-flex align-items-center w-100">
      <i className="fas fa-list" />
      <span>{props.props.children}</span>
    </div>
  )
}

export const typeItemRender = (props, checkTypeChange, selectedItemType, selectedItemPublished, questionTypeValues, index) => {
  const data = returnChangeTypeValues(questionTypeValues, selectedItemType, selectedItemPublished)
  const value = data.find(el => el.text === props.props.children)
  if (props.props.children === 'Single grid') {
    return (
      <li className="k-item d-flex align-items-center w-100" onClick={() => checkTypeChange(selectedItemType, value, index)}>
        <i className="fas fa-list-ul text-primary w-25" />
        <span className="w-75">{props.props.children}</span>
      </li>
    )
  }
  if (props.props.children === 'Single') {
    return (
      <li className="k-item d-flex align-items-center w-100" onClick={() => checkTypeChange(selectedItemType, value, index)}>
        <i className="fas fa-dot-circle text-primary w-25" />
        <span className="w-75">{props.props.children}</span>
      </li>
    )
  }
  if (props.props.children === 'Multi') {
    return (
      <li className="k-item d-flex align-items-center w-100" onClick={() => checkTypeChange(selectedItemType, value, index)}>
        <i className="fas fa-check-square text-primary w-25" />
        <span className="w-75">{props.props.children}</span>
      </li>
    )
  }
  if (props.props.children === 'Multi grid') {
    return (
      <li className="k-item d-flex align-items-center w-100" onClick={() => checkTypeChange(selectedItemType, value, index)}>
        <i className="fas fa-list text-primary w-25" />
        <span className="w-75">{props.props.children}</span>
      </li>
    )
  }
  if (props.props.children === 'Duration') {
    return (
      <li className="k-item d-flex align-items-center w-100" onClick={() => checkTypeChange(selectedItemType, value, index)}>
        <span className="k-icon k-i-group-collection text-primary w-25 mr-0 justify-content-start" style={{ fontSize: "20px" }} />
        <span className="w-75">{props.props.children}</span>
      </li>
    )
  }
  if (props.props.children === 'Numeric') {
    return (
      <li className="k-item d-flex align-items-center w-100" onClick={() => checkTypeChange(selectedItemType, value, index)}>
        <span className="k-icon k-i-parameter-integer text-primary w-25 mr-0 justify-content-start" style={{ fontSize: "20px" }} />
        <span className="w-75">{props.props.children}</span>
      </li>
    )
  }
  return (
    <li className="k-item d-flex align-items-center w-100">
      <span className="w-75">{props.props.children}</span>
    </li>
  )
}

const checkRotation = (rows) => {
  let hasRotation = false
  rows.forEach(el => {
    if (el.attributes?.rot) {
      hasRotation = true
    }
  })
  return hasRotation
}

export const checkRotationType = (rows) => {
  const checkForRotation = checkRotation(rows)
  let rotationType = 0
  const excludedRows = []
  if (checkForRotation) {
    rotationType = 1
    if (rows[0].attributes?.rot && rows[0].attributes.rot === "R" && !rows[0].attributes.rotsync) {
      rows.slice(1).forEach((row, key) => {
        if (row.attributes?.rot && row.attributes.rot !== "N" && !row.attributes.rotsync) {
          rotationType = 2
        }
        else if (row.attributes?.rot && row.attributes.rot === "N" && !row.attributes.rotsync) {
          excludedRows.push(key)
        }
      })
    } else { rotationType = 2 }
  }

  return { rotationType, excludedRows }
}

export const returnAdvancedRotationElement = (element, values) => {
  const updatedElement = element
  values.forEach((el, key) => {
    if (el.rot !== '') {
      if (updatedElement.elements.find(el => el.type === 'rgroup').elements[key].attributes) {
        updatedElement.elements.find(el => el.type === 'rgroup').elements.filter((item) => item.type !== "rc")[key].attributes["rot"] = el.rot
      } else {
        updatedElement.elements.find(el => el.type === 'rgroup').elements[key] = {
          ...updatedElement.elements.find(el => el.type === 'rgroup').elements.filter((item) => item.type !== "rc")[key],
          attributes: { "rot": el.rot }
        }
      }
    } if (el.rotsync !== 0) {
      if (updatedElement.elements.find(el => el.type === 'rgroup').elements[key].attributes) {
        updatedElement.elements.find(el => el.type === 'rgroup').elements.filter((item) => item.type !== "rc")[key].attributes["rotsync"] = el.rotsync
      } else {
        updatedElement.elements.find(el => el.type === 'rgroup').elements[key] = {
          ...updatedElement.elements.find(el => el.type === 'rgroup').elements.filter((item) => item.type !== "rc")[key],
          attributes: { "rotsync": el.rotsync }
        }
      }
    }
  })
  return updatedElement
}

export const returnValidationErrors = (res) => {
  const errors = []
  res.errors.forEach(el => {
    if (el.location) {
      const list = []
      const descr = el.description
      list.push(el.location.split('/').slice(1))
      list.forEach(el => {
        const errorLocation = []
        el.forEach(loc => {
          errorLocation.push({ type: `${loc.split('[')[0]}`, index: `${loc.split('[').pop().split(']')[0]}` })
        })
        errors.push({ location: errorLocation, description: descr })
      })
    } else {
      errors.push(el)
    }
  })
  return errors
}

export const returnQuestionTypeChange = (type, value, subQuesIndex, theData) => {
  const originalItem = JSON.parse(JSON.stringify(theData.selectedItem));
  if ((type !== 'rm' && type !== 'rn') && (value.id === 'rm' || value.id === 'rn')) {
    const values = {};
    theData.originalData.languages.map(el => el.code).forEach(el => values[el] = "")
    originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].attributes = { ...originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].attributes, type: value.id }
    originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex] = {
      ...originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex],
      elements: originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.push({
        type: "hgroup", metadata: null, attributes: {}, elements: [{ type: "h", metadata: null, attributes: { code: "1" }, elements: [{ type: "text", attributes: {}, textValues: values, metadata: null, elements: [] }] }]
      })
    }
    if (originalItem.elements.find(el => el.type === 'rgroup').elements.length > 0) {
      originalItem.elements.find(el => el.type === 'rgroup').elements = originalItem.elements.find(el => el.type === 'rgroup').elements.filter(el => el.type !== "other")
    } else {
      originalItem.elements.find(el => el.type === 'rgroup').elements.push({ "type": "r", "attributes": { "code": "1" }, "elements": [{ "type": "text", "attributes": {}, "textValues": values }] })
    }
  } else if ((type === 'rm' || type === 'rn') && (value.id !== 'rm' && value.id !== 'rn')) {
    originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].attributes = { ...originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].attributes, type: value.id }
    originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex] = {
      ...originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex],
      elements: originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.splice(originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.findIndex(el => el.type === 'hgroup'), 1)
    }
    originalItem.elements.find(el => el.type === 'rgroup').elements.forEach((el, key) => el.attributes.code = key + 1)
  } else {
    originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].attributes = { ...originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].attributes, type: value.id }
  }
  originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].customQuestionId = null
  originalItem.elements.filter(el => el.type === 'subq')[subQuesIndex].customQuestionProperties = null

  originalItem._etag = theData.originalSelectedItem._etag;

  return originalItem
}

export const returnPasteItems = (theData) => {
  const items = theData.data.map((el, key) => { return { "text": `Section ${key + 1}`, "id": key } })
  if (theData.originalSelectedItem) {
    items.unshift({ "text": "After selected element", "id": "selectedElement", })
  }
  items.push({ "text": "New section", "id": "newSection" })

  return items;
}

export const returnPasteBodyData = (copiedData, selectedSection, theData) => {
  let bodyData = null
  if (selectedSection === 'selectedElement') {
    bodyData = theData.originalData
    const pushElements = []
    const index = bodyData.elements[theData.selectedItem.section].elements.findIndex(el => el.referenceId === theData.selectedItem.id)
    copiedData.forEach(el => {
      pushElements.push(el.indexElement)
    })
    bodyData.elements[theData.selectedItem.section].elements.splice(index + 1, 0, ...pushElements)
  }
  else {
    bodyData = theData.originalData
    copiedData.forEach(el => {
      bodyData.elements[selectedSection].elements.push(el.indexElement)
    })
  }
  return bodyData
}

export const returnDuplicateBodyData = (copiedData, selectedSection, theData) => {
  let bodyData = null
  bodyData = theData.originalData
  copiedData.forEach(el => {
    bodyData.elements[selectedSection].elements.push(el.indexElement)
  })

  return bodyData
}

export const returnSkipDestination = (value) => {
  let name = ''
  if (value === "nextques") {
    name = "Next question"
  } else if (value === "nextsection") {
    name = "Next section"
  } else if (value === "exit") {
    name = "Exit"
  } else {
    name = value
  }
  return name
}

export const checkDisabledTab = (el, selectedItem) => {
  if (selectedItem) {
    if (el.key === 'preview' && selectedItem.type === 'quota') {
      return true
    }
    if (el.key === 'logic' && (selectedItem.type === 'quota' || selectedItem.type === 'quotastop' || selectedItem.type === 'info' || selectedItem.type === 'goto')) {
      return true
    }
    return false
  }
}

// The functions below (ConvertToJsDates, monthDiff, ConvertToSortedList and updateFinalCompletesData) are all helpers for the fieldworkDashboardData function

const ConvertToJsDates = (completesOverTime) => {
  const jsDatesDict = {};

  for (const key in completesOverTime) {
    if (completesOverTime.hasOwnProperty(key)) {
      jsDatesDict[new Date(key)] = completesOverTime[key];
    }
  }
  return jsDatesDict;
}

const monthDiff = (dateFrom, dateTo) => {
  return dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
}

const ConvertToSortedList = (jsDatesDict) => {
  const completesOverTimeList = [];
  for (const key in jsDatesDict) {
    if (jsDatesDict.hasOwnProperty(key)) {
      completesOverTimeList.push({
        "date": key,
        "completes": jsDatesDict[key]
      });
    }
  }

  return completesOverTimeList.sort((a, b) => new Date(a.date) - new Date(b.date));
}

const updateFinalCompletesData = (initialDiffMonths, sortedByDatesList, years) => {
  const updatedFinalData = initialDiffMonths > 12 ? [] : new Array(sortedByDatesList.length)

  if (initialDiffMonths > 0 && initialDiffMonths <= 12) {
    for (const [i, el] of sortedByDatesList.entries()) {
      updatedFinalData[i] = {
        'date': new Date(el.date).toLocaleString('default', { month: 'long' }),
        'value': el.completes
      }
    }
  } else if (initialDiffMonths > 12) {
    for (const [i, element] of Object.keys(years).entries()) {
      updatedFinalData[i] = ({
        'date': element,
        'value': years[element]
      })
    }
  } else {
    for (const [i, el] of sortedByDatesList.entries()) {
      updatedFinalData[i] = {
        'date': new Date(el.date).toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' }),
        'value': el.completes
      }
    }
  }

  return updatedFinalData
}

export const fieldworkDashboardData = (startDate, endDate, completesOverTime) => {
  const months = []
  const years = {}
  const jsDatesDict = ConvertToJsDates(completesOverTime);
  const dates = Object.keys(jsDatesDict);
  const firstDate = new Date(dates[0]);
  const lastDate = new Date(dates[dates.length - 1]);
  const initialDiffMonths = monthDiff(startDate, endDate)

  // Fill in missing days or months between two dates
  const fillDaysOrMonths = (currentDate, theLastDate) => {
    if (theLastDate === null) {
      return;
    }

    const diffTime = Math.abs(currentDate - theLastDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffMonths = monthDiff(currentDate, theLastDate)
    const currentDateMonth = currentDate.toLocaleString('default', { month: 'long' })

    if (diffDays === 0 && diffMonths === 0) {
      theLastDate = null
    }

    if (initialDiffMonths > 0) {
      if (currentDate in jsDatesDict) {
        if (!months.includes(currentDateMonth)) {
          months.push(currentDateMonth)
          fillDaysOrMonths(new Date(currentDate.setDate(currentDate.getDate() + 1)), theLastDate);
        } else {
          const keys = Object.keys(jsDatesDict)
          jsDatesDict[keys[keys.length - 1]] += jsDatesDict[currentDate]
          delete jsDatesDict[currentDate]
          fillDaysOrMonths(new Date(currentDate.setDate(currentDate.getDate() + 1)), theLastDate);
        }
      }
      else {
        if (!months.includes(currentDateMonth)) {
          months.push(currentDateMonth)
          jsDatesDict[new Date(currentDate)] = 0;
          fillDaysOrMonths(new Date(currentDate.setDate(currentDate.getDate() + 1)), theLastDate);
        } else {
          fillDaysOrMonths(new Date(currentDate.setDate(currentDate.getDate() + 1)), theLastDate);
        }
      }
    }
    else {
      if (currentDate in jsDatesDict) {
        fillDaysOrMonths(new Date(currentDate.setDate(currentDate.getDate() + 1)), theLastDate);
      } else {
        jsDatesDict[new Date(currentDate)] = 0;
        fillDaysOrMonths(new Date(currentDate.setDate(currentDate.getDate() + 1)), theLastDate);
      }
    }
  }

  // Fill in missing years between two dates
  const fillYears = (yearOfFirstDate, yearOfLastDate) => {
    for (let index = yearOfFirstDate; index <= yearOfLastDate; index++) {
      Object.keys(jsDatesDict).forEach((currentDate) => {
        const currentDateYear = new Date(currentDate).getFullYear()
        if (currentDateYear === index) {
          if (index.toString() in years) {
            years[index.toString()] += jsDatesDict[currentDate]
          } else {
            years[index.toString()] = jsDatesDict[currentDate]
          }
        } else {
          if (!(index.toString() in years)) {
            years[index.toString()] = 0
          }
        }
      })
    }
  }

  if (initialDiffMonths > 12) {
    fillYears(startDate.getFullYear(), endDate.getFullYear())
  } else {
    fillDaysOrMonths(firstDate, lastDate);
  }

  const sortedByDatesList = ConvertToSortedList(jsDatesDict);
  const finalData = updateFinalCompletesData(initialDiffMonths, sortedByDatesList, years)

  return finalData
}

export const returnQuesElementIcon = (firstSubQuesType) => {
  switch (firstSubQuesType) {
    case 'n':
      return 'single'
    case 'rn':
      return 'single-grid'
    case 'm':
      return 'multi'
    case 'rm':
      return 'multi-grid'
    case 'f':
      return 'numeric'
    case 'h':
      return 'duration'
    default:
      return 'general';
  }
}

export const returnElementIconAddNewElementModal = (firstSubQuesType) => {
  switch (firstSubQuesType.id) {
    case 'DefaultQuesN':
      return 'single'
    case 'DefaultQuesRn':
      return 'single-grid'
    case 'DefaultQuesM':
      return 'multi'
    case 'DefaultQuesRm':
      return 'multi-grid'
    case 'DefaultQuesF':
      return 'numeric'
    case 'DefaultQuesH':
      return 'duration'
    case 'DefaultSection':
      return 'section'
    case 'DefaultQuesOpen':
      return 'open-end'
    case 'DefaultInfo':
      return 'info'
    case 'DefaultGoto':
      return 'goto'
    case 'DefaultQuotaStop':
      return 'quota-stop'
    case 'DefaultQuota':
      return 'quota'
    default:
      return 'general';
  }
}

export const returnElementNameVariable = (item) => {
  switch (item.id) {
    case 'DefaultQuesN':
      return 'Single'
    case 'DefaultQuesRn':
      return 'Single Grid'
    case 'DefaultQuesM':
      return 'Multiple'
    case 'DefaultQuesRm':
      return 'Multiple Grid'
    case 'DefaultQuesF':
      return 'Numeric'
    case 'DefaultQuesH':
      return 'Duration'
    case 'DefaultSection':
      return 'Section'
    case 'DefaultQuesOpen':
      return 'Open End'
    case 'DefaultInfo':
      return 'Information'
    case 'DefaultGoto':
      return 'GoTo'
    case 'DefaultQuotaStop':
      return 'Quota Stop'
    case 'DefaultQuota':
      return 'Quota'
    default:
      return item.name;
  }
}

export const clickedButton = (event, allElements, onAddNewElement) => {
  switch (event.keyCode) {
    case 48:
      onAddNewElement(allElements[11])
      break;
    case 49:
      onAddNewElement(allElements[0])
      break;
    case 50:
      onAddNewElement(allElements[1])
      break;
    case 51:
      onAddNewElement(allElements[2])
      break;
    case 52:
      onAddNewElement(allElements[3])
      break;
    case 53:
      onAddNewElement(allElements[4])
      break;
    case 54:
      onAddNewElement(allElements[5])
      break;
    case 55:
      onAddNewElement(allElements[6])
      break;
    case 56:
      onAddNewElement(allElements[7])
      break;
    case 57:
      onAddNewElement(allElements[8])
      break;
    case 81:
      onAddNewElement(allElements[9])
      break;
    case 87:
      onAddNewElement(allElements[10])
      break;
    default:
      break;
  }
}

export const returnQuesType = (firstSubQuesType) => {
  switch (firstSubQuesType) {
    case 'n':
      return 'DefaultQuesN'
    case 'rn':
      return 'DefaultQuesRn'
    case 'm':
      return 'DefaultQuesM'
    case 'rm':
      return 'DefaultQuesRm'
    case 'f':
      return 'DefaultQuesF'
    case 'h':
      return 'DefaultQuesH'
    default:
      return 'general';
  }
}