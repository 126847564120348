import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import type { RootState } from "../../../store/reducers/rootReducer";
import type { User } from "../../../interfaces/userInterface/userInterface";
import { DashboardKnowledgeBase } from "./DashboardKnowledgeBase/DashboardKnowledgeBase";
import { DashboardRecentProjects } from "./DashboardRecentProjects/DashboardRecentProjects";
import { DashboardRecentDocuments } from "./DashboardRecentDocuments/DashboardRecentDocuments";
import PendingInvitationsContent from "../../shared/PendingInvitationsContent/PendingInvitationsContent";
import type { PendingInvitation, PendingInvitationsResponse, } from "../../../interfaces/pendingInvitationInterface/pendingInvitationInterface";
import AddProjectModal from "../../Project/AddProjectModal/AddProjectModal";
import { fetchGetRes as getPendingInvitations } from "../../../services/services";

interface Props {
  user: User
}

export const DashboardUserData = ({ user }: Props) => {
  const dispatch = useDispatch();

  const [pendingInvitations, setPendingInvitations] = useState<PendingInvitation[] | null>(null);
  const { showAddProjectModal } = useSelector((state: RootState) => (state.projectsReducer));
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);

  useEffect(() => {
    getPendingInvitations("share", token)
      .then(((response: TODO) => response.status !== 204 ? response.json() : {}))
      .then((res: PendingInvitationsResponse) => {
        if (res?.invitations) {
          if (res.invitations.length > 0) {
            setPendingInvitations(res.invitations);
          }
        } else if (res.message || res.error) {
          dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: res.message || res.error } })
        }
      });
  }, [dispatch, token]);

  return (
    <div className="container">
      {showAddProjectModal && (
        <AddProjectModal
          userData={user}
          token={token}
          onHide={() => dispatch({ type: "UPDATE_SHOW_ADD_PROJECT_MODAL", payload: false })}
        />
      )}
      <div className="row gap-row-lg">
        {pendingInvitations && (
          <PendingInvitationsContent
            token={token}
            pendingInvitations={pendingInvitations}
          />
        )}
        <DashboardRecentProjects />
        <DashboardRecentDocuments />
        <DashboardKnowledgeBase />
      </div>
    </div>
  );
};
