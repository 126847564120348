import findCommonTextInTitles from "../findCommonTextInTitles/findCommonTextInTitles"

export default (data, checkedQuestions, initialData) => {
  let updatedData = [...data]
  const firstElIndex = updatedData.indexOf(checkedQuestions[0])
  const firstInitialElIndex = initialData.indexOf(checkedQuestions[0])
  const updatedTitles = findCommonTextInTitles(checkedQuestions)
  const updatedRowTitles = updatedTitles.updatedRowTitles
  const updatedTitle = updatedTitles.updatedTitle
  let uniqueValues = []

  if (checkedQuestions.filter(el => el.variable).length === checkedQuestions.length) {
    const allValues = []
    const map = new Map();

    checkedQuestions[0].variable.forEach(val => {
      val.values.value.forEach(item => {
        allValues.push(JSON.stringify(item))
      })
    })

    allValues.forEach((item, i) => {
      if (map.has(item)) map.get(item).push(i);
      else map.set(item, [i]);
    });

    uniqueValues = allValues.filter((item, i) => map.get(item).length === 1).map(el => JSON.parse(el));
  }

  let multiGridElement = null

  const multilistVariables = checkedQuestions.filter((item) => item.type === 'dichotomyb')
  if (multilistVariables.length === checkedQuestions.length) {
    multiGridElement = {
      id: `${checkedQuestions[0].id}.1`,
      title: updatedTitle,
      "miextsss:multilist": checkedQuestions.map((el, index) => {
        return {
          "@ident": el["@ident"],
          "@miextsss:qno": el.id,
          "@miextsss:excluded": "false",
          label: {
            text: updatedRowTitles[index]
          },
          position: el.position ? el.position : '',
          "@miextsss:type": el.type,
          name: el.id,
          variable: el.mergedQuestions.map((newVar) => {
            return {
              name: newVar.id,
              '@type': newVar.type,
              '@ident': newVar['@ident'],
              position: newVar.position,
              label: { text: newVar.title },
              values: newVar.values
            }
          })
        }
      }),
      mergedQuestions: checkedQuestions,
      selected: true,
      checked: false,
      multigrid: true,
      merged: true,
      order: checkedQuestions[0].oldParentOrder ? checkedQuestions[0].oldParentOrder : checkedQuestions[0].order ? checkedQuestions[0].order : '0'
    }
  } else if (uniqueValues.length > 0) {
    multiGridElement = {
      id: `${checkedQuestions[0].id}.1`,
      title: updatedTitle,
      "miextsss:multilist": checkedQuestions.map((el, index) => {
        return {
          "@ident": el["@ident"],
          "@miextsss:qno": el.id,
          "@miextsss:excluded": "false",
          label: {
            text: updatedRowTitles[index]
          },
          position: el.position ? el.position : '',
          "@miextsss:type": el.type,
          name: el.id,
          variable: el.variable.map((newVar, varIndex) => {
            return {
              ...newVar,
              values: {
                value: {
                  "@code": uniqueValues[varIndex]['@code'] ? uniqueValues[varIndex]['@code'] : (varIndex + 1).toString(),
                  "@miextsss:code": uniqueValues[varIndex]['@miextsss:code'] ? uniqueValues[varIndex]['@miextsss:code'] : (varIndex + 1).toString(),
                  "@miextsss:excluded": "false",
                  text: uniqueValues[varIndex].text
                }
              }
            }
          })
        }
      }),
      mergedQuestions: checkedQuestions,
      selected: true,
      checked: false,
      multigrid: true,
      merged: true,
      order: checkedQuestions[0].oldParentOrder ? checkedQuestions[0].oldParentOrder : checkedQuestions[0].order ? checkedQuestions[0].order : '0'
    }
  } else {
    multiGridElement = {
      id: `${checkedQuestions[0].id}.1`,
      title: updatedTitle,
      variable: checkedQuestions.map((el, index) => {
        return {
          "@ident": el["@ident"],
          "@miextsss:qno": el.id,
          "@miextsss:excluded": "false",
          label: {
            text: updatedRowTitles[index]
          },
          position: el.position ? el.position : '',
          "@type": el.type,
          name: el.id,
          values: {
            value: el.values.value.map((val, id) => {
              return {
                "@ident": val['@ident'],
                "@code": (id + 1).toString(),
                "@miextsss:code": (id + 1).toString(),
                "@miextsss:excluded": "false",
                text: val.text
              }
            })
          }
        }
      }),
      mergedQuestions: checkedQuestions,
      selected: true,
      checked: false,
      multigrid: true,
      merged: true,
      order: checkedQuestions[0].oldParentOrder ? checkedQuestions[0].oldParentOrder : checkedQuestions[0].order ? checkedQuestions[0].order : '0'
    }
  }

  updatedData.splice(firstElIndex, 0, multiGridElement);
  checkedQuestions.forEach(el => {
    updatedData = updatedData.filter(val => JSON.stringify(val) !== JSON.stringify(el))
  })
  initialData.splice(firstInitialElIndex, 0, multiGridElement);
  checkedQuestions.forEach(el => {
    initialData = initialData.filter(val => JSON.stringify(val) !== JSON.stringify(el))
  })

  return {
    data: updatedData,
    initialData: initialData,
    element: multiGridElement
  }
}