import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { filterBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { Stepper } from "@progress/kendo-react-layout";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Checkbox, Input, NumericTextBox, Switch } from "@progress/kendo-react-inputs";

import { Icon } from '../../../../../shared/Icon/Icon';
import { FullHeightSpinner } from "../../../../../shared/FullHeightSpinner/FullHeightSpinner";
import { returnQuestionIcon } from "../../../../../Analysis/Analyze/components/shared/helpers/returnQuestionIcon/returnQuestionIcon";
import { fetchGetJson as getSurveyStructure, fetchPostJson as getCellTemplates } from "../../../../../../services/services";

const stepperItems = [{ label: "Select questions" }, { label: "Modify and options" }];

const initialFilter = {
  logic: "and",
  filters: [{ field: "qno", operator: "contains", value: "" }],
};

export const QuotaModal = ({ handleClose, theData, token, addSurveyElement, quotaRequestError, clearErrorMessage, setSectionIndex }) => {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [stepperValue, setStepperValue] = useState(0);
  const [questions, setQuestions] = useState(null);
  const [cellTemplateState, setCellTemplateState] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [filter, setFilter] = useState(initialFilter);

  let closestValidItem = null
  const allQuestions = []
  let selectedItemIndex = allQuestions.length - 1
  theData.data.forEach(el => el.elements.forEach(item => allQuestions.push(item)))

  if (theData.selectedItem) {
    selectedItemIndex = allQuestions.findIndex(el => el.id === theData.selectedItem?.id)
  }
  if (selectedItemIndex > 0) {
    for (let index = selectedItemIndex; index >= 0; index--) {
      if (allQuestions[index].type !== 'info' && allQuestions[index].type !== 'goto' && allQuestions[index].type !== 'quotastop') {
        closestValidItem = allQuestions[index]
        break;
      }
    }
  } else {
    closestValidItem = theData.selectedItem
  }

  useEffect(() => {
    if (questions === null) {
      getSurveyStructure(`su/projects/${params.name}/surveys/${params.survey}/structure`, token).then((res) => {
        if (res?.error) {
          setErrorMessage(res.message ? res.message : res.error);
        } else if (res) {
          const data = { ...res, questions: [] };
          res.questions.forEach((el, key) => {
            const index = closestValidItem
              ? res.questions.findIndex((el) => el.questionId === closestValidItem.id)
              : res.questions.length;
            if (key <= index && (el.type === "n" || el.type === "m")) {
              if (res.questions.filter((item) => item.qno.slice(0, -2) === el.qno.slice(0, -2)).length > 1) {
                data.questions.push({
                  ...el,
                  name: `${el.qno.slice(0, -2)} ${data.questions.filter((item) => item.qno.slice(0, -2) === el.qno.slice(0, -2)).length + 1}`,
                  selected: false,
                });
              } else {
                data.questions.push({
                  ...el,
                  name: `${el.qno.slice(0, -2)}`,
                  selected: false,
                });
              }
            }
          });
          // Remove Quotas from questions array
          const removedQuotas = data.questions.filter(el => !el.isQuota);
          data.questions = [...removedQuotas]
          setQuestions(data.questions);
        }
      });
    }
  }, [questions, params, token, closestValidItem]);

  useEffect(() => {
    if (quotaRequestError) {
      setErrorMessage(quotaRequestError);
      setIsLoading(false);
    }
  }, [quotaRequestError])

  const onSelectQuestion = (e, item) => {
    const updateQuestions = [...questions];
    updateQuestions.find((el) => el.qno === item.qno).selected = e.value;
    setQuestions(updateQuestions);
  };

  const onGetCombinations = () => {
    if (questions.filter((el) => el.selected).length > 0) {
      setIsLoading(true);
      const qnos = [];
      questions.forEach((el) => {
        if (el.selected === true) {
          qnos.push(el.qno);
        }
      });
      const body = {
        qnos: qnos,
      };
      getCellTemplates(`projects/${params.name}/surveys/${params.survey}/quotas/cell-template`, token, body)
        .then((res) => {
          if (res?.error) {
            setErrorMessage(res.message ? res.message : res.error);
            setIsLoading(false);
          } else {
            setCellTemplateState(res);
            setStepperValue(stepperValue + 1);
            setIsLoading(false);
          }
        }
        );
    } else {
      setErrorMessage("You need to select at least one question");
    }
  };

  const onFinalize = () => {
    setIsLoading(true);
    addSurveyElement("Quota", "Quota", "quota", cellTemplateState);
    setSectionIndex(0)
  };

  const onChangeHandler = (e, id, name) => {
    const updatedCellTemplateState = JSON.parse(JSON.stringify(cellTemplateState))
    const updatedCells = JSON.parse(JSON.stringify(updatedCellTemplateState.cells))

    if (name === 'selected') {
      updatedCells[id][name] = e.value
    } else if (name === 'isDisabled') {
      updatedCells[id][name] = !updatedCells[id][name]
    } else {
      updatedCells[id][name] = e.target.value
    }
    setCellTemplateState({ ...cellTemplateState, cells: updatedCells })
  }

  const onToggleSelectAllRows = (e) => {
    const updatedCellTemplateState = JSON.parse(JSON.stringify(cellTemplateState))
    const updatedCells = JSON.parse(JSON.stringify(updatedCellTemplateState.cells))

    updatedCells.forEach(cell => {
      if (e.value === true) {
        cell.selected = true
      } else {
        cell.selected = false
      }
    })
    setCellTemplateState({ ...cellTemplateState, cells: updatedCells })
  }

  const onToggleActivateAllRows = (e) => {
    const updatedCellTemplateState = JSON.parse(JSON.stringify(cellTemplateState))
    const updatedCells = JSON.parse(JSON.stringify(updatedCellTemplateState.cells))

    updatedCells.forEach(cell => {
      if (e.target.value === true) {
        cell.isDisabled = false
      } else {
        cell.isDisabled = true
      }
    })
    setCellTemplateState({ ...cellTemplateState, cells: updatedCells })
  }

  const isMergeRowsDisabled = cellTemplateState?.cells.filter(cell => cell.selected === true).length < 2

  const onMergeRowsHandler = () => {
    const updatedCellTemplateState = JSON.parse(JSON.stringify(cellTemplateState))
    const updatedCells = JSON.parse(JSON.stringify(updatedCellTemplateState.cells))
    const selectedRows = updatedCells.filter(cell => cell.selected === true)

    if (selectedRows.length > 1) {
      const allExpressions = []
      const allTexts = []
      selectedRows.forEach(cell => {
        allExpressions.push(`(${cell.expression})`)
        allTexts.push(`(${cell.text})`)
      })

      const newRow = {}
      newRow.id = null
      newRow.selected = false
      newRow.isDisabled = false
      newRow.targetValue = 9999
      newRow.text = allTexts.join(' OR ')
      newRow.expression = allExpressions.join('|')

      updatedCells.forEach(cell => {
        if (cell.selected === true) {
          cell.selected = false
          cell.isDisabled = true
        }
      })

      const lastSelectedOriginalIndex = updatedCells.indexOf(selectedRows[selectedRows.length - 1])

      updatedCells.splice(lastSelectedOriginalIndex + 1, 0, newRow)
      setCellTemplateState({ ...cellTemplateState, cells: updatedCells })
    }
  }

  const selectedQuestions = questions?.filter((item) => item.selected).length;

  return (
    <Dialog className="quota-modal" title="Add quota" width="60%" height="80%" onClose={handleClose}>
      <div className="d-flex flex-column p-3 h-100">
        <Stepper className="add-quota-stepper" items={stepperItems} value={stepperValue} />
        {stepperValue === 0 ? (
          <div className="d-flex h-100 p-3">
            {questions ? (
              <Grid
                data={filterBy(questions, filter)}
                filterable={true}
                filter={filter}
                onFilterChange={(e) => setFilter(e.filter)}
                className="quota-grid-modal"
                style={{
                  maxHeight: errorMessage
                    ? "48vh"
                    : "58vh"
                }}>
                <GridColumn
                  className="test"
                  filterable={true}
                  field={"qno"}
                  placeholder="search"
                  cell={(props) => {
                    return (
                      <td style={{ width: "10%" }} className={`${selectedQuestions >= 5 && !props.dataItem.selected ? "disabled" : ""}`}>
                        <Checkbox value={props.dataItem.selected} disabled={selectedQuestions >= 5 && !props.dataItem.selected} onChange={(e) => onSelectQuestion(e, props.dataItem)} />
                      </td>
                    );
                  }}
                />
                <GridColumn
                  filterable={false}
                  cell={(props) => {
                    const icon = returnQuestionIcon(props.dataItem.type);
                    return (
                      <td className={`d-flex py-2 px-3 align-items-center gap-md ${selectedQuestions >= 5 && !props.dataItem.selected ? "disabled" : ""}`}>
                        <span className="d-flex justify-content-center align-items-center avatar-lg bg-primary rounded-circle" title={icon}>
                          <Icon type={icon} fill="white" />
                        </span>

                        <div className="d-flex gap-lg">
                          <p className="strong m-0">
                            {props.dataItem.name}
                          </p>
                          <p className="m-0">{props.dataItem.texts.en}</p>
                        </div>
                      </td>
                    );
                  }}
                />
              </Grid>
            ) : (
              <FullHeightSpinner />
            )}
          </div>
        ) : (
          <div className="d-flex flex-column overflow-hidden">
            <div className="d-flex align-items-center justify-content-between p-3">
              <h6 className="mb-0">Modify</h6>
              <button onClick={onMergeRowsHandler} disabled={isMergeRowsDisabled} className="btn btn-primary" title={isMergeRowsDisabled ? "Atleast 2 rows need to be selected!" : ""}>
                <i className="fas fa-code-merge mr-2" />
                <span>Merge cells</span>
              </button>
            </div>

            <div className="d-flex flex-column overflow-auto border-bottom">
              <table className="table table-striped table-bordered m-0">
                <thead className="text-center">
                  <tr>
                    <th>
                      <Checkbox
                        checked={cellTemplateState.cells.filter(cell => cell.selected === true).length === cellTemplateState.cells.length}
                        onChange={onToggleSelectAllRows} />
                    </th>
                    <th>
                      <Switch
                        checked={cellTemplateState.cells.filter(cell => cell.isDisabled === false).length === cellTemplateState.cells.length}
                        onChange={onToggleActivateAllRows}
                        size="small"
                      />
                    </th>
                    <th className="w-75">Cell</th>
                    <th>Target</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    cellTemplateState.cells.map((cell, key) => (
                      <tr key={key}>
                        <td style={{ verticalAlign: 'middle' }}>
                          <Checkbox
                            checked={cell.selected === true}
                            onChange={(e) => onChangeHandler(e, key, 'selected')} />
                        </td>
                        <td style={{ verticalAlign: 'middle' }}>
                          <Switch
                            checked={cell.isDisabled === false}
                            onChange={(e) => onChangeHandler(e, key, 'isDisabled')}
                            size="small"
                          />
                        </td>
                        <td style={{ verticalAlign: 'middle' }}>
                          <input
                            type="text"
                            className="w-100 p-2 border"
                            value={cell.text}
                            onChange={(e) => onChangeHandler(e, key, 'text')} />
                        </td>
                        <td style={{ verticalAlign: 'middle' }}>
                          <NumericTextBox
                            min={0}
                            className="quota-numeric-input"
                            value={cell.targetValue}
                            onChange={(e) => onChangeHandler(e, key, 'targetValue')}
                          />
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>

            <div className="d-flex flex-column p-3">
              <h6 className="my-4 text-dark">Options</h6>
              <div className="d-flex flex-column">
                <div className="btn-group mb-3 w-50">
                  <Button
                    className={`btn w-50 ${cellTemplateState.method === "LeastFill" ? "btn-primary" : "btn-secondary"} `}
                    onClick={() => setCellTemplateState({ ...cellTemplateState, method: "LeastFill" })}
                    disabled={!cellTemplateState.containsMultiQuestion}>Least fill</Button>
                  <Button
                    className={`btn  w-50 ${cellTemplateState.method === "Random" ? "btn-primary" : "btn-secondary"} `}
                    onClick={() => setCellTemplateState({ ...cellTemplateState, method: "Random" })}
                    disabled={!cellTemplateState.containsMultiQuestion}>Random</Button>
                </div>
              </div>
              <div className="d-flex">
                <div className="d-flex flex-column mr-4">
                  <p className="h6 m-0">Maximum</p>
                  <NumericTextBox
                    value={cellTemplateState.maximumMultiSelect}
                    onChange={(e) => setCellTemplateState({ ...cellTemplateState, maximumMultiSelect: e.target.value })}
                    min={1}
                    disabled={!cellTemplateState.containsMultiQuestion}
                  />
                </div>
                <div className="d-flex flex-grow-1">
                  <div className="d-flex flex-column w-75">
                    <p className="h6 m-0">Label</p>
                    <Input
                      className="w-100"
                      title="Labels can only contain characters a-z, A-Z, 0-9, _ and and has to contain one uppercase or lowercase letter"
                      value={cellTemplateState.label}
                      onChange={(e) => setCellTemplateState({ ...cellTemplateState, label: e.target.value })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {errorMessage &&
          <div className="d-flex align-items-center alert alert-danger alert-dismissible fade show" role="alert">
            <i className="fas fa-2x fa-exclamation-triangle text-danger pr-3" />
            <strong className="text-center w-100">
              <p className="m-0">An error occured:</p>
              <p className="m-0">{errorMessage === "Invalid quota label (ID)" ? "Labels can only contain characters a-z, A-Z, 0-9, _ and and has to contain one uppercase or lowercase letter" : errorMessage}</p>
            </strong>
            <button type="button" className="close" aria-label="Close" onClick={() => { clearErrorMessage(); setErrorMessage("") }}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        }
      </div>
      <DialogActionsBar>
        <Button className="btn btn-secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button className="btn btn-primary" disabled={stepperValue === 0} onClick={() => setStepperValue(stepperValue - 1)}>
          Back
        </Button>
        {stepperValue !== 1 ? (
          <Button className="btn btn-primary" onClick={() => onGetCombinations()} disabled={isLoading}>
            Next {isLoading && <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" />}
          </Button>
        ) : (
          <Button
            className="btn btn-primary"
            onClick={() => onFinalize()}
            disabled={cellTemplateState.label === "" || cellTemplateState.label === null || isLoading}
          >
            Finalize
            {isLoading && <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" />}
          </Button>
        )}
      </DialogActionsBar>
    </Dialog>
  );
};
