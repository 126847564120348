import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { ErrorMessage } from '../../../../shared/ErrorMessage/ErrorMessage';
import type { RootState } from '../../../../../store/reducers/rootReducer';
import type { APIKey } from '../../../../../interfaces/adminInterfaces/adminInterfaces';
import { fetchDelete } from '../../../../../services/services';

interface Props {
  apiKeys: APIKey[] | null
  onHide: () => void
  updateData: (data: APIKey[]) => void
}

export const RemoveAPIKeyModal = ({ apiKeys, onHide, updateData }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { showRemoveAPIKeyModal } = useSelector((theState: RootState) => theState.adminReducer);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onDeleteHandler = () => {
    if (showRemoveAPIKeyModal.apiKey?.id) {
      setIsLoading(true);
      fetchDelete(`apikeys/${showRemoveAPIKeyModal.apiKey.id}`, token)
        .then((res: TODO) => {
          setIsLoading(false);
          if (res.status === 200) {
            if (apiKeys && apiKeys.length > 0) {
              const updatedApiKeys = [...apiKeys]
              updatedApiKeys.splice(updatedApiKeys.indexOf(showRemoveAPIKeyModal.apiKey))
              updateData(updatedApiKeys)
              onHide()
            }
          } else {
            setErrorMessage(true)
          }
        })
    }
  }

  return (
    <Dialog
      width={450}
      onClose={onHide}
      title={"Delete API Key"}>
      <div className="px-3">
        <p className="text-center mt-3">Are you sure you would like to delete this API Key?</p>
        {
          errorMessage &&
          <ErrorMessage
            type="alert"
            errorMessage="Something went wrong, please try again!" />
        }
      </div>
      <DialogActionsBar>
        <button type='button' className="k-button k-button-solid-base" onClick={onHide}>Cancel</button>
        <button disabled={isLoading} type='button' className="k-button btn-danger" onClick={onDeleteHandler}>Delete</button>
      </DialogActionsBar>
    </Dialog >
  )
}