import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useState } from 'react';

interface Props {
  onHide: () => void
  onSelect: (type: string) => void
}

const SMSProviderTypes = [
  { id: 'twilio', name: "Twilio", icon: "fa-paper-plane", theme: 'orange' }
]

export const SMSProviderTypeModal = ({ onHide, onSelect }: Props) => {
  const [selectedType, setSelectedType] = useState('twilio');

  return (
    <Dialog className="import-dialog" minWidth={700} title={"Choose sms provider type"} onClose={onHide}>
      <div className="py-5">
        <div className="container">
          <div className="row justify-content-center">
            {
              SMSProviderTypes.map((type) => (
                <div key={type.id} className="col-md-3" onClick={() => setSelectedType(type.id)}>
                  <div className={`h-100 project-card card p-3 text-center ${selectedType === type.id ? `text-${type.theme} border-${type.theme}` : 'text-muted'}`}>
                    <i className={`fas fa-2x ${type.icon}`} />
                    <h4 className="mt-3 mb-0">{type.name}</h4>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>

      <DialogActionsBar>
        <button type='button' className="k-button k-button-solid-base" onClick={onHide}>Cancel</button>
        <button type='button' className="k-button k-button-solid-primary" onClick={() => onSelect(selectedType)}>Next</button>
      </DialogActionsBar>
    </Dialog >
  )
}