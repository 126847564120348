import type { ChangeEvent } from "react";
import { useSelector } from "react-redux";

import type { Answer, Question } from "../../types/audienceTargetingTypes"
import type { RootState } from "../../../../../store/reducers/rootReducer";
import type { ScreeningAnswers } from "../../../../../interfaces/auddineceAccessInterfaces/batchOverviewTypes";

type Props = {
  selectedAnswers: Answer[];
  selectedQuestion: Question;
  setSelectedAnswers: (answer: Answer[] | ScreeningAnswers[]) => void;
  setSelectAllAnswers: (selectAll: boolean) => void;
  selectAllAnswers: boolean
}

export const QuotaModalAnswersTable = ({ selectedAnswers, selectedQuestion, setSelectedAnswers, setSelectAllAnswers, selectAllAnswers }: Props) => {
  const { targeting } = useSelector((state: RootState) => state.batchDataReducer);

  const selectedQuestionTargeting = targeting?.screenings?.filter((item) => item.questionId === selectedQuestion.id)[0];
  const selectedQuestionAnswers = selectedQuestionTargeting ? selectedQuestionTargeting.answers : selectedQuestion.answers;
  const listThing = selectedQuestionTargeting ? selectedQuestionTargeting : selectedQuestion;

  const handleSelectAnswer = (e: ChangeEvent<HTMLInputElement>, answer: Answer | ScreeningAnswers) => {
    if (e.target.checked) {
      if (selectedAnswers.length === 0) {
        setSelectedAnswers([answer])
      } else {
        setSelectedAnswers([...selectedAnswers, answer])
      }
    } else {
      const removeItem = selectedAnswers.filter((item) => item.id !== answer.id);
      setSelectedAnswers(removeItem)
    }
  }

  const handleSelectAllAnswers = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectAllAnswers(e.target.checked);
    if (e.target.checked) {

      if (selectedAnswers.length === 0) {
        setSelectedAnswers(selectedQuestionAnswers)
      } else {
        setSelectedAnswers([...selectedAnswers, ...selectedQuestionAnswers])
      }
    } else {
      setSelectedAnswers([]);
    }
  }

  return (
    <div className="overflow-auto" style={{ maxHeight: "500px" }}>
      <table className="table table-striped table-bordered m-0 text-center">
        <thead>
          <tr>
            <th scope="row" className="p-2">
              <input
                className="k-checkbox mb-0 box-shadow-none"
                type="checkbox"
                id="selectAllCheckbox"
                checked={selectAllAnswers}
                onChange={(e) => handleSelectAllAnswers(e)}
              />
            </th>
            <th className="p-2">
              {selectedQuestion.name}
            </th>
          </tr>
        </thead>
        <tbody>
          {listThing.answers.map((answer) =>
            <tr key={answer.id}>
              <td className="p-2">
                <input
                  className="k-checkbox mb-0 box-shadow-none"
                  type="checkbox"
                  id={answer.text}
                  checked={selectedAnswers.some((item) => item.id === answer.id)}
                  onChange={(e) => { handleSelectAnswer(e, answer) }}
                />
              </td>
              <td className="p-2 text-left">
                {answer.text}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}