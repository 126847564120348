import { Icon } from "../../../shared/Icon/Icon"
import type { ProjectsSortingType, SortingState } from "../ProjectsTypes/projectTypes"

export const SortingArrow = ({ field, text, sort }: { field: ProjectsSortingType, text: string, sort: SortingState }) => {
  return (
    <div className="d-flex align-items-center">
      <span className='mr-0'>{text}</span>
      {
        sort.field === field ?
          sort.dir === 'desc' ?
            <div style={{ transform: 'rotate(180deg)' }}>
              <Icon type="chevron-mini-down-alt" />
            </div>
            :
            <div>
              <Icon type="chevron-mini-down-alt" />
            </div>
          : <div><Icon type='lists' className="h-20" /></div>
      }
    </div>
  )
}