import { useState, useEffect, Fragment } from 'react';
import Editor from "@monaco-editor/react";
import { useDispatch, useSelector } from 'react-redux';
import { ColorPicker } from "@progress/kendo-react-inputs";

import FileManager from '../../../shared/FileManager/FileManager';
import type { RootState } from '../../../../store/reducers/rootReducer';
import { fetchPatch as saveCustomizations, fetchGetJson } from '../../../../services/services';

export const CustomizationsTabContent = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({ welcomeHtml: '', frontPageUrl: '', headerLogoUrl: '', headerBackgroundColor: '', customDns: '' });
  const [showFileManager, setShowFileManager] = useState<{ show?: boolean, type?: string }>({ show: false, type: '' });

  useEffect(() => {
    fetchGetJson("customisations/customsettings", token)
      .then((res: TODO) => {
        if (res && !res.error && !res.message) {
          setFormData({
            customDns: res.customDns ? res.customDns : '',
            welcomeHtml: res.welcomeHtml ? res.welcomeHtml : '',
            frontPageUrl: res.frontPageLogoUrl ? res.frontPageLogoUrl : '',
            headerLogoUrl: res.headerLogoUrl ? res.headerLogoUrl : '',
            headerBackgroundColor: res.headerBackgroundColor ? res.headerBackgroundColor : ''
          })
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.message || res.error } })
        }
      })
  }, [token, dispatch])

  const updateFormData = (value: TODO, type: string) => {
    setFormData({
      ...formData,
      [type]: value
    })
  }

  const updateCustomizations = () => {
    setIsLoading(true)
    const body = {
      "welcomeHtml": formData.welcomeHtml,
      "headerLogoUrl": formData.headerLogoUrl,
      "frontPageUrlLogoUrl": formData.frontPageUrl,
      "headerBackgroundColor": formData.headerBackgroundColor,
    }
    saveCustomizations("customisations/customsettings", token, body)
      .then((res: TODO) => {
        setIsLoading(false)
        if (res && res.status === 200) {
          dispatch({ type: 'SET_SHOULD_UPDATE_NAVBAR_DATA', payload: true })
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The project customizations have been updated successfully.' } })
        } else return res.json();
      })
      .then((res: TODO) => {
        if (res.error || res.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error || res.message } })
        }
      })
  }

  return (
    <Fragment>
      {
        showFileManager.show &&
        <FileManager
          type={'public'}
          displayType="modal"
          onHide={() => setShowFileManager({ show: false })} token={token}
          onSelect={(value) => updateFormData(value, showFileManager.type || "")}
        />
      }
      <div className="overflow-auto h-100 mt-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <div className="d-flex flex-column gap-lg p-3">
                <p className="text-muted m-0">The changes made to the options bellow will appear when you share a project externally.</p>

                <div className="input-group align-items-center gap-lg">
                  <div className="custom-file">
                    <input id="frontPageUrl" onChange={(e) => updateFormData(e.target.value, 'frontPageUrl')} type="text" className="form-control" value={formData.frontPageUrl} placeholder={"Front page logo url"} />
                  </div>
                  <button type='button' onClick={() => setShowFileManager({ show: true, type: 'frontPageUrl' })} className="btn btn-primary btn-file">
                    Browse
                  </button>
                </div>

                <div className="input-group align-items-center gap-lg">
                  <div className="custom-file">
                    <input id="headerLogoUrl" onChange={(e) => updateFormData(e.target.value, 'headerLogoUrl')} type="text" className="form-control" value={formData.headerLogoUrl} placeholder={"Header logo url"} />
                  </div>
                  <button type='button' onClick={() => setShowFileManager({ show: true, type: 'headerLogoUrl' })} className="btn btn-primary btn-file">
                    Browse
                  </button>
                </div>

                <div className="input-group gap-lg">
                  <div className="custom-file">
                    <input id="headerBackgroundColor" readOnly value={formData.headerBackgroundColor ? formData.headerBackgroundColor : 'rgba(39, 112, 190, 1)'} type="text" className="form-control" placeholder={"Header background color"} />
                  </div>
                  <ColorPicker view="gradient" value={formData.headerBackgroundColor ? formData.headerBackgroundColor : 'rgba(39, 112, 190, 1)'} onChange={e => updateFormData(e.value, 'headerBackgroundColor')} gradientSettings={{ opacity: false, }} />
                </div>

                <input id="customDNS" value={formData.customDns} placeholder={'Custom DNS'} type="text" className="form-control" readOnly />
              </div>
            </div>

            <div className="col-lg-6 col-sm-10 pt-3">
              <p className="text-muted">Enter HTML:</p>
              <Editor
                height="40vh"
                theme={"vs-dark"}
                defaultLanguage="html"
                value={formData.welcomeHtml}
                onChange={(e) => updateFormData(e, 'welcomeHtml')}
              />
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end m-5">
          <button
            type='button'
            disabled={isLoading}
            onClick={() => updateCustomizations()}
            className="btn btn-primary px-5">
            {
              isLoading ?
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                :
                <span>Update</span>
            }
          </button>
        </div>
      </div>
    </Fragment>
  )
}