export default () => {
  let clearQuestionItems = [
    {
      actionName: 'Clear all',
      action: 'TOPLINE_CLEAR_QUESTIONS'
    },
    {
      actionName: 'Clear rows',
      action: 'TOPLINE_CLEAR_QUESTION_ROWS'
    },
    {
      actionName: 'Clear selected',
      action: 'TOPLINE_CLEAR_QUESTION_CHECKED'
    },
    {
      actionName: 'Clear options',
      action: 'TOPLINE_CLEAR_QUESTION_OPTIONS'
    }

  ]
  return clearQuestionItems
}