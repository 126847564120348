import React, { useState, Fragment } from 'react';
import { Redirect } from 'react-router-dom'
import { Input } from '@progress/kendo-react-inputs';
import { useDispatch, useSelector } from 'react-redux';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { PanelBar, PanelBarItem, TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { useAuth0 } from '@auth0/auth0-react';

import { ErrorMessage } from '../../../../../shared/ErrorMessage/ErrorMessage';
import TabTitle from '../../../../Analyze/components/dashboard/main/TabTitle/TabTitle';
import ImportTypeSwitch from '../../../../../shared/ImportModal/ImportTypeSwitch/ImportTypeSwitch';
import { QuickImportFileInput } from '../../../../../shared/QuickImport/QuickImportFileInput/QuickImportFileInput';
import { onUploadHandler } from '../../../../../shared/helpers/project/advancedImportHelpers/advancedImportHelpers';
import GenericLibrary from '../../../../../Survey/SurveyTabContent/SurveyDesignTabContent/helpers/LibraryModal/GenericLibrary';
import { returnAcceptedFileExtension } from '../../../../../shared/helpers/project/returnAcceptedFileType/returnAcceptedFileType';

export const AdvancedImport = ({ onAdvancedImportOptionsChangeHandler, automaticMergeOptions, showCollapse, onUpdateCollapse, selectedTab, setSelectedTab, tabsData, formData, setFormData, caseFile, setCaseFile, dataMetaFile, setDataMetaFile, projectdetails, name, updateData, isAdvancedImportModalShown, projectDocuments, ...props }) => {
  const { user } = useAuth0();
  const { token } = useSelector((state) => state.tokenStateReducer);
  const dispatch = useDispatch();
  const { advancedImportStatusData } = useSelector(state => state.advancedImportStatusReducer);
  const [genericLibrary, setGenericLibrary] = useState(false)
  const [loadLibraryDataset, setLoadLibraryDataset] = useState(false);

  const onChangeHandler = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const onChangeTabHandler = (e) => {
    if (e.selected === 3) {
      dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "Advanced import options for Miprox files are unavailable." } })
    } else {
      setSelectedTab(e.selected)
      setDataMetaFile(null)
      setCaseFile(null)
      setFormData({
        ...formData,
        name: '',
        description: '',
        fileType: tabsData[e.selected].title
      })
    }
  }

  const onInputChangeHandler = (e) => {
    const existingDatasets = projectDocuments.map(el => el.name)
    const selectedFile = e.target.files[0]
    if (selectedFile) {
      const fileName = selectedFile.name.substr(0, selectedFile.name.lastIndexOf("."));
      const existingDatasetsVersion = projectDocuments.filter(el => el.name === fileName).length
      if (formData.fileType === 'Triple S') {
        if (e.target.name === 'dataMetaFile') {
          setDataMetaFile({ file: selectedFile, fileName: selectedFile.name, extension: returnAcceptedFileExtension(formData.fileType) })
        } else {
          setCaseFile({ file: selectedFile, fileName: selectedFile.name, extension: returnAcceptedFileExtension(formData.fileType) })
        }
      } else {
        setDataMetaFile({ file: selectedFile, fileName: selectedFile.name, extension: returnAcceptedFileExtension(formData.fileType) })
      }
      setFormData({
        ...formData,
        name: selectedFile && existingDatasets.includes(fileName) ? `${fileName}(${existingDatasetsVersion})` : fileName
      })
    } else {
      setDataMetaFile({ ...dataMetaFile })
      setCaseFile({ ...caseFile })
      setFormData({ ...formData })
    }
  }

  const cancelImportHandler = () => {
    props.onHide()
    window.location.reload()
  }

  const onShowGenericLibraryHandler = (value) => {
    if (value === true) {
      setGenericLibrary(true)
    } else {
      setGenericLibrary(false)
    }
  }

  const onCloseHandler = () => {
    setGenericLibrary(false)
    setLoadLibraryDataset(false)
  }

  const importElement = (e) => {
    setLoadLibraryDataset(true)
    onUploadHandler(dispatch, advancedImportStatusData, formData, dataMetaFile, caseFile, token, e, updateData, redirectData, automaticMergeOptions, onCloseHandler)
  }

  const redirectData = projectdetails.id;

  if (advancedImportStatusData.redirect) {
    return (
      <Redirect
        to={{ pathname: `/projects/${projectdetails.id}/import`, state: { projectId: projectdetails.id, uploadId: advancedImportStatusData.uploadId, projectName: projectdetails.name } }} />
    )
  }
  return (
    <Fragment>
      {genericLibrary &&
        <GenericLibrary
          type="import-template"
          scope="import-template"
          handleClose={onCloseHandler}
          addingFromLibrary={true}
          importElement={importElement}
          loadLibraryDataset={loadLibraryDataset}
        />
      }
      <TabStrip selected={selectedTab} onSelect={onChangeTabHandler} className="analyze-tabs">
        {
          tabsData.map((tab) => (
            <TabStripTab key={tab.title} disabled={tab.disabled} title={<TabTitle icon={tab.icon} title={tab.title} user={user} />}>
              <div className="p-3">
                <QuickImportFileInput
                  selectedFileType={formData.fileType}
                  onInputChangeHandler={onInputChangeHandler}
                  dataMetaFile={dataMetaFile}
                  caseFile={caseFile}
                />

                {
                  advancedImportStatusData.uploadErrorMessage &&
                  <div className="alert alert-danger" role="alert">
                    <span>Please select one of the following valid file types:</span>
                    <ul className="m-0">
                      <li>Spss (.sav)</li>
                      <li>Excel (.xls or .xlsx)</li>
                      <li>Triple S (.sss and .csv)</li>
                    </ul>
                  </div>
                }
                <Input
                  name="name"
                  className="w-100 mb-3"
                  placeholder="Name"
                  required={true}
                  value={formData.name}
                  onChange={onChangeHandler}
                  valid={!projectDocuments.map(el => el.name).includes(formData.name)}
                />
                {projectDocuments.map(el => el.name).includes(formData.name) &&
                  <div className="mt-3">
                    <ErrorMessage
                      type="alert"
                      errorMessage={"A dataset with this name already exists"} />
                  </div>
                }
                <Input
                  className="w-100"
                  name="description"
                  placeholder="Description"
                  value={formData.description}
                  onChange={onChangeHandler}
                />
                {
                  advancedImportStatusData.importErrorMessage &&
                  <div className="mt-3">
                    <ErrorMessage
                      type="alert"
                      errorMessage={advancedImportStatusData.importErrorMessage} />
                  </div>
                }
              </div>
            </TabStripTab>
          ))
        }
      </TabStrip>
      <div className="accordion p-3" id="accordionExample">
        <PanelBar onSelect={onUpdateCollapse}>
          <PanelBarItem disabled={selectedTab === 3} title="Advanced import" expanded={showCollapse}>
            <div className="d-flex flex-column gap-lg py-2 px-3">
              <ImportTypeSwitch automaticMergeOptions={automaticMergeOptions} user={user} isAdvancedImportModalShown={isAdvancedImportModalShown} onAdvancedImportOptionsChangeHandler={onAdvancedImportOptionsChangeHandler} selectedTab={selectedTab} onShowGenericLibraryHandler={onShowGenericLibraryHandler} genericLibrary={genericLibrary} />
            </div>
          </PanelBarItem>
        </PanelBar>
      </div>

      <DialogActionsBar>
        <button
          type='button'
          className="k-button k-button-solid-base"
          onClick={advancedImportStatusData.isInProgress ? cancelImportHandler : props.onHide}>Cancel</button>
        {
          advancedImportStatusData.isInProgress ?
            <button type='button' className="k-button k-button-solid-base" onClick={props.onHide}>Close</button>
            :
            <button
              type='button'
              disabled={advancedImportStatusData.isInProgress || projectDocuments.map(el => el.name).includes(formData.name)}
              className="k-button k-button-solid-primary"
              onClick={() => onUploadHandler(dispatch, advancedImportStatusData, formData, dataMetaFile, caseFile, token, projectdetails, updateData, redirectData, automaticMergeOptions, onCloseHandler)}
            >
              Next
            </button>
        }
      </DialogActionsBar>
    </Fragment>
  );
}