import React from 'react';

import { Icon } from '../../../../../shared/Icon/Icon';

interface IProps {
  tabType: string
  dashboardData: any
}
interface IEl {
  key: string
  value: number
}

export const FielworkDashboardReportTabContent: React.FC<IProps> = ({ tabType, dashboardData }) => {
  const isWhatPercentOf = (x: number, y: number, decimals: number) => {
    return ((x / y) * 100).toFixed(decimals);
  }

  const screenOuts: IEl[] = dashboardData.overviewReport.screenOuts && Object.entries(dashboardData.overviewReport.screenOuts.labels).map(([key, value]) => ({ key, value }))
  const dropOuts: IEl[] = dashboardData.overviewReport.dropOuts && Object.entries(dashboardData.overviewReport.dropOuts.labels).map(([key, value]) => ({ key, value }))
  const quotaFails: IEl[] = dashboardData.overviewReport.quotaFails && Object.entries(dashboardData.overviewReport.quotaFails.labels).map(([key, value]) => ({ key, value }))
  const qualityFails: IEl[] = dashboardData.overviewReport.qualityFails && Object.entries(dashboardData.overviewReport.qualityFails.labels).map(([key, value]) => ({ key, value }))
  if (tabType === 'screenOut') {
    return (
      <React.Fragment>
        <div className="d-flex justify-content-between align-items-center border border-light rounded p-3 m-3">
          <div className="strong">
            Total
          </div>
          <div className="strong">
            {dashboardData.screenedOuts ? dashboardData.screenedOuts : 0}
          </div>
        </div>
        {screenOuts && screenOuts.map((el: IEl, key: number) => {
          return (
            <div className='border rounded d-flex align-items-center p-3 m-3' key={key}>
              <div className='p-2 mr-3 rounded' style={{ backgroundColor: "rgba(23, 33, 71, 0.05)" }}>
                <Icon type={"globe"} />
              </div>
              <div style={{ width: "100%" }}>
                <div className='d-flex justify-content-between align-items-center'>
                  <small className='m-0 strong'>{el.key}</small>
                  <p className='m-0 strong'>{el.value}</p>
                </div>
                <div className="d-flex justify-content-between align-items-baseline">
                  <div className="flex-grow-1 mr-3" style={{ display: "table", width: "100%" }}>
                    <div style={{ borderRadius: "8px", backgroundColor: "#edeef1" }}>
                      <div style={{ height: "4px", backgroundColor: "rgba(53, 136, 239)", borderRadius: "8px", width: `${isWhatPercentOf(el.value, dashboardData.screenedOuts, 0)}%` }}></div>
                    </div>
                  </div>
                  <small className='text-muted'>{isWhatPercentOf(el.value, dashboardData.screenedOuts, 0)}%</small>
                </div>
              </div>
            </div>
          )
        })}
      </React.Fragment>
    )
  } else if (tabType === 'dropOut') {
    return (
      <React.Fragment>
        <div className="d-flex justify-content-between align-items-center border border-light rounded p-3 m-3">
          <div className="strong">
            Total
          </div>
          <div className="strong">
            {dashboardData.dropOuts ? dashboardData.dropOuts : 0}
          </div>
        </div>
        {dropOuts && dropOuts.map((el: IEl, key: number) => {
          return (
            <div className='border rounded d-flex align-items-center p-3 m-3' key={key}>
              <div className='p-2 mr-3 rounded' style={{ backgroundColor: "rgba(23, 33, 71, 0.05)" }}>
                <Icon type={"globe"} />
              </div>
              <div style={{ width: "100%" }}>
                <div className='d-flex justify-content-between align-items-center'>
                  <span className='m-0 fieldwork-dashboard-stats'>{el.key}</span>
                  <span className='m-0 fieldwork-dashboard-stats'>{el.value}</span>
                </div>
                <div className="d-flex justify-content-between align-items-baseline">
                  <div className="flex-grow-1 mr-3" style={{ display: "table", width: "100%" }}>
                    <div style={{ borderRadius: "8px", backgroundColor: "#edeef1" }}>
                      <div style={{ height: "4px", backgroundColor: "rgba(53, 136, 239)", borderRadius: "8px", width: `${isWhatPercentOf(el.value, dashboardData.dropOuts, 0)}%` }}></div>
                    </div>
                  </div>
                  <small className='text-muted'>{isWhatPercentOf(el.value, dashboardData.dropOuts, 0)}%</small>
                </div>
              </div>
            </div>
          )
        })}
      </React.Fragment>
    )
  } else if (tabType === 'quotaFull') {
    return (
      <React.Fragment>
        <div className="d-flex justify-content-between align-items-center border border-light rounded p-3 m-3">
          <div className="strong">
            Total
          </div>
          <div className="strong">
            {dashboardData.quotaFulls ? dashboardData.quotaFulls : 0}
          </div>
        </div>
        {quotaFails && quotaFails.map((el: IEl, key: number) => {
          return (
            <div className='border rounded d-flex align-items-center p-3 m-3' key={key}>
              <div className='p-2 mr-3 rounded' style={{ backgroundColor: "rgba(23, 33, 71, 0.05)" }}>
                <Icon type={"globe"} />
              </div>
              <div style={{ width: "100%" }}>
                <div className='d-flex justify-content-between align-items-center'>
                  <small className='m-0 strong'>{el.key}</small>
                  <p className='m-0 strong'>{el.value}</p>
                </div>
                <div className="d-flex justify-content-between align-items-baseline">
                  <div className="flex-grow-1 mr-3" style={{ display: "table", width: "100%" }}>
                    <div style={{ borderRadius: "8px", backgroundColor: "#edeef1" }}>
                      <div style={{ height: "4px", backgroundColor: "rgba(53, 136, 239)", borderRadius: "8px", width: `${isWhatPercentOf(el.value, dashboardData.quotaFulls, 0)}%` }}></div>
                    </div>
                  </div>
                  <small className='text-muted'>{isWhatPercentOf(el.value, dashboardData.quotaFulls, 0)}%</small>
                </div>
              </div>
            </div>
          )
        })}
      </React.Fragment>
    )
  } else if (tabType === 'qualityFail') {
    return (
      <React.Fragment>
        <div className="d-flex justify-content-between align-items-center border border-light rounded p-3 m-3">
          <div className="strong">
            Total
          </div>
          <div className="strong">
            {dashboardData.qualityFails ? dashboardData.qualityFails : 0}
          </div>
        </div>
        {qualityFails && qualityFails.map((el: IEl, key: number) => {
          return (
            <div className='border rounded d-flex align-items-center p-3 m-3' key={key}>
              <div className='p-2 mr-3 rounded' style={{ backgroundColor: "rgba(23, 33, 71, 0.05)" }}>
                <Icon type={"globe"} />
              </div>
              <div style={{ width: "100%" }}>
                <div className='d-flex justify-content-between align-items-center'>
                  <small className='m-0 strong'>{el.key}</small>
                  <p className='m-0 strong'>{el.value}</p>
                </div>
                <div className="d-flex justify-content-between align-items-baseline">
                  <div className="flex-grow-1 mr-3" style={{ display: "table", width: "100%" }}>
                    <div style={{ borderRadius: "8px", backgroundColor: "#edeef1" }}>
                      <div style={{ height: "4px", backgroundColor: "rgba(53, 136, 239)", borderRadius: "8px", width: `${isWhatPercentOf(el.value, dashboardData.qualityFails, 0)}%` }}></div>
                    </div>
                  </div>
                  <small className='text-muted'>{isWhatPercentOf(el.value, dashboardData.qualityFails, 0)}%</small>
                </div>
              </div>
            </div>
          )
        })}
      </React.Fragment>
    )
  } else {
    return (
      <div></div>
    )
  }
}
