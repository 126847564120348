import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../../../store/reducers/rootReducer';
import { ShareProjectSubAccountUsersModal } from './ShareProjectSubAccountUsersModal/ShareProjectSubAccountUsersModal';
import { returnNumberOfSelectedSubAccountUsers } from '../../../../../shared/helpers/returnNumberOfSelectedSubAccountUsers/returnNumberOfSelectedSubAccountUsers';

interface Props {
  shareDetails: any
  reloadProject: () => void
}

export const ShareProjectUsersTabContent = ({ shareDetails, reloadProject }: Props) => {
  const [showSubAccountUsersModal, setShowSubAccountUsersModal] = useState<{ show: boolean, subAccount: any }>({ show: false, subAccount: null })
  const { subAccounts, invalidSubAccountUsers } = useSelector((theState: RootState) => theState.projectExternalShareStateReducer);

  return (
    <Fragment>
      {
        showSubAccountUsersModal.show &&
        <ShareProjectSubAccountUsersModal
          shareDetails={shareDetails}
          reloadProject={reloadProject}
          subAccount={showSubAccountUsersModal.subAccount}
          onHide={() => setShowSubAccountUsersModal({ show: false, subAccount: null })} />
      }
      <h6 className="text-muted text-center my-4">
        A sub account is managed by you and it allows you to easily share projects with a group of people. <br />
        Sub accounts can be setup in the Admin section.
      </h6>

      <table className="table table-striped table-bordered" style={{ height: '350px', overflowY: 'scroll' }}>
        <tbody>
          {
            subAccounts.map((subAccount: any, key: number) => (
              <tr
                key={key}
                className={`list-group-item list-group-item-action p-0 border-0 cursor-pointer`}
                onClick={() => setShowSubAccountUsersModal({ show: true, subAccount: subAccount })}>
                <td>
                  <i className="fas fa-user-circle px-3"></i>
                </td>
                <td className="w-100 text-left pl-4">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <span>{subAccount.name}</span>
                      {
                        invalidSubAccountUsers.length > 0 && invalidSubAccountUsers.find((user: any) => user.subAccountId === subAccount.id) &&
                        <i className="fas fa-exclamation-circle text-danger ml-3"></i>
                      }
                    </div>
                    <div className="d-flex align-items-center">
                      {
                        returnNumberOfSelectedSubAccountUsers(subAccount.users, subAccount.id, shareDetails) > 0 &&
                        <span className="badge badge-pill badge-primary py-1 px-2">
                          <i className="fas fa-user-check"></i>
                        </span>
                      }
                    </div>
                  </div>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </Fragment>
  )
}