import { Fragment } from 'react';
import type { EmailProvider } from '../../../../../../interfaces/emailProviderInterfaces/emailProviderInterfaces';

interface Props {
  formData: TODO
  stepperValue: number
  isInProgress: boolean | null
  isLoading?: boolean
  emailProvider?: EmailProvider | null
  handleClose: () => void
  onSubmitHandler?: (e: TODO) => void
  onSendTestEmailHandler?: () => void
  setStepperValue: (value: number) => void
}

export const EmailProvidersActionBars = ({ formData, isInProgress, isLoading, stepperValue, emailProvider, handleClose, onSubmitHandler, setStepperValue, onSendTestEmailHandler }: Props) => {
  let isSendTestEmailButtonDisabled = true
  if (onSendTestEmailHandler) {
    if (formData.relay && formData.port && formData.username && formData.password) {
      isSendTestEmailButtonDisabled = false
    } else if (formData.publicApiKey && formData.privateApiKey) {
      isSendTestEmailButtonDisabled = false
    } else if (formData.apiKey) {
      isSendTestEmailButtonDisabled = false
    } else {
      isSendTestEmailButtonDisabled = true
    }
  } else {
    isSendTestEmailButtonDisabled = true
  }

  return (
    <Fragment>
      <button
        type="button"
        className="k-button btn btn-secondary"
        onClick={handleClose}>Cancel
      </button>
      {
        stepperValue === 1 &&
        <button
          type="button"
          className="k-button btn btn-secondary"
          onClick={() => setStepperValue(stepperValue - 1)}>Back
        </button>
      }
      {
        !emailProvider && isInProgress !== null &&
        <button
          type="button"
          className="k-button btn-info"
          disabled={!!(isSendTestEmailButtonDisabled || isInProgress)}
          onClick={onSendTestEmailHandler}>
          {
            isInProgress &&
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          }
          Send test email
        </button>
      }
      {
        stepperValue === 0 &&
        <button
          type="submit"
          onClick={onSubmitHandler}
          className="k-button btn btn-primary">Next
        </button>
      }
      {
        stepperValue === 1 &&
        <button
          type="submit"
          disabled={isLoading}
          onClick={onSubmitHandler}
          className="k-button btn btn-primary">
          {emailProvider ? 'Update' : 'Create'}
        </button>
      }
    </Fragment>
  )
}