import disableOptionByPlan from "../disableOptionByPlan/disableOptionByPlan"

export default (data, user, groups) => {
  let initialBooleanOptions = ['XtCatValColPercent', 'XtIncludeTotalColumn', 'XtPctSignFilt', 'XtPctSignHori', 'XtPctSignHoriSum', 'XtPctSignVert', 'XtPctSignVertSum', 'XtQuaValAverage']
  let initialDropdownValuesFirst = ['XtDecimalAccumulated', 'XtDecimalAverage', 'XtDecimalChi2', 'XtDecimalQuantDeriv', 'XtQno', 'XtChi2Arrow']
  let initialDropdownValuesSecond = ['XtCategoricalBase', 'XtQuantitativeBase']

  let list = {}
  data.forEach(option => {
    if (option.type === "Boolean") {
      if (initialBooleanOptions.includes(option.id)) {
        let disabled = disableOptionByPlan(option, user, groups)
        if (disabled === false) {
          list[option.id] = true
        }
        else (
          list[option.id] = true
        )
      }
      else {
        list[option.id] = false
      }
    }
    else if (option.type === "String") {
      list[option.id] = ''
    }
    else if (option.type === "Int32" || option.type === "Double") {
      list[option.id] = 0
    }
    else if (option.id === 'XtSelectedWeight') {
      list[option.id] = 'None'
    }
    else {
      if (initialDropdownValuesFirst.includes(option.id)) {
        let disabled = disableOptionByPlan(option, user, groups)
        if (disabled === false) {
          list[option.id] = 1
        }
        else {
          list[option.id] = 0
        }
      }
      else if (initialDropdownValuesSecond.includes(option.id)) {
        let disabled = disableOptionByPlan(option, user, groups)
        if (disabled === false) {
          list[option.id] = 2
        }
        else {
          list[option.id] = 0
        }
      }
      else {
        list[option.id] = 0
      }
    }
  })
  return list
}