export default (data, checkedQuestions, initialData, toggleSearch) => {
  const updatedData = JSON.parse(JSON.stringify(data))
  const updatedInitialData = JSON.parse(JSON.stringify(initialData))
  const updatedCheckedQuestions = JSON.parse(JSON.stringify(checkedQuestions))

  if (checkedQuestions.filter(el => !el.disabled).length === checkedQuestions.length || checkedQuestions.filter(el => el.disabled).length === checkedQuestions.length) {
    updatedInitialData.forEach(val => {
      updatedCheckedQuestions.forEach(el => {
        if (el.id === val.id) {
          if (el.disabled) {
            val.disabled = false
            val.checked = false
          } else {
            val.disabled = true
            val.checked = false
          }
        }
      })
    })
    updatedData.forEach(val => {
      updatedCheckedQuestions.forEach(el => {
        if (el.id === val.id) {
          if (el.disabled) {
            el.disabled = false
            val.disabled = false
            el.checked = false
            val.checked = false
          } else {
            el.disabled = true
            val.disabled = true
            el.checked = false
            val.checked = false
          }
        }
      })
    })
  } else {
    const enabledQuestions = updatedCheckedQuestions.filter(el => !el.disabled)
    updatedInitialData.forEach(val => {
      enabledQuestions.forEach(el => {
        if (el.id === val.id) {
          val.disabled = true
          val.checked = false
        }
      })
    })
    updatedData.forEach(val => {
      enabledQuestions.forEach(el => {
        if (el.id === val.id) {
          el.disabled = true
          val.disabled = true
          el.checked = false
          val.checked = false
        }
      })
    })
  }

  return {
    data: updatedData,
    initialData: updatedInitialData,
    checkedQuestions: []
  }
}