export default (ques) => {
  let buttonStyle = null;
  if (ques.disabled) {
    buttonStyle = "bg-secondary text-white d-flex justify-content-between list-group-item-question"
  } else {
    if (ques.selected && !ques.checked && !ques.multigrid && !ques.multilist && !ques.merged) {
      buttonStyle = "selected d-flex justify-content-between list-group-item-question"
    } else if (ques.selected && !ques.checked && ((ques.multigrid || ques.multilist) && !ques.merged)) {
      buttonStyle = "selected d-flex justify-content-between list-group-item-question"
    } else if (ques.merged && ques.checked && !ques.multigrid && !ques.multilist) {
      buttonStyle = "bg-dark-analyze text-white d-flex justify-content-between list-group-item list-group-item-question"
    } else if (ques.checked) {
      buttonStyle = "bg-dark-analyze text-white d-flex justify-content-between list-group-item-question"
    } else {
      if (ques.merged) {
        buttonStyle = "bg-analyze text-light d-flex justify-content-between list-group-item list-group-item-question"
      } else {
        buttonStyle = "d-flex justify-content-between list-group-item-question"
      }
    }
  }
  return buttonStyle
}
