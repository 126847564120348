import type { ISubelements } from "../../../../../../../interfaces/addNewElementModalInterfaces/addNewElementModalInterfaces";

export const allElements: ISubelements[] = [
  {
    demo: "https://url.to.demo",
    descr: "This is a standard single question with two rows",
    help: "https://url.to.help",
    icon: "fa fas fa-cog",
    id: "DefaultQuesN",
    isNotCustomQuestion: true,
    name: "Single question",
    plan: "professional",
    thumb: "https://url.to.image/1.png",
    type: "element"
  },
  {
    demo: "https://url.to.demo",
    descr: "This is a standard multi question with two rows",
    help: "https://url.to.help",
    icon: "fa fas fa-cog",
    id: "DefaultQuesM",
    isNotCustomQuestion: true,
    name: "Multi question",
    plan: "professional",
    thumb: "https://url.to.image/1.png",
    type: "element"
  },
  {
    demo: "https://url.to.demo",
    descr: "This is a standard single grid question with two rows and two columns",
    help: "https://url.to.help",
    icon: "fa fas fa-cog",
    id: "DefaultQuesRn",
    isNotCustomQuestion: true,
    name: "Single grid question",
    plan: "professional",
    thumb: "https://url.to.image/1.png",
    type: "element"
  },
  {
    demo: "https://url.to.demo",
    descr: "This is a standard multi grid question with two rows and two columns",
    help: "https://url.to.help",
    icon: "fa fas fa-cog",
    id: "DefaultQuesRm",
    isNotCustomQuestion: true,
    name: "Multi grid question",
    plan: "professional",
    thumb: "https://url.to.image/1.png",
    type: "element"
  },
  {
    demo: "https://url.to.demo",
    descr: "This is a standard open-ended question with one row",
    help: "https://url.to.help",
    icon: "fa fas fa-cog",
    id: "DefaultQuesOpen",
    isNotCustomQuestion: true,
    name: "Open-ended question",
    plan: "professional",
    thumb: "https://url.to.image/1.png",
    type: "element"
  },
  {
    demo: "https://url.to.demo",
    descr: "This is a standard numeric question with one row",
    help: "https://url.to.help",
    icon: "fa fas fa-cog",
    id: "DefaultQuesF",
    isNotCustomQuestion: true,
    name: "Numeric question",
    plan: "professional",
    thumb: "https://url.to.image/1.png",
    type: "element"
  },
  {
    demo: "https://url.to.demo",
    descr: "This is a standard duration question with one row",
    help: "https://url.to.help",
    icon: "fa fas fa-cog",
    id: "DefaultQuesH",
    isNotCustomQuestion: true,
    name: "Duration question",
    plan: "professional",
    thumb: "https://url.to.image/1.png",
    type: "element",
  },
  {
    demo: "https://url.to.demo",
    descr: "This is an empty information element",
    help: "https://url.to.help",
    icon: "fa fas fa-cog",
    id: "DefaultInfo",
    isNotCustomQuestion: true,
    name: "Information",
    plan: "professional",
    thumb: "https://url.to.image/1.png",
    type: "element"
  },
  {
    demo: "https://url.to.demo",
    descr: "This is an empty goto element",
    help: "https://url.to.help",
    icon: "fa fas fa-cog",
    id: "DefaultGoto",
    isNotCustomQuestion: true,
    name: "Goto",
    plan: "professional",
    thumb: "https://url.to.image/1.png",
    type: "element"
  },
  {
    descr: "This is the default quota element",
    icon: "fa fas fa-cog",
    id: "DefaultQuota",
    isNotCustomQuestion: true,
    name: "Quota",
  },
  {
    demo: "https://url.to.demo",
    descr: "This is an empty quota stop element",
    help: "https://url.to.help",
    icon: "fa fas fa-cog",
    id: "DefaultQuotaStop",
    isNotCustomQuestion: true,
    name: "Quota stop",
    plan: "professional",
    thumb: "https://url.to.image/1.png",
    type: "element",
  },
  {
    demo: "https://url.to.demo",
    descr: "This is an empty section",
    help: "https://url.to.help",
    icon: "fa fas fa-cog",
    id: "DefaultSection",
    isNotCustomQuestion: true,
    name: "Section",
    plan: "professional",
    thumb: "https://url.to.image/1.png",
    type: "element"
  }
]