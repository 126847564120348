import React from 'react'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'

interface IProps {
  setShowPreview: (a: boolean) => void
  composeData: any
}

export const PreviewSmsModal: React.FC<IProps> = ({ setShowPreview, composeData }) => {
  return (
    <Dialog width="300px" height="600px" onClose={() => setShowPreview(false)}>
      <section className="chat-section mx-2">
        <div className="clear"></div>
        <div className="from-them">
          <p>{composeData.text}</p>
        </div>
      </section>
      <DialogActionsBar>
        <button className="k-button" onClick={() => setShowPreview(false)}>Cancel</button>
      </DialogActionsBar>
    </Dialog>
  )
}
