import returnMergeRowGroupsData from "./returnMergeRowGroupsData/returnMergeRowGroupsData";

export default (questionsState, selectedQuestion, questions, rowsSelectedState, source, body, selectedGroupTab) => {
  let updatedQuestions = JSON.parse(JSON.stringify(questionsState));
  const activeQuestion = updatedQuestions.find(question => {
    return question.id === questionsState[selectedQuestion].id;;
  });
  const oldElement = questions[source].find(el => el.id === activeQuestion.id)
  const newState = { ...questions };
  const selectedGroups = activeQuestion.groups.filter(group => group.selected)
  const selectedSubGroups = activeQuestion.subGroups ? activeQuestion.subGroups.filter(group => group.selected) : []

  if (selectedGroupTab === 0) {
    if (selectedGroups.length > 1) {
      returnMergeRowGroupsData(selectedGroups, activeQuestion, source, newState, body, questions, oldElement, 'groups', 'selectedGroups')
    }
  } else {
    if (selectedSubGroups.length > 1) {
      returnMergeRowGroupsData(selectedSubGroups, activeQuestion, source, newState, body, questions, oldElement, 'subGroups', 'selectedSubGroups')
    }
  }

  return updatedQuestions;
}