import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

type Props = {
  handleClose: () => void;
  changeType: () => void;
  fromType: string;
  toType: TODO;
  type: string;
}

export default function ConfirmChangeTypeModal({ handleClose, changeType, fromType, toType, type }: Props) {
  return (
    <Dialog title="Change question type" onClose={handleClose} width="30%" >
      {type === "editor" ?
        <div className="d-flex flex-column p-4">
          <p>Changing {fromType === "rm" ? "multi grid" : "single grid"} question to {toType.id === "m" ? "multi" : "single"} question
            will remove all columns data.</p>
          <p>Please confirm to continue.</p>
        </div> :
        <div className="d-flex flex-column p-4">
          <p>Changing from {fromType} to {toType} will remove all previous compose data.</p>
        </div>}
      <DialogActionsBar>
        <Button className="btn btn-secondary" onClick={handleClose}>Cancel</Button>
        <Button className="btn btn-primary" onClick={changeType}>Continue</Button>
      </DialogActionsBar>
    </Dialog>
  )
}