import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PasteTargetsModal } from './PasteTargetsModal/PasteTargetsModal';
import { QuotaTable } from '../../../../../../shared/QuotaTable/QuotaTable';
import { useDebounce } from '../../../../../../shared/customHooks/useDebounce';
import { DropdownButton } from '../../../../../../shared/DropdownButton/DropdownButton';
import { InProgressOverlay } from '../../../../../../shared/InProgressOverlay/InProgressOverlay';
import { ValidationErrorMessage } from '../../../helpers/ValidationErrorMesage/ValidationErrorMessage';
import { fetchGetJson as fetchGetQuota, fetchPatch as fetchPatchQuotaCells, fetchPutRes, fetchPatchJson as patchQuota } from '../../../../../../../services/services';

export const EditTabQuotaElementContent = ({ handleAction, setErrorMessage }) => {
  const { token } = useSelector((state) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const [showPasteTargetsModal, setShowPasteTargetsModal] = useState(false)
  const { theData } = useSelector((theState) => (theState.surveyInitialDataReducer))
  const [changedQuotaAnswers, setChangedQuotaAnswers] = useState({});

  const saveLabelWithDebounce = useDebounce(() => updateQuotaLabel(), 1000);
  const saveQuotaWithDebounce = useDebounce(() => saveQuota(), 1000);
  const saveQuotaAnswersWithDebounce = useDebounce(() => quotaUpdateHandler(theData.selectedItem, "updateAnswers"), 2000);

  const saveQuota = () => {
    dispatch({ type: 'SET_AUTOSAVE_STATUS', payload: 'saving' })
    const body = theData.selectedItem.quotaCells.map((el) => {
      return { id: el.id, targetValue: el.targetValue }
    })
    fetchPatchQuotaCells(`projects/${theData.selectedItem.projectId}/surveys/${theData.selectedItem.surveyId}/quotas/${theData.selectedItem.quotaId}/cells`, token, body)
      .then((res) => { return res.json() })
      .then((res) => {
        setTimeout(() => {
          dispatch({ type: 'SET_AUTOSAVE_STATUS', payload: '' })
        }, 1500)
        if (res && !res.error && !res.message) {
          const updatedQuota = JSON.parse(JSON.stringify(theData.selectedItem))
          updatedQuota.quotaCells = res.cells;
          updatedQuota.quotaCells.forEach((cell, index) => {
            cell.targetValue = theData.selectedItem.quotaCells[index].targetValue
          })
          updatedQuota.quotaTable = { ...res.quotaTable, show: updatedQuota.quotaTable?.show, showRemaining: updatedQuota.quotaTable?.showRemaining };
          updatedQuota.quotaTable.rows.forEach((row, rowIndex) => {
            row.cells.forEach((cell, cellIndex) => {
              cell.targetValue = theData.selectedItem.quotaTable.rows[rowIndex].cells[cellIndex].targetValue
            })
          })
          updateQuota(updatedQuota);
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "An error has occured and your quota could not be updated" } })
        }
      })
  }

  const updateQuota = (quota, shouldSave) => {
    const originalItem = {
      ...theData.originalSelectedItem,
      quotaId: quota.quotaId,
      label: quota.label,
      disabled: quota.disabled,
      published: quota.published,
      quotaCells: quota.quotaCells,
      projectId: theData.selectedItem.projectId,
      surveyId: theData.selectedItem.surveyId,
      quotaTable: quota.quotaTable,
    }
    const updatedQuota = {
      ...theData.selectedItem,
      quotaId: quota.quotaId,
      label: quota.label,
      disabled: quota.disabled,
      published: quota.published,
      quotaCells: quota.quotaCells,
      quotaTable: quota.quotaTable,
    }
    dispatch({ type: 'UPDATE_SURVEY_ELEMENT_DATA', payload: { item: updatedQuota, originalItem: originalItem, elementOptions: null } })
    if (shouldSave) {
      saveQuotaWithDebounce()
    }
  }

  const validateLabel = (theLabel) => {
    const labelValid = theLabel.match(/((?=.*[a-z])|(?=.*[A-Z]))([a-zA-Z0-9_])\w+/g) ? theLabel.match(/((?=.*[a-z])|(?=.*[A-Z]))([a-zA-Z0-9_])\w+/g).toString() === theLabel : false;
    return labelValid;
  }

  const updateQuotaLabel = () => {
    const labelValid = validateLabel(theData.selectedItem.label);
    if (labelValid) {
      dispatch({ type: 'SET_AUTOSAVE_STATUS', payload: 'saving' })
      const body = { label: theData.selectedItem.label };
      patchQuota(`projects/${theData.selectedItem.projectId}/surveys/${theData.selectedItem.surveyId}/quotas/${theData.selectedItem.quotaId}`, token, body)
        .then(res => res.json())
        .then(res => {
          setTimeout(() => {
            dispatch({ type: 'SET_AUTOSAVE_STATUS', payload: '' })
          }, 1500)
          if (res.error || res.message) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
          } else {
            let newIndexElement = null
            if (res.elements) {
              res.elements.forEach(section => {
                section.elements.forEach(el => {
                  if (el.referenceId === theData.selectedItem.id) {
                    newIndexElement = el
                  }
                })
              })
            }
            if (newIndexElement) {
              const updatedOriginalData = JSON.parse(JSON.stringify(theData.originalData))
              const updatedData = JSON.parse(JSON.stringify(theData.data))
              const oldIndexItemId = updatedOriginalData.elements[theData.selectedItem.section].elements.findIndex(el => el.referenceId === newIndexElement.referenceId)
              const oldDataItemId = updatedData[theData.selectedItem.section].elements.findIndex(el => el.id === newIndexElement.referenceId)
              const oldDataItem = updatedData[theData.selectedItem.section].elements.find(el => el.id === newIndexElement.referenceId)
              updatedOriginalData.elements[theData.selectedItem.section].elements[oldIndexItemId] = newIndexElement
              updatedData[theData.selectedItem.section].elements[oldDataItemId] = { ...oldDataItem, id: newIndexElement.referenceId, label: newIndexElement.referenceDisplayLabel, text: newIndexElement.referenceDisplayTexts?.[theData.editingLanguage] ? newIndexElement.referenceDisplayTexts[theData.editingLanguage] : "", type: newIndexElement.type, referenceQuesTypes: newIndexElement.referenceQuesTypes }
              dispatch({ type: 'UPDATE_SURVEY_DATA', payload: { data: updatedData, originalData: updatedOriginalData } })
            }
          }
        })
    } else {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "Labels can only contain characters a-z, A-Z, 0-9, _ and has to contain one uppercase or lowercase letter" } })
    }
  }

  const enableDisableQuota = (quota) => {
    const body = { disabled: !quota.disabled }
    patchQuota(`projects/${theData.selectedItem.projectId}/surveys/${theData.selectedItem.surveyId}/quotas/${theData.selectedItem.quotaId}`, token, body)
      .then((result) => result.json())
      .then((res) => {
        if (res.error || res.message) {
          setErrorMessage(res.error ? res.error : res.message)
        } else {
          const updatedQuota = { ...quota, disabled: !quota.disabled };
          updateQuota(updatedQuota)
        }
      })
  }

  const quotaUpdateHandler = (quota, type) => {
    const updatedQuota = { ...quota };
    const relevantIds = { projectId: theData.selectedItem.projectId, surveyId: theData.selectedItem.surveyId }

    switch (type) {
      case "updateTable":
        fetchGetQuota(`projects/${relevantIds.projectId}/surveys/${relevantIds.surveyId}/quotas/${quota.quotaId}/quota-cell-tabular-overview`, token)
          .then((res) => {
            if (res.error || res.message) {
              setErrorMessage(res.error ? res.error : res.message)
            } else {
              updatedQuota.quotaTable = { ...res, show: true };
              updateQuota(updatedQuota);
            }
          })
        break;
      case "refresh":
        fetchGetQuota(`projects/${relevantIds.projectId}/surveys/${relevantIds.surveyId}/quotas/${quota.quotaId}/extended`, token)
          .then((res) => {
            if (res.error || res.message) {
              setErrorMessage(res.error ? res.error : res.message)
            } else {
              updatedQuota.cellOverview = res.cells
              updatedQuota.quotaTable = { ...res.quotaTable, show: updatedQuota.quotaTable?.show, showRemaining: updatedQuota.quotaTable?.showRemaining };
              updateQuota(updatedQuota);
              dispatch({ type: "FORCE_STOP_LOADING", payload: null });
              dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Target values have been updated successfully' } })
            }
          })
        break;
      case "updateAnswers": {
        const body = { ...changedQuotaAnswers }
        fetchPutRes(`su/projects/${relevantIds.projectId}/surveys/${relevantIds.surveyId}/quotas/${quota.quotaId}`, token, body)
          .then(res => {
            if (res.error || res.message) {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
              dispatch({ type: 'SET_AUTOSAVE_STATUS', payload: '' })
            } else {
              dispatch({ type: 'SET_AUTOSAVE_STATUS', payload: '' })
            }
          })
        break;
      }
      case "hide":
        updatedQuota.quotaTable = { ...quota.quotaTable, show: false };
        updateQuota(updatedQuota);
        break;
      case "remainingSwitch":
        updatedQuota.quotaTable = { ...quota.quotaTable, showRemaining: quota.quotaTable.showRemaining !== true };
        updateQuota(updatedQuota);
        break;
      case "customTotalsSwitch":
        updatedQuota.quotaTable = { ...quota.quotaTable, customTotals: quota.quotaTable.customTotals !== true };
        updateQuota(updatedQuota);
        break;
      case "copyToClipboard": {
        const text = `Name\tTarget\tActual\tActual percentage\tRemaining\tRemaining percentage\n${theData.selectedItem.quotaCells.map((el) => `${el.name}\t${el.targetValue}\t${el.actualValue}\t${((100 * el.actualValue) / el.targetValue).toFixed(2)}\t${el.remainingValue}\t${el.remainingPercentageValue}\n`)}`
        navigator.clipboard.writeText(text).then(() => {
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The data has been copied to clipboard.' } })
        });
        break;
      }
      default:
        break;
    }
  }

  const onQuotaDropdownItemClick = ({ item }) => {
    switch (item.text) {
      case 'Delete':
        handleAction({ item: { text: 'Delete', id: 'delete' } }, 0, 0, "quota")
        break;
      case "Enable":
        enableDisableQuota(theData.selectedItem)
        break;
      case "Disable":
        enableDisableQuota(theData.selectedItem)
        break;
      case "Update quota overview":
        quotaUpdateHandler(theData.selectedItem, "refresh")
        break;
      case "Copy to clipboard":
        quotaUpdateHandler(theData.selectedItem, 'copyToClipboard')
        break;
      case "Paste targets":
        setShowPasteTargetsModal(true)
        break;
      case "Advanced view":
        quotaUpdateHandler(theData.selectedItem, 'hide')
        break;
      case "Table view":
        quotaUpdateHandler(theData.selectedItem, 'updateTable')
        break;
      default:
        break;
    }
  }

  const quotaMenuItemsList = (quota) => {
    const items = []
    // Published quotas cannot be deleted, they can only be disabled and enabled
    if (!quota.published) {
      items.push({ text: "Delete", type: "delete" });
    } else if (quota.disabled) {
      items.push({ text: "separator" })
      items.push({ text: "Enable", type: "enable" });
    } else if (!quota.disabled) {
      items.push({ text: "Disable", type: "disable" });
    }
    return items
  }

  const changeQuotaCellTarget = (e, quotaCell) => {
    // Regex accepts for digits only
    const theValue = !e.target.value ? "0" : e.target.value;
    const updatedQuota = { ...theData.selectedItem };
    const quotaId = quotaCell.quotaCellId ? quotaCell.quotaCellId : quotaCell.id

    if (theValue.match(/^\d+$/g)) {
      if (updatedQuota.quotaTable.rows) {
        // If a quota has merged cells, it will not contain quotaTable data and is only viewable in Advanced view
        // Otherwise the quotaCell needs to be updated in two objects in theData.selectedItem:

        // A - One here in theData.selectedItem.quotaTable (used for rendering Table View of quota)
        const rowIndex = updatedQuota.quotaTable.rows.findIndex((row) => row.cells.some(cell => cell.quotaCellId === quotaId))
        const rowCellIndex = updatedQuota.quotaTable.rows[rowIndex].cells.findIndex(cell => cell.quotaCellId === quotaId)
        const cellInTable = updatedQuota.quotaTable.rows[rowIndex].cells[rowCellIndex];
        cellInTable.targetValue = Number.parseInt(theValue);
      }

      // B - And here in theData.selectedItem.quotaCells. These quotaCells are used for:
      //  - rendering Advanced View of quota
      //  - sending a request body to backend (see onSaveChanges > fetchPatchQuotaCells call)
      const cellInList = updatedQuota.quotaCells.find(cell => cell.id === quotaId);
      cellInList.targetValue = Number.parseInt(theValue);
    }

    updateQuota(updatedQuota, true)
  }

  const onChangeQuotaLabel = (e) => {
    const updatedQuota = JSON.parse(JSON.stringify(theData.selectedItem))
    updatedQuota.label = e.target.value
    updateQuota(updatedQuota)
    saveLabelWithDebounce()
  }

  const updateQuotaAnswers = (userInput, cellIndex) => {
    dispatch({ type: 'SET_AUTOSAVE_STATUS', payload: 'saving' })
    setChangedQuotaAnswers({ ...changedQuotaAnswers, [cellIndex]: userInput })
    const updatedQuota = JSON.parse(JSON.stringify(theData.selectedItem))
    updatedQuota.quotaCells[cellIndex].name = userInput
    updateQuota(updatedQuota)
    saveQuotaAnswersWithDebounce()
  }
  // ...(formData.respondentStatuses ? { respondentStatuses: JSON.parse(formData.respondentStatuses as string) } 
  return (
    <div style={{ backgroundColor: '#f3f4f4' }} className="card w-100 h-100" title={theData.selectedItem.disabled ? "This element is disabled" : undefined}>
      {theData.loadingMessage && theData.loadingState &&
        <InProgressOverlay type="overlay" theme="primary" message={theData.loadingMessage} />
      }
      {
        showPasteTargetsModal &&
        <PasteTargetsModal quota={theData.selectedItem} updateQuota={updateQuota} handleClose={() => setShowPasteTargetsModal(false)} />
      }
      <div className="card-header d-flex flex-column px-3 h-48 rounded-0">
        {theData.selectedItem.errorMessage !== "" &&
          <ValidationErrorMessage errorMessage={theData.selectedItem.errorMessage} />
        }
        <div id="quotaHeader" className="d-flex justify-content-between h-100">
          <div className="d-flex justify-content-between flex-fill align-items-center">
            <span />
            <div className="d-flex align-items-center flex-wrap">
              <DropdownButton
                className="px-1 btn-transparent"
                hideChevron={true}
                icon="more-horizontal"
                items={[...(theData.selectedItem.quotaTable?.rows?.length ? [{ text: theData.selectedItem.quotaTable?.show === true ? 'Advanced view' : 'Table view' }] : []), { text: 'Update quota overview' }, { text: 'separator' }, { text: 'Copy to clipboard', disabled: theData.selectedItem.quotaTable.show }, { text: 'Paste targets', disabled: theData.selectedItem.quotaTable.show }, ...quotaMenuItemsList(theData.selectedItem)]}
                onItemClick={onQuotaDropdownItemClick}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card-body d-flex flex-column h-100 overflow-auto m-4 p-0 bg-white answer-layout">
        <div className='card-header d-flex justify-content-between align-items-center py-0 px-2'>
          <div className="d-flex align-items-center flex-grow-1 h-48">
            <input
              name='surveyQuestionTitle'
              type="text"
              style={{ minWidth: '150px' }}
              className='form-control survey-question-title'
              value={theData.selectedItem.label}
              onChange={onChangeQuotaLabel}
            />
          </div>
        </div>
        <div id="quotaBody" className="quota-grid d-flex">
          <QuotaTable
            quota={{
              id: theData.selectedItem.quotaId,
              name: theData.selectedItem.label,
              disabled: theData.selectedItem.disabled,
              published: theData.selectedItem.published,
              cellCount: theData.selectedItem.quotaCells.length,
              cellOverview: theData.selectedItem.quotaCells,
              quotaTable: theData.selectedItem.quotaTable
            }}
            calledFrom="editTab"
            changeTargetValue={changeQuotaCellTarget}
            updateQuotaAnswers={updateQuotaAnswers}
          />
        </div>
        {theData.selectedItem.quotaTable.show &&
          <div className="d-flex py-3">
            <div className="pl-3 d-flex always-on-switch cursor-pointer" title="Toggle between achieved and remaining">
              <span className={`d-flex align-items-center cursor-pointer mr-1 medium ${!theData.selectedItem.quotaTable.showRemaining ? "text-primary" : "text-muted"}`}>Achieved</span>
              <div className="d-flex align-items-center custom-control custom-switch">
                <input className="cursor-pointer custom-control-input invisible" value={theData.selectedItem.quotaTable.showRemaining} onChange={() => quotaUpdateHandler(theData.selectedItem, "remainingSwitch")} type="checkbox" id="remainingSwitch" />
                <label className={`cursor-pointer custom-control-label medium ${!theData.selectedItem.quotaTable.showRemaining ? "text-muted" : "text-primary"}`} htmlFor="remainingSwitch">Remaining</label>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}