import { Switch } from '@progress/kendo-react-inputs';

import { BaseDropDownList } from "../../../../../shared/Inputs";
import type { ExportDocumentFormData } from '../../../../../../interfaces/projectDetailsInterfaces/projectDetailsInterfaces';

interface Props {
  exportType: number
  exportDocumentInputsCopy: TODO
  formData: ExportDocumentFormData
  onChangeHandler: (e: TODO) => void
  selectExportType: (key: number) => void
}

// const languages = [{ text: "English", id: "en" }, { text: "Macedonian", id: "mk" }]
const formatTypes = ["Default", "Dapresy", "DisplayR"];

export const ExportDocumentTabContent = ({ exportDocumentInputsCopy, exportType, formData, onChangeHandler, selectExportType }: Props) => {
  const checkInput = (option: TODO) => {
    const arr = exportDocumentInputsCopy[Object.keys(exportDocumentInputsCopy)[exportType]].inputs.map((input: TODO) => input.id)
    if (arr.includes(option.id)) {
      return false
    }
    return true
  }

  return (
    <div className="container my-3">
      <p className="mb-0">Choose export type:</p>
      <div className="row">
        {
          Object.keys(exportDocumentInputsCopy).map((tab, key) => (
            <div key={key} onClick={() => selectExportType(key)} className="col-md-3 mt-3">
              <div className={`h-100 project-card shadow-none card p-3 text-center text-${exportDocumentInputsCopy[tab].color} ${exportType === key ? `selected-type border-${exportDocumentInputsCopy[tab].color}` : ''}`}>
                <i className="fas fa-2x fa-file-alt" />
                <h4 className="mt-3 mb-0">{tab}</h4>
              </div>
            </div>
          ))
        }
      </div>

      {/* <p className="mt-3 mb-2">Options:</p>
      <BaseDropDownList
        // disabled={exportDocumentInputsCopy[Object.keys(exportDocumentInputsCopy)[exportType]].inputs.length > 0 ? false : true}
        disabled
        id="language"
        textField="text"
        dataItemKey="id"
        className="w-100"
        data={languages}
        value={formData.language}
        onChange={onChangeHandler}
        defaultItem={{ text: "Use default language", id: "default" }}
      /> */}

      <p className="mt-3 mb-2">Format type:</p>
      <BaseDropDownList
        filterable={false}
        disabled={exportType !== 0}
        id="formatType"
        className="w-100"
        data={formatTypes}
        value={formData.formatType}
        onChange={onChangeHandler}
      />

      {
        exportDocumentInputsCopy.SAV.inputs.map((option: { text: string, id: "useIdAsLabel" | "includeLogin" | "useMultiValues" }) => (
          <div key={option.id} className="d-flex align-items-center justify-content-between mt-4 mx-1">
            <p className={`m-0 pr-5 ${checkInput(option) ? 'text-muted' : ''}`}>{option.text}</p>
            <Switch
              disabled={checkInput(option)}
              id={option.id}
              checked={formData[option.id]}
              onChange={onChangeHandler}
              size="small"
            />
          </div>
        ))
      }
    </div>
  )
}