// The Library accepts few props that are required in order for it to function properlly.
// The Library has two modes of operation, "Load" and "Save", controled by the addingFromLibrary boolean.

// handleClose --------->
// A function called upon successful Load, Save or Cancel actions.
// Provide a function in your own parent component to close the modal.

// isSelectedQuestionSurveyObject <---------
// isSelectedQuestionSurveyObject should be false unless it is used to add a survey object

// Scope <---------
// Scope is a string, which is used to create and read an endpoint which is unique to your specific library.
// The library will create an endpoint containing whatever value you send as "Scope" the first time you save an item.
// After that the Library uses that endpoint for CRUD of directories and elements.

// addingFromLibrary <---------
// addingFromLibrary is a boolean which controls the mode.
// Send true if you would like to open the Library in "Load/Read" mode.
// Send falsy value if you would like to open the Library in "Save/Store" mode.

// setPayload --------->
// setPayload is a function called when you are using the Library in Save mode, to store items.
// The Library works by creating the item with one call to backend by itself, which (if successful) brings you back a result with the necessary IDs.
// You then use the two values, which setPayload sends out, to update the newly created item with whatever extra data/property you need.
// To do this you need to provide a function in your own parent component, to make another call to backend to update it.
// setPayload sends 2 values: first is the result that we get from backend from the first call,
// Аnd the second is the URL to use in a fetchPost call to update your newly created item with extra data.

// importElement --------->
// importElement is the function that adds from library and works similarly to setPayload, but for Load mode.
// The Library gets the item on its own, but it does not contain the data which you've added with setPayload, but only has the necessary IDs.
// You need to provide a function in your own parent component, which makes a call to backend to get the item's extra data which you've added with setPayload. 
// importElement will (same as setPayload) send out two values: the result from the first call the item with it's necessary IDs,
// and an URL for you to call in order to fetchGetRes the item again, for it's extra data 

import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import { fetchGetJson, fetchPostJson, rsAppUrl } from '../../../../../../services/services';
import RenameFolderModal from './RenameFolderModal/RenameFolderModal';
import DeleteFolderModal from './DeleteFolderModal/DeleteFolderModal';
import NewFolderModal from './NewFolderModal/NewFolderModal';
import RenameElementModal from './RenameElementModal/RenameElementModal';
import DeleteElementModal from './DeleteElementModal/DeleteElementModal';
import { Icon } from '../../../../../shared/Icon/Icon';
import { DropdownButton } from '../../../../../shared/DropdownButton/DropdownButton';
import LibraryFolderIcon from '../../../../../../assets/img/library-folder.svg';
import LibraryFileIcon from '../../../../../../assets/img/library-file.svg';
import { formatDate } from '../../../../../shared/DateUtils/DateUtils';

function GenericLibrary({ handleClose, isSelectedQuestionSurveyObject, scope, type, setPayload, addingFromLibrary, importElement, loadLibraryDataset }) {
  const { theData } = useSelector(theState => (theState.surveyInitialDataReducer));
  const [library, setLibrary] = useState('personal');
  const [selectedRow, setSelectedRow] = useState();
  const [folderName, setFolderName] = useState();
  const [dropdownLibraryValue, setDropdownLibraryValue] = useState();
  const [selectedElementType, setSelectedElementType] = useState(null);
  const [rowClicked, setrowClicked] = useState()
  const [renameModal, setRenameModal] = useState({
    show: false,
    id: "",
    name: "",
    pid: null
  });
  const [deleteModal, setDeleteModal] = useState({
    show: false,
    id: "",
    name: "",
    pid: null
  });
  const [newFolderModal, setNewFolderModal] = useState(false);
  const [itemName, setItemName] = useState("");
  const [renameElementModal, setRenameElementModal] = useState({
    show: false,
    id: "",
    name: "",
    description: "",
    folderId: null,
    type: ""
  });
  const [elementRowClicked, setElementRowClicked] = useState();
  const [deleteElementModal, setDeleteElementModal] = useState({
    show: false,
    id: "",
    name: "",
    description: "",
    folderId: null,
    type: ""
  });
  const [personalLibraryExists, setPersonalLibraryExists] = useState(false);
  const [globalLibraryExists, setGlobalLibraryExists] = useState(false);
  const [isRegularUser, setIsRegularUser] = useState(false);
  const [mimicRedux, setMimicRedux] = useState();
  const [foldersClicked, setFoldersClicked] = useState([]);
  const [shouldMount, setShouldMount] = useState(true);
  const [elementDescription, setElementDescription] = useState("");
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const { token } = useSelector((state) => state.tokenStateReducer);
  const libraryScope = scope;

  const buttonItems = [
    {
      text: "Rename",
      icon: "fa fas fa-pen",
    },
    {
      text: "Delete folder",
      icon: "fa fas fa-trash",
    }
  ];

  const elementButtonItems = [
    {
      text: "Rename",
      icon: "fa fas fa-pen",
    },
    {
      text: "Delete",
      icon: "fa fas fa-trash",
    }
  ];

  // this function sets the delete modal state
  const selectDeleteOrRename = (e, el) => {
    if (e.item.text === "Rename") {
      setRenameModal({
        show: true,
        id: el.id,
        name: el.n,
        pid: el.pid
      })
    } else if (e.item.text === "Delete folder") {
      setDeleteModal({
        show: true,
        id: el.id,
        name: el.n,
        pid: el.pid
      })
    } else if (e.item.text === "Open") {
      const items = [];
      if (rowClicked.childFolders) {
        items.push(rowClicked.childFolders)
      }
      setDropdownLibraryValue({ ...dropdownLibraryValue, f: items[0] })
    }
  };

  // this function sets if the modal is to delete or to rename
  const selectDeleteOrRenameElement = (e, el) => {
    if (e.item.text === "Rename") {
      setRenameElementModal({
        show: true,
        id: el.id,
        name: el.name,
        type: el.type,
        description: el.description,
        folderId: el.folderId
      })
    } else if (e.item.text === "Delete") {
      setDeleteElementModal({
        show: true,
        id: el.id,
        name: el.name,
        type: el.type,
        description: el.description,
        folderId: el.folderId
      })
    }
  };

  // this useEffect check if the user is regular. If the user is regular then he can not save to the global library
  useEffect(() => {
    fetchGetJson(`users/${user[`${rsAppUrl}/userid`]}/extended`, token)
      .then(res => {
        if (res.error || res.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
          setIsRegularUser(true)
        } else {
          if (res && res.userRole === 'regular') {
            setIsRegularUser(true)
          } else {
            setIsRegularUser(false)
          }
        }
      })
  }, [user, token, dispatch]);

  useEffect(() => {
    setItemName(isSelectedQuestionSurveyObject ? theData.selectedQuestion.label : "")
  }, [theData.selectedQuestion.label, isSelectedQuestionSurveyObject])

  // this function is called when the user adds a folder 
  const addFolder = () => {
    if (library === 'personal') {
      if (folderName === undefined) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'Please add a folder name' } })
      } else if ((selectedRow?.childFolders?.some(folder => folder.n === folderName)) ||
        (dropdownLibraryValue.f?.some(folder => folder.n === folderName))) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'A folder with that name already exists' } })
      } else {
        if (selectedRow) {
          const body = {
            name: folderName,
            parentFolderId: selectedRow.id
          }
          fetchPostJson(`/libraries/${libraryScope}/user`, token, body)
            .then(res => {
              if (res.error || res.message) {
                dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
              } else {
                setSelectedRow()
                setShouldMount(true)
                setFoldersClicked([])
                dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Folder added successfully' } })
                setNewFolderModal(false)
                setFolderName()
              }
            })
        } else {
          const body = {
            name: folderName,
            parentFolderId: null
          }
          fetchPostJson(`/libraries/${libraryScope}/user`, token, body)
            .then(res => {
              if (res.error || res.message) {
                dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
              } else {
                setSelectedRow()
                setShouldMount(true)
                setFoldersClicked([])
                dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Folder added successfully' } })
                setNewFolderModal(false)
                setFolderName()
              }
            })
        }
      }
    } else if (library === 'global') {
      if (folderName === undefined) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'Please add a folder name' } })
      } else if ((selectedRow?.childFolders?.some(folder => folder.n === folderName)) ||
        (dropdownLibraryValue.f?.some(folder => folder.n === folderName))) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'A folder with that name already exists' } })
      } else {
        if (selectedRow) {
          const body = {
            name: folderName,
            parentFolderId: selectedRow.id
          }
          fetchPostJson(`/libraries/${libraryScope}/customer`, token, body)
            .then(res => {
              if (res.error || res.message) {
                dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
              } else {
                setSelectedRow()
                setShouldMount(true)
                setFoldersClicked([])
                dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Folder added successfully' } })
                setNewFolderModal(false)
                setFolderName()
              }
            })
        } else {
          const body = {
            name: folderName,
            parentFolderId: null
          }
          fetchPostJson(`/libraries/${libraryScope}/customer`, token, body)
            .then(res => {
              if (res.error || res.message) {
                dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
              } else {
                setSelectedRow()
                setShouldMount(true)
                setFoldersClicked([])
                dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Folder added successfully' } })
                setNewFolderModal(false)
                setFolderName()
              }
            })
        }
      }
    } else if (library === undefined) {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'Please select personal or global library' } })
    }
  }

  // this function is called when add element button is pressed
  const addElement = () => {
    const body = {
      name: itemName,
      description: elementDescription ? elementDescription : "This is a description",
      type: type,
    }
    if (library === 'personal') {
      if (selectedRow) {
        if (body.name.trim().length === 0) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'Element name can not be blank' } })
        } else if (selectedElementType?.questionItems.some(el => el.name === body.name)) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'An element with that name already exists' } })
        } else {
          fetchPostJson(`/libraries/${libraryScope}/user/${selectedRow.id}/items`, token, body)
            .then(res => {
              setPayload(res, `/libraries/${libraryScope}/user/${selectedRow.id}/items/${res.id}`)
            })
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "Operation was successfull" } })
          handleClose()
        }
      } else {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'Please select a folder to save the question' } })
      }
    } else if (library === 'global') {
      if (selectedRow) {
        if (body.name.trim().length === 0) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'Element name can not be blank' } })
        } else if (selectedElementType?.questionItems.some(el => el.name === body.name)) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'An element with that name already exists' } })
        } else {
          fetchPostJson(`/libraries/${libraryScope}/customer/${selectedRow.id}/items`, token, body)
            .then(res => {
              if (res.error) {
                dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'Access denied, no authorization to add to Global Library' } })
              } else {
                setPayload(res, `/libraries/${libraryScope}/user/${selectedRow.id}/items/${res.id}`)
                dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "Operation was successfull" } })
              }
            })
          handleClose()
        }
      } else {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'Please select a folder to save in' } })
      }
    }
  }

  // this function check wether global or personal library item is clicked
  const sidebarSelectHandler = (e) => {
    if (e.currentTarget.textContent === 'Personal library') {
      setGlobalLibraryExists(false)
      setFoldersClicked([])
      setSelectedElementType()
      setLibrary('personal')
      setSelectedRow()
      setrowClicked()
      fetchGetJson(`/libraries/${libraryScope}/user`, token)
        .then(res => {
          if (res.error) {
            if (res.statusCode === 404 && res.error === "Library not found") {
              setPersonalLibraryExists(true)
              setDropdownLibraryValue({ f: [] })
            }
            setPersonalLibraryExists(false)
          } else {
            if (res.f.length === 0) {
              setPersonalLibraryExists(false)
            } else {
              setPersonalLibraryExists(true)
              setDropdownLibraryValue(res)
              setMimicRedux(res)
            }
          }
        })
    } else if (e.currentTarget.textContent === 'Global library') {
      setPersonalLibraryExists(false)
      setFoldersClicked([])
      setSelectedElementType()
      setLibrary('global')
      setSelectedRow()
      setrowClicked()
      fetchGetJson(`/libraries/${libraryScope}/customer`, token)
        .then(res => {
          if (res.error) {
            if (res.statusCode === 404 && res.error === "Library not found") {
              setPersonalLibraryExists(true)
              setDropdownLibraryValue({ f: [] })
            }
            setGlobalLibraryExists(false)
          } else {
            if (res.f.length === 0) {
              setGlobalLibraryExists(false)
            } else {
              setGlobalLibraryExists(true)
              setDropdownLibraryValue(res)
              setMimicRedux(res)
            }
          }
        })
    }
  };

  // this useEffect gets the items under a library
  useEffect(() => {
    if (shouldMount) {
      if (library === 'personal') {
        fetchGetJson(`/libraries/${libraryScope}/user`, token)
          .then(res => {
            if (res.error) {
              setPersonalLibraryExists(false)
            } else {
              if (res.f.length === 0) {
                setPersonalLibraryExists(false)
              } else {
                setPersonalLibraryExists(true)
                setDropdownLibraryValue(res)
                setMimicRedux(res)
              }
            }
          })
      } else if (library === 'global') {
        fetchGetJson(`/libraries/${libraryScope}/customer`, token)
          .then(res => {
            if (res.error) {
              setGlobalLibraryExists(false)
            } else {
              if (res.f.length === 0) {
                setGlobalLibraryExists(false)
              } else {
                setGlobalLibraryExists(true)
                setDropdownLibraryValue(res)
                setMimicRedux(res)
              }
            }
          })
      }
    }
    setShouldMount(false)
  }, [shouldMount, library, token, libraryScope]);

  // this function is called when a folder is clicked from the personal library
  const onPersonalRowClick = (e, key, event, reload) => {
    setGlobalLibraryExists(false)
    const items = [];
    if (reload) {
      fetchGetJson(`/libraries/${libraryScope}/user/${e.dataItem.id}/items`, token)
        .then(res => {
          if (res.length > 0) {
            setSelectedElementType({ questionItems: res })
          } else {
            setSelectedElementType()
          }
        })
      if (e.dataItem.childFolders) {
        items.push(e.dataItem.childFolders)
        setDropdownLibraryValue({ ...dropdownLibraryValue, f: items[0] })
        setrowClicked(e.dataItem)
        setSelectedRow(e.dataItem)
        setFoldersClicked([...foldersClicked, e.dataItem])
      } else {
        items.push([])
        setDropdownLibraryValue({ ...dropdownLibraryValue, f: [] })
        setrowClicked(e.dataItem)
        setSelectedRow(e.dataItem)
        const foundFolder = foldersClicked.find(el => el.id === e.dataItem.id)
        if (!foundFolder) {
          setFoldersClicked([...foldersClicked, e.dataItem])
        }
      }
    }

    switch (event?.detail) {
      case 1: {
        setrowClicked(e.dataItem)
        break;
      }
      case 2: {
        fetchGetJson(`/libraries/${libraryScope}/user/${e.dataItem.id}/items`, token)
          .then(res => {
            if (res.length > 0) {
              setSelectedElementType({ questionItems: res })
            } else {
              setSelectedElementType()
            }
          })
        if (e.dataItem.childFolders) {
          items.push(e.dataItem.childFolders)
          setDropdownLibraryValue({ ...dropdownLibraryValue, f: items[0] })
          setrowClicked(e.dataItem)
          setSelectedRow(e.dataItem)
          setFoldersClicked([...foldersClicked, e.dataItem])
        } else {
          items.push([])
          setDropdownLibraryValue({ ...dropdownLibraryValue, f: [] })
          setrowClicked(e.dataItem)
          setSelectedRow(e.dataItem)
          const foundFolder = foldersClicked.find(el => el.id === e.dataItem.id)
          if (!foundFolder) {
            setFoldersClicked([...foldersClicked, e.dataItem])
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  // this function is called when a folder is clicked from the global library
  const onGlobalRowClick = (e, key, event, reload) => {
    setPersonalLibraryExists(false)
    const items = [];
    if (reload) {
      fetchGetJson(`/libraries/${libraryScope}/customer/${e.dataItem.id}/items`, token)
        .then(res => {
          if (res.length > 0) {
            setSelectedElementType({ questionItems: res })
          } else {
            setSelectedElementType()
          }
        })
      if (e.dataItem.childFolders) {
        items.push(e.dataItem.childFolders)
        setDropdownLibraryValue({ ...dropdownLibraryValue, f: items[0] })
        setrowClicked(e.dataItem)
        setSelectedRow(e.dataItem)
        setFoldersClicked([...foldersClicked, e.dataItem])
      } else {
        items.push([])
        setDropdownLibraryValue({ ...dropdownLibraryValue, f: [] })
        setrowClicked(e.dataItem)
        setSelectedRow(e.dataItem)
        const foundFolder = foldersClicked.find(el => el.id === e.dataItem.id)
        if (!foundFolder) {
          setFoldersClicked([...foldersClicked, e.dataItem])
        }
      }
    }

    switch (event?.detail) {
      case 1: {
        setrowClicked(e.dataItem)
        break;
      }
      case 2: {
        fetchGetJson(`/libraries/${libraryScope}/customer/${e.dataItem.id}/items`, token)
          .then(res => {
            if (res.length > 0) {
              setSelectedElementType({ questionItems: res })
            } else {
              setSelectedElementType()
            }
          })
        if (e.dataItem.childFolders) {
          items.push(e.dataItem.childFolders)
          setDropdownLibraryValue({ ...dropdownLibraryValue, f: items[0] })
          setrowClicked(e.dataItem)
          setSelectedRow(e.dataItem)
          setFoldersClicked([...foldersClicked, e.dataItem])
        } else {
          items.push([])
          setDropdownLibraryValue({ ...dropdownLibraryValue, f: [] })
          setrowClicked(e.dataItem)
          setSelectedRow(e.dataItem)
          const foundFolder = foldersClicked.find(el => el.id === e.dataItem.id)
          if (!foundFolder) {
            setFoldersClicked([...foldersClicked, e.dataItem])
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  // this function is called when an element (question) is clicked
  const handleElementRowClick = (key) => {
    const found = selectedElementType.questionItems[key]
    setElementRowClicked(found)
  }

  const handleNewFolderClick = () => {
    if (library === undefined) {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'Please select personal or global library' } })
    } else {
      setNewFolderModal(true)
    }
  };

  // this function is called when the back arrow is clicked. It goes through an array of objects (folders) and sets the previous as clicked
  const findTheFolder = () => {
    const data = { ...mimicRedux }
    if (rowClicked.pid === null) {
      setrowClicked()
      setSelectedRow()
      setDropdownLibraryValue(data)
      setSelectedElementType()
      setFoldersClicked([])
    } else {
      let items = foldersClicked
      const found = items.find(el => el.id === rowClicked.pid)
      setrowClicked(found)
      setSelectedRow(found)
      setDropdownLibraryValue({ ...dropdownLibraryValue, f: found.childFolders ? found.childFolders : null })
      if (foldersClicked.length === 1) {
        setFoldersClicked([])
        items = []
      } else {
        items.pop()
      }
      if (library === 'personal') {
        fetchGetJson(`/libraries/${libraryScope}/user/${found.id}/items`, token)
          .then(res => {
            if (res.length > 0) {
              setSelectedElementType({ questionItems: res })
            } else {
              setSelectedElementType()
            }
          })
      } else if (library === 'global') {
        fetchGetJson(`/libraries/${libraryScope}/customer/${found.id}/items`, token)
          .then(res => {
            if (res.length > 0) {
              setSelectedElementType({ questionItems: res })
            } else {
              setSelectedElementType()
            }
          })
      }
    }
  };

  const findDeep = useCallback((data, displayName, matchedItems) => {
    if (data) {
      return data.some((e) => {
        if (e.displayName === displayName) {
          matchedItems.push(e)
          return true
        }
        if (e.contents) return findDeep(e.contents, displayName, matchedItems)
        return false
      })
    }
  }, [])

  return (
    <React.Fragment>
      <Dialog width="60%" height="80%" className="library-dialog no-wrap" style={{ zIndex: '500000' }} title={"Library"} onClose={handleClose}>
        {newFolderModal && <NewFolderModal
          setNewFolderModal={setNewFolderModal}
          folderName={folderName}
          setFolderName={setFolderName}
          addFolder={addFolder}
          setSelectedElementType={setSelectedElementType}
        />
        }
        {renameModal.show &&
          <RenameFolderModal
            setRenameModal={setRenameModal}
            library={library}
            token={token}
            setShouldMount={setShouldMount}
            libraryScope={libraryScope}
            renameModal={renameModal}
            directoryElements={foldersClicked.length > 0 ? foldersClicked.find(folder => folder.childFolders.some(el => el.id === renameModal.id)).childFolders : dropdownLibraryValue.f}
            setFoldersClicked={setFoldersClicked}
            setSelectedRow={setSelectedRow}
            setSelectedElementType={setSelectedElementType}
          />
        }
        {deleteModal.show && <DeleteFolderModal
          deleteModal={deleteModal}
          onClose={handleClose}
          setDeleteModal={setDeleteModal}
          token={token}
          library={library}
          setShouldMount={setShouldMount}
          libraryScope={libraryScope}
          setFoldersClicked={setFoldersClicked}
          setSelectedRow={setSelectedRow}
          setSelectedElementType={setSelectedElementType}
        />}
        {renameElementModal.show && <RenameElementModal
          renameElementModal={renameElementModal}
          setSelectedElementType={setSelectedElementType}
          setRenameElementModal={setRenameElementModal}
          directoryElements={selectedElementType}
          library={library}
          token={token}
          libraryScope={libraryScope}
        />}
        {deleteElementModal.show && <DeleteElementModal
          deleteElementModal={deleteElementModal}
          setSelectedElementType={setSelectedElementType}
          onClose={handleClose}
          setDeleteElementModal={setDeleteElementModal}
          library={library}
          token={token}
          libraryScope={libraryScope}
        />}

        <div className="d-flex flex-row w-100 h-100 library-container">
          <div className="d-flex flex-column h-100 sidebar">
            <h1 className='stronger'>Library</h1>
            <small>Folders</small>
            {
              isRegularUser === true && !addingFromLibrary ? null :
                <div className={library === 'global' ? "library-nav selected" : "library-nav"} onClick={(e) => sidebarSelectHandler(e)}>
                  <div className='d-flex align-items-center'>
                    <Icon className='mr-1' type="globe" />
                    <span>Global library</span>
                  </div>
                </div>
            }
            <div className={library === 'personal' ? "library-nav selected" : "library-nav"} onClick={(e) => sidebarSelectHandler(e)}>
              <div className='d-flex align-items-center'>
                <Icon className='mr-1' type="library-personal" />
                <span>Personal library</span>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column w-100">
            <div className="w-100 border-bottom p-4">
              <div className="d-flex flex-row justify-content-between w-100">
                <div className="d-flex flex-row w-100 align-items-center">
                  <button type='button' disabled={selectedRow?.childFolders || selectedRow ? false : true} className="btn btn-transparent p-0 mr-2" onClick={findTheFolder}><Icon type="chevron-left" /></button>
                  <span className="library-title"> {foldersClicked.length > 0 ? `${foldersClicked[foldersClicked.length - 1].n}` : `${library.charAt(0).toUpperCase()}${library.slice(1)} library`}</span>
                </div>
              </div>
            </div>
            <div>
              <div style={{ height: "2rem" }} className="d-flex flex-row align-items-center border-bottom">
                <small id="elementName" className="form-text text-muted d-flex flex-row px-4 m-0 align-items-center">{library === "personal" ? "Personal Library" : library === "global" ? "Global Library" : null}
                  {foldersClicked?.map((folder, key) => {
                    return (
                      <Fragment key={key}>
                        <Icon className='library-path-icon' type="chevron-right" />
                        <span>{folder.n}</span>
                      </Fragment>
                    )
                  })}
                </small>
              </div>
            </div>
            {personalLibraryExists || globalLibraryExists ?
              <div style={{ overflowY: "auto" }} className="w-100 px-3 pt-1 flex-grow-1">
                <table className="table table-hover table-borderless table-sm table-library">
                  <thead>
                    <tr>
                      <th scope="col" width="50%" className='medium strong'>Name</th>
                      <th scope="col" width="20%" className='medium strong'>Type</th>
                      <th scope="col" width="20%" className='medium strong'>Last modified</th>
                      <th scope="col" width="10%" />
                    </tr>
                  </thead>
                  <tbody>
                    {personalLibraryExists || globalLibraryExists ?
                      < >
                        {dropdownLibraryValue?.f?.map((el, key) => {
                          const e = { dataItem: el }
                          return (
                            <tr className="text-left align-items-center cursor-pointer" onClick={(event) => {
                              if (personalLibraryExists) {
                                onPersonalRowClick(e, key, event)
                              } else if (globalLibraryExists) {
                                onGlobalRowClick(e, key, event)
                              }
                            }} key={key}>
                              <td className="text-left" >
                                <div className="d-flex flex-row align-items-center">
                                  <img src={LibraryFolderIcon} alt="Library folder icon" className='mr-1' />
                                  <span className='text-primary'>{el.n}</span>
                                </div>
                              </td>
                              <td className="text-left text-muted">Folder</td>
                              <td className="text-left text-muted">{formatDate(el.c, "DATE_MED")}</td>
                              <td>
                                <div className='d-flex w-100 justify-content-end'>
                                  <DropdownButton
                                    items={buttonItems}
                                    hideChevron={true}
                                    onItemClick={(e) => selectDeleteOrRename(e, el)}
                                    className="btn-transparent more p-05 m-0"
                                  />
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </>
                      : null
                    }
                    <>
                      {selectedElementType?.questionItems ? (
                        selectedElementType.questionItems.map((el, key) => {
                          return (
                            <tr className={elementRowClicked === el && addingFromLibrary ? "library-element-clicked" : null} onClick={() => handleElementRowClick(key)} key={key}>
                              <td className={addingFromLibrary ? "text-left cursor-pointer" : "text-left library-row-disabled"}>
                                <div className="d-flex flex-row align-items-center">
                                  <img src={LibraryFileIcon} alt="Library file icon" className='mr-1' />
                                  <span className='text-primary'>{el.name}</span>
                                </div>
                              </td>
                              <td className={addingFromLibrary ? "text-left text-muted cursor-pointer" : "text-left text-muted library-row-disabled"}>{el.type}</td>
                              <td className={addingFromLibrary ? "text-left text-muted cursor-pointer" : "text-left text-muted library-row-disabled"}>{formatDate(el.createdUtc, "DATE_MED")}</td>
                              <td className="text-left">
                                <div className='d-flex w-100 justify-content-end'>
                                  <DropdownButton
                                    items={elementButtonItems}
                                    hideChevron={true}
                                    onItemClick={(e) => selectDeleteOrRenameElement(e, el)}
                                    className="btn-transparent more p-05 m-0"
                                  />
                                </div>
                              </td>
                            </tr>
                          )
                        })
                      ) : (
                        null
                      )}
                    </>
                  </tbody>
                </table>
              </div>
              : <span className="d-flex justify-content-center droppable-text h6 text-center w-100 align-items-center flex-grow-1 m-0">Empty folder</span>
            }
            {/* {
              addingFromLibrary &&
              <div className="w-100 border-top">
                <div className="form-group row mx-5 align-items-center">
                  <h3 className="col-sm-10 pl-0 pt-4 pb-1">
                    {elementRowClicked?.name}
                  </h3>
                  <h6 className="col-sm-10 pl-0">
                    {elementRowClicked?.description}
                  </h6>
                </div>
              </div>
            } */}
            {!addingFromLibrary &&
              <form className='container-fluid py-3 border-top'>
                <div className="form-group row justify-content-center mb-1">
                  <label htmlFor="save-as" className="col-xl-2 col-form-label">Save as</label>
                  <div className="col-xl-6 col-lg-10">
                    <input required={true} type="text" className="form-control" placeholder="Element name" value={itemName} onChange={(e) => setItemName(e.target.value)} />
                  </div>
                </div>
                <div className="form-group row justify-content-center mb-0">
                  <label htmlFor="description" className="col-xl-2 col-form-label">Description</label>
                  <div className="col-xl-6 col-lg-10">
                    <input required={true} type="text" className="form-control" placeholder="Add a description" value={elementDescription} onChange={(e) => setElementDescription(e.target.value)} />
                  </div>
                </div>
              </form>
            }
            <DialogActionsBar>
              {addingFromLibrary ?
                <>
                  <div className="d-flex flex-row justify-content-end w-100">
                    <button type='button' className="k-button btn btn-secondary mr-2" onClick={handleClose}>
                      Cancel
                    </button>
                    <button type='button' disabled={!elementRowClicked || loadLibraryDataset} className="btn btn-primary text-white" onClick={() => importElement(elementRowClicked, library)}>
                      {
                        loadLibraryDataset &&
                        <div className="spinner-border spinner-border-sm mr-2" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      }
                      <span>Add from library</span>
                    </button>
                  </div>
                </> :
                <>
                  <button onClick={handleNewFolderClick} type="button" className="btn btn-shadow btn-icon icon-l">
                    <Icon className='m-0' type="add" />
                    Add folder
                  </button>
                  <div className='d-flex justify-content-end'>
                    <button type='button' className="btn btn-secondary" onClick={handleClose}>
                      Cancel
                    </button>
                    <button type='button' disabled={rowClicked && itemName ? false : true} className="btn btn-primary ml-2" onClick={addElement}>
                      Save
                    </button>
                  </div>
                </>
              }
            </DialogActionsBar>
          </div>
        </div>
      </Dialog>
    </React.Fragment >
  )
}

export default GenericLibrary
