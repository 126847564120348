import React, { useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { RadioButton } from '@progress/kendo-react-inputs';

import { ErrorMessage } from '../ErrorMessage/ErrorMessage';


export default ({ title, description, handleClose, onScheduleSend, onSend, error, verifying, type }) => {
  const [date, setDate] = useState(null)
  const [scheduledTime, setScheduledTime] = useState(false)
  const defaultValue = new Date();

  const onSendClick = () => {
    if (scheduledTime) {
      const utcDate = new Date(date)
      const body = {
        "year": utcDate.getUTCFullYear(),
        "month": utcDate.getUTCMonth() + 1,
        "day": utcDate.getUTCDate(),
        "hour": utcDate.getUTCHours(),
        "minute": utcDate.getUTCMinutes()
      }
      onScheduleSend(body)
    }
    else {
      onSend(false)
    }
  }



  return (
    <Dialog title={title} onClose={handleClose} className="survey-launch-dialog" width="30rem">
      <div className="p-4 d-flex flex-column">
        {error.show &&
          <ErrorMessage errorMessage={error.message} type="alert" />}
        <div className="d-flex flex-column">
          <div className="d-flex  align-items-center mb-3">
            <RadioButton className="mr-3 mt-1"
              onChange={(e) => setScheduledTime(false)}
              checked={!scheduledTime}
            />
            <div className="d-flex flex-column">
              <span className="mb-1 strong">Now</span>
              {type !== 'sample' && <span className="audience-desc">{description}</span>}
            </div>
          </div>
          <div className="d-flex align-items-center mb-3">
            <RadioButton className="mr-3 mt-1"
              onChange={(e) => setScheduledTime(true)}
              checked={scheduledTime}
              disabled={type === 'sample'}
            />
            <div className="d-flex flex-column">
              <span className={`mb-1 strong ${type === 'sample' ? 'text-disabled' : ''}`}>Schedule time</span>
              <DateTimePicker
                format={"dd-MM-yyyy hh:mm:ss a"}
                defaultValue={defaultValue}
                placeholder="Type in your birth date..."
                name="date"
                required
                disabled={!scheduledTime}
                onChange={(e) => setDate(e.value)} />
            </div>
          </div>
        </div>
      </div>
      <DialogActionsBar>
        <Button className="btn btn-secondary" onClick={handleClose}>Cancel</Button>
        <Button className="btn btn-primary d-flex" onClick={() => onSendClick()} disabled={scheduledTime && !date}>
          {
            verifying &&
            <span className="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
          }
          {type === 'sample' ? 'Submit' : 'Send'}
        </Button>
      </DialogActionsBar>
    </Dialog>
  )
}