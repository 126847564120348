import type { SetCategorySorting } from "../../../../../../../../interfaces/workflowInterfaces/toolInterfaces"
import { fetchPatch } from "../../../../../../../../services/services"

interface standardFetchData {
  projectId: string
  workflowId: string
  toolId: string
  codeMapId: string
  token: string
}

interface withCategory extends standardFetchData {
  categoryId: string
}

interface withAnswer extends standardFetchData {
  answerId: string
}

interface categoryAndAnswer extends standardFetchData {
  categoryId: string
  answerId: string
}

interface sortingStateProps {
  sortBy: string
  descending: boolean
}

export const calculateCounts = (answers: TODO) => {
  const answersCopy = JSON.parse(JSON.stringify(answers))
  const totalCount = answersCopy.map((answer: TODO) => !answer.isSplit ? answer.c : 0).reduce((a: number, b: number) => a + b, 0)
  const codedCount = answersCopy.map((answer: TODO) => answer.isDeleted || answer.isCoded ? answer.c : 0).reduce((a: number, b: number) => a + b, 0)
  const codedPercent = ((codedCount / totalCount) * 100).toFixed(1)
  return { totalCount: totalCount, codedCount: codedCount, codedPercent: codedPercent }
}

export const createCategory = (code: string, text: string, fetchData: standardFetchData, codeMapData: TODO, categorySorting: sortingStateProps, setCategorySorting: SetCategorySorting, closeModal: () => void, dispatch: TODO) => {
  const body = { code: code, text: text, }
  fetchPatch(`projects/${fetchData.projectId}/workflows/${fetchData.workflowId}/tools/${fetchData.toolId}/code-maps/${fetchData.codeMapId}/create-category`, fetchData.token, body)
    .then((result: TODO) => result.json())
    .then((res: TODO) => {
      if (res.error || res.message) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } })
      } else {
        const updatedCodeMapData = JSON.parse(JSON.stringify(codeMapData))
        updatedCodeMapData.categories.push(res)
        dispatch({ type: "UPDATE_SELECTED_CODEMAP", payload: updatedCodeMapData })
        setCategorySorting({ ...categorySorting, sortBy: "" })
        closeModal();
      }
    })
}

export const editCategory = (code: string, text: string, codeMapData: TODO, fetchData: withCategory, categorySorting: sortingStateProps, setCategorySorting: SetCategorySorting, closeModal: () => void, dispatch: TODO) => {
  const body = { code: code, text: text, }
  fetchPatch(`projects/${fetchData.projectId}/workflows/${fetchData.workflowId}/tools/${fetchData.toolId}/code-maps/${fetchData.codeMapId}/categories/${fetchData.categoryId}/edit`, fetchData.token, body)
    .then((result: TODO) => result.json())
    .then((res: TODO) => {
      if (res.error || res.message) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } })
      } else {
        const updatedCodeMapData = JSON.parse(JSON.stringify(codeMapData))
        const catIndex = updatedCodeMapData.categories.findIndex((el: TODO) => el.id === res.id)
        updatedCodeMapData.categories[catIndex] = res
        dispatch({ type: "UPDATE_SELECTED_CODEMAP", payload: updatedCodeMapData })
        setCategorySorting({ ...categorySorting, sortBy: "" })
        closeModal();
      }
    })
}

export const deleteCategory = (fetchData: withCategory, closeModal: () => void, codeMapData: TODO, dispatch: TODO, setIsDeletingCategory: (val: boolean) => void) => {
  setIsDeletingCategory(true)
  fetchPatch(`/projects/${fetchData.projectId}/workflows/${fetchData.workflowId}/tools/${fetchData.toolId}/code-maps/${fetchData.codeMapId}/categories/${fetchData.categoryId}/delete`, fetchData.token)
    .then((result: TODO) => result.json())
    .then((res: TODO) => {
      setIsDeletingCategory(false)
      if (res.error || res.message) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } })
      } else {
        const updatedCodeMapData = JSON.parse(JSON.stringify(codeMapData))
        updatedCodeMapData.categories.splice(updatedCodeMapData.categories.findIndex((el: TODO) => el.id === res.categoryId), 1)
        res.updatedRespondentAnswers.forEach((answer: TODO) => !answer.isCoded && updatedCodeMapData.rspAnswers.push(answer) && updatedCodeMapData.filteredAnswers.push(answer))
        dispatch({ type: "UPDATE_SELECTED_CODEMAP", payload: updatedCodeMapData })
        closeModal();
      }
    })
}

export const addCustomDefinition = (body: string[], fetchData: withCategory, closeModal: () => void, codeMapData: TODO, dispatch: TODO) => {
  fetchPatch(`projects/${fetchData.projectId}/workflows/${fetchData.workflowId}/tools/${fetchData.toolId}/code-maps/${fetchData.codeMapId}/categories/${fetchData.categoryId}/create-answers`, fetchData.token, body)
    .then((result: TODO) => result.json())
    .then((res: TODO) => {
      if (res.error || res.message) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } })
      } else {
        const updatedCodeMapData = JSON.parse(JSON.stringify(codeMapData))
        const updatedCategory = updatedCodeMapData.categories.find((el: TODO) => el.id === res.updatedCategory.id)
        updatedCategory.oaToInc = updatedCategory.oaToInc || [];
        updatedCategory.oaToInc = res.updatedCategory.oaToInc;
        updatedCategory.items = updatedCategory.items || [];
        updatedCategory.items.push(...res.addedRespondentAnswers)
        updatedCodeMapData.isCoded = updatedCodeMapData.isCoded || [];
        updatedCodeMapData.isCoded.push(...res.addedRespondentAnswers)

        dispatch({ type: "UPDATE_SELECTED_CODEMAP", payload: updatedCodeMapData })
        closeModal()
      }
    })
}

export const deleteUncategorisedAnswer = (fetchData: withAnswer, codeMapData: TODO, dispatch: TODO) => {
  fetchPatch(`projects/${fetchData.projectId}/workflows/${fetchData.workflowId}/tools/${fetchData.toolId}/code-maps/${fetchData.codeMapId}/answers/${fetchData.answerId}/delete`, fetchData.token)
    .then((result: TODO) => result.json())
    .then((res: TODO) => {
      if (res.error || res.message) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } })
      } else {
        const updatedCodeMapData = JSON.parse(JSON.stringify(codeMapData))
        updatedCodeMapData.deletedAnswers.push(res)
        updatedCodeMapData.rspAnswers.splice(updatedCodeMapData.rspAnswers.findIndex((el: TODO) => el.id === res.id), 1)
        updatedCodeMapData.filteredAnswers.splice(updatedCodeMapData.filteredAnswers.findIndex((el: TODO) => el.id === res.id), 1)

        updatedCodeMapData.countNumbers = calculateCounts([...updatedCodeMapData.rspAnswers, ...updatedCodeMapData.codedAnswers, ...updatedCodeMapData.deletedAnswers])
        dispatch({ type: "UPDATE_SELECTED_CODEMAP", payload: updatedCodeMapData })
      }
    })
}

export const reopenUncategorisedAnswers = (fetchData: standardFetchData, answers: string[], codeMapData: TODO, onHide: () => void, dispatch: TODO) => {
  fetchPatch(`/projects/${fetchData.projectId}/workflows/${fetchData.workflowId}/tools/${fetchData.toolId}/code-maps/${fetchData.codeMapId}/answers/reopen`, fetchData.token, answers)
    .then((result: TODO) => result.json())
    .then((res: TODO) => {
      if (res.error || res.message) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } });
      } else {
        const updatedCodeMapData = JSON.parse(JSON.stringify(codeMapData));
        res.forEach((answer: TODO) => {
          updatedCodeMapData.rspAnswers.push(answer)
          updatedCodeMapData.filteredAnswers.push(answer)
          updatedCodeMapData.deletedAnswers.splice(updatedCodeMapData.deletedAnswers.findIndex((el: TODO) => el.id === answer.id), 1)
        });

        updatedCodeMapData.countNumbers = calculateCounts([...updatedCodeMapData.rspAnswers, ...updatedCodeMapData.codedAnswers, ...updatedCodeMapData.deletedAnswers])
        dispatch({ type: "UPDATE_SELECTED_CODEMAP", payload: updatedCodeMapData });
        onHide();
      }
    })
}

export const deleteCategorisedAnswer = (fetchData: categoryAndAnswer, codeMapData: TODO, dispatch: TODO) => {
  fetchPatch(`/projects/${fetchData.projectId}/workflows/${fetchData.workflowId}/tools/${fetchData.toolId}/code-maps/${fetchData.codeMapId}/categories/${fetchData.categoryId}/answers/${fetchData.answerId}/delete`, fetchData.token)
    .then((result: TODO) => result.json())
    .then((res: TODO) => {
      if (res.error || res.message) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } })
      } else {
        const updatedCodeMapData = JSON.parse(JSON.stringify(codeMapData))
        updatedCodeMapData.codedAnswers.splice(updatedCodeMapData.codedAnswers.findIndex((el: TODO) => el.id === res.id), 1)
        const updatedCategory = updatedCodeMapData.categories.find((el: TODO) => el.id === fetchData.categoryId)
        updatedCategory.items.splice(updatedCategory.items.findIndex((el: TODO) => el.id === res.id), 1)
        updatedCategory.oaToInc.splice(updatedCategory.items.findIndex((el: TODO) => el === res.id), 1)
        updatedCodeMapData.deletedAnswers.push(res)

        updatedCodeMapData.countNumbers = calculateCounts([...updatedCodeMapData.rspAnswers, ...updatedCodeMapData.codedAnswers, ...updatedCodeMapData.deletedAnswers])
        dispatch({ type: "UPDATE_SELECTED_CODEMAP", payload: updatedCodeMapData })
      }
    })
}

export const splitAnswer = (fetchData: withAnswer, body: { splitTexts: string[] }, codeMapData: TODO, closeModal: () => void, dispatch: TODO) => {
  fetchPatch(`/projects/${fetchData.projectId}/workflows/${fetchData.workflowId}/tools/${fetchData.toolId}/code-maps/${fetchData.codeMapId}/answers/${fetchData.answerId}/split`, fetchData.token, body)
    .then((result: TODO) => result.json())
    .then((res: TODO) => {
      if (res.error || res.message) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } })
      } else {
        const updatedCodeMapData = JSON.parse(JSON.stringify(codeMapData));
        const splitAnswer = updatedCodeMapData.rspAnswers.find((el: TODO) => el.id === fetchData.answerId);                                  // Find the split answer in live answers
        updatedCodeMapData.rspAnswers.splice(updatedCodeMapData.rspAnswers.findIndex((el: TODO) => el.id === splitAnswer.id), 1);            // Remove the split answer from live answers
        updatedCodeMapData.filteredAnswers.splice(updatedCodeMapData.filteredAnswers.findIndex((el: TODO) => el.id === splitAnswer.id), 1);  // Remove the split answer from filtered answers
        updatedCodeMapData.splitAnswers.push(splitAnswer);               // Push the split answer to splitAnswers
        updatedCodeMapData.rspAnswers.push(...res);                      // Push the produced answers to live answers
        updatedCodeMapData.filteredAnswers.push(...res);                 // Push the produced answers to filtered answers

        updatedCodeMapData.countNumbers = calculateCounts([...updatedCodeMapData.rspAnswers, ...updatedCodeMapData.codedAnswers, ...updatedCodeMapData.deletedAnswers])
        dispatch({ type: "UPDATE_SELECTED_CODEMAP", payload: updatedCodeMapData })
        closeModal();
      }
    })
}

export const reopenCategory = (fetchData: withCategory, codeMapData: TODO, dispatch: TODO) => {
  fetchPatch(`projects/${fetchData.projectId}/workflows/${fetchData.workflowId}/tools/${fetchData.toolId}/code-maps/${fetchData.codeMapId}/categories/${fetchData.categoryId}/reopen`, fetchData.token)
    .then((result: TODO) => result.json())
    .then((res: TODO) => {
      if (res.error || res.message) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } });
      } else {
        const updatedCodeMapData = JSON.parse(JSON.stringify(codeMapData));
        const updatedCategory = updatedCodeMapData.categories.find((el: TODO) => el.id === fetchData.categoryId)
        updatedCategory.items = [];
        updatedCategory.oaToInc = [];

        res.updatedRespondentAnswers.forEach((answer: TODO) => {
          if (!answer.isCoded) {
            updatedCodeMapData.codedAnswers.splice(updatedCodeMapData.codedAnswers.findIndex((el: TODO) => el.id === answer.id), 1)
            updatedCodeMapData.rspAnswers.push(answer)
            updatedCodeMapData.filteredAnswers.push(answer)
          }
        })

        updatedCodeMapData.countNumbers = calculateCounts([...updatedCodeMapData.rspAnswers, ...updatedCodeMapData.codedAnswers, ...updatedCodeMapData.deletedAnswers])
        dispatch({ type: "UPDATE_SELECTED_CODEMAP", payload: updatedCodeMapData });
      }
    })
}

export const reopenCategorisedAnswers = (fetchData: categoryAndAnswer, codeMapData: TODO, dispatch: TODO, reopenFromAll: boolean) => {
  const reopenUrl = reopenFromAll ? `projects/${fetchData.projectId}/workflows/${fetchData.workflowId}/tools/${fetchData.toolId}/code-maps/${fetchData.codeMapId}/answers/${fetchData.answerId}/reopen` : `projects/${fetchData.projectId}/workflows/${fetchData.workflowId}/tools/${fetchData.toolId}/code-maps/${fetchData.codeMapId}/categories/${fetchData.categoryId}/answers/${fetchData.answerId}/reopen`;
  fetchPatch(reopenUrl, fetchData.token)
    .then((result: TODO) => result.json())
    .then((res: TODO) => {
      if (res.error || res.message) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } });
      } else {
        const updatedCodeMapData = JSON.parse(JSON.stringify(codeMapData));
        if (reopenFromAll) {
          res.updatedCategoryIds.forEach((categoryId: TODO) => {
            const reopenedAnswerIndexInItems = updatedCodeMapData.categories.find((el: TODO) => el.id === categoryId).items.findIndex((el: TODO) => el.id === res.updatedRespondentAnswer.id)
            const reopenedAnswerIndexInOaToInc = updatedCodeMapData.categories.find((el: TODO) => el.id === categoryId).items.findIndex((el: TODO) => el.id === res.updatedRespondentAnswer.id)
            updatedCodeMapData.categories.find((el: TODO) => el.id === categoryId).items.splice(reopenedAnswerIndexInItems, 1)
            updatedCodeMapData.categories.find((el: TODO) => el.id === categoryId).oaToInc.splice(reopenedAnswerIndexInOaToInc, 1)
          })

          if (!res.updatedRespondentAnswer.isCoded) {
            // If the answer has isCoded = true, then it means it's still coded in other categories and it's not returned to rspAnswers
            // If !isCoded then it's removed from codedAnswers and added to rspAnswers and filteredAnswers 
            const reopenedAnswerIndex = updatedCodeMapData.codedAnswers.findIndex((el: TODO) => el.id === res.updatedRespondentAnswer.id)
            updatedCodeMapData.codedAnswers.splice(reopenedAnswerIndex, 1)
            updatedCodeMapData.rspAnswers.push(res.updatedRespondentAnswer)
            updatedCodeMapData.filteredAnswers.push(res.updatedRespondentAnswer)
          }
          updatedCodeMapData.countNumbers = calculateCounts([...updatedCodeMapData.rspAnswers, ...updatedCodeMapData.codedAnswers, ...updatedCodeMapData.deletedAnswers])
        } else {
          const categoryAnswerIndex = updatedCodeMapData.categories.find((el: TODO) => el.id === fetchData.categoryId).items.findIndex((el: TODO) => el.id === fetchData.answerId)
          updatedCodeMapData.categories.find((el: TODO) => el.id === fetchData.categoryId).items.splice(categoryAnswerIndex, 1)
          updatedCodeMapData.categories.find((el: TODO) => el.id === fetchData.categoryId).oaToInc = res.updatedCategoryAnswerIds;
          res.updatedRespondentAnswers.filter((answer: TODO) => !answer.isCoded).forEach((answer: TODO) => {
            const reopenedAnswerIndex = updatedCodeMapData.codedAnswers.findIndex((el: TODO) => el.id === answer.id)
            updatedCodeMapData.codedAnswers.splice(reopenedAnswerIndex, 1)
            updatedCodeMapData.rspAnswers.push(answer)
            updatedCodeMapData.filteredAnswers.push(answer)
          })
        }
        dispatch({ type: "UPDATE_SELECTED_CODEMAP", payload: updatedCodeMapData });
      }
    })
}

export const mergeCategories = (_code: string, _text: string, _fetchData: standardFetchData, _token: string, _dispatch: TODO) => {
  // Needs backend support, to be implmeneted in the future, remove underscore from params to use them
}

export const moveAnswersToCategories = (fetchData: standardFetchData, body: { categoryCodes: number[], textIds: string[] }, codeMapData: TODO, dispatch: TODO) => {
  fetchPatch(`projects/${fetchData.projectId}/workflows/${fetchData.workflowId}/tools/${fetchData.toolId}/code-maps/${fetchData.codeMapId}/categories/add-answers-to-codes`, fetchData.token, body)
    .then((result: TODO) => result.json())
    .then((res: TODO) => {
      if (res.error || res.message) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } })
      } else {
        const updatedCodeMapData = JSON.parse(JSON.stringify(codeMapData))

        res.categories.forEach((resCategory: TODO) => {
          const catIndex = updatedCodeMapData.categories.findIndex((el: TODO) => resCategory.id === el.id);
          // Need this logic to populate the .items of a category, cause Kendo TreeView accepts subitems only in that manner
          if (typeof catIndex === 'number' && catIndex >= 0) {
            const updatedItems = updatedCodeMapData.categories[catIndex].items ? updatedCodeMapData.categories[catIndex].items : []
            updatedItems.push(...res.updatedRespondentAnswers)
            updatedCodeMapData.categories[catIndex] = { ...resCategory, items: updatedItems };
          } else {
            const updatedCategory = { ...resCategory, items: res.updatedRespondentAnswers }
            updatedCodeMapData.categories.push(updatedCategory)
          }
        })

        res.updatedRespondentAnswers.forEach((resAnswer: TODO) => {
          updatedCodeMapData.rspAnswers.splice(updatedCodeMapData.rspAnswers.findIndex((el: TODO) => resAnswer.id === el.id), 1);   // Remove answer from rspAnswers (live answers)
          updatedCodeMapData.filteredAnswers.splice(updatedCodeMapData.filteredAnswers.findIndex((el: TODO) => resAnswer.id === el.id), 1);   // Remove answer from rspAnswers (live answers)
          updatedCodeMapData.codedAnswers.push(resAnswer);        // Add the answer to codedAnswers
        })

        updatedCodeMapData.countNumbers = calculateCounts([...updatedCodeMapData.rspAnswers, ...updatedCodeMapData.codedAnswers, ...updatedCodeMapData.deletedAnswers])
        dispatch({ type: "UPDATE_SELECTED_CODEMAP", payload: updatedCodeMapData });
      }
    })
}

export const refreshCodeMap = (fetchData: standardFetchData, codeMapData: TODO, selectedCodeMap: TODO, selectCodeMapHandler: (value: TODO) => void, dispatch: TODO) => {
  dispatch({ type: "SET_CODING_LOADING_STATE", payload: "both" })
  fetchPatch(`/projects/${fetchData.projectId}/workflows/${fetchData.workflowId}/tools/${fetchData.toolId}/refresh`, fetchData.token)
    .then((result: TODO) => result.json())
    .then((res: TODO) => {
      if (res.error || res.message) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } })
        dispatch({ type: "SET_CODING_LOADING_STATE", payload: null })
      } else {
        if (res.updatedCodeMaps.length > 0) {
          if (res.updatedCodeMaps.includes(codeMapData.id)) {
            selectCodeMapHandler({ value: selectedCodeMap })
          } else {
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "Coding tool updated, but there were no changes to this code map" } })
            dispatch({ type: "SET_CODING_LOADING_STATE", payload: null })
          }
        } else {
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: res.reason } })
          dispatch({ type: "SET_CODING_LOADING_STATE", payload: null })
        }
      }
    })
}

export const sortAnswersHandler = (sortType: string, codeMapData: TODO, sortingState: sortingStateProps, setSortingState: (obj: sortingStateProps) => void, setSelected: (value: TODO) => void, dispatch: TODO, sortDir: boolean, fetchData: standardFetchData) => {
  const sortedData = [...codeMapData.filteredAnswers]

  const sortingFunction = (sortBy: string, descending: boolean) => {
    sortedData.sort((catA, catB) => {
      if (catA[sortBy] === catB[sortBy]) return 0;
      return descending
        ? (catA[sortBy] < catB[sortBy] ? 1 : -1)
        : (catA[sortBy] < catB[sortBy] ? -1 : 1);
    });
  }

  setSortingState({ ...sortingState, sortBy: sortType, descending: typeof sortDir === 'boolean' ? sortDir : !sortingState.descending })
  sortingFunction(sortType, typeof sortDir === 'boolean' ? sortDir : !sortingState.descending)

  const body = { verbatimSortBy: sortType === 'c' ? 'AnswerCount' : 'Text', descending: sortDir }
  fetchPatch(`projects/${fetchData.projectId}/workflows/${fetchData.workflowId}/tools/${fetchData.toolId}/code-maps/${codeMapData.id}/set-verbatim-sorting`, fetchData.token, body)
    .then((res: TODO) => {
      if (res.status !== 200) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `Sort error: ${res.error ? res.error : res.message}` } })
      }
    })

  dispatch({ type: "UPDATE_SELECTED_CODEMAP", payload: { ...codeMapData, filteredAnswers: sortedData } })
  setSelected(["0"]);
}

export const sortCategoryHandler = (fetchData: standardFetchData, sortType: string, codeMapData: TODO, sortingState: sortingStateProps, setSortingState: (obj: sortingStateProps) => void, setSelected: (value: TODO) => void, dispatch: TODO, sortDir: boolean) => {
  const updatedSortType = sortType === "oaToInc" ? "AnswerCount" : sortType === 'code' ? 'Code' : 'Text';
  const body = { sortBy: updatedSortType, descending: sortDir }
  setSortingState({ ...sortingState, sortBy: sortType, descending: sortDir })

  fetchPatch(`projects/${fetchData.projectId}/workflows/${fetchData.workflowId}/tools/${fetchData.toolId}/code-maps/${fetchData.codeMapId}/sort-categories`, fetchData.token, body)
    .then((result: TODO) => result.json())
    .then((res: TODO) => {
      if (res.error || res.message) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } })
      } else {
        const updatedCategories = [...codeMapData.categories]

        updatedCategories.sort((a: TODO, b: TODO) => {
          const A = a.id;
          const B = b.id;
          if (res.indexOf(A) > res.indexOf(B)) {
            return 1;
          }
          return -1
        })

        setSelected([]);
        dispatch({ type: "UPDATE_SELECTED_CODEMAP", payload: { ...codeMapData, categories: updatedCategories } })
      }
      dispatch({ type: "SET_CODING_LOADING_STATE", payload: null })
    })
}