import type React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';

import { Skeleton } from '../../../shared/Skeleton/Skeleton';
import { formatDate } from '../../../shared/DateUtils/DateUtils';
import { timeSince } from '../../../shared/helpers/timeSince/timeSince';
import type { RootState } from '../../../../store/reducers/rootReducer';
import { returnTableIcon } from '../../../shared/helpers/project/returnTableIcon/returnTableIcon';
import type { RecentData, RecentDocument } from '../../../../interfaces/dashboardDataInterfaces/dashboardDataInterfaces';
import { fetchGetJson as getRecentDocuments } from '../../../../services/services';

export const DashboardRecentDocuments: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const recentData: RecentData = useSelector((theState: TODO) => (theState.recentDataReducer))
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [isLoadingDocuments, setIsLoadingDocuments] = useState<boolean>(recentData?.documentsLoaded === true ? false : true);
  const [error, setError] = useState(false)

  useEffect(() => {
    getRecentDocuments("sta/statistics/recentlymodifiedprojectitems", token)
      .then((res: TODO) => {
        setIsLoadingDocuments(false);
        if (res && !res.error && !res.message) {
          if (Array.isArray(res)) {
            dispatch({ type: "SET_RECENT_DOCUMENTS_DATA", payload: res })
          } else setError(true)
        } else setError(true)
      })
  }, []);

  const onRecentItemClick = (dataItem: RecentDocument) => {
    dispatch({ type: 'UPDATE_DOCUMENT_BREADCRUMB', payload: { id: dataItem.id, name: dataItem.name } })
    switch (dataItem.type) {
      case "dataset":
        history.push({
          pathname: `projects/${dataItem.projectId}/${dataItem.id}/analyze/${dataItem.type}s`,
          state: { datasetName: dataItem.name },
        });
        break;
      case "survey":
        history.push({
          pathname: `projects/${dataItem.projectId}/${dataItem.id}/survey-builder`,
        });
        break;
      case "pinboard":
        dispatch({ type: "RESET_PINBOARD_DATA" });
        history.push({
          pathname: `projects/${dataItem.projectId}/${dataItem.id}/pinboards`,
        });
        break;
      case "sample":
        history.push({
          pathname: `projects/${dataItem.projectId}/${dataItem.id}/panel`,
        });
        break;
      case "workflow":
        history.push({
          pathname: `projects/${dataItem.projectId}/${dataItem.id}/workflow`,
        });
        break;
      case "audience":
        history.push({
          pathname: `projects/${dataItem.projectId}/audience/${dataItem.id}`,
        });
        break;
      default:
        history.push({ pathname: `/projects/${dataItem.id}` });
    }
  };

  return (
    <div className="col-lg-6">
      {
        error && !isLoadingDocuments ?
          <div className="dashboard-no-activity-card text-muted gap-md">
            <i className="fal fa-exclamation-circle fa-2x" />
            <p className='m-0'>Failed to load recent activity</p>
          </div>
          :
          recentData?.recentDocuments.length === 0 && !isLoadingDocuments ?
            <div className="dashboard-no-activity-card text-muted gap-md">
              <i className="fal fa-folder-open fa-2x" />
              <p className="mb-0 h5">No recent activity</p>
            </div>
            : null
      }
      <div className="dashboard-card activity gap-lg">
        <p className="m-0 h5">
          <strong>Recent activity</strong>
        </p>
        <table className="table table-hover m-0">
          {
            !isLoadingDocuments && recentData?.recentDocuments.length > 0 && !error &&
            <thead>
              <tr className="no-hover">
                <td className="text-muted border-0 py-2 label">Name</td>
                <td className="text-muted border-0 py-2 label date">Date modified</td>
              </tr>
            </thead>
          }
          <tbody>
            {
              isLoadingDocuments ?
                Array(5).fill("").map((_, index) => (
                  <tr key={index}>
                    <td style={{ verticalAlign: "middle" }} className="text-left">
                      <div className="d-flex align-items-center gap-md">
                        <Skeleton style={{ width: '25px' }} />
                        <Skeleton style={{ width: '150px' }} />
                      </div>
                    </td>
                    <td style={{ verticalAlign: "middle" }} className='text-left'>
                      <Skeleton style={{ width: '100px' }} />
                    </td>
                  </tr>
                ))
                :
                recentData?.recentDocuments.length > 0 &&
                recentData?.recentDocuments.map((recent, key: number) => (
                  <tr
                    key={key}
                    className="cursor-pointer"
                    onClick={() => onRecentItemClick(recent)}>
                    <td style={{ verticalAlign: "middle" }} className="text-left">
                      <div className="d-flex align-items-center">
                        <i className={`${returnTableIcon(recent.type + "s")} mr-3`} />
                        <div className="text-primary text-truncate text-truncate-fixed">{recent.name}</div>
                      </div>
                    </td>
                    <td style={{ verticalAlign: "middle" }} className="text-left">
                      <Tooltip anchorElement="target" position="top">
                        <span title={formatDate(recent.lastModifiedUtc, "DATETIME_MED")}>
                          {timeSince(new Date(recent.lastModifiedUtc))}
                        </span>
                      </Tooltip>
                    </td>
                  </tr>
                ))
            }
          </tbody>
        </table>
      </div>
    </div >
  )
}