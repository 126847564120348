import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from '../../../../../../shared/Icon/Icon';
import type { RootState } from '../../../../../../../store/reducers/rootReducer';
import { FullHeightSpinner } from '../../../../../../shared/FullHeightSpinner/FullHeightSpinner';
import type { IPropsAdvancedElements, IAdvancedElement, GroupedCustomQuestionData } from '../../../../../../../interfaces/addNewElementModalInterfaces/addNewElementModalInterfaces';
import { groupCustomQuestions } from '../groupCustomQuestions';
import { fetchGetJson } from '../../../../../../../services/services';

export const AddNewElementAdvancedTab = ({ onAddNewElement, inputValue }: IPropsAdvancedElements) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const { groupedCustomQuestionsData, theData } = useSelector((theState: RootState) => (theState.surveyInitialDataReducer))

  const getAllElements = useCallback((folders: TODO) => {
    let elements: TODO = [];
    for (const folder of folders) {
      elements = elements.concat(folder.questionItems)
      if (folder.elementFolders) {
        elements = elements.concat(getAllElements(folder.elementFolders))
      }
    }
    return elements
  }, [])

  useEffect(() => {
    fetchGetJson(`su/customquestions${theData.dcV2 === 2 ? '?version=2' : ''}`, token)
      .then((res: TODO) => {
        if (res.error || res.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
        } else {
          const updatedCustomQuestions = res.map((ques: TODO) => {
            return { customQuestionId: ques.id, ...ques, group: ques.group ? ques.group : "Other" }
          })

          const groupedData = groupCustomQuestions(updatedCustomQuestions);

          dispatch({ type: 'SET_CUSTOM_QUESTIONS_DATA', payload: updatedCustomQuestions })
          dispatch({ type: 'SET_GROUPED_CUSTOM_QUESTIONS_DATA', payload: groupedData })
        }
      })
  }, [dispatch, token, theData.dcV2])

  const tableContent = (value: IAdvancedElement[]) => {
    if (value) {
      return value.filter((val) => {
        if (!inputValue) {
          return val
        }
        return val?.name?.toLowerCase().includes(inputValue?.toLowerCase())
      })
    }
  };

  return (
    <div className="w-100 d-flex flex-column">
      {
        !groupedCustomQuestionsData ?
          <div className='elements-loading'><FullHeightSpinner />
          </div>
          :
          <div className='d-flex flex-column gap-md'>
            {groupedCustomQuestionsData.map((item: GroupedCustomQuestionData) =>
              <div key={item.group}>
                {/* <p className='mb-1 strong'>{item.group}</p> */}
                {
                  tableContent(item.value)?.map((advancedElement: IAdvancedElement) =>
                    <div onClick={() => onAddNewElement(advancedElement)} key={advancedElement.customQuestionId} className="d-flex flex-row px-2">
                      <div className="d-flex justify-content-between align-items-center flex-grow-1 w-100 item">
                        <div className="d-flex align-items-center w-100 gap-md" style={{ minWidth: '0' }}>
                          <Icon className='flex-shrink-0' type={advancedElement.icon ? advancedElement.icon : 'general'} />
                          <p className='text-truncate m-0'>{advancedElement.name}</p>
                        </div>
                        {
                          advancedElement.isInternal &&
                          <div className="badge badge-light">Internal</div>
                        }
                      </div>
                    </div>
                  )
                }
              </div>
            )}
          </div>
      }
    </div>
  )
}
