import React from 'react';
import { Tooltip } from '@progress/kendo-react-tooltip';

const SearchInputGroup = ({ searchValue, setSearchValue }) => {
  return (
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text bg-white" id="search-addon">
          <i className="fas fa-search text-muted" />
        </span>
      </div>
      <input
        type="text"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        aria-label="Search"
        placeholder="Search"
        aria-describedby="search-addon"
        className="form-control shadow-none"
      />
    </div>
  )
}

export default ({ multiSelect, toggleReplace, replaceValue, setSearchValue, searchValue, onReplaceHandler, replaceValueHandler }) => {
  let toggleSection = null;

  if (multiSelect) {
    toggleSection =
      <div className="alert alert-disabled small strong text-center m-0" role="alert">
        Please select 2 questions to select everything between them.
      </div>
  } else if (toggleReplace) {
    toggleSection =
      <div className="d-flex align-items-center gap-md">
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text bg-white" id="replace-addon">
              <i className="fas fa-sync text-muted" />
            </span>
          </div>
          <input
            type="text"
            value={replaceValue}
            onChange={(e) => replaceValueHandler(e.target.value)}
            aria-label="Replace"
            placeholder="Replace"
            aria-describedby="replace-addon"
            className="form-control shadow-none"
          />
        </div>
        <Tooltip openDelay={200} anchorElement="target" position="bottom">
          <button
            type="button"
            title="Replace first occurrence"
            className="btn btn-light mh-100 p-4"
            onClick={() => onReplaceHandler('replaceFirst', replaceValue, searchValue)}>
            <i className="far fa-repeat-1 fa-lg pointer-events-none" />
          </button>
        </Tooltip>
        <Tooltip openDelay={200} anchorElement="target" position="bottom">
          <button
            type="button"
            title="Replace all occurrences"
            className="btn btn-light mh-100 p-4"
            onClick={() => onReplaceHandler('replaceAll', replaceValue, searchValue)}>
            <i className="far fa-repeat fa-lg pointer-events-none" />
          </button>
        </Tooltip>
      </div>
  }

  return (
    <React.Fragment>
      <SearchInputGroup searchValue={searchValue} setSearchValue={setSearchValue} />
      {toggleSection}
    </React.Fragment>
  )
}