type CrossTabQuestionEditorCopyData = {
  selectedQuestionId: string
  groups: TODO[]
} | null

export const returnQuestionEditorActionButtonsData = (mergeRowsBtnActive: boolean, selectedQuestion: string, crossTabQuestionEditorCopyData: CrossTabQuestionEditorCopyData) => {
  return [
    {
      actionName: 'Merge rows',
      action: 'mergeRows',
      disabled: !mergeRowsBtnActive
    },
    {
      actionName: 'Set scaling factor',
      action: 'setWeightValues'
    },
    {
      actionName: 'Copy edits',
      action: 'copyEdits',
    },
    {
      actionName: 'Paste edits',
      action: 'pasteEdits',
      disabled: !(typeof crossTabQuestionEditorCopyData?.selectedQuestionId === 'string' && selectedQuestion !== crossTabQuestionEditorCopyData.selectedQuestionId)
    }
  ]
}