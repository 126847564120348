import React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { fetchGetJson, fetchPutJson } from '../../../../../../../services/services';
import { useDispatch } from 'react-redux';

function RenameElementModal({ setRenameElementModal, setSelectedElementType, renameElementModal, directoryElements, library, token, libraryScope }) {
  const dispatch = useDispatch()

  const closeDialog = () => {
    setRenameElementModal({
      show: false,
      id: "",
      name: "",
      description: "",
      folderId: null,
      type: ""
    })
  };

  const onSaveHandler = () => {
    const body = {
      name: renameElementModal.name,
      description: renameElementModal.description,
      type: renameElementModal.type,
    }

    if (library === 'personal') {
      if (directoryElements?.questionItems.some((el) => el.name === body.name)) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'An element with that name already exists' } })
      } else {
        fetchPutJson(`/libraries/${libraryScope}/user/${renameElementModal.folderId}/items/${renameElementModal.id}`, token, body)
          .then(res => {
            if (res.error || res.message) {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
            }
          })
          .then(() => {
            fetchGetJson(`/libraries/${libraryScope}/user/${renameElementModal.folderId}/items`, token)
              .then(res => {
                if (res.length > 0) {
                  setSelectedElementType({ questionItems: res })
                } else {
                  setSelectedElementType()
                }
              })
          })
        setRenameElementModal({
          show: false,
          id: "",
          name: "",
          description: "",
          pid: null,
          type: ""
        })
      }
    } else if (library === 'global') {
      if (directoryElements?.questionItems.some((el) => el.name === body.name)) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'An element with that name already exists' } })
      } else {
        fetchPutJson(`/libraries/${libraryScope}/customer/${renameElementModal.folderId}/items/${renameElementModal.id}`, token, body)
          .then(res => {
            if (res.error || res.message) {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
            }
          })
          .then(() => {
            fetchGetJson(`/libraries/${libraryScope}/customer/${renameElementModal.folderId}/items`, token)
              .then(res => {
                if (res.length > 0) {
                  setSelectedElementType({ questionItems: res })
                } else {
                  setSelectedElementType()
                }
              })
          })
        setRenameElementModal({
          show: false,
          id: "",
          name: "",
          description: "",
          pid: null,
          type: ""
        })
      }
    }
  };

  return (
    <Dialog width="400px" height="auto" className="import-dialog library-sub-dialog no-wrap" title={`Rename element`} onClose={closeDialog}>
      <div className="d-flex flex-row justify-content-center align-items-center p-4">
        <input className="form-control my-auto" value={renameElementModal.name} onChange={(e) => setRenameElementModal({ ...renameElementModal, name: e.target.value })} />
      </div>
      <DialogActionsBar>
        <button className="k-button btn btn-secondary mr-2" onClick={closeDialog}>
          Cancel
        </button>
        <button onClick={onSaveHandler} className="k-button k-button-solid-primary text-white">
          Save
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}

export default RenameElementModal