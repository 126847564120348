import { useState } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';

import type { EmailProviderFormData } from '../../../../../../../../interfaces/emailProviderInterfaces/emailProviderInterfaces';

interface Props {
  formData: EmailProviderFormData
  onChangeHandler: (e: TODO, name: string) => void
}

export const EmailProvidersSMTPSettingsResetPassword = ({ formData, onChangeHandler }: Props) => {
  const [showCollapse, setShowCollapse] = useState(false)

  return (
    <div className="accordion mt-4" id="accordionExample">
      <PanelBar onSelect={() => setShowCollapse(!showCollapse)}>
        <PanelBarItem title="Reset SMTP Password" expanded={showCollapse}>
          <div className="px-4 pb-3">
            <div className="mb-2">
              <Input
                type="password"
                label={'New SMTP password'}
                value={formData.newPassword}
                onChange={(e: TODO) => onChangeHandler(e, 'newPassword')} />
            </div>
            <div className="mb-2">
              <Input
                type="password"
                label={'Confirm new SMTP password'}
                value={formData.confirmNewPassword}
                onChange={(e: TODO) => onChangeHandler(e, 'confirmNewPassword')} />
            </div>
          </div>
        </PanelBarItem>
      </PanelBar>
    </div>
  )
}