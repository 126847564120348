import type { SampleData } from '../../../../../interfaces/panelInterfaces/panelInterfaces';
import { Avatar } from '../../../../shared/Avatar/Avatar';
import { formatDate } from '../../../../shared/DateUtils/DateUtils';

interface Props {
  sampleData: SampleData
}

export const Header = ({ sampleData }: Props) => {

  return (
    <div className="d-flex justify-content-between my-5">
      <div className="d-flex align-items-center">
        <Avatar color="primary" icon="fa fa-poll-h" class="avatar-lg" />
        <div className="d-flex flex-column justify-content-center ml-2">
          <h3 className="text-primary mb-0">{sampleData.name} </h3>
          {sampleData.description !== '' && <h6 className="mt-2">{sampleData.description}</h6>}
        </div>
      </div>
      <div className="d-flex flex-column align-items-center">
        <h6 className="mb-1">Status</h6>
        <h4 className="mb-0">
          <span className={`badge ${sampleData.isSubmitNow === true ? "bg-success text-white" : "bg-light text-dark"}`}>
            {sampleData.isSubmitNow ? 'SUBMITTED' : 'NOT SUBMITTED'}
          </span></h4>
      </div>
      <div className="d-flex">
        <div className="d-flex justify-content-center align-items-center">
          <Avatar icon="fas fa-user" class="avatar-sm" color="light-blue" />
          <div className="d-flex flex-column">
            <h6 className="mb-2"> {sampleData.createdByName}</h6>
            <h6 className="m-0">{formatDate(sampleData.createdUtc, "DATETIME_MED")}</h6>
          </div>
        </div>
      </div>
    </div>
  )
}

