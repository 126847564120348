import type React from 'react'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'

import type { GkAddSuppliers } from '../../../interfaces/gateKeeperInterfaces/gateKeeperInterfaces'
import { Icon } from '../../shared/Icon/Icon'

interface Props {
  onHide: (reason: string) => void
  saveSupplier: (e: React.FormEvent<HTMLFormElement>) => void
  addSuplierData: GkAddSuppliers | undefined
  setAddSuplierData: (data: Partial<GkAddSuppliers>) => void
  error: TODO
}

export const GateKeeperNewSupplierModal = ({ onHide, saveSupplier, setAddSuplierData, addSuplierData, error }: Props) => {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddSuplierData({ ...addSuplierData, [e.target.name]: e.target.value })
  }

  return (
    <Dialog width={"600px"} className='supplier-new-modal' contentStyle={{ padding: 0, fontSize: 14 }} title={"New supplier"} onClose={() => onHide("Suppliers New")}>
      <form onSubmit={(e) => saveSupplier(e)} >
        <div className='p-2' >
          <div className="details-input d-flex flex-column">
            <label htmlFor='name' className='p-0 mb-0 strong text-primary'>Supplier name</label>
            <input className='form-control' type="text" name='name' placeholder='Enter supplier name' value={addSuplierData?.name} onChange={(e) => handleChange(e)} />
            {error.name?.name &&
              <div className="text-danger">
                <small>This field is required.</small>
              </div>}
          </div>
          <p className='text-primary bg-secondary p-2 mb-1 mx-3 medium d-flex align-items-center' ><span><Icon type="info-icon" fill='primary' /></span> Please use [%RID%] to indicate where the Respondent ID should be populated.</p>
          <div className="details-input d-flex flex-column">
            <label htmlFor='completeUrl' className='p-0 mb-0 strong text-primary'>Complete redirect</label>
            <input className='form-control' type="text" name="completeUrl" placeholder='http://' value={addSuplierData?.completeUrl} onChange={(e) => handleChange(e)} />
            {error.completeUrl?.completeUrl &&
              <div className="text-danger">
                <small>This field is required.</small>
              </div>}
            {error.completeUrl?.invalidUrl &&
              <div className="text-danger">
                <small>Invalid URL format.</small>
              </div>}
          </div>
          <div className="details-input d-flex flex-column">
            <label htmlFor='screenOutUrl' className='p-0 mb-0 strong text-primary'>Screen out redirect</label>
            <input className='form-control' type="text" name='screenOutUrl' placeholder='http://' value={addSuplierData?.screenOutUrl} onChange={(e) => handleChange(e)} />
            {error.screenOutUrl?.screenOutUrl &&
              <div className="text-danger">
                <small>This field is required.</small>
              </div>}
            {error.screenOutUrl?.invalidUrl &&
              <div className="text-danger">
                <small>Invalid URL format.</small>
              </div>}
          </div>
          <div className="details-input d-flex flex-column">
            <label htmlFor='quotaOutUrl' className='p-0 mb-0 strong text-primary'>Over quota redirect</label>
            <input className='form-control' type="text" name='quotaOutUrl' placeholder='http://' value={addSuplierData?.quotaOutUrl} onChange={(e) => handleChange(e)} />
            {error.quotaOutUrl?.quotaOutUrl &&
              <div className="text-danger">
                <small>This field is required.</small>
              </div>}
            {error.quotaOutUrl?.invalidUrl &&
              <div className="text-danger">
                <small>Invalid URL format.</small>
              </div>}
          </div>
          <div className="details-input d-flex flex-column">
            <label htmlFor='qualityFailUrl' className='p-0 mb-0 strong text-primary'>Quality termination redirect</label>
            <input className='form-control' type="text" name='qualityFailUrl' placeholder='http://' value={addSuplierData?.qualityFailUrl} onChange={(e) => handleChange(e)} />
            {error.qualityFailUrl?.qualityFailUrl &&
              <div className="text-danger">
                <small>This field is required.</small>
              </div>}
            {error.qualityFailUrl?.invalidUrl &&
              <div className="text-danger">
                <small>Invalid URL format.</small>
              </div>}
          </div>

        </div>
        <DialogActionsBar>
          <div className='d-flex'>
            <button type='button' className="btn btn-secondary mr-2" onClick={() => onHide("Suppliers New")}>Cancel</button>
            <button type='submit' className="btn btn-primary">Save supplier
            </button>
          </div>
        </DialogActionsBar>
      </form>
    </Dialog >
  )
}