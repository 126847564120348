import { onDragEnd } from "../../../components/Analysis/Analyze/components/shared/helpers/onDragEnd/onDragEnd";

const initialState = {
  theState: {
    columnData: [],
    questions: [],
    displayTable: "",
    icon: '',
    activeState: 0,
    zoomLevel: 1,
    source: {},
    destination: {},
    removeItem: { 'id': null, 'item': null },
  }
};

function reportStateReducer(state = initialState.theState, action) {
  switch (action.type) {
    case 'UPDATE_INITIAL_REPORT_DATA_STATE':
      if (action.payload) {
        return {
          ...state,
          ...action.payload
        }
      } else {
        return {
          ...state,
          columnData: [],
          questions: [],
          displayTable: "",
          icon: '',
          activeState: 0,
          zoomLevel: 1,
          source: {},
          destination: {},
          removeItem: { 'id': null, 'item': null },
        }
      }
    case 'REPORT_CLEAR_DATA':
      return {
        ...state,
        columnData: [],
        questions: [],
        displayTable: "",
        icon: '',
        activeState: 0,
        zoomLevel: 1,
        source: {},
        destination: {},
        removeItem: { 'id': null, 'item': null },
      }
    case 'REPORT_ON_DRAG_END':
      return onDragEnd(
        action.payload.result,
        state,
        state.source,
        state.destination,
        state.removeItem,
        [],
        [],
        action.payload.theData,
        action.payload.rangeItems)
    case 'SET_REPORT_COLUMN_DATA':
      return {
        ...state,
        columnData: [...state.columnData].concat([...action.payload.data])
      }
    case 'SET_REPORT_QUESTIONS_DATA':
      return {
        ...state,
        questions: [...state.questions].concat([...action.payload.data])
      }
    case 'SET_REPORT_DISPLAY_TABLE':
      return {
        ...state,
        activeState: action.payload
      }
    case 'REPORT_ON_CHECK_QUESTION':
      return {
        ...state,
        questions: action.payload
      }
    case 'REPORT_DISABLE_SELECTED_QUESTIONS':
      return {
        ...state,
        questions: action.payload.questions,
        columnData: action.payload.columns
      }
    case 'REPORT_ENABLE_SELECTED_QUESTIONS':
      return {
        ...state,
        questions: action.payload.questions,
        columnData: action.payload.columns
      }
    case 'REPORT_DELETE_SELECTED_QUESTIONS':
      return {
        ...state,
        activeState: 0,
        questions: action.payload.questions,
        columnData: action.payload.columnData
      }
    case 'REPORT_UPDATE_QUESTIONS':
      return {
        ...state,
        questions: action.payload

      }
    default:
      return { ...state };
  }
}

export default reportStateReducer;
