import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { extendDataItem, mapTree, TreeList } from '@progress/kendo-react-treelist';
import { Pager } from '@progress/kendo-react-data-tools';
//@ts-ignore
import { v4 as uuid } from 'uuid';

import { FullHeightSpinner } from '../../shared/FullHeightSpinner/FullHeightSpinner';
import { fetchDelete as removeTask, fetchGetJson as getTasksOrHistory, fetchPutResOrJson as cancelTask } from '../../../services/services';
import { returnTasksTreeListColumnSettings } from '../../shared/helpers/returnTreeListColumnSettings/returnTreeListColumnSettings';
import type { Task } from '../../../interfaces/tasksInterfaces/tasksInterfaces';
import { Icon } from '../../shared/Icon/Icon';
import { formatDate } from '../../shared/DateUtils/DateUtils';
import ConfirmCancelModal from '../ConfirmCancelModal/ConfirmCancelModal';

interface Props {
  token: string;
}

export default function TasksList({ token }: Props) {
  const [tasks, setTasks] = useState<Task[] | null>(null)
  const [newTasks, setNewTasks] = useState<Task[] | null | undefined>(null)
  const [expanded, setExpanded] = useState<string[]>([])
  const [pagination, setPagination] = useState<{ skip: number, take: number }>({
    skip: 0,
    take: 30
  })
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [taskId, setTaskId] = useState<string>("");
  const [type, setType] = useState("");

  const toggleDialog = () => {
    setDialogVisible(!dialogVisible);
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (tasks === null) {
      getTasksOrHistory("t/tasks", token).then((res: TODO) => {
        if (res && (res.message || res.error)) {
          dispatch({ type: "UPDATE_ERROR_MESSAGE", payload: { msg: res.message ? res.message : res.error, type: "modal" } });
        } else {
          const reversedTasks = res.reverse();
          const newTasks = reversedTasks.map((task: TODO) => {
            return { ...task, itemId: uuid(), items: [{}] }
          })
          setTasks(newTasks)
          setNewTasks(newTasks)
        }
      })
    }
  }, [tasks, token, dispatch])

  const TreeListPager = (props: TODO) => {
    return <Pager {...props} previousNext={true} buttonCount={5} />;
  };

  const onPageChange = (event: TODO) => {
    const { skip, take } = event;
    setPagination({ ...pagination, skip, take });
  };

  const onExpandChange = (e: TODO) => {
    const item = e.dataItem;
    !item.expanded && getTasksOrHistory(`t/tasks/${item.id}/history`, token).then((res: TODO) => {
      if (res.length) {
        const updatedTasks: Task[] | undefined = newTasks?.map((originalItem) => {
          if (originalItem.id === item.id) {
            return { ...originalItem, items: [...res] };
          }
          return originalItem;
        });
        setNewTasks(updatedTasks);
      }
    })

    setExpanded(e.value ? expanded.filter((itemId: TODO) => itemId !== item.itemId) : [...expanded, item.itemId]);
  }

  const updateFields = (tasks: TODO) => {
    const tree = tasks;
    return mapTree(tree, "items", item => extendDataItem(item, "items", {
      expanded: expanded.includes(item.itemId)
    }))
  }

  const handleButtonClick = (id: string, canceled: boolean) => {
    setTaskId(id);
    toggleDialog();

    if (canceled) {
      setType("remove");
    } else {
      setType("cancel");
    }
  }

  const onConfirmCancel = () => {
    let updatedTasks: Task[] | undefined = newTasks?.map(task => {
      if (task.id === taskId) {
        return { ...task, status: 999 }
      }
      return task;
    })
    setNewTasks(updatedTasks);

    cancelTask(`t/tasks/${taskId}/cancel`, token).then((res: TODO) => {
      if (res && res.status === 202) {
        updatedTasks = newTasks?.map((task) => {
          if (task.id === taskId) {
            return { ...task, status: 3 };
          }
          return task;
        })
        setNewTasks(updatedTasks);
        dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The task has been canceled successfully' } })
      } else if (res && (res.message || res.error)) {
        dispatch({ type: "UPDATE_ERROR_MESSAGE", payload: { msg: res.message ? res.message : res.error, type: "modal" } });
      }
    })
    toggleDialog();
  }

  const onConfirmRemove = () => {
    const updatedTasks: Task[] | undefined = newTasks?.map(task => {
      if (task.id === taskId) {
        return { ...task, spinner: true }
      }
      return task;
    })
    setNewTasks(updatedTasks);

    removeTask(`t/tasks/${taskId}`, token).then((res: TODO) => {
      if (res && (res.message || res.error)) {
        dispatch({ type: "UPDATE_ERROR_MESSAGE", payload: { msg: res.message ? res.message : res.error, type: "modal" } });
      } else {
        const updatedTasks = newTasks?.filter((task) => task.id !== taskId)
        setNewTasks(updatedTasks);
        dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The task has been removed successfully' } })
      }
    })
    toggleDialog();
  }

  const badgeProps = (item: TODO, fromStatus: boolean, fromOutcome: boolean) => {
    let color = "";
    let text = "";

    if (fromStatus) {
      if (item.status === 1) {
        color = "badge-queued";
        text = "Queued"
      }
      if (item.status === 2) {
        color = "badge-success";
        text = "Processed"
      }
      if (item.status === 3) {
        color = "badge-cancelled";
        text = "Cancelled"
      }
      if (item.status === 4) {
        color = "badge-paused";
        text = "Paused"
      }
    }

    if (fromOutcome) {
      if (item.lastOutcome === 1 || item.outcome === 1) {
        color = "bg-success";
        text = "Successful"
      }
      if (item.lastOutcome === 2 || item.outcome === 2) {
        color = "bg-danger";
        text = "Error"
      }
    }

    return { color, text }
  }

  const typeIconCell = () => {
    return (
      <Fragment>
        <td />
      </Fragment>
    )
  }

  const eventTypeCell = (props: TODO) => {
    const item = props.dataItem;

    if (item.eventType) {
      return (
        <td>
          <span>{item.eventType}</span>
        </td>
      )
    }
    if (item.errorMessage) {
      return (
        <td className="second-row-td">
          <div className="second-row-td__container">
            <span className="second-row-td__title">Error message</span>
            <span>{item.errorMessage}</span>
          </div>
        </td>
      )
    }
    return (
      <td colSpan={9}>
        <span>No history for this task</span>
      </td>
    )
  }

  const titleCell = (props: TODO) => {
    const item = props.dataItem;

    return (
      <Fragment>
        {item.eventType ?
          <td>
            <span>{item.title}</span>
          </td>
          : (
            item.processedUtc ?
              <td className="second-row-td">
                <div className="second-row-td__container">
                  <span className="second-row-td__title">Processed</span>
                  <span>{formatDate(item.processedUtc, "DATETIME_MED")}</span>
                </div>
              </td>
              :
              null
          )
        }
      </Fragment>
    )
  }

  const createdByCell = (props: TODO) => {
    const item = props.dataItem;
    const badge = badgeProps(item, false, true);

    return (
      <Fragment>
        {item.eventType ?
          <td>
            <span>{item.createdByName}</span>
          </td>
          : (
            item.outcome ?
              <td className="second-row-td">
                <div className="second-row-td__container">
                  <span className="second-row-td__title">Outcome</span>
                  <span className={`badge ${badge.color} text-white align-self-start strong p-1`}>{badge.text}</span>
                </div>
              </td>
              :
              null
          )
        }
      </Fragment>
    )
  }

  const createdDateCell = (props: TODO) => {
    const item = props.dataItem;

    return (
      <Fragment>
        {item.eventType ?
          <td>
            <span>{formatDate(item.createdUtc, "DATETIME_MED")}</span>
          </td>
          : (
            item.errorMessage ?
              <td />
              :
              null
          )
        }
      </Fragment>
    )
  }

  const nextProcessingCell = (props: TODO) => {
    const item = props.dataItem;

    return (
      <Fragment>
        {item.eventType ?
          <td><span>{formatDate(item.nextProcessingUtc, "DATETIME_MED")}</span></td>
          : (
            item.errorMessage ?
              <td />
              :
              null
          )
        }
      </Fragment>
    )
  }

  const lastProcessingCell = (props: TODO) => {
    const item = props.dataItem;

    return (
      <Fragment>
        {item.eventType ?
          <td><span>{formatDate(item.lastProcessingUtc, "DATETIME_MED")}</span></td>
          : (
            item.errorMessage ?
              <td />
              :
              null
          )
        }
      </Fragment>
    )
  }

  const cancelTaskCell = (props: TODO) => {
    const item = props.dataItem;
    const canceled: boolean = item.status === 3;

    return (
      <Fragment>
        {item.eventType ?
          (!item.spinner ?
            <td>
              {item.status !== 2 && (
                <button type='button' onClick={() => handleButtonClick(item.id, canceled)} className="btn btn-icon icon-l btn-shadow flex-shrink-0 fit-content">
                  <Icon type={canceled ? "delete-alt" : "cancel"} />
                  <span className="ml-1">{canceled ? "Remove" : "Cancel"}</span>
                </button>
              )
              }
            </td>
            :
            <td>
              <div className="d-flex flex-grow-1 align-items-center h-100">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </td>
          )
          :
          (
            item.errorMessage ?
              <td />
              :
              null
          )
        }
      </Fragment>
    )
  }

  const statusCell = (props: TODO) => {
    const item = props.dataItem;

    const badge = badgeProps(item, true, false);

    return (
      <Fragment>
        {item.eventType ? (
          item.status === 999 ?
            <td>
              <div className="d-flex flex-grow-1 align-items-center h-100">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </td>
            :
            <td><span className={`status-badge ${badge.color}`}><span className='bullet mr-1' />{badge.text}</span></td>
        )
          :
          (
            item.errorMessage ?
              <td />
              :
              null
          )
        }
      </Fragment>
    )
  }

  return (
    <Fragment>
      {newTasks ? (
        newTasks.length ? (
          <TreeList
            data={updateFields(newTasks)}
            subItemsField="items"
            onExpandChange={onExpandChange}
            onPageChange={onPageChange}
            skip={pagination.skip}
            take={pagination.take}
            className="tasks-tree-list"
            columns={returnTasksTreeListColumnSettings(createdDateCell, cancelTaskCell, eventTypeCell, nextProcessingCell, lastProcessingCell, statusCell, titleCell, createdByCell, typeIconCell)}
            expandField="expanded"
            pager={TreeListPager}
          />
        ) : (
          <div className="d-flex justify-content-center mt-3">
            <p className="text-center">No tasks found</p>
          </div >
        )
      ) : (
        <div className='d-flex py-10'>
          <FullHeightSpinner />
        </div>
      )}
      {
        dialogVisible &&
        <ConfirmCancelModal toggleDialog={toggleDialog} onConfirmCancel={onConfirmCancel} onConfirmRemove={onConfirmRemove} type={type} />
      }
    </Fragment>
  )
}