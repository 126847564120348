import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

import { RootState } from '../../store/reducers/rootReducer';
import { PanelTabContent } from './PanelTabContent/PanelTabContent';
import { FullHeightSpinner } from '../shared/FullHeightSpinner/FullHeightSpinner';
import { fetchGetJson as getSample, fetchPutJson as updateSampleSpecification } from '../../services/services'

interface Props {
  token: string
}

const tabs = [{ title: 'Overview', id: 'overview' }, { title: 'Design', id: 'design' }, { title: 'Preview', id: 'preview' }, { title: 'Submit', id: 'submit' }, { title: 'Collect', id: 'collect' }]

export const Panel = ({ token }: Props) => {
  document.title = "Panel – Walr"
  const dispatch = useDispatch();
  const params: { name: string, sample: string } = useParams();
  const [tabSelected, setTabSelected] = useState<number>(0)
  const { panelData } = useSelector((theState: RootState) => theState.panelStateReducer);

  useEffect(() => {
    if (params.name !== '' && params.sample !== '') {
      getSample(`projects/${params.name}/samples/${params.sample}`, token)
        .then((res: any) => {
          if (res.error || res.message) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } })
          } else {
            dispatch({ type: 'SET_PANEL_INITIAL_DATA', payload: res })
          }
        })
    }
  }, [])

  const handleSelect = (selected: any) => {
    setTabSelected(selected)
  }

  const onReviewAndSubmit = () => {
    if (panelData.sampleSpecHasChanges) {
      const body = panelData.sampleSpecification
      updateSampleSpecification(`projects/${panelData.sampleData.projectId}/samples/${panelData.sampleData.id}/specification`, token, body)
        .then((res: any) => {
          dispatch({ type: 'SET_SAMPLE_SPECIFICATION_DATA', payload: res })
          setTabSelected(3)
        })
    } else {
      setTabSelected(3)
    }
  }

  return (
    <section className="main panel-container">
      {panelData.sampleData !== null ?
        <TabStrip selected={tabSelected} onSelect={(e) => handleSelect(e.selected)} className="analyze-tabs h-100">
          {
            tabs.map((el, id) => (
              <TabStripTab key={id} title={el.title}>
                <PanelTabContent
                  tabType={el.id}
                  token={token}
                  onReviewAndSubmit={onReviewAndSubmit}
                />
              </TabStripTab>
            ))
          }
        </TabStrip> :
        <FullHeightSpinner />}
    </section>
  )
}
