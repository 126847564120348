import React from 'react';
import { ListView } from '@progress/kendo-react-listview';
import { DropDownButton, Button } from '@progress/kendo-react-buttons';

const CombineFilterData = ({ data, onOperatorClick, onRemoveFilterQuestion, onNestingStartClick, onNestingEndClick, filterType }) => {
  const itemRender = (props) => {
    const selectedExpression = data.find(item => item.ques === props.dataItem.ques).ques
    const actions = data.find(item => item.ques === props.dataItem.ques).operators
    const nestingStart = data.find(item => item.ques === props.dataItem.ques).nestingStart
    const nestingEnd = data.find(item => item.ques === props.dataItem.ques).nestingEnd

    return (
      <div className="card d-flex flex-row mb-2 p-1 align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-sm">
          <DropDownButton
            className={props.dataItem === data[0] ? 'd-none' : ''}
            iconClass="fa fas fa-plus fa-lg"
            items={actions}
            disabled={props.dataItem === data[0]}
            onItemClick={(props) => onOperatorClick(props.item, selectedExpression)}>
          </DropDownButton>
          <DropDownButton
            iconClass="k-icon k-font-icon k-i-filter-add-group"
            items={nestingStart}
            onItemClick={(props) => onNestingStartClick(props.item, selectedExpression)}>
          </DropDownButton>
          {props.dataItem.ques !== data[0].ques ?
            <Button className={`btn btn-sm ml-1 text-uppercase ${props.dataItem.operator === 'AND' ? 'btn-success' : 'btn-danger'}`} disabled={true}>{props.dataItem.operator}</Button>
            : null}
          {props.dataItem.ques.charAt(0) === '!' ?
            <p className="mb-0 ml-1">{props.dataItem.operatorStart}NOT {props.dataItem.text}{props.dataItem.operatorEnd}</p> :
            <p className="mb-0 ml-1">{props.dataItem.operatorStart}{props.dataItem.text}{props.dataItem.operatorEnd}</p>
          }
        </div>
        <div className="d-flex align-items-center gap-sm">
          <DropDownButton
            iconClass="k-icon k-font-icon k-i-filter-add-group"
            buttonClass="ml-3"
            items={nestingEnd}
            onItemClick={(props) => onNestingEndClick(props.item, selectedExpression)}></DropDownButton>
          <Button iconClass='fas fa fa-times' look="bare" onClick={() => onRemoveFilterQuestion(props.dataItem.ques)}></Button>
        </div>
      </div>
    )
  }

  return (
    <div className={filterType === 'basic' ? 'mh-100' : 'filter-combine'}>
      {
        data.length > 0 ?
          <ListView data={data} item={(props) => itemRender(props)} />
          : null
      }
    </div>
  );
};

export default CombineFilterData;