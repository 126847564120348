import type { Dispatch } from "redux";

type State = {
  checkedNum: {
    id: string
    source: 'firstColumn' | 'scndColumn'
  }[]
  firstColumn: { id: string }[]
  scndColumn: { id: string }[]
}

type CopiedElement = {
  id: string
  source: 'firstColumn' | 'scndColumn'
}

type PasteSource = 'xt' | 'topline' | 'raw_data' | 'qtable' | 'chart' | 'dig';

export const pasteAnalysisQuestions = (state: State, copyData: { source: string | null, elements: CopiedElement[] | null }, dispatch: Dispatch, pasteSource: PasteSource) => {
  const reducerType = `${pasteSource.toUpperCase()}_PASTE_QUESTIONS`;

  const copiedElements = copyData.elements;
  const copySource = copyData.source;
  if (copySource === pasteSource) {
    if (copiedElements && copiedElements?.length > 0) {
      const copiedFromFirstColumn = copiedElements.filter(el => el.source === 'firstColumn')
      const copiedFromScndColumn = copiedElements.filter(el => el.source === 'scndColumn')
      if (state.checkedNum.length > 0) { // If there is atleast one checked element, paste after the last checked element based on the source
        const firstColumnCheckedElements = state.checkedNum.filter(el => el.source === 'firstColumn')
        const lastCheckedFromFirstColumn = state.checkedNum.filter(el => el.source === 'firstColumn')[firstColumnCheckedElements.length - 1];
        const lastCheckedFromFirstColumnIndex = state.firstColumn.findIndex(el => el.id === lastCheckedFromFirstColumn?.id);
        const updatedFirstColumn = [...state.firstColumn];
        if (typeof lastCheckedFromFirstColumnIndex === 'number') {
          updatedFirstColumn.splice(lastCheckedFromFirstColumnIndex + 1, 0, ...copiedFromFirstColumn);
        }
        const scndColumnCheckedElements = state.checkedNum.filter(el => el.source === 'scndColumn')
        const lastCheckedFromScndColumn = state.checkedNum.filter(el => el.source === 'scndColumn')[scndColumnCheckedElements.length - 1];
        const lastCheckedFromScndColumnIndex = state.scndColumn?.findIndex(el => el.id === lastCheckedFromScndColumn?.id);
        const updatedScndColumn = state.scndColumn ? [...state.scndColumn] : [];
        if (typeof lastCheckedFromScndColumnIndex === 'number') {
          updatedScndColumn.splice(lastCheckedFromScndColumnIndex + 1, 0, ...copiedFromScndColumn);
        }
        dispatch({ type: reducerType, payload: { firstColumn: updatedFirstColumn, scndColumn: updatedScndColumn } })
        dispatch({ type: 'SET_COPIED_ELEMENTS', payload: { source: null, elements: null } })
        dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The selected rows/columns have been pasted' } })
      } else { // If there is no checked element, paste at the end of the list based on the source
        const updatedFirstColumn = [...state.firstColumn, ...copiedFromFirstColumn];
        const updatedScndColumn = [...state.scndColumn, ...copiedFromScndColumn];
        dispatch({ type: reducerType, payload: { firstColumn: updatedFirstColumn, scndColumn: updatedScndColumn } })
        dispatch({ type: 'SET_COPIED_ELEMENTS', payload: { source: null, elements: null } })
        dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The selected rows/columns have been pasted' } })
      }
    } else {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'No copied rows/columns found' } })
    }
  } else {
    dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'Cannot paste rows/columns from different source' } })
  }
}