import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

interface Props {
  onClose: (e: TODO) => void;
}

export const SaveDatasetToLibraryModal = ({ onClose }: Props) => {
  return (
    <Dialog title={"Save as template"} closeIcon={false}>
      <p className='m-0 px-5 py-3'>Do you want to save these changes as a template?</p>
      <DialogActionsBar>
        <button
          type='button'
          value={"No"}
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
          onClick={(e) => onClose(e)}>No
        </button>
        <button
          type='button'
          value={"Yes"}
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
          onClick={(e) => onClose(e)}>Yes
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}