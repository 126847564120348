import type React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import type { RootState } from '../../../../../../store/reducers/rootReducer';

interface Props {
  scriptId: string | null;
  selectedAnalysisScriptingItem: string | null;
  onHide: () => void;
  onAutoSaveHandler: (currentState: any) => void;
}

export const DeleteAnalysisScriptingItemModal: React.FC<Props> = ({ scriptId, selectedAnalysisScriptingItem, onHide, onAutoSaveHandler }) => {
  const dispatch = useDispatch();
  const analysisScriptingState = useSelector((theState: RootState) => ({ ...theState.analysisScriptingStateReducer }))

  const onSubmitHandler = () => {
    const updatedAnalysisScriptingData = [...analysisScriptingState.data];
    if (selectedAnalysisScriptingItem === scriptId) {
      dispatch({ type: 'UPDATE_ANALYSIS_SCRIPTING_RESULT', payload: [] })
    }
    dispatch({ type: 'UPDATE_ANALYSIS_SCRIPTING_DATA', payload: updatedAnalysisScriptingData.filter((item) => item.id !== scriptId) })
    onAutoSaveHandler({ data: updatedAnalysisScriptingData.filter((item) => item.id !== scriptId) })
    dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The script has been deleted successfully' } })
    onHide()
  }

  return (
    <Dialog width={400} title={"Delete script"} onClose={onHide} className="import-dialog">
      <div className="p-4">
        <p className='m-0 text-center'>Are you sure you want to delete this script?</p>
      </div>

      <DialogActionsBar>
        <button type="button" className="k-button btn-secondary" onClick={onHide}>Cancel</button>
        <button type='button' onClick={onSubmitHandler} className="k-button btn-danger">Delete</button>
      </DialogActionsBar>
    </Dialog>
  )
}