
import { useSelector } from 'react-redux';

import type { RootState } from '../../store/reducers/rootReducer';
import TasksList from './TasksList/TasksList';

export const Tasks = () => {
  document.title = "Tasks – Walr";

  const { token } = useSelector((state: RootState) => state.tokenStateReducer);

  return (
    <section className='main tasks-container'>
      <div className='d-flex flex-column w-100 border taskslist'>
        <div className="d-flex justify-content-center flex-column w-100 px-6 pt-5 pb-1">
          <h4 className="stronger">Tasks</h4>
        </div>
        <div className="d-flex px-6 py-5 w-100">
          <div className="nav-board d-flex justify-content-center flex-column overflow-hidden w-100 bg-white pb-3">
            <TasksList token={token} />
          </div>
        </div>
      </div>
    </section>
  )
}