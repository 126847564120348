import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { InProgressOverlay } from '../../../InProgressOverlay/InProgressOverlay';
import { fetchDelete as removeProjectSharing } from '../../../../../services/services';

export default ({ onHide, reloadProject, projectId }) => {
  const { token } = useSelector((state) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const onRemoveSharingHandler = () => {
    setIsLoading(true)
    removeProjectSharing(`share/${projectId}`, token)
      .then(res => {
        if (res.status === 204) {
          dispatch({ type: 'RESET_PROJECT_SHARING_ACCOUNTS' })
          setIsLoading(false)
          reloadProject()
          onHide()
        }
      })
  }

  return (
    <Dialog title={"Remove sharing"} onClose={onHide}>
      <div className="p-3">
        <p className="m-0">Are you sure you would like remove sharing for this project?</p>
      </div>
      <DialogActionsBar>
        <button type='button' className="k-button k-button-solid-base" onClick={onHide}>No</button>
        <button
          type='button'
          disabled={isLoading}
          className="k-button btn-danger"
          onClick={() => onRemoveSharingHandler()}>
          {
            isLoading ?
              <InProgressOverlay type="button" theme="light" />
              :
              <span>Yes</span>
          }
        </button>
      </DialogActionsBar>
    </Dialog >
  )
}