import { DropdownButton } from "../../../../shared/DropdownButton/DropdownButton";

const dropDownOptions: DropDownOption[] = [
  { text: 'Labels and texts', icon: 'append', id: '1' },
  { text: 'IDs and texts', icon: 'advanced', id: '2' },
  { text: 'Texts', icon: 'match', id: '3' },
  { text: 'Internal IDs', icon: 'match', id: '4' }
]

interface Props {
  autoMatchType: string
  handleSelectMatchType: (id: string, text: string) => void;
  disabled: boolean
  handleDisableMaps: () => void;
}

interface DropDownOption {
  text: string
  icon: string
  id: string
}

export const MatchToolActions = ({ handleSelectMatchType, disabled, handleDisableMaps }: Props) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="separator mx-2" />
      <button
        type="button"
        className="btn"
        disabled={disabled}
        onClick={handleDisableMaps}>
        Enable/Disable</button>
      <DropdownButton
        text={'Auto matching'}
        items={dropDownOptions}
        className="btn-transparent icon-r"
        onItemClick={(e) => handleSelectMatchType(e.item.id, e.item.text)}
      />
    </div>
  )
}