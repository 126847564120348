import LanguagesList from "./LanguagesList.json"

interface Language {
  code: string
  isDefault: boolean
  name?: string
  native?: string
}

export const updateLanguagesList = (originalLanguagesList: Language[]) => {
  const updatedLanguagesList = [...originalLanguagesList]
  
  originalLanguagesList.forEach((lang: Language) => { // Add language info, a compensation for backend
    const langInfo = LanguagesList.find(el => el.code === lang.code);
    if (langInfo) {
      lang.name = langInfo.name;
      lang.native = langInfo.native;
    }
  })

  return updatedLanguagesList
}