
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { BaseMultiSelect } from '../../shared/Inputs';
import { fetchPostJson as addSample } from '../../../services/services'
import { useFetchTags } from '../customHooks/useFetchTags'
import { ErrorMessage } from '../ErrorMessage/ErrorMessage'
import type { SampleFormData } from '../../../interfaces/projectDetailsInterfaces/projectDetailsInterfaces';

interface Props {
  token: string
  projectId: string
  history: TODO
  handleClose: () => void
}

export const AddSampleModal = ({ token, projectId, handleClose, history }: Props) => {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState<SampleFormData>({ name: '', description: '', tags: [] })
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const tags = useFetchTags(token)

  const onChangeHandler = (e: TODO) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const onSubmitHandler = (e: TODO) => {
    e.preventDefault()
    const body = {
      name: formData.name,
      description: formData.description,
      tags: formData.tags
    }

    if (formData.name === '') {
      setErrorMessage("Sample name cannot be empty")
    } else {
      addSample(`projects/${projectId}/samples`, token, body)
        .then((res: TODO) => {
          if (res?.sampleId) {
            handleClose()
            const location = history.location.pathname
            dispatch({ type: 'UPDATE_DOCUMENT_BREADCRUMB', payload: { id: res.sampleId, name: formData.name } })
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The sample has been created successfully' } })
            history.push({ pathname: `${location}/${res.sampleId}/panel` })
          } else if (res && (res.message || res.error)) {
            setErrorMessage(res.message ? res.message : res.error)
          }
        })
    }
  }

  const listNoDataRender = () => {
    return (
      <h5 className="text-muted text-center">
        <span className="k-icon k-i-warning my-3" style={{ fontSize: '2.5em' }} />
        <br />
        <span>No tags created yet</span>
      </h5>
    );
  }

  return (
    <Dialog title="Create sample" onClose={handleClose} width={650} height={500}>
      <Form
        onSubmit={onSubmitHandler}
        render={(_formRenderProps) => (
          <div className="p-3">
            <div className="d-flex flex-column">
              <Input
                name="name"
                className="w-100 mb-2"
                label="Name"
                required={true}
                value={formData.name}
                onChange={onChangeHandler}
              />
              <Input
                className="w-100 mb-2"
                name="description"
                label="Description"
                value={formData.description}
                onChange={onChangeHandler}
              />
              <BaseMultiSelect
                data={tags ? tags : []}
                value={formData.tags}
                label="Tags"
                textField="name"
                dataItemKey="id"
                name="tags"
                allowCustom={true}
                listNoDataRender={listNoDataRender}
                onChange={onChangeHandler} />

            </div>
            {
              errorMessage !== null &&
              <ErrorMessage
                type="alert"
                errorMessage={errorMessage}
                onHide={() => setErrorMessage(null)} />
            }
          </div>
        )}>
      </Form>
      <DialogActionsBar>
        <Button className="btn btn-secondary" onClick={handleClose}>Cancel</Button>
        <input
          type="submit"
          value="Create"
          disabled={false}
          onClick={(e) => onSubmitHandler(e)}
          className="k-button k-button-solid-primary"
        />
      </DialogActionsBar>
    </Dialog>
  )
}