import { RadioButton } from '@progress/kendo-react-inputs';

import type { Option } from '../../../../../../interfaces/surveyInterfaces/surveyIterfaces';
import { DropdownButton, type DropdownItem } from '../../../../../shared/DropdownButton/DropdownButton';

type LoopType = "none" | "list" | "number" | undefined;

interface Props {
  option: Option
  loopType: LoopType
  listItemsDropdown: DropdownItem[] | undefined
  listItemValue: { list: string, number: string } | undefined
  updateLoopType: (loopType: LoopType) => void
  onValueChange: (e: unknown, type: string, option: { id: string }) => void
}

export const SectionLoopOption = ({ loopType, updateLoopType, listItemsDropdown, listItemValue, onValueChange, option }: Props) => {
  return (
    <div className="d-flex flex-column survey-option gap-md">
      <div className="d-flex align-items-center gap-lg">
        <RadioButton
          id='loopNoneOption'
          name="group1"
          value="first"
          checked={loopType === "none"}
          onChange={() => updateLoopType("none")}
        />
        <label className={`m-0 ${loopType !== "none" ? "text-muted" : ""}`} htmlFor="loopNoneOption">None</label>
      </div>
      <div className="d-flex align-items-center gap-lg">
        <RadioButton
          name="group1"
          value="first"
          checked={loopType === "list"}
          onChange={() => updateLoopType("list")}
        />
        <DropdownButton
          parentClass='w-50'
          className='btn-block'
          items={listItemsDropdown}
          text={listItemValue?.list || "Choose a list"}
          disabled={loopType !== "list"}
          onItemClick={(e) => onValueChange(e, 'loop', option)}
        />
      </div>
      <div className="d-flex align-items-center gap-lg">
        <RadioButton
          name="group1"
          value="first"
          checked={loopType === "number"}
          onChange={() => updateLoopType("number")}
        />
        <input
          min={"1"}
          type="number"
          placeholder='Number'
          className='form-control w-50'
          disabled={loopType !== "number"}
          value={listItemValue?.number || ""}
          onChange={(e) => onValueChange({ item: { text: e.target.value } }, 'loop', option)}
        />
      </div>
    </div>
  )
}