export default (state, action) => {
  const v = action.payload.event.target.value.toLowerCase()

  let matchedQuestions = [];

  action.payload.oldData.forEach(ques => {
    if (ques.title.toLowerCase().includes(v) || ques.content.toLowerCase().includes(v) ||
      (ques.groups && ques.groups.forEach(el => { if (el.text.toLowerCase().includes(v)) { return true } }))) {
      matchedQuestions.push(ques);
    }
    else {
      matchedQuestions = [...matchedQuestions]
    }
  });
  if (action.payload.event.target.value !== '') {
    if (matchedQuestions.length > 0) {
      return { ...state, theData: matchedQuestions };
    } else {
      return { ...state, theData: null };
    }
  } else {
    return { ...state, theData: action.payload.oldData };
  }
}