import { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Editor from "@monaco-editor/react";
import { Button } from "@progress/kendo-react-buttons";

import { ErrorMessage } from "../../../../../../../shared/ErrorMessage/ErrorMessage";
import { fetchPostResOrJson as createScriptedWeight } from "../../../../../../../../services/services"

interface Props {
  token: string,
  onClose: () => void,
}

export const AddScriptedWeights = ({ token, onClose, }: Props) => {
  const params: { name: string, dataset: string } = useParams();
  const projectId: string = params.name;
  const datasetId: string = params.dataset;
  const dispatch = useDispatch();
  const [editorValue, setEditorValue] = useState<string>("");
  const [editorTheme, setEditorTheme] = useState<string>("vs-light");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleEditorChange = (value: any) => {
    setEditorValue(value);
  }

  const openHelp = () => {
    window.open("https://help.researchstudio.net/article/777-how-to-create-a-scripted-weight")
  }

  const changeTheme = (event: any) => {
    event.preventDefault();
    if (editorTheme === "vs-light") {
      setEditorTheme("vs-dark");
    } else {
      setEditorTheme("vs-light");
    }
  }

  const submitHandler = (event: any) => {
    event.preventDefault();

    let body = {
      "definition": editorValue,
    };

    // @ts-ignore
    createScriptedWeight(`an/projects/${projectId}/analysis/${datasetId}/xml-weights`, token, body)
      .then((res: any) => {
        if (res.status === 201) {
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Scripted weights have been uploaded successfully' } })
          onClose();
        } else {
          setErrorMessage(res.error ? res.error : res.message);
        }
      })
  }

  return (
    <Fragment>
      {
        errorMessage &&
        <ErrorMessage
          type="modal"
          errorMessage={errorMessage}
          onHide={() => setErrorMessage("")}
        />
      }
      <div className="d-flex flex-column h-100" >
        <div className="card weight-card flex-grow-1">
          <Editor
            theme={editorTheme}
            defaultLanguage="xml"
            value={editorValue}
            onChange={handleEditorChange}
          />
        </div>
        <div className="d-flex flex-grow-1 justify-content-between align-items-center m-2" >
          <Button
            id="help-button"
            title="Open scripted weights documentation"
            className="btn btn-outline-dark d-flex weight-next-button"
            icon="fas fa fa-question-circle m-0 mr-2"
            onClick={openHelp}
          >Help</Button>
          <div className="d-flex flex-grow-1 justify-content-end align-items-center">
            <Button
              id="theme-button"
              className="btn btn-outline-dark d-flex weight-next-button"
              icon="fas fa fa-moon m-0 mr-2"
              onClick={changeTheme}
            >Theme</Button>
            <Button
              id="create-button"
              className="btn btn-outline-dark d-flex flex-row-reverse weight-next-button mx-2"
              icon="fas fa fa-arrow-circle-right"
              disabled={!editorValue}
              onClick={e => submitHandler(e)}
            >Create</Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}