import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import QuillEditor from "../../shared/QuillEditor/QuillEditor";
import { ColorPicker } from "@progress/kendo-react-inputs";
import { Tooltip } from "@progress/kendo-react-tooltip";

import { Icon } from "../../shared/Icon/Icon";
import { GridItemDefaultOptions, QuillToolbar } from "../pinboardHelpers";
import { SimpleSwitch } from "../../shared/SimpleSwitch/SimpleSwitch";
import type { DataGridItem, GridItemOptions, Pinboard } from "../../../interfaces/pinboardInterfaces/pinboardInterfaces";
import FileManager from "../../shared/FileManager/FileManager";
import { fetchPatchJson } from "../../../services/services";

interface Props {
  token: string
  pinboardData: Pinboard,
  dataGrid: DataGridItem[],
  setDataGrid: (arr: DataGridItem[]) => void,
  selectedItem: DataGridItem,
  onHide: (gridItem: DataGridItem) => void
}

const PinboardsItemOptions = ({ token, pinboardData, dataGrid, setDataGrid, selectedItem, onHide }: Props) => {
  const dispatch = useDispatch();

  const [showFileManager, setShowFileManager] = useState<boolean>(false);
  const [showContent, setShowContent] = useState<boolean>(selectedItem.type !== "analysis" ? true : false);

  const [options, setOptions] = useState<GridItemOptions>(selectedItem.options);
  const [htmlInput, setHtmlInput] = useState<string>(selectedItem.data.html ? selectedItem.data.html : "");
  const [imageUrl, setImageUrl] = useState<string>(selectedItem.data.url ? selectedItem.data.url : "");

  useEffect(() => {
    if (selectedItem.i !== "") {
      setOptions(selectedItem.options);
      setHtmlInput(selectedItem.data.html ? selectedItem.data.html : "");
      setImageUrl(selectedItem.data.url ? selectedItem.data.url : "");
    }
  }, [selectedItem])

  const valueChangeHander = (value: string | number | boolean, optionType: string) => {
    switch (optionType) {
      case "imageUrl":
        setImageUrl(String(value));
        break;
      case "html":
        setHtmlInput(String(value));
        break;
      case "chartHeight":
        setOptions({ ...options, chartStyle: { ...options.chartStyle, chartHeight: value } })
        break;
      case "chartBarSize":
        setOptions({ ...options, chartStyle: { ...options.chartStyle, chartBarSize: Number.parseFloat(value.toString()) } })
        break;
      default:
        setOptions({ ...options, [optionType]: value })
        break;
    }
  }

  const fileMgrConfirmHandler = (value: string) => {
    const splitUrl = value.split(".")
    const fileType = splitUrl[splitUrl.length - 1]
    const acceptedFileTypes = ["jpg", "jpeg", "png", "bmp", "svg", "gif", "avif", "webp"]

    if (acceptedFileTypes.includes(fileType)) {
      valueChangeHander(value, "imageUrl")
    } else {
      dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: "That file type is not supported.\nSupported file types: jpg, jpeg, png, bmp, svg, gif, avif, webp" } })
    }
  }

  const closeHandler = () => {
    onHide(selectedItem);
    setOptions({ ...selectedItem.options });
  }

  const saveOptions = (newOptions: GridItemOptions, item: DataGridItem) => {
    const body: TODO = {
      pageEtag: pinboardData.activePage._etag,
      // position: {},
      title: newOptions.title,
      style: {
        backgroundColor: newOptions.backgroundColor,
        borderColor: newOptions.borderColor,
        textColor: newOptions.textColor,
        borderRadius: newOptions.borderRadius,
        borderWidth: newOptions.borderWidth,
        boxShadow: newOptions.boxShadow,
        showTitle: newOptions.showTitle,
      }
    }

    if (item.type === "image") {
      Object.assign(body.style, { padding: newOptions.padding })
      Object.assign(body, { url: newOptions.imageUrl })
    } else if (item.type === "text") {
      Object.assign(body.style, { padding: newOptions.padding })
      Object.assign(body, { html: newOptions.html })
    }
    else if (item.type === "analysis") {
      if (item.data.analysisType === "chart" && newOptions.chartStyle && item.data.chartJson && item.data.chartJson[0].type === "hbar") {
        Object.assign(body, { chartStyle: {} })
        if (newOptions.chartStyle.chartHeight) {
          Object.assign(body.chartStyle, { chartHeight: Number.parseInt(newOptions.chartStyle.chartHeight) })
        }
        if (newOptions.chartStyle.chartBarSize) {
          Object.assign(body.chartStyle, { chartBarSize: Number.parseFloat(newOptions.chartStyle.chartBarSize) })
        }
      }

      Object.assign(body.style, { padding: newOptions.padding })
    }

    fetchPatchJson(`pb/projects/${pinboardData.projectId}/pinboards/${pinboardData.pinboardId}/pages/${pinboardData.activePage.id}/${item.type}/${item.i}`, token, body)
      .then((res: TODO) => res.json())
      .then((result: TODO) => {
        if (result.error || result.message) {
          dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: result.error ? result.error : result.message } });
        } else {
          const updatedGridData = [...dataGrid]
          const itemIndex = updatedGridData.findIndex(el => el.i === item.i)
          updatedGridData[itemIndex].options = newOptions;
          if (item.type === "image") {
            updatedGridData[itemIndex].data.url = newOptions.imageUrl
            delete updatedGridData[itemIndex].options.imageUrl
          } else if (item.type === "text") {
            updatedGridData[itemIndex].data.html = newOptions.html
          }

          setDataGrid(updatedGridData)
          dispatch({ type: "SET_ACTIVE_PINBOARD_PAGE", payload: { page: result, reload: false } })
        }
      })
  }

  const saveHandler = () => {
    switch (selectedItem.type) {
      case "image":
        saveOptions({ ...options, imageUrl: imageUrl, }, selectedItem);
        break;
      case "text":
        saveOptions({ ...options, html: htmlInput, }, selectedItem);
        break;
      default:
        saveOptions(options, selectedItem);
        break;
    }

    closeHandler();
  }

  return (
    <div className="d-flex flex-column h-100" style={{ width: `${showFileManager ? "" : "20rem"}` }}>
      {showFileManager ?
        <div className="fileMgr-container">
          <FileManager
            token={token}
            type={"public"}
            displayType={"fullscreen"}
            onHide={() => setShowFileManager(false)}
            onSelect={value => fileMgrConfirmHandler(value)}
          />
        </div>
        :
        <div className="d-flex flex-column overflow-hidden flex-fill justify-content-between">
          <div className="header flex-shrink-0">
            <span className="ml-1">{selectedItem.type}</span>
            <div className="d-flex">
              <Tooltip openDelay={100} position="bottom" anchorElement={"target"}>
                <button type="button" className="btn btn-transparent p-1" onClick={closeHandler} title="Close"><Icon type="close" className="pe-none" /></button>
              </Tooltip>
            </div>
          </div>
          <ul className="nav option-tabs flex-grow-1 justify-content-center border-bottom">
            <li className={`nav-item ${showContent ? "active" : ""}`}>
              <Tooltip openDelay={300} position="bottom" anchorElement={"target"}>
                <button type="button" title="Content" className="p-0" onClick={() => setShowContent(true)}><Icon type="opt-options" className="pe-none" /></button>
              </Tooltip>
            </li>
            <li className={`nav-item ${showContent ? "" : "active"}`}>
              <Tooltip openDelay={300} position="bottom" anchorElement={"target"}>
                <button type="button" title="Styles" className="p-0" onClick={() => setShowContent(false)}><Icon type="opt-styles" className="pe-none" /></button>
              </Tooltip>
            </li>
          </ul>
          <div className="options-body overflow-auto h-100">
            {showContent ?
              <Fragment>
                <section>
                  <div className="d-flex flex-column">
                    <label>
                      <span className="text-capitalize">{selectedItem.type}</span> title
                    </label>
                    <input type="text" className="sidebar-input form-control" value={options.title} onChange={(e) => valueChangeHander(e.target.value, "title")} placeholder="Enter title" />
                  </div>
                </section>
                <hr className="w-100 m-0" />
                {selectedItem.type === "text" &&
                  <Fragment>
                    <section>
                      <div>
                        <div className="mb-2">Text</div>
                        <div className="cursor-pointer">
                          <QuillEditor
                            value={htmlInput}
                            setValue={(value: string) => valueChangeHander(value, "html")}
                            toolbar={QuillToolbar} />
                        </div>
                      </div>
                    </section>
                  </Fragment>
                }
                {selectedItem.type === "image" &&
                  <Fragment>
                    <section>
                      {/* <div className="d-flex flex-column justify-content-between">
                        <label htmlFor="url">Image</label>
                        <div className="select-image p-4 mb-2 rounded d-flex justify-content-center align-items-center">
                          <button className="btn" onClick={() => setShowFileManager(true)}>Select image</button>
                        </div>
                        <div className="select-image selected p-4 mb-2 rounded d-flex justify-content-center align-items-center">
                          <button className="btn"><span className="text-truncate">imageUrl</span></button>
                          <button className="btn p-1" onClick={(e) => valueChangeHander("", "imageUrl")}><Icon type="delete" /></button>
                        </div>
                      </div> */}
                      <div className="d-flex flex-column">
                        <label htmlFor="url">Image</label>
                        <div className="d-flex">
                          <input
                            type="text"
                            name="url"
                            placeholder="http://"
                            value={imageUrl}
                            className="sidebar-input form-control file-library border-right-0"
                            onChange={(e) => valueChangeHander(e.target.value, "imageUrl")}
                          />
                          <button type="button" className="btn border right-corners-round p-1" onClick={() => valueChangeHander("", "imageUrl")}><Icon type="delete" /></button>
                        </div>
                        <button type="button" className="btn btn-shadow justify-content-center mt-2" onClick={() => setShowFileManager(true)}>Open file library</button>
                        <span className="text-muted small mt-3">Select from the file manager or provide a direct URL or link to the image you want to use.</span>
                      </div>
                    </section>

                    {/* <section>
                      <div className="d-flex flex-column justify-content-between mb-1">
                        <label htmlFor="alt">Alt text</label>
                        <div className="d-flex m-0">
                          <textarea
                            rows={3}
                            className="sidebar-input form-control mb-0 text-area no-resize"
                            name="alt"
                          />
                        </div>
                      </div>
                      <span className="text-muted small">Describe the purpose of the image.</span>
                    </section> */}
                  </Fragment>
                }
              </Fragment>
              :
              <Fragment>
                {selectedItem.type === "analysis" && selectedItem.data.chartJson && selectedItem.data.chartJson[0].type === "hbar" &&
                  <Fragment>
                    <section>
                      <div className="strong">Chart style</div>
                      <div className="d-flex align-items-center flex-column w-100">
                        <div className="d-flex justify-content-between align-items-center w-100 mb-1">Chart height</div>
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <input type="range" min={100} max={1000} className="form-range mr-3" value={options.chartStyle?.chartHeight} onChange={(e) => valueChangeHander(e.target.value, "chartHeight")} />
                          <div className="sidebar-group">
                            <input type="text" min={1} className="sidebar-numeric form-control text range" value={options.chartStyle?.chartHeight} onChange={(e) => valueChangeHander(e.target.value, "chartHeight")} />
                            <span className="input-text bg-white cursor-default">px</span>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-1">Chart bar spacing</div>
                        <div className="d-flex justify-content-between align-items-center w-100" title="Empty space betwen bars. Lower value makes the bars bigger">
                          <input type="range" min={0.01} max={10} step="0.05" className="form-range mr-3" value={options.chartStyle?.chartBarSize} onChange={(e) => valueChangeHander(e.target.value, "chartBarSize")} />
                          <div className="sidebar-group">
                            <input type="text" min={0.01} max={10} className="sidebar-numeric form-control pr-1" value={options.chartStyle?.chartBarSize} onChange={(e) => valueChangeHander(e.target.value, "chartBarSize")} />
                          </div>
                        </div>
                      </div>
                    </section>
                    <hr className="w-100 m-0" />
                  </Fragment>
                }
                <section>
                  <div className="strong">Colors</div>
                  <div className="d-flex justify-content-between align-items-center">
                    <span>Text</span>
                    <div className="color-picker">
                      <ColorPicker view="gradient" fillMode="flat" gradientSettings={{ opacity: false, }} value={options.textColor} onChange={(e) => valueChangeHander(e.value, "textColor")} />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <span>Background</span>
                    <div className="color-picker">
                      <ColorPicker view="gradient" fillMode="flat" gradientSettings={{ opacity: false, }} value={options.backgroundColor} onChange={(e) => valueChangeHander(e.value, "backgroundColor")} />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-between align-items-center w-100 mb-1">Border</div>
                    <div className="color-picker">
                      <ColorPicker view="gradient" fillMode="flat" gradientSettings={{ opacity: false, }} value={options.borderColor} onChange={(e) => valueChangeHander(e.value, "borderColor")} />
                    </div>
                  </div>
                </section>
                <hr className="w-100 m-0" />
                <section>
                  <div className="strong">Border</div>
                  <div className="d-flex align-items-center flex-column w-100">
                    <div className="d-flex justify-content-between align-items-center w-100 mb-1">Width</div>
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <input type="range" className="form-range mr-3" value={options.borderWidth} onChange={(e) => valueChangeHander(e.target.value, "borderWidth")} />
                      <div className="sidebar-group">
                        <input type="text" min={0} className="sidebar-numeric form-control text range" value={options.borderWidth} onChange={(e) => valueChangeHander(e.target.value, "borderWidth")} />
                        <span className="input-text bg-white cursor-default">px</span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center flex-column w-100">
                    <div className="d-flex justify-content-between align-items-center w-100 mb-1">Radius</div>
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <input type="range" className="form-range mr-3" value={options.borderRadius} onChange={(e) => valueChangeHander(e.target.value, "borderRadius")} />
                      <div className="sidebar-group">
                        <input type="text" min={0} className="sidebar-numeric form-control text range" value={options.borderRadius} onChange={(e) => valueChangeHander(e.target.value, "borderRadius")} />
                        <span className="input-text bg-white cursor-default">px</span>
                      </div>
                    </div>
                  </div>
                </section>
                <hr className="w-100 m-0" />
                <section>
                  <div className="strong">Dimensions</div>
                  <div className="d-flex align-items-center flex-column w-100">
                    <div className="d-flex justify-content-between align-items-center w-100 mb-1">Padding</div>
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <input type="range" className="form-range mr-3" value={options.padding} onChange={(e) => valueChangeHander(e.target.value, "padding")} />
                      <div className="sidebar-group">
                        <input type="text" min={0} className="sidebar-numeric form-control text range" value={options.padding} onChange={(e) => valueChangeHander(e.target.value, "padding")} />
                        <span className="input-text bg-white cursor-default">px</span>
                      </div>
                    </div>
                  </div>
                </section>
                <hr className="w-100 m-0" />
                <section>
                  <div className="strong">Layout</div>
                  <div className="d-flex justify-content-between">
                    <span>Box-shadow</span>
                    <SimpleSwitch checked={options.boxShadow} onChange={() => valueChangeHander(!options.boxShadow, "boxShadow")} />
                  </div>
                </section>
              </Fragment>
            }
          </div>
          <div className="d-flex flex-shrink-0">
            <button type="button" className="btn btn-secondary w-50 justify-content-center rounded-0 p-2" onClick={() => setOptions({ ...GridItemDefaultOptions })}>Restore default</button>
            <button type="button" className="btn btn-primary w-50 justify-content-center rounded-0 p-2" onClick={saveHandler}>Save</button>
          </div>
        </div>
      }
    </div >
  )
}

export default PinboardsItemOptions;