import React from 'react';

export default ({ onToggleControl, onToggleControlAction, toggleElement, buttonText }) => {
  return (
    <button
      type='button'
      onClick={() => onToggleControl(onToggleControlAction)}
      className={`btn ${toggleElement ? 'btn-light' : 'btn-transparent'}`}>
      {buttonText}
    </button>
  );
}
