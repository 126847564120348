import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

interface Props {
  handleClose: () => void;
  handleSubmit: (leagasyImport: boolean) => void;
}

export const ImportOptionsModal = ({ handleClose, handleSubmit }: Props) => {
  const onSubmitHandler = (leagasyImport: boolean) => {
    handleSubmit(leagasyImport);
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} title={'Choose an option to import'} className="d-flex flex-column" width={'600'}>
      <div className="row m-4">
        <div onClick={() => onSubmitHandler(false)} className="col">
          <div className="card border h-100">
            <div className="card-body d-flex flex-column btn-transparent rounded cursor-pointer media align-items-center text-center">
              <i className={"align-self-center mb-3 py-2 px-3 fa-4x fa fas fa-file"} />
              <div className="media-body d-flex flex-column gap-sm">
                <h5 className="text-center m-0">File import</h5>
                <p className='m-0 text-muted'>Import dataset from file</p>
              </div>
            </div>
          </div>
        </div>
        <div onClick={() => onSubmitHandler(true)} className="col">
          <div className="card border h-100">
            <div className="card-body d-flex flex-column btn-transparent rounded cursor-pointer media align-items-center text-center">
              <i className={"align-self-center mb-3 py-2 px-3 fa-4x fa fas fa-server"} />
              <div className="media-body d-flex flex-column gap-sm">
                <h5 className="text-center m-0">Import from library</h5>
                <p className='m-0 text-muted'>Import dataset from legacy platform</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialogActionsBar>
        <button type='button' className="k-button btn btn-secondary" onClick={handleClose}>
          Cancel
        </button>
      </DialogActionsBar>
    </Dialog>
  );
};
