import type React from 'react';

import type { IPropsTable } from '../../../../../../../interfaces/importedRecipientListinterfaces/importedRecipientListinterfaces';

export const ImportedRecipientListTable: React.FC<IPropsTable> = ({ handleColumnClick, orderBy, recipientList, handleRecipientListClick, theKeys, handleSearchChange, selectRespondents, selectedRespondents }) => {
  const tableHeaders = theKeys.map((el: TODO) => el)

  return (
    <table className="table table-hover quality-table" style={{ borderTop: "2px solid white" }}>
      <thead>
        <tr style={{ background: "rgba(23, 33, 71, 0.025)" }}>
          <th />
          {tableHeaders.map((el: TODO, key: TODO) => {
            const capitalLetter = el.charAt(0).toUpperCase() + el.slice(1);
            return (
              <th key={key} className="cursor-pointer" onClick={el !== "checkbox" ? () => handleColumnClick(`v.${el}`) : undefined}>
                <div className="d-flex flex-row align-items-center">
                  <div className="mr-1">{capitalLetter}</div>
                  <i className={orderBy.direction !== "" && orderBy.column === `v.${el}` ? `fas fa-arrow-${orderBy.direction === "ascending" ? "up ml-2" : "down ml-2"}` : "dispay-none-the-item"} />
                </div>
              </th>
            )
          })}
          <th className="cursor-pointer" onClick={() => handleColumnClick("status")}>
            <div className="d-flex flex-row align-items-center">
              <div className="mr-1">Status</div>
              <i className={orderBy.direction !== "" && orderBy.column === 'status' ? `fas fa-arrow-${orderBy.direction === "ascending" ? "up" : "down"}` : "dispay-none-the-item"} />
            </div>
          </th>
          <th className="cursor-pointer" onClick={() => handleColumnClick("shortId")}>
            <div className="d-flex flex-row align-items-center">
              <div className="mr-1">ID</div>
              <i className={orderBy.direction !== "" && orderBy.column === 'shortId' ? `fas fa-arrow-${orderBy.direction === "ascending" ? "up" : "down"}` : "dispay-none-the-item"} />
            </div>
          </th>
        </tr>
        <tr>
          <th />
          {tableHeaders.map((el: TODO, key: number) => {
            return (
              <th key={key}>
                <input type="text" className="form-control k-textbox" onChange={(e) => handleSearchChange(e, el)} />
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {recipientList?.recipients?.map((el: TODO, key: number) => {
          return (
            <tr className="cursor-pointer" onClick={() => handleRecipientListClick(el.id)} key={key}>
              <td>
                <input type='checkbox' checked={selectedRespondents?.some((res: TODO) => res.id === el.id) ? true : false} onChange={(e) => selectRespondents(e, el)} onClick={(e) => e.stopPropagation()} />
              </td>
              {theKeys.map((element: TODO, i: number) => (
                <td key={i} className="text-left">
                  {el.v[element]}
                </td>
              ))}
              <td className="text-left">{el.status ? el.status : "Not started"}</td>
              <td className="text-left">{el.shortId}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  );
};