import { useState, useEffect, useCallback } from "react";
import { filterBy } from "@progress/kendo-data-query";

export const useFilter = (dataObj: any[]) => {
  const [data, setData] = useState(dataObj);

  const [filter, setFilter] = useState(null);

  useEffect(() => {
    setData(dataObj);
  }, [dataObj]);

  const updateData = (data: any[]) => {
    setData(data);
  };

  const filterData = (filter: any, data: any[]) => {
    if (!data) return [];
    return filterBy(data, filter);
  }


  const filterChange = (event: any) => {
    setData(event.filter.value ? filterData(event.filter, dataObj) : dataObj);
    setFilter(event.filter.value);
  };

  const onSetFilter = useCallback((filter: TODO) => {
    setFilter(filter);
  }, []);

  return { data, filter, updateData, filterChange, onSetFilter };
};
