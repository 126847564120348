import { Fragment } from 'react';

import type { IPropsTransactionsLog } from '../../../../../../interfaces/importedRecipientListinterfaces/importedRecipientListinterfaces';

export const TransactionsLog = ({ selectedTransaction }: IPropsTransactionsLog) => {
  const updatedTransactionLogs = selectedTransaction.t.map((el: TODO) => {
    return {
      ...el,
      createdDate: new Date(el.createdUtc).toLocaleDateString('default', { month: 'long', day: 'numeric', year: 'numeric' }),
      createdTime: new Date(el.createdUtc).toLocaleTimeString()
    }
  })

  const transactionsLogs = updatedTransactionLogs.reduce((r: TODO, a: TODO) => {
    r[a.createdDate] = r[a.createdDate] || [];
    r[a.createdDate].push(a);
    return r;
  }, Object.create(null));

  const returnTransactionLogDescription = (transactionLog: TODO) => {
    switch (transactionLog.tt) {
      case 1:
        return 'Email has been queued';
      case 2:
        return 'Email has been sent';
      case 3:
        return 'Email has been bounced';
      case 4:
        return 'Email has been delivered';
      case 5:
        return `Respondent information has changed${transactionLog.information ? `: ${transactionLog.information}` : ''}`;
      case 6:
        return `Respondent new status: ${transactionLog.information}`;
      case 7:
        return 'Sms has been queued';
      case 8:
        return 'Sms has been sent';
      case 9:
        return 'Sms has been delivered';
      case 10:
        return `Sms delivery has failed ${transactionLog.information ? `: ${transactionLog.information}` : ''}`;
      default:
        return '';
    }
  }

  return (
    <Fragment>
      {
        Object.entries(transactionsLogs).length > 0 ?
          Object.entries(transactionsLogs).map(([transactionDate, transactionLogs], index) => (
            <div key={index} className='d-flex flex-column'>
              <h6><strong>{transactionDate}</strong></h6>
              <ul className="list-group list-group-flush mb-3">
                {Array.isArray(transactionLogs) && transactionLogs.length > 0 && (
                  transactionLogs.map((transactionLog, transactionKey) => (
                    <li key={transactionKey} className="list-group-item d-flex align-items-center px-0">
                      <div style={{ minWidth: '70px' }}>
                        <small className="text-muted">{new Date(transactionLog.createdUtc).toLocaleTimeString()}</small>
                      </div>
                      <p className='mb-0'>{returnTransactionLogDescription(transactionLog)}</p>
                    </li>
                  ))
                )}
              </ul>
            </div>
          ))
          :
          <div className="d-flex justify-content-center flex-grow-1 mt-3">
            <span className='text-muted'>No transactions found</span>
          </div>
      }
    </Fragment>
  )
}