const initialState = {
  theData: {
    tabSelected: 0,
    customQuestions: [],
    shouldUpdateData: true,
    showDeleteCustomQuestionModal: false,
    editorValues: {
      js: '',
      css: '',
      questionSettings: '',
      tags: {},
      defaultElement: '',
      references: {
        js: [],
        css: []
      }
    },
    showOverview: {
      show: false,
      customQuestion: null
    },
    showCloneCustomQuestionModal: {
      show: false,
      customQuestion: null
    },
    showCreateCustomQuestionModal: {
      show: false,
      customQuestion: null
    },
    showPublishCustomQuestionModal: {
      show: false,
      customQuestion: null
    },
    sort: { field: 'createdUtc', dir: 'desc' },
    statusFilter: "All",
    dcVersionFilter: "All",
    tagsFilter: [],
    customersFilter: [],
    searchValue: "",
    tags: [],
    customQuestionCustomers: []
  }
};

function surveyCustomQuestionsStateReducer(state = initialState.theData, action) {
  switch (action.type) {
    case 'UPDATE_CUSTOM_QUESTION_CUSTOMERS':
      return {
        ...state,
        customQuestionCustomers: action.payload
      }
    case 'UPDATE_CUSTOM_QUESTION_TAGS':
      return {
        ...state,
        tags: action.payload
      }
    case 'UPDATE_SORT':
      return {
        ...state,
        sort: action.payload
      }
    case 'UPDATE_STATUS_FILTER':
      return {
        ...state,
        statusFilter: action.payload
      }
    case 'UPDATE_DC_VERSION_FILTER':
      return {
        ...state,
        dcVersionFilter: action.payload
      }
    case 'UPDATE_TAGS_FILTER':
      return {
        ...state,
        tagsFilter: action.payload
      }
    case 'UPDATE_CUSTOMERS_FILTER':
      return {
        ...state,
        customersFilter: action.payload
      }
    case 'UPDATE_SEARCH_VALUE':
      return {
        ...state,
        searchValue: action.payload
      }
    case 'UPDATE_EDITOR_VALUES':
      return {
        ...state,
        editorValues: action.payload
      }
    case 'UPDATE_SHOW_OVERVIEW':
      return {
        ...state,
        showOverview: action.payload
      }
    case 'UPDATE_SELECTED_TAB':
      return {
        ...state,
        tabSelected: action.payload
      }
    case 'UPDATE_CUSTOM_QUESTIONS_DATA':
      return {
        ...state,
        shouldUpdateData: action.payload
      }
    case 'UPDATE_SHOW_PUBLISH_CUSTOM_QUESTION_MODAL':
      return {
        ...state,
        showPublishCustomQuestionModal: action.payload
      }
    case 'UPDATE_SHOW_CLONE_CUSTOM_QUESTION_MODAL':
      return {
        ...state,
        showCloneCustomQuestionModal: action.payload
      }
    case 'UPDATE_SHOW_CREATE_CUSTOM_QUESTION_MODAL':
      return {
        ...state,
        showCreateCustomQuestionModal: action.payload
      }
    case 'UPDATE_CUSTOM_QUESTIONS':
      return {
        ...state,
        customQuestions: action.payload
      }
    case 'UPDATE_SHOW_DELETE_CUSTOM_QUESTION_MODAL':
      return {
        ...state,
        showDeleteCustomQuestionModal: action.payload
      }
    default:
      return state;
  }
}
export default surveyCustomQuestionsStateReducer;