export default (controlVal, toggleSearch, toggleReplace, multiSelect) => {
  const controlValues = {
    toggleSearch: false,
    toggleReplace: false,
    toggleMultiSelect: false
  }
  if (controlVal === 'search'){
    if (!toggleSearch) {
      controlValues.toggleSearch = true
      controlValues.toggleReplace = false
      controlValues.toggleMultiSelect = false
    }
  } else if (controlVal === 'replace'){
    if (!toggleReplace){
      controlValues.toggleSearch = false
      controlValues.toggleReplace = true
      controlValues.toggleMultiSelect = false
    }
  } else if (controlVal === 'multiSelect'){
    if (!multiSelect) {
      controlValues.toggleSearch = false
      controlValues.toggleReplace = false
      controlValues.toggleMultiSelect = true
    }
  }

  return controlValues
}