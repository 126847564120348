export const returnUpdatedHeaders = (token: string, subscriptionKey: string, contentType?: string) => {
  const userData = {
    subAccountCustomerId: localStorage.getItem('selectedCustomerId'),
    ImpersonateCID: localStorage.getItem('ImpersonateCID'),
    ImpersonateUID: localStorage.getItem('ImpersonateUID')
  }

  const updatedHeaders = new Headers({
    'Authorization': `Bearer ${token}`,
    'Ocp-Apim-Subscription-Key': subscriptionKey
  })

  if (!userData.subAccountCustomerId && userData.ImpersonateCID && userData.ImpersonateUID) {
    updatedHeaders.append('ImpersonateCID', userData.ImpersonateCID)
    updatedHeaders.append('ImpersonateUID', userData.ImpersonateUID)
  }
  if (!userData.ImpersonateCID && !userData.ImpersonateUID && userData.subAccountCustomerId) {
    updatedHeaders.append('rs-cid', userData.subAccountCustomerId)
  }
  if (contentType) {
    updatedHeaders.append('Content-Type', contentType)
  }

  return updatedHeaders;
}