import React from 'react';
import { usePagination, DOTS } from '../customHooks/usePagination';

const Pagination = props => {
	const {
		onPageChange,
		totalCount,
		siblingCount = 1,
		currentPage,
		pageSize,
	} = props;

	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize
	});

	if (currentPage === 0 || paginationRange.length < 2) {
		return null;
	}

	const onNext = () => {
		if (currentPage < paginationRange.length) {
			onPageChange(currentPage + 1);
		}
	};

	const onPrevious = () => {
		if (currentPage > 1) {
			onPageChange(currentPage - 1);
		}
	};

	return (
		<div className="btn-group pagination-buttons-wrapper">
			<button type='button' onClick={onPrevious} className="btn btn-outline-secondary text-muted">
				<i className="fas fa fa-chevron-left" />
			</button>
			{
				paginationRange.map((pageNumber, key) => {
					if (pageNumber === DOTS) {
						return (
							<button type='button' key={key} disabled className="pagination-item dots btn btn-outline-secondary text-muted">
								<i className="fas fa-ellipsis-h" />
							</button>
						)
					}
					return (
						<button type='button' onClick={() => onPageChange(pageNumber)} key={key} className={` ${pageNumber === currentPage ? "btn btn-secondary" : "btn btn-outline-secondary text-muted"}`}>
							{pageNumber}
						</button>
					)
				})
			}
			<button type='button' className="btn btn-outline-secondary text-muted" onClick={onNext}>
				<i className="fas fa fa-chevron-right" />
			</button>
		</div>
	);
};

export default Pagination;