import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import JsxParser from "react-jsx-parser";
import { useParams } from "react-router-dom";

import type { RootState } from "../../../../../../../../store/reducers/rootReducer";
import ChartTypeContent from "../ChartTabContent/ChartTypeContent/ChartTypeContent";
import DroppableColumn from "../../../DroppableColumn/DroppableColumn";
import { updateReport, updateTableData } from "../../../../../../../../store/reducers/analysisReportsStateReducer/analysisReportsStateReducer";
import { InProgressOverlay } from "../../../../../../../shared/InProgressOverlay/InProgressOverlay";
import { ReportsTabHeader } from "./ReportsTabHeader";
import { returnUpdatedAnalysisBody } from "../../../../shared/helpers/returnUpdatedAnalysisBody/returnUpdatedAnalysisBody";
import { fetchPostJson } from "../../../../../../../../services/services";
import type { User } from "../../../../../../../../interfaces/userInterface/userInterface";

type Props = {
  user: User;
  analysisFunctionalities: {
    analysisActions: {
      [key: string]: boolean;
    }
    analysisTypes: {
      [key: string]: boolean;
    }
  }
  optionsData: TODO;
  datasetName: string;
  setTabSelected: (item: TODO) => void;
}

export const ReportsTabContent = ({ user, analysisFunctionalities, optionsData, datasetName, setTabSelected }: Props) => {
  const { defaultLanguage, editingLanguage } = useSelector((theState: RootState) => (theState.setInitialDataReducer));
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const reportData = useSelector((state: RootState) => state.analysisReportsReducer);

  const params: { name: string, dataset: string } = useParams();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const onSelectQuestion = (clickedQuestion: TODO) => {
    const updatedQuestions = reportData.report.firstColumn.map((item: TODO) => item.id === clickedQuestion.id ? { ...item, selected: true } : { ...item, selected: false })
    const updatedReport = { ...reportData.report, firstColumn: updatedQuestions }

    dispatch(updateReport(updatedReport));

    setIsLoading(true);
    const body = returnUpdatedAnalysisBody('datasets', reportData.report.type, reportData.report.newQuestionOptions, reportData.report.firstColumn, reportData.report?.scndColumn?.filter((item: TODO) => !item.disabled), clickedQuestion.id, editingLanguage, defaultLanguage);

    fetchPostJson(`an/projects/${params.name}/analysis/${params.dataset}`, token, body)
      .then((res: TODO) => {
        if (res.error || res.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
          setIsLoading(false);
        } else {
          const tableResult = reportData.report.type === "g" ? res : res.result;
          dispatch(updateTableData(tableResult))
          setIsLoading(false);
        }
      })
  }

  const onCheckQuestionReportTab = () => {

  }

  return (
    <div className="d-flex flex-column h-100 border-table-area">
      <ReportsTabHeader
        user={user}
        analysisFunctionalities={analysisFunctionalities}
        optionsData={optionsData}
        datasetName={datasetName}
        setTabSelected={setTabSelected}
      />

      <div className="d-flex h-100">
        {reportData.isLoadingData ? <InProgressOverlay message="" className="" theme="primary" type="fullscreen" /> :
          <Fragment>
            <div className="d-flex flex-column bg-white analyze-sidebar border-right w-25">
              <div className="d-flex flex-column overflow-hidden report-list crosstab h-100 w-100">
                <DroppableColumn isReports={true} onSelectQuestion={onSelectQuestion} firstColumn={true} rangeItems={[]} state={reportData.report} onCheck={onCheckQuestionReportTab} />
              </div>
            </div>

            <div className={`d-flex flex-column overflow-hidden flex-fill w-75 ${reportData.report.type}`}>
              {
                isLoading ? <InProgressOverlay message="" className="" theme="primary" type="fullscreen" />
                  :
                  <Fragment>
                    {
                      reportData.report.type === 'g' ?
                        <div className="w-100 d-flex flex-column overflow-auto">
                          <ChartTypeContent currentZoom={1} data={reportData.tableData} />
                        </div>
                        :
                        <div className="w-100 d-flex flex-column overflow-hidden">
                          <JsxParser jsx={reportData.tableData} />
                        </div>
                    }
                  </Fragment>
              }
            </div>
          </Fragment>
        }
      </div>
    </div>
  )
}
