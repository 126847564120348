import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import { Icon } from '../../../../../shared/Icon/Icon';
import TagsModal from "../../helpers/TagsModal/TagsModal";
import usePrevious from "../../../../../shared/customHooks/usePrevious";
import { ErrorMessage } from "../../../../../shared/ErrorMessage/ErrorMessage";
import { useDebounce } from "../../../../../shared/customHooks/useDebounce";
import { XmlScriptingModal } from "../../helpers/XmlScriptingModal/XmlScriptingModal";
import { InProgressOverlay } from "../../../../../shared/InProgressOverlay/InProgressOverlay";
import ConfirmChangeTypeModal from "../../helpers/ConfirmChangeTypeModal/ConfirmChangeTypeModal";
import { EditTabQuesElementContent } from "./EditTabQuesElementContent/EditTabQuesElementContent";
import { EditTabGotoElementContent } from "./EditTabGotoElementContent/EditTabGotoElementContent";
import { returnHighestCodeValue } from "../../helpers/returnHighestCodeValue/returnHighestCodeValue";
import { EditTabQuotaElementContent } from "./EditTabQuotaElementContent/EditTabQuotaElementContent";
import { returnEmptyAndDuplicatedCodes } from "../../helpers/returnEmptyAndDuplicatedCodes/returnEmptyAndDuplicatedCodes";
import { EditTabInfoAndQuotastopElementContent } from "./EditTabInfoAndQuotastopElementContent/EditTabInfoAndQuotastopElementContent";
import { returnElementOptions, returnOnAdvancedEditData, returnOnDeleteElement, returnOnElementHandleAction, returnOnReorderColumns, returnOnReorderRows, returnQuestionTypeChange, returnSelectedElementData } from "../../helpers/returnDataHelpers/returnDataHelpers";
import { fetchPutJson as updateSurveyElement, fetchPatchJson as patchQuota, fetchGetJson as fetchGetQuota, fetchPatch as fetchPatchQuotaCells, fetchGetJsonOrEmptyArray } from "../../../../../../services/services";

export const EditTabContent = ({ onShowOptions, onShowScript, showDeleteElementModal, updateElements, token, onShowCSS }) => {
  const dispatch = useDispatch()
  const activeRef = useRef()
  const [didMount, setDidMount] = useState(true)
  const [tagsModal, setTagsModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isValidating, setIsValidating] = useState(false)
  const [updateLanguage, setUpdateLanguage] = useState(null)
  const [labelInvalid, setLabelInvalid] = useState("");
  const [quotaStopLabel, setQuotaStopLabel] = useState("");
  const [xmlModal, setXmlModal] = useState({ show: false })
  const [renameModal, setRenameModal] = useState({ show: false, value: "" });
  const [changeTypeModal, setChangeTypeModal] = useState({ show: false, fromType: "", toType: "", index: 0, })
  const [editingElement, setEditingElement] = useState({ content: null, type: null, subQuesIndex: null, index: null, })
  const [advancedEdit, setAdvancedEdit] = useState({ show: false, type: null, subQuesIndex: null, content: null, })
  const { theData, tags } = useSelector((theState) => (theState.surveyInitialDataReducer))

  const prevChangeType = usePrevious(theData?.changeType)
  const prevSelectedItem = theData ? usePrevious(theData.originalSelectedItem) : null;
  const saveWithDebounce = useDebounce(() => dispatch({ type: 'SAVE_QUESTION' }), 1000);

  useEffect(() => {
    if (tags === null) {
      fetchGetJsonOrEmptyArray(`tags`, token)
        .then((res) => {
          if (res) {
            dispatch({ type: 'SET_TAGS', payload: res })
          }
        })
    }
  }, [tags])

  useEffect(() => {
    if (editingElement && editingElement.type === "label") {
      dispatch({ type: "SURVEY_SET_LAST_EDITING_CONTENT", payload: editingElement.content, })
    }
  }, [editingElement?.type])

  useEffect(() => {
    if (theData.selectedElement && theData.optionsType && (theData.optionsType.type === "ques" || theData.optionsType.type === "subq" || theData.optionsType.type === "info") && theData.selectedItem.type !== "info" && theData.selectedItem.type !== "quotastop" && theData.selectedItem.type !== 'quota' && theData.selectedItem.elements.filter((el) => el.type === "subq").length <= 1 && theData.displayOptions === true) {
      activeRef.current.scrollIntoView({ block: "end", behavior: "smooth" })
    }
  }, [theData.selectedElement, theData.optionsType, theData.selectedItem?.type, theData.selectedItem?.elements, theData.displayOptions])

  useEffect(() => {
    if (updateLanguage) {
      dispatch({ type: "UPDATE_SURVEY_EDITING_LANGUAGE", payload: updateLanguage, })
      setUpdateLanguage(null)
    }
  }, [updateLanguage])

  useEffect(() => {
    if (theData.originalSelectedItem && theData.originalSelectedItem !== prevSelectedItem) {
      setDidMount(true)
      dispatch({ type: "SURVEY_SET_SUBQUESTION_INDEX", payload: 0 })
      setEditingElement({ content: null, type: null, subQuesIndex: null, index: null })
    }
  }, [theData.originalSelectedItem, prevSelectedItem])

  const updateSelectedElement = useCallback((item, originalItem) => {
    const elementOptions =
      item.type !== "quota" ? [{
        type: originalItem.type,
        expanded: true,
        published: originalItem.attributes && originalItem.attributes["miext:id"] ? true : false,
        isDisabled: originalItem.attributes && originalItem.attributes["miext:disabled"] && originalItem.attributes["miext:disabled"] === "true" ? true : false,
        index: null,
        items: returnElementOptions(originalItem, originalItem.attributes && originalItem.attributes["miext:disabled"] && originalItem.attributes["miext:disabled"] === "true" ? true : false, originalItem.type, null),
      },
      ] : null;
    dispatch({ type: "UPDATE_SURVEY_ELEMENT_DATA", payload: { item: item, originalItem: originalItem, elementOptions: elementOptions } });
    if (item.type === "quotastop") {
      dispatch({ type: 'SURVEY_RELOAD_OPTIONS', payload: { reload: false, expression: null } })
    }
    dispatch({ type: 'SAVE_QUESTION' })
  }, [dispatch])

  const onDragEnd = (result, index) => {
    let destinationError;
    let sourceError
    let reorderedItem
    if (result.destination) {
      if (result.type === "columns") {
        destinationError = theData.selectedItem.elements.filter((el) => el.type === "subq")
        [index].elements.find((el) => el.type === "hgroup").elements[
          result.source.index
        ].errorMessage;
        sourceError = theData.selectedItem.elements.filter((el) => el.type === "subq")[index].elements.find((el) => el.type === "hgroup").elements[result.destination.index].errorMessage
        reorderedItem = returnOnReorderColumns(result.source.index, result.destination.index, index, theData)
      } else if (result.type === "rows") {
        destinationError = theData.selectedItem.elements.find((el) => el.type === "rgroup").elements[result.source.index].errorMessage
        sourceError = theData.selectedItem.elements.find((el) => el.type === "rgroup").elements[result.destination.index].errorMessage
        reorderedItem = returnOnReorderRows(result.source.index, result.destination.index, theData)
      }
      let selectedItem = returnSelectedElementData(reorderedItem, theData.data.find((el) => el.index === theData.selectedItem.section).elements.find((el) => el.selected === true), theData.validationErrors, theData.data)
      if (result.type === "rows") {
        selectedItem.elements.find((el) => el.type === "rgroup").elements[result.source.index].errorMessage = sourceError;
        selectedItem.elements.find((el) => el.type === "rgroup").elements[result.destination.index].errorMessage = destinationError
      } else if (result.type === "columns") {
        selectedItem.elements.filter((el) => el.type === "subq")[index].elements.find((el) => el.type === "hgroup").elements[result.source.index].errorMessage = sourceError
        selectedItem.elements.filter((el) => el.type === "subq")[index].elements.find((el) => el.type === "hgroup").elements[result.destination.index].errorMessage = destinationError
      }
      reorderedItem['_etag'] = theData.originalSelectedItem['_etag']
      updateSelectedElement(selectedItem, reorderedItem)
    }
  };

  const onAddElement = (type, subQuesIndex) => {
    let originalItem = JSON.parse(JSON.stringify(theData.selectedItem))
    let values = {}
    theData.originalData.languages.map((el) => el.code).forEach((el) => (values[el] = ""));
    if (type === "r") {
      const newRow = {
        type: "r",
        attributes: {},
        metadata: null,
        elements: [{ type: "text", attributes: {}, elements: [], textValues: values, metadata: null, }],
      }
      if (originalItem.elements.filter((el) => el.type === "subq")[subQuesIndex].attributes.type === 'n' || originalItem.elements.filter((el) => el.type === "subq")[subQuesIndex].attributes.type === 'm') {
        newRow.attributes.code = returnHighestCodeValue(originalItem.elements.find((el) => el.type === "rgroup").elements)
      }
      originalItem.elements.find((el) => el.type === "rgroup").elements.push(newRow)
    } else if (type === "rc") {
      const newRow = {
        type: "rc",
        attributes: {},
        metadata: null,
        elements: [{ type: "text", attributes: {}, elements: [], textValues: values, metadata: null, }],
      }
      originalItem.elements.find((el) => el.type === "rgroup").elements.push(newRow)
    } else if (type === "h") {
      const newColumn = {
        type: "h",
        attributes: {},
        metadata: null,
        elements: [{ type: "text", attributes: {}, elements: [], textValues: values, metadata: null, }],
      }
      if (originalItem.elements.filter((el) => el.type === "subq")[subQuesIndex].attributes.type === 'rn' || originalItem.elements.filter((el) => el.type === "subq")[subQuesIndex].attributes.type === 'rm') {
        newColumn.attributes.code = returnHighestCodeValue(originalItem.elements.filter((el) => el.type === "subq")[subQuesIndex].elements.find((el) => el.type === "hgroup").elements)
      }
      originalItem.elements.filter((el) => el.type === "subq")[subQuesIndex].elements.find((el) => el.type === "hgroup").elements.push(newColumn)
    }
    const selectedItem = returnSelectedElementData(originalItem, theData.data.find((el) => el.index === theData.selectedItem.section).elements.find((el) => el.selected === true), theData.validationErrors, theData.data)
    // updateSelectedElement(selectedItem, originalItem)
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: selectedItem })
    saveWithDebounce()
  }

  const onTypeChange = useCallback((type, value, subQuesIndex) => {
    if (type !== value.id) {
      let originalItem = returnQuestionTypeChange(type, value, subQuesIndex, theData)
      const selectedItem = returnSelectedElementData(originalItem, theData.data.find((el) => el.index === theData.selectedItem.section).elements.find((el) => el.selected === true), theData.validationErrors, theData.data)
      setChangeTypeModal({ show: false, toType: "", fromType: "", index: "" })
      updateSelectedElement(selectedItem, originalItem)
      dispatch({ type: 'SET_CHANGE_TYPE', payload: null })
    }
  }, [theData, updateSelectedElement, dispatch])

  useEffect(() => {
    if (theData.changeType && theData.changeType?.toType !== prevChangeType?.toType) {
      if (theData.changeType?.show) {
        setChangeTypeModal({ show: true, toType: theData.changeType.toType, fromType: theData.changeType.fromType, index: theData.selectedSubquestion })
      } else {
        onTypeChange(theData.changeType.fromType, theData.changeType.toType, theData.selectedSubquestion)
      }
    }
  }, [theData.changeType, theData.selectedSubquestion, prevChangeType, onTypeChange])

  const handleAction = (e, subQuesIndex, index, type) => {
    let originalItem = JSON.parse(JSON.stringify(theData.selectedItem))
    if (e.item.id !== "delete") {
      if (type === "column" || type === "row" || type === "subq" || type === "info") {
        originalItem = returnOnElementHandleAction(originalItem, e, subQuesIndex, index, type, theData.originalData.languages.map((el) => el.code))
      } else if (type === "ques") {
        if (e.item.id === "subq") {
          let values = {}
          theData.originalData.languages.map((el) => el.code).forEach((el) => (values[el] = ""))
          originalItem.elements.splice(-1, 0, {
            type: "subq",
            metadata: null,
            attributes: { type: "n", range: "*" },
            elements: [{ type: "stext", attributes: {}, metadata: null, elements: [{ type: "text", attributes: {}, textValues: values, metadata: null, }] },],
          })
          dispatch({ type: 'SURVEY_SET_SUBQUESTION_INDEX', payload: originalItem.elements.filter((el) => el.type === "subq").length - 1 })
        }
        if (e.item.id === "qtext") {
          let values = {};
          theData.originalData.languages.map((el) => el.code).forEach((el) => { if (el !== theData.editingLanguage) { values[el] = ""; } })
          originalItem.elements.unshift({
            type: "qtext",
            elements: [{ type: "text", attributes: { lang: "en" }, elements: [], textValues: values }],
            attributes: {},
          });
        } else if (e.item.id === "disable") {
          originalItem.attributes = { ...originalItem.attributes, "miext:disabled": "true" }
        }
      }
    } else {
      if (type !== "subq" && type !== "ques" && type !== "info" && type !== 'quota') {
        const deleted = returnOnDeleteElement(type === "row" ? "r" : "h", subQuesIndex, index, null, null, theData)
        originalItem = deleted
      } else {
        dispatch({ type: "SURVEY_SET_OPTIONS_TYPE", payload: { type: { type: type === "ques" ? "ques" : type === "info" ? "info" : type === "quota" ? "quota" : "subq", subQuesIndex: subQuesIndex, index: index } } })
        showDeleteElementModal();
      }
    }
    if (e.item.id !== 'delete' && type !== 'quota') {
      const selectedItem = returnSelectedElementData(originalItem, theData.data.find((el) => el.index === theData.selectedItem.section).elements.find((el) => el.selected === true), theData.validationErrors, theData.data)
      // updateSelectedElement(selectedItem, originalItem)
      dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: selectedItem })
      saveWithDebounce()
    }
  }

  const onClose = () => {
    setXmlModal({ show: false })
  }

  const onAdvancedEdit = (type, subQuesIndex) => {
    let content = [];
    if (type === "r" || type === "other") {
      let items = theData.selectedItem.elements.find((el) => el.type === "rgroup").elements.filter(el => el.type === 'r' || el.type === 'other')
      items.forEach((el) => el.elements.forEach((item) => content.push(item.textValues[theData.editingLanguage])))
    } else if (type === "h") {
      let items = theData.selectedItem.elements.filter((el) => el.type === "subq")[subQuesIndex].elements.find((el) => el.type === "hgroup").elements.filter(el => el.type === 'h')
      items.forEach(el => el.elements.forEach(item => content.push(item.textValues[theData.editingLanguage])))
    }

    const removedParagraphs = JSON.parse(JSON.stringify(content))
    removedParagraphs.forEach((item, index) => {
      if (new DOMParser().parseFromString(item, "text/html").body.childNodes.length === 1) {
        const doc = new DOMParser().parseFromString(item, "text/html").body.firstChild;
        const array = Array.from(doc.childNodes)
        if (array.length === 1) {
          removedParagraphs[index] = doc?.textContent ? doc.textContent : item
        }
      }
    })
    content = removedParagraphs.join('\n')

    setAdvancedEdit({ show: true, type: type, subQuesIndex: subQuesIndex, content: content, })
  }

  const onSaveAdvancedEdit = () => {
    const updatedContent = JSON.parse(JSON.stringify(advancedEdit.content.split('\n')))
    const originalItem = returnOnAdvancedEditData(theData.selectedItem, advancedEdit.type, advancedEdit.subQuesIndex, updatedContent, theData.editingLanguage, theData.originalData.languages.map((el) => el.code));
    const selectedItem = returnSelectedElementData(originalItem, theData.data.find((el) => el.index === theData.selectedItem.section).elements.find((el) => el.selected === true), theData.validationErrors, theData.data);

    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: selectedItem })
    dispatch({ type: 'SAVE_ADVANCED_QUESTION' })

    setAdvancedEdit({ show: false, type: null, subQuesIndex: null, content: null, })
  }

  const submitLabelChange = (e) => {
    e.preventDefault();
    onSaveChanges();
  }

  const validateLabel = (theLabel) => {
    const labelValid = theLabel.match(/((?=.*[a-z])|(?=.*[A-Z]))([a-zA-Z0-9_])\w+/g) ? theLabel.match(/((?=.*[a-z])|(?=.*[A-Z]))([a-zA-Z0-9_])\w+/g).toString() === theLabel ? true : false : false;
    return labelValid;
  }

  const onUpdateXmlScript = () => {
    updateElements(theData.originalSelectedItem.id)
    setDidMount(true)
  }

  const onSaveChanges = () => {
    const { hasEmptyCodes, hasDuplicateCodes } = returnEmptyAndDuplicatedCodes(theData)

    if (!hasDuplicateCodes && !hasEmptyCodes) {
      if (!isValidating) {
        dispatch({ type: "LOAD_SURVEY_ELEMENT_DATA", payload: "Saving..." });
      }

      if (theData.selectedItem.type !== "quota") {
        const body = {
          ...theData.originalSelectedItem,
          elements: theData.selectedItem.elements,
          attributes: theData.selectedItem.attributes,
        }

        if (theData.selectedItem.attributes.id) {
          let labelValid = true
          labelValid = validateLabel(theData.selectedItem.attributes.id)
          if (!labelValid) {
            setLabelInvalid("Labels can only contain characters a-z, A-Z, 0-9, _ and has to contain one uppercase or lowercase letter");
            dispatch({ type: "FORCE_STOP_LOADING", payload: null });
          }
        }

        if (theData.selectedItem.type === "quotastop") {
          body.attributes.quota = theData.selectedItem.referenceQuotas.setQuota.referenceDisplayLabel;
          const labelValid = validateLabel(quotaStopLabel);
          if (quotaStopLabel === "" || labelValid) {
            body.attributes.id = quotaStopLabel;
          } else {
            dispatch({ type: "FORCE_STOP_LOADING", payload: null });
            setLabelInvalid("Labels can only contain characters a-z, A-Z, 0-9, _ and and has to contain one uppercase or lowercase letter");
            return;
          }
        }
        updateSurveyElement(`su/projects/${theData.originalSelectedItem.projectId}/surveys/${theData.originalSelectedItem.surveyId}/elements/${theData.originalSelectedItem.id}`, token, body)
          .then((res) => {
            if (res.error || res.message) {
              dispatch({ type: "FORCE_STOP_LOADING", payload: null });
              setErrorMessage(res.error ? res.error : res.message)
            } else {
              if (res.indexElement && res.definition) {
                setRenameModal({ show: false, value: "" });
                setLabelInvalid("");
                updateElements(theData.originalSelectedItem.id)
                setDidMount(true)
              }
            }
          })
      } else if (theData.selectedItem.type === "quota") {
        if (renameModal.show !== true) {
          const body = theData.selectedItem.quotaCells.map((el) => {
            return { id: el.id, targetValue: el.targetValue }
          })
          fetchPatchQuotaCells(`projects/${theData.selectedItem.projectId}/surveys/${theData.selectedItem.surveyId}/quotas/${theData.selectedItem.quotaId}/cells`, token, body)
            .then((res) => {
              if (res.status !== 200) {
                dispatch({ type: "FORCE_STOP_LOADING", payload: null });
                setErrorMessage("An error has occured and your quota could not be updated")
              } else if (res.status === 200) {
                // There is no response json from this call, so we make another API call to refresh
                quotaUpdateHandler(theData.selectedItem, "refresh")
              }
            })
        } else {
          const labelValid = validateLabel(renameModal.value);
          if (labelValid) {
            const body = { label: renameModal.value };
            patchQuota(`projects/${theData.selectedItem.projectId}/surveys/${theData.selectedItem.surveyId}/quotas/${theData.selectedItem.quotaId}`, token, body)
              .then(res => {
                if (res.error || res.message) {
                  if (renameModal.show) {
                    setLabelInvalid(res.error ? res.error : res.message)
                  } else {
                    setErrorMessage(res.error ? res.error : res.message)
                  }
                } else {
                  setRenameModal({ show: false, value: "" });
                  setLabelInvalid("");
                  updateElements(theData.originalSelectedItem.id)
                  setDidMount(true)
                }
              })
          } else {
            dispatch({ type: "FORCE_STOP_LOADING", payload: null });
            setLabelInvalid("Labels can only contain characters a-z, A-Z, 0-9, _ and and has to contain one uppercase or lowercase letter");
            return;
          }
        }
      }
    } else {
      if (hasEmptyCodes) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'Some of the statements/answers have an empty code value' } })
      } else if (hasDuplicateCodes) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'Some of the statements/answers do not have unique code values' } })
      }
    }
  }

  const onLanguageChange = (e) => {
    dispatch({ type: "LOAD_SURVEY_ELEMENT_DATA", payload: null })
    setUpdateLanguage(e.item.code)
  }

  const updateTags = (tags) => {
    const updatedOriginalElement = theData.originalSelectedItem
    const updatedElement = theData.selectedItem
    updatedOriginalElement.tags = tags
    updatedElement.tags = tags
    updateSelectedElement(updatedElement, updatedOriginalElement)
    setTagsModal(false)
  }

  // -------- Quota Functions --------
  const updateQuota = (quota) => {
    let originalItem = {
      ...theData.originalSelectedItem,
      quotaId: quota.quotaId,
      label: quota.label,
      disabled: quota.disabled,
      published: quota.published,
      quotaCells: quota.quotaCells,
      projectId: theData.selectedItem.projectId,
      surveyId: theData.selectedItem.surveyId,
      quotaTable: quota.quotaTable,
    }
    let updatedQuota = {
      ...theData.selectedItem,
      quotaId: quota.quotaId,
      label: quota.label,
      disabled: quota.disabled,
      published: quota.published,
      quotaCells: quota.quotaCells,
      quotaTable: quota.quotaTable,
    }
    dispatch({ type: 'UPDATE_SURVEY_ELEMENT_DATA', payload: { item: updatedQuota, originalItem: originalItem, elementOptions: null } })
  }

  const quotaUpdateHandler = (quota, type) => {
    const updatedQuota = { ...quota };
    const relevantIds = { projectId: theData.selectedItem.projectId, surveyId: theData.selectedItem.surveyId }

    switch (type) {
      case "updateTable":
        //@ts-ignore - Suppress warning until services.js gets updated with TypeScript
        fetchGetQuota(`projects/${relevantIds.projectId}/surveys/${relevantIds.surveyId}/quotas/${quota.quotaId}/quota-cell-tabular-overview`, token)
          .then((res) => {
            if (res.error || res.message) {
              setErrorMessage(res.error ? res.error : res.message)
            } else {
              updatedQuota.quotaTable = { ...res, show: true };
              updateQuota(updatedQuota);
            }
          })
        break;
      case "refresh":
        fetchGetQuota(`projects/${relevantIds.projectId}/surveys/${relevantIds.surveyId}/quotas/${quota.quotaId}/extended`, token)
          .then((res) => {
            if (res.error || res.message) {
              setErrorMessage(res.error ? res.error : res.message)
            } else {
              updatedQuota.cellOverview = res.cells
              updatedQuota.quotaTable = { ...res.quotaTable, show: updatedQuota.quotaTable?.show, showRemaining: updatedQuota.quotaTable?.showRemaining };
              updateQuota(updatedQuota);
              dispatch({ type: "FORCE_STOP_LOADING", payload: null });
              dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Target values have been updated successfully' } })
            }
          })
        break;
      case "hide":
        updatedQuota.quotaTable = { ...quota.quotaTable, show: false };
        updateQuota(updatedQuota, relevantIds);
        break;
      case "remainingSwitch":
        updatedQuota.quotaTable = { ...quota.quotaTable, showRemaining: quota.quotaTable.showRemaining === true ? false : true };
        updateQuota(updatedQuota, relevantIds);
        break;
      case "customTotalsSwitch":
        updatedQuota.quotaTable = { ...quota.quotaTable, customTotals: quota.quotaTable.customTotals === true ? false : true };
        updateQuota(updatedQuota, relevantIds);
        break;
      default:
        break;
    }
  }
  // ^^^^^^ Quota Functions ^^^^^^

  const onDropdownItemClick = ({ item }) => {
    if (item.id) {
      handleAction({ item: item }, 0, 0, 'ques')
    } else if (item.text === 'Edit tags') {
      setTagsModal(true)
    } else if (item.text === 'Turn logic on') {
      dispatch({ type: "UPDATE_SURVEY_SHOW_LOGIC", payload: true })
    } else if (item.text === 'Paste pad') {
      dispatch({ type: 'SURVEY_DISPLAY_PASTE_PAD', payload: true })
    } else if (item.text === 'Turn logic off') {
      dispatch({ type: "UPDATE_SURVEY_SHOW_LOGIC", payload: false })
    } else if (item.text === 'Turn rich text off') {
      dispatch({ type: "UPDATE_SURVEY_HTML_MODE", payload: true })
      localStorage.setItem('SURVEY_HTML_MODE', JSON.parse(true))
    } else if (item.text === 'Turn rich text on') {
      dispatch({ type: "UPDATE_SURVEY_HTML_MODE", payload: false })
      localStorage.setItem('SURVEY_HTML_MODE', JSON.parse(false))
    } else if (item.text === 'Options') {
      onShowOptions()
    } else if (item.text === 'Insert JavaScript') {
      onShowScript()
    } else if (item.text === 'Rename') {
      setRenameModal({ show: true, value: editingElement.type === 'label' ? editingElement.content : theData.selectedItem.attributes["id"] })
    } else if (item.text === "XML Scripting") {
      setXmlModal({ show: true })
    } else if (item.text === 'Add container text') {
      const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
      updatedSelectedItem.elements.push({ "type": "qtext", "attributes": {}, "elements": [{ "type": "text", "attributes": {}, "textValues": { [theData.editingLanguage]: "" } }] })
      dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
      saveWithDebounce()
    } else if (item.text === "Insert CSS") {
      onShowCSS()
    }
  }

  let selectedLanguage = null
  if (theData?.originalData?.languages) {
    selectedLanguage = theData?.originalData?.languages?.find(item => item.code === theData.editingLanguage)
  }

  return (
    <div className="edit-section h-100 overflow-hidden">
      {
        errorMessage &&
        <ErrorMessage
          type="modal"
          errorMessage={errorMessage}
          onHide={() => setErrorMessage(null)} />
      }
      {advancedEdit.show &&
        <Dialog title="Paste texts" height="auto" width="50%" onClose={() => setAdvancedEdit({ show: false, type: null, subQuesIndex: null, content: null })} >
          <div className="d-flex flex-column px-4 pb-4">
            <textarea className="form-control" style={{ maxHeight: 'unset', resize: 'none' }} value={advancedEdit.content} onChange={(e) => setAdvancedEdit({ ...advancedEdit, content: e.target.value })} name="test" id="test" cols="30" rows="17"></textarea>
          </div>
          <DialogActionsBar>
            <div className="d-flex w-100 justify-content-between">
              <div className='d-flex align-items-center medium'>
                <Icon type="alert" className="mr-1" />
                <span>Removing answers in this editor will reset the answer options.</span>
              </div>
              <div className="d-flex">
                <button className="k-button btn btn-secondary" id="close" onClick={() => setAdvancedEdit({ show: false, type: null, subQuesIndex: null, content: null })}>Cancel</button>
                <button className="k-button btn btn-primary" onClick={() => onSaveAdvancedEdit()}>Save</button>
              </div>
            </div>
          </DialogActionsBar>
        </Dialog>
      }
      {
        xmlModal.show &&
        <XmlScriptingModal
          projectId={theData.selectedItem.projectId}
          surveyId={theData.selectedItem.surveyId}
          elementId={theData.selectedItem.id}
          onUpdateXmlScript={onUpdateXmlScript}
          onClose={onClose} />
      }

      {
        changeTypeModal.show &&
        <ConfirmChangeTypeModal
          toType={changeTypeModal.toType}
          fromType={changeTypeModal.fromType}
          handleClose={() => setChangeTypeModal({ show: false, toType: "", fromType: "", index: 0, })}
          changeType={() => onTypeChange(changeTypeModal.fromType, changeTypeModal.toType, changeTypeModal.index)}
          type="editor"
        />
      }
      {
        tagsModal &&
        <TagsModal
          handleClose={() => setTagsModal(false)}
          data={tags}
          tagsValues={theData.selectedItem.tags ? theData.selectedItem.tags : []}
          updateTags={updateTags}
        />
      }
      {
        renameModal.show &&
        <Dialog width={"30rem"} title={`Change label`} onClose={() => { setRenameModal({ show: false, msg: "" }); setLabelInvalid("") }}>
          <form onSubmit={e => submitLabelChange(e)}>
            <div className="d-flex flex-column p-4">
              <span className="mb-1">New label</span>
              <input
                className="w-100 k-textbox"
                value={renameModal.value}
                onChange={e => setRenameModal({ ...renameModal, value: e.target.value })}
                onKeyPress={(e) => { e.key === 'Enter' && submitLabelChange(e) }}
                autoFocus={true} />
            </div>
            {labelInvalid &&
              <div className="d-flex mx-3 align-items-center alert alert-danger alert-dismissible fade show" role="alert">
                <i className="fas fa-2x fa-exclamation-triangle text-danger pr-3"></i>
                <strong className="text-center w-100">
                  <p className="m-0">{labelInvalid}</p>
                </strong>
                <button type="button" className="close" onClick={() => setLabelInvalid("")} aria-label="Close">
                  <span>&times;</span>
                </button>
              </div>
            }
            <DialogActionsBar>
              <button className="k-button btn btn-secondary" id="close" onClick={(e) => { e.preventDefault(); setLabelInvalid(""); setRenameModal({ show: false, value: "" }) }}>Cancel</button>
              <button className="k-button btn btn-primary" type="submit">Update</button>
            </DialogActionsBar>
          </form>
        </Dialog>
      }
      {
        theData.loadingState && !theData.loadingMessage ?
          <div className="card w-100 h-100">
            <div className="card-header d-flex flex-column px-3 h-48 rounded-0"></div>
            <div className="h-100 w-100 d-flex p-4" style={{ backgroundColor: "rgb(243, 244, 244)" }}>
              <div className="card-body d-flex flex-column h-100 bg-white align-items-center justify-content-center answer-layout">
                <InProgressOverlay theme="primary" type="fullscreen" />
              </div>
            </div>
          </div>
          : theData.loadingState && theData.loadingMessage === "Updating" ?
            <div className="d-flex flex-column h-100">
              <div className="card w-100 h-100" title={theData.selectedItem.disabled ? "This element is disabled" : undefined} >
                <InProgressOverlay type="overlay" theme="primary" message={theData.loadingMessage} />
              </div>
            </div>
            : theData.selectedItem !== null ?
              <div className="d-flex flex-column h-100">
                {theData.selectedItem.type !== "info" && theData.selectedItem.type !== "goto" && theData.selectedItem.type !== "quota" && theData.selectedItem.type !== "quotastop" ? (
                  // IF SELECTED QUESTION IS TYPE QUES
                  <EditTabQuesElementContent
                    selectedLanguage={selectedLanguage}
                    onDropdownItemClick={onDropdownItemClick}
                    onLanguageChange={onLanguageChange}
                    onSaveChanges={onSaveChanges}
                    onDragEnd={onDragEnd}
                    onAddElement={onAddElement}
                    handleAction={handleAction}
                    onAdvancedEdit={onAdvancedEdit}
                    onShowScript={onShowScript}
                  />
                ) : theData.selectedItem.type === "quota" ?
                  // QUOTA ELEMENTS
                  <EditTabQuotaElementContent
                    setRenameModal={setRenameModal}
                    handleAction={handleAction}
                    setErrorMessage={setErrorMessage}
                    onSaveChanges={onSaveChanges}
                  />
                  : (theData.selectedItem.type === "info" || theData.selectedItem.type === "quotastop") ?
                    // INFO OR QUOTA STOP
                    <EditTabInfoAndQuotastopElementContent
                      activeRef={activeRef}
                      onDropdownItemClick={onDropdownItemClick}
                      selectedLanguage={selectedLanguage}
                      onLanguageChange={onLanguageChange}
                      onSaveChanges={onSaveChanges}
                      quotaStopLabel={quotaStopLabel}
                      setQuotaStopLabel={setQuotaStopLabel}
                      labelInvalid={labelInvalid}
                      setLabelInvalid={setLabelInvalid}
                    />
                    :
                    // GOTO ELEMENTS
                    <EditTabGotoElementContent
                      activeRef={activeRef}
                      setIsValidating={setIsValidating}
                    />
                }
              </div >
              :
              <div className="h-100 w-100 d-flex p-4" style={{ backgroundColor: "rgb(243, 244, 244)" }}>
                <div className="card-body d-flex flex-column h-100 bg-white align-items-center justify-content-center answer-layout">
                  <Icon type="editor" className='pe-none fill-primary select-placeholder' />
                  <h1 className="droppable-text strong mt-2 h4">Select an element to edit</h1>
                </div>
              </div >
      }
    </div >
  );
};