import { returnSelectedSubAccountUsers } from "../returnSelectedSubAccountUsers/returnSelectedSubAccountUsers"

interface ProjectSharingFormData {
  radioButton: 'readonly' | 'collaborative'
  checked: boolean
  analysisActions: {
    exportToPpt: boolean
    exportToWord: boolean
    exportToExcel: boolean
    createQuestions: boolean
    createWeightSet: boolean
  }
  analysisTypes: {
    dig: boolean
    data: boolean
    chart: boolean
    qTable: boolean
    topline: boolean
    crossTable: boolean
  }
}

export const returnProjectSharingBody = (formData: ProjectSharingFormData, regularAccounts: any[], subAccounts: any[]) => {
  const body = {
    "shareDetails": {
      "accessMode": formData.radioButton,
      "invitedUsersCanShareInternally": formData.checked,
      "shareFunctionalities": {
        "analysisActions": formData.analysisActions,
        "analysisTypes": formData.analysisTypes
      }
    },
    "regularUsers": regularAccounts.map(item => { return { email: item } }),
    "subAccountUsers": returnSelectedSubAccountUsers(subAccounts)
  }

  return body
}