import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import type { RootState } from '../../../../../store/reducers/rootReducer';
import { ErrorMessage } from '../../../../shared/ErrorMessage/ErrorMessage';
import type { SubAccount } from '../../../../../interfaces/adminInterfaces/adminInterfaces';
import { fetchDelete as deleteSubAccount } from '../../../../../services/services';

interface Props {
  subAccountId: string
  subAccounts: SubAccount[] | null
  handleClose: () => void
  setSubAccounts: (data: SubAccount[] | null) => void
}

export const DeleteSubAccountModal = ({ subAccountId, subAccounts, handleClose, setSubAccounts }: Props) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteSubAccount = () => {
    if (subAccountId) {
      setIsLoading(true);
      deleteSubAccount(`sub-accounts/${subAccountId}`, token, false)
        .then((res: TODO) => {
          setIsLoading(false);
          if (res && res.status === 204) {
            const updatedSubAccounts = subAccounts?.filter(subAccount => subAccount.id !== subAccountId)
            if (updatedSubAccounts) {
              setSubAccounts(updatedSubAccounts);
            }
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Sub account has been deleted successfully' } })
            handleClose();
          } else return res.json();
        })
        .then((res: TODO) => {
          if (res.error || res.message) {
            setErrorMessage(res.error ? res.error : res.message)
          }
        })
    }
  }

  return (
    <Dialog
      title="Delete Sub account"
      onClose={handleClose}
      className="d-flex flex-column w-100"
      width={500}>
      <div className="p-5">
        <span>
          Are you sure you want to delete this sub account? <strong>This action will delete the sub account and all users inside</strong>.
        </span>
      </div>
      {
        errorMessage &&
        <div className="mx-4">
          <ErrorMessage
            type="alert"
            errorMessage={errorMessage}
            onHide={() => { setErrorMessage("") }} />
        </div>
      }
      <DialogActionsBar>
        <button type='button' className="k-button btn btn-secondary" onClick={handleClose}>Cancel</button>
        <button disabled={isLoading} type='button' className="k-button btn-danger" onClick={handleDeleteSubAccount}>Delete</button>
      </DialogActionsBar>
    </Dialog>
  );
}