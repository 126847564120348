import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useParams } from 'react-router';

import { FullHeightSpinner } from '../../../../../shared/FullHeightSpinner/FullHeightSpinner';
import { returnSurveyOptionsByType } from '../returnSurveyOptionsByType/returnSurveyOptionsByType';
import { fetchGetJson as getSurveyStructure, fetchPutJson as updateSurveyIndex } from '../../../../../../services/services';

export const SectionOptionsDialog = ({ setOptionValues, handleClose, optionsDialog, updateElements }) => {
  const listItemValueType = optionsDialog.item.attributes.loop === "" || optionsDialog.item.attributes.loop === undefined ? "none" : Number.isNaN(Number.parseInt(optionsDialog.item.attributes.loop || "")) ? "list" : "number";
  const dispatch = useDispatch();
  const [listItemsDropdown, setListItemsDropdown] = useState([])
  const [listItemValue, setListItemValue] = useState({
    list: listItemValueType === "list" ? optionsDialog.item.attributes.loop : "Choose a list",
    number: listItemValueType === "number" ? optionsDialog.item.attributes.loop : "",
  })
  const [loopType, setLoopType] = useState(listItemValueType)
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useSelector((state) => state.tokenStateReducer);
  const { theData } = useSelector(theState => (theState.surveyInitialDataReducer));
  const params = useParams();

  useEffect(() => {
    const lists = theData.originalData.lists
    const array = []
    lists.map(el => {
      return array.push({ text: el.attributes.id })
    })
    setListItemsDropdown(array)
  }, [theData.originalData.lists])

  const onValueChange = (e, type, option) => {
    if (type === 'string') {
      setOptionValues({ ...optionsDialog.optionValues, [option.id]: e.value })

    } else if (type === 'dropdown') {
      setOptionValues({ ...optionsDialog.optionValues, [option.id]: e.value.name })
    }
    else if (type === 'css') {
      if (e.value.id !== 'none') {
        setOptionValues({ ...optionsDialog.optionValues, [option.id]: e.value.id })
      } else {
        const selectedValues = optionsDialog.optionValues
        if (selectedValues.css) {
          delete selectedValues.css
          setOptionValues(selectedValues)
        }
      }
    } else if (type === 'loop') {
      setListItemValue({ ...listItemValue, [loopType]: e.item.text })
      setOptionValues({ ...optionsDialog.optionValues, [option.id]: e.item.text })
    }
  }

  const showFilterBuilder = (optionId) => {
    getSurveyStructure(`su/projects/${params.name}/surveys/${params.survey}/structure`, token)
      .then(res => {
        dispatch({
          type: 'SURVEY_DISPLAY_FILTER_BUILDER', payload: {
            display: true,
            filterData: res,
            filterBuilderOptionId: optionId,
            combineFilterData: optionsDialog.item.metadata?.filterData ? optionsDialog.item.metadata.filterData : []
          }
        })
      })
  }

  const updateLoopType = (val) => {
    setLoopType(val)
    if (val === "none") {
      setOptionValues({ ...optionsDialog.optionValues, loop: "" })
    }
  }

  const themes = [];
  const selectedElement = optionsDialog.item;
  const isSectionOptionsDialogOpen = true;
  const emailProviders = [];
  const getEmailProviders = () => { };
  const options = returnSurveyOptionsByType(theData, selectedElement, optionsDialog.optionValues, onValueChange, showFilterBuilder, themes, listItemsDropdown, listItemValue, emailProviders, getEmailProviders, isSectionOptionsDialogOpen, loopType, (loopType) => updateLoopType(loopType))

  const onSubmitHandler = (optionValues) => {
    if (loopType === "number") {
      const loopValue = Number.parseInt(optionValues.loop);
      if (Number.isNaN(loopValue)) {
        setErrorMessage("Loop section value must be a number");
      } else if (loopValue === 0) {
        setErrorMessage("Loop section value must be greater than 0");
      } else {
        setErrorMessage("");
        onSaveSectionOptions(optionValues)
      }
    } else {
      onSaveSectionOptions(optionValues)
    }
  }

  const onSaveSectionOptions = (values) => {
    setIsLoading(true);
    const body = theData.originalData;
    body.elements[optionsDialog.id].attributes = values;
    if (optionsDialog.combineFilterData.length !== 0) {
      body.elements[optionsDialog.id].metadata = { ...body.elements[optionsDialog.id].metadata, filterData: optionsDialog.combineFilterData };
    }

    updateSurveyIndex(`su/projects/${params.name}/surveys/${params.survey}/index`, token, body).then((res) => {
      if (res.error) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error } })
        setIsLoading(false);
      } else {
        if (theData.selectedItem) {
          updateElements(theData.selectedItem.id);
        } else {
          updateElements();
        }

        dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Section options have been saved' } })
        setIsLoading(false);
        handleClose();
      }
    });
  };

  return (
    <Dialog className='section-options-dialog' title="Section options" width="600px" height={"600px"} onClose={handleClose}>
      {
        options ?
          <div className="p-4 d-flex flex-column gap-md">
            {options.map(option => option)}
          </div>
          :
          <FullHeightSpinner />
      }
      {
        errorMessage &&
        <div role='alert' className="alert alert-danger mx-4">{errorMessage}</div>
      }
      <DialogActionsBar>
        <button type='button' className="k-button btn-secondary" onClick={handleClose}>Cancel</button>
        <button disabled={isLoading} type='button' className="k-button btn-primary" onClick={() => onSubmitHandler(optionsDialog.optionValues)}>
          {isLoading && <span className="spinner-border spinner-border-sm mr-2" />}
          Save options
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}