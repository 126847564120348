export const getUpdatedAnalysisOptions = (state: any, values: any) => {
  let updatedOptionsWithInitialValue = {}

  for (const [initialKey, initialValue] of Object.entries(state.questionOptions)) {
    for (const [updatedKey, updatedValue] of Object.entries(values)) {
      if (initialKey === updatedKey && initialValue !== updatedValue) {
        const newItem = { [initialKey]: initialValue }
        updatedOptionsWithInitialValue = { ...updatedOptionsWithInitialValue, ...newItem }
      }
    }
  }

  return updatedOptionsWithInitialValue
}