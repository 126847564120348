import type { Type } from "../../../interfaces/iconInterfaces/iconInterfaces";

export const NavRoutes = ["/", "/projects", "/audiences", "/files", "/tasks", "/activity"] as const;
export type NavRoutesType = (typeof NavRoutes)[number];

export const NavRouteTitles = ["Home", "Projects", "Audiences", "File library", "Tasks", "Activity"] as const;
export type NavRouteTitlesType = (typeof NavRouteTitles)[number];

export type NavbarItem = {
  route: NavRoutesType;
  title: NavRouteTitlesType;
  icon: Type
}

export const navbarItems: NavbarItem[] = [
  {
    route: "/",
    title: "Home",
    icon: "nav-home"
  },
  {
    route: "/projects",
    title: "Projects",
    icon: "nav-projects"
  },
  {
    route: "/audiences",
    title: "Audiences",
    icon: "nav-audience"
  },
  {
    route: "/files",
    title: "File library",
    icon: "nav-files"
  },
  {
    route: "/tasks",
    title: "Tasks",
    icon: "nav-tasks"
  },
  {
    route: "/activity",
    title: "Activity",
    icon: "nav-activity"
  }
]