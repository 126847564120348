const initialState = {
  markets: []
};

function gatekeeperStateReducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_MARKETS":
      return {
        ...state,
        markets: action.payload,
      };
    default:
      return state;
  }
}

export default gatekeeperStateReducer;