import React, { useEffect, useState } from 'react';
import { ContainerClient } from '@azure/storage-blob';
import { Stepper } from '@progress/kendo-react-layout';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from '../../../../shared/Icon/Icon';
import RecipientListGrid from './RecipientListGrid/RecipientListGrid';
import UploadRecipientsFile from './UploadRecipientsFile/UploadRecipientsFile';
import { returnEditingRows, returnUpdatedRecipientsRows } from '../helpers/helpers';
import FinishRecipientsImport from './FinishRecipientsImport/FinishRecipientsImport';
import { ImportedRecipientList } from './ImportedRecipientList/ImportedRecipientList';
import { FullHeightSpinner } from '../../../../shared/FullHeightSpinner/FullHeightSpinner';
import { InProgressOverlay } from '../../../../shared/InProgressOverlay/InProgressOverlay';
import {
  fetchGetJson as getSurveyRecipientList,
  fetchPostJson as getUploadItemToken,
  fetchPostJson as createRecipientsTable,
  fetchGetJson as getRecipientsRows,
  fetchPutJson as updateRecipientsRows,
  fetchPost as importRecipientList
} from '../../../../../services/services'

const stepperItems = [{ label: "Upload" }, { label: "Review" }, { label: "Import" }]

export default ({ closeRecipientListEditor, id, token, projectId, surveyId }) => {
  const dispatch = useDispatch()
  const [didMount, setDidMount] = useState(true)
  const [recipientList, setRecipientList] = useState(null)
  const [isLoading, setIsLoading] = useState({ show: false, message: "" })
  const [uploadItem, setUploadItem] = useState(null)
  const [notifyUser, setNotifyUser] = useState(false)
  const [recipientsTable, setRecipientsTable] = useState(null)
  const [stepper, setStepper] = useState(null)
  const [warnings, setWarnings] = useState('')
  const [errorMessage, setErrorMessage] = useState("");
  const [pendingRequest, setPendingRequest] = useState(false);
  const { notification } = useSelector((state) => state.inboxNotificationStateReducer)
  const isProcessingComplete = notification.type === 'RecipientListImport' && notification.status === 'Completed' && notification.completedStatus === 'Success';

  const handleSetWarnings = (warningsArray) => {
    if (warningsArray.length > 1) {
      const last = warningsArray.pop();
      const result = warningsArray.join(', ') + ' and ' + last;
      setWarnings(result);
    } else if (warningsArray.length === 1) {
      setWarnings(warningsArray);
    } else {
      setWarnings(null);
    }
  };

  useEffect(() => {
    if (didMount && !pendingRequest) {
      setPendingRequest(true);
      getSurveyRecipientList(`/projects/${projectId}/surveys/${surveyId}/recipients/${id}`, token)
        .then(res => {
          if (res && !res.error && !res.message) {
            const list = res
            if (res.status === "TableCreated" || res.status === "TableEdited" || res.status === "Imported") {
              getRecipientsRows(`projects/${projectId}/surveys/${surveyId}/recipients/${id}/table/rows/0`, token)
                .then(res => {
                  if (res && !res.error && !res.message) {
                    const rows = res
                    const editingRows = returnEditingRows(res)
                    setRecipientsTable({
                      originalRows: rows,
                      editingRows: editingRows
                    })
                  } else {
                    dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } })
                  }
                })
            } else {
              setRecipientsTable({
                originalRows: null,
                editingRows: null
              })
            }
            setStepper(list.status === "Created" ? 0 : list.status === "TableCreated" ? 1 : list.status === "TableEdited" ? 2 : null)
            setRecipientList(list)
          } else {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } })
          }
          setDidMount(false)
          setPendingRequest(false)
        }
        )
    }
  }, [didMount, pendingRequest])

  useEffect(() => {
    if (isProcessingComplete) {
      setIsLoading({ show: false, message: '' })
      setRecipientsTable(null)
      setDidMount(true)
    }
  }, [isProcessingComplete])

  const onBrowseRecipients = (e) => {
    setUploadItem(e.target.files[0])
  }

  const onUploadRecipients = async () => {
    setIsLoading({ show: true, message: "Uploading your file..." })
    const body = { "fileName": uploadItem.name }
    const uploadToken = await getUploadItemToken(`projects/${projectId}/surveys/${surveyId}/recipients/${id}/token`, token, body)
    if (uploadToken && !uploadToken.message && !uploadToken.error) {
      const promises = []
      const containerClient = new ContainerClient(uploadToken.sasUri);
      const blobName = uploadToken.blob.name
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);
      const blobOptions = {
        blobHTTPHeaders: { blobContentType: uploadToken.blob.contentType }
      };
      promises.push(blockBlobClient.uploadBrowserData(uploadItem, blobOptions));
      const resp = await Promise.all(promises);
      if (resp) {
        const body = {
          "fileName": uploadToken.blob.name,
        }

        createRecipientsTable(`projects/${projectId}/surveys/${surveyId}/recipients/${id}/table`, token, body)
          .then(res => {
            if (res && !res.error && !res.message) {
              handleSetWarnings(res.warnings);
              getRecipientsRows(`projects/${projectId}/surveys/${surveyId}/recipients/${id}/table/rows/0`, token)
                .then(rowsData => {
                  if (rowsData && !rowsData.error && !rowsData.message) {
                    setIsLoading(false)
                    const rows = rowsData
                    const editingRows = returnEditingRows(rowsData)
                    setRecipientsTable({
                      originalRows: rows,
                      editingRows: editingRows
                    })
                    setStepper(1)
                  } else {
                    dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${rowsData.error ? rowsData.error : rowsData.message}` } })
                  }
                })
            } else {
              setIsLoading({ show: false, message: '' })
              setErrorMessage(res.error ? res.error : res.message)
            }
          })
      } else {
        setIsLoading(false)
      }
    } else {
      setIsLoading(false)
    }
  }

  const updateRowsData = (rows, deletedRows) => {
    if (deletedRows && deletedRows.length) {
      return rows.map(row => deletedRows.find(deletedRow => deletedRow === row.i) ? { ...row, isDeleted: true } : { ...row, isDeleted: false })
    } else {
      return rows;
    }
  }

  const onNextStep = (data, deletedRows) => {
    const body = returnUpdatedRecipientsRows(recipientsTable.originalRows, data)
    const rows = updateRowsData(body.rows, deletedRows);
    updateRecipientsRows(`projects/${projectId}/surveys/${surveyId}/recipients/${id}/table/rows`, token, rows)
      .then(res => {
        if (res && res.error) {
          setIsLoading({ show: false, message: "" })
          dispatch({ type: 'UPDATE_ERROR_MESSAGE', payload: { msg: res.message ? res.message : res.error, type: 'modal' } })
        } else if (res && res.errors.length === 0) {
          const editingRows = returnEditingRows(body)
          setRecipientsTable({ originalRows: body, editingRows: editingRows })
          setStepper(2)
          setIsLoading({ show: false, message: "" })
        }
      })
  }

  const updateData = (data, deletedRows) => {
    const body = returnUpdatedRecipientsRows(recipientsTable.originalRows, data)
    const rows = updateRowsData(body.rows, deletedRows);
    updateRecipientsRows(`projects/${projectId}/surveys/${surveyId}/recipients/${id}/table/rows`, token, rows)
      .then(res => {
        if (res && res.error) {
          setIsLoading({ show: false, message: "" })
          dispatch({ type: 'UPDATE_ERROR_MESSAGE', payload: { msg: res.message ? res.message : res.error, type: 'modal' } })
        } else if (res && res.errors.length === 0) {
          const editingRows = returnEditingRows(body)
          setRecipientsTable({ originalRows: body, editingRows: editingRows })
          handleSetWarnings(res.warnings);
          setIsLoading({ show: false, message: "" })
        }
      })
  }

  const onImportRecipients = () => {
    let body = {}
    if (notifyUser) {
      body = { "notify": true }
    }
    importRecipientList(`projects/${projectId}/surveys/${surveyId}/recipients/${id}/import`, token, body)
      .then(res => {
        if (res && res.error) {
          setIsLoading({ show: false, message: '' })
          dispatch({ type: 'UPDATE_ERROR_MESSAGE', payload: { msg: res.message ? res.message : res.error, type: 'modal' } })
        }
        else if (res && res.status === 202) {
          setIsLoading({ show: false, message: '' })
          setRecipientsTable(null)
          setDidMount(true)
        }
      })
  }

  return (<React.Fragment>
    {didMount ?
      <FullHeightSpinner />
      :
      <div className="d-flex flex-column h-100 overflow-hidden mx-width survey-main">
        <div className="d-flex bg-white align-items-center h-56 px-4 py-3 justify-content-between">
          <div className="d-flex align-items-center">
            <button className='btn btn-icon btn-transparent px-1' onClick={closeRecipientListEditor} >
              <Icon type="chevron-left" />
            </button>
            <h5 className='mb-0 lh-24 ml-1 strong'>{recipientList.name}</h5>
          </div>
        </div>
        <div className="overflow-hidden campaign-content bg-white">
          <div className="h-100">
            {recipientsTable === null ?
              <div className="d-flex w-100 p-3">
                <FullHeightSpinner />
              </div> :
              <div className="d-flex campaign-content p-0 overflow-auto h-100">
                <div className="d-flex flex-column h-100 w-100">
                  {isLoading.show &&
                    <InProgressOverlay type="overlay" theme="primary" message={isLoading.message} />
                  }{stepper !== null &&
                    <Stepper items={stepperItems} value={stepper} className="margin-auto" />
                  }
                  {stepper !== null && stepper === 0 ?
                    <UploadRecipientsFile onBrowseRecipients={onBrowseRecipients} onUploadRecipients={onUploadRecipients} uploadItem={uploadItem} onSetErrorMessage={() => setErrorMessage("")} errorMessage={errorMessage} />
                    : stepper !== null && stepper === 1 ?
                      <div className="d-flex flex-column h-100 position-relative">
                        <RecipientListGrid data={recipientsTable.editingRows}
                          onNextStep={(data, deletedRows) => { setIsLoading({ show: true, message: "Uploading recipients..." }); onNextStep(data, deletedRows) }}
                          onCancel={() => setRecipientsTable({ originalRows: null, editingRows: null })}
                          status={recipientList.status}
                          columnTypes={recipientsTable.originalRows.columnTypes}
                          warnings={warnings}
                          updateData={updateData}
                        />
                      </div>
                      : stepper !== null && stepper === 2 ?
                        <div className="d-flex flex-column h-100">
                          <FinishRecipientsImport
                            notifyUser={notifyUser}
                            recipientsTable={recipientsTable}
                            onSetNotifyUser={(value) => setNotifyUser(value)}
                            onStepBack={() => setStepper(1)}
                            onImport={() => { setIsLoading({ show: true, message: "Importing recipients..." }); onImportRecipients() }}
                          />
                        </div>
                        : stepper === null && recipientList.status === "Imported" ?
                          <div className="d-flex flex-column h-100">
                            <ImportedRecipientList
                              id={id}
                              token={token}
                              projectId={projectId}
                              surveyId={surveyId}
                              recipientListName={recipientList.name}
                            />
                          </div>
                          : stepper === null && recipientList.status === "Expired" ?
                            <div className="d-flex flex-column h-100">
                              <div className="d-flex flex-column h-100 align-items-center justify-content-center">
                                <i className="fa fa-exclamation-circle fa-4x mb-3 text-disabled" />
                                <h4 className="text-disabled mb-3">Expired</h4>
                                <h6 className="text-expired">This recipient list has expired.</h6>
                              </div>
                            </div>
                            : stepper === null && recipientList.status === "Processing" ?
                              <div className="d-flex flex-column h-100">
                                <div className="d-flex flex-column h-100 align-items-center justify-content-center">
                                  <span className="spinner-border mb-3 text-info"></span>
                                  <h4 className="text-info mb-3">Import processing</h4>
                                  <h6>Processing import for {recipientList.count - recipientList.deletedCount} recipient{(recipientList.count - recipientList.deletedCount) !== 1 ? 's' : ''}</h6>
                                </div>
                              </div>
                              : null
                  }
                </div>
                <div>
                </div>
              </div>}
          </div>
        </div>
      </div>
    }
  </React.Fragment >
  )
}