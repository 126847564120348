export const returnSessionStateType = (selectedTab: number) => {
  let stateType = ''

  if (selectedTab === 0) {
    stateType = 'toplineState'
  } else if (selectedTab === 1) {
    stateType = 'xtState'
  } else if (selectedTab === 2) {
    stateType = 'chartState'
  } else if (selectedTab === 3) {
    stateType = 'digState'
  } else if (selectedTab === 4) {
    stateType = 'qTableState'
  } else if (selectedTab === 5) {
    stateType = 'dataState'
  } else if (selectedTab === 6) {
    stateType = 'reportState'
  }

  return stateType
}