import findCommonTextInTitles from "../findCommonTextInTitles/findCommonTextInTitles"

export default (data, checkedQuestions, initialData) => {
  let updatedData = JSON.parse(JSON.stringify(data));
  const firstElIndex = updatedData.findIndex(el => el.id === checkedQuestions[0].id)
  const firstInitialElIndex = initialData.findIndex(el => el.id === checkedQuestions[0].id)
  const updatedTitles = findCommonTextInTitles(checkedQuestions, 'numericList')
  const updatedRowTitles = updatedTitles.updatedRowTitles
  const updatedTitle = updatedTitles.updatedTitle
  const mergedNumericListQuestion = {
    id: `${checkedQuestions[0].id}.1`,
    title: updatedTitle.length < 60 ? updatedTitle : `${updatedTitle.substring(0, 60)}...`,
    values: {
      value: updatedRowTitles.map((el, id) => {
        return {
          "@code": id + 1,
          text: el
        }
      })
    },
    selected: false,
    checked: false,
    merged: true,
    type: 'numericList',
    order: checkedQuestions[0].order ? checkedQuestions[0].order : '0',
    mergedQuestions: checkedQuestions
  }
  updatedData.splice(firstElIndex, 0, mergedNumericListQuestion);
  checkedQuestions.forEach(el => {
    updatedData = updatedData.filter(val => JSON.stringify(val) !== JSON.stringify(el))
  })
  initialData.splice(firstInitialElIndex, 0, mergedNumericListQuestion);
  checkedQuestions.forEach(el => {
    initialData = initialData.filter(val => JSON.stringify(val) !== JSON.stringify(el))
  })

  return {
    data: updatedData,
    initialData: initialData,
    element: mergedNumericListQuestion
  }
}