import React from 'react';
import { RadioButton } from '@progress/kendo-react-inputs';
import { filterBy } from "@progress/kendo-data-query";

import { BaseMultiSelect } from '../../../shared/Inputs';

const radioButtons = [
  { id: 1, name: 'Private', message: 'Only you can see the project' },
  { id: 2, name: 'Organisation', message: 'Everyone in your organisation can see the project' },
  { id: 3, name: 'Custom', message: 'Specify users and/or groups that can see the project' }
]

export const AddProjectAccessControl = ({ userData, formData, onChangeHandler, list, setList, isLoadingAccessData }) => {
  const filterChange = (event) => {
    setList(filterBy(list.slice(), event.filter))
  }

  return (
    <div className="d-flex flex-column gap-lg px-4 pt-4 create-project-second-step">
      {
        radioButtons.map((radioButton) => (
          <div key={radioButton.id} className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <RadioButton
                className="p-2 mr-2"
                value={radioButton.id}
                label={radioButton.name}
                checked={formData.radio === radioButton.id}
                onChange={(e) => onChangeHandler(e, 'radio')} />
            </div>
            <small className="text-muted text-right m-0 pl-5">{radioButton.message}</small>
          </div>
        ))
      }
      {
        formData.radio === radioButtons.find(el => el.name === 'Custom').id && userData.role === 'regular' &&
        <div className="alert alert-warning mb-3" role="alert">
          Standard users can only specify groups that can see the project
        </div>
      }
      <BaseMultiSelect
        disabled={formData.radio !== radioButtons.find(el => el.name === 'Custom').id ? true : !!isLoadingAccessData}
        data={list?.filter(item => item.name)}
        value={!isLoadingAccessData ? formData.accessControl.list : []}
        textField="name"
        dataItemKey="id"
        loading={isLoadingAccessData}
        onChange={(e) => onChangeHandler(e, 'list')}
        filterable={true}
        onFilterChange={filterChange}
      />
    </div>
  )
}