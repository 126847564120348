export const returnAnalysisActionsItems = (undoQuestionOptions: TODO, redoQuestionOptions: TODO, filterItems: boolean, datasetType: string, isCopyXmlDisabled: boolean, checkedNum: TODO[], copiedElements: TODO[] | null) => {
  let items = [
    {
      "actionName": "Undo option changes",
      "action": "undo",
      "iconClass": "fas fa-undo",
      "disabled": undoQuestionOptions.length === 0
    },
    {
      "actionName": "Redo option changes",
      "action": "redo",
      "iconClass": "fas fa-redo",
      "disabled": redoQuestionOptions.length === 0
    },
    {
      "actionName": "Enable selected questions",
      "action": "enable",
      "iconClass": "fas fa-toggle-on"
    },
    {
      "actionName": "Disable selected questions",
      "action": "disable",
      "iconClass": "fas fa-toggle-off"
    },
    {
      "actionName": "Select All Rows",
      "action": "select rows",
      "iconClass": "fas fa-check-square"
    },
    {
      "actionName": "Deselect All Rows",
      "action": "deselect rows",
      "iconClass": "fas fa-times-square"
    },
    {
      "actionName": "Select All Columns",
      "action": "select columns",
      "iconClass": "fas fa-check-square"
    },
    {
      "actionName": "Deselect All Columns",
      "action": "deselect columns",
      "iconClass": "fas fa-times-square"
    },
    {
      "actionName": "Copy XML",
      "action": "copy xml",
      "iconClass": "fas fa-copy",
      "disabled": isCopyXmlDisabled
    },
    {
      "actionName": "Weighting",
      "action": "weight",
      "iconClass": "fas fa-weight-hanging",
      "disabled": datasetType === "surveys"
    },
    {
      "actionName": "Copy rows/columns",
      "action": "copy-row-column",
      "iconClass": "far fa-copy",
      "disabled": checkedNum?.length < 1
    },
    {
      "actionName": "Paste rows/columns",
      "action": "paste-row-column",
      "iconClass": "far fa-paste",
      "disabled": !(copiedElements && copiedElements?.length > 0)
    }
  ]

  if (filterItems) {
    items = items.filter(e => !e.actionName.includes("Columns"))
  }

  return items
}