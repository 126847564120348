import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { ErrorMessage } from '../../../../../../../shared/ErrorMessage/ErrorMessage';
import { fetchPutResOrJson } from '../../../../../../../../services/services';

export default function AddNewThemeModal({ handleClose, themes, id, setThemes }) {
  const { token } = useSelector((state) => state.tokenStateReducer);
  const [isUpdating, setIsUpdating] = useState(false)
  const [error, setError] = useState({ show: false, message: '' })
  const [themeInfo, setThemeInfo] = useState({
    name: themes.data.find(el => el.id === id).name,
    descr: themes.data.find(el => el.id === id).description,
    baseTheme: themes.data[0].id
  })
  const { theData } = useSelector(theState => (theState.surveyInitialDataReducer));

  const onUpdateThemeInfo = (themeInfo) => {
    setIsUpdating(true)
    fetchPutResOrJson(theData.dcV2 === 2 ? `su/themes/${id}` : `themes/${id}`, token, { name: themeInfo.name, })
      .then(res => {
        setIsUpdating(false)
        if (res.error || res.message) {
          setError({ show: true, message: res.error ? res.error : res.message })
        } else {
          setThemes({ ...themes, didMount: false })
          handleClose()
        }
      })
  }

  return (
    <Dialog title="Rename theme" width={500} onClose={handleClose}>
      <div className="d-flex flex-column p-3">
        <label>Name</label>
        <input
          required
          className="form-control"
          placeholder="Enter theme name"
          value={themeInfo.name}
          onChange={e => setThemeInfo({ ...themeInfo, name: e.target.value })}
        />
        {
          error.show &&
          <div className="mt-3">
            <ErrorMessage type="alert" errorMessage={error.message} />
          </div>
        }
      </div>
      <DialogActionsBar>
        <button type='button' className="k-button btn btn-secondary" onClick={handleClose}>Cancel</button>
        <button
          type='button'
          className="k-button btn btn-primary"
          onClick={() => onUpdateThemeInfo(themeInfo)}
          disabled={themeInfo.name === '' || isUpdating}>
          {
            isUpdating &&
            <span className="spinner-border spinner-border-sm mr-2" />
          }
          <span>Update</span>
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}