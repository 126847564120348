import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { SideBar } from '../../../shared/SideBar/SideBar';
import { formatDate } from '../../../shared/DateUtils/DateUtils';
import type { RootState } from '../../../../store/reducers/rootReducer';
import fileManagerDownloadHandler from '../../../shared/helpers/fileManagerDownloadHandler/fileManagerDownloadHandler';
import { fetchGetJson, fetchGetBlob } from '../../../../services/services';

interface Props {
  versions: Version[]
  setShowVersions: (data: boolean) => void
}

interface Version {
  id: string;
  comment: string
  createdUtc: string
  createdByName: string
}

export const SurveyVersionHistorySidebar = ({ setShowVersions, versions }: Props) => {
  const params: { name: string, survey: string } = useParams()
  const dispatch = useDispatch()
  const [didMount, setDidMount] = useState(false)
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { theData } = useSelector((theState: RootState) => (theState.surveyInitialDataReducer))
  const { data } = useSelector((theState: RootState) => (theState.breadcrumbStateReducer));

  useEffect(() => {
    if (theData.surveyStatus === null && !didMount) {
      setDidMount(true)
      fetchGetJson(`su/projects/${params.name}/surveys/${params.survey}`, token)
        .then((res: TODO) => {
          if (res && !res.error) {
            dispatch({
              type: 'SET_SURVEY_PUBLISH_DATA', payload: {
                surveyStatus: res.surveyStatus,
                versions: res.publishedVersions === null ? [] : res.publishedVersions
              }
            })
          }
        })
    }
  }, [theData.surveyStatus, token, dispatch, didMount, params])

  const downloadPreviousVersion = (versionId: string, index: number) => {
    fetchGetBlob(`su/projects/${params.name}/surveys/${params.survey}/versions/${versionId}`, token)
      .then((res: TODO) => {
        if (res && !res.error) {
          fileManagerDownloadHandler(res, { displayName: `${data.documentName}_v${index + 1}.xml` })
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } })
        }
      })
  }

  return (
    <SideBar onHide={() => setShowVersions(false)} header={{ show: true, title: "Version history" }} width={'400px'}>
      <div style={{ height: 'calc(100vh - 56px)' }} className="d-flex flex-column overflow-auto p-5">
        {
          versions?.length > 0 ?
            versions?.reverse().map((version: Version, index) => (
              <div key={version.id} className="d-flex flex-column pb-3 version-history">
                <div className="d-flex align-items-center justify-content-between text-muted pl-4 h-24">
                  <div>
                    <span className='small text-primary strong'>{version.createdByName}</span>
                    <span className='mx-1'>·</span>
                    <span className='small'>{formatDate(version.createdUtc, "DATETIME_MED")}</span>
                  </div>

                  <button type='button' className="btn btn-icon btn-transparent px-1" title="Download version" onClick={() => downloadPreviousVersion(version.id, index)}>
                    <i className="far fa-cloud-download pe-none fa-lg" />
                  </button>
                </div>
                {
                  version.comment &&
                  <span className="bg-white comment medium py-2 pr-2 pl-3 ml-4 mt-1">{version.comment}</span>
                }
              </div>
            ))
            :
            <div className="d-flex flex-grow-1 justify-content-center align-items-center">
              <p className="m-0 text-disabled">No previous versions were found</p>
            </div>
        }
      </div>
    </SideBar >
  )
}