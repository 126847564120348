import type { Tag } from '../TagsTabContent';
import { Avatar } from '../../../../shared/Avatar/Avatar';
import { formatDate } from '../../../../shared/DateUtils/DateUtils';

interface Props {
  tagDefinition: Tag
  setShowEditTag: (data: { tag: Tag, show: boolean }) => void
  setShowDeleteTag: (data: { tag: Tag, show: boolean }) => void
}

export const TagDefinitionCard = ({ tagDefinition, setShowEditTag, setShowDeleteTag }: Props) => {
  return (
    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
      <div style={{ cursor: 'default' }} className="h-100 project-card card border-0">
        <div className="d-flex flex-column justify-content-between h-100">
          <div className="d-flex flex-column justify-content-between p-3 gap-md h-100">
            <div className="d-flex justify-content-between align-items-center gap-md">
              <div className="d-flex flex-column text-truncate">
                <p className="m-0 text-dark strong h6 text-truncate">{tagDefinition.name}</p>
              </div>
              <Avatar
                color={"light"}
                rounded="large"
                class="avatar-sm m-0"
                icon={'far fa-tags text-primary'} />
            </div>
            <div className="d-flex align-items-center text-muted small gap-sm">
              <span>{tagDefinition.createdByName}</span>
              <span>-</span>
              <span>{formatDate(tagDefinition.createdUtc, "DATE_MED")}</span>
            </div>
          </div>

          <div className="project-card-buttons d-flex border-top">
            <button onClick={() => setShowEditTag({ tag: tagDefinition, show: true })} type="button" className="btn btn-block text-dark border-right">
              <i className="far fa-edit pr-2" />
              <span>Edit</span>
            </button>
            <button onClick={() => setShowDeleteTag({ tag: tagDefinition, show: true })} type="button" className="btn btn-block text-dark m-0">
              <i className="far fa-trash-alt pr-2" />
              <span>Delete</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}