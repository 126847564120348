import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { Form } from '@progress/kendo-react-form';
import { Stepper } from '@progress/kendo-react-layout';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { AddProjectContent } from './AddProjectContent/AddProjectContent';
import { ErrorMessage } from '../../shared/ErrorMessage/ErrorMessage';
import ProjectActionButtons from '../../shared/ProjectActionButtons/ProjectActionButtons';
import returnOnChangeProjectData from '../../shared/helpers/returnOnChangeProjectData/returnOnChangeProjectData';
import FileManager from '../../shared/FileManager/FileManager';
import { fetchPostJson as addProject, fetchGetJson as getAllGroups, fetchGetJsonOrEmptyArray as getTags, rsAppUrl } from '../../../services/services';

const items = [{ label: 'General settings' }, { label: 'Access control' }, { label: 'Branding' }];

export default function AddProjectModal({ token, onHide, userData }) {
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ projectName: '', description: '', iconUrl: '', tags: [], radio: 1, accessControl: { list: [] } })
  const [errorMessage, setErrorMessage] = useState(null)
  const [showFileManager, setShowFileManager] = useState(false)
  const [stepperValue, setStepperValue] = useState(0);
  const [tags, setTags] = useState([])
  const [list, setList] = useState([])
  const history = useHistory()

  const handleChange = (e) => {
    setStepperValue(e.value);
  };

  useEffect(() => {
    const updatedList = [...list]
    getTags("tags", token)
      .then(res => {
        if (res && !res.error && !res.message) {
          setTags(res)
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `Tags: ${res.message ? res.message : res.error}` } })
        }
      })
    getAllGroups("groups", token)
      .then(groups => {
        if (groups && !groups.error && !groups.message) {
          groups.forEach(group => { updatedList.push({ name: group.name, id: group.id, type: 'group' }) })
          getAllGroups("users", token)
            .then(users => {
              if (users && !users.error && !users.message) {
                users.forEach(userInfo => {
                  user[`${rsAppUrl}/userid`] !== userInfo.id && userInfo.email && userInfo.id &&
                    updatedList.push({ name: userInfo.email, id: userInfo.id, type: 'user' })
                })
                setList(updatedList)
              } else {
                setList(updatedList)
                dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `Users: ${users.message ? users.message : users.error}` } })
              }
            })
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `Groups: ${groups.message ? groups.message : groups.error}` } })
        }
      })
  }, [])

  const onChangeHandler = (e, name) => {
    const updatedFormData = returnOnChangeProjectData(name, formData, e)
    setFormData(updatedFormData)
  }

  const onSubmitHandler = (e) => {
    e.preventDefault()
    const body = {
      name: formData.projectName,
      description: formData.description,
      iconUrl: formData.iconUrl ? formData.iconUrl : '',
      tags: formData.tags,
      iconBackgroundColor: formData.iconBackgroundColor,
      accessControl: {
        accessType: formData.radio,
        userIdAccessList: formData.accessControl.list.filter(el => el.type === 'user').map(user => user.id),
        groupIdAccessList: formData.accessControl.list.filter(el => el.type === 'group').map(group => group.id)
      }
    }
    addProject("projects", token, body)
      .then(res => {
        if (res && (res.message || res.error)) {
          setErrorMessage(res.message ? res.message : res.error)
        } else if (Object.entries(res).length !== 0) {
          dispatch({ type: 'UPDATE_PROJECT_BREADCRUMB', payload: { id: res.id, name: res.name } })
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The project has been created successfully' } })
          onHide()
          history.push(`/projects/${res.id}`)
        }
      })
  }

  return (
    <Dialog width={650} height={450} className="import-dialog" title="Create project" onClose={onHide}>
      {
        showFileManager &&
        <FileManager
          displayType="modal"
          type={'public'}
          onSelect={(value) => setFormData({ ...formData, iconUrl: value })} onHide={() => setShowFileManager(false)}
          token={token}
        />
      }

      <Form
        onSubmit={onSubmitHandler}
        render={(formRenderProps) => (
          <React.Fragment>
            <div className="px-4 py-4">
              <div className="pb-3">
                <Stepper value={stepperValue} onChange={handleChange} items={items} />
              </div>

              <AddProjectContent
                userData={userData}
                stepperValue={stepperValue}
                tags={tags}
                list={list}
                formData={formData}
                onChangeHandler={onChangeHandler}
                setShowFileManager={() => setShowFileManager(true)} />

              {
                errorMessage &&
                <ErrorMessage
                  type="alert"
                  errorMessage={errorMessage} />
              }
            </div>
          </React.Fragment>
        )}>
      </Form>
      <DialogActionsBar>
        <ProjectActionButtons
          submitAction={'Create'}
          onHide={onHide}
          stepperValue={stepperValue}
          steps={items.length}
          handleChange={handleChange}
          onSubmitHandler={onSubmitHandler} />
      </DialogActionsBar>
    </Dialog>
  )
}