export default (selectedGroup, user, groups) => {
  let disableGroup = false
  if (selectedGroup) {
    let groupPlan = groups.find(el => el.text === selectedGroup).plan
    if (user.isSubAccount) {
      disableGroup = false
    }
    else if (user.plan === 'basic') {
      if (groupPlan !== user.plan) {
        disableGroup = true
      }
      else {
        disableGroup = false
      }
    }
    else if (user.plan === 'essential_yearly' || user.plan === 'essential_monthly') {
      if (groupPlan === 'essential' || groupPlan === 'basic') {
        disableGroup = false
      }
      else {
        disableGroup = true
      }
    }
    else if (user.plan === 'professional_monthly' || user.plan === 'professional_yearly') {
      if (groupPlan === 'enterprise') {
        disableGroup = true
      }
      else {
        disableGroup = false
      }
    }
    else {
      disableGroup = false
    }
  }
  return (
    disableGroup
  )
}