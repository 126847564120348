import returnProjectOnChangeHandlerValues from "../returnProjectOnChangeHandlerValues/returnProjectOnChangeHandlerValues";

export default (name, formData, e) => {
  let updatedFormData = { ...formData };

  if (name === 'tags') {
    const values = returnProjectOnChangeHandlerValues(e)
    updatedFormData = {
      ...updatedFormData,
      [name]: values
    }
  } else if (name === 'list') {
    updatedFormData = {
      ...updatedFormData,
      accessControl: {
        ...updatedFormData.accessControl,
        list: e.value
      }
    }
  } else {
    updatedFormData = {
      ...updatedFormData,
      [name]: e.value
    }
  }

  return updatedFormData
}