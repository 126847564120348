import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "@progress/kendo-react-tooltip";

import type { RootState } from "../../../../store/reducers/rootReducer";
import { formatDate } from "../../../shared/DateUtils/DateUtils";
import { Avatar } from "../../../shared/Avatar/Avatar";
import { returnTableIcon } from "../../../shared/helpers/project/returnTableIcon/returnTableIcon";
import type { ProjectDetailsObject } from "../../../../interfaces/projectDetailsInterfaces/projectDetailsInterfaces";
import type { Action } from "../ProjectsTypes/projectTypes";
import { Icon } from "../../../shared/Icon/Icon";
import { ProjectsCardsSkeleton } from "./ProjectsCardsSkeleton";

type Props = {
  isLoading: boolean;
  onProjectActionHandler: (type: Action, projectId: string, projectName: string) => void;
}

export default function ProjectsCards({ isLoading, onProjectActionHandler }: Props) {
  const { newProjects } = useSelector((state: RootState) => state.projectsReducer);
  const dispatch = useDispatch();

  const onNavigateProject = () => {
    dispatch({
      type: "SHOULD_FETCH_PROJECTS",
      payload: false,
    });
  }
  return (
    <Fragment>
      {
        isLoading && <ProjectsCardsSkeleton />
      }

      {!isLoading && newProjects.length > 0 && newProjects.map((project: ProjectDetailsObject) =>
        <div key={project.id} className="project-card-wrapper position-relative col-xl-3 col-lg-4 col-md-5 col-sm-7 col-xs-12 mb-5">
          <div className="project-card-actions">
            <div className='d-flex gap-sm'>
              <Tooltip position='top' anchorElement={'target'}>
                <button
                  title="Edit"
                  className='btn btn-transparent p-0'
                  type='button'
                  onClick={() => {
                    onProjectActionHandler("edit", project.id, project.name)
                  }}
                >
                  <Icon type='edit' className='pe-none' />
                </button>
              </Tooltip>

              <Tooltip position='top' anchorElement={'target'}>
                <button title="Delete"
                  className='btn btn-transparent p-0'
                  type='button'
                  onClick={() => {
                    onProjectActionHandler("delete", project.id, project.name)
                  }}
                >
                  <Icon type='delete-alt' className='pe-none' />
                </button>
              </Tooltip>
            </div>
          </div>

          <Link
            to={`projects/${project.id}`} onClick={onNavigateProject}
            className={`h-100 project-card card border-0 ${project.isShared ? project.sharedProjectInformation ? "project-shared-information" : "project-shared" : ""}`}
            title={project.name}
          >
            <div className="d-flex flex-column justify-content-around py-4 px-3 gap-md">
              <div className="d-flex gap-lg align-items-center h-64">
                <Avatar
                  backgroundColor={project.iconBackgroundColor}
                  image={project.iconUrl}
                  class="m-0"
                  size="medium"
                  rounded="small"
                  iconColor="text-dark"
                  icon={
                    project.iconUrl
                      ? null
                      : returnTableIcon(
                        project.isShared ? "sharedProject" : "projects"
                      )}
                  color={
                    project.sharedProjectInformation
                      ? "success" :
                      project.iconBackgroundColor ? '' : "secondary"
                  }
                />

                <p className="m-0 text-dark line-clamp-2 px-1" style={{ fontSize: "1rem" }}>{project.name}</p>
              </div>

              <div className="d-flex text-muted gap-md">
                <p className="m-0 project-card-name truncate-text">{project.createdByName}</p>
                <span>-</span>
                <p className="m-0 project-card-name truncate-text">
                  {formatDate(project.createdUtc, "DATETIME_MED")}
                </p>
              </div>
            </div>
          </Link>
        </div>
      )}
    </Fragment>
  )
}
