import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { Icon } from '../Icon/Icon';

type Props = {
  type: "modal" | "alert";
  onHide?: () => void;
  errorMessage: string;
}

export const ErrorMessage = ({ type, onHide, errorMessage }: Props) => {
  const longErrorMessage = errorMessage?.length > 160;

  if (type === 'modal') {
    return (
      <Dialog
        minWidth={500}
        title={' '}
        onClose={onHide}
        className={`import-dialog error-dialog ${longErrorMessage === true ? 'shorter-dialog' : null}`}>
        <div className="d-flex flex-column h-100 error-container">
          <Icon className='menu-icon' type="walr" />
          <p className="error-title">Something went wrong</p>
          <p className="error-message">
            <span>Error message: {errorMessage}</span>
          </p>
        </div>
        <DialogActionsBar>
          <button type='button' className="k-button btn btn-secondary" onClick={onHide && onHide}>
            Dismiss
          </button>
          <button type='button' className="k-button btn btn-primary" onClick={() => window.location.reload()}>
            Reload
          </button>
        </DialogActionsBar>
      </Dialog>
    );
  }
  if (type === 'alert') {
    return (
      <div className="d-flex align-items-center alert alert-danger alert-dismissible fade show" role="alert">
        <p className="m-0 w-100">{errorMessage}</p>
        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={onHide && onHide}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
  return null;
};