import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Icon } from '../../../../../shared/Icon/Icon';
import { ErrorMessage } from '../../../../../shared/ErrorMessage/ErrorMessage';
import { FullHeightSpinner } from '../../../../../shared/FullHeightSpinner/FullHeightSpinner';
import { ThemesSkeleton } from './Themes/ThemeSkeleton/ThemesSkeleton';
import FileManager from '../../../../../shared/FileManager/FileManager';
import Themes from './Themes/Themes';
import { PreviewTabContent } from '../../SurveyDesignTabs/PreviewTabContent/PreviewTabContent';
import { fetchGetJson as getSurveyOptions, fetchPutResOrJson as updateSurveyIndex, fetchGetJson as getSurveyThemes } from '../../../../../../services/services';

export const SurveyThemesTool = ({ setQuestionSelected, updateElements }) => {
  const { token } = useSelector((state) => state.tokenStateReducer);
  const { theData, themeId } = useSelector(theState => (theState.surveyInitialDataReducer))
  const [selectedTab, setSelectedTab] = useState(themeId ? 'survey' : 'gallery')
  const [settings, setSettings] = useState(null)
  const [settingsValues, setSettingsValues] = useState(null)
  const [previewItems, setPreviewItems] = useState([])
  const [didMount, setDidMount] = useState(true)
  const [showFileManager, setShowFileManager] = useState({ show: false, setting: null })
  const [errorMessage, setErrorMessage] = useState(null)
  const [shouldUpdatePreview, setShouldUpdatePreview] = useState(true)
  const [themes, setThemes] = useState({
    didMount: false,
    data: [],
    searchData: null,
    selected: null,
  });

  useEffect(() => {
    if (!themes.didMount) {
      setThemes({ ...themes, didMount: true })
      getSurveyThemes(theData.dcV2 === 2 ? 'su/themes' : 'themes', token)
        .then(res => {
          if (res?.error) {
            setErrorMessage(res.message ? res.message : res.error)
          } else {
            setThemes({ ...themes, data: res, didMount: true })
          }
        })
    }
  }, [themes, token, theData.dcV2])

  useEffect(() => {
    if (didMount) {
      setDidMount(false)
      getSurveyOptions(theData.dcV2 === 2 ? 'su/resources/survey-options' : 'resources/survey-options', token)
        .then(res => {
          if (res?.error) {
            setErrorMessage(res.message ? res.message : res.error)
          } else {
            if (res.displaySettings && res.themeSettings) {
              setSettings({ displaySettings: res.displaySettings, themeSettings: res.themeSettings })
            }
          }
        })

      let previewItems = []
      theData.data.forEach(el => previewItems = [...previewItems, ...el.elements.filter(el => el.type === 'ques')]);
      setPreviewItems(previewItems);

      if (settingsValues === null) {
        setSettingsValues({ displaySettings: theData.originalData.displaySettings, themeSettings: theData.originalData.themeSettings })
      }
    }
  }, [didMount, theData, token, settingsValues])

  const onUpdateSettings = (id) => {
    let body = {}
    if (id) {
      body = { ...theData.originalData, themeSettings: { id: id } }
    } else {
      body = { ...theData.originalData, displaySettings: settingsValues.displaySettings }
    }
    updateSurveyIndex(`su/projects/${theData.originalData.projectId}/surveys/${theData.originalData.surveyId}/index`, token, body)
      .then(res => {
        if (res?.error) {
          setErrorMessage(res.message ? res.message : res.error)
        } else if (res.status === 200) {
          updateElements()
          setPreviewItems([])
          setDidMount(true)
        }
      })
  }

  return (
    <div className="row no-gutters flex-grow-1 h-100 survey-main overflow-hidden">
      {
        showFileManager.show &&
        <FileManager
          displayType="modal"
          type={'public'}
          onSelect={(value) => setSettingsValues({ ...settingsValues, displaySettings: { ...settingsValues.displaySettings, [showFileManager.setting]: value } })}
          onHide={() => setShowFileManager({ show: false, setting: null })}
          token={token}
        />
      }
      {
        errorMessage &&
        <ErrorMessage
          type="modal"
          errorMessage={errorMessage}
          onHide={() => setErrorMessage(null)}
        />
      }
      <div className="col-lg-3 d-flex flex-column border-right survey-content bg-white">
        {settings === null || themes.data?.length === 0 ?
          <ThemesSkeleton /> :
          <Themes
            token={token}
            themes={themes}
            setThemes={setThemes}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            settings={settings.themeSettings}
            onUpdateSettings={onUpdateSettings}
            fonts={theData.supportedFonts}
            dropdownValues={theData.dropdownValues}
            indexThemeSettings={settingsValues.themeSettings}
            setShouldUpdatePreview={setShouldUpdatePreview}
          />
        }
      </div>
      {
        previewItems.length !== 0 ?
          <div className="col-lg-9 survey-content bg-white d-flex flex-column themes-preview">
            <PreviewTabContent
              token={token}
              type='themes'
              themes={themes}
              selectedTab={selectedTab}
              previewItems={previewItems}
              shouldUpdatePreview={shouldUpdatePreview}
              setShouldUpdatePreview={setShouldUpdatePreview}
              setQuestionSelected={setQuestionSelected}
            />
          </div>
          :
          <div className="col-md-12 col-lg-9 p-4" style={{ backgroundColor: "rgb(243, 244, 244)" }}>
            <div className="card-body d-flex flex-column h-100 bg-white align-items-center justify-content-center answer-layout">
              <Icon type="themes" className='pe-none fill-primary select-placeholder' />
              <h1 className="droppable-text strong mt-2 h4">Select an element to preview themes</h1>
            </div>
          </div>
      }
    </div >
  )
}