import { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';

import { Icon } from '../../shared/Icon/Icon';
import { SideBar } from '../../shared/SideBar/SideBar';
import { InboxDrawer } from '../../shared/InboxDrawer/InboxDrawer';
import type { RootState } from '../../../store/reducers/rootReducer';
import ErrorModal from '../SurveyTabContent/SurveyPublishTabContent/ErrorModal/ErrorModal';
import PublishModal from '../SurveyTabContent/SurveyPublishTabContent/PublishModal/PublishModal';
import { SurveyVersionHistorySidebar } from './SurveyVersionHistorySidebar/SurveyVersionHistorySidebar';
import SubmitCloseModal from '../SurveyTabContent/SurveyPublishTabContent/SubmitCloseModal/SubmitCloseModal';
import { returnSelectedElementData, returnSurveyDesignData, returnValidationErrors } from '../SurveyTabContent/SurveyDesignTabContent/helpers/returnDataHelpers/returnDataHelpers';
import { fetchGetJson, fetchPutJson as updateSurveyStatus } from '../../../services/services';

export const SurveyHeaderActions = ({ onFixValidationErrors }: { onFixValidationErrors: () => void }) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const params: { name: string, survey: string } = useParams()
  const { theData, autoSaveStatus } = useSelector((theState: RootState) => (theState.surveyInitialDataReducer))
  const [showVersions, setShowVersions] = useState(false)
  const [errorModal, setErrorModal] = useState(false)
  const [publishModal, setPublishModal] = useState(false)
  const [closeSurveyModal, setCloseSurveyModal] = useState(false)
  const [showActivitySidebar, setShowActivitySidebar] = useState(false);

  const projectId: string = params.name
  const surveyId: string = params.survey

  const changeSurveyStatus = (status: number) => {
    const body = {
      surveyStatus: status
    }
    updateSurveyStatus(`su/projects/${projectId}/surveys/${surveyId}/status`, token, body)
      .then((res: TODO) => {
        if (res && res.surveyStatus === status) {
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: res.surveyStatus === 1 ? 'The survey has been opened for respondents' : 'The survey has been closed to respondents' } })
          dispatch({ type: 'SET_SURVEY_PUBLISH_DATA', payload: { surveyStatus: res.surveyStatus, versions: res.publishedVersions === null ? [] : res.publishedVersions } })
        }
      })
  }

  const toggleOpenCloseSurvey = (status: number) => {
    if (status === 1) {
      changeSurveyStatus(1)
    } else {
      setCloseSurveyModal(true)
    }
  }

  const onValidateSurvey = () => {
    fetchGetJson(`su/projects/${projectId}/surveys/${surveyId}/validate`, token)
      .then((validationRes: TODO) => {
        if (validationRes && (validationRes.error || validationRes.message)) {
          dispatch({ type: 'UPDATE_ERROR_MESSAGE', payload: { msg: validationRes.message ? validationRes.message : validationRes.error, type: 'modal' } })
        } else {
          let errors = []
          if (validationRes.isValid === false) {
            dispatch({ type: "SET_SHOW_ERROR_LOG", payload: true })
            if (validationRes.errors.length > 0) {
              errors = returnValidationErrors(validationRes)
            }
            else {
              errors = theData.validationErrors
            }
          } else {
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'No validation errors were found.' } })
          }
          dispatch({
            type: 'UPDATE_SURVEY_VALIDATION_ERRORS',
            payload: {
              errors: errors,
              originalItem: theData.selectedItem ? theData.selectedItem : theData.originalSelectedItem,
              selectedItem: theData.selectedItem ? returnSelectedElementData(theData.selectedItem, theData.data.find((el: { index: TODO; }) => el.index === theData.selectedItem.section)?.elements.find((el: { selected: boolean; }) => el.selected === true), errors, theData.data) : null,
              data: returnSurveyDesignData(theData.originalData, theData.selectedItem ? theData.selectedItem.id : null, errors)
            }
          })
          dispatch({ type: 'TOGGLE_DISABLED_QUESTIONS', payload: false });
        }
      })
  }

  return (
    <Fragment>
      {
        publishModal &&
        <PublishModal
          handleClose={() => setPublishModal(false)}
          setErrorModal={(data: boolean) => setErrorModal(data)}
        />
      }
      {
        errorModal &&
        <ErrorModal
          handleClose={() => setErrorModal(false)}
          onFixValidationErrors={onFixValidationErrors} />
      }
      {
        closeSurveyModal &&
        <SubmitCloseModal
          handleClose={() => setCloseSurveyModal(false)}
          closeSurvey={() => { changeSurveyStatus(2); setCloseSurveyModal(false) }} />
      }
      {
        showVersions &&
        <SurveyVersionHistorySidebar
          versions={theData.versions}
          setShowVersions={(data) => setShowVersions(data)}
        />
      }
      {
        showActivitySidebar &&
        <div className='sidebar-activity'>
          <SideBar width="400px" onHide={() => setShowActivitySidebar(false)}>
            <InboxDrawer />
          </SideBar>
        </div>
      }
      <div style={{ position: 'absolute', right: '0', minHeight: '3.5rem' }} className='d-flex align-items-center mr-3'>
        <div className={"d-flex align-items-center mr-2 auto-save"}>
          {
            autoSaveStatus === 'saving' ?
              <Tooltip anchorElement="target" position="bottom">
                <span title="Saving your changes...">
                  <Icon type="cloud-saving" className='pe-none' />
                </span>
              </Tooltip>
              :
              <Tooltip anchorElement="target" position="bottom">
                <span title="All changes saved">
                  <Icon type="cloud" className='pe-none' />
                </span>
              </Tooltip>
          }
        </div>
        {theData.validationErrors.length > 0 &&
          <Tooltip anchorElement="target" position="bottom">
            <button
              type='button'
              onClick={() => { dispatch({ type: 'LOAD_SURVEY_ELEMENT_DATA', payload: 'Validating...' }); onValidateSurvey() }}
              title={'Validate'} className="btn btn-icon btn-transparent mr-2 px-1">
              <Icon type="validate" className='pe-none' />
            </button>
          </Tooltip>
        }
        <Tooltip anchorElement="target" position="bottom">
          <button type='button' title='Version history' onClick={() => setShowVersions(true)} className="btn btn-icon btn-transparent mr-2 px-1">
            <Icon type="survey-version-history" className='pe-none' />
          </button>
        </Tooltip>
        <Tooltip anchorElement="target" position="bottom">
          <button type='button' className='btn btn-shadow btn-icon icon-b mr-2' title="Show activity" onClick={() => setShowActivitySidebar(prevState => !prevState)}>
            <Icon type='nav-activity-mini' className='pe-none' />
          </button>
        </Tooltip>
        {
          (theData.surveyStatus === 1 || theData.surveyStatus === 2) &&
          <button
            type='button'
            onClick={() => toggleOpenCloseSurvey(theData.surveyStatus === 1 ? 2 : 1)}
            className={`btn ${theData.surveyStatus === 1 ? 'btn-shadow' : 'btn-survey-success'} mr-2`}>
            <span>{theData.surveyStatus === 1 ? 'Close survey' : 'Open survey'}</span>
          </button>
        }
        <button type='button' className="btn btn-primary" onClick={() => setPublishModal(true)}>Publish</button>
      </div>
    </Fragment>
  )
}