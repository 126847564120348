export default (firstColumn) => {
  let selectedQuestions = []
  let selectedQuestionsOptions = {}
  if (firstColumn && firstColumn.length > 0) {
    selectedQuestions = firstColumn.filter(el => el.selected)
  }
  selectedQuestions.forEach(el => {
    if (el.options) {
      selectedQuestionsOptions = { ...selectedQuestionsOptions, ...el.options }
    }
  })
  return selectedQuestionsOptions
}
