import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContainerClient } from '@azure/storage-blob';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { ErrorMessage } from '../../ErrorMessage/ErrorMessage';
import { InProgressOverlay } from '../../InProgressOverlay/InProgressOverlay';
import { fetchPatchResOrJson, fetchPostJson } from '../../../../services/services';

export default ({ onHide, projectId, surveyId, type, datasetId }) => {
  const { token } = useSelector((state) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const [formData, setFormData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const onChangeHandler = (e) => {
    setFormData(e.target.files[0])
  }

  const onSubmitHandler = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    if (type === "surveys") {
      const body = { fileName: formData.name }
      const uploadToken = await fetchPostJson(`projects/${projectId}/surveys/${surveyId}/respondents/token`, token, body)
      if (uploadToken && !uploadToken.message && !uploadToken.error) {
        const containerClient = new ContainerClient(uploadToken.sasUri);
        const promises = [];
        const blobName = uploadToken.blob.name
        const blobOptions = {
          blobHTTPHeaders: { blobContentType: uploadToken.blob.contentType }
        };
        const blockBlobClient = containerClient.getBlockBlobClient(blobName);
        promises.push(blockBlobClient.uploadBrowserData(formData, blobOptions))

        const response = await Promise.all(promises);
        if (response) {
          fetchPatchResOrJson(`projects/${projectId}/surveys/${surveyId}/respondents/edit-data`, token, { fileName: uploadToken.blob.name })
            .then(res => {
              if (res.status === 202) {
                onHide()
                dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Outcome editing has been added to the queue.' } })
              } else {
                setIsLoading(false)
                setErrorMessage(res.message ? res.message : res.error)
              }
            })
        } else {
          setIsLoading(false)
          setErrorMessage('ERROR: Azure container client error')
        }
      } else {
        setIsLoading(false)
        setErrorMessage(uploadToken.message ? uploadToken.message : uploadToken.error)
      }
    } else if (type === "datasets") {
      const body = { fileName: formData.name }
      const uploadToken = await fetchPostJson(`projects/${projectId}/datasets/${datasetId}/respondents/token`, token, body)
      if (uploadToken && !uploadToken.message && !uploadToken.error) {
        const containerClient = new ContainerClient(uploadToken.sasUri);
        const promises = [];
        const blobName = uploadToken.blob.name
        const blobOptions = {
          blobHTTPHeaders: { blobContentType: uploadToken.blob.contentType }
        };
        const blockBlobClient = containerClient.getBlockBlobClient(blobName);
        promises.push(blockBlobClient.uploadBrowserData(formData, blobOptions))

        const response = await Promise.all(promises);
        if (response) {
          fetchPatchResOrJson(`projects/${projectId}/datasets/${datasetId}/respondents/edit-data`, token, { fileName: uploadToken.blob.name })
            .then(res => {
              if (res.status === 202) {
                onHide()
                dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Outcome editing has been added to the queue.' } })
              } else {
                setIsLoading(false)
                setErrorMessage(res.message ? res.message : res.error)
              }
            })
        } else {
          setIsLoading(false)
          setErrorMessage('ERROR: Azure container client error')
        }
      } else {
        setIsLoading(false)
        setErrorMessage(uploadToken.message ? uploadToken.message : uploadToken.error)
      }
    }
  }

  return (
    <Dialog minWidth={500} title={"Edit outcomes"} onClose={onHide} className="import-dialog">
      {
        isLoading &&
        <InProgressOverlay type="overlay" theme="primary" message="Uploading your file..." />
      }
      <form onSubmit={onSubmitHandler}>
        <div className="p-3">
          <label>Select a file:</label>
          <div className="input-group mb-3">
            <div className="custom-file">
              <input
                type="file"
                required
                onChange={(e) => onChangeHandler(e)}
                className="custom-file-input"
                accept=".xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
              <label className="custom-file-label">
                {formData !== null ? formData.name : 'Choose file'}
              </label>
            </div>
          </div>
          {
            errorMessage &&
            <ErrorMessage
              type="alert"
              errorMessage={errorMessage}
            />
          }
        </div>
        <DialogActionsBar>
          <button type="button" className="k-button btn btn-secondary" onClick={onHide}>Cancel</button>
          <button type="submit" className="k-button btn btn-primary">Add</button>
        </DialogActionsBar>
      </form>
    </Dialog >
  )
}