export default (status) => {
  if (status === "Planned") {
    return "badge-planned"
  }
  else if (status === "Completed") {
    return "badge-completed"
  }
  else if (status === "To report") {
    return "badge-report"
  }
  else if (status === "In progress") {
    return "badge-progress"
  } else return null
}