interface Props {
  checked: boolean
  onChange: (e: any) => void
  disabled?: boolean
}

export const SimpleSwitch = ({ checked, onChange, disabled }: Props) => {
  return (
    <div className="d-flex align-items-center">
      <label className="simple-switch" htmlFor="simpleSwitch">
        <input id="simpleSwitch" type="checkbox" disabled={disabled} checked={checked} onChange={onChange} />
        <span className="simple-switch-slider round"></span>
      </label>
    </div>
  )
}