import { Icon } from '../../Icon/Icon';
import type { IconsType } from '../../../../interfaces/iconInterfaces/iconInterfaces';

interface Props {
  text?: string
  icon?: IconsType
  hideChevron?: boolean
  renderMainButton?: () => void
  fillColor?: string
  loading?: boolean
}

export const DropdownButtonMainButtonContent = ({ text, icon, hideChevron, renderMainButton, fillColor, loading }: Props) => {
  let mainButtonContent: TODO = null

  if (renderMainButton) {
    mainButtonContent = renderMainButton()
  } else if (text && icon) {
    mainButtonContent =
      <div className='d-flex align-items-center'>
        <Icon type={icon} className={`mr-1 pe-none ${fillColor && `fill-${fillColor}`}`} />
        <span className='label text-truncate'>{text}</span>
      </div>
  } else if (text) {
    if (text === " ") {
      mainButtonContent = <span className='label text-truncate' />
    } else {
      mainButtonContent = <span className='label text-truncate'>{text}</span>
    }
  } else {
    mainButtonContent = <Icon type={icon ? icon : 'more-horizontal'} className='pe-none' />
  }

  return (
    <div id='dropdown-button-content' className="d-flex align-items-center justify-content-between flex-grow-1 overflow-hidden">
      {mainButtonContent}
      <div className="d-flex align-items-center">
        {
          loading &&
          <span className="spinner-border spinner-border-sm text-disabled mr-1" />
        }
        {
          !hideChevron &&
          <Icon type='chevron-mini-down-alt' className={`${fillColor ? `fill-${fillColor}` : "fill-primary"} flex-shrink-0`} />
        }
      </div>
    </div>
  )
}