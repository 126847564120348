import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { AudienceOverviewType, PreScreeningAudienceType } from '../../../interfaces/auddineceAccessInterfaces/audienceOverviewTypes'

const initialState: AudienceOverviewType = {
  audienceAction: undefined,
  id: "",
  allowReentry: false,
  batchIds: [],
  buyerDetails: null,
  createdByEmail: "",
  createdUtc: "",
  customerId: "",
  displayName: "",
  shortKey: "",
  exclusion: null,
  isOpen: false,
  pii: false,
  projectNumber: "",
  reconciliationId: "",
  redirectCaptureParameter: "",
  surveyLiveUrl: "",
  surveyTestUrl: "",
  surveyType: "AdHoc",
  uniqueUrlsWidParam: "",
  useUniqueUrls: false,
  market: { country: "", countryName: "", language: "", languageName: "", locale: "" },
  etag: null,
  projectId: "",
  sampleId: "",
  originalIdRecontactVariable: "",
  walrIdRecontactVariable: "",
  sampleInfo: null,
  prescreening: null
}

const audienceDataSlice = createSlice({
  name: 'audienceAccess',
  initialState,
  reducers: {
    createNewAudience(_state, action: PayloadAction<{ projectId: string, sampleId: string, audienceAction: "new" }>) {
      const stateUpdate = { ...initialState, projectId: action.payload.projectId, sampleId: action.payload.sampleId, audienceAction: action.payload.audienceAction }
      return stateUpdate
    },

    setAudience(_state, action: PayloadAction<AudienceOverviewType>) {
      const stateUpdate = action.payload
      return stateUpdate
    },
    editAudienceStatus(state, action: PayloadAction<"new" | "copy" | "edit">) {
      const stateUpdate = { ...state, audienceAction: action.payload }
      return stateUpdate
    },
    editAudienceProjectId(state, action: PayloadAction<string>) {
      const stateUpdate = { ...state, projectId: action.payload }
      return stateUpdate
    },
    editAudienceSampleId(state, action: PayloadAction<string>) {
      const stateUpdate = { ...state, sampleId: action.payload }
      return stateUpdate
    },
    editPrescreening(state, action: PayloadAction<PreScreeningAudienceType>) {
      const stateUpdate = { ...state, prescreening: action.payload }
      return stateUpdate
    },
    editEtag(state, action: PayloadAction<string>) {
      const stateUpdate = { ...state, etag: action.payload }
      return stateUpdate
    },
  }
})

export const {
  setAudience,
  editAudienceStatus,
  createNewAudience,
  editAudienceProjectId,
  editAudienceSampleId,
  editPrescreening,
  editEtag
} = audienceDataSlice.actions
export default audienceDataSlice.reducer