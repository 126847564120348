export default (expandedState, state) => {
  let directoryReference = null
  if (expandedState.data && expandedState.data[0]) {
    directoryReference = expandedState.data[0].id
  } else if (state.selected) {
    if (state.selected.isDirectory) {
      directoryReference = state.selected.directoryReference
    } else {
      if (state.selected.blobName.includes('/')) {
        directoryReference = state.selected.blobName.split('/')
        directoryReference.pop()
        directoryReference = directoryReference.length === 1 ? directoryReference + '/' : directoryReference.join('/')
      } else {
        directoryReference = null
      }
    }
  } else {
    directoryReference = null
  }

  return directoryReference
}