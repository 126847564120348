import { useState, useEffect, Fragment } from 'react'
import JsxParser from 'react-jsx-parser';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DropDownButton, DropDownButtonItem } from '@progress/kendo-react-buttons';

import items from '../exportItems.json';
import reportActions from './reportActions.json';
import { ExportModal } from '../../ExportModal/ExportModal';
import ChartTypeContent from '../ChartTabContent/ChartTypeContent/ChartTypeContent';
import { ErrorMessage } from '../../../../../../../shared/ErrorMessage/ErrorMessage';
import { ExportActionItem } from '../../../../shared/ExportActionItem/ExportActionItem';
import returnActionsData from '../../../../shared/helpers/returnActionsData/returnActionsData';
import { InProgressOverlay } from '../../../../../../../shared/InProgressOverlay/InProgressOverlay';
import returnUpdatedAnalysisActionItems from '../../../../shared/helpers/returnUpdatedAnalysisActionItems/returnUpdatedAnalysisActionItems';
import type { RootState } from '../../../../../../../../store/reducers/rootReducer';
import { updateEditedTextField } from '../../../../shared/helpers/resetEditedText/resetEditedText';
import { fetchPostJson as updateToplineData } from '../../../../../../../../services/services';

interface Props {
  user: TODO
  userData: TODO
  rangeItems: TODO
  datasetName: TODO
  optionsData: TODO
  onAutoSaveHandler: TODO
  onSaveActionHandler: TODO
  analysisFunctionalities: TODO
}

export const ReportTabContent = ({ user, datasetName, optionsData, onAutoSaveHandler, analysisFunctionalities }: Props) => {
  const reportState: TODO = useSelector((theState: RootState) => theState.reportStateReducer);
  const { state, defaultLanguage, languages, editingLanguage } = useSelector((theState: RootState) => ({
    ...theState.stateReducer,
    ...theState.setInitialDataReducer,
    ...theState.surveyInitialDataReducer
  }));
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);

  const history = useHistory();
  const dispatch = useDispatch();
  const params: { dataset: string; analysisType: string } = useParams();

  const datasetId = params.dataset;
  const datasetType = params.analysisType;

  const [type, setType] = useState('')
  const [noData, setNoData] = useState(false)
  const [updateLanguage, setUpdateLanguage] = useState(null)
  const [errorMessage, setErrorMessage] = useState("");
  const [localTableState, setLocalTableState] = useState('')
  const [showExportModal, setShowExportModal] = useState<{ show: boolean, action: TODO, type: TODO, extension: TODO }>({ show: false, action: null, type: null, extension: null })

  const updatedItems = returnUpdatedAnalysisActionItems(analysisFunctionalities, items, datasetType, true)
  const allColumnsDisabled = reportState.questions.filter((e: TODO) => !e.disabled).length === 0

  useEffect(() => {
    if (reportState.columnData.length !== 0) {
      let type = reportState.questions[reportState.activeState].type
      setType(type)
      if (type === "data") {
        setType("rawData")
      } else if (type === "dig") {
        setType("digData")
      }
      setNoData(false)
      setLocalTableState('')
      let count = reportState.activeState
      let body = reportState.columnData[count].body
      body.language = editingLanguage
      const dataUrl = reportState.columnData[count].dataUrl
      let updatedBody;
      if (body.language && body.language !== defaultLanguage) {
        updatedBody = updateEditedTextField(body)
      }
      updateToplineData(dataUrl, token, updatedBody ? updatedBody : body)
        .then((res: any) => {
          if (res && (res.error || res.message)) {
            setErrorMessage(res.error ? res.error : res.message)
          } else {
            if (res.result) {
              setLocalTableState(res.result)
            } else {
              setLocalTableState(res)
            }
          }
        })
      onAutoSaveHandler(state)
    } else {
      setLocalTableState('')
      setNoData(true)
    }
  }, [dispatch, token, reportState, state, onAutoSaveHandler, reportState.activeState, editingLanguage, defaultLanguage])

  useEffect(() => {
    if (updateLanguage) {
      dispatch({ type: "SET_EDITING_LANGUAGE", payload: updateLanguage, })
    }
  }, [dispatch, updateLanguage])

  const exportFunc = (props: any) => {
    if (reportState.questions && reportState.questions.length > 0 && allColumnsDisabled === false) {
      setShowExportModal({ show: true, action: props.item.action, type: props.item.actionName, extension: props.item.extension })
    }
  }

  const actionItemRender = (props: any) => {
    return (
      <ExportActionItem
        tab='report'
        user={user}
        props={props}
        state={state}
        reportState={reportState}
        disabled={allColumnsDisabled}
      />
    )
  }

  const onActionHandler = (action: string) => {
    const updatedReportState = JSON.parse(JSON.stringify(reportState))
    const updatedReportColumns = JSON.parse(JSON.stringify(updatedReportState.columnData))
    const updatedReportQuestions = JSON.parse(JSON.stringify(updatedReportState.questions))

    if (action === 'deleteAll') {
      dispatch({ type: 'REPORT_DELETE_SELECTED_QUESTIONS', payload: { questions: [], columnData: [] } })
    } else if (action === 'deleteSelected') {
      let updatedQuestions: any = []
      let updatedColumnData: any = []
      updatedReportQuestions.forEach((el: any, index: number) => {
        if (!el.selected) {
          updatedQuestions.push(el)
          updatedReportState.columnData.forEach((item: any, id: number) => {
            if (index === id) {
              updatedColumnData.push(item)
            }
          })
        }
      })
      dispatch({ type: 'REPORT_DELETE_SELECTED_QUESTIONS', payload: { questions: updatedQuestions, columnData: updatedColumnData } })
    } else if (action === 'enable') {
      updatedReportQuestions.filter((item: any) => item.selected).forEach((el: any) => {
        el.disabled = false
      })
      updatedReportQuestions.filter((item: any, id: any) => {
        if (item.selected) {
          updatedReportColumns[id].disabled = false
        }
        return null
      })
      updatedReportQuestions.forEach((item: any) => {
        item.selected = false
      })
      dispatch({ type: 'REPORT_ENABLE_SELECTED_QUESTIONS', payload: { questions: updatedReportQuestions, columns: updatedReportColumns } })
    } else if (action === 'disable') {
      updatedReportQuestions.filter((item: any) => item.selected).forEach((el: any) => {
        el.disabled = true
      })
      updatedReportQuestions.filter((item: any, id: any) => {
        if (item.selected) {
          updatedReportColumns[id].disabled = true
        }
        return null
      })
      updatedReportQuestions.forEach((item: any) => {
        item.selected = false
      })
      dispatch({ type: 'REPORT_DISABLE_SELECTED_QUESTIONS', payload: { questions: updatedReportQuestions, columns: updatedReportColumns } })
    } else if (action === 'select rows') {
      const rows = updatedReportQuestions
      rows.map((e: any) => e.selected = true)
      dispatch({ type: 'REPORT_UPDATE_QUESTIONS', payload: rows })
    } else if (action === 'deselect rows') {
      const rows = updatedReportQuestions
      rows.map((e: any) => e.selected = false)
      dispatch({ type: 'REPORT_UPDATE_QUESTIONS', payload: rows })
    }
  }

  const onLanguageChange = (e: any) => {
    setUpdateLanguage(e.item.text)
  }

  return (
    <Fragment>
      {
        errorMessage &&
        <ErrorMessage type={"modal"} errorMessage={errorMessage} onHide={() => setErrorMessage("")} />
      }
      {
        showExportModal && showExportModal.show &&
        <ExportModal
          tab={'report'}
          state={reportState}
          updatedLanguage={editingLanguage}
          defaultLanguage={defaultLanguage}
          token={token}
          projectType={undefined}
          history={history}
          editorValue={null}
          datasetId={datasetId}
          datasetName={datasetName}
          datasetType={datasetType}
          showExportModal={showExportModal}
          onExportHandler={returnActionsData}
          options={{ exportOptions: optionsData.exportOptions, values: optionsData.dropdownValues }}
          onHide={() => { setShowExportModal({ ...showExportModal, show: false }); dispatch({ type: 'CLOSE_EXPORT_MODAL' }) }}
          exportBody={updateEditedTextField(reportState.columnData)}
        />
      }
      <div className={`d-flex justify-content-between flex-wrap border-left`}>
        <div className="btn-group m-2 analysis-actions">
          <div className="btn-group">
            <DropDownButton
              text="Actions"
              icon="fas fa fa-caret-down"
              className='analyze-actions-button'
              buttonClass={`btn btn-outline-analyze d-flex flex-row-reverse rounded-right-0 px-2`}
              popupSettings={{ popupClass: 'p-0' }}
              items={reportActions}
              onItemClick={(e) => onActionHandler(e.item.action)}
              itemRender={(props: any) => (
                <div className="p-1 d-flex align-items-center justify-content-between">
                  <i className={`${props.item.icon} mr-3`}></i>
                  <span>{props.item.actionName}</span>
                </div>
              )}
            />
          </div>
          {languages.length > 1 ?
            <div className='btn-group'>
              <DropDownButton
                className="analyze-actions-button"
                text={editingLanguage}
                buttonClass="btn btn-outline-analyze d-flex flex-row-reverse rounded-left-0 px-2"
                icon="fa fas fa-globe"
                onItemClick={(e) => onLanguageChange(e)}
                disabled={!reportState.columnData.length}
                tabIndex={-1} >
                {languages.map((item: any, key: number) =>
                  <DropDownButtonItem key={key} text={item} />
                )}
              </DropDownButton>
            </div>
            : null}
        </div>

        <div className="btn-group m-2 analysis-actions">
          {/* <div className="btn-group">
            <DropDownButton
              icon="fas fa fa-caret-down"
              className='analyze-actions-button'
              buttonClass={`btn btn-outline-analyze rounded-right-0 d-flex flex-row-reverse px-2`}
              text="Sessions"
              textField="actionName"
              items={sessionDropdownItems}
              onItemClick={(e) => onSaveActionHandler(e, state)}
            />
          </div> */}
          <div className="btn-group">
            <DropDownButton
              text="Export"
              icon="fas fa fa-caret-down"
              className='analyze-actions-button'
              buttonClass={`btn btn-outline-analyze rounded-left-0 d-flex flex-row-reverse px-2`}
              items={updatedItems}
              onItemClick={(e) => exportFunc(e)}
              itemRender={actionItemRender}
              popupSettings={{ popupClass: 'export-dropdown' }}
            />
          </div>
        </div>
      </div>
      <div className={`d-flex overflow-hidden flex-fill border-table-area ${type !== '' ? type : ''}`}>
        {
          localTableState === "" ?
            noData ?
              <div className="flex-fill p-4">
                <div className="alert alert-warning d-flex justify-content-center align-items-center">No Data</div>
              </div>
              :
              <InProgressOverlay message="" className="" theme="primary" type="fullscreen" />
            :
            <Fragment>
              {
                type === 'chart' ?
                  <div className="w-100 d-flex flex-column overflow-auto">
                    <ChartTypeContent currentZoom={1} data={localTableState} />
                  </div>
                  :
                  <div className="w-100 d-flex flex-column overflow-hidden">
                    <JsxParser jsx={localTableState} />
                  </div>
              }
            </Fragment>
        }
      </div>
    </Fragment>
  )
}