import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { Icon } from '../../Icon/Icon';
import type { InboxItem } from '../InboxDrawer';
import { timeSince } from '../../helpers/timeSince/timeSince';
import { FullHeightSpinner } from '../../FullHeightSpinner/FullHeightSpinner';
import { InboxDrawerCustomItemStatus } from './InboxDrawerCustomItemStatus/InboxDrawerCustomItemStatus';
import { downloadInboxItemHandler } from '../../helpers/downloadInboxItemHandler/downloadInboxItemHandler';
import { returnisInboxItemDownloadButtonDisabled } from '../../helpers/returnisInboxItemDownloadButtonDisabled/returnisInboxItemDownloadButtonDisabled';
import { fetchDelete as removeInboxItem, fetchGetJson as getProjectById } from '../../../../services/services';

interface Props {
  item: InboxItem
  token: string
  inboxData: InboxItem[] | null
  notification: {
    id: string
    link: string
    status: string
    completedStatus: string
  }
  handleClick: () => void
  setInboxData: (data: InboxItem[] | null) => void
}

export const InboxDrawerCustomItem = ({ item, token, inboxData, notification, handleClick, setInboxData }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isButtonDisabled = returnisInboxItemDownloadButtonDisabled(item, notification)

  const onRemoveItemHandler = (item: TODO) => {
    if (item.status) {
      if (item.status === 'Completed' || item.status === 'Unknown') {
        removeInboxItem(`inboxes/items/${item.id}`, token)
          .then((res: TODO) => {
            if (res.ok && res.status === 204) {
              if (inboxData !== null) {
                setTimeout(() => {
                  const updatedInboxData: InboxItem[] = JSON.parse(JSON.stringify([...inboxData]))
                  const removeItem = updatedInboxData.find((el) => el.id === item.id)
                  if (removeItem) {
                    updatedInboxData.splice(updatedInboxData.indexOf(removeItem), 1)
                    setInboxData(updatedInboxData)
                  }
                }, 500)
              }
            } else {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error || res.message } })
            }
          })
      }
    }
  }

  const openSurvey = (item: TODO) => {
    getProjectById(`projects/${item.projectId}/extended`, token)
      .then((project: TODO) => {
        if (project && (project.message || project.error)) {
          dispatch({ type: 'UPDATE_ERROR_MESSAGE', payload: { msg: project.message ? project.message : project.error, type: 'modal' } })
        } else {
          handleClick()
          dispatch({
            type: 'UPDATE__PROJECT_AND_DOCUMENT_BREADCRUMB', payload: {
              documentId: item.id,
              documentName: item.name,
              projectId: item.projectId,
              projectName: project.name
            }
          })
          history.push({ pathname: `/projects/${item.projectId}/${item.id}/survey-builder` })
        }
      })
  }

  if (inboxData && inboxData.length > 0) {
    if (item) {
      const inboxItemIcon = item.type === 'DatasetExport' ? 'fa-file-download' : item.type === 'CreateSurveyFromDataset' ? 'fa-poll-h' : item.type === "Audience" ? "fa-fw fas fa-users" : 'fa-file-alt'

      return (
        <div className="wrapper">
          <div className={`w-100 drawer-item-wrapper overflow-hidden mb-2 mt-1 ${item.removeItem ? 'remove-item' : ''}`}>
            {
              item.status === 'Completed' &&
              <button
                type='button'
                onClick={() => onRemoveItemHandler(item)}
                className="cursor-pointer item-close btn-transparent btn-transparent"
              >
                <Icon type="close" />
              </button>
            }

            <div style={{ borderRadius: '5px', cursor: 'default' }} className="d-flex flex-grow-1 justify-content-between align-items-center p-3">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <div style={{ width: '44px', height: '44px', borderRadius: '14px' }} className="d-flex justify-content-center align-items-center bg-light">
                    <i className={`fas fa-lg ${inboxItemIcon} ${item.isRead ? 'text-muted' : 'text-primary'}`} />
                  </div>
                </div>
                <div className="d-flex flex-column text-left ml-3 mr-4">
                  <h6 style={{ overflowWrap: 'anywhere' }} className={`mb-0 strong ${item.isRead ? 'text-muted' : 'text-primary'}`}>{item.name}</h6>
                  <small className="text-muted m-0">
                    {timeSince(new Date(item.updatedUtc))}
                  </small>

                  <div className="mt-1">
                    <InboxDrawerCustomItemStatus
                      item={item}
                      notification={notification}
                    />
                  </div>
                </div>
              </div>
            </div>

            {(item.type === "TranslationExport" || item.type === "DatasetImport") ? null :
              item.type !== 'CreateSurveyFromDataset' ?
                item.link ?
                  <div className="project-card-buttons d-flex border-top">
                    <button
                      disabled={isButtonDisabled}
                      onClick={() => downloadInboxItemHandler(item.link ? item.link : notification.link, dispatch, item, token)}
                      type="button" className="btn d-flex justify-content-center text-primary shadow-none w-100 rounded-0">
                      <span>Download</span>
                    </button>
                  </div> : null
                :
                <div className="project-card-buttons d-flex border-top">
                  <button
                    disabled={isButtonDisabled}
                    onClick={() => openSurvey(item)}
                    type="button" className="btn d-flex justify-content-center text-primary shadow-none w-100 rounded-0">
                    <span>Open</span>
                  </button>
                </div>}
          </div>
        </div>
      )
    } return null
  }
  return (
    <FullHeightSpinner />
  )
}