import { Input } from '@progress/kendo-react-inputs';

import { EmailProvidersSMTPSettings } from './EmailProvidersSMTPSettings/EmailProvidersSMTPSettings';
import { EmailProvidersGeneralSettings } from './EmailProvidersGeneralSettings/EmailProvidersGeneralSettings';
import type { EmailProvider } from '../../../../../interfaces/emailProviderInterfaces/emailProviderInterfaces';
import type { ProjectTag } from '../../../../../interfaces/projectDetailsInterfaces/projectDetailsInterfaces';
import { EmailProviderTwilioSmsSettings } from './EmailProviderTwilioSmsSettings/EmailProviderTwilioSmsSettings';

interface Props {
  formData: TODO
  providerType?: string
  stepperValue: number
  tags: ProjectTag[] | null
  emailProvider?: EmailProvider | null
  onChangeHandler: (e: TODO, name: string) => void
  isTwilio?: boolean
}

export const EmailProviderModalContent = ({ stepperValue, tags, emailProvider, formData, providerType, onChangeHandler, isTwilio }: Props) => {
  if (stepperValue === 0) {
    return (
      <EmailProvidersGeneralSettings
        tags={tags}
        formData={formData}
        onChangeHandler={(e, name) => onChangeHandler(e, name)}
        isTwilio={isTwilio} />
    )
  } if (stepperValue === 1) {
    if (providerType === 'smtp') {
      return (
        <EmailProvidersSMTPSettings
          formData={formData}
          emailProvider={emailProvider}
          onChangeHandler={(e, name) => onChangeHandler(e, name)} />
      )
    }
    if (providerType === 'sendgrid') {
      return (
        <Input
          required={!emailProvider}
          label={'API Key'}
          value={formData.apiKey}
          onChange={(e) => onChangeHandler(e, 'apiKey')}
        />
      )
    }
    if (providerType === 'twilio') {
      return (
        <EmailProviderTwilioSmsSettings
          formData={formData}
          emailProvider={emailProvider}
          onChangeHandler={(e, name) => onChangeHandler(e, name)}
        />
      )
    } return null
  } return null
}