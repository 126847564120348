import type React from 'react';
import { useState, type FormEvent } from 'react'
import Editor from '@monaco-editor/react';
import { Dialog } from '@progress/kendo-react-dialogs'
import { useDispatch, useSelector } from 'react-redux';

import type { RootState } from '../../../store/reducers/rootReducer';
import { ErrorMessage } from '../../shared/ErrorMessage/ErrorMessage';
import { FullHeightSpinner } from '../../shared/FullHeightSpinner/FullHeightSpinner';
import type { WidType } from '../../../interfaces/gateKeeperInterfaces/gateKeeperWIDType';
import { fetchGetJsonData } from '../../../services/services';

type Props = {
  onHide: (hide: boolean) => void;
}

type ResponseData = {
  widType: WidType
}

export default function WidSearchModal({ onHide }: Props) {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState<{ widType: WidType }>();

  const getData = (e: FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    e.preventDefault();

    fetchGetJsonData<ResponseData>({ url: `co/gk/respondents/${searchValue}`, token: token, baseUrl: "default" })
      .then((data) => {
        setErrorMessage("");
        setData(data);
        setSearchValue("");
      })
      .catch((errorMessage: string) => {
        setErrorMessage(errorMessage);
        setData(undefined);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  const copyData = () => {
    navigator.clipboard.writeText(JSON.stringify(data)).then(() => {
      dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "Data copied to clipboard" } })
    });
  }

  return (
    <Dialog width={"70%"} height={"80%"} title={"Search Walr ID"} onClose={() => onHide(false)}>
      <form className="d-flex align-items-center gap-md px-2 py-4" onSubmit={getData}>
        <input
          type="text"
          value={searchValue}
          className="form-control search-input bg-white h-32"
          placeholder="Search"
          aria-label="Search"
          required
          disabled={isLoading}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
        />

        <button type="submit" className="btn btn-shadow" disabled={isLoading}>
          Search
        </button>
      </form>

      {errorMessage && (
        <div className="px-4">
          <ErrorMessage type="alert" errorMessage={errorMessage} onHide={undefined} />
        </div>
      )}

      {isLoading && !data && (
        <div className="d-flex flex-column gap-md justify-content-center align-items-center h-75">
          <FullHeightSpinner />
        </div>
      )}

      {!isLoading && data && (
        <>
          <Editor
            theme='vs-dark'
            defaultLanguage='json'
            height="85%"
            className='px-2'
            value={JSON.stringify(data, null, 4)}
            options={{ readOnly: true, minimap: { enabled: false } }}
          />
          <button type='button' className="btn btn-shadow mt-2 ml-2" onClick={copyData}>Copy Data</button>
        </>
      )}
    </Dialog>
  )
}