export default (state, index) => {
  const updatedState = JSON.parse(JSON.stringify(state));

  updatedState.data.forEach(el => {
    el.selected = false;
    if (el.id === index) {
      el.selected = true;
      updatedState.displayTable = JSON.parse(JSON.stringify(el))
    } else if (el.mergedQuestions) {
      el.mergedQuestions.forEach(val => {
        val.selected = false
        if (val.id === index) {
          val.selected = true
          updatedState.displayTable = JSON.parse(JSON.stringify(el))
        }
      })
    }
  })

  return {
    data: updatedState.data,
    displayTable: updatedState.displayTable
  }
}