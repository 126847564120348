import { reorder, copy, move } from '../dndFunctions/dndFunctions';

export function onDragEnd(result, theState, source, destination, removeItem, secondColumnIndex, columns, theData, rangeItems) {
  const state = JSON.parse(JSON.stringify(theState.state));
  state.updateTable = true
  source = result.source;
  destination = result.destination;
  let columnsCopy = [...columns],
    secondColumnIndexCopy = [...secondColumnIndex]
  // dropped outside the list
  if (!destination) {
    return {
      ...theState
    }
  }

  // Remove second column item from 'secondColumnIndex'
  if (destination.droppableId !== 'scndColumn') {
    if (isNaN(removeItem.id) === false && removeItem.item) {
      let index = secondColumnIndex.indexOf(removeItem.item);
      secondColumnIndexCopy.splice(index, 1)
    }
    if (secondColumnIndex.length === 1) {
      state.firstColumn.forEach(el => {
        if (el.active) {
          el.mergedBody = null;
        }
      })
    }
  }

  // Update state depending on the DND action (reorder, copy, move)
  switch (source.droppableId) {
    case destination.droppableId:
      if (destination.droppableId === 'ITEMS') {
        return {
          ...theState
        }
      } else if (destination.droppableId === 'questions') {
        return {
          ...theState,
          [destination.droppableId]: reorder(
            theState[source.droppableId],
            source.index,
            destination.index,
            source.droppableId,
          ),
          columnData: reorder(
            theState.columnData,
            source.index,
            destination.index,
            source.droppableId,
          ),
          source: source,
          destination: destination,
        }
      }
      return {
        ...theState,
        state: {
          ...state,
          [destination.droppableId]: reorder(
            state[source.droppableId],
            source.index,
            destination.index,
            source.droppableId,
          ),
        },
        source: source,
        destination: destination,
      }
    case 'ITEMS':
      columnsCopy = [...columns, destination.droppableId]
      let destinationCopy
      if (rangeItems.length === 0) {
        destinationCopy = copy(
          theData,
          state[destination.droppableId],
          source,
          destination
        )
      }
      if (rangeItems.length !== 0) {
        let destIndex = 0
        let destinationList = []
        theData.forEach((item, index) => {
          if (rangeItems.filter(el => el.id === item.id).length !== 0) {
            destinationCopy = copy(
              theData,
              destinationList,
              { ...source, index: index },
              { ...destination, index: destIndex }
            )
            destinationList.push(destinationCopy[destinationCopy.length - 1])
            destIndex++
          }
        })
        let list = state[destination.droppableId]
        if (list.length !== 0) {
          list.forEach(el => el.active = false)
        }
        destinationList.forEach((el) => {
          list.push(el)
        })
        destinationCopy = list

      }
      return {
        ...theState,
        state: {
          ...state,
          [destination.droppableId]: destinationCopy,
          columns: columnsCopy,
        },
        source: source,
        destination: destination,
      }
    default:
      const foundSourceColumnIndex = columnsCopy.indexOf(source.droppableId);
      if (foundSourceColumnIndex !== -1) {
        columnsCopy.splice(foundSourceColumnIndex, 1)
      }
      columnsCopy = [...columns, destination.droppableId]
      return {
        ...theState,
        state: move(
          state[source.droppableId],
          state[destination.droppableId],
          source,
          destination,
          state,
          columnsCopy,
          secondColumnIndexCopy
        ),
        source: source,
        destination: destination,
      }
  }

};