import React, { useEffect, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { BaseDropDownList } from "../../../../../shared/Inputs/index.tsx";
import { ErrorMessage } from '../../../../../shared/ErrorMessage/ErrorMessage';
import { returnPasteBodyData, returnPasteItems } from '../returnDataHelpers/returnDataHelpers';
import { fetchPostJson as pasteCopiedData, fetchPutResOrJson as updateSurveyIndex, fetchPostJson as createSurveyElement } from '../../../../../../services/services'

export default ({ handleClose, theData, token, updateElements }) => {
  const [didMount, setDidMount] = useState(true)
  const [selectedSection, setSelectedSection] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState({ show: false, message: '' })
  const [items, setItems] = useState([])

  useEffect(() => {
    if (didMount) {
      setDidMount(false)
      let pasteItems = returnPasteItems(theData)
      setSelectedSection(pasteItems[0].id)
      setItems(pasteItems)
    }
  }, [didMount])

  const onPaste = () => {
    if (navigator.clipboard) {
      navigator.clipboard.readText()
        .then(text => {
          const checkCopiedData = checkJson(text)
          if (checkCopiedData) {
            const body = JSON.parse(text)
            pasteCopiedData(`su/projects/${theData.originalData.projectId}/surveys/${theData.originalData.surveyId}/prepare-paste`, token, body)
              .then(res => {
                if (res && res.error) {
                  setError({ show: true, message: res.message ? res.message : res.error })
                  setIsLoading(false)
                } else {
                  let copiedData = res
                  if (selectedSection === "newSection") {
                    const body = {
                      "templateId": "DefaultSection",
                      "languages": ["en"]
                    }
                    createSurveyElement(`su/projects/${theData.originalData.projectId}/surveys/${theData.originalData.surveyId}`, token, body)
                      .then(res => {
                        const newSectionData = JSON.parse(JSON.stringify(res.index))
                        copiedData.forEach(el => {
                          newSectionData.elements[newSectionData.elements.length - 1].elements.push(el.indexElement)
                        })
                        updateOriginalData(newSectionData)
                      })
                  } else {
                    let data = returnPasteBodyData(copiedData, selectedSection, theData)
                    updateOriginalData(data)
                  }
                }
              })
          } else {
            setError({ show: true, message: "Copied data is not valid, please try copying the elements again" })
            setIsLoading(false)
          }
        })
        .catch(err => {
          setError({ show: true, message: "Permission denied. You need to enable access to read from clipboard" })
          setIsLoading(false)
        })
    }
  }

  const updateOriginalData = (data) => {
    const body = data
    updateSurveyIndex(`su/projects/${theData.originalData.projectId}/surveys/${theData.originalData.surveyId}/index`, token, body)
      .then(res => {
        if (res.status === 200) {
          if (theData.selectedItem) {
            updateElements(theData.selectedItem.id)
          } else {
            updateElements()
          }
          handleClose()
        } else {
          setError({ show: true, message: res.message ? res.message : res.error })
          setIsLoading(false)
        }
      })
  }

  const checkJson = (data) => {
    try {
      JSON.parse(data);
    } catch (e) {
      return false;
    }
    return true;
  }

  return (
    <Dialog title="Paste questions" onClose={handleClose} width="30rem">
      {
        !didMount &&
        <div className="p-4 d-flex flex-column">
          <span className="mb-2">Select section to paste the questions</span>
          <BaseDropDownList
            className="w-100"
            data={items}
            textField="text"
            popupSettings={{ className: 'survey-dropdown' }}
            defaultValue={items.find(el => el.id === selectedSection)}
            onChange={(e) => setSelectedSection(e.value.id)}
          />
          {
            error.show &&
            <div className="mt-3">
              <ErrorMessage errorMessage={error.message} type="alert" />
            </div>
          }
        </div>
      }
      <DialogActionsBar>
        <Button className="btn btn-secondary" onClick={handleClose}>Cancel</Button>
        <Button disabled={isLoading} className="btn btn-primary" onClick={() => { setIsLoading(true); onPaste() }}>
          {isLoading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>}
          <span>Paste</span>
        </Button>
      </DialogActionsBar>
    </Dialog>
  )
}