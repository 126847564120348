export const returnMultigridVariablePropertyName = (el: TODO) => {
  let variablePropertyName = 'variable'

  if (el) {
    if (el["miextsss:multilist"]) {
      variablePropertyName = "miextsss:multilist"
    } else if (el["miextsss:nmopen"]) {
      variablePropertyName = "miextsss:nmopen"
    } else if (el["miextsss:grid"]) {
      variablePropertyName = "miextsss:grid"
    } else if (el.variable) {
      variablePropertyName = "variable"
    }
  }

  return variablePropertyName
}