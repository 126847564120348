import { Users } from "./UsersTabContent/Users";
import { SMSTabContent } from "./SMSTabContent/SMSTabContent";
import { TagsTabContent } from "./TagsTabContent/TagsTabContent";
import { EmailTabContent } from "./EmailTabContent/EmailTabContent";
import { GroupsTabContent } from "./GroupsTabContent/GroupsTabContent";
import { APIKeysTabContent } from "./APIKeysTabContent/APIKeysTabContent";
import { AuditLogsTabContent } from "./AuditLogsTabContent/AuditLogsTabContent";
import { SubAccountsTabContent } from "./SubAccountsTabContent/SubAccountsTabContent";
import { CustomizationsTabContent } from "./CustomizationsTabContent/CustomizationsTabContent";
import type { TabIds, UserSelectedTab } from "../../../interfaces/adminInterfaces/adminInterfaces";
import { SharingProjectTabContent } from "./EmailTemplatesTabContent/SharingProjectTabContent/SharingProjectTabContent";

type Props = {
  tabType: TabIds;
  usersSelectedTab: UserSelectedTab;
}

export const AdminTabContent = ({ tabType, usersSelectedTab }: Props) => {
  switch (tabType) {
    case "Users":
      return <Users usersSelectedTab={usersSelectedTab} />
    case "Groups":
      return <GroupsTabContent />
    case "Tags":
      return <TagsTabContent />
    case "Email":
      return <EmailTabContent />
    case "SMS":
      return <SMSTabContent />
    case "SubAccounts":
      return <SubAccountsTabContent />
    case "APIKeys":
      return <APIKeysTabContent />
    case "AuditLogs":
      return <AuditLogsTabContent />
    case "EmailTemplates":
      return <SharingProjectTabContent />
    case "Customizations":
      return <CustomizationsTabContent />
    default:
      return <div />;
  }
};
