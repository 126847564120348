export const returnSelectedSubAccountUsers = (subAccounts: { users?: any[] }[]) => {
  let selectedSubAccountUsers: any = []

  subAccounts.forEach((subAccount: any) => {
    if (subAccount.users) {
      const currentSelectedUsers = subAccount.users.filter((user: any) => user.selected)
      if (currentSelectedUsers && currentSelectedUsers.length > 0) {
        currentSelectedUsers.forEach((currSelected: any) => {
          selectedSubAccountUsers.push({
            email: currSelected.email,
            subAccountId: subAccount.id
          })
        })
      }
    }
  })

  return selectedSubAccountUsers
}