import React from 'react';
import { Input } from '@progress/kendo-react-inputs';

export default ({ el, onChangeHandler, index, onChangeExcluded }) => {
  if (el.multilist || el.nmopen) {
    const valuesProperty = el.values ? 'values' : 'variable'
    const allValues = []
    const map = new Map();

    el[valuesProperty].forEach(val => {
      if (val.values) {
        if (Array.isArray(val.values.value)) {
          val.values.value.forEach(item => {
            allValues.push(item.text)
          })
        }
      } else if (val.label?.text) {
        allValues.push(val.label.text)
      }
    })

    allValues.forEach((item, i) => {
      if (map.has(item)) map.get(item).push(i);
      else map.set(item, [i]);
    });

    // const uniqueValues = allValues.filter((item, i) => map.get(item).length === 1);

    return (
      <tbody id="multilist-table-body">
        {
          el[valuesProperty] ?
            el[valuesProperty].map((val, id) => {
              if (val.values) {
                if (Array.isArray(val.values.value)) {
                  return val.values.value.filter(val => !val['@miextsss:excluded'] || val['@miextsss:excluded'] === 'false').map((value, i) => {
                    const indexVal = el[valuesProperty].indexOf(val)
                    const indexText = el[valuesProperty][indexVal].values.value.indexOf(value)
                    return (
                      <tr key={i}>
                        <td>
                          <input
                            onChange={(e) => onChangeExcluded(e, index, 'nmopen-arr', valuesProperty, indexVal)}
                            type="checkbox"
                            className="k-checkbox"
                            checked={val['@miextsss:excluded'] === 'true' ? false : true}
                          />
                        </td>
                        <td>
                          {value['@miextsss:code']}
                        </td>
                        <td>
                          <div className="form-group m-0">
                            <Input
                              onChange={(e) => onChangeHandler(e, index, 'questionValue', 'title', id, valuesProperty, 'nmopen-arr', indexText)}
                              type="text"
                              className="form-control"
                              value={value.text}
                            />
                          </div>
                        </td>
                      </tr>
                    )
                  })
                }
                return (
                  <tr key={id}>
                    <td>
                      <input
                        onChange={(e) => onChangeExcluded(e, index, 'nmopen-obj', valuesProperty, id)}
                        type="checkbox"
                        className="k-checkbox"
                        checked={val['@miextsss:excluded'] === 'true' ? false : true}
                      />
                    </td>
                    <td>{val.values.value['@miextsss:code']}</td>
                    <td>
                      <div className="form-group m-0">
                        <Input
                          onChange={(e) => onChangeHandler(e, index, 'questionValue', 'title', id, valuesProperty, 'nmopen-obj')}
                          type="text"
                          className="form-control"
                          value={val.values.value.text}
                        />
                      </div>
                    </td>
                  </tr>
                )
              }
              return (
                <tr key={id}>
                  <td>
                    <input
                      onChange={(e) => onChangeExcluded(e, index, 'nmopen-arr', valuesProperty, id)}
                      type="checkbox"
                      className="k-checkbox"
                      checked={val['@miextsss:excluded'] === 'true' ? false : true}
                    />
                  </td>
                  <td>{val['@miextsss:code']}</td>
                  <td>
                    <div className="form-group m-0">
                      <Input
                        onChange={(e) => onChangeHandler(e, index, 'questionValue', 'title', id, valuesProperty, 'nmopen-arr-no-values')}
                        type="text"
                        className="form-control"
                        value={val.label?.text}
                      />
                    </div>
                  </td>
                </tr>
              )
            }) : null
        }
      </tbody>
    );
  }
}