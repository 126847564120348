import returnItemsInRange from "../returnItemsInRange/returnItemsInRange"

export default (rangeItems, data, itemId) => {
  let list = rangeItems
  data.forEach((item, key) => {
    if (item.id === itemId) {
      if (rangeItems.length === 0) {
        item.selected = true
        list.push({ id: item.id, index: key })
      }
      else if (rangeItems.length === 1) {
        if (rangeItems.filter(el => el.id === itemId).length !== 0) {
          item.selected = false
          list = []
        }
        else {
          item.selected = true
          list.push({ id: item.id, index: key })
          const itemsInRange = returnItemsInRange(list, data)
          data.forEach(el => {
            if (itemsInRange.filter(item => item.id === el.id).length !== 0) {
              el.selected = true
            }
          })
        }
      }
      else if (rangeItems.length === 2) {
        if (rangeItems.filter(el => el.id === itemId).length !== 0) {
          item.selected = false
          list = list.filter(el => el.id !== itemId)
        }
        else {
          const itemsInRange = returnItemsInRange(list, data)
          if (itemsInRange.filter(el => el.id === itemId).length !== 0 && itemId !== itemsInRange[0] && itemId !== itemsInRange[itemsInRange.length - 1]) {
            data.forEach(el => {
              if (itemsInRange.filter(item => item.id === el.id).length !== 0) {
                if (el.id === itemId) {
                  el.selected = false
                }
              }
            })
          }
          else {
            data.find(item => item.id === list[1].id).selected = false
            item.selected = true
            list.splice(1, 1, { id: item.id, index: key })
            const itemsInRange = returnItemsInRange(list, data)
            data.forEach(el => {
              if (itemsInRange.filter(item => item.id === el.id).length !== 0) {
                el.selected = true
              }
            })
          }
        }
      }
    }
  })

  return {
    data, list
  }
}