interface Props {
  id: number
  selectionData: TODO
  selectedSession: TODO
  document: { name: string, id: string, sessions: TODO }
  session: { name: string, description: string, createdUtc: string, createdByName: string, id: string }
  setSelectionData: (data: TODO) => void
  showDeleteSessionModal: (e: TODO, id: string) => void
}

export const SessionCard = ({ selectedSession, document, session, id, setSelectionData, selectionData, showDeleteSessionModal }: Props) => {
  const onSessionClickHandler = () => {
    if (session.id === selectedSession?.id) {
      setSelectionData({ ...selectionData, selectedSession: null })
    } else {
      setSelectionData({ ...selectionData, selectedSession: session, selectedDocument: document })
    }
  }

  return (
    <div className="col-md-3 pb-3">
      <div
        onClick={onSessionClickHandler}
        className={`card session-card border shadow-sm h-100 text-muted ${selectedSession === id && 'shadow'}`}
        style={{ cursor: 'pointer', transition: '.15s' }}
      >
        <div className={`card-body d-flex flex-column justify-content-between p-3 ${session.id === selectedSession?.id ? 'text-primary' : null}`}>
          <div className="d-flex justify-content-between">
            <div style={{ wordBreak: 'break-word' }} className="mr-3">
              <h5 className="card-title m-0">{session.name}</h5>
              <p className="card-text">{session.description}</p>
            </div>
            <div>
              <i className="fas fa-file-alt fa-3x" />
            </div>
          </div>

          <div className="d-flex flex-column pt-3">
            <span>{session.createdUtc.substring(0, 10)}</span>
            <span>{session.createdByName}</span>
          </div>
        </div>
        <button
          type="button"
          onClick={(e) => showDeleteSessionModal(e, session.id)}
          style={{ transition: '0s' }}
          className={`btn btn-block btn-white border-top ${selectedSession === id ? 'text-primary' : 'text-muted'}`}>
          <i className="fas fa-trash-alt pr-2" />
          <span>Delete</span>
        </button>
      </div>
    </div>
  )
}