import React from 'react';

import { EditTabContent } from './EditTabContent/EditTabContent';
import { LogicTabContent } from './LogicTabContent/LogicTabContent';
import { PreviewTabContent } from './PreviewTabContent/PreviewTabContent';


export const SurveyDesignTabs = ({ token, tabType, onShowOptions, onShowScript, showDeleteElementModal, updateElements, onShowCSS }) => {
  switch (tabType) {
    case "edit":
      return <EditTabContent
        onShowOptions={onShowOptions}
        onShowScript={onShowScript}
        showDeleteElementModal={showDeleteElementModal}
        updateElements={updateElements}
        token={token}
        onShowCSS={onShowCSS}
      />;
    case "logic":
      return <LogicTabContent
        token={token}
        updateElements={updateElements}
      />
    case "preview":
      return <PreviewTabContent
        token={token}
        type='edit'
        onShowScript={onShowScript}
      />
    default:
      return null;
  }
}