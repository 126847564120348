import type { Dispatch } from "redux";
import { returnCompatibleQuestionsForPasteEdits } from "../returnCompatibleQuestionsForPasteEdits/returnCompatibleQuestionsForPasteEdits";

type UpdatedQuestionsState = {
  id: string
  title: string
  type: string
  groups: { code: string }[]
  subGroups: { code: string }[]
}[];

type CrossTabQuestionEditorCopyData = {
  selectedQuestionId: string
  selectedQuestionType: string
  groups: { code: string }[]
  subGroups: { code: string }[]
}

type SetShowPasteEditsModal = (param: {
  show: boolean
  compatibility: 'oneQuestionNonActive' | 'multipleQuestionsIncludingActive' | 'multipleQuestionsExcludingActive'
  compatibleQuestions: UpdatedQuestionsState
}) => void;

type DispatchParam = {
  type: string
  payload: {
    msg: string
  }
}

export const questionEditorPasteEditsHandler = (updatedQuestionsState: UpdatedQuestionsState, crossTabQuestionEditorCopyData: CrossTabQuestionEditorCopyData, selectedQuestion: number, dispatch: Dispatch<DispatchParam>, setShowPasteEditsModal: SetShowPasteEditsModal) => {
  const filteredQuestionsState = updatedQuestionsState.filter(question => question.id !== crossTabQuestionEditorCopyData.selectedQuestionId);
  if (filteredQuestionsState.length > 1) {
    const compatibleQuestions = returnCompatibleQuestionsForPasteEdits(filteredQuestionsState, crossTabQuestionEditorCopyData);
    if (compatibleQuestions.length > 1) {
      if (compatibleQuestions.some((item) => item.id === updatedQuestionsState[selectedQuestion].id)) {
        setShowPasteEditsModal({ show: true, compatibility: 'multipleQuestionsIncludingActive', compatibleQuestions: compatibleQuestions });
      } else {
        setShowPasteEditsModal({ show: true, compatibility: 'multipleQuestionsExcludingActive', compatibleQuestions: compatibleQuestions });
      }
    } else if (compatibleQuestions.length === 1) {
      if (compatibleQuestions[0].id !== updatedQuestionsState[selectedQuestion].id) {
        setShowPasteEditsModal({ show: true, compatibility: 'oneQuestionNonActive', compatibleQuestions: compatibleQuestions });
      } else {
        updatedQuestionsState[selectedQuestion].groups = crossTabQuestionEditorCopyData.groups;
        if (crossTabQuestionEditorCopyData.subGroups) {
          updatedQuestionsState[selectedQuestion].subGroups = crossTabQuestionEditorCopyData.subGroups;
        }
      }
    } else {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'The copied edits are not compatible with any of the selected elements.' } })
    }
  } else if (filteredQuestionsState.length === 1) {
    const compatibleQuestions = returnCompatibleQuestionsForPasteEdits(filteredQuestionsState, crossTabQuestionEditorCopyData);
    if (compatibleQuestions.length === 1) {
      updatedQuestionsState[selectedQuestion].groups = crossTabQuestionEditorCopyData.groups;
      if (crossTabQuestionEditorCopyData.subGroups) {
        updatedQuestionsState[selectedQuestion].subGroups = crossTabQuestionEditorCopyData.subGroups;
      }
    } else {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'The copied edits are not compatible with the selected element.' } })
    }
  } else {
    dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'Please select a different element to the one you copied the edits from.' } })
  }
}