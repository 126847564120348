import { fetchPutJson as updateEmailProvider } from "../../../../../services/services"
import type { EmailProvider, EmailProviderFormData } from "../../../../../interfaces/emailProviderInterfaces/emailProviderInterfaces"

export const submitUpdateEmailProviderHandler = (formData: EmailProviderFormData, token: string, dispatch: TODO, setIsLoading: (val: boolean) => void, emailProvider: EmailProvider) => {
  const body = {
    smtpPassword: '',
    tags: formData.tags,
    smtpPort: formData.port,
    smtpRelay: formData.relay,
    smtpEnableSsl: formData.SSL,
    name: formData.providerName,
    smtpUsername: formData.username,
    description: formData.providerDescription
  }
  if (formData.newPassword || formData.confirmNewPassword) {
    // Update old data and new password (Reset password)
    if (formData.newPassword === formData.confirmNewPassword) {
      setIsLoading(true);
      body.smtpPassword = formData.newPassword
      updateEmailProvider(`emailproviders/${emailProvider.id}`, token, body)
        .then((res: TODO) => {
          setIsLoading(false);
          if (res && (!res.error || !res.message)) {
            dispatch({ type: 'UPDATE_EMAIL_PROVIDERS', payload: true })
            dispatch({ type: 'UPDATE_SHOW_EMAIL_PROVIDERS_MODAL', payload: { emailProvider: null, show: false } })
          } else {
            dispatch({ type: 'UPDATE_EMAIL_PROVIDERS_ERROR_MESSAGE', payload: 'Something went wrong, please try again!' })
          }
        })
    } else {
      dispatch({ type: 'UPDATE_EMAIL_PROVIDERS_ERROR_MESSAGE', payload: 'The passwords you entered do not match' })
    }
  } else {
    // Update old data only
    setIsLoading(true);
    updateEmailProvider(`emailproviders/${emailProvider.id}`, token, body)
      .then((res: TODO) => {
        setIsLoading(false);
        if (res && (!res.error || !res.message)) {
          dispatch({ type: 'UPDATE_EMAIL_PROVIDERS', payload: true })
          dispatch({ type: 'UPDATE_SHOW_EMAIL_PROVIDERS_MODAL', payload: { emailProvider: null, show: false } })
        } else {
          dispatch({ type: 'UPDATE_EMAIL_PROVIDERS_ERROR_MESSAGE', payload: 'Something went wrong, please try again!' })
        }
      })
  }
}