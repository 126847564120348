import React from 'react';
import { Input, Switch, NumericTextBox } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';

import disableOptionByPlan from '../disableOptionByPlan/disableOptionByPlan';
import { returnSetPreviousData } from '../optionsDialoghelpers/optionsDialogHelpers';
import { BaseDropDownList } from "../../../../../../shared/Inputs";

export default (
  data,
  dropdownValues,
  weightValues,
  initialOptionsValues,
  updateOptionsValues,
  groups,
  selectedQuestions,
  user,
  onSwitchChange,
  onValueChange,
  onDropdownChange,
  showFilterBuilder,
  clearQuestionOptions,
  optionId,
  tab,
  onApplyWeightToAllTabs,
  conflictedOptions,
) => {
  let optionType;
  let optionData;
  let exportOption;
  const hasQuestionSelected = selectedQuestions.length >= 1 ? true : false;
  const options = []
  const filterOptions = ['XtFilterCode', 'FilterCode', 'XtUniverseCode', 'UniverseCode', 'QtUniverseCode', 'QtFilterCode', 'Universe', 'Filter', 'GFilterCode', 'GUniverseCode']
  const weightOptions = ['SelectedWeight', 'XtSelectedWeight', 'GSelectedWeight', 'QtSelectedWeight']

  data.forEach((option, id) => {
    let disabledOption = disableOptionByPlan(option, user, groups, updateOptionsValues)
    const conflictedOptionWarning =
      <span className="ml-1" title={`The selected questions have different value for this option applied,\nany changes you make here will apply to all the questions you have selected.`} >
        <i className="fa fa-exclamation-triangle text-danger"></i>
      </span>;

    const valueHandler = (option) => {
      // Basic form of Cascading Options
      if (hasQuestionSelected) {
        if (selectedQuestions[0].options) {
          if (option.id in selectedQuestions[0].options) {
            return selectedQuestions[0].options[option.id]
          } else if (option.id in updateOptionsValues) {
            return updateOptionsValues[option.id]
          } else {
            return initialOptionsValues[option.id]
          }
        } else {
          if (option.id in updateOptionsValues) {
            return updateOptionsValues[option.id]
          } else {
            return initialOptionsValues[option.id]
          }
        }
      } else {
        if (option.id in updateOptionsValues) {
          return updateOptionsValues[option.id]
        } else {
          return initialOptionsValues[option.id]
        }
      }
    }

    const dropdownValueHandler = (option) => {
      // Basic form of Cascading Options but for Dropdowns
      // Instead of adding extra logic to valueHandler function, we have a separate function. Easier to read and work with

      if (hasQuestionSelected) {
        if (selectedQuestions[0].options) {
          if (option.id in selectedQuestions[0].options) {
            return dropdownValues.find(el => el.name === option.type).enumValues[selectedQuestions[0].options[option.id]]
          } else if (option.id in updateOptionsValues) {
            return dropdownValues.find(el => el.name === option.type).enumValues[updateOptionsValues[option.id]]
          } else {
            return dropdownValues.find(el => el.name === option.type).enumValues[initialOptionsValues[option.id]]
          }
        } else {
          if (option.id in updateOptionsValues) {
            return dropdownValues.find(el => el.name === option.type).enumValues[updateOptionsValues[option.id]]
          } else {
            return dropdownValues.find(el => el.name === option.type).enumValues[initialOptionsValues[option.id]]
          }
        }
      } else {
        if (option.id in updateOptionsValues) {
          return dropdownValues.find(el => el.name === option.type).enumValues[updateOptionsValues[option.id]]
        } else {
          return dropdownValues.find(el => el.name === option.type).enumValues[initialOptionsValues[option.id]]
        }
      }
    }

    if (option.type === 'Boolean') {
      options.push(
        <div key={id} className="form-group row flex-shrink-0 align-items-center">
          <div className="col-sm-9 d-flex flex-column">
            <label className="m-0">{option.name}{conflictedOptions.includes(option.id) && conflictedOptionWarning}</label>
            <small className="text-muted">{option.descr}</small>
          </div>
          <div className="col-sm-3 pl-8 pr-4 d-flex justify-content-end">
            <Switch
              checked={valueHandler(option)}
              onChange={(e) => onSwitchChange(option.id, e.value)}
              disabled={disabledOption}
              size="small"
            />
          </div>
        </div>
      )
    } else if (filterOptions.includes(option.id)) {
      const setType = returnSetPreviousData([option.id], tab)
      exportOption = option;

      if (!optionId.includes(option.id)) {
        optionType = setType.optionType
        optionData = option.id
      }

      options.push(
        <React.Fragment key={id}>
          <div className="form-group row flex-shrink-0 align-items-center">
            <div className="col-sm-8 d-flex flex-column">
              <label className="m-0">{option.name}{conflictedOptions.includes(option.id) && conflictedOptionWarning}</label>
              <small className="form-text text-muted">{option.descr}</small>
            </div>
            <div className="col-sm-4 d-flex flex-nowrap">
              <input className="form-control left-corners-round h-32"
                placeholder="Filter"
                value={valueHandler(option)}
                onChange={(e) => onValueChange(e, option.id)} />
              <Button
                className="btn btn-primary right-corners-round"
                iconClass="fas fa-ellipsis-h"
                onClick={() => showFilterBuilder(option.id)}
              />
            </div>
          </div>
        </React.Fragment >
      )
    } else if (weightOptions.includes(option.id)) {
      let value;

      if (updateOptionsValues[option.id] === '') {
        value = 'None'
      } else {
        if (hasQuestionSelected) {
          if (selectedQuestions[0].options) {
            value = selectedQuestions[0].options[option.id]
          } else {
            value = weightValues.find(el => el === updateOptionsValues[option.id])
          }
        } else {
          value = weightValues.find(el => el === updateOptionsValues[option.id])
        }
      }
      options.push(
        <div key={id}>
          <div className="form-group row flex-shrink-0 align-items-center">
            <div className="col-sm-8 d-flex flex-column">
              <label className="m-0">{option.name}{conflictedOptions.includes(option.id) && conflictedOptionWarning}</label>
              <small className="form-text text-muted">{option.descr}</small>
            </div>
            <div className="col-sm-4">
              <BaseDropDownList
                className="form-control h-32 p-0"
                data={weightValues}
                onChange={(event) => onDropdownChange(event, option.id)}
                value={value}
                disabled={disabledOption}
              />
            </div>
          </div>
          <div className="form-group row flex-shrink-0 align-items-center">
            <div className="col-sm-8 d-flex flex-column">
              <label className="m-0">Apply selected weights to all tabs</label>
            </div>
            <div className="col-sm-4 d-flex justify-content-end">
              <button
                className="btn btn-primary text-nowrap"
                style={{ maxHeight: "calc(1.5em + 0.75rem + 2px)" }}
                onClick={() => onApplyWeightToAllTabs(option.id)}>
                Apply weights to all tabs
              </button>
            </div>
          </div>
        </div>
      )
    } else if (option.type === 'String') {
      options.push(
        <div key={id} className="form-group row flex-shrink-0 align-items-center">
          <div className="col-sm-8 d-flex flex-column">
            <label className="m-0">{option.name}{conflictedOptions.includes(option.id) && conflictedOptionWarning}</label>
            <small className="form-text text-muted">{option.descr}</small>
          </div>
          <div className="col-sm-4">
            <Input className="form-control h-32"
              type="text"
              value={valueHandler(option)}
              onChange={(event) => onValueChange(event, option.id)}
              disabled={disabledOption} />
          </div>
        </div>
      )
    } else if (option.type === "Int32" || option.type === "Double") {
      options.push(
        <div key={id} className="form-group row flex-shrink-0 align-items-center">
          <div className="col-sm-8 d-flex flex-column">
            <label className="m-0">{option.name}{conflictedOptions.includes(option.id) && conflictedOptionWarning}</label>
            <small className="form-text text-muted">{option.descr}</small>
          </div>
          <div className="col-sm-4">
            <NumericTextBox className="form-control h-32 p-0"
              value={valueHandler(option)}
              onChange={(event) => onValueChange(event, option.id)}
              disabled={disabledOption} />
          </div>
        </div>
      )
    } else {
      if (dropdownValues.length !== 0 && dropdownValues.find(el => el.name === option.type).enumValues.length !== 0) {
        options.push(
          <div key={id} className="form-group row flex-shrink-0 align-items-center">
            <div className="col-sm-8 d-flex flex-column">
              <label className="m-0">{option.name}{conflictedOptions.includes(option.id) && conflictedOptionWarning}</label>
              <small className="form-text text-muted">{option.descr}</small>
            </div>
            <div className="col-sm-4">
              <BaseDropDownList
                className="form-control h-32 p-0"
                data={dropdownValues.find(el => el.name === option.type).enumValues}
                textField="text"
                onChange={(event) => onDropdownChange(event, option.id)}
                value={dropdownValueHandler(option)}
                disabled={disabledOption}
              />
            </div>
          </div>
        )
      }
    }
  })

  return {
    options,
    optionType,
    optionData,
    exportOption
  };
}