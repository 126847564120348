
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@progress/kendo-react-buttons';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { RadioButton, Input, Checkbox } from '@progress/kendo-react-inputs';

import { Avatar } from '../../../../../../../shared/Avatar/Avatar';
import { ErrorMessage } from '../../../../../../../shared/ErrorMessage/ErrorMessage';
import { InProgressOverlay } from '../../../../../../../shared/InProgressOverlay/InProgressOverlay';
import { returnOnSelectionChange, returnOnHeaderChange, returnSelectedDefinitions, returnOnSwitchChange, returnAllEnabled, returnAllDisabled, returnMergedQuestions, returnOnInsertColumn, returnOnDeleteColumn, validateWeightId } from '../../../../shared/helpers/WeightWizardHelpers/WeightWizardHelpers';
import { fetchGetJson as getWeightDefinitions, fetchGetJson as getAllWeightIds, fetchPost as createWeight } from '../../../../../../../../services/services';
import WeightActionButtons from '../CreateWeight/WeightActionButtons/WeightActionButtons';
import WeightGrid from '../CreateWeight/WeightGrid/WeightGrid';

const NumericQuestionsWeight = ({ token, datasetId, onClose }) => {
  const history = useHistory()
  const dispatch = useDispatch();
  const [createWeightStep, setCreateWeightStep] = useState(0)
  const [weightDefinitions, setWeightDefinitions] = useState([])
  const [isLoadingWeightDefinitions, setIsLoadingWeightDefinitions] = useState(true)
  const [selectedDefinitionTab, setSelectedDefinitionTab] = useState(0)
  const [selectedWeightType, setSelectedWeightType] = useState('cell')
  const [selectedWeightDefinitions, setSelectedWeightDefinitions] = useState([])
  const [weightDefinitionResponse, setWeightDefinitionResponse] = useState({})
  const [responseGridData, setResponseGridData] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)
  const [errorInputMessage, setErrorInputMessage] = useState(null)
  const params = useParams()
  const projectId = params.name

  const weightType = [{ label: 'Cell weighting (Combine questions into descrete weighting cells)', value: 'cell' },
  { label: 'Rim weighting (Combine questions as a separate weighting rim)', value: 'rim', }]

  useEffect(() => {
    getWeightDefinitions(`an/projects/${projectId}/analysis/${datasetId}/weight-candidates-all`, token)
      .then(res => {
        setIsLoadingWeightDefinitions(false)
        const list = res.numerics.map(el => { return { ...el, selected: false } })
        setWeightDefinitions(list)
      })
  }, [token, datasetId, projectId])

  const onSelectQuestion = (e, type, id) => {
    if (type === 'definitions') {
      const data = returnOnSelectionChange(e, type, id, weightDefinitions)
      setWeightDefinitions(data)
    }
    else {
      const data = returnOnSelectionChange(e, type, id, selectedWeightDefinitions)
      setSelectedWeightDefinitions(data)
    }
  }

  const headerSelectionChange = (e, type, id) => {
    if (type === 'definitions') {
      const data = returnOnHeaderChange(e, type, id, weightDefinitions)
      setWeightDefinitions(data)
    }
    else {
      const data = returnOnHeaderChange(e, type, id, selectedWeightDefinitions)
      setSelectedWeightDefinitions(data)
    }
  }

  const onSwitchChange = (item, type, id) => {
    const data = returnOnSwitchChange(item, type, id, selectedWeightDefinitions)
    setSelectedWeightDefinitions(data)
  }

  const onNextStep = () => {
    if (createWeightStep === 0 && weightDefinitions.filter(el => el.selected === true).length !== 0) {
      setCreateWeightStep(1)
      const questions = returnSelectedDefinitions(weightDefinitions)
      setSelectedWeightDefinitions(questions)
    }
    else if (createWeightStep === 1) {
      setCreateWeightStep(2)
    }
  }

  const onBackStep = () => {
    if (createWeightStep === 1) {
      setCreateWeightStep(0)
    }
    else if (createWeightStep === 2) {
      setCreateWeightStep(1)
      setErrorInputMessage(null)
      setErrorMessage(null)
    }
  }

  const onFinishWizard = () => {
    getAllWeightIds(`an/projects/${history.location.pathname.split('/')[2]}/analysis/${datasetId}/weight-ids`, token)
      .then(res => {
        const message = validateWeightId(weightDefinitionResponse.id, res)
        if (message !== '') {
          setErrorInputMessage(message)
        } else {
          setErrorInputMessage(null)
          const body = weightDefinitionResponse
          createWeight(`an/projects/${projectId}/analysis/${datasetId}/weights/questions/${selectedWeightDefinitions[0].id}`, token, body)
            .then(res => {
              if (res.status === 201) {
                onClose()
                dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The weight has been created successfully' } })
              } else (
                setErrorMessage(res.statusText)
              )
            })
        }
      })
  }

  const handleTypeChange = (e) => {
    setSelectedWeightType(e.value)
  }

  const onEnableAll = (id, type) => {
    const data = returnAllEnabled(id, type, selectedWeightDefinitions)
    setSelectedWeightDefinitions(data)
  }

  const onDisableAll = (id, type) => {
    const data = returnAllDisabled(id, type, selectedWeightDefinitions)
    setSelectedWeightDefinitions(data)
  }

  const onMergeQuestions = (id, type) => {
    const data = returnMergedQuestions(id, type, selectedWeightDefinitions)
    setSelectedWeightDefinitions(data)
  }

  const onInsertColumn = (id, type) => {
    const data = returnOnInsertColumn(id, type, selectedWeightDefinitions)
    setSelectedWeightDefinitions(data)
  }

  const onDeleteColumn = (id, type) => {
    const data = returnOnDeleteColumn(id, type, selectedWeightDefinitions)
    setSelectedWeightDefinitions(data)
  }

  const onItemChange = (e) => {
    e.dataItem[e.field] = e.value;
    const data = responseGridData.map(el => { return { ...el } })
    setResponseGridData(data)
  }

  return (
    <div className="d-flex flex-column h-100">
      {createWeightStep === 0 &&
        <div className="card weight-card flex-grow-1 overflow-hidden">
          <div className="card-header d-flex align-items-center">
            <Avatar value="1" class="avatar-sm" color="success" />
            <h2 className="m-0 h5">Select the questions to be used</h2>
          </div>
          {!isLoadingWeightDefinitions ?
            <div className="card-body p-0">
              {weightDefinitions.length !== 0 ?
                <WeightGrid
                  type='definitions'
                  data={weightDefinitions}
                  onSelectQuestion={onSelectQuestion}
                  headerSelectionChange={headerSelectionChange}
                />
                :
                <p className='text-center text-muted mt-5'>No numeric questions found</p>}
            </div>
            :
            <InProgressOverlay type="fullscreen" theme="primary" />}
        </div>}
      {createWeightStep === 1 &&
        <div className="card weight-card">
          <div className="card-header d-flex align-items-center">
            <Avatar value="2" class="avatar-sm" color="success" />
            <h2 className="m-0 h5">Select weight type and modify questions (optional)</h2>
          </div>
          <div className="card-body">
            <div className="d-flex flex-column px-3 mt-2">
              {
                weightType.map((el, id) => (
                  <div className={`${id === 0 ? 'mb-3' : 'mb-1'}`} key={id}>
                    <RadioButton
                      name="weightType"
                      value={el.value}
                      checked={selectedWeightType === el.value}
                      label={el.label}
                      onChange={handleTypeChange}
                    />
                  </div>
                ))
              }
            </div>
            <TabStrip selected={selectedDefinitionTab} onSelect={(e) => setSelectedDefinitionTab(e.selected)} className="weight-tabs">
              {selectedWeightDefinitions.map((item, key) => {
                return (
                  <TabStripTab title={item.qno.slice(0, -2)} key={key}>
                    <div className="d-flex flex-column p-3 pb-5">
                      <span className="h5 mb-3">{item.texts.en}</span>
                      <div className="d-flex flex-column mb-5">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <span className="h6">Categories</span>
                          <WeightActionButtons
                            type="categories"
                            data={item.type !== 'rm' && item.type !== 'rn' ? item.rows : item.columns}
                            onEnableAll={() => onEnableAll(item.id, 'categories')}
                            onDisableAll={() => onDisableAll(item.id, 'categories')}
                            onMergeQuestions={() => onMergeQuestions(item.id, 'categories')}
                            onInsertColumn={() => onInsertColumn(item.id, 'categories')}
                            onDeleteColumn={() => onDeleteColumn(item.id, 'categories')} />
                        </div>
                        <WeightGrid
                          type='categories'
                          data={item.type !== 'rm' && item.type !== 'rn' ? item.rows : item.columns}
                          onSelectQuestion={onSelectQuestion}
                          headerSelectionChange={headerSelectionChange}
                          id={item.id}
                          onSwitchChange={onSwitchChange}
                        />
                      </div>
                      {(item.type === 'rm' || item.type === 'rn') && item.columns ?
                        <div className="d-flex flex-column pb-5">
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <span className="h6">Response rows</span>
                            <WeightActionButtons
                              type="responseRows"
                              data={item.rows}
                              onEnableAll={() => onEnableAll(item.id, 'responseRows')}
                              onDisableAll={() => onDisableAll(item.id, 'responseRows')}
                              onMergeQuestions={() => onMergeQuestions(item.id, 'responseRows')}
                              onInsertColumn={() => onInsertColumn(item.id, 'responseRows')}
                              onDeleteColumn={() => onDeleteColumn(item.id, 'responseRows')}
                            />
                          </div>
                          <WeightGrid
                            type='responseRows'
                            data={item.rows}
                            onSelectQuestion={onSelectQuestion}
                            headerSelectionChange={headerSelectionChange}
                            id={item.id}
                            onSwitchChange={onSwitchChange}
                          />
                        </div> : null}
                    </div>
                  </TabStripTab>
                )
              })}
            </TabStrip>
          </div>
        </div>}
      {
        createWeightStep === 2 &&
        <div className="card weight-card flex-grow-1 overflow-hidden">
          <div className="card-header d-flex align-items-center">
            <Avatar value="3" class="avatar-sm" color="success" />
            <h2 className="m-0 h5">Provide weight information and relative values for created rims or cells</h2>
          </div>
          <div className="card-body">
            <div className="d-flex flex-column h-100">
              <div className="d-flex flex-column px-3 pt-3">
                <Input
                  required
                  onChange={e => setWeightDefinitionResponse({ ...weightDefinitionResponse, id: e.target.value })}
                  value={weightDefinitionResponse.id}
                  name="id"
                  placeholder="Enter weight name"
                  className="w-100 mb-3"
                />
                {errorInputMessage !== null &&
                  <div>
                    <ErrorMessage type="alert" errorMessage={errorInputMessage} />
                  </div>}
                <Input
                  required
                  onChange={e => setWeightDefinitionResponse({ ...weightDefinitionResponse, text: e.target.value })}
                  value={weightDefinitionResponse.text}
                  name="desc"
                  placeholder="Enter weight description"
                  className="w-100 mb-3"
                />
                <Input
                  required
                  onChange={e => setWeightDefinitionResponse({ ...weightDefinitionResponse, population: e.target.value })}
                  value={weightDefinitionResponse.population}
                  type="number"
                  name="id"
                  placeholder="Enter population (numeric)"
                  className="w-100 mb-3"
                />
                <Checkbox
                  value={weightDefinitionResponse.autoCorrect}
                  onChange={e => setWeightDefinitionResponse({ ...weightDefinitionResponse, autoCorrect: e.value })}
                  label="Auto correct"
                  className="mb-3"
                />
              </div>
              {responseGridData.length !== 0 ?
                <WeightGrid
                  type='rimGrid'
                  data={responseGridData}
                  onItemChange={onItemChange}
                />
                :
                null}
            </div>
          </div>
          {errorMessage !== null &&
            <div>
              <ErrorMessage type="alert" errorMessage={errorMessage} />
            </div>}
        </div>
      }

      <div className="d-flex flex-grow-1 justify-content-end align-items-center m-2">
        <Button
          className="btn btn-outline-primary d-flex"
          icon="fas fa fa-arrow-circle-left"
          onClick={onBackStep}
          disabled={createWeightStep === 0 ? true : false}
        >Back</Button>
        {createWeightStep !== 2 ?
          <Button
            className="btn btn-outline-primary d-flex flex-row-reverse weight-next-button mx-2"
            icon="fas fa fa-arrow-circle-right"
            disabled={weightDefinitions.filter(el => el.selected === true).length === 0 ? true : false}
            onClick={onNextStep}
          >Next</Button>
          :
          <Button
            className="btn btn-primary d-flex flex-row-reverse weight-next-button mx-2"
            disabled={weightDefinitions.filter(el => el.selected === true).length === 0 ? true : false}
            onClick={onFinishWizard}
          >Finish</Button>
        }
      </div>
    </div >
  );
};

export default NumericQuestionsWeight;
