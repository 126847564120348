import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Editor from '@monaco-editor/react';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { ErrorMessage } from '../../ErrorMessage/ErrorMessage';
import { fetchPatchJson as editWeight } from "../../../../services/services";

interface WeightInterface {
  id: string;
  definition: string;
  pop?: number;
  selected?: boolean,
  text: string
}

interface Props {
  token: string;
  handleClose: () => void;
  setWeightsData: Function;
  editedWeight: WeightInterface;
  datasetId: string;
  type: string;
  submitButtonDisabled: boolean;
}
export const UpdateModal = ({ handleClose, setWeightsData, editedWeight, datasetId, type, submitButtonDisabled, token }: Props) => {
  const [editorValue, setEditorValue] = useState(editedWeight.definition);
  const [editorTheme, setEditorTheme] = useState('vs-light');
  const [errorMessage, setErrorMessage] = useState(null);
  const handleEditorChange = (value: any) => setEditorValue(value);
  const changeTheme = () => setEditorTheme((prevTheme) => (prevTheme === 'vs-light' ? 'vs-dark' : 'vs-light'));
  const history = useHistory();
  const dispatch = useDispatch();

  const openHelp = () => window.open('https://help.researchstudio.net/article/777-how-to-create-a-scripted-weight');

  const handleSubmit = (definition: string) => {
    // @ts-ignore - Suppress warning until services.js gets updated with TypeScript
    editWeight(`an/projects/${history.location.pathname.split('/')[2]}/analysis/${datasetId}/weights/${editedWeight.id}`, token, { definition })
      .then((res: any) => res.json())
      .then((result: any) => {
        if (result.error || result.message) {
          setErrorMessage(result.error ? result.error : result.message)
        } else {
          setWeightsData(result);
          dispatch({ type: 'UPDATE_TABLE', payload: true })
        }
      })
  };

  // @ts-ignore
  const onMounthHandler = (editor) => {
    editor.onDidScrollChange(function () {
      editor._actions['editor.action.formatDocument']._run();
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      title={type !== 'element' ? `Update '${editedWeight.id}'` : `Update ${editedWeight.id}`}
      className="d-flex flex-column weight-upd-modal"
      height={'90%'}
      width={'70%'}
    >
      <div className="card weight-card flex-grow-1">
        {errorMessage && <ErrorMessage type="alert" errorMessage={errorMessage} />}
        <Editor
          onMount={onMounthHandler}
          onChange={handleEditorChange}
          value={editorValue}
          options={{
            autoIndent: 'full',
            tabSize: 2,
            formatOnPaste: true,
            formatOnType: true,
          }}
          theme={editorTheme}
          defaultLanguage="html"
        />
      </div>
      <div className="d-flex flex-grow-0 m-2">
        <Button
          id="help-button"
          title="Open scripted weights documentation"
          className="btn btn-outline-dark d-flex weight-next-button"
          icon="fas fa fa-question-circle m-0 mr-2"
          onClick={openHelp}
        >
          Help
        </Button>
        <Button
          id="theme-button"
          className="btn btn-outline-dark d-flex weight-next-button ml-auto"
          icon={`fas fa ${editorTheme === 'vs-light' ? 'fa-moon' : 'fa-sun'} m-0 mr-2`}
          onClick={changeTheme}
        >
          Theme
        </Button>
      </div>
      <DialogActionsBar>
        <button className="k-button btn btn-secondary" onClick={handleClose}>
          Cancel
        </button>
        <button className="k-button btn btn-primary" disabled={submitButtonDisabled} onClick={() => handleSubmit(editorValue)}>
          Update
        </button>
      </DialogActionsBar>
    </Dialog>
  );
};

