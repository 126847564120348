
export const returnUpdatedNumericListData = (result: any, newData: any) => {
  if (Array.isArray(result.sss.survey.record["miextsss:numericlist"])) {
    result.sss.survey.record["miextsss:numericlist"].forEach((ques, id) => {
      newData.push({
        "@ident": ques["@ident"],
        "@miextsss:type": "integer",
        "checked": false,
        "id": ques["@miextsss:qno"],
        "index": id,
        "order": Number(ques["@miextsss:docorder"]),
        "position": ques.position ? ques.position : "",
        "selected": false,
        "title": ques["miextsss:label"].text,
        "type": ques['@type'] ? ques['@type'] : ques['@miextsss:type'] ? ques["@miextsss:type"] : "",
        "disabled": ques["@miextsss:excluded"] === "true" ? true : false
      })
    })
  } else {
    const ques = result.sss.survey.record["miextsss:numericlist"]
    newData.push({
      "@ident": ques["@ident"],
      "@miextsss:type": "integer",
      "checked": false,
      "id": ques["@miextsss:qno"],
      "index": 0,
      "order": Number(ques["@miextsss:docorder"]),
      "position": ques.position ? ques.position : "",
      "selected": false,
      "title": ques["miextsss:label"].text,
      "type": ques['@type'] ? ques['@type'] : ques['@miextsss:type'] ? ques["@miextsss:type"] : "",
      "values": ques.variable,
      "disabled": ques["@miextsss:excluded"] === "true" ? true : false
    })
  }
  return newData;
}
