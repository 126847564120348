export const groupCustomQuestions = (updatedCustomQuestions: []) => {
  // @ts-ignore
  const groupedMap = updatedCustomQuestions.reduce((map, { group, ...value }) => {
    if (!map.has(group)) {
      map.set(group, [])
    }
    map.get(group).push(value)
    return map
  }, new Map())

  const groupedCustomQuestions = Array.from(groupedMap, ([group, value]) => ({
    group,
    value
  }));

  return groupedCustomQuestions
}