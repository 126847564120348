import type React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';

import { Icon } from '../../../../../../../shared/Icon/Icon';
import type { RootState } from '../../../../../../../../store/reducers/rootReducer';
import { SurveyFilterBuilder } from '../../../../SurveyFilterBuilder/SurveyFilterBuilder';
import { returnSurveyDesignData } from '../../../../helpers/returnDataHelpers/returnDataHelpers';
import { fetchPutJson } from '../../../../../../../../services/services';

interface IProps {
  onClose: () => void;
  items: [];
  listItem: TODO;
  itemSelectedKey: number;
  showFilterBuilder: () => void;
  setOpenFilterBuilder?: (open: boolean) => void;
}

export const SurveyDesignFilterQuestionsModalView: React.FC<IProps> = ({ onClose, items, listItem, itemSelectedKey, showFilterBuilder }) => {
  const [openFilterBuilder, setOpenFilterBuilder] = useState(false);
  const [filterSelected, setFilterSelected] = useState("")
  const { theData } = useSelector((theState: RootState) => (theState.surveyInitialDataReducer));
  const { data } = useSelector((theState: RootState) => (theState.breadcrumbStateReducer));
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch();
  const filterArray = Array.from({ length: 26 }, (_, i) => `filter${String.fromCharCode(97 + i)}`)

  const inputFilterChange = (e: TODO, _key: number, name: string) => {
    const lists = theData.originalData.lists
    lists[theData.selectedListIndex].elements[itemSelectedKey].attributes[name] = e.target.value
    dispatch({ type: 'ADD_LIST', payload: lists });
  }

  const onSaveList = () => {
    fetchPutJson(`su/projects/${data.projectId}/surveys/${data.documentId}/index`, token, theData.originalData)
      .then((res: TODO) => {
        if (res.error || res.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
        } else {
          const originalData = res;
          const data = returnSurveyDesignData(res);
          dispatch({ type: 'UPDATE_SURVEY_DATA', payload: { data: data, originalData: originalData } });
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "Filters have been updated" } });
          onClose()
        }
      })
  };

  const saveFilterData = (expression: string) => {
    const lists = theData.originalData.lists
    lists[theData.selectedListIndex].elements[itemSelectedKey].attributes[filterSelected] = expression
    setOpenFilterBuilder(false)
  }

  return (
    <Dialog width="70%" height="60%" title="Edit question filters" onClose={onClose}>
      {openFilterBuilder &&
        <SurveyFilterBuilder
          handleClose={() => {
            setOpenFilterBuilder(false);
            // setListKeyClicked(0)
            dispatch({ type: 'SURVEY_DISPLAY_FILTER_BUILDER', payload: { display: false, filterData: [], combineFilterData: [] } });
          }}
          filterData={theData.filterData}
          surveyId={theData.data.documentId}
          combineFilterData={theData.combineFilterData}
          // manualFilterExpression={theData.originalSelectedItem.elements.filter(el => el.type === 'subq')[0].attributes["filter"]}
          option='filter'
          token={token}
          type='list-filters'
          onSaveFilter={saveFilterData}
          manualFilterExpression={undefined}
          onSaveTextFilter={undefined}
          selectedTab={undefined}
        />}
      <div className="table-responsive h-100 advanced-dialog">
        <table className="table table-sm answer-layout-table mb-0">
          <thead className='table-header'>
            <tr>
              <td className='w-25'><span>Description</span></td>
              <td className='w-100'><span>Filter</span></td>
            </tr>
          </thead>
          <tbody>
            {items?.map((item: TODO, key: number) => {
              return (
                <tr key={key}>
                  <td id='input'><span className='text-left px-3 truncate'>{item.name}</span></td>
                  <td id='input'>
                    <div className="d-flex flex-row no-wrap align-items-center">
                      <input name={filterArray[key]} className="form-control shadow-none text-primary" value={listItem.attributes[filterArray[key]]} onChange={(e) => inputFilterChange(e, key, filterArray[key])} />
                      <div>
                        <button
                          type='button'
                          className="btn btn-icon shadow-none dropdown-toggle btn-transparent p-0 mr-2 ml-2"
                          onClick={() => {
                            showFilterBuilder()
                            setFilterSelected(filterArray[key])
                            setOpenFilterBuilder(true)
                          }}>
                          <Icon type="dashboard-edit" />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <DialogActionsBar>
        <Button className="btn btn-secondary" onClick={onClose}>Cancel</Button>
        <Button className="btn btn-primary" onClick={onSaveList}>Save</Button>
      </DialogActionsBar>
    </Dialog>
  )
}
