import { Fragment } from 'react';

import type { FileManagerActionButton as FileManagerActionButtonType } from '../../../../interfaces/fileManagerInterfaces/fileManagerInterfaces';
import { Icon } from '../../Icon/Icon';

interface Props {
  uploadValue: string
  item: FileManagerActionButtonType
  onActionHandler: (e: TODO, item: FileManagerActionButtonType) => void
}

export const FileManagerActionButton = ({ uploadValue, onActionHandler, item }: Props) => {
  if (item.text === 'Upload') {
    return (
      <Fragment>
        <input value={uploadValue} onChange={(e) => onActionHandler(e, item)} id="file-upload" type="file" className="d-none" multiple />
        <label htmlFor="file-upload" className="btn btn-icon icon-l btn-shadow mr-2">
          <Icon type={item.icon} className='mr-05' />
          <span>{item.text}</span>
        </label>
        <span className='divider' />
      </Fragment>
    )
  } if (item.text === 'New folder') { // Hide New folder - we could remove it all together as it is replaced by another button
    return (
      <Fragment>
        <button onClick={(e) => onActionHandler(e, item)} disabled={item.disabled} type="button" className="btn btn-icon icon-l btn-primary mr-2 d-none">
          <Icon type={item.icon} />
          <span>{item.text}</span>
        </button>
      </Fragment>
    )
  }
  return (
    <button onClick={(e) => onActionHandler(e, item)} disabled={item.disabled} type="button" className="btn btn-icon icon-l btn-shadow mr-2">
      <Icon type={item.icon} className='mr-05' />
      <span>{item.text}</span>
    </button>
  )
}