import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { NumericTextBox, Slider, SliderLabel } from '@progress/kendo-react-inputs';

import { RootState } from '../../../../../../store/reducers/rootReducer';
import { FullHeightSpinner } from '../../../../../shared/FullHeightSpinner/FullHeightSpinner';
import { ActionButtons } from '../../helpers/ActionButtons/ActionButtons';

const sliderItems = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

interface Props {
  step: string
  onNextStep: (type: string) => void
  onReviewAndSubmit: () => void
}

export const General = ({ step, onNextStep, onReviewAndSubmit }: Props) => {
  const { panelData } = useSelector((theState: RootState) => theState.panelStateReducer);
  const [selectedTarget, setSelectedTarget] = useState<string>('sample')
  const [responseRate, setResponseRate] = useState<number>(0)
  const [targetInterviews, setTargetInterviews] = useState<number | null>(null)

  const dispatch = useDispatch()

  useEffect(() => {
    if (targetInterviews === null && panelData.sampleSpecification !== null) {
      setTargetInterviews(panelData.sampleSpecification.targetInterviews === 0 ? 1000 : panelData.sampleSpecification.targetInterviews)
    }
  }, [targetInterviews, panelData])


  const onChangeHandler = (value: any, name: string) => {
    dispatch({ type: 'UPDATE_SAMPLE_SPECIFICATION_DATA', payload: { ...panelData.sampleSpecification, [name]: value } })
  }

  return (
    <div className="d-flex flex-column container-fluid p-0 h-100">
      {targetInterviews ?
        <div className="row no-gutters d-flex flex-column border-bottom align-items-center flex-grow-1">
          <div className="d-flex flex-column p-3 align-items-center w-100 border-bottom flex-grow-1">
            <span className="mb-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
            <div className="d-flex flex-column col-md-6 align-items-center">
              <ButtonGroup className="mb-3">
                <Button
                  togglable={true}
                  className="w-50 btn-lg"
                  selected={selectedTarget === 'sample'}
                  onClick={() => setSelectedTarget('sample')}
                  disabled={panelData.sampleData.isSubmitNow}
                >Sample</Button>
                <Button
                  togglable={true}
                  className="w-50 btn-lg"
                  selected={selectedTarget === 'completes'}
                  onClick={() => setSelectedTarget('completes')}
                  disabled={panelData.sampleData.isSubmitNow}
                >Completes</Button>
              </ButtonGroup>
              <h5 className="mb-3">Target interviews</h5>
              <NumericTextBox className="numeric-text-box-lg"
                min={0}
                value={panelData.sampleSpecification.targetInterviews}
                name="targetInterviews"
                onChange={(e: any) => onChangeHandler(e.target.value, e.target.name)}
                disabled={panelData.sampleData.isSubmitNow}
              />
            </div>
          </div>
          <div className={`d-flex flex-column align-items-center p-3 pb-4 w-100 ${selectedTarget === 'sample' ? 'bg-light' : 'bg-white'}`}>
            <span className={`mb-3 ${selectedTarget === 'sample' ? 'text-disabled' : 'text-dark'}`}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
            <div className="d-flex flex-column col-md-8 align-items-center">
              <h5 className={`mb-3 ${selectedTarget === 'sample' ? 'text-disabled' : 'text-dark'}`}>Response rate</h5>
              <div className="d-flex align-items-center w-100">
                <Slider min={0} max={100} step={10}
                  className="w-100 mr-5 mb-3" value={responseRate}
                  onChange={(e: any) => setResponseRate(Math.floor(e.value))}
                  disabled={selectedTarget === 'sample'}>
                  {sliderItems.map((item, key) => (
                    <SliderLabel position={item} key={key} onClick={() => setResponseRate(item)}>
                      {!(item !== 0 && item !== 100) &&
                        <span>{item} %</span>}
                    </SliderLabel>))}
                </Slider>
                <NumericTextBox
                  disabled={selectedTarget === 'sample'}
                  value={responseRate}
                  onChange={(e: any) => setResponseRate(Math.floor(e.value))}
                  max={100}
                  min={0} />
              </div>
            </div>
          </div>
        </div>
        :
        <FullHeightSpinner />}
      <ActionButtons
        step={step}
        onNextStep={onNextStep}
        onReviewAndSubmit={onReviewAndSubmit}
        targetInterviews={panelData.sampleSpecification.targetInterviews}
      />
    </div>
  )
}

