import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form } from '@progress/kendo-react-form';
import { Stepper } from '@progress/kendo-react-layout';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { DialogActionsBar, Dialog } from '@progress/kendo-react-dialogs';

import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { parseJwt } from '../helpers/decodeJWT/decodeJWT';
import { useFetchTags } from '../customHooks/useFetchTags';
import { BaseDropDownList, BaseMultiSelect } from "../../shared/Inputs"
import ProjectActionButtons from '../ProjectActionButtons/ProjectActionButtons';
import { returnProjectsData } from '../helpers/project/returnProjectsData/returnProjectsData';
import { fetchPostJson as addSurvey, fetchGetJsonOrEmptyArray as getDatasetsFromProject, rsAppUrl } from '../../../services/services';

const items = [{ label: "General settings" }, { label: "Add dataset" }]

export default function AddSurveyFromDatasetModal({ token, handleClose }) {
  const params = useParams()
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ name: '', description: '', languages: [], tags: [], projectId: null, datasetId: null })
  const [errorMessage, setErrorMessage] = useState(null)
  const [stepperValue, setStepperValue] = useState(0)
  const [projects, setProjects] = useState([])
  const [datasets, setDatasets] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [DCVersion, setDCVersion] = useState(1)

  const tags = useFetchTags(token)
  const projectId = params.name

  // For now, only show the DC version toggle for the two customers that have access to it (Walr Staging and Walr Production customers)
  const parsedToken = parseJwt(token);
  const customerId = parsedToken[`${rsAppUrl}/customerid`];
  const showDCVersionToggle = (customerId === '024f7442-48b8-4b31-9b07-92421f0e602f' || customerId === '3ef11f3f-290d-4a64-925c-34ae02f863ce') ? true : false;

  useEffect(() => {
    returnProjectsData(token, dispatch)
      .then(res => {
        if (res) {
          setProjects(res.originalData)
        }
      })
    getDatasetsFromProject(`projects/${projectId}/datasets`, token)
      .then(res => {
        if (res) {
          setDatasets(res)
          setFormData({ ...formData, datasetId: res.length > 0 ? res[0].id : null, projectId: projectId })
        }
      })
  }, [])

  const onChangeHandler = (e) => {
    if (e.target.name === 'projectId') {
      getDatasetsFromProject(`/projects/${e.target.value.id}/datasets`, token)
        .then(res => {
          if (res) {
            setDatasets(res)
            setFormData({
              ...formData,
              [e.target.name]: e.target.value.id,
              datasetId: res.length > 0 ? res[0].id : null
            })
          }
        })
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.name !== 'datasetId' ? e.target.value : e.target.value.id,
      })
    }
  }
  const handleChange = (e) => {
    setStepperValue(e.value)
  }

  const onSubmitHandler = (e) => {
    e.preventDefault()
    const body = {
      'name': formData.name,
      'description': formData.description,
      'tags': formData.tags,
      'dataCollectionVersion': DCVersion
    }

    if (formData.name === '' || formData.datasetId === null) {
      setErrorMessage(`${formData.datasetId === null && formData.name === '' ? "Survey name and dataset" : formData.datasetId === null ? "Dataset" : "Survey name"} cannot be empty`)
    } else {
      setIsLoading(true)
      addSurvey(`su/projects/${projectId}/surveys/from-dataset/${formData.projectId}/${formData.datasetId}`, token, body)
        .then(res => {
          setIsLoading(false)
          if (res && res.surveyId) {
            handleClose()
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Creating survey from dataset started' } })
          } else if (res && (res.message || res.error)) {
            setErrorMessage(res.message ? res.message : res.error)
          }
        })
    }
  }

  const listNoDataRender = () => {
    return (
      <h5 className="text-muted text-center">
        <span className="k-icon k-i-warning my-3" style={{ fontSize: '2.5em' }} />
        <br />
        <span>No tags created yet</span>
      </h5>
    );
  }

  return (
    <Dialog title="Create survey from dataset" onClose={handleClose} width={650} height={600}>
      <Form
        onSubmit={onSubmitHandler}
        render={(formRenderProps) => (
          <div className="p-4">
            <div className="pb-3">
              <Stepper value={stepperValue} onChange={handleChange} items={items} />
            </div>
            {stepperValue === 0 ?
              <div className="d-flex flex-column">
                <Input
                  name="name"
                  className="w-100 mb-2"
                  label="Name"
                  required={true}
                  value={formData.name}
                  onChange={onChangeHandler}
                />
                <Input
                  className="w-100 mb-2"
                  name="description"
                  label="Description"
                  value={formData.description}
                  onChange={onChangeHandler}
                />
                <BaseMultiSelect
                  data={tags ? tags : []}
                  value={formData.tags}
                  label="Tags"
                  textField="name"
                  dataItemKey="id"
                  name="tags"
                  allowCustom={true}
                  listNoDataRender={listNoDataRender}
                  onChange={onChangeHandler}
                />
                {showDCVersionToggle && (
                  <div className="d-flex justify-content-between align-items-center form-control mt-5 py-5">
                    <p className="mr-3 mb-0 text-primary">Use Data Collection V2</p>
                    <Switch checked={DCVersion === 2 ? true : false} onChange={(e) => setDCVersion(e.value ? 2 : 1)} />
                  </div>
                )}
              </div> :
              <div className="d-flex flex-column">
                <BaseDropDownList
                  data={projects}
                  textField="name"
                  label="Projects"
                  defaultValue={projects.find(project => project.id === formData.projectId)}
                  className="w-100 mb-2"
                  onChange={onChangeHandler}
                  name="projectId" />
                <BaseDropDownList
                  data={datasets}
                  textField="name"
                  label="Datasets"
                  defaultValue={datasets.filter(el => el.id === formData.datasetId).length > 0 ? datasets.find(dataset => dataset.id === formData.datasetId) : datasets[0]}
                  className="w-100 mb-2"
                  onChange={onChangeHandler}
                  name="datasetId" />
              </div>}
            {
              errorMessage &&
              <ErrorMessage
                type="alert"
                errorMessage={errorMessage} />
            }
          </div>
        )}>
      </Form>
      <DialogActionsBar>
        <ProjectActionButtons
          submitAction={'Create'}
          onHide={handleClose}
          stepperValue={stepperValue}
          steps={items.length}
          isLoading={isLoading}
          handleChange={handleChange}
          onSubmitHandler={onSubmitHandler} />
      </DialogActionsBar>
    </Dialog>
  )
}