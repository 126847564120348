import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DropDownButton } from '@progress/kendo-react-buttons';
import { Input, TextArea } from '@progress/kendo-react-inputs';

import { fetchPostJson as testEmail } from '../../../../../../../services/services';
import { BaseDropDownList, BaseMultiSelect } from "../../../../../../shared/Inputs";
import { fetchPutResOrJson as updateComposeSettings, fetchGetJson as getRecipientsRows } from '../../../../../../../services/services'
import EmailEditor from '../../../helpers/EmailEditor/EmailEditor';
import { FullHeightSpinner } from '../../../../../../shared/FullHeightSpinner/FullHeightSpinner';
import { returnComposeBody, returnComposeStateData, returnSmsCount } from '../../../helpers/helpers';
import ConfirmChangeTypeModal from '../../../../SurveyDesignTabContent/helpers/ConfirmChangeTypeModal/ConfirmChangeTypeModal';
import PreviewEmailModal from '../../../../SurveyDesignTabContent/helpers/PreviewEmailModal/PreviewEmailModal';
import { PreviewSmsModal } from '../../../../SurveyDesignTabContent/helpers/PreviewSmsModal/PreviewSmsModal';
import { ErrorMessage } from '../../../../../../shared/ErrorMessage/ErrorMessage';

// eslint-disable-next-line no-useless-escape
const emailPattern = new RegExp(/^(?:(.+)\s*<(\w+)([\-+.][\w]+)*@(\w[\-\w]*\.){1,5}([A-Za-z]){2,6}>|(\w+)([\-+.][\w]+)*@(\w[\-\w]*\.){1,5}([A-Za-z]){2,6})$/);

export default ({ token, campaign, onStepChange, recipientsData, providers, updateCampaign }) => {
  const params = useParams();
  const [didMount, setDidMount] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [variables, setVariables] = useState([])
  const [errorModal, setErrorModal] = useState({ show: false, message: '' })
  const [changeTypeModal, setChangeTypeModal] = useState({ show: false, toType: null, fromType: null })
  const [previewEmail, setPreviewEmail] = useState({ show: false })
  const [composeData, setComposeData] = useState(null)
  const [invalidEmailMessage, setInvalidEmailMessage] = useState("")
  const [smsCount, setSmsCount] = useState({ characters: 0, messages: 1 })
  const [showPreview, setShowPreview] = useState(false)
  const dispatch = useDispatch();

  const items = [{ text: "Design view", id: "create" }, { text: "Html view", id: "html" }]
  const linkItems =
    [{ text: "Survey link", id: "surveyLink" },
      //  { text: "Opt-out link", id: "optOutLink" }
    ]

  useEffect(() => {
    if (didMount) {
      if (campaign.type === 'email-campaign') {
        setComposeData(returnComposeStateData(campaign, recipientsData, providers))
      } else {
        setComposeData(returnComposeStateData(campaign, recipientsData, providers))

        if (campaign.text && campaign.text.length > 0) {
          const count = returnSmsCount(campaign.text)
          setSmsCount({ characters: count.characters, messages: count.messages + 1 })
        }

        if (campaign.recipientLists) {
          campaign.recipientLists.forEach(list => {
            getRecipientsRows(`projects/${params.name}/surveys/${params.survey}/recipients/${list}/table/rows/0`, token)
              .then(res => {
                setVariables([...variables, ...res.headers])
              })
          })
        }
      }
      setDidMount(false)
    }
  }, [didMount, params, variables, providers, campaign, recipientsData, token])

  const onChangeRecipients = (e) => {
    setComposeData({ ...composeData, to: e.target.value })
    e.target.value.forEach(list => {
      getRecipientsRows(`projects/${params.name}/surveys/${params.survey}/recipients/${list.id}/table/rows/0`, token)
        .then(res => {
          setVariables([...variables, ...res.headers])
        })
    })
  }

  const onSubmitHandler = (e) => {
    e.preventDefault()
    setInvalidEmailMessage("")
    if (composeData.smsProvider) {
      const body = returnComposeBody(composeData, campaign.type)
      updateComposeSettings(`projects/${campaign.projectId}/surveys/${campaign.surveyId}/campaigns/${campaign.type === "email-campaign" ? "email" : "sms"}/${campaign.id}/compose-settings`, token, body)
        .then(res => {
          if (res && (res.message || res.error)) {
            setErrorModal({ show: true, message: res.message ? res.message : res.error })
          } else {
            onStepChange(1)
            updateCampaign()
          }
        })
    } else {
      if (!emailPattern.test(composeData.from)) {
        setInvalidEmailMessage('The sender email you provided is not valid')
      } else {
        const body = returnComposeBody(composeData, campaign.type)
        updateComposeSettings(`projects/${campaign.projectId}/surveys/${campaign.surveyId}/campaigns/${campaign.type === "email-campaign" ? "email" : "sms"}/${campaign.id}/compose-settings`, token, body)
          .then(res => {
            if (res && (res.message || res.error)) {
              setErrorModal({ show: true, message: res.message ? res.message : res.error })
            } else {
              onStepChange(1)
              updateCampaign()
            }
          })
      }
    }
  }

  const onSaveChanges = () => {
    setInvalidEmailMessage("")
    if (campaign.type === "email-campaign" && !emailPattern.test(composeData.from)) {
      setInvalidEmailMessage('The sender email you provided is not valid')
    } else {
      setIsSaving(true)
      const body = returnComposeBody(composeData, campaign.type)
      updateComposeSettings(`projects/${campaign.projectId}/surveys/${campaign.surveyId}/campaigns/${campaign.type === "email-campaign" ? "email" : "sms"}/${campaign.id}/compose-settings`, token, body)
        .then(res => {
          if (res && (res.message || res.error)) {
            setErrorModal({ show: true, message: res.message ? res.message : res.error })
          } else {
            updateCampaign()
            setIsSaving(false)
          }
        })
    }

  }

  const onBodyChange = (body) => {
    setComposeData({ ...composeData, emailBody: body })
  }

  const onTypeDropdownChange = (item) => {
    if ((item.id === "html" && !composeData.isRawHtml) || (item.id === "create" && composeData.isRawHtml)) {
      setChangeTypeModal({
        show: true,
        to: item.id,
        toType: item.id === "html" ? "html view" : "design view",
        fromType: composeData.isRawHtml ? "html view" : "design view"
      })
    }
  }

  const onComposeTypeChange = () => {
    setComposeData({ ...composeData, isRawHtml: changeTypeModal.to === "html" ? true : false, emailBody: '' })
    setChangeTypeModal({ show: false, to: null, toType: null, fromType: null })
  }

  const onInsertText = (props, type) => {
    const index = document.getElementById("emailText").selectionStart
    let body
    if (campaign.type === 'email-campaign') {
      body = `${composeData.emailBody.slice(0, index)}{${type === 'link' ? props.item.id : props.item}}${composeData.emailBody.slice(index)}`
      setComposeData({ ...composeData, emailBody: body })
    }
    else {
      body = `${composeData.text.slice(0, index)}{${type === 'link' ? props.item.id : props.item}}${composeData.text.slice(index)}`
      const count = returnSmsCount(body)
      setSmsCount({ characters: count.characters, messages: count.messages + 1 })
      setComposeData({ ...composeData, text: body })
    }
  }

  const setTextData = (e) => {
    const count = returnSmsCount(e.value)
    setSmsCount({ characters: count.characters, messages: count.messages + 1 })
    if (campaign.type === 'email-campaign') {
      setComposeData({ ...composeData, emailBody: e.value })
    } else {
      setComposeData({ ...composeData, text: e.value })
    }
  }

  const onPreviewEmail = () => {
    if (!emailPattern.test(composeData.from)) {
      setInvalidEmailMessage('The sender email you provided is not valid')
    } else {
      updateCampaign()
      setPreviewEmail({ show: true })
      setInvalidEmailMessage("")
    }
  }

  const sendTestEmail = () => {
    const body = returnComposeBody(composeData, "email-campaign")
    const testBody = {}
    updateComposeSettings(`projects/${campaign.projectId}/surveys/${campaign.surveyId}/campaigns/email/${campaign.id}/compose-settings`, token, body)
      .then(res => {
        if (res && (res.message || res.error)) {
          setErrorModal({ show: true, message: res.message ? res.message : res.error })
        } else {
          testEmail(`projects/${campaign.projectId}/surveys/${campaign.surveyId}/campaigns/email/${campaign.id}/testsend`, token, testBody)
            .then(res => {
              if (res?.error) {
                dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'An error occured while delivering test email to specified email(s).' } });
              } else {
                dispatch({ type: 'SHOW_SUCCESS_NOTIFICATION', payload: { msg: 'Your test email has been delivered to specified e- mail(s).' } });
                setPreviewEmail({ show: false })
              }
            })
        }
      })

  }

  return (
    <React.Fragment>
      {!didMount ?
        <React.Fragment>
          {changeTypeModal.show &&
            <ConfirmChangeTypeModal
              handleClose={() => setChangeTypeModal({ show: false, to: null, toType: null, fromType: null })}
              toType={changeTypeModal.toType}
              fromType={changeTypeModal.fromType}
              changeType={() => onComposeTypeChange()}
              type="compose"
            />}
          {previewEmail.show &&
            <PreviewEmailModal
              handleClose={() => setPreviewEmail({ show: false })}
              emailContent={composeData}
              onSendTestEmail={sendTestEmail}
            />
          }
          {errorModal.show &&
            <ErrorMessage
              type="modal"
              errorMessage={errorModal.message}
              onHide={() => setErrorModal({ show: false, message: '' })}
            />
          }
          {showPreview &&
            <PreviewSmsModal
              setShowPreview={setShowPreview}
              setTextData={setTextData}
              composeData={composeData}
              campaign={campaign}
              smsCount={smsCount}
            />
          }
          <form onSubmit={onSubmitHandler} className="d-flex flex-column overflow-hidden compose-main">
            <div className="d-flex flex-column flex-grow-1 overflow-hidden compose-view">
              <div className="d-flex survey-compose">
                {campaign.type === 'email-campaign' ?
                  <React.Fragment>
                    <div className="survey-compose-item mr-4">
                      <span className="mr-1 compose-label">From</span>
                      <input
                        required
                        type={"text"}
                        className="form-control border-0"
                        value={composeData.from || ''}
                        placeholder='example@domain.com'
                        onChange={(e) => setComposeData({ ...composeData, from: e.target.value })}
                      />
                    </div>
                  </React.Fragment>
                  : null
                }
                <div className="survey-compose-item mr-4">
                  <span className="mr-1 compose-label">To</span>
                  <BaseMultiSelect
                    required
                    textField="name"
                    data={recipientsData}
                    value={composeData.to}
                    className="border-0"
                    placeholder='Select recipient(s)'
                    onChange={(e) => onChangeRecipients(e)}
                  />
                </div>
                {campaign.type === 'sms-campaign' &&
                  <div className="survey-compose-item">
                    <span className="mr-1 compose-label">Provider</span>
                    <BaseDropDownList
                      data={providers}
                      textField="name"
                      required
                      fillMode={null}
                      popupSettings={{ className: 'survey-dropdown' }}
                      value={providers.find(el => el.id === composeData.smsProvider)}
                      onChange={(e) => setComposeData({ ...composeData, smsProvider: e.target.value.id })}
                      className="w-100 border-0"
                    />
                  </div>}
              </div>
              {campaign.type === 'email-campaign' &&
                <div className="d-flex survey-compose">
                  <div className="py-1 survey-compose-item mr-4">
                    <span className="mr-1 compose-label">Subject</span>
                    <input
                      required
                      className="form-control border-0"
                      value={composeData.subject}
                      placeholder='Enter email subject'
                      onChange={(e) => setComposeData({ ...composeData, subject: e.target.value })}
                    />
                  </div>
                  <div className="survey-compose-item">
                    <span className="mr-1 compose-label">Provider</span>
                    <BaseDropDownList
                      data={providers}
                      disabled={providers.length < 1}
                      textField="name"
                      required
                      fillMode={null}
                      popupSettings={{ className: 'survey-dropdown' }}
                      value={providers.length < 1 ? {
                        name: "Customer hasn't set up a providers list"
                      } :
                        providers.find(el => el.id === composeData.emailProvider)}
                      onChange={(e) => setComposeData({ ...composeData, emailProvider: e.target.value.id })}
                      className="w-100 border-0"
                    />
                  </div>
                </div>}
              {
                invalidEmailMessage &&
                <div className="alert alert-danger mb-0 mt-3 text-center" role="alert">
                  {invalidEmailMessage}
                </div>
              }
              {!composeData.isRawHtml && campaign.type !== 'sms-campaign' ?
                <div className="d-flex flex-column h-100 border survey-main compose-email-view overflow-hidden">
                  <EmailEditor variables={variables} token={token} content={composeData.emailBody} onBodyChange={onBodyChange} />
                </div>
                :
                <div className="d-flex flex-column h-100 border survey-main compose-sms-view overflow-hidden">
                  <div className="d-flex tools">
                    <DropDownButton
                      onItemClick={(props) => onInsertText(props, 'link')}
                      items={linkItems}
                      textField="text"
                      icon="link-horizontal"
                      buttonClass="btn btn-transparent text-primary"
                      className="mr-1"
                    />
                    <DropDownButton
                      onItemClick={(props) => onInsertText(props, 'variable')}
                      items={variables.filter((value, index) => variables.indexOf(value) === index).filter(el => !(el.includes('id') || el.includes('Id')))}
                      icon="css"
                      buttonClass="btn btn-transparent text-primary"
                      disabled={variables.length === 0}
                    />
                  </div>
                  <TextArea className="w-100 h-100 overflow-auto"
                    id="emailText"
                    value={campaign.type === "email-campaign" ? composeData.emailBody : composeData.text}
                    onChange={(e) => setTextData(e)}
                    placeholder="Enter your message" />
                  <div className="d-flex p-3">
                    <span className='audience-desc mr-2'>Number of messages: {smsCount.messages}</span>
                    <span className="audience-desc">Characters: {smsCount.characters}</span>
                  </div>
                </div>
              }
            </div>
            <div className={`d-flex ${campaign.type === 'sms-campaign' ? "justify-content-end" : "justify-content-between"} p-3 border-top`}>
              {campaign.type === 'email-campaign' &&
                <div className="d-flex compose-type mr-2 h-32">
                  <DropDownButton
                    items={items}
                    textField="text"
                    text={composeData.isRawHtml === true ? items.find(el => el.id === "html").text : items.find(el => el.id === "create").text}
                    onItemClick={(e) => onTypeDropdownChange(e.item)}
                    buttonClass="d-flex flex-row-reverse"
                    icon="fa fas fa-caret-down mr-0 ml-1" />
                </div>}
              <div className="d-flex">
                {
                  campaign.type === 'sms-campaign' ?
                    <button type="button" onClick={() => setShowPreview(!showPreview)} className="k-button btn-primary mr-2">
                      Preview SMS
                    </button>
                    :
                    <button
                      type="button"
                      onClick={onPreviewEmail}
                      disabled={
                        composeData.emailBody === "" ||
                          composeData.emailProvider === "" ||
                          composeData.from === "" ||
                          composeData.subject === "" ||
                          composeData.to.length === 0 ? true : false}
                      className="k-button btn btn-secondary mr-2">Preview</button>
                }
                <div className="d-flex align-items-center">
                  {isSaving &&
                    <div className="spinner-border spinner-border-sm text-disabled mr-2" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  }
                  <button type='button' className="btn btn-secondary" title="Save changes" onClick={() => { onSaveChanges() }}>Save</button>
                </div>
                <div className="d-flex">
                  <button type="submit" className="k-button k-survey btn btn-primary h-32 ml-2">Next</button>
                </div>
              </div >

            </div >
          </form >
        </React.Fragment >
        :
        <FullHeightSpinner />
      }
    </React.Fragment >
  )
}
