import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';

import { Icon } from '../../../../../shared/Icon/Icon';
import type { RootState } from '../../../../../../store/reducers/rootReducer';
import { DropdownButton } from '../../../../../shared/DropdownButton/DropdownButton';
import { rsAppUrl, fetchPutResOrJson } from '../../../../../../services/services';

interface Props {
  userSettings: TODO,
  zoomType: string,
  hideSeparator?: boolean
  dropdownMenuPosition?: 'left' | 'right'
}

export const ZoomButtons = ({ userSettings, zoomType, hideSeparator, dropdownMenuPosition }: Props) => {
  const dispatch = useDispatch();
  const { user } = useAuth0()
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  let updatedZoom = userSettings.zoomLevels[zoomType] ? userSettings.zoomLevels[zoomType] : 1;

  const zoomActionHandler = (action: string) => {
    switch (action) {
      case "minus":
        if (updatedZoom !== 0.25) {
          updatedZoom = updatedZoom - 0.25
        }
        break
      case "plus":
        updatedZoom = updatedZoom + 0.25
        break
      case "save": {
        const { compactView, ...updatedUserSettings } = JSON.parse(JSON.stringify(userSettings));
        const body = {
          settings: {
            ...updatedUserSettings,
            zoomLevels: {
              ...updatedUserSettings.zoomLevels,
              [zoomType]: updatedZoom
            }
          }
        }

        if (user) {
          fetchPutResOrJson(`users/${user[`${rsAppUrl}/userid`]}/settings`, token, body)
            .then((res: TODO) => {
              if (res && (res.message || res.error)) {
                dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
              } else {
                dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Zoom changes have been saved successfully' } })
              }
            })
        }
        break
      }
      default:
        updatedZoom = 1
    }
    if (action !== 'save') {
      dispatch({
        type: "UPDATE_ZOOM_LEVEL",
        payload: { zoomType: zoomType, value: updatedZoom },
      })
    }
  }

  return (
    <div className="btn-group d-flex align-items-center" role="group">
      <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
        <button type="button" title="Zoom out" onClick={() => zoomActionHandler('minus')} className="btn btn-outline-analyze btn-ic p-1">
          <Icon type="minus" />
        </button>
      </Tooltip>
      <DropdownButton
        menuPosition={dropdownMenuPosition ? dropdownMenuPosition : undefined}
        text={`${((updatedZoom / 1) * 100).toString()}%`}
        hideChevron={true}
        className="btn-outline-analyze strong px-2 zoom"
        onItemClick={(e) => zoomActionHandler(e.item.action)}
        items={[
          { text: 'Zoom in', action: 'plus' },
          { text: 'Zoom out', action: 'minus' },
          { text: 'separator' },
          { text: 'Reset zoom', action: 'reset' },
          { text: 'Save zoom', action: 'save' }
        ]}
      />
      <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
        <button type="button" title="Zoom in" onClick={() => zoomActionHandler('plus')} className="btn btn-outline-analyze btn-ic p-1">
          <Icon type="add" />
        </button>
      </Tooltip>
      {hideSeparator !== true && <div className='zoom-separator mx-2'></div>}
    </div>
  )
}