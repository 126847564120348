import { Fragment } from "react/jsx-runtime"
import { Skeleton } from "../../../../../../../shared/Skeleton/Skeleton";

export const ThemesSkeleton = () => {
  return (
    <Fragment>
      <div className="d-flex flex-column align-items-between justify-content-center build-tab-header">
        <div className="d-flex align-items-center justify-content-between p-3">
          <p className='mb-0 lh-24 build-tab-header-title'>Themes</p>
        </div>
      </div>

      <div className="d-flex px-3 pb-3 w-100 border-bottom">
        <Skeleton className="h-32" />
      </div>

      <div className="d-flex align-items-center justify-content-between p-3 border-bottom medium h-52">
        <Skeleton className="d-flex align-items-center w-50 h-20" />
        <Skeleton className="d-flex h-20 w-25" />
      </div>

      <div className="d-flex flex-column flex-grow-1 h-100 border-bottom overflow-auto position-relative">
        {[...Array(9).keys()].map((item) => (
          <div key={item} className="border-bottom item h-40 px-2 d-flex align-items-center">
            <Skeleton className="h-20" />
          </div>
        ))}
      </div>

      <div className="d-flex flex-column border-bottom p-3 h-52">
        <div>
          <Skeleton className="h-24" />
        </div>
      </div>

      <div>
        <Skeleton className="m-3 h-32" />
      </div>
    </Fragment>
  )
}