import { type ChangeEvent, useEffect, useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs"
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useParams } from "react-router-dom";
import { v4 as uuid } from 'uuid';

import type { RootState } from "../../../../../store/reducers/rootReducer";
import type { Question, TargetingResponseData, TargetingDatas, Answer, Target, TargetingResponseType, TargetAnswers } from "../../types/audienceTargetingTypes";
import { QuotaModalCategories } from "./QuotaModalCategories";
import { QuotaModalCategoriesSkeleton } from "./QuotaModalCategoriesSkeleton";
import { QuotaModalSelectedQuestions } from "./QuotaModalSelectedQuestions";
import { Icon } from "../../../../shared/Icon/Icon";
import { QuotaModalAnswersTable } from "./QuotaModalAnswersTable";
import { setBatch } from "../../../../../store/reducers/batchReducer/batchReducer";
import { QuotaModalTargets } from "./QuotaModalTargets";
import { ErrorMessage } from "../../../../shared/ErrorMessage/ErrorMessage";
import type { ScreeningAnswers, Targeting } from "../../../../../interfaces/auddineceAccessInterfaces/batchOverviewTypes";
import { fetchGetJsonData, fetchPutJsonData } from "../../../../../services/services";

type Props = {
  onHide: (show: boolean) => void;
}

export const QuotaModal = ({ onHide }: Props) => {
  const batchData = useSelector((state: RootState) => state.batchDataReducer);
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);

  const params: { projectId: string, audienceId: string, sampleId: string, batchId: string } = useParams();
  const dispatch = useDispatch()
  const { projectId, sampleId, audienceId, batchId } = params;

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [targetingResponseData, setTargetingResponseData] = useState<TargetingResponseData | null>(null);
  const [selectedQuestions, setSelectedQuestions] = useState<Question[]>([]);
  const [selectedQuestion, setSelectedQuestion] = useState<Question | null>(null);
  const [selectedTarget, setSelectedTarget] = useState<TargetingDatas | null>(null);
  const [selectedAnswers, setSelectedAnswers] = useState<Answer[] | ScreeningAnswers[]>([]);
  const [selectAllAnswers, setSelectAllAnswers] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [inputValueType, setInputValueType] = useState<"%" | "#">("#");
  const [isTargetCompletesError, setIsTargetCompletesError] = useState(false);
  const [isRangeError, setIsRangeError] = useState(false);
  const [rangeErrorText, setRangeErrorText] = useState("");

  useEffect(() => {
    fetchGetJsonData<TargetingResponseData>({ url: `au/targeting/markets/${batchData.market.locale}`, token: token, baseUrl: "default" })
      .then((res) => {
        if (res) {
          setTargetingResponseData(res);
          setIsLoading(false);
        }
      }).catch((error) => {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error } });
        setIsLoading(false);
      })

  }, [batchData.market, token, dispatch]);

  useEffect(() => {
    if (batchData.targeting?.quotas && targetingResponseData) {
      const includedQuestions = batchData.targeting.quotas.flatMap((quota) => {
        return targetingResponseData.questions.filter((item) => item.id.toString() === quota.questionId.toString());
      });

      setSelectedQuestions(includedQuestions)
    }
  }, [batchData.targeting, targetingResponseData])

  const handleInputValueType = (inputValueType: string) => {
    inputValueType === "%" ? setInputValueType("#") : setInputValueType("%")
  }

  const addQuota = (type: "quota" | "group" | "range") => {
    if (type === "quota" && selectedQuestion) {
      if (selectedTarget) {
        const targets = selectedAnswers.map((answer) => {
          return {
            id: `${selectedQuestion.name}, ${answer.id}`,
            targetCompletes: 0,
            answers: [{ id: answer.id, text: answer.text, localizedText: answer.localizedText ? answer.localizedText : null, start: null, end: null }]
          }
        })

        const targetIds = targets.map(target => target.id);
        const seletedTargetsIds = selectedTarget.targets.map(target => target.id);
        const isDuplicate = targetIds.every(value => !seletedTargetsIds.includes(value));

        if (isDuplicate) {
          const updatedSelectedTarget = {
            ...selectedTarget,
            targets: [...selectedTarget.targets, ...targets]
          }

          setSelectedTarget(updatedSelectedTarget);
          setSelectedAnswers([]);
          setSelectAllAnswers(false);
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "Answer already added" } });
        }
      } else {
        const newSelectedTarget = {
          name: selectedQuestion.name,
          text: selectedQuestion.text,
          localizedText: selectedQuestion.localizedText,
          questionId: selectedQuestion.id,
          targets: selectedAnswers.map((answer) => {
            return {
              id: `${selectedQuestion.name}, ${answer.id}`,
              targetCompletes: 0,
              answers: [{ id: answer.id, text: answer.text, localizedText: answer.localizedText ? answer.localizedText : null, start: null, end: null }]
            }
          })
        }
        setSelectedTarget(newSelectedTarget);
        setSelectedAnswers([]);
        setSelectAllAnswers(false);
      }
    }

    if (type === "group") {
      if (selectedTarget && selectedQuestion) {
        const answerIds = selectedAnswers.map((item) => item.id).toString();
        const targets = [{
          id: `${selectedQuestion.name}, ${answerIds}`,
          targetCompletes: 0,
          answers: selectedAnswers.map((answer) => {
            return { id: answer.id, text: answer.text, localizedText: answer.localizedText ? answer.localizedText : null, start: null, end: null }
          })
        }]

        const updatedSelectedTarget = {
          ...selectedTarget,
          targets: [...selectedTarget.targets, ...targets]
        }

        setSelectedTarget(updatedSelectedTarget);
        setSelectedAnswers([]);
      } else {
        if (selectedQuestion) {
          const answerIds = selectedAnswers.map((item) => item.id).toString();
          const targets = [{
            id: answerIds,
            targetCompletes: 0,
            answers: selectedAnswers.map((answer) => {
              return { id: answer.id, text: answer.text, localizedText: answer.localizedText ? answer.localizedText : null, start: null, end: null }
            })
          }]

          const newSelectedTarget = {
            name: selectedQuestion.name,
            text: selectedQuestion.text,
            localizedText: selectedQuestion.localizedText,
            questionId: selectedQuestion.id,
            targets: targets
          }
          setSelectedTarget(newSelectedTarget)
        }

        setSelectedAnswers([])
        setSelectAllAnswers(false);
      }
    }

    if (type === "range" && selectedQuestion) {
      if (selectedTarget) {
        const targets = [{
          id: uuid(),
          targetCompletes: 0,
          answers: [{ id: null, text: null, localizedText: null, start: null, end: null }]
        }]

        const updatedSelectedTarget = {
          ...selectedTarget,
          targets: [...selectedTarget.targets, ...targets]
        }

        setSelectedTarget(updatedSelectedTarget);
        setSelectedAnswers([])
      } else {
        const newSelectedTarget = {
          name: selectedQuestion.name,
          text: selectedQuestion.text,
          localizedText: selectedQuestion.localizedText,
          questionId: selectedQuestion.id,
          targets: [{
            id: uuid(),
            targetCompletes: 0,
            answers: [{ id: null, text: null, localizedText: null, start: null, end: null }]
          }]
        }
        setSelectedTarget(newSelectedTarget);
        setSelectedAnswers([])
      }
    }
  }

  const handleQuotaInputValue = (event: ChangeEvent<HTMLInputElement>, target: Target) => {
    if (selectedTarget) {
      setIsTargetCompletesError(false);

      const updatedTarget = {
        ...target,
        targetCompletes: inputValueType === "%" ? Math.ceil((Number(event.target.value) / 100) * Number(batchData.quantity)) : Number(event.target.value),
      }

      const updatedData = selectedTarget.targets.map(target => (target.id === updatedTarget.id ? { ...updatedTarget } : target));

      const updatedSelectedTarget = {
        ...selectedTarget,
        targets: updatedData
      }

      setSelectedTarget(updatedSelectedTarget);
    }
  }
  const isOutsideRange = (num: number, min: number, max: number) => num < min || num > max

  const handleQuotaRangeInput = (event: ChangeEvent<HTMLInputElement>, target: Target, answer: TargetAnswers, type: "start" | "end") => {
    if (selectedTarget) {
      const inputNumber = Number(event.target.value);

      if (type === "start" && isOutsideRange(inputNumber, 1, 99)) {
        setIsRangeError(true);
        setRangeErrorText("Value must be in range 1 to 99");
        return;
      }

      if (type === "end") {
        if (!answer.start) {
          setIsRangeError(true);
          setRangeErrorText("From value must be in range 1 to 99");
          return;
        }

        if (answer.start && isOutsideRange(inputNumber, answer.start, 99)) {
          setRangeErrorText("Value must be larger than start number");
          setIsRangeError(true);
          return;
        }

        if (isOutsideRange(inputNumber, 1, 99)) {
          setRangeErrorText("Value must be in range 1 to 99");
          setIsRangeError(true);
          return;
        }
      }

      setIsRangeError(false);
      setRangeErrorText("");

      const updatedAnswer = [{ ...answer, start: type === "start" ? inputNumber : answer.start, end: type === "end" ? inputNumber : answer.end }]

      const updatedTarget = { ...target, answers: updatedAnswer }

      const updatedData = selectedTarget.targets.map(target => (target.id === updatedTarget.id ? { ...updatedTarget } : target));

      const updatedSelectedTarget = {
        ...selectedTarget,
        targets: updatedData
      }

      setSelectedTarget(updatedSelectedTarget);
    }
  }

  const handleRemoveQuota = (target: Target) => {
    if (selectedTarget) {
      const filteredTargets = selectedTarget.targets.filter((item) => item.id !== target.id);

      const updatedSelectedTarget = {
        ...selectedTarget,
        targets: filteredTargets
      }

      if (isRangeError) {
        setIsRangeError(false);
        setRangeErrorText("");
      }

      setSelectedTarget(updatedSelectedTarget);
    }
  }

  const saveQuotasTargeting = () => {
    if (selectedTarget) {
      const isTargetCompletsValid = selectedTarget.targets.some((item) => item.targetCompletes === 0);
      if (isTargetCompletsValid) {
        setIsTargetCompletesError(true);
        return;
      }

      setIsTargetCompletesError(false);
      setIsLoadingSubmit(true);
      const existingQuestion = batchData.targeting?.quotas?.find((item) => item.questionId === selectedTarget?.questionId);

      const updatedQuota = {
        ...(existingQuestion ? existingQuestion : { questionId: selectedTarget.questionId }),
        targets: [...selectedTarget.targets]
      }

      const updatedData = batchData?.targeting?.quotas ?
        batchData?.targeting?.quotas?.map(item => (item.questionId === updatedQuota.questionId ? { ...item, ...updatedQuota } : item)) : [];

      const targetingData = {
        targetingRequest: {
          screenings: batchData.targeting?.screenings ? batchData.targeting.screenings : [],
          quotas: existingQuestion ? updatedData : [...updatedData, updatedQuota],
          existingId: null,
          type: 1,
        },
        country: batchData.market.country,
        language: batchData.market.language,
        etag: batchData.etag
      }

      if (batchData.batchAction === "copy") {
        const updatedTargeting = {
          ...batchData.targeting,
          screenings: batchData.targeting?.screenings ? batchData.targeting.screenings : [],
          quotas: existingQuestion ? updatedData : [...updatedData, updatedQuota],
        } as unknown as Targeting

        dispatch(setBatch({ ...batchData, targeting: updatedTargeting }));

        setIsLoadingSubmit(false);
        dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Quota updated' } });
      } else {
        fetchPutJsonData<TargetingResponseType>({ url: `au/a/projects/${projectId}/samples/${sampleId}/audiences/${audienceId}/batches/${batchId}/targeting`, token: token, body: targetingData, baseUrl: "audience" })
          .then((res) => {
            dispatch(setBatch({ ...batchData, targeting: res.targetingResponse, etag: res.etag }));
            setIsLoadingSubmit(false);
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Quota added' } });
          })
          .catch((error) => {
            setIsLoadingSubmit(false);
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error.errorMessage } });
          })
      }

    }
  }

  return (
    <Dialog width={"95%"} height={"95%"} title={"Quotas"} className="quota-modal" onClose={() => onHide(false)}>
      <div className="d-flex gap-md py-2 px-2 h-100">

        <div className="border-right px-2 overflow-auto" style={{ width: "20%" }}>
          {isLoading ?
            <QuotaModalCategoriesSkeleton />
            :
            <QuotaModalCategories
              targetingResponseData={targetingResponseData}
              setSelectedQuestions={setSelectedQuestions}
              selectedQuestions={selectedQuestions}
              setSelectedQuestion={setSelectedQuestion}
              setSelectedTarget={setSelectedTarget}
            />
          }
        </div>

        <div className="d-flex gap-md" style={{ width: "80%" }}>
          <div className="d-flex flex-column gap-md w-25 p-2 overflow-auto" style={{ backgroundColor: "#f5f5f6" }}>
            <QuotaModalSelectedQuestions
              selectedQuestion={selectedQuestion}
              selectedQuestions={selectedQuestions}
              setSelectedQuestion={setSelectedQuestion}
              setSelectedQuestions={setSelectedQuestions}
              setSelectedTarget={setSelectedTarget}
              setSelectedAnswers={setSelectedAnswers}
              setSelectAllAnswers={setSelectAllAnswers}
            />
          </div>

          <div className="d-flex flex-column justify-content-between w-75 overflow-auto">
            {selectedQuestion ?
              <div className="d-flex flex-column gap-lg w-100">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex flex-column gap-sm">
                    <p className="m-0 small text-indigo-dark strong">{selectedQuestion.category}</p>
                    <p className="m-0 large strong">{selectedQuestion.text}</p>
                  </div>
                </div>

                <div className="d-flex flex-column gap-lg pb-2 px-1">
                  {selectedQuestion.answerType === 2 ?
                    <div className="d-flex flex-column">
                      <button type="button" className='btn btn-transparent text-blue-survey' onClick={() => addQuota("range")} style={{ width: "fit-content" }}>
                        Add new range
                      </button>
                    </div>
                    :
                    <div className="d-flex flex-column gap-md">
                      <QuotaModalAnswersTable
                        selectedAnswers={selectedAnswers}
                        selectedQuestion={selectedQuestion}
                        setSelectedAnswers={setSelectedAnswers}
                        setSelectAllAnswers={setSelectAllAnswers}
                        selectAllAnswers={selectAllAnswers}
                      />

                      <div className="d-flex gap-md">
                        <button className="btn btn-secondary" type="button" onClick={() => addQuota("quota")} disabled={selectedAnswers.length === 0}>Add quota</button>
                        <button className="btn btn-secondary" type="button" onClick={() => addQuota("group")} disabled={selectedAnswers.length <= 1}>Add group</button>
                      </div>
                    </div>
                  }

                  <div className="d-flex flex-column gap-lg">
                    <div className="d-flex flex-column gap-md border-bottom pb-2">
                      <p className="m-0 large strong">Quotas</p>

                      <div className="d-flex justify-content-end">
                        <button className="cs-input-switch h-32" type="button" onClick={() => handleInputValueType(inputValueType)}>
                          <div className={`cs-input-item d-flex justify-content-center ${inputValueType === "%" ? "bg-white" : ""}`}>%</div>
                          <div className={`cs-input-item d-flex justify-content-center ${inputValueType === "#" ? "bg-white" : ""}`}>#</div>
                        </button>
                      </div>
                    </div>

                    {selectedTarget && (
                      <div className="d-flex flex-column gap-lg text-right">
                        <p style={{ margin: "0 3.5rem 0 0" }}>Target {inputValueType}</p>
                        {selectedTarget?.targets.map((target, index) =>
                          <div key={target.id ? target.id : index} className="d-flex gap-md align-items-center justify-content-between">
                            <QuotaModalTargets
                              answerType={selectedQuestion.answerType}
                              handleQuotaRangeInput={handleQuotaRangeInput}
                              target={target}
                            />

                            <div className="d-flex gap-md align-items-center">
                              {inputValueType === "%" ?
                                <input
                                  type="number"
                                  step={5}
                                  min={0}
                                  max={100}
                                  name={`targetCompletes${target.id ? target.id : index}`}
                                  defaultValue={target.targetCompletes && batchData.quantity ? Math.round((target.targetCompletes / batchData.quantity) * 100) : 0}
                                  className="form-control shadow-none pl-2 pt-2 pb-2 pr-1 h-32 w-64"
                                  onChange={(e) => handleQuotaInputValue(e, target)}
                                />
                                :
                                <input
                                  type="number"
                                  step={5}
                                  min={0}
                                  name={`targetCompletes${target.id ? target.id : index}`}
                                  value={target.targetCompletes ? target.targetCompletes : 0}
                                  className="form-control shadow-none pl-2 pt-2 pb-2 pr-1 h-32 w-64"
                                  onChange={(e) => handleQuotaInputValue(e, target)}
                                />
                              }
                              <Tooltip anchorElement="target" position="top">
                                <button type="button" className="btn btn-transparent" title="Remove quota" onClick={() => handleRemoveQuota(target)}>
                                  <Icon type="close" className="pe-none" />
                                </button>
                              </Tooltip>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              :
              <div>
                Select a question
              </div>
            }

            {
              isRangeError &&
              <ErrorMessage
                type="alert"
                errorMessage={rangeErrorText}
              />
            }

            {isTargetCompletesError &&
              <ErrorMessage
                type="alert"
                errorMessage="Target value must be larger than 0"
              />
            }
          </div>
        </div>
      </div>

      <DialogActionsBar>
        <button type='button' className="k-button btn btn-secondary" disabled={isLoadingSubmit} onClick={() => onHide(false)}>
          Close
        </button>

        <button type="button" className="btn btn-primary strong" disabled={isLoadingSubmit || !selectedTarget || isRangeError || isTargetCompletesError} onClick={() => saveQuotasTargeting()} style={{ width: "fit-content" }}>
          Save quota
          {
            isLoadingSubmit &&
            <span className="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true" />
          }
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}