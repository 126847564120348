import { useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import { useDebounce } from "../../shared/customHooks/useDebounce";

interface Props {
  onHide: () => void
  onConfirm: (value: string, type: string) => void
  type: string
  originalTitle?: string
}

export const PinboardPageModal = ({ onHide, onConfirm, type, originalTitle }: Props) => {
  const [inputValue, setInputValue] = useState<string>((originalTitle && type === "Edit") ? originalTitle : "");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onConfirmHandler = useDebounce(() => { setIsLoading(false); onConfirm(inputValue, type) }, 1500)

  const onHideHandler = () => { setInputValue(""); onHide(); }

  return (
    <Dialog
      width={"480px"}
      className="page-dialog"
      title={type === "Delete" ? `Delete "${originalTitle}"` : type === "Add" ? "Add board" : `Edit "${originalTitle}"`}
      onClose={onHideHandler}>
      <div className="p-1">
        <input
          autoFocus
          type="text"
          placeholder="Enter board title"
          value={inputValue}
          className="sidebar-input form-control"
          onChange={e => setInputValue(e.target.value)} />
      </div>
      <DialogActionsBar>
        <button className="btn btn-secondary" onClick={onHideHandler}>Cancel</button>
        <button
          className={`btn ${type === "Delete" ? "btn-danger" : "btn-primary"}`}
          disabled={(type === "Delete" && inputValue.toLowerCase() !== originalTitle?.toLowerCase()) || isLoading || (type === "Edit" && inputValue === originalTitle)}
          onClick={() => { setIsLoading(true); onConfirmHandler(); }}>
          {type}
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}

export default PinboardPageModal;