import { type FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Stepper, type StepperChangeEvent } from '@progress/kendo-react-layout';

import type { RootState } from '../../../../../store/reducers/rootReducer';
import { ErrorMessage } from '../../../../shared/ErrorMessage/ErrorMessage';
import { useFetchTags } from '../../../../shared/customHooks/useFetchTags';
import { EmailProvidersActionBars } from './EmailProvidersActionBars/EmailProvidersActionBars';
import { EmailProviderModalContent } from '../EmailProviderModalContent/EmailProviderModalContent';
import { sendTestEmailHandler } from '../../../../shared/helpers/sendTestEmailHandler/sendTestEmailHandler';
import { useUpdateEmailProvidersFormData } from '../../../../shared/customHooks/useUpdateEmailProvidersFormData';
import { submitEmailProvidersHandler } from '../../../../shared/helpers/submitEmailProvidersHandler/submitEmailProvidersHandler';
import type { EmailProvider, EmailProviderFormData } from '../../../../../interfaces/emailProviderInterfaces/emailProviderInterfaces';

const items = [{ label: 'General settings' }, { label: 'SMTP' }];

interface Props {
  handleClose: () => void
  emailProvider?: EmailProvider | null
}

export const EditEmailProviderModal = ({ handleClose, emailProvider }: Props) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { emailProvidersErrorMessage } = useSelector((theState: RootState) => theState.adminReducer);
  const [stepperValue, setStepperValue] = useState(0);
  const [sendTestEmailMessage, setSendTestEmailMessage] = useState(false)
  const [isInProgress, setIsInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<EmailProviderFormData>({ providerName: '', providerDescription: '', tags: [], relay: '', port: '', username: '', password: '', newPassword: '', confirmNewPassword: '', SSL: false })

  const tags = useFetchTags(token);
  useUpdateEmailProvidersFormData(token, (val) => setFormData(val), emailProvider)

  const onChangeHandler = (e: TODO, name: string) => {
    setFormData({
      ...formData,
      [name]: e.target.value
    })
  }

  const handleChange = (e: StepperChangeEvent) => {
    if (formData.providerName) {
      setStepperValue(e.value);
    }
  };

  const onSubmitHandler = (e: FormEvent) => {
    e.preventDefault()
    if (stepperValue === 0) {
      setStepperValue(stepperValue + 1)
    } else {
      submitEmailProvidersHandler(formData, token, dispatch, setIsLoading, emailProvider)
    }
  }

  const onSendTestEmailHandler = () => {
    sendTestEmailHandler('smtp', dispatch, formData, token, (val: boolean) => setIsInProgress(val), (val: boolean) => setSendTestEmailMessage(val))
  }

  return (
    <Dialog
      width="40%"
      title={emailProvider ? 'Edit email provider' : 'Create email provider'}
      className="email-provider-modal"
      onClose={handleClose}>
      <form onSubmit={onSubmitHandler} className="k-form d-flex flex-column h-100 ">
        <div className="px-4 pt-4 d-flex flex-column" style={{ flex: '1 1 auto' }}>
          <div className="pb-3">
            <Stepper value={stepperValue} onChange={handleChange} items={items} />
          </div>

          <EmailProviderModalContent
            tags={tags}
            formData={formData}
            providerType={"smtp"}
            emailProvider={emailProvider}
            stepperValue={stepperValue}
            onChangeHandler={onChangeHandler}
          />

          {
            emailProvidersErrorMessage &&
            <div className="mt-4">
              <ErrorMessage
                type="alert"
                errorMessage={emailProvidersErrorMessage} />
            </div>
          }
          {
            sendTestEmailMessage &&
            <div className="alert alert-success alert-dismissible fade show mt-4" role="alert">
              <span>The test email has been sent successfully!</span>
              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          }
        </div>

        <DialogActionsBar>
          <EmailProvidersActionBars
            formData={formData}
            isLoading={isLoading}
            isInProgress={isInProgress}
            stepperValue={stepperValue}
            emailProvider={emailProvider}
            handleClose={handleClose}
            onSendTestEmailHandler={onSendTestEmailHandler}
            setStepperValue={(val) => setStepperValue(val)} />
        </DialogActionsBar>
      </form>
    </Dialog>
  )
}