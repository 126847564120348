import { v4 as uuid } from 'uuid';

export const validateQuestionId = (id, data, type) => {
  if (type === "multiple") {
    let list = data.map((el) => {
      return el.qno
    })
    let message = ''
    if (list.some(r => id.includes(r))) {
      message = 'Another question with this Id already exists. Please try another one.'
    }
    else if (!id.some(p => p.match(/^[a-z0-9]+$/i))) {
      message = 'Please match the requested question Id format.'
    }
    else { message = '' }
    return message
  }
  else {
    let list = []
    let message = ''
    data.forEach(el => list.push(el.qno.slice(0, -2)))
    if (list.includes(id)) {
      message = 'Another question with this Id already exists. Please try another one.'
    }
    else if (!id.match(/^[a-z0-9]+$/i)) {
      message = 'Please match the requested question Id format.'
    }
    else { message = '' }
    return message
  }

}

export const checkCalcValues = (rows, type) => {
  if (type === "single") {
    let calculations = []
    rows.forEach(el => calculations.push(el.calc))
    if (calculations.includes(null) || calculations.includes('')) {
      return true
    }
    else {
      return false
    }
  } else {
    let calculations = []
    rows.forEach(el => calculations.push(el.calc))
    if (calculations.includes(null) || calculations.includes('')) {
      return true
    }
    else {
      return false
    }
  }
}

export const initializeQuestionData = (importedCreatedQuestion, type) => {
  if (importedCreatedQuestion) {
    if (importedCreatedQuestion.length === 1) {
      // setEditQuestionData(false)
      let rowsData = []
      importedCreatedQuestion[0].rows.forEach((row) => {
        rowsData.push({ ...row, id: uuid(), inEdit: true })
      })

      return ({
        id: importedCreatedQuestion[0].id,
        text: importedCreatedQuestion[0].text,
        rows: rowsData,
        type: importedCreatedQuestion[0].questionType
      })
    } else if (importedCreatedQuestion.length > 1) {
      const multipleQuestions = importedCreatedQuestion.map((el) => {
        return (
          {
            id: el.id,
            text: el.text,
            type: el.questionType,
            uId: el.uId,
            rows: el.rows.map((el) => {
              return ({
                ...el,
                id: uuid(),
                inEdit: true
              })
            })
          }
        )
      })
      return multipleQuestions
    }
  } else {
    return { id: "", text: "", type: type, rows: [] }
  }
}