// This function is used to set the sorting direction to Descending (2) automatically when the user updates another related sorting option
// It's only used in Crosstab, Chart and QTable

export const setAnalysisSortingDirectionAutomatically = (optionId: string, updatedValues: { [optionId: string]: TODO }) => {
  switch (optionId) {
    case 'XtSortRollValue':
    case 'XtSortTableCol':
    case 'XtSortExclude':
    case 'XtSortRollEnd':
      if (!updatedValues.XtSortRollDir || updatedValues.XtSortRollDir === 0) {
        updatedValues.XtSortRollDir = 2;
      }
      break;
    case 'QtSortRollValue':
    case 'QtSortTableCol':
    case 'QtSortExclude':
    case 'QtSortRollEnd':
      if (!updatedValues.QtSortRollDir || updatedValues.QtSortRollDir === 0) {
        updatedValues.QtSortRollDir = 2;
      }
      break;
    case 'GSortChart':
    case 'GSortExclude':
    case 'GSortRollEnd':
      if (!updatedValues.GSortRollDir || updatedValues.GSortRollDir === 0) {
        updatedValues.GSortRollDir = 2;
      }
      break;
  }
}