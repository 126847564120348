import type { SetAnswerSorting, SetCategorySorting } from "../../../../../../interfaces/workflowInterfaces/toolInterfaces";
import { calculateCounts } from "../../WorkflowDesignTabs/LogicTabContent/TabContent/CodingToolTabContent/CodingToolHelpers";

type rspAnswer = { id: string, t: string, c: number, isDeleted?: boolean, isCoded?: boolean, isSplit?: boolean };

type Category = { oaToInc: string[], items?: rspAnswer[] };

type Res = {
  rspAnswers: rspAnswer[];
  categories: Category[];
  verbatimSortBy: string;
  verbatimSortDescending: boolean;
  sortBy: string;
  sortDescending: boolean;
}

export const populateCodeMapData = (res: Res, setCategorySorting: SetCategorySorting, setAnswerSorting: SetAnswerSorting, dispatch: (parameter: any) => void) => {
  // Organize answers
  const organizedAnswers = {
    deleted: [...res.rspAnswers].filter((el) => el.isDeleted),
    coded: [...res.rspAnswers].filter((el) => el.isCoded),
    split: [...res.rspAnswers].filter((el) => el.isSplit),
    live: [...res.rspAnswers].filter((el) => !el.isDeleted && !el.isCoded && !el.isSplit),
  }

  // Organize categories
  const updatedCategories = [...res.categories]
  updatedCategories.forEach((cat) => {
    if (cat.oaToInc.length > 0) {
      cat.items = [] // Has to be named "items" cause of Kendo TreeView limitation
      cat.oaToInc.forEach((answerId) => {
        const codedAnswer = organizedAnswers.coded.find((el) => el.id === answerId)
        if (codedAnswer) {
          cat.items?.push(codedAnswer)
        }
      })
    }
  })

  const verbatimSortBy = res.verbatimSortBy === "Text" ? "t" : res.verbatimSortBy === "AnswerCount" ? "c" : ""
  if (verbatimSortBy !== "") {
    organizedAnswers.live.sort((catA, catB) => {
      if (catA[verbatimSortBy] === catB[verbatimSortBy]) return 0;
      return res.verbatimSortDescending
        ? (catA[verbatimSortBy] < catB[verbatimSortBy] ? 1 : -1)
        : (catA[verbatimSortBy] < catB[verbatimSortBy] ? -1 : 1);
    });
  }

  setCategorySorting({ sortBy: res.sortBy === 'AnswerCount' ? 'oaToInc' : res.sortBy ? res.sortBy.toLowerCase() : "code", descending: res.sortDescending })
  setAnswerSorting({ sortBy: verbatimSortBy, descending: res.verbatimSortDescending })

  const countNumbers = calculateCounts(res.rspAnswers)
  const updatedRes = {
    ...res,
    categories: updatedCategories,
    rspAnswers: organizedAnswers.live,
    filteredAnswers: organizedAnswers.live,
    deletedAnswers: organizedAnswers.deleted,
    codedAnswers: organizedAnswers.coded,
    splitAnswers: organizedAnswers.split,
    countNumbers: { ...countNumbers },
  }

  dispatch({ type: "UPDATE_SELECTED_CODEMAP", payload: updatedRes })
}