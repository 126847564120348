import type { FileManagerActionButton, FileManagerItem } from "../../../../interfaces/fileManagerInterfaces/fileManagerInterfaces"

export const returnUpdatedActionButtons = (buttons: FileManagerActionButton[], stateType: string, checkedFiles: FileManagerItem[]) => {
  const updatedActionButtons = JSON.parse(JSON.stringify(buttons))
  if (stateType === 'expandedState' && checkedFiles.length > 0) {
    updatedActionButtons[3].disabled = false
  } else {
    updatedActionButtons[3].disabled = true
  }

  if (checkedFiles.length === 0 || checkedFiles.length > 1) {
    updatedActionButtons[1].disabled = true
  } else {
    if (checkedFiles.find(item => !item.isDirectory)) {
      updatedActionButtons[1].disabled = false
    } else {
      updatedActionButtons[1].disabled = true
    }
  }

  return updatedActionButtons
}