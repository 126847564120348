import type React from 'react';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Input, Switch } from '@progress/kendo-react-inputs';

import { Icon } from '../../../../../shared/Icon/Icon';
import { BaseDropDownList } from '../../../../../shared/Inputs';
import { SectionLoopOption } from '../SectionLoopOption/SectionLoopOption';
import { DropdownButton, type DropdownItem } from '../../../../../shared/DropdownButton/DropdownButton';
import type { Option, SelectedElement, TheData, Theme } from '../../../../../../interfaces/surveyInterfaces/surveyIterfaces';

type Values = { [x: string]: string; }
type ShowFilterBuilder = (option: string, optionName?: string, previousItemLabel?: string) => void
type OnValueChange = (e: unknown, type: string, option: { id: string }) => void
type EmailProviders = { loading: boolean, data: { id: string, name: string }[] }
type GetEmailProviders = () => void
type ListItemValue = { list: string, number: string } | undefined
type LoopType = "none" | "list" | "number" | undefined
type UpdateLoopType = (loopType: LoopType) => void

export const returnSurveyOptionsByType = (theData: TheData, selectedElement: SelectedElement, values: Values, onValueChange: OnValueChange, showFilterBuilder: ShowFilterBuilder, themes: Theme[], listItemsDropdown: DropdownItem[] | undefined, listItemValue: ListItemValue, emailProviders: EmailProviders, getEmailProviders: GetEmailProviders, isSectionOptionsDialogOpen: boolean, loopType: LoopType, updateLoopType: UpdateLoopType) => {
  // This file's main function returns renderable jsx elements in an array, based on the each option's type
  // All available options are gotten from backend through /resources/survey-options (used in SurveyDesignTabContent.js and SurveyThemesTool.js)
  // All the available options are stored in theData.allOptions in redux
  // That request also returns which options are available to which type of element
  // The returned options are being cross-matched in the next few lines, based on what element is selected

  const selectedOptions = theData.elementTypes.find((el) => el.id === selectedElement.type)?.props;
  const allOptions = theData.allOptions;
  const dropdownValues = theData.dropdownValues;
  const subqCount = theData.originalSelectedItem?.elements ? theData.originalSelectedItem.elements.filter((el) => el.type === 'subq').length : 0;
  const options: React.ReactNode[] = [];
  const updateThemes = [{ name: 'None', id: 'none' }, ...themes];
  const isParentDisabled = theData.selectedItem?.disabled
  const data: Option[] = [];

  const checkSubquestions = theData.selectedItem?.elements?.filter((item) => item.type === 'subq')

  const isSingleMultiQuestion = theData.selectedItem?.type === "ques" && checkSubquestions &&
    (checkSubquestions[theData.selectedSubquestion]?.attributes?.type === "n" || checkSubquestions[theData.selectedSubquestion]?.attributes?.type === "m");

  // Add afill options
  selectedOptions?.filter((item) => item.id.includes('afill')).forEach((item, index: number) => {
    if (index < subqCount) {
      const foundOption = allOptions.find((el) => el.id === item.id)
      if (foundOption) {
        data.push(foundOption)
      }
    }
  })

  // Add normal Filter option
  let filterOption = !isSingleMultiQuestion ? selectedOptions?.find((item) => item.id === 'filter') : undefined;
  if (isSectionOptionsDialogOpen) {
    filterOption = selectedOptions?.find((item) => item.id === 'filter')
  }

  if (filterOption?.id) {
    const foundOption = allOptions.find((el) => el.id === filterOption?.id)
    if (foundOption) {
      data.push(foundOption)
    }
  }

  // Add extra filter options (filtera, filterb etc.)
  selectedOptions?.filter((item) => item.id.includes('filter') && item.id !== 'filter').forEach((item, index: number) => {
    if (index < subqCount) {
      const foundOption = allOptions.find((el) => el.id === item.id)
      if (foundOption) {
        data.push(foundOption)
      }
    }
  })

  // Add rest of options
  selectedOptions?.forEach((item) => {
    if (!item.id.includes('afill') && !item.id.includes('filter')) {
      const foundOption = allOptions.find((el) => el.id === item.id)
      if (foundOption) {
        data.push(foundOption)
      }
    }
  })

  // Remove gdprPii for goto elements
  // if (selectedElement.type === "goto") {
  // data.splice(data.findIndex(el => el.id === "gdprPii"), 1)
  // }

  const indexOfGdprOption = data.findIndex(el => el.id === "gdprPii")
  if (typeof indexOfGdprOption === "number" && indexOfGdprOption >= 0) {
    data.splice(indexOfGdprOption, 1);
  }

  // Push gdpr to the end of the array, gdpr needs to appear at the bottom of the options
  // if (data.length > 0 && selectedElement.type !== "goto") {
  //   data.push(data.splice(data.findIndex(el => el.id === "gdprPii"), 1)[0]);
  // }

  // Delete css option from section options
  if (selectedElement.type === 'section') {
    const indexOfCssOption = data.findIndex(el => el.id === "css")
    if (typeof indexOfCssOption === "number" && indexOfCssOption >= 0) {
      data.splice(indexOfCssOption, 1);
    }
  }

  // Move mailprovider option above other email options
  if (data.filter(el => el.id === 'mailprovider' || el.id === 'mailfrom').length === 2) {
    const mailFormIndex = data.findIndex(el => el.id === 'mailfrom')
    const mailProviderIndex = data.findIndex(el => el.id === 'mailprovider')
    const mailProviderOption = data.splice(mailProviderIndex, 1)[0]
    data.splice(mailFormIndex, 0, mailProviderOption)
  }

  const isMailProviderSet = theData.selectedItem?.type === 'info' && theData.selectedItem?.attributes?.mailprovider && theData.selectedItem?.attributes?.mailprovider?.length > 0;

  data.forEach((option, id) => {
    if (option.id.includes('filter')) {
      options.push(
        <div key={id}>
          <div className="d-flex flex-column options gap-md">
            <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
              <p className={`text-primary m-0 text-truncate medium ${(selectedElement.disabled || isParentDisabled) && 'text-disabled'}`} title={option.name}>
                {option.descr}
              </p>
            </Tooltip>
          </div>
          <div className="d-flex align-items-center" title={values.hasOwnProperty(option.id) ? values[option.id].toString() : undefined}>
            <div className='d-flex flex-column flex-grow-1 survey-option visualisations-dropdown'>
              <Input
                className="form-control"
                disabled={selectedElement.disabled || isParentDisabled}
                onChange={(e) => onValueChange(e, 'string', option)}
                type="text"
                value={values.hasOwnProperty(option.id) ? values[option.id] : ''}
              />
            </div>
            <button
              type='button'
              className="btn btn-shadow px-1 ml-2"
              disabled={selectedElement.disabled || isParentDisabled}
              onClick={(e) => { e.preventDefault(); showFilterBuilder(option.id) }}>
              <Icon type="more-horizontal" />
            </button>
          </div>
        </div>
      );
    } else if (option.id === 'css') {
      let value = updateThemes[0];
      if (values.hasOwnProperty(option.id)) {
        const found = updateThemes.find((el) => el.id === values[option.id])
        if (found) {
          value = found
        };
      }
      options.push(
        <div key={id}>
          <div className="d-flex flex-column options">
            <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
              <p className={`text-primary m-0 text-truncate medium ${(selectedElement.disabled || isParentDisabled) && 'text-disabled'}`} title={option.name}>
                {option.descr}
              </p>
            </Tooltip>
          </div>
          <div className="d-flex flex-column survey-option">
            <DropdownButton
              disabled={selectedElement.disabled || isParentDisabled}
              className='btn-block'
              textField='name'
              onItemClick={({ item }) => onValueChange({ value: item }, 'css', option)}
              items={updateThemes}
              text={value.name}
            />
          </div>
        </div>
      );
    } else if (option.type === 'String') {
      if (option.id === 'quota') {
        options.push(
          <div key={id}>
            <div className="d-flex flex-column options">
              <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
                <p className={`text-primary m-0 text-truncate medium ${(selectedElement.disabled || isParentDisabled) && 'text-disabled'}`} title={option.name}>
                  {option.descr}
                </p>
              </Tooltip>
            </div>
            <div className="d-flex flex-column survey-option">
              <DropdownButton
                className='btn-block'
                textField='referenceDisplayLabel'
                text={theData.selectedItem ? theData.selectedItem.referenceQuotas?.setQuota?.referenceDisplayLabel : ""}
                items={theData.selectedItem ? theData.selectedItem.referenceQuotas?.availableQuotas : undefined}
                onItemClick={({ item }) => onValueChange({ value: item }, 'quotaStop', option)}
              />
            </div>
          </div>
        );
      } else if (option.id === 'loop') {
        options.push(
          <div key={id}>
            <div className="d-flex flex-column options">
              <div className="d-flex align-items-center gap-sm">
                <p className={`text-primary m-0 text-truncate medium ${(selectedElement.disabled || isParentDisabled) && 'text-disabled'}`}>
                  {option.descr}
                </p>
                <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
                  <i title="All question IDs inside the loop should end in $cnt; For instance Q1_$cnt;" className="far fa-question-circle" />
                </Tooltip>
              </div>
              <SectionLoopOption
                option={option}
                loopType={loopType}
                listItemValue={listItemValue}
                listItemsDropdown={listItemsDropdown}
                onValueChange={onValueChange}
                updateLoopType={updateLoopType}
              />
            </div>
          </div>
        )
      } else if (option.id === 'idref') {
        const allItems = theData.data.filter(el => el.type === "section").flatMap((el) => el.elements) // flatten all items from their sections
        const itemsAfterSelected = allItems.slice(allItems.findIndex((ques) => ques?.selected) + 1, allItems.length) // remove items before the selected item
        const filteredItems = itemsAfterSelected.filter((item) => item && item.type !== "quota" && item.type !== "goto") // remove quota and goto items
        if (theData.selectedItem && theData.selectedItem.type === 'goto' && Array.isArray(filteredItems)) {
          const items = [{ label: "Exit (complete)", id: "exit" }, { label: "Next section", id: "nextsection" }]
          filteredItems.push(...items)
        }
        const dropdownValue = filteredItems.find((el) => {
          if (el) {
            if (el.label === "Exit (complete)" || el.label === "Next section") {
              return el.id === selectedElement.attributes.idref
            }
            return el.label === selectedElement.attributes.idref
          }
          return false
        })
        options.push(
          <div key={id}>
            <div className="d-flex flex-column options">
              <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
                <p className={`text-primary m-0 text-truncate medium ${(selectedElement.disabled || isParentDisabled) && 'text-disabled'}`} title={option.name}>
                  {option.descr}
                </p>
              </Tooltip>
            </div>
            <div className="d-flex flex-column survey-option">
              <BaseDropDownList
                className='btn-block'
                disabled={selectedElement.disabled || isParentDisabled}
                data={filteredItems}
                value={dropdownValue ? dropdownValue : ""}
                filterable={true}
                textField="label"
                onChange={(e: { value: { label: string; id: string } }) => onValueChange(e.value.label === "Exit (complete)" || e.value.label === "Next section" ? { value: e.value.id } : { value: e.value.label }, 'string', option)} />
            </div>
          </div>
        )
      } else if (option.id === "if" && option.name === "Condition") {
        const allItems = theData.data.filter(el => el.type === "section").flatMap((el) => el.elements)
        const filteredItems = allItems.filter((item) => item && (item.type === "quota" || item.type === "ques" || (item.type === "goto" && item.selected))) // filter only questions, quotas and the selected goto
        const previousItem = filteredItems[filteredItems.findIndex((el) => el?.selected) - 1] // needed to filter out questions below the selected goto in filter builder

        options.push(
          <div key={id}>
            <div className="d-flex flex-column options">
              <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
                <p className={`text-primary m-0 text-truncate medium ${(selectedElement.disabled || isParentDisabled) && 'text-disabled'}`} title={option.name}>
                  {option.descr}
                </p>
              </Tooltip>
            </div>
            <div className="d-flex align-items-center" title={values.hasOwnProperty(option.id) ? values[option.id].toString() : undefined}>
              <div className='d-flex flex-column flex-grow-1 survey-option visualisations-dropdown'>
                <Input
                  className="form-control"
                  disabled={selectedElement.disabled || isParentDisabled}
                  onChange={(e) => onValueChange(e, 'string', option)}
                  type="text"
                  value={values.hasOwnProperty(option.id) ? values[option.id] : ''}
                />
              </div>
              <button
                type='button'
                className="btn btn-shadow px-1 ml-2"
                disabled={selectedElement.disabled || isParentDisabled}
                onClick={(e) => { e.preventDefault(); showFilterBuilder(option.id, option.name, previousItem ? previousItem.label : '') }}>
                <Icon type="more-horizontal" />
              </button>
            </div>
          </div>
        );
      } else if (option.id === 'mailprovider') {
        options.push(
          <div key={id}>
            <div className="d-flex flex-column options">
              <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
                <p className={`text-primary m-0 text-truncate medium ${(selectedElement.disabled || isParentDisabled) && 'text-disabled'}`} title={option.name}>
                  {option.descr}
                </p>
              </Tooltip>
            </div>
            <div className="d-flex flex-column survey-option">
              <DropdownButton
                textField='name'
                className='btn-block'
                loading={emailProviders.loading}
                onOpen={() => getEmailProviders()}
                items={[{ name: 'None' }, ...emailProviders.data]}
                text={emailProviders.data.find(el => el.id === theData.selectedItem?.attributes?.mailprovider)?.name || 'None'}
                onItemClick={({ item }) => onValueChange({ value: item.name === 'None' ? '' : item.id }, 'string', option)}
              />
            </div>
          </div>
        );
      } else {
        if (option.id !== 'id' && option.name !== 'Label') {
          options.push(
            <div key={id}>
              <div className="d-flex flex-column options">
                <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
                  <p className={`text-primary m-0 text-truncate medium ${(selectedElement.disabled || isParentDisabled) && 'text-disabled'}`} title={option.name}>
                    {option.descr}
                  </p>
                </Tooltip>
              </div>
              <div className="d-flex flex-column" title={values.hasOwnProperty(option.id) ? values[option.id].toString() : undefined}>
                {option.name === 'Label' ?
                  <Input
                    className="form-control"
                    pattern="((?=.*[a-z])|(?=.*[A-Z]))([a-zA-Z0-9_])\w+"
                    title="Labels can only contain characters a-z, A-Z, 0-9, _ and and has to contain one uppercase or lowercase letter"
                    disabled={selectedElement.disabled || isParentDisabled}
                    onChange={(e) => onValueChange(e, 'string', option)}
                    type="text"
                    value={values.hasOwnProperty(option.id) ? values[option.id] : ''}
                  />
                  :
                  option.id === 'mailfrom' || option.id === 'mailsubject' || option.id === 'mailto' ?
                    <Tooltip openDelay={100} position='bottom' anchorElement={'target'} filter={(element) => element.className.includes('disabled-mail-option-tooltip')}>
                      <input
                        type="text"
                        title="An email provider needs to be selected first."
                        className={`form-control ${option.id.includes('mail') && !isMailProviderSet ? 'disabled-mail-option-tooltip' : ''}`}
                        disabled={selectedElement.disabled || isParentDisabled || (option.id.includes('mail') && !isMailProviderSet)}
                        onChange={(e) => onValueChange({ value: e.target.value }, 'string', option)}
                        value={option.id in values ? values[option.id] : ''}
                      />
                    </Tooltip>
                    :
                    <Input
                      className="form-control test"
                      disabled={selectedElement.disabled || isParentDisabled}
                      onChange={(e) => onValueChange(e, 'string', option)}
                      type="text"
                      value={option.id in values ? values[option.id] : ''}
                    />
                }
              </div>
            </div>
          )
        }
      }
    } else if (option.id === 'hcdir') {
      if (dropdownValues.length !== 0 && dropdownValues.find((el) => el.name === option.type)?.enumValues.length !== 0) {
        let value = dropdownValues.find((el) => el.name === option.type)?.enumValues[0];
        if (values.hasOwnProperty(option.id)) {
          value = dropdownValues.find((el) => el.name === option.type)?.enumValues.find((el) => el.name === values[option.id]);
        }
        let displayOption = false;
        if (
          selectedElement.type === 'ques' &&
          theData.selectedItem && theData.selectedItem.elements.filter((el) => el.type === 'subq' && (el.attributes?.type === 'n' || el.attributes?.type === 'm'))
            .length > 0
        ) {
          displayOption = true;
        } else if (selectedElement.type === 'subq' && (selectedElement.attributes['type'] === 'rn' || selectedElement.attributes['type'] === 'rm')) {
          displayOption = true;
        } else {
          displayOption = false;
        }
        if (displayOption) {
          options.push(
            <div key={id}>
              <div className="d-flex flex-column options">
                <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
                  <p className={`text-primary m-0 text-truncate medium ${(selectedElement.disabled || isParentDisabled) && 'text-disabled'}`} title={option.name}>
                    {option.descr}
                  </p>
                </Tooltip>
              </div>
              <div className="d-flex flex-column survey-option">
                <DropdownButton
                  disabled={selectedElement.disabled || isParentDisabled}
                  className='btn-block'
                  onItemClick={({ item }) => onValueChange({ value: item }, 'dropdown', option)}
                  items={dropdownValues.find((el) => el.name === option.type)?.enumValues}
                  text={value?.text}
                />
              </div>
            </div>
          );
        }
      }
    } else if (option.id === "gdprPii") {
      const theValue = (values.gdprPii === "true") ? true : false;
      // Values for attributes can only be stored as strings on backend, this line is compensation for that
      options.push(
        <div key={id}>
          <div className="d-flex flex-column">
            <div className={`d-flex align-items-center mt-2 ${(selectedElement.disabled || isParentDisabled) && 'text-disabled'}`}>
              <input className="mr-2" type="checkbox" id="gdprPiiInput" checked={theValue} onChange={(e) => onValueChange(e, 'checkbox', option)} />
              <label className='m-0 medium' htmlFor="gdprPiiInput">GDPR</label>
              <Tooltip openDelay={100} position='top' anchorElement={'target'}>
                <span className='d-flex ml-0' title='Mark data as PII'>
                  <Icon type="info-tooltip" className="pe-none" />
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
      )
    } else if (option.id === 'rotend') {
      if (dropdownValues.length !== 0 && dropdownValues.find((el) => el.name === option.type)?.enumValues.length !== 0) {
        let value = dropdownValues.find((el) => el.name === option.type)?.enumValues[0];
        if (option.id in values) {
          value = dropdownValues.find((el) => el.name === option.type)?.enumValues.find((el) => el.name === values[option.id]);
        }
        options.push(
          <div key={id}>
            <div className="d-flex flex-column options">
              <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
                <p className={`text-primary m-0 text-truncate medium ${(selectedElement.disabled || isParentDisabled) && 'text-disabled'}`} title={option.name}>
                  {option.descr}
                </p>
              </Tooltip>
            </div>
            <Switch
              checked={value?.text === 'yes' ? true : false}
              onChange={(e) => onValueChange({ value: e.value ? { text: "yes" } : { text: "no" } }, 'dropdown', option)}
              size="small"
            />
          </div>
        );
      }
    } else {
      if (dropdownValues.length !== 0 && dropdownValues.find((el) => el.name === option.type)?.enumValues.length !== 0) {
        let data: { idx?: number; name: string; text: string }[] = []
        const foundDropdownValue = dropdownValues.find((el) => el.name === option.type)
        if (option.id === 'decimals' || option.id === 'length') {
          if (foundDropdownValue?.enumValues) {
            data = [...foundDropdownValue.enumValues]
          }
        } else {
          if (foundDropdownValue?.enumValues) {
            data = [{ name: 'none', text: 'None' }, ...foundDropdownValue.enumValues]
          }
        }

        options.push(
          <div key={id}>
            <div className="d-flex flex-column options">
              <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
                <p className={`text-primary m-0 text-truncate medium ${(selectedElement.disabled || isParentDisabled) && 'text-disabled'}`} title={option.name}>
                  {option.descr}
                </p>
              </Tooltip>
            </div>
            <div className="d-flex flex-column survey-option">
              <DropdownButton
                disabled={selectedElement.disabled || isParentDisabled}
                className='btn-block'
                onItemClick={({ item }) => onValueChange({ value: item.text === 'None' ? { ...item, text: "" } : item }, 'dropdown', option)}
                items={data}
                text={values.hasOwnProperty(option.id) ? data.find((el) => el.name === values[option.id])?.text : data[0]?.text}
              />
            </div>
          </div>
        );
      }
    }
  });

  return options;
};
