import { Input } from '@progress/kendo-react-inputs';

import type { TwilioSmsProviderFormData } from '../../../../../../interfaces/emailProviderInterfaces/emailProviderInterfaces';

interface Props {
  emailProvider: TODO
  formData: TwilioSmsProviderFormData
  onChangeHandler: (e: TODO, name: string) => void
}

export const EmailProviderTwilioSmsSettings = ({ formData, emailProvider, onChangeHandler }: Props) => {
  return (
    <div>
      <div className="mb-2">
        <Input
          required
          label={'AccountSid'}
          value={formData.accountSid}
          onChange={(e) => onChangeHandler(e, 'accountSid')}
        />
      </div>
      <div className="mb-2">
        <Input
          required
          label={'ServiceSid'}
          value={formData.serviceSid}
          onChange={(e) => onChangeHandler(e, 'serviceSid')}
        />
      </div>
      <div className="mb-2">
        <Input
          required
          label={'ApiKeySid'}
          value={formData.apiKeySid}
          onChange={(e) => onChangeHandler(e, 'apiKeySid')}
        />
      </div>
      <div className="mb-2">
        <Input
          required={!emailProvider}
          label={'ApiKeyToken'}
          value={formData.apiKeyToken}
          onChange={(e) => onChangeHandler(e, 'apiKeyToken')}
        />
      </div>
    </div>
  )
}