import { Tooltip } from "@progress/kendo-react-tooltip"
import { useDispatch, useSelector } from "react-redux";

import { DropdownButton } from "../../../../../../../shared/DropdownButton/DropdownButton"
import { useDebounce } from "../../../../../../../shared/customHooks/useDebounce";
import { Icon } from "../../../../../../../shared/Icon/Icon";
import type { RootState } from "../../../../../../../../store/reducers/rootReducer";

type Props = {
  answersSort: string;
  setAnswersSort: (sort: string) => void;
  subQuesIndex: number;
  subquestion: TODO;
  onAddElement: (type: string, index: number) => void;
  onAdvancedEdit: (type: string, index: number) => void;
  listItemsDropdown: TODO;
}

export const AnswersActions = ({ subQuesIndex, subquestion, answersSort, setAnswersSort, onAddElement, onAdvancedEdit, listItemsDropdown }: Props) => {
  const dispatch = useDispatch();
  const { theData } = useSelector((theState: RootState) => (theState.surveyInitialDataReducer));
  const saveWithDebounce = useDebounce(() => dispatch({ type: 'SAVE_QUESTION' }), 1000);

  const onMoreActionsItemClick = (action: string, elements?: TODO) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    const updatedElements = JSON.parse(JSON.stringify(elements))
    if (answersSort === action) {
      setAnswersSort('')
    } else {
      setAnswersSort(action)
      if (action === 'Ascending order') {
        updatedElements.sort((a: { attributes: { code: string; }; }, b: { attributes: { code: string; }; }) => Number.parseFloat(a.attributes.code) - Number.parseFloat(b.attributes.code));
      } else if (action === 'Descending order') {
        updatedElements.sort((a: { attributes: { code: string; }; }, b: { attributes: { code: string; }; }) => Number.parseFloat(b.attributes.code) - Number.parseFloat(a.attributes.code));
      }
      updatedSelectedItem.elements[theData.selectedSubquestion].elements.find((el: { type: string; }) => el.type === 'hgroup').elements = updatedElements
      dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
      saveWithDebounce()
    }
  }

  const renderMoreActionsItem = (item: TODO, elements: TODO) => {
    if (item.text === "Ascending order") {
      return (
        <div className="d-flex flex-column ">
          <div style={{ fontSize: "0.8rem" }} className="text-muted px-4 pt-2 pb-1">Code</div>
          <button
            type="button"
            className="dropdown-item px-4"
            onClick={() => onMoreActionsItemClick(item.text, elements)}>
            <span className='text-truncate'>{item.text}</span>
          </button>
        </div>
      )
    }
    return (
      <button
        type="button"
        className="dropdown-item px-4"
        onClick={() => onMoreActionsItemClick(item.text, elements)}>
        <span className='text-truncate'>{item.text}</span>
      </button>
    )
  }

  const onAnswersDropdownItemClick = ({ item }: { item: TODO }) => {
    if (item.text === 'Edit all answers') {
      onAdvancedEdit('h', subQuesIndex)
    }
  }

  const addList = (name: string) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    const refElement = {
      type: "listref",
      attributes: { idref: name }
    }
    const selectedSubQuestion = updatedSelectedItem.elements.filter((el: { type: string }) => el.type === "subq")[theData.selectedSubquestion];
    if (selectedSubQuestion) {
      const filtered = selectedSubQuestion.elements.find((el: { type: string }) => el.type === "hgroup").elements.filter((el: { type: string }) => el.type === 'listref')
      const found = filtered.find((el: { attributes: { idref: string } }) => el.attributes.idref === name)
      if (found) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "List already exists" } })
      } else {
        selectedSubQuestion.elements.find((el: { type: string }) => el.type === "hgroup").elements.push(refElement)
      }

      dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
      saveWithDebounce()
    }
  }

  return (
    <div className="d-flex align-items-center">
      <Tooltip className='answer-layout-table-tooltip ' anchorElement="target" position="top">
        <DropdownButton
          hideChevron={true}
          title="More actions"
          icon="more-horizontal"
          className="btn btn-transparent p-0 mr-1"
          renderItem={(item) => renderMoreActionsItem(item, theData.selectedItem.elements.find((el: TODO) => el.type === 'rgroup').elements.filter((row: TODO) => row.type === 'listref' || row.elements?.length > 0))}
          items={[{ text: 'Ascending order' }, { text: 'Descending order' }]}
        />
      </Tooltip>
      <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
        <button
          type='button'
          onClick={() => onAnswersDropdownItemClick({ item: { text: 'Edit all answers' } })}
          disabled={theData.selectedItem.disabled || subquestion.disabled || theData.selectedItem.published || subquestion.elements.find((el: { type: string; }) => el.type === 'hgroup').elements.filter((el: { type: string; }) => el.type === 'h').find((el: { elements: { filter: (arg0: (el: TODO) => boolean) => { (): TODO; new(): TODO; length: number; }; }; }) => el.elements.filter(el => el.type === 'text').length > 1)}
          title={"Paste answers"}
          className="btn btn-transparent p-0 mr-1">
          <Icon type="dashboard-edit" className='pe-none fill-primary' />
        </button>
      </Tooltip>
      <Tooltip className='answer-layout-table-tooltip ' anchorElement="target" position="top">
        <DropdownButton
          className={`btn btn-transparent p-0 mr-1 ${theData.originalData.lists?.length === 0 ? "disabled" : ""}`}
          hideChevron={true}
          icon="add-lists"
          onItemClick={e => addList(e.item.text)}
          items={listItemsDropdown}
          title="Add list"
        />
      </Tooltip>
      <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
        <button
          type="button"
          className="btn btn-icon btn-transparent p-0"
          title="Add answer"
          onClick={() => onAddElement('h', subQuesIndex)}
          disabled={theData.selectedItem.disabled || subquestion.disabled}>
          <Icon type="add" className='pe-none fill-primary' />
        </button>
      </Tooltip>
    </div>
  )
}
