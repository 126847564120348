import Editor from '@monaco-editor/react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../../../../../../../store/reducers/rootReducer';

export const SurveyCustomQuestionsOverviewSettingsTabContent = () => {
  const dispatch = useDispatch();
  const { editorValues } = useSelector((theState: RootState) => theState.surveyCustomQuestionsStateReducer);

  return (
    <div className="d-flex flex-column h-100">
      <div className='custom-questions-overview-editor-container'>
        <p className='mb-0'>The following properties refer to the settings of the question</p>
        <Editor
          defaultLanguage='json'
          defaultValue={editorValues.questionSettings}
          theme='vs-dark'
          className='my-3'
          onChange={(value) => dispatch({ type: 'UPDATE_EDITOR_VALUES', payload: { ...editorValues, questionSettings: value } })}
        />
      </div>
    </div>
  )
}