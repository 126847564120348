import Editor from '@monaco-editor/react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../../../../../../../store/reducers/rootReducer';

export const SurveyCustomQuestionsOverviewJavascriptTabContent = () => {
  const dispatch = useDispatch()
  const { editorValues } = useSelector((theState: RootState) => theState.surveyCustomQuestionsStateReducer)

  return (
    <div className="d-flex flex-column h-100">
      <p className='mb-3'>External Javascript references (one per line)</p>
      <textarea
        defaultValue={editorValues.references && editorValues.references.js ? editorValues.references.js.join("\n") : null}
        onChange={(e) => dispatch({ type: 'UPDATE_EDITOR_VALUES', payload: { ...editorValues, references: { ...editorValues.references, js: e.target.value.split(/\n/) } } })}
        className="form-control mb-3"
        rows={5}>
      </textarea>
      <div className='custom-questions-overview-editor-container'>
        <Editor
          theme='vs-dark'
          defaultValue={editorValues.js}
          defaultLanguage='javascript'
          onChange={(value) => dispatch({ type: 'UPDATE_EDITOR_VALUES', payload: { ...editorValues, js: value } })}
        />
      </div>
    </div>
  )
}