import type { IconsType } from '../../../interfaces/iconInterfaces/iconInterfaces';
import { DropdownButtonRenderedItems } from './DropdownButtonRenderedItems/DropdownButtonRenderedItems';
import { DropdownButtonMainButtonContent } from './DropdownButtonMainButtonContent/DropdownButtonMainButtonContent';

export interface DropdownItem {
  [field: string]: TODO
}

interface Props {
  text?: string
  icon?: IconsType
  disabled?: boolean
  className?: string
  textField?: string
  hideChevron?: boolean
  items?: DropdownItem[]
  direction?: 'left' | 'right' | 'up'
  menuPosition?: 'left' | 'right'
  parentClass?: string
  onOpen?: () => void
  renderMainButton?: () => void
  onItemClick?: (e: TODO) => void
  renderItem?: (item: TODO) => void
  title?: string
  fillColor?: string
  loading?: boolean
}

export const DropdownButton = ({ text, icon, disabled, className, hideChevron, items, textField, direction, menuPosition, parentClass, onOpen, onItemClick, renderItem, renderMainButton, title, fillColor, loading }: Props) => {
  return (
    <div title={title} className={`${direction ? `drop${direction}` : 'dropdown'} ${parentClass ? parentClass : ''}`}>
      <button
        type="button"
        disabled={disabled}
        aria-haspopup="true"
        aria-expanded="false"
        data-toggle="dropdown"
        onClick={onOpen}
        className={`btn btn-icon dropdown-toggle ${className ? className : ''}`}>
        <DropdownButtonMainButtonContent
          fillColor={fillColor}
          text={text}
          icon={icon}
          hideChevron={hideChevron}
          loading={loading}
          renderMainButton={renderMainButton} />
      </button>
      <div
        style={{ maxHeight: '400px', overflow: 'scroll' }}
        className={`dropdown-menu dropdown-menu-${menuPosition ? menuPosition : 'right'} border-0`}
        aria-labelledby="survey-dropdown">
        <div>
          {
            items && items.length > 0 ?
              <DropdownButtonRenderedItems
                text={text}
                items={items}
                textField={textField}
                renderItem={renderItem && renderItem}
                onItemClick={onItemClick}
              />
              :
              <p className='py-3 text-center text-muted m-0'>No data found</p>
          }
        </div>
      </div>
    </div>
  )
}