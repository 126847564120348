import React from 'react';
import { AddProjectAccessControl } from '../AddProjectAccessControl/AddProjectAccessControl';
import { AddProjectBranding } from '../AddProjectBranding/AddProjectBranding';
import { AddProjectGeneralSettings } from '../AddProjectGeneralSettings/AddProjectGeneralSettings';

export const AddProjectContent = ({ userData, stepperValue, tags, formData, onChangeHandler, setShowFileManager, list, isLoadingAccessData, setList }) => {
  if (stepperValue === 0) {
    return (
      <AddProjectGeneralSettings formData={formData} tags={tags} value={formData.tags} onChangeHandler={onChangeHandler} />
    )
  } if (stepperValue === 1) {
    return (
      <AddProjectAccessControl userData={userData} isLoadingAccessData={isLoadingAccessData} list={list} setList={setList} formData={formData} onChangeHandler={onChangeHandler} />
    )
  } if (stepperValue === 2) {
    return (
      <AddProjectBranding formData={formData} onChangeHandler={onChangeHandler} setShowFileManager={setShowFileManager} />
    )
  } return null
}