import { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GroupCard } from './GroupCard/GroupCard';
import { EditGroupModal } from './EditGroupModal/EditGroupModal';
import { DeleteGroupModal } from './DeleteGroupModal/DeleteGroupModal';
import type { RootState } from '../../../../store/reducers/rootReducer';
import { AdminCardsSkeleton } from '../AdminCardsSkeleton/AdminCardsSkeleton';
import type { Group } from '../../../../interfaces/adminInterfaces/adminInterfaces';
import { fetchGetJson as getAllGroups } from '../../../../services/services';

type GroupModal = {
  group?: Group | null;
  show: boolean;
  id?: null | string
}

export const GroupsTabContent = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { shouldUpdateGroups, groups } = useSelector((theState: RootState) => (theState.adminReducer));
  const [didMount, setDidMount] = useState(false);
  const [showEditGroup, setShowEditGroup] = useState<GroupModal>({ group: null, show: false });
  const [showDeleteGroup, setShowDeleteGroup] = useState<GroupModal>({ group: null, show: false });

  useEffect(() => {
    if (shouldUpdateGroups || !didMount) {
      setDidMount(true);
      dispatch({ type: "SHOULD_UPDATE_GROUPS", payload: false });
      getAllGroups('groups', token)
        .then((res: TODO) => {
          if (res.error || res.message) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.message || res.error } })
          } else {
            dispatch({ type: 'UPDATE_GROUPS', payload: res })
          }
        })
    }
  }, [token, shouldUpdateGroups, didMount, dispatch])

  return (
    <Fragment>
      {
        showEditGroup.show &&
        <EditGroupModal
          group={showEditGroup.group}
          handleClose={() => setShowEditGroup({ show: false, id: null })}
          updateGroups={() => dispatch({ type: "SHOULD_UPDATE_GROUPS", payload: true })} />
      }
      {
        showDeleteGroup.show &&
        <DeleteGroupModal
          groups={groups}
          group={showDeleteGroup.group}
          handleClose={() => setShowDeleteGroup({ show: false, id: null })}
          updateGroups={(data: Group[]) => dispatch({ type: 'UPDATE_GROUPS', payload: data })}
        />
      }
      <div className="container-fluid py-5">
        <div style={{ rowGap: '1.5rem' }} className="row">
          {
            groups !== null ?
              groups.length > 0 ?
                groups.map((group: Group) => (
                  <Fragment key={group.id}>
                    <GroupCard
                      group={group}
                      setShowEditGroup={(data) => setShowEditGroup(data)}
                      setShowDeleteGroup={(data) => setShowDeleteGroup(data)}
                    />
                  </Fragment>
                ))
                :
                <div className="d-flex flex-column gap-lg text-disabled text-center h5 pt-10 w-100">
                  <i className="fal fa-2x fa-folder-open" />
                  <p className='m-0'>No groups have been found</p>
                </div>
              :
              <AdminCardsSkeleton />
          }
        </div>
      </div>
    </Fragment>
  )
}