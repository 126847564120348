const initialState = {
  createdById: "",
  createdByName: "",
  createdUtc: "",
  customerId: "",
  id: "",
  isDeleted: false,
  pages: [],
  originalBackendPages: [],
  activePage: {},
  pinboardId: "",
  projectId: "a",
  type: "",
  _etag: "",
  reloadPage: false,
};

const pinboardStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PINBOARD_DATA":
      return {
        ...action.payload
      };
    case "RESET_PINBOARD_DATA":
      return {
        ...initialState
      };
    case "SET_ACTIVE_PINBOARD_PAGE":
      return {
        ...state,
        activePage: action.payload.page,
        reloadPage: action.payload.reload
      };
    case "UPDATE_PINBOARD_PUBLISH_DATA":
      return {
        ...state,
        publishData: action.payload
      };
    case "UPDATE_PINBOARD_PAGES":
      return {
        ...state,
        pages: action.payload
      };
    case "UPDATE_PINBOARD_ETAG":
      return {
        ...state,
        _etag: action.payload
      };
    default:
      return state;
  }
}

export default pinboardStateReducer;