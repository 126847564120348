//  Make questions active/selected when clicked
export function onSelectQuestion(theState, id) {
  const updatedState = JSON.parse(JSON.stringify(theState.state))
  updatedState.firstColumn.forEach((col, colId) => {
    if (colId === id) {
      col.active = true;
      updatedState.activeQuestion = col
    } else {
      col.active = false;
    }
  })

  updatedState.updateTable = true

  return {
    ...theState,
    state: updatedState
  }
}
