export default (droppableSource, droppableDestination, sourceClone, result) => {
  const droppedQuestion = sourceClone[droppableSource.index]
  if (droppedQuestion.selected) {
    if (droppableSource.droppableId !== droppableDestination.droppableId) {
      const droppedQuestionIndex = result.checkedNum.indexOf(droppedQuestion)
      if (droppableDestination.droppableId === 'ITEMS') {
        result.checkedNum.splice(droppedQuestionIndex, 1)
        if (result.checkedNum.length > 0) {
          result.selectedQuestionSource = result.checkedNum[result.checkedNum.length - 1].source
        }
      } else if (droppableDestination.droppableId === 'firstColumn') {
        result.checkedNum.find(el => el.id === droppedQuestion.id).source = "firstColumn"
        result.selectedQuestionSource = "firstColumn"
      } else if (droppableDestination.droppableId === 'scndColumn') {
        result.selectedQuestionSource = "scndColumn"
        result.checkedNum.find(el => el.id === droppedQuestion.id).source = "scndColumn"
      }
    }
  }
}