export default (date, type, isTrial) => {
  if (date && date !== undefined) {
    if (!type) {
      return date.slice(0, 10)
    } else if (type === "localTime") {
      return new Date(date).toLocaleString("en-GB", {
        dateStyle: "medium",
        timeStyle: "short",
      });
    } else if (type === "filterTrial") {
      return ((new Date(date.slice(0, 10)) >= new Date()) || isTrial) ? "Active trial" : ""
    }
  }
};
