import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from '@progress/kendo-react-inputs';

import type { RootState } from '../../../../../../../../store/reducers/rootReducer';
import { FullHeightSpinner } from '../../../../../../../shared/FullHeightSpinner/FullHeightSpinner';
import { InProgressOverlay } from '../../../../../../../shared/InProgressOverlay/InProgressOverlay'
import { BaseDropDownList } from "../../../../../../../shared/Inputs";
import { Icon } from '../../../../../../../shared/Icon/Icon';
import { fetchGetJson as getVectorIdInformation } from '../../../../../../../../services/services';
import { ErrorMessage } from '../../../../../../../shared/ErrorMessage/ErrorMessage';

interface Props {
  handleEditTool: () => void
}

interface DatasetOption {
  address: string
  vectorId: string
  type?: string
}

interface DatasetOptions {
  optionsA: DatasetOption[]
  optionsB: DatasetOption[]
}

interface RadioButtonData {
  alternative: boolean
  respondent: boolean
  numeric: boolean
  text: boolean
}

interface RadioButtonsData {
  dataset1: RadioButtonData
  dataset2: RadioButtonData
}

export const AppendToolTabContent = ({ handleEditTool }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { workflowData } = useSelector((theState: RootState) => theState.workflowStateReducer);
  const [datasetOptions, setDatasetOptions] = useState<DatasetOptions>({
    optionsA: [],
    optionsB: []
  })

  const initialRadioButtonData = {
    dataset1: {
      alternative: false,
      respondent: false,
      numeric: false,
      text: false
    },
    dataset2: {
      alternative: false,
      respondent: false,
      numeric: false,
      text: false
    }
  };
  const updatedRadioButtonData = {
    dataset1: {
      alternative: !!(workflowData.selectedTool && workflowData.selectedTool.compareTypeA === 'UseAlternativeId'),
      respondent: !!(workflowData.selectedTool && workflowData.selectedTool.compareTypeA === 'UseRespondentId'),
      numeric: !!(workflowData.selectedTool && workflowData.selectedTool.compareTypeA === 'UseNumericQuestion'),
      text: !!(workflowData.selectedTool && workflowData.selectedTool.compareTypeA === 'UseTextQuestion')
    },
    dataset2: {
      alternative: !!(workflowData.selectedTool && workflowData.selectedTool.compareTypeB === 'UseAlternativeId'),
      respondent: !!(workflowData.selectedTool && workflowData.selectedTool.compareTypeB === 'UseRespondentId'),
      numeric: !!(workflowData.selectedTool && workflowData.selectedTool.compareTypeB === 'UseNumericQuestion'),
      text: !!(workflowData.selectedTool && workflowData.selectedTool.compareTypeB === 'UseTextQuestion')
    }
  };
  const [radioButtonsData, setRadioButtonsData] = useState<RadioButtonsData>(updatedRadioButtonData);

  const dispatch = useDispatch();

  useEffect(() => {
    if (workflowData.selectedTool?.inputA?.projectId && workflowData.selectedTool.inputA.datasetId) {
      getVectorIdInformation(`projects/${workflowData.selectedTool.inputA.projectId}/workflows/helpers/${workflowData.selectedTool.inputA.inputIsSurveyDataset ? 'surveys' : 'datasets'}/${workflowData.selectedTool.inputA.datasetId}/compare-information`, token)
        .then((res: TODO) => {
          if (res) {
            if (res.alternativeIdVectorId || (res.numerics?.length) || res.respondentIdVectorId || (res.texts?.length)) {
              setDatasetOptions(prevState => ({ ...prevState, optionsA: generateDatasetOptions(res) }));
            }
          }
        })
    }
    if (workflowData.selectedTool?.inputB?.projectId && workflowData.selectedTool.inputB.datasetId) {
      getVectorIdInformation(`projects/${workflowData.selectedTool.inputB.projectId}/workflows/helpers/${workflowData.selectedTool.inputB.inputIsSurveyDataset ? 'surveys' : 'datasets'}/${workflowData.selectedTool.inputB.datasetId}/compare-information`, token)
        .then((res: TODO) => {
          if (res) {
            if (res.alternativeIdVectorId || (res.numerics?.length) || res.respondentIdVectorId || (res.texts?.length)) {
              setDatasetOptions(prevState => ({ ...prevState, optionsB: generateDatasetOptions(res) }));
            }
          }
        })
    }
  }, [workflowData.selectedTool, token])

  const handleRadioButtonClick = (group: 'dataset1' | 'dataset2', field: 'alternative' | 'respondent' | 'numeric' | 'text') => {
    setMatchMethodByError(false);
    if (field === "respondent") {
      setRadioButtonsData(prevState => (
        { ...prevState, "dataset1": { ...initialRadioButtonData[group], [field]: true }, "dataset2": { ...initialRadioButtonData[group], [field]: true } }
      ));

      dispatch({ type: 'UPDATE_TOOL', payload: { ...workflowData.selectedTool, compareTypeB: 'UseRespondentId', compareVectorIdB: null, compareTypeA: 'UseRespondentId', compareVectorIdA: null } });
      return;
    }

    if (field === "numeric" || field === "text") {
      group === "dataset1" ? dispatch({ type: 'UPDATE_TOOL', payload: { ...workflowData.selectedTool, compareTypeA: "NotSet", compareVectorIdA: null } }) : dispatch({ type: 'UPDATE_TOOL', payload: { ...workflowData.selectedTool, compareTypeB: "NotSet", compareVectorIdB: null } })
    }


    if ((field === "alternative" || field === "numeric" || field === "text") && radioButtonsData.dataset1.respondent && radioButtonsData.dataset2.respondent) {
      setRadioButtonsData(prevState => ({ ...prevState, "dataset2": { ...initialRadioButtonData[group], "respondent": false }, "dataset1": { ...initialRadioButtonData[group], "respondent": false } }))

    }

    setRadioButtonsData(prevState => ({ ...prevState, [group]: { ...initialRadioButtonData[group], [field]: true } }))
  }

  const updateSelectedTool = (field: string, value: TODO) => {
    dispatch({ type: 'UPDATE_TOOL', payload: { ...workflowData.selectedTool, [field]: value } })
  }

  const setMatchMethodMergeError = (show: boolean) => {
    dispatch({ type: 'APPEND_MATCH_MEGE_ERROR', payload: show });
  }

  const setMatchMethodByError = (show: boolean) => {
    dispatch({ type: 'APPEND_MATCH_BY_ERROR', payload: show });
  }

  const handleSetMergeMethods = (mergeMethod: string, value: boolean) => {
    setMatchMethodMergeError(false);
    if (value) {
      updateSelectedTool('mergeMethod', mergeMethod)
    } else {
      updateSelectedTool('mergeMethod', "NotSet")
    }
  }

  const handleSetMatchBy = (input: 'A' | 'B', value: string) => {
    const datasetOption: TODO = datasetOptions[`options${input}`];
    const selectedOption = datasetOption.find((option: DatasetOption) => option.address === value);

    if (selectedOption.type === 'numeric') {
      dispatch({ type: 'UPDATE_TOOL', payload: { ...workflowData.selectedTool, [`compareType${input}`]: 'UseNumericQuestion', [`compareVectorId${input}`]: selectedOption.value } })
    } else if (selectedOption.type === 'text') {
      dispatch({ type: 'UPDATE_TOOL', payload: { ...workflowData.selectedTool, [`compareType${input}`]: 'UseTextQuestion', [`compareVectorId${input}`]: selectedOption.value } })
    }
  }

  const generateDatasetOptions = (res: TODO) => {
    const { numerics, texts } = res;
    const numericsArr = numerics?.length ? numerics.map((numeric: DatasetOption) => ({
      address: numeric.address.split('.')[0],
      value: numeric.vectorId,
      type: 'numeric'
    })) : [];
    const textsArr = texts?.length ? texts.map((text: DatasetOption) => ({
      address: text.address.split('.')[0],
      value: text.vectorId,
      type: 'text'
    })) : []
    const vectorIdInformation = [
      ...numericsArr,
      ...textsArr
    ];
    return vectorIdInformation;
  }

  return (
    <>
      <div className="d-flex align-items-center workflow-tool-header h-48 border-bottom">
        <div className="d-flex justify-content-between align-items-center w-100">
          <h6 className="mb-0 strong mr-2">{workflowData.selectedTool.toolType} tool</h6>
          <button type='button' onClick={handleEditTool} className="btn btn-transparent btn-icon icon-l" ><Icon type="dashboard-edit" className='mr-1 fill-primary' />Edit tool</button>
        </div>
      </div>
      <div className="d-flex flex-column h-100 workflow-tool-append" >
        {(workflowData.loadingTool && workflowData.loadingToolType === 'load') ?
          <FullHeightSpinner />
          : workflowData.selectedTool && !workflowData.loadingTool &&
          <div className="d-flex flex-column p-5 align-items-center gap-lg" style={{ height: 'calc(100% - 42px)', overflow: 'auto' }}>
            {workflowData.loadingTool && workflowData.loadingToolType === 'save' &&
              <InProgressOverlay type="overlay" theme="primary" message='Saving' />
            }
            <div className="d-flex flex-column align-items-stretch content w-100 gap-md">
              <h6 className="stronger m-0">Type</h6>

              <div className="d-flex flex-column p-4 rounded border gap-md">
                <div className="d-flex align-items-center gap-md">
                  <Switch checked={workflowData.selectedTool && workflowData.selectedTool.mergeMethod === 'Match'} onChange={(e: TODO) => handleSetMergeMethods('Match', e.value)} size="small" />
                  <p className="fixed-row strong m-0">Match</p>
                  <p className="text-muted m-0">Only include respondents that are present in both datasets</p>
                </div>

                <div className="d-flex align-items-center gap-md">
                  <Switch checked={workflowData.selectedTool && workflowData.selectedTool.mergeMethod === 'Merge'} onChange={(e: TODO) => handleSetMergeMethods('Merge', e.value)} size="small" />
                  <p className="fixed-row strong m-0">Merge</p>
                  <p className="text-muted m-0">Include all respondents that are present in both datasets</p>
                </div>

                <div className="d-flex align-items-center gap-md">
                  <Switch checked={workflowData.selectedTool && workflowData.selectedTool.mergeMethod === 'Master'} onChange={(e: TODO) => handleSetMergeMethods('Master', e.value)} size="small" />
                  <p className="fixed-row strong m-0">Master</p>
                  <p className="text-muted m-0">Only include respondents that are present in master datasets (number 1)</p>
                </div>

                <div className="d-flex align-items-center gap-md">
                  <Switch checked={workflowData.selectedTool && workflowData.selectedTool.mergeMethod === 'Lookup'} onChange={(e: TODO) => handleSetMergeMethods('Lookup', e.value)} size="small" />
                  <p className="fixed-row strong m-0">Lookup</p>
                  <p className="text-muted m-0">Respondents master datasets (number 1) receive corresponding data from second dataset</p>
                </div>
              </div>
              {
                workflowData.appendMatchMergeError &&
                <ErrorMessage
                  type="alert"
                  errorMessage="Please select an answer for Type"
                  onHide={() => setMatchMethodMergeError(false)}
                />
              }
            </div>

            <div className="d-flex flex-column align-items-stretch content w-100 gap-md">
              <div className='d-flex flex-column'>
                <h6 className="m-0 stronger">Match by</h6>
                <p className="text-muted m-0">Select either respid, altid, a numeric question or a text question to match respondents.</p>
              </div>

              <div className='d-flex flex-row w-100 gap-md'>
                <div className="d-flex flex-column w-50 p-4 rounded border gap-md">
                  <p className="strong m-0">Dataset 1</p>
                  <div className="d-flex align-items-center gap-md">
                    <input
                      type="radio"
                      id="dataset1-altid"
                      className="k-radio k-radio-md"
                      checked={radioButtonsData.dataset1.alternative}
                      onClick={() => {
                        dispatch({ type: 'UPDATE_TOOL', payload: { ...workflowData.selectedTool, compareTypeA: 'UseAlternativeId', compareVectorIdA: null } });
                        handleRadioButtonClick('dataset1', 'alternative')
                      }} />
                    <label className='m-0 cursor-pointer' htmlFor="dataset1-altid">altid</label>
                  </div>

                  <div className="d-flex align-items-center gap-md">
                    <input
                      type="radio"
                      id="dataset1-resid"
                      className="k-radio k-radio-md"
                      disabled={radioButtonsData.dataset2.alternative || radioButtonsData.dataset2.numeric || radioButtonsData.dataset2.text}
                      checked={radioButtonsData.dataset1.respondent}
                      onClick={() => {
                        handleRadioButtonClick('dataset1', 'respondent')
                      }} />
                    <label className={`m-0 cursor-pointer ${radioButtonsData.dataset2.alternative || radioButtonsData.dataset2.numeric || radioButtonsData.dataset2.text ? "disabled" : ""}`} htmlFor="dataset1-resid">respid</label>
                  </div>

                  <div className="d-flex align-items-center gap-md">
                    <input
                      type="radio"
                      id="dataset1-num"
                      className="k-radio k-radio-md"
                      disabled={radioButtonsData.dataset2.text}
                      checked={radioButtonsData.dataset1.numeric}
                      onClick={() => handleRadioButtonClick('dataset1', 'numeric')} />
                    <label className={`m-0 cursor-pointer ${radioButtonsData.dataset2.text ? "disabled" : ""}`} htmlFor="dataset1-num">Numeric question</label>
                  </div>
                  {radioButtonsData.dataset1.numeric &&
                    <BaseDropDownList
                      className="ml-3 w-100"
                      data={datasetOptions.optionsA.filter((option: DatasetOption) => option.type === 'numeric').map((option: DatasetOption) => option.address)}
                      value={workflowData.selectedTool.compareTypeA === 'UseNumericQuestion' && datasetOptions.optionsA.find((el: TODO) => el.value === workflowData.selectedTool.compareVectorIdA)?.address}
                      onChange={(e: { target: { value: string; }; }) => handleSetMatchBy('A', e.target.value)}
                    />
                  }
                  <div className="d-flex align-items-center gap-md">
                    <input
                      type="radio"
                      id="dataset1-text"
                      className="k-radio k-radio-md"
                      disabled={radioButtonsData.dataset2.numeric}
                      checked={radioButtonsData.dataset1.text}
                      onClick={() => handleRadioButtonClick('dataset1', 'text')} />
                    <label className={`m-0 cursor-pointer ${radioButtonsData.dataset2.numeric ? "disabled" : ""}`} htmlFor="dataset1-text">Text question</label>
                  </div>

                  {radioButtonsData.dataset1.text &&
                    <BaseDropDownList
                      className="ml-3 w-100"
                      data={datasetOptions.optionsA.filter((option: DatasetOption) => option.type === 'text').map((option: DatasetOption) => option.address)}
                      value={workflowData.selectedTool.compareTypeA === 'UseTextQuestion' && datasetOptions.optionsA.find((el: TODO) => el.value === workflowData.selectedTool.compareVectorIdA)?.address}
                      onChange={(e: { target: { value: string; }; }) => handleSetMatchBy('A', e.target.value)}
                    />}
                </div>

                <div className="d-flex flex-column w-50 p-4 rounded border gap-md">
                  <p className="m-0 strong">Dataset 2</p>

                  <div className="d-flex align-items-center gap-md">
                    <input
                      type="radio"
                      id="dataset2-altid"
                      className="k-radio k-radio-md"
                      checked={radioButtonsData.dataset2.alternative}
                      onClick={() => {
                        dispatch({ type: 'UPDATE_TOOL', payload: { ...workflowData.selectedTool, compareTypeB: 'UseAlternativeId', compareVectorIdB: null } });
                        handleRadioButtonClick('dataset2', 'alternative')
                      }} />
                    <label className='m-0 cursor-pointer' htmlFor="dataset2-altid">altid</label>
                  </div>

                  <div className="d-flex align-items-center gap-md">
                    <input
                      type="radio"
                      id="dataset2-resid"
                      className="k-radio k-radio-md"
                      disabled={radioButtonsData.dataset1.alternative || radioButtonsData.dataset1.numeric || radioButtonsData.dataset1.text}
                      checked={radioButtonsData.dataset2.respondent}
                      onClick={() => {
                        handleRadioButtonClick('dataset2', 'respondent')
                      }}
                    />
                    <label className={`m-0 cursor-pointer ${radioButtonsData.dataset1.alternative || radioButtonsData.dataset1.numeric || radioButtonsData.dataset1.text ? "disabled" : ""}`} htmlFor="dataset2-resid">respid</label>
                  </div>

                  <div className="d-flex align-items-center gap-md">
                    <input
                      type="radio"
                      id="dataset2-num"
                      className="k-radio k-radio-md"
                      disabled={radioButtonsData.dataset1.text}
                      checked={radioButtonsData.dataset2.numeric}
                      onClick={() => handleRadioButtonClick('dataset2', 'numeric')} />
                    <label className={`m-0 cursor-pointer ${radioButtonsData.dataset1.text ? "disabled" : ""}`} htmlFor="dataset2-num">Numeric question</label>
                  </div>

                  {radioButtonsData.dataset2.numeric &&
                    <BaseDropDownList
                      className="ml-3 w-100"
                      data={datasetOptions.optionsB.filter((option: DatasetOption) => option.type === 'numeric').map((option: DatasetOption) => option.address)}
                      value={workflowData.selectedTool.compareTypeB === 'UseNumericQuestion' && datasetOptions.optionsB.find((el: TODO) => el.value === workflowData.selectedTool.compareVectorIdB)?.address}
                      onChange={(e: { target: { value: string; }; }) => handleSetMatchBy('B', e.target.value)}
                    />
                  }

                  <div className="d-flex align-items-center gap-md">
                    <input
                      type="radio"
                      id="dataset2-text"
                      className="k-radio k-radio-md"
                      disabled={radioButtonsData.dataset1.numeric}
                      checked={radioButtonsData.dataset2.text}
                      onClick={() => handleRadioButtonClick('dataset2', 'text')} />
                    <label className={`m-0 cursor-pointer ${radioButtonsData.dataset1.numeric ? "disabled" : ""}`} htmlFor="dataset2-text">Text question</label>
                  </div>

                  {radioButtonsData.dataset2.text &&
                    <BaseDropDownList
                      className="ml-3 w-100"
                      data={datasetOptions.optionsB.filter((option: DatasetOption) => option.type === 'text').map((option: DatasetOption) => option.address)}
                      value={workflowData.selectedTool.compareTypeB === 'UseTextQuestion' && datasetOptions.optionsB.find((el: TODO) => el.value === workflowData.selectedTool.compareVectorIdB)?.address}
                      onChange={(e: { target: { value: string; }; }) => handleSetMatchBy('B', e.target.value)}
                    />
                  }
                </div>
              </div>
              {
                workflowData.appendMatchByError &&
                <ErrorMessage
                  type="alert"
                  errorMessage="Please select answers for Match by"
                  onHide={() => setMatchMethodByError(false)}
                />
              }
            </div>

            <div className="d-flex align-items-stretch flex-column content w-100 gap-md">
              <div className='d-flex flex-column'>
                <h6 className="m-0 stronger">Duplicates</h6>
                <p className="text-muted m-0">Remove duplicates based on the values from the input in the match option above. If any duplicate is found, the first occurence will be kept.</p>
              </div>

              <div className="d-flex flex-column p-4 rounded border gap-md">
                <div className="d-flex align-items-center gap-md">
                  <Switch checked={workflowData.selectedTool?.removeDuplicatesA} onChange={(e: TODO) =>
                    updateSelectedTool('removeDuplicatesA', e.value)
                  }
                    size="small"
                  />
                  <p className='strong m-0'>Dataset 1</p>
                </div>

                <div className="d-flex align-items-center gap-md">
                  <Switch checked={workflowData.selectedTool?.removeDuplicatesB} onChange={(e: TODO) =>
                    updateSelectedTool('removeDuplicatesB', e.value)
                  }
                    size="small"
                  />
                  <p className='strong m-0'>Dataset 2</p>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  )
}