import returnFileManagerDirectoryReference from "../returnFileManagerDirectoryReference/returnFileManagerDirectoryReference"
import { fetchPostJson as getFileManagerUploadToken } from "../../../../services/services";
import { updateIsLoading } from '../../../../store/reducers/fileManagerStateReducer/fileManagerStateReducer';

import { ContainerClient } from '@azure/storage-blob';

export default async (updateData, expandedState, state, token, e, type, dispatch) => {
  const directoryReference = returnFileManagerDirectoryReference(expandedState, state)
  const filesArr = Array.from(e.target.files).map((file) => file)
  const fileNames = Array.from(e.target.files).map(({ name }) => name)

  const currentUploadedFileNames = expandedState.data[0] ? expandedState.data[0].contents.map((file) => file.displayName) : [];

  if (currentUploadedFileNames.some(uploadedFileNames => fileNames.includes(uploadedFileNames))) {
    dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'A file with that name already exists' } });
    dispatch(updateIsLoading(false));
    return;
  }

  const body = { "directoryReference": directoryReference, "fileNames": fileNames }
  const uploadToken = await getFileManagerUploadToken(`files/upload/${type}/token`, token, body)
  if (uploadToken && !uploadToken.message && !uploadToken.error) {
    const containerClient = new ContainerClient(uploadToken.sasUri);
    const promises = [];
    filesArr.forEach((file, id) => {
      const blobName = uploadToken.blobs[id].name
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);
      const blobOptions = {
        blobHTTPHeaders: { blobContentType: uploadToken.blobs[id].contentType }
      };

      promises.push(blockBlobClient.uploadBrowserData(file, blobOptions));
    })
    const resp = await Promise.all(promises);
    if (resp) {
      updateData()
      dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The file has been uploaded successfully.' } })
    } else {
      console.log('ERROR: Azure container client error')
    }
  } else {
    console.log('ERROR (getFileManagerUploadToken):', uploadToken.message ? uploadToken.message : uploadToken.error)
  }
}