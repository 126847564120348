import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";

import usePrevious from "./usePrevious";
import type { RootState } from "../../../store/reducers/rootReducer";
import type { FileManagerItem } from "../../../interfaces/fileManagerInterfaces/fileManagerInterfaces";
import { updateBreadCrumbData, updateSelectCreatedFolder } from "../../../store/reducers/fileManagerStateReducer/fileManagerStateReducer";

export const useUpdateFileManagerBreadCrumb = (findDeep: any) => {
  const dispatch = useDispatch()
  const { state, expandedState, breadCrumbData, selectCreatedFolder } = useSelector((state: RootState) => state.fileManagerStateReducer);

  const data = expandedState.data[0]
  const prevData = usePrevious(data)
  const prevStateData = usePrevious(state.data)

  useEffect(() => {
    const newItem = { ...data }
    let updatedBreadCrumbData = [...breadCrumbData]

    if (selectCreatedFolder && state.data !== prevStateData) {
      dispatch(updateSelectCreatedFolder(''))
      updatedBreadCrumbData = []
      const newData = newItem.id.split('/').filter(item => item !== "")
      if (newData.length > 0) {
        const dataCopy = [...state.data]
        const matchedItems: FileManagerItem[] = []
        newData.forEach(item => {
          findDeep(dataCopy, item, matchedItems)
        })
        updatedBreadCrumbData = matchedItems
        dispatch(updateBreadCrumbData(updatedBreadCrumbData))
      }
    } else {
      if (data !== prevData) {
        if (updatedBreadCrumbData.length > 0) {
          const lastItem = updatedBreadCrumbData[updatedBreadCrumbData.length - 1]
          if (newItem?.id && newItem?.id !== lastItem?.id) {
            if (newItem.id.includes(lastItem.id)) {
              updatedBreadCrumbData.push(newItem)
              dispatch(updateBreadCrumbData(updatedBreadCrumbData))
            } else {
              updatedBreadCrumbData = []
              const newData = newItem.id.split('/').filter(item => item !== "")
              if (newData.length > 0) {
                const dataCopy = [...state.data]
                const matchedItems: FileManagerItem[] = []
                newData.forEach(item => {
                  findDeep(dataCopy, item, matchedItems)
                })
                updatedBreadCrumbData = matchedItems
                dispatch(updateBreadCrumbData(updatedBreadCrumbData))
              }
            }
          }
        } else {
          if (Object.keys(newItem).length > 0) {
            if (data?.id) {
              const newData = data.id.split('/').filter(item => item !== "")
              newData.pop()
              if (newData.length > 0) {
                const dataCopy = [...state.data]
                const matchedItems: FileManagerItem[] = []
                newData.forEach(item => {
                  findDeep(dataCopy, item, matchedItems)
                })
                updatedBreadCrumbData = matchedItems
                dispatch(updateBreadCrumbData(updatedBreadCrumbData))
              }
            }
            updatedBreadCrumbData.push(newItem)
            dispatch(updateBreadCrumbData(updatedBreadCrumbData))
          }
        }
      }
    }
  }, [data, dispatch, prevData, breadCrumbData, state.data, prevStateData, selectCreatedFolder, findDeep])
}