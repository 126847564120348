import { fetchGetRes, fetchPost } from "../../../../../services/services";
import { getImportStatus } from "../../../../../services/uploadImportDatasetServices/uploadImportDatasetServices"
import returnQuickImportBody from "../returnImportBody/returnImportBody";
import { ContainerClient } from '@azure/storage-blob';

export const checkImportStatus = (interval, quickImportRes, quickImportStatusData, updateData, dispatch, token) => {
  let updatedQuickImportStatusData = { ...quickImportStatusData }
  setTimeout(async () => {
    const importStatus = await getImportStatus(quickImportRes.StatusUri, token)
    if (!importStatus.errMsg) {
      if (importStatus.runtimeStatus === 'Completed') {
        updatedQuickImportStatusData = { ...updatedQuickImportStatusData, importErrorMessage: null, uploadErrorMessage: null }
        dispatch({ type: 'UPDATE_QUICK_IMPORT_STATUS', payload: updatedQuickImportStatusData })
        updateData('quick-import')
      } else if (importStatus.runtimeStatus === 'Failed') {
        updatedQuickImportStatusData = { ...updatedQuickImportStatusData, isInProgress: false, progressStatus: '', importErrorMessage: "Something went wrong, please try again." }
        dispatch({ type: 'UPDATE_QUICK_IMPORT_STATUS', payload: updatedQuickImportStatusData })
      } else {
        const newInterval = interval !== 30000 ? interval * 2 : 30000
        checkImportStatus(newInterval, quickImportRes, updatedQuickImportStatusData, updateData, dispatch, token)
      }
    } else {
      updatedQuickImportStatusData = { ...updatedQuickImportStatusData, isInProgress: false, progressStatus: '', importErrorMessage: "Something went wrong, please try again." }
      dispatch({ type: 'UPDATE_QUICK_IMPORT_STATUS', payload: updatedQuickImportStatusData })
    }
  }, interval)
}

export const onUploadHandler = async (dispatch, quickImportStatusData, formData, dataMetaFile, caseFile, token, updateData, projectDetails) => {
  dispatch({ type: 'HIDE_ADVANCED_IMPORT_NOTIFICATION' })
  let updatedQuickImportStatusData = { ...quickImportStatusData }
  updatedQuickImportStatusData = { ...updatedQuickImportStatusData, importErrorMessage: null, uploadErrorMessage: null, isProgressCompleted: false }
  dispatch({ type: 'UPDATE_QUICK_IMPORT_STATUS', payload: updatedQuickImportStatusData })

  updatedQuickImportStatusData = { ...updatedQuickImportStatusData, isInProgress: true, progressStatus: 'Uploading...' }
  dispatch({ type: 'UPDATE_QUICK_IMPORT_STATUS', payload: updatedQuickImportStatusData })

  const uploadData = await fetchGetRes('uploads/token', token)
  const uploadDataRes = await uploadData.json();

  if (uploadDataRes && !uploadDataRes.message && !uploadDataRes.error && dataMetaFile) {
    const filesArr = []
    filesArr.push(dataMetaFile.file)
    if (caseFile) {
      filesArr.push(caseFile.file)
    }

    const containerClient = new ContainerClient(uploadDataRes.sasUri);
    const promises = [];
    for (const file of filesArr) {
      const blobName = `${uploadDataRes.uploadPath}/${file.name}`;
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);
      promises.push(blockBlobClient.uploadBrowserData(file));
    }
    const resp = await Promise.all(promises);

    if (resp) {
      updatedQuickImportStatusData = { ...updatedQuickImportStatusData, progressStatus: 'Importing...' }
      dispatch({ type: 'UPDATE_QUICK_IMPORT_STATUS', payload: updatedQuickImportStatusData })

      const quickImportBody = returnQuickImportBody(dataMetaFile, caseFile, formData, uploadDataRes)
      const quickImport = await fetchPost(`projects/${projectDetails.id}/import`, token, quickImportBody)
      const quickImportRes = await quickImport.json();

      if (quickImport.status === 202 && quickImportRes.StatusUri) {
        checkImportStatus(1500, quickImportRes, updatedQuickImportStatusData, updateData, dispatch, token)
      } else if (quickImportRes.message || quickImportRes.error) {
        updatedQuickImportStatusData = { ...updatedQuickImportStatusData, isInProgress: false, progressStatus: '', importErrorMessage: quickImportRes.message ? quickImportRes.message : quickImportRes.error }
        dispatch({ type: 'UPDATE_QUICK_IMPORT_STATUS', payload: updatedQuickImportStatusData })
      } else {
        updatedQuickImportStatusData = { ...updatedQuickImportStatusData, isInProgress: false, progressStatus: '', importErrorMessage: "Something went wrong while importing the dataset." }
        dispatch({ type: 'UPDATE_QUICK_IMPORT_STATUS', payload: updatedQuickImportStatusData })
      }
    } else {
      updatedQuickImportStatusData = { ...updatedQuickImportStatusData, isInProgress: false, progressStatus: '', importErrorMessage: "Something went wrong while uploading the dataset." }
      dispatch({ type: 'UPDATE_QUICK_IMPORT_STATUS', payload: updatedQuickImportStatusData })
    }
  } else {
    if (uploadDataRes.message || uploadDataRes.error)
      updatedQuickImportStatusData = { ...updatedQuickImportStatusData, isInProgress: false, progressStatus: '', importErrorMessage: uploadDataRes.message ? uploadDataRes.message : uploadDataRes.error }
    dispatch({ type: 'UPDATE_QUICK_IMPORT_STATUS', payload: updatedQuickImportStatusData })
  }
}