import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import type { Tag } from "../../Admin/AdminTabContent/TagsTabContent/TagsTabContent";
import { fetchGetJsonOrEmptyArray as getTags } from "../../../services/services"

export const useFetchTags = (token: string, url?: string) => {
  const dispatch = useDispatch();
  const [tags, setTags] = useState<Tag[]>([])

  useEffect(() => {
    getTags(url ? url : "tags", token)
      .then((res: TODO) => {
        if (res && !res.error && !res.message) {
          setTags(res)
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `Tags: ${res.error ? res.error : res.message}` } })
        }
      })
  }, [dispatch, token, url])

  return tags;
}