import { AppendToolTabContent } from './AppendToolTabContent/AppendToolTabContent';
import { CleanAndDefineToolTabContent } from './CleanAndDefineToolTabContent/CleanAndDefineToolTabContent';
import { MatchToolTabContent } from './MatchToolTabContent/MatchToolTabContent';
import { CodingToolTabContent } from './CodingToolTabContent/CodingToolTabContent';


interface Props {
  toolType: string
  onSaveChanges: (tab?: TODO) => void
  setShowFilterBuilder: (value: boolean) => void
  preFilter: string | null
  handleEditTool: () => void
}

export const TabContent = ({ toolType, handleEditTool }: Props) => {
  switch (toolType) {
    case "CleanAndDefine":
      return <CleanAndDefineToolTabContent handleEditTool={handleEditTool} />
    case "Append":
      return <AppendToolTabContent handleEditTool={handleEditTool} />
    case "Match":
      return <MatchToolTabContent handleEditTool={handleEditTool} />
    case "Coding":
      return <CodingToolTabContent handleEditTool={handleEditTool} />
    default:
      return null
  }
}