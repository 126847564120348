import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Dialog } from '@progress/kendo-react-dialogs'

import type { ReconciliationData } from '../../../interfaces/reconciliationsInterfaces/gateKeeperInterfaces'
import { Icon } from '../../shared/Icon/Icon'
import { ReconcilePreview } from '../ReconciliationPreview/ReconciliationPreview'
import { fetchDeleteWithBody, fetchPostResOrJson } from '../../../services/services'

interface Props {
  reconciliationData: ReconciliationData | undefined,
  isLoading: boolean
  handleNewReconcile: Function
  onHide: Function
  setReconciliationData: Function
  token: string
}

export const GKPreviewReconciliationModal = ({ reconciliationData, setReconciliationData, isLoading, handleNewReconcile, onHide, token }: Props) => {
  const [searchInput, setSearchInput] = useState<string>("")
  const dispatch = useDispatch()

  const submitReconcile = () => {
    fetchPostResOrJson(`au/r/${reconciliationData?.id}/reconcile`, token, null)
      .then(async (res: TODO) => {
        const body = await res.json()
        setReconciliationData(body)
        dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Reconciliation has been submitted' } })
      })
  }

  const discardReconcile = () => {
    fetchDeleteWithBody(`au/r/${reconciliationData?.id}/discard`, token, null)
      .then(async (res: TODO) => {
        try {
          const body = await res.json()
          setReconciliationData(body)
        } catch (err) {
          setReconciliationData(undefined)
        }
      })
  }

  return (
    <Dialog width={"80%"} height={"80%"} className='reconcile-modal reconcile-preview' contentStyle={{ padding: 0, fontSize: 14 }} title={"Reconciliation preview"} onClose={() => onHide("Reconciliation Preview")}>
      <div className='board-container reconcile-container d-flex justify-content-center flex-column w-100 h-100'>
        <div className='d-flex justify-content-between p-4'>
          <div className="input-group w-100 h-32 mr-3">
            <input
              type="text"
              value={searchInput}
              className="form-control search-input"
              placeholder={"Search"}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
          <button style={{ whiteSpace: "nowrap" }} className="btn btn-primary btn-icon icon-l flex-shrink-0" type="button" id="newReconciliation" onClick={() => handleNewReconcile()}>
            <Icon type="add" />
            New reconciliation
          </button>
        </div>
        <div className='board-content-container reconcile-content d-flex justify-content-center align-items-center h-100 overflow-y mx-4 mb-4'>
          <div className={"table target-table mb-0 pb-0 h-100"} >
            {reconciliationData?.hasOwnProperty('reconciliations') ?
              <div className="accordion w-100" id="accordionExample">
                <div className="accordion-item">
                  <table className="mb-0 w-100 ">
                    <thead >
                      <tr>
                        <th className='text-muted font-weight-normal'>Name</th>
                        <th className='text-muted font-weight-normal'>Market</th>
                        <th className='text-muted font-weight-normal' />
                        <th className='text-muted font-weight-normal' />
                        <th className='text-muted font-weight-normal' />
                        <th className='text-muted font-weight-normal' />
                      </tr>
                    </thead>
                    {reconciliationData.reconciliations.filter(item => item.name ? item.name.toLowerCase().includes(searchInput.toLowerCase()) : item).map((item: TODO, index: number) => (
                      <React.Fragment key={index}>
                        <tbody>
                          <tr className='cursor-pointer' id={`heading${index}`} data-toggle="collapse" data-target={`#main${index}`} aria-expanded="false" aria-controls="main">
                            <td className='text-left text-primary border-secondary border-top-0 bg-white p-3 stronger' width={"25%"} >{item.name ? item.name : "-"}</td>
                            <td className='text-left text-primary border-secondary border-top-0 bg-white p-3' width={"20%"}>
                              <p className='recon-market'>{item.marketName ? item.marketName : "Not Found"}</p>
                            </td>
                            <td className='text-left text-primary border-secondary border-top-0 bg-white p-3' width={"20%"} />
                            <td className='text-left text-primary border-secondary border-top-0 bg-white p-3'>
                              <span className="alert py-1 px-2 m-0 mr-4 span-default small" >{item.ignoredCount} terminates</span>
                            </td>
                            <td className='text-left text-primary border-secondary border-top-0 bg-white p-3'>
                              <span className="alert py-1 px-2 m-0 span-success small">{item.successCount} accepted completes</span>
                            </td>
                            <td className='text-left text-primary border-secondary border-top-0 bg-white p-3'>
                              <Icon type="chevron-mini-down-alt" className="submenu" />
                            </td>
                          </tr>
                        </tbody>
                        <tbody
                          id={`main${index}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`heading${index}`}
                          data-parent="#accordionExample">
                          <tr style={{ width: "100%" }} className='w-100'>
                            <th className='text-muted font-weight-normal'>WalrID</th>
                            <th className='text-muted font-weight-normal'>Supplier RID</th>
                            <th className='text-muted font-weight-normal'>Supplier</th>
                            <th className='text-muted font-weight-normal'>Gatekepeer satus</th>
                            <th className='text-muted font-weight-normal'>Reconciled status</th>
                            <th className='text-muted font-weight-normal' />
                          </tr>
                          {item.respondents.map((respondents: TODO, index: number) => (
                            <tr key={index} className='medium '>
                              <td className='text-left text-primary border-top border-secondary p-3'>{respondents.id}</td>
                              <td className='text-left text-primary border-top border-secondary p-3'>{respondents.rid ? respondents.rid : "-"}
                              </td>
                              <td className='text-left text-primary border-top border-secondary p-3'>{respondents.platform ? respondents.platform : "-"}</td>
                              <td className='text-left text-primary border-top border-secondary p-3'>{respondents.status ? respondents.status : "Not Found"}</td>
                              <td className='text-left text-primary border-top border-secondary p-3'>{respondents.reconciliationStatus ? respondents.reconciliationStatus : "Unable to reconcile"}</td>
                              <td className='text-left text-primary border-top border-secondary p-3' />
                            </tr>
                          ))}
                        </tbody>
                      </React.Fragment>
                    ))}
                  </table>
                </div>
              </div>
              :
              <>
                {
                  isLoading ?
                    <div className='h-75 d-flex align-items-center justify-content-center'><span className="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true" /></div>
                    :
                    <div className='h-75 text-muted d-flex align-items-center justify-content-center'>No reconciled IDs found</div>
                }
              </>
            }
          </div>
        </div>
        {reconciliationData?.status === "Preview" &&
          <ReconcilePreview
            reconciliationData={reconciliationData}
            discardReconcile={discardReconcile}
            submitReconcile={submitReconcile}
          />}
      </div>
    </Dialog>
  )
}