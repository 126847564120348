import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import type { RootState } from '../../../../store/reducers/rootReducer';
import { InProgressOverlay } from '../../InProgressOverlay/InProgressOverlay';
import NotificationContainer from '../../NotificationContainer/NotificationContainer';
import { UserImpersonationInfo } from '../../UserImpersonationInfo/UserImpersonationInfo';
import { rsAppUrl } from '../../../../services/services';

interface Props {
  user: TODO;
  Component: TODO;
  parentProps: TODO;
  extendedUserData: TODO;
  isTrialExpired: null | boolean;
}

export const AuthenticatedRouteContent: React.FC<Props> = ({ user, extendedUserData, isTrialExpired, Component, parentProps }) => {
  const location = useLocation();
  const { token }: { token: string | null } = useSelector((state: RootState) => state.tokenStateReducer);
  const selectedCustomer = localStorage.getItem('selectedCustomerId')
  const customerIdArr = user ? user[`${rsAppUrl}/customerid`].split(',') : []

  const NewComponent = withAuthenticationRequired(Component, {
    onRedirecting: () => (
      <div className='vh-100 w-100'>
        <InProgressOverlay
          type="fullscreen"
          theme="primary"
        />
      </div>
    )
  });

  if ([customerIdArr].length > 1 && !selectedCustomer) {
    return (<Redirect to={{ pathname: "/customers" }} />)
  }

  if (isTrialExpired === false && (extendedUserData.plan || extendedUserData.enterpriseSettings)) {
    const updatedUser = { ...user, plan: extendedUserData.plan, role: extendedUserData.role, enterpriseSettings: extendedUserData.enterpriseSettings, canCreateSubAccounts: extendedUserData.canCreateSubAccounts, isSubAccount: extendedUserData.isSubAccount, customer: extendedUserData.customer }
    if (token) {
      if (location.pathname === '/admin' && extendedUserData.role === 'regular') {
        return (<Redirect to="/" />)
      }
      return (
        <React.Fragment>
          <UserImpersonationInfo extendedUserData={extendedUserData} />
          <NewComponent {...parentProps} token={token} user={updatedUser} />
          <NotificationContainer />
        </React.Fragment>
      )
    }
  }

  if (isTrialExpired === true) {
    return (<Redirect to="/trial-expired" />)
  }
}