import { FullHeightSpinner } from '../FullHeightSpinner/FullHeightSpinner';

export const InProgressOverlay = ({ type, theme, message, className }: { type: string, theme: string, message?: string, className?: string }) => {
  if (type === 'overlay') {
    return (
      <div className={`in-progress-overlay import-status text-${theme}`}>
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <p className="mt-3">{message}</p>
      </div>
    )
  }
  if (type === 'button') {
    return (
      <div className={`spinner-border spinner-border-sm mr text-${theme} ${className ? className : ''}`} role="status">
        <span className="sr-only">Loading...</span>
      </div>
    )
  }
  if (type === 'fullscreen') {
    return (
      <FullHeightSpinner />
    )
  }
  return null
}