import React, { type ReactElement, useEffect, useState } from 'react';
import { process } from '@progress/kendo-data-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import { DropDownButton, type DropDownButtonItemClickEvent } from '@progress/kendo-react-buttons';
import { Grid, GridColumn as Column, GridNoRecords, type GridDataStateChangeEvent } from '@progress/kendo-react-grid';

import itemActions from './menuItemActions.json';
import usePrevious from '../../../shared/customHooks/usePrevious';
import { GridNoData } from '../../../shared/GridNoData/GridNoData';
import type { RootState } from '../../../../store/reducers/rootReducer';
import { InProgressOverlay } from '../../../shared/InProgressOverlay/InProgressOverlay';
import EditOutcomesModal from '../../../shared/Modals/EditOutcomesModal/EditOutcomesModal';
import { ExportNonCompletesModal } from './ExportNonCompletesModal/ExportNonCompletesModal';
import { ColumnValue } from '../../../shared/helpers/project/ReturnTableColumns/ColumnValue';
import { returnTableIcon } from '../../../shared/helpers/project/returnTableIcon/returnTableIcon';
import setStatus from '../../../shared/helpers/project/returnProjectStatusData/returnProjectStatusData';
import { sortProjectDocuments } from '../../../shared/helpers/sortProjectDocuments/sortProjectDocuments';
import type { ShareDetails } from '../../../../interfaces/projectDetailsInterfaces/shareDetailsInterfaces';
import { returnColumnDataInfo } from '../../../shared/helpers/project/returnColumnDataInfo/returnColumnDataInfo';
import fileManagerDownloadHandler from '../../../shared/helpers/fileManagerDownloadHandler/fileManagerDownloadHandler';
import { ProjectDocumentsFilteringAndSorting } from './ProjectDocumentsFilteringAndSorting/ProjectDocumentsFilteringAndSorting';
import type { ProjectDetails as ProjectDetailsInterface, ProjectDocument, ProjectTag } from '../../../../interfaces/projectDetailsInterfaces/projectDetailsInterfaces';
import { fetchGetBlob, fetchPost, fetchPostResOrJson, fetchPutResOrJson } from '../../../../services/services';

type Props = {
  projectId: string
  shareDetails: ShareDetails
  projectDocuments: ProjectDocument[] | null
  showAddSurveyFromProjectModal: (survey: TODO) => void
  setProjectDetails: (data: ProjectDetailsInterface) => void
  showConfirmActionModal: (projectId: string, id: string, name: string, type: string) => void
  showEditDocumentModal: (projectId: string, id: string, name: string, type: string, description: string, tags: ProjectTag[], _etag: string, dataCollectionVersion: number) => void
  showExportProjectDocument: (projectId: string, surveyId: string, type: string) => void
}

export const ProjectDocumentsTable = ({ shareDetails, projectDocuments, showConfirmActionModal, showEditDocumentModal, showExportProjectDocument, showAddSurveyFromProjectModal, projectId, setProjectDetails }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params: { name: string } = useParams();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);

  const [showImportFileModal, setShowImportFileModal] = useState(false);
  const [uploadSurveyId, setUploadSurveyId] = useState("");
  const [datasetsState, setDatasetsState] = useState<TODO>(null); // state for project documents
  const [originalDatasetsState, setOriginalDatasetsState] = useState<TODO>([]);
  const [sortType, setSortType] = useState({ sortBy: 'date', direction: 'desc' });
  const [openNonCompletesModal, setOpenNonCompletesModal] = useState(false);
  const [itemClicked, setItemClicked] = useState<TODO>(null);
  const [nonCompletesBody, setNonCompletesBody] = useState({
    datasetName: "",
    includeStatus: {
      complete: true,
      screened: false,
      quotaFull: false,
      qualityFail: false,
      dropout: false,
      archived: false,
    }
  });

  const prevProjectDocuments = usePrevious(projectDocuments);

  const createDataState = (dataState: TODO, data: TODO) => {
    return {
      ...data,
      result: process(data.projects.slice(0), dataState),
      dataState: dataState
    };
  }

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDatasetsState(createDataState(event.dataState, datasetsState));
  }

  useEffect(() => {
    if (projectDocuments !== prevProjectDocuments) {
      const updatedSortProjectDocuments = sortProjectDocuments(projectDocuments, sortType.direction, sortType.sortBy);
      setDatasetsState({
        result: process(updatedSortProjectDocuments.projectsDocuments.documents.slice(0), { take: 20, skip: 0 }),
        dataState: { take: 20, skip: 0 },
        projects: updatedSortProjectDocuments.projectsDocuments.documents
      })
      setOriginalDatasetsState(projectDocuments);
    }
  }, [projectDocuments, prevProjectDocuments, sortType])

  const selectAction = (e: DropDownButtonItemClickEvent, item: TODO) => {
    if (e.item.text === 'Delete' || e.item.text === 'Edit') {
      const projectId = params.name;
      const id = item.id;
      if (e.item.text === 'Delete') {
        showConfirmActionModal(projectId, id, item.name, item.type);
      } else {
        showEditDocumentModal(projectId, id, item.name, item.type, item.description, item.tags, item._etag, item.dataCollectionVersion);
      }
    } else if (e.item.text === 'Copy') {
      showAddSurveyFromProjectModal(item);
    } else if (e.item.text === 'Analyze') {
      const location = history.location.pathname;
      let updatedPathname = `${location}/${item.id}/analyze/datasets`;
      dispatch({ type: 'UPDATE_DOCUMENT_BREADCRUMB', payload: { id: item.id, name: item.name } });
      if (item.type === 'surveys') {
        updatedPathname = `${location}/${item.id}/analyze/surveys`;
      }
      history.push({ pathname: updatedPathname });
    } else if (e.item.text === 'Open') {
      const returnEndpoint = () => {
        switch (item.type) {
          case "surveys":
            return "survey-builder"
          case "workflow":
            return "workflows"
          case "audiences":
            return "audiences"
          case "pinboards":
            return "pinboards"
          default:
            return "panel"
        }
      }
      const location = history.location.pathname
      dispatch({ type: 'UPDATE_DOCUMENT_BREADCRUMB', payload: { id: item.id, name: item.name } })

      if (item.type === "audiences") {                                            // Solved merged conflict, changed inline if-then
        history.push({ pathname: `${location}/${returnEndpoint()}/${item.id}` })  // Different history logic for audiences, cause of backend limitations
      } else {
        if (item.type === "pinboards") {
          dispatch({ type: "RESET_PINBOARD_DATA" })
        }
        history.push({ pathname: `${location}/${item.id}/${returnEndpoint()}` })
      }
    } else if (e.item.text === 'Download') {
      if (item.type === 'files') {
        fetchGetBlob(`projects/${projectId}/files/${item.id}/download`, token)
          .then((res: TODO) => {
            if (res) {
              fileManagerDownloadHandler(res, { displayName: item.name })
            }
          })
      }
    } else if (e.item.text === 'Export') {
      showExportProjectDocument(projectId, item.id, item.type)
    } else if (e.item.text === 'Edit data') {
      setItemClicked(item)
      setUploadSurveyId(item.id)
      setShowImportFileModal(true)
    } else if (e.item.text === "Status report") {
      fetchPutResOrJson(`projects/${projectId}/surveys/${item.id}/respondents/status-report`, token)
        .then((res: TODO) => {
          if (res?.error) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : "Something went wrong" } })
          } else {
            dispatch({ type: 'SHOW_SUCCESS_NOTIFICATION', payload: { msg: res.error ? res.error : "Status reported downloaded" } })
          }
        })
    } else if (e.item.text === "Clone") {
      fetchPostResOrJson(`au/a/projects/${projectId}/audiences/from-audience`, token, { audienceId: item.id, projectId: projectId })
        .then(async (res: TODO) => {
          const response = await res.json();
          // @ts-ignore
          setProjectDetails((prev: ProjectDetailsInterface) => ({ ...prev, documents: [...prev.documents, { ...response, type: "audiences" }] }));
        })
    } else if (e.item.text === "Create dataset") {
      setUploadSurveyId(item.id);
      setOpenNonCompletesModal(true);
    }
  }

  const setDatasetBreadCrumb = (item: { type: string, id: string, name: string }) => {
    if (item.type.includes('datasets') || item.type === 'surveys' || item.type === 'samples' || item.type === 'workflows' || item.type === 'pinboards') {
      dispatch({ type: 'UPDATE_DOCUMENT_BREADCRUMB', payload: { id: item.id, name: item.name } })
    }
  }

  const bulletValueAxis = {
    min: 0,
    max: 100,
    plotBands: [{
      from: 0, to: 15, color: '#ef4c52', opacity: 0.15
    }, {
      from: 15, to: 50, color: '#f1e42f', opacity: 0.3
    }, {
      from: 50, to: 100, color: '#6bb756', opacity: 0.15
    }]
  };

  const analysisTypes = shareDetails.sharedWithDetails?.shareFunctionalities?.analysisTypes
  if (analysisTypes) {
    // @ts-ignore
    const enabledTypes = Object.keys(analysisTypes).filter(type => analysisTypes[type] === true)
    if (enabledTypes.length === 0) {
      const datasetsActions = itemActions.document.datasets
      const analyzeItem = datasetsActions.find(item => item.text === 'Analyze')
      if (analyzeItem) {
        const analyzeItemIndex = datasetsActions.indexOf(analyzeItem)
        datasetsActions.splice(analyzeItemIndex, 1)
      }
    }
  }

  if (!datasetsState) {
    return (
      <InProgressOverlay
        type="fullscreen"
        theme="primary" />
    )
  }
  const dataElements = datasetsState?.projects;
  const renderData = datasetsState && datasetsState;
  let columns: TODO = [];
  if (dataElements && dataElements.length > 0) {
    const dataPropertyKeys: TODO = [];
    // biome-ignore lint/complexity/noForEach: <explanation>
    dataElements.forEach((el: TODO) => {
      // biome-ignore lint/complexity/noForEach: <explanation>
      Object.keys(el).forEach(el => dataPropertyKeys.push(el))
    })
    columns = returnColumnDataInfo(dataPropertyKeys)
  }

  // Needed to decide index for each row
  let rowCounter = 0;
  const rowRender = (tr: ReactElement<HTMLTableRowElement>) => {
    rowCounter++;
    return tr;
  }

  const handleExportNonCompletesCreate = () => {
    let hasErrors = false
    fetchPost(`projects/${projectId}/surveys/${uploadSurveyId}/export-non-completes`, token, nonCompletesBody)
      .then((res: TODO) => {
        if (res.status === 202) {
          dispatch({ type: 'UPDATE_FETCH_PROJECTS', payload: true })
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Succesfully created dataset' } })
          setOpenNonCompletesModal(false)
        } else if (res.status === 400) {
          hasErrors = true
          return res.json()
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "Something went wrong, please try again" } });
        }
      })
      .then((res: TODO) => {
        if (hasErrors) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error } });
        }
      })
  }

  const itemRender = ({ item }: { item: { icon?: string, text: string } }) => (
    <div>
      {item.icon && <i className={`${item.icon} mr-3`} />}
      {item.text}
    </div>
  );

  const handleHideModal = () => {
    setOpenNonCompletesModal(false)
    setNonCompletesBody({
      datasetName: "",
      includeStatus: {
        complete: true,
        screened: false,
        quotaFull: false,
        qualityFail: false,
        dropout: false,
        archived: false,
      }
    })
  }

  return (
    <React.Fragment>
      {
        showImportFileModal &&
        <EditOutcomesModal
          projectId={projectId}
          surveyId={uploadSurveyId}
          onHide={() => setShowImportFileModal(false)}
          type={itemClicked?.type}
          datasetId={itemClicked?.id} />
      }
      {
        openNonCompletesModal &&
        <ExportNonCompletesModal
          onHide={handleHideModal}
          nonCompletesBody={nonCompletesBody}
          setNonCompletesBody={setNonCompletesBody}
          handleExportNonCompletesCreate={handleExportNonCompletesCreate}
        />
      }

      <ProjectDocumentsFilteringAndSorting
        originalDatasetsState={originalDatasetsState}
        datasetsState={datasetsState}
        setDatasetsState={setDatasetsState}
        sortType={sortType}
        setSortType={setSortType}
      />

      <Grid
        className="h-100 project-grid mt-3"
        data={renderData.result}
        skip={datasetsState.dataState.skip}
        take={datasetsState.dataState.take}
        total={datasetsState.projects.length}
        {...renderData.dataState}
        onDataStateChange={dataStateChange}
        pageable={true}
        pageSize={3}
        rowRender={rowRender}>
        <GridNoRecords>
          <GridNoData secondaryMessage="Use the actions above to import datasets, add files and create surveys" />
        </GridNoRecords>
        {
          columns.map((el: TODO) => (
            <Column key={el.data} cell={props =>
              <ColumnValue
                {...props}
                token={token}
                data={el.data}
                history={history}
                setStatus={setStatus}
                returnIcon={returnTableIcon}
                dataPropertyKeys={el.keys}
                shareDetails={shareDetails}
                bulletValueAxis={bulletValueAxis}
                setProjectBreadCrumb={() => { }}
                setDatasetBreadCrumb={setDatasetBreadCrumb}
                isLastEntry={renderData.result.data.length === rowCounter} />
            } />
          ))
        }
        <Column cell={props => {
          const itemType = props.dataItem.type === 'surveys' && props.dataItem.publishedVersions && props.dataItem.publishedVersions.length > 0 ? 'publishedSurveys' : null
          return (
            <td style={{ width: '50px' }}>
              <div className="d-flex justify-content-end">
                <DropDownButton
                  iconClass="fa fas fa-ellipsis-h"
                  buttonClass="btn btn-light px-1 shadow-none border-0"
                  itemRender={itemRender} // @ts-ignore
                  items={itemActions.document[itemType ? itemType : props.dataItem.type]}
                  onItemClick={(e) => selectAction(e, datasetsState ?
                    { id: props.dataItem.id, name: props.dataItem.name, type: props.dataItem.type, description: props.dataItem.description, tags: props.dataItem.tags, _etag: props.dataItem._etag, dataCollectionVersion: props.dataItem.dataCollectionVersion }
                    : { id: props.dataItem.id, name: props.dataItem.name }
                  )}
                />
              </div>
            </td>
          )
        }}
        />
      </Grid>
    </React.Fragment >
  )
}