import React from 'react';

import { ThemeSettingsTabContent } from './ThemeSettingsTabContent/ThemeSettingsTabContent';
import { SurveySettingsTabContent } from './SurveySettingsTabContent/SurveySettingsTabContent';
import { AdvancedSettingsTabContent } from './AdvancedSettingsTabContent/AdvancedSettingsTabContent';

export default ({ settings, settingsValues, onChangeSettingsValues, onShowFileManager, selectedTab, languages, updateDefaultLanguage, setCustomSettingValues }) => {
  return (
    <React.Fragment>
      <div style={{ backgroundColor: '#f3f4f4' }} className="card w-100 h-100">
        <div className='card-body d-flex flex-column h-100 overflow-auto m-4 p-0 bg-white answer-layout'>
          <div className="p-5 border-bottom">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <h6 className='mb-0 stronger'>{selectedTab.title}</h6>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column overflow-auto p-5 w-100 h-100">
            {
              selectedTab.title === 'Survey settings' ?
                <SurveySettingsTabContent settings={settings} settingsValues={settingsValues} onChangeSettingsValues={onChangeSettingsValues} languages={languages} updateDefaultLanguage={updateDefaultLanguage} />
                :
                selectedTab.title === 'Theme settings' ?
                  <ThemeSettingsTabContent settings={settings} settingsValues={settingsValues} onChangeSettingsValues={onChangeSettingsValues} onShowFileManager={onShowFileManager} setCustomSettingValues={setCustomSettingValues} />
                  :
                  <AdvancedSettingsTabContent settings={settings} settingsValues={settingsValues} onChangeSettingsValues={onChangeSettingsValues} onShowFileManager={onShowFileManager} setCustomSettingValues={setCustomSettingValues} />
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
