export function onCheckQuestion(id, source, itemId, theState, checkedNum) {
  const updatedState = { ...theState.state };
  const currentCheckedQuestion = { 'source': source, id: itemId };
  updatedState[source][id].selected = !theState.state[source][id].selected;
  const updatedCheckedNums = [...checkedNum];
  let matchedElement = null;
  checkedNum.forEach(el => {
    matchedElement = checkedNum.find(el => el.id === currentCheckedQuestion.id)
  })

  if (matchedElement) {
    updatedCheckedNums.splice(checkedNum.indexOf(matchedElement), 1);
  } else {
    updatedCheckedNums.push(currentCheckedQuestion)
  }

  updatedState.checkedNum = updatedCheckedNums

  if (updatedState[source][id].selected === false) {
    if (updatedState.firstColumn.find(el => el.selected)) {
      updatedState.selectedQuestionSource = 'firstColumn'
    } else if (updatedState.scndColumn?.find(el => el.selected)) {
      updatedState.selectedQuestionSource = 'scndColumn'
    } else {
      updatedState.selectedQuestionSource = 'firstColumn'
    }
  } else {
    updatedState.selectedQuestionSource = currentCheckedQuestion.source
  }

  return {
    ...theState,
    state: updatedState
  }
}