export const Search = ({ dispatch, oldData, analysisScriptingSearch }: { dispatch: TODO, oldData: TODO, analysisScriptingSearch?: TODO }) => {
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (analysisScriptingSearch) {
      dispatch({ type: 'UPDATE_ANALYSIS_SCRIPTING_SEARCH_VALUE', payload: e.target.value })
    } else {
      dispatch({ type: 'SEARCH', payload: { event: e, oldData: oldData } })
    }
  }

  return (
    <div className="input-group p-2">
      <input
        type="text"
        placeholder="Search"
        className="form-control question-search"
        onChange={onChangeHandler} />
    </div>
  );
}