import type { IconsType } from "../../../../../../../interfaces/iconInterfaces/iconInterfaces";


export const ReportActionsName = ["Edit", "Copy", "Delete"] as const;
export type ReportActionsNameType = (typeof ReportActionsName)[number];

export const ReportsActions = ["edit", "copy", "delete"] as const;
export type ReportsActionsType = (typeof ReportsActions)[number];

export type ReportActionItem = {
  name: ReportActionsNameType;
  icon?: IconsType;
  id: ReportsActionsType;
  iconClass?: string;
  disabled?: boolean
}

export const returnActionItems = () => {
  const actionItems: ReportActionItem[] = [
    {
      name: "Edit",
      icon: "edit",
      id: "edit"
    },
    // {
    //   name: "Copy",
    //   icon: "copy",
    //   id: "copy"
    // },
    {
      name: "Delete",
      icon: "delete",
      id: "delete"
    }
  ];

  return actionItems
} 