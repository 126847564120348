import { Input } from '@progress/kendo-react-inputs';

import type { EmailProvider, EmailProviderFormData } from '../../../../../../../interfaces/emailProviderInterfaces/emailProviderInterfaces';
import { EmailProvidersSMTPSettingsResetPassword } from './EmailProvidersSMTPSettingsResetPassword/EmailProvidersSMTPSettingsResetPassword';

interface Props {
  emailProvider?: EmailProvider | null
  formData: EmailProviderFormData
  onChangeHandler: (e: TODO, name: string) => void
}

export const EmailProvidersSMTPSettingsPasswordField = ({ emailProvider, formData, onChangeHandler }: Props) => {
  if (emailProvider) {
    return (
      <EmailProvidersSMTPSettingsResetPassword
        formData={formData}
        onChangeHandler={onChangeHandler} />
    )
  }
  return (
    <div className="mb-2">
      <Input
        required
        type="password"
        label={'SMTP Password'}
        value={formData.password}
        onChange={(e: TODO) => onChangeHandler(e, 'password')} />
    </div>
  )
}