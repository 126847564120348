import FileLibraryIconWord from '../../../../assets/img/FileLibrary/file-library-word.svg';
import FileLibraryIconExcel from '../../../../assets/img/FileLibrary/file-library-excel.svg';
import FileLibraryIconPowerpoint from '../../../../assets/img/FileLibrary/file-library-powerpoint.svg';
import FileLibraryIconPDF from '../../../../assets/img/FileLibrary/file-library-pdf.svg';
import FileLibraryIconArchive from '../../../../assets/img/FileLibrary/file-library-archive.svg';
import FileLibraryIconImage from '../../../../assets/img/FileLibrary/file-library-image.svg';
import FileLibraryIconVideo from '../../../../assets/img/FileLibrary/file-library-video.svg';
import FileLibraryIconAudio from '../../../../assets/img/FileLibrary/file-library-audio.svg';
import FileLibraryIconFont from '../../../../assets/img/FileLibrary/file-library-font.svg';
import FileLibraryIconSVG from '../../../../assets/img/FileLibrary/file-library-svg.svg';
import FileLibraryIconJS from '../../../../assets/img/FileLibrary/file-library-js.svg';
import FileLibraryIconCSS from '../../../../assets/img/FileLibrary/file-library-css.svg';
import FileLibraryIconDefault from '../../../../assets/img/FileLibrary/file-library-default.svg';

export const returnFileIcon = (item: { extension: string }) => {
  let fileIcon = { alt: '', icon: '' }

  switch (item.extension) {
    case '.doc':
    case '.docx':
      fileIcon = { alt: 'Word', icon: FileLibraryIconWord }
      break;
    case '.xls':
    case '.xlsx':
    case '.xltx':
    case '.csv':
      fileIcon = { alt: 'Excel', icon: FileLibraryIconExcel }
      break;
    case '.ppt':
    case '.pptx':
      fileIcon = { alt: 'Powerpoint', icon: FileLibraryIconPowerpoint }
      break;
    case '.pdf':
      fileIcon = { alt: 'PDF', icon: FileLibraryIconPDF }
      break;
    case '.zip':
    case '.rar':
    case '.tar':
    case '.7z':
      fileIcon = { alt: 'Archive', icon: FileLibraryIconArchive }
      break;
    case '.jpg':
    case '.jpeg':
    case '.png':
    case '.gif':
      fileIcon = { alt: 'Image', icon: FileLibraryIconImage }
      break;
    case '.mp4':
    case '.mov':
    case '.wmv':
    case '.flv':
    case '.avi':
    case '.webm':
    case '.mkv':
      fileIcon = { alt: 'Video', icon: FileLibraryIconVideo }
      break;
    case '.mp3':
    case '.wav':
    case '.flac':
    case '.m4a':
    case '.aac':
      fileIcon = { alt: 'Audio', icon: FileLibraryIconAudio }
      break;
    case '.ttf':
    case '.otf':
      fileIcon = { alt: 'Font', icon: FileLibraryIconFont }
      break;
    case '.svg':
      fileIcon = { alt: 'SVG', icon: FileLibraryIconSVG }
      break;
    case '.js':
      fileIcon = { alt: 'JavaScript', icon: FileLibraryIconJS }
      break;
    case '.css':
      fileIcon = { alt: 'CSS', icon: FileLibraryIconCSS }
      break;
    default:
      fileIcon = { alt: 'File', icon: FileLibraryIconDefault }
  }

  return fileIcon
}