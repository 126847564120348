import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import type { RootState } from '../../../store/reducers/rootReducer';
import type { ProjectDetailsObject } from '../../../interfaces/projectDetailsInterfaces/projectDetailsInterfaces';

type Props = {
  handleClose: () => void;
  handleSubmit?: <T>() => Promise<T>;
  projectName: string;
  type: "element" | "project" | "document" | "theme";
  deleteItem?: () => void;
  errorMessage: string | null;
  setError: (message: string) => void;
  deleteButtonDisabled?: boolean;
  isDeleting?: boolean;
}

export default function ConfirmActionModal({ handleClose, handleSubmit, projectName, type, deleteItem, errorMessage, setError, deleteButtonDisabled, isDeleting }: Props) {
  const [name, setName] = useState<string | undefined>("");
  const { newProjects } = useSelector((state: RootState) => state.projectsReducer);
  const { selectedElement } = useSelector((theState: RootState) => (theState.surveyInitialDataReducer));
  const [isDeletingItem, setIsDeletingItem] = useState(false);
  const dispatch = useDispatch()

  const isQuestionWithMoreThanOneSubQuestion = selectedElement?.type === 'ques' && selectedElement?.elements?.filter((el: { type: string }) => el.type === 'subq')?.length > 1;

  const onSubmitHandler = () => {
    setIsDeletingItem(true);
    handleSubmit?.().then((res: TODO) => {
      if (res.status === 204 || res.status === 202) {
        dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The project has been deleted successfully' } });

        if (type === "project") {
          const filteredProjects = newProjects.filter((item: ProjectDetailsObject) => item.name !== projectName);
          dispatch({ type: "UPDATE_PROJECTS_DATA", payload: filteredProjects });
        }

        handleClose()
        setIsDeletingItem(false);
      } else if (res && (res.message || res.error)) {
        setError(res.message ? res.message : res.error)
      }
    });
  }

  return (
    <Dialog
      onClose={handleClose}
      title={type !== 'element' ? `Delete '${projectName}'` : `Delete ${projectName}`}
      className="d-flex flex-column w-100"
      width={type !== 'project' ? 400 : undefined}>
      <div className="p-3">
        {
          type === 'project' ?
            <p>
              Deleting a project will remove everything related to the project, including interview data. <br />
              Confirm the deletion of the project by typing the project <strong>name</strong> in the input field
            </p>
            : type === 'document' ?
              <div className="h-100 d-flex flex-column">
                <p> Confirm the deletion by typing the document <strong>name</strong> in the input field</p>
              </div>
              : type === 'element' ?
                <div className="h-100 d-flex flex-column">
                  {
                    isQuestionWithMoreThanOneSubQuestion ?
                      <p className="m-2">
                        The container has more than one question. <br />
                        Deleting the container would permanently delete all questions within.
                        Are you sure you want to proceed?
                      </p>
                      :
                      <p className="m-2">Are you sure you want to delete this <strong>{projectName}</strong>?</p>
                  }
                </div>
                : type === 'theme' ?
                  <React.Fragment>
                    <div className="alert alert-warning" role="alert">
                      <strong className="mb-1">Deleting a theme can cause other surveys that use this theme to change appearance.</strong><br />
                      Are you sure you want to delete <strong>{projectName}</strong>?
                    </div>
                    <p className='m-0'>Confirm by typing the theme <strong>name</strong> below:</p>
                  </React.Fragment>
                  :
                  <div className="h-100 d-flex flex-column">
                    <p className="m-2">Are you sure you want to delete <strong>{projectName}</strong>?</p>
                  </div>
        }
        {
          type === 'project' || type === 'document' || type === 'theme' ?
            <Input
              required
              onChange={(e) => setName(String(e.target.value))}
              value={name}
              placeholder={`Enter ${type === 'project' ? 'project ' : ''}name`}
              name="projectName"
              className="w-100" />
            : null
        }
        {
          errorMessage &&
          <div className="mt-4">
            <ErrorMessage
              type="alert"
              errorMessage={errorMessage} />
          </div>
        }
      </div>
      {
        type === 'project' || type === 'document' || type === 'theme' ?
          <DialogActionsBar>
            <button
              type='button'
              className="k-button btn btn-danger"
              onClick={type === 'project' ? onSubmitHandler : deleteItem}
              disabled={projectName.trim() !== name?.trim() || isDeletingItem}>
              {
                isDeletingItem ?
                  <div className="spinner-border spinner-border-sm" />
                  :
                  <span>Delete</span>
              }
            </button>
          </DialogActionsBar>
          :
          <DialogActionsBar>
            <button type='button' disabled={deleteButtonDisabled || isDeleting} className="k-button btn btn-secondary " onClick={handleClose}>Cancel</button>
            <button type='button' className="k-button btn btn-danger " disabled={deleteButtonDisabled || isDeleting} onClick={deleteItem}>
              {
                isDeleting &&
                <div className="spinner-border spinner-border-sm mr-2" />
              }
              <span>Delete</span>
            </button>
          </DialogActionsBar>
      }
    </Dialog>
  )
}
