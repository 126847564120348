const initialState = {
  notification: {},
  inboxNotificationBadge: false
};

function inboxNotificationStateReducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_NOTIFICATION':
      return {
        ...state,
        notification: action.payload
      }
    case 'UPDATE_INBOX_NOTIFICATIONS_BADGE':
      return {
        ...state,
        inboxNotificationBadge: action.payload
      }
    default:
      return state;
  }
}

export default inboxNotificationStateReducer;