import WALRLogo from '../../assets/img/walr_black.png';

export const TrialExpired = () => {
  return (
    <div className="trial-expired-container" >
      <div className="d-flex flex-column justify-content-between h-100 p-5">
        <img className="trial-expired-walr-logo" src={WALRLogo} alt="WALR logo" />
        <div className="container flex-grow-1">
          <div className="row justify-content-center align-items-center h-100">
            <div className="col-md-6">
              <div className="d-flex flex-column gap-xl text-center">
                <span className="trial-expired-header">Your Walr platform login has expired</span>
                <div className="trial-expired-text">
                  To continue using Walr, you need to contact your
                  <br />
                  account manager or Walr support for assistance
                </div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="mailto:support@walr.com"
                  className="btn btn-primary m-auto w-50">
                  Contact Walr support
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between border-disabled border-top pt-5">
          <a href="https://support.walr.com/support/home" target="_blank" rel="noopener noreferrer">Help center</a>
          <div className="d-flex gap-xl">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://walr.com/app/uploads/2022/12/Walr-Terms-and-Conditions.pdf">
              Terms and Condtitions
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://walr.com/privacy-policy/">
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  )
};
