import React, { useEffect, useState } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { filterBy } from '@progress/kendo-data-query';
import { Switch } from '@progress/kendo-react-inputs';

import { returnQuestionIcon } from '../../../../../shared/helpers/returnQuestionIcon/returnQuestionIcon';
import { Avatar } from '../../../../../../../../shared/Avatar/Avatar';

export default ({ type, data, onSelectQuestion, headerSelectionChange, id, onSwitchChange, onItemChange }) => {
  const [filter, setFilter] = useState();

  useEffect(() => {
    // A function which sets a placeholder to the input field,
    // which is dinamycally generated by Kendo React Grid element
    const filterInputElement = document.querySelector('.k-filtercell-wrapper>.k-textbox>.k-input-inner');
    if (filterInputElement) {
      filterInputElement.setAttribute('placeholder', 'Search...');
    }
  }, []);

  return (
    <Grid
      data={filterBy(data, filter)}
      filterable={true}
      filter={filter}
      onFilterChange={(e) => setFilter(e.filter)}
      selectedField="selected"
      onSelectionChange={(e) => onSelectQuestion(e, type, id)}
      onHeaderSelectionChange={(e) => headerSelectionChange(e, type, id)}
      className="weight-grid"
      editField="inEdit"
      onItemChange={onItemChange}
    >
      {type !== 'rimGrid' ? (
        <Column filterable={false} field="selected" width="50px" />
      ) : (
        <Column filterable={false} title="Relative value" editor="numeric" field="population" />
      )}
      {type !== 'definitions' && type !== 'rimGrid' ? (
        <Column
          filterable={false}
          title="Active"
          cell={(props) => {
            return (
              <td>
                <Switch checked={props.dataItem.active} onChange={() => onSwitchChange(props.dataItem, type, id)} size="small" />
              </td>
            );
          }}
        />
      ) : null}
      {type === 'rimGrid' ? (
        <Column
          filterable={false}
          title="Text"
          cell={(props) => {
            return <td>{props.dataItem.expressionDescription}</td>;
          }}
        />
      ) : null}
      {type === 'definitions' ? (
        <Column
          field="qno"
          title="Id"
          cell={(props) => {
            const icon = returnQuestionIcon(props.dataItem.type);
            return (
              <td>
                <div className="d-flex align-items-center">
                  <Avatar icon={icon} color="analyze" class="avatar-sm" />
                  {props.dataItem.qno.slice(0, -2)}
                </div>
              </td>
            );
          }}
        />
      ) : null}
      {type === 'categories' || type === 'responseRows' ? (
        <Column
          filterable={false}
          title="Code"
          cell={(props) => {
            return (
              <td>
                {type === 'categories' ? (
                  <div className="d-flex align-items-center">{props.dataItem.code ? props.dataItem.code : props.dataItem.position}</div>
                ) : (
                  <div className="d-flex align-items-center">{props.dataItem.position}</div>
                )}
              </td>
            );
          }}
        />
      ) : null}
      {type !== 'rimGrid' ? (
        <Column
          filterable={false}
          title="Text"
          cell={(props) => {
            return <td>{props.dataItem.texts.en || Object.values(props.dataItem.texts)[0]}</td>;
          }}
        />
      ) : null}
    </Grid>
  );
};
