import { fetchPostJson as createEmailProvider } from "../../../../../services/services"
import type { EmailProviderFormData } from "../../../../../interfaces/emailProviderInterfaces/emailProviderInterfaces"

export const submitCreateEmailProvidersHandler = (formData: EmailProviderFormData, dispatch: TODO, token: string, setIsLoading: (val: boolean) => void) => {
  const body = {
    tags: formData.tags,
    smtpPort: formData.port,
    smtpRelay: formData.relay,
    name: formData.providerName,
    smtpEnableSsl: formData.SSL,
    smtpUsername: formData.username,
    smtpPassword: formData.password,
    description: formData.providerDescription,
  }
  setIsLoading(true);
  createEmailProvider("emailproviders/smtp", token, body)
    .then((res: TODO) => {
      setIsLoading(false);
      if (res && (!res.error || !res.message)) {
        dispatch({ type: 'UPDATE_EMAIL_PROVIDERS', payload: true })
        dispatch({ type: 'UPDATE_SHOW_EMAIL_PROVIDERS_MODAL', payload: { emailProvider: null, show: false } })
      } else {
        dispatch({ type: 'UPDATE_EMAIL_PROVIDERS_ERROR_MESSAGE', payload: 'Something went wrong, please try again!' })
      }
    })
}