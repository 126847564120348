import React, { useState } from 'react';

import ToggleSection from './ToggleSection/ToggleSection';
import QuestionControlsButton from './QuestionControlsButton';
import setQuestionControlsButtons from '../helpers/importDataHelpers/setQuestionControlsButtons/setQuestionControlsButtons';

export default ({ multiSelect, onToggleAll, toggleAll, onToggleControl, setSearchValue, searchValue, toggleSearch, toggleReplace, onReplaceHandler }) => {
  const [replaceValue, setReplaceValue] = useState('')
  const questionControlsButtons = setQuestionControlsButtons(toggleSearch, toggleReplace, multiSelect)

  const replaceValueHandler = (e) => {
    setReplaceValue(e)
  }

  return (
    <div className="d-flex flex-column gap-md px-3 py-2 border-bottom">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex flex-wrap gap-sm">
          {
            questionControlsButtons.map((button, id) => (
              <QuestionControlsButton
                key={id}
                onToggleControl={onToggleControl}
                onToggleControlAction={button.onToggleControlAction}
                toggleElement={button.toggleElement}
                buttonText={button.buttonText}
              />
            ))
          }
          <button
            type='button'
            onClick={() => onToggleAll(undefined)}
            className={`btn ${toggleAll ? 'btn-light' : 'btn-transparent'}`}>
            {toggleAll ? 'Deselect all' : 'Select all'}
          </button>
        </div>
      </div>
      <ToggleSection
        multiSelect={multiSelect}
        toggleSearch={toggleSearch}
        toggleReplace={toggleReplace}
        replaceValue={replaceValue}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        onReplaceHandler={onReplaceHandler}
        replaceValueHandler={replaceValueHandler}
      />
    </div>
  );
}
