import { Icon } from '../../../../../../../shared/Icon/Icon';

interface Props {
  settings: any
  settingsValues: any
  onShowFileManager: (id: string) => void
  onChangeSettingsValues: (a: any, b: any) => void
  setCustomSettingValues: (value: any) => void
}

export const ThemeSettingsTabContent = ({ onShowFileManager, settingsValues, onChangeSettingsValues, setCustomSettingValues }: Props) => {
  return (
    <div className='d-flex flex-column w-50'>
      <div className="form-group flex-shrink-0">
        <label className="mb-1">Logo</label>
        <div className="input-group">
          <input
            type="text"
            className="form-control h-32"
            placeholder='Select an image'
            name='logo'
            style={{ maxHeight: 'unset' }}
            value={settingsValues.logo ? settingsValues.logo : ""}
            onChange={(e) => setCustomSettingValues(e)}
          />
          <div className="input-group-append">
            <button className="btn btn-outline-secondary p-1" type="button" onClick={() => onShowFileManager("logo")}>
              <Icon type="add" />
            </button>
            <button className="btn btn-outline-secondary p-1" type="button" onClick={() => onChangeSettingsValues(null, "logo")}>
              <Icon type="delete-alt" />
            </button>
          </div>
        </div>
      </div>
      <div className="form-group flex-shrink-0">
        <label className="mb-1">Fav icon</label>
        <div className="input-group">
          <input
            type="text"
            className="form-control h-32"
            placeholder='Select an image'
            name='favIcon'
            style={{ maxHeight: 'unset' }}
            value={settingsValues.favIcon ? settingsValues.favIcon : ""}
            onChange={(e) => setCustomSettingValues(e)}
          />
          <div className="input-group-append">
            <button className="btn btn-outline-secondary p-1" type="button" onClick={() => onShowFileManager("favIcon")}>
              <Icon type="add" />
            </button>
            <button className="btn btn-outline-secondary p-1" type="button" onClick={() => onChangeSettingsValues(null, "favIcon")}>
              <Icon type="delete-alt" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}