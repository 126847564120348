import { useEffect, useState } from "react"

import { timeSince } from "../../../../shared/helpers/timeSince/timeSince";

interface Props {
  readReceipts: ReadReceipt[],
  dateToday: Date,
  dateYesterday: Date
  isLastEntry: boolean,
}

interface ReadReceipt {
  userId: string,
  userName: string,
  timeStamp: string,
}

export const ReadReceiptsDetailsComponent = (props: Props) => {
  const [expandRecepits, setExpandReceipts] = useState<boolean>(false);
  const [splicedReceipts, setSplicedReceipts] = useState<ReadReceipt[]>([]);

  useEffect(() => {
    if (props.readReceipts) {
      props.readReceipts.sort((el1, el2) => {
        //@ts-ignore
        return new Date(el2.timeStamp) - new Date(el1.timeStamp);
      });
      if (props.readReceipts.length > 1) {
        setSplicedReceipts(props.readReceipts.splice(1))
      }
    }
  }, [])

  const timeHandler = (date: Date) => {
    if (date >= props.dateToday) {
      return ` today at ${new Date(date).toLocaleTimeString([], { timeStyle: "short" })}`
    } else if (date >= props.dateYesterday && date < props.dateToday) {
      return " yesterday"
    } else {
      return timeSince(date)
    }
  }

  const handleTooltip = (e: any) => {
    e.stopPropagation()
    setExpandReceipts(() => !expandRecepits)
  }

  return (
    <div>
      <div className="read-receipts-text">
        {props.readReceipts.map((el) => {
          return (
            <p className="p-0 m-0 read-receipts-text" key={el.userId}><span className="far fa-eye pr-1"></span> {`${el.userName}`} - {timeHandler(new Date(el.timeStamp))}</p>
          )
        })}
        {
          splicedReceipts.length >= 1 &&
          <span onClick={handleTooltip} className="show-more-less">{expandRecepits ? ' show less' : `show more`}</span>
        }
        {
          expandRecepits &&
          <div className={`read-receipts-tooltip border border-light ${props.isLastEntry ? "last-tooltip" : ""}`}>
            {splicedReceipts.map((el) => {
              return <p className="p-0 m-0 read-receipts-text" key={el.userId}><span className="far fa-eye pr-1"></span> {`${el.userName}`} - {timeHandler(new Date(el.timeStamp))}</p>
            })}
          </div>
        }
      </div >
    </div>
  )
}