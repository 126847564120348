const initialState = {
  quickImportStatusData: {
    isInProgress: false,
    isProgressCompleted: false,
    progressStatus: '',
    uploadErrorMessage: null,
    importErrorMessage: null,
    redirectData: null,
  },
  isQuickImportModalShown: false,
  isQuickOptionsModalShown: false,
  isLeagasyImportModalShown: false,
};

function quickImportStatusReducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_QUICK_IMPORT_STATUS':
      return {
        ...state,
        quickImportStatusData: action.payload,
      };
    case 'DISPLAY_LEGASY_IMPORT_MODAL': {
      return {
        ...state,
        quickImportStatusData: { ...state.quickImportStatusData },
        isLeagasyImportModalShown: action.payload,
      };
    }
    case 'DISPLAY_IMPORT_OPTIONS_MODAL': {
      return {
        ...state,
        quickImportStatusData: { ...state.quickImportStatusData },
        isQuickOptionsModalShown: action.payload,
      };
    }
    case 'DISPLAY_QUICK_IMPORT_MODAL':
      return {
        ...state,
        quickImportStatusData: { ...state.quickImportStatusData },
        isQuickImportModalShown: action.payload,
      };
    case 'HIDE_QUICK_IMPORT_NOTIFICATION':
      return {
        ...state,
        quickImportStatusData: {
          ...state.quickImportStatusData,
          isInProgress: false,
          isProgressCompleted: false,
        },
      };
    case 'COMPLETE_QUICK_IMPORT':
      return {
        ...state,
        quickImportStatusData: {
          ...state.quickImportStatusData,
          isInProgress: false,
          progressStatus: '',
          isProgressCompleted: true,
          redirectData: action.payload,
        },
        isQuickImportModalShown: false,
      };
    default:
      return state;
  }
}

export default quickImportStatusReducer;

