import React from 'react';
import { useDispatch } from 'react-redux';
import { Switch } from '@progress/kendo-react-inputs';

export default ({ user, isAdvancedImportModalShown, onAdvancedImportOptionsChangeHandler, automaticMergeOptions, selectedTab, onShowGenericLibraryHandler, genericLibrary }) => {
  const dispatch = useDispatch()

  const onChangeHandler = (value) => {
    if (value === true) {
      dispatch({ type: 'DISPLAY_ADVANCED_IMPORT_MODAL', payload: true })
      dispatch({ type: 'DISPLAY_QUICK_IMPORT_MODAL', payload: false })
    } else {
      dispatch({ type: 'DISPLAY_QUICK_IMPORT_MODAL', payload: true })
      dispatch({ type: 'DISPLAY_ADVANCED_IMPORT_MODAL', payload: false })
    }
  }

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between align-items-center">
        <span>Open import for manual processing</span>
        <Switch
          className=""
          onChange={(e) => { onChangeHandler(e.target.value) }}
          checked={isAdvancedImportModalShown}
          size="small"
        />
      </div>
      <div style={{ paddingLeft: '25px' }} className="d-flex justify-content-between align-items-center">
        <span className={`${!isAdvancedImportModalShown && "text-muted"}`}>Load saved template</span>
        <Switch
          className=""
          onChange={(e) => { onShowGenericLibraryHandler(e.target.value) }}
          checked={genericLibrary}
          disabled={!isAdvancedImportModalShown}
          size="small"
        />
      </div>
      <div style={{ paddingLeft: '25px' }} className="d-flex justify-content-between align-items-center">
        <span className={`${!isAdvancedImportModalShown && "text-muted"}`}>Create grids automatically</span>
        <Switch
          name="createGridsAutomatically"
          disabled={!isAdvancedImportModalShown}
          className=""
          onChange={onAdvancedImportOptionsChangeHandler}
          checked={automaticMergeOptions.createGridsAutomatically}
          size="small"
        />
      </div>
      <div style={{ paddingLeft: '25px' }} className="d-flex justify-content-between align-items-center">
        <span className={`${!isAdvancedImportModalShown && "text-muted"}`}>Create multiples automatically</span>
        <Switch
          name="createMultiplesAutomatically"
          disabled={!isAdvancedImportModalShown}
          className=""
          onChange={onAdvancedImportOptionsChangeHandler}
          checked={automaticMergeOptions.createMultiplesAutomatically}
          size="small"
        />
      </div>
      {/* This option is shown only for Excel imports */}
      {selectedTab === 1 &&
        <div style={{ paddingLeft: '25px' }} className="d-flex justify-content-between align-items-center">
          <span className={`${!isAdvancedImportModalShown && "text-muted"}`}>Max unique values for single choice</span>
          <input
            type="number"
            name="maxUniqueValuesForSingleChoice"
            min={0}
            defaultValue={20}
            style={{ maxWidth: "80px" }}
            disabled={!isAdvancedImportModalShown}
            className="k-textbox form-control form-control-sm numeric-noArrows"
            onKeyDown={e => {
              if (e.key === "." || e.key === "-" || e.key === "e") {
                e.preventDefault();
              }
            }}
            onChange={onAdvancedImportOptionsChangeHandler}>
          </input>
        </div>}
    </React.Fragment>
  )
}