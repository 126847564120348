import React, { useState } from 'react';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import TabTitle from '../TabTitle/TabTitle';
import CreateWeight from './CreateWeight/CreateWeight';
import ExistingWeights from './ExistingWeights/ExistingWeights';
import { AddScriptedWeights } from './AddScriptedWeights/AddScriptedWeights'
import NumericQuestionsWeight from './NumericQuestionsWeight/NumericQuestionsWeight';
import UpgradePlanMessage from '../../../../../../shared/UpgradePlanMessage/UpgradePlanMessage';

const WeightWizard = ({ handleClose, token, datasetId, user }) => {
  const [tabSelected, setTabSelected] = useState(0)

  return (
    <Dialog onClose={() => handleClose()} title='Weight wizard' height="90%" width="70%" className="create-question">
      <TabStrip selected={tabSelected} onSelect={(e) => setTabSelected(e.selected)} className="analyze-tabs weight-wizard-tabs h-100">
        <TabStripTab title={<TabTitle title="Create new weight" user={user} />}>
          {user.plan === 'professional_monthly' || user.plan === 'professional_yearly' || user.isSubAccount ?
            <CreateWeight token={token} datasetId={datasetId} onClose={() => handleClose()} />
            : <UpgradePlanMessage className="tab-overlay" user={user} />}
        </TabStripTab>
        <TabStripTab title={<TabTitle title="Existing weights" user={user} />}>
          {user.plan === 'professional_monthly' || user.plan === 'professional_yearly' || user.isSubAccount ?
            <ExistingWeights token={token} datasetId={datasetId} />
            : <UpgradePlanMessage className="tab-overlay" user={user} />}
        </TabStripTab>
        <TabStripTab title={<TabTitle title="Script Weight" user={user} />}>
          {user.plan === 'professional_monthly' || user.plan === 'professional_yearly' || user.isSubAccount ?
            <AddScriptedWeights token={token} onClose={() => handleClose()} />
            : <UpgradePlanMessage className="tab-overlay" user={user} />}
        </TabStripTab>
        <TabStripTab title={<TabTitle title="Numeric Questions" user={user} />}>
          {user.plan === 'professional_monthly' || user.plan === 'professional_yearly' || user.isSubAccount ?
            <NumericQuestionsWeight token={token} datasetId={datasetId} onClose={() => handleClose()} />
            : <UpgradePlanMessage className="tab-overlay" user={user} />}
        </TabStripTab>
      </TabStrip>
      <DialogActionsBar>
        <button type='button' className="k-button btn btn-secondary" id="close" onClick={() => handleClose()}>Close</button>
      </DialogActionsBar>
    </Dialog >
  );
};

export default WeightWizard;
