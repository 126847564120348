interface DataElement {
  id: string
  title: string
  order: string
}

export const updateOpenListData = (data: DataElement[], initialData: DataElement[], checkedQuestions: DataElement[]) => {
  let updatedData: DataElement[] = JSON.parse(JSON.stringify(data));
  let updatedInitialData: DataElement[] = JSON.parse(JSON.stringify(initialData));
  const firstElIndex = updatedData.findIndex(el => el.id === checkedQuestions[0].id);
  const firstInitialElIndex = updatedInitialData.findIndex(el => el.id === checkedQuestions[0].id);

  const mergedMultiChoiceQuestion = {
    id: `${checkedQuestions[0].id}.1`,
    title: checkedQuestions[0].title,
    mergedQuestions: checkedQuestions,
    values: {
      value: checkedQuestions.map((el, id) => {
        return {
          "@code": id + 1,
          text: el.title
        }
      })
    },
    selected: false,
    checked: false,
    type: 'multichoiceNmopen',
    order: checkedQuestions[0].order || '0',
    merged: true
  }

  updatedData.splice(firstElIndex, 0, mergedMultiChoiceQuestion);
  updatedInitialData.splice(firstInitialElIndex, 0, mergedMultiChoiceQuestion);
  checkedQuestions.forEach(el => {
    updatedData = updatedData.filter(val => val.id !== el.id)
    updatedInitialData = updatedInitialData.filter(val => val.id !== el.id)
  })

  return {
    data: updatedData,
    initialData: updatedInitialData,
    displayTable: mergedMultiChoiceQuestion
  }
}