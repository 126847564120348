import { Fragment } from 'react';
import { Input, type InputChangeEvent } from '@progress/kendo-react-inputs';

import type { SubAccountFormData } from '../../../../../../../interfaces/adminInterfaces/adminInterfaces';

interface Props {
  formData: SubAccountFormData
  onChangeHandler: (e: InputChangeEvent, name: string) => void
}

export const SubAccountGeneralSettingsContent = ({ formData, onChangeHandler }: Props) => {
  return (
    <Fragment>
      <div className="mb-2">
        <Input
          required
          label={'Sub account name'}
          value={formData.subAccountName}
          onChange={(e) => onChangeHandler(e, 'subAccountName')} />
      </div>
      <div className="mb-2">
        <Input
          label={'Sub account description'}
          value={formData.subAccountDescription}
          onChange={(e) => onChangeHandler(e, 'subAccountDescription')} />
      </div>
    </Fragment>
  )
}