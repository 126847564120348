import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { DropDownButton } from '@progress/kendo-react-buttons';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';

import { InProgressOverlay } from '../../../../../../../shared/InProgressOverlay/InProgressOverlay';
import ConfirmActionModal from '../../../../../../../shared/ConfirmActionModal/ConfirmActionModal';
import { UpdateModal } from '../../../../../../../shared/Modals/UpdateModal/UpdateModal.tsx';
import { WeightStatsModal } from '../WeightStatsModal/WeightStatsModal.tsx';
import { fetchGetJson as getAllWeights, fetchDeleteJson as deleteWeight } from '../../../../../../../../services/services';

const ExistingWeights = ({ token, datasetId }) => {
  const history = useHistory();
  const [didUpdate, setDidUpdate] = useState(true);
  const [weightsData, setWeightsData] = useState([]);
  const [deleteWeightModal, setDeleteWeightModal] = useState({ weight: null, show: false });
  const [editWeightModal, setEditWeightModal] = useState({ weight: null, show: false });
  const [weightStatsModal, setWeightStatsModal] = useState({ weight: null, show: false });

  const items = [
    { text: 'Delete', icon: 'fa fas fa-trash-alt', id: 'delete' },
    { text: 'Edit', icon: 'fa fas fa-edit', id: 'edit' },
    { text: 'Weighting stats', icon: 'fa fas fa-circle-info', id: 'weightingStats' }
  ];

  useEffect(() => {
    if (didUpdate) {
      getAllWeights(`an/projects/${history.location.pathname.split('/')[2]}/analysis/${datasetId}/weights`, token).then((res) => {
        setWeightsData(res.map((val) => ({ ...val, selected: false })));
      });
    }
    setDidUpdate(false);
  }, [didUpdate, datasetId, token, history.location.pathname]);

  const selectAction = (e, dataItem) => {
    const { id } = dataItem;
    if (e.item.id === 'delete') {
      setDeleteWeightModal({ show: true, weight: id });
    }
    if (e.item.id === 'edit') {
      setEditWeightModal({ show: true, weight: dataItem });
    }

    if (e.item.id === 'weightingStats') {
      setWeightStatsModal({ show: true, weight: dataItem });
    }
  };

  const onDeleteWeight = () => {
    if (deleteWeightModal.weight !== null) {
      deleteWeight(`an/projects/${history.location.pathname.split('/')[2]}/analysis/${datasetId}/weights/${deleteWeightModal.weight}`, token).then(
        (res) => {
          if (res && res.status === 204) {
            setDeleteWeightModal({ show: false, weight: null });
            setDidUpdate(true);
          }
        }
      );
    }
  };

  const updateEditedWeights = (updatedWeight) => {
    const updatedWeightsData = [...weightsData]
    updatedWeightsData.splice(weightsData.findIndex(weight => weight.id === editWeightModal.weight.id), 1, updatedWeight)
    setWeightsData(updatedWeightsData)
    setEditWeightModal({ show: false, weight: null })
  }

  return (
    <div className="d-flex flex-column h-100 p-2">
      {deleteWeightModal.show && (
        <ConfirmActionModal
          show={deleteWeightModal.show}
          projectName={`${deleteWeightModal.weight}`}
          handleClose={() => setDeleteWeightModal({ show: false, weight: null })}
          deleteItem={() => onDeleteWeight()}
          type="user"
        />
      )}

      {editWeightModal.show && (
        <UpdateModal
          show={editWeightModal.show}
          editedWeight={editWeightModal.weight}
          datasetId={datasetId}
          handleClose={() => setEditWeightModal({ show: false, weight: null })}
          setWeightsData={updateEditedWeights}
          type="user"
          token={token}
        />
      )}

      {
        weightStatsModal.show && (
          <WeightStatsModal handleClose={() => setWeightStatsModal({ show: false, weight: null })} weight={weightStatsModal.weight} />
        )
      }

      {weightsData ? (
        <Grid data={weightsData} className="weight-grid p-3">
          <Column field="id" title="Weight Id" width={200} />
          <Column
            title="Text"
            cell={(props) => {
              return <td>{props.dataItem.text && <span>{props.dataItem.text}</span>}</td>;
            }}
          />
          <Column
            width={100}
            title="Population"
            cell={(props) => {
              return <td>{props.dataItem.pop && <span>{props.dataItem.pop}</span>}</td>;
            }}
          />
          <Column
            width={100}
            cell={(props) => (
              <td className="d-flex justify-content-end">
                <DropDownButton
                  iconClass="fa fas fa-ellipsis-h"
                  buttonClass="btn btn-secondary d-flex"
                  items={items}
                  onItemClick={(e) => selectAction(e, props.dataItem)}
                />
              </td>
            )}
          />
        </Grid>
      ) : (
        <InProgressOverlay type="fullscreen" theme="primary" />
      )}
    </div>
  );
};

export default ExistingWeights;

