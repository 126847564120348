import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from '@progress/kendo-react-inputs';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

import { useDebounce } from '../../../../../../shared/customHooks/useDebounce';
import SubQuestionTabTitle from '../../../helpers/SubQuestionTabTitle/SubQuestionTabTitle';

export const CustomErrorMessages = ({ selectedTab, setSelectedTab }) => {
  const { theData } = useSelector(theState => (theState.surveyInitialDataReducer));
  const dispatch = useDispatch();
  const saveWithDebounce = useDebounce(() => dispatch({ type: 'SAVE_QUESTION' }), 1000);

  const addCustomErrorMessage = (e) => {
    const selectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    if (e.value === true) {
      const newElement = {
        type: "customerrormsg",
        attributes: {},
        elements: [
          {
            type: "text",
            attributes: {},
            elements: [],
            textValues: {
              en: ""
            }
          }
        ]
      }
      selectedItem.elements.filter(el => el.type === 'subq')[selectedTab].elements.find(el => el.type === 'stext').elements.push(newElement)
    } else {
      let filtered = selectedItem.elements.filter(el => el.type === 'subq')[selectedTab].elements.find(el => el.type === 'stext').elements.filter(el => el.type !== "customerrormsg")
      selectedItem.elements.filter(el => el.type === 'subq')[selectedTab].elements.find(el => el.type === 'stext').elements = filtered
    }
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: selectedItem })
    saveWithDebounce()
  }

  const onCustomErrorMessageChange = (value) => {
    const selectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    selectedItem.elements.filter(el => el.type === 'subq')[selectedTab].elements.find(el => el.type === 'stext').elements.find(el => el.type === "customerrormsg").elements.find(el => el.type === "text").textValues[theData.editingLanguage] = value
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: selectedItem })
    saveWithDebounce()
  }

  return (
    <div className='row d-flex justify-content-between pb-3 m-4 content'>
      <div className='w-25 px-0 pb-3'>
        <h6 className='mb-3 mt-3'>Custom error messages</h6>
        <p>Specify a custom error message for this question.</p>
      </div>
      {theData.selectedItem.elements.filter(el => el.type === 'subq').length === 1 ?
        <div className='col-lg-7 pb-3 px-0 pt-5 mb-3 logic-content-tab'>
          <div className="d-flex flex-column align-items-center w-100 p-2">
            <div className='d-flex align-items-center w-100 mb-4'>
              <Switch
                checked={theData.selectedItem.elements.filter(el => el.type === 'subq')[selectedTab]?.elements.find(el => el.type === 'stext').elements?.find(el => el.type === 'customerrormsg') ? true : false}
                onChange={e => addCustomErrorMessage(e)}
                size="small"
              />
              <span className='ml-2'>Enable custom error message</span>
            </div>
            <input
              name="Enable custom error message"
              className="form-control flex-grow-1 k-textbox"
              value={theData.selectedItem.elements.filter(el => el.type === 'subq')[selectedTab].elements.find(el => el.type === 'stext').elements?.find(el => el.type === 'customerrormsg')?.elements.find(el => el.type === "text").textValues[theData.editingLanguage] ? theData.selectedItem.elements.filter(el => el.type === 'subq')[selectedTab].elements.find(el => el.type === 'stext').elements.find(el => el.type === 'customerrormsg').elements.find(el => el.type === "text").textValues[theData.editingLanguage] : ""}
              onChange={(e) => onCustomErrorMessageChange(e.target.value)}
              disabled={theData.selectedItem.elements.filter(el => el.type === 'subq')[selectedTab].elements.find(el => el.type === 'stext').elements?.find(el => el.type === 'customerrormsg') ? false : true}
              placeholder="Enter text..."
            />
          </div>
        </div>
        :
        <div className='col-lg-7 pb-3 px-0 pt-5 mb-3 logic-content-tab'>
          <div className="d-flex flex-column align-items-center w-100">
            <TabStrip selected={selectedTab} className="subquestion-tabs h-100 w-100" onSelect={(e) => setSelectedTab(e)}>
              {theData.selectedItem.elements.filter(el => el.type === 'subq').map((subquestion, key) => (
                <TabStripTab key={key} title={<SubQuestionTabTitle theData={theData} index={key} selected={selectedTab} />}>
                  <div className="d-flex flex-column align-items-center w-100 p-2">
                    <div className='d-flex align-items-center w-100 mb-4'>
                      <Switch
                        checked={theData.selectedItem.elements.filter(el => el.type === 'subq')[selectedTab]?.elements.find(el => el.type === 'stext').elements?.find(el => el.type === 'customerrormsg') ? true : false}
                        onChange={e => addCustomErrorMessage(e)}
                      />
                      <span className='ml-2'>Enable custom error message</span>
                    </div>
                    <input
                      name="Enable custom error message"
                      className="form-control flex-grow-1 k-textbox"
                      value={theData.selectedItem.elements.filter(el => el.type === 'subq')[selectedTab].elements.find(el => el.type === 'stext').elements?.find(el => el.type === 'customerrormsg')?.elements.find(el => el.type === "text").textValues[theData.editingLanguage] ? theData.selectedItem.elements.filter(el => el.type === 'subq')[selectedTab].elements.find(el => el.type === 'stext').elements.find(el => el.type === 'customerrormsg').elements.find(el => el.type === "text").textValues[theData.editingLanguage] : ""}
                      onChange={(e) => onCustomErrorMessageChange(e.target.value)}
                      disabled={theData.selectedItem.elements.filter(el => el.type === 'subq')[selectedTab].elements.find(el => el.type === 'stext').elements?.find(el => el.type === 'customerrormsg') ? false : true}
                      placeholder="Enter text..."
                    />
                  </div>
                </TabStripTab>
              ))}
            </TabStrip>
          </div>
        </div>
      }
    </div>
  )
}
