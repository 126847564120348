import disableOptionByPlan from "../disableOptionByPlan/disableOptionByPlan"

export default (data, user, groups) => {
  let list = {}
  data.forEach(option => {
    if (option.type === "Boolean") {
      if (option.id === 'ShowLabels') {
        let disabled = disableOptionByPlan(option, user, groups)
        if (disabled === false) {
          list[option.id] = true
        }
        else {
          list[option.id] = false
        }
      }
      else {
        list[option.id] = false
      }
    }
    else if (option.type === "String") {
      list[option.id] = ''
    }
    else if (option.type === "Int32" || option.type === "Double") {
      list[option.id] = 0
    }
    else {
      if (option.id === 'HeaderType') {
        list[option.id] = 0
      }
      else {
        list[option.id] = 0
      }
    }
  })
  return list
}