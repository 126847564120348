const initialState = {
  theState: {
    exportState: {
      isExportInProgress: false,
      isExportFinished: false,
      exportErrorMsg: null
    },
    isOverwriteModalShown: false
  }
};

function toplineStateReducer(state = initialState.theState, action) {
  switch (action.type) {
    case 'START_EXPORT_PROGRESS':
      return ({
        ...state,
        exportState: {
          ...state.exportState,
          isExportInProgress: true,
          exportErrorMsg: null
        }
      })
    case 'FINISH_EXPORT_PROGRESS':
      return ({
        ...state,
        exportState: {
          ...state.exportState,
          isExportFinished: true,
          isExportInProgress: false,
          exportErrorMsg: null
        }
      })
    case 'EXPORT_SET_ERROR_MESSAGE':
      return ({
        ...state,
        exportState: {
          ...state.exportState,
          isExportFinished: false,
          isExportInProgress: false,
          exportErrorMsg: action.payload
        }
      })
    case 'DISPLAY_OVERWRITE_MODAL':
      return ({
        ...state,
        exportState: { ...state.exportState },
        isOverwriteModalShown: action.payload
      })
    case 'CLOSE_EXPORT_MODAL':
      return ({
        ...state,
        exportState: {
          ...state.exportState,
          isExportFinished: false,
          isExportInProgress: false,
          exportErrorMsg: null
        }
      })
    default:
      return { ...state };
  }
}

export default toplineStateReducer;