import type { Dispatch, SetStateAction } from "react"

type Props = {
  icon: string
  filterTrialStatus: "All" | "Active" | "Non Active"
  setFilterTrialStatus: Dispatch<SetStateAction<"All" | "Active" | "Non Active">>
}

export const DropDownIcon = ({ icon, filterTrialStatus, setFilterTrialStatus }: Props) => {
  const dropDownTrialFilterItems = ["All", "Active", "Non Active"] as const;

  return (
    <div className="input-group-prepend d-inline position-relative">
      <i className={`${icon} dropdown-toggle`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
      <div style={{ width: "max-content" }} className="dropdown-menu">
        {
          dropDownTrialFilterItems.map(title =>
            <button
              key={title}
              type="button"
              className="dropdown-item  m-0 cursor-pointer d-flex justify-content-between align-items-center"
              onClick={() => setFilterTrialStatus(title)}>
              {title}
              {filterTrialStatus === title && <i className="far fa-check" />}
            </button>)
        }
      </div>
    </div>
  )
}