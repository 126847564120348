interface Props {
  secondaryMessage?: string
}

export const GridNoData = ({ secondaryMessage }: Props) => {
  return (
    <div className="d-flex flex-column gap-md my-3">
      <i className="fal fa-folder-open fa-3x text-muted"></i>
      <p className="text-muted h5 m-0">There is no data available</p>
      {
        secondaryMessage &&
        <p className="h6 text-muted m-0">{secondaryMessage}</p>
      }
    </div>
  )
}