import React, { Fragment } from 'react';

import { AddScriptModal } from '../AddScriptModal/AddScriptModal';
import { DeleteAnalysisScriptingItemModal } from '../DeleteAnalysisScriptingItemModal/DeleteAnalysisScriptingItemModal';
import LoadSessionModal from '../LoadSessionModal/LoadSessionModal';
import ClearSessionsModal from '../ClearSessionsModal/ClearSessionsModal';
import CreateQuestionDialog from '../../dashboard/main/CreateQuestionDialog/CreateQuestionDialog';
import ConfirmActionModal from '../../../../../shared/ConfirmActionModal/ConfirmActionModal';
import SaveSessionModal from '../SaveSessionModal/SaveSessionModal';

export const AnalysisModals = ({
  activeTabs,
  tabSelected,
  openCreateQuestion,
  createQuestionData,
  editCustomQuestion,
  customItemSelected,
  showAddScriptModal,
  deleteQuestionModal,
  showLoadSessionModal,
  showSaveSessionModal,
  showClearSessionModal,
  showDeleteScriptModal,
  importedCreatedQuestion,
  selectedAnalysisScriptingItem,
  setDidMount,
  setTabSelected,
  onAutoSaveHandler,
  setShowAddScriptModal,
  setDeleteQuestionModal,
  onDeleteCustomQuestion,
  deleteButtonDisabled,
  showAnalysisScripting,
  setShowLoadSessionModal,
  setShowSaveSessionModal,
  setShowClearSessionModal,
  setShowDeleteScriptModal,
  handleCloseCreateQuestionModal }) => {

  return (
    <Fragment>
      {
        showClearSessionModal &&
        <ClearSessionsModal onHide={() => setShowClearSessionModal(false)} />
      }
      {
        showLoadSessionModal &&
        <LoadSessionModal
          activeTabs={activeTabs}
          onHide={() => setShowLoadSessionModal(false)}
          setTabSelected={(tab) => setTabSelected(tab)}
        />
      }
      {openCreateQuestion.show &&
        <CreateQuestionDialog
          handleClose={handleCloseCreateQuestionModal}
          filterData={createQuestionData}
          type={openCreateQuestion.type}
          editMode={editCustomQuestion}
          questionId={customItemSelected}
          importedCreatedQuestion={importedCreatedQuestion}
          updateData={() => setDidMount(true)}
        />
      }
      {
        deleteQuestionModal.show &&
        <ConfirmActionModal
          projectName={`${deleteQuestionModal.question}`}
          handleClose={() => setDeleteQuestionModal({ show: false, question: null })}
          deleteItem={() => onDeleteCustomQuestion()}
          isDeleting={deleteButtonDisabled}
          type='user'
        />
      }
      {
        showSaveSessionModal.show &&
        <SaveSessionModal
          selectedTab={tabSelected}
          onHide={() => setShowSaveSessionModal(false)}
        />
      }
      {
        showAddScriptModal.show &&
        <AddScriptModal
          scriptElement={showAddScriptModal.item}
          onAutoSaveHandler={onAutoSaveHandler}
          showAnalysisScripting={showAnalysisScripting}
          onHide={() => setShowAddScriptModal({ show: false, item: null })}
        />
      }
      {
        showDeleteScriptModal.show &&
        <DeleteAnalysisScriptingItemModal
          scriptId={showDeleteScriptModal.id}
          onAutoSaveHandler={onAutoSaveHandler}
          selectedAnalysisScriptingItem={selectedAnalysisScriptingItem}
          onHide={() => setShowDeleteScriptModal({ show: false, id: null })}
        />
      }
    </Fragment>
  )
}