import { Fragment, useState } from "react"
import { DropDownButton } from "@progress/kendo-react-buttons"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { ExportModal } from "../../ExportModal/ExportModal"
import { ExportActionItem } from "../../../../shared/ExportActionItem/ExportActionItem"
import type { User } from "../../../../../../../../interfaces/userInterface/userInterface"
import type { RootState } from "../../../../../../../../store/reducers/rootReducer"
import returnUpdatedAnalysisActionItems from "../../../../shared/helpers/returnUpdatedAnalysisActionItems/returnUpdatedAnalysisActionItems"
import items from '../../TabContent/exportItems.json';
import { updateEditedTextField } from '../../../../shared/helpers/resetEditedText/resetEditedText';
import returnActionsData from '../../../../shared/helpers/returnActionsData/returnActionsData';
import { returnUpdatedAnalysisBody } from "../../../../shared/helpers/returnUpdatedAnalysisBody/returnUpdatedAnalysisBody"
import type { ReportTableTypes } from "../../Reports/ReportsList"
import { updateEditReport } from '../../../../../../../../store/reducers/analysisReportsStateReducer/analysisReportsStateReducer';

type Props = {
  user: User;
  analysisFunctionalities: {
    analysisActions: {
      [key: string]: boolean;
    }
    analysisTypes: {
      [key: string]: boolean;
    }
  };
  optionsData: TODO;
  datasetName: string;
  setTabSelected: (item: TODO) => void;
}

const analysisTabs = {
  "g": 2,
  "xt": 1,
  "topline": 0,
  "qt": 4,
  "data": 5,
  "dig": 3
}

export const ReportsTabHeader = ({ user, analysisFunctionalities, optionsData, datasetName, setTabSelected }: Props) => {
  const { defaultLanguage, editingLanguage } = useSelector((theState: RootState) => (theState.setInitialDataReducer));
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const reportData = useSelector((state: RootState) => state.analysisReportsReducer);

  const params: { name: string, dataset: string } = useParams();
  const dispatch = useDispatch();

  const reportType = reportData.report.type as ReportTableTypes;

  const [showExportModal, setShowExportModal] = useState<{ show: boolean, action: TODO, type: TODO, extension: TODO }>({ show: false, action: null, type: null, extension: null })

  const updatedItems = returnUpdatedAnalysisActionItems(analysisFunctionalities, items, "datasets", true);

  const reportColumnData = reportData?.report?.firstColumn?.map((item: TODO) => {
    return {
      body: returnUpdatedAnalysisBody("datasets", reportData.report.type, reportData.report.newQuestionOptions, reportData.report.firstColumn, reportData.report.scndColumn, item.id),
      dataUrl: `an/projects/${params.name}/analysis/${params.dataset}`,
      icon: "fa fa-table fa-sm"
    }
  })

  const reportState = {
    columnData: reportColumnData,
    questions: reportData?.report?.firstColumn?.map((item: TODO) => { return { ...item, id: crypto.randomUUID(), type: "crosstab", active: false, selected: false } }),
    displayTable: "",
    icon: '',
    activeState: 0,
    zoomLevel: 1,
    source: {},
    destination: {},
    removeItem: { 'id': null, 'item': null },
  }

  const exportFunc = (props: TODO) => {
    if (reportState.questions && reportState.questions.length > 0) {
      setShowExportModal({ show: true, action: props.item.action, type: props.item.actionName, extension: props.item.extension })
    }
  }

  const actionItemRender = (props: TODO) => {
    return (
      <ExportActionItem
        tab='report'
        user={user}
        props={props}
        state={reportData.report}
        reportState={reportState}
        disabled={false}
      />
    )
  }

  const handleEditReport = () => {
    switch (reportType) {
      case "g":
        dispatch({ type: "UPDATE_INITIAL_CHART_STATE", payload: reportData.report })
        break;
      case "xt":
        dispatch({ type: "UPDATE_INITIAL_STATE", payload: reportData.report })
        break;
      case "topline":
        dispatch({ type: "UPDATE_INITIAL_TOPLINE_STATE", payload: reportData.report })
        break;
      case "qt":
        dispatch({ type: "UPDATE_INITIAL_QTABLE_STATE", payload: reportData.report })
        break;
      case "dig":
        dispatch({ type: "UPDATE_INITIAL_DIG_STATE", payload: reportData.report })
        break;
    }
    dispatch(updateEditReport(true));
    setTabSelected(analysisTabs[reportType])
  }

  return (
    <Fragment>
      {
        showExportModal?.show &&
        <ExportModal
          tab={'report'}
          state={reportState}
          updatedLanguage={editingLanguage}
          defaultLanguage={defaultLanguage}
          token={token}
          history={history}
          editorValue={null}
          datasetId={params.dataset}
          datasetName={datasetName}
          datasetType={"datasets"}
          projectType={undefined}
          showExportModal={showExportModal}
          onExportHandler={returnActionsData}
          projectId={params.name}
          options={{ exportOptions: optionsData.exportOptions, values: optionsData.dropdownValues }}
          onHide={() => { setShowExportModal({ ...showExportModal, show: false }); dispatch({ type: 'CLOSE_EXPORT_MODAL' }); }}
          exportBody={updateEditedTextField(reportState.columnData)}
        />
      }

      <div className="d-flex justify-content-between border-bottom p-4">
        <div className="d-flex gap-md">
          <button type="button" className="btn btn-primary" disabled={Object.keys(reportData.report).length === 0} onClick={() => handleEditReport()}>Edit</button>
          {/* <button type="button" className="btn btn-primary">Actions</button> */}
        </div>

        <div>
          <DropDownButton
            text="Export"
            icon="fas fa fa-caret-down"
            buttonClass={"btn btn-secondary rounded-left-0 d-flex flex-row-reverse px-2"}
            items={updatedItems}
            disabled={Object.keys(reportData.report).length === 0}
            onItemClick={(e) => exportFunc(e)}
            itemRender={actionItemRender}
            popupSettings={{ popupClass: 'export-dropdown' }}
          />
        </div>
      </div>
    </Fragment>

  )
}