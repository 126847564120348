import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import type { RootState } from '../../../../../store/reducers/rootReducer';
import { ErrorMessage } from '../../../../shared/ErrorMessage/ErrorMessage';
import type { EmailProvider } from '../../../../../interfaces/emailProviderInterfaces/emailProviderInterfaces';
import { fetchDelete as deleteEmailProvider } from '../../../../../services/services';

interface Props {
  handleClose: () => void
  emailProvider: EmailProvider | null
}

export const DeleteEmailProviderModal = ({ handleClose, emailProvider }: Props) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [emailProviderName, setEmailProviderName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onDeleteHandler = () => {
    if (emailProvider) {
      setIsLoading(true);
      deleteEmailProvider(`emailproviders/${emailProvider.id}`, token)
        .then((res: TODO) => {
          setIsLoading(false);
          if (res.status === 204) {
            handleClose()
            dispatch({ type: 'UPDATE_EMAIL_PROVIDERS', payload: true })
          } else {
            setErrorMessage('Something went wrong, please try again!')
          }
        })
    }
  }

  return (
    <Dialog
      title={`Delete "${emailProvider?.name}"`}
      onClose={handleClose}>
      <div className="p-4">
        <p>
          Deleting an email provider will remove everything related to the email provider, including SMTP settings. <br />
          Confirm the deletion by typing the email provider <strong>name</strong> in the input field below.
        </p>
        <Input
          className="w-100"
          value={emailProviderName}
          onChange={(e: TODO) => setEmailProviderName(e.target.value)}
        />
        {
          errorMessage &&
          <div className="mt-4">
            <ErrorMessage
              type="alert"
              errorMessage={errorMessage} />
          </div>
        }
      </div>

      <DialogActionsBar>
        <button
          type='button'
          className="k-button btn btn-secondary"
          onClick={handleClose}>Cancel
        </button>
        <button
          type='button'
          className="k-button btn-danger"
          disabled={emailProviderName !== emailProvider?.name || isLoading}
          onClick={onDeleteHandler}>Delete
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}