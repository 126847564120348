import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, type InputChangeEvent } from "@progress/kendo-react-inputs";
import { extendDataItem, mapTree, TreeList, type TreeListPageChangeEvent, type TreeListExpandChangeEvent } from "@progress/kendo-react-treelist";

import { TreeListPager } from "./TreeListPager/TreeListPager";
import type { RootState } from "../../../../store/reducers/rootReducer";
import type { AuditLog } from "../../../../interfaces/adminInterfaces/adminInterfaces";
import { FullHeightSpinner } from "../../../shared/FullHeightSpinner/FullHeightSpinner";
import { returnAuditLogsNestedList } from "../../../shared/helpers/returnAuditLogsNestedList/returnAuditLogsNestedList";
import { returnTreeListColumnSettings } from "../../../shared/helpers/returnTreeListColumnSettings/returnTreeListColumnSettings";
import { fetchGetJsonOrEmptyArray as getAuditLogs } from "../../../../services/services";

export const AuditLogsTabContent = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [auditLogs, setAuditLogs] = useState<AuditLog[] | null>(null)
  const [originalAuditLogs, setOriginalAuditLogs] = useState<AuditLog[] | null>(null);
  const [expanded, setExpanded] = useState<string[]>([])
  const [pagination, setPagination] = useState({
    skip: 0,
    take: 12
  })

  useEffect(() => {
    if (auditLogs === null) {
      getAuditLogs("audit-logs", token)
        .then((res: TODO) => {
          if (res && (res.message || res.error)) {
            dispatch({ type: "UPDATE_ERROR_MESSAGE", payload: { msg: res.message ? res.message : res.error, type: "modal" } });
          } else {
            setAuditLogs(returnAuditLogsNestedList(res))
            setOriginalAuditLogs(returnAuditLogsNestedList(res))
          }
        })
    }
  }, [auditLogs, token, dispatch])

  const onSearchData = (e: InputChangeEvent) => {
    if (e.value) {
      const searchedData = originalAuditLogs?.filter((item: TODO) => {
        if (
          (item.action?.toLowerCase().includes(e.value.toLowerCase())) ||
          (item.name?.toLowerCase().includes(e.value.toLowerCase())) ||
          (item.type?.toLowerCase().includes(e.value.toLowerCase())) ||
          (item.user.name?.toLowerCase().includes(e.value.toLowerCase())) ||
          (item.startDate && (new Date(item.startDate).toLocaleString()).toLowerCase().includes(e.value.toLowerCase()))
        )
          return item;
      });
      if (searchedData) {
        setAuditLogs(searchedData);
      }
    } else {
      setAuditLogs(originalAuditLogs);
    }
  }

  const onExpandChange = (e: TreeListExpandChangeEvent) => {
    setExpanded(e.value ? expanded.filter((itemId) => itemId !== e.dataItem.itemId) : [...expanded, e.dataItem.itemId])
  }

  const updateFields = (auditLogs: AuditLog[]) => {
    const tree = auditLogs;
    return mapTree(tree, "items", item => extendDataItem(item, "items", {
      expanded: expanded.includes(item.itemId)
    }))
  }

  const actionCell = (props: TODO) => {
    const action = props.dataItem.action

    let newAction: string = action;
    if (action === "share.invite.accept") {
      newAction = action.replaceAll(".", " > ")
    }

    const badgeColor = () => {
      if (newAction && newAction === 'create') return "bg-success";
      if (newAction && newAction === 'delete') return "bg-danger";
      if (newAction && newAction === 'update') return "bg-info"
      return "bg-primary"
    }

    return (
      <td>
        {newAction ?
          <span className={`badge ${badgeColor()} text-white align-self-start strong text-capitalize`}>{newAction}</span>
          : null}
      </td>
    )
  }

  const dateCell = (props: TODO) => {
    const item = props.dataItem;
    return (
      <td>
        {item.action ?
          <span>{new Date(item.startDate).toLocaleString()}</span>
          : null
        }
      </td>
    )
  }

  const onPageChange = (event: TreeListPageChangeEvent) => {
    const { skip, take } = event;
    setPagination({ ...pagination, skip, take });
  };

  return (
    <div className="d-flex flex-column p-3 h-100">
      <div className="d-flex gap-sm py-3">
        <Input onChange={(e) => onSearchData(e)} type="text" placeholder="Search" className="w-100" />
        <button type="button" className="btn btn-primary d-flex align-items-center">
          <i className="fa fa-search" />
        </button>
      </div>
      {auditLogs ? (
        auditLogs.length > 0 ? (
          <TreeList
            data={updateFields(auditLogs)}
            subItemsField="items"
            onExpandChange={onExpandChange}
            onPageChange={onPageChange}
            skip={pagination.skip}
            take={pagination.take}
            className="audit-logs-list"
            columns={returnTreeListColumnSettings(dateCell, actionCell)}
            expandField="expanded"
            pager={TreeListPager}
          />
        ) : (
          <div className="d-flex flex-column gap-lg text-disabled text-center h5 pt-10 w-100">
            <i className="fal fa-2x fa-folder-open" />
            <p className='m-0'>No logs have been found</p>
          </div>
        )
      ) : (
        <FullHeightSpinner />
      )}
    </div >
  );
};
