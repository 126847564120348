import React from 'react';
import OptionsTabContent from './OptionsTabContent/OptionsTabContent';
import OverviewTabContent from './OverviewTabContent/OverviewTabContent';
import ComposeSendTabContent from './ComposeSendTabContent/ComposeSendTabContent';


export default ({ tabType, token, campaign, updateCampaign, onComposeClick, updateCampaignState, onDeleteInEditMode }) => {
  if (tabType === 'overview') {
    return (
      <OverviewTabContent
        token={token}
        campaign={campaign}
        updateCampaign={updateCampaign}
        onDeleteInEditMode={onDeleteInEditMode}
        onComposeClick={onComposeClick}
        updateCampaignState={updateCampaignState}
      />
    )
  } else if (tabType === 'composeSend') {
    return (
      <ComposeSendTabContent
        token={token}
        campaign={campaign}
        updateCampaign={updateCampaign}
        updateCampaignState={updateCampaignState}
      />
    )
  } else if (tabType === 'options') {
    return (
      <OptionsTabContent
        token={token}
        campaign={campaign}
        updateCampaign={updateCampaign}
      />
    )
  } else {
    return (
      <div></div>
    )
  }
}