import { useSelector } from 'react-redux';
import { Fragment, type SetStateAction } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import type { RootState } from '../../../../../../../../store/reducers/rootReducer';
import { QuestionEditorListOfCompatibleQuestions } from './QuestionEditorListOfCompatibleQuestions/QuestionEditorListOfCompatibleQuestions';

type Question = {
  id: string
  title: string
}

type ShowPasteEditsModal = {
  show: boolean,
  compatibleQuestions: Question[],
  compatibility: 'oneQuestionNonActive' | 'multipleQuestionsIncludingActive' | 'multipleQuestionsExcludingActive'
}

interface Props {
  showPasteEditsModal: ShowPasteEditsModal
  selectedQuestion: Question
  handleClose: () => void
  tabTitleHandler: (question: Question) => string
  setQuestionsState: (param: SetStateAction<{ groups: unknown[], subGroups: unknown[], id: string }[]>) => void
}

export const QuestionEditorPasteEditsModal = ({ showPasteEditsModal, selectedQuestion, handleClose, tabTitleHandler, setQuestionsState }: Props) => {
  const { compatibility, compatibleQuestions } = showPasteEditsModal;
  const { crossTabQuestionEditorCopyData } = useSelector((theState: RootState) => (theState.setInitialDataReducer));

  const onPasteToActiveHandler = () => {
    setQuestionsState((prevQuestionsState) => {
      const updatedQuestionsState = [...prevQuestionsState];
      const updatedSelectedQuestion = updatedQuestionsState.find((question) => question.id === selectedQuestion.id);
      if (updatedSelectedQuestion) {
        updatedSelectedQuestion.groups = crossTabQuestionEditorCopyData.groups;
        if (updatedSelectedQuestion.subGroups) {
          updatedSelectedQuestion.subGroups = crossTabQuestionEditorCopyData.subGroups;
        }
      }
      return updatedQuestionsState;
    })
    handleClose();
  }

  const onPasteToAllHandler = () => {
    setQuestionsState((prevQuestionsState) => {
      const updatedQuestionsState = [...prevQuestionsState];
      for (const updatedQuestion of updatedQuestionsState) {
        for (const compatibleQuestion of compatibleQuestions) {
          if (updatedQuestion.id === compatibleQuestion.id) {
            updatedQuestion.groups = crossTabQuestionEditorCopyData.groups;
            if (updatedQuestion.subGroups) {
              updatedQuestion.subGroups = crossTabQuestionEditorCopyData.subGroups;
            }
          }
        }
      }
      return updatedQuestionsState;
    })
    handleClose();
  }

  return (
    <Dialog
      width={550}
      className="question-editor"
      onClose={handleClose}
      title={"Paste edits"}>
      <div className="p-3 d-flex flex-column gap-lg">
        {
          compatibility === 'multipleQuestionsIncludingActive' ?
            <Fragment>
              <p className='m-0'>You can paste the copied edits to more than one question:</p>
              <QuestionEditorListOfCompatibleQuestions
                compatibleQuestions={compatibleQuestions}
                selectedQuestion={selectedQuestion}
                tabTitleHandler={tabTitleHandler}
              />
            </Fragment>
            :
            compatibility === 'multipleQuestionsExcludingActive' || compatibility === 'oneQuestionNonActive' ?
              <Fragment>
                <div className="alert alert-warning m-0">The copied edits are not compatible with the active question, you can paste the edits to the following selected questions instead:</div>
                <QuestionEditorListOfCompatibleQuestions
                  compatibleQuestions={compatibleQuestions}
                  selectedQuestion={selectedQuestion}
                  tabTitleHandler={tabTitleHandler}
                />
              </Fragment>
              :
              null
        }
      </div>
      <DialogActionsBar>
        <button type='button' className="k-button btn-secondary" onClick={handleClose}>
          Cancel
        </button>
        <button
          type='button'
          onClick={onPasteToActiveHandler}
          className="k-button btn-analyze"
          disabled={compatibility === 'multipleQuestionsExcludingActive' || compatibility === 'oneQuestionNonActive'}>
          Paste to active
        </button>
        <button type='button' className="k-button btn-analyze" onClick={onPasteToAllHandler}>
          Paste to all
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}