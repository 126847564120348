import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ShareProjectUsersTabContent } from './ShareProjectUsersTabContent/ShareProjectUsersTabContent';
import { ShareProjectAccessTabContent } from './ShareProjectAccessTabContent/ShareProjectAccessTabContent';
import { fetchGetJson } from '../../../../../services/services';

const tabs = ["Sub accounts", "Regular accounts"]

export const ShareProjectUsers = ({ shareDetails, reloadProject }) => {
  const { token } = useSelector((state) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const { subAccounts, usersTabSelected, invalidSubAccountUsers, invalidRegularUsers } = useSelector((theState) => theState.projectExternalShareStateReducer);

  useEffect(() => {
    if (subAccounts.length === 0) {
      fetchGetJson("sub-accounts", token)
        .then(res => {
          if (res && !res.error) {
            dispatch({ type: 'SET_SHARE_PROJECT_SUB_ACCOUNTS', payload: res })
          }
        })
    }
  }, [dispatch, token, subAccounts])

  const ShareUserErrorIcon = ({ tab, id }) => {
    if (tab === "Sub accounts" && invalidSubAccountUsers.length > 0) {
      return (
        <i className={`fas fa-exclamation-circle ${usersTabSelected === id ? 'text-white' : 'text-danger'} ml-2`} />
      )
    }
    if (tab === 'Regular accounts' && invalidRegularUsers.length > 0) {
      return (
        <i className={`fas fa-exclamation-circle ${usersTabSelected === id ? 'text-white' : 'text-danger'} ml-2`} />
      )
    }
    return null
  }

  return (
    <div className="px-5 py-3">
      <div className="d-flex justify-content-center">
        <div className="list-group list-group-horizontal w-100">
          {
            tabs.map((tab, key) => (
              <button
                key={key}
                type="button"
                style={{ outline: 'none' }}
                onClick={() => dispatch({ type: 'UPDATE_USERS_TAB_SELECTED', payload: key })}
                className={`list-group-item list-group-item-action text-center ${usersTabSelected === key ? 'active' : ''}`}>
                <span>{tab}</span>
                <ShareUserErrorIcon tab={tab} id={key} />
              </button>
            ))
          }
        </div>
      </div>

      {
        usersTabSelected === 0 ?
          <ShareProjectUsersTabContent
            shareDetails={shareDetails}
            reloadProject={reloadProject}
          />
          :
          <ShareProjectAccessTabContent
            shareDetails={shareDetails}
          />
      }
    </div>
  )
}