import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import type { RootState } from '../../../../../../../store/reducers/rootReducer';
import { FullHeightSpinner } from '../../../../../FullHeightSpinner/FullHeightSpinner';
import { fetchGetJson as getSubAccountUsers } from '../../../../../../../services/services';

interface Props {
  subAccount: TODO
  shareDetails: TODO
  onHide: () => void
  reloadProject: () => void
}

export const ShareProjectSubAccountUsersModal = ({ onHide, subAccount, shareDetails }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const [didMount, setDidMount] = useState<boolean>(false)
  const { subAccounts, invalidSubAccountUsers } = useSelector((theState: RootState) => theState.projectExternalShareStateReducer);

  useEffect(() => {
    let newData = []
    if (!subAccount.users && !didMount) {
      setDidMount(true)
      getSubAccountUsers(`sub-accounts/${subAccount.id}/users`, token)
        .then((res: TODO) => {
          if (res && !res.error) {
            newData = subAccounts.map((item: TODO) => {
              if (item.id === subAccount.id) {
                item.users = res
              }
              return item;
            });
            dispatch({ type: 'SET_SHARE_PROJECT_SUB_ACCOUNTS', payload: newData })
          }
        })
    }
  }, [token, dispatch, subAccounts, subAccount, didMount])

  const onSelectAllHandler = (e: TODO, subAccount: TODO) => {
    const newData = subAccounts.map((item: TODO) => {
      if (item.id === subAccount.id) {
        const newUsers = subAccount.users.map((user: TODO) => {
          user.selected = e.value
          return user
        })
        item.users = newUsers;
      }
      return item;
    });
    dispatch({ type: 'SET_SHARE_PROJECT_SUB_ACCOUNTS', payload: newData })
  }

  const onSelectUserHandler = (e: TODO, subAccount: TODO, selectedUser: TODO) => {
    const newData = subAccounts.map((item: TODO) => {
      if (item.id === subAccount.id) {
        const newUsers = subAccount.users.map((user: TODO) => {
          if (user.id === selectedUser.id) {
            user.selected = e.value
          }
          return user
        })
        item.users = newUsers;
      }
      return item;
    });
    dispatch({ type: 'SET_SHARE_PROJECT_SUB_ACCOUNTS', payload: newData })
  }

  return (
    <Dialog
      title={"Add users"}
      minWidth={600}
      height={520}
      onClose={onHide}>
      <div className="p-2 h-100">
        {
          !subAccount.users ?
            <FullHeightSpinner />
            :
            subAccount.users?.length > 0 ?
              <table className="table table-striped table-bordered m-0">
                <thead className="text-center">
                  <tr>
                    <th style={{ width: '10%' }}>
                      <Checkbox
                        defaultChecked={false}
                        onChange={(e) => onSelectAllHandler(e, subAccount)}
                        checked={subAccount.users && subAccount.users.filter((user: TODO) => user.selected).length === subAccount.users.length}
                      />
                    </th>
                    <th className="w-25">Name</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {
                    subAccount.users.map((user: TODO, key: number) => (
                      <tr key={key}>
                        <th style={{ verticalAlign: 'middle' }}>
                          {
                            !shareDetails.shareUsers.filter((shareUser: TODO) => shareUser.isSubAccountUser).find((saUser: TODO) => saUser.email === user.email) ?
                              <Checkbox
                                checked={subAccount.users.find((el: TODO) => el.id === user.id).selected}
                                onChange={(e) => onSelectUserHandler(e, subAccount, user)}
                                defaultChecked={false} />
                              :
                              <i className="fas fa-user-check" />
                          }
                        </th>
                        <td style={{ verticalAlign: 'middle' }}>{user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : ''}</td>
                        <td style={{ verticalAlign: 'middle' }} title={user.email}>
                          <span>{user.email}</span>
                          {
                            invalidSubAccountUsers.length > 0 && invalidSubAccountUsers.find((invalidUser: { id: string }) => invalidUser.id === user.email) &&
                            <i className="fas fa-exclamation-circle text-danger ml-3" />
                          }
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
              :
              <p className="text-center m-0 h5 text-muted">No users found</p>
        }
      </div>

      <DialogActionsBar>
        <button
          type='button'
          className="k-button btn btn-secondary"
          onClick={onHide}>Close
        </button>
      </DialogActionsBar>
    </Dialog >
  )
}