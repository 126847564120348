import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Input } from '@progress/kendo-react-inputs';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { ErrorMessage } from '../../../../shared/ErrorMessage/ErrorMessage';
import { returnSelectedElementData, returnSurveyDesignData, returnValidationErrors } from '../../SurveyDesignTabContent/helpers/returnDataHelpers/returnDataHelpers';
import { fetchGetJson as getSurveyIndex, fetchPostJson as publishSurvey } from '../../../../../services/services';

export default function PublishModal({ handleClose, setErrorModal }) {
  const params = useParams()
  const { token } = useSelector((state) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const { theData } = useSelector(theState => (theState.surveyInitialDataReducer))
  const [errorMessage, setErrorMessage] = useState(null)
  const [publishComment, setPublishComment] = useState('')
  const [isValidating, setIsValidating] = useState({ loading: false, text: 'Validating', icon: "fas fa-spinner" });
  const [isPublishing, setIsPublishing] = useState(false);

  const projectId = params.name
  const surveyId = params.survey

  const onPublishSurvey = (comment) => {
    setIsValidating({ ...isValidating, loading: true });
    setIsPublishing(true);
    const body = { comment: comment }
    publishSurvey(`su/projects/${projectId}/surveys/${surveyId}/publish`, token, body)
      .then(res => {
        if (res && (res.error || res.message)) {
          setIsPublishing(false);
          setErrorMessage(res.message ? res.message : res.error)
        }
        else {
          handleClose()
          if (res) {
            if (res.isValid) {
              getSurveyIndex(`su/projects/${projectId}/surveys/${surveyId}/index`, token)
                .then(res => {
                  const originalData = res
                  const updatedData = returnSurveyDesignData(res)
                  dispatch({ type: 'UPDATE_SURVEY_DATA', payload: { data: updatedData, originalData: originalData } })
                  dispatch({ type: 'SURVEY_DATA_SELECT_ELEMENT', payload: { data: updatedData, item: null, originalItem: null, elementOptions: null } })
                })
              dispatch({ type: 'SET_SURVEY_PUBLISH_DATA', payload: { surveyStatus: null, versions: null, dcV2: theData.dcV2 } })
              dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The latest version of your survey has been published' } })
            } else {
              const errors = returnValidationErrors(res)
              setErrorModal(true)
              dispatch({
                type: 'UPDATE_SURVEY_VALIDATION_ERRORS',
                payload: {
                  errors: errors,
                  originalItem: theData.originalSelectedItem ? theData.originalSelectedItem : null,
                  selectedItem: theData.selectedItem ? returnSelectedElementData(theData.originalSelectedItem, theData.data.find(el => el.index === theData.selectedItem.section).elements.find(el => el.selected === true), errors, theData.data) : null,
                  data: returnSurveyDesignData(theData.originalData, theData.selectedItem ? theData.selectedItem.id : null, errors)
                }
              });
              dispatch({ type: 'TOGGLE_DISABLED_QUESTIONS', payload: false });
            }
          }
        }
        setIsValidating({ ...isValidating, loading: false })
      })
  }

  return (
    <Dialog title="Publish survey" onClose={handleClose} width={500}>
      <div className="d-flex flex-column p-4">
        <span className='mb-1'>Enter comment:</span>
        <Input className="w-100 mt-0" onChange={(e) => setPublishComment(e.target.value)} />
        {
          errorMessage &&
          <div className="mt-3">
            <ErrorMessage
              type="alert"
              errorMessage={errorMessage}
              onHide={() => setErrorMessage(null)} />
          </div>
        }
      </div>
      <DialogActionsBar>
        <Button className="btn btn-secondary" onClick={handleClose}>Cancel</Button>
        <Button disabled={isPublishing} className="btn btn-primary d-flex align-items-center gap-sm" onClick={() => onPublishSurvey(publishComment)}>
          {
            isPublishing &&
            <div className="spinner-border spinner-border-sm mr-2" />
          }
          Publish
        </Button>
      </DialogActionsBar>
    </Dialog>
  )
}