import React from 'react';
import { useSelector } from 'react-redux';

import { InProgressOverlay } from '../../../../../../shared/InProgressOverlay/InProgressOverlay';
import { ValidationErrorMessage } from '../../../helpers/ValidationErrorMesage/ValidationErrorMessage';
import { Icon } from '../../../../../../shared/Icon/Icon';

export const EditTabGotoElementContent = ({ activeRef }) => {
  const { theData } = useSelector((theState) => (theState.surveyInitialDataReducer))

  return (
    <React.Fragment>
      <div className="card w-100 h-100">
        {theData.loadingMessage && theData.loadingState &&
          <InProgressOverlay type="overlay" theme="primary" message={theData.loadingMessage} />
        }
        <div className="card-header d-flex flex-column px-3 h-48 rounded-0">
          <div className="d-flex justify-content-between h-100">
            <div className="d-flex survey-subquestion align-items-center">
              {theData.optionsType && theData.optionsType.type === 'goto' && theData.displayOptions === true ?
                <div ref={activeRef} className="edit-dot pulse mr-2" /> : null}
            </div>
          </div>
        </div>
        <div className="card-body d-flex flex-column h-100 overflow-auto p-0">
          <div className="h-100 w-100 d-flex flex-column p-4" style={{ backgroundColor: "rgb(243, 244, 244)" }}>
            {theData.selectedItem.errorMessage !== "" &&
              <ValidationErrorMessage errorMessage={theData.selectedItem.errorMessage} />}
            <div className="card-body d-flex flex-column h-100 bg-white align-items-center justify-content-center answer-layout">
              <Icon type="goto" className='pe-none fill-primary select-placeholder' />
              <h1 className="droppable-text strong mt-2 h4">Edit Goto element in Options</h1>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

