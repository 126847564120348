import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TreeList, orderBy, filterBy, mapTree, extendDataItem, TreeListTextFilter } from '@progress/kendo-react-treelist';

import { Icon } from '../../Icon/Icon';
import type { RootState } from '../../../../store/reducers/rootReducer';
import { InProgressOverlay } from '../../InProgressOverlay/InProgressOverlay';
import { FileManagerNewFolder } from '../FileManagerNewFolder/FileManagerNewFolder';
import { FileManagerActionButton } from '../FileManagerActionButton/FileManagerActionButton';
import { FileManagerExpandedScreen } from '../FileManagerExpandedScreen/FileManagerExpandedScreen';
import { FileManagerDeleteDirectoryModal } from '../FileManagerDeleteDirectoryModal/FileManagerDeleteDirectoryModal';
import { FileManagerDeleteConfirmationModal } from '../FileManagerDeleteConfirmationModal/FileManagerDeleteConfirmationModal';
import { selectFileManagerFile } from '../../helpers/selectFileManagerFile/selectFileManagerFile';
import handleFileManagerUpload from '../../helpers/handleFileManagerUpload/handleFileManagerUpload';
import fileManagerDownloadHandler from '../../helpers/fileManagerDownloadHandler/fileManagerDownloadHandler';
import { returnUpdatedActionButtons } from '../../helpers/returnUpdatedActionButtons/returnUpdatedActionButtons';
import { fileManagerDownloadFile, getSpecificDirectoryListing } from '../../../../services/fileManagerServices/fileManagerServices';
import type { FileManagerActionButton as FileManagerActionButtonType, FileManagerItem, FileManagerState } from '../../../../interfaces/fileManagerInterfaces/fileManagerInterfaces';
import { updateActionButtons, updateCheckedFiles, updateDeleteRootDirectoryModal, updateFileManagerExpandedState, updateFileManagerState, updateIsLoading, updateShowDeleteConfirmationModal, updateShowNewFolderModal } from '../../../../store/reducers/fileManagerStateReducer/fileManagerStateReducer';
import { fetchGetJson as getDirectoryListing } from '../../../../services/services';

const subItemsField = 'contents';
const columns = [{ field: 'displayName', title: 'Name', width: 300, filter: TreeListTextFilter, expandable: true }];

interface Props {
  type: string
  token: string
  displayType?: string
  updateData: () => void
}

export const FileManagerContent = ({ type, token, updateData }: Props) => {
  const dispatch = useDispatch()
  const [showCreateRootDirectoryModal, setShowCreateRooteDirectoryModal] = useState<boolean>(false)
  const [uploadValue, setUploadValue] = useState('')
  const { state, expandedState, checkedFiles, actionButtons, isLoading, showNewFolderModal, showDeleteConfirmationModal, deleteRootDirectoryModal } = useSelector((state: RootState) => state.fileManagerStateReducer);

  const onExpandChange = (e: { syntheticEvent: TODO, value: boolean, dataItem: FileManagerItem }, stateType: string) => {
    if (stateType === 'state') {
      dispatch(updateFileManagerState({
        ...state,
        expanded: e.value ?
          state.expanded.filter(id => id !== e.dataItem.id) :
          [...state.expanded, e.dataItem.id]
      }))
    } else {
      dispatch(updateFileManagerExpandedState({
        ...expandedState,
        expanded: e.value ?
          expandedState.expanded.filter(id => id !== e.dataItem.id) :
          [...expandedState.expanded, e.dataItem.id]
      }))
    }
  }

  const handleDataStateChange = (event: TODO, stateType: string) => {
    if (stateType === 'state') {
      dispatch(updateFileManagerState({ ...state, dataState: event.dataState }))
    } else {
      dispatch(updateFileManagerExpandedState({ ...expandedState, dataState: event.dataState }))
    }
  }

  const addExpandField = (dataTree: FileManagerItem[], stateType: string) => {
    const expanded = stateType === 'state' ? state.expanded : expandedState.expanded;
    return mapTree(dataTree, subItemsField, (item) =>
      extendDataItem(item, subItemsField, {
        expanded: expanded.includes(item.id)
      })
    );
  }

  const processData = (data: FileManagerItem[], stateType: string) => {
    const { expanded, selected, dataState } = stateType === 'state' ? state : expandedState;
    const filteredData = filterBy(data, dataState.filter, subItemsField)
    const sortedData = orderBy(filteredData, dataState.sort, subItemsField)
    const updateData = mapTree(sortedData, subItemsField, (item) =>
      extendDataItem(item, subItemsField, {
        expanded: expanded.includes(item.id),
        selected: selected ? selected.id === item.id : null
      }))
    return addExpandField(updateData, stateType)
  }

  const selectRow = (e: TODO, stateType: string) => {
    const updatedExpandedState: FileManagerState = JSON.parse(JSON.stringify(expandedState))
    const selected = e.dataItem

    if (stateType === 'state') {
      const updatedActionButtons = returnUpdatedActionButtons([...actionButtons], stateType, checkedFiles)
      dispatch(updateActionButtons(updatedActionButtons))
      dispatch(updateFileManagerState({ ...state, selected: selected }))
      dispatch(updateFileManagerExpandedState({ ...expandedState, data: [e.dataItem] }))
    } else {
      const item = e.dataItem
      const updatedCheckedFiles = selectFileManagerFile(item, checkedFiles)
      if (updatedExpandedState.data?.[0]?.contents) {
        const updatedActionButtons = returnUpdatedActionButtons([...actionButtons], stateType, updatedCheckedFiles)
        dispatch(updateActionButtons(updatedActionButtons))
        dispatch(updateCheckedFiles(updatedCheckedFiles))
        dispatch(updateFileManagerExpandedState({ ...expandedState, selected: selected }))
      }
    }
  }

  const uploadUpdateData = () => {
    getDirectoryListing(`files/${type}`, token)
      .then((res: TODO) => {
        if (res.contents) {
          dispatch(updateFileManagerState({ ...state, data: res.contents, expanded: [...state.expanded, state.selected ? state.selected.id : null] }))
          getSpecificDirectoryListing(`files/${type}`, token, expandedState.data?.[0] ? expandedState.data[0].id : state.selected ? state.selected.id : '')
            .then((res: TODO) => {
              dispatch(updateIsLoading(false))
              if (res) {
                if (state.selected?.id) {
                  dispatch(updateFileManagerExpandedState({ ...expandedState, data: [res] }))
                } else {
                  dispatch(updateFileManagerExpandedState({ ...expandedState }))
                }
              }
            })
        }
      })
  }

  const onDragOverHandler = (e: TODO) => {
    e.preventDefault()
  }

  const handleDrop = (e: TODO) => {
    e.preventDefault()
    dispatch(updateIsLoading(true))
    handleFileManagerUpload(uploadUpdateData, expandedState, state, token, { target: { files: e.dataTransfer.files } }, type, dispatch)
  }

  const onActionHandler = async (e: TODO, item: FileManagerActionButtonType) => {
    if (item.text === 'New folder') {
      dispatch(updateShowNewFolderModal(true))
    } else if (item.text === 'Refresh') {
      dispatch(updateIsLoading(true))
      updateData()
    } else if (item.text === 'Download') {
      const selectedFile = checkedFiles.find(item => !item.isDirectory)
      if (selectedFile) {
        fileManagerDownloadFile(`files/download/${type}`, token, selectedFile.id)
          .then((res: TODO) => {
            if (res) {
              if (res.error || res.message) {
                dispatch({ type: 'UPDATE_ERROR_MESSAGE', payload: { msg: res.message ? res.message : res.error, type: 'modal' } })
              } else {
                fileManagerDownloadHandler(res, selectedFile)
              }
            }
          })
      }
    } else if (item.text === 'Upload') {
      setUploadValue('')
      dispatch(updateIsLoading(true))
      handleFileManagerUpload(uploadUpdateData, expandedState, state, token, e, type, dispatch)
    } else if (item.text === 'Delete') {
      dispatch(updateShowDeleteConfirmationModal(true))
    }
  }

  const showDeleteRootDirectoryModal = (e: TODO, directory: { displayName: string, directoryReference: string }) => {
    e.stopPropagation()
    if (directory?.displayName && directory?.directoryReference) {
      dispatch(updateDeleteRootDirectoryModal({
        show: true, directory: {
          displayName: directory.displayName,
          directoryReference: directory.directoryReference
        }
      }))
    }
  }

  const customDirectoryCell = (cell: TODO, props: TODO) => {
    if (props.expandable && props.dataItem.isDirectory) {
      return (
        <cell.type {...cell.props}>
          {/* <span className='text-truncate w-100'> */}
          {cell.props.children.map((item: TODO) => item)}
          {/* </span> */}

          <button type='button' className='btn btn-transparent p-05 delete' title='Delete folder' onClick={(e) => showDeleteRootDirectoryModal(e, props.dataItem)}>
            <Icon type="delete-alt" className='pe-none' />
          </button>
        </cell.type>
      );
    }
    return null;
  }

  return (
    <Fragment>
      {
        deleteRootDirectoryModal.show &&
        <FileManagerDeleteDirectoryModal
          type={type}
          token={token}
          updateData={updateData} />
      }
      {
        showDeleteConfirmationModal &&
        <FileManagerDeleteConfirmationModal
          type={type}
          token={token}
          updateData={updateData} />
      }
      {
        showNewFolderModal || showCreateRootDirectoryModal ?
          <FileManagerNewFolder
            type={type}
            token={token}
            selectedItem={state.selected}
            selectedExpandedItem={expandedState.data[0]}
            showCreateRootDirectoryModal={showCreateRootDirectoryModal}
            updateData={updateData}
            setShowCreateRooteDirectoryModal={(val) => setShowCreateRooteDirectoryModal(val)} />
          : null
      }
      <div className='d-flex flex-grow-1 px-6 toolbar'>
        <button type='button' className="btn btn-icon icon-l btn-primary mr-2"
          onClick={(e) => {
            e.stopPropagation()
            setShowCreateRooteDirectoryModal(true)
          }}>
          <Icon type="add" />
          <span>New folder</span>
        </button>
        {
          actionButtons.map((item, key) => (
            <Fragment key={key}>
              <FileManagerActionButton
                item={item}
                uploadValue={uploadValue}
                onActionHandler={onActionHandler} />
            </Fragment>
          ))
        }
      </div>
      <div className="d-flex w-100 h-100">
        <div className="nav-board d-flex flex-grow-1 bg-white">
          {
            isLoading &&
            <InProgressOverlay
              message=""
              className=""
              type="overlay"
              theme="primary" />
          }
          <div className="sidebar p-0 h-100 border-right bg-white">
            <h2 className='pt-5 px-4 pb-3 lh-24 m-0 h6'>
              Folders
            </h2>
            <TreeList
              {...state.dataState}
              className="h-100"
              columns={columns}
              sortable={false}
              expandField={'expanded'}
              selectedField={'selected'}
              selectable={{
                enabled: true,
              }}
              subItemsField={subItemsField}
              onRowClick={(e) => selectRow(e, 'state')}
              data={processData(state.data, 'state')}
              onExpandChange={(e) => onExpandChange(e, 'state')}
              onDataStateChange={(e) => handleDataStateChange(e, 'state')}
              cellRender={customDirectoryCell}
              toolbar={undefined}
            />
          </div>
          <div
            onDrop={handleDrop}
            onDragOver={onDragOverHandler}
            className="d-flex flex-column h-100 flex-grow-1 position-relative">
            <FileManagerExpandedScreen
              expandedState={expandedState}
              selectRow={(item, stateType) => selectRow(item, stateType)} />
          </div>
        </div>
      </div>
    </Fragment >
  )
}