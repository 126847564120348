import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from '../../../../shared/Icon/Icon';
import { Avatar } from '../../../../shared/Avatar/Avatar';
import { EditUserModal } from './EditUserModal/EditUserModal';
import { formatDate } from '../../../../shared/DateUtils/DateUtils';
import { DeleteUserModal } from './DeleteUserModal/DeleteUserModal';
import type { RootState } from '../../../../../store/reducers/rootReducer';
import { ErrorMessage } from '../../../../shared/ErrorMessage/ErrorMessage';
import type { AdminUser } from '../../../../../interfaces/userInterface/userInterface';
import { renameUserTypeRoles } from '../../../../shared/helpers/renameUserTypeRoles/renameUserTypeRoles';
import { fetchDelete as deleteUser } from '../../../../../services/services';

type Props = {
  users: AdminUser[];
  updateUsers: () => void;
}

export const ActiveUsersTabContent = ({ users, updateUsers }: Props) => {
  const dispatch = useDispatch()
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [showEditUserPlan, setShowEditUserPlan] = useState<{ show: boolean, user: AdminUser | null }>({ show: false, user: null })
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<AdminUser | null>(null);
  const [errorMessage, setErrorMessage] = useState("");

  const showDeleteModalHandler = (user: AdminUser) => {
    setShowDeleteUserModal(true);
    setSelectedUser(user)
  }

  const onConfirmDeleteHandler = () => {
    if (selectedUser?.id) {
      deleteUser(`users/${selectedUser.id}`, token, false)
        .then((res: TODO) => {
          if (res && res.status === 200) {
            const updatedUsers = [...users].filter(el => el.id !== selectedUser.id);
            dispatch({ type: 'SET_USER_DATA', payload: updatedUsers });
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The user has been deleted successfully' } })
            setShowDeleteUserModal(false);
          } else return res.json();
        })
        .then((res: TODO) => {
          if (res.error || res.message) {
            setErrorMessage(res.error ? res.error : res.message)
          }
        })
    }
  }

  return (
    <Fragment>
      {showEditUserPlan.show &&
        <EditUserModal
          handleClose={() => setShowEditUserPlan({ show: false, user: null })}
          updateUsers={updateUsers}
          activeUser={showEditUserPlan.user} />
      }
      {showDeleteUserModal &&
        <DeleteUserModal
          user={selectedUser}
          handleClose={() => setShowDeleteUserModal(false)}
          onConfirmDelete={onConfirmDeleteHandler}>
          {
            errorMessage &&
            <div className="mx-3">
              <ErrorMessage
                type="alert"
                errorMessage={errorMessage}
                onHide={() => { setErrorMessage("") }} />
            </div>
          }
        </DeleteUserModal>
      }
      {
        users && users.length > 0 ?
          <table className="table table-striped admin-table mt-3">
            <thead>
              <tr>
                <th scope="col" />
                <th scope="col">
                  Name
                  <i className="fas fa-sm fa-arrow-up ml-3" />
                </th>
                <th scope="col">Email</th>
                <th scope="col">Date created</th>
                <th className='text-center' scope="col">User type</th>
                <th className='text-center' scope="col" />
              </tr>
            </thead>
            <tbody>
              {
                users.sort((a, b) => a.firstName.localeCompare(b.firstName)).map((user) => (
                  <tr key={user.id}>
                    <td className='admin-avatar-cell' style={{ width: '60px' }}>
                      <Avatar
                        color={"primary"}
                        class="avatar-sm text-white m-0"
                        icon={'fas fa-user text-white'}
                      />
                    </td>
                    <th scope='row'>{`${user.firstName} ${user.lastName}`}</th>
                    <td>{user.email}</td>
                    <td>{formatDate(user.createdUtc, "DATE_MED")}</td>
                    <td>
                      <div className="d-flex justify-content-center">
                        <span className={`badge badge-pill badge-primary ${user.role !== 'regular' ? 'text-walr' : ''} text-capitalize`}>{renameUserTypeRoles(user.role)}</span>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex justify-content-center gap-lg admin-table-actions">
                        <button onClick={() => setShowEditUserPlan({ show: true, user: user })} type="button" className="btn text-dark p-0">
                          <Icon className='pe-none' type='edit' />
                        </button>
                        <button onClick={() => showDeleteModalHandler(user)} type="button" className="btn text-dark p-0">
                          <Icon className='pe-none' type='delete-alt' />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          :
          <div className="d-flex flex-column gap-lg text-disabled text-center h5 pt-10 w-100">
            <i className="fal fa-2x fa-folder-open" />
            <p className='m-0'>No active users have been found</p>
          </div>
      }
    </Fragment>
  )
}