import React from 'react';
import { Slide, toast } from "react-toastify";

import { EtagErrorNotificationContent, PendingNotificationContent } from '../../../components/shared/ActionNotification/ActionNotificationContent/ActionNotificationContent';

const initialState = {
  notificationData: {
    message: '',
    show: false,
    showCloseBtn: false,
    extraData: undefined,
  }
};

function actionNotificationStateReducer(state = initialState, action) {
  switch (action.type) {
    case 'SHOW_ACTION_NOTIFICATION':
      toast.dismiss();
      toast.clearWaitingQueue();
      toast.info(action.payload.msg, {
        icon: false,
        transition: Slide,
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        limit: 1,
        progress: 0,
        theme: "colored",
        className: "toaster-margin bg-toastify",
      });
      return { ...state }
    case 'SHOW_ERROR_NOTIFICATION':
      toast.error(action.payload.msg, {
        icon: false,
        transition: Slide,
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        limit: 1,
        progress: 0,
        theme: "colored",
        className: "toaster-margin bg-danger"
      });
      return { ...state }
    case 'SHOW_ETAG_ERROR_NOTIFICATION':
      toast.info(<EtagErrorNotificationContent msg={action.payload.msg} />, {
        transition: Slide,
        position: "bottom-center",
        hideProgressBar: true,
        autoClose: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        limit: 1,
        progress: 0,
        theme: "colored",
        className: "toaster-margin bg-danger",
        toastId: "etagErrorNotification"
      });
      return { ...state }
    case 'SHOW_SUCCESS_NOTIFICATION':
      toast.success(action.payload.msg, {
        icon: false,
        transition: Slide,
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        limit: 1,
        progress: 0,
        theme: "colored",
        className: "toaster-margin bg-success"
      });
      return { ...state }
    case 'SHOW_PENDING_NOTIFICATION':
      // A permanent notification, with a spinner, that does not go away until clicked
      toast.info(<PendingNotificationContent msg={action.payload.msg} showSpinner={true} showCloseBtn={action.payload.showCloseBtn} />, {
        transition: Slide,
        position: "bottom-center",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        limit: 1,
        progress: 0,
        theme: "colored",
        className: "toaster-margin bg-toastify",
        toastId: "pendingNotification"
      });
      return { ...state }
    case 'UPDATE_PENDING_NOTIFICATION':
      // An update to the permanent notification
      toast.update("pendingNotification", {
        transition: Slide,
        position: "bottom-center",
        render: <PendingNotificationContent msg={action.payload.msg} showSpinner={false} showCloseBtn={action.payload.showCloseBtn} itemLink={action.payload.itemLink} />,
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        limit: 1,
        progress: 0,
        theme: "colored",
        className: "toaster-margin bg-toastify"
      });
      return { ...state }
    case 'CLOSE_PENDING_NOTIFICATION':
      // Closes the permanent notification
      toast.dismiss("pendingNotification");
      return { ...state }
    case 'CLOSE_ETAG_ERROR_NOTIFICATION':
      // Closes the etag notification
      toast.dismiss("etagErrorNotification");
      return { ...state }
    default:
      return state;
  }
}

export default actionNotificationStateReducer;


