
import { useEffect, useState } from 'react'
import { Switch } from '@progress/kendo-react-inputs'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip } from '@progress/kendo-react-tooltip'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'

import { Icon } from '../../shared/Icon/Icon'
import { ExclusionsForm } from './ExclusionsForm/ExclusionsForm'
import type { RootState } from '../../../store/reducers/rootReducer'
import type { CreateNewProjectProps, GatekeeperNewFormData, Market } from '../../../interfaces/gateKeeperInterfaces/gateKeeperInterfaces'
import { fetchPostJson as generateProject, fetchPatchJson as editProject, fetchGetJson as getProjectsByCountry, } from '../../../services/services'

export const GKCreateNewProject = ({
  onHide,
  formData,
  setFormData,
  showExclution,
  setShowExclution,
  gkProjectsToExclude,
  setGKProjectsToExclude,
  showCreateNewProject,
  setShowCreateNewProject,
  batchVariables,
  setBatchVariablesResponse,
  showReEntry,
  setShowReEntry,
  projectIds,
  setDidMount,
}: CreateNewProjectProps) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<TODO>({})
  const [searchMarket, setSearchMarket] = useState<string>("")
  const { markets }: { markets: Market[] } = useSelector((theState: RootState) => (theState.gatekeeperStateReducer));

  //GK PROJECT BY COUNTRY
  useEffect(() => {
    if (formData.countryCode !== "") {
      getProjectsByCountry(`co/gk/projects/country?countryCode=${formData.countryCode}`, token)
        .then((res: TODO) => {
          setSearchMarket("")
          if (res && !res.error && !res.message) {
            if (res.gatekeeperProjects) {
              setGKProjectsToExclude(res.gatekeeperProjects.filter((item: TODO) => item.name && item))
            } else {
              setGKProjectsToExclude([])
              setShowExclution(false)
            }
          } else {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
          }
        })
    }
  }, [formData.countryCode, token, dispatch, setGKProjectsToExclude, setShowExclution])

  // HANDLE NEW PROJECT FORM
  const onChangeHandler = (e: TODO) => {

    let inputValue: TODO
    let countryCodeValue: string | undefined
    const inputName = e.target.name
    const inputValueIfExclution = (inputName === "exclusionDurationDays" || inputName === "respondentStatuses" || inputName === "gatekeeperProjectIds") && e.target.value
    const inputNameIfExclution = (inputName === "exclusionDurationDays" || inputName === "respondentStatuses" || inputName === "gatekeeperProjectIds") && e.target.name
    if (inputName === "country") {
      inputValue = e.item
      countryCodeValue = e.item.countryCode
      if (showCreateNewProject.updateProject) {
        setShowExclution(false)
      }
    } else if (inputName === "loi" || inputName === "wave") {
      inputValue = Number(e.target.value)
    } else {
      inputValue = e.target.value
    }
    if (e.target.name === "allowReentry" && e.target.value) {
      setShowReEntry(true)
      setFormData({ ...formData, allowReentry: true })
    }

    setFormData({
      ...formData,
      [inputName]: inputValue,
      exclusion: showExclution ? { ...formData.exclusion, [inputNameIfExclution]: inputValueIfExclution } : null,
      countryCode: countryCodeValue ? countryCodeValue : ""
    })
  }

  // GENERATE NEW/UPDATE PROJECT
  const onGenerateHandler = (e: TODO) => {
    e.preventDefault()
    let body: TODO = {
      displayName: formData.displayName,
      countryCode: formData.country.countryCode,
      languageCode: formData.country.languageCode,
      wave: Number(formData.wave),
      loi: Number(formData.loi),
      surveyTestUrl: formData.surveyTestUrl,
      surveyLiveUrl: formData.surveyLiveUrl,
      useResearchDefender: formData.useResearchDefender,
      exclusion: null,
      redirectCaptureParameter: formData.redirectCaptureParameter ? formData.redirectCaptureParameter : "",
      allowReentry: formData.allowReentry
    }

    if (showExclution) {
      body.exclusion = {
        respondentStatuses: formData.exclusion.respondentStatuses,
        exclusionDurationDays: Number(formData.exclusion.exclusionDurationDays),
        gatekeeperProjectIds: formData.exclusion.gatekeeperProjectIds.map((items: TODO) => items.id)
      }
    }

    if (batchVariables.liveVariables?.fileId) {
      body = {
        ...body,
        customVariablesLiveFileId: batchVariables.liveVariables?.fileId
      }
    }
    if (batchVariables.testVariables?.fileId) {
      body = {
        ...body,
        customVariablesTestFileId: batchVariables.testVariables?.fileId
      }
    }
    if (handleErrorCreteNewPoject(body)) {
      if (showCreateNewProject.createNewProject) {
        setIsLoading(true)
        generateProject("co/gk/projects", token, body)
          .then((res: TODO) => {
            setIsLoading(false)
            if (Array.isArray(res)) {
              const error = res.find(el => el.errorMessage)
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error.errorMessage } })
            } else if (res.message || res.error) {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.message ? res.message : res.error } })
            } else if (typeof res === 'string') {
              const message = res.slice(res.indexOf("Message"))
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: message } })
            } else {
              setDidMount(false)
              setShowCreateNewProject({ ...showCreateNewProject, createNewProject: false })
              setShowExclution(false)
              setFormData({
                displayName: '',
                country: { name: "", countryCode: "", languageCode: "" },
                loi: "",
                surveyTestUrl: '',
                surveyLiveUrl: '',
                useResearchDefender: true,
                wave: "",
                exclusion: null,
                countryCode: "",
                allowReentry: false,
                redirectCaptureParameter: ""
              })
              setGKProjectsToExclude([])
              dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Project created successfully' } })
              setBatchVariablesResponse({ liveVariables: null, testVariables: null, isBatch: false })
            }
          })
      } else {
        editProject(`co/gk/projects/${projectIds.id}/${projectIds.projectNumber}`, token, body)
          .then((response: TODO) => {
            setIsLoading(false)
            if (response.error || response.message) {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: response.error ? response.error : response.message } })
            } else if (typeof response === 'string') {
              const message = response.slice(response.indexOf("Message"))
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: message } })
            } else {
              setDidMount(false)
              setShowCreateNewProject({ ...showCreateNewProject, updateProject: false })
              setShowExclution(false)
              setFormData({
                displayName: '',
                country: { name: "", countryCode: "", languageCode: "" },
                loi: "",
                surveyTestUrl: '',
                surveyLiveUrl: '',
                useResearchDefender: true,
                wave: "",
                exclusion: null,
                countryCode: "",
                allowReentry: false,
                redirectCaptureParameter: ""
              })
              setGKProjectsToExclude([])
              dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Project has been successfully updated' } })
              setBatchVariablesResponse({ liveVariables: null, testVariables: null, isBatch: false })
            }
          })
      }
    }
  }

  const handleErrorCreteNewPoject = (obj: GatekeeperNewFormData) => {
    let emptyFieldError = {}

    if (obj.displayName === "") {
      emptyFieldError = Object.assign({ ...emptyFieldError, displayName: true })
    }
    if (obj.loi === 0) {
      emptyFieldError = Object.assign({ ...emptyFieldError, loi: true })
    }
    if (obj.wave === 0) {
      emptyFieldError = Object.assign({ ...emptyFieldError, wave: true })
    }
    if (obj.surveyLiveUrl !== "") {
      const validURL = isValidUrl(obj.surveyLiveUrl)
      !validURL && (emptyFieldError = Object.assign({ ...emptyFieldError, invalidLiveUrl: true }))
    } else {
      emptyFieldError = Object.assign({ ...emptyFieldError, surveyLiveUrl: true })
    }
    if (obj.surveyTestUrl !== "") {
      const validURL = isValidUrl(obj.surveyTestUrl)
      !validURL && (emptyFieldError = Object.assign({ ...emptyFieldError, invalidTestUrl: true }))
    } else {
      emptyFieldError = Object.assign({ ...emptyFieldError, surveyTestUrl: true })
    }
    if (obj.countryCode === "" || obj.languageCode === "") {
      emptyFieldError = Object.assign({ ...emptyFieldError, country: true })
    }
    if (showExclution) {
      if (!formData.exclusion?.exclusionDurationDays || formData.exclusion?.exclusionDurationDays === "") {
        emptyFieldError = Object.assign({ ...emptyFieldError, exclusionDurationDays: true })
      }
      if (!formData.exclusion?.gatekeeperProjectIds || formData.exclusion?.gatekeeperProjectIds.length === 0) {
        emptyFieldError = Object.assign({ ...emptyFieldError, gatekeeperProjectIds: true })
      }
      if (!formData.exclusion?.respondentStatuses || formData.exclusion?.respondentStatuses.length === 0) {
        emptyFieldError = Object.assign({ ...emptyFieldError, respondentStatuses: true })
      }
    }

    if (Object.keys(emptyFieldError).length > 0) {
      setError({ ...emptyFieldError })
      return false
    }
    return true
  }

  const isValidUrl = (urlString: string) => {
    try {
      return Boolean(new URL(urlString))
    } catch (e) {
      return false
    }
  }

  const handleSearch = (marketSearch: string) => {
    setSearchMarket(marketSearch)
  }

  const handleShowExclusions = () => {
    setShowExclution(!showExclution)
    setFormData({
      ...formData,
      exclusion: !showExclution ? {
        respondentStatuses: ["ScreenOut"],
        exclusionDurationDays: "",
        gatekeeperProjectIds: []
      } : null
    })
  }

  const closeReEntry = () => {
    setShowReEntry(false)
    setFormData({
      ...formData,
      allowReentry: false
    })
  }

  return (
    <>
      <Dialog height={"560px"} width={"600px"} className='gk-new-project' title={showCreateNewProject.updateProject ? "Update project" : "New project"} onClose={() => { onHide("New Project"); setError({}) }}>
        <form id='np-form' onSubmit={(e) => onGenerateHandler(e)} >
          {/*Project number*/}
          <div className="details-input pt-2 pb-2 d-flex flex-column">
            <label className='p-0 mb-0 strong text-primary'>Project Name</label>
            <input className='form-control' type="text" name='displayName' placeholder='' value={formData.displayName} onChange={(e) => onChangeHandler(e)} />
            {error.displayName &&
              <div className="text-danger">
                <small>This field is required.</small>
              </div>}
          </div>
          {/*Country*/}
          <div className="details-input pt-2 pb-2 details-dropdown d-flex flex-column">
            <label className='p-0 m-0 strong text-primary'>Country</label>
            <button
              className={"btn dropdown-toggle form-control"}
              type="button"
              id="dropdownMenuAction"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {formData?.country?.name || "Select a country"}
              <span><Icon type="chevron-mini-down-alt" className="submenu" /></span>
            </button>
            <div className="dropdown-menu menu-action" aria-labelledby="dropdownMenuAction">
              <div className='d-flex px-3 pb-3 mt-1 border-bottom'><input className='form-control' value={searchMarket} type="text" placeholder="Search market" id="myInput" onChange={(e) => handleSearch(e.target.value)} /></div>
              <div className='d-flex flex-column market overflow-auto'>
                {markets.filter(market => market.name.toLowerCase().includes(searchMarket)).map((item, index) => (
                  <button key={index} className="dropdown-item" type="button" name='country' onClick={(e) => onChangeHandler({ ...e, item: item })} >{item.name}</button>
                ))}
              </div>
            </div>
            {error?.country &&
              <div className="text-danger">
                <small>This field is required.</small>
              </div>}
          </div>
          {/*Wave & LOI*/}
          <div className='d-flex'>
            <div className="details-input d-flex flex-column w-50 ">
              <label className='p-0 m-0 strong text-primary'>Wave</label>
              <input name='wave' className='form-control' type="text" value={formData.wave ? formData.wave : ""} onChange={(e) => onChangeHandler(e)} />
              {error?.wave &&
                <div className="text-danger">
                  <small>This field is required.</small>
                </div>}
            </div>
            <div className="details-input d-flex flex-column w-50 ">
              <label className='p-0 m-0 strong text-primary'>Length of interview</label>
              <div className="input-group">
                <input name='loi' className='form-control' type="text" placeholder='0' value={formData.loi ? formData.loi : ""} onChange={(e) => onChangeHandler(e)} />
                <div className="input-group-append">
                  <span aria-disabled="true" className="input-group-text py-1 time-span">minutes</span>
                </div>
              </div>
              {error?.loi &&
                <div className="text-danger">
                  <small>This field is required.</small>
                </div>}
            </div>
          </div>
          {
            showCreateNewProject.isRecontact &&
            <p className='text-primary info-box p-2 my-1 mx-3 medium d-flex align-items-center' ><span><Icon type="info-icon" fill='primary' /></span>Please use [%RecontactId%] to append the original respondent ID.</p>
          }
          {
            batchVariables.isBatch &&
            <p className='text-primary info-box p-2 my-1 mx-3 medium d-flex align-items-center' ><span><Icon type="info-icon" fill='primary' /></span>{batchVariables.liveVariables?.message}</p>
          }
          {/*test URL*/}
          <div className="details-input d-flex flex-column">
            <label className='d-flex align-items-center p-0 mb-1 strong text-primary'>
              Client test entry URL
              <Tooltip className='gk-tooltip-table' content={() =>
                // TABLE TOOLTIP
                <table className="gk-tooltip-table-inner">
                  <thead>
                    <tr>
                      <th scope="col">Link variable</th>
                      <th scope="col">Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td >{"[%PID%]"}</td>
                      <td>Platform ID</td>
                    </tr>
                    <tr>
                      <td>{"[%RID%]"}</td>
                      <td style={{ whiteSpace: "nowrap" }} >Walr transaction ID</td>
                    </tr>
                    <tr>
                      <td>{"[%SupplierRID%]"}</td>
                      <td style={{ whiteSpace: "nowrap" }} >Supplier expose ID</td>
                    </tr>
                    <tr>
                      <td>{"[%Country%]"}</td>
                      <td>GK country</td>
                    </tr>
                    <tr>
                      <td>{"[%Language%]"}</td>
                      <td>GK language</td>
                    </tr>
                  </tbody>
                </table>
              } anchorElement="target" position="top">
                {/* <span className='icon cursor-pointer' title={`[%RID%] to indicate where the Respondent ID should be populated.\n[%PID%] to indicate where the platform ID should be populated.`}><Icon type="info-tooltip" className='pe-none' /></span> */}
                <span className='icon cursor-pointer' title={"table"}><Icon type="info-tooltip" className='pe-none' /></span>

              </Tooltip>
            </label>
            <input className='form-control' type="text" name="surveyTestUrl" placeholder='http://' value={formData.surveyTestUrl} onChange={(e) => onChangeHandler(e)} />
            {error.surveyTestUrl &&
              <div className="text-danger">
                <small>This field is required.</small>
              </div>}
            {error.invalidTestUrl &&
              <div className="text-danger">
                <small>Invalid URL format.</small>
              </div>}
          </div>
          {/*live URL*/}
          <div className="details-input d-flex flex-column">
            <label className='p-0 mb-0 strong text-primary'>Client live survey URL</label>
            <input className='form-control' type="text" name='surveyLiveUrl' placeholder='http://' value={formData.surveyLiveUrl} onChange={(e) => onChangeHandler(e)} />
            {error.surveyLiveUrl &&
              <div className="text-danger">
                <small>This field is required.</small>
              </div>}
            {error.invalidLiveUrl &&
              <div className="text-danger">
                <small>Invalid URL format.</small>
              </div>}
          </div>
          {/* Redirect Capture Parameter */}
          <div className="details-input d-flex flex-column">
            <label className='d-flex align-items-center p-0 mb-0 strong text-primary'>Redirect capture parameter
              <Tooltip anchorElement="target" position="top">
                <span className='icon cursor-pointer' title={"This defines a parameter name that allows the survey host to pass back an additional field on the Walr redirects"}><Icon type="info-tooltip" className='pe-none' /></span>
              </Tooltip>
            </label>
            <input className='form-control' type="text" name='redirectCaptureParameter' placeholder='optional' value={formData.redirectCaptureParameter ? formData.redirectCaptureParameter : ""} onChange={(e) => onChangeHandler(e)} />
          </div>
          {/* SWITCH OPTIONS */}
          <div className="details-input d-flex flex-column">
            {/* commented out for RD now, value set to true
             <div className="customer-dialog d-flex align-items-center flex-row p-0 mt-2">
              <Switch className="mr-2 asap-switch"
                name="useResearchDefender"
                checked={formData.useResearchDefender}
                onChange={(e: TODO) => onChangeHandler(e)}
                size="small"
              />
              <label className="form-check-label d-flex align-items-center" htmlFor="adHocCompleteAsap">Multiple panel sources
                <Tooltip anchorElement="target" position="top">
                  <span className='icon cursor-pointer' title='Toggles on/off enhanced deduping via Research Defender'><Icon type="info-tooltip" className='pe-none' /></span>
                </Tooltip>
              </label>
            </div> */}
            <div className="customer-dialog d-flex align-items-center flex-row p-0 mt-3">
              <Switch className="mr-2 asap-switch"
                name="allowReentry"
                checked={formData.allowReentry}
                onChange={(e) => onChangeHandler(e)}
                size="small"
              />
              <label className="form-check-label d-flex align-items-center" htmlFor="exclusions">Re-entry
                <Tooltip anchorElement="target" position="top">
                  <span className='icon cursor-pointer' title='Selecting this option will allow a respondent to re-entry this link multiple times without being flagged as a duplicate'><Icon type="info-tooltip" className='pe-none' /></span>
                </Tooltip>
              </label>
            </div>
            <div className="customer-dialog d-flex align-items-center flex-row p-0 mt-3 gap-md">
              <Switch className="asap-switch"
                checked={showExclution}
                disabled={gkProjectsToExclude.length < 1}
                onChange={() => handleShowExclusions()}
                size="small"
              />
              <label className="form-check-label d-flex align-items-center" htmlFor="exclusions">Exclusions
                <Tooltip anchorElement="target" position="top">
                  <span className='icon cursor-pointer' title='Allows exclusions of respondents who have previously finished a survey with Research Defender enabled'><Icon type="info-tooltip" className='pe-none' /></span>
                </Tooltip>
              </label>
            </div>
          </div>
          {/* Exclusions */}
          {
            gkProjectsToExclude.length > 0 && showExclution &&
            <ExclusionsForm
              onChangeHandler={onChangeHandler}
              excData={formData.exclusion}
              gkProjectsToExclude={gkProjectsToExclude}
              error={error}
            />
          }
        </form>
        <DialogActionsBar>
          <div className='d-flex'>
            <button type='button' className="btn btn-secondary mr-2" disabled={isLoading} onClick={() => { onHide("New Project"); setError({}) }}>Cancel</button>
            <button form='np-form' type='submit' className="btn btn-primary">
              {showCreateNewProject.updateProject ? "Update project" : "Generate"}
              {
                isLoading &&
                <span className="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true" />
              }
            </button>
          </div>
        </DialogActionsBar>
      </Dialog >
      {/* RE-ENTRY DIALOG */}
      {showReEntry &&
        <Dialog height={"300px"} width={"400px"} className='gk-new-project' title={"Re-entry confirmation"} onClose={closeReEntry}>
          <p className='text-primary p-3'  >
            Enabling re-entry will allow respondents to take this survey multiple times and achieve multiple statuses.
            This feature should only be enabled with manager approval.
            <br />
            <br />
            Do you want to proceed?
          </p>
          <DialogActionsBar>
            <div className='d-flex'>
              <button type='submit' className="btn btn-secondary mr-2" onClick={closeReEntry}>Cancel</button>
              <button type='button' className="btn btn-primary" onClick={() => onHide("Re-Entry")}>Proceed</button>
            </div>
          </DialogActionsBar>
        </Dialog>
      }
    </>
  )
}