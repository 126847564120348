import { useDispatch } from 'react-redux';

import type { ShareDetails, ShareUser } from '../../../interfaces/projectDetailsInterfaces/shareDetailsInterfaces';

type Props = {
  shareDetails: ShareDetails
  onShowShareProject: (val: TODO) => void
}

export const ProjectSharedUsers = ({ shareDetails, onShowShareProject }: Props) => {
  const dispatch = useDispatch();

  const onDeleteUserClickHandler = (user: ShareUser) => {
    const userType = user.isSubAccountUser ? 'subAccount' : 'regularAccount'
    dispatch({ type: 'UPDATE_SHOW_DELETE_SHARED_USERS_MODAL', payload: { show: true, account: user.email, type: userType } })
  }

  if (shareDetails.shareUsers && shareDetails.shareUsers?.length > 0) {
    return (
      <div className="alert alert-secondary d-flex justify-content-between align-items-center mb-2 mx-4 px-3 py-2 border">
        <div className="d-flex align-items-center">
          <i className="fas fa-users mr-3 text-primary" />
          <div>
            <p className="mb-1 text-primary">This project is shared with:</p>
            <div className="d-flex flex-wrap gap-md">
              {
                shareDetails.shareUsers?.map((el) => (
                  <span key={el.email} style={{ fontWeight: '500' }} className={"badge badge-light p-2"}>
                    <span>{el.email}</span>
                    {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
                    <i
                      onClick={() => onDeleteUserClickHandler(el)}
                      className="fas fa-lg fa-times-circle cursor-pointer ml-2">
                    </i>
                  </span>
                ))
              }
            </div>
          </div>
        </div>
        <div className="d-flex">
          <button type='button' onClick={() => onShowShareProject({ show: true, step: 'users' })} className="btn btn-shadow d-flex align-items-center">
            <i className="fas fa-edit mr-2" />
            <span>Edit</span>
          </button>
        </div>
      </div>
    )
  }
  return (
    <div className="alert alert-secondary border mb-2 mx-4" role="alert">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <i className="far fa-lg fa-info-circle mr-4" />
          <span>This project is not shared with any external users yet.</span>
        </div>
        <button type='button' onClick={() => onShowShareProject({ show: true, step: 'users' })} className="btn btn-shadow">
          <i className="far fa-user-plus mr-2" />
          <span>Invite users</span>
        </button>
      </div>
    </div>
  )
}