export default (dataMetaFile, caseFile, formData, uploadDataRes) => {
  let quickImportBody = null
  if (caseFile) {
    quickImportBody = {
      "type": "sss",
      "settings": { "name": formData.name, "description": formData.description },
      "metafile": dataMetaFile.fileName,
      "casefile": caseFile.fileName,
      "uploadId": uploadDataRes.uploadId
    }
  } else {
    quickImportBody = {
      "type": dataMetaFile.extension,
      "settings": { "name": formData.name, "description": formData.description },
      "datafile": dataMetaFile.fileName,
      "uploadId": uploadDataRes.uploadId
    }
  }
  return quickImportBody
}