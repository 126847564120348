import { type FormEvent, useState } from 'react';
import auth0 from 'auth0-js';
import { Redirect } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import Logo from '../../assets/img/walr_black.png';
import { ErrorMessage } from '../shared/ErrorMessage/ErrorMessage';
import { apiUrl } from '../../services/services';

interface FormData {
  email: string
  password: string
}

export const Login = () => {
  const { user, loginWithRedirect } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState<FormData>({ email: '', password: '' });

  const onChangeHandler = (e: TODO) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const onSubmitHandler = (e: FormEvent) => {
    e.preventDefault()
    setIsLoading(true)

    const webAuth = new auth0.WebAuth({
      domain: import.meta.env.VITE_APP_AUTH0_DOMAIN,
      clientID: import.meta.env.VITE_APP_AUTH0_CLIENT_ID,
      responseType: 'token',
      redirectUri: window.location.origin,
      audience: apiUrl,
      // cacheLocation: 'localstorage',
      // useRefreshTokens: true,
    });

    webAuth.login({
      realm: 'research-studio',
      email: formData.email,
      password: formData.password,
      onRedirecting: () => {
        loginWithRedirect()
      }
    }, (err: TODO) => {
      if (err) {
        setErrorMessage(err.description)
        setIsLoading(false)
      }
    })
  }

  if (user) {
    return <Redirect to="/" />
  }
  return (
    <div className="d-flex flex-column vh-100 w-100">
      <div className="container flex-grow-1">
        <div className="row justify-content-center h-100">
          <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
            <div className="login-card border shadow-lg w-100">
              <div className="d-flex justify-content-center mb-7">
                <img className='walr-logo' src={Logo} alt="Walr" />
              </div>
              <form onSubmit={onSubmitHandler} className="d-flex flex-column gap-lg">
                <input
                  type="email"
                  name="email"
                  required={true}
                  autoFocus={true}
                  value={formData.email}
                  className="form-control"
                  placeholder='Email address'
                  onChange={onChangeHandler}
                />
                <input
                  type="password"
                  name="password"
                  required={true}
                  placeholder='Password'
                  className="form-control"
                  value={formData.password}
                  onChange={onChangeHandler}
                />
                {
                  errorMessage &&
                  <div className="mt-4">
                    <ErrorMessage
                      type="alert"
                      errorMessage={errorMessage}
                      onHide={() => setErrorMessage('')}
                    />
                  </div>
                }

                <button
                  type="submit"
                  disabled={isLoading}
                  className="btn btn-dark btn-block"
                  style={{ maxHeight: 'unset', height: '100%', fontSize: 'unset', fontWeight: 'normal' }}>
                  {
                    isLoading ?
                      <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      :
                      <span>Log in</span>
                  }
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}