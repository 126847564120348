export default (analysisFunctionalities, items, datasetType, scriptItems) => {
  const enabledActions = Object.keys(analysisFunctionalities.analysisActions).filter(action => analysisFunctionalities.analysisActions[action] === true)
  const updatedItems = []
  enabledActions.forEach(action => {
    items.forEach(item => {
      if (scriptItems || datasetType === 'surveys') {
        if (item.action !== 'weight' && action.toLowerCase().includes(item.action.toLowerCase())) {
          updatedItems.push(item)
        }
      } else {
        if (action.toLowerCase().includes(item.action.toLowerCase())) {
          updatedItems.push(item)
        }
      }
    })
  })
  return updatedItems
}