export default (tabSelected, currentState, toplineState, xtState, chartState, digState, dataState, qTableState, scriptState, reportState, showScriptModal, analysisScriptingState) => {
  const stateNames = ['toplineState', 'xtState', 'chartState', 'digState', 'qTableState', 'dataState', 'scriptState', 'reportState']
  const body = {
    selectedTab: tabSelected,
    toplineState: toplineState,
    xtState: xtState,
    chartState: chartState,
    dataState: dataState,
    digState: digState,
    qTableState: qTableState,
    scriptState: scriptState,
    reportState: reportState,
    analysisScriptingState: { data: analysisScriptingState.data }
  }

  stateNames.forEach((stateName, id) => {
    body[stateName].questionOptions = body[stateName].questionOptions || {}
    if (stateName !== "digState") {
      body[stateName].newQuestionOptions = body[stateName].questionOptions || {}
    }

    if (showScriptModal) {
      body.scriptState = currentState
    } else if (tabSelected === 8) {
      body.analysisScriptingState = currentState
    } else {
      if (tabSelected === id) {
        body[stateName] = currentState
      }
    }
  })

  return body
}