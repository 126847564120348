import { Tooltip } from "@progress/kendo-react-tooltip";

import { Icon } from "../../../Icon/Icon";
import type { InboxItem } from "../../InboxDrawer";

interface Props {
  item: InboxItem;
  notification: {
    id: string
    status: string
    completedStatus: string
  };
}

export const InboxDrawerCustomItemStatus = ({ item, notification }: Props) => {
  if (item.completedStatus) {
    if (item.completedStatus === 'Success') {
      return (
        <div className="d-flex align-items-center gap-md text-success">
          <i className="fas fa-check-circle" />
          <small className="line-clamp-3">
            {item.message ? item.message : 'Export is completed'}
          </small>
          {item.message && item.message.length > 120 && (
            <Tooltip anchorElement="target" position="bottom" style={{ zIndex: 1000000 }}>
              <span title={item.message}>
                <Icon type="info-tooltip" className="pe-none" />
              </span>
            </Tooltip>
          )}
        </div>
      );
    } if (item.completedStatus === 'Failure') {
      return (
        <div title={item.message} className="d-flex align-items-center gap-md text-danger">
          <i className="fas fa-exclamation-circle pt-1" />
          <small className="line-clamp-3">
            {item.message ? item.message : 'Export failed'}
          </small>
          {item.message && item.message.length > 120 && (
            <Tooltip anchorElement="target" position="bottom" style={{ zIndex: 1000000 }}>
              <span title={item.message}>
                <Icon type="info-tooltip" className="pe-none" />
              </span>
            </Tooltip>
          )}
        </div>
      );
    } if (item.completedStatus === 'NotSet') {
      let itemProperty: TODO = { ...item }
      if (item.id === notification.id) {
        itemProperty = { ...notification }
      }
      if (itemProperty?.status) {
        if (itemProperty.status === 'Initialized') {
          return (
            <div className="d-flex align-items-center">
              <div className="spinner-grow spinner-grow-sm text-primary mr-2" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <small className="text-primary">Initializing...</small>
            </div>
          );
        } if (itemProperty.status === 'Queued') {
          if (itemProperty.type === "Reconciliation") {
            return (
              <div className="d-flex align-items-center text-primary">
                <i className="fas fa-hourglass-half mr-2" />
                <small>{itemProperty.message}</small>
              </div>
            )
          }
          return (
            <div className="d-flex align-items-center text-primary">
              <i className="fas fa-hourglass-half mr-2" />
              <small>{item.type === "Audience" ? item.message : "Queued for export..."}</small>
            </div>
          );
        } if (itemProperty.status === 'Processing') {
          return (
            <div className="d-flex align-items-center">
              <div className="spinner-grow spinner-grow-sm text-primary mr-2" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <small className="text-primary">{item.type === "Audience" ? item.message : "Processing..."}</small>
            </div>
          );
        } if (item.type !== 'DatasetImport' && itemProperty.status === 'Completed' && itemProperty.completedStatus === 'Success') {
          return (
            <span>
              <i className="fas fa-check-circle pt-1 mr-1 text-success" />
              <small className="text-success">{item.type === 'CreateSurveyFromDataset' ? 'Created' : 'Ready to download'}</small>
            </span>
          );
        } if (itemProperty.status === 'Completed' && (itemProperty.completedStatus === 'Failed' || itemProperty.completedStatus === 'Failure')) {
          if (item.type === 'DatasetImport') {
            return (
              <span className='text-danger'>
                <i className="fas fa-times-circle pt-1 mr-1" />
                <small>Dataset import failed</small>
              </span>
            );
          }
          return (
            <span className='text-danger'>
              <i className="fas fa-times-circle pt-1 mr-1" />
              <small>Something went wrong</small>
            </span>
          );
        } return null;
      } return null;
    } return null
  } return null;
};

