import React, { useState, useEffect, type FormEvent, type ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input, type InputChangeEvent } from '@progress/kendo-react-inputs';
import type { ListItemProps, MultiSelectChangeEvent } from '@progress/kendo-react-dropdowns';

import { BaseMultiSelect } from '../../../../shared/Inputs';
import type { RootState } from '../../../../../store/reducers/rootReducer';
import { ErrorMessage } from '../../../../shared/ErrorMessage/ErrorMessage';
import type { AdminUser } from '../../../../../interfaces/userInterface/userInterface';
import { returnUserDisplayName } from '../../../../shared/helpers/returnUserDisplayName/returnUserDisplayName';
import { fetchPostJson as createGroup, fetchGetJson as getUsers } from '../../../../../services/services';

type Props = {
  handleClose: () => void;
  updateGroups: () => void;
}

export const CreateGroupModal = ({ handleClose, updateGroups }: Props) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [formData, setFormData] = useState<{ name: string, description: string, userIds: AdminUser[] }>({ name: '', description: '', userIds: [] })
  const [errorMessage, setErrorMessage] = useState("")
  const [users, setUsers] = useState<AdminUser[]>([])

  useEffect(() => {
    getUsers('users', token)
      .then((res: TODO) => {
        if (res && (res.message || res.error)) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.message ? res.message : res.error } })
        } else {
          setUsers(res.map((user: AdminUser) => {
            return {
              ...user,
              displayName: returnUserDisplayName(user)
            }
          }))
        }
      })
  }, [dispatch, token])

  const onChangeHandler = (e: InputChangeEvent | MultiSelectChangeEvent, name: string) => {
    setFormData({
      ...formData,
      [name]: e.target.value
    })
  }

  const onSubmitHandler = (e: FormEvent) => {
    e.preventDefault()
    const body = {
      name: formData.name,
      description: formData.description,
      userIds: formData.userIds.map(a => a.id)
    }
    createGroup("groups", token, body)
      .then((res: TODO) => {
        if (res && (res.message || res.error)) {
          setErrorMessage(res.message ? res.message : res.error)
        } else if (Object.entries(res).length !== 0) {
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The group has been created successfully' } })
          handleClose()
          updateGroups()
        }
      })
  }

  const itemRender = (li: ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = itemProps.dataItem?.displayName;
    return React.cloneElement(li, li.props, itemChildren);
  }

  return (
    <Dialog title="Create group"
      onClose={handleClose}
      className="d-flex flex-column import-dialog"
      width="40%">
      <form onSubmit={onSubmitHandler}>
        <div className="d-flex flex-column p-3 gap-md">
          <p className="m-0">Name</p>
          <Input
            required
            onChange={e => onChangeHandler(e, 'name')}
            value={formData.name}
            placeholder="Enter name"
            name="name"
            className="w-100"
          />

          <p className="m-0">Description</p>
          <Input
            onChange={e => onChangeHandler(e, 'description')}
            value={formData.description}
            placeholder="Enter description"
            name="description"
            className="w-100"
          />

          <p className="m-0">Users</p>
          <BaseMultiSelect
            data={users}
            itemRender={itemRender}
            className="w-100"
            textField="displayName"
            dataItemKey="id"
            onChange={(e: MultiSelectChangeEvent) => onChangeHandler(e, 'userIds')}
            name="userIds"
            value={formData.userIds}
            placeholder="Select users to add"
          />
        </div>
        {
          errorMessage &&
          <ErrorMessage
            type="alert"
            errorMessage={errorMessage} />
        }
        <DialogActionsBar>
          <button type="button" className="k-button btn btn-secondary" onClick={handleClose}>Cancel</button>
          <button type="submit" className="k-button btn btn-primary">Create group</button>
        </DialogActionsBar>
      </form>
    </Dialog>
  )
}