import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { FileManagerState } from '../../../../interfaces/fileManagerInterfaces/fileManagerInterfaces';
import { updateFileManagerExpandedState } from '../../../../store/reducers/fileManagerStateReducer/fileManagerStateReducer';

import type { RootState } from '../../../../store/reducers/rootReducer';
import { FileManagerExpandedScreenDetails } from './FileManagerExpandedScreenDetails/FileManagerExpandedScreenDetails';
import { FileManagerExpandedScreenLargeIcons } from './FileManagerExpandedScreenLargeIcons/FileManagerExpandedScreenLargeIcons';

interface Props {
  expandedState: TODO
  selectRow: (item: TODO, stateType: string) => void
}

export const FileManagerExpandedScreen = ({ expandedState, selectRow }: Props) => {
  const dispatch = useDispatch()
  const { expandedDisplayType } = useSelector((theState: RootState) => theState.fileManagerStateReducer);

  const onDoubleClickRowHandler = (item: TODO) => {
    if (item.isDirectory && item.contents?.length > 0) {
      const updatedExpandedState: FileManagerState = JSON.parse(JSON.stringify(expandedState))
      updatedExpandedState.data[0] = item
      dispatch(updateFileManagerExpandedState(updatedExpandedState))
    }
  }

  return (
    <Fragment>
      {
        expandedState.data.length === 0 ?
          <div className="d-flex flex-grow-1 align-items-center justify-content-center">
            <p className="text-muted m-0">No records available</p>
          </div>
          :
          expandedDisplayType === 'details' ?
            <FileManagerExpandedScreenDetails
              expandedState={expandedState}
              expandedDisplayType={expandedDisplayType}
              selectRow={(item, stateType) => selectRow(item, stateType)}
              onDoubleClickRowHandler={(item) => onDoubleClickRowHandler(item)}
            />
            :
            <FileManagerExpandedScreenLargeIcons
              expandedState={expandedState}
              expandedDisplayType={expandedDisplayType}
              selectRow={(item, stateType) => selectRow(item, stateType)}
              onDoubleClickRowHandler={(item) => onDoubleClickRowHandler(item)}
            />
      }
    </Fragment>
  )
}