export default (dataItem, history) => {
  const location = history.location.pathname

  if (dataItem.type.includes('datasets')) {
    history.push({ pathname: `${location}/${dataItem.id}/analyze/datasets`, state: { datasetName: dataItem.name } })
  } else if (dataItem.type === 'surveys') {
    history.push({ pathname: `${location}/${dataItem.id}/survey-builder` })
  } else if (dataItem.type === 'samples') {
    history.push({ pathname: `${location}/${dataItem.id}/panel` })
  } else if (dataItem.type === 'pinboards') {
    history.push({ pathname: `${location}/${dataItem.id}/pinboards` })
  } else if (dataItem.type === 'workflows') {
    history.push({ pathname: `${location}/${dataItem.id}/workflow` })
  } else if (dataItem.type === "audiences") {
    history.push({ pathname: `${location}/audiences/${dataItem.id}` })
  } else {
    history.push({ pathname: `/projects/${dataItem.id}` })
  }
}
