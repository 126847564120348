import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SubAccountCard } from './SubAccountCard/SubAccountCard';
import type { RootState } from '../../../../store/reducers/rootReducer';
import { AdminCardsSkeleton } from '../AdminCardsSkeleton/AdminCardsSkeleton';
import { DeleteSubAccountModal } from './DeleteSubAccountModal/DeleteSubAccountModal';
import type { SubAccount } from '../../../../interfaces/adminInterfaces/adminInterfaces';
import { useFetchSubAccountsData } from '../../../shared/customHooks/useFetchSubAccountsData';

export const SubAccountsTabContent = () => {
  const dispatch = useDispatch();
  const { subAccounts } = useSelector((theState: RootState) => theState.adminReducer);
  const [showDeleteSubAccountModal, setShowDeleteSubAccountModal] = useState(false);
  const [subAccountId, setSubAccountId] = useState("");

  useFetchSubAccountsData()

  const handleOpenDeleteSubAccountModal = (id: string) => {
    setSubAccountId(id);
    setShowDeleteSubAccountModal(true);
  };

  return (
    <Fragment>
      {
        showDeleteSubAccountModal &&
        <DeleteSubAccountModal
          subAccounts={subAccounts}
          subAccountId={subAccountId}
          setSubAccounts={(data) => dispatch({ type: 'SET_SUB_ACCOUNTS', payload: data })}
          handleClose={() => setShowDeleteSubAccountModal(false)}
        />
      }
      <div className="container-fluid py-5">
        <div style={{ rowGap: '1.5rem' }} className="row">
          {
            subAccounts !== null ?
              subAccounts.length > 0 ?
                subAccounts.map((subAccount: SubAccount) => (
                  <Fragment key={subAccount.id}>
                    <SubAccountCard
                      subAccount={subAccount}
                      handleOpenDeleteSubAccountModal={() => handleOpenDeleteSubAccountModal(subAccount.id)}
                    />
                  </Fragment>
                ))
                :
                <div className="d-flex flex-column gap-lg text-disabled text-center h5 pt-10 w-100">
                  <i className="fal fa-2x fa-folder-open" />
                  <p className='m-0'>No sub accounts have been found</p>
                </div>
              :
              <AdminCardsSkeleton />
          }
        </div>
      </div>
    </Fragment>
  )
}