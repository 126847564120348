import type React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';

import { Icon } from '../../../../../shared/Icon/Icon';
import { useDebounce } from '../../../../../shared/customHooks/useDebounce';
import { ImportedRecipientSidebar } from '../ImportedRecipientSidebar/ImportedRecipientSidebar'
import { ImportedRecipientListTable } from './ImportedRecipientListTable/ImportedRecipientListTable';
import fileManagerDownloadHandler from '../../../../../shared/helpers/fileManagerDownloadHandler/fileManagerDownloadHandler';
import type { IProps, IRespondent, IRecipientList, IPostRes, IOrderBy, IBodySearch, IBodyToken, IResBlob } from '../../../../../../interfaces/importedRecipientListinterfaces/importedRecipientListinterfaces';
import { fetchGetBlob, fetchGetJson, fetchPostJson, fetchPatchJson as updateRespondentsReq } from '../../../../../../services/services';

export const ImportedRecipientList: React.FC<IProps> = ({ id, token, projectId, surveyId, recipientListName }) => {
  const dispatch = useDispatch()
  const [searchInput, setSearchInput] = useState("");
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);
  const [theKeys, setTheKeys] = useState<string[]>([]);
  const [didMount, setDidMount] = useState(true);
  const [selectedRespondents, setSelectedRespondents] = useState([])
  const [elementClicked, setElementClicked] = useState<IRespondent>({
    cIds: "",
    id: "",
    isDisabled: false,
    optOut: false,
    rId: "",
    shortId: "",
    t: [],
    v: {
      name: "",
      altid: "",
      email: "",
      age: "",
      gender: "",
    },
    status: "",
    _etag: ""
  });
  const [updatedVariables, setUpdatedVariables] = useState({});
  const [orderBy, setOrderBy] = useState<IOrderBy>({ column: "id", direction: "", number: 0 });
  const [recipientList, setRecipientList] = useState<IRecipientList>({ continuationToken: "", count: 0, recipients: [] });
  const [filters, setFilters] = useState({})
  const [shouldSort, setShouldSort] = useState<boolean>(false)
  const [sortBody, setSortBody] = useState<TODO>({ stringFilters: [] })
  const [isExporting, setIsExporting] = useState(false)
  const filterWithDebounce = useDebounce(() => setDidMount(false), 1000);
  const sortWithDebounce = useDebounce(() => setShouldSort(true), 500);

  useEffect(() => {
    const body = { stringFilters: [] }
    fetchPostJson(`projects/${projectId}/surveys/${surveyId}/recipientsv2/${id}`, token, body)
      .then((res: IPostRes) => {
        if (res.error || res.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
        } else {
          setRecipientList(res)
          if (res.recipients.length > 0) {
            setTheKeys(Object.keys(res.recipients[0].v))
          }
        }
      })
  }, [projectId, surveyId, id, token, dispatch]);

  const handleMoreRecipientsClick = () => {
    const body: IBodyToken = {
      continuationToken: recipientList?.continuationToken,
      orderBy: "",
      orderByDirection: 0
    }
    if (orderBy.direction) {
      body.orderBy = orderBy.column
      body.orderByDirection = orderBy.number
    }
    fetchPostJson(`projects/${projectId}/surveys/${surveyId}/recipientsv2/${id}`, token, body)
      .then((res: IPostRes) => {
        if (res.error || res.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
        } else {
          const newRecipients = [...recipientList.recipients, ...res.recipients]
          setRecipientList({
            ...recipientList,
            continuationToken: res.continuationToken,
            recipients: newRecipients
          })
        }
      })
  };

  const bodyForSearch = useCallback(() => {
    const body: IBodySearch = { stringFilters: [] }
    for (const [key, value] of Object.entries(filters)) {
      body.stringFilters.push(`${key}:${value}`)
    }
    if (orderBy.direction !== "") {
      body.orderBy = orderBy.column
      body.orderByDirection = orderBy.direction === "ascending" ? 0 : orderBy.direction === "descending" ? 1 : undefined
    }
    return body
  }, [orderBy.column, orderBy.direction, filters])

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>, type: TODO) => {
    const updatedFilters = JSON.parse(JSON.stringify(filters))
    if (`v.${type}` in updatedFilters && e.target.value === '') {
      delete updatedFilters[`v.${type}`]
    } else {
      updatedFilters[`v.${type}`] = e.target.value
    }
    setSearchInput(e.target.value)
    setFilters(updatedFilters)
    filterWithDebounce()
  }

  const handleUpdateRespondents = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setElementClicked((prevRespondent) => {
      const updatedRespondent = { ...prevRespondent }
      if (theKeys.includes(name)) {
        updatedRespondent.v[name] = value
      } else {
        updatedRespondent.isDisabled = value
      }
      return updatedRespondent
    })
    if (name !== "isDisabled") {
      setUpdatedVariables({
        ...updatedVariables,
        [name]: value
      })
    }
  }

  const updateRespondents = () => {
    let body;
    if (selectedRespondents.length > 1) {
      body = selectedRespondents.map((resp: TODO) => {
        return {
          id: resp.id,
          isDisabled: elementClicked.isDisabled,
          v: updatedVariables,
          etag: resp._etag
        }
      })
    } else {
      body = [{
        id: elementClicked.id,
        isDisabled: elementClicked.isDisabled,
        v: updatedVariables,
        etag: elementClicked._etag
      }]
    }

    updateRespondentsReq(`projects/${projectId}/surveys/${surveyId}/recipientsv2/${id}`, token, body)
      .then((res: TODO) => {
        if (res && res.status === 400) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "Something went wrong" } });
          setOpenSidebar(false)
        } else {
          dispatch({ type: 'SHOW_SUCCESS_NOTIFICATION', payload: { msg: "Respondent was successfully updated" } });
          const updatedBody = JSON.parse(JSON.stringify(sortBody))
          for (const [key, value] of Object.entries(filters)) {
            updatedBody.stringFilters.push(`${key}:${value}`)
          }
          fetchPostJson(`projects/${projectId}/surveys/${surveyId}/recipientsv2/${id}`, token, updatedBody)
            .then((res: IPostRes) => {
              setOpenSidebar(false)
              if (res.error || res.message) {
                dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
              } else {
                setRecipientList(res)
                setTheKeys(Object.keys(res.recipients[0].v))
              }
            })
        }
        setOpenSidebar(false)
        setSelectedRespondents([])
        setUpdatedVariables({})
      })
  }

  const selectRespondents = (e: TODO, recipient: TODO) => {
    const selectedRecipientListCopy: TODO = [...selectedRespondents]
    if (e.target.checked) {
      recipient.selected = true
      selectedRecipientListCopy.push(recipient)
      setSelectedRespondents(selectedRecipientListCopy)
    } else {
      const recipientToBeRemovedIndex = selectedRecipientListCopy.findIndex((obj: TODO) => obj.id === recipient.id)
      selectedRecipientListCopy.splice(recipientToBeRemovedIndex, 1)
      setSelectedRespondents(selectedRecipientListCopy)
    }
  }

  useEffect(() => {
    // The call that applies the search/filtering
    if (!didMount) {
      setDidMount(true)
      const body = searchInput !== "" ? bodyForSearch() : {}
      fetchPostJson(`projects/${projectId}/surveys/${surveyId}/recipientsv2/${id}`, token, body)
        .then((res: IPostRes) => {
          if (res.error || res.message) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
          } else {
            setRecipientList(res)
          }
        })
    }
  }, [searchInput, bodyForSearch, id, projectId, surveyId, token, dispatch, didMount])

  const handleColumnClick = (columnClicked: string) => {
    // Logic for preparing the body for the call that applies the sorting
    setDidMount(true)
    const body: IBodySearch = { stringFilters: [] }
    for (const [key, value] of Object.entries(filters)) {
      body.stringFilters.push(`${key}:${value}`)
    }
    switch (orderBy.direction) {
      case "":
        setOrderBy({
          column: columnClicked,
          direction: "ascending",
          number: 1
        })
        body.orderBy = columnClicked
        body.orderByDirection = 1
        break;
      case "ascending":
        setOrderBy({
          column: columnClicked,
          direction: "descending",
          number: 0
        })
        body.orderBy = columnClicked
        body.orderByDirection = 0
        break;
      case "descending":
        setOrderBy({
          column: columnClicked,
          direction: "",
          number: 0
        })
        break
    }
    setSortBody(body)
    sortWithDebounce()
  }

  useEffect(() => {
    // The call that applies the sorting
    if (shouldSort) {
      setShouldSort(false)
      fetchPostJson(`projects/${projectId}/surveys/${surveyId}/recipientsv2/${id}`, token, sortBody)
        .then((res: IPostRes) => {
          if (res.error || res.message) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
          } else {
            setRecipientList(res)
          }
        })
    }
  }, [shouldSort, sortBody, dispatch, id, projectId, surveyId, token])

  const handleRecipientListClick = (respondentId: string) => {
    fetchGetJson(`projects/${projectId}/surveys/${surveyId}/recipientsv2/${id}/${respondentId}`, token)
      .then((res: IRespondent) => {
        setElementClicked(res)
      })
    setOpenSidebar(true)
  };

  //this function exports the whole respondents. We need to revisit this function as to get the headers so that we don't harcode the file name
  const exportToExcel = () => {
    setIsExporting(true)
    fetchGetBlob(`projects/${projectId}/surveys/${surveyId}/recipientsv2export/recipientlist/${id}`, token)
      .then((res: IResBlob) => {
        setIsExporting(false)
        if (res.error || res.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
        } else {
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Respondent list has been exported successfully' } })
          fileManagerDownloadHandler(res, { displayName: `${recipientListName}.xlsx` })
        }
      })
  };

  const updateMultipleRespondents = () => {
    setOpenSidebar(true)
    setElementClicked({
      cIds: "",
      id: "",
      isDisabled: false,
      optOut: false,
      rId: "",
      shortId: "",
      t: [],
      v: {
        name: "",
        altid: "",
        email: "",
        age: "",
        gender: "",
      },
      status: "",
      _etag: ""
    })
  }

  return (
    <div>
      {openSidebar &&
        <ImportedRecipientSidebar
          setOpenSidebar={setOpenSidebar}
          elementClicked={elementClicked}
          recipientListName={recipientListName}
          handleUpdateRespondents={handleUpdateRespondents}
          updateRespondents={updateRespondents}
          theKeys={theKeys}
          selectedRespondents={selectedRespondents}
        />
      }
      <div className="d-flex flex-grow-1 justify-content-end align-items-center pb-3 px-4">
        <button type='button' disabled={isExporting} onClick={exportToExcel} className="btn btn-primary">
          {
            isExporting &&
            <div className="spinner-border spinner-border-sm mr-2" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          }
          <span>Export to Excel</span>
        </button>
      </div>
      <ImportedRecipientListTable
        handleColumnClick={handleColumnClick}
        orderBy={orderBy}
        recipientList={recipientList}
        handleRecipientListClick={handleRecipientListClick}
        theKeys={theKeys}
        handleSearchChange={handleSearchChange}
        selectRespondents={selectRespondents}
        selectedRespondents={selectedRespondents}
      />
      <div className="d-flex justify-content-center py-2">
        {recipientList?.continuationToken ? <button type='button' className="btn btn-transparent py-1" onClick={handleMoreRecipientsClick}>Load more</button> : null}
      </div>
      {selectedRespondents.length > 1 &&
        <div className='p-3 bg-primary d-flex respondents-floating-actions'>
          <button type='button' className='btn btn-primary border border-dark py-1 px-2' onClick={() => updateMultipleRespondents()}>
            <Icon className='fill-white' type='dashboard-edit' />
            <span className='px-1 strong text-white'>Edit respondents</span>
          </button>
          <button type='button' className='btn btn-primary border border-dark p-1 ml-3'>
            <Icon className='fill-white' type='delete' />
          </button>
        </div>
      }
    </div>
  )
};