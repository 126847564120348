const initialState = {
  theState: {
    data: {
      projectId: '',
      projectName: '',
      documentId: '',
      documentName: '',
      documentDescription: "",
    },
    selectedSession: {
      name: '',
      id: '',
      lastSaved: ''
    },
    documentRequestInProgress: false,
    projectRequestInProgress: false
  }
};

function breadcrumbStateReducer(state = initialState.theState, action) {
  switch (action.type) {
    case 'SET_INITIAL_BREADCRUMB_DATA':
      return {
        ...state,
        data: {
          projectId: '',
          projectName: '',
          documentId: '',
          documentName: '',
          documentDescription: "",
        }
      }
    case 'UPDATE_PROJECT_BREADCRUMB':
      const copyState = { ...state.data }
      return {
        ...state,
        data: {
          ...copyState,
          projectId: action.payload.id,
          projectName: action.payload.name,
        }
      }
    case 'UPDATE_DOCUMENT_BREADCRUMB':
      const copyData = { ...state.data }
      return {
        ...state,
        data: {
          ...copyData,
          documentId: action.payload.id,
          documentName: action.payload.name,
          documentDescription: action.payload.documentDescription,
        }
      }
    case 'UPDATE__PROJECT_AND_DOCUMENT_BREADCRUMB':
      return {
        ...state,
        data: {
          documentId: action.payload.documentId,
          documentName: action.payload.documentName,
          projectId: action.payload.projectId,
          projectName: action.payload.projectName
        }
      }
    case 'UPDATE_SELECTED_SESSION':
      return {
        ...state,
        selectedSession: action.payload
      }

    case 'UPDATE_SELECTED_SESSION_LAST_SAVED':
      return {
        ...state,
        selectedSession: {
          ...state.selectedSession,
          lastSaved: action.payload
        }
      }
    case 'SET_DOCUMENT_REQUEST_IN_PROGRESS': {
      return {
        ...state,
        documentRequestInProgress: action.payload
      }
    }
    case 'SET_PROJECT_REQUEST_IN_PROGRESS': {
      return {
        ...state,
        projectRequestInProgress: action.payload
      }
    }
    default:
      return { ...state };
  }
}

export default breadcrumbStateReducer;