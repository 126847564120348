import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { ErrorMessage } from '../../../shared/ErrorMessage/ErrorMessage';

interface BaseDeleteModalInterface {
  onSubmit: (e: any) => void;
  onCancel: (e: any) => void;
  error: { show: boolean; message: string };
  title: string;
  modalText: string;
  cancelBtn: string;
  submitBtn: string;
}

export const BaseDeleteModal = ({
  onSubmit,
  onCancel,
  error,
  title,
  modalText,
  cancelBtn = 'Cancel',
  submitBtn = 'Delete',
}: BaseDeleteModalInterface) => {
  return (
    <Dialog title={title} onClose={onCancel}>
      <div className="p-3">
        <div className="alert alert-light-blue shadow-sm mb-3 d-flex p-3 align-items-center">
          <i className="fas fa-info-circle mr-3" />
          <span>{modalText}</span>
        </div>
        {error.show && <ErrorMessage type="alert" errorMessage={error.message} />}
      </div>
      <DialogActionsBar>
        <Button className="k-button btn btn-secondary" onClick={onCancel}>
          {cancelBtn}
        </Button>
        <Button className="btn btn-danger" onClick={onSubmit}>
          {submitBtn}
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};
