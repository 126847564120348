import React from 'react'

export default () => {
  return (
    <div className="d-flex align-items-baseline">
      <i className="fa fas fa-sm fa-lock mr-2"></i>
      <div className="d-flex flex-column">
        <span>This feature is not available on your current plan.</span>
      </div>
    </div>
  )
}