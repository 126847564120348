import type React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from '../../../shared/Icon/Icon';
import { Skeleton } from '../../../shared/Skeleton/Skeleton';
import type { RecentData } from '../../../../interfaces/dashboardDataInterfaces/dashboardDataInterfaces';
import { fetchGetJson as getRecentProjects } from '../../../../services/services';
import type { RootState } from '../../../../store/reducers/rootReducer';

export const DashboardRecentProjects: React.FC = () => {
  const dispatch = useDispatch()
  const recentData: RecentData = useSelector((theState: TODO) => (theState.recentDataReducer))
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);

  const [isLoadingProjects, setIsLoadingProjects] = useState<boolean>(recentData?.projectsLoaded === true ? false : true);
  const [error, setError] = useState(false)

  useEffect(() => {
    getRecentProjects("sta/statistics/recentlymodifiedprojects", token)
      .then((res: TODO) => {
        setIsLoadingProjects(false)
        if (res && !res.error && !res.message) {
          if (Array.isArray(res)) {
            dispatch({ type: "SET_RECENT_PROJECTS_DATA", payload: res })
          } else setError(true)
        } else setError(true)
      })
  }, [token, dispatch])

  return (
    <div className="col-lg-6">
      {
        error &&
        <div className="dashboard-no-activity-card text-muted gap-md">
          <i className="fal fa-exclamation-circle fa-2x" />
          <p className='m-0'>Failed to load projects</p>
        </div>
      }
      <div className="dashboard-card projects">
        <div className="d-flex flex-wrap h-100 justify-content-between">
          <div className="d-flex w-100 h-100 flex-column gap-lg">
            <div className="d-flex w-100 justify-content-between">
              <p className='m-0 h5'>
                <strong>Your projects</strong>
              </p>
              <Link to="/projects" className="d-flex align-items-center show-more">
                <p className="text-muted m-0">View all projects</p>
              </Link>
            </div>
            <div className="d-flex w-100 flex-wrap">
              {
                !error &&
                <div
                  onClick={() => dispatch({ type: "UPDATE_SHOW_ADD_PROJECT_MODAL", payload: true, })}
                  className="col-lg-6 project cursor-pointer">
                  <div className="d-flex align-items-center item">
                    <div className="rounded-circle d-flex justify-content-center align-items-center text-primary circle create-new">
                      <Icon type="add" />
                    </div>
                    <p className="mb-0">Create project</p>
                  </div>
                </div>
              }
              {
                isLoadingProjects ?
                  Array(7).fill("").map((_, index) => (
                    <div key={index} className="col-lg-6 p-2 d-flex align-items-center gap-md" style={{ pointerEvents: isLoadingProjects ? 'none' : 'unset' }}>
                      <Skeleton shape="circle" style={{ width: '48px', height: '48px' }} />
                      <Skeleton style={{ width: 'unset' }} className="flex-grow-1" />
                    </div>
                  ))
                  :
                  recentData?.recentProjects.map((project, key: number) => (
                    <Link
                      key={key}
                      to={`projects/${project.id}`}
                      className="col-lg-6 project"
                      style={{ pointerEvents: isLoadingProjects ? 'none' : 'unset' }}>
                      <div className="d-flex align-items-center item">
                        <div
                          style={{ backgroundColor: project.iconBackgroundColor ? project.iconBackgroundColor : "" }}
                          className={`rounded-circle d-flex justify-content-center align-items-center text-white circle ${project.iconBackgroundColor ? "" : "bg-primary"}`}>
                          <i className="fas fa-folder" />
                        </div>
                        <p className="mb-0 text-primary text-truncate">{project.name}</p>
                      </div>
                    </Link>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}