import { DropDownButton } from "@progress/kendo-react-buttons";
import type { Theme, Themes } from "../../../../../../../../interfaces/surveyInterfaces/themeTypes"
import { Icon } from "../../../../../../../shared/Icon/Icon";

type Props = {
  themes: Themes;
  theme: Theme;
  onSelectTheme: (themeId: string) => void;
  onThemeDropdownItemClick: (itemName: string, theme: Theme) => void;
  setShowApplyModal: ({ show, theme }: { show: boolean, theme: Theme }) => void;
}

type DropDownItem = {
  id: "delete" | "rename" | "duplicate";
  text: "Delete" | "Rename" | "Duplicate";
  icon: "dashboard-edit" | "delete-alt" | "copy"
}

export const ThemeCard = ({ themes, theme, onSelectTheme, onThemeDropdownItemClick, setShowApplyModal }: Props) => {
  const dropdownItemRender = ({ item }: { item: DropDownItem }) => {
    return (
      <div id={item.id} className="d-flex align-items-center">
        <Icon
          type={item.icon}
          className={`mr-2 ${item.text === 'Delete' ? 'fill-danger' : ''}`}
        />
        <span className={`mr-5 ${item.text === 'Delete' ? 'text-danger' : ''}`}>
          {item.text}
        </span>
      </div>
    )
  }

  return (
    <div className="d-flex flex-column gap-sm" id={theme.id}>
      <div
        onClick={() => onSelectTheme(theme.id)} style={{ borderRadius: '0.5rem' }}
        className={`cursor-pointer custom-theme ${theme.id === themes.selected ? 'active-theme' : ''}`}
      >
        <div
          style={{ backgroundColor: theme?.themeSettings?.find(setting => setting?.id === "pageBackgroundColor")?.value }}
          className="d-flex flex-column align-items-center justify-content-end px-3 pt-2 pb-4 w-100 custom-theme-inner overflow-hidden rounded">
          {
            themes?.selected === theme.id &&
            <div className="custom-theme-inner-selected-overlay">
              <button type="button" onClick={() => setShowApplyModal({ show: true, theme: theme })} className="btn btn-shadow">
                <strong>Apply</strong>
              </button>
            </div>
          }
          {
            (theme.type === "custom-theme" || theme.type === "custom-themev2" || theme.type === "survey-theme" || theme.type === "survey-themev2") &&
            <span className='theme-actions-dropdown' onClick={(e) => e.stopPropagation()}>
              <DropDownButton
                iconClass='far fa-ellipsis-h'
                items={[{ id: "rename", text: 'Rename', icon: 'dashboard-edit' }, { id: "duplicate", text: 'Duplicate', icon: 'copy' }, { id: "delete", text: 'Delete', icon: 'delete-alt' }]}
                buttonClass={'btn-shadow p-1 border-0 w-auto h-auto'}
                popupSettings={{ popupClass: 'themes-dropdown' }}
                itemRender={dropdownItemRender}
                onItemClick={(e) => (onThemeDropdownItemClick(e.item.id, theme))}
              />
            </span>
          }
          <span style={{ backgroundColor: theme?.themeSettings?.find(setting => setting?.id === "buttonsBackgroundColor")?.value }} className='theme-bar-indicator w-75' />
          <h4 style={{ color: theme?.themeSettings?.find(setting => setting.id === "pageForegroundColor")?.value }} className='mb-1 strong'>Aa</h4>
          <div className='d-flex flex-row'>
            <div className='mr-1' style={{ height: '10px', width: '24px', borderRadius: '8px', backgroundColor: theme?.themeSettings?.find(el => el.id === 'buttonsBackgroundColor')?.value }} />
            <div style={{ height: '10px', width: '24px', borderRadius: '8px', border: '1.5px solid', borderColor: theme?.themeSettings?.find(el => el.id === 'buttonsBackgroundColor')?.value }} />
          </div>
        </div>
      </div>
      <p title={theme.name} className="m-0 theme-title">{theme.name}</p>
    </div>
  )
}