import type { InboxItem } from "../../InboxDrawer/InboxDrawer"

export const returnisInboxItemDownloadButtonDisabled = (item: InboxItem, notification: { completedStatus: string }) => {
  let isButtonDisabled = true

  if (item.status === 'Completed') {
    if (item.completedStatus === 'Success') {
      isButtonDisabled = false
    } else {
      isButtonDisabled = true
    }
  } else {
    if (Object.keys(notification).length > 0) {
      if (notification.completedStatus === 'Success') {
        isButtonDisabled = false
      } else {
        isButtonDisabled = true
      }
    }
  }

  return isButtonDisabled
}