export default (e, hidepctsign) => {
  let value
  if (e.value < 0) {
    value = e.value * -1
  }
  else { value = e.value }
  if (hidepctsign) {
    return `${value}`
  }
  else {
    return `${value}%`
  }
}