import React, { useState, useRef, useEffect } from 'react';

import GroupSettings from '../../../../helpers/GroupSettings/GroupSettings';

export default function ThemeSettings({ settings, settingsValues, fonts, dropdownValues, onChangeSettingsValues, onShowFileManager, type, hasBeenEditedAsCss }) {
  const wrapperRef = useRef(null);
  const [dropdownPopupSettings, setDropdownPopupSettings] = useState({})
  const groups = settings.map(el => el.group).filter((item, i) => settings.map(el => el.group).indexOf(item) === i)

  useEffect(() => {
    setDropdownPopupSettings({ className: "dropdown-hight-unset", appendTo: wrapperRef ? wrapperRef.current : undefined })
  }, [wrapperRef])

  return (
    <div className={`d-flex flex-column flex-grow-1 h-100 border-bottom ${type === 'standard-theme' || hasBeenEditedAsCss ? "blur pointer-events-none overflow-hidden" : "overflow-auto"} position-relative`} ref={wrapperRef}>
      <GroupSettings
        dropdownPopupSettings={dropdownPopupSettings}
        settings={settings}
        groups={groups}
        settingsValues={settingsValues}
        fonts={fonts}
        dropdownValues={dropdownValues}
        onChangeSettingsValues={onChangeSettingsValues}
        onShowFileManager={onShowFileManager}
        type={type}
      />
    </div >
  )
}