import { Fragment } from 'react';

export const FullHeightOverlaySpinner = () => {
  const sharedClasses = "d-flex flex-grow-1 justify-content-center align-items-center align-self-center w-100 h-100 position-absolute";
  return (
    <Fragment>
      <div className={`${sharedClasses} bg-white overlay-spinner-background`} />
      <div className={`${sharedClasses}`}>
        <div className="spinner-border overlay-spinner text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </Fragment>
  )
}