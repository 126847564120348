export const formatFormData = (formData: { [k: string]: FormDataEntryValue }, etag: string | null, useUniqueUrls: boolean, audienceAction?: "new" | "copy" | "edit") => {
  return {
    displayName: formData.audienceDisplayName,
    language: formData.language,
    country: formData.country,
    surveyType: formData.surveyType,
    surveyLiveUrl: formData.liveUrl,
    surveyTestUrl: formData.testUrl,
    pii: formData.containsPersonalData === "true",
    allowReentry: formData.allowReentry === "true",
    redirectCaptureParameter: formData.redirectCaptureParameter,
    integrationPartners: null,
    ...(formData.respondentStatuses || formData.audienceIds ? {
      exclusion: {
        ...(formData.respondentStatuses ? { respondentStatuses: JSON.parse(formData.respondentStatuses as string) } : { respondentStatuses: null }),
        ...(formData.exclusionDurationDays ? { exclusionDurationDays: Number(formData.exclusionDurationDays) } : { exclusionDurationDays: null }),
        ...(formData.audienceIds ? { audienceIds: JSON.parse(formData.audienceIds as string).map((item: { id: string, displayName: string }) => item.id) } : { audienceIds: null })
      }
    } : { exclusion: null }),
    ...(formData.useUniqueUrls && audienceAction === "edit" && { uniqueUrlsLiveFileId: formData.uniqueUrlsLiveFileIdVerified ? formData.uniqueUrlsLiveFileIdVerified : "" }),
    ...(formData.useUniqueUrls && audienceAction !== "edit" && { uniqueUrlsLiveFileId: formData.uniqueUrlsLiveFileIdVerified ? formData.uniqueUrlsLiveFileIdVerified : null }),
    ...(formData.useUniqueUrls && formData.uniqueUrlsTestFileIdVerified && { uniqueUrlsTestFileId: formData.uniqueUrlsTestFileIdVerified }),
    uniqueUrlsWidParam: formData.useUniqueUrls ? formData.uniqueUrlsWidParam : null,
    etag: etag ? etag : null,
    useUniqueUrls: useUniqueUrls,
    ...(audienceAction === "new" && { batchDisplayName: formData.batchDisplayName }),
    ...(audienceAction !== "edit" && { currency: formData.currency }),
    ...(audienceAction !== "edit" && { targetType: formData.targetType }),
    ...(audienceAction !== "edit" && { targetEndDate: formData.targetEndDate }),
    ...(audienceAction !== "edit" && { launchDate: formData.launchDate }),
    ...(audienceAction !== "edit" && { quantity: Number(formData.quantity) }),
    ...(audienceAction !== "edit" && { lengthOfInterview: Number(formData.lengthOfInterview) }),
    ...(audienceAction !== "edit" && { incidenceRate: Number(formData.incidenceRate) }),
    ...(audienceAction !== "edit" && { clientCPI: Number(formData.clientCPI) }),
    ...(audienceAction !== "edit" && { cpi: Number(formData.cpi) }),
    ...(audienceAction !== "edit" && { completeAsap: formData.completeAsap === "true" }),
    ...(audienceAction === "new" && formData.surveyType === "Recontact" ? { originalIdRecontactVariable: formData.originalIdRecontactVariable } : { originalIdRecontactVariable: null }),
    ...(audienceAction === "new" && formData.surveyType === "Recontact" ? { walrIdRecontactVariable: formData.walrIdRecontactVariable } : { walrIdRecontactVariable: null }),
    ...(audienceAction === "new" && formData.surveyType === "Recontact" ? { recontactsFileId: formData.recontactsFileId } : { recontactsFileId: null })
  }
}