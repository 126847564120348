export const returnSortDirectionIcon = (sortBy: string, sortType: { sortBy: string, direction: string }) => {
  if (sortType.sortBy) {
    if (sortBy === sortType.sortBy) {
      if (sortType.direction === 'asc') {
        return 'fa-long-arrow-alt-up'
      } else if (sortType.direction === 'desc') {
        return 'fa-long-arrow-alt-down'
      }
    }
  }
}