import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import usePrevious from '../customHooks/usePrevious';
import { BreadcrumbSessionItem } from './BreadcrumbSessionItem/BreadcrumbSessionItem';
import { fetchGetJson as getProjectById, fetchGetJson as getProjectDocuments } from '../../../services/services';

const statusTypes = [{ id: 0, name: "Draft" }, { id: 1, name: "Open" }, { id: 2, name: "Closed" }]

export const Breadcrumb = ({ routePath, activeRouteTitle }) => {
  const { token } = useSelector((state) => state.tokenStateReducer);
  const dispatch = useDispatch();
  const { surveyStatus } = useSelector(theState => theState.surveyInitialDataReducer, shallowEqual).theData
  const { data, selectedSession, documentRequestInProgress, projectRequestInProgress } = useSelector((theState) => theState.breadcrumbStateReducer, shallowEqual);
  const prevData = usePrevious(data);

  const routeLength = routePath.split('/').length;
  let routes = routePath.split('/');
  if (routes.length > 0) {
    routes = routes.slice(1);
  }

  useEffect(() => {
    if (!routePath.includes('analyze')) {
      dispatch({ type: 'UPDATE_SELECTED_SESSION', payload: { name: '', id: '', lastSaved: '' } });
    }
  }, [routePath, dispatch]);

  useEffect(() => {
    if (token) {
      if (data && JSON.stringify(data) !== JSON.stringify(prevData)) {
        if (data.projectId === '') {
          if (routes.length >= 2 && routes[1] !== 'add-project' && !projectRequestInProgress) {
            dispatch({ type: 'SET_PROJECT_REQUEST_IN_PROGRESS', payload: true });
            getProjectById(`projects/${routes[1]}`, token).then((res) => {
              dispatch({ type: 'SET_PROJECT_REQUEST_IN_PROGRESS', payload: false });
              if (res && (res.message || res.error)) {
                dispatch({ type: 'UPDATE_ERROR_MESSAGE', payload: { msg: res.message ? res.message : res.error, type: 'modal' } });
              } else {
                dispatch({ type: 'UPDATE_PROJECT_BREADCRUMB', payload: { id: routes[1], name: res.name, description: res.description } });
              }
            });
          }
        }
        if (data.documentId === '') {
          if (routes[2] && routes[2] !== 'import' && routes[2] !== 'edit' && !documentRequestInProgress) {
            dispatch({ type: 'SET_DOCUMENT_REQUEST_IN_PROGRESS', payload: true });
            getProjectDocuments(`projects/${routes[1]}/documents`, token)
              .then((res) => {
                dispatch({ type: 'SET_DOCUMENT_REQUEST_IN_PROGRESS', payload: false });
                if (res && (res.message || res.error)) {
                  dispatch({ type: 'UPDATE_ERROR_MESSAGE', payload: { msg: res.message ? res.message : res.error, type: 'modal' } });
                } else {
                  let findDocument;
                  if (routes[3] !== "survey-builder" && routes[3] !== "panel" && routes[3] !== "workflow" && routes[3] !== "pinboards") {
                    if (activeRouteTitle === 'datasets') {
                      findDocument = res.datasets.find((item) => item.id === routes[2]);
                    } else if (activeRouteTitle === 'surveys') {
                      findDocument = res.surveys.find((item) => item.id === routes[2]);
                    }
                  } else if (routes[3] === 'survey-builder') {
                    findDocument = res.surveys.find((item) => item.id === routes[2]);
                  } else if (routes[3] === 'workflow') {
                    findDocument = res.workflows.find((item) => item.id === routes[2]);
                  } else if (routes[3] === "pinboards") {
                    findDocument = res.pinboards.find((item) => item.id === routes[2]);
                  }
                  dispatch({
                    type: 'UPDATE_DOCUMENT_BREADCRUMB',
                    payload: {
                      id: routes[2],
                      name: findDocument ? findDocument.name : null,
                      documentDescription: findDocument?.description
                    },
                  });
                }
              });
          }
        }
      }
    }
  }, [data, routes, token, prevData, documentRequestInProgress, projectRequestInProgress, activeRouteTitle, dispatch]);

  const breadCrumbItems = [];
  let breadCrumbTheme = 'light';
  if (window.location.href.includes('analyze')) {
    breadCrumbTheme = 'light-analyze';
  } else if (window.location.href.includes('survey-builder') || window.location.href.includes('workflow')) {
    breadCrumbTheme = 'white';
  }

  routes.forEach((route, id) => {
    if (id === routes.length - 1 && (routes[4] === 'datasets' || routes[4] === 'surveys')) {
      breadCrumbItems.push(
        <li key={id} className="breadcrumb-item active d-flex align-items-center cursor-default">
          {route === data.projectId ? data.projectName : null}
          {routePath.includes('analyze') && selectedSession.name && <BreadcrumbSessionItem />}
        </li>
      );
    } else if ((activeRouteTitle === 'surveys' || activeRouteTitle === 'datasets' || activeRouteTitle === 'samples' || activeRouteTitle === 'workflows') && id === routes.length - 3) {
      breadCrumbItems.push(
        <li key={id} className="breadcrumb-item active cursor-default">
          {route === data.documentId ? data.documentName : null}
        </li>
      );
    } else if ((activeRouteTitle === "survey-builder" || activeRouteTitle === "panel" || activeRouteTitle === "workflow") && id === routes.length - 2) {
      breadCrumbItems.push(
        <li key={id} className="breadcrumb-item active cursor-default">
          {route === data.documentId ? data.documentName : null}
        </li>
      );
    } else if (activeRouteTitle === "pinboards" && id === routes.length - 2) {
      breadCrumbItems.push(
        <li key={id} className="breadcrumb-item active cursor-default">
          <span className="breadcrumb-slash mr-1">/</span>{route === data.documentId ? data.documentName : null}
        </li>
      );
    } else {
      if (route === data.projectId) {
        breadCrumbItems.push(
          <li key={id} className="breadcrumb-item">
            <Link
              className=""
              to={{
                pathname: `${routePath
                  .split('/')
                  .slice(0, routeLength - (routeLength - (id + 1)))
                  .join('/')}/${route}`,
              }}
            >
              {data.projectName}
            </Link>
          </li>
        );
      } else if (route === data.dpId) {
        breadCrumbItems.push(
          <li key={id} className="breadcrumb-item">
            <Link
              className=""
              to={{
                pathname: `${routePath
                  .split('/')
                  .slice(0, routeLength - (routeLength - (id + 1)))
                  .join('/')}/${route}`,
              }}
            >
              {data.documentName}
            </Link>
          </li>
        );
      } else {
        if (route !== 'analyze') {
          if (route !== "survey-builder") {
            if (route !== 'projects' && activeRouteTitle === 'survey-builder') {
              breadCrumbItems.push(
                <li key={id} className="breadcrumb-item">
                  <Link className=""
                    to={{
                      pathname: `${routePath.split('/').slice(0, routeLength - (routeLength - (id + 1))).join('/')}/${route}`,
                    }}>{route}</Link>
                </li>
              )
            }
          } else {
            return null
          }
        }
      }
    }
  });

  return (
    <nav aria-label="breadcrumb">
      <ol className={`breadcrumb d-flex align-items-center rounded-0 ${(activeRouteTitle === 'survey-builder' || activeRouteTitle === 'workflow') ? 'h-56' : (activeRouteTitle === 'analyze' || activeRouteTitle === 'pinboards') ? 'h-48' : ''}  bg-${breadCrumbTheme}`}>
        {
          activeRouteTitle !== 'survey-builder' &&
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
        }
        {Object.keys(data).length > 0 ? breadCrumbItems.map((breadCrumb) => breadCrumb) : null}
        {
          // Show survey status next to last breadcrumb item
          activeRouteTitle === 'survey-builder' && typeof surveyStatus === 'number' &&
          <li className="survey-status">
            <span className={`ml-3 ${surveyStatus === 1 ? "show" : surveyStatus === 2 ? "show closed" : "hide"}`}>
              <span className="bullet mr-1" />
              {statusTypes.find(el => el.id === surveyStatus).name}
            </span>
          </li>
        }
      </ol>
    </nav>
  );
};