import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import fileManagerActionButtonsData from '../../../components/shared/FileManager/fileManagerActionButtonsData/fileManagerActionButtonsData'
import type { FileManager, FileManagerActionButton, FileManagerItem, FileManagerState } from '../../../interfaces/fileManagerInterfaces/fileManagerInterfaces'

const initialState = {
  expandedDisplayType: 'details',
  actionButtons: fileManagerActionButtonsData(),
  checkedFiles: [],
  isLoading: true,
  showNewFolderModal: false,
  showDeleteConfirmationModal: false,
  selectCreatedFolder: '',
  deleteRootDirectoryModal: {
    show: false,
    directory: null
  },
  breadCrumbData: [],
  expandedFilter: { type: 'date', filter: 'desc' },
  activeType: '',
  state: {
    data: [],
    dataState: {
      sort: [{ field: 'name', dir: 'asc' }],
      filter: []
    },
    expanded: [],
    selected: null
  },
  expandedState: {
    data: [],
    dataState: {
      sort: [{ field: 'name', dir: 'asc' }],
      filter: []
    },
    expanded: [],
    selected: null
  }
} as FileManager

const fileManagerSlice = createSlice({
  name: 'fileManager',
  initialState,
  reducers: {
    updateExpandedDisplayType(state, action: PayloadAction<string>) {
      state.expandedDisplayType = action.payload
    },
    updateActiveType(state, action: PayloadAction<string>) {
      state.activeType = action.payload
    },
    updateCheckedFiles(state, action: PayloadAction<FileManagerItem[]>) {
      state.checkedFiles = action.payload
    },
    updateActionButtons(state, action: PayloadAction<FileManagerActionButton[]>) {
      state.actionButtons = action.payload
    },
    updateIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    updateShowNewFolderModal(state, action: PayloadAction<boolean>) {
      state.showNewFolderModal = action.payload
    },
    updateShowDeleteConfirmationModal(state, action: PayloadAction<boolean>) {
      state.showDeleteConfirmationModal = action.payload
    },
    updateDeleteRootDirectoryModal(state, action: PayloadAction<{ show: boolean, directory: { displayName: string, directoryReference: string } | null }>) {
      state.deleteRootDirectoryModal = action.payload
    },
    updateSelectCreatedFolder(state, action: PayloadAction<string>) {
      state.selectCreatedFolder = action.payload
    },
    updateBreadCrumbData(state, action: PayloadAction<FileManagerItem[]>) {
      state.breadCrumbData = action.payload
    },
    updateExpandedFilter(state, action: PayloadAction<{ type: string, filter: string }>) {
      state.expandedFilter = action.payload
    },
    updateFileManagerState(state, action: PayloadAction<FileManagerState>) {
      state.state = action.payload
    },
    updateFileManagerExpandedState(state, action: PayloadAction<FileManagerState>) {
      state.expandedState = action.payload
    },
    //@ts-ignore TODO
    clearFileManager(state) {
      state = initialState
    },
  },
})

export const {
  updateExpandedDisplayType,
  updateActiveType,
  updateCheckedFiles,
  updateActionButtons,
  updateIsLoading,
  updateShowNewFolderModal,
  updateShowDeleteConfirmationModal,
  updateSelectCreatedFolder,
  updateDeleteRootDirectoryModal,
  updateBreadCrumbData,
  updateExpandedFilter,
  updateFileManagerState,
  updateFileManagerExpandedState,
  clearFileManager
} = fileManagerSlice.actions
export default fileManagerSlice.reducer