import React from 'react';

export default ({ submitAction, isLoading, onHide, stepperValue, steps, handleChange, onSubmitHandler, formRef }) => {
  const onStepperChangeHandler = (value) => {
    if (formRef && formRef.current) {
      const inputs = Array.from(formRef.current.elements);
      const invalidInput = inputs.find((input) => !input.checkValidity());
      if (invalidInput) return invalidInput.reportValidity();
    }
    handleChange({ value });
  };

  return (
    <React.Fragment>
      <button type="button" className="k-button k-button-solid-base" onClick={onHide}>
        Cancel
      </button>
      {
        <button
          disabled={stepperValue === 0}
          type="button"
          className="k-button k-button-solid-primary"
          onClick={onStepperChangeHandler.bind(null, stepperValue - 1)}
        >
          Back
        </button>
      }
      {
        stepperValue !== steps - 1 ? (
          <button type="button" className="k-button k-button-solid-primary" onClick={onStepperChangeHandler.bind(null, stepperValue + 1)}>
            Next
          </button>
        ) : (
          <button type="submit" disabled={isLoading} onClick={onSubmitHandler} className="k-button k-button-solid-primary">
            {submitAction}
          </button>
        )
      }
    </React.Fragment >
  );
};

