import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'

interface Props {
  onHide: Function
  platformId: number | null
}

export const SaveSupplierSuccessModal = ({ onHide, platformId }: Props) => {

  return (
    <Dialog width={"35%"} className='reconcile-modal' contentStyle={{ padding: 0, fontSize: 14 }} title={"Add new supplier"} onClose={() => onHide("Suppliers Success")}>
      <div className='submitted-content' >
        <div className='d-flex align-items-center row p-2 m-4 submit-info border rounded'>
          <i style={{ width: 24, height: 24 }} className="fas fa-check bg-success mr-2"></i>
          <p className='pt-3' >Your new suppliers has successfully been created.<br />The platform ID is <strong>{platformId}</strong></p>
        </div>
        <DialogActionsBar>
          <div className='p-2'>
            <button
              type='submit'
              className="k-button rounded bg-secondary text-primary medium mr-3"
              onClick={() => onHide("Suppliers Success")}
            >
              Close
            </button>
          </div>
        </DialogActionsBar>
      </div>
    </Dialog >
  )
}