export default (role, isEnterpriseUser, canSeeBackOffice) => {
  let items = []
  if (role) {
    if (role === 'owner') {
      if (isEnterpriseUser) {
        items = [
          { text: 'Admin settings', id: 'admin-settings', route: '/admin' },
          { text: 'Logout', id: 'logout' }
        ]
      } else {
        items = [
          { text: 'Admin settings', id: 'admin-settings', route: '/admin' },
          { text: 'Billing', id: 'billing', route: '/billing' },
          { text: 'Logout', id: 'logout' }
        ]
      }
    }
    else if (role === 'admin') {
      items = [
        { text: 'Admin settings', id: 'admin-settings', route: '/admin' },
        { text: 'Logout', id: 'logout' }
      ]
    }
    else {
      items = [
        { text: 'Logout', id: 'logout' }
      ]
    }
  }
  if (canSeeBackOffice) {
    items.unshift({ "text": "Backoffice", "icon": "fa fas fa-address-book", "route": "/backoffice" })
  }
  return items
}