import React, { useState } from 'react'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { Button } from '@progress/kendo-react-buttons'

import { BaseMultiSelect } from '../../../../../shared/Inputs';


export default ({ handleClose, data, tagsValues, updateTags }) => {
  const [tags, setTags] = useState(tagsValues)

  const listNoDataRender = () => {
    return (
      <h5 className="text-muted text-center">
        <span className="k-icon k-i-warning my-3" style={{ fontSize: '2.5em' }} />
        <br />
        <span>No tags created yet</span>
      </h5>
    );
  }

  return (
    <Dialog title="Edit tags" onClose={handleClose} width="500px">
      <div className="d-flex flex-column p-4">
        <span className="strong medium mb-2">Tags</span>
        <BaseMultiSelect
          data={data.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)}
          value={tags}
          textField="name"
          dataItemKey="id"
          name="tags"
          allowCustom={true}
          listNoDataRender={listNoDataRender}
          onChange={(e) => setTags(e.value)} />
      </div>
      <DialogActionsBar>
        <Button className="btn btn-secondary" onClick={handleClose}>Cancel</Button>
        <Button className="btn btn-primary" onClick={() => updateTags(tags)}>Save</Button>
      </DialogActionsBar>
    </Dialog>
  )
}