import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import GridLayout, { WidthProvider } from "react-grid-layout";
import JsxParser from "react-jsx-parser";
import QuillEditor from "../shared/QuillEditor/QuillEditor";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Tooltip } from '@progress/kendo-react-tooltip';

import { Icon } from "../shared/Icon/Icon";
import { useDebounce } from "../shared/customHooks/useDebounce";
import PinboardItemOptions from "./PinboardItemOptions/PinboardItemOptions";
import { FullHeightSpinner } from "../shared/FullHeightSpinner/FullHeightSpinner";
import ConfirmActionModal from "../shared/ConfirmActionModal/ConfirmActionModal";
import PinboardSidebarContents from "./PinboardSidebarContents/PinboardSidebarContents";
import { PinboardAppNavBar, PinboardMainNavBar, PinboardPreviewNavBar } from "./PinboardNavBars/PinboardNavBars";
import type { LayoutGridItem, DataGridItem, PinboardPage } from "../../interfaces/pinboardInterfaces/pinboardInterfaces";
import { returnUpdatedAnalysisBody } from "../Analysis/Analyze/components/shared/helpers/returnUpdatedAnalysisBody/returnUpdatedAnalysisBody";
import ChartTypeContent from "../Analysis/Analyze/components/dashboard/main/TabContent/ChartTabContent/ChartTypeContent/ChartTypeContent";
import EditProjectDocument from "../Project/ProjectDetails/ProjectDocumentsTable/EditProjectDocument/EditProjectDocument";
import { reorganizePinboardPages, returnAnalysisType, returnUpdatedLayoutGrid, returnUpdatedDataGrid, GridItemDefaultOptions as itemDefaultOptions, fetchGetPreviewPinboard, confirmDeletePinboard, } from "./pinboardHelpers";
import { fetchGetJson, fetchPostJson, fetchPatchJson, fetchDeleteWithBody } from "../../services/services";
import { ErrorMessage } from "../shared/ErrorMessage/ErrorMessage";
import { Checkbox } from "@progress/kendo-react-inputs";

interface Props {
  token: string
}

export const Pinboards = ({ token }: Props) => {
  document.title = "Pinboard – Walr";
  const params: { name: string, pinboard: string } = useParams()
  const history = useHistory();
  const dispatch = useDispatch();
  const breadcrumbs = useSelector((theState: TODO) => theState.breadcrumbStateReducer).data;
  const isPreview: string = history.location.pathname.split("/")[1] === "public" ? "public" : history.length < 2 ? "local" : "none";
  const currentProjectId = params.name;
  const currentPinboardId = params.pinboard;
  const shareLink = isPreview ? history.location.pathname.split("/")[3] : "none"
  const [didMount, setDidMount] = useState(true);
  const [loadingPinboard, setLoadingPinboard] = useState(true);
  const [loadingNewObject, setLoadingNewObject] = useState(false);
  const [savingLayout, setSavingLayout] = useState(false);
  const [sendGridLayout, setSendGridLayout] = useState(false);
  const [authenticateError, setAuthenticateError] = useState("");
  const shouldSaveLayout = useDebounce(() => setSendGridLayout(true), 2500)
  const ResponsiveGridLayout = useMemo(() => WidthProvider(GridLayout), []);
  const [freezeHeader, setFreezeHeader] = useState("");
  const [freezeColumn, setFreezeColumn] = useState("");

  // Conditional component variables
  const [showAppMenu, setShowAppMenu] = useState(false);
  const [showMainMenu, setShowMainMenu] = useState(false);
  const [requestPassword, setRequestPassword] = useState<{ show: boolean, password: string }>({ show: false, password: "" })
  const [showSidebar, setShowSidebar] = useState<{ show: boolean, type: string }>({ show: false, type: "add" });
  const [showOptions, setShowOptions] = useState<{ show: boolean, animate: boolean }>({ show: false, animate: false });
  const [showDeletePinboardModal, setShowDeletePinboardModal] = useState(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [showEditPinboardModal, setShowEditPinboardModal] = useState(false);

  // Data variables
  const [selectedItem, setSelectedItem] = useState<DataGridItem | null | undefined>(null);
  const [layoutGrid, setLayoutGrid] = useState<LayoutGridItem[]>([]);
  const [dataGrid, setDataGrid] = useState<DataGridItem[]>([]);
  const [searchState, setSearchState] = useState<{ value: string, type: string }>({ value: "", type: "add" });
  const pinboardData = useSelector((theState: TODO) => theState.pinboardStateReducer);
  const [analysisData, setAnalysisData] = useState<TODO>({ projects: [], projectsLoaded: false, datasets: [], sessions: [], sessionData: [], backendSessionData: {} });
  const [draggedItemData, setDraggedItemData] = useState<TODO>({ type: "" });

  const fetchGetPreviewPage = useCallback((pinboardRes: TODO, pageId: string, password?: string | null) => {
    const reorganizedPages = reorganizePinboardPages(JSON.parse(JSON.stringify(pinboardRes.pages)))

    fetchGetPreviewPinboard(shareLink, password ? password : null, pageId)
      .then((pageRes: TODO) => {
        if (pageRes.error || pageRes.message) {
          dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: pageRes.error ? pageRes.error : pageRes.message } })
        } else {
          const pinboardPayload = { ...pinboardRes, pages: reorganizedPages, originalBackendPages: pinboardRes.pages, activePage: { ...pageRes, id: pageRes.pageId } }
          dispatch({ type: "SET_PINBOARD_DATA", payload: { ...pinboardPayload, reloadPage: true } })
        }
      })
  }, [dispatch, shareLink])

  const authenticatePreview = () => {
    fetchGetPreviewPinboard(shareLink, requestPassword.password)
      .then((pinboardRes: TODO) => {
        if (pinboardRes.error || pinboardRes.message) {
          if (pinboardRes.statusCode === 403 && pinboardRes.error === "Pinboard is protected") {
            setRequestPassword({ show: true, password: "" });
          } else {
            if (pinboardRes.error === "invalid secret") {
              setAuthenticateError("Wrong password, please try again");
            } else {
              setAuthenticateError(pinboardRes.error ? pinboardRes.error : pinboardRes.message);
            }
          }
        } else {
          setRequestPassword({ ...requestPassword, show: false, });
          setAuthenticateError("");
          fetchGetPreviewPage(pinboardRes, pinboardRes.pages[0].id, requestPassword.password)
        }
      })
  }

  const onEditPinboard = (editBody: { name: string, description: string }) => {
    dispatch({
      type: 'UPDATE_DOCUMENT_BREADCRUMB',
      payload: {
        id: currentPinboardId,
        name: editBody.name,
        documentDescription: editBody.description
      },
    });
  }

  useEffect(() => {
    if (didMount) {
      setDidMount(false)
      if (isPreview === "none" || isPreview === "local") {
        fetchGetJson(`pb/projects/${currentProjectId}/pinboards/${currentPinboardId}`, token)
          .then((pinboardRes: TODO) => {
            if (pinboardRes.error || pinboardRes.message) {
              dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: `${pinboardRes.error ? pinboardRes.error : pinboardRes.message}` } })
            } else {
              if (pinboardRes.pages.length > 0) {
                const reorganizedPages = reorganizePinboardPages(JSON.parse(JSON.stringify(pinboardRes.pages)))

                fetchGetJson(`pb/projects/${currentProjectId}/pinboards/${currentPinboardId}/pages/${reorganizedPages[0].id}`, token) // Get first page
                  .then((pageRes: TODO) => {
                    if (pageRes.error || pageRes.message) {
                      dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: pageRes.error ? pageRes.error : pageRes.message } })
                    } else {
                      const pinboardPayload = { ...pinboardRes, pages: reorganizedPages, originalBackendPages: pinboardRes.pages, activePage: pageRes }
                      fetchGetJson(`pb/projects/${pinboardRes.projectId}/pinboards/${pinboardRes.pinboardId}/shareLink`, token) // Get publish data
                        .then((shareLinkRes: TODO) => {
                          let publishDataRes;

                          if (shareLinkRes.error || shareLinkRes.message) {
                            if (shareLinkRes.error === "pinboard has no sharelinks") {
                              publishDataRes = { ...shareLinkRes, published: false }
                              dispatch({ type: "SET_PINBOARD_DATA", payload: { ...pinboardPayload, publishData: publishDataRes, reloadPage: true } })

                            } else {
                              dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: shareLinkRes.error ? shareLinkRes.error : shareLinkRes.message } });
                            }
                          } else {
                            publishDataRes = { ...shareLinkRes, published: true }
                            dispatch({ type: "SET_PINBOARD_DATA", payload: { ...pinboardPayload, publishData: publishDataRes, reloadPage: true } })
                          }
                        })
                    }
                  })
              } else {
                setLoadingPinboard(false);
                dispatch({ type: "SET_PINBOARD_DATA", payload: { ...pinboardRes, reloadPage: false } })
              }
            }
          })
      } else {
        fetchGetPreviewPinboard(shareLink,)
          .then((pinboardRes: TODO) => {
            if (pinboardRes.error || pinboardRes.message) {
              if (pinboardRes.statusCode === 403 && pinboardRes.error === "Pinboard is protected") {
                setRequestPassword({ show: true, password: "" });
              } else {
                dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: pinboardRes.error ? pinboardRes.error : pinboardRes.message } })
              }
            } else {
              fetchGetPreviewPage(pinboardRes, pinboardRes.pages[0].id)
            }
          })
      }
    }
  }, [didMount, dispatch, token, currentProjectId, currentPinboardId, isPreview, shareLink, fetchGetPreviewPage])

  useEffect(() => {
    const getAnalysisTables = async (dataGrid: DataGridItem[]) => {
      // If we have analysis objects in the page, we have to get analysis tables with another call
      fetchGetJson(`pb/projects/${currentProjectId}/pinboards/${currentPinboardId}/pages/${pinboardData.activePage.id}/analyse`, token)
        .then((res: TODO) => {
          if (res.error || res.message) {
            dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: `${res.error ? res.error : res.message}` } })
          } else {
            const updatedDataGrid = [...dataGrid]

            for (const table in res) {
              const tableIndex = updatedDataGrid.findIndex((el: DataGridItem) => el.i === table)
              if (updatedDataGrid[tableIndex].data.hasOwnProperty("jsx")) {
                updatedDataGrid[tableIndex].data.jsx = res[table].result
                updatedDataGrid[tableIndex].data.analysisType = returnAnalysisType(res[table].result.slice(0, 30))
              } else {
                updatedDataGrid[tableIndex].data.chartJson = res[table]
              }
            }
            setDataGrid(updatedDataGrid);
          }
        })
    }

    const getPreviewAnalysisTables = (dataGrid: DataGridItem[], analysisResults: TODO) => {
      dataGrid.forEach((item: DataGridItem) => {
        if (item.type === "analysis") {
          if (item.data.analysisType === "table") {
            item.data.jsx = analysisResults[item.i].result
            item.data.analysisType = returnAnalysisType(analysisResults[item.i].result.slice(0, 30))
          } else {
            item.data.chartJson = analysisResults[item.i]
          }
        }
      })
    }

    if (pinboardData.reloadPage && pinboardData.activePage?.pageObjects) {
      const updatedLayoutGrid = returnUpdatedLayoutGrid(pinboardData.activePage.pageObjects, pinboardData.activePage.positions)
      const updatedDataGrid = returnUpdatedDataGrid(pinboardData.activePage.pageObjects)

      setLoadingPinboard(false)
      setLayoutGrid(updatedLayoutGrid);
      setDataGrid(updatedDataGrid);

      if (pinboardData.activePage?.pageObjects.some((el: PinboardPage) => el.type === "analysis")) {
        if (isPreview === "none" || isPreview === "local") {
          getAnalysisTables(updatedDataGrid);
        } else {
          getPreviewAnalysisTables(updatedDataGrid, pinboardData.activePage.analysisResults)
        }
      }

    }
  }, [pinboardData.activePage, pinboardData.reloadPage, currentPinboardId, currentProjectId, isPreview, dispatch, token])

  useEffect(() => {
    // Saves the current layout to backend, can be called by just setting sendGridLayout to true,
    // or just call shouldSaveLayout() if you would like the call debounced and throttled by 3 seconds 
    setSendGridLayout(false)
    if (sendGridLayout) {
      if (pinboardData.pages.length > 0) {
        const newPositions: TODO = {};
        layoutGrid.forEach((layout: LayoutGridItem) => {
          newPositions[layout.i] = {
            w: layout.w,
            h: layout.h,
            x: layout.x,
            y: layout.y,
          }
        })
        const body = {
          pageEtag: pinboardData.activePage._etag,
          positions: { ...newPositions }
        }
        fetchPatchJson(`pb/projects/${currentProjectId}/pinboards/${currentPinboardId}/pages/${pinboardData.activePage.id}/positions`, token, body)
          .then((res: TODO) => res.json())
          .then((result: TODO) => {
            if (result.error || result.message) {
              dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: result.error ? result.error : result.message } });
            } else {
              setSavingLayout(false);
              dispatch({ type: "SET_ACTIVE_PINBOARD_PAGE", payload: { page: result, reload: false } })
            }
          })
      } else {
        dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: "No board selected" } });
      }
    }
  }, [sendGridLayout, currentPinboardId, currentProjectId, dispatch, layoutGrid, pinboardData, token])

  const deleteGridItemHandler = (item: DataGridItem | null | undefined) => {
    if (item) {
      const updatedGridLayout = JSON.parse(JSON.stringify(layoutGrid));
      const updatedGridData = JSON.parse(JSON.stringify(dataGrid));
      const layoutIndex = updatedGridLayout.findIndex((el: TODO) => el.i === item.i)
      const dataIndex = updatedGridData.findIndex((el: TODO) => el.i === item.i)

      fetchDeleteWithBody(`pb/projects/${currentProjectId}/pinboards/${currentPinboardId}/pages/${pinboardData.activePage.id}/objects/${item.i}`, token, { pageEtag: pinboardData.activePage._etag })
        .then((response: TODO) => response.json())
        .then((res: TODO) => {
          if (res.error || res.message) {
            dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: res.error ? res.error : res.message } });
          } else {
            updatedGridLayout.splice(layoutIndex, 1);
            updatedGridData.splice(dataIndex, 1);

            dispatch({ type: "SET_ACTIVE_PINBOARD_PAGE", payload: { page: res, reload: false } });
            setShowDeleteItemModal(false);
            setLayoutGrid(updatedGridLayout);
            setDataGrid(updatedGridData);
          }
        })
    }
  }

  const optionsClickHandler = (dataGridItem: DataGridItem | undefined) => {
    if (dataGridItem && isPreview === "none") {
      if (showOptions.show === true) {
        if (dataGridItem.i !== selectedItem?.i) {
          setSelectedItem(dataGridItem);
        } else {
          setSelectedItem(null);
          setShowOptions({ show: true, animate: false });
          setTimeout(() => setShowOptions({ show: false, animate: false }), 250)
        }
      } else {
        setSelectedItem(dataGridItem)
        setShowOptions({ show: true, animate: true });
        setShowSidebar({ show: false, type: showSidebar.type });
        setTimeout(() => setShowOptions({ show: true, animate: true }), 250)
      }
    }
  }

  const onDropHandler = (layout: TODO, layoutItem: TODO) => {
    setLayoutGrid(layout)
    setLoadingNewObject(true);

    const newPositions: TODO = {};
    layout.forEach((item: LayoutGridItem) => {
      if (item.i === "__new-item__") {
        newPositions["-1"] = {
          w: 10,
          h: 4,
          x: item.x,
          y: item.y,
        }
      } else {
        newPositions[item.i] = { w: item.w, h: item.h, x: item.x, y: item.y, }
      }
    })

    if (draggedItemData.type === "text") {
      const body = {
        pageEtag: pinboardData.activePage._etag,
        positions: {
          ...newPositions
        }
      }

      fetchPostJson(`pb/projects/${pinboardData.projectId}/pinboards/${pinboardData.pinboardId}/pages/${pinboardData.activePage.id}/text`, token, body)
        .then((res: TODO) => {
          if (res.error || res.message) {
            dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: res.error ? res.error : res.message } })
            setLoadingNewObject(false);
          } else {
            const updatedLayout = [...layout];
            const itemIndex = updatedLayout.findIndex(el => el.i === layoutItem.i);
            const newPageObject = res.page.pageObjects.find((el: TODO) => el.id === res.pageObjectId);

            updatedLayout[itemIndex] = {
              ...res.page.positions[newPageObject.id],
              i: newPageObject.id
            }

            const updatedData = [...dataGrid]
            const gridItem = { i: newPageObject.id, data: { html: newPageObject.html }, type: newPageObject.type, options: { ...itemDefaultOptions, title: "" } }
            updatedData.splice(itemIndex, 0, gridItem)

            setLayoutGrid(updatedLayout)
            setDataGrid(updatedData)
            setLoadingNewObject(false);
            dispatch({ type: "SET_ACTIVE_PINBOARD_PAGE", payload: { page: res.page, reload: false } })
          }
        })
    } else if (draggedItemData.type === "image") {
      const body = {
        url: "image.placeholder",
        // innerPosition: "string",
        positions: newPositions,
        pageEtag: pinboardData.activePage._etag
      }

      fetchPostJson(`pb/projects/${currentProjectId}/pinboards/${currentPinboardId}/pages/${pinboardData.activePage.id}/image`, token, body)
        .then((res: TODO) => {
          if (res.error || res.message) {
            dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: res.error ? res.error : res.message } });
            setLoadingNewObject(false);
          } else {
            const updatedLayout = [...layout];
            const itemIndex = updatedLayout.findIndex(el => el.i === layoutItem.i);
            const newPageObject = res.page.pageObjects.find((el: TODO) => el.id === res.pageObjectId);

            updatedLayout[itemIndex] = {
              ...res.page.positions[newPageObject.id],
              i: newPageObject.id
            }

            const updatedData = [...dataGrid]
            const gridItem = { i: newPageObject.id, data: { html: newPageObject.html }, type: newPageObject.type, options: { ...itemDefaultOptions, padding: "0", title: "" } }
            updatedData.splice(itemIndex, 0, gridItem)

            setLayoutGrid(updatedLayout)
            setDataGrid(updatedData)
            dispatch({ type: "SET_ACTIVE_PINBOARD_PAGE", payload: { page: res.page, reload: false } })
            setLoadingNewObject(false);
          }
        })

    } else if (draggedItemData.type === "analysis") {
      const updatedLayout = [...layout];
      const updatedData = [...dataGrid]
      const formData = draggedItemData.data

      const tabData = analysisData.backendSessionData[formData.selectedQuestion.analysisType.stateName]
      tabData.firstColumn = new Array(formData.selectedQuestion)
      tabData.firstColumn[0].active = true

      const analysisJson = returnUpdatedAnalysisBody("datasets", formData.selectedQuestion.analysisType.backendType, tabData.newQuestionOptions, tabData.firstColumn, tabData.scndColumn?.filter((item: TODO) => !item.disabled), "")

      const body = {
        datasetId: formData.selectedDataset.id,
        projectId: formData.selectedProject.id,
        analysisJson: JSON.stringify(analysisJson),
        positions: newPositions,
        pageEtag: pinboardData.activePage._etag,
        isSurveyDataset: formData.selectedDataset.isSurveyDataset === true ? true : false
      }

      fetchPostJson(`pb/projects/${currentProjectId}/pinboards/${currentPinboardId}/pages/${pinboardData.activePage.id}/analysis`, token, body)
        .then((res: TODO) => {
          if (res.error || res.message) {
            dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: res.error ? res.error : res.message } });
            setLoadingNewObject(false);
          } else {
            const layoutIndex = updatedLayout.findIndex((el: LayoutGridItem) => el.i === "__new-item__")
            updatedLayout[layoutIndex] = { i: res.pageObjectId, w: 10, h: 4, x: updatedLayout[layoutIndex].x, y: updatedLayout[layoutIndex].y }

            fetchGetJson(`pb/projects/${currentProjectId}/pinboards/${currentPinboardId}/pages/${pinboardData.activePage.id}/analyse/${res.pageObjectId}`, token)
              .then((result: TODO) => {
                if (result.error || result.message) {
                  dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: result.error ? result.error : result.message } });
                  setLoadingNewObject(false);
                } else {
                  if (formData.selectedQuestion.analysisType.frontendType !== "chart") {
                    updatedData.push({
                      i: res.pageObjectId,
                      options: { ...itemDefaultOptions },
                      type: "analysis",
                      data: {
                        analysisType: returnAnalysisType(result[res.pageObjectId].result.slice(0, 30)),
                        jsx: result[res.pageObjectId].result
                      },
                    })
                  } else {
                    updatedData.push({
                      i: res.pageObjectId,
                      options: { ...itemDefaultOptions },
                      type: "analysis",
                      data: {
                        analysisType: "chart",
                        chartJson: result[Object.keys(result)[0]]
                      },
                    })
                  }

                  setLayoutGrid(updatedLayout)
                  setDataGrid(updatedData)
                  dispatch({ type: "SET_ACTIVE_PINBOARD_PAGE", payload: { page: res.page, reload: false } })
                  setLoadingNewObject(false);
                }
              })
          }
        })
    }
  }

  const dragItemHandler = (event: TODO, data: TODO) => {
    if (pinboardData.pages.length > 0) {
      setDraggedItemData(data)
      event.dataTransfer.setData("text/plain", "")
    } else {
      dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: "No board selected" } });
    }
  }

  const gridItemRenderer = (_layoutGridItem: LayoutGridItem, dataGridItem: DataGridItem | undefined) => {
    if (dataGridItem) {
      switch (dataGridItem.type) {
        case "text":
          return (
            <div className="pinboard-text">
              <div className="pinboard-obj-content">
                {dataGridItem.options.title &&
                  <div className="object-title">
                    <span>{dataGridItem?.options.title}</span>
                  </div>}
                {dataGridItem.data.html &&
                  <div className="object-content d-flex flex-column overflow-auto simple-scrollbar" style={{ padding: `${dataGridItem.options.padding ? `${dataGridItem.options.padding}px` : "none"}` }}>
                    <QuillEditor value={dataGridItem.data.html} readOnly={true} />
                  </div>}
                <span className="pinboard-label">{dataGridItem.type}</span>
              </div>
            </div>
          )
        case "image":
          return (
            <div className="pinboard-image">
              <div className="pinboard-obj-content">
                {dataGridItem.options.title &&
                  <div className="object-title">
                    <span>{dataGridItem?.options.title}</span>
                  </div>
                }
                <div className="object-content simple-scrollbar" style={{ padding: `${dataGridItem.options.padding ? `${dataGridItem.options.padding}px` : "none"}` }}>
                  {dataGridItem.data.url === "image.placeholder" ?

                    <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center no-image">
                      <Icon type="image-outline" />
                      <span className="mt-3">No image selected</span>
                    </div>
                    :
                    <img src={dataGridItem.data.url} alt="" />}
                </div>
                <span className="pinboard-label">{dataGridItem.type}</span>
              </div>
            </div>
          )
        case "analysis":
          return (
            <div className="pinboard-analysis">
              {dataGridItem.data.analysisType === "crosstab" && (
                <div className='d-flex gap-md px-4 py-1'>
                  <div className="d-flex flex-row gap-sm">
                    <label htmlFor={dataGridItem.i} className="d-flex gap-sm align-items-center">
                      <Checkbox
                        id={dataGridItem.i}
                        value={freezeHeader === dataGridItem.i ? true : false}
                        onChange={() => dataGridItem.i === freezeHeader ? setFreezeHeader("") : setFreezeHeader(dataGridItem.i)}
                      />
                      Freeze header
                    </label>
                  </div>

                  <div className="d-flex flex-row gap-sm">
                    <label htmlFor={dataGridItem.i} className="d-flex gap-sm align-items-center">
                      <Checkbox
                        id={dataGridItem.i}
                        value={freezeColumn === dataGridItem.i ? true : false}
                        onChange={() => dataGridItem.i === freezeColumn ? setFreezeColumn("") : setFreezeColumn(dataGridItem.i)}
                      />
                      Freeze Column
                    </label>
                  </div>
                </div>
              )}

              <div className={`pinboard-obj-content w-100 h-100 ${dataGridItem.data.analysisType !== "chart" ? dataGridItem.data.analysisType : "chart"} ${freezeColumn === dataGridItem.i ? "freeze-column" : ""} ${freezeHeader === dataGridItem.i ? "freeze-header" : ""}`}>
                {dataGridItem.options.title &&
                  <div className="object-title">
                    <span>{dataGridItem?.options.title}</span>
                  </div>}
                {dataGridItem.data.analysisType === "chart" ?
                  <div className="object-content simple-scrollbar" style={{ padding: `${dataGridItem.options.padding ? `${dataGridItem.options.padding}px` : "none"}` }}>
                    {!dataGridItem.data.chartJson ?
                      <div className="overflow-hidden h-100"><FullHeightSpinner /></div>
                      :
                      <ChartTypeContent data={dataGridItem.data.chartJson} currentZoom={1} additionalSettings={dataGridItem.options.chartStyle} />
                    }
                  </div>
                  :
                  !dataGridItem.data.jsx ?
                    <div className="overflow-hidden h-100"><FullHeightSpinner /></div> :
                    <JsxParser jsx={dataGridItem.data.jsx} />
                }
                <span className="pinboard-label">{dataGridItem.type}</span>
              </div>
            </div>
          )
        default:
          return <React.Fragment />
      }
    }
  }

  const layoutUpdateHandler = (layout: TODO) => {
    const newLayout: TODO = {}
    layout.forEach((item: TODO) => { newLayout[item.i] = { w: item.w, h: item.h, x: item.x, y: item.y, } })

    if (JSON.stringify(newLayout) !== JSON.stringify(pinboardData.activePage.positions) && !loadingNewObject) {
      shouldSaveLayout();
    } else {
      setSavingLayout(false)
    }
  }

  return (
    <div className={`pinboards-main ${pinboardData.pages?.length > 0 && pinboardData.activePage && (!pinboardData.activePage?.pageStyle || (!pinboardData.activePage.pageStyle.backgroundColor && pinboardData.activePage.pageStyle.backgroundImage === "url()") ? "default-page-bkg" : "nonDefault-page-bkg")}`}
      style={{
        backgroundColor: pinboardData.pages.length > 0 && pinboardData.activePage?.pageStyle?.backgroundColor && pinboardData.activePage.pageStyle.backgroundColor,
        backgroundImage: pinboardData.pages.length > 0 && pinboardData.activePage?.pageStyle?.backgroundImage && pinboardData.activePage.pageStyle?.backgroundImage !== "url()" && pinboardData.activePage.pageStyle.backgroundImage,
      }}>
      {/* Modals */}
      {showDeletePinboardModal &&
        //@ts-ignore
        <ConfirmActionModal
          type="document"
          projectName={breadcrumbs.documentName}
          handleClose={() => setShowDeletePinboardModal(false)}
          deleteItem={() => confirmDeletePinboard(currentProjectId, currentPinboardId, token, dispatch, setShowDeletePinboardModal, history,)}
        />
      }
      {showEditPinboardModal &&
        <EditProjectDocument
          item={{ type: "pinboards", id: currentPinboardId, description: breadcrumbs.documentDescription, projectId: currentProjectId, projectName: breadcrumbs.documentName }}
          itemAction={{ type: "pinboards", id: currentPinboardId, description: breadcrumbs.documentDescription, projectId: currentProjectId, projectName: breadcrumbs.documentName }}
          onUpdateData={(editBody: { name: string; description: string; }) => { onEditPinboard(editBody); setDidMount(true); }}
          handleClose={() => setShowEditPinboardModal(false)}
        />
      }
      {showDeleteItemModal &&
        <Dialog title="Delete item" onClose={() => { setSelectedItem(null); setShowDeleteItemModal(false) }}>
          <div style={{ width: "400px" }}>Are you sure you want to delete this item?</div>
          <DialogActionsBar>
            <button type="button" className="btn btn-secondary fw-bold" onClick={() => { setSelectedItem(null); setShowDeleteItemModal(false) }}>Cancel</button>
            <button type="button" className="btn btn-primary fw-bold" onClick={() => deleteGridItemHandler(selectedItem)}>Confirm</button>
          </DialogActionsBar>
        </Dialog>
      }
      {isPreview === "public" && requestPassword.show &&
        <Dialog title="Password required" minWidth={"35%"} onClose={() => setRequestPassword({ show: false, password: "" })}>
          <input type="password" name="password" placeholder="Password" value={requestPassword.password} className="sidebar-input form-control" onChange={(e) => setRequestPassword({ show: true, password: e.target.value })} />
          {authenticateError && <div className="pt-3"><ErrorMessage type="alert" errorMessage={authenticateError} onHide={() => setAuthenticateError("")} /></div>}
          <DialogActionsBar>
            <button type="button" className="btn btn-secondary rounded-0" onClick={() => setRequestPassword({ show: false, password: "" })}>Cancel</button>
            <button type="button" className="btn btn-primary rounded-0" onClick={() => authenticatePreview()}>Confirm</button>
          </DialogActionsBar>
        </Dialog>
      }

      {/* Sidebars */}
      <div className={`pinboards-sidebar-main ${showSidebar.show ? "sidebar-slide-out" : "sidebar-slide-in"}`} >
        <PinboardSidebarContents
          token={token}
          searchState={searchState}
          setSearchState={setSearchState}
          loadingNewObject={loadingNewObject}
          savingLayout={savingLayout}
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
          analysisData={analysisData}
          setAnalysisData={setAnalysisData}
          currentProjectId={currentProjectId}
          dragItem={dragItemHandler}
          setLoadingPinboard={value => setLoadingPinboard(value)}
        />
      </div>

      {/* Options sidebar */}
      <div className={`pinboards-options-main ${showOptions.animate ? "options-slide-out" : "options-slide-in"} float-right`} >
        {showOptions.show &&
          <PinboardItemOptions
            token={token}
            pinboardData={pinboardData}
            dataGrid={dataGrid}
            setDataGrid={setDataGrid}
            selectedItem={selectedItem ? selectedItem : { i: "", options: JSON.parse(JSON.stringify({ ...itemDefaultOptions, title: "" })), type: "", data: {} }}
            onHide={optionsClickHandler} />
        }
      </div>

      {isPreview === "none" ?
        <React.Fragment>
          <PinboardAppNavBar
            pinboardData={pinboardData}
            showAppMenu={showAppMenu}
            setShowAppMenu={setShowAppMenu}
            setShowDeletePinboardModal={setShowDeletePinboardModal}
            loadingPinboard={loadingPinboard}
            setShowEditPinboardModal={setShowEditPinboardModal}
          />

          <PinboardMainNavBar
            token={token}
            pinboardData={pinboardData}
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
            showMainMenu={showMainMenu} setShowMainMenu={setShowMainMenu}
            loadingPinboard={loadingPinboard}
            setLoadingPinboard={setLoadingPinboard}
            savingLayout={savingLayout} />
        </React.Fragment> :
        <PinboardPreviewNavBar
          token={token}
          pinboardData={pinboardData}
          fetchGetPreviewPage={(pinboardData, pageId) => fetchGetPreviewPage(pinboardData, pageId, requestPassword.password ? requestPassword.password : null)}
          showMainMenu={showMainMenu}
          setShowMainMenu={setShowMainMenu}
          setLoadingPinboard={setLoadingPinboard}
          isPreview={isPreview} />
      }

      {/* Pinboard body container */}
      <div className={`pinboards-body flex-fill d-flex justify-content-center ${isPreview === "local" || isPreview === "public" ? "pinboard-preview" : ""}`}>
        {!loadingPinboard ?
          pinboardData.pages.length > 0 ?
            // React Grid Layout Component
            <ResponsiveGridLayout
              className="layout"
              layout={layoutGrid}
              cols={48}
              rowHeight={48}
              margin={[24, 24]}
              resizeHandles={["s", "se", "e"]}
              isDroppable={((isPreview === "public" || isPreview === "local") || showOptions.show || loadingNewObject) ? false : true}
              isDraggable={((isPreview === "public" || isPreview === "local") || showOptions.show || loadingNewObject) ? false : true}
              isResizable={((isPreview === "public" || isPreview === "local") || showOptions.show || loadingNewObject) ? false : true}
              droppingItem={{ i: "__new-item__", w: 10, h: 4 }}
              onDrop={onDropHandler}
              onResizeStop={(layout: TODO) => { setSavingLayout(true); layoutUpdateHandler(layout) }}
              onDragStop={(layout: TODO) => { setSavingLayout(true); layoutUpdateHandler(layout) }}
              compactType={("vertical")}
              onLayoutChange={(newLayout: TODO) => {
                const newItemIndex = newLayout.findIndex((el: TODO) => el.i === "__new-item__")
                if (!loadingNewObject) { // needed to disable dragging new items
                  if (newItemIndex >= 0) {
                    const updatedLayout = JSON.parse(JSON.stringify(newLayout));
                    updatedLayout[newItemIndex].isDraggable = false
                    setLayoutGrid(updatedLayout);
                  } else {
                    setLayoutGrid(newLayout);
                  }
                }
              }
              }>

              {layoutGrid.map((item: LayoutGridItem) => {
                const extraData = dataGrid.find((el: TODO) => el.i === item.i)

                return (
                  <div key={item.i} className={`grid-component-main ${selectedItem?.i === item.i ? "pinboard-obj-selected" : ""}`} >
                    {item.i === "__new-item__" ?
                      <div className="bg-white w-100 h-100 rounded">
                        <FullHeightSpinner />
                      </div>
                      :
                      <div className="d-flex flex-column w-100 h-100 overflow-hidden"
                        style={{
                          backgroundColor: extraData?.options.backgroundColor,
                          borderColor: extraData?.options.borderColor,
                          color: extraData?.options.textColor,
                          borderRadius: `${extraData?.options.borderRadius}px`,
                          borderWidth: `${extraData?.options.borderWidth}px`,
                          borderStyle: `${(extraData?.options.borderWidth && extraData?.options.borderWidth > 0) ? "solid" : "none"}`,
                          boxShadow: extraData?.options.boxShadow ? "2px 2px 4px rgba(0, 0, 0, 0.1)" : "none",
                        }}>
                        <div className="d-flex justify-content-end fixed-top item-actions">
                          {isPreview === "none" &&
                            <React.Fragment>
                              <div className="open-opt-btn">
                                <span className="d-flex align-items-center pr-2" onClick={e => { e.stopPropagation(); optionsClickHandler(extraData) }}><Icon type="dashboard-edit" />Edit</span>
                                <div className="divider" />
                                <Tooltip openDelay={300} position="bottom" anchorElement={"target"}>
                                  <span className="d-flex align-items-center" title="Delete item" onClick={() => { setSelectedItem(extraData); setShowDeleteItemModal(true) }}><Icon type="delete-alt" className="pe-none" /></span>
                                </Tooltip>
                              </div>
                            </React.Fragment>
                          }
                        </div>
                        {gridItemRenderer(item, extraData)}
                      </div>}
                  </div>
                )
              })}
            </ResponsiveGridLayout>
            :
            <div className="d-flex flex-column flex-fill justify-content-center align-items-center">
              <h3 className="text-secondary">The pinboard does not have TODO boards.</h3>
            </div>
          :
          <div className="d-flex flex-column flex-fill">
            <FullHeightSpinner />
          </div>
        }

      </div >
    </div >
  )
}