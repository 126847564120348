export const returnTableIcon = (type, extension) => {
  switch (type) {
    case 'datasets':
      return 'fas fa-database';
    case 'datasetsv2':
      return 'fas fa-server';
    case 'surveys':
      return 'fas fa-poll-h';
    case 'workflows':
      return 'fas fa-cogs';
    case 'samples':
      return 'fas fa-file-alt';
    case 'pinboards':
      return 'fas fa-solid fa-table';
    case 'sharedProject':
      return 'far fa-share-alt';
    case 'audiences':
      return 'fas fa-users';
    case 'projects':
      return 'far fa-folder';
    case 'files':
      switch (extension) {
        case '.jpg':
        case '.png':
          return 'fas fa-file-image';
        case '.doc':
        case '.docx':
          return 'fas fa-file-word';
        case '.ppt':
        case '.pptx':
          return 'fas fa-file-powerpoint';
        case '.pdf':
          return 'fas fa-file-pdf';
        case '.xls':
        case '.xlsx':
          return 'fas fa-file-excel';
        case '.csv':
          return 'fas fa-file-csv';
        case '.zip':
        case '.rar':
          return 'fas fa-file-archive';
        default:
          return 'fas fa-file';
      }
    default:
      return 'fas fa-folder';
  }
}