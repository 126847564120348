import { Fragment } from "react";

interface Props {
  currentValue: number,
  targetValue: number,
  showRemaining: boolean,
}

export const QuotaProgressBar = ({ currentValue, targetValue, showRemaining }: Props) => {

  const valuesCalculation = () => {
    const fillRatio = targetValue > 0 ? parseFloat((currentValue / targetValue).toFixed(5)) : 0;
    const fillPercent = parseFloat((fillRatio * 100).toFixed(2));
    const overflowRatio = parseFloat((fillRatio > 1 ? fillRatio - 1 : 0).toFixed(5));
    const overflowPercent = parseFloat((overflowRatio * 100).toFixed(2));
    const remaining = (targetValue - currentValue);
    const remainingRatio = targetValue !== 0 ? parseFloat(((targetValue - currentValue) / targetValue).toFixed(5)) : 0;
    const remainingPercent = parseFloat((remainingRatio * 100).toFixed(2));
    let rgbValue;

    switch (true) {
      case (fillRatio <= 0.2):
        rgbValue = "#ff8787";
        break;
      case (fillRatio > 0.2 && fillRatio <= 0.4):
        rgbValue = "#ffbc87";
        break;
      case (fillRatio > 0.4 && fillRatio <= 0.6):
        rgbValue = "#fff187";
        break;
      case (fillRatio > 0.6 && fillRatio <= 0.8):
        rgbValue = "#d9ff87";
        break;
      case (fillRatio > 0.8 && fillRatio <= 1):
        rgbValue = "#a4ff87";
        break;
      case (fillRatio > 1 && fillRatio <= 1.2):
        rgbValue = "#87ffa4";
        break;
      case (fillRatio > 1.2 && fillRatio <= 1.4):
        rgbValue = "#87ffd9";
        break;
      case (fillRatio > 1.4 && fillRatio <= 1.6):
        rgbValue = "#87f1ff";
        break;
      case (fillRatio > 1.6 && fillRatio <= 1.8):
        rgbValue = "#87bcff";
        break;
      case (fillRatio > 1.8):
        rgbValue = "#8787ff";
        break;
      default:
        rgbValue = "#8787ff"
    }

    return ({
      currentValue: currentValue,
      fillRatio: fillRatio,
      fillPercent: fillPercent,
      overflowRatio: overflowRatio,
      overflowPercent: overflowPercent,
      remaining: remaining,
      remainingRatio: remainingRatio,
      remainingPercent: remainingPercent,
      rgbValue: rgbValue,
    })
  }

  const theValues = valuesCalculation();

  if (!showRemaining) {
    return (
      <Fragment>
        <div className="d-flex w-100 progress-container">
          <div className="progress table-achieved-bkg dashed-border w-75">
            <span className="table-numbers-overlay">
              {`${theValues.currentValue} (${theValues.fillPercent}%)`}
            </span>
            <div className="progress-bar h-100" role="progressbar"
              style={{
                width: `${theValues.fillPercent}%`,
                background: `${theValues.rgbValue}`,
              }}>
            </div>
          </div>
          <div className="progress table-overflow-bkg w-25">
            <div className="progress-bar h-100" role="progressbar"
              style={{
                width: `${theValues.overflowPercent}%`,
                background: `${theValues.rgbValue}`,
              }}>
            </div>
          </div>
        </div>
      </Fragment>
    )
  } else {
    return (
      <div className="progress table-remaining-bkg w-100">
        <span className="table-numbers-overlay">
          {`${theValues.remaining} (${theValues.remainingPercent}%)`}
        </span>
        <div className="progress-bar h-100" role="progressbar"
          style={{
            width: `${theValues.remainingPercent}%`,
            background: `${theValues.rgbValue}`,
            borderRight: "1px solid rgb(200, 200, 200)",
            boxShadow: "0px 0px 0.5rem 0.5rem rgba(0, 0, 0, 7%)",
          }}>
        </div>
      </div>
    )
  }
}
