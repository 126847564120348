import { type FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { InputChangeEvent } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import type { RootState } from '../../../../../store/reducers/rootReducer';
import { ErrorMessage } from '../../../../shared/ErrorMessage/ErrorMessage';
import type { User } from '../../../../../interfaces/userInterface/userInterface';
import { EditSubAccountModalContent } from './EditSubAccountModalContent/EditSubAccountModalContent';
import { useUpdateSubAccountsFormData } from '../../../../shared/customHooks/useUpdateSubAccountsFormData';
import { EditSubAccountModalActionBars } from './EditSubAccountModalActionBars/EditSubAccountModalActionBars';
import type { SubAccount, SubAccountFormData } from '../../../../../interfaces/adminInterfaces/adminInterfaces';
import { createSubAccountSubmitHandler } from '../../../../shared/helpers/createSubAccountSubmitHandler/createSubAccountSubmitHandler';
import { updateSubAccountSubmitHandler } from '../../../../shared/helpers/updateSubAccountSubmitHandler/updateSubAccountSubmitHandler';

interface Props {
  user: User
  subAccount?: SubAccount | null
  handleClose: () => void
}

export const EditSubAccountModal = ({ user, subAccount, handleClose }: Props) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { subAccountsErrorMessage } = useSelector((theState: RootState) => theState.adminReducer);
  const [formData, setFormData] = useState<SubAccountFormData>({ subAccountName: '', subAccountDescription: '', subAccountShareName: user.customer, subAccountShareMessage: '' })

  useUpdateSubAccountsFormData(formData, (val) => setFormData(val), subAccount)

  const onChangeHandler = (e: InputChangeEvent, name: string) => {
    setFormData({
      ...formData,
      [name]: e.value
    })
  }

  const onSubmitHandler = (e: FormEvent) => {
    e.preventDefault()
    if (subAccount) {
      updateSubAccountSubmitHandler(formData, token, dispatch, subAccount)
    } else {
      createSubAccountSubmitHandler(formData, token, dispatch)
    }
  }

  return (
    <Dialog
      width="40%"
      className="sub-accounts-modal"
      title={subAccount ? 'Edit sub account' : 'Create sub account'}
      onClose={handleClose}>
      <form onSubmit={onSubmitHandler} className="d-flex flex-column h-100">
        <div className="px-4 pt-4 d-flex flex-column" style={{ flex: '1 1 auto' }}>
          <EditSubAccountModalContent
            formData={formData}
            onChangeHandler={onChangeHandler}
          />
          {
            subAccountsErrorMessage &&
            <div className="mt-4">
              <ErrorMessage
                type="alert"
                errorMessage={subAccountsErrorMessage} />
            </div>
          }
        </div>

        <DialogActionsBar>
          <EditSubAccountModalActionBars
            subAccount={subAccount}
            handleClose={handleClose}
          />
        </DialogActionsBar>
      </form>
    </Dialog>
  )
}