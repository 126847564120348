export default (option, user, groups, updateOptionsValues) => {
  let disableOption = false
  let groupPlan = groups.find(el => el.text === option?.group)?.plan
  if (user.isSubAccount) {
    disableOption = false
  }
  else if (user.plan === 'basic') {
    if (groupPlan !== user.plan) {
      disableOption = true
    }
    else {
      disableOption = false
    }
  }
  else if (user.plan === 'essential_yearly' || user.plan === 'essential_monthly') {
    if (groupPlan === 'essential' || groupPlan === 'basic') {
      disableOption = false
    }
    else {
      disableOption = true
    }
  }
  else if (user.plan === 'professional_monthly' || user.plan === 'professional_yearly') {
    if (groupPlan === 'enterprise') {
      disableOption = true
    }
    else {
      disableOption = false
    }
  }
  else {
    disableOption = false
  }
  if (option != null && updateOptionsValues != null){
    if (option.id === "GChartHidePctSign" && (updateOptionsValues.GChartShow === 4 || updateOptionsValues.GChartShow === 5)){
      disableOption = true
    }
  }
  
  return (
    disableOption
  )
}