export default (data, index, multiSelect) => {
  const updatedData = [...data]
  updatedData[index].checked = !updatedData[index].checked
  let updatedCheckedQuestions = updatedData.filter(el => el.checked === true)

  if (multiSelect) {
    if (updatedCheckedQuestions.length === 2) {
      const firstElIndex = updatedData.indexOf(updatedCheckedQuestions[0])
      const secondElIndex = updatedData.indexOf(updatedCheckedQuestions[1])
      for (let i = firstElIndex; i < secondElIndex + 1; i++) {
        updatedData[i].checked = true
        updatedCheckedQuestions = updatedData.filter(el => el.checked === true)
      }
    }
  }

  return {
    data: updatedData,
    checkedQuestions: updatedCheckedQuestions
  }
}