import React from 'react'
import { Button } from '@progress/kendo-react-buttons'

export default ({ recipientsTable, onStepBack, onImport }) => {

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex flex-column h-100 align-items-center justify-content-center">
        <i className="fas fa-check-circle fa-4x mb-3 text-success" />
        <h4 className="text-success mb-3">Ready for import</h4>
        <p>About to import {recipientsTable.originalRows.length} respondents</p>
      </div>
      <div className="d-flex p-4 border-top">
        <div className="d-flex w-100 justify-content-end ">
          <Button className="btn btn-secondary mr-2 h-32" onClick={onStepBack}>Back</Button>
          <Button className="btn btn-primary h-32" onClick={onImport}>Import</Button>
        </div>
      </div>
    </div>
  )
}