import { returnSelectedResponsesList } from "../FilterBuilderHelpers/FilterBuilderHelpers"

export default (negateFilter, selectedQuestionData, filterData, selectedQuestion, responseInterval, responseValue) => {
  let list = returnSelectedResponsesList(selectedQuestionData)
  let body = {}
  let question = ''
  if (selectedQuestion.qno) {
    let mainQuestion = filterData.questions.find(el => el.qno === selectedQuestion.qno)
    let index = mainQuestion.rows.find(row => row.texts[filterData.defaultLanguage] === selectedQuestion.text).position
    question = `${selectedQuestion.qno}.${index}`
  }
  else if (filterData.questions.find(el => el.qno === selectedQuestion.text).type === 'f') {
    question = `${selectedQuestion.text}.1`
  }
  else {
    question = selectedQuestion.text
  }
  if (list.length > 0) {
    if (negateFilter === false) {
      body = {
        "expression": `\\${question}=${list.join(';')}`
      }
    } else {
      body = {
        "expression": `(!\\${question}=${list.join(';')})`
      }
    }
  }
  else {
    if (responseInterval === null && responseValue !== null) {
      if (negateFilter === false) {
        body = {
          "expression": `\\${question}=${responseValue}`
        }
      }
      else {
        body = {
          "expression": `(!\\${question}=${responseValue})`
        }
      }
    }
    else if (responseInterval !== null && responseValue !== null) {
      if (negateFilter === false) {
        body = {
          "expression": `\\${question}=${responseValue}:${responseInterval}`
        }
      }
      else {
        body = {
          "expression": `(!\\${question}=${responseValue}:${responseInterval})`
        }
      }
    } else {
      body = null
    }
  }
  return body
}