import { useState } from 'react';
import { Switch } from '@progress/kendo-react-inputs';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { AddUserModal } from './AddUserModal/AddUserModal';
import { parseJwt } from '../../shared/helpers/decodeJWT/decodeJWT';
import type { RootState } from '../../../store/reducers/rootReducer';
import { BackofficeUserList } from './BackofficeUserList/BackofficeUserList';
import returnDateFormat from '../../Billing/helpers/returnDateFormat.js/returnDateFormat';
import { fetchPatchJson, fetchPostJson } from '../../../services/services';

interface Props {
  editOrAddModalOpen: {
    shouldModalOpen: boolean,
    whichModalOpens: string,
    body: {
      country: string,
      isTrial: boolean,
      name: string,
      trialEndTimeUtc: string,
      subAccountSettings: {
        canCreateSubAccounts: boolean
      },
      enterpriseSettings: {
        hasSurveyAccess: boolean,
        hasToolsAccess: boolean,
        singleSignOnConnectionName: boolean,
        hasPinboardAccess: boolean,
        hasAudienceAccess: boolean
      },
      users: [],
      customerID: string
    }

  },
  setEditOrAddModalOpen: (props: TODO) => void,
  customerID: string
}

export const BackofficeModal = ({ editOrAddModalOpen, setEditOrAddModalOpen, customerID }: Props) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [inputValue, setInputValue] = useState(returnDateFormat(editOrAddModalOpen.body?.trialEndTimeUtc) ? returnDateFormat(editOrAddModalOpen.body?.trialEndTimeUtc) : "")
  const [addUserModal, setAddUserModal] = useState(false);
  const [addUserInputValue, setAddUserInputValue] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState({ show: false, message: "" });

  const parsedJwt = parseJwt(token);

  //this function is the onchange handler for the inputs
  const handleInputChange = (e: TODO) => {
    setEditOrAddModalOpen({
      ...editOrAddModalOpen,
      body: {
        ...editOrAddModalOpen.body,
        [e.target.name]: e.target.value,
      }
    })
  };

  //this function handles nested boolean changes for hasPanelAccess, hasSurveyAccess and hasToolsAccess
  const handleEnterpriseSettingsChange = (e: TODO) => {
    setEditOrAddModalOpen({
      ...editOrAddModalOpen,
      body: {
        ...editOrAddModalOpen.body,
        enterpriseSettings: {
          ...editOrAddModalOpen.body.enterpriseSettings,
          [e.target.name]: e.target.value
        }
      }
    });
  };

  //this function handles nested boolean changes for can create canCreateSubAccounts
  const handleSubaccSettingsChange = (e: TODO) => {
    setEditOrAddModalOpen({
      ...editOrAddModalOpen,
      body: {
        ...editOrAddModalOpen.body,
        subAccountSettings: {
          ...editOrAddModalOpen.body.subAccountSettings,
          [e.target.name]: e.target.value
        }
      }
    });
  };

  //this is a handler for the date input
  const handleTimeChange = (e: TODO) => {
    setInputValue(e.target.value)
  };

  //this function is called to close the modal. It sets the state to it's initial state
  const closeModal = () => {
    setEditOrAddModalOpen({
      shouldModalOpen: false,
      whichModalOpens: "",
      body: {
        country: "",
        isTrial: false,
        name: "",
        trialEndTimeUtc: "",
        subAccountSettings: {
          canCreateSubAccounts: false
        },
        enterpriseSettings: {
          hasSurveyAccess: false,
          hasToolsAccess: false,
          singleSignOnConnectionName: false,
          hasPinboardAccess: false,
          hasAudienceAccess: false
        }
      }
    })
  };

  //save modal has 2 checks. If the whichModalOpens is edit then patch by id is called and if everything is ok then the modal is set to initial value
  //if the value for whichModalOpens is add then post is called and there is validation on FE for the contry and name input. If isTrial is false then
  //the date input could go empty however if isTrial is true then FE checks for the date input also
  //in edit we change the date input from utc to local and then for the post request we change it back to utc
  //in add we change only to utc when sending. For adding we also validate the format for the date. If it's wrong then an error message displays
  const saveModal = () => {
    if (editOrAddModalOpen.whichModalOpens === 'edit') {
      const date = inputValue
      const currentDateTime = new Date();
      const time = `${currentDateTime.getHours()}:${currentDateTime.getMinutes()}`
      let d = null
      let isoDateTime = null
      if (date) {
        d = new Date(`${date} ${time}`);
        isoDateTime = d.toISOString();
      }
      const body = {
        companyName: editOrAddModalOpen.body?.name,
        hasToolAccess: editOrAddModalOpen.body?.enterpriseSettings?.hasToolsAccess,
        hasSurveyAccess: editOrAddModalOpen.body?.enterpriseSettings?.hasSurveyAccess,
        isTrial: editOrAddModalOpen.body?.isTrial,
        trialEndTimeUtc: isoDateTime,
        canCreateSubAccounts: editOrAddModalOpen.body?.subAccountSettings?.canCreateSubAccounts,
        country: editOrAddModalOpen.body?.country,
        hasPinboardAccess: editOrAddModalOpen.body?.enterpriseSettings?.hasPinboardAccess,
        hasAudienceAccess: editOrAddModalOpen.body?.enterpriseSettings?.hasAudienceAccess,
      }
      fetchPatchJson(`/backoffice/customers/${customerID}`, token, body)
        .then((res: TODO) => res.json())
        .then((data: TODO) => {
          if (data.error || data.message) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${data.error ? data.error : data.message}` } })
          } else {
            setEditOrAddModalOpen({
              shouldModalOpen: false,
              whichModalOpens: "",
              body: {
                country: "",
                isTrial: false,
                name: "",
                trialEndTimeUtc: "",
                subAccountSettings: {
                  canCreateSubAccounts: false
                },
                enterpriseSettings: {
                  hasSurveyAccess: false,
                  hasToolsAccess: false,
                  singleSignOnConnectionName: false,
                  hasPinboardAccess: false,
                  hasAudienceAccess: false
                }
              }
            })
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Customer edited successfully' } })
          }
        })
    } else if (editOrAddModalOpen.whichModalOpens === 'add') {
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      const date = inputValue
      const currentDateTime = new Date();
      const time = `${currentDateTime.getHours()}:${currentDateTime.getMinutes()}`;

      if (date.match(regex) === null && editOrAddModalOpen.body?.isTrial === true) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'Wrong date format' } })
      } else {
        const d = new Date(`${date} ${time}`);
        const isoDateTime = editOrAddModalOpen.body?.isTrial === true ? d.toISOString() : "";
        const body = {
          companyName: editOrAddModalOpen.body?.name,
          hasToolAccess: editOrAddModalOpen.body?.enterpriseSettings?.hasToolsAccess,
          hasSurveyAccess: editOrAddModalOpen.body?.enterpriseSettings?.hasSurveyAccess,
          isTrial: editOrAddModalOpen.body?.isTrial,
          trialEndsUtc: isoDateTime,
          canCreateSubAccounts: editOrAddModalOpen.body?.subAccountSettings?.canCreateSubAccounts,
          country: editOrAddModalOpen.body?.country,
          hasPinboardAccess: editOrAddModalOpen.body?.enterpriseSettings?.hasPinboardAccess,
          hasAudienceAccess: editOrAddModalOpen.body?.enterpriseSettings?.hasAudienceAccess
        }
        if (body.companyName.length <= 0 || body.country.length <= 0) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'Please fill all the fields' } })
        } else {
          fetchPostJson("/backoffice/customers", token, body)
            .then((res: TODO) => {
              if (res.error) {
                dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error } })
              } else {
                setEditOrAddModalOpen({
                  shouldModalOpen: false,
                  whichModalOpens: "",
                  body: {
                    country: "",
                    isTrial: false,
                    name: "",
                    trialEndTimeUtc: "",
                    subAccountSettings: {
                      canCreateSubAccounts: false
                    },
                    enterpriseSettings: {
                      hasSurveyAccess: false,
                      hasToolsAccess: false,
                      singleSignOnConnectionName: false,
                      hasPinboardAccess: false,
                      hasAudienceAccess: false
                    }
                  }
                });
                dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Customer added successfully' } })
              };
            });
        };
      };
    };
  };

  //functionn to open or close the add user modal
  const closeAddUserModal = () => {
    setAddUserModal(false)
    setAddUserInputValue('')
    setError({
      ...error,
      show: false,
      message: ""
    })
  }

  //function that checks if an email is a valid format
  function isValidEmail(email: TODO) {
    return /\S+@\S+\.\S+/.test(email);
  }

  //fundtuion to save the user and send an email
  const saveAddUserModal = () => {
    const body = [
      {
        emailAddress: addUserInputValue,
        admin: isAdmin
      }
    ]
    if (!error.show) {
      fetchPostJson(`backoffice/customers/${customerID}/userInvite`, token, body)
        .then((res: TODO) => {
          if (res[0]?.error || res[0]?.errorMessage) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res[0].error ? res[0].error : res[0].errorMessage } })
          } else {
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Email has been sent' } })
          }
        })
      setAddUserInputValue('')
      setAddUserModal(false)
    }
  }

  //handle change for an input that checks for the mail type
  const handleAddUserInputChange = (e: TODO) => {
    if (!isValidEmail(e.target.value)) {
      setError({
        ...error,
        show: true,
        message: 'Email is invalid'
      });
    } else {
      setError({
        ...error,
        show: false
      });
    }
    setAddUserInputValue(e.target.value)
  }

  return (
    <Dialog width="800px" height="70%" className="import-dialog customer-dialog no-wrap" title={editOrAddModalOpen.whichModalOpens === "edit" ? "Edit organisation" : "Add organisation"} onClose={() => closeModal()}>
      {addUserModal &&
        <AddUserModal
          closeModal={closeAddUserModal}
          saveModal={saveAddUserModal}
          addUserInputValue={addUserInputValue}
          handleAddUserInputChange={handleAddUserInputChange}
          error={error}
          isAdmin={isAdmin}
          setIsAdmin={setIsAdmin}
        />
      }
      <div className="container">
        <div className="d-flex flex-row p-5">
          <div className="d-flex flex-column w-50">
            <input type="text" placeholder="Organisation name" name="name" autoComplete="off" className={`${!parsedJwt[`${import.meta.env.VITE_APP_AUTH0_NAMESPACE}/backofficeadmin`] ? "border-0 mb-5 company-name-input pointer-events-none" : "border-0 mb-5 company-name-input"}`} value={editOrAddModalOpen.body?.name} onChange={(e) => handleInputChange(e)} />
            <div className="d-flex flex-row justify-content-between w-100">
              <div className="d-flex flex-column w-75">
                <div className="lh-dialog mb-3">
                  Location
                </div>
                <div className="lh-dialog">
                  Sharing (Sub accounts)
                </div>
                <div className="lh-dialog">
                  Survey Builder
                </div>
                <div className="lh-dialog">
                  Data Operations
                </div>
                <div className="lh-dialog">
                  Pinboard
                </div>
                <div className="lh-dialog">
                  Audiences
                </div>
                <div className="lh-dialog mt-3">
                  Trial period
                </div>
                <div className="lh-dialog">
                  Trial period ends
                </div>
                {editOrAddModalOpen.whichModalOpens === 'edit' &&
                  <div className="d-flex">
                    <button type='button' className={`${!parsedJwt[`${import.meta.env.VITE_APP_AUTH0_NAMESPACE}/backofficeadmin`] ? "dispay-none-the-item pointer-events-none" : "btn btn-link add-user p-0 mt-3"}`} onClick={() => setAddUserModal(true)}>
                      Add new user
                    </button>
                  </div>
                }
              </div>
              <div className="d-flex flex-column w-25 align-items-start">
                <input type="text" name="country" placeholder="Not specified" className={`${!parsedJwt[`${import.meta.env.VITE_APP_AUTH0_NAMESPACE}/backofficeadmin`] ? "border-0 lh-dialog mb-3 pointer-events-none" : "border-0 lh-dialog mb-3"}`} value={editOrAddModalOpen.body?.country || ""} onChange={(e) => handleInputChange(e)} />
                <Switch className={`${!parsedJwt[`${import.meta.env.VITE_APP_AUTH0_NAMESPACE}/backofficeadmin`] ? "lh-dialog pointer-events-none" : "lh-dialog"}`} onChange={(e) => handleSubaccSettingsChange(e)} checked={editOrAddModalOpen.body?.subAccountSettings?.canCreateSubAccounts} name="canCreateSubAccounts" size="small" />
                <Switch className={`${!parsedJwt[`${import.meta.env.VITE_APP_AUTH0_NAMESPACE}/backofficeadmin`] ? "lh-dialog pointer-events-none" : "lh-dialog"}`} onChange={(e) => handleEnterpriseSettingsChange(e)} checked={editOrAddModalOpen.body?.enterpriseSettings?.hasSurveyAccess} name="hasSurveyAccess" size="small" />
                <Switch className={`${!parsedJwt[`${import.meta.env.VITE_APP_AUTH0_NAMESPACE}/backofficeadmin`] ? "lh-dialog pointer-events-none" : "lh-dialog"}`} onChange={(e) => handleEnterpriseSettingsChange(e)} checked={editOrAddModalOpen.body?.enterpriseSettings?.hasToolsAccess} name="hasToolsAccess" size="small" />
                <Switch className={`${!parsedJwt[`${import.meta.env.VITE_APP_AUTH0_NAMESPACE}/backofficeadmin`] ? "lh-dialog pointer-events-none" : "lh-dialog"}`} onChange={(e) => handleEnterpriseSettingsChange(e)} checked={editOrAddModalOpen.body?.enterpriseSettings?.hasPinboardAccess} name="hasPinboardAccess" size="small" />
                <Switch className={`${!parsedJwt[`${import.meta.env.VITE_APP_AUTH0_NAMESPACE}/backofficeadmin`] ? "lh-dialog pointer-events-none" : "lh-dialog"}`} onChange={(e) => handleEnterpriseSettingsChange(e)} checked={editOrAddModalOpen.body?.enterpriseSettings?.hasAudienceAccess} name="hasAudienceAccess" size="small" />
                <Switch className={`${!parsedJwt[`${import.meta.env.VITE_APP_AUTH0_NAMESPACE}/backofficeadmin`] ? "lh-dialog mt-3 pointer-events-none" : "lh-dialog mt-3"}`} onChange={(e) => handleInputChange(e)} checked={editOrAddModalOpen.body?.isTrial} name="isTrial" size="small" />
                <input disabled={!editOrAddModalOpen.body?.isTrial} className={`${!parsedJwt[`${import.meta.env.VITE_APP_AUTH0_NAMESPACE}/backofficeadmin`] ? "lh-dialog border-0 w-date pointer-events-none" : "lh-dialog border-0 w-date"}`} type="date" value={inputValue} onChange={(e) => handleTimeChange(e)} />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end w-50">
            <div className="backoffice-customer-logo bg-light">
              {editOrAddModalOpen.body?.name[0] ? editOrAddModalOpen.body?.name[0].toUpperCase() : "C"}
            </div>
          </div>
        </div>
        <div className="d-flex flex-row table-padding">
          {editOrAddModalOpen.whichModalOpens === 'edit' && editOrAddModalOpen.body?.users ?
            <BackofficeUserList
              closeMainModal={closeModal}
              customerID={customerID}
              editOrAddModalOpen={editOrAddModalOpen}
              setEditOrAddModalOpen={setEditOrAddModalOpen}
            />
            : null
          }
        </div>
      </div>
      <DialogActionsBar>
        <button type="button" className="k-button btn-secondary" onClick={() => closeModal()}>
          Cancel
        </button>
        <button
          type="button"
          className={`${!parsedJwt[`${import.meta.env.VITE_APP_AUTH0_NAMESPACE}/backofficeadmin`] ? "dispay-none-the-item pointer-events-none" : "k-button btn-primary"}`}
          onClick={() => saveModal()}>
          Save
        </button>
      </DialogActionsBar>
    </Dialog>
  );
};