import { Button } from '@progress/kendo-react-buttons';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchPostResOrJson as submitPanel, fetchGetJson as getSample } from '../../../../services/services';
import { RootState } from '../../../../store/reducers/rootReducer';
import ScheduleModal from '../../../shared/ScheduleModal/ScheduleModal';
import { Avatar } from '../../../shared/Avatar/Avatar'
import { formatDate } from '../../../shared/DateUtils/DateUtils';

interface Props {
  token: string
}

export const PanelSubmitTabContent = ({ token }: Props) => {
  const { panelData } = useSelector((theState: RootState) => theState.panelStateReducer);
  const [scheduleModal, setScheduleModal] = useState<boolean>(false)
  const [error, setError] = useState({ show: false, message: '' })
  const [verifying, setVerifying] = useState<boolean>(false)

  const dispatch = useDispatch()

  const onSend = () => {
    const body = { "submitNow": true }
    submitPanel(`projects/${panelData.sampleData.projectId}/samples/${panelData.sampleData.id}/submit`, token, body)
      .then((res: any) => {
        if (res && (res.message || res.error)) {
          setError({ show: true, message: res.message ? res.message : res.error })
        } else if (res) {
          getSample(`projects/${panelData.sampleData.projectId}/samples/${panelData.sampleData.id}`, token)
            .then((res: any) => {
              dispatch({ type: 'SET_PANEL_INITIAL_DATA', payload: res })
              setScheduleModal(false)
              setVerifying(false)
              setError({ show: false, message: '' })
            })
        }
      })
  }

  return (
    <div className="flex-grow-1 d-flex flex-column h-100">
      {scheduleModal &&
        <ScheduleModal
          title="Schedule submit"
          description="Submit will be processed in 5 minutes."
          handleClose={() => setScheduleModal(false)}
          onScheduleSend={() => onSend()}
          onSend={() => { setVerifying(true); onSend() }}
          error={error}
          verifying={verifying}
          type='sample'
        />}
      <div className="container-fluid h-100 p-0">
        <div className="row no-gutters flex-grow-1 h-100 w-100 overflow-hidden">
          <div className="col-lg-9 survey-content bg-white border-bottom border-right d-flex flex-column">
            <div className="d-flex flex-column border-bottom flex-grow-1">
              <div className="p-3 border-bottom bg-light">
                <h5 className="mb-0">Summary</h5>
              </div>
              <div className="d-flex flex-column p-3">
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <Avatar color="primary" icon="fa fa-poll-h" class="avatar-lg" />
                    <div className="d-flex flex-column justify-content-center ml-2">
                      <h3 className="text-primary mb-0">{panelData.sampleData.name} </h3>
                      {panelData.sampleData.description !== '' && <h6 className="mt-2">{panelData.sampleData.description}</h6>}
                    </div>
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <h6 className="mb-1">Status</h6>
                    <h4 className="mb-0">
                      <span className={`badge ${panelData.sampleData.isSubmitNow === true ? "bg-success text-white" : "bg-light text-dark"}`}>
                        {panelData.sampleData.isSubmitNow ? 'SUBMITTED' : 'NOT SUBMITTED'}
                      </span></h4>
                  </div>
                  <div className="d-flex">
                    <div className="d-flex justify-content-center align-items-center">
                      <Avatar icon="fas fa-user" class="avatar-sm" color="light-blue" />
                      <div className="d-flex flex-column">
                        <h6 className="mb-2"> {panelData.sampleData.createdByName}</h6>
                        <h6 className="m-0">{formatDate(panelData.sampleData.createdUtc, "DATETIME_MED")}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex p-3 justify-content-end">
              <div className="col-md-2">
                <Button className="btn btn-primary w-100" onClick={() => setScheduleModal(true)} disabled={panelData.sampleData.isSubmitNow}>Submit</Button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 survey-content bg-white border-right border-bottom ">
            <div className="p-3 border-bottom bg-light">
              <h5 className="mb-0">Progress</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

