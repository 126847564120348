import { useDispatch } from 'react-redux';

import { Avatar } from '../../../../shared/Avatar/Avatar';
import type { SubAccount } from '../../../../../interfaces/adminInterfaces/adminInterfaces';

interface Props {
  subAccount: SubAccount
  handleOpenDeleteSubAccountModal: () => void
}

export const SubAccountCard = ({ subAccount, handleOpenDeleteSubAccountModal }: Props) => {
  const dispatch = useDispatch()

  return (
    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
      <div style={{ cursor: 'default' }} className="h-100 project-card card border-0">
        <div className="d-flex flex-column justify-content-between h-100">
          <div className="d-flex flex-column justify-content-between p-3 gap-md h-100">
            <div className="d-flex justify-content-between align-items-center gap-md">
              <div className="d-flex flex-column text-truncate">
                <p className="m-0 text-dark h6 strong text-truncate">{subAccount.name}</p>
                <span className="text-muted small text-truncate">{subAccount.description}</span>
              </div>
              <Avatar
                color={"light"}
                rounded="large"
                class="avatar-sm m-0"
                icon={'fal fa-lg fa-user-circle text-primary'} />
            </div>
            <div className="d-flex align-items-center small text-muted gap-sm">
              <span>{subAccount.createdByName}</span>
              <span>-</span>
              <span>{subAccount.createdUtc.substr(0, 10)}</span>
            </div>
          </div>

          <div className="project-card-buttons d-flex border-top">
            <button onClick={() => dispatch({ type: 'UPDATE_SHOW_SUB_ACCOUNTS_PROVIDERS_MODAL', payload: { subAccount: subAccount, show: true } })} type="button" className="btn btn-block text-dark border-right">
              <i className="far fa-edit pr-2" />
            </button>
            <button onClick={() => dispatch({ type: 'UPDATE_SHOW_SUB_ACCOUNTS_INVITE_USERS_MODAL', payload: { show: true, subAccount: subAccount.id } })} type="button" className="btn btn-block text-dark m-0 border-right">
              <i className="far fa-user pr-2" />
            </button>
            <button onClick={handleOpenDeleteSubAccountModal} type="button" className="btn btn-block text-dark m-0">
              <i className="far fa-trash-alt pr-2" />
            </button>
          </div>

        </div>
      </div>
    </div>
  )
}