import React from 'react';
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartValueAxisItem,
  ChartValueAxis,
} from '@progress/kendo-react-charts';
import returnLabelContent from '../../../../../../shared/helpers/returnLabelContent/returnLabelContent';
import returnChartScaleData from '../../../../../../shared/helpers/returnChartScaleData/returnChartScaleData';

export default ({ data, zoomLevel }) => {
  const categories = data[0].categories
  const series = []

  data[0].series.forEach((el, key) => {
    if (key % 2 === 0) {
      let negateValues = []
      el.data.forEach(value => {
        negateValues.push(value * -1)
      })
      series.push({ name: el.name, data: negateValues })
    }
    else {
      series.push({ name: el.name, data: el.data })
    }
  })


  const values = returnChartScaleData(data[0].scale, data[0].isstacked, data[0].type)

  return (
    <React.Fragment>
      <span className="table-chart-title" style={{ "fontSize": `${((zoomLevel) * 0.875).toString()}rem` }}>{data[0].title}</span>
      <Chart transitions={false} style={{ "minHeight": `${800 * (zoomLevel)}px` }} >
        <ChartLegend position="right" orientation="vertical" labels={{ font: `${zoomLevel}rem sans-serif` }} />
        <ChartValueAxis>
          <ChartValueAxisItem
            min={values.min}
            max={values.max}
            axisCrossingValue={[0, Number.NEGATIVE_INFINITY]}
            majorUnit={values.scale}
            labels={{ content: (e) => returnLabelContent(e, data[0].hidepctsign), rotation: "auto", font: `${zoomLevel}rem sans-serif` }} />
        </ChartValueAxis>
        <ChartCategoryAxis >
          <ChartCategoryAxisItem />
          <ChartCategoryAxisItem categories={categories} labels={{ font: `${zoomLevel}rem sans-serif` }} />
        </ChartCategoryAxis>
        <ChartSeries visible={true}>
          {
            series.map((item, key) => (
              <ChartSeriesItem
                type="bar"
                spacing={key === 0 ? 0.5 : null}
                gap={key === 0 ? 4 : null}
                key={key}
                data={item.data}
                name={series.length < 50 ? item.name : null}
                tooltip={{ visible: data[0].hidepctsign ? false : true }}
                labels={{ visible: data[0].hidevalues ? false : true, content: (e) => returnLabelContent(e, data[0].hidepctsign), font: `${zoomLevel}rem sans-serif` }} />
            ))
          }
        </ChartSeries>
      </ Chart>
    </React.Fragment>
  )
}