import React, { useState } from 'react';

import ShareProjectFunctionalityAnalysisTabContent from './ShareProjectFunctionalityAnalysisTabContent/ShareProjectFunctionalityAnalysisTabContent';

const tabs = ["Analysis types", "Analysis actions", /* "Project actions", */ "Access control"]

export default ({ onChangeInputHandler, formData, isShared }) => {
  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <div className="px-5 py-3">
      <div className="d-flex justify-content-center">
        <div className="list-group list-group-horizontal w-100">
          {
            tabs.map((tab, key) => (
              <button
                key={key}
                type="button"
                style={{ outline: 'none' }}
                onClick={() => setSelectedTab(key)}
                className={`list-group-item list-group-item-action text-center ${selectedTab === key ? 'active' : ''}`}>
                <span>{tab}</span>
              </button>
            ))
          }
        </div>
      </div>

      <h6 className="text-muted text-center my-4">Select how a shared project may be used. <br />You can always change this later.</h6>

      <ShareProjectFunctionalityAnalysisTabContent
        formData={formData}
        isShared={isShared}
        selectedTab={selectedTab}
        onChangeInputHandler={onChangeInputHandler}
      />
    </div>
  )
}