import React from 'react';
import { Button } from '@progress/kendo-react-buttons';

export default (props) => {
  return (
    <Button
      icon={props.icon}
      disabled={props.disabled}
      className={`btn d-flex ${props.bgColor}`}
      onClick={props.action ? props.action : null}>
      {props.title}
    </Button>
  );
}