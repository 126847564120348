
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog } from '@progress/kendo-react-dialogs';

import FilterBuilder from '../FilterBuilder/FilterBuilder';
import { FullHeightSpinner } from '../../../../../../shared/FullHeightSpinner/FullHeightSpinner';
import { fetchGetJson } from '../../../../../../../services/services';

const OptionsFilterBuilder = ({ handleClose, filterData, setFilterData, manualFilterExpression, userData, datasetId, projectId, isSurveyDataset, onSaveFilterOptions, combineFilterData, option, token, analysisType, workflowProjectId }) => {
  const dispatch = useDispatch()
  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    // Currently this is only used in Data operations, to get the structure of the dataset/survey after the user has clicked a button to open the Filter Builder modal
    // The structure endpoint with the mode=cases parameter can be very costly, so here we only call it once, when the modal is opened
    // Ideally, in the future we should be using this method for every Filter Builder modal to avoid unnecessary calls to the structure endpoint
    if (!didMount && filterData?.questions?.length === 0 && datasetId && projectId) {
      setDidMount(true)
      const analysisStructureUrl = isSurveyDataset ? `an/projects/${projectId}/analysis/surveys/${datasetId}/structure` : `an/projects/${projectId}/analysis/${datasetId}/structure`
      fetchGetJson(analysisStructureUrl, token)
        .then((res) => {
          if (res && (res.message || res.error)) {
            handleClose()
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `An error has occured: ${res.error ? res.error : res.message}` } })
          } else {
            setFilterData({ languages: res.languages, questions: res.questions, defaultLanguage: res.defaultLanguage, cases: res.cases })
          }
        })
    }
  }, [datasetId, projectId, didMount, isSurveyDataset, token, dispatch, handleClose, setFilterData, filterData?.questions])

  return (
    <Dialog height="90%" width="90%" className="filter-builder">
      {
        filterData?.questions?.length > 0 ?
          <FilterBuilder
            handleClose={handleClose}
            filterData={filterData}
            manualFilterExpression={manualFilterExpression}
            userData={userData}
            datasetId={datasetId}
            onSaveFilterOptions={onSaveFilterOptions}
            combineFilterData={combineFilterData}
            option={option}
            token={token}
            type='basic'
            analysisType={analysisType}
            workflowProjectId={workflowProjectId}
          />
          :
          <FullHeightSpinner />
      }
    </Dialog >
  );
};

export default OptionsFilterBuilder;
