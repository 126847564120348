import { useState } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import type { RootState } from '../../../../../store/reducers/rootReducer';
import { ErrorMessage } from '../../../../shared/ErrorMessage/ErrorMessage';
import { fetchPostResOrJson as cloneCustomQuestion } from '../../../../../services/services';

interface Props {
  customQuestion: TODO
  onHide: () => void
  updateData: () => void
}

export const CloneSurveyCustomQuestionModal = ({ customQuestion, onHide, updateData }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const [name, setName] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { showOverview } = useSelector((theState: RootState) => theState.surveyCustomQuestionsStateReducer)

  const onSubmitHandler = (e: TODO) => {
    e.preventDefault()
    const body = { name: name }
    setIsLoading(true)
    cloneCustomQuestion(`su/customquestions/${customQuestion.id}/clone`, token, body)
      .then((res: TODO) => {
        setIsLoading(false)
        if (res && !res.error && !res.message) {
          if (showOverview) {
            dispatch({ type: 'UPDATE_SHOW_OVERVIEW', payload: { show: false, customQuestion: null } })
          }
          setErrorMessage('')
          updateData()
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "The custom question has been duplicated successfuly." } })
          onHide()
        } else {
          setErrorMessage(res.error ? res.error : res.message)
        }
      })
  }

  return (
    <Dialog width={600} title={"Duplicate custom question"} onClose={onHide}>
      <form onSubmit={onSubmitHandler}>
        <div className='p-3'>
          <p className='mb-2'>Enter a new name for the selected custom question:</p>
          <Input
            required
            className="w-100"
            value={name}
            onChange={(e: TODO) => setName(e.target.value)}
          />
          {
            errorMessage &&
            <div className="mt-3">
              <ErrorMessage
                errorMessage={errorMessage}
                type="alert"
              />
            </div>
          }
        </div>
        <DialogActionsBar>
          <button type="button" className="k-button k-button-solid-base" onClick={onHide}>Close</button>
          <button disabled={isLoading} type="submit" className="k-button btn btn-primary">
            {isLoading &&
              <div className="spinner-border spinner-border-sm mr-2" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            }
            <span>Duplicate</span>
          </button>
        </DialogActionsBar>
      </form >
    </Dialog >
  )
}