import type { ChangeEvent } from "react";
import type { Target, TargetAnswers } from "../../types/audienceTargetingTypes";

type Props = {
  target: Target;
  answerType: number | null;
  handleQuotaRangeInput: (event: ChangeEvent<HTMLInputElement>, target: Target, answer: TargetAnswers, type: "start" | "end") => void;
}

export const QuotaModalTargets = ({ target, answerType, handleQuotaRangeInput }: Props) => {
  switch (answerType) {
    case 1:
    case 0:
    case null:
      return <div className="d-flex gap-md">
        {target?.answers?.length > 1 ?
          target.answers.map((answer) =>
            <div
              className="d-flex align-items-center py-1 px-2 bg-walr text-primary rounded"
              key={answer.id}>
              <p className="text-start m-0">{answer.text}</p>
            </div>
          )
          :
          <div className="d-flex align-items-center py-1 px-2 bg-secondary text-primary rounded">
            <p className="text-start m-0">{target.answers[0].text}</p>
          </div>
        }
      </div>
    case 2:
      return <div className="d-flex flex-column gap-md py-1 px-2">
        {target?.answers?.map((answer, index) =>
          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
          <div key={index} className="d-flex align-items-center gap-md">
            <input className="form-control shadow-none pl-2 pt-2 pb-2 pr-1 h-32 w-64" type="numeric" min={1} max={99} onChange={(e) => handleQuotaRangeInput(e, target, answer, "start")} defaultValue={answer.start ? answer.start : ""} />
            <p className="m-0">to</p>
            <input className="form-control shadow-none pl-2 pt-2 pb-2 pr-1 h-32 w-64" type="numeric" min={answer.start ? answer.start : 1} max={99} onChange={(e) => handleQuotaRangeInput(e, target, answer, "end")} defaultValue={answer.end ? answer.end : ""} />
          </div>
        )}
      </div>
    default:
      break;
  }
}