import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import type { RootState } from '../../../../../../../../store/reducers/rootReducer';
import { ErrorMessage } from '../../../../../../../shared/ErrorMessage/ErrorMessage';
import { fetchPutJson } from '../../../../../../../../services/services';

interface Props {
  theme: TODO
  themes: TODO
  onHide: () => void
  setThemeData: (data: TODO) => void
  setThemeName: (name: string) => void
  setSelectedTab: (tab: string) => void
}

export default function ApplyThemeModal({ theme, themes, setThemeName, setThemeData, onHide, setSelectedTab }: Props) {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const params: { name: string, survey: string } = useParams()
  const [isApplyingTheme, setIsApplyingTheme] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { theData } = useSelector((theState: RootState) => (theState.surveyInitialDataReducer))

  const onApplyTheme = () => {
    setIsApplyingTheme(true)
    fetchPutJson(`su/projects/${params.name}/surveys/${params.survey}/theme`, token, { id: theme.id })
      .then((res: TODO) => {
        if (res && !res.error && !res.message) {
          const basedOnTheme = themes.data.find((el: { id: string }) => el.id === res.theme.basedOnId)
          const updatedData = JSON.parse(JSON.stringify(theData.data))
          const updatedOriginalData = JSON.parse(JSON.stringify(theData.originalData))
          updatedOriginalData._etag = res._etag
          dispatch({ type: 'UPDATE_SURVEY_DATA', payload: { data: updatedData, originalData: updatedOriginalData } })
          setThemeData(res.theme)
          setThemeName(basedOnTheme ? basedOnTheme.name : 'Survey theme')
          dispatch({ type: "SET_THEME_ID", payload: res.theme.id })
          setIsApplyingTheme(false)
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The theme was applied to the survey successfully' } })
          setSelectedTab('survey')
          onHide()
        } else {
          setIsApplyingTheme(false)
          setErrorMessage(res.error ? res.error : res.message)
        }
      })
  }

  return (
    <Dialog title="Apply to survey" onClose={onHide} width="500px">
      <div className="p-5">
        <p className={`${errorMessage ? 'mb-3' : 'mb-0'}`}>Are you sure you want to apply this theme to your survey?</p>
        {
          errorMessage &&
          <ErrorMessage
            errorMessage={errorMessage}
            type="alert"
          />
        }
      </div>
      <DialogActionsBar>
        <button type='button' className="k-button btn btn-secondary" onClick={onHide}>Cancel</button>
        <button type='button' disabled={isApplyingTheme} className="k-button btn btn-primary" onClick={onApplyTheme}>
          {
            isApplyingTheme &&
            <div className="spinner-border spinner-border-sm mr-2" role="status" />
          }
          <span>Apply theme</span>
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}