import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';

interface Props {
  handleClose: () => void;
  onSendTestEmail: (value: TODO) => void;
  emailContent: TODO;
}

const PreviewEmailModal = ({ handleClose, emailContent, onSendTestEmail }: Props) => {
  const { user } = useAuth0()

  return (
    <Dialog title="Preview" onClose={handleClose} width="75%" height="80%" >
      <div className='d-flex justify-content-evenly h-100'>
        <div className='email-preview w-75 bg-light p-4 d-flex justify-content-center'>
          {/* TODO: Implement JSX parser instead of using dangerouslySetInnerHTML */}
          <div className='w-100 bg-white shadow-sm p-4 overflow-auto' dangerouslySetInnerHTML={{ __html: emailContent.emailBody }} />
        </div>
        <div className='email-actions w-25 p-4'>
          <p className='strong'>Send a test email</p>
          <p className='text-muted'>Send a test email to see how a email invite will appear in your inbox, or to share a draft email with a client.</p>
          <p>Send to</p>
          <Input className='w-100' defaultValue={user?.email} disabled={true} />
          <Button className="btn-primary w-100 mt-4 text-white" onClick={onSendTestEmail}>
            <span>Send test email</span>
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default PreviewEmailModal