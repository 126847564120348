import { useEffect, useState } from 'react'
import { Switch } from '@progress/kendo-react-inputs';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { parseJwt } from '../../../../shared/helpers/decodeJWT/decodeJWT';
import type { RootState } from '../../../../../store/reducers/rootReducer';
import { DeleteUserConfirmationModal } from '../../DeleteUserConfirmationModal/DeleteUserConfirmationModal';
import type { IPropsEditUserModal, IUserClaimsBackofficeRes } from '../../../../../interfaces/backofficeInterfaces/backofficeInterfaces';
import { fetchGetJson, fetchPatchJson } from '../../../../../services/services';

export const BackofficeEditUserModal = ({ closeModal, editUserModalState, showDeleteUserConfirmationModal, setShowDeleteUserConfirmationModal, showDeleteUserConfirmationModalHandler }: IPropsEditUserModal) => {
  const [userClaims, setUserClaims] = useState<IUserClaimsBackofficeRes>({
    backOffice: false,
    backOfficeAdmin: false,
    customQuestionAccess: false,
    supportAgent: false,
  });
  // const [showDeleteUserConfirmationModal, setShowDeleteUserConfirmationModal] = useState(false);
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const parsedToken = parseJwt(token)
  const isBackofficeOwner = parsedToken[`${import.meta.env.VITE_APP_AUTH0_NAMESPACE}/backofficeowner`]
  const dispatch = useDispatch();

  const handleSwitchChange = (e: TODO) => {
    setUserClaims({
      ...userClaims,
      [e.target.name]: e.target.value
    })
  };

  const saveUserModal = () => {
    fetchPatchJson(`backoffice/customers/${editUserModalState.customerID}/users/${editUserModalState.userObject.id}/claims`, token, userClaims)
      .then((res: TODO) => {
        if (res.error || res.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
        } else {
          closeModal();
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'User updated successfully' } })
        }
      })
  };

  useEffect(() => {
    fetchGetJson(`backoffice/customers/${editUserModalState.customerID}/users/${editUserModalState.userObject.id}/claims`, token)
      .then((res: IUserClaimsBackofficeRes) => {
        if (res.error || res.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
        } else {
          setUserClaims(res)
        }
      })
  }, [editUserModalState.customerID, editUserModalState.userObject.id, token, dispatch])

  return (
    <>
      {showDeleteUserConfirmationModal &&
        <DeleteUserConfirmationModal handleClose={showDeleteUserConfirmationModalHandler} editUserModalState={editUserModalState} isBackofficeOwner={isBackofficeOwner} editOrAddModalOpen={undefined} setEditOrAddModalOpen={undefined} />
      }
      <Dialog width="760px" height="55%" className="import-dialog customer-dialog edit-customer-dialog no-wrap" title="Edit user" onClose={() => closeModal()}>
        <div className="container">
          <div className="d-flex flex-row p-5">
            <div className="d-flex flex-column w-50">
              <div className="d-flex flex-column justify-content-start align-items-start mb-5">
                <span className="border-0 company-name-input">{editUserModalState.userObject?.firstName} {editUserModalState.userObject?.lastName}</span>
                <small className="form-text text-muted">{editUserModalState.userObject?.email}</small>
              </div>
              <div className="d-flex flex-row justify-content-between w-100">
                <div className="d-flex flex-column w-75">
                  <div className="lh-dialog mb-3">
                    Location
                  </div>
                  <div className="lh-dialog">
                    BackOffice
                  </div>
                  <div className="lh-dialog">
                    BackOfficeAdmin
                  </div>
                  <div className="lh-dialog">
                    CustomQuestionAccess
                  </div>
                  <div className="lh-dialog">
                    SupportAgent
                  </div>
                </div>
                <div className="d-flex flex-column w-25 align-items-start ">
                  <span className="border-0 lh-dialog mb-3">{editUserModalState.country}</span>
                  <Switch className="lh-dialog" onChange={(e) => handleSwitchChange(e)} disabled={Object.keys(parsedToken).filter(el => el.includes("backofficeowner")).length === 0} checked={userClaims.backOffice} name="backOffice" size="small" />
                  <Switch className="lh-dialog" onChange={(e) => handleSwitchChange(e)} disabled={Object.keys(parsedToken).filter(el => el.includes("backofficeowner")).length === 0} checked={userClaims.backOfficeAdmin} name="backOfficeAdmin" size="small" />
                  <Switch className="lh-dialog" onChange={(e) => handleSwitchChange(e)} disabled={Object.keys(parsedToken).filter(el => el.includes("backofficeowner")).length === 0} checked={userClaims.customQuestionAccess} name="customQuestionAccess" size="small" />
                  <Switch className="lh-dialog" onChange={(e) => handleSwitchChange(e)} disabled={Object.keys(parsedToken).filter(el => el.includes("backofficeowner")).length === 0} checked={userClaims.supportAgent} name="supportAgent" size="small" />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end align-items-center w-50">
              <div className="backoffice-customer-logo-edit-user ">
                {editUserModalState.userObject?.firstName[0] ? editUserModalState.userObject?.firstName[0].toUpperCase() : "C"}
              </div>
            </div>
          </div>
        </div>

        <DialogActionsBar >
          {isBackofficeOwner &&
            <div className='d-flex align-items-center'>
              <button type="button" className="k-button-flat btn" onClick={() => setShowDeleteUserConfirmationModal(true)} style={{ color: "#E5335B" }}>
                Delete user
              </button>
            </div>
          }
          <button type='button' className="k-button btn-secondary" onClick={() => closeModal()}>
            Cancel
          </button>
          <button type='button' onClick={() => saveUserModal()} className="k-button k-button-solid-primary text-white">
            Save
          </button>
        </DialogActionsBar>
      </Dialog>
    </>
  )
}

