import { v4 as uuid } from 'uuid';

export default (selectedGroupsType, activeQuestion, source, newState, body, questions, oldElement, groupsType) => {
  let matched = [],
    matchedTitles = [],
    fetchBody = [];

  const lastSelectedItem = activeQuestion[groupsType].filter(item => item.selected).slice(-1)[0]
  const indexOfLastMatchedElement = activeQuestion[groupsType].indexOf(lastSelectedItem) + 1

  selectedGroupsType.forEach(row => {
    activeQuestion[groupsType].forEach(group => {
      if (group.id === row.id) {
        group.selected = false;
        group.active = false;
        matched.push(group.code);
        matchedTitles.push(group.text);
      }
    })
  })

  let matchedTitle = matchedTitles.join(' & ');
  let matchedCode = matched.join(';')
  activeQuestion[groupsType].splice(
    indexOfLastMatchedElement,
    0,
    {
      selected: false,
      active: true,
      id: uuid(),
      code: matchedCode,
      text: matchedTitle,
      merged: true
    }
  )

  activeQuestion[groupsType].forEach(group => {
    if (group.active && group.code !== matchedCode) {
      fetchBody.push(group.code)
    } else if (group.active && group.code === matchedCode) {
      fetchBody.push(matchedCode)
    }
  })

  if (source === 'firstColumn') {
    newState[source].forEach(el => {
      if (el.active) {
        el.active = false;
      }
    })
    activeQuestion.active = true;
    activeQuestion.mergedBody = body;
    activeQuestion.mergedRows = true;
    newState[source][questions[source].indexOf(oldElement)] = activeQuestion
  }
}