import { Skeleton } from "../../../../shared/Skeleton/Skeleton"

export const QuotaModalCategoriesSkeleton = () => {
  return (
    <div className="d-flex flex-column gap-md">
      <div className="input-group">
        <input type="search" className="form-control search-input" placeholder="Search target variables" />
      </div>

      {[...Array(10).keys()].map((item) => (
        <div key={item}>
          <Skeleton className="h-32" />
        </div>

      ))}
    </div>
  )
}