import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DropDownButton, DropDownButtonItem } from '@progress/kendo-react-buttons';
import { returnUpdatedAnalysisBody } from '../returnUpdatedAnalysisBody/returnUpdatedAnalysisBody';

interface Props {
  token: string,
  dispatchType: string,
  dataType: any,
  requestUrl: string,
  notChangedQuestions: [],
  setLanguageError: (error: string | null) => void,
  tabType: string,
  newQuestionOptions: [],
  firstColumn: [],
  scndColumn: [],
  componentType: string,
  defaultLanguage: any,
  languages: any,
  editingLanguage: any
}

export const LanguageSwitchButton = ({ token, dispatchType, dataType, requestUrl, setLanguageError, tabType, newQuestionOptions, firstColumn, scndColumn, componentType, defaultLanguage, languages, editingLanguage }: Props) => {
  const [updateLanguage, setUpdateLanguage] = useState(null)
  const dispatch = useDispatch();

  useEffect(() => {
    if (updateLanguage) {
      dispatch({ type: "SET_EDITING_LANGUAGE", payload: updateLanguage, })
    }
  }, [dispatch, updateLanguage])

  const onLanguageChange = (e: any) => {
    setUpdateLanguage(e.item.text)
    const body = returnUpdatedAnalysisBody("surveys", tabType, newQuestionOptions, firstColumn, scndColumn, '', e.item.text, defaultLanguage)
    dataType(requestUrl, token, body)

      .then((res: any) => {
        if (componentType === "chartTab") {
          if (res && (res.message || res.error)) {
            if (firstColumn.length > 0) {
              setLanguageError(res.error ? res.error : res.message)
            }
            dispatch({ type: dispatchType, payload: { value: null } })
          } else {
            dispatch({ type: dispatchType, payload: { value: res } })
            setLanguageError(null)
          }
        } else {
          if (res && res.error) {
            setLanguageError(res.error)
            dispatch({ type: dispatchType, payload: { value: null } })
          } else {
            dispatch({ type: dispatchType, payload: { value: res.result } })
            setLanguageError(null)
          }
        }

      })
  }

  return (
    <DropDownButton
      className="analyze-actions-button"
      text={editingLanguage}
      buttonClass="btn btn-outline-analyze d-flex flex-row-reverse rounded-0 px-2"
      icon="fa fas fa-globe"
      onItemClick={(e) => onLanguageChange(e)}
      tabIndex={-1} >
      {languages.map((item: any, key: number) =>
        <DropDownButtonItem key={key} text={item} />
      )}
    </DropDownButton>
  )
}
