import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { AudiencesData, Sample } from '../../../interfaces/auddineceAccessInterfaces/audiencesTypes'

const initialState: AudiencesData = {
  continuationToken: null,
  totalCount: 0,
  samples: []
}

const audiencesDataSlice = createSlice({
  name: 'audiences',
  initialState,
  reducers: {
    createNewAudience: (state) => { Object.assign(state, initialState) },

    setAudiences(_state, action: PayloadAction<AudiencesData>) {
      return action.payload;
    },
    editProjects(state, action: PayloadAction<Sample[]>) {
      const updatedState = { ...state, samples: action.payload }
      return updatedState
    }
  }
})

export const {
  setAudiences,
  editProjects
} = audiencesDataSlice.actions
export default audiencesDataSlice.reducer