import React from 'react';
import { DropdownButton } from '../../../../../shared/DropdownButton/DropdownButton';

export const ElementActions = ({ type, showOptionsDialog, id, deleteSection, sections, setSectionIndex, setShowModal, showModal, openSectionCSS }) => {
  const allItems = [{ text: 'Add new element', icon: 'fa fas fa-cog', action: 'adding', disabled: false }, { text: 'Options', icon: 'fa fas fa-cog', action: 'options', disabled: false }, { text: 'Add CSS', icon: 'fa fas fa-cog', action: 'addingCSS', disabled: false }, { text: 'Delete', icon: 'fa fas fa-trash', action: 'delete', disabled: sections.length === 1 ? true : false }]

  const onActionClick = (props) => {
    if (props.item.text === 'Options') {
      showOptionsDialog(type, id)
    } else if (props.item.text === 'Delete') {
      deleteSection(id)
    } else if (props.item.text === "Add new element") {
      setSectionIndex(id)
      setShowModal({ ...showModal, show: !showModal.show })
    } else if (props.item.text === "Add CSS") {
      openSectionCSS(id)
    }
  }
  return (
    <DropdownButton
      items={allItems}
      hideChevron={true}
      icon="more"
      onItemClick={onActionClick}
    />
  )
}