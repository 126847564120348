
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { v4 as uuid } from 'uuid';
import { useHistory } from 'react-router-dom';
import { Input } from '@progress/kendo-react-inputs';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import FilterBuilder from '../FilterBuilder/FilterBuilder';
import { Avatar } from '../../../../../../shared/Avatar/Avatar'
import { returnFilterDescription } from '../../../shared/helpers/FilterBuilderHelpers/FilterBuilderHelpers';
import { validateQuestionId, checkCalcValues, initializeQuestionData } from '../../../shared/helpers/createQuestionHelpers/createQuestionHelpers';
import { fetchPostJson as createCustomQuestion, fetchGetJson as getCustomQuestion, fetchPutResOrJson as updateCustomQuestion } from '../../../../../../../services/services'

export default function CreateQuestionDialog({ handleClose, filterData, type, editMode, questionId, importedCreatedQuestion, updateData }) {
  const { token } = useSelector((state) => state.tokenStateReducer);
  const params = useParams();
  const history = useHistory()
  const [combineData, setCombineData] = useState([])
  const [editQuestionData, setEditQuestionData] = useState(true)
  const [questionData, setQuestionData] = useState(() => initializeQuestionData(importedCreatedQuestion, type))
  const areMultipleQuestions = !!(Array.isArray(questionData) && questionData.length > 1);
  const [selectedQuestion, setSelectedQuestion] = useState(Array.isArray(questionData) ? questionData[0] : questionData);
  const [idError, setIdError] = useState({ show: false, message: '' })
  const [calcError, setCalcError] = useState(false)
  const [alertInfoCalc, setAlertInfoCalc] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [hasEmptyField, setHasEmptyField] = useState(false)
  const [failedQuestions, setFailedQuestions] = useState(null)
  const [sentQuestions, setSentQuestions] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const { selectedSession } = useSelector(theState => ({ selectedSession: theState.breadcrumbStateReducer.selectedSession }), shallowEqual);
  const dispatch = useDispatch();

  const datasetId = params.dataset;
  const projectId = params.name;

  useEffect(() => {
    if (questionData.length === 1) {
      const disabledSwitch = questionData.rows.some(row => {
        if (questionData.type === "m") {
          return !!(!row.text || !row.if);
        } if (questionData.type === "f") {
          return !!(!row.text || !row.if || !row.calc);
        }
        return false;
      })
      setHasEmptyField(disabledSwitch)
    }
  }, [questionData])

  useEffect(() => {
    if (editMode && editQuestionData) {
      getCustomQuestion(`an/projects/${history.location.pathname.split('/')[2]}/analysis/${datasetId}/custom-questions/${questionId}`, token)
        .then(res => {
          if (res) {
            const rowsData = []
            res.rows.forEach((row) => {
              rowsData.push({ ...row, id: uuid(), inEdit: true })
            })
            setQuestionData({
              id: res.id,
              text: res.text,
              rows: rowsData,
              type: res.questionType
            })
          }
        })
    }
    setEditQuestionData(false)
  }, [editMode, datasetId, questionId, token, editQuestionData, history.location.pathname])

  const onAddQuestion = () => {
    if (questionData.length > 1) {
      let calc = false;
      const numericQuestions = questionData.filter(el => el.type !== "m")
      if (numericQuestions.length > 0) {
        const rows = numericQuestions.map((row) => {
          return row.rows
        })
        calc = checkCalcValues(rows.flat(), "multiple")
        setCalcError(calc)
      }
      const questionDataIds = questionData.map((el) => { return el.id })
      const message = validateQuestionId(questionDataIds, filterData.questions, "multiple")
      if (message !== '') {
        setIdError({ show: true, message: message })
      }
      else {
        setIdError({ show: false, message: '' })
      }

      if (message === '' && calc === false) {
        setIsLoading(true)
        let body = questionData
        if (failedQuestions?.some(el => !el.success)) {
          const filteredBody = body.filter((el) => {
            return failedQuestions.some((f) => {
              return f.uId === el.uId;
            });
          });
          body = filteredBody
        }

        body.forEach(question => {
          question.questionType = question.type
        })

        const selectedQuestionIndex = questionData.findIndex((que) => que.uId === selectedQuestion.uId)
        createCustomQuestion(`an/projects/${projectId}/analysis/${datasetId}/custom-questions-batch`, token, body)
          .then(res => {
            setIsLoading(false)
            if (res?.some(el => !el.success)) {
              if (sentQuestions === null) {
                setSentQuestions(res)
                setErrorMessage(res[selectedQuestionIndex].message)
              } else if (sentQuestions.length > 0) {
                const updatedQuestions = sentQuestions.map(que => res.find(obj => obj.uId === que.uId) || que)
                setSentQuestions(updatedQuestions)
                setErrorMessage(updatedQuestions[selectedQuestionIndex].message)
              }
              setFailedQuestions(res.filter(que => !que.success))
            } else {
              handleClose()
              updateData()
            }
          })
      }
    } else {
      let calc = false;
      if (questionData.type && questionData.type !== "m") {
        calc = checkCalcValues(questionData.rows, "single")
        setCalcError(calc)
      }

      const message = validateQuestionId(questionData.id, filterData.questions, "single")
      if (message !== '') {
        setIdError({ show: true, message: message })
      }
      else {
        setIdError({ show: false, message: '' })
      }

      if (message === '' && calc === false) {
        setIsLoading(true)
        const body = {
          text: questionData.text,
          id: questionData.id,
          rows: questionData.rows,
          questionType: questionData.type
        }
        createCustomQuestion(`an/projects/${history.location.pathname.split('/')[2]}/analysis/${datasetId}/custom-questions`, token, body)
          .then(res => {
            setIsLoading(false)
            if (res && res.qno === `${body.id}.a`) {
              handleClose()
              updateData()
            } else if (res && (res.error || res.message)) {
              setErrorMessage(res.error ? res.error : res.message)
            }
          })
      }
    }
  }

  const onUpdateQuestion = () => {
    let calc = false;
    if (questionData.type !== "m") {
      calc = checkCalcValues(questionData.rows)
      setCalcError(calc)
    }
    if (calc === false) {
      setIsLoading(true)
      const body = {
        text: questionData.text,
        id: questionData.id,
        rows: questionData.rows,
        questionType: questionData.type,
      }
      updateCustomQuestion(`an/projects/${history.location.pathname.split('/')[2]}/analysis/${datasetId}/custom-questions`, token, body)
        .then(res => {
          setIsLoading(false)
          if (res && res.status === 200) {
            handleClose();
            updateData();
          } else if (res && (res.error || res.message)) {
            setErrorMessage(res.error ? res.error : res.message)
          }
        })
    }
  }

  const onSaveFilterOptions = (data, expression) => {
    const text = returnFilterDescription(data)

    if (areMultipleQuestions) {
      const selectedQuestionIndex = questionData.findIndex(ques => ques.uId === selectedQuestion.uId)
      const updatedQuestionData = [...questionData]
      const updatedRows = selectedQuestion.rows

      if (selectedQuestion.type === "f") {
        updatedRows.push({ text: text, if: expression, calc: '', id: uuid(), inEdit: true })
      } else {
        updatedRows.push({ text: text, if: expression, id: uuid(), inEdit: true })
      }

      updatedQuestionData[selectedQuestionIndex].rows = [...updatedRows]
      const updatedSelectedQuestion = { ...selectedQuestion, rows: updatedRows }

      setSelectedQuestion(updatedSelectedQuestion)
      setQuestionData(updatedQuestionData)

    } else {
      const updatedRows = questionData.rows
      if (type === 'f') {
        updatedRows.push({ text: text, if: expression, calc: '', id: uuid(), inEdit: true })
      } else {
        updatedRows.push({ text: text, if: expression, id: uuid(), inEdit: true })
      }
      setQuestionData({ ...questionData, rows: updatedRows })

      if (type === 'f') {
        setAlertInfoCalc(true)
      }
    }

    setCombineData([])
  }

  const onItemChange = (e) => {
    if (e.field === 'calc') {
      setAlertInfoCalc(false)
    }
    if (questionData.length > 1) {
      e.dataItem[e.field] = e.value;
      setSelectedQuestion({ ...selectedQuestion })
    } else {
      e.dataItem[e.field] = e.value;
      setQuestionData({ ...questionData })
    }
  };

  const rowOptionsClickHandler = (e, theRow, type) => {
    e.stopPropagation();
    if (questionData.length > 1) {
      const selectedQuestionIndex = questionData.findIndex((el) => el.id === selectedQuestion.id)
      const updatedRow = { ...theRow, id: uuid() };
      const rowsToUpdate = questionData[selectedQuestionIndex].rows
      const updatedRowIndex = rowsToUpdate.findIndex((el) => el.id === theRow.id)

      switch (type) {
        case "duplicate":
          rowsToUpdate.push(updatedRow)
          setSelectedQuestion({ ...selectedQuestion, rows: rowsToUpdate })
          break
        case "delete":
          rowsToUpdate.splice(updatedRowIndex, 1);
          setSelectedQuestion({ ...selectedQuestion, rows: rowsToUpdate })
          break
        case "addEmpty":
          rowsToUpdate.push(theRow)
          setSelectedQuestion({ ...selectedQuestion, rows: rowsToUpdate })
          break
        default:
          break
      }
    } else {
      const updatedRows = [...questionData.rows]
      const selectedRowIndex = updatedRows.findIndex((el) => el.id === theRow.id);

      switch (type) {
        case "duplicate": {
          const updatedRow = { ...theRow, id: uuid() };
          updatedRows.splice(selectedRowIndex, 0, updatedRow);
          setQuestionData({ ...questionData, rows: updatedRows });
          break
        }
        case "delete":
          updatedRows.splice(selectedRowIndex, 1);
          setQuestionData({ ...questionData, rows: updatedRows });
          break
        case "addEmpty":
          updatedRows.push(theRow)
          setQuestionData({ ...questionData, rows: updatedRows });
          break
        default:
          break
      }
    }

  }

  const optionsCellRenderer = (theRow, type) => {
    if (type === "options") {
      return (
        <td className="d-flex justify-content-start mx-1 px-0" style={{ height: "49px" }}>
          <div
            className="d-flex align-items-center text-muted"
            title="Duplicate row"
            onClick={(e) => rowOptionsClickHandler(e, theRow.dataItem, "duplicate")}
          >
            <i className="fas fa-copy" />
          </div>
        </td>
      )
    } if (type === "delete") {
      return (
        <td className="d-flex justify-content-start px-0" style={{ height: "49px" }}>
          <div
            className="d-flex align-items-center text-danger m-auto"
            title="Delete row"
            onClick={(e) => rowOptionsClickHandler(e, theRow.dataItem, "delete")}
          >
            <i className="fas fa-trash-alt" />
          </div>
        </td>
      )
    }
  }

  const handleChange = (event) => {
    if (questionData.length > 1) {
      const { value, name } = event.target
      const updatedQuestions = [...questionData]
      const selectedQuestionIndex = questionData.findIndex((el) => el.uId === selectedQuestion.uId)
      updatedQuestions[selectedQuestionIndex][name] = value
      setSelectedQuestion(updatedQuestions[selectedQuestionIndex])
      setQuestionData(updatedQuestions)
    } else {
      const { value, name } = event.target
      setQuestionData({
        ...questionData,
        [name]: value
      })
    }
  }

  const changeQuestion = (changedQuestion) => {
    setSelectedQuestion(changedQuestion)
    const selectedQuestionIndex = questionData.findIndex((que) => que.uId === changedQuestion.uId)
    setErrorMessage(sentQuestions?.[selectedQuestionIndex].message)
  }

  const tableData = areMultipleQuestions ? [...selectedQuestion.rows] : [...questionData.rows];

  return (
    <Dialog onClose={handleClose} title={editMode ? 'Edit Question' : 'Custom Question'} height="90%" width="90%" className="create-question">
      <div className="d-flex h-100 no-gutters">
        <div className="col-md-6">
          <FilterBuilder
            handleClose={handleClose}
            changeQuestion={changeQuestion}
            filterData={filterData}
            datasetId={datasetId}
            onSaveFilterOptions={onSaveFilterOptions}
            combineFilterData={combineData}
            questionData={questionData}
            errorMessage={sentQuestions}
            option='createQuestion'
            token={token}
            type='question'
            emptyField={hasEmptyField}
          />
        </div>
        <div className="card filter-card col-md-6">
          <div className="card-header d-flex align-items-center">
            <Avatar value={questionData.length > 1 ? "5" : "4"} class="avatar-sm" color="dark" />
            <h2 className="m-0 h5">{editMode ? 'Edit question' : 'Create new Question'}</h2>
          </div>
          <div className="card-body d-flex flex-column">
            <span>Question ID</span>
            <Input
              onChange={handleChange}
              value={areMultipleQuestions ? selectedQuestion.id : questionData.id}
              name="id"
              autoComplete='off'
              placeholder="Enter question id"
              className="w-100 mb-3"
              disabled={!!editMode}
            />
            {idError.show ?
              <div className="alert alert-danger mt-3" role="alert">
                {idError.message}
              </div> : null
            }
            <span>Question text</span>
            <Input
              onChange={handleChange}
              value={areMultipleQuestions ? selectedQuestion.text : questionData.text}
              placeholder="Enter question text"
              autoComplete='off'
              className="w-100 mb-3"
              name="text"
            />
            <Grid
              data={tableData}
              className="questions-grid"
              editField="inEdit"
              onItemChange={onItemChange}>
              <GridColumn
                title=""
                cell={item => optionsCellRenderer(item, "delete")} />
              <GridColumn
                className={`${(Array.isArray(questionData) && selectedQuestion.type === "f") || questionData.type === "f" ? "col-md-4" : "col-md-5"}`}
                headerClassName={`${(Array.isArray(questionData) && selectedQuestion.type === "f") || questionData.type === "f" ? "col-md-4" : "col-md-5"}`}
                title="Text"
                field="text" />
              <GridColumn
                className={`${(Array.isArray(questionData) && selectedQuestion.type === "f") || questionData.type === "f" ? "col-md-4" : "col-md-5"}`}
                headerClassName={`${(Array.isArray(questionData) && selectedQuestion.type === "f") || questionData.type === "f" ? "col-md-4" : "col-md-5"}`}
                title="Filter"
                field="if" />
              {(Array.isArray(questionData) && selectedQuestion.type === "f") || questionData.type === "f" ?
                <GridColumn
                  className="col-md-2"
                  headerClassName="col-md-2"
                  title="Calculation"
                  field="calc" />
                : null}
              <GridColumn
                title=""
                cell={item => optionsCellRenderer(item, "options")} />
            </Grid>
            <div className="d-flex border-top px-3 py-2"
              onClick={(e) => rowOptionsClickHandler(e, { text: "", if: "", id: uuid(), calc: "", inEdit: true, }, "addEmpty")}
              style={{ cursor: "pointer" }}
            >
              <i className="fal fa-plus d-flex align-items-center text-primary mr-2" />
              <span className="text-dark flex-fill">Add empty row</span>
            </div>
            {alertInfoCalc ?
              <div className="alert alert-info m-0" role="alert">
                Please enter row calculation
              </div> : null
            }
            {calcError ?
              <div className="alert alert-danger m-0" role="alert">
                Please enter calculation
              </div> : null
            }
            {errorMessage ?
              <div className={`alert alert-${errorMessage === "Created" ? "success m-0" : "danger m-0"}`} role="alert">
                {errorMessage}
              </div> : null
            }
          </div>
        </div>
      </div>
      <DialogActionsBar>
        <button type='button' disabled={isLoading} className="k-button btn btn-secondary" id="close" onClick={handleClose}>Cancel</button>
        <button type='button' disabled={isLoading} className="k-button btn btn-primary" id="add" onClick={editMode ? onUpdateQuestion : onAddQuestion}>
          {isLoading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" />}
          <span>{editMode ? 'Update question' : 'Add question'}</span>
        </button>
      </DialogActionsBar>
    </Dialog >
  );
};
