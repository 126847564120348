export default (e) => {
  const values = e.target.value;
  const lastItem = values[values.length - 1];
  if (lastItem) {
    values.pop();
    const sameItem = values.find(value => value.name === lastItem.name);
    if (sameItem === undefined) {
      if (lastItem.id) {
        values.push(lastItem);
      } else {
        values.push({ ...lastItem, id: null })
      }
    }
  }
  return values
}