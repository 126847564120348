import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

type Report<T = TODO> = {
  [key: string]: T;
};


type InitialState = {
  report: Report;
  tableData: string;
  isLoadingData: boolean;
  isEditReport: boolean;
  selectedReportFolder?: string;
}

const initialState: InitialState = {
  report: {},
  tableData: "",
  isLoadingData: false,
  isEditReport: false,
  selectedReportFolder: undefined
}

const AnalysisReportsDataSlice = createSlice({
  name: 'analysisReports',
  initialState,
  reducers: {
    setReport(_state, action: PayloadAction<Report>) {
      return { ...initialState, report: action.payload }
    },
    updateReport(state, action: PayloadAction<Report>) {
      return { ...state, report: action.payload }
    },
    updateTableData(state, action: PayloadAction<string>) {
      return { ...state, tableData: action.payload }
    },
    updateLoading(state, action: PayloadAction<boolean>) {
      return { ...state, isLoadingData: action.payload }
    },
    updateEditReport(state, action: PayloadAction<boolean>) {
      return { ...state, isEditReport: action.payload }
    },
    updateCurrentReportFolder(state, action: PayloadAction<string>) {
      return { ...state, selectedReportFolder: action.payload }
    }
  }
})

export const {
  setReport,
  updateReport,
  updateTableData,
  updateLoading,
  updateEditReport,
  updateCurrentReportFolder,
} = AnalysisReportsDataSlice.actions
export default AnalysisReportsDataSlice.reducer