import { dashboardCountCards } from "../../../components/Dashboard/DashboardCountData/DashboardCountData"

const initialState = {
  recentDocuments: [],
  recentProjects: [],
  dashboardCountData: dashboardCountCards.map(cardDataType => { return { type: cardDataType } }),
  dashboardCountDataLoaded: false,
  documentsLoaded: false,
  projectsLoaded: false,
}

// The triggers (documentsLoaded, projectsLoaded) are needed to check if we've called backend for the data
// because backend returns an empty array if there's no data, and if we check (recentDocuments.lenght < 1) we will be stuck in infinite loop

const recentDataReducers = (state = initialState, action) => {
  switch (action.type) {
    case "SET_RECENT_DOCUMENTS_DATA":
      return {
        ...state,
        recentDocuments: action.payload,
        documentsLoaded: true
      }
    case "SET_RECENT_PROJECTS_DATA":
      return {
        ...state,
        recentProjects: action.payload,
        projectsLoaded: true
      }
    case "SET_DASHBOARD_COUNT_DATA":
      return {
        ...state,
        dashboardCountData: action.payload,
        dashboardCountDataLoaded: true
      }
    default:
      return state;
  }
}

export default recentDataReducers;