import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { BaseMultiSelect } from '../../../../../shared/Inputs';
import { ErrorMessage } from '../../../../../shared/ErrorMessage/ErrorMessage';
import { useFetchTags } from '../../../../../shared/customHooks/useFetchTags';
import type { RootState } from '../../../../../../store/reducers/rootReducer';
import { fetchPostJson as createCampaign, fetchPostJson as createRecipientList, fetchPutJson as updateCampaign } from '../../../../../../services/services';

interface Props {
  editType?: string
  editFormData?: TODO
  disableCampaign?: boolean
  updateData: () => void
  handleClose: () => void
  updateCampaignList: (res: TODO) => void
  updateRecipientList: (res: TODO) => void
}

interface AudienceType {
  id: string
  text: string
}

const audienceTypes: AudienceType[] = [
  { text: 'Link invite', id: 'link-campaign' },
  { text: 'Email invite', id: 'email-campaign' },
  { text: 'SMS invite', id: 'sms-campaign' },
  { text: 'Respondent list', id: 'recipient-list' }
]

export const CreateAudienceModal = ({ editType, editFormData, disableCampaign, handleClose, updateData, updateCampaignList, updateRecipientList }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const params: { name: string, survey: string } = useParams()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [selectedType, setSelectedType] = useState<AudienceType>(audienceTypes[0])
  const [formData, setFormData] = useState(editFormData ? editFormData : { name: '', description: '', tags: [] })

  const projectId = params.name
  const surveyId = params.survey
  const tags = useFetchTags(token)

  useEffect(() => {
    if (editType) {
      const newType: TODO = audienceTypes.find(el => el.id === editType)
      if (newType) {
        setSelectedType(newType)
      }
    } else if (disableCampaign) {
      const newType: TODO = audienceTypes.find(el => el.id === 'recipient-list')
      setSelectedType(newType)
    }
  }, [editType, disableCampaign])

  const onChangeHandler = (e: TODO) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const onChangeSelectedType = (type: AudienceType) => {
    if (disableCampaign && type.id === 'recipient-list') {
      setSelectedType(type)
    } else if (!disableCampaign) {
      setSelectedType(type)
    }
  }

  const onCreateHandler = (e: TODO) => {
    e.preventDefault()
    const body: TODO = {
      name: formData.name,
      description: formData.description,
      tags: formData.tags,
      type: selectedType.id === 'email-campaign' ? 'email' : null
    }
    if (selectedType.id === 'recipient-list') {
      createRecipientList(`projects/${projectId}/surveys/${surveyId}/recipients`, token, body)
        .then((res: TODO) => {
          if (res && (res.message || res.error)) {
            setErrorMessage(res.message ? res.message : res.error)
          } else if (Object.entries(res).length !== 0) {
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The respondent list has been created successfully' } })
            handleClose()
            updateRecipientList(res)
          }
        })
    } else {
      createCampaign(`projects/${projectId}/surveys/${surveyId}/campaigns/${selectedType.id === "link-campaign" ? "link" : selectedType.id === "email-campaign" ? "email" : "sms"}`, token, body)
        .then((res: TODO) => {
          if (res && (res.message || res.error)) {
            setErrorMessage(res.message ? res.message : res.error)
          } else if (Object.entries(res).length !== 0) {
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'An audience has been created successfully' } })
            handleClose()
            updateCampaignList(res)
          }
        })
    }
  }

  const onEditHandler = (e: TODO) => {
    e.preventDefault()
    if (editType !== 'recipient-list') {
      const body = {
        name: formData.name,
        description: formData.description,
        tags: formData.tags
      }
      updateCampaign(`projects/${projectId}/surveys/${surveyId}/campaigns/${formData.id}`, token, body)
        .then((res: TODO) => {
          if (res && (res.message || res.error)) {
            setErrorMessage(res.message ? res.message : res.error)
          } else if (Object.entries(res).length !== 0) {
            updateData()
            handleClose()
          }
        })
    }
  }

  const listNoDataRender = () => {
    return (
      <h5 className="text-muted text-center">
        <span className="k-icon k-i-warning my-3" style={{ fontSize: '2.5em' }} />
        <br />
        <span>No tags created yet</span>
      </h5>
    );
  }

  return (
    <Dialog width="40rem" title={`${editType && editFormData ? 'Edit' : 'Create'} audience`} onClose={handleClose}>
      <form onSubmit={editType && editFormData ? onEditHandler : onCreateHandler}>
        <div className="d-flex flex-column p-4">
          <div className="d-flex justify-content-between flex-wrap">
            {
              !editType && !editFormData && audienceTypes.map((type, key) => (
                <div
                  key={key}
                  onClick={() => onChangeSelectedType(type)}
                  className={`card d-flex flex-column align-items-center justify-content-center flex-grow-1 text-center p-2 mx-1 mb-3 ${disableCampaign && type.id !== 'recipient-list' ? 'text-disabled' : 'cursor-pointer'} ${selectedType.id === type.id ? 'bg-primary text-white' : ''}`}>
                  <span>{type.text}</span>
                </div>
              ))
            }
            {
              disableCampaign &&
              <span className='alert alert-warning w-100'>Invites are disabled until the survey is published.</span>
            }
          </div>
          <div className='px-1'>
            <label>Name</label>
            <input
              type="text"
              name="name"
              className='form-control mb-3'
              value={formData.name}
              onChange={onChangeHandler}
            />
            <label>Description</label>
            <input
              type="text"
              onChange={onChangeHandler}
              value={formData.description}
              name="description"
              className='form-control mb-3'
            />
            <label>Tags</label>
            <BaseMultiSelect
              data={tags ? tags : []}
              value={formData.tags}
              textField="name"
              dataItemKey="id"
              name="tags"
              allowCustom={true}
              listNoDataRender={listNoDataRender}
              onChange={onChangeHandler} />
          </div>
        </div>
        {
          errorMessage &&
          <ErrorMessage
            type="alert"
            errorMessage={errorMessage} />
        }
        <DialogActionsBar>
          <button type="button" className="k-button btn btn-secondary" onClick={handleClose}>Cancel</button>
          <button type="submit" className="k-button btn btn-primary">{editType && editFormData ? 'Update' : 'Create'}</button>
        </DialogActionsBar>
      </form>
    </Dialog>
  )
}