import { PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  theState: {
    errorMessage: null,
    errorType: null
  }
};

function errorMessageStateReducer(state = initialState.theState, action: PayloadAction<{ msg: string, type: string }>) {
  switch (action.type) {
    case 'UPDATE_ERROR_MESSAGE':
      return ({
        ...state,
        errorMessage: action.payload.msg,
        errorType: action.payload.type
      })
    default:
      return { ...state };
  }
}

export default errorMessageStateReducer;