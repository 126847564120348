import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers/rootReducer';
import { FullHeightSpinner } from '../../../shared/FullHeightSpinner/FullHeightSpinner';
import { Header } from './Header/Header';

export const PanelOverviewTabContent: React.FC = () => {
  const { panelData } = useSelector((theState: RootState) => theState.panelStateReducer);

  return (
    <div className="container-fluid">
      {panelData.sampleData !== null ?
        <div className="row">
          <div className="d-flex flex-column col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 ">
            <Header sampleData={panelData.sampleData} />
          </div>
        </div>
        :
        <FullHeightSpinner />}
    </div>
  )
}

