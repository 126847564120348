import { returnMultigridVariablePropertyName } from "../returnMultigridVariablePropertyName/returnMultigridVariablePropertyName";

const areAllPropertiesEqual = (arrayOfObjects, propertyName) => {
  if (Array.isArray(arrayOfObjects) && arrayOfObjects.length > 1) {
    return arrayOfObjects.every(obj => obj[propertyName] === arrayOfObjects[0][propertyName]);
  }
}

export default (originalFormatData, data) => {
  const updatedData = data.filter(item => !item.multigrid && !item.multilist)
  const dataToBeReturned = JSON.parse(JSON.stringify(originalFormatData))
  dataToBeReturned.sss.survey.record.variable = []
  dataToBeReturned.sss.survey.record["miextsss:grid"] = []
  // biome-ignore lint/complexity/noForEach: <explanation>
  data.filter(item => item.multigrid).forEach((el) => {
    const variablePropertyName = returnMultigridVariablePropertyName(el)
    if (variablePropertyName) {
      dataToBeReturned.sss.survey.record["miextsss:grid"].push({
        "miextsss:label": { text: el.title },
        "@miextsss:qno": el.id,
        "@ident": "",
        "@miextsss:excluded": el.disabled ? "true" : "false",
        "@miextsss:docorder": el.order.toString(),
        [variablePropertyName]: el[variablePropertyName]
      })
    }
  })

  // Add automatically merged multilist questions back to the multilist array
  dataToBeReturned.sss.survey.record["miextsss:multilist"] = data.filter(item => item.multilist).map(item => {
    return {
      ...item,
      '@miextsss:docorder': item.order.toString(),
      '@miextsss:qno': item.id,
      '@miextsss:type': item.type,
      '@miextsss:excluded': item.disabled ? 'true' : 'false',
      'miextsss:label': { text: item.title }
    }
  })

  // Add new manually merged multilist questions to the multilist array
  // biome-ignore lint/complexity/noForEach: <explanation>
  data.filter(item => !item.multilist && (item.type === 'dichotomyb' || item.type === 'dichotomya')).forEach((item) => {
    const newMultilistElement = {
      "@ident": "",
      "@miextsss:excluded": item.disabled ? "true" : "false",
      "@miextsss:qno": item.id,
      "@miextsss:type": item.type,
      "miextsss:label": { text: item.title },
      "@miextsss:docorder": item.order.toString(),
      variable: item.mergedQuestions.map((ques) => {
        return {
          name: ques.id,
          "@type": ques.type,
          "@miextsss:qno": ques.id,
          "@ident": ques['@ident'],
          "@miextsss:excluded": "false",
          position: { "@start": ques.position["@start"] },
          label: { text: ques.title },
          values: ques.values
        }
      })
    }
    if (item['@miextsss:isweight'] && item['@miextsss:weighttype']) {
      newMultilistElement['@miextsss:isweight'] = item['@miextsss:isweight']
      newMultilistElement['@miextsss:weighttype'] = item['@miextsss:weighttype']
    }
    if (item['@miextsss:isrspid']) {
      newMultilistElement['@miextsss:isrspid'] = item['@miextsss:isrspid']
    }
    dataToBeReturned.sss.survey.record["miextsss:multilist"].push(newMultilistElement)
  })

  const matchedIDs = []
  updatedData.forEach((el, id) => {
    if (Array.isArray(originalFormatData.sss.survey.record.variable)) {
      // biome-ignore lint/complexity/noForEach: <explanation>
      originalFormatData.sss.survey.record.variable.forEach(val => {
        if (el['@miextsss:qno'] === val.name) {
          val.label.text = el.title
          val['@miextsss:qno'] = el.id
          if (el['@miextsss:isweight'] && el['@miextsss:weighttype'] && val) {
            val['@miextsss:isweight'] = el['@miextsss:isweight']
            val['@miextsss:weighttype'] = el['@miextsss:weighttype']
          }
          if (el['@miextsss:isrspid'] && val) {
            val['@miextsss:isrspid'] = el['@miextsss:isrspid']
          }
          if (el.disabled) {
            val['@miextsss:excluded'] = 'true';
          }
          dataToBeReturned.sss.survey.record.variable.push(val)
          matchedIDs.push(id)
        }
      })
    } else {
      if (el['@miextsss:qno'] === originalFormatData.sss.survey.record.variable.name) {
        originalFormatData.sss.survey.record.variable.label.text = el.title
        originalFormatData.sss.survey.record.variable['@miextsss:qno'] = el.id
        if (el['@miextsss:isweight'] && el['@miextsss:weighttype'] && originalFormatData.sss.survey.record.variable) {
          originalFormatData.sss.survey.record.variable['@miextsss:isweight'] = el['@miextsss:isweight']
          originalFormatData.sss.survey.record.variable['@miextsss:weighttype'] = el['@miextsss:weighttype']
        }
        if (el['@miextsss:isrspid'] && originalFormatData.sss.survey.record.variable) {
          originalFormatData.sss.survey.record.variable['@miextsss:isrspid'] = el['@miextsss:isrspid']
        }
        if (el.disabled) {
          originalFormatData.sss.survey.record.variable['@miextsss:excluded'] = 'true';
        }
        dataToBeReturned.sss.survey.record.variable.push(originalFormatData.sss.survey.record.variable)
        matchedIDs.push(id)
      }
    }
  })

  updatedData.forEach((el, id) => {
    if (!matchedIDs.includes(id)) {
      if (el.type === 'numericList') {
        const updatedQuestion = {
          '@ident': '',
          '@miextsss:qno': el.id,
          '@miextsss:excluded': el.disabled ? 'true' : 'false',
          '@miextsss:docorder': el.order.toString(),
          'miextsss:label': { text: el.title },
          variable: el.mergedQuestions.map((item, index) => {
            return {
              '@ident': item['@ident'],
              '@type': item.type,
              '@miextsss:type': item['@miextsss:type'],
              '@miextsss:qno': item.id,
              '@miextsss:excluded': 'false',
              name: item.id,
              position: item.position,
              label: { text: item.title },
              'miextsss:rowtext': { text: el.values.value[index].text },
              values: item.values
            }
          })
        }
        if (dataToBeReturned.sss.survey.record["miextsss:numericlist"]) {
          if (Array.isArray(dataToBeReturned.sss.survey.record["miextsss:numericlist"])) {
            dataToBeReturned.sss.survey.record["miextsss:numericlist"].push(updatedQuestion)
          } else {
            const numericListArray = [dataToBeReturned.sss.survey.record["miextsss:numericlist"]]
            numericListArray.push(updatedQuestion)
            dataToBeReturned.sss.survey.record["miextsss:numericlist"] = numericListArray
          }
        } else {
          dataToBeReturned.sss.survey.record["miextsss:numericlist"] = [updatedQuestion]
        }
      } else if (el.type === 'multichoiceNmopen') {
        const merged = el.mergedQuestions.flatMap(el => {
          return el.values.value
        })

        const updatedQuestion = {
          '@ident': '',
          '@miextsss:qno': el.id,
          '@miextsss:excluded': el.disabled ? 'true' : 'false',
          '@miextsss:docorder': el.order.toString(),
          'miextsss:label': { text: el.title },
          variable: el.mergedQuestions.map((item, index) => {
            return {
              '@ident': item['@ident'],
              '@type': item.type,
              '@miextsss:code': areAllPropertiesEqual(el.mergedQuestions, 'code') && index > 0 ? (Number(item.code) + index).toString() : Number(item.code).toString(),
              '@miextsss:type': item['@miextsss:type'],
              '@miextsss:qno': item.id,
              '@miextsss:excluded': 'false',
              name: item.id,
              position: item.position,
              label: { text: el.values.value[index].text },
              'miextsss:rowtext': { text: el.values.value[index].text },
              values: item.values?.value ? {
                value: item.values.value.map(e => {
                  return {
                    ...e,
                    "@miextsss:code": merged.findIndex(obj => {
                      return obj.text === e.text
                    }).toString(),
                  }
                })
              } : null
            }
          })
        }

        if (el['@miextsss:isweight'] && el['@miextsss:weighttype']) {
          updatedQuestion['@miextsss:isweight'] = el['@miextsss:isweight']
          updatedQuestion['@miextsss:weighttype'] = el['@miextsss:weighttype']
        }
        if (el['@miextsss:isrspid']) {
          updatedQuestion['@miextsss:isrspid'] = el['@miextsss:isrspid']
        }
        if (dataToBeReturned.sss.survey.record['miextsss:nmopen']) {
          if (Array.isArray(dataToBeReturned.sss.survey.record["miextsss:nmopen"])) {
            dataToBeReturned.sss.survey.record["miextsss:nmopen"].push(updatedQuestion)
          } else {
            const nmOpenArray = [dataToBeReturned.sss.survey.record["miextsss:nmopen"]]
            nmOpenArray.push(updatedQuestion)
            dataToBeReturned.sss.survey.record["miextsss:nmopen"] = nmOpenArray
          }
        } else {
          dataToBeReturned.sss.survey.record["miextsss:nmopen"] = [updatedQuestion]
        }
      }
    }
  })

  // Add auto-merged items that have been undone back to the variable array
  // biome-ignore lint/complexity/noForEach: <explanation>
  data.forEach((el) => {
    if (el.undoAutoMerged) {
      const updatedElement = {
        name: el.id,
        "@type": el.type,
        "@ident": el["@ident"],
        "@miextsss:qno": el.id,
        position: el.position,
        "@miextsss:excluded": el.disabled ? "true" : "false",
        "@miextsss:docorder": typeof el.oldParentOrder === 'number' ? (el.oldParentOrder + 1).toString() : el.order.toString(),
        label: { text: el.title },
        values: {
          value: el.values.value.map(value => {
            return {
              ...value,
              "@miextsss:excluded": value["@miextsss:excluded"].toString()
            }
          })
        }
      }
      dataToBeReturned.sss.survey.record.variable.push(updatedElement)
    }
  })

  return dataToBeReturned
}