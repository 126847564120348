import React, { useState, useEffect } from 'react';
import { DropDownButton } from '@progress/kendo-react-buttons';

export default (props) => {
  const [check, setCheck] = useState('none')

  useEffect(() => {
    const checkedQuestion = props.checkedQuestions[0]
    if (props.checkedQuestions.length === 1) {
      if (checkedQuestion['@miextsss:weighttype']) {
        setCheck(checkedQuestion['@miextsss:weighttype'])
      } else {
        if (checkedQuestion['@miextsss:isrspid']) {
          setCheck('respondentId')
        } else {
          setCheck('none')
        }
      }
    } else {
      setCheck('none')
    }
  }, [props.data, check, props.checkedQuestions])

  const action = props.action

  const itemRender = (props) => {
    return (
      <div onClick={() => action(props.item.action)}>
        <i className={`fas fa-xs pr-3  ${check === props.item.action ? 'fa-check' : null}`}></i>
        <span>{props.item.title}</span>
      </div>
    )
  }

  return (
    <DropDownButton
      disabled={props.disabled}
      buttonClass={`btn d-flex ${props.bgColor}`}
      id="dropdownMenuLink"
      data-toggle="dropdown"
      text={props.title}
      icon={props.icon}
      items={props.dropdownItems}
      itemRender={itemRender}
    />
  )
}