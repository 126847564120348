import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import React from 'react'
import { useDispatch } from 'react-redux';
import { fetchDelete, fetchGetJson } from '../../../../../../../services/services';

function DeleteElementModal({ setDeleteElementModal, library, token, libraryScope, deleteElementModal, setSelectedElementType }) {

    const dispatch = useDispatch();

    const closeDialog = () => {
        setDeleteElementModal({
            show: false,
            id: "",
            name: "",
            description: "",
            folderId: null,
            type: ""
        })
    };

    const save = () => {
        if (library === 'personal') {
            fetchDelete(`/libraries/${libraryScope}/user/${deleteElementModal.folderId}/items/${deleteElementModal.id}`, token)
                .then(res => {
                    if (res.error || res.message) {
                        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
                    }
                })
                .then(() => {
                    fetchGetJson(`/libraries/${libraryScope}/user/${deleteElementModal.folderId}/items`, token)
                        .then(res => {
                            if (res.length > 0) {
                                setSelectedElementType({ questionItems: res })
                            } else {
                                setSelectedElementType()
                            }
                        })
                })
            setDeleteElementModal({
                show: false,
                id: "",
                name: "",
                description: "",
                folderId: null,
                type: ""
            })
        } else if (library === 'global') {
            fetchDelete(`/libraries/${libraryScope}/user/${deleteElementModal.folderId}/items/${deleteElementModal.id}`, token)
                .then(res => {
                    if (res.error || res.message) {
                        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
                    }
                })
                .then(() => {
                    fetchGetJson(`/libraries/${libraryScope}/customer/${deleteElementModal.folderId}/items`, token)
                        .then(res => {
                            if (res.length > 0) {
                                setSelectedElementType({ questionItems: res })
                            } else {
                                setSelectedElementType()
                            }
                        })

                })
            setDeleteElementModal({
                show: false,
                id: "",
                name: "",
                description: "",
                folderId: null,
                type: ""
            })
        }
    };

    return (
        <Dialog width="400px" height="auto" className="import-dialog library-sub-dialog no-wrap" title={`Delete element`} onClose={closeDialog}>
            <div className="d-flex flex-row justify-content-center align-items-center p-4">
                Are you sure you want to permanently delete this element?
            </div>
            <DialogActionsBar>
                <button className="k-button btn btn-secondary mr-2" onClick={closeDialog}>
                    Cancel
                </button>
                <button onClick={save} className="k-button k-button-solid-primary text-white">
                    Delete
                </button>
            </DialogActionsBar>
        </Dialog>
    )
}

export default DeleteElementModal