import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { useFilter } from "../../customHooks/useFilter";

export const BaseMultiSelect = ({ filterable = true, ...props }) => {
  const { data, filterChange } = useFilter(props.data);

  return <MultiSelect
    {...props}
    filterable={filterable}
    onFilterChange={filterable ? filterChange : undefined}
    data={data}
  />;
};
