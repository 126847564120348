import JsxParser from 'react-jsx-parser';
import { Dialog } from '@progress/kendo-react-dialogs';
import ChartTypeContent from '../TabContent/ChartTabContent/ChartTypeContent/ChartTypeContent';

interface Props {
  result: any
  chartZoom?: number
  onHide: () => void
}

export const ExpandResultModal = ({ result, chartZoom, onHide }: Props) => {
  return (
    <Dialog height="95%" width={"95%"} title="Analysis result" className='import-dialog' onClose={onHide}>
      <div className="d-flex flex-column overflow-hidden flex-fill h-100"> {/* This container is needed for the chart to expand to full dialog height */}
        {
          Number(chartZoom) ?
            <div className='w-100 d-flex flex-column overflow-auto p-4'>
              <ChartTypeContent
                data={result}
                currentZoom={chartZoom}
              />
            </div>
            :
            <JsxParser jsx={result} />
        }
      </div>
    </Dialog>
  )
}