import type { User } from "../../../../interfaces/userInterface/userInterface"

export const returnDocumentsByAccess = (documents: { type: string }[], user?: User) => {
  let updatedDocuments: TODO[] = []
  if (documents) {
    updatedDocuments = documents.filter(el => el.type === 'datasetsv2' || el.type === 'datasets' || el.type === 'files')
  }
  if (user?.enterpriseSettings) {
    if (user.enterpriseSettings.hasSurveyAccess) {
      const surveyDocuments = documents?.filter(el => el.type === 'surveys')
      if (surveyDocuments) {
        updatedDocuments = updatedDocuments.concat(surveyDocuments)
      }
    }
    if (user.enterpriseSettings.hasSurveyAccess) {
      const pinboardDocuments = documents?.filter(el => el.type === 'pinboards')
      if (pinboardDocuments) {
        updatedDocuments = updatedDocuments.concat(pinboardDocuments)
      }
    }
    if (user.enterpriseSettings.hasPanelAccess) {
      const panelDocuments = documents?.filter(el => el.type === 'samples')
      if (panelDocuments) {
        updatedDocuments = updatedDocuments.concat(panelDocuments)
      }
    }
    if (user.enterpriseSettings.hasToolsAccess) {
      const toolsDocuments = documents?.filter(el => el.type === 'workflows')
      if (toolsDocuments) {
        updatedDocuments = updatedDocuments.concat(toolsDocuments)
      }
    }
    if (user.enterpriseSettings.hasAudienceAccess) {
      const audienceDocuments = documents?.filter(el => el.type === 'audiences')
      if (audienceDocuments) {
        updatedDocuments = updatedDocuments.concat(audienceDocuments)
      }
    }
  }
  return updatedDocuments
}