import React from 'react'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'

interface Props {
  onHide: Function,
  handlePreview: Function,
  modalData: ModalData,
  setModalData: Function,
  error: any,
}
interface ModalData {
  respondentIds: string[],
  name?: string | undefined
}
export const GKNewReconciliationModal = ({ onHide, handlePreview, modalData, setModalData, error }: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const arr: any = []
    arr.push(e.target.value.split('\n'))
    setModalData({ ...modalData, [e.target.name]: e.target.name === "respondentIds" ? e.target.value.split(/\n/) : e.target.value })
  }

  return (
    <Dialog width={"35%"} className='reconcile-modal' contentStyle={{ padding: 0, fontSize: 14 }} title={"New reconciliation"} onClose={() => onHide("Reconciliation")}>
      <form onSubmit={(e) => handlePreview(e)} >
        <div className='pt-3 px-4' >
          <p className='p-0 m-0 text-start' ><label className='p-0 m-0 medium strong' htmlFor="reconcileModal">Walr IDs</label></p>
          <p className='p-0 m-0 text-start text-muted'><label className='medium m-0' htmlFor="reconcileModal">Upload all the accept Walr IDs, any IDs not included will be removed.</label></p>
          <div className="d-flex flex-column justify-content-center w-100 py-3">
            <div className="details-input d-flex p-0 flex-column w-100 pb-2 ">
              <label className='p-0 m-0 strong text-primary'>Reconciliation name</label>
              <input className='form-control medium' type="text" name='name' placeholder='Enter name' value={modalData.name} onChange={(e) => handleChange(e)} />
              {error?.name &&
                <div className="text-danger">
                  <small>This field is required.</small>
                </div>}
            </div>
            <textarea
              className='w-100 form-control'
              id="reconcileModal"
              name="respondentIds"
              placeholder='Add IDs one per line'
              rows={5}
              cols={50}
              onChange={(e) => handleChange(e)}>
            </textarea>
            {error?.respondentIds &&
              <div className="text-danger">
                <small>This field is required.</small>
              </div>}
          </div>
        </div>
        <DialogActionsBar>
          <div className='d-flex'>
            <button className="btn btn-secondary mr-2" onClick={() => onHide("Reconciliation")}>Cancel</button>
            <button
              type='submit' className="btn btn-primary">Preview</button>
          </div>
        </DialogActionsBar>
      </form>
    </Dialog >
  )
}