import { v4 as uuid } from 'uuid';
import type { Dispatch } from 'redux';

type State = {
  checkedNum: {
    id: string
    source: 'firstColumn' | 'scndColumn'
  }[]
  firstColumn: { id: string }[]
  scndColumn: { id: string }[]
}

export const copyAnalysisQuestions = (state: State, dispatch: Dispatch, source: string) => {
  const selectedElements: TODO[] = [];
  // biome-ignore lint/complexity/noForEach: <explanation>
  state.checkedNum.forEach((checkedEl) => {
    const selectedElement = state[checkedEl.source].find(el => el.id === checkedEl.id)
    if (selectedElement) {
      selectedElements.push({ ...selectedElement, id: uuid(), selected: false, active: false, source: checkedEl.source })
    }
  });
  if (selectedElements.length > 0) {
    dispatch({ type: 'SET_COPIED_ELEMENTS', payload: { source: source, elements: selectedElements } })
    dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The selected rows/columns have been copied' } })
  } else {
    dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'Selected row/column not found' } })
  }
}