import { useState } from 'react'
import { useSelector } from 'react-redux';
import Editor from '@monaco-editor/react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { Icon } from '../../../../../../shared/Icon/Icon';
import { fetchPutResOrJson } from '../../../../../../../services/services';
import type { RootState } from '../../../../../../../store/reducers/rootReducer';
import FileManager from '../../../../../../shared/FileManager/FileManager';

interface IProps {
  onClose: () => void
  updateElements: () => void
}

interface showFileManager {
  show: boolean
  setting: null | string
}

export const SurveyDesignSectionCSS = ({ onClose, updateElements }: IProps) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { data } = useSelector((theState: RootState) => theState.breadcrumbStateReducer);
  const { theData } = useSelector((theState: RootState) => theState.surveyInitialDataReducer)
  const [selectedItem, setSelectedItem] = useState(JSON.parse(JSON.stringify(theData.originalData)));
  const [showFileManager, setShowFileManager] = useState<showFileManager>({ show: false, setting: null });

  const removeCSSReference = () => {
    const updatedElement = JSON.parse(JSON.stringify(selectedItem))
    updatedElement.elements[theData.displaySectionCSS.sectionID].attributes.css = ""
    setSelectedItem(updatedElement)
  };

  const onSelectFromFileManager = (value: string) => {
    const updatedElement = JSON.parse(JSON.stringify(selectedItem))
    updatedElement.elements[theData.displaySectionCSS.sectionID].attributes.css = value
    setSelectedItem(updatedElement)
  };

  const handleChange = (value: string) => {
    const updatedElement = JSON.parse(JSON.stringify(selectedItem))
    updatedElement.elements[theData.displaySectionCSS.sectionID].attributes.css = value
    setSelectedItem(updatedElement)
  };

  const handleStyleChange = (value: string | undefined) => {
    const updatedElement = JSON.parse(JSON.stringify(selectedItem))
    updatedElement.elements[theData.displaySectionCSS.sectionID].style = value
    setSelectedItem(updatedElement)
  };

  const saveCSS = () => {
    fetchPutResOrJson(`su/projects/${data.projectId}/surveys/${data.documentId}/index`, token, selectedItem).then((res: TODO) => {
      if (res.status === 200) {
        updateElements()
        onClose()
      }
    });
  };

  return (
    <Dialog title="Section CSS" width="95%" height="95%" onClose={onClose}>
      {
        showFileManager.show &&
        <FileManager
          displayType="modal"
          type={'public'}
          onSelect={(value) => onSelectFromFileManager(value)}
          onHide={() => setShowFileManager({ show: false, setting: null })}
          token={token}
        />
      }
      <div className="d-flex flex-column h-100">
        <div className="form-group p-3 m-0" >
          <label>Extra CSS reference</label>
          <div className="input-group">
            <input
              type="text"
              className="form-control h-32"
              placeholder="Select a file"
              name='css'
              style={{ maxHeight: 'unset' }}
              value={selectedItem.elements[theData.displaySectionCSS.sectionID].attributes.css ? selectedItem.elements[theData.displaySectionCSS.sectionID].attributes.css : ""}
              onChange={(e) => handleChange(e.target.value)}
            />
            <div className="input-group-append">
              <button className="btn btn-outline-secondary p-1" type="button" onClick={() => setShowFileManager({ show: true, setting: 'css' })}>
                <Icon type="add" />
              </button>
              <button disabled={selectedItem.elements[theData.displaySectionCSS.sectionID].attributes.css ? false : true} className="btn btn-outline-secondary p-1" type="button" onClick={removeCSSReference}>
                <Icon type="delete-alt" />
              </button>
            </div>
          </div>
        </div>
        <Editor
          defaultLanguage='css'
          theme='vs-dark'
          options={{
            minimap: { enabled: false, },
            lineNumbers: 'off',
            glyphMargin: false,
            folding: false,
            overviewRulerLanes: 0,
            hideCursorInOverviewRuler: true,
            overviewRulerBorder: false,
          }}
          value={selectedItem.elements[theData.displaySectionCSS.sectionID].style}
          onChange={(value) => handleStyleChange(value)}
        />
      </div>
      <DialogActionsBar>
        <button type='button' className="k-button btn btn-secondary" onClick={onClose}>Cancel</button>
        <button type='button' className="k-button btn btn-primary" onClick={saveCSS}>Save</button>
      </DialogActionsBar>
    </Dialog>
  )
}