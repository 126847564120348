
const initialState = {
  workflowData: {
    workflowData: {
      data: null,
      loading: true,
      selectedTool: null,
      loadingTool: false,
      toolHasChanges: false,
      loadingToolType: null,
      appendMatchMergeError: false,
      appendMatchByError: false
    }
  }
};

function workflowStateReducer(state = initialState.workflowData, action) {
  switch (action.type) {
    case 'SET_WORKFLOW_INITIAL_DATA':
      return {
        ...state,
        workflowData: {
          data: action.payload,
          loading: false,
          selectedTool: null,
          loadingTool: false,
          toolHasChanges: false,
          loadingToolType: null,
          appendMatchMergeError: false,
          appendMatchByError: false
        }
      }
    case 'UPDATE_WORKFLOW_DATA':
      const stateCopy = { ...state.workflowData }
      return {
        ...state,
        workflowData: {
          ...stateCopy,
          data: action.payload,
          loading: false,
        }
      }
    case 'APPEND_MATCH_MEGE_ERROR':
      return {
        ...state,
        workflowData: {
          ...state.workflowData,
          appendMatchMergeError: action.payload
        }
      }
    case 'APPEND_MATCH_BY_ERROR':
      return {
        ...state,
        workflowData: {
          ...state.workflowData,
          appendMatchByError: action.payload
        }
      }
    case 'LOADING_WORKFLOW_DATA':
      const copyState = { ...state.workflowData }
      return {
        ...state,
        workflowData: {
          ...copyState,
          loading: true
        }
      }
    case 'SET_TOOL_SELECTED':
      const copy = { ...state.workflowData }
      return {
        ...state,
        workflowData: {
          ...copy,
          selectedTool: action.payload,
          loadingTool: false,
          loadingToolType: null,
          toolHasChanges: false
        }
      }
    case 'LOADING_TOOL':
      const copied = { ...state.workflowData }
      return {
        ...state,
        workflowData: {
          ...copied,
          loadingTool: true,
          loadingToolType: action.payload,
        }
      }
    case 'UPDATE_TOOL':
      const copiedToolState = { ...state.workflowData }
      return {
        ...state,
        workflowData: {
          ...copiedToolState,
          selectedTool: action.payload,
          toolHasChanges: true,
        }
      }
    case 'UPDATE_SELECTED_CODEMAP':
      const previousState = { ...state.workflowData }
      return {
        ...state,
        workflowData: {
          ...previousState,
          selectedTool: { ...previousState.selectedTool, selectedCodeMap: action.payload }
        }
      }
    case 'SET_CODING_LOADING_STATE':
      const earlierState = { ...state.workflowData }
      return {
        ...state,
        workflowData: {
          ...earlierState,
          selectedTool: { ...earlierState.selectedTool, loadingState: action.payload }
        }
      }
    case 'RESET_FILTERED_ANSWERS':
      const prevState = { ...state.workflowData }
      return {
        ...state,
        workflowData: {
          ...prevState,
          selectedTool: {
            ...prevState.selectedTool,
            selectedCodeMap: {
              ...prevState.selectedTool.selectedCodeMap,
              filteredAnswers: [...prevState.selectedTool.selectedCodeMap.rspAnswers]
            }
          }
        }
      }
    default:
      return state;
  }
}
export default workflowStateReducer;