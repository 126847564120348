import React, { useEffect, useState } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { Icon } from '../../../../../shared/Icon/Icon';
import FileManager from '../../../../../shared/FileManager/FileManager';

export const ImageUploadModal = ({ handleClose, onImageUpload, image, token, onImageEdit }) => {
  const [imageSettings, setImageSettings] = useState({ width: 0, height: 0, url: null })
  const [showFileManager, setShowFileManager] = useState(false)

  useEffect(() => {
    if (image) {
      setImageSettings({ width: image.width !== null ? Number.parseInt(image.width) : 0, height: image.height !== null ? Number.parseInt(image.height) : 0, url: image.src })
    }
  }, [image])

  return (
    <Dialog title={image ? "Edit image" : "Upload image"} width={500} onClose={handleClose}>
      {
        showFileManager &&
        <FileManager
          displayType="modal"
          type={'public'}
          onSelect={(value) => setImageSettings({ ...imageSettings, url: value })}
          onHide={() => setShowFileManager(false)}
          token={token}
        />
      }
      <div className="d-flex flex-column p-3 h-100">
        <div className="input-group themes-image mb-3">
          <div className="select" onClick={() => setShowFileManager(true)}>
            <input
              type="text"
              className="form-control w-100 k-textbox"
              placeholder={imageSettings.url ? imageSettings.url : 'Select an image'}
            />
          </div>
          <div className="input-group-append">
            <button className="btn btn-outline-survey p-1" onClick={() => setImageSettings({ ...imageSettings, url: null })}>
              <Icon type="delete-alt" />
            </button>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center pl-1 mb-3">
          <label className="m-0">Width</label>
          <div className="input-group w-auto">
            <input
              min="1"
              type="number"
              className="form-control"
              value={imageSettings.width}
              onChange={e => setImageSettings({ ...imageSettings, width: e.target.value })} />
            <div className="input-group-append">
              <button className="btn btn-white shadow-none text-muted" type="button">
                <span>px</span>
              </button>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center pl-1">
          <label className="m-0">Height</label>
          <div className="input-group w-auto">
            <input
              min="1"
              type="number"
              className="form-control"
              value={imageSettings.height}
              onChange={e => setImageSettings({ ...imageSettings, height: e.target.value })} />
            <div className="input-group-append">
              <button className="btn btn-white shadow-none text-muted" type="button">px</button>
            </div>
          </div>
        </div>

      </div>
      <DialogActionsBar>
        <button className="k-button btn btn-secondary" id="close" onClick={handleClose}>Cancel</button>
        {image ?
          <button className="k-button btn btn-primary text-white"
            disabled={imageSettings.url === null}
            onClick={() => onImageEdit(imageSettings)}>Update</button> :
          <button className="k-button btn btn-primary text-white"
            disabled={imageSettings.url === null}
            onClick={() => onImageUpload(imageSettings)}>Save</button>}
      </DialogActionsBar>
    </Dialog>
  )
}