import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import type { RootState } from '../../../store/reducers/rootReducer';
import { ActionNotification } from '../ActionNotification/ActionNotification';

export default function NotificationContainer() {
  const dispatch = useDispatch();
  const { errorMessage, errorType } = useSelector((state: RootState) => (state.errorMessageStateReducer));

  return (
    <Fragment>
      <ActionNotification />
      {
        errorMessage && errorType === 'modal' &&
        <ErrorMessage
          type="modal"
          errorMessage={errorMessage}
          onHide={() => dispatch({ type: 'UPDATE_ERROR_MESSAGE', payload: { msg: null, type: null } })} />
      }
    </Fragment>
  )
}