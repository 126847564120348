import { useState } from "react"
import { Tooltip } from "@progress/kendo-react-tooltip"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { Icon } from "../../../shared/Icon/Icon"
import { formatDate } from "../../../shared/DateUtils/DateUtils"
import { SortingArrow } from "./SortingArrow"
import { ProjectsTableSkeleton } from "./ProjectsTableSkeleton"
import type { ProjectDetailsObject } from "../../../../interfaces/projectDetailsInterfaces/projectDetailsInterfaces"
import { returnTableIcon } from "../../../shared/helpers/project/returnTableIcon/returnTableIcon"
import type { Action, ProjectsSortingType, SortingState } from "../ProjectsTypes/projectTypes"
import type { RootState } from "../../../../store/reducers/rootReducer"
import { Avatar } from "../../../shared/Avatar/Avatar"

type Props = {
  isLoading: boolean;
  searchParams: URLSearchParams;
  onSearchSubmit: ({ sort, sortOrder }: { sort: ProjectsSortingType, sortOrder: "desc" | "asc" | undefined }) => void;
  onProjectActionHandler: (type: Action, projectId: string, projectName: string) => void;
}

export default function ProjectsTable({ isLoading, searchParams, onSearchSubmit, onProjectActionHandler }: Props) {
  const { newProjects } = useSelector((state: RootState) => state.projectsReducer);
  const [sort, setSort] = useState<SortingState>({ dir: searchParams.get("sortOrder"), text: "", field: searchParams.get("sortBy") });
  const dispatch = useDispatch();

  const minDate = "0001-01-01T00:00:00";

  const onNavigateProject = () => {
    dispatch({
      type: "SHOULD_FETCH_PROJECTS",
      payload: false,
    });
  }

  const onSorting = (sortBy: ProjectsSortingType) => {
    const sortingOrderParam = searchParams.get("sortOrder");
    let sortingOrder: "desc" | "asc" | undefined = sortBy === "createdUtc" || sortBy === "lastModifiedUtc" ? "desc" : "asc";

    switch (sortBy) {
      case "createdByName":
      case "projectName":
        if (sortingOrderParam && sortingOrderParam === "asc") {
          sortingOrder = "desc"
          setSort({ dir: sortingOrder, field: sortBy });
        }

        if (sortingOrderParam && sortingOrderParam === "desc") {
          sortingOrder = undefined
          setSort({ dir: null, field: null });
        }
        break;

      case "createdUtc":
      case "lastModifiedUtc":
        if (sortingOrderParam && sortingOrderParam === "desc") {
          sortingOrder = "asc"
          setSort({ dir: sortingOrder, field: sortBy });
        }

        if (sortingOrderParam && sortingOrderParam === "asc") {
          sortingOrder = undefined
          setSort({ dir: null, field: null });
        }
        break;
    }

    onSearchSubmit({ sort: sortBy, sortOrder: sortingOrder, ...(!sortingOrder && { deleteSortOrder: true }) });
  }

  return (
    <table className="table table-hover custom-questions-table mb-0" style={{ tableLayout: "fixed" }}>
      <thead className='h-40'>
        <tr>
          <th style={{ width: "0" }} className='p-0' />
          <th scope='col' className='w-25 cursor-pointer' onClick={() => onSorting("projectName")}>
            <SortingArrow field='projectName' text='Project name' sort={sort} />
          </th>
          <th scope="col" className='cursor-pointer' onClick={() => onSorting("createdByName")}>
            <SortingArrow field='createdByName' text='Created by' sort={sort} />
          </th>
          <th scope="col" className='cursor-pointer' onClick={() => onSorting("createdUtc")}>
            <SortingArrow field='createdUtc' text='Created date' sort={sort} />
          </th>
          <th scope="col" className='cursor-pointer' onClick={() => onSorting("lastModifiedUtc")}>
            <SortingArrow field='lastModifiedUtc' text='Last modified' sort={sort} />
          </th>
          <th scope="col">Description</th>
          <th scope="col">Tags</th>
          <th scope="col" style={{ width: "5rem" }} />
        </tr>
      </thead>

      <tbody>
        {
          isLoading &&
          <ProjectsTableSkeleton />
        }

        {
          !isLoading && newProjects.length > 0 && newProjects.map((project: ProjectDetailsObject) => (
            <tr className='cursor-pointer project-table-item' key={project.id}>
              <td className='p-0 project-table-row-link'>
                <Link className='project-table-row-link' to={`projects/${project.id}`} onClick={onNavigateProject} />
              </td>
              <td title={project.name} >
                <div className='d-flex gap-lg align-items-center'>
                  <Avatar
                    backgroundColor={project.iconBackgroundColor}
                    image={project.iconUrl}
                    class="m-0"
                    size="medium"
                    rounded="small"
                    iconColor="text-dark"
                    icon={
                      project.iconUrl
                        ? null
                        : returnTableIcon(
                          project.isShared ? "sharedProject" : "projects"
                        )}
                    color={
                      project.sharedProjectInformation
                        ? "success" :
                        project.iconBackgroundColor ? '' : "secondary"
                    }
                  />
                  <p className='m-0 strong text-primary line-clamp-3'>
                    {project.name}
                  </p>
                </div>
              </td>

              <td title={project.createdByName}>
                {project.createdByName}
              </td>

              <td>{formatDate(project.createdUtc, "DATETIME_MED")}</td>
              <td>{project.lastModifiedUtc === minDate ? "-" : formatDate(project.lastModifiedUtc, "DATETIME_MED")}</td>

              <td>
                <p className='line-clamp-3 m-0'>
                  {project.description}
                </p>
              </td>

              <td>
                <div className='d-flex gap-sm flex-wrap'>
                  {project.tags?.map((tag) => (
                    <p key={tag.id} className="badge badge-light m-0">
                      {tag.name}
                    </p>
                  ))}
                </div>
              </td>

              <td>
                <div className='project-table-item-buttons gap-sm'>
                  <Tooltip position='top' anchorElement={'target'}>
                    <button
                      title="Edit"
                      className='btn btn-transparent p-1'
                      type='button'
                      onClick={() => {
                        onProjectActionHandler("edit", project.id, project.name)
                      }}
                    >
                      <Icon type='edit' className='pe-none' />
                    </button>
                  </Tooltip>

                  <Tooltip position='top' anchorElement={'target'}>
                    <button title="Delete"
                      className='btn btn-transparent p-1'
                      type='button'
                      onClick={() => {
                        onProjectActionHandler("delete", project.id, project.name)
                      }}
                    >
                      <Icon type='delete-alt' className='pe-none' />
                    </button>
                  </Tooltip>
                </div>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  )
}