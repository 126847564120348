import React from 'react';

export const PanelPreviewTabContent: React.FC = () => {

  return (
    <div className="flex-grow-1 d-flex flex-column h-100">
    </div>
  )
}

