export const returnTreeListColumnSettings = (dateCell: any, actionCell: any) => {
    return [
        {
            field: "type",
            title: "Type",
            width: "23.25%",
            expandable: true,
        },
        {
            field: "name",
            title: "Name",
            width: "23.25%"
        },
        {
            field: "userName",
            title: "User",
            width: "23.25%"
        },
        {
            field: "startDate",
            title: "Date & Time",
            width: "23.25%",
            cell: dateCell
        },
        {
            field: "action",
            title: "Action",
            width: "7%",
            cell: actionCell,
        },
    ]
}

export const returnTasksTreeListColumnSettings = (
    createdDateCell: any,
    cancelTaskCell: any,
    eventTypeCell: any,
    nextProcessingCell: any,
    lastProcessingCell: any,
    statusCell: any,
    titleCell: any,
    createdByCell: any,
    typeIconCell: any
) => {
    return [
        {
            expandable: true,
            width: "4%"
        },
        {
            width: "3%",
            cell: typeIconCell
        },
        {
            field: "eventType",
            title: "Event type",
            width: "12.5%",
            cell: eventTypeCell
        },
        {
            field: "title",
            title: "Title",
            width: "12.5%",
            cell: titleCell
        },
        {
            field: "createdByName",
            title: "Created by",
            width: "12.5%",
            cell: createdByCell
        },
        {
            field: "nextProcessingUtc",
            title: "Next processing",
            width: "12.5%",
            cell: nextProcessingCell
        },
        {
            field: "lastProcessingUtc",
            title: "Last processing",
            width: "12.5%",
            cell: lastProcessingCell
        },
        {
            title: "Created",
            width: "12.5%",
            cell: createdDateCell
        },
        {
            title: "Status",
            width: "9%",
            cell: statusCell
        },
        {
            width: "9%",
            cell: cancelTaskCell
        }
    ]
}