export default (data, type, replaceVal, initialData, findValue) => {
  const escapedFindValue = findValue.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const updatedFindValue = new RegExp(escapedFindValue, "gi");

  const updatedData = [...data]
  if (type === 'replaceFirst') {
    updatedData[0].title = updatedData[0].title.replace(updatedFindValue, replaceVal);
    initialData[initialData.indexOf(updatedData[0])].title = initialData[initialData.indexOf(updatedData[0])].title.replace(updatedFindValue, replaceVal);
  } else {
    initialData.forEach(el => {
      updatedData.forEach(val => {
        if (el['@ident'] === val['@ident']) {
          el.title = el.title.replace(updatedFindValue, replaceVal);
        }
      })
    })
    updatedData.forEach(el => {
      el.title = el.title.replace(updatedFindValue, replaceVal);
    })
  }
  return updatedData
}