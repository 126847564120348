type Question = {
  id: string
  title: string
}

type Props = {
  compatibleQuestions: Question[]
  selectedQuestion: Question
  tabTitleHandler: (question: Question) => string
}

export const QuestionEditorListOfCompatibleQuestions: React.FC<Props> = ({ compatibleQuestions, selectedQuestion, tabTitleHandler }) => {
  return (
    <ul className="list-group">
      {
        compatibleQuestions.map((question) => {
          if (question.id === selectedQuestion.id) {
            return (
              <li key={question.id} className="list-group-item list-group-item d-flex justify-content-between align-items-center">
                {tabTitleHandler(question)}
                <span className="badge badge-analyze badge-pill">Active</span>
              </li>
            );
          }
          return (
            <li key={question.id} className="list-group-item">{tabTitleHandler(question)}</li>
          );
        })
      }
    </ul>
  );
}