import React from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import SubQuestionTabTitle from '../../../helpers/SubQuestionTabTitle/SubQuestionTabTitle';

export default ({ theData, showFilterBuilder, selectedTab, onTabSelect, onChangeFilterInput }) => {

  return (
    <React.Fragment>
      {
        theData.selectedItem.elements.filter(el => el.type === 'subq').length === 1 ?
          <div className="input-group">
            <Input
              className="form-control flex-grow-1"
              value={theData.selectedItem.elements.filter(el => el.type === 'subq')[0].attributes["filter"] ?
                theData.selectedItem.elements.filter(el => el.type === 'subq')[0].attributes["filter"] : ''}
              onChange={(e) => onChangeFilterInput(e.target.value)}
            />
            <div className="input-group-append">
              <button
                className="btn btn-primary d-flex align-items-center"
                onClick={() => showFilterBuilder()}>Open Filter Builder
              </button>
            </div>
          </div>

          : <TabStrip selected={selectedTab} className="subquestion-tabs h-100" onSelect={(e) => onTabSelect(e)}>
            {theData.selectedItem.elements.filter(el => el.type === 'subq').map((subquestion, key) => (
              <TabStripTab key={key} title={<SubQuestionTabTitle theData={theData} index={key} selected={selectedTab} />}>
                <div className="input-group p-3">
                  <Input
                    className="form-control flex-grow-1"
                    value={theData.selectedItem.elements.filter(el => el.type === 'subq')[selectedTab].attributes["filter"] ?
                      theData.selectedItem.elements.filter(el => el.type === 'subq')[selectedTab].attributes["filter"] : ''}
                    onChange={(e) => onChangeFilterInput(e.target.value)} />
                  <div className="input-group-append">
                    <button
                      className="btn btn-primary d-flex align-items-center"
                      onClick={() => showFilterBuilder()}>Open Filter Builder
                    </button>
                  </div>
                </div>
              </TabStripTab>
            ))}
          </TabStrip>
      }
    </React.Fragment>
  )
}