import { v4 as uuid } from 'uuid';

import { activeQuestion } from '../activeQuestion/activeQuestion';
import updateCheckedQuestionsData from '../updateCheckedQuestionsData/updateCheckedQuestionsData';

// Initial function declarations for the DND actions
export function reorder(list, startIndex, endIndex, dest) {
  const reorder = true;
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  if (dest === 'firstColumn' && list.length > 1) {
    result[0].active = true
  }
  result.splice(endIndex, 0, removed);

  //Make new dropped question active
  activeQuestion(result, startIndex, endIndex, reorder);

  return result;
};

export function copy(source, destination, droppableSource, droppableDestination) {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const item = sourceClone[droppableSource.index];

  destClone.splice(droppableDestination.index, 0, { ...item, id: uuid() });

  //Make new dropped question active
  activeQuestion(destClone, droppableDestination, droppableSource);

  return destClone;
};

export function move(source, destination, droppableSource, droppableDestination, state, columns, secondColumnIndexCopy) {
  let destClone;
  const reorder = null;
  const result = { ...state };
  const sourceClone = Array.from(source);

  updateCheckedQuestionsData(droppableSource, droppableDestination, sourceClone, result)

  const [removed] = sourceClone.splice(droppableSource.index, 1);

  if (destination) {
    destClone = Array.from(destination);
    destClone.splice(droppableDestination.index, 0, removed);
  }

  result[droppableSource.droppableId] = sourceClone;

  if (destination) {
    result[droppableDestination.droppableId] = destClone;
  } else {
    if (!result.firstColumn) {
      result.firstColumn = state.firstColumn;
    }
    if (!result.scndColumn) {
      result.scndColumn = state.scndColumn;
    }
  }

  //Make new dropped question active
  activeQuestion(result[droppableDestination.droppableId], droppableDestination, droppableSource, reorder, state)
  if (result.firstColumn.length === 0) {
    result.displayTable = null;
    result.columns = columns;
    result.secondColumnIndex = secondColumnIndexCopy;
  } else {
    result.columns = columns;
    result.secondColumnIndex = secondColumnIndexCopy;
    result.firstColumn.forEach(el => {
      el.active = false;
    })
    result.firstColumn[0].active = true;
  }

  result.updateTable = true;

  return result;
};