import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';

import { BaseMultiSelect } from '../../../../../../shared/Inputs';
import { checkRotationType, returnAdvancedRotationElement } from '../../../helpers/returnDataHelpers/returnDataHelpers';
import { useDebounce } from '../../../../../../shared/customHooks/useDebounce';
import AdvancedRotationModal from '../../../helpers/AdvancedRotationModal/AdvancedRotationModal';

export const Rotation = () => {
  const { theData } = useSelector(theState => theState.surveyInitialDataReducer);
  const [selectedRotation, setSelectedRotation] = useState(null)
  const [excludedRows, setExcludedRows] = useState([])
  const [showAdvancedRotation, setShowAdvancedRotation] = useState(false)
  const dispatch = useDispatch()
  const saveWithDebounce = useDebounce(() => dispatch({ type: 'SAVE_QUESTION' }), 1000);

  const rowTexts = []
  theData.selectedItem.elements.find(el => el.type === 'rgroup').elements.filter((item) => item.type !== "rc").forEach(r => {
    if (r?.elements) {
      if (r.elements.length > 1) {
        const newJoinedText = r.elements.map(text => {
          const htmlObject = document.createElement('div');
          htmlObject.innerHTML = text.textValues[theData.editingLanguage]
          const newText = htmlObject.lastChild ? htmlObject.lastChild.textContent : ''
          if (newText === '') {
            return `[empty text] [${r.attributes.code}]`
          }
          return newText
        }).join(' ')
        rowTexts.push(`${newJoinedText} [${r.attributes.code}]`)
      } else {
        const htmlObject = document.createElement('div');
        htmlObject.innerHTML = r.elements[0].textValues[theData.editingLanguage];
        const newText = htmlObject.lastChild ? htmlObject.lastChild.textContent : '';
        if (newText === '' && r.attributes.code) {
          rowTexts.push(`[empty text] [${r.attributes.code}]`)
        } else {
          rowTexts.push(`${newText} [${r.attributes?.code ? r.attributes.code : ""}]`)
        }
      }
    }
  })

  const items = theData.selectedItem.elements.find(el => el.type === 'rgroup').elements.filter((item) => item.type !== "rc").map((el, key) => ({ ...el, text: rowTexts[key], index: key })).slice(1);
  const advancedRotationRows = theData.selectedItem.elements.find(el => el.type === 'rgroup').elements.filter((item) => item.type !== "rc");

  useEffect(() => {
    if (!selectedRotation && excludedRows.length === 0) {
      const checkSelectedRotation = checkRotationType(theData.selectedItem.elements.find(el => el.type === 'rgroup').elements.filter((item) => item.type !== "rc"))
      if (checkSelectedRotation.rotationType === 1) {
        const excluded = items.filter((el, key) => checkSelectedRotation.excludedRows.includes(key))
        setExcludedRows(excluded)
      }
      setSelectedRotation(checkSelectedRotation.rotationType)
    }
  }, [selectedRotation, excludedRows, items, theData.selectedItem.elements])

  const handleChange = (value, type) => {
    const updatedElement = JSON.parse(JSON.stringify(theData.selectedItem))
    if (value) {
      if (type === 1) {
        setSelectedRotation(1)
        if (updatedElement.elements.find(el => el.type === 'rgroup').elements[0]?.attributes) {
          updatedElement.elements.find(el => el.type === 'rgroup').elements.filter((item) => item.type !== "rc")[0].attributes.rot = "R"
          updatedElement.elements.find(el => el.type === 'rgroup').elements.filter((item) => item.type !== "rc")[0].attributes.rotsync = undefined
        } else {
          updatedElement.elements.find(el => el.type === 'rgroup').elements.filter((item) => item.type !== "rc")[0] = {
            ...updatedElement.elements.find(el => el.type === 'rgroup').elements.filter((item) => item.type !== "rc")[0],
            attributes: { rot: "R" }
          }
        }
      } else if (type === 2) {
        setSelectedRotation(2)
        if (updatedElement.elements.find(el => el.type === 'rgroup').elements[0].attributes?.rot) {
          updatedElement.elements.find(el => el.type === 'rgroup').elements.filter((item) => item.type !== "rc")[0].attributes.rot = undefined
          updatedElement.elements.find(el => el.type === 'rgroup').elements.filter((item) => item.type !== "rc")[0].attributes.rotsync = undefined

        }
      }
      updatedElement.elements.find(el => el.type === 'rgroup').elements.filter((item) => item.type !== "rc").forEach((row, key) => {
        if (key !== 0) {
          if (row.attributes) {
            if (row.attributes.rot) {
              row.attributes.rot = undefined
            }
            if (row.attributes.rotsync) {
              row.attributes.rotsync = undefined
            }
          }
        }
      })
    }
    else {
      setSelectedRotation(0)
      setExcludedRows([])
      updatedElement.elements.find(el => el.type === 'rgroup').elements.filter((item) => item.type !== "rc").forEach(row => {
        if (row.attributes) {
          if (row.attributes.rot) {
            row.attributes.rot = undefined
          }
          else if (row.attributes.rotsync) {
            row.attributes.rotsync = undefined
          }
        }
      })
    }
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedElement })
    saveWithDebounce()
  }

  const handleExcludedRows = (value) => {
    const updatedElement = JSON.parse(JSON.stringify(theData.selectedItem))
    const selected = value.map(el => el.attributes.code);

    updatedElement.elements.find(el => el.type === 'rgroup').elements.filter((item) => item.type !== "rc").forEach((el, key) => {
      if (key !== 0) {
        if (selected.includes(el.attributes.code)) {
          if (updatedElement.elements.find(el => el.type === 'rgroup').elements[key].attributes) {
            updatedElement.elements.find(el => el.type === 'rgroup').elements.filter((item) => item.type !== "rc")[key].attributes.rot = "N"
          } else {
            updatedElement.elements.find(el => el.type === 'rgroup').elements[key] = {
              ...updatedElement.elements.find(el => el.type === 'rgroup').elements.filter((item) => item.type !== "rc")[key],
              attributes: { rot: "N" }
            }
          }
        } else {
          updatedElement.elements.find(el => el.type === 'rgroup').elements.filter((item) => item.type !== "rc")[key].attributes.rot = undefined;
        }
      }
    })
    setExcludedRows(value)
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedElement })
    saveWithDebounce()
  }

  const saveAdvancedRotation = (values) => {
    const updatedElement = returnAdvancedRotationElement(theData.selectedItem, values)
    setShowAdvancedRotation(false)
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedElement })
    saveWithDebounce()
  }

  return (
    <Fragment>
      {selectedRotation !== null &&
        <div className="d-flex flex-column">
          {showAdvancedRotation &&
            <AdvancedRotationModal
              handleClose={() => setShowAdvancedRotation(false)}
              rows={advancedRotationRows}
              saveAdvancedRotation={saveAdvancedRotation}
            />
          }
          <div className="d-flex flex-row h-32 justify-content-between mb-3">
            <div className="d-flex align-items-center w-50 ">
              <Switch
                disabled={theData.selectedItem.elements.find(el => el.type === 'rgroup').elements.length === 0}
                className="mr-3 d-flex align-items-center"
                onChange={(e) => handleChange(e.value, 2)}
                checked={selectedRotation === 2}
                size="small"
              />
              <span>Advanced row randomisation</span>
            </div>
            <Button className="btn btn-secondary"
              disabled={selectedRotation !== 2 || advancedRotationRows.length === 0} onClick={() => setShowAdvancedRotation(true)}>
              Advanced row randomisation</Button>
          </div>

          <div className="d-flex flex-column mb-3">
            <div className="d-flex align-items-center w-50">
              <Switch
                disabled={theData.selectedItem.elements.find(el => el.type === 'rgroup').elements.length === 0}
                className="mr-3 d-flex align-items-center"
                onChange={(e) => handleChange(e.value, 1)}
                checked={selectedRotation === 1}
                size="small"
              />
              <span>Randomise rows</span>
            </div>
            <div className="d-flex flex-column w-100">
              <BaseMultiSelect
                value={excludedRows}
                onChange={(e) => handleExcludedRows(e.value)}
                className="w-100 mt-3"
                data={items}
                textField="text" disabled={selectedRotation !== 1}
              />
              <p className={`mt-1 mb-0 ${selectedRotation !== 1 && 'text-disabled'}`}>Select rows to exclude</p>
            </div>
          </div>
        </div>
      }
    </Fragment>
  )
}