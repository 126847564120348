import Editor from '@monaco-editor/react';

import type { AdvancedOptionChanges, Theme } from '../../../../../../../../../../interfaces/surveyInterfaces/themeTypes';

interface Props {
  themeData: Theme
  unsavedChanges: AdvancedOptionChanges
  setThemeData: (themeData: TODO) => void
  setUnsavedChanges: (unsaved: AdvancedOptionChanges) => void
}

export const CustomHTMLFooter = ({ themeData, unsavedChanges, setThemeData, setUnsavedChanges }: Props) => {
  const handleEditorChange = (value: TODO) => {
    if (!unsavedChanges.htmlFooter) { setUnsavedChanges({ ...unsavedChanges, htmlFooter: true }) }
    setThemeData({ ...themeData, htmlFooter: value })
  }

  return (
    <Editor
      onChange={handleEditorChange}
      defaultValue={themeData.htmlFooter}
      options={{
        autoIndent: 'full',
        tabSize: 4,
        formatOnPaste: true,
        formatOnType: true,
      }}
      theme={'vs-dark'}
      defaultLanguage="html"
    />
  )
}