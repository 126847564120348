import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

type Props = {
  handleClose: () => void;
  closeSurvey: () => void;
}

export default function SubmitCloseModal({ handleClose, closeSurvey }: Props) {
  return (
    <Dialog title="Close survey" onClose={handleClose} width={500} >
      <div className="d-flex flex-column p-5">
        <p>Closing the survey will stop any further participation from new respondents and ative campaigns will not be sent out. A closed survey can easily be opened again.</p>
        <p className='mb-0'>Please confirm if you'd like to proceed.</p>
      </div>
      <DialogActionsBar>
        <Button className="btn btn-secondary" onClick={handleClose}>Cancel</Button>
        <Button className="btn btn-primary" onClick={closeSurvey}>Close survey</Button>
      </DialogActionsBar>
    </Dialog>
  )
}