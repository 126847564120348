import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input, type InputChangeEvent } from '@progress/kendo-react-inputs';

import type { Tag } from '../TagsTabContent';
import type { RootState } from '../../../../../store/reducers/rootReducer';
import { ErrorMessage } from '../../../../shared/ErrorMessage/ErrorMessage';
import { fetchDeleteJson as deleteTag } from '../../../../../services/services';

type Props = {
  tag?: Tag | null;
  tags: Tag[] | null;
  handleClose: () => void;
  updateTags: (tags: Tag[]) => void;
}

export const DeleteTagModal = ({ handleClose, tag, tags, updateTags }: Props) => {
  const dispatch = useDispatch()
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [errorMessage, setErrorMessage] = useState("")
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = () => {
    if (tag?.id) {
      setIsLoading(true);
      deleteTag(`tags/${tag.id}`, token)
        .then((res: TODO) => {
          setIsLoading(false);
          if (res && res.status === 204) {
            if (tags !== null) {
              const updatedTags = [...tags]
              const matchedTag = updatedTags.find(item => item.id === tag.id)
              if (matchedTag) {
                updatedTags.splice(updatedTags.indexOf(matchedTag), 1)
                dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The tag has been deleted successfully' } })
                updateTags(updatedTags)
                handleClose()
              }
            }
          } else if (res && (res.message || res.error)) {
            setErrorMessage(res.message ? res.message : res.error)
          }
        })
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      title="Delete tag"
      className="d-flex flex-column w-100"
      width="30%">
      <div className="p-3">
        <p>
          Confirm the deletion of the tag by typing the tag <strong>name</strong> in the input field
        </p>
        <Input
          required
          onChange={(e: InputChangeEvent) => setName(e.value)}
          value={name}
          placeholder="Enter tag name"
          name="tagName"
          className="w-100" />
        {
          errorMessage &&
          <div className="mt-4">
            <ErrorMessage
              type="alert"
              errorMessage={errorMessage} />
          </div>
        }
      </div>
      <DialogActionsBar>
        <button
          type='button'
          className="k-button btn btn-secondary"
          onClick={handleClose}>Cancel
        </button>
        <button
          type='button'
          className="k-button btn-danger"
          disabled={tag?.name !== name || isLoading}
          onClick={onSubmitHandler}>Delete
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}
