import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from '../../../shared/Icon/Icon';
import { Avatar } from '../../../shared/Avatar/Avatar';
import { formatDate } from '../../../shared/DateUtils/DateUtils';
import type { RootState } from '../../../../store/reducers/rootReducer';
import { useFetchEmailProviders } from '../../../shared/customHooks/useFetchEmailProviders';
import { DeleteEmailProviderModal } from './DeleteEmailProviderModal/DeleteEmailProviderModal';
import type { EmailProvider } from '../../../../interfaces/emailProviderInterfaces/emailProviderInterfaces';
import { AdminEmailProvidersTableSkeleton } from './AdminEmailProvidersTableSkeleton/AdminEmailProvidersTableSkeleton';

export const EmailTabContent = () => {
  const dispatch = useDispatch();
  const { showDeleteEmailProviderModal, emailProviders } = useSelector((theState: RootState) => theState.adminReducer);

  useFetchEmailProviders();

  const onEditClick = (emailProvider: EmailProvider) => {
    const type = emailProvider.providerType
    if (type === 'smtp') {
      dispatch({ type: 'UPDATE_SHOW_EMAIL_PROVIDERS_MODAL', payload: { emailProvider: emailProvider, show: true } })
    } else if (type === 'sendgrid') {
      dispatch({ type: 'UPDATE_SHOW_SENDGRID_PROVIDERS_MODAL', payload: { emailProvider: emailProvider, show: true } })
    }
  }

  const returnAvatarIcon = (emailProvider: EmailProvider) => {
    switch (emailProvider.providerType) {
      case 'smtp':
        return 'fas fa-envelope-open-text';
      case 'sendgrid':
        return 'fas fa-th-large';
      default:
        return '';
    }
  }

  return (
    <Fragment>
      {
        showDeleteEmailProviderModal.show &&
        <DeleteEmailProviderModal
          emailProvider={showDeleteEmailProviderModal.emailProvider}
          handleClose={() => dispatch({ type: 'UPDATE_SHOW_DELETE_EMAIL_PROVIDERS_MODAL', payload: { show: false, emailProvider: null } })} />
      }
      <div className="px-3 mt-5">
        {
          emailProviders !== null ?
            emailProviders.length > 0 ?
              <table className="table table-striped admin-table mt-3">
                <thead>
                  <tr>
                    <th scope='col' />
                    <th scope="col">Provider name</th>
                    <th scope="col">Description</th>
                    <th scope="col">Date created</th>
                    <th scope="col">Created by</th>
                    <th className='text-center' scope="col">Provider type</th>
                    <th className='text-center' scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {
                    emailProviders.map((emailProvider: EmailProvider) => (
                      <tr key={emailProvider.id}>
                        <td className='admin-avatar-cell' style={{ width: '60px' }}>
                          <Avatar
                            color={"primary"}
                            class="avatar-sm text-white m-0"
                            icon={returnAvatarIcon(emailProvider)}
                          />
                        </td>
                        <th scope='row'>{emailProvider.name}</th>
                        <td>
                          <p style={{ maxWidth: '400px' }} className='m-0 text-truncate'>{emailProvider.description}</p>
                        </td>
                        <td>{formatDate(emailProvider.createdUtc, "DATE_MED")}</td>
                        <td>{emailProvider.createdByName}</td>
                        <td>
                          <div className="d-flex justify-content-center">
                            <span className="badge badge-pill badge-primary text-white text-uppercase">{emailProvider.providerType}</span>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex justify-content-center gap-lg admin-table-actions">
                            <button
                              type="button"
                              className="btn text-dark p-0"
                              onClick={() => onEditClick(emailProvider)}>
                              <Icon className='pe-none' type='edit' />
                            </button>
                            <button
                              type="button"
                              className="btn text-dark p-0"
                              onClick={() => dispatch({ type: 'UPDATE_SHOW_DELETE_EMAIL_PROVIDERS_MODAL', payload: { emailProvider: emailProvider, show: true } })}>
                              <Icon className='pe-none' type='delete-alt' />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
              :
              <div className="d-flex flex-column gap-lg text-disabled text-center h5 pt-10 w-100">
                <i className="fal fa-2x fa-folder-open" />
                <p className='m-0'>No email providers have been found</p>
              </div>
            :
            <AdminEmailProvidersTableSkeleton />
        }
      </div>
    </Fragment>
  )
}