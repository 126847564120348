export const returnValueChange = (updateOptionsValues, initialOptionsValues, updatedValue, id) => {
  const updateInitialValues = initialOptionsValues;

  if (updateInitialValues.length !== 0) {
    for (const value in updateInitialValues) {
      if (value === id) {
        updateInitialValues[value] = updatedValue
      }
    }
  }

  const updateValues = updateOptionsValues;
  if (updateValues.length > 0) {
    updateValues.forEach(el => {
      if (el !== id) {
        updateValues[id] = updatedValue
      }
      else (
        updateValues[el] = updatedValue
      )
    })
  } else {
    updateValues[id] = updatedValue
  }

  if ((id === 'XtSortTableCol' || id === 'XtSortExclude' || id === 'XtSortRollEnd') && updateValues.XtSortRollDir === 0 && updateInitialValues.XtSortRollDir === 0) {
    updateValues.XtSortRollDir = 2;
    updateInitialValues.XtSortRollDir = 2;
  } else if ((id === 'QtSortTableCol' || id === 'QtSortExclude' || id === 'QtSortRollEnd') && updateValues.QtSortRollDir === 0 && updateInitialValues.QtSortRollDir === 0) {
    updateValues.QtSortRollDir = 2;
    updateInitialValues.QtSortRollDir = 2;
  } else if ((id === 'GSortExclude' || id === 'GSortRollEnd') && updateValues.GSortRollDir === 0 && updateInitialValues.GSortRollDir === 0) {
    updateValues.GSortRollDir = 2;
    updateInitialValues.GSortRollDir = 2;
  }

  return {
    updateValues, updateInitialValues
  }
}

export const returnFilteredData = (originalData, value) => {
  if (value) {
    return originalData.filter(el => el.name.toLowerCase().includes(value.toLowerCase()))
  }
}

export const returnSelectedGroup = (selectedGroup, groups) => {
  if (selectedGroup) {
    const currentGroup = groups.find(group => group.text === selectedGroup)
    return currentGroup.text
  }
}

export const returnOnSaveFilterData = (data, expression, option, tab, updateOptionsValues, initialOptionsValues) => {
  let updateData = "";
  let updateValues;
  const description = "";
  let updateDataSelected = '';

  if (tab === 'crosstab') {
    updateDataSelected = 'SET_SELECTED_DATA';
    if (option === 'XtFilterCode') {
      updateValues = returnValueChange(updateOptionsValues, initialOptionsValues, expression, 'XtFilterCode')
      updateData = 'SET_FILTER_DATA'
    }
    else if (option === 'XtUniverseCode') {
      updateValues = returnValueChange(updateOptionsValues, initialOptionsValues, expression, 'XtUniverseCode')
      updateData = 'SET_UNIVERSE_DATA'
    }
  }
  else if (tab === 'topline') {
    if (option === 'FilterCode') {
      updateValues = returnValueChange(updateOptionsValues, initialOptionsValues, expression, 'FilterCode')
      updateData = 'TOPLINE_SET_FILTER_DATA'
    }
    else if (option === 'UniverseCode') {
      updateValues = returnValueChange(updateOptionsValues, initialOptionsValues, expression, 'UniverseCode')
      updateData = 'TOPLINE_SET_UNIVERSE_DATA'

    }
  }
  else if (tab === 'qt') {
    if (option === 'QtFilterCode') {
      updateValues = returnValueChange(updateOptionsValues, initialOptionsValues, expression, 'QtFilterCode')
      updateData = 'QTABLE_SET_FILTER_DATA'
    }
    else if (option === 'QtUniverseCode') {
      updateValues = returnValueChange(updateOptionsValues, initialOptionsValues, expression, 'QtUniverseCode')
      updateData = 'QTABLE_SET_UNIVERSE_DATA'
    }
  }
  else if (tab === 'data') {
    if (option === 'Filter') {
      updateValues = returnValueChange(updateOptionsValues, initialOptionsValues, expression, 'Filter')
      updateData = 'RAW_DATA_SET_FILTER_DATA'
    }
    else if (option === 'Universe') {
      updateValues = returnValueChange(updateOptionsValues, initialOptionsValues, expression, 'Universe')
      updateData = 'RAW_DATA_SET_UNIVERSE_DATA'

    }
  }
  else if (tab === 'dig') {
    if (option === 'TargetCode') {
      updateValues = description
      updateData = 'DIG_SET_TARGET_GROUP'
    }
    else if (option === 'UniverseCode') {
      updateValues = returnValueChange(updateOptionsValues, initialOptionsValues, expression, 'UniverseCode')
      updateData = 'DIG_SET_UNIVERSE_DATA'

    }
  } if (tab === 'chart') {
    updateDataSelected = 'SET_CHART_SELECTED_DATA';
    if (option === 'GFilterCode') {
      updateValues = returnValueChange(updateOptionsValues, initialOptionsValues, expression, 'GFilterCode')
      updateData = 'CHART_SET_FILTER_DATA'
    }
    else if (option === 'GUniverseCode') {
      updateValues = returnValueChange(updateOptionsValues, initialOptionsValues, expression, 'GUniverseCode')
      updateData = 'CHART_SET_UNIVERSE_DATA'
    }
  }
  return {
    updateValues, updateData, updateDataSelected
  }

}

export const returnOnClearFilterData = (option, tab, updateOptionsValues, initialOptionsValues) => {
  let updateData = '';
  let updateDataSelected = '';
  let clearedUpdateOptionsValues;
  let clearedInitialOptionsValues;

  if (tab === 'crosstab') {
    updateDataSelected = 'SET_SELECTED_DATA';
    if (option === 'XtFilterCode') {
      clearedUpdateOptionsValues = { ...updateOptionsValues, XtFilterCode: "", XtFilterText: "" }
      clearedInitialOptionsValues = { ...initialOptionsValues, XtFilterCode: "", XtFilterText: "" }
      updateData = 'SET_FILTER_DATA'
    }
    if (option === 'XtUniverseCode') {
      clearedUpdateOptionsValues = { ...updateOptionsValues, XtUniverseCode: "" }
      clearedInitialOptionsValues = { ...initialOptionsValues, XtUniverseCode: "" }
      updateData = 'SET_UNIVERSE_DATA'
    }
  }

  if (tab === 'topline') {
    if (option === 'FilterCode') {
      clearedUpdateOptionsValues = { ...updateOptionsValues, FilterCode: "", FilterText: "" }
      clearedInitialOptionsValues = { ...initialOptionsValues, FilterCode: "", FilterText: "" }
      updateData = 'TOPLINE_SET_FILTER_DATA'
    }
    if (option === 'UniverseCode') {
      clearedUpdateOptionsValues = { ...updateOptionsValues, UniverseCode: "" }
      clearedInitialOptionsValues = { ...initialOptionsValues, UniverseCode: "" }
      updateData = 'TOPLINE_SET_UNIVERSE_DATA'
    }
  }

  if (tab === 'qt') {
    if (option === 'QtFilterCode') {
      clearedUpdateOptionsValues = { ...updateOptionsValues, QtFilterCode: "", QtFilterText: "" }
      clearedInitialOptionsValues = { ...initialOptionsValues, QtFilterCode: "", QtFilterText: "" }
      updateData = 'QTABLE_SET_FILTER_DATA'
    }
    if (option === 'QtUniverseCode') {
      clearedUpdateOptionsValues = { ...updateOptionsValues, QtUniverseCode: "" }
      clearedInitialOptionsValues = { ...initialOptionsValues, QtUniverseCode: "" }
      updateData = 'QTABLE_SET_UNIVERSE_DATA'
    }
  }

  if (tab === 'data') {
    if (option === 'Filter') {
      clearedUpdateOptionsValues = { ...updateOptionsValues, Filter: "" }
      clearedInitialOptionsValues = { ...initialOptionsValues, Filter: "" }
      updateData = 'RAW_DATA_SET_FILTER_DATA'
    }
    if (option === 'Universe') {
      clearedUpdateOptionsValues = { ...updateOptionsValues, Universe: "" }
      clearedInitialOptionsValues = { ...initialOptionsValues, Universe: "" }
      updateData = 'RAW_DATA_SET_UNIVERSE_DATA'

    }
  }

  if (tab === 'dig') {
    if (option === 'UniverseCode') {
      clearedUpdateOptionsValues = { ...updateOptionsValues, UniverseCode: "" }
      clearedInitialOptionsValues = { ...initialOptionsValues, UniverseCode: "" }
      updateData = 'DIG_SET_UNIVERSE_DATA'

    }
  }

  if (tab === 'chart') {
    updateDataSelected = 'SET_CHART_SELECTED_DATA';
    if (option === 'GFilterCode') {
      clearedUpdateOptionsValues = { ...updateOptionsValues, GFilterCode: "", GFilterText: "" }
      clearedInitialOptionsValues = { ...initialOptionsValues, GFilterCode: "", GFilterText: "" }
      updateData = 'CHART_SET_FILTER_DATA'
    }
    if (option === 'GUniverseCode') {
      clearedUpdateOptionsValues = { ...updateOptionsValues, GUniverseCode: "" }
      clearedInitialOptionsValues = { ...initialOptionsValues, GUniverseCode: "" }
      updateData = 'CHART_SET_UNIVERSE_DATA'
    }
  }

  return {
    updateData,
    updateDataSelected,
    clearedUpdateOptionsValues,
    clearedInitialOptionsValues
  }

}

export const returnSetPreviousData = (option, tab) => {
  let currentFilterData = '';
  let currentUniverseData = '';
  let optionType = '';
  let setCurrentOptionsData = '';
  let initialQuestionOptionsValues = '';

  if (tab === 'crosstab') {
    optionType = "SET_OPTION";
    setCurrentOptionsData = 'SET_CURRENT_OPTIONS_DATA';
    initialQuestionOptionsValues = 'SET_INITIAL_OPTIONS_VALUES';

    if (option?.includes('XtFilterCode')) {
      currentFilterData = 'SET_FILTER_DATA';
    }
    if (option?.includes('XtUniverseCode')) {
      currentUniverseData = 'SET_UNIVERSE_DATA';
    }
  }

  if (tab === 'topline') {
    optionType = "SET_TOPLINE_OPTION";
    setCurrentOptionsData = 'SET_TOPLINE_CURRENT_OPTIONS_DATA';
    initialQuestionOptionsValues = 'SET_TOPLINE_INITIAL_OPTIONS_VALUES';

    if (option?.includes('FilterCode')) {
      currentFilterData = 'TOPLINE_SET_FILTER_DATA';
    }
    if (option?.includes('UniverseCode')) {
      currentUniverseData = 'TOPLINE_SET_UNIVERSE_DATA';
    }
  }

  if (tab === 'qt') {
    optionType = "SET_QTABLE_OPTION";
    setCurrentOptionsData = 'SET_QTABLE_CURRENT_OPTIONS_DATA';
    initialQuestionOptionsValues = 'SET_QTABLE_INITIAL_OPTIONS_VALUES';

    if (option?.includes('QtFilterCode')) {
      currentFilterData = 'QTABLE_SET_FILTER_DATA';
    }
    if (option?.includes('QtUniverseCode')) {
      currentUniverseData = 'QTABLE_SET_UNIVERSE_DATA';
    }
  }

  if (tab === 'data') {
    optionType = "SET_RAW_DATA_OPTION";
    setCurrentOptionsData = 'SET_RAW_DATA_CURRENT_OPTIONS_DATA';
    initialQuestionOptionsValues = 'SET_RAW_DATA_INITIAL_OPTIONS_VALUES';

    if (option?.includes('Filter')) {
      currentFilterData = 'RAW_DATA_SET_FILTER_DATA';
    }
    if (option?.includes('Universe')) {
      currentUniverseData = 'RAW_DATA_SET_UNIVERSE_DATA';
    }
  }

  if (tab === 'dig') {
    optionType = "SET_DIG_OPTION";
    setCurrentOptionsData = 'SET_DIG_CURRENT_OPTIONS_DATA';
    initialQuestionOptionsValues = 'SET_DIG_INITIAL_OPTIONS_VALUES';

    if (option?.includes('UniverseCode')) {
      currentUniverseData = 'DIG_SET_UNIVERSE_DATA';
    }
  }

  if (tab === 'chart') {
    optionType = "SET_CHART_OPTION";
    setCurrentOptionsData = 'SET_CHART_CURRENT_OPTIONS_DATA';
    initialQuestionOptionsValues = 'SET_CHART_INITIAL_OPTIONS_VALUES';

    if (option?.includes('GFilterCode')) {
      currentFilterData = 'CHART_SET_FILTER_DATA';
    }
    if (option?.includes('GUniverseCode')) {
      currentUniverseData = 'CHART_SET_UNIVERSE_DATA';
    }
  }

  return {
    currentFilterData,
    currentUniverseData,
    optionType,
    setCurrentOptionsData,
    initialQuestionOptionsValues
  }
}