export default () => {
  let clearQuestionItems = [
    {
      actionName: 'Clear all',
      action: 'CLEAR_QUESTIONS'
    },
    {
      actionName: 'Clear rows',
      action: 'CLEAR_QUESTION_ROWS'
    },
    {
      actionName: 'Clear columns',
      action: 'CLEAR_QUESTION_COLUMNS'
    },
    {
      actionName: 'Clear selected',
      action: 'CLEAR_QUESTION_CHECKED'
    },
    {
      actionName: 'Clear options for selected',
      action: 'CLEAR_SELECTED_QUESTION_OPTIONS'
    },
    {
      actionName: 'Clear options',
      action: 'CLEAR_QUESTION_OPTIONS'
    }
  ]
  return clearQuestionItems
}