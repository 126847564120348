import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';

import type { RootState } from '../../../../../store/reducers/rootReducer';
import { updateExpandedDisplayType, updateFileManagerExpandedState, updateShowNewFolderModal } from '../../../../../store/reducers/fileManagerStateReducer/fileManagerStateReducer';
import type { FileManagerState } from '../../../../../interfaces/fileManagerInterfaces/fileManagerInterfaces';
import { Icon } from '../../../Icon/Icon';

interface Props {
  expandedState: TODO
  expandedDisplayType: string
}

export const FileManagerExpandedScreenSortDetails = ({ expandedState, expandedDisplayType }: Props) => {
  const dispatch = useDispatch()
  const { breadCrumbData, checkedFiles } = useSelector((state: RootState) => state.fileManagerStateReducer);

  const onBreadCrumbClick = (item: TODO) => {
    const updatedExpandedState: FileManagerState = JSON.parse(JSON.stringify(expandedState))
    updatedExpandedState.data[0] = item
    dispatch(updateFileManagerExpandedState(updatedExpandedState))
  }

  const onCopyFileUrlHandler = () => {
    const selectedFile = expandedState.selected
    if (selectedFile && !selectedFile.isDirectory) {
      navigator.clipboard.writeText(selectedFile.uri).then(() => {
        dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The file URL has been copied to clipboard' } })
      });
    }
  }

  const onNewFolderClick = () => {
    dispatch(updateShowNewFolderModal(true))
  }

  return (
    <div className="d-flex justify-content-between align-items-center w-100 h-56 px-4 border-bottom">
      <nav aria-label="breadcrumb flex-grow-1">
        <ol className="breadcrumb d-flex align-items-center bg-white p-0">
          <li style={{ cursor: 'default' }} className="breadcrumb-item medium text-disabled lh-24">
            File library
          </li>
          {
            breadCrumbData.map((item: TODO, key: number) => (
              <li key={key} className="d-flex breadcrumb-item">
                <span
                  onClick={() => onBreadCrumbClick(item)}
                  className={`medium strong lh-24 p-0 ${key === breadCrumbData.length - 1 ? "pe-none" : ""}`}
                >
                  {item.displayName}
                </span>
              </li>
            ))
          }
        </ol>
      </nav>
      <div className="d-flex align-items-center">
        <Tooltip anchorElement="target" position="top">
          <button type='button' title='Copy to clipboard' className='btn btn-icon icon-b btn-shadow mr-2' onClick={onCopyFileUrlHandler} disabled={checkedFiles.length !== 1 ? true : expandedState.selected && expandedState.selected.isDirectory ? true : false}><Icon type="copy" className='pe-none' /></button>
        </Tooltip>
        <Tooltip anchorElement="target" position="top">
          <button type='button' className='btn btn-icon icon-b btn-shadow' title="New sub-folder" onClick={onNewFolderClick}><Icon type="new-sub-folder" className='pe-none' /></button>
        </Tooltip>
        <div className="btn-group ml-2 table-view">
          <button type='button' title='Grid view' className={`btn p-1 ${expandedDisplayType === 'largeIcons' ? 'btn-primary' : 'btn-shadow'}`} onClick={() => dispatch(updateExpandedDisplayType('largeIcons'))}><Icon type="view-grid" className='pe-none' /></button>
          <button type='button' title='List view' className={`btn p-1 ${expandedDisplayType === 'details' ? 'btn-primary' : 'btn-shadow'}`} onClick={() => dispatch(updateExpandedDisplayType('details'))}><Icon type="view-list" className='pe-none' /></button>
        </div>
      </div>
    </div>
  )
}