export type ItemType = "n" | "m" | "f" | "s" | "dl" | "d" | "rn" | "rm" | "data" | "crosstab" | "chart" | "dig" | "topline" | "qtable" | "";

export const returnQuestionIcon = (itemType: ItemType) => {
  switch (itemType) {
    case "n":
      return "single" // Single
    case "m":
      return "multi"  // Multi
    case "f":
      return "numeric" // Numeric
    case "s":
      return "numeric" // Numeric
    case "dl":
      return "numeric" // Numeric
    case "d":
      return "duration" // Date
    case "rn":
      return "single-grid" // Single grid
    case "rm":
      return "multi-grid" // Multi grid
    case "data":
      return "analysis-table-data"
    case "crosstab":
      return "analysis-table-crosstable"
    case "chart":
      return "analysis-table-chart"
    case "dig":
      return "analysis-table-dig"
    case "topline":
      return "analysis-table-topline"
    case "qtable":
      return "analysis-table-qtable"
    default:
      return "single"
  }
}