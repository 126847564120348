import { useState } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

interface Props {
  quota: any
  handleClose: () => void
  updateQuota: (updatedQuota: any, shouldSave: boolean) => void
}

export const PasteTargetsModal = ({ quota, handleClose, updateQuota }: Props) => {
  const [targetsData, setTargetsData] = useState<string>('')

  const onSaveHandler = () => {
    const trimmedTargetsDataArr = targetsData.split(/\r?\n/).filter(line => line.trim() !== '').join('\n').split('\n').filter(el => Number(el))
    const updatedQuota = JSON.parse(JSON.stringify(quota))
    updatedQuota.quotaCells.forEach((cell: { targetValue: number }, index: number) => {
      if (trimmedTargetsDataArr[index]) {
        cell.targetValue = Number(trimmedTargetsDataArr[index])
      }
    })
    updateQuota(updatedQuota, true)
    handleClose()
  }

  return (
    <Dialog title="Paste targets" onClose={handleClose} width={500}>
      <div className="p-4">
        <label>Paste your targets here (one per line):</label>
        <textarea
          rows={20}
          name="targets"
          value={targetsData}
          className='form-control'
          onChange={(e) => setTargetsData(e.target.value.replace(/\n\n\n/, "\n\n"))}
        />
      </div>
      <DialogActionsBar>
        <button className="k-button btn btn-secondary" onClick={handleClose}>Cancel</button>
        <button disabled={!targetsData} className="k-button btn btn-primary" onClick={onSaveHandler}>Save</button>
      </DialogActionsBar>
    </Dialog>
  )
}