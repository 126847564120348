import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { Avatar } from '../Avatar/Avatar';
import WALRLogo from '../../../assets/img/walr_black.png';

export default () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <div className="trial-expired-container" >
      <div className="d-flex flex-column h-100">
        <img className="trial-expired-walr-logo mx-5 mt-5" src={WALRLogo} alt="WALR logo" />
        <div className="container flex-grow-1">
          <div className="row justify-content-center align-items-center h-100">
            <div className="col-md-6">
              <div className="text-center d-flex flex-column align-items-center rounded shadow gap-xl px-3 py-10 ">
                <i className="far fa-3x fa-check-circle" />
                <p className="error-message text-primary">Your account has been activated successfully!</p>
                <button
                  type="button"
                  onClick={() => loginWithRedirect()}
                  className="btn w-50 btn-primary">
                  <span>Sign in</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}