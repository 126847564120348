import { useState } from 'react';

import { Dashboard } from './TabComponents/Dashboard/Dashboard';
import { Customers } from './TabComponents/Customers/Customers';
import { BackofficeModal } from './BackofficeModal/BackofficeModal';
import type { State, editModalState } from '../../interfaces/backofficeInterfaces/backofficeInterfaces';

const backOfficeLinks = ['Dashboard', 'Organisation'] as const;

export const Backoffice = () => {
  document.title = "Backoffice – Walr";
  const [state, setState] = useState<State>({ data: [], skip: 0, take: 40, filter: [] });
  const [editOrAddModalOpen, setEditOrAddModalOpen] = useState<editModalState>({
    shouldModalOpen: false,
    whichModalOpens: "",
    body: {
      country: "",
      isTrial: false,
      name: "",
      trialEndTimeUtc: "",
      subAccountSettings: {
        canCreateSubAccounts: false
      },
      enterpriseSettings: {
        hasSurveyAccess: false,
        hasToolsAccess: false,
        singleSignOnConnectionName: false,
        hasPinboardAccess: false,
        hasAudienceAccess: false
      },
      users: [],
      customerID: ""
    }
  });
  const [customerID, setCustomerID] = useState("");
  const [activeTab, setActiveTab] = useState<typeof backOfficeLinks[number]>("Dashboard");

  const onAddCustomerClick = () => {
    setEditOrAddModalOpen({
      ...editOrAddModalOpen,
      shouldModalOpen: true,
      whichModalOpens: "add"
    })
  };

  return (
    <section className="main backoffice-container flex-grow-1 d-flex flex-column h-100">
      {
        editOrAddModalOpen.shouldModalOpen &&
        <BackofficeModal
          customerID={customerID}
          editOrAddModalOpen={editOrAddModalOpen}
          setEditOrAddModalOpen={setEditOrAddModalOpen}
        />
      }
      <h2 className="backofficeTitle p-5">Backoffice</h2>
      <ul className='mb-5 mx-5 list-unstyled width-fit-content d-flex backoffice-list'>
        {
          backOfficeLinks.map((link) =>
            // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
            <li
              key={link}
              className={`mr-1 py-1 ${activeTab === link && "activeTab"}`}
              onClick={() => setActiveTab(link)}>
              {link}
            </li>
          )}
      </ul>
      {
        activeTab === "Dashboard" ?
          <Dashboard />
          :
          activeTab === "Organisation" ?
            <Customers
              setCustomersData={(data: TODO) => setState(data)}
              state={state}
              setCustomerID={setCustomerID}
              editOrAddModalOpen={editOrAddModalOpen}
              setEditOrAddModalOpen={setEditOrAddModalOpen}
              handleClick={onAddCustomerClick}
            />
            : null
      }
    </section>
  )
}
