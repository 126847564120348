import { DateTime } from "luxon";

export const DATE_FORMATS = [
  'DATE_MED',
  'DATE_SHORT',
  'DATE_HUGE',
  'DATETIME_MED',
  'DATETIME_MED_WITH_SECONDS',
  'DATETIME_SHORT',
  'DATETIME_FULL',
] as const;
export type DateFormatsType = (typeof DATE_FORMATS)[number];

/**
 * Format date
 * @param dateTimeFormat Luxon date format 
 * @param dateTime Luxon DateTime
 */

export const formatDate = (dateString: string, dateTimeFormat: DateFormatsType) => {
  const dateISOString = DateTime.fromISO(dateString);

  if (!dateISOString) return dateString;

  return DateTime.fromISO(dateString).setLocale('en-GB').toLocaleString(DateTime[dateTimeFormat]);
}

/**
 * @param {dateString} dateString utc string
 * returns diff toRelative to now when comment was created
 */

export const commentCreatedAt = (dateString: string) => {
  const dateISOString = DateTime.fromISO(dateString);

  if (!dateISOString) return "";

  const dateDiff = DateTime.now().diff(dateISOString);

  return dateDiff ? DateTime.now().minus({ milliseconds: dateDiff.milliseconds }).toRelative({ unit: ["days", "hours", "minutes", "seconds"] }) : "";
}