import { useEffect, useState } from "react"
import { useSelector } from "react-redux";

import { fetchGetJson as getAnalysisFunctionalities } from "../../../services/services"
import type { RootState } from "../../../store/reducers/rootReducer";

export const useFetchAnalysisFunctionalities = (projectId: string) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [analysisFunctionalities, setAnalysisFunctionalities] = useState(null)

  useEffect(() => {
    if (projectId) {
      getAnalysisFunctionalities(`projects/${projectId}/analysis/functionalities`, token)
        .then((res: TODO) => {
          if (res) {
            setAnalysisFunctionalities(res)
          }
        })
    }
  }, [projectId, token])

  return analysisFunctionalities
}