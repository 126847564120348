export function clearQuestionColumns(theState) {
  if (theState.state.scndColumn) {
    if (theState.state.scndColumn.length > 0) {
      return {
        ...theState,
        state: {
          ...theState.state,
          scndColumn: [],
          updateTable: true,
          displayTable: null
        }
      }
    }
  }
}