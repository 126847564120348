import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';

import { Icon } from '../../../../../../../shared/Icon/Icon';
import { EditTabContent } from './EditTabContent/EditTabContent';
import type { RootState } from '../../../../../../../../store/reducers/rootReducer';
import { fetchPutJson, fetchPostResOrJson } from '../../../../../../../../services/services';

interface Props {
  handleEditTool: () => void
}

export const CleanAndDefineToolTabContent = ({ handleEditTool }: Props) => {
  const { workflowData } = useSelector((theState: RootState) => theState.workflowStateReducer);
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch();

  const saveAndValidate = () => {
    fetchPutJson(`projects/${workflowData.data.projectId}/workflows/${workflowData.data.id}/tools/${workflowData.selectedTool.id}`, token, workflowData.selectedTool)
      .then((res: TODO) => {
        if (res && !res.message && !res.error) {
          dispatch({ type: 'SET_TOOL_SELECTED', payload: res })
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `An error has occured: ${res.error ? res.error : res.message}` } })
        }
      });

    fetchPostResOrJson(`projects/${workflowData.data.projectId}/workflows/${workflowData.data.id}/tools/${workflowData.selectedTool.id}/validate-advanced`, token, workflowData.selectedTool)
      .then(async (res: TODO) => {
        if (res && !res.message && !res.error) {
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Definition is valid' } })
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `An error has occured: ${res.error ? res.error : res.message}` } })
        }
      });
  }

  return (
    <Fragment>
      <div className="d-flex align-items-center workflow-tool-header h-48 border-bottom">
        <div className="d-flex align-items-center gap-sm">
          <h6 className="mb-0 strong">Advanced tool</h6>
          <Tooltip anchorElement="target" position="top">
            <button type='button' className='btn' title='Save and validate' onClick={saveAndValidate}>
              <Icon type='validate' className='pe-none' />
            </button>
          </Tooltip>
        </div>
      </div>
      <div className="d-flex flex-column h-100" >
        <EditTabContent handleEditTool={handleEditTool} />
      </div>
    </Fragment>
  )
}