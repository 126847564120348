import React, { useEffect, useRef, useState } from 'react';
import { Button, DropDownButton } from '@progress/kendo-react-buttons';
import { Editor, EditorTools, EditorUtils, ProseMirror } from "@progress/kendo-react-editor"

import { v4 as uuid } from 'uuid';
import { InsertLinkTool } from '../helpers';
import { ImageUploadModal } from '../../../SurveyDesignTabContent/helpers/ImageUploadModal/ImageUploadModal';

const { Bold, Italic, Underline,
  AlignLeft, AlignRight, AlignCenter, AlignJustify,
  Indent, Outdent,
  OrderedList, UnorderedList,
  ForeColor, Undo, Redo, FontSize, FontName, FormatBlock } = EditorTools;

export default ({ variables, token, content, onBodyChange }) => {
  const [state, setState] = useState({ view: undefined })
  const [imageUploadModal, setImageUploadModal] = useState({ show: false, ref: null })
  const [imageSelected, setImageSelected] = useState({ selected: false, attributes: null })

  const editorRef = useRef()

  useEffect(() => {
    if (state.view && state.view.lastSelectedViewDesc && state.view.lastSelectedViewDesc.node.type.name === "image") {
      setImageSelected({ selected: true, attributes: state.view.lastSelectedViewDesc.node.attrs })
    } else {
      setImageSelected({ selected: false, attributes: null })
    }
  }, [state])

  const createInsertVariableTool = settings =>
    props => {
      const { view } = props;
      const nodeType = view && view.state.schema.nodes[settings.nodeType];
      const canInsert = view && EditorUtils.canInsert(view.state, nodeType);

      return (
        <DropDownButton
          onItemClick={(props) => EditorUtils.insertNode(view, view.state.schema.text(`{${props.item}}`))}
          items={variables.filter((value, index) => variables.indexOf(value) === index).filter(el => !(el.includes('id') || el.includes('Id')))}
          icon="css"
          buttonClass="btn btn-success"
          disabled={!canInsert || variables.length === 0}
          onMouseDown={e => e.preventDefault()}
          onPointerDown={e => e.preventDefault()}
          {...settings.props}
        />
      )
    }

  const selectionToolsPlugin = () => {
    let plugin = new ProseMirror.Plugin({
      key: new ProseMirror.PluginKey("selection-tools"),
      view: () => ({
        update: (view, _prevState) => {
          setState({ ...state, view: view });
        }
      })
    })
    return plugin
  }

  const createInsertImageTool = settings =>
    props => {
      return (
        <Button
          icon="image"
          className={`${imageSelected.selected ? "k-state-selected" : "border-none"}`}
          onMouseDown={e => { setImageUploadModal({ show: true, ref: editorRef.current }) }}
          {...settings.props}
        />
      )
    }

  const InsertVariableTool = createInsertVariableTool({
    nodeType: 'text',
    text: '',
    props: {
      title: 'Insert variable'
    }
  })

  const InsertImageTool = createInsertImageTool({
    nodeType: 'text',
    text: '',
    props: {
      title: 'Insert image'
    }
  })

  const onImageUpload = (settings) => {
    let id = uuid()
    const data = `${EditorUtils.getHtml(imageUploadModal.ref.view.state)} <img src="${settings.url}"  ${settings.width !== null && settings.width !== 0 && `width="${settings.width}"`} ${settings.height !== null && settings.height !== 0 && `height="${settings.height}"`} alt="img" id=${id} />`
    EditorUtils.setHtml(imageUploadModal.ref.view, data)
    setImageUploadModal({ show: false, ref: null })
  }

  const onImageEdit = (settings) => {
    let html = EditorUtils.getHtml(imageUploadModal.ref.view.state)
    let doc = new DOMParser().parseFromString(html, "text/html")
    doc.getElementById(imageSelected.attributes.id).src = settings.url
    if (settings.height !== null && settings.height !== 0) {
      doc.getElementById(imageSelected.attributes.id).height = settings.height
    }
    if (settings.width !== null && settings.width !== 0) {
      doc.getElementById(imageSelected.attributes.id).width = settings.width
    }
    const data = doc.body.innerHTML
    EditorUtils.setHtml(imageUploadModal.ref.view, data)
    setImageUploadModal({ show: false, ref: null })
    setImageSelected({ selected: false, attributes: null })
  }

  const onMount = (event) => {
    const state = event.viewProps.state
    const plugins = [...state.plugins, selectionToolsPlugin()]
    return new ProseMirror.EditorView(
      { mount: event.dom },
      {
        ...event.viewProps,
        state: ProseMirror.EditorState.create({ schema: state.schema, doc: state.doc, plugins }),
      }
    )
  }


  return (
    <React.Fragment>
      {imageUploadModal.show &&
        <ImageUploadModal
          handleClose={() => setImageUploadModal({ show: false, ref: null })}
          onImageUpload={onImageUpload}
          onImageEdit={onImageEdit}
          image={imageSelected.attributes}
          token={token}
        />
      }
      <Editor
        tools={[
          [InsertLinkTool], [InsertVariableTool],
          [InsertImageTool],
          [Undo, Redo],
          [Bold, Italic, Underline],
          ForeColor,
          [AlignLeft, AlignCenter, AlignRight, AlignJustify],
          [Indent, Outdent],
          [OrderedList, UnorderedList],
          FontSize, FontName, FormatBlock,
        ]
        }
        contentStyle={{ overflow: "hidden" }}
        onMount={(e) => onMount(e)}
        defaultContent={content}
        onChange={(e) => onBodyChange(e.html)}
        className="h-100 d-flex flex-column"
        style={{ overflow: "auto" }}
        ref={editorRef}
      />
    </React.Fragment>
  )
}
