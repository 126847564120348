import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';

import { Icon } from '../Icon/Icon';
import type { RootState } from '../../../store/reducers/rootReducer';
import { FullHeightSpinner } from '../FullHeightSpinner/FullHeightSpinner';
import { InboxDrawerCustomItem } from './InboxDrawerCustomItem/InboxDrawerCustomItem';
import { fetchDeleteJson as clearAllInboxItems, fetchGetJson as getInboxItems, fetchPutResOrJson as markInboxItemsAsRead } from '../../../services/services';

export type InboxItem = {
  id: string;
  type: string;
  name: string;
  link: string;
  title: string;
  status: string;
  isRead: boolean;
  message: string;
  createdAt: string;
  updatedAt: string;
  updatedUtc: string;
  removeItem: boolean;
  description: string;
  completedStatus: string;
}

export const InboxDrawer = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { notification } = useSelector((state: RootState) => state.inboxNotificationStateReducer);
  const [expanded, setExpanded] = useState(false);
  const [inboxData, setInboxData] = useState<InboxItem[] | null>(null);
  const [shouldFetchInboxData, setShouldFetchInboxData] = useState(true);

  useEffect(() => {
    if (notification.status === "Completed") {
      setShouldFetchInboxData(true)
    }
  }, [notification])

  const handleClick = () => {
    if (expanded) {
      setInboxData([])
      if (inboxData !== null && inboxData?.length > 1) {
        const unReadElement = inboxData.find((item) => item.isRead === false)
        if (unReadElement) {
          markInboxItemsAsRead("inboxes/mark-item-read", token)
            .then((res: TODO) => {
              if (res.error || res.message) {
                dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error || res.message } })
              }
            })
        }
      }
    }
    else {
      getInboxItems("inboxes", token)
        .then((res: TODO) => {
          if (res && !res.error && !res.message) {
            setInboxData(res)
          } else {
            if (res.error || res.message) {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error || res.message } })
            }
          }
        })
    }
    setExpanded(prevState => !prevState)
  };

  useEffect(() => {
    if (token && shouldFetchInboxData) {
      setShouldFetchInboxData(false)
      getInboxItems("inboxes", token)
        .then((res: TODO) => {
          if (res && !res.error && !res.message) {
            setInboxData(res)
          } else {
            if (res.error || res.message) {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error || res.message } })
            }
          }
        })
    }
  }, [token, shouldFetchInboxData, dispatch])

  const onHeaderDropdownItemClick = () => {
    clearAllInboxItems("inboxes/items", token)
      .then((res: TODO) => {
        if (!res.error && !res.message) {
          setShouldFetchInboxData(false)
          setInboxData([])
          setExpanded(false)
        }
      })
  }

  return (
    <div className='d-flex flex-column h-100 inbox-content'>
      <div className="inbox-header px-5 pt-5 pb-3">
        <h5 className='m-0 stronger d-flex align-items-center h-24'>Activity</h5>
        <Tooltip anchorElement="target" position="bottom">
          <button
            type='button'
            disabled={inboxData?.filter((item) => item.status === 'Completed').length === 0}
            className="btn btn-transparent p-1" title='Clear all activities'
            onClick={onHeaderDropdownItemClick}
          >
            <Icon type='clear-activities' className='pe-none' />
          </button>
        </Tooltip>
      </div>
      {
        inboxData !== null ?
          inboxData.length > 0 ?
            <div className="inbox-drawer overflow-auto">
              {
                inboxData.map((item) => (
                  <Fragment key={item.id}>
                    <InboxDrawerCustomItem
                      item={item}
                      token={token}
                      inboxData={inboxData}
                      notification={notification}
                      handleClick={handleClick}
                      setInboxData={(data) => setInboxData(data)} />
                  </Fragment>
                ))
              }
            </div >
            :
            <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1 inbox-delay">
              <div className='inner'>
                <div className='icon d-flex align-items-center justify-content-center'>
                  <Icon type="checkmark" className='inbox-icon' />
                </div>
                <div className='circle' />
                <div className='circle' />
                <div className='circle' />
                <div className='circle' />
              </div >
              <h5 className='mt-4 mb-1 stronger'>You're all caught up</h5>
              <p className="text-muted m-0">There are no new activities</p>
            </div>
          :
          <FullHeightSpinner />
      }
    </div >
  );
};
