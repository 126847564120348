export const returnMultiSelectionData = (data: any, checkedItems: any, shouldDefineRange: boolean, itemId: string, updateCheckedItems: (data: any) => void, selectedName: string) => {
  let updatedCheckedItems = [...checkedItems]
  const updatedItemsData = JSON.parse(JSON.stringify(data))

  if (updatedCheckedItems.find(el => el === itemId)) {
    updatedCheckedItems.splice(updatedCheckedItems.indexOf(itemId), 1)
    updateCheckedItems(updatedCheckedItems)
  } else if (shouldDefineRange) {
    if (updatedCheckedItems.length > 0) {
      const updatedData = JSON.parse(JSON.stringify(data))
      const selectedItemIndex = updatedData.findIndex((el: { id: string }) => el.id === itemId)
      const lastCheckedIndex = updatedData.findIndex((el: { id: string }) => el.id === updatedCheckedItems[updatedCheckedItems.length - 1])
      let selectedItems = []
      if (selectedItemIndex > lastCheckedIndex) {
        selectedItems = updatedItemsData.filter((_el: { id: string }, key: number) => key >= lastCheckedIndex + 1 && key <= selectedItemIndex)
      } else {
        selectedItems = updatedItemsData.filter((_el: { id: string }, key: number) => key >= selectedItemIndex && key <= lastCheckedIndex - 1)
      }
      updatedCheckedItems = [...updatedCheckedItems].concat(selectedItems.map((el: { id: string }) => el.id))
      updateCheckedItems(updatedCheckedItems)
    } else {
      updatedCheckedItems = [...updatedCheckedItems, itemId]
      updateCheckedItems(updatedCheckedItems)
    }
  } else {
    updatedCheckedItems = [...updatedCheckedItems, itemId]
    updateCheckedItems(updatedCheckedItems)
  }

  updatedItemsData.forEach((el: any) => {
    if (updatedCheckedItems.includes(el.id)) {
      el[selectedName] = true
    } else {
      el[selectedName] = false
    }
  })

  return updatedItemsData
}