import { useEffect, useState } from 'react';
import Editor from '@monaco-editor/react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { Icon } from '../../../../../shared/Icon/Icon';
import type { RootState } from '../../../../../../store/reducers/rootReducer';
import { fetchPatchJson as updateXmlScript, fetchGetJson as getXml } from '../../../../../../services/services';

interface Props {
  onClose: () => void,
  onUpdateXmlScript: (data: TODO) => void,
  projectId: string,
  surveyId: string,
  elementId: string
}

export const XmlScriptingModal = ({ onClose, onUpdateXmlScript, projectId, surveyId, elementId }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [xmlScript, setXmlScript] = useState({ xml: '', etag: '' });
  const [editorTheme, setEditorTheme] = useState<"vs-dark" | "vs-light">("vs-dark");
  const [isXmlChanged, setIsXmlChanged] = useState(false);
  const dispatch = useDispatch()

  useEffect(() => {
    getXml(`su/projects/${projectId}/surveys/${surveyId}/elements/${elementId}/xml`, token)
      .then((res: TODO) => {
        if (res.error || res.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.message } })
        } else {
          setXmlScript({
            xml: res.xml,
            etag: res._etag
          })
        }
      })
  }, [])

  const changeTheme = (event: TODO) => {
    event.preventDefault();
    if (editorTheme === "vs-light") {
      setEditorTheme("vs-dark");
    } else {
      setEditorTheme("vs-light");
    }
  }

  const handleEditorChange = (value: TODO) => {
    if (!isXmlChanged) {
      setIsXmlChanged(true)
    }
    setXmlScript({
      ...xmlScript,
      xml: value
    });
  }

  const saveXML = (shouldCloseModal: boolean) => {
    setIsXmlChanged(false);
    const body = {
      _etag: xmlScript.etag,
      surveyId: surveyId,
      elementId: elementId,
      xml: xmlScript.xml
    }
    updateXmlScript(`su/projects/${projectId}/surveys/${surveyId}/elements/${elementId}/xml`, token, body)
      .then((response: TODO) => response.json())
      .then((res: TODO) => {
        setIsXmlChanged(true);
        if (res.error || res.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error || res.message } })
        } else {
          setXmlScript({ ...xmlScript, etag: res.definition._etag })
          onUpdateXmlScript(res);
          if (shouldCloseModal) {
            onClose();
          }
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The XML script has been saved successfully' } })
        }
      })
  }

  const CustomTitleBar = () => {
    return (
      <div className='d-flex align-items-center'>
        <span className='stronger'>XML Scripting</span>
      </div>
    )
  }

  return (
    <Dialog
      onClose={onClose}
      title={<CustomTitleBar />}
      width={'90%'}
      height={'90%'}
      className="d-flex flex-column w-100">
      <div className="d-flex flex-column h-100" >
        <div className="card weight-card flex-grow-1">
          {xmlScript.xml &&
            <Editor
              theme={editorTheme}
              defaultLanguage="xml"
              value={xmlScript.xml}
              onChange={handleEditorChange}
            />
          }
        </div>
      </div>
      <DialogActionsBar>
        <div className='d-flex justify-content-between align-items-center w-100'>
          <button type='button' className="btn btn-transparent btn-icon icon-l" onClick={changeTheme}>
            <Icon type="editor-theme" className="pe-none mr-1" />
            Theme
          </button>
          <div className='d-flex'>
            <button type='button' className="k-button btn btn-secondary" onClick={onClose}>Cancel</button>
            <button
              type='button'
              className="k-button btn btn-primary text-white"
              disabled={!isXmlChanged}
              onClick={() => saveXML(false)}>Save
            </button>
            <button
              type='button'
              className="k-button btn btn-primary text-white"
              disabled={!isXmlChanged}
              onClick={() => saveXML(true)}>Save & Close
            </button>
          </div>
        </div>
      </DialogActionsBar>
    </Dialog>
  )
}