import { Tooltip } from "@progress/kendo-react-tooltip"
import { Icon } from "../../../../../../../shared/Icon/Icon"

type Props = {
  subquestionAttributesType: "n" | "m" | "f";
  isSingleMultiQuestion: boolean
  subQuesIndex: number
}

export const StatementsTableHeader = ({ subquestionAttributesType, isSingleMultiQuestion, subQuesIndex }: Props) => {
  return (
    <div className='d-flex w-100 pl-4 py-2 table-header'>
      <div className='d-flex align-items-center w-100'>
        <span>Text</span>
      </div>

      {
        (subquestionAttributesType === 'n' || subquestionAttributesType === 'm') &&
        <div className='code d-flex align-items-center justify-content-center'>
          <span>Code</span>
        </div>
      }

      <div className='px-2'>
        <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
          <span className='icon' title='Exclusive'>
            <Icon type="answer-layout-exclusive" />
          </span>
        </Tooltip>
      </div>

      <div className='px-2'>
        <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
          <span className='icon' title='Keep position'>
            <Icon type="answer-layout-keep-position" />
          </span>
        </Tooltip>
      </div>

      {
        !(subQuesIndex === 0 && subquestionAttributesType === 'f') &&
        <div className='px-2'>
          <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
            <span className='icon' title='Other'>
              <Icon type="answer-layout-other" />
            </span>
          </Tooltip>
        </div>
      }

      <div className={`px-2 border-right ${isSingleMultiQuestion ? "d-none" : ""}`}>
        <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
          <span className='icon' title='Filter'>
            <Icon type="filter" />
          </span>
        </Tooltip>
      </div>
    </div>
  )
}