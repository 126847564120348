import React from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { checkSelectedQuestionType, returnTableCases } from '../../../../shared/helpers/FilterBuilderHelpers/FilterBuilderHelpers';

const ResponsesTable = ({ filterData, selectedQuestionData, responseSelectionChange, selectedQuestion, headerSelectionChange, negateFilter, onNegateFilter, onSetResponseValues, responseValue, responseInterval, filterType }) => {
  const type = checkSelectedQuestionType(selectedQuestion, filterData)

  return (
    <Grid
      data={selectedQuestionData}
      selectedField="selected"
      onSelectionChange={responseSelectionChange}
      className={filterType === 'basic' || filterType === 'survey' ? 'filter-grid' : 'filter-question-grid'}
    >
      <Column
        field="selected"
        width="50px"
        headerCell={() => {
          return (
            <Checkbox checked={selectedQuestionData?.every(ques => ques.selected === true)} onChange={headerSelectionChange} />
          )
        }
        }
        footerCell={() => {
          return (
            <td >
              {type !== 'numeric' ?
                <Checkbox checked={negateFilter} onChange={() => onNegateFilter(negateFilter)} />
                :
                <Checkbox checked={negateFilter} onChange={() => onNegateFilter(negateFilter)} />
              }
            </td>
          )
        }
        }
      />
      {type === 'numeric' ?
        <Column title="Value / Interval of values" cell={(props) => {
          return (
            <td>
              <Form
                onSubmit={(dataItem) => onSetResponseValues(dataItem)}
                render={(formRenderProps) => (
                  <FormElement className="d-flex w-50 align-items-center" >
                    <Field name={'responseValue'} component={Input} defaultValue={responseValue} disabled={!props.dataItem.selected} className="form-control form-control-sm w-25 mr-2" />
                    <span className="mr-2">:</span>
                    <Field name={'responseInterval'} component={Input} defaultValue={responseInterval} disabled={!props.dataItem.selected} className="form-control form-control-sm w-25 mr-2" />
                    <div className="k-form-buttons mt-0">
                      <button type={'submit'} disabled={!formRenderProps.allowSubmit && !props.dataItem.selected} className={'btn btn-primary'}>
                        Add
                      </button>
                    </div>
                  </FormElement>
                )} />
            </td>
          )
        }} footerCell={() => {
          return (
            <td style={{ width: "200px" }}>
              Negate filter
            </td>
          )
        }
        } />
        : <Column title="Code" field="code" footerCell={() => {
          return (
            <td style={{ width: "200px" }}>
              Negate filter
            </td>
          )
        }
        } />

      }
      {/* {type !== 'numeric' && filterType !== 'survey' ?
        <Column title="Cases" width="150px" cell={(props) => {
          const responseCases = returnTableCases(selectedQuestion, filterData, props)

          return (
            <td>
              <span> {responseCases !== undefined ? responseCases : 0}</span>
            </td>
          )
        }} />
        : null} */}

      {/* {type !== 'numeric' && filterType !== 'survey' ?
        <Column title="Percentage" width="150px" cell={(props) => {
          const responseCases = returnTableCases(selectedQuestion, filterData, props)
          const percentage = ((100 * responseCases) / filterData.cases).toFixed(0)

          return (
            <td>
              <span> {responseCases !== undefined ? percentage : 0}%</span>
            </td>
          )
        }} />
        : null} */}
      {type !== 'numeric' ?
        <Column title="Text" cell={(props) => {
          return (
            <td>
              {props.dataItem.texts[filterData.defaultLanguage]}
            </td>
          )
        }} />
        :
        null
      }
    </Grid>
  )
};

export default ResponsesTable;