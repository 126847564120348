import React from 'react'
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'

interface IProps {
  onClose: () => void
  listNameInputValue: string
  setListNameInputValue: (e: any) => void
  onSaveList: (action: string) => void
}

export const SurveyDesignListNameModal: React.FC<IProps> = ({ onClose, listNameInputValue, setListNameInputValue, onSaveList }) => {
  return (
    <Dialog title="List name" width="24rem" height="auto" onClose={onClose}>
      <div className="d-flex justify-content-center align-items-center w-100 h-100 p-4">
        <input placeholder="Enter list name" className="form-control" value={listNameInputValue} onChange={(e) => setListNameInputValue(e.target.value)} />
      </div>
      <DialogActionsBar>
        <Button className="btn btn-secondary" onClick={onClose}>Cancel</Button>
        <Button className="btn btn-primary" onClick={() => onSaveList('rename')}>Save</Button>
      </DialogActionsBar>
    </Dialog>
  )
}
