export const returnAdminButtonText = (title: string) => {
  let actionText = ''

  if (title === "Users") {
    actionText = "Invite user"
  } else if (title === "Groups") {
    actionText = "Create group"
  } else if (title === "Email") {
    actionText = "Create Email Provider"
  } else if (title === "SMS") {
    actionText = "Create SMS Provider"
  } else if (title === "Sub Accounts") {
    actionText = "Create Sub Account"
  } else if (title === "API Keys") {
    actionText = "Create API Key"
  } else if (title === "Panel Providers") {
    actionText = "Create Panel Provider"
  }

  return actionText
}