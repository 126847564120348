import { Fragment } from 'react';
import { Input, Switch } from '@progress/kendo-react-inputs';

import type { EmailProvider, EmailProviderFormData } from '../../../../../../interfaces/emailProviderInterfaces/emailProviderInterfaces';
import { EmailProvidersSMTPSettingsPasswordField } from './EmailProvidersSMTPSettingsPasswordField/EmailProvidersSMTPSettingsPasswordField';

interface Props {
  emailProvider?: EmailProvider | null
  formData: EmailProviderFormData
  onChangeHandler: (e: TODO, name: string) => void
}

export const EmailProvidersSMTPSettings = ({ formData, emailProvider, onChangeHandler }: Props) => {
  return (
    <Fragment>
      <div className="mb-2">
        <Input
          required
          label={'SMTP Relay (smtp.test.com)'}
          value={formData.relay}
          onChange={(e) => onChangeHandler(e, 'relay')}
        />
      </div>
      <div className="mb-2">
        <Input
          required
          type="number"
          label={'SMTP Port (25)'}
          value={formData.port}
          onChange={(e) => onChangeHandler(e, 'port')}
        />
      </div>
      <div className="mb-2">
        <Input
          required
          label={'SMTP Username'}
          value={formData.username}
          onChange={(e) => onChangeHandler(e, 'username')} />
      </div>

      <EmailProvidersSMTPSettingsPasswordField
        formData={formData}
        emailProvider={emailProvider}
        onChangeHandler={onChangeHandler} />

      <div className="mb-2 mt-4">
        <span className="pr-3">Use SSL:</span>
        <Switch checked={formData.SSL} onChange={(e) => onChangeHandler(e, 'SSL')} size="small" />
      </div>
    </Fragment>
  )
}