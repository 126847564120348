import { useState } from 'react'
import { Dialog } from '@progress/kendo-react-dialogs'

import type { ViewAllModalProps } from '../../../../interfaces/gateKeeperInterfaces/gateKeeperInterfaces'
import { Icon } from '../../../shared/Icon/Icon'

export const ViewAllModal = ({ validationResponse, showViewAllModal }: ViewAllModalProps) => {
  const [searchValue, setSearchValue] = useState<string>("")
  const [filterItem, setFilterItem] = useState<{ filterBy: string }>({ filterBy: "" })

  const setViewAllModalTitle = (invalidEnries: number): string => {
    if (invalidEnries === 1) {
      return `${invalidEnries} invalid enrie`
    }
    return `${invalidEnries} invalid enries`
  }

  const handleFilterItem = (filterName: string, i: string) => {
    setFilterItem({ ...filterItem, [filterName]: i })
  }

  return (
    <Dialog width={"600px"} className='nonValidModal' title={setViewAllModalTitle(validationResponse.invalidRespondentCount)} onClose={() => showViewAllModal(false)}>
      <div className="d-flex flex-column w-100">
        <div className="search-container" >
          <input
            type="text"
            value={searchValue}
            className="form-control search-input mb-3"
            placeholder="Search ID"
            aria-label="Search"
            onChange={(e: TODO) => setSearchValue(e.target.value)}
          />
          <div>
            {filterItem.filterBy ?
              <button type='button' onClick={() => handleFilterItem("filterBy", "")} className="btn border mx-1 d-flex align-items-center">
                <Icon type="close" className="submenu" />
                <span >{filterItem.filterBy}</span>
              </button> :
              <div className="dropdown">
                <button type='button' className="btn border mx-1 d-flex align-items-center" id="dropdownMenuAction" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <Icon type="filter" className="submenu" />
                  <span>All types</span>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuAction">
                  <div className='d-flex flex-column'>
                    {["No Fingerprint", "Not Found", "Invalid Length"].map((item, index) => (
                      <div key={index} className='d-flex justify-content-center align-items-center' >
                        <button key={index} className="dropdown-item" type="button" onClick={() => handleFilterItem("filterBy", item)} >{item}</button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
        <div className="table-container border p-3 ">
          <table className='table-borderless m-0 p-0' >
            <thead>
              <tr className='pb-2'>
                <th className='text-left medium strong pb-1'>Walr ID</th>
                <th className='text-left medium strong pb-1'>Reason</th>
              </tr>
            </thead>
            <tbody>
              {validationResponse.invalidRespondents.filter(item => item.id.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())).length === 0 ||
                validationResponse.invalidRespondents.filter(item => filterItem.filterBy !== "" ? item.reason === filterItem.filterBy.replace(/\s/g, "") : item).length === 0
                ?
                <tr>
                  <td width={"100%"}>No data found</td>
                </tr> :
                validationResponse.invalidRespondents
                  .filter(item => filterItem.filterBy !== "" ? item.reason === filterItem.filterBy.replace(/\s/g, "") : item)
                  .filter(item => item.id.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
                  .map((item: { id: string, reason: string }, index: number) => (
                    <tr key={index} className='py-2'>
                      <td className='text-left py-1'><span><Icon type="info-icon" fill='red' /></span> {item.id}</td>
                      <td className='text-left py-1'>{item.reason.replace(/([a-z])([A-Z])/g, '$1 $2')}</td>
                    </tr>
                  ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </Dialog>
  )
}