import { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import {
  Chart,
  ChartArea,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import "hammerjs";

import { Icon } from '../../../shared/Icon/Icon';
import { QuotaTable } from '../../../shared/QuotaTable/QuotaTable';
import type { Quota } from '../../../../interfaces/surveyInterfaces/surveyIterfaces';
import { fieldworkDashboardData } from '../SurveyDesignTabContent/helpers/returnDataHelpers/returnDataHelpers';
import { FielworkDashboardReport } from './FielworkDashboardReport/FielworkDashboardReport';
import { SimpleSwitch } from '../../../shared/SimpleSwitch/SimpleSwitch';
import { ErrorMessage } from '../../../shared/ErrorMessage/ErrorMessage';
import type { RootState } from '../../../../store/reducers/rootReducer';
import { fetchGetJson as getData, fetchGetJson as getQuotas, fetchGetJson, fetchPostJson, fetchDeleteJson, fetchPatchJson, fetchGetPreviewDashboard, fetchPutJson } from '../../../../services/services';
import { FullHeightSpinner } from '../../../shared/FullHeightSpinner/FullHeightSpinner';

export const SurveyNewOverviewTabContent = () => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const history = useHistory();
  const dispatch = useDispatch();
  const isPreview: string = history.location.pathname.split("/")[1] === "public" ? "public" : "none";
  const shareLink = isPreview ? history.location.pathname.split("/")[3] : "none";
  const params: { name: string, survey: string } = useParams();
  const [didMount, setDidMount] = useState(false);
  const [dashboardData, setDashboardData] = useState<TODO>(null);
  const [quotas, setQuotas] = useState<Quota[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [completesOverTime, setCompletesOverTime] = useState<TODO>([]);
  const [legendVisibility, setLegendVisibility] = useState(true);
  const [dataFromSharedDashboard, setDataFromSharedDashboard] = useState<TODO>()
  const [shareInputValue, setShareInputValue] = useState("")
  const [password, setPassword] = useState("")
  const [openEditTotalNumberModal, setOpenEditTotalNumberModal] = useState(false)
  const [openEditTotalNumberModalInputValue, setopenEditTotalNumberModalInputValue] = useState("")
  const [dashboardIsProtected, setDashboardIsProtected] = useState<{ show: boolean, isProtected: boolean }>({ show: false, isProtected: false })
  const [errorMessage, setErrorMessage] = useState("")
  const [openReport, setOpenReport] = useState(false);
  const [fieldworkMetricsData, setFieldWorkMetricsData] = useState([
    { value: '', code: "incidenceRate", text: 'Incidence rate', icon: 'incidence-rate' },
    { value: '', code: "conversionRate", text: 'Conversion rate', icon: 'conversion-rate' },
    { value: '', code: "dropOutRate", text: 'Drop out rate', icon: 'drop-out' },
    { value: '', code: "medianInterviewLengthSeconds", text: 'Median length of interview', icon: 'interview-length' }
  ])
  const shareUrl = `http://${window.location.href.split("/")[2]}/public/dashboards/${dataFromSharedDashboard?.path}`
  const chartData = [{ name: "Active", code: "active" }, { name: "Screen out", code: "screenedOuts" }, { name: "Quota full", code: "quotaFulls" }, { name: "Drop out", code: "dropOuts" }, { name: "Quality fail", code: "qualityFails" }, { name: "Archived", code: "archived" }]

  useEffect(() => {
    if (isPreview !== "public" && !didMount) {
      fetchGetJson(`su/projects/${params.name}/surveys/${params.survey}/sharelink`, token)
        .then((res: TODO) => {
          if (res.error || res.message) {
            if (res.statusCode !== 404) {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
            }
          } else {
            setDataFromSharedDashboard(res)
          }
        })
    }
  }, [didMount, isPreview, params, token, dispatch])

  useEffect(() => {
    if (!didMount) {
      setDidMount(true)
      if (isPreview === "none") {
        if (params.name && params.survey) {
          setIsLoading(true);
          getQuotas(`projects/${params.name}/surveys/${params.survey}/quota-overview`, token)
            .then((res: TODO) => {
              if (res && !res.error && !res.message) {
                setQuotas(res.quotas)
                setIsLoading(false);
                // Set quotas and get overview data
                getData(`su/projects/${params.name}/surveys/${params.survey}/overview`, token)
                  .then((res: TODO) => {
                    if (res && (res.error || res.message)) {
                      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
                    } else {
                      setDashboardData(res)
                      setFieldWorkMetricsData(fieldworkMetricsData.map((el: TODO) => {
                        return {
                          ...el,
                          value: el.code.includes('Rate') ? Math.round(res[el.code] * 100) : res[el.code]
                        }
                      }))
                      if (Object.keys(res.completesOverTime).length > 0) {
                        if (Object.values(res.completesOverTime).length === 1) {
                          setCompletesOverTime([
                            {
                              date: new Date(Object.keys(res.completesOverTime)[0]).toLocaleDateString('default', { month: 'long', day: 'numeric' }),
                              value: Object.values(res.completesOverTime)[0]
                            },
                            {
                              date: "",
                              value: Object.values(res.completesOverTime)[0]
                            },
                          ])
                        } else {
                          setCompletesOverTime(fieldworkDashboardData(new Date(Object.keys(res.completesOverTime)[0]), new Date(Object.keys(res.completesOverTime)[Object.keys(res.completesOverTime).length - 1]), res.completesOverTime))
                        }
                      }
                    }
                  })
              } else {
                setIsLoading(false);
                dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
              }
            })
        }
      } else {
        fetchGetPreviewDashboard(shareLink)
          .then((res: TODO) => {
            if (res.statusCode === 403) {
              setDashboardIsProtected({ show: true, isProtected: true })
            } else if (res.error || res.message) {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
            } else {
              fetchGetPreviewDashboard(shareLink, null, true)
                .then((res: TODO) => {
                  if (res.error || res.message) {
                    dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
                  } else {
                    setQuotas(res.quotas)
                  }
                })
              setDashboardData(res)
              setFieldWorkMetricsData(fieldworkMetricsData.map((el: TODO) => {
                return {
                  ...el,
                  value: el.code.includes('Rate') ? Math.round(res[el.code] * 100) : res[el.code]
                }
              }))
              if (Object.keys(res.completesOverTime).length > 0) {
                if (Object.values(res.completesOverTime).length === 1) {
                  setCompletesOverTime([
                    {
                      date: new Date(Object.keys(res.completesOverTime)[0]).toLocaleDateString('default', { month: 'long', day: 'numeric' }),
                      value: Object.values(res.completesOverTime)[0]
                    },
                    {
                      date: "",
                      value: Object.values(res.completesOverTime)[0]
                    },
                  ])
                } else {
                  setCompletesOverTime(fieldworkDashboardData(new Date(Object.keys(res.completesOverTime)[0]), new Date(Object.keys(res.completesOverTime)[Object.keys(res.completesOverTime).length - 1]), res.completesOverTime))
                }
              }
            }
          })

      }
    }
  }, [didMount, isPreview, params, dispatch, token, shareLink, fieldworkMetricsData])

  const updateQuotaData = (dataItem: TODO) => {
    const quotaIndex = quotas.findIndex((el: TODO) => el.id === dataItem.id)
    const updatedQuota = { ...quotas[quotaIndex] }
    const updatedQuotas = [...quotas]

    if (isPreview === "none") {
      if (!dataItem.expanded) {
        updatedQuota.expanded = true;
        fetchGetJson(`projects/${params.name}/surveys/${params.survey}/quotas/${dataItem.id}/extended`, token)
          .then((res: TODO) => {
            if (res) {
              if (res.error || res.message) {
                dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
              } else {
                updatedQuota.cellOverview = res.cells
                updatedQuotas.splice(quotaIndex, 1, updatedQuota)
                setQuotas([...updatedQuotas])
              }
            }
          })
      } else {
        updatedQuota.expanded = false;
        updatedQuotas.splice(quotaIndex, 1, updatedQuota)
        setQuotas([...updatedQuotas])
      }

    } else {
      if (!dataItem.expanded) {
        updatedQuota.expanded = true;
        fetchGetPreviewDashboard(shareLink, dashboardIsProtected.isProtected ? password : null, true, true, dataItem.id)
          .then((res: TODO) => {
            if (res) {
              if (res.error || res.message) {
                dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
              } else {
                updatedQuota.cellOverview = res.cells
                updatedQuotas.splice(quotaIndex, 1, updatedQuota)
                setQuotas([...updatedQuotas])
              }
            }
          })
      } else {
        updatedQuota.expanded = false;
        updatedQuotas.splice(quotaIndex, 1, updatedQuota)
        setQuotas([...updatedQuotas])
      }

    }

  };

  const makeLinkPublic = () => {
    if (dataFromSharedDashboard?.path) {
      fetchDeleteJson(`su/projects/${params.name}/surveys/${params.survey}/sharelink`, token)
        .then((res: TODO) => {
          if (res.error || res.message) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
          } else {
            setDataFromSharedDashboard({})
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Dashboard unpublished successfully' } })
          }
        })
    } else {
      if (shareInputValue.length > 0) {
        const body = { protected: true, password: shareInputValue }
        fetchPostJson(`su/projects/${params.name}/surveys/${params.survey}/sharelink`, token, body)
          .then((res: TODO) => {
            if (res.error || res.message) {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
            } else {
              setDataFromSharedDashboard(res)
              dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Dashboard published successfully' } })
            }
          })
      } else {
        fetchPostJson(`su/projects/${params.name}/surveys/${params.survey}/sharelink`, token, {})
          .then((res: TODO) => {
            if (res.error || res.message) {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
            } else {
              setDataFromSharedDashboard(res)
              dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Dashboard published successfully' } })
            }
          })
      }
    }
  };

  const hasWhiteSpace = (s: string) => {
    return s.indexOf(' ') >= 0;
  }

  const addPasswordToLink = () => {
    if (dataFromSharedDashboard?.path) {
      if (shareInputValue.length > 0) {
        const isThereWhiteSpace = hasWhiteSpace(shareInputValue)
        if (isThereWhiteSpace === false) {
          const body = { protected: true, password: shareInputValue, _etag: dataFromSharedDashboard._etag }
          fetchPatchJson(`su/projects/${params.name}/surveys/${params.survey}/sharelink`, token, body)
            .then((res: TODO) => res.json())
            .then((data: TODO) => {
              if (data.error || data.message) {
                dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: data.error ? data.error : data.message } })
              } else {
                dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Password has been successfully set' } })
                setDataFromSharedDashboard(data)
              }
            })
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "Password can't contain spaces" } })
        }
      } else {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "Enter a password in the input field" } })
      }
    } else {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "Publish the dashboard before you set a password" } })
    }
  }

  const removePasswordFromLink = () => {
    const body = { protected: false, _etag: dataFromSharedDashboard._etag }
    fetchPatchJson(`su/projects/${params.name}/surveys/${params.survey}/sharelink`, token, body)
      .then((res: TODO) => res.json())
      .then((data: TODO) => {
        if (data.error || data.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: data.error ? data.error : data.message } })
        } else {
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Password removed successfully' } })
          setDataFromSharedDashboard(data)
        }
      })
  }

  const sendPassword = () => {
    fetchGetPreviewDashboard(shareLink, password)
      .then((res: TODO) => {
        if (res.error || res.message) {
          if (res.statusCode === 401) {
            setErrorMessage("Wrong password")
          } else {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
            setErrorMessage(res.error ? res.error : res.message)
          }
        } else {
          fetchGetPreviewDashboard(shareLink, password, true)
            .then((res: TODO) => {
              if (res.error || res.message) {
                setErrorMessage(res.error ? res.error : res.message)
              } else {
                setQuotas(res.quotas)
              }
            })
          setDashboardData(res)
          setDashboardIsProtected({ show: false, isProtected: true })
          setFieldWorkMetricsData(fieldworkMetricsData.map((el: TODO) => {
            return {
              ...el,
              value: el.code.includes('Rate') ? Math.round(res[el.code] * 100) : res[el.code]
            }
          }))
          if (Object.keys(res.completesOverTime).length > 0) {
            if (Object.values(res.completesOverTime).length === 1) {
              setCompletesOverTime([
                {
                  date: new Date(Object.keys(res.completesOverTime)[0]).toLocaleDateString('default', { month: 'long', day: 'numeric' }),
                  value: Object.values(res.completesOverTime)[0]
                },
                {
                  date: "",
                  value: Object.values(res.completesOverTime)[0]
                },
              ])
            } else {
              setCompletesOverTime(fieldworkDashboardData(new Date(Object.keys(res.completesOverTime)[0]), new Date(Object.keys(res.completesOverTime)[Object.keys(res.completesOverTime).length - 1]), res.completesOverTime))
            }
          }
        }
      })
  }

  const saveEditTotalNumberModal = () => {
    if (openEditTotalNumberModalInputValue.match(/^\d+$/g)) {
      const body = { completesTarget: openEditTotalNumberModalInputValue }
      fetchPutJson(`su/projects/${params.name}/surveys/${params.survey}/completestarget`, token, body)
        .then((res: TODO) => {
          if (res.error || res.message) {
            setErrorMessage(res.error ? res.error : res.message);
          } else {
            setOpenEditTotalNumberModal(false);
            setopenEditTotalNumberModalInputValue("");
            setDidMount(false);
          }
        })

    } else {
      setErrorMessage("Please enter valid numbers");
    }
  };

  const handleEditTotalNumberModalClose = () => {
    setOpenEditTotalNumberModal(false);
    setopenEditTotalNumberModalInputValue('');
  }

  return (
    <Fragment>
      {dashboardIsProtected.show &&
        <Dialog title="Enter password" onClose={() => setDashboardIsProtected({ show: false, isProtected: true })} width="400px">
          <input placeholder="Password" type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
          {errorMessage && <ErrorMessage type="alert" onHide={() => setErrorMessage("")} errorMessage={errorMessage} />}
          <DialogActionsBar>
            <button type='button' className="k-button btn btn-secondary" onClick={() => setDashboardIsProtected({ show: false, isProtected: true })}>Cancel</button>
            <button type='button' className="k-button btn btn-primary" onClick={sendPassword}>Submit</button>
          </DialogActionsBar>
        </Dialog>}
      {
        openEditTotalNumberModal &&
        <Dialog title="Edit total number" width="30rem" height="auto" onClose={handleEditTotalNumberModalClose}>
          <div className="d-flex justify-content-center align-items-center w-100 h-100 p-4">
            <input
              placeholder="Enter number"
              type="number"
              className="form-control no-arrows-on-input"
              value={openEditTotalNumberModalInputValue}
              onChange={(e) => setopenEditTotalNumberModalInputValue(e.target.value)}
              onKeyDown={e => {
                if (e.key === "." || e.key === "-" || e.key === "e") {
                  e.preventDefault();
                }
              }}
            />
          </div>
          {errorMessage && <ErrorMessage type="alert" onHide={() => setErrorMessage("")} errorMessage={errorMessage} />}
          <DialogActionsBar>
            <button type='button' className="btn btn-secondary mr-2" onClick={handleEditTotalNumberModalClose}>Cancel</button>
            <button type='button' className="btn btn-primary" onClick={saveEditTotalNumberModal}>Save</button>
          </DialogActionsBar>
        </Dialog>
      }
      <div className={isPreview === "public" ? "container-fluid fieldwork-container p-4 shared" : "container-fluid fieldwork-container p-4"}>
        {openReport && <FielworkDashboardReport closeReport={() => setOpenReport(false)} dashboardData={dashboardData} />
        }
        <div className='px-5 pt-5 pb-1 mb-4 graph card-body d-flex flex-column answer-layout mb-4' style={{ backgroundColor: "rgba(22, 33, 48)" }}>
          <div className='d-flex justify-content-between align-items-center w-100'>

            <div className='d-flex align-items-center w-100 h-32 justify-content-between'>
              <div className='d-flex align-items-center justify-content-between top-section'>
                <span className='text-white m-0 fieldwork-dashboard-title'>Fieldwork progress</span>

              </div>
              <div className="d-flex flex-row justify-content-end align-items-center">
                {dashboardData && Object.values(dashboardData.completesOverTime).length > 0 ?
                  Object.keys(dashboardData.completesOverTime).length > 1 ? <div className='d-flex justify-content-end date'><span className='text-white text-nowrap'>{new Date(Object.keys(dashboardData.completesOverTime)[0]).toLocaleDateString('default', { month: 'short', day: 'numeric', year: 'numeric' })}<span className='mx-1'>-</span></span><span className='text-white text-nowrap mr-3'>{new Date(Object.keys(dashboardData.completesOverTime)[Object.keys(dashboardData.completesOverTime).length - 1]).toLocaleDateString('default', { month: 'short', day: 'numeric', year: 'numeric' })}</span></div> : <span className='text-white mr-3'>{new Date(Object.keys(dashboardData.completesOverTime)[0]).toLocaleDateString('default', { month: 'long', day: 'numeric', year: 'numeric' })}</span>
                  :
                  null
                }
                {dashboardData && <button type='button' className="btn btn-fieldwork text-nowrap" onClick={() => setOpenReport(true)}>
                  View report
                </button>}
                {isPreview !== "public" &&
                  <div className="d-flex flex-row">
                    {dashboardData &&
                      <div className="d-flex dropdown" auto-close="disabled">
                        <button type="button" className="btn btn-fieldwork text-nowrap ml-3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Share
                        </button>
                        <div className="dropdown-menu dropdown-menu-right fieldwork-dashboard-share p-0" >
                          <div className="border-bottom stronger title">Share dashboard</div>
                          <div className="d-flex justify-content-between align-items-center border-bottom p-3">
                            <div className="d-flex align-items-center">
                              <Icon type="globe-major" className="mr-2" />
                              <span>Publish to the web</span>
                            </div>
                            <SimpleSwitch
                              checked={!!dataFromSharedDashboard?.path}
                              onChange={() => makeLinkPublic()}
                            />
                          </div>
                          <div className="d-flex flex-column">
                            {dataFromSharedDashboard?.path &&
                              <div className="d-flex align-items-center justify-content-between p-3 border-bottom">
                                <div className="d-flex align-items-center">
                                  <Icon type="link" className="mr-2" />
                                  <a target="_blank" rel="noopener noreferrer" className="link" href={dataFromSharedDashboard?.path ? shareUrl : ""}>{dataFromSharedDashboard?.path ? shareUrl : ""}</a>
                                </div>
                                <Tooltip anchorElement="target" position="top">
                                  <div title='Copy link' style={{ cursor: "pointer" }} onClick={() => { dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "Dashboard link copied to clipboard" } }); navigator.clipboard.writeText(dataFromSharedDashboard && dataFromSharedDashboard.path ? shareUrl : "") }}><Icon className='pe-none' type="copy"></Icon></div>
                                </Tooltip>
                              </div>}
                            {dataFromSharedDashboard?.protected ?
                              <div className="d-flex flex-column p-4">
                                <div className="strong">Restrict access</div>
                                <span className="w-100 p-2 mt-2 d-flex justify-content-center align-items-center" style={{ backgroundColor: "#3365DE33" }}><Icon type="lock"></Icon> Password has been set</span>
                                <div className="btn d-flex justify-content-center btn-shadow mt-3" onClick={removePasswordFromLink}>
                                  Remove password
                                </div>
                              </div>
                              :
                              <div className="d-flex flex-column p-4">
                                <div className="strong">Restrict access</div>
                                <input name="Enter password" type="password" className="sidebar-input form-control mt-2" value={shareInputValue} onChange={(e) => setShareInputValue(e.target.value)} placeholder="Enter password" />
                                {<div className="btn d-flex justify-content-center btn-primary mt-3" onClick={addPasswordToLink}>Set password</div>}
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
          </div>
          <div style={{ backgroundColor: "rgba(31, 43, 56)" }} className='p-5 position-relative rounded mt-4'>
            <p className='m-0' style={{ fontSize: "14px", color: "#d2d5d7" }} >Completes over time</p>
            {dashboardData &&
              <div className="d-flex align-items-start">
                <p className='text-white strong' style={{ fontSize: "24px" }}>{Object.values(dashboardData.completesOverTime).reduce((a: TODO, b: TODO) => a + b, 0)} / {dashboardData && dashboardData.targetValue !== 0 ? dashboardData.targetValue : ""} {dashboardData && (dashboardData.targetValue !== null && dashboardData.targetValue !== 0) ? <span style={{ color: "rgba(142,149,156,255)" }}>{`(${(((dashboardData && Object.values(dashboardData.completesOverTime).reduce((a: TODO, b: TODO) => a + b, 0)) / dashboardData.targetValue) * 100).toFixed(0)}%) `}</span> : <span>∞</span>}
                </p>
                {
                  isPreview !== "public" &&
                  <Tooltip anchorElement="target" position="top">
                    <button type='button' title='Edit total number' className='btn btn-fieldwork border-0 p-05 mt-1 ml-2' onClick={() => setOpenEditTotalNumberModal(true)}>
                      <Icon fill="white" className='pe-none' type="dashboard-edit" />
                    </button>
                  </Tooltip>
                }
              </div>
            }
            {!dashboardData && <div className="spinner-border spinner-border-sm mr-2 position-absolute text-white" role="status" style={{ top: "50%", left: "50%", zIndex: 50000 }}>
              <span className="sr-only">Loading...</span>
            </div>}
            {dashboardData && Object.values(dashboardData.completesOverTime).length === 0 ? <p className='position-absolute' style={{ top: "50%", left: "50%", transform: "translate(-50%,-50%)", zIndex: 1, fontSize: "20px", color: "#8e959c" }}>No available data</p> : null}
            <Chart transitions={false} style={{ height: '250px' }} onLegendItemClick={() => setLegendVisibility(true)}>
              <ChartArea background='transparent' height={250} />
              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  categories={completesOverTime ? completesOverTime.map((el: TODO) => { return el.date }) : []}
                  labels={{ color: "#d2d5d7", margin: 10 }}
                />
              </ChartCategoryAxis>
              <ChartValueAxis>
                <ChartValueAxisItem visible={!!(dashboardData && Object.values(dashboardData.completesOverTime).length > 0)} labels={{ color: "#e2e4e5", step: 2 }} majorGridLines={{ color: "#283641", step: 2 }} />
              </ChartValueAxis>
              <ChartSeries>
                <ChartSeriesItem
                  type="area"
                  name='Number of completes'
                  data={completesOverTime ? completesOverTime.map((el: TODO) => { return el.value }) : ["3"]}
                  markers={{ visible: false }}
                  line={{ style: "step", width: 2, color: "#3365DE" }}
                  color={"rgba(51, 101, 222, 0.2)"}
                  visible={legendVisibility}
                />
              </ChartSeries>
              <ChartLegend visible={!!(dashboardData && Object.values(dashboardData.completesOverTime).length > 0)} position={"bottom"} orientation={"horizontal"} labels={{ color: "#e2e4e5" }} />
            </Chart>
          </div>
          <div className='d-flex mt-4 fieldwork-dashboard-stats row'>
            {chartData.map((el: TODO, key: TODO) => {
              return (
                <div className="col-lg-2 col-6 w-100" key={key}>
                  <div style={{ backgroundColor: "rgba(31, 43, 56)" }} className='px-4 py-3 mb-4 rounded w-100'>
                    <span style={{ color: "rgba(255, 255, 255, 0.6)" }} className='m-0'>{el.name}</span>
                    <p className='text-white mb-0' style={{ fontSize: "16px" }}>{dashboardData?.[el.code]}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <section className='card-body d-flex flex-column bg-white answer-layout p-5 mb-4'>
          <h6 className='stronger mb-4'>Fieldwork metrics</h6>
          <div className='d-flex fieldwork-dashboard-stats row'>
            {fieldworkMetricsData.map((el: TODO, key: TODO) => {
              return (
                <div className='col-lg col-12 w-100 mb-2' key={key}>
                  <div className='d-flex align-items-center border rounded w-100 p-3'>
                    <div className='p-2 rounded mr-3' style={{ backgroundColor: "rgba(23, 33, 71, 0.05)" }}>
                      <Icon type={el.icon} />
                    </div>
                    <div className='w-100'>
                      <div className='d-flex justify-content-between mb-1'>
                        <span className='m-0 strong'>{el.text}</span>
                        {el.code === "medianInterviewLengthSeconds" ?
                          <p className='m-0 strong'>{(dashboardData && Object.values(dashboardData.completesOverTime).length > 0) ? dashboardData && dashboardData.medianInterviewLengthSeconds > 60 ? `${Math.floor(dashboardData.medianInterviewLengthSeconds / 60)}' ${dashboardData.medianInterviewLengthSeconds - Math.floor(dashboardData.medianInterviewLengthSeconds / 60) * 60}` : `${dashboardData?.medianInterviewLengthSeconds} sec` : "-"}</p>
                          :
                          el.code === "conversionRate" ?
                            <p className='m-0 strong'>{(dashboardData && Object.values(dashboardData.completesOverTime).length > 0) ? `${el.value}%` : "-"}</p>
                            :
                            <p className='m-0 strong'>{el.value}%</p>
                        }
                      </div>
                      {el.code !== "medianInterviewLengthSeconds" ?
                        <div style={{ display: "table", width: "100%" }}>
                          <div style={{ borderRadius: "8px", backgroundColor: "#edeef1" }}>
                            <div style={{ height: "4px", backgroundColor: "rgba(53, 136, 239)", borderRadius: "8px", width: `${el.value}%` }} />
                          </div>
                        </div>
                        :
                        null
                      }
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </section>

        <section className='card-body d-flex flex-column bg-white p-5 gap-lg'>
          <h6 className='stronger'>Quotas</h6>
          {isLoading &&
            <div className="d-flex justify-content-center h-100 w-100">
              <FullHeightSpinner />
            </div>
          }
          {quotas.length > 0 ?
            <div className='d-flex w-100'>
              <div className="accordion w-100" id="overviewQuota">
                {quotas.filter(quota => !quota.disabled).map((quota) =>
                  <div className="card" key={quota.id}>
                    <div className="p-4" id={quota.name}>
                      <button className="btn shadow-none w-100 d-flex align-items-center justify-content-between gap-md" type="button" data-toggle="collapse" data-target={`#collapseOne-${quota.id}`} aria-expanded="false" aria-controls={quota.id} onClick={() => updateQuotaData(quota)}>
                        <p className='m-0 text-left text-truncate' style={{ width: "33%" }}>
                          {quota.name}
                        </p>

                        <p className='m-0' style={{ width: "33%" }}>
                          {quota.cellCount} quota cells
                        </p>

                        <div className='d-flex justify-content-end' style={{ width: "33%" }}>
                          <Icon type='chevron' />
                        </div>

                      </button>
                    </div>

                    <div id={`collapseOne-${quota.id}`} className="collapse" aria-labelledby={quota.name} data-parent="#overviewQuota">
                      <div className="card-body border-top">
                        <QuotaTable quota={quota} calledFrom="overviewTab" />
                      </div>
                    </div>
                  </div>
                )}

              </div>
            </div>
            :
            <div className="d-flex justify-content-center h-100 w-100">
              No quotas
            </div>
          }
        </section>
      </div>
    </Fragment >
  )
}