import Editor from '@monaco-editor/react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../../../../../../../store/reducers/rootReducer';

export const SurveyCustomQuestionsOverviewCSSTabContent = () => {
  const dispatch = useDispatch()
  const { editorValues } = useSelector((theState: RootState) => theState.surveyCustomQuestionsStateReducer);

  return (
    <div className="d-flex flex-column h-100">
      <p className='mb-3'>External CSS references (one per line)</p>

      <textarea
        defaultValue={editorValues.references.css.join("\n")}
        onChange={(e) => dispatch({ type: 'UPDATE_EDITOR_VALUES', payload: { ...editorValues, references: { ...editorValues.references, css: e.target.value.split(/\n/) } } })}
        className="form-control mb-3"
        rows={5}>
      </textarea>
      <div className='custom-questions-overview-editor-container'>
        <Editor
          defaultValue={editorValues.css}
          theme='vs-dark'
          defaultLanguage='css'
          onChange={(value) => dispatch({ type: 'UPDATE_EDITOR_VALUES', payload: { ...editorValues, css: value } })}
        />
      </div>
    </div>
  )
}

