import React from 'react'
import { rsUrl } from '../../../services/services'

export default (props) => {
  return (
    <div className={`${props.className ? props.className : 'p-5 m-3'}`}>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <i className="fa fas fa-lock text-dark fa-4x mb-3" />
        <h4 className="text-dark card-title mb-4">
          Upgrade to do more
        </h4>
        <h5>Read all about the different features <a href={`${rsUrl}/v9/index.html#features`} target="_">here</a></h5>
      </div>
    </div>
  )
}