import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import type { RootState } from "../../../store/reducers/rootReducer";
import { fetchGetJson as getSMSProviders } from "../../../services/services";

export const useFetchSMSProviders = () => {
  const dispatch = useDispatch()
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { shouldUpdateSmsProviders } = useSelector((theState: RootState) => theState.adminReducer);
  const [didMount, setDidMount] = useState<boolean>(false);

  useEffect(() => {
    if (shouldUpdateSmsProviders || !didMount) {
      setDidMount(true)
      dispatch({ type: 'UPDATE_SMS_PROVIDERS', payload: false })
      getSMSProviders("smsproviders", token)
        .then((res: TODO) => {
          if (res && !res.message && !res.error) {
            dispatch({ type: 'SET_SMS_PROVIDERS', payload: res })
          } else {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.message || res.error } })
          }
        })
    }
  }, [didMount, dispatch, shouldUpdateSmsProviders, token])
}