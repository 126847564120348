import { useState, useEffect, type FormEvent, Fragment } from 'react';
import { Switch, Input } from '@progress/kendo-react-inputs';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import ExportOption from './ExportOption/ExportOption';
import type { RootState } from '../../../../../../../store/reducers/rootReducer';
import { ErrorMessage } from '../../../../../../shared/ErrorMessage/ErrorMessage';
import OverwriteFile from '../../../shared/helpers/overwriteFile/overwriteFile';
import { InProgressOverlay } from '../../../../../../shared/InProgressOverlay/InProgressOverlay';
import { updateCheckedFiles } from '../../../../../../../store/reducers/fileManagerStateReducer/fileManagerStateReducer';
import FileManager from '../../../../../../shared/FileManager/FileManager';

interface Props {
  onHide: () => void
  datasetName: string
  showExportModal: TODO
  options: {
    exportOptions: { exportType: string }[]
    values: unknown
    id?: string
  }
  onExportHandler: TODO
  state: {
    questions?: { disabled: boolean }[]
    firstColumn?: { disabled: boolean }[],
    scndColumn?: { disabled: boolean }[]
  }
  datasetId: string
  history: unknown
  tab: string
  token: string
  editorValue: string | null
  datasetType: string
  updatedLanguage?: unknown
  projectType?: string
  defaultLanguage?: unknown
  exportBody?: unknown
  projectId?: string;
}

export const ExportModal = ({ onHide, datasetName, showExportModal, options, onExportHandler, state, datasetId, history, tab, token, editorValue, datasetType, updatedLanguage, projectType, defaultLanguage, exportBody, projectId }: Props) => {
  const { exportState, isOverwriteModalShown } = useSelector((theState: RootState) => theState.exportStateReducer, shallowEqual);
  const [optionsValues, setOptionsValues] = useState<{ TemplateName?: string }>({})
  const [filteredState, setFilteredState] = useState<{ newQuestionOptions?: unknown }>({})

  const [showFileManager, setShowFileManager] = useState(false)
  const [formData, setFormData] = useState({ fileName: '', fileDescription: '', saveToProject: false })
  const exportOptions: TODO = options.exportOptions.filter(option => showExportModal.type.includes(option.exportType))
  const dispatch = useDispatch()

  useEffect(() => {
    if (tab !== 'analysisScripting') {
      const stateCopy = JSON.parse(JSON.stringify(state))
      if (stateCopy.questions) {
        stateCopy.questions = state?.questions?.filter(e => !e.disabled)
      }
      else {
        if (stateCopy.firstColumn) {
          stateCopy.firstColumn = state?.firstColumn?.filter(e => !e.disabled)
        }
        if (stateCopy.scndColumn) {
          stateCopy.scndColumn = state?.scndColumn?.filter(e => !e.disabled)
        }
      }
      setFilteredState(stateCopy)
    }
  }, [state, tab])

  const onChangeHandler = (e: TODO) => {
    setOptionsValues({
      ...optionsValues,
      [e.target.id ? e.target.id : e.target.props.id]: e.target.value ? e.target.value : e.value
    })
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const updateExportTemplate = (value: string) => {
    setOptionsValues({
      ...optionsValues,
      TemplateName: value
    })
  }

  const onSubmitHandler = (e: FormEvent) => {
    e.preventDefault()
    onExportHandler(showExportModal, filteredState, datasetId, history, tab, filteredState?.newQuestionOptions ? filteredState?.newQuestionOptions : {}, optionsValues, token, dispatch, datasetName, formData, false, editorValue, datasetType, onHide, updatedLanguage, projectType, defaultLanguage, exportBody, projectId)
  }

  return (
    <Dialog title={`Export table to ${exportOptions[0].exportType}`} onClose={onHide} className="import-dialog">
      <form onSubmit={onSubmitHandler}>
        {
          exportState.isExportInProgress &&
          <InProgressOverlay
            type="overlay"
            theme="primary" />
        }
        {
          showFileManager &&
          <FileManager displayType="modal" type={'internal'} onSelect={updateExportTemplate} onHide={() => {
            dispatch(updateCheckedFiles([]))
            setShowFileManager(false)
          }} token={token}
          />
        }
        {
          isOverwriteModalShown &&
          <OverwriteFile
            showExportModal={showExportModal}
            datasetId={datasetId}
            datasetName={datasetName}
            optionsValues={optionsValues}
            token={token}
            formData={formData}
            state={filteredState}
            onExportHandler={onExportHandler}
            history={history}
            tab={tab}
            handleClose={() => { dispatch({ type: 'DISPLAY_OVERWRITE_MODAL', payload: false }) }}
          />
        }
        <div className="p-4">
          <p className='m-0' style={{ fontWeight: '500' }}>Template:</p>
          <div className="input-group mb-3">
            <div className="custom-file">
              <input
                type="button"
                id="inputGroupFile02"
                className="custom-file-input"
                onClick={() => setShowFileManager(true)}
              />
              {
                optionsValues.TemplateName ?
                  <label className="custom-file-label" htmlFor="inputGroupFile02">{optionsValues.TemplateName}</label>
                  :
                  <label className="custom-file-label" htmlFor="inputGroupFile02">Select a template</label>
              }
            </div>
          </div>
          <div>
            <div className="mb-2 strong">Options:</div>
            {
              exportOptions?.[0] ?
                exportOptions[0].props.map((option: TODO, key: number) => (
                  <Fragment key={key}>
                    <ExportOption
                      option={option}
                      dropDownValues={options.values}
                      onChangeHandler={onChangeHandler} />
                  </Fragment>
                ))
                : null
            }
          </div>
          <hr />
          <div className="d-flex align-items-center justify-content-between my-3">
            <p className="m-0 pr-5" style={{ fontWeight: '500' }}>Save export to project</p>
            <Switch
              onChange={onChangeHandler}
              value={formData.saveToProject}
              name="saveToProject"
              id={options.id}
              size="small"
            />
          </div>
          <div>
            <Input
              required
              onChange={onChangeHandler}
              value={formData.fileName}
              placeholder="Name"
              name="fileName"
              className="input-group mb-3"
              disabled={!formData.saveToProject} />
            <Input
              onChange={onChangeHandler}
              value={formData.fileDescription}
              placeholder="Description"
              name="fileDescription"
              className="input-group mb-3"
              disabled={!formData.saveToProject} />
          </div>
          {
            exportState.exportErrorMsg &&
            <ErrorMessage
              type="alert"
              errorMessage={exportState.exportErrorMsg} />
          }
        </div>
        <DialogActionsBar>
          <button type="button" className="k-button k-button-solid-base" onClick={onHide}>Close</button>
          <button type="submit" disabled={exportState.isExportInProgress} className="k-button k-button-solid-primary">Export</button>
        </DialogActionsBar>
      </form>
    </Dialog >
  )
}