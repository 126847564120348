import { useState } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import type { AdminUser } from '../../../../../../interfaces/userInterface/userInterface';

interface Props {
  user: AdminUser | null
  children: TODO
  handleClose: () => void
  onConfirmDelete: () => void
}

export const DeleteUserModal = ({ user, children, handleClose, onConfirmDelete }: Props) => {
  const [email, setEmail] = useState<string>("");
  const userFullName: string = `${user?.firstName} ${user?.lastName}`;

  return (
    <Dialog
      title="Delete User"
      onClose={() => handleClose()}
      className="d-flex flex-column w-100"
      width="30%">
      <div className="p-3">
        <span>
          Confirm the deletion of the user {userFullName} by typing the <strong>email</strong> in the input field.
        </span>
        <p className="text-muted">{`\n${user?.email}`}</p>
        <Input
          required
          onChange={(e: TODO) => setEmail(e.target.value)}
          value={email}
          placeholder="Enter user's email"
          name="userEmail"
          className="w-100" />
      </div>

      {children}

      <DialogActionsBar>
        <button
          type='button'
          className="k-button btn btn-secondary"
          onClick={() => handleClose()}>Cancel
        </button>
        <button
          type='button'
          className="k-button btn-danger"
          onClick={() => onConfirmDelete()}
          disabled={user?.email !== email}>Delete
        </button>
      </DialogActionsBar>
    </Dialog>
  );
}