import { useState } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import type { RootState } from '../../../../../store/reducers/rootReducer';
import { ErrorMessage } from '../../../../shared/ErrorMessage/ErrorMessage';
import { fetchDelete as deleteCustomQuestion } from '../../../../../services/services';

interface Props {
  customQuestion: TODO
  updateData: () => void
  handleClose: () => void
}

export const DeleteSurveyCustomQuestionModal = ({ handleClose, updateData, customQuestion }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const [customQuestionName, setCustomQuestionName] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { showOverview } = useSelector((theState: RootState) => theState.surveyCustomQuestionsStateReducer)

  const onDeleteHandler = () => {
    if (customQuestion) {
      setIsLoading(true)
      deleteCustomQuestion(`su/customquestions/${customQuestion.id}`, token)
        .then((res: TODO) => {
          setIsLoading(false)
          if (res.status === 200) {
            if (showOverview) {
              dispatch({ type: 'UPDATE_SHOW_OVERVIEW', payload: { show: false, customQuestion: null } })
            }
            handleClose()
            updateData()
          } else {
            return res.json()
          }
        })
        .then((data: TODO) => {
          if (data && (data.error || data.message)) {
            setErrorMessage(data.error ? data.error : data.message)
          }
        })
    }
  }

  return (
    <Dialog
      title={`Delete "${customQuestion?.name}"`}
      onClose={handleClose}>
      <div className="p-2">
        <p>
          Deleting a custom question will remove everything related to the custom question. <br />
          Confirm the deletion by typing the custom question's <strong>name</strong> in the input field below:
        </p>
        <Input
          className="w-100"
          value={customQuestionName}
          onChange={(e: TODO) => setCustomQuestionName(e.target.value)}
        />
        {
          errorMessage &&
          <div className="mt-4">
            <ErrorMessage
              type="alert"
              errorMessage={errorMessage} />
          </div>
        }
      </div>

      <DialogActionsBar>
        <button
          type='button'
          className="k-button btn btn-secondary"
          onClick={handleClose}>Cancel
        </button>
        <button
          type='button'
          disabled={customQuestionName !== customQuestion?.name || isLoading}
          className="k-button btn-danger"
          onClick={onDeleteHandler}>
          {isLoading &&
            <div className="spinner-border spinner-border-sm mr-2" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          }
          <span>Delete</span>
        </button>
      </DialogActionsBar>
    </Dialog >
  )
}