export const returnAvatarContent = (icon?: string, iconSize?: string, errorImage?: string, image?: string, value?: string, updateErrorImage?: TODO) => {
  let avatarContent = null
  let avatarType = null

  if (errorImage) {
    avatarType = 'icon'
    avatarContent = <i className={`far fa-image ${iconSize}`} />
  } else if (image) {
    avatarType = 'image'
    avatarContent =
      <img
        onError={updateErrorImage}
        src={image}
        alt="icon"
        loading='lazy'
        width="50"
        height="50"
      />
  } else if (icon) {
    avatarType = 'icon'
    avatarContent = <i className={`${icon} ${iconSize}`} />
  } else {
    avatarType = 'initials'
    avatarContent = <span>{value}</span>
  }

  return {
    avatarType: avatarType,
    avatarContent: avatarContent
  }
}