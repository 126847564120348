import React, { useEffect, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Checkbox, RadioButton, Switch } from '@progress/kendo-react-inputs';

import {
  fetchPutResOrJson as updateSendSettings,
  fetchPutResOrJson as updateScheduleSettings,
  fetchPostResOrJson as sendEmailCampaign,
  fetchGetJson as getSurvey
} from '../../../../../../../services/services';
import { BaseMultiSelect } from '../../../../../../shared/Inputs';
import { returnSendOutSettingsBody } from '../../../helpers/helpers';
import { FullHeightSpinner } from '../../../../../../shared/FullHeightSpinner/FullHeightSpinner';
import { ErrorMessage } from '../../../../../../shared/ErrorMessage/ErrorMessage';
import ScheduleModal from '../../../../../../shared/ScheduleModal/ScheduleModal';

export default ({ token, campaign, onStepChange, campaignsList, updateCampaign, updateCampaignState }) => {
  const validateRecipients = [{ name: "Everyone", description: `${campaign.type === "email-campaign" ? "Email" : "Sms"} will be sent to everyone in the respondent list`, id: "everyone" }, { name: "Subset", description: `Choose which respondents you want to send ${campaign.type === "email-campaign" ? "email" : "sms"} to`, id: "subset" }]
  const subsetRecipients = [{ name: "Not started", description: `${campaign.type === "email-campaign" ? "Email" : "Sms"} will be sent to the respondents that have not started the survey`, id: "notStarted" }, { name: "Started", description: `${campaign.type === "email-campaign" ? "Email" : "Sms"} will be sent to the respondents that have started the survey`, id: "started" }, { name: "Completed", description: `${campaign.type === "email-campaign" ? "Email" : "Sms"} will be sent to the respondents that have completed started the survey`, id: "completed" }]
  const [didMount, setDidMount] = useState(true)
  const [surveyStatus, setSurveyStatus] = useState(0)
  const [settings, setSettings] = useState({ validateCampaigns: [], sendTo: [validateRecipients[0].id] })
  const [subset, setSubset] = useState(false)
  const [errorModal, setErrorModal] = useState({ show: false, message: '' })
  const [showValidation, setShowValidation] = useState(false)
  const [scheduleModal, setScheduleModal] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [error, setError] = useState({ show: false, message: '' })
  const [verifying, setVerifying] = useState(false)

  useEffect(() => {
    if (didMount) {
      getSurvey(`su/projects/${campaign.projectId}/surveys/${campaign.surveyId}`, token)
        .then(res => {
          if (res) {
            setSurveyStatus(res.surveyStatus)
          }
        })
      if (campaign.sampleCampaignSettings !== null) {
        let campaignSettings = campaign.sampleCampaignSettings
        if (campaignSettings.sendTarget === 'Subset') {
          setSubset(true)
          let list = []
          for (const key in campaignSettings.sendSubset) {
            if (campaignSettings.sendSubset[key] === true)
              list.push(key)
          }
          setSettings({
            validateCampaigns: campaignSettings.rejectCampaignIdList !== null ? campaignsList.filter(el => campaignSettings.rejectCampaignIdList.includes(el.id) ? true : false) : [],
            sendTo: list
          })
        } else {
          setSettings({
            validateCampaigns: campaignSettings.rejectCampaignIdList !== null ? campaignsList.filter(el => campaignSettings.rejectCampaignIdList.includes(el.id) ? true : false) : [],
            sendTo: ["everyone"]
          })
        }
        setShowValidation(campaignSettings.useCampaignValidation)
      }
      setDidMount(false)
    }
  }, [didMount, campaignsList, campaign, token])

  const onSendTo = (id) => {
    if (id === 'everyone') {
      setSubset(false);
      setSettings({ ...settings, sendTo: ['everyone'] })
    } else {
      let list = settings.sendTo
      if (list.length === 0 || !list.includes(id)) {
        list.push(id)
      } else {
        list = list.filter(el => el !== id)
      }
      setSettings({ ...settings, sendTo: list })
    }
  }

  const onSend = (scheduled) => {
    if (campaign.disabled) {
      setError({ show: true, message: `You need to activate invite to send ${campaign.type === "email-campaign" ? "email" : "sms"}` })
    } else if (surveyStatus !== 1) {
      setError({ show: true, message: `You need to publish the survey to send ${campaign.type === "email-campaign" ? "email" : "sms"}.` })
    } else {
      const body = returnSendOutSettingsBody(showValidation, settings, subset)
      updateSendSettings(`projects/${campaign.projectId}/surveys/${campaign.surveyId}/campaigns/${campaign.type === "email-campaign" ? "email" : "sms"}/${campaign.id}/send-settings`, token, body)
        .then(res => {
          if (res) {
            const body = { "sendNow": scheduled ? false : true }
            sendEmailCampaign(`projects/${campaign.projectId}/surveys/${campaign.surveyId}/campaigns/${campaign.type === "email-campaign" ? "email" : "sms"}/${campaign.id}/send`, token, body)
              .then(res => {
                if (res && (res.message || res.error)) {
                  setError({ show: true, message: res.message ? res.message : res.error })
                } else if (res) {
                  setScheduleModal(false)
                  updateCampaignState()
                  setError({ show: false, message: '' })
                }
                setVerifying(false)
              })
          }
        })
    }
  }

  const onScheduleSend = (body) => {
    updateScheduleSettings(`projects/${campaign.projectId}/surveys/${campaign.surveyId}/campaigns/${campaign.type === "email-campaign" ? "email" : "sms"}/${campaign.id}/sendout-settings`, token, body)
      .then(res => {
        if (res && (res.message || res.error)) {
          setErrorModal({ show: true, message: res.message ? res.message : res.error })
        } else {
          onSend(true)
        }
      })
  }

  const onSaveChanges = () => {
    const body = returnSendOutSettingsBody(showValidation, settings, subset)
    updateSendSettings(`projects/${campaign.projectId}/surveys/${campaign.surveyId}/campaigns/${campaign.type === "email-campaign" ? "email" : "sms"}/${campaign.id}/send-settings`, token, body)
      .then(res => {
        if (res && (res.message || res.error)) {
          setErrorModal({ show: true, message: res.message ? res.message : res.error })
        } else {
          updateCampaign()
          setIsSaving(false)
        }
      })
  }

  return (
    <div className="d-flex flex-column overflow-hidden compose-main">
      {scheduleModal &&
        <ScheduleModal
          title={campaign.type === 'email-campaign' ? "Schedule emails" : "Schedule sms"}
          description={`${campaign.type === "email-campaign" ? "Email" : "Sms"} will be processed in 5 minutes.`}
          handleClose={() => setScheduleModal(false)}
          onScheduleSend={onScheduleSend}
          onSend={() => { setVerifying(true); onSend() }}
          error={error}
          verifying={verifying}
          type='survey' />
      }
      {errorModal.show &&
        <ErrorMessage
          type="modal"
          errorMessage={errorModal.message}
          onHide={() => setErrorModal({ show: false, message: '' })}
        />
      }
      {didMount ?
        <FullHeightSpinner /> :
        <div className="d-flex flex-column h-100 overflow-auto">
          <div className="d-flex align-items-start flex-column flex-shrink-0 overflow-hidden justify-content-between border-bottom audience-pre-validation">
            <div className="d-flex align-items-center">
              <Switch checked={showValidation} onChange={() => setShowValidation(!showValidation)} size="small" />
              <div className="d-flex flex-column">
                <span className="ml-2 mb-0">Activate pre-validation</span>
              </div>
            </div>
            {showValidation &&
              <div className="d-flex flex-column">
                <BaseMultiSelect className="mt-3 mb-2" data={campaignsList} textField="name" value={settings.validateCampaigns}
                  onChange={(e) => setSettings({ ...settings, validateCampaigns: e.target.value })} />
                <span className="audience-desc">Do <strong>NOT</strong> {`send ${campaign.type === "email-campaign" ? "email" : "sms"} if recipient has recieved any of the following invites.`}</span>
              </div>}
          </div>
          <div className="d-flex flex-column overflow-auto align-items-center p-4">
            <h5 className="mb-4 mt-4 strong">{`Who do you want to send ${campaign.type === "email-campaign" ? "email" : "sms"} to?`}</h5>
            <div className="d-flex flex-column w-50">
              {validateRecipients.map((item, key) =>
                <div className="d-flex flex-row mb-2 border survey-main p-4" key={key}>
                  <div className='d-flex flex-column w-100 align-items-start justify-content-center'>
                    <div className="d-flex flex-row w-100 align-items-center">
                      <RadioButton className="mr-3"
                        onChange={() => { item.id === 'everyone' ? onSendTo(item.id) : setSubset(true) }}
                        checked={item.id === 'everyone' ? settings.sendTo.includes(item.id) && !subset ? true : false : subset} />
                      <div className="d-flex flex-column w-100">
                        <span className="mr-3 strong">{item.name}</span>
                        {item.description &&
                          <span className="audience-desc">{item.description}</span>}
                      </div>
                    </div>
                    {item.id === "subset" &&
                      <div className="d-flex flex-column w-100">
                        {subsetRecipients.map((el, key) =>
                          <div className="d-flex flex-row align-items-start mb-3 subset-options" key={key} disabled={!subset}>
                            <Checkbox disabled={!subset}
                              onChange={() => onSendTo(el.id)}
                              className="mr-3"
                              checked={settings.sendTo.includes(el.id)} />
                            <div className='d-flex flex-column'>
                              <span className="mr-3">{el.name}</span>
                              <span className="audience-desc">{el.description}</span>
                            </div>
                          </div>)}
                      </div>}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      }
      {
        <div className="d-flex justify-content-end flex-shrink-0 p-3 border-top">
          <div className="d-flex">
            <div className="d-flex align-items-center">
              {isSaving &&
                <div className="spinner-border spinner-border-sm text-disabled mr-2" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              }
              <div className="btn btn-secondary" title="Save changes" onClick={() => { setIsSaving(true); onSaveChanges() }}>Save</div>
            </div>
            <div className="d-flex">
              <Button className="btn btn-secondary mr-2 ml-2 h-32" onClick={() => -onStepChange(0)}>Back</Button>
              <Button className="btn btn-primary h-32" disabled={settings.sendTo.length === 0} onClick={() => setScheduleModal(true)}>
                Schedule
              </Button>
            </div>
          </div>
        </div>

      }
    </div >
  )
}