const initialState = {
  token: null
};

function tokenStateReducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_TOKEN":
      return {
        ...state,
        token: action.payload,
      };
    default:
      return state;
  }
}

export default tokenStateReducer;