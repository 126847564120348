import React from 'react';

import { InProgressOverlay } from '../../InProgressOverlay/InProgressOverlay';

export default ({ onHide, shareDetails, isLoading, onShareHandler }) => {
  return (
    <React.Fragment>
      <button className="k-button k-button-solid-base" onClick={onHide}>Cancel</button>
      <button
        className="k-button k-button-solid-primary"
        disabled={isLoading.share || isLoading.update}
        onClick={() => onShareHandler(shareDetails.isShared ? 'update' : 'share')}>
        {
          isLoading.share || isLoading.update ?
            <InProgressOverlay type="button" theme="light" />
            :
            <span>{shareDetails.isShared ? 'Update' : 'Share'}</span>
        }
      </button>
    </React.Fragment >
  )
}