import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Input } from "@progress/kendo-react-inputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import { BaseMultiSelect } from "../Inputs";
import { useFetchTags } from "../customHooks/useFetchTags";
import { fetchPostJson } from "../../../services/services";

interface Props {
  token: string
  projectId: string
  onHide: () => void
}

const AddPinboardModal = ({ onHide, projectId, token }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [formData, setFormData] = useState<{ name: string, description: string, tags: any[] }>({ name: "", description: "", tags: [] });
  const [creatingPinboard, setCreatingPinboard] = useState<boolean>(false);
  const availableTags = useFetchTags(token);

  const onChangeHandler = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const onConfirmHandler = () => {
    setCreatingPinboard(true);
    const body = {
      "name": formData.name,
      "description": formData.description,
      "tags": formData.tags,
    }

    fetchPostJson(`pb/projects/${projectId}/pinboards`, token, body)
      .then((res: any) => {
        setCreatingPinboard(false);
        if (res) {
          if (res.error || res.message) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
          } else {
            dispatch({ type: 'UPDATE_DOCUMENT_BREADCRUMB', payload: { id: res.pinboardId, name: formData.name, documentDescription: formData.description } });
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The pinboard has been created successfully' } });
            history.push({ pathname: `/projects/${projectId}/${res.pinboardId}/pinboards` });
          }
        }
      })
  }

  return (
    <Dialog
      title="Add new pinboard"
      onClose={onHide}
      width={650}>
      <div className="d-flex flex-column p-4">
        <Input
          name="name"
          className="w-100 mb-2"
          label="Name"
          required={true}
          value={formData.name}
          onChange={onChangeHandler} />
        <Input
          className="w-100 mb-2"
          name="description"
          label="Description"
          value={formData.description}
          onChange={onChangeHandler} />
        <BaseMultiSelect
          data={availableTags}
          value={formData.tags}
          label="Tags"
          textField="name"
          dataItemKey="id"
          name="tags"
          allowCustom={true}
          listNoDataRender={() => (<h5 className="text-muted text-center"><span className="k-icon k-i-warning my-3" style={{ fontSize: '2.5em' }} /><br /><span>No tags created yet</span></h5>)}
          onChange={onChangeHandler} />
      </div>
      <DialogActionsBar>
        <button className="k-button btn btn-secondary" onClick={onHide}>Cancel</button>
        <button className="k-button btn btn-primary" disabled={creatingPinboard} onClick={onConfirmHandler}>Create</button>
      </DialogActionsBar>
    </Dialog>
  )
}

export default AddPinboardModal;