import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Input } from '@progress/kendo-react-inputs';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import type { RootState } from '../../../../../../../store/reducers/rootReducer';
import { updateLanguagesList } from "../../../../../../shared/LanguagesList/updateLanguagesList";
import { fetchPatchJson as deleteSurveyLanguage, fetchGetJson as getSurveyIndex } from '../../../../../../../services/services';

interface Props {
  language: TODO
  handleClose: () => void
  updateLanguages: () => void
}

export const SurveyDesignDeleteLanguageModal = ({ language, handleClose, updateLanguages }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const params: { name: string, survey: string } = useParams()
  const [name, setName] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const deleteLanguageHandler = () => {
    const body = { code: language.code }
    setIsLoading(true)
    deleteSurveyLanguage(`su/projects/${params.name}/surveys/${params.survey}/delete-language`, token, body)
      .then((res: TODO) => {
        if (res && !res.error && !res.message) {
          getSurveyIndex(`su/projects/${params.name}/surveys/${params.survey}/index`, token)
            .then((res: TODO) => {
              if (res && !res.error) {
                const enhancedLanguagesList = updateLanguagesList([...res.languages]) // Add language info, a compensation for backend
                dispatch({ type: 'UPDATE_SURVEY_LANGUAGES_DATA', payload: enhancedLanguagesList })
                dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: `${language.name} (${language.code}) has been deleted successfuly!` } })
                updateLanguages()
                handleClose()
              } else {
                setIsLoading(false)
              }
            })
        } else {
          setIsLoading(false)
        }
      })
  }

  return (
    <Dialog
      onClose={handleClose}
      title={`Delete '${language.name}' language`}
      className="d-flex flex-column w-100"
      width={'500'}>
      <div className="p-4">
        <p>
          Deleting a language will remove everything related to the language, including language data. <br />
          Confirm the deletion of the language by typing the language <strong>name</strong> in the input field below:
        </p>
        <Input
          required
          className="w-100"
          name="projectName"
          placeholder={"Enter language name"}
          value={name}
          onChange={(e: TODO) => setName(e.target.value)}
        />

      </div>
      <DialogActionsBar>
        <button type='button' className="k-button btn btn-secondary " onClick={handleClose}>Cancel</button>
        <button type='button' disabled={name !== language.name || isLoading} className="k-button btn-danger " onClick={deleteLanguageHandler}>
          {
            isLoading &&
            <div className="spinner-border spinner-border-sm mr-2" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          }
          <span>Delete</span>
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}