import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import tabs from '../../dashboard/main/analysis/tabsData.json';
import defaultOptions from '../../dashboard/main/analysis/defaultOptionValues.json';
import sortOptionsGroups from '../helpers/sortOptionGroups/sortOptionsGroups';
import returnInitialOptions from '../helpers/returnInitialOptions/returnInitialOptions';
import returnDigOptionsInitialValues from '../helpers/returnDigOptionsInitialValues/returnDigOptionsInitialValues';
import returnChartOptionsInitialValues from '../helpers/returnChartOptionsInitialValues/returnChartOptionsInitialValues';
import returnQTableOptionsInitialValues from '../helpers/returnQTableOptionsInitialValues/returnQTableOptionsInitialValues';
import returnToplineOptionsInitialValues from '../helpers/returnToplineOptionsInitialValues/returnToplineOptionsInitialValues';
import returnRawDataOptionsInitialValues from '../helpers/returnRawDataOptionsInitialValues/returnRawDataOptionsInitialValues';
import returnCrosstabOptionsInitialValues from '../helpers/returnCrosstabOptionsInitialValues/returnCrosstabOptionsInitialValues';
import {
  fetchGetJson as getAnalysisOptions,
  fetchDelete as clearSession,
} from '../../../../../../services/services';

export default function ClearSessionsModal({ onHide }) {
  const { user } = useAuth0();
  const params = useParams();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.tokenStateReducer);
  const [optionsData, setOptionsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const datasetId = params.dataset;

  useEffect(() => {
    const optionsData = {};
    const groups = {};
    setIsLoading(true);
    getAnalysisOptions('/resources/analysis-options', token).then((res) => {
      if (res && (res.message || res.error)) {
        dispatch({ type: 'UPDATE_ERROR_MESSAGE', payload: { msg: res.message ? res.message : res.error, type: 'modal' } });
      } else {
        tabs.forEach((tab) => {
          if (res.analysisOptions.find((el) => el.analysisType === tab.id)) {
            groups[tab.id] = sortOptionsGroups(res.analysisOptions.find((el) => el.analysisType === tab.id).groups, user);
          }
        });
        if (Object.keys(groups).length > 0 && groups.constructor === Object) {
          tabs.forEach((tab) => {
            optionsData[tab.id] = {
              allOptions: res.analysisOptions,
              exportOptions: res.exportOptions,
              groups: groups[tab.id],
              data: res.analysisOptions.find((el) => el.analysisType === tab.id)?.props,
              dropdownValues: res.analysisEnumerations,
              didFetchAttributes: true,
              shouldUpdateOptions: true,
            };
          });
        }
        setIsLoading(false);
        setOptionsData(optionsData);
      }
    });
  }, [dispatch, token, user]);

  const onClearSessionsHandler = async () => {
    try {
      setIsLoading(true);
      const crosstabValues = returnCrosstabOptionsInitialValues(optionsData.Crosstab.data, user, optionsData.Crosstab.groups);
      const initialCrosstabOptions = returnInitialOptions(
        defaultOptions[1].crosstab,
        user,
        optionsData.Crosstab.groups,
        optionsData.Crosstab.data
      );
      dispatch({ type: 'CLEAR_QUESTIONS', payload: { values: crosstabValues, newValues: initialCrosstabOptions, clearSession: true } });

      const toplineValues = returnToplineOptionsInitialValues(optionsData.Topline.data, user, optionsData.Topline.groups);
      const initialToplineOptions = returnInitialOptions(defaultOptions[0].topline, user, optionsData.Topline.groups, optionsData.Topline.data);
      dispatch({ type: 'TOPLINE_CLEAR_QUESTIONS', payload: { values: toplineValues, newValues: initialToplineOptions, clearSession: true } });

      const qtValues = returnQTableOptionsInitialValues(optionsData.Qt.data, user, optionsData.Qt.groups);
      const initialQTableOptions = returnInitialOptions(defaultOptions[2].qt, user, optionsData.Qt.groups, optionsData.Qt.data);
      dispatch({ type: 'QTABLE_CLEAR_QUESTIONS', payload: { values: qtValues, newValues: initialQTableOptions, clearSession: true } });

      const chartValues = returnChartOptionsInitialValues(optionsData.Chart.data, user, optionsData.Chart.groups);
      const initialChartOptions = returnInitialOptions(defaultOptions[5].chart, user, optionsData.Chart.groups, optionsData.Chart.data);
      dispatch({ type: 'CHART_CLEAR_QUESTIONS', payload: { values: chartValues, newValues: initialChartOptions, clearSession: true } });

      const digValues = returnDigOptionsInitialValues(optionsData.Dig.data, user, optionsData.Dig.groups);
      const initialDigOptions = returnInitialOptions(defaultOptions[4].dig, user, optionsData.Dig.groups, optionsData.Dig.data);
      dispatch({ type: 'DIG_CLEAR_QUESTIONS', payload: { values: digValues, newValues: initialDigOptions, clearSession: true } });

      const dataValues = returnRawDataOptionsInitialValues(optionsData.Data.data, user, optionsData.Data.groups);
      const initialRawDataOptions = returnInitialOptions(defaultOptions[3].data, user, optionsData.Data.groups, optionsData.Data.data);
      dispatch({ type: 'RAW_DATA_CLEAR_QUESTIONS', payload: { values: dataValues, newValues: initialRawDataOptions, clearSession: true } });

      dispatch({ type: 'REPORT_CLEAR_DATA', payload: null });

      const clearSessionRes = await clearSession(`workingsessions/${datasetId}`, token);
      if (clearSessionRes && clearSessionRes.status === 200) {
        onHide();
        dispatch({
          type: 'UPDATE_SELECTED_SESSION',
          payload: {
            name: '',
            id: '',
            lastSaved: '',
          },
        });
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog minWidth={500} title={"Clear session"} onClose={onHide} className="import-dialog">
      <div className="text-center p-4">
        <h5>Are you sure you want to remove all changes for this session ?</h5>
        <h6 className="m-0">Elements and options will be reset to default for all analysis types</h6>
      </div>
      <DialogActionsBar>
        <button type='button' className="k-button k-button-solid-base" onClick={onHide}>
          No
        </button>

        <button type='button' disabled={isLoading} className="k-button btn-danger" onClick={onClearSessionsHandler}>
          {isLoading ? (
            <div className="spinner-border spinner-border-sm" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <span>Yes</span>
          )}
        </button>
      </DialogActionsBar>
    </Dialog >
  );
};

