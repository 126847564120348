import { useState, useEffect, useRef, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DrawerItem } from '@progress/kendo-react-layout';

import DialogTitle from './DialogTitle/DialogTitle';
import OptionsFilterBuilder from '../OptionsFilterBuilder/OptionsFilterBuilder';
import { OptionsDialogContent } from './OptionsDialogContent/OptionsDialogContent';
import { FullHeightSpinner } from '../../../../../../shared/FullHeightSpinner/FullHeightSpinner';
import returnOptionByType from '../../../shared/helpers/returnOptionByType/returnOptionByType';
import disableOptionGroupByPlan from '../../../shared/helpers/disableOptionGroupByPlan/disableOptionGroupByPlan';
import GenericLibrary from '../../../../../../Survey/SurveyTabContent/SurveyDesignTabContent/helpers/LibraryModal/GenericLibrary';
import returnSelectedQuestionsOptions from '../../../shared/helpers/returnSelectedQuestionsOptions/returnSelectedQuestionsOptions';
import { setAnalysisSortingDirectionAutomatically } from '../../../shared/helpers/setAnalysisSortingDirectionAutomatically/setAnalysisSortingDirectionAutomatically';
import { returnValueChange, returnFilteredData, returnOnSaveFilterData, returnOnClearFilterData, returnSetPreviousData } from '../../../shared/helpers/optionsDialoghelpers/optionsDialogHelpers';
import { fetchGetJson as getAnalysisStructure, fetchGetJson as getAllWeightIds, fetchPost, fetchGetRes } from '../../../../../../../services/services';

export const OptionsDialog = ({
  firstColumn,
  initialFirstColumn,
  defaultQuestionOptions,
  tab,
  activeGroup,
  data,
  isLoading,
  dropdownValues,
  values,
  updateValues,
  expandedOptions,
  optionGroups,
  handleClose,
  handleUpdate,
  setCombineFilterData,
  userData,
  datasetId,
  combineFilterData,
  user,
  token,
  analysisType,
  optionId,
  initialOptionsData,
  initialAndUpdateOptionsValues,
  onApplyWeightSet,
}) => {
  const params = useParams();
  const selectedQuestionsOptions = returnSelectedQuestionsOptions(firstColumn)
  const selectedQuestions = firstColumn ? firstColumn.filter(el => el.selected) : [];
  const [state, setState] = useState({
    originalData: data,
    data: [],
    updateOptionsValues: { ...updateValues },
    initialOptionsValues: (selectedQuestionsOptions && Object.keys(selectedQuestionsOptions).length !== 0) || (selectedQuestions.length > 0) ? { ...defaultQuestionOptions, ...selectedQuestionsOptions } : { ...values },
    selectedGroup: activeGroup,
    expandedOptions: expandedOptions,
    optionGroups: optionGroups,
    searchValue: null,
    combineFilterData: combineFilterData
  })
  const { structure } = useSelector(theState => theState.setInitialDataReducer, shallowEqual);
  const [weightValues, setWeightValues] = useState([])
  const [filterData, setFilterData] = useState(null)
  const [showFilterBuilder, setShowFilterBuilder] = useState(false);
  const [filterBuilderOption, setFilterBuilderOption] = useState(null)
  const [shouldUpdateDataOptions, setShouldUpdateDataOptions] = useState(true)
  const dispatch = useDispatch();
  const history = useHistory();
  const [changedValues, setChangedValues] = useState({}); // A list of values which have been changed since the Options Dialog has been open 
  const [showOptionBatchingModal, setShowOptionBatchingModal] = useState({ show: false, save: false })

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevSelectedGroup = usePrevious(state.selectedGroup)

  useEffect(() => {
    if (prevSelectedGroup !== state.selectedGroup) {
      const list = []
      if (state.selectedGroup) {
        data.forEach(option => {
          if (option.group === state.selectedGroup) {
            list.push(option)
          }
        })
        setState({ ...state, data: list })
      }
    }
  }, [state, data, prevSelectedGroup])

  useEffect(() => {
    if (analysisType !== 'surveys') {
      getAllWeightIds(`an/projects/${params.name}/analysis/${datasetId}/weight-ids`, token)
        .then(res => {
          if (res && (res.message || res.error)) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.message ? res.message : res.error } })
          } else {
            setWeightValues(['None', ...res])
          }
        })
    }
  }, [token, dispatch, datasetId, params, analysisType])

  const handleFilterBuilderClose = () => {
    setShowFilterBuilder(false);
  }

  const updateChangedValuesList = (value, optionId) => {
    const updatedValues = { ...changedValues }
    updatedValues[optionId] = value;

    setAnalysisSortingDirectionAutomatically(optionId, updatedValues);

    setChangedValues(updatedValues)
  }

  const handleFilterBuilderOpen = (id) => {
    setCombineFilterData(id)
    setFilterBuilderOption(id)
    setShowFilterBuilder(true)
  }

  const selectGroup = (e) => {
    setState({ ...state, selectedGroup: state.optionGroups[e.itemIndex].text })
  }

  const updateFirstColumnState = (value, id, batchValues) => {
    // If you'd like to process a single option, just add <value> and <id>, but leave <batchValues> as faulty 
    // If you'd like to process a batch of options, leave <value> and <id> as fauly, and push an object with values and ids as properties through <batchValues>

    const updatedFirstColumn = [...firstColumn]
    if (selectedQuestions.length > 0) {
      selectedQuestions.forEach(selQuestion => {
        const index = updatedFirstColumn.findIndex(el => el.id === selQuestion.id)

        if (!batchValues) {
          if (updatedFirstColumn[index].options) {
            updatedFirstColumn[index].options[id] = value
          } else {
            updatedFirstColumn[index].options = {}
            updatedFirstColumn[index].options[id] = value
          }
        } else {
          if (updatedFirstColumn[index].options) {
            updatedFirstColumn[index].options = { ...batchValues }
          } else {
            updatedFirstColumn[index].options = {}
            updatedFirstColumn[index].options = { ...batchValues }
          }
        }
      })
    }
    return updatedFirstColumn
  }

  const onValueChange = (e, id) => {
    const updateOptions = returnValueChange(state.updateOptionsValues, state.initialOptionsValues, e.target.value, id)

    let updatedFirstColumn;
    if (selectedQuestions.length > 0) {
      updatedFirstColumn = updateFirstColumnState(e.target.value, id);
    } else {
      updatedFirstColumn = firstColumn;
    }
    updateChangedValuesList(e.target.value, id)

    setState({
      ...state,
      initialOptionsValues: updateOptions.updateInitialValues,
      updateOptionsValues: updateOptions.updateValues,
      firstColumn: updatedFirstColumn,
    })
  }

  const onSwitchChange = (id, value) => {
    const updateOptions = returnValueChange(state.updateOptionsValues, state.initialOptionsValues, value != null ? value : !state.initialOptionsValues[id], id)

    let updatedFirstColumn;
    if (selectedQuestions.length > 0) {
      updatedFirstColumn = updateFirstColumnState(value, id);
    } else {
      updatedFirstColumn = firstColumn;
    }
    updateChangedValuesList(value, id);

    if (id === 'GSortChart' && updateOptions.updateInitialValues.GSortRollDir === 0 && updateOptions.updateValues.GSortRollDir === 0) {
      updateOptions.updateInitialValues.GSortRollDir = 2;
      updateOptions.updateValues.GSortRollDir = 2;
    }

    setState({
      ...state,
      initialOptionsValues: updateOptions.updateInitialValues,
      updateOptionsValues: updateOptions.updateValues,
      firstColumn: updatedFirstColumn,
    })
  }

  const onDropdownChange = (e, id) => {
    if (id === "GChartShow") {
      if (e.value.idx === 4 || e.value.idx === 5) {
        onSwitchChange("GChartHidePctSign", true)
      } else {
        onSwitchChange("GChartHidePctSign", false)
      }
    }
    const weightOptions = ['SelectedWeight', 'XtSelectedWeight', 'GSelectedWeight', 'QtSelectedWeight']
    let updateOptions = {}
    let updatedFirstColumn;
    if (weightOptions.includes(id)) {
      let value
      if (e.target.value === 'None') {
        value = ''
      } else {
        value = e.target.value
      }

      if (selectedQuestions.length > 0) {
        updatedFirstColumn = updateFirstColumnState(value, id);
      } else {
        updatedFirstColumn = firstColumn;
      }

      updateOptions = returnValueChange(state.updateOptionsValues, state.initialOptionsValues, value, id)
      updateChangedValuesList(value, id)
    } else {
      if (selectedQuestions.length > 0) {
        updatedFirstColumn = updateFirstColumnState(e.target.value.idx, id);
      } else {
        updatedFirstColumn = firstColumn;
      }

      updateOptions = returnValueChange(state.updateOptionsValues, state.initialOptionsValues, e.target.value.idx, id)
      updateChangedValuesList(e.target.value.idx, id)
    }

    if (id === 'XtSortRollValue' && updateOptions.updateInitialValues.XtSortRollDir === 0 && updateOptions.updateValues.XtSortRollDir === 0) {
      updateOptions.updateInitialValues.XtSortRollDir = 2;
      updateOptions.updateValues.XtSortRollDir = 2;
    } else if (id === 'QtSortRollValue' && updateOptions.updateInitialValues.QtSortRollDir === 0 && updateOptions.updateValues.QtSortRollDir === 0) {
      updateOptions.updateInitialValues.QtSortRollDir = 2;
      updateOptions.updateValues.QtSortRollDir = 2;
    }

    setState({
      ...state,
      initialOptionsValues: updateOptions.updateInitialValues,
      updateOptionsValues: updateOptions.updateValues,
      firstColumn: updatedFirstColumn,
    })
  }

  const onApplyWeightToAllTabs = (type) => {
    onApplyWeightSet(state.initialOptionsValues[type])
  }

  const onSearchOptions = (e) => {
    if (e.target.value) {
      const updatedData = returnFilteredData(state.originalData, e.target.value)
      setState({ ...state, data: updatedData, selectedGroup: null, searchValue: e.target.value })
    } else {
      setState({ ...state, data: state.originalData, selectedGroup: activeGroup })
    }
  }

  const onSaveFilterOptions = (data, expression, option) => {
    const filterData = returnOnSaveFilterData(data, expression, option, tab, state.updateOptionsValues, state.initialOptionsValues)
    const filterOrUniverseData = expression ? data : [];
    const actionType = selectedQuestions.length > 0 ? (filterData.updateDataSelected ? filterData.updateDataSelected : null) : filterData.updateData;
    actionType && dispatch({ type: actionType, payload: { data: filterOrUniverseData } })

    let updatedFirstColumn;
    if (selectedQuestions.length > 0) {
      updatedFirstColumn = updateFirstColumnState(expression, option);
    } else {
      updatedFirstColumn = firstColumn;
    }
    updateChangedValuesList(state.initialOptionsValues[filterBuilderOption], option)

    setState({
      ...state,
      updateOptionsValues: filterData.updateValues.updateValues,
      initialOptionsValues: filterData.updateValues.updateInitialValues,
      combineFilterData: filterOrUniverseData,
      firstColumn: updatedFirstColumn,
    })
    setShowFilterBuilder(false)
  }

  const clearQuestionOptions = (option) => {
    setCombineFilterData(option);
    const clearFilterData = returnOnClearFilterData(option, tab, state.updateOptionsValues, state.initialOptionsValues)
    const actionType = selectedQuestions.length > 0 ? (clearFilterData.updateDataSelected ? clearFilterData.updateDataSelected : null) : clearFilterData.updateData;

    setState({
      ...state,
      updateOptionsValues: clearFilterData.clearedUpdateOptionsValues,
      initialOptionsValues: clearFilterData.clearedInitialOptionsValues,
      combineFilterData: []
    })

    actionType && dispatch({ type: actionType, payload: { data: [] } })
  }

  const customItem = (props) => {
    const disabled = disableOptionGroupByPlan(props.text, user, state.optionGroups)
    return (
      <DrawerItem className="option-item" {...props}>
        <span className={`text-truncate ${disabled ? 'disabled-tab' : ''}`}>{props.text}</span>
      </DrawerItem>
    )
  }

  const onSetOptions = () => {
    const setData = returnSetPreviousData(optionId, tab)
    dispatch({ type: setData.optionType, payload: { data: "" } })
    handleUpdate(changedValues, state.initialOptionsValues)
  }

  const onCancelOptions = () => {
    const setData = returnSetPreviousData(optionId, tab);
    dispatch({
      type: setData.setCurrentOptionsData,
      payload: {
        filterData: initialOptionsData.initialFilterData,
        universeData: initialOptionsData.initialUniverseData,
        questionOptions: initialOptionsData.initialQuestionOptions,
        newQuestionOptions: initialOptionsData.initialNewQuestionOptions,
        selectedFilterOrUniverseData: initialOptionsData.initialSelectedFilterOrUniverseData,
        firstColumn: initialFirstColumn,
      }
    });
    dispatch({ type: setData.optionType, payload: { data: "" } });
    handleClose();
  }

  const conflictedOptions = [];
  // Needed to decide if multiple questions have the same option with different values  
  if (selectedQuestions.length > 1 && selectedQuestions.some(ques => ques.options)) {
    selectedQuestions.forEach((question, index) => {
      if (question.options) {
        for (const optionId in question.options) {
          selectedQuestions.forEach(ques => {
            if (ques.options?.hasOwnProperty(optionId) && !conflictedOptions.includes(optionId) && ques.options[optionId] !== question.options[optionId]) {
              conflictedOptions.push(optionId)
            }
          })
        }
      }
    })
  }

  const { options, optionType, optionData } = returnOptionByType(
    state.data,
    dropdownValues,
    weightValues,
    state.initialOptionsValues,
    state.updateOptionsValues,
    state.optionGroups,
    selectedQuestions,
    user,
    onSwitchChange,
    onValueChange,
    onDropdownChange,
    handleFilterBuilderOpen,
    clearQuestionOptions,
    optionId,
    tab,
    onApplyWeightToAllTabs,
    conflictedOptions,
  )

  const onSaveBatchHandler = (res, url) => {
    const updatedPayload = { ...res }
    updatedPayload.options = { ...state.updateOptionsValues }
    fetchPost(url, token, updatedPayload)
  }

  const onLoadBatchHandler = (element, library) => {
    fetchGetRes(`libraries/${tab}-options/${library === "personal" ? "user" : "customer"}/${element.folderId}/items/${element.id}`, token)
      .then(res => res.json())
      .then(data => {
        if (data.error || data.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: data.error ? data.error : data.message } })
        } else {
          let updatedFirstColumn;
          if (selectedQuestions.length > 0) {
            updatedFirstColumn = updateFirstColumnState(null, null, data.options);
          } else {
            updatedFirstColumn = firstColumn;
          }

          setState({
            ...state,
            initialOptionsValues: data.options,
            updateOptionsValues: data.options,
            firstColumn: updatedFirstColumn,
          })

          setChangedValues(data.options)
          setShowOptionBatchingModal({ show: false, save: false })
        }
      })
  }

  useEffect(() => {
    if (shouldUpdateDataOptions && optionType && optionData) {
      dispatch({ type: optionType, payload: { data: optionData } })
      setShouldUpdateDataOptions(false)
    }
  }, [dispatch, optionData, optionType, shouldUpdateDataOptions])

  const disableGroup = disableOptionGroupByPlan(state.selectedGroup, user, state.optionGroups)

  return (
    <Fragment>
      {
        <Dialog onClose={onCancelOptions} title={<DialogTitle />} className="options-dialog" height="100%">
          {
            structure ?
              <Fragment>
                <OptionsDialogContent
                  user={user}
                  state={state}
                  options={options}
                  customItem={customItem}
                  firstColumn={firstColumn}
                  selectGroup={selectGroup}
                  optionGroups={optionGroups}
                  disableGroup={disableGroup}
                  onSearchOptions={onSearchOptions}
                  openOptionBatchingModal={(saveMode) => setShowOptionBatchingModal({ show: true, save: saveMode })}
                  tab={tab}
                />
              </Fragment>
              :
              <FullHeightSpinner />
          }
          {
            structure &&
            <DialogActionsBar>
              <Button className="btn btn-secondary" onClick={onCancelOptions}>Cancel</Button>
              <Button className="btn btn-primary text-white" onClick={onSetOptions}>Set options</Button>
            </DialogActionsBar>
          }
        </Dialog>
      }
      {
        showOptionBatchingModal.show &&
        <GenericLibrary
          setPayload={showOptionBatchingModal.save === true ? onSaveBatchHandler : null}
          importElement={showOptionBatchingModal.save === false ? onLoadBatchHandler : null}
          scope={`${tab}-options`}
          type={`${tab}-batch`}
          addingFromLibrary={!showOptionBatchingModal.save}
          handleClose={() => setShowOptionBatchingModal({ show: false, save: false })}
          isSelectedQuestionSurveyObject={false}
        />
      }
      {
        showFilterBuilder &&
        <OptionsFilterBuilder
          filterData={structure}
          combineFilterData={combineFilterData}
          manualFilterExpression={state.initialOptionsValues[filterBuilderOption]}
          option={filterBuilderOption}
          handleClose={handleFilterBuilderClose}
          onSaveFilterOptions={onSaveFilterOptions}
          analysisType={analysisType}
          datasetId={datasetId}
          userData={userData}
          token={token}
        />
      }
    </Fragment>
  );
};
