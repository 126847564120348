import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import type { RootState } from '../../../../../../store/reducers/rootReducer';
import { fetchDeleteWithBody as deleteUser } from '../../../../../../services/services';

interface Props {
  userType: string
  selectedAccount: string
  onHide: () => void
  reloadProject: () => void
}

export const ShareProjectDeleteUserModal = ({ onHide, selectedAccount, userType, reloadProject }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const params: { name: string } = useParams()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { regularAccounts } = useSelector((theState: RootState) => theState.projectExternalShareStateReducer);

  const onDeleteUserHandler = () => {
    setIsLoading(true)
    const body = { email: selectedAccount }
    if (params.name) {
      deleteUser(`share/${params.name}/users`, token, body)
        .then((res: TODO) => {
          setIsLoading(false)
          if (res && res.status === 204) {
            if (userType === 'subAccount') {
              reloadProject()
              onHide()
            } else {
              const updatedRegularAccounts = [...regularAccounts]
              updatedRegularAccounts.splice(updatedRegularAccounts.indexOf(selectedAccount), 1)
              dispatch({ type: 'SET_SHARE_PROJECT_REGULAR_ACCOUNTS', payload: updatedRegularAccounts })
              reloadProject()
              onHide()
            }
          }
        })
    }
  }

  return (
    <Dialog
      title={`Delete "${selectedAccount}"`}
      onClose={onHide}>
      <div className="p-4">
        <p className="m-0">Are you sure you would like to delete this user from the project?</p>
      </div>

      <DialogActionsBar>
        <button
          type='button'
          className="k-button btn btn-secondary"
          onClick={onHide}>No
        </button>
        <button
          type='button'
          disabled={isLoading}
          className="k-button btn-danger"
          onClick={onDeleteUserHandler}>
          {
            isLoading ?
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              :
              <span>Yes</span>
          }
        </button>
      </DialogActionsBar>
    </Dialog >
  )
}