import React from 'react'
import { Button } from '@progress/kendo-react-buttons'

export default ({ type, data, onEnableAll, onDisableAll, onMergeQuestions, onInsertColumn, onDeleteColumn }) => {

  return (
    <div className="d-flex">
      <Button
        className="btn btn-outline-dark mr-2 d-flex"
        title="Enable all columns"><i className="fas fa fa-toggle-on"
          onClick={onEnableAll} /></Button>
      <Button
        className="btn btn-outline-dark mr-2 d-flex"
        title="Disable all columns"
        onClick={onDisableAll}><i className="fas fa fa-toggle-off" /></Button>
      <Button
        className="btn btn-outline-dark mr-2 d-flex"
        title={type === 'categories' ? "Merge columns" : "Merge rows"}
        disabled={data.filter(el => el.selected === true).length > 1 && data.filter(el => el.selected === true && el.isMerged === true).length === 0 ? false : true}
        onClick={onMergeQuestions}><i className="fas fa fa-layer-group" /></Button>
      {type === 'categories' ?
        <Button
          className="btn btn-outline-dark mr-2 d-flex"
          title={"Insert \"No answer\" column"}
          disabled={data.some(question => question.code === "-")}
          onClick={onInsertColumn}><i className="fas fa fa-plus" /></Button>
        : null}
      <Button
        className="btn btn-outline-dark d-flex"
        title={"Delete \"No Answer\" column"}
        disabled={data.some(el => el.code === "-") ? false : true}
        onClick={onDeleteColumn}><i className="fas fa fa-trash-alt" /></Button>

    </div>
  )
}