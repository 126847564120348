import React from 'react'
import { useDispatch } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'

export default ({ handleClose, onExportHandler, showExportModal, state, datasetId, history, optionsValues, token, datasetName, formData, tab }) => {
  const dispatch = useDispatch()

  const onSubmitHandler = (e) => {
    e.preventDefault()
    onExportHandler(showExportModal, state, datasetId, history, tab, state.newQuestionOptions, optionsValues, token, dispatch, datasetName, formData, true)
    dispatch({ type: 'DISPLAY_OVERWRITE_MODAL', payload: false })
    handleClose()
  }

  return (
    <Dialog
      width={400}
      onClose={handleClose}
      title="Overwrite file"
      className="d-flex flex-column import-dialog">
      <div className="d-flex flex-column p-3">
        <p className='m-0 text-center'>Do you want to overwrite the existing file?</p>
      </div>
      <DialogActionsBar>
        <button className="k-button btn btn-secondary" onClick={handleClose}>Cancel</button>
        <button className="k-button btn btn-primary" onClick={onSubmitHandler} >Overwrite</button>
      </DialogActionsBar>
    </Dialog>
  )
}