import { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EditTagModal } from './EditTagModal/EditTagModal';
import { DeleteTagModal } from './DeleteTagModal/DeleteTagModal';
import type { RootState } from '../../../../store/reducers/rootReducer';
import { TagDefinitionCard } from './TagDefinitionCard/TagDefinitionCard';
import { AdminCardsSkeleton } from '../AdminCardsSkeleton/AdminCardsSkeleton';
import { fetchGetJsonOrEmptyArray as getTagDefinitions } from '../../../../services/services';

export type Tag = {
  id: string;
  name: string;
  createdUtc: string
  createdByName: string
}

type TagModal = {
  tag?: Tag | null;
  show: boolean;
  id?: null | string
}

export const TagsTabContent = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { tagDefinitions } = useSelector((theState: RootState) => (theState.adminReducer));
  const [didMount, setDidMount] = useState(false)
  const [showEditTag, setShowEditTag] = useState<TagModal>({ tag: null, show: false })
  const [showDeleteTag, setShowDeleteTag] = useState<TagModal>({ tag: null, show: false })

  useEffect(() => {
    if (!didMount) {
      setDidMount(true)
      getTagDefinitions('tag-definitions', token)
        .then((res: TODO) => {
          if (res.error || res.message) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error || res.message } })
          } else {
            dispatch({ type: 'UPDATE_TAG_DEFINITIONS', payload: res })
          }
        })
    }
  }, [didMount, token, dispatch])

  return (
    <Fragment>
      {
        showEditTag.show &&
        <EditTagModal
          handleClose={() => setShowEditTag({ show: false, id: null })}
          tag={showEditTag.tag}
          updateTags={() => setDidMount(false)}
        />
      }
      {
        showDeleteTag.show &&
        <DeleteTagModal
          handleClose={() => setShowDeleteTag({ show: false, id: null })}
          tags={tagDefinitions}
          tag={showDeleteTag.tag}
          updateTags={(data: Tag[]) => dispatch({ type: 'UPDATE_TAG_DEFINITIONS', payload: data })}
        />
      }
      <div className="container-fluid py-5">
        <div style={{ rowGap: '1.5rem' }} className="row">
          {
            tagDefinitions !== null ?
              tagDefinitions.length > 0 ?
                tagDefinitions.map((tagDefinition: Tag) => (
                  <Fragment key={tagDefinition.id}>
                    <TagDefinitionCard
                      tagDefinition={tagDefinition}
                      setShowEditTag={(data) => setShowEditTag(data)}
                      setShowDeleteTag={(data) => setShowDeleteTag(data)}
                    />
                  </Fragment>
                ))
                :
                <div className="d-flex flex-column gap-lg text-disabled text-center h5 pt-10 w-100">
                  <i className="fal fa-2x fa-folder-open" />
                  <p className='m-0'>No tags have been found</p>
                </div>
              :
              <AdminCardsSkeleton />
          }
        </div>
      </div>
    </Fragment>
  )
}