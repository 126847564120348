import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { ErrorMessage } from '../../../../../../../shared/ErrorMessage/ErrorMessage';
import { fetchPostJson } from '../../../../../../../../services/services';
import type { RootState } from '../../../../../../../../store/reducers/rootReducer';

interface Props {
  themes: TODO
  onHide: () => void
  setThemes: (data: TODO) => void
}

export default function SaveThemeAsModal({ themes, setThemes, onHide }: Props) {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const params: { name: string, survey: string } = useParams()
  const [newThemeName, setNewThemeName] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isSavingTheme, setIsSavingTheme] = useState(false)

  const onSaveTheme = (e: TODO) => {
    e.preventDefault();
    setIsSavingTheme(true)
    fetchPostJson(`su/projects/${params.name}/surveys/${params.survey}/saveAsTheme`, token, { name: newThemeName })
      .then((res: TODO) => {
        setIsSavingTheme(false)
        if (res && !res.error && !res.message) {
          const updatedThemes = JSON.parse(JSON.stringify(themes))
          updatedThemes.data.push(res)
          setThemes(updatedThemes)
          onHide()
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The theme has been saved successfully' } })
        } else {
          setErrorMessage(res.error ? res.error : res.message)
        }
      })
  }

  return (
    <Dialog title="Save as a new theme" onClose={onHide} width="600px">
      <form onSubmit={onSaveTheme}>
        <div className="p-4">
          <p className={"mb-1 strong"}>Theme name</p>
          <input required onChange={(e) => setNewThemeName(e.target.value)} type="text" className="form-control mb-3" />
          {
            errorMessage &&
            <ErrorMessage
              errorMessage={errorMessage}
              type="alert"
            />
          }
        </div>
        <DialogActionsBar>
          <button type="button" className="k-button btn btn-secondary" onClick={onHide}>Cancel</button>
          <button type="submit" disabled={isSavingTheme} className="k-button btn btn-primary">
            {
              isSavingTheme &&
              <div className="spinner-border spinner-border-sm mr-2" role="status" />
            }
            <span>Save</span>
          </button>
        </DialogActionsBar>
      </form>
    </Dialog>
  )
}