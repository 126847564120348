import { SurveyCustomQuestionsOverviewCSSTabContent } from './SurveyCustomQuestionsOverviewCSSTabContent/SurveyCustomQuestionsOverviewCSSTabContent';
import { SurveyCustomQuestionsOverviewDefaultTabContent } from './SurveyCustomQuestionsOverviewDefaultTabContent/SurveyCustomQuestionsOverviewDefaultTabContent';
import { SurveyCustomQuestionsOverviewSettingsTabContent } from './SurveyCustomQuestionsOverviewSettingsTabContent/SurveyCustomQuestionsOverviewSettingsTabContent';
import { SurveyCustomQuestionsOverviewJavascriptTabContent } from './SurveyCustomQuestionsOverviewJavascriptTabContent/SurveyCustomQuestionsOverviewJavascriptTabContent';
import { SurveyCustomQuestionsAdvancedOverviewTabContent } from './SurveyCustomQuestionsAdvancedOverviewTabContent/SurveyCustomQuestionsAdvancedOverviewTabContent';

interface Props {
  tags: string[]
  selectedTab: number
  formData: any,
  onChangeHandler: (e: any) => void
}

export const SurveyCustomQuestionsOverviewTabs = ({ tags, selectedTab, formData, onChangeHandler }: Props) => {
  if (selectedTab === 0) {
    return (
      <SurveyCustomQuestionsAdvancedOverviewTabContent
        tags={tags}
        formData={formData}
        onChangeHandler={onChangeHandler}
      />
    )
  } else if (selectedTab === 1) {
    return (
      <SurveyCustomQuestionsOverviewJavascriptTabContent />
    )
  } else if (selectedTab === 2) {
    return (
      <SurveyCustomQuestionsOverviewCSSTabContent />
    )
  } else if (selectedTab === 3) {
    return (
      <SurveyCustomQuestionsOverviewSettingsTabContent />
    )
  } else if (selectedTab === 4) {
    return (
      <SurveyCustomQuestionsOverviewDefaultTabContent />
    )
  } else return null
}