import React, { useEffect, useState } from 'react';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { filterBy } from '@progress/kendo-data-query';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';

import { Icon } from '../../../../../shared/Icon/Icon';

export default ({ data, onNextStep, status, columnTypes, warnings, updateData }) => {
  const [state, setState] = useState({ skip: 0, take: 10 })
  const [filter, setFilter] = useState(undefined)
  const [editingElement, setEditingElement] = useState({ id: null, originalElement: null })
  const columns = Object.keys(data[0])
  const [rows, setRows] = useState([])
  const [deletedRows, setDeletedRows] = useState([])
  const [didMount, setDidMount] = useState(true)

  useEffect(() => {
    if (didMount || (JSON.stringify(data) !== JSON.stringify(rows))) {
      if (deletedRows.length === 0) {
        let deleted = []
        data.forEach(el => {
          if (el.isDeleted) {
            deleted.push(el.id)
          }
        })
        setDeletedRows(deleted)
      }
      let rows = data
      if (deletedRows.length > 0) {
        deletedRows.forEach(id => {
          if (data.find(el => el.customId === id)) {
            data.find(el => el.customId === id).isDeleted = true
          }
        })
      } else {
        rows.forEach(el =>
          el.isDeleted = false)
      }
      setRows(rows)
    }
    setDidMount(false)
  }, [didMount, deletedRows, data, rows])

  const pageChange = (e) => {
    setState({
      skip: e.page.skip,
      take: e.page.take,
    })
  }
  const onItemChange = (e, field) => {
    let data = rows
    data.find(el => el.customId === editingElement.id)[field] = e.value
    setRows(data)
  }

  const cancelEditing = () => {
    let data = rows
    let index = data.findIndex(el => el.customId === editingElement.id)
    data[index] = editingElement.originalElement
    setRows(data)
    setEditingElement({ id: null, editingElement: null })
  }

  const onDelete = (id) => {
    let rows = deletedRows
    if (deletedRows.length > 0 && deletedRows.includes(id)) {
      let index = deletedRows.indexOf(id)
      rows.splice(index, 1)
    } else {
      rows.push(id)
    }
    setDeletedRows(rows)
    const result = data.map(el => el.customId === id ? { ...el, isDeleted: deletedRows.find(deletedRow => deletedRow === id) ? false : true } : el);
    updateData(result, deletedRows);
    setDidMount(true)
  }

  return (
    <div className="d-flex flex-column h-100">
      <Grid
        className={status === "Imported" ? "imported-recipients-grid" : "recipients-grid"}
        data={filterBy(rows, filter).slice(state.skip, state.take + state.skip)}
        skip={state.skip}
        take={state.take}
        total={rows.length}
        pageable={true}
        onPageChange={pageChange}
        filterable
        filter={filter}
        onFilterChange={e => setFilter(e.filter)}
      >

        {
          columns.filter(item => item !== "isDeleted" && item !== 'id' && item !== 'customId').map((item, key) =>
            <GridColumn key={key} field={item} title={item} cell={(props) => {
              return (
                <td>{
                  editingElement.id !== props.dataItem.customId ?
                    <div className={deletedRows.includes(props.dataItem.customId) ? 'text-disabled' : 'text-dark'}>
                      {props.dataItem[props.field]}
                    </div>
                    :
                    <div>
                      {columnTypes[key] === 'text' ?
                        <Input defaultValue={props.dataItem[props.field]} onChange={(e) => onItemChange(e, props.field)} /> :
                        <NumericTextBox defaultValue={props.dataItem[props.field]} onChange={(e) => onItemChange(e, props.field)} />
                      }
                    </div>

                }
                </td>
              )
            }} />
          )
        }
        {status !== "Imported" &&
          <GridColumn filterable={false} cell={(props) => {
            return (
              <td>
                {editingElement.id !== props.dataItem.customId ?
                  <div className="d-flex justify-content-end">
                    <Button
                      disabled={deletedRows.includes(props.dataItem.customId)}
                      className="btn btn-outline-primary mr-2"
                      icon="fas fa fa-pen"
                      onClick={() => setEditingElement({ id: props.dataItem.customId, originalElement: props.dataItem })}></Button>
                    <Button icon={`fas fa ${deletedRows.includes(props.dataItem.customId) ? "fa-user-plus" : "fa-user-slash"}`}
                      className={`btn ${deletedRows.includes(props.dataItem.customId) ? "btn-outline-success" : "btn-outline-danger"} d-flex mr-2`}
                      onClick={() => onDelete(props.dataItem.customId)}></Button>
                  </div>
                  : <div className="d-flex justify-content-end">
                    <Button className="btn btn-primary mr-2" icon="fas fa fa-check" onClick={() => {
                      updateData(rows);
                      setEditingElement({ id: null, originalElement: null })
                    }
                    }></Button>
                    <Button className="btn btn-secondary d-flex mr-2" icon="fas fa fa-times" onClick={() => cancelEditing()}></Button>
                  </div>}
              </td>
            )
          }
          } />}
      </Grid >
      {status !== "Imported" &&
        <div>{warnings &&

          <div className="d-flex align-items-center alert alert-danger w-100 preview-warning shadow alert-dismissible fade show" role="alert" >
            <div className="w-100">
              <p className="m-0">Warnings: {warnings}</p>
            </div>
            <button type="button" data-dismiss="alert" aria-label="Close" className="btn btn-icon btn-transparent shadow-none px-1">
              <span aria-hidden="true"><Icon type="close" /></span>
            </button>
          </div>
        }
          <div className="d-flex flex-column justify-content-center align-items-center px-4 border-top btn-next h-64">

            <div className="w-100 d-flex justify-content-end ml-auto">
              <Button className="k-button btn-primary" onClick={() => onNextStep(rows, deletedRows)}>Next</Button>
            </div>
          </div>
        </div>
      }
    </div>

  )
}