import React, { useState } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { TextArea } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';

export default ({ content, handleClose, onSaveAdvanced }) => {
  const [data, setData] = useState(content)

  return (
    <Dialog title="Advanced answer control" onClose={handleClose} width="50%" height="60%">
      <div className="d-flex flex-column p-3 h-100">
        <TextArea className="h-100 w-100 k-textarea" defaultValue={data} onChange={(e) => setData(e.value)} />
      </div>
      <DialogActionsBar>
        <Button className="btn btn-secondary" onClick={handleClose}>Cancel</Button>
        <Button className="btn btn-primary" onClick={() => onSaveAdvanced(data)}>Save</Button>
      </DialogActionsBar>
    </Dialog>
  )
}