export const returnSubAccountStatus = (isRegistered: boolean, type: string) => {
  if (type === 'color') {
    if (isRegistered) {
      return 'success'
    } else {
      return 'primary'
    }
  } else if (type === 'message') {
    if (isRegistered) {
      return 'Registered'
    } else {
      return 'Pending'
    }
  } else if (type === 'icon') {
    if (isRegistered) {
      return 'fas fa-check-circle'
    } else {
      return 'fas fa-hourglass-half'
    }
  }
}