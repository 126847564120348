import { useState } from 'react';
import Editor from '@monaco-editor/react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { ConflictingCodesModal } from './ConflictingCodesModal/ConflictingCodesModal';
import type { RootState } from '../../../../../../../../../../store/reducers/rootReducer';
import { ErrorMessage } from '../../../../../../../../../shared/ErrorMessage/ErrorMessage';
import { returnValidJson } from '../../../../../../helpers/returnValidJson/returnValidJson';
import { populateCodeMapData } from '../../../../../../helpers/populateCodeMapData/populateCodeMapData';
import type { SetAnswerSorting, SetCategorySorting } from '../../../../../../../../../../interfaces/workflowInterfaces/toolInterfaces';
import { fetchPatchJson, fetchPostJson } from '../../../../../../../../../../services/services';

interface Code {
  code: number
  type: "Created" | "Existing"
}

interface Props {
  onHide: () => void
  fetchData: {
    toolId: string
    projectId: string
    codeMapId: string
    workflowId: string
  }
  setCategorySorting: SetCategorySorting
  setAnswerSorting: SetAnswerSorting
}

export const ImportCodeFrameModal = ({ onHide, fetchData, setCategorySorting, setAnswerSorting }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch();
  const { projectId, workflowId, toolId, codeMapId } = fetchData;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [codeFrameJson, setCodeFrameJson] = useState('');
  const [conflictCodes, setConflictCodes] = useState<Code[]>([]);
  const [showConflictingCodesModal, setShowConflictingCodesModal] = useState(false);

  const onImportHandler = () => {
    const validCodeFrameJson = returnValidJson(codeFrameJson);
    if (validCodeFrameJson) {
      setIsLoading(true);
      fetchPostJson(`projects/${projectId}/workflows/${workflowId}/tools/${toolId}/code-maps/${codeMapId}/codeframe-exchange-check`, token, JSON.parse(codeFrameJson))
        .then((res: TODO) => {
          if (res?.error || res?.message) {
            setIsLoading(false);
            setErrorMessage(res.message || res.error);
          } else {
            setConflictCodes(res.conflictCodes)
            if (res.conflictCodes.length === 0) {
              finalizeCodeframeImport();
            } else {
              setIsLoading(false);
              setShowConflictingCodesModal(true);
            }
          }
        })
    } else {
      setErrorMessage('Please enter a valid JSON code frame to proceed')
    }
  }

  const finalizeCodeframeImport = () => {
    const validCodeFrameJson = returnValidJson(codeFrameJson);
    if (validCodeFrameJson) {
      fetchPatchJson(`projects/${projectId}/workflows/${workflowId}/tools/${toolId}/code-maps/${codeMapId}/codeframe-exchange`, token, validCodeFrameJson)
        .then((res: TODO) => res.json())
        .then((resData: TODO) => {
          if (resData?.error || resData?.message) {
            setIsLoading(false);
            setErrorMessage(resData.message || resData.error);
          } else {
            setIsLoading(false);
            populateCodeMapData(resData, setCategorySorting, setAnswerSorting, dispatch);
            onHide();
          }
        })
    } else {
      setErrorMessage('Please enter a valid JSON code frame to proceed')
    }
  }

  return (
    <Dialog width={"60%"} height={"90%"} className='import-codeframe-modal' title="Import code frame" onClose={onHide}>
      {
        showConflictingCodesModal &&
        <ConflictingCodesModal
          fetchData={fetchData}
          codeFrameJson={codeFrameJson}
          conflictCodes={conflictCodes}
          onHideParent={onHide}
          setAnswerSorting={setAnswerSorting}
          setCategorySorting={setCategorySorting}
          onHide={() => setShowConflictingCodesModal(false)}
        />
      }
      {
        errorMessage &&
        <ErrorMessage type="alert" errorMessage={errorMessage} />
      }
      <Editor
        theme='vs-dark'
        defaultLanguage='json'
        value={codeFrameJson}
        onChange={(value) => setCodeFrameJson(value || '')}
      />
      <DialogActionsBar>
        <button type='button' className="btn btn-secondary mr-2" onClick={onHide}>Close</button>
        <button type='button' className="btn btn-primary" disabled={isLoading || codeFrameJson === ''} onClick={onImportHandler}>
          {
            isLoading &&
            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" />
          }
          <span>Import</span>
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}