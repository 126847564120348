import { useEffect } from "react"

import usePrevious from "./usePrevious"
import type { SubAccount, SubAccountFormData } from "../../../interfaces/adminInterfaces/adminInterfaces"

export const useUpdateSubAccountsFormData = (formData: SubAccountFormData, setFormData: (val: SubAccountFormData) => void, subAccount?: SubAccount | null,) => {
  const prevSubAccount = usePrevious(subAccount)

  useEffect(() => {
    if (subAccount && subAccount !== prevSubAccount) {
      setFormData({
        ...formData,
        subAccountName: subAccount.name,
        subAccountDescription: subAccount.description,
        subAccountShareName: subAccount.invitationName,
        subAccountShareMessage: subAccount.invitationMessage
      })
    }
  }, [formData, subAccount, prevSubAccount, setFormData])
}