import React, { useState } from 'react';
import JsxParser from 'react-jsx-parser';
import { useHistory } from 'react-router-dom';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { fetchPost as respondToInvitation } from '../../../services/services';

export default ({ onHide, invitation, token, ...props }) => {
  const [showError, setShowError] = useState(false)
  const history = useHistory()

  const onActionHandler = (e, action) => {
    respondToInvitation(`share/${invitation.projectId}/invite/${action}`, token)
      .then(res => {
        if (res.status === 200) {
          if (action === 'accept') {
            history.push(`/projects/${invitation.projectId}`)
          } else {
            setShowError(false)
            onHide()
          }
        } else {
          setShowError(true)
        }
      })
  }

  return (
    <Dialog minWidth={500} title={`Respond to invitation`} onClose={onHide}>
      <div className="alert alert-walr shadow-sm text-center mb-3 px-2">
        <span>Would you like to accept this invitation for <strong>{invitation.projectName}</strong>?</span>
      </div>
      {
        invitation.invitedByMessage &&
        <React.Fragment>
          <div className="d-flex align-items-center mb-2 mr-2 alert-light-blue bg-white">
            <div className="d-flex justify-content-center align-items-center rounded-circle bg-primary text-white mr-2" style={{ width: '24px', height: '24px' }}>{invitation.invitedByName[0]}</div>
            <span>{`${invitation.invitedByName}:`}</span>
          </div>
          <div className="d-flex">
            <div className="alert-light-blue py-2 px-3 rounded">
              <JsxParser jsx={invitation.invitedByMessage} />
            </div>
          </div>
        </React.Fragment>
      }

      {
        showError &&
        <div class="alert alert-danger text-center mt-3 mb-0" role="alert">
          <span>Something went wrong. Please try again!</span>
        </div>
      }

      <DialogActionsBar>
        <button className="k-button btn btn-primary" onClick={(e) => onActionHandler(e, 'reject')}>Reject</button>
        <button className="k-button btn-walr" onClick={(e) => onActionHandler(e, 'accept')}>Accept</button>
      </DialogActionsBar>
    </Dialog>
  )
}