import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input, type InputChangeEvent } from '@progress/kendo-react-inputs';

import type { Tag } from '../TagsTabContent';
import type { RootState } from '../../../../../store/reducers/rootReducer';
import { ErrorMessage } from '../../../../shared/ErrorMessage/ErrorMessage';
import { fetchPutResOrJson as updateTag } from '../../../../../services/services';

type Props = {
  handleClose: () => void;
  updateTags: () => void;
  tag?: Tag | null;
}

export const EditTagModal = ({ handleClose, updateTags, tag }: Props) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [tagName, setTagName] = useState(tag?.name || "")
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = () => {
    if (tag?.id) {
      setIsLoading(true);
      const body = { "name": tagName }
      updateTag(`tags/${tag.id}`, token, body)
        .then((res: TODO) => {
          setIsLoading(false);
          if (res && res.status === 200) {
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The tag has been updated successfully' } })
            handleClose()
            updateTags()
          } else if (res && (res.message || res.error)) {
            setErrorMessage(res.message ? res.message : res.error)
          }
        })
    }
  }

  return (
    <Dialog title="Edit tag"
      onClose={handleClose}
      className="d-flex flex-column"
      width="40%">
      <div className="d-flex flex-column p-4 gap-md">
        <p className="m-0">Name</p>
        <Input
          required
          name="name"
          className="w-100"
          placeholder="Enter name"
          value={tagName}
          onChange={(e: InputChangeEvent) => setTagName(e.value)}
        />
      </div>
      {
        errorMessage &&
        <div className="mx-4">
          <ErrorMessage
            type="alert"
            errorMessage={errorMessage} />
        </div>
      }
      <DialogActionsBar>
        <button type='button' className="k-button btn btn-secondary" onClick={handleClose}>Cancel</button>
        <button disabled={isLoading} type='button' className="k-button btn btn-primary" onClick={onSubmitHandler}>Update</button>
      </DialogActionsBar>
    </Dialog>
  )
}
