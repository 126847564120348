import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { Icon } from '../../../../../../shared/Icon/Icon';

interface IProps {
  onListClick: (key: number) => void
  onListItemMoreClick: (itemText: string, key: number, id: string) => void
}

export const SurveyDesignListColumn: React.FC<IProps> = ({ onListClick, onListItemMoreClick }) => {
  const { theData } = useSelector((theState: any) => (theState.surveyInitialDataReducer));
  const [showDeleteListModal, setShowDeleteListModal] = useState<{ show: boolean, key: number, id: string }>({ show: false, key: 0, id: "" });

  return (
    <React.Fragment>
      {showDeleteListModal.show &&
        <Dialog title="Delete list" width={400} onClose={() => setShowDeleteListModal({ show: false, key: 0, id: "" })}>
          <div className="p-5">Are you sure you want to delete this <strong>list</strong>?</div>
          <DialogActionsBar>
            <button className="btn btn-secondary fw-bold mr-2" onClick={() => setShowDeleteListModal({ show: false, key: 0, id: "" })}>Cancel</button>
            <button className="btn btn-danger fw-bold" onClick={() => { onListItemMoreClick("Delete", showDeleteListModal.key, showDeleteListModal.id); setShowDeleteListModal({ show: false, key: 0, id: "" }) }}>Confirm</button>
          </DialogActionsBar>
        </Dialog>
      }
      {theData.originalData.lists?.length > 0 ?
        theData.originalData.lists.map((el: any, key: number) => {
          return (
            <div key={key} className=' d-flex flex-row justify-content-between align-items-center survey-list-item cursor-pointer p-1'>
              <div onClick={() => onListClick(key)} className='d-flex flex-row align-items-center list-row'>
                <div className='icon-spacing mr-1'><span className='icon'><Icon className="icon-fill" type="lists" /></span></div>
                <span className='text-truncate' role="heading">
                  {el.attributes.id}
                </span>
              </div>
              <div className="d-flex justify-content-start actions">
                <Tooltip anchorElement="target" position="top">
                  <button title='Rename list' onClick={() => onListItemMoreClick("Rename", key, el.attributes.id)} className="btn btn-icon p-0 mr-1">
                    <Icon type="dashboard-edit" className='pe-none' />
                  </button>
                </Tooltip>
                <Tooltip anchorElement="target" position="top">
                  <button title='Delete list' onClick={() => setShowDeleteListModal({ show: true, key: key, id: el.attributes.id })} className="btn btn-icon p-0">
                    <Icon type="delete-alt" className='pe-none' />
                  </button>
                </Tooltip>
              </div>
            </div>
          )
        })
        : null}
    </React.Fragment >
  )
}
