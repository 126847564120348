import { process } from "@progress/kendo-data-query"

export const sortProjectDocuments = (projectDocuments: any, updatedDirection: string, sortBy: string) => {
  let updatedProjectDocuments = [...projectDocuments]

  if (sortBy === 'name') {
    if (updatedDirection === 'asc') {
      updatedProjectDocuments.sort(function (a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    } else if (updatedDirection === 'desc') {
      updatedProjectDocuments.sort(function (a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
      });
    }
  } else if (sortBy === 'date') {
    if (updatedDirection === 'asc') {
      updatedProjectDocuments.sort(function compare(a, b) {
        var dateA = new Date(a.createdUtc);
        var dateB = new Date(b.createdUtc);
        return (dateA < dateB) ? -1 : (dateA > dateB) ? 1 : 0;
      });
    } else if (updatedDirection === 'desc') {
      updatedProjectDocuments.sort(function compare(a, b) {
        var dateA = new Date(a.createdUtc);
        var dateB = new Date(b.createdUtc);
        return (dateA > dateB) ? -1 : (dateA < dateB) ? 1 : 0;
      });
    }
  }

  return {
    sortBy: sortBy,
    projectsDocuments: {
      result: process(updatedProjectDocuments.slice(0), { take: 10, skip: 0 }),
      dataState: { take: 10, skip: 0 },
      documents: updatedProjectDocuments
    }
  }
}