import { type FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '../../Icon/Icon';

interface Props {
  id?: string,
  updateFreshworksWidget: (type: string, id?: string | number) => void;
}

interface RecommendedLink {
  text: string
  link: string
  icon: "help-whatsnew" | "help-browse" | "help-report"
}

const helpCentreOtherLinks: { text: string, link: string }[] = [
  { text: 'About', link: 'https://walr.com/company/about/' },
  { text: 'Blog', link: 'https://walr.com/blog/' },
  { text: 'Careers', link: 'https://careers.walr.com/' },
  { text: 'Tech Hub', link: 'https://walr.com/tech-hub/' },
  { text: 'Privacy', link: 'https://walr.com/privacy-policy/' },
  { text: 'Terms and conditions', link: 'https://walr.com/app/uploads/2022/12/Walr-Terms-and-Conditions.pdf' }
]

const helpCentreRecommendedLinks: RecommendedLink[] = [
  { text: "Browse knowledge base", icon: "help-browse", link: "https://support.walr.com/support/home" },
  { text: "Report an issue", icon: "help-report", link: "https://support.walr.com/support/tickets/new" }
]

export const HelpCentreDropdownItem = ({ id, updateFreshworksWidget }: Props) => {
  const [searchData, setSearchData] = useState("")

  const onSubmitSearch = (e: FormEvent) => {
    e.preventDefault()
    if (searchData) {
      window.open(`https://support.walr.com/support/search?term=${searchData}`, '_blank', 'noopener,noreferrer');
    }
  }

  if (id === 'primary') {
    return (
      <div className="user-dropdown-info">
        <p className='help-header mb-3 h6'>Help Center</p>
        <form onSubmit={onSubmitSearch}>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text bg-white px-2">
                <Icon type="search" className='fill-disabled' />
              </span>
            </div>
            <input
              name='Find answers quickly'
              type="text"
              value={searchData}
              className="form-control h-auto"
              placeholder="Find answers quickly"
              onChange={(e) => setSearchData(e.target.value)}
            />
          </div>
        </form>
        <p className="label h6 m-0">Recommended</p>
        <button type='button' onClick={() => updateFreshworksWidget('openArticle', 101000399001)} className="d-flex justify-content-start align-items-center mx-0 mt-0 mb-2 p-0 btn btn-link recommended-item">
          <Icon type="help-article" />
          <p className="m-0 ml-1">Access the Walr Platform (Sign In)</p>
        </button>
        <button type='button' onClick={() => updateFreshworksWidget('openArticle', 101000390655)} className="d-flex justify-content-start align-items-center mx-0 mt-0 mb-2 p-0 btn btn-link recommended-item">
          <Icon type="help-article" />
          <p className="m-0 ml-1">How Sessions Work</p>
        </button>
        <button type='button' onClick={() => updateFreshworksWidget('openArticle', 101000399137)} className="d-flex justify-content-start align-items-center mx-0 mt-0 p-0 btn btn-link recommended-item">
          <Icon type="help-article" />
          <p className="m-0 ml-1">Creating Custom Questions</p>
        </button>
      </div>
    )
  } if (id === 'secondary') {
    return (
      <div className="user-dropdown-info">
        <button type='button' onClick={() => updateFreshworksWidget('openTicketForm')} className="d-flex justify-content-start align-items-center mx-0 mt-0 mb-2 p-0 btn btn-link recommended-item">
          <Icon type="help-contact" />
          <p className="m-0 ml-2">Contact support</p>
        </button>
        {
          helpCentreRecommendedLinks.map((recommendedLink, key) => (
            <Link key={recommendedLink.text} className="d-flex justify-content-between" to={{ pathname: recommendedLink.link }} target="_blank" rel="noopener noreferrer">
              <div className={`d-flex align-items-center ${key === helpCentreRecommendedLinks.length - 1 ? '' : 'mb-2'}`}>
                <Icon type={recommendedLink.icon} />
                <p className="m-0 ml-2">{recommendedLink.text}</p>
              </div>
              <Icon type="help-external" />
            </Link>
          ))
        }
      </div >
    )
  } if (id === 'other') {
    return (
      <div className="user-dropdown-info flex-row flex-wrap py-2 mt-2">
        {
          helpCentreOtherLinks.map((otherItem) => (
            <Link
              key={otherItem.text}
              target="_blank"
              rel="noopener noreferrer"
              className="text-muted mr-2 mb-1"
              to={{ pathname: otherItem.link }}>{otherItem.text}
            </Link>
          ))
        }
      </div>
    )
  } return null
}