import React, { Fragment } from 'react';
import { ColorPicker } from '@progress/kendo-react-inputs';

import { Avatar } from '../../../shared/Avatar/Avatar';

export const AddProjectBranding = ({ onChangeHandler, formData, setShowFileManager }) => {
  console.log(formData);
  return (
    <div className='d-flex flex-column gap-xl'>
      <div className="d-flex flex-column align-items-center gap-lg">
        <Avatar
          backgroundColor={formData.iconBackgroundColor}
          image={formData.iconUrl ? formData.iconUrl : ''}
          color={formData.iconBackgroundColor ? "" : "secondary"}
          icon="far fa-folder"
          rounded="small"
          iconColor="text-dark"
          class="avatar-xl m-0"
        />
        <p className="text-muted h5 m-0">Preview</p>
      </div>

      <div className="d-flex flex-column form-group m-0">
        <div className="input-group">
          <input
            disabled
            type="text"
            name="iconUrl"
            className="form-control h-32"
            placeholder={formData.iconUrl ? formData.iconUrl : "Select an image"} />
          <div className="input-group-append">
            <button onClick={setShowFileManager} type="button" className="btn btn-primary">Browse</button>
            <button onClick={() => onChangeHandler({ target: { value: '' } }, 'iconUrl')} type="button" className="btn btn-secondary">Clear</button>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between">
        <div className="input-group">
          <input
            disabled
            type="text"
            className="form-control h-32"
            placeholder="Select background color"
          />
          <div className="input-group-append h-32">
            <ColorPicker
              view="gradient"
              className='h-32'
              onChange={(e) => onChangeHandler(e, 'iconBackgroundColor')} />
          </div>
        </div>
      </div>
    </div>
  )
}