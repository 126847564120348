const initialState = {
  advancedImportStatusData: {
    isInProgress: false,
    progressStatus: '',
    isProgressCompleted: false,
    uploadErrorMessage: null,
    importErrorMessage: null,
    redirect: false,
    uploadId: null,
    redirectData: null
  },
  isAdvancedImportModalShown: false
};

function advancedImportStatusReducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_ADVANCED_IMPORT_STATUS':
      return {
        ...state,
        advancedImportStatusData: action.payload
      }
    case 'HIDE_ADVANCED_IMPORT_NOTIFICATION':
      return {
        ...state,
        advancedImportStatusData: {
          ...state.advancedImportStatusData,
          isInProgress: false,
          isProgressCompleted: false
        }
      }
    case 'DISPLAY_ADVANCED_IMPORT_MODAL':
      return {
        ...state,
        advancedImportStatusData: { ...state.advancedImportStatusData },
        isAdvancedImportModalShown: action.payload
      }
    case 'UPDATE_ADVANCED_IMPORT_REDIRECT':
      return {
        ...state,
        advancedImportStatusData: {
          ...state.advancedImportStatusData,
          redirect: false
        },
        isAdvancedImportModalShown: false
      }
    case 'COMPLETE_ADVANCED_IMPORT':
      return {
        ...state,
        advancedImportStatusData: {
          ...state.advancedImportStatusData,
          isInProgress: false,
          progressStatus: '',
          isProgressCompleted: true,
          redirect: true,
          redirectData: action.payload,
          formData: action.payload.formData
        }
      }
    default:
      return state;
  }
}

export default advancedImportStatusReducer;