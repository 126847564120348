export const returnEmptyAndDuplicatedCodes = (theData: any) => {
  let hasEmptyCodes = false
  let hasDuplicateCodes = false

  if (theData.selectedItem && theData.selectedItem.type === 'ques') {
    const seen = new Set();
    const subquestion = theData.selectedItem.elements.filter((el: { type: string }) => el.type === 'subq')[theData.selectedSubquestion]
    let elements = []
    if (subquestion && subquestion?.attributes.type === 'rm' || subquestion?.attributes.type === 'rn') {
      elements = subquestion.elements.find((el: { type: string }) => el.type === 'hgroup').elements.filter((el: { type: string }) => el.type === 'h')
    } else if (subquestion && subquestion?.attributes.type === 'm' || subquestion?.attributes.type === 'n') {
      elements = theData.selectedItem.elements.find((el: { type: string }) => el.type === 'rgroup').elements.filter((el: { type: string }) => el.type === 'r')
    }

    hasEmptyCodes = elements.filter((el: { attributes: { code?: string } }) => el.attributes.code === undefined || el.attributes.code === "").length > 0 ? true : false
    hasDuplicateCodes = elements.some(function (currentObject: any) {
      return seen.size === seen.add(currentObject.attributes.code).size;
    });
  }

  return {
    hasEmptyCodes: hasEmptyCodes,
    hasDuplicateCodes: hasDuplicateCodes
  }
}