import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar } from '../shared/Avatar/Avatar';
import type { RootState } from '../../store/reducers/rootReducer';
import { AdminTabContent } from './AdminTabContent/AdminTabContent';
import { returnTabs } from '../shared/helpers/returnTabs/returnTabs';
import { AdminHeader } from './AdminTabContent/AdminHeader/AdminHeader';
import type { User } from '../../interfaces/userInterface/userInterface';
import type { Tab, UserSelectedTab } from '../../interfaces/adminInterfaces/adminInterfaces';
import { CreateGroupModal } from './AdminTabContent/GroupsTabContent/CreateGroupModal/CreateGroupModal';
import { SMSProviderTypeModal } from './AdminTabContent/EmailTabContent/SMSProviderTypeModal/SMSProviderTypeModal';
import { EditSubAccountModal } from './AdminTabContent/SubAccountsTabContent/EditSubAccountModal/EditSubAccountModal';
import { EmailProviderTypeModal } from './AdminTabContent/EmailTabContent/EmailProviderTypeModal/EmailProviderTypeModal';
import { EditEmailProviderModal } from './AdminTabContent/EmailTabContent/EditEmailProviderModal/EditEmailProviderModal';
import { InviteUserModal } from './AdminTabContent/UsersTabContent/InvitedUsersTabContent/InviteUserModal/InviteUserModal';
import { EditSendgridProviderModal } from './AdminTabContent/EmailTabContent/EditSendgridProviderModal/EditSendgridProviderModal';
import { SubAccountsInviteUsersModal } from './AdminTabContent/SubAccountsTabContent/SubAccountsInviteUsersModal/SubAccountsInviteUsersModal';
import { EditTwilioSmsProviderModalContent } from './AdminTabContent/EmailTabContent/EditTwilioSmsProviderModalContent/EditTwilioSmsProviderModalContent';

interface Props {
  user: User
  token: string
}

export const Admin = ({ user }: Props) => {
  document.title = "Admin – Walr";
  const dispatch = useDispatch();
  const [tabSelected, setTabSelected] = useState(0);
  const [usersSelectedTab, setUsersSelectedTab] = useState<UserSelectedTab>('Active');
  const {
    showCreateGroup,
    showEmailProvidersModal,
    showSendGridProvidersModal,
    showSubAccountModal,
    showEmailProviderTypeModal,
    showSMSProviderTypeModal,
    showInviteUserModal,
    showSubAccountInviteUsersModal,
    showTwilioSmsProvidersModal
  } = useSelector((theState: RootState) => theState.adminReducer);

  const tabs: Tab[] = returnTabs(user);

  const selectAction = (e: { target: { id: string } }) => {
    const buttonId = e.target.id
    switch (buttonId) {
      case "Invite user":
        dispatch({ type: "UPDATE_SHOW_INVITE_USER_MODAL", payload: true });
        break;
      case 'Create group':
        dispatch({ type: "UPDATE_SHOW_ADD_GROUP_MODAL", payload: true });
        break;
      case 'Create Email Provider':
        dispatch({ type: 'UPDATE_SHOW_EMAIL_PROVIDER_TYPE_MODAL', payload: true });
        break;
      case 'Create SMS Provider':
        dispatch({ type: 'UPDATE_SHOW_SMS_PROVIDER_TYPE_MODAL', payload: true });
        break;
      case 'Create Sub Account':
        dispatch({ type: 'UPDATE_SHOW_SUB_ACCOUNTS_PROVIDERS_MODAL', payload: { subAccount: null, show: true } });
        break;
      case 'Create API Key':
        dispatch({ type: 'UPDATE_SHOW_API_KEYS_MODAL', payload: { show: true, apiKey: null } });
        break;
      case 'Create Panel Provider':
        dispatch({ type: 'UPDATE_SHOW_CREATE_PANEL_PROVIDERS_MODAL', payload: true });
        break;
    }
  }

  const handleCloseEmailProvidersModal = (type: string) => {
    dispatch({ type: 'UPDATE_EMAIL_PROVIDERS_ERROR_MESSAGE', payload: null })
    dispatch({ type: `UPDATE_SHOW_${type}_PROVIDERS_MODAL`, payload: { emailProvider: null, show: false } })
  }

  const handleCloseSubAccountsModal = () => {
    dispatch({ type: 'UPDATE_SUB_ACCOUNTS_ERROR_MESSAGE', payload: null })
    dispatch({ type: 'UPDATE_SHOW_SUB_ACCOUNTS_PROVIDERS_MODAL', payload: { show: false, subAccount: null } })
  }

  const onSelectEmailProviderType = (type: string) => {
    dispatch({ type: 'UPDATE_SHOW_EMAIL_PROVIDER_TYPE_MODAL', payload: false })
    if (type === 'smtp') {
      dispatch({ type: 'UPDATE_SHOW_EMAIL_PROVIDERS_MODAL', payload: { emailProvider: null, show: true } })
    } else if (type === 'sendgrid') {
      dispatch({ type: 'UPDATE_SHOW_SENDGRID_PROVIDERS_MODAL', payload: { emailProvider: null, show: true } })
    }
  }

  const onSelectSMSProviderType = (type: string) => {
    dispatch({ type: 'UPDATE_SHOW_SMS_PROVIDER_TYPE_MODAL', payload: false })
    if (type === 'twilio') {
      dispatch({ type: 'UPDATE_SHOW_TWILIO_SMS_PROVIDERS_MODAL', payload: { show: true, smsProvider: null } })
    }
  }

  return (
    <Fragment>
      {
        showInviteUserModal &&
        <InviteUserModal
          updateInvitedUsers={() => dispatch({ type: 'UPDATE_INVITED_USERS', payload: true })}
          handleClose={() => dispatch({ type: "UPDATE_SHOW_INVITE_USER_MODAL", payload: false })}
        />
      }
      {
        showCreateGroup &&
        <CreateGroupModal
          updateGroups={() => dispatch({ type: "SHOULD_UPDATE_GROUPS", payload: true })}
          handleClose={() => dispatch({ type: "UPDATE_SHOW_ADD_GROUP_MODAL", payload: false })}
        />
      }
      {
        showEmailProviderTypeModal &&
        <EmailProviderTypeModal
          onHide={() => dispatch({ type: 'UPDATE_SHOW_EMAIL_PROVIDER_TYPE_MODAL', payload: false })}
          onSelect={onSelectEmailProviderType}
        />
      }
      {
        showSMSProviderTypeModal &&
        <SMSProviderTypeModal
          onHide={() => dispatch({ type: 'UPDATE_SHOW_SMS_PROVIDER_TYPE_MODAL', payload: false })}
          onSelect={onSelectSMSProviderType}
        />
      }
      {
        showEmailProvidersModal.show &&
        <EditEmailProviderModal
          emailProvider={showEmailProvidersModal.emailProvider}
          handleClose={() => handleCloseEmailProvidersModal('EMAIL')}
        />
      }
      {
        showSendGridProvidersModal.show &&
        <EditSendgridProviderModal
          emailProvider={showSendGridProvidersModal.emailProvider}
          handleClose={() => handleCloseEmailProvidersModal('SENDGRID')}
        />
      }
      {
        showTwilioSmsProvidersModal.show &&
        <EditTwilioSmsProviderModalContent
          smsProvider={showTwilioSmsProvidersModal.smsProvider}
          handleClose={() => handleCloseEmailProvidersModal('TWILIO_SMS')}
        />
      }
      {
        showSubAccountModal.show &&
        <EditSubAccountModal
          user={user}
          subAccount={showSubAccountModal.subAccount}
          handleClose={handleCloseSubAccountsModal}
        />
      }
      {
        showSubAccountInviteUsersModal.show &&
        <SubAccountsInviteUsersModal
          reloadSubAccounts={() => { }}
          onHide={() => dispatch({ type: 'UPDATE_SHOW_SUB_ACCOUNTS_INVITE_USERS_MODAL', payload: { show: false, subAccount: null } })}
        />
      }
      <section className="main admin-container">
        <div className="d-flex h-100">
          <div className="col-lg-2 p-0">
            <div className="d-flex flex-column position-sticky vh-100 overflow-auto" style={{ top: 0 }}>
              <div className="d-flex flex-column align-items-center py-3 border-right">
                <Avatar class="avatar-lg m-0" icon="fas fa-lg fa-user-shield" color="primary" />
                <h1 className="text-primary mb-2 mt-3 h5">Admin settings</h1>
                <small title={user.email} className="text-muted text-center text-truncate w-100 m-0">{user.email}</small>
              </div>
              <div className="list-group list-group-flush border-top border-bottom border-right h-100">
                {
                  tabs.map((el, id) => (
                    <button style={{ outline: 'none' }} onClick={() => setTabSelected(id)} key={el.id} type="button" className={`list-group-item list-group-item-action p-2 ${tabSelected === id ? 'active pe-none' : ''}`}>
                      <span className="d-flex align-items-center gap-md">
                        {
                          el.icon ?
                            <Avatar
                              icon={el.icon}
                              class={`avatar-sm m-0 ${tabSelected === id ? 'text-primary' : 'text-white'}`}
                              color={`${tabSelected === id ? 'white' : 'primary'}`} />
                            : null
                        }
                        <p title={el.title} className="mb-0 text-truncate w-100 h6">{el.title}</p>
                      </span>
                    </button>
                  ))
                }
              </div>
            </div>
          </div>
          <div className="col-lg-10 p-0">
            <AdminHeader
              title={tabs[tabSelected].title}
              selectAction={selectAction}
              setUsersSelectedTab={(e: UserSelectedTab) => setUsersSelectedTab(e)}
            />
            <AdminTabContent
              tabType={tabs[tabSelected].id}
              usersSelectedTab={usersSelectedTab}
            />
          </div>
        </div>
      </section>
    </Fragment >
  )
}
