import React, { type FC, useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import Pagination from '../../../shared/Pagination/Pagination';
import { parseJwt } from '../../../shared/helpers/decodeJWT/decodeJWT';
import type { RootState } from '../../../../store/reducers/rootReducer';
import { DropDownIcon } from '../../../shared/DropDownIcon/DropDownIcon';
import returnDateFormat from '../../../Billing/helpers/returnDateFormat.js/returnDateFormat';
import type { State, editModalState } from '../../../../interfaces/backofficeInterfaces/backofficeInterfaces';
import { fetchGetJson } from '../../../../services/services';

interface Props {
  state: State,
  setEditOrAddModalOpen: React.Dispatch<React.SetStateAction<editModalState>>,
  setCustomerID: React.Dispatch<React.SetStateAction<string>>,
  editOrAddModalOpen: editModalState,
  handleClick: () => void
  setCustomersData: (data: TODO) => void
}

const PageSize = 40;

export const Customers: FC<Props> = ({ state, setEditOrAddModalOpen, setCustomerID, editOrAddModalOpen, handleClick, setCustomersData }) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const [inputValue, setInputValue] = useState<string>("")
  const [currentPage, setCurrentPage] = useState(1);
  const [filterTrialStatus, setFilterTrialStatus] = useState<"All" | "Active" | "Non Active">("All");
  const parsedJwt = parseJwt(token)

  useEffect(() => {
    fetchGetJson('backoffice/customers', token)
      .then((res: TODO) => {
        if (res && !res.error && !res.message) {
          setCustomersData((s: TODO) => {
            return {
              ...s,
              data: res
            }
          })
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
        }
      })
  }, [])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setInputValue(e.target.value)
  };

  //this function returns the page from pagination
  const currentTableData: object[] = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return state.data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, state]);

  //this function gets a customer by id and opens the edit modal
  const onRowClick = (el: TODO) => {
    setCustomerID(el.customerId)
    //@ts-ignore
    fetchGetJson(`/backoffice/customers/${el.customerId}`, token)
      .then((res: TODO) => {
        if (res.error || res.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error } });
        } else {
          setEditOrAddModalOpen({
            ...editOrAddModalOpen,
            shouldModalOpen: true,
            whichModalOpens: "edit",
            body: res
          })
        }
      })
  };

  const filterIsTrial = (isTrial: boolean, trialEndTimeUtc: string) => isTrial || (new Date(trialEndTimeUtc) >= new Date())

  const filterIsNotTrial = (isTrial: boolean, trialEndTimeUtc: string) => !isTrial && !(new Date(trialEndTimeUtc) >= new Date())

  const tableContent = () => {
    if (inputValue.length > 0 && filterTrialStatus === "All") {
      return state.data.filter((val: TODO) => {
        if (inputValue === "" && inputValue === undefined) { return val }
        return val?.name?.toLowerCase().includes(inputValue?.toLowerCase())
      })
    }
    if (filterTrialStatus !== "All" && inputValue.length === 0) {
      return state.data.filter((val: TODO) => {
        if (filterTrialStatus === "Active") { return filterIsTrial(val.isTrial, val.trialEndTimeUtc) }
        if (filterTrialStatus === "Non Active") { return filterIsNotTrial(val.isTrial, val.trialEndTimeUtc) }
        return val?.name?.toLowerCase().includes(inputValue?.toLowerCase());
      })
    }
    if (filterTrialStatus !== "All" && inputValue.length > 0) {
      return state.data.filter((val: TODO) => {
        if (filterTrialStatus === "Active") {
          return inputValue === "" ? filterIsTrial(val.isTrial, val.trialEndTimeUtc) : filterIsTrial(val.isTrial, val.trialEndTimeUtc) && val?.name?.toLowerCase().includes(inputValue?.toLowerCase());
        }
        if (filterTrialStatus === "Non Active") {
          return inputValue === "" ? filterIsNotTrial(val.isTrial, val.trialEndTimeUtc) : filterIsNotTrial(val.isTrial, val.trialEndTimeUtc) && val?.name?.toLowerCase().includes(inputValue?.toLowerCase());
        }
        return val?.name?.toLowerCase().includes(inputValue?.toLowerCase());
      })
    }
    return currentTableData.filter((val: TODO) => val?.name?.toLowerCase().includes(inputValue?.toLowerCase()));
  }

  return (
    <React.Fragment>
      <div className="d-flex flex-row mr-4">
        <form className="input-group search-bar mb-2">
          <input
            type="text"
            className="form-control ml-4 mr-3 px-3"
            placeholder="Search organisation"
            aria-label="Search organisation"
            aria-describedby="basic-addon2"
            value={inputValue || ""}
            onChange={(e) => handleInputChange(e)}
          />
        </form>
        <button type='button' id="add-organisation" className={`${!parsedJwt[`${import.meta.env.VITE_APP_AUTH0_NAMESPACE}/backofficeadmin`] ? "dispay-none-the-item pointer-events-none" : "btn btn-primary btn-cta"}`} onClick={() => handleClick()}>Add&nbsp;organisation</button>
      </div>
      {state.data &&
        <div className="p-0">
          <table className="table table-customers">
            <thead>
              <tr>
                <th className='pl-4'>Name</th>
                <th>Location</th>
                <th>
                  Status
                  <DropDownIcon
                    icon={"fas fa-filter ml-3 cursor-pointer"}
                    filterTrialStatus={filterTrialStatus}
                    setFilterTrialStatus={setFilterTrialStatus}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {tableContent().map((el: TODO) => {
                return (
                  // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                  <tr className="my-1 cursor-pointer" onClick={() => onRowClick(el)} key={el.id}>
                    <td className="text-left w-50 pl-4">
                      <span className='name'>{el.name}</span>
                    </td>
                    <td className="text-left w-25">
                      {el.country}
                    </td>
                    <td className="text-left w-25 pl-0">
                      <span className='badge badge-pill badge-success'>{returnDateFormat(el.trialEndTimeUtc, "filterTrial", el.isTrial)}</span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      }
      <div className={`px-4 pb-3 ${inputValue || filterTrialStatus === 'Active' || filterTrialStatus === 'Non Active' ? "dispay-none-the-item" : ""}`}>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={state.data.length}
          pageSize={PageSize}
          onPageChange={(page: TODO) => setCurrentPage(page)}
        />
      </div>
    </React.Fragment>
  )
}
