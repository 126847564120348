import { Skeleton } from "../../../shared/Skeleton/Skeleton"

export const ProjectsCardsSkeleton = () => {
  return (
    [...Array(25).keys()].map((item) => (
      <div key={item} className="project-card-wrapper position-relative col-xl-3 col-lg-4 col-md-5 col-sm-7 col-xs-12 mb-5">
        <div className="project-card card border-0 gap-md justify-content-around py-3 px-3" style={{ height: "125px" }}>
          <div className="d-flex gap-md align-items-center h-64">
            <Skeleton className="h-32 w-32" />
            <Skeleton className="h-24" />
          </div>

          <div>
            <div className="d-flex gap-md align-items-center">
              <Skeleton className="h-24 w-50" />
              <Skeleton className="h-24 w-50" />
            </div>
          </div>

        </div>
      </div>
    ))
  )
}