import { apiUrl } from "../../../../services/services"

export const returnAuth0ProviderConfig = () => {
  return {
    domain: import.meta.env.VITE_APP_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_APP_AUTH0_CLIENT_ID,
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: apiUrl
    }
  }
}