import { getConvertStatus, convertUploadedFileToTripleS } from "../../../../../services/uploadImportDatasetServices/uploadImportDatasetServices"
import { fetchGetRes } from "../../../../../services/services";

import { ContainerClient } from '@azure/storage-blob';

export const checkConvertStatus = (interval, convertUploadedFileToTripleSResponse, token, dispatch, advancedImportStatusData, updateData, redirectData, projectdetails, formData, onCloseHandler) => {
  let updatedAdvancedImportStatusData = { ...advancedImportStatusData }
  setTimeout(async () => {
    const convertStatus = await getConvertStatus(convertUploadedFileToTripleSResponse.StatusUri, token)
    if (!convertStatus.errMsg) {
      if (convertStatus.runtimeStatus === 'Completed') {
        if (convertStatus.customStatus?.messages && (convertStatus.customStatus?.messages.templateSkippedDueToErrors)) {
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: "File has been imported, but there have been some errors while implementing the template" })
        } else {
          updatedAdvancedImportStatusData = { ...updatedAdvancedImportStatusData, importErrorMessage: null, uploadErrorMessage: null }
          updateData()
          dispatch({ type: 'UPDATE_ADVANCED_IMPORT_STATUS', payload: updatedAdvancedImportStatusData })
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The dataset has been imported successfully.' } })
          dispatch({ type: 'COMPLETE_ADVANCED_IMPORT', payload: { formData: formData, urlProjectName: redirectData, projectId: projectdetails.id, uploadId: updatedAdvancedImportStatusData.uploadId, projectName: projectdetails.name } })
        }
      } else if (convertStatus.runtimeStatus === 'Failed') {
        onCloseHandler() // Calls the function that closes the GenericLibrary from AdvancedImport.js, if it's open to load a template
        updatedAdvancedImportStatusData = { ...updatedAdvancedImportStatusData, isInProgress: false, progressStatus: '', importErrorMessage: 'Something went wrong. Please try again.' }
        if (convertStatus.output.includes("Template errors")) {
          updatedAdvancedImportStatusData.importErrorMessage = "An error occured while loading the template"
        }

        dispatch({ type: 'UPDATE_ADVANCED_IMPORT_STATUS', payload: updatedAdvancedImportStatusData })
      } else {
        const newInterval = interval !== 30000 ? interval * 2 : 30000
        checkConvertStatus(newInterval, convertUploadedFileToTripleSResponse, token, dispatch, updatedAdvancedImportStatusData, updateData, redirectData, projectdetails, formData, onCloseHandler)
      }
    } else {
      updatedAdvancedImportStatusData = { ...updatedAdvancedImportStatusData, isInProgress: false, progressStatus: '', importErrorMessage: 'Something went wrong. Please try again.' }
      dispatch({ type: 'UPDATE_ADVANCED_IMPORT_STATUS', payload: updatedAdvancedImportStatusData })
    }
  }, interval)
}

export const onUploadHandler = async (dispatch, advancedImportStatusData, formData, dataMetaFile, caseFile, token, projectdetails, updateData, redirectData, automaticMergeOptions, onCloseHandler) => {
  dispatch({ type: 'HIDE_QUICK_IMPORT_NOTIFICATION' })
  let updatedAdvancedImportStatusData = { ...advancedImportStatusData }
  updatedAdvancedImportStatusData = { ...updatedAdvancedImportStatusData, uploadErrorMessage: null, importErrorMessage: null, isProgressCompleted: false, redirectData: null }
  dispatch({ type: 'UPDATE_ADVANCED_IMPORT_STATUS', payload: updatedAdvancedImportStatusData })

  updatedAdvancedImportStatusData = { ...updatedAdvancedImportStatusData, isInProgress: true, progressStatus: 'Uploading...' }
  dispatch({ type: 'UPDATE_ADVANCED_IMPORT_STATUS', payload: updatedAdvancedImportStatusData })
  const uploadData = await fetchGetRes('uploads/token', token)
  const uploadDataRes = await uploadData.json();

  if (uploadDataRes && !uploadDataRes.message && !uploadDataRes.error && dataMetaFile) {
    updatedAdvancedImportStatusData = { ...updatedAdvancedImportStatusData, uploadId: uploadDataRes.uploadId }
    dispatch({ type: 'UPDATE_ADVANCED_IMPORT_STATUS', payload: updatedAdvancedImportStatusData })

    const filesArr = []
    filesArr.push(dataMetaFile.file)
    if (caseFile) {
      filesArr.push(caseFile.file)
    }

    const containerClient = new ContainerClient(uploadDataRes.sasUri);
    const promises = [];
    for (const file of filesArr) {
      const blobName = `${uploadDataRes.uploadPath}/${file.name}`;
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);
      promises.push(blockBlobClient.uploadBrowserData(file));
    }
    const resp = await Promise.all(promises);

    if (resp) {
      updatedAdvancedImportStatusData = { ...updatedAdvancedImportStatusData, progressStatus: 'Converting...' }
      dispatch({ type: 'UPDATE_ADVANCED_IMPORT_STATUS', payload: updatedAdvancedImportStatusData })

      let fileNames = null
      if (caseFile) {
        fileNames = {
          fileNameData: caseFile.fileName,
          fileNameMeta: dataMetaFile.fileName,
          options: {
            createGridsAutomatically: automaticMergeOptions.createGridsAutomatically,
            createMultiplesAutomatically: automaticMergeOptions.createMultiplesAutomatically,
          }
        }
      } else {
        fileNames = {
          fileName: dataMetaFile.fileName,
          options: {
            createGridsAutomatically: automaticMergeOptions.createGridsAutomatically,
            createMultiplesAutomatically: automaticMergeOptions.createMultiplesAutomatically,
            maxUniqueValuesForSingleChoice: automaticMergeOptions.maxUniqueValuesForSingleChoice - 2
          },
          templateId: projectdetails.id
        }
      }
      const convertUploadedFileToTripleSResponse = await convertUploadedFileToTripleS(`uploads/${uploadDataRes.uploadId}/convert/${dataMetaFile.extension}`, fileNames, token,)
      if (!convertUploadedFileToTripleSResponse.message || !convertUploadedFileToTripleSResponse.error) {
        updatedAdvancedImportStatusData = { ...updatedAdvancedImportStatusData, isInProgress: true, progressStatus: 'Converting...' }
        dispatch({ type: 'UPDATE_ADVANCED_IMPORT_STATUS', payload: updatedAdvancedImportStatusData })
        checkConvertStatus(1500, convertUploadedFileToTripleSResponse, token, dispatch, updatedAdvancedImportStatusData, updateData, redirectData, projectdetails, formData, onCloseHandler)
      } else {
        updatedAdvancedImportStatusData = { ...updatedAdvancedImportStatusData, isInProgress: false, progressStatus: '', importErrorMessage: convertUploadedFileToTripleSResponse.message }
        dispatch({ type: 'UPDATE_ADVANCED_IMPORT_STATUS', payload: updatedAdvancedImportStatusData })
      }
    } else {
      updatedAdvancedImportStatusData = { ...updatedAdvancedImportStatusData, isInProgress: false, progressStatus: '', importErrorMessage: 'Something went wrong while uploading the dataset.' }
      dispatch({ type: 'UPDATE_ADVANCED_IMPORT_STATUS', payload: updatedAdvancedImportStatusData })
    }
  } else {
    if (uploadDataRes.message || uploadDataRes.error)
      updatedAdvancedImportStatusData = { ...updatedAdvancedImportStatusData, isInProgress: false, progressStatus: '', importErrorMessage: uploadDataRes.message ? uploadDataRes.message : uploadDataRes.error }
    dispatch({ type: 'UPDATE_ADVANCED_IMPORT_STATUS', payload: updatedAdvancedImportStatusData })
  }
}