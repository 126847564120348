import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import GroupSettings from '../GroupSettings/GroupSettings';
import FileManager from '../../../../../shared/FileManager/FileManager';

export default function VisualisationOptionsModal({ name, settings, settingsValues, handleClose, saveVisualSettings }) {
  const { theData } = useSelector((theState) => (theState.surveyInitialDataReducer));
  const { token } = useSelector((state) => state.tokenStateReducer);
  const [visual, setVisual] = useState({ props: settingsValues })
  const [showFileManager, setShowFileManager] = useState({ show: false, setting: null })
  const groups = settings.map(el => el.group).filter((item, i) => settings.map(el => el.group).indexOf(item) === i);

  useEffect(() => {
    if (theData.selectedItem.elements.filter(el => el.type === 'subq').length > 0 && theData.selectedItem.elements.filter(el => el.type === 'subq')[theData.selectedSubquestion]?.customQuestionId) {
      setVisual({ props: theData.selectedItem.elements.filter(el => el.type === 'subq')[theData.selectedSubquestion]?.customQuestionProperties })
    }
  }, [theData.selectedItem.elements, theData.selectedSubquestion])

  const onChangeSettingsValues = (value, setting, type) => {
    let values = [...visual.props]
    if (type === 'sizeType') {
      if (values.filter(el => el.id === setting).length === 0) {
        values = [...values, { id: setting, value: null, type: value }]
      } else {
        values.find(el => el.id === setting).type = value
      }
    }
    else if (type === 'sizeValue') {
      if (values.filter(el => el.id === setting).length === 0) {
        values = [...values, { id: setting, value: value, type: "px" }]
      } else {
        values.find(el => el.id === setting).value = value
      }
    } else {
      if (values.filter(el => el.id === setting).length === 0) {
        values = [...values, { id: setting, value: value }]
      } else {
        values.find(el => el.id === setting).value = value
      }
    }
    setVisual({ ...visual, props: values })
  }

  const setUrlName = (value) => {
    const values = visual.props
    if (theData.selectedItem.elements.filter(el => el.type === 'subq').length > 0 && theData.selectedItem.elements.filter(el => el.type === 'subq')[theData.selectedSubquestion]?.customQuestionId) {
      const filtered = values.findIndex(el => el.type === "url" || el.type === "Url")
      if (filtered !== -1) {
        values[filtered].value = value
      }
      setVisual({ ...visual, props: values })
    } else {
      setVisual({ ...visual, props: [...visual.props, { [showFileManager.setting]: value }] })
    }
  }

  const onSaveOptionsHandler = () => {
    saveVisualSettings(visual.props)
  }

  return (
    <Dialog title={`${name} options`} width="600px" height="500px" onClose={handleClose}>
      {
        showFileManager.show &&
        <FileManager
          displayType="modal"
          type={'public'}
          onSelect={(value) => setUrlName(value)}
          onHide={() => setShowFileManager({ show: false, setting: null })}
          token={token}
        />
      }
      <div className="h-100 overflow-auto">
        <GroupSettings
          settings={settings}
          groups={groups}
          fonts={theData.supportedFonts}
          settingsValues={visual.props}
          onChangeSettingsValues={onChangeSettingsValues}
          onShowFileManager={(id) => setShowFileManager({ show: true, setting: id })}
          type="visualisation"
          dropdownValues={theData.dropdownValues}
        />
      </div>
      <DialogActionsBar>
        <button type='button' className="k-button btn btn-secondary" onClick={handleClose}>Cancel</button>
        <button type='button' className="k-button btn btn-primary" onClick={onSaveOptionsHandler}>Save options</button>
      </DialogActionsBar>
    </Dialog>
  )
}