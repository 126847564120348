import { fetchGetJson as getProjectDocuments } from "../../../../../services/services"

export default (projectId, token, dispatch) => {
  return getProjectDocuments(`projects/${projectId}/documents`, token)
    .then(documents => {
      if (documents) {
        if (documents.message || documents.error) {
          dispatch({ type: 'UPDATE_ERROR_MESSAGE', payload: { msg: documents.message ? documents.message : documents.error, type: 'modal' } })
          return null
        } else if (Object.entries(documents).length !== 0) {
          const updatedDocuments = { ...documents }
          Object.entries(updatedDocuments).forEach(([documentKey, documentValue]) => {
            documentValue.forEach(el => {
              el.selected = false
              el.type = documentKey
              if (el.createdDate) {
                el.createdDate = el.createdDate.substr(0, 10)
              } else {
                el.createdDate = null
              }
            })
          })
          return updatedDocuments
        } else {
          return null
        }
      } else {
        return null
      }
    })
}