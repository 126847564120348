import React from 'react';
import { CrossTableTabContent } from './CrossTableTabContent/CrossTableTabContent';
import { ToplineTabContent } from './ToplineTabContent/ToplineTabContent';
import { QTableTabContent } from './QTableTabContent/QTableTabContent';
import { DataTabContent } from './DataTabContent/DataTabContent';
import { DigTabContent } from './DigTabContent/DigTabContent';
import { ChartTabContent } from './ChartTabContent/ChartTabContent';
import { ReportTabContent } from './ReportTabContent/ReportTabContent';
import { ScriptTabContent } from './ScriptTabContent/ScriptTabContent';
import { AnalysisScriptingTabContent } from './AnalysisScriptingTabContent/AnalysisScriptingTabContent';
import { ReportsTabContent } from './ReportsTabContent/ReportsTabContent';

export const TabContent = ({ onAutoSaveHandler, activeTabs, setTabSelected, setOptionsData, analysisFunctionalities, selectedTab, tabType, datasetName, userData, optionsData, expandedOptions, showOptionsModal, handleOptionsDialogClose, openOptionsDialog, user, rangeItems, onApplyWeightSet, loadingAnalysisScriptingResult, selectedAnalysisScriptingItem, setDidMountSession }) => {
  switch (tabType) {
    case "Cross Table":
      return <CrossTableTabContent
        user={user}
        activeTabs={activeTabs}
        rangeItems={rangeItems}
        selectedTab={selectedTab}
        datasetName={datasetName}
        optionsData={optionsData}
        setOptionsData={setOptionsData}
        setTabSelected={setTabSelected}
        expandedOptions={expandedOptions}
        showOptionsModal={showOptionsModal}
        openOptionsDialog={openOptionsDialog}
        onAutoSaveHandler={onAutoSaveHandler}
        analysisFunctionalities={analysisFunctionalities}
        handleOptionsDialogClose={handleOptionsDialogClose}
        onApplyWeightSet={onApplyWeightSet}
        setDidMountSession={setDidMountSession}
      />

    case "Topline":
      return <ToplineTabContent
        user={user}
        rangeItems={rangeItems}
        datasetName={datasetName}
        optionsData={optionsData}
        expandedOptions={expandedOptions}
        showOptionsModal={showOptionsModal}
        openOptionsDialog={openOptionsDialog}
        onAutoSaveHandler={onAutoSaveHandler}
        analysisFunctionalities={analysisFunctionalities}
        handleOptionsDialogClose={handleOptionsDialogClose}
        onApplyWeightSet={onApplyWeightSet}
        setDidMountSession={setDidMountSession}
      />

    case "Q Table":
      return <QTableTabContent
        user={user}
        userData={userData}
        rangeItems={rangeItems}
        datasetName={datasetName}
        optionsData={optionsData}
        expandedOptions={expandedOptions}
        showOptionsModal={showOptionsModal}
        onAutoSaveHandler={onAutoSaveHandler}
        openOptionsDialog={openOptionsDialog}
        analysisFunctionalities={analysisFunctionalities}
        handleOptionsDialogClose={handleOptionsDialogClose}
        onApplyWeightSet={onApplyWeightSet}
        setDidMountSession={setDidMountSession}
      />

    case "Data":
      return <DataTabContent
        user={user}
        userData={userData}
        rangeItems={rangeItems}
        selectedTab={selectedTab}
        datasetName={datasetName}
        optionsData={optionsData}
        expandedOptions={expandedOptions}
        showOptionsModal={showOptionsModal}
        onAutoSaveHandler={onAutoSaveHandler}
        openOptionsDialog={openOptionsDialog}
        analysisFunctionalities={analysisFunctionalities}
        handleOptionsDialogClose={handleOptionsDialogClose}
      />

    case "Dig":
      return <DigTabContent
        user={user}
        userData={userData}
        rangeItems={rangeItems}
        selectedTab={selectedTab}
        datasetName={datasetName}
        optionsData={optionsData}
        expandedOptions={expandedOptions}
        showOptionsModal={showOptionsModal}
        onAutoSaveHandler={onAutoSaveHandler}
        openOptionsDialog={openOptionsDialog}
        analysisFunctionalities={analysisFunctionalities}
        handleOptionsDialogClose={handleOptionsDialogClose}
        onApplyWeightSet={onApplyWeightSet}
        setDidMountSession={setDidMountSession}
      />

    case "Chart":
      return <ChartTabContent
        user={user}
        userData={userData}
        rangeItems={rangeItems}
        selectedTab={selectedTab}
        datasetName={datasetName}
        optionsData={optionsData}
        expandedOptions={expandedOptions}
        showOptionsModal={showOptionsModal}
        onAutoSaveHandler={onAutoSaveHandler}
        openOptionsDialog={openOptionsDialog}
        analysisFunctionalities={analysisFunctionalities}
        handleOptionsDialogClose={handleOptionsDialogClose}
        onApplyWeightSet={onApplyWeightSet}
        setDidMountSession={setDidMountSession}
      />

    case "Report":
      return <ReportTabContent
        user={user}
        activeTabs={activeTabs}
        rangeItems={rangeItems}
        selectedTab={selectedTab}
        datasetName={datasetName}
        optionsData={optionsData}
        setOptionsData={setOptionsData}
        setTabSelected={setTabSelected}
        expandedOptions={expandedOptions}
        showOptionsModal={showOptionsModal}
        openOptionsDialog={openOptionsDialog}
        onAutoSaveHandler={onAutoSaveHandler}
        analysisFunctionalities={analysisFunctionalities}
        handleOptionsDialogClose={handleOptionsDialogClose}
      />

    case "Script":
      return <ScriptTabContent
        user={user}
        datasetName={datasetName}
        optionsData={optionsData}
        onAutoSaveHandler={onAutoSaveHandler}
        analysisFunctionalities={analysisFunctionalities}
      />
    case "Scripting":
      return <AnalysisScriptingTabContent
        optionsData={optionsData}
        datasetName={datasetName}
        selectedAnalysisScriptingItem={selectedAnalysisScriptingItem}
        loadingAnalysisScriptingResult={loadingAnalysisScriptingResult}
      />
    case "Reports":
      return <ReportsTabContent
        user={user}
        analysisFunctionalities={analysisFunctionalities}
        optionsData={optionsData}
        datasetName={datasetName}
        setTabSelected={setTabSelected}
      />
    default:
      return null;
  }
}