import type { SelectedSession } from "../../../../../../../interfaces/analysisInterfaces/analysisInterfaces";

const actions = ["update", "saveAs", "open", "reset"] as const
export type ActionsType = (typeof actions)[number];

type Item = {
  actionName: string;
  action: ActionsType;
  disabled?: boolean
}

export const returnSessionDropdownItems = (selectedSession: SelectedSession) => {
  const items: Item[] = [
    {
      actionName: "Save",
      action: "update",
      disabled: selectedSession.id ? false : true
    },
    {
      actionName: "Save as",
      action: "saveAs",
    },
    {
      actionName: "Load",
      action: "open",
    },
    {
      actionName: "Reset",
      action: "reset",
    }
  ]
  return items
}