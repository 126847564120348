export function toplineClearQuestionChecked(theState) {
  if (theState.state.checkedNum) {
    if (theState.state.checkedNum.length > 0) {
      let firstCol = theState.state.firstColumn.filter(el => el.selected === false)
      if (theState.state.firstColumn.length === 1) {
        firstCol = []
      }
      else if (firstCol.filter(el => el.active === true).length === 0) {
        firstCol[firstCol.length - 1].active = true
      }
      return {
        ...theState,
        state: {
          ...theState.state,
          checkedNum: [],
          firstColumn: firstCol,
          displayTable: firstCol.length === 0 ? null : theState.state.displayTable,
          updateTable: true,
          selectedQuestionSource: null,
          secondColumnIndex: [],
          data: {},
          secondColumns: { value: '' }
        }
      }
    } else return { ...theState }
  } else return { ...theState }
}
