import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';

import Button from '../../../shared/buttons/Button';
import DropdownButton from '../../../shared/buttons/DropdownButton';
import QuestionButton from '../../../shared/buttons/QuestionButton';
import QuestionControls from '../../../shared/buttons/QuestionControls';
import usePrevious from '../../../../../../shared/customHooks/usePrevious';
import { ErrorMessage } from '../../../../../../shared/ErrorMessage/ErrorMessage';
import MultiChoiceModal from '../../../shared/MultiChoiceModal/MultiChoiceModal';
import UpdateValuesTable from '../../../shared/setTable/updateValuesTable/UpdateValuesTable';
import { InProgressOverlay } from '../../../../../../shared/InProgressOverlay/InProgressOverlay';
import updateData from '../../../shared/helpers/importDataHelpers/updateData/updateData';
import setButtonData from '../../../shared/helpers/importDataHelpers/setButtonData/setButtonData';
import setSearchData from '../../../shared/helpers/importDataHelpers/setSearchData/setSearchData';
import setReplaceData from '../../../shared/helpers/importDataHelpers/setReplaceData/setReplaceData';
import setControlValues from '../../../shared/helpers/importDataHelpers/setControlValues/setControlValues';
import updateWeightData from '../../../shared/helpers/importDataHelpers/updateWeightData/updateWeightData';
import updateMultiGridData from '../../../shared/helpers/importDataHelpers/updateMultigridData/updateMultiGridData';
import updateToggleAllData from '../../../shared/helpers/importDataHelpers/updateToggleAllData/updateToggleAllData';
import updateSubmittedData from '../../../shared/helpers/importDataHelpers/updateSubmittedData/updateSubmittedData';
import { updateOpenListData } from '../../../shared/helpers/importDataHelpers/updateOpenListData/updateOpenListData';
import { SaveDatasetToLibraryModal } from '../../../../../../shared/SaveDatasetToLibraryModal/SaveDatasetToLibraryModal';
import updateNumericListData from '../../../shared/helpers/importDataHelpers/updateNumericListData/updateNumericListData';
import updateSelectedProperty from '../../../shared/helpers/importDataHelpers/updateSelectedProperty/updateSelectedProperty';
import updateCheckQuestionData from '../../../shared/helpers/importDataHelpers/updateCheckQuestionData/updateCheckQuestionData';
import GenericLibrary from '../../../../../../Survey/SurveyTabContent/SurveyDesignTabContent/helpers/LibraryModal/GenericLibrary';
import updateMergeMultichoiceData from '../../../shared/helpers/importDataHelpers/updateMergeMultichoiceData/updateMergeMultichoiceData';
import returnUpdatedActionButtons from '../../../shared/helpers/importDataHelpers/returnUpdatedActionButtons/returnUpdatedActionButtons';
import updateEnableDisableQuestionData from '../../../shared/helpers/importDataHelpers/updateEnableDisableQuestionData/updateEnableDisableQuestionData';
import updateUndoQuestionData, { undoAutoMergedQuestions } from '../../../shared/helpers/importDataHelpers/updateUndoQuestionData/updateUndoQuestionData';
import updateMergeMultichoiceNmopenData from '../../../shared/helpers/importDataHelpers/updateMergeMultichoiceNmopenData/updateMergeMultichoiceNmopenData';
import { returnQuestionsWithReplacedText } from '../../../shared/helpers/importDataHelpers/returnQuestionsWithReplacedText/returnQuestionsWithReplacedText';
import { getDatasetValue, sendUpdatedDatasetValue, finalizeImport, getFinalizeImportStatus } from '../../../../../../../services/uploadImportDatasetServices/uploadImportDatasetServices';

export const Import = ({ userData, token, ...props }) => {
  const dispatch = useDispatch()

  const uploadData = props.location.state

  const { advancedImportStatusData } = useSelector(state => state.advancedImportStatusReducer);
  const [actionButtons, setActionButtons] = useState([])
  const [genericLibrary, setGenericLibrary] = useState(false)
  const [libraryConfirmModal, setLibraryConfirmModal] = useState(false)
  const [searchValue, setSearchValue] = useState('');
  const [state, setState] = useState({
    originalFormatData: null,
    data: null,
    initialData: null,
    initialOriginalData: null,
    hideTable: true,
    checkedQuestions: [],
    actionButtons: [],
    multiChoiceModalShow: false,
    displayTable: null,
    multiSelect: false,
    toggleAll: false,
    toggleSearch: false,
    toggleReplace: false,
    submitLoadingStatus: false,
    submitErrMsg: '',
    redirect: false
  })
  const originalData = useRef(null);
  const _cache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight: 63
  })

  const prevCheckedQuestions = usePrevious(state.checkedQuestions);

  useEffect(() => {
    dispatch({ type: 'UPDATE_ADVANCED_IMPORT_REDIRECT' })
    if (token && uploadData && uploadData.uploadId) {
      try {
        getDatasetValue(`/uploads/${uploadData.uploadId}/conversion-metadata`, token)
          .then(res => {
            if (res && (res.message || res.error)) {
              dispatch({ type: 'UPDATE_ERROR_MESSAGE', payload: { msg: res.message ? res.message : res.error, type: 'modal' } })
            } else {
              const newData = updateData(res)
              setState({
                ...state,
                originalFormatData: res,
                data: newData,
                initialData: newData,
                initialOriginalData: JSON.parse(JSON.stringify(newData))
              })
            }
          })
      } catch (error) {
        alert(error)
      }
    } else {
      setState({
        ...state,
        redirect: true
      })
    }
  }, [])

  useEffect(() => {
    originalData.current = JSON.parse(JSON.stringify(state.initialOriginalData))
    setActionButtons(
      setButtonData())
  }, [state.initialOriginalData])

  useEffect(() => {
    // The data that's used to display the table is stored in a separate state called displayTable
    // When the original data is updated, the displayTable state has to be updated here to reflect the changes in the table
    const updatedData = JSON.parse(JSON.stringify(state.data));
    if (updatedData) {
      const indexOfSelected = updatedData.findIndex(el => el.selected === true);
      if (typeof indexOfSelected === 'number') {
        setState((prevState) => ({
          ...prevState,
          hideTable: false,
          displayTable: updatedData[indexOfSelected]
        }))
      }
    }
  }, [state.data])

  const toggleQuestion = (index) => {
    const updatedState = updateSelectedProperty(state, index)
    setState({
      ...state,
      hideTable: false,
      data: updatedState.data,
      displayTable: updatedState.displayTable
    })
  }

  const onChangeHandler = (e, index, category, prop, valID, valuesProperty, type, val) => {
    const updatedData = [...state.data]
    if (category === 'question') {
      updatedData[index][prop] = e.target.value
    } else if (category === 'questionValue') {
      if (!Number.isNaN(valID)) {
        switch (type) {
          case 'nmopen-arr-no-values':
            updatedData[index][valuesProperty][valID].label.text = e.target.value
            break;
          case 'nmopen-arr':
            updatedData[index][valuesProperty][valID].values.value[val].text = e.target.value
            break;
          case 'nmopen-obj':
            updatedData[index][valuesProperty][valID].values.value.text = e.target.value
            break;
          case 'default-obj':
            updatedData[index].title = e.target.value
            break;
          case 'default-array':
            updatedData[index][valuesProperty].value[valID].text = e.target.value
            break;
          default:
            break;
        }
      }
    }
    setState({
      ...state,
      data: updatedData
    })
  }

  const onChangeMultiGridTextHandler = (value, questionIndex, variableIndex, variablePropertyName, variableValueIndex) => {
    const updatedData = JSON.parse(JSON.stringify(state.data));
    if (typeof variableValueIndex === 'number') {
      if (updatedData[questionIndex][variablePropertyName][variableIndex].values?.value) {
        updatedData[questionIndex][variablePropertyName][variableIndex].values.value[variableValueIndex].text = value;
      } else {
        if (Array.isArray(updatedData[questionIndex][variablePropertyName][variableIndex].variable[variableValueIndex].values.value)) {
          updatedData[questionIndex][variablePropertyName][variableIndex].variable[variableValueIndex].values.value.find(val => !val['@miextsss:excluded'] || val['@miextsss:excluded'] === 'false').text = value;
        } else {
          updatedData[questionIndex][variablePropertyName][variableIndex].variable[variableValueIndex].values.value.text = value;
        }
      }
    } else {
      if (updatedData[questionIndex][variablePropertyName][variableIndex].label) {
        updatedData[questionIndex][variablePropertyName][variableIndex].label.text = value;
      } else {
        updatedData[questionIndex][variablePropertyName][variableIndex]["miextsss:label"].text = value;
      }
    }
    setState({ ...state, data: updatedData })
  }

  const onCheckedChangedHandler = (e, index, valID, category) => {
    const updatedCheckedQuestions = JSON.parse(JSON.stringify([...state.checkedQuestions]))
    if (category === 'question') {
      updatedCheckedQuestions[index].title = e.target.value
    } else {
      if (Array.isArray(updatedCheckedQuestions[index].values.value)) {
        updatedCheckedQuestions[index].values.value[valID].text = e.target.value
      } else {
        updatedCheckedQuestions[index].values.value.text = e.target.value
      }
    }
    setState({
      ...state,
      checkedQuestions: updatedCheckedQuestions
    })
  }

  const onCheckQuestion = (index) => {
    const checkQuestionData = updateCheckQuestionData(state.data, index, state.multiSelect)
    setState({
      ...state,
      checkedQuestions: checkQuestionData.checkedQuestions,
      data: checkQuestionData.data
    })
  }

  const onToggleControl = (controlVal) => {
    const controlValues = setControlValues(controlVal, state.toggleSearch, state.toggleReplace, state.multiSelect)
    setState({
      ...state,
      toggleSearch: controlValues.toggleSearch,
      toggleReplace: controlValues.toggleReplace,
      multiSelect: controlValues.toggleMultiSelect
    })
  }

  const onToggleAll = () => {
    const toggleAllData = updateToggleAllData(state.data, state.toggleAll)
    setState({
      ...state,
      toggleAll: toggleAllData.toggleAll,
      data: toggleAllData.data,
      checkedQuestions: toggleAllData.data,
      multiSelect: false
    })
  }

  const onMergeMultiChoiceSubmit = (checkedData, uniqueValues, activeTitleIndex, dichotomyB, dichotomyA, updatedQuestionTitle) => {
    let updatedData = [...state.data]
    const updatedInitialData = [...state.initialData]
    updatedData = updateMergeMultichoiceData(updatedData, checkedData, uniqueValues, activeTitleIndex, dichotomyB, dichotomyA, updatedInitialData, updatedQuestionTitle)
    setState({
      ...state,
      data: updatedData.data,
      initialData: updatedData.initialData,
      displayTable: updatedData.element,
      multiChoiceModalShow: false,
      checkedQuestions: []
    })
  }

  const findCommonAnswer = (checkedQuestions) => {
    const frequencyMap = {};
    for (const ques of checkedQuestions) {
      const values = ques.values.value;
      if (Array.isArray(values)) {
        for (const item of values) {
          const text = item.text;
          if (frequencyMap[text]) {
            return text;
          }
          frequencyMap[text] = true;
        }
      } else if (typeof values === 'object' && values !== null) {
        const text = values.text;
        if (frequencyMap[text]) {
          return text;
        }
        frequencyMap[text] = true;
      }
    }
    return null;
  };

  const onMultiselectMerge = () => {
    if (state.checkedQuestions.filter(el => el.values.value.length === state.checkedQuestions[0].values.value.length).length === state.checkedQuestions.length) {
      const commonAnswer = findCommonAnswer(state.checkedQuestions);
      if (commonAnswer) {
        setState({ ...state, multiChoiceModalShow: true })
      } else {
        let updatedData = [...state.data]
        updatedData = updateMergeMultichoiceNmopenData(updatedData, state.checkedQuestions, state.initialData)
        setState({
          ...state,
          displayTable: updatedData.element,
          data: updatedData.data,
          initialData: updatedData.initialData,
          checkedQuestions: []
        })
      }
    } else {
      let updatedData = [...state.data]
      updatedData = updateMergeMultichoiceNmopenData(updatedData, state.checkedQuestions, state.initialData)
      setState({
        ...state,
        displayTable: updatedData.element,
        data: updatedData.data,
        initialData: updatedData.initialData,
        checkedQuestions: []
      })
    }
  }

  const onMultiGridMerge = () => {
    const updatedData = updateMultiGridData(state.data, state.checkedQuestions, state.initialData)
    setState({
      ...state,
      displayTable: updatedData.element,
      data: updatedData.data,
      initialData: updatedData.initialData,
      checkedQuestions: []
    })
  }

  const onCreateNumericList = () => {
    const updatedData = updateNumericListData(state.data, state.checkedQuestions, state.initialData)
    setState({
      ...state,
      displayTable: updatedData.element,
      data: updatedData.data,
      initialData: updatedData.initialData,
      checkedQuestions: []
    })
  }

  const onCreateOpenList = () => {
    const openListData = updateOpenListData(state.data, state.initialData, state.checkedQuestions)
    setState({
      ...state,
      data: openListData.data,
      initialData: openListData.initialData,
      displayTable: openListData.displayTable,
      checkedQuestions: []
    })
  }

  const onEnableDisableQuestion = () => {
    const updatedData = updateEnableDisableQuestionData(state.data, state.checkedQuestions, state.initialData, state.toggleSearch)
    setState({
      ...state,
      checkedQuestions: updatedData.checkedQuestions,
      data: updatedData.data,
      initialData: updatedData.initialData
    })
  }

  const onUndoQuestion = () => {
    const updatedData = updateUndoQuestionData(state.data, originalData.current, state.checkedQuestions, state.initialData)
    setState({
      ...state,
      data: updatedData.data,
      initialData: updatedData.initialData,
      checkedQuestions: []
    })
  }

  const onUndoAutoMerged = () => {
    const updatedData = undoAutoMergedQuestions(state.data, state.originalFormatData, state.checkedQuestions,)
    setState({
      ...state,
      data: updatedData.data,
      initialData: updatedData.data,
      originalFormatData: updatedData.originalFormatData,
      checkedQuestions: []
    })
  }

  const onReplaceHandler = (type, replaceVal, findValue) => {
    const updatedData = setReplaceData(state.data, type, replaceVal, state.initialData, findValue)
    setState({
      ...state,
      data: updatedData
    })
    setSearchValue('');
  }

  const onSetWeightHandler = (action) => {
    const updatedData = updateWeightData(state.data, state.checkedQuestions, action)
    setState({
      ...state,
      data: updatedData
    })
  }

  const onSetRespondentIdHandler = (action) => {
    const updatedData = [...state.data]
    let matchedElement = null
    if (state.checkedQuestions.length === 1) {
      matchedElement = updatedData.find(el => el['@miextsss:isrspid'])
      if (matchedElement && JSON.parse(JSON.stringify(matchedElement)) === JSON.parse(JSON.stringify(state.checkedQuestions[0]))) {
        if (action === 'none') {
          updatedData[updatedData.indexOf(state.checkedQuestions[0])]['@miextsss:isrspid'] = false
        }
      } else {
        if (action === 'respondentId') {
          updatedData[updatedData.indexOf(state.checkedQuestions[0])]['@miextsss:isrspid'] = true
        } else {
          updatedData[updatedData.indexOf(state.checkedQuestions[0])]['@miextsss:isrspid'] = false
        }
      }
    }
    setState({
      ...state,
      data: updatedData
    })
  }

  const handleClose = () => {
    setGenericLibrary(false)
  }

  const setPayload = (res) => {
    res.saveTemplateId = res.id
    res.projectId = uploadData.projectId
    res.name = advancedImportStatusData.formData.name
    onSubmitImportedData(res)
  }

  const onFinalizeImportData = () => {
    setLibraryConfirmModal(true)
  }

  const closeLibraryModal = (e) => {
    if (e.target.value === "Yes") {
      setLibraryConfirmModal(false)
      setGenericLibrary(true)
    } else {
      setLibraryConfirmModal(false)
      onSubmitImportedData({ projectId: uploadData.projectId, name: advancedImportStatusData.formData?.name, description: advancedImportStatusData.formData?.description })
    }
  }

  const onSubmitImportedData = async (importBody) => {
    setState({
      ...state,
      submitLoadingStatus: true
    })
    const dataToBeReturned = updateSubmittedData(state.originalFormatData, state.data, state.initialData)
    // console.log(dataToBeReturned.sss.survey.record);
    const submittedDataResponse = await sendUpdatedDatasetValue(`uploads/${uploadData.uploadId}/conversion-metadata`, dataToBeReturned, token)
    if (!submittedDataResponse.errMsg) {
      const finalizeImportBody = importBody
      const finalizeImportResponse = await finalizeImport(`uploads/${uploadData.uploadId}/finalize-conversion`, finalizeImportBody, token)
      if (!finalizeImportResponse.errMsg && finalizeImportResponse && finalizeImportResponse.StatusUri) {
        const getStatus = setInterval(async () => {
          const convertStatus = await getFinalizeImportStatus(finalizeImportResponse.StatusUri, token)
          if (!convertStatus.errMsg) {
            switch (convertStatus.runtimeStatus) {
              case 'Completed':
                clearInterval(getStatus)
                dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The changes have been submitted successfully' } })
                setState({
                  ...state,
                  submitLoadingStatus: false
                })
                props.history.push({ pathname: `/projects/${uploadData.projectId}` })
                break
              case 'Pending':
                // console.log("Pending...");
                break
              case 'Running':
                // console.log("Running...");
                break
              case 'Failed':
                clearInterval(getStatus)
                setState({
                  ...state,
                  submitLoadingStatus: false,
                  submitErrMsg: convertStatus.output
                })
                break
              default:
                clearInterval(getStatus)
                break
            }
          } else {
            clearInterval(getStatus)
            setState({
              ...state,
              submitLoadingStatus: false,
              submitErrMsg: 'Something went wrong. Please try again'
            })
          }
        }, 2000);
      } else {
        setState({
          ...state,
          submitLoadingStatus: false,
          submitErrMsg: 'Something went wrong. Please try again'
        })
      }
    } else {
      setState({
        ...state,
        submitLoadingStatus: false,
        submitErrMsg: 'Something went wrong. Please try again'
      })
    }
  }

  useEffect(() => {
    if (prevCheckedQuestions !== state.checkedQuestions) {
      const updatedActionButtons = [...actionButtons]
      let returnedActionButtons = []
      const multiChoice = false;
      if (updatedActionButtons.length > 0) {
        returnedActionButtons = returnUpdatedActionButtons(state.data, updatedActionButtons, state.checkedQuestions, multiChoice)
      }
      setActionButtons(returnedActionButtons)
    }
  }, [state.data, state.checkedQuestions, actionButtons, prevCheckedQuestions])

  const onExpandRowHandler = (questionId) => {
    const dataCopy = [...state.data]
    dataCopy[questionId].expanded = !dataCopy[questionId].expanded
    setState({ ...state, data: dataCopy })
  }

  const onSubmitReplaceHandler = (findValue, replaceValue, isDichotomyA, isCaseSensitive) => {
    if (findValue && replaceValue) {
      const updatedCheckedQuestions = returnQuestionsWithReplacedText(state.checkedQuestions, isCaseSensitive, isDichotomyA, findValue, replaceValue)
      setState({
        ...state,
        checkedQuestions: updatedCheckedQuestions
      })
    }
  }

  const onChangeExcluded = (e, index, type, valuesProperty, valID) => {
    const newValue = e.target.checked ? 'false' : 'true'
    const updatedData = [...state.data]
    switch (type) {
      case 'nmopen-arr':
        updatedData[index][valuesProperty][valID]['@miextsss:excluded'] = newValue
        break;
      case 'nmopen-obj':
        updatedData[index][valuesProperty][valID]['@miextsss:excluded'] = newValue
        break;
      case 'default-obj':
        updatedData[index]['@miextsss:excluded'] = newValue
        break;
      case 'default-array':
        updatedData[index][valuesProperty].value[valID]['@miextsss:excluded'] = newValue
        break;
      case 'multiGridCol':
        updatedData[index][valuesProperty].forEach(ques => {
          if (ques.variable) {
            ques.variable[valID]['@miextsss:excluded'] = newValue
          } else {
            ques.values.value[valID]['@miextsss:excluded'] = newValue
          }
        })
        break;
      case 'multiGridRow':
        updatedData[index][valuesProperty][valID]['@miextsss:excluded'] = newValue
        break;
      default:
        break;
    }
    setState({
      ...state,
      data: updatedData
    })
  }

  const rowRender = ({ index, key, parent, style }) => {
    const updatedData = setSearchData(searchValue, state.data);
    const ques = updatedData[index]
    const originalIndex = state.data.findIndex(el => el.id === ques.id)
    return (
      <CellMeasurer
        cache={_cache}
        columnIndex={0}
        key={key}
        rowIndex={index}
        parent={parent}>
        {({ measure }) => (
          <QuestionButton
            ques={ques}
            id={index}
            originalIndex={originalIndex}
            measure={measure}
            onExpandHandler={onExpandRowHandler}
            toggleQuestion={toggleQuestion}
            onCheckQuestion={onCheckQuestion}
            style={style} />
        )}
      </CellMeasurer>
    )
  }

  const actions = [
    onMultiselectMerge,
    onMultiGridMerge,
    onCreateNumericList,
    onCreateOpenList,
    onEnableDisableQuestion,
    onUndoQuestion,
    onUndoAutoMerged,
    onSetWeightHandler,
    onSetRespondentIdHandler
  ]

  const hideErrorModal = () => {
    setState({ ...state, submitLoadingStatus: false, submitErrMsg: '' })
    props.history.push({ pathname: `/projects/${uploadData.projectId}` })
  }

  if (state.redirect) {
    return <Redirect to="/" />
  }
  return (
    <section className="main import-container">
      {
        state.submitErrMsg &&
        <ErrorMessage
          type="modal"
          onHide={() => hideErrorModal()}
          errorMessage={state.submitErrMsg}
        />
      }
      {
        genericLibrary &&
        <GenericLibrary
          type="import-template"
          scope="import-template"
          setPayload={setPayload}
          handleClose={handleClose}
        />
      }
      {
        libraryConfirmModal &&
        <SaveDatasetToLibraryModal onClose={closeLibraryModal} />
      }
      {
        state.multiChoiceModalShow &&
        <MultiChoiceModal
          onMergeMultiChoiceSubmit={onMergeMultiChoiceSubmit}
          onCheckedChangedHandler={onCheckedChangedHandler}
          onSubmitReplaceHandler={onSubmitReplaceHandler}
          data={state.checkedQuestions}
          show={state.multiChoiceModalShow}
          onHide={() => setState({ ...state, multiChoiceModalShow: false })}
        />
      }
      {
        !state.data ?
          <InProgressOverlay theme="primary" type="fullscreen" />
          :
          <React.Fragment>
            <div className="container-fluid">
              <div className="row border-bottom border-primary">
                <div className="bg-dark-analyze p-3 d-flex justify-content-between align-items-center w-100">
                  <button className="btn btn-import-dark-grey d-md-none m-1" type="button" data-toggle="collapse" data-target="#actionButtons" aria-expanded="false" aria-controls="actionButtons">
                    <span>Actions</span>
                    <i className="fas fa-angle-down ml-2" />
                  </button>
                  <div className="collapse d-md-flex " id="actionButtons">
                    <div className="d-flex flex-wrap gap-md">
                      {
                        actionButtons.map((btn, id) => {
                          if (btn.dropdown && btn.dropdownItems) {
                            return (
                              <DropdownButton
                                key={id}
                                bgColor={btn.bgColor}
                                title={btn.title}
                                icon={btn.icon}
                                action={actions[id]}
                                disabled={btn.disabled}
                                dropdown={btn.dropdown}
                                dropdownItems={btn.dropdownItems}
                                checkedQuestions={state.checkedQuestions}
                                data={state.data} />
                            )
                          }
                          return (
                            <Button
                              key={id}
                              bgColor={btn.bgColor}
                              title={btn.title}
                              icon={btn.icon}
                              action={actions[id]}
                              disabled={btn.disabled} />
                          )
                        })
                      }
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      disabled={state.submitLoadingStatus}
                      className="btn btn-analyze"
                      onClick={onFinalizeImportData}>
                      {
                        state.submitLoadingStatus &&
                        <span className="spinner-border spinner-border-sm mr-2" />
                      }
                      <span>Finalize</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid h-100 px-0">
              <div className="no-gutters import-section row bg-white h-100">
                <div className="col-xs-12 col-sm-6 col-lg-4 d-flex flex-column ">
                  <QuestionControls
                    multiSelect={state.multiSelect}
                    onToggleAll={onToggleAll}
                    toggleAll={state.toggleAll}
                    onToggleControl={onToggleControl}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    toggleSearch={state.toggleSearch}
                    toggleReplace={state.toggleReplace}
                    onReplaceHandler={onReplaceHandler} />
                  <div className="flex-grow-1 import-list">
                    <AutoSizer>
                      {({ width, height }) => (
                        <List
                          deferredMeasurementCache={_cache}
                          rowCount={setSearchData(searchValue, state.data).length}
                          height={height}
                          rowHeight={_cache.rowHeight}
                          width={width}
                          rowRenderer={rowRender} />
                      )}
                    </AutoSizer>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-lg-8 d-flex h-100 flex-column border-left categories p-3">
                  {
                    !state.hideTable ?
                      state.displayTable ?
                        <UpdateValuesTable
                          el={state.displayTable}
                          onChangeHandler={onChangeHandler}
                          onChangeExcluded={onChangeExcluded}
                          onChangeMultiGridTextHandler={onChangeMultiGridTextHandler}
                          index={state.data.findIndex(el => el.id === state.displayTable.id)}
                        />
                        : null
                      : null
                  }
                </div>
              </div>
            </div>
          </React.Fragment>
      }
    </section >
  );
}
