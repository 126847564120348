export default () => {
  const buttons = [
    {
      id: "multiChoice",
      bgColor: 'btn-import-dark-grey',
      title: 'Create multi choice',
      icon: 'fa fa-fw far fa-check-square',
      action: null,
      disabled: true
    },
    {
      id: "multiGrid",
      bgColor: 'btn-import-dark-grey',
      title: 'Create grid',
      icon: 'fa fa-fw far fa-table',
      action: null,
      disabled: true
    },
    {
      id: "numericList",
      bgColor: 'btn-import-dark-grey',
      title: 'Create numeric list',
      icon: 'fa fa-fw fas fa-list-ol',
      action: null,
      disabled: true
    },
    {
      id: "openList",
      bgColor: 'btn-import-dark-grey',
      title: 'Create open list',
      icon: 'fa fa-fw fas fa-line-columns',
      action: null,
      disabled: true
    },
    {
      id: "enable/disable",
      bgColor: 'btn-import-dark-grey',
      title: 'Enable/Disable',
      icon: 'fa fa-fw fas fa-toggle-on',
      action: null,
      disabled: true
    },
    {
      id: "undo",
      bgColor: 'btn-import-dark-grey',
      title: 'Undo Grid/Multi',
      icon: 'fa fa-fw fas fa-redo',
      action: null,
      disabled: true
    },
    {
      id: "undoAutoMerged",
      bgColor: 'btn-import-dark-grey',
      title: 'Undo Auto-merged',
      icon: 'fa fa-fw fas fa-redo',
      action: null,
      disabled: true
    },
    {
      id: "weight",
      bgColor: 'btn-outline-dark-grey',
      title: 'Weight',
      icon: 'fa fa-fw fas fa-weight-hanging',
      action: null,
      disabled: true,
      dropdown: true,
      dropdownItems: [
        {
          title: 'Population weight',
          action: 'population'
        },
        {
          title: 'Sample weight',
          action: 'sample'
        },
        {
          title: 'Do not use as weight',
          action: 'none'
        }
      ]
    },
    {
      id: "id",
      bgColor: 'btn-outline-dark-grey',
      title: 'ID',
      icon: 'fa fa-fw fas fa-fingerprint',
      action: null,
      disabled: true,
      dropdown: true,
      dropdownItems: [
        {
          title: 'Use as respondent id',
          action: 'respondentId'
        },
        {
          title: 'Do not use as respondent id',
          action: 'none'
        }
      ]
    }
  ]

  return buttons
}