import { Tooltip } from "@progress/kendo-react-tooltip"
import { Link } from "react-router-dom"
import { Icon } from "../../shared/Icon/Icon"
import { useDispatch } from "react-redux"

import type { PreScreeningAudienceType } from "../../../interfaces/auddineceAccessInterfaces/audienceOverviewTypes"

type Props = {
  prescreening: PreScreeningAudienceType
}

export const PreScreenerSuccess = ({ prescreening }: Props) => {
  const dispatch = useDispatch();

  const handleCopySurveyLink = () => {
    if (prescreening?.surveyUrl) {
      navigator.clipboard.writeText(prescreening.surveyUrl).then(() => {
        dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The survey link has been copied to clipboard.' } })
      });
    }
  }

  return (
    <div className="d-flex flex-column gap-sm alert alert-success m-0" role="alert">
      <p className="m-0 stronger">Survey is published</p>
      <div className="d-flex gap-sm align-items-center">
        <Link target="_blank" rel="noopener noreferrer" to={{ pathname: prescreening.surveyUrl?.replace("[%WID%]", "test") }}>Survey link: {prescreening.surveyUrl}</Link>
        <Tooltip anchorElement="target" position="top">
          <button type='button' onClick={() => handleCopySurveyLink()} className='btn btn-transparent h-24 w-40' title="Copy survey link to clipboard">
            <Icon type="copy" className="pe-none" />
          </button>
        </Tooltip>
      </div>
    </div>
  )
}