import returnMergeRowsData from "../returnMergeRowsData/returnMergeRowsData";
import { questionEditorPasteEditsHandler } from "./questionEditorPasteEditsHandler/questionEditorPasteEditsHandler";
import { returnValidJson } from "../../../../../../Workflow/WorkflowTabContent/WorkflowDesignTabContent/helpers/returnValidJson/returnValidJson";

export default (e, questionsState, selectedQuestion, questions, rowsSelectedState, source, body, toggleCategorizeDialog, selectedGroupTab, dispatch, crossTabQuestionEditorCopyData, setShowPasteEditsModal) => {
  let updatedQuestionsState = [...questionsState]

  if (e.item.action === 'mergeRows') {
    updatedQuestionsState = returnMergeRowsData(questionsState, selectedQuestion, questions, rowsSelectedState, source, body, selectedGroupTab)
  } else if (e.item.action === 'setWeightValues') {
    if (updatedQuestionsState[selectedQuestion].subGroups.length > 0) {
      updatedQuestionsState[selectedQuestion].subGroups.filter(group => !group.merged).forEach(row => { row.weightValue = Number(row.code) })
    } else {
      updatedQuestionsState[selectedQuestion].groups.filter(group => !group.merged).forEach(row => { row.weightValue = row.code })
    }
  } else if (e.item.action === 'categorize' && questionsState[selectedQuestion].subGroups) {
    toggleCategorizeDialog()
  } else if (e.item.action === 'copyEdits') {
    dispatch({
      type: 'SET_XT_QUESTION_EDITOR_COPY_DATA', payload: {
        selectedQuestionId: questionsState[selectedQuestion].id,
        selectedQuestionType: questionsState[selectedQuestion].type,
        groups: questionsState[selectedQuestion].groups,
        subGroups: questionsState[selectedQuestion].subGroups
      }
    })
    dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The row edits have been copied successfully.' } })
  } else if (e.item.action === 'pasteEdits') {
    if (crossTabQuestionEditorCopyData) {
      questionEditorPasteEditsHandler(updatedQuestionsState, crossTabQuestionEditorCopyData, selectedQuestion, dispatch, setShowPasteEditsModal);
    } else {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'No copied edits have been found, please try copying the elements again.' } })
    }
  }

  return updatedQuestionsState;
}