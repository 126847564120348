import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DropDownList } from '@progress/kendo-react-dropdowns';

import type { RootState } from '../../../../../store/reducers/rootReducer';
import { fetchGetJson as fetchAllQuestions, fetchPostJson } from '../../../../../services/services';

export const SurveyCustomQuestionsOverviewTabContentDropdown = () => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch();
  const { name, survey }: { name: string, survey: string } = useParams();
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dropdownValue, setDropdownValue] = useState<string | null>(null);
  const [value, setValue] = useState(null);

  const data = useSelector((theState: RootState) => theState.surveyCustomQuestionsStateReducer);

  const handleChange = (item: TODO) => {
    setValue(item)
    setDropdownValue(null)
    fetchPostJson(`su/projects/${name}/surveys/${survey}/questions/${item.questionId}/subquestion-index/${item.subqIndex}/custom-questions/${data.showOverview.customQuestion.id}/preview`, token)
      .then((res: TODO) => {
        if (res && !res.error && !res.message) {
          setDropdownValue(res.url)
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
        }
      })
  };

  const onOpenDropdownHandler = () => {
    setIsLoading(true)
    fetchAllQuestions(`su/projects/${name}/surveys/${survey}/structure`, token)
      .then((res: TODO) => {
        setIsLoading(false)
        if (res && !res.error && !res.message) {
          setQuestions(res.questions.map((el: TODO) => { return { ...el, ignoreDeleteDisable: true } }))
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
        }
      })
  }

  const listNoDataRender = () => {
    if (isLoading) {
      return (
        <div className='py-5 d-flex justify-content-center'>
          <div className='spinner-border spinner-border-sm' />
        </div>
      )
    }
    return <p className='py-5 text-center text-muted m-0'>No questions found</p>
  }

  return (
    <div className="d-flex flex-column h-100 p-5 border-left">
      <div className="d-flex justify-content-between align-items-end pb-3">
        <div className="d-flex flex-column flex-grow-1">
          <label className="medium strong mb-1">Preview</label>
          <DropDownList
            value={value}
            data={questions}
            loading={isLoading}
            textField='qno'
            defaultItem={{ qno: 'Select a question', questionId: 1 }}
            onOpen={onOpenDropdownHandler}
            listNoDataRender={listNoDataRender}
            onChange={(e) => handleChange(e.value)}
          />
        </div>
        {
          dropdownValue &&
          <button
            type="button"
            className="btn btn-shadow ml-3"
            onClick={() => handleChange(value)}>Refresh
          </button>
        }
      </div>
      {
        dropdownValue &&
        <iframe
          src={dropdownValue}
          id="iframeDropdown"
          title="Custom question"
          className="border-0 mt-0"
          style={{ flex: '1 1 auto', overflow: 'auto', height: '0px' }}
        />
      }
    </div>
  );
};