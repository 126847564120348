import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

import { apiSurveyURL, fetchGetJson as getUnreadItemCount, subscriptionKey } from '../../../services/services';

export const useSignalR = () => {
  const { token } = useSelector((state) => state.tokenStateReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      getUnreadItemCount("inboxes/has-unread-items", token).then((res) => {
        if (res) {
          if (res.value) {
            dispatch({ type: 'UPDATE_INBOX_NOTIFICATIONS_BADGE', payload: res.value });
          }
        }
      });
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (token) {
      const connection = new HubConnectionBuilder()
        .withUrl(`${apiSurveyURL}inboxes/signalr`, {
          accessTokenFactory: () => token,
          headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();
      connection.on('inboxnotifications', inboxNotificationAdded);
      connection.onclose(() => console.log('disconnected'));
      connection
        .start()
        .then(() => console.log("connection started"))
        .catch(console.error);

      function inboxNotificationAdded(inboxItem) {
        // console.log('inbox item', inboxItem);
        // New inbox item...
        dispatch({ type: 'UPDATE_NOTIFICATION', payload: inboxItem });
        dispatch({ type: 'UPDATE_INBOX_NOTIFICATIONS_BADGE', payload: true });
        if (inboxItem.type === 'DatasetExport' && inboxItem.status === 'Completed' && !inboxItem.isRead) {
          dispatch({ type: 'FINISH_EXPORT_PROGRESS' })
          if (inboxItem.completedStatus === 'Success') {
            dispatch({ type: 'UPDATE_PENDING_NOTIFICATION', payload: { msg: "Dataset has been exported", showCloseBtn: true, itemLink: inboxItem.link } });
          } else if (inboxItem.completedStatus === 'Failure') {
            dispatch({ type: 'UPDATE_PENDING_NOTIFICATION', payload: { msg: inboxItem.message || "Export failed", showCloseBtn: true } });
          }
        }
        if (inboxItem.type === 'AnalysisExport' && inboxItem.status === 'Completed' && !inboxItem.isRead) {
          dispatch({ type: 'FINISH_EXPORT_PROGRESS' })
          if (inboxItem.completedStatus === 'Success') {
            dispatch({ type: 'UPDATE_PENDING_NOTIFICATION', payload: { msg: "Dataset has been exported", showCloseBtn: true, itemLink: inboxItem.link } });
          } else if (inboxItem.completedStatus === 'Failure') {
            dispatch({ type: 'UPDATE_PENDING_NOTIFICATION', payload: { msg: inboxItem.message || "Export failed", showCloseBtn: true } });
          }
        }
        if (inboxItem.type === 'RecipientListImport' && inboxItem.status === 'Completed' && inboxItem.completedStatus === 'Success' && !inboxItem.isRead) {
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Respondent list uploaded successfully' } });
        }
        if (inboxItem.type === 'RunWorkflow' && inboxItem.status === 'Completed' && inboxItem.completedStatus === 'Success' && !inboxItem.isRead) {
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Finished processing workflow' } });
        }
        if (inboxItem.type === 'RunWorkflow' && inboxItem.status === 'Completed' && inboxItem.completedStatus === 'Failure' && !inboxItem.isRead) {
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Run workflow failed' } });
        }
        if (inboxItem.type === 'TranslationImport' && inboxItem.id === localStorage.getItem('translationImportId')) {
          if (inboxItem.completedStatus === 'Failure') {
            dispatch({ type: 'SHOULD_UPDATE_LANGUAGES', payload: inboxItem.completedStatus });
          } else if (inboxItem.status === 'Completed') {
            dispatch({ type: 'SHOULD_UPDATE_LANGUAGES', payload: "Completed" });
          }
        }
        if (inboxItem.type === 'TranslationExport' && inboxItem.id === localStorage.getItem('translationExportId')) {
          if (inboxItem.completedStatus === 'Failure') {
            dispatch({ type: 'SET_TRANSLATIONS_EXPORT_STATUS', payload: 'failure' });
          } else if (inboxItem.status === 'Completed') {
            dispatch({ type: 'SET_TRANSLATIONS_EXPORT_STATUS', payload: 'completed' });
          }
        }
        if (inboxItem.type === 'DatasetImport' && inboxItem.status === 'Completed') {
          if (inboxItem.completedStatus === 'Success' && !inboxItem.isRead) {
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Your dataset has been imported' } });
          }
          // Fetch projects again
          dispatch({ type: 'UPDATE_FETCH_PROJECTS', payload: true });
        } else if (inboxItem.type === 'CreateSurveyFromSurvey' && inboxItem.status === 'Completed') {
          if (inboxItem.completedStatus === 'Success' && !inboxItem.isRead) {
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The survey has been duplicated' } });
            dispatch({ type: 'UPDATE_FETCH_PROJECTS', payload: true });
          } else if (inboxItem.completedStatus === 'Failure' && !inboxItem.isRead) {
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Survey duplication failed' } });
          }
        }
      }
    }
  }, [token, dispatch]);
};

