import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import type { RootState } from '../../../../../../../../store/reducers/rootReducer';

interface Props {
  onClose: () => void
  lists: TODO;
  setListData: (lists: TODO) => void;
}

export const SurveyDesignPasteListModal = ({ onClose, lists, setListData }: Props) => {
  const { theData } = useSelector((theState: RootState) => theState.surveyInitialDataReducer);
  const [textAreaValue, setTextAreaValue] = useState(lists[theData.selectedListIndex]?.elements?.map((el: TODO) => el.elements[0].textValues[theData.editingLanguage]).join('\n'))
  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    if (!didMount && textAreaValue?.split('\n').length > 0) {
      setDidMount(true)
      // Remove html tags from element if it only contains text inside
      const splitTexts = JSON.parse(JSON.stringify(textAreaValue?.split('\n')))
      const textsArray = Array.from(splitTexts)
      textsArray.forEach((item: any, index: number) => {
        if (new DOMParser().parseFromString(item, "text/html").body.childNodes.length === 1) {
          const doc = new DOMParser().parseFromString(item, "text/html").body.firstChild;
          const array = doc ? Array.from(doc.childNodes) : []
          if (array.length === 1) {
            textsArray[index] = doc?.textContent ? doc.textContent : item
          }
        }
      })
      setTextAreaValue(textsArray.join('\n'))
    }
  }, [textAreaValue, didMount])

  const onPasteList = () => {
    const listItems: string[] = JSON.parse(JSON.stringify(textAreaValue.split('\n')))
    const updatedListsArr = [...lists]
    let updatedListElements: TODO[] = updatedListsArr[theData.selectedListIndex].elements
    const newListItem: TODO = {
      type: "l",
      attributes: {
        code: 1
      },
      elements: [{
        attributes: {},
        textValues: { [theData.editingLanguage]: '' },
        type: "text"
      }]
    }
    if (listItems.length >= updatedListElements.length) {
      listItems.forEach((item: string, index: number) => {
        if (updatedListElements[index]) {
          updatedListElements[index].elements[0].textValues[theData.editingLanguage] = item
        } else {
          const maxCodeValue = updatedListElements.reduce((max, obj) => (Number(obj.attributes.code) > max ? Number(obj.attributes.code) : max), Number.MIN_SAFE_INTEGER);
          updatedListElements.push({ ...newListItem, attributes: { ...newListItem.attributes, code: (maxCodeValue + 1).toString() }, elements: [{ ...newListItem.elements[0], textValues: { [theData.editingLanguage]: item } }] })
        }
      })
    } else {
      updatedListElements = listItems.map((item: TODO, index: number) => { return { ...updatedListElements[index], elements: [{ ...updatedListElements[index].elements[0], textValues: { [theData.editingLanguage]: item } }] } })
    }

    updatedListsArr[theData.selectedListIndex].elements = updatedListElements
    setListData(updatedListsArr);
    onClose()
  }

  return (
    <Dialog width={600} title={"Paste list"} onClose={onClose}>
      <div className="p-4">
        <label>Enter list items (one per line):</label>
        <textarea
          rows={15}
          value={textAreaValue}
          className='form-control'
          onChange={(e) => setTextAreaValue(e.target.value)}
        />
      </div>
      <DialogActionsBar>
        <button type='button' className="k-button btn btn-secondary" onClick={onClose}>Cancel</button>
        <button type='button' disabled={!textAreaValue} className="k-button btn btn-primary" onClick={onPasteList}>Save</button>
      </DialogActionsBar>
    </Dialog>
  )
}