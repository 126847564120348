export function activeQuestion(destClone, droppableDestination, droppableSource, reorder, state) {
  if (!reorder) {
    if (droppableDestination.droppableId === 'firstColumn') {
      destClone.forEach(col => {
        col.active = false
      })
      if (droppableSource.droppableId === 'scndColumn') {
        destClone[droppableDestination.index].active = true
      } else {
        destClone[droppableDestination.index].active = true
      }

    } else {
      if (droppableSource.droppableId === 'firstColumn') {
        if (state.firstColumn.length > 1) {
          state.firstColumn.forEach(el => {
            el.active = false;
          })
          state.firstColumn[0].active = true
        }
      }
    }
  } else {
    destClone.forEach(el => {
      el.active = false;
    })
    destClone[0].active = true
  }
}