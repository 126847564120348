import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BaseDropDownList } from '../../../../../../shared/Inputs';
import InlineElementEditor from '../../EditTabContent/InlineElementEditor/InlineElementEditor';

export const Skip = ({ items, onSkipDestination }) => {
  const { theData } = useSelector(theState => theState.surveyInitialDataReducer);

  return (
    <div className="h-100">
      <table className="table table-borderless survey-grid">
        <thead className="d-flex border-bottom py-2 mb-3">
          <tr className='w-100 d-flex'>
            <td className="w-50 py-1 px-0">If the answer is...</td>
            <td className="w-50 py-1 px-0">Skip to</td>
          </tr>
        </thead>
        <tbody>
          {theData.selectedItem.elements.find(el => el.type === 'rgroup').elements.filter((item) => item.type !== "rc").map((row, key) => (
            <tr key={key} className="d-flex ">
              {
                row.type !== 'r' ?
                  <td className="w-50 d-flex align-self-start px-0">
                    <InlineElementEditor
                      disabled={true}
                      content={row.attributes?.idref}
                      type="r"
                      subQuesIndex={0} />
                  </td>
                  :
                  row.elements.filter(el => el.type === 'text').length > 1 ?
                    <td className="w-50 d-flex flex-column align-self-start">
                      <span className="badge badge-secondary align-self-start">Multiple elements row</span>
                      {row.elements.filter(el => el.type === 'text').map((row, key) => (
                        <InlineElementEditor
                          key={key}
                          disabled={true}
                          content={row.textValues[theData.editingLanguage] !== '<p></p>' && row.textValues[theData.editingLanguage] !== '' ?
                            row.textValues[theData.editingLanguage] : `Row element ${key + 1}`}
                          type="r"
                          subQuesIndex={0}
                        />
                      ))}
                    </td> :
                    <td className="w-50 d-flex align-self-start px-0">
                      <InlineElementEditor
                        disabled={true}
                        content={row.elements.find(el => el.type === 'text').textValues[theData.editingLanguage] !== '<p></p>' && row.elements.find(el => el.type === 'text').textValues[theData.editingLanguage] !== '' ?
                          row.elements.find(el => el.type === 'text').textValues[theData.editingLanguage] : `Row ${key + 1}`}
                        type="r"
                        subQuesIndex={0}
                      />
                    </td>
              }
              {
                row.type === 'r' &&
                <td className={`w-50 d-flex ${row.elements.filter(el => el.type === 'text').length > 1 ? "align-self-center" : "align-self-start"} py-1 px-0`}>
                  <BaseDropDownList
                    data={items}
                    textField="text"
                    popupSettings={{ className: 'survey-dropdown' }}
                    value={row.attributes?.skip ? items.find(item => item.id === row.attributes.skip) : items[0]}
                    onChange={(e) => onSkipDestination(e, key)}
                  />
                </td>
              }
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}