import { Tooltip } from "@progress/kendo-react-tooltip"
import { useDispatch, useSelector } from "react-redux";

import { DropdownButton } from "../../../../../../../shared/DropdownButton/DropdownButton"
import { Icon } from "../../../../../../../shared/Icon/Icon"
import { useDebounce } from "../../../../../../../shared/customHooks/useDebounce";
import type { RootState } from "../../../../../../../../store/reducers/rootReducer";

type Props = {
  statementsSort: string;
  setStatementsSort: (sort: string) => void;
  onAddElement: (type: string, index: number) => void;
  subQuesIndex: number;
  subquestion: TODO;
  onAdvancedEdit: (type: string, index: number) => void;
  setShowStatementsAdvancedTable: (show: boolean) => void;
  listItemsDropdown: TODO;
}

export const StatementsActions = ({ statementsSort, setStatementsSort, onAddElement, subQuesIndex, subquestion, onAdvancedEdit, setShowStatementsAdvancedTable, listItemsDropdown }: Props) => {
  const dispatch = useDispatch();
  const { theData } = useSelector((theState: RootState) => (theState.surveyInitialDataReducer));
  const saveWithDebounce = useDebounce(() => dispatch({ type: 'SAVE_QUESTION' }), 1000);

  const onMoreActionsItemClick = (action: string, elements?: TODO) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    const updatedElements = JSON.parse(JSON.stringify(elements))
    if (statementsSort === action) {
      setStatementsSort('')
    } else {
      setStatementsSort(action)
      if (action === 'Ascending order') {
        updatedElements.sort((a: { attributes: { code: string; }; }, b: { attributes: { code: string; }; }) => Number.parseFloat(a.attributes.code) - Number.parseFloat(b.attributes.code));
      } else if (action === 'Descending order') {
        updatedElements.sort((a: { attributes: { code: string; }; }, b: { attributes: { code: string; }; }) => Number.parseFloat(b.attributes.code) - Number.parseFloat(a.attributes.code));
      }
      updatedSelectedItem.elements.find((el: { type: string; }) => el.type === 'rgroup').elements = updatedElements
      dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
      saveWithDebounce()
    }
  }

  const renderMoreActionsItem = (item: TODO, elements: TODO) => {
    if (item.text === "Ascending order") {
      return (
        <div className="d-flex flex-column ">
          <div style={{ fontSize: "0.8rem" }} className="text-muted px-4 pt-2 pb-1">Code</div>
          <button
            type="button"
            className="dropdown-item px-4"
            onClick={() => onMoreActionsItemClick(item.text, elements)}>
            <span className='text-truncate'>{item.text}</span>
          </button>
        </div>
      )
    }
    return (
      <button
        type="button"
        className="dropdown-item px-4"
        onClick={() => onMoreActionsItemClick(item.text, elements)}>
        <span className='text-truncate'>{item.text}</span>
      </button>
    )
  }

  const onStatementsDropdownItemClick = ({ item }: { item: TODO }) => {
    if (item.text === 'Edit all statements') {
      onAdvancedEdit('r', subQuesIndex)
    } else if (item.text === 'Advanced view') {
      setShowStatementsAdvancedTable(true)
    }
  }

  const addList = (name: string) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    const refElement = {
      type: "listref",
      attributes: { idref: name }
    }

    const filtered = updatedSelectedItem.elements.find((el: { type: string; }) => el.type === "rgroup").elements.filter((el: { type: string; }) => el.type === 'listref')
    const found = filtered.find((el: { attributes: { idref: TODO; }; }) => el.attributes.idref === name)
    if (found) {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "List already exists" } })
    } else {
      updatedSelectedItem.elements.find((el: { type: string; }) => el.type === "rgroup").elements.push(refElement)
    }

    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    saveWithDebounce()
  }

  return (
    <div className="d-flex align-items-center gap-sm">
      {
        subquestion && (subquestion.attributes?.type !== 'rn' && subquestion.attributes?.type !== 'rm' && subquestion.attributes?.type !== 'f' && subquestion.attributes?.type !== 'h') &&
        <Tooltip className='answer-layout-table-tooltip ' anchorElement="target" position="top">
          <DropdownButton
            hideChevron={true}
            title="More actions"
            icon="more-horizontal"
            className="btn btn-transparent p-0"
            renderItem={(item) => renderMoreActionsItem(item, theData.selectedItem.elements.find((el: TODO) => el.type === 'rgroup').elements.filter((row: TODO) => row.type === 'listref' || row.elements?.length > 0))}
            onItemClick={e => onMoreActionsItemClick(e.item.text, "statement")}
            items={[{ text: 'Ascending order' }, { text: 'Descending order' }]}
          />
        </Tooltip>
      }
      <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
        <button
          type="button"
          disabled={theData.selectedItem.disabled
            || theData.selectedItem.published
            || subquestion.disabled
            || theData.selectedItem.elements.find((el: { type: string }) => el.type === "rgroup").elements.some((el: { type: string; }) => el.type === "rc")
            || theData.selectedItem.elements.find((el: { type: string; }) => el.type === 'rgroup').elements.filter((el: { type: string; }) => el.type === 'r').find((el: { elements: { filter: (arg0: (el: TODO) => boolean) => { (): TODO; new(): TODO; length: number; }; }; }) => el.elements.filter(el => el.type === 'text').length > 1)
          }
          onClick={() => onStatementsDropdownItemClick({ item: { text: 'Edit all statements' } })}
          title={subquestion && (subquestion.attributes?.type === 'rn' || subquestion.attributes?.type === 'rm') ? "Paste statements" : "Paste answers"}
          className="btn btn-transparent p-0">
          <Icon type="dashboard-edit" className='pe-none fill-primary' />
        </button>
      </Tooltip>
      <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
        <button type="button" onClick={() => onStatementsDropdownItemClick({ item: { text: 'Advanced view' } })} title="Advanced view" className="btn btn-transparent p-0">
          <Icon type="survey-advanced-view" className='pe-none fill-primary' />
        </button>
      </Tooltip>
      <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
        <DropdownButton
          className={`btn btn-transparent p-0 ${theData.originalData.lists?.length === 0 ? "disabled" : ""}`}
          hideChevron={true}
          icon="add-lists"
          onItemClick={e => addList(e.item.text)}
          items={listItemsDropdown}
          title="Add list"
        />
      </Tooltip>
      <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
        <button
          type="button"
          className="btn btn-icon btn-transparent p-0"
          onClick={() => onAddElement('r', subQuesIndex)}
          title={subquestion?.attributes?.type === 'rn' || subquestion?.attributes?.type === 'rm' ? 'Add statement' : 'Add answer'}
          disabled={theData.selectedItem.disabled || subquestion.disabled}>
          <Icon type="add" className='pe-none fill-primary' />
        </button>
      </Tooltip>

      <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
        <button
          type="button"
          className="btn btn-icon btn-transparent p-0"
          onClick={() => onAddElement('rc', subQuesIndex)}
          title="Add answer header"
          disabled={theData.selectedItem.disabled || subquestion.disabled}
        >
          <i className="far fa-layer-plus fa-lg pe-none d-flex align-items-center justify-content-center" style={{ width: "24px", height: "24px" }} />
        </button>
      </Tooltip>

    </div>
  )
}