import { CustomHTMLHeader } from './CustomHTMLHeader/CustomHTMLHeader';
import { CustomHTMLFooter } from './CustomHTMLFooter/CustomHTMLFooter';
import { AdvancedOptionsJavascript } from './AdvancedOptionsJavascript/AdvancedOptionsJavascript';
import { AdvancedOptionsCustomCSS } from './AdvancedOptionsCustomCSS/AdvancedOptionsCustomCSS';
import type { Theme } from '../../../../../../../../../interfaces/surveyInterfaces/themeTypes';

interface Props {
  themeData: Theme
  selectedTab: number
  unsavedChanges: TODO
  setThemeData: (themeData: TODO) => void
  setUnsavedChanges: (unsaved: TODO) => void
}

export const ThemesAdvancedOptionsTabsContent = ({ selectedTab, unsavedChanges, themeData, setThemeData, setUnsavedChanges }: Props) => {
  if (selectedTab === 0) {
    return (
      <CustomHTMLHeader
        themeData={themeData}
        setThemeData={setThemeData}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      />
    )
  } if (selectedTab === 1) {
    return (
      <CustomHTMLFooter
        themeData={themeData}
        setThemeData={setThemeData}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      />
    )
  } if (selectedTab === 2) {
    return (
      <AdvancedOptionsJavascript
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      />
    )
  } if (selectedTab === 3) {
    return (
      <AdvancedOptionsCustomCSS
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      />
    )
  } return null
}