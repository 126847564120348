import { Fragment } from 'react';
import { Input } from '@progress/kendo-react-inputs';

import { BaseDropDownList } from "../../../../../shared/Inputs";
import type { CreateAPIKeyFormData } from '../../../../../../interfaces/adminInterfaces/adminInterfaces';

interface Props {
  errorMessage: string
  formData: CreateAPIKeyFormData
  onChangeHandler: (e: TODO) => void
}

export const CreateAPIKeyContent = ({ formData, errorMessage, onChangeHandler }: Props) => {
  return (
    <Fragment>
      <Input
        required={true}
        name="name"
        label="Name"
        style={{ width: '100%' }}
        className="input-group mb-2"
        onChange={onChangeHandler}
        value={formData.name}
      />
      <BaseDropDownList
        required={true}
        name="userType"
        label="User Type"
        className="mb-3"
        data={["Administrator", "Advanced", "Standard"]}
        style={{ width: '100%' }}
        onChange={onChangeHandler}
        value={formData.userType}
      />
      {
        errorMessage &&
        <div className="alert alert-danger text-center mb-1" role="alert">
          <span>{errorMessage}</span>
        </div>
      }
    </Fragment>
  )
}