const iconColors = ["grey", "light-green", "green", "blue", "light-blue", "yellow", "torqouis", "light-pink", "pink", "bronze", "brown", "light-brown", "light-purple", "purple"]

export const renderCustomQuestionIconColorItem = (onChangeHandler: (data: any) => void) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className='pt-1 pb-2 strong medium'>Select color</div>
        </div>
      </div>
      <div className="row">
        <div className="w-100 my-1 bg-light" style={{ height: '1px' }}></div>
      </div>
      <div className="row p-2">
        {iconColors.map((iconColor, key) => {
          return (
            <div onClick={() => { onChangeHandler({ target: { name: 'iconColor', value: iconColor } }) }} key={key} className="p-05 m-05 cursor-pointer select-color">
              <div className={`custom-question-icon-color bg-custom-questions-${iconColor}`}></div>
            </div>
          )
        })}
      </div>
    </div>
  )
}