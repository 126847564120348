import type React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Redirect, useLocation } from 'react-router-dom';

import ActivateAccountMessage from '../../ActivateAccountMessage/ActivateAccountMessage';

export const UnauthenticatedRouteContent: React.FC = () => {
  const location = useLocation();
  const { loginWithRedirect } = useAuth0();

  if (location.pathname === '/' && location.search.includes('?supportSignUp=true')) {
    return <ActivateAccountMessage />
  }

  if ((window.location.origin.includes('access-staging.researchstudio') || window.location.origin.includes('access.researchstudio') || window.location.origin.includes('access.walr') || window.location.origin.includes('access-staging.walr')) && (!window.location.href.includes('#access_token=') && !window.location.href.includes('?code='))) {
    return (<Redirect to="/welcome" />)
  }

  localStorage.removeItem('selectedCustomerId')
  loginWithRedirect()
}