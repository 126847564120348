import React from 'react';

export default ({ checkBoxAction, checkBoxState, className, originalIndex }) => {
  const onCheckBoxChangeHandler = (e) => {
    e.stopPropagation()
    checkBoxAction(originalIndex)
  }

  return (
    <i
      onClick={(e) => onCheckBoxChangeHandler(e)}
      className={`${className ? `${className} text-primary` : 'text-white'} fa-lg cursor-pointer ${checkBoxState ? 'fas fa-check-square' : 'far fa-square text-muted'}  `}>
    </i>
  )
}