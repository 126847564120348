import { FullHeightSpinner } from '../../../../shared/FullHeightSpinner/FullHeightSpinner';
import type { RemoveConfirmModal, SubAccountUser } from '../../../../../interfaces/userInterface/userInterface';
import { returnSubAccountStatus } from '../../../../shared/helpers/returnSubAccountStatus/returnSubAccountStatus';

interface Props {
  data: SubAccountUser[] | null
  removeConfirmModal: RemoveConfirmModal
  setRemoveConfirmModal: (data: TODO) => void
}

export const SubAccountUsersTable = ({ data, removeConfirmModal, setRemoveConfirmModal }: Props) => {
  if (data) {
    if (data.length > 0) {
      return (
        <table className="table table-striped table-bordered m-0">
          <tbody>
            {
              data.map((el, key) => (
                <tr key={key}>
                  <td className="align-middle">
                    <i className="fas fa-user-circle" />
                  </td>
                  <td className="align-middle">{el.email}</td>
                  <td>
                    <span
                      className={`badge badge-pill badge-${returnSubAccountStatus(el.subAccountUserInfo.isRegistered, 'color')} text-capitalize align-middle py-2 px-3`}>
                      <i className={`${returnSubAccountStatus(el.subAccountUserInfo.isRegistered, 'icon')}`} />
                    </span>
                  </td>
                  <td
                    className="align-middle"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setRemoveConfirmModal({ ...removeConfirmModal, element: el, show: true })}>
                    <i className="fas fa-trash-alt" />
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      )
    }
    return (
      <div className="alert alert-warning text-center mb-0" role="alert">
        <span>There are no invited for this sub account.</span>
      </div>
    )
  }
  return <FullHeightSpinner />
}