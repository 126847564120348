import { useEffect, useState } from 'react';
import Editor from '@monaco-editor/react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { ErrorMessage } from '../../../../../../../../../shared/ErrorMessage/ErrorMessage';
import { FullHeightSpinner } from '../../../../../../../../../shared/FullHeightSpinner/FullHeightSpinner';
import type { RootState } from '../../../../../../../../../../store/reducers/rootReducer';
import { fetchGetJson } from '../../../../../../../../../../services/services';

interface Props {
  onHide: () => void
  fetchData: {
    toolId: string
    projectId: string
    codeMapId: string
    workflowId: string
  }
}

export const ExportCodeFrameModal = ({ onHide, fetchData }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch();
  const { projectId, workflowId, toolId, codeMapId } = fetchData;
  const [isLoading, setIslLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [codeFrameJson, setCodeFrameJson] = useState('');

  useEffect(() => {
    fetchGetJson(`projects/${projectId}/workflows/${workflowId}/tools/${toolId}/code-maps/${codeMapId}/codeframe-exchange`, token)
      .then((res: TODO) => {
        setIslLoading(false);
        if (res?.error || res?.message) {
          setErrorMessage(res.message || res.error);
        } else {
          setErrorMessage('');
          setCodeFrameJson(JSON.stringify(res, null, 4));
        }
      })
  }, [codeMapId, projectId, token, toolId, workflowId])

  const onCopyHandler = () => {
    navigator.clipboard.writeText(codeFrameJson);
    dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The code frame data has been copied to clipboard.' } })
    // onHide();
  }

  return (
    <Dialog width={"60%"} height={"90%"} className='export-codeframe-modal' title="Export code frame" onClose={onHide}>
      {
        isLoading ?
          <FullHeightSpinner />
          :
          errorMessage ?
            <ErrorMessage type="alert" errorMessage={errorMessage} />
            :
            <Editor
              theme='vs-dark'
              defaultLanguage='json'
              value={codeFrameJson}
              onChange={(value) => setCodeFrameJson(value || '')}
            />
      }
      <DialogActionsBar>
        <button type='button' className="btn btn-secondary" onClick={onHide}>Close</button>
        <button type='button' className="btn btn-primary ml-2" onClick={onCopyHandler}>Copy</button>
      </DialogActionsBar>
    </Dialog>
  )
}