export const returnHighestCodeValue = (elements: { type: string, attributes?: { code?: string, redid: string } }[]) => {
  const allCodes: any[] = []
  elements.forEach((el) => {
    if (el.type === 'r') {
      allCodes.push(el.attributes?.code)
    } else if (el.type === 'h') {
      allCodes.push(el.attributes?.code)
    } else if (el.type === 'other') {
      allCodes.push(el.attributes?.code)
    }
  })

  if (allCodes.length > 0) {
    return (Math.max(...allCodes) + 1).toString()
  } else {
    return '1'
  }
}