import { fetchGetJson as getProjectsData } from "../../../../../services/services"
import { process } from '@progress/kendo-data-query';

export const returnProjectsData = (token, dispatch) => {
  return getProjectsData("projects", token)
    .then(res => {
      if (res && Array.isArray(res)) {
        const updatedProjects = [...res]
        updatedProjects.forEach(project => {
          project.createdDate = project.createdUtc.substr(0, 10)
        })
        return {
          data: {
            type: 'SET_PROJECTS_DATA',
            payload: {
              result: process(updatedProjects.slice(0), { take: 10, skip: 0 }),
              dataState: { take: 10, skip: 0 },
              projects: updatedProjects
            }
          },
          originalData: updatedProjects,
          fetchProjects: false
        }
      } if (res && (res.message || res.error)) {
        dispatch({ type: 'UPDATE_ERROR_MESSAGE', payload: { msg: res.message ? res.message : res.error, type: 'modal' } })
      } else return null
    })
}