import React from 'react';

import TableHead from '../TableHead/TableHead';
import DefaultTableBody from '../DefaultTableBody/DefaultTableBody';
import MultigridTableBody from '../MultigridTableBody/MultigridTableBody';
import MultilistTableBody from '../MultilistTableBody/MultilistTableBody';

export default ({ el, onChangeHandler, index, onChangeExcluded, onChangeMultiGridTextHandler }) => {
  let displayTable = null;
  let tableBody = null;

  if (el.multigrid) {
    tableBody = <MultigridTableBody onChangeMultiGridTextHandler={onChangeMultiGridTextHandler} onChangeExcluded={onChangeExcluded} el={el} index={index} />
  } else if (el.multilist || el.nmopen) {
    tableBody = <MultilistTableBody onChangeExcluded={onChangeExcluded} el={el} onChangeHandler={onChangeHandler} index={index} />
  } else {
    tableBody = <DefaultTableBody onChangeExcluded={onChangeExcluded} el={el} onChangeHandler={onChangeHandler} index={index} />
  }

  displayTable =
    <table className="w-100 m-0 valuesTable">
      <TableHead el={el} onChangeHandler={onChangeHandler} index={index} />
      {tableBody}
    </table>

  return displayTable
}