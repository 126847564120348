import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useDispatch } from 'react-redux';

interface Props {
  onHide: () => void
  xmlCode: string
}

export const CopyXmlModal = ({ onHide, xmlCode }: Props) => {
  const dispatch = useDispatch();

  const onCopyXMLHandler = () => {
    navigator.clipboard.writeText(xmlCode).then(() => {
      dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'XML definition has been copied to clipboard' } });
      onHide();
    });
  }

  return (
    <Dialog
      width={450}
      onClose={onHide}
      title={`Copy XML`}>
      <div className="px-3">
        <p className="text-center text-muted m-0">Due to clipboard restrictions in Safari, you need to confirm you want to copy the XML code you requested.</p>
      </div>
      <DialogActionsBar>
        <button className="k-button k-button-solid-base" onClick={onHide}>Cancel</button>
        <button className="k-button btn-analyze" onClick={onCopyXMLHandler}>Copy XML</button>
      </DialogActionsBar>
    </Dialog >
  )
}