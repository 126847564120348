export const returnGreetingText = () => {
  let hour = new Date().getHours();
  switch (true) {
    case hour > 18 && hour <= 5:
      return 'evening';
    case hour >= 5 && hour <= 10:
      return 'morning';
    case hour >= 10 && hour <= 14:
      return 'day';
    case hour >= 14 && hour <= 18:
      return 'afternoon';
    default:
      return 'day'
  }
}

export const returnCountDataType = (type: string) => {
  switch (type) {
    case 'project':
      return 'dashboard-project'
    case 'dataset':
      return 'dashboard-dataset'
    case 'survey':
      return 'dashboard-survey'
    default:
      return 'dashboard-file'
  }
}

export const returnCountDataText = (type: string) => {
  switch (type) {
    case 'project':
      return 'Projects'
    case 'dataset':
      return 'Datasets'
    case 'survey':
      return 'Surveys'
    case 'file':
      return 'Files'
    default:
      return `${type}s`
  }
}