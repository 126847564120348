import { Fragment, type ReactNode, useEffect, useState } from "react";

import { Icon } from "../Icon/Icon";
import { SimpleSwitch } from "../SimpleSwitch/SimpleSwitch";
import type { IconsType } from "../../../interfaces/iconInterfaces/iconInterfaces";

interface menuItem {
  title: string
  action: string
  type?: string
  switchValue?: boolean
  details?: string
  originalData?: TODO
  expanded?: boolean
  icon?: IconsType
  subItems?: menuItem[]
}

interface Props {
  menuButton: string | ReactNode
  items: menuItem[]
  onClickHandler: (item: menuItem) => void
  showMenu: boolean
  onOpenMenu: () => void
}

const DropdownMenu = ({ items, menuButton, onClickHandler, showMenu, onOpenMenu, }: Props) => {
  const [menuItems, setMenuItems] = useState<menuItem[]>([...items])

  useEffect(() => {
    if (items.length > 0) {
      setMenuItems([...items])
    } else {
      setMenuItems([])
    }
  }, [items])

  const expandSubmenu = (itemTitle: string, value: boolean) => {
    const index = menuItems.findIndex(el => el.title === itemTitle)
    const updatedMenuItems = [...menuItems]
    updatedMenuItems[index].expanded = value
    setMenuItems(updatedMenuItems)
  }

  const onItemClick = (e: TODO, item: menuItem) => {
    if (item.type === "switch") {
      onSwitchClick(item)
    } else {
      e.preventDefault();
      onClickHandler(item)
    }
  }

  const onSwitchClick = (item: menuItem) => {
    // Discover the item's index (or if it's a subItem, it's parents' index and the item's index within that parent array )
    // And change their value accordingly
    const updatedMenuItems = [...menuItems]
    if (menuItems.some(el => el.title === item.title)) {
      const index = menuItems.findIndex(el => el.title === item.title)
      updatedMenuItems[index].switchValue = !updatedMenuItems[index].switchValue
    } else {
      menuItems.forEach((el, elIndex) => {
        if (el.subItems) {
          if (el.subItems.some(subItem => subItem.title === item.title)) {
            const index = elIndex;
            const subIndex = el.subItems.findIndex(subItem => subItem.title === item.title)
            // @ts-ignore
            updatedMenuItems[index].subItems[subIndex].switchValue = !updatedMenuItems[index].subItems[subIndex].switchValue
          }
        }
      })
    }
    setMenuItems(updatedMenuItems)
    onClickHandler(item)
  }

  const menuItemRenderer = (item: menuItem, index: number) => {

    if (item.action !== "separator") {
      return (
        <li
          key={index}
          className={`dropdown-item text-truncate ${item.type === "switch" ? "d-flex justify-content-between" : ""} ${item.action === "delete" ? "text-danger" : ""}`}
          onClick={e => onItemClick(e, item)}
          title={item.title}>
          {item.icon}
          <span className={`${item.type === "subpage" ? "pl-3" : ""}`}>{item.title}</span>
          {item.type === "switch" &&
            <SimpleSwitch checked={item.switchValue ? item.switchValue : false} onChange={(e: TODO) => onItemClick(e, item)} />
          }
        </li>
      )
    }
    return <div key={index} style={{ height: '1px' }} className="w-100 my-2 bg-light" />
  }

  return (
    <Fragment>
      <div className={`dropdown ${showMenu ? "show" : ""}`}>
        <button className="btn btn-menu dropdown-toggle" style={{ backgroundColor: "transparent" }} type="button" id="dropdownMenu2" onClick={onOpenMenu} aria-haspopup="true" aria-expanded="false">
          {menuButton}
        </button>
        {showMenu &&
          <Fragment>
            <div className={`dropdown-menu border-0 ${showMenu ? "show dropdown-menu-appear" : ""}`} aria-labelledby="dropdownMenu2">
              {menuItems.map((item, index) => {
                if (!item.subItems) {
                  return menuItemRenderer(item, index)
                }
                return (
                  <div key={index} className="dropdown-item d-flex justify-content-between cursor-pointer" onMouseEnter={() => expandSubmenu(item.title, true)} onMouseLeave={() => expandSubmenu(item.title, false)}>
                    <li>{item.title}</li>
                    <Icon type="chevron-right" className="submenu" />
                    {item.expanded &&
                      <div className={`dropdown-submenu bg-white ${item.expanded ? "dropdown-submenu-appear" : ""}`}>
                        <span className="submenu-label text-muted dropdown-item bg-white">{item.details ? item.details : item.title}</span>
                        {item.subItems.map((subItem: TODO, subIndex: TODO) => {
                          return menuItemRenderer(subItem, subIndex)
                        })}
                      </div>
                    }
                  </div>
                )
              })}
            </div>
            <div className="dropdown-underlay" onClick={onOpenMenu}>
            </div>
          </Fragment>
        }
      </div>
    </Fragment>
  )
}

export default DropdownMenu;