import React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { InProgressOverlay } from '../../../InProgressOverlay/InProgressOverlay';

export default ({ onHide, onRemovePersonHandler, element, isLoading, removeInviteError }) => {
  return (
    <Dialog title={`Delete person`} onClose={onHide}>
      <div className="p-3">
        <p className="m-0">Are you sure you would like to delete this person from the invite list?</p>
        {
          removeInviteError &&
          <div className="alert alert-danger text-center mt-3 mb-0" role="alert">
            <span>{removeInviteError}</span>
          </div>
        }
      </div>
      <DialogActionsBar>
        <button className="k-button k-button-solid-base" onClick={onHide}>Cancel</button>
        <button
          disabled={isLoading}
          className="k-button btn-danger"
          onClick={() => onRemovePersonHandler(element)}>
          {
            isLoading ?
              <InProgressOverlay type="button" theme="light" />
              :
              <span>Delete</span>
          }
        </button>
      </DialogActionsBar>
    </Dialog >
  )
}