import { type FormEvent, useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs"
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import type { ReportFolder, Report } from "../ReportsList";
import type { RootState } from "../../../../../../../../store/reducers/rootReducer";
import { fetchPatchResOrJson } from "../../../../../../../../services/services";

type Props = {
  setShowEditReportsModal: (show: boolean) => void;
  editData: { type: "reportFolder" | "report", reportFolder: ReportFolder, report?: Report } | null
  setEditData: (item: null) => void;
  setReportFolders: (reportFolders: ReportFolder[]) => void;
  reportFolders: ReportFolder[];
}

export const EditReportsModal = ({ setShowEditReportsModal, editData, setEditData, setReportFolders, reportFolders }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);

  const params: { name: string, dataset: string } = useParams();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    const formData = Object.fromEntries(new FormData(event.currentTarget)) as { name: string };

    const patchUrl = editData?.type === "reportFolder" ? `an/projects/${params.name}/analysis/${params.dataset}/reportfolders` : `an/projects/${params.name}/analysis/${params.dataset}/reportfolders/${editData?.reportFolder.id}/reportSnapshots/${editData?.report?.id}`;
    const body = editData?.type === "reportFolder" ? { ...editData.reportFolder, name: formData.name } : { ...editData?.report, name: formData.name }

    fetchPatchResOrJson(patchUrl, token, body)
      .then((res: TODO) => {
        if (res.error || res.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
          setIsLoading(false);
        } else {
          return res.json();
        }
      }).then((responseData: unknown) => {
        if (editData?.type === "reportFolder") {
          const reportFolderResponse = responseData as ReportFolder
          const updatedItems = reportFolders.map(reportFolder => reportFolder.id === reportFolderResponse.id ? reportFolderResponse : reportFolder);
          setReportFolders(updatedItems);
        }

        if (editData?.type === "report") {
          const reportResponse = responseData as Report;

          const updatedReports = editData?.reportFolder.reports.map((item) => item.id === editData.report?.id ? reportResponse : item);
          const updatedItems = reportFolders.map(el => el.id === editData.reportFolder.id ? { ...editData.reportFolder, reports: updatedReports } : el);
          setReportFolders(updatedItems);
        }

        dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: `${editData?.type === "reportFolder" ? "Report folder name updated" : "Report name updated"}` } });

        setIsLoading(false);
        setShowEditReportsModal(false);
        setEditData(null);
      })
  }

  return (
    <Dialog width={500} title={`${editData?.type === "reportFolder" ? "Edit folder name" : "Edit report name"}`} onClose={() => setShowEditReportsModal(false)}>
      <form className="d-flex flex-column justify-content-between h-100 gap-md px-2 py-4" onSubmit={(e) => handleSubmit(e)}>
        <div className="d-flex flex-column gap-md">
          <div className='d-flex flex-column gap-sm'>
            <label htmlFor='reportName' className="d-flex gap-sm align-items-center m-0" style={{ whiteSpace: "nowrap" }}>
              {editData?.type === "reportFolder" ? "Folder name" : "Report name"}
            </label>
            <input id="reportName" name='name' required className='form-control' defaultValue={editData?.type === "reportFolder" ? editData.reportFolder.name : editData?.report?.name} />
          </div>
        </div>

        <DialogActionsBar>
          <button type='button' className="btn btn-secondary" onClick={() => setShowEditReportsModal(false)}>Cancel</button>
          <button
            type='submit'
            className="btn btn-primary"
            disabled={isLoading}>
            {
              isLoading &&
              <span className="spinner-border spinner-border-sm mr-2" />
            }
            Update name
          </button>
        </DialogActionsBar>
      </form>
    </Dialog>
  )
}