import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import type { RootState } from "../../../../../../../../store/reducers/rootReducer";
import { FullHeightSpinner } from "../../../../../../../shared/FullHeightSpinner/FullHeightSpinner";
import { fetchGetJson } from '../../../../../../../../services/services';

type Weight = {
  id: string;
  text: string;
  pop: number;
  definition: null,
  selected: boolean
}

type Props = {
  handleClose: () => void;
  weight: Weight
}

type StatsData = {
  name: string;
  efficiency: number;
  max: number;
  min: number;
  median: number;
}

export const WeightStatsModal = ({ handleClose, weight }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [statsData, setStatsData] = useState<StatsData>({ name: "", efficiency: 0, max: 0, min: 0, median: 0 });

  const dispatch = useDispatch();
  const params: { name: string, dataset: string } = useParams();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);

  useEffect(() => {
    fetchGetJson(`an/projects/${params.name}/analysis/${params.dataset}/weight-stats/${weight.id}`, token)
      .then((res: TODO) => {
        if (res && (res.message || res.error)) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.message ? res.message : res.error}` } })
        } else {
          setIsLoading(false);
          setStatsData(res)
        }
      })
  }, [token, params, weight, dispatch]);

  return (
    <Dialog
      onClose={handleClose}
      title="Weight stats"
      className="d-flex flex-column w-100"
      height={'40%'}
      width={'25%'}
    >
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <FullHeightSpinner />
        </div>
      )
        :
        <div className="d-flex flex-column p-4 gap-md">
          {Object.entries(statsData).map(([key, value]) => (
            <div key={key} className="d-flex justify-content-start">
              <p className={`m-0 ${key !== "name" ? "w-25" : ""}`} style={{ textTransform: "capitalize" }}>
                {key !== "name" ? `${key}:` : ""}
              </p>
              <p className="m-0" title={value.toString()}>
                {value.toString()}
              </p>
            </div>
          ))}
        </div>
      }

      <DialogActionsBar>
        <button type='button' className="k-button btn btn-secondary " onClick={handleClose}>Close</button>
      </DialogActionsBar>
    </Dialog>
  )
}