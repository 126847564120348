export const returnUpdatedMultilistData = (result: any, newData: any) => {
  if (Array.isArray(result.sss.survey.record["miextsss:multilist"])) {
    result.sss.survey.record["miextsss:multilist"].forEach((ques, id) => {
      newData.push({
        "index": id,
        "multilist": true,
        "type": ques['@type'] ? ques['@type'] : ques['@miextsss:type'] ? ques["@miextsss:type"] : "",
        "variable": ques.variable,
        "id": ques["@miextsss:qno"],
        "title": ques["miextsss:label"].text,
        "order": Number(ques["@miextsss:docorder"]),
        "selected": false,
        "checked": false,
        "@ident": ques["@ident"],
        "position": ques.position ? ques.position : "",
        "disabled": ques["@miextsss:excluded"] === "true" ? true : false
      })
    })
  } else {
    const ques = result.sss.survey.record["miextsss:multilist"]
    newData.push({
      "index": 0,
      "multilist": true,
      "type": ques['@type'] ? ques['@type'] : ques['@miextsss:type'] ? ques["@miextsss:type"] : "",
      "variable": ques.variable,
      "id": ques["@miextsss:qno"],
      "title": ques["miextsss:label"].text,
      "order": Number(ques["@miextsss:docorder"]),
      "selected": false,
      "checked": false,
      "@ident": ques["@ident"],
      "position": ques.position ? ques.position : "",
      "disabled": ques["@miextsss:excluded"] === "true" ? true : false
    })
  }
  return newData;
}