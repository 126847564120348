import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import type { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";

import usePrevious from '../../../../../../shared/customHooks/usePrevious';
import type { RootState } from '../../../../../../../store/reducers/rootReducer';
import { ErrorMessage } from '../../../../../../shared/ErrorMessage/ErrorMessage';
import { BaseDropDownList, BaseMultiSelect } from '../../../../../../shared/Inputs';
import { fetchPostResOrJson as exportLanguage, fetchGetJson } from '../../../../../../../services/services';

interface Props {
  languages: TODO[];
  systemLanguages: TODO[];
  updateLanguages: () => void;
  handleClose: () => void;
}

interface Language {
  name: string
  code: string | null
}

export const SurveyDesignExportTranslationModal = ({ languages, systemLanguages, handleClose }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const params: { name: string, survey: string } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedSystemLanguage, setSelectedSystemLanguage] = useState<Language[]>([])
  const [selectedSurveyLanguage, setSelectedSurveyLanguage] = useState<Language>({ name: "None", code: null })
  const { translationsExportStatus, theData } = useSelector((theState: RootState) => theState.surveyInitialDataReducer)
  const prevTranslationsExportStatus = usePrevious(translationsExportStatus)

  const updatedLanguages: TODO[] = []
  languages.forEach((item) => {
    updatedLanguages.push({
      name: `${item.name} (${item.code})`,
      code: item.code
    })
  })

  useEffect(() => {
    if (translationsExportStatus !== prevTranslationsExportStatus) {
      localStorage.removeItem('translationExportId')
      if (translationsExportStatus === 'completed') {
        dispatch({ type: 'SET_TRANSLATIONS_EXPORT_STATUS', payload: null });
        fetchGetJson(`su/projects/${params.name}/surveys/${params.survey}/index/etag`, token)
          .then((res: TODO) => {
            if (res && !res.error && !res.message) {
              const updatedOriginalData = JSON.parse(JSON.stringify(theData.originalData))
              updatedOriginalData._etag = res.etag
              dispatch({ type: 'UPDATE_SURVEY_DATA', payload: { data: JSON.parse(JSON.stringify(theData.data)), originalData: updatedOriginalData } })
              dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The translation file has been sent to your email successfully' } })
              handleClose()
            } else {
              setIsLoading(false)
              setErrorMessage(res.error)
            }
          })
      } else if (translationsExportStatus === 'failure') {
        dispatch({ type: 'SET_TRANSLATIONS_EXPORT_STATUS', payload: null });
        setIsLoading(false)
        setErrorMessage('Something went wrong. Please try again.')
      }
    }
  }, [translationsExportStatus, prevTranslationsExportStatus])

  const onExportLanguageHandler = () => {
    setIsLoading(true)
    const body: { targetLanguageCodes: string[], copyFromCode: string } = {
      targetLanguageCodes: selectedSystemLanguage.map((lang: TODO) => lang.code),
      copyFromCode: selectedSurveyLanguage?.code !== null ? selectedSurveyLanguage?.code : ""
    }
    exportLanguage(`su/projects/${params.name}/surveys/${params.survey}/translation-export`, token, body)
      .then((res: TODO) => {
        if (res && !res.error && !res.message) {
          if (res.status === 202) { return res.json() }
        } else {
          setIsLoading(false)
          setErrorMessage(res.error)
        }
      })
      .then((res: TODO) => {
        if (res?.inboxItemId) {
          localStorage.setItem('translationExportId', res.inboxItemId)
        }
      })
  }

  return (
    <Dialog closeIcon={!isLoading} onClose={handleClose} title={"Export language translation"} width={600}>
      <div className="p-4">
        <BaseMultiSelect
          required
          filterable
          textField="name"
          className="mb-3"
          dataItemKey="code"
          label="Select a language to add"
          data={systemLanguages ? systemLanguages : []}
          style={{ width: '100%' }}
          value={selectedSystemLanguage}
          onChange={(e: TODO) => setSelectedSystemLanguage(e.target.value)}
        />
        <BaseDropDownList
          data={[{ name: "None", code: null }].concat(updatedLanguages)}
          textField="name"
          dataItemKey="code"
          style={{ width: '100%' }}
          className="mb-3"
          label="Copy texts from (optional)"
          value={selectedSurveyLanguage}
          onChange={(e: DropDownListChangeEvent) => setSelectedSurveyLanguage(e.target.value)}
        />
        <p className="text-muted my-2"> Note: We will email you the translation file when it is created.</p>
        {
          errorMessage &&
          <ErrorMessage
            type="alert"
            errorMessage={errorMessage} />
        }
      </div>
      <DialogActionsBar>
        <button type='button' disabled={isLoading} className="k-button btn btn-secondary" onClick={handleClose}>Cancel</button>
        <button
          type='button'
          className="k-button btn btn-primary"
          disabled={selectedSystemLanguage.length === 0 || isLoading}
          onClick={onExportLanguageHandler}>
          {
            isLoading &&
            <div className="spinner-border spinner-border-sm mr-2" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          }
          <span>Export</span>
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}