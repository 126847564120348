import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Input } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { updateSelectCreatedFolder, updateShowNewFolderModal } from '../../../../store/reducers/fileManagerStateReducer/fileManagerStateReducer';
import type { FileManagerItem } from '../../../../interfaces/fileManagerInterfaces/fileManagerInterfaces';
import { ErrorMessage } from '../../ErrorMessage/ErrorMessage';
import { fetchPostResOrJson as createDirectory } from '../../../../services/services';

interface Props {
  type: string
  token: string
  selectedItem: FileManagerItem | null
  selectedExpandedItem: FileManagerItem
  showCreateRootDirectoryModal: boolean
  updateData: () => void
  setShowCreateRooteDirectoryModal: (val: boolean) => void
}

export const FileManagerNewFolder = ({ type, token, selectedItem, selectedExpandedItem, showCreateRootDirectoryModal, updateData, setShowCreateRooteDirectoryModal }: Props) => {
  const dispatch = useDispatch()
  const [folderName, setFolderName] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const onHide = () => {
    dispatch(updateShowNewFolderModal(false))
    setShowCreateRooteDirectoryModal(false)
  }

  const onChangeHandler = (e: TODO) => {
    setFolderName(e.target.value)
  }

  const onSubmitHandler = () => {
    let directoryReference = ''
    if (showCreateRootDirectoryModal) {
      directoryReference = ''
    } else if (selectedExpandedItem) {
      directoryReference = selectedExpandedItem.id
    } else if (selectedItem) {
      directoryReference = selectedItem.id
    }
    const body = { directoryName: folderName, directoryReference: directoryReference }
    //@ts-ignore Suppress warning until services.js gets updated with TypeScript
    createDirectory(`files/directory/${type}`, token, body)
      .then((res: TODO) => {
        if (res.error || res.message) {
          if (res.error) {
            setErrorMessage(res.error)
          } else if (res.message) {
            setErrorMessage(res.message)
          }
        }
        else {
          return res.json()
            .then(() => {
              if (showCreateRootDirectoryModal) {
                dispatch(updateSelectCreatedFolder(folderName))
              }
              updateData()
              onHide()
            })
        }
      })
  }

  return (

    <Dialog minWidth={500} title={"Enter folder name"} onClose={onHide}>
      <div className="p-4">
        <span className='d-flex mb-1'>Name</span>
        <Input
          autoFocus={true}
          required
          className="form-control"
          placeholder="Folder name"
          value={folderName}
          onChange={onChangeHandler} />
      </div>
      {errorMessage &&
        <div className='px-3' onClick={() => setErrorMessage("")}>
          <ErrorMessage type="alert" onHide={() => setErrorMessage("")} errorMessage={errorMessage} />
        </div>
      }
      <DialogActionsBar>
        <button type="button" className="k-button btn btn-secondary" onClick={onHide}>Cancel</button>
        <button type="button" onClick={onSubmitHandler} className="k-button btn btn-primary">Create</button>
      </DialogActionsBar>
    </Dialog >
  )
}