import React, { useEffect, useState } from 'react';

import {
  fetchGetJsonOrEmptyArray as getSurveyRecipients,
  fetchGetJsonOrEmptyArray as getEmailProviders,
  fetchGetJsonOrEmptyArray as getSurveyCampaigns,
  fetchGetJsonOrEmptyArray as getSmsProviders
} from '../../../../../../../services/services'
import ComposeTabContent from '../ComposeTabContent/ComposeTabContent';
import SendTabContent from '../SendTabContent/SendTabContent';
import { FullHeightSpinner } from '../../../../../../shared/FullHeightSpinner/FullHeightSpinner';

export default ({ token, campaign, updateCampaign, updateCampaignState }) => {
  const [stepperValue, setStepperValue] = useState(0)
  const [recipientsData, setRecipientsData] = useState(null)
  const [providers, setProviders] = useState(null)
  const [campaignsList, setCampaignsList] = useState([])

  useEffect(() => {
    if (recipientsData === null && providers === null) {
      getSurveyRecipients(`/projects/${campaign.projectId}/surveys/${campaign.surveyId}/recipients`, token)
        .then(res => {
          if (res) {
            setRecipientsData(res.filter(el => el.status === 'Imported'))
          }
        })
      if (campaign.type === 'email-campaign') {
        getEmailProviders('emailproviders', token)
          .then(res => {
            if (res) {
              setProviders(res)
            }
          })
      } else if (campaign.type === 'sms-campaign') {
        getSmsProviders('smsproviders', token)
          .then(res => {
            if (res) {
              setProviders(res)
            }
          })
      }
      getSurveyCampaigns(`/projects/${campaign.projectId}/surveys/${campaign.surveyId}/campaigns`, token)
        .then(res => {
          if (res) {
            setCampaignsList(res.filter(el => el.id !== campaign.id))
          }
        })
    }
  }, [recipientsData, providers, campaign, token])

  const onStepChange = (value) => {
    setStepperValue(value)
  }

  return (
    <React.Fragment>
      {recipientsData && providers ?
        <React.Fragment>
          {stepperValue === 0 ?
            <ComposeTabContent token={token}
              campaign={campaign}
              onStepChange={onStepChange}
              recipientsData={recipientsData}
              providers={providers}
              updateCampaign={updateCampaign}
            />
            :
            <SendTabContent token={token}
              campaign={campaign}
              onStepChange={onStepChange}
              campaignsList={campaignsList}
              updateCampaign={updateCampaign}
              updateCampaignState={updateCampaignState} />}
        </React.Fragment>
        :
        <FullHeightSpinner />}
    </React.Fragment>
  )
}