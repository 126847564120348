import { Fragment } from 'react';

import type { ToolInfo } from '../../../../interfaces/workflowInterfaces/toolInterfaces';

interface Props {
  editMode: boolean
  workflowData: TODO
  toolData: ToolInfo
  inputPath: { input: string, inputA: string, inputB: string }
  setShowFilterBuilder: (val: boolean) => void
  manuallyUpdateFilter: (dataset: string, expression: string) => void
  setDataset: (val: 'input' | 'inputA' | 'inputB') => void
  setShowInputModal: (val: { show: boolean, type: 'input' | 'output' | null }) => void
}

export const WorkflowToolInputTabContent = ({ editMode, workflowData, toolData, inputPath, manuallyUpdateFilter, setDataset, setShowInputModal, setShowFilterBuilder }: Props) => {
  const editCodingTool = (editMode && workflowData.selectedTool && workflowData.selectedTool.toolType === 'Coding')

  if (
    (editMode && workflowData.selectedTool && workflowData.selectedTool.toolType === 'CleanAndDefine') ||
    editCodingTool ||
    toolData.toolType === 'CleanAndDefine' ||
    toolData.toolType === 'Coding'
  ) {
    return (
      <Fragment>
        <span className="mb-2">Dataset 1</span>
        <div className="d-flex w-100 mb-3">
          <input placeholder="Select dataset" className="form-control h-32" disabled={true} value={editMode && !inputPath.input && workflowData.selectedTool && workflowData.selectedTool.input ?
            `${workflowData.selectedTool.input.projectName || ''}/${workflowData.selectedTool.input.datasetName || ''}` : inputPath.input} />
          <button type='button' className="btn btn-shadow ml-2 flex-shrink-0" disabled={editCodingTool} onClick={() => { setDataset("input"); setShowInputModal({ show: true, type: 'input' }) }}>Select</button>
        </div>
        <div className="d-flex w-100 mb-5">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text h-32">Filter</span>
            </div>
            <input
              className="form-control h-32"
              onChange={(e) => manuallyUpdateFilter('input', e.target.value)}
              disabled={editCodingTool || toolData.toolType === 'Coding' ? true : inputPath.input?.split('/')[1]?.length > 0 ? false : workflowData.selectedTool?.input?.projectId && workflowData.selectedTool?.input?.datasetId ? false : true}
              value={editMode && workflowData.selectedTool?.input?.preFilter ? workflowData.selectedTool.input.preFilter : toolData.input?.preFilter ? toolData.input.preFilter : ''} />
          </div>
          <button
            type='button'
            disabled={editCodingTool || toolData.toolType === 'Coding' ? true : inputPath.input?.split('/')[1]?.length > 0 ? false : workflowData.selectedTool?.input?.projectId && workflowData.selectedTool?.input?.datasetId ? false : true}
            className="btn btn-shadow ml-2 flex-shrink-0"
            onClick={() => { setDataset('input'); setShowFilterBuilder(true) }}>
            Select</button>
        </div>
        {editCodingTool && <div className="d-flex text-muted small w-100">The coding tool does not support changing the input dataset</div>}
        {toolData.toolType === 'Coding' && <div className="d-flex text-muted small w-100">Filtering is not available for Coding tool yet</div>}
      </Fragment>
    )
  }
  return (
    <Fragment>
      <span className="mb-2">Dataset 1</span>
      <div className="d-flex w-100 mb-3">
        <input placeholder="Select dataset" className="form-control h-32" disabled={true} value={editMode && !inputPath.inputA && workflowData.selectedTool && workflowData.selectedTool.inputA ?
          `${workflowData.selectedTool.inputA.projectName || ''}/${workflowData.selectedTool.inputA.datasetName || ''}` : inputPath.inputA} />
        <button type='button' className="btn btn-shadow ml-2 flex-shrink-0" onClick={() => { setDataset("inputA"); setShowInputModal({ show: true, type: 'input' }) }}>Select</button>
      </div>
      <div className="d-flex w-100 mb-5">
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text h-32">Filter</span>
          </div>
          <input
            className="form-control h-32"
            name='form-control inputA'
            onChange={(e) => manuallyUpdateFilter('inputA', e.target.value)}
            disabled={inputPath.inputA?.split('/')[1]?.length > 0 ? false : workflowData.selectedTool?.inputA?.projectId && workflowData.selectedTool?.inputA?.datasetId ? false : true}
            value={editMode && workflowData.selectedTool?.inputA?.preFilter ? workflowData.selectedTool.inputA.preFilter : toolData.inputA?.preFilter ? toolData.inputA?.preFilter : ''}
          />
        </div>
        <button
          type='button'
          disabled={inputPath.inputA?.split('/')[1]?.length > 0 ? false : workflowData.selectedTool?.inputA?.projectId && workflowData.selectedTool?.inputA?.datasetId ? false : true}
          className="btn btn-shadow ml-2 flex-shrink-0"
          onClick={() => { setDataset('inputA'); setShowFilterBuilder(true) }}>
          Select</button>
      </div>
      <span className="mb-2">Dataset 2</span>
      <div className="d-flex w-100 mb-3">
        <input placeholder="Select dataset" className="form-control h-32" disabled={true} value={editMode && !inputPath.inputB && workflowData.selectedTool && workflowData.selectedTool.inputB ?
          `${workflowData.selectedTool.inputB.projectName || ''}/${workflowData.selectedTool.inputB.datasetName || ''}` : inputPath.inputB} />
        <button type='button' className="btn btn-shadow ml-2 flex-shrink-0" onClick={() => { setDataset("inputB"); setShowInputModal({ show: true, type: 'input' }) }}>Select</button>
      </div>
      <div className="d-flex w-100 mb-5">
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text h-32">Filter</span>
          </div>
          <input
            className="form-control h-32"
            name='form-control inputB'
            onChange={(e) => manuallyUpdateFilter('inputB', e.target.value)}
            disabled={inputPath.inputB?.split('/')[1]?.length > 0 ? false : workflowData.selectedTool?.inputB?.projectId && workflowData.selectedTool?.inputB?.datasetId ? false : true}
            value={editMode && workflowData.selectedTool?.inputB?.preFilter ? workflowData.selectedTool.inputB.preFilter : toolData.inputB?.preFilter ? toolData.inputB?.preFilter : ''}
          />
        </div>
        <button
          type='button'
          disabled={inputPath.inputB?.split('/')[1]?.length > 0 ? false : workflowData.selectedTool?.inputB?.projectId && workflowData.selectedTool?.inputB?.datasetId ? false : true}
          className="btn btn-shadow ml-2 flex-shrink-0"
          onClick={() => { setDataset('inputB'); setShowFilterBuilder(true) }}>
          Select</button>
      </div>
    </Fragment>
  )
}