import { Fragment } from 'react';

import Logo from '../../../assets/img/walr_black.png';
import { ErrorMessage } from '../../shared/ErrorMessage/ErrorMessage';

interface Props {
  isLoading: boolean
  errorMessage: string
  onSubmitHandler: (e: TODO) => void
  onChangeHandler: (e: TODO) => void
}

export const SignUpCard = ({ isLoading, errorMessage, onSubmitHandler, onChangeHandler }: Props) => {
  return (
    <Fragment>
      <div className="d-flex justify-content-center mb-5">
        <img className='walr-logo' src={Logo} alt="" />
      </div>
      <form autoComplete='off' onSubmit={onSubmitHandler} className='d-flex flex-column gap-lg py-3'>
        <input autoComplete='false' hidden />
        <input
          type='text'
          required={true}
          name="firstName"
          placeholder='First name'
          className="form-control"
          onChange={onChangeHandler}
        />
        <input
          type='text'
          required={true}
          name="lastName"
          placeholder='Last name'
          className="form-control"
          onChange={onChangeHandler}
        />
        <input
          minLength={8}
          required={true}
          name="password"
          type="password"
          placeholder='Password'
          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
          className="form-control"
          title="Must contain at least one uppercase and lowercase letter, one number and at least 8 or more characters"
          onChange={onChangeHandler}
        />
        <input
          minLength={8}
          required={true}
          type="password"
          name="confirmPassword"
          placeholder='Confirm password'
          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
          className="form-control"
          title="Must contain at least one uppercase and lowercase letter, one number and at least 8 or more characters"
          onChange={onChangeHandler}
        />
        {
          errorMessage &&
          <ErrorMessage
            type="alert"
            errorMessage={errorMessage} />
        }
        <button
          type="submit"
          disabled={isLoading}
          style={{ maxHeight: 'unset', height: '100%', fontSize: 'unset', fontWeight: 'normal' }}
          className="btn btn-dark btn-block">
          {
            isLoading ?
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              :
              <span>Sign up</span>
          }
        </button>
      </form>
      <p className="text-muted text-center mt-5 mb-0">
        By clicking on Sign up, <br />
        you agree to our <a href="https://walr.com/app/uploads/2022/12/Walr-Terms-and-Conditions.pdf"
          className="text-dark cursor-pointer" target="_blank" rel="noopener noreferrer"><u>Terms and Conditions</u></a> of use.
      </p>
    </Fragment>
  )
}