export const returnColumnDataInfo = (dataPropertyKeys) => {
  const dataInfo = []
  if (dataPropertyKeys.includes('name') && dataPropertyKeys.includes('description')) {
    dataInfo.push(
      {
        data: 'name/description',
        keys: dataPropertyKeys
      }
    )
  }
  if (dataPropertyKeys.includes('createdUtc') && dataPropertyKeys.includes('createdByName')) {
    dataInfo.push(
      {
        data: 'createdUtc',
        keys: dataPropertyKeys
      }
    )
  }
  if (dataPropertyKeys.includes('status')) {
    dataInfo.push(
      {
        data: 'status',
        keys: dataPropertyKeys
      }
    )
  }
  if (dataPropertyKeys.includes('interviews')) {
    dataInfo.push(
      {
        data: 'interviews',
        keys: dataPropertyKeys
      },
    )
  }
  if (dataPropertyKeys.includes('live')) {
    dataInfo.push(
      {
        data: 'live',
        keys: dataPropertyKeys
      }
    )
  }
  if (dataPropertyKeys.includes('tags')) {
    dataInfo.push(
      {
        data: 'tags',
        keys: dataPropertyKeys
      }
    )
  }
  return dataInfo
}