export default (data, toggleAll) => {
  let updatedToggleAll = null;
  const updatedData = [...data]
  if (!toggleAll) {
    updatedData.forEach(el => {
      el.checked = true;
    })
    updatedToggleAll = true
  } else {
    updatedData.forEach(el => {
      el.checked = false;
    })
    updatedToggleAll = false
  }
  return {
    data: updatedData,
    toggleAll: updatedToggleAll
  }
}