import { useRef } from 'react'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'

import { Icon } from '../../../shared/Icon/Icon'
import type { BatchUrlsModalComponent } from '../../../../interfaces/gateKeeperInterfaces/gateKeeperInterfaces'

export const BatchURLsModal = ({ onHide, batchUrlsHandler, error, batchUrls, onChangeBatchVariables, batchVariables }: BatchUrlsModalComponent) => {

  const hiddenFileInputLive = useRef<HTMLInputElement>(null);
  const hiddenFileInputTest = useRef<HTMLInputElement>(null);

  const handleClickLive = () => {
    if (hiddenFileInputLive.current !== null) {
      hiddenFileInputLive.current.click();
    }
  };
  const handleClickTest = () => {
    if (hiddenFileInputTest.current !== null) {
      hiddenFileInputTest.current.click();
    }
  };

  return (
    <Dialog width={"600px"} className='gk-new-project' contentStyle={{ padding: 0, fontSize: 14 }} title={"Batch URL's"} onClose={() => onHide("Batch URL's")}>
      <form id='batch-form' encType="multipart/form-data" onSubmit={(e) => batchUrlsHandler(e)} >
        <div className="details-input batch-input d-flex flex-column">
          <label className='p-0 mb-1 strong text-primary'>Custom variables live</label>
          <div className="d-flex align-items-center gap-sm">
            <button type='button' aria-disabled="true" className="btn btn-shadow" onClick={() => handleClickLive()}>
              Browse
            </button>
            {/* custom input */}
            <input ref={hiddenFileInputLive} name="isLiveFile" type="file" accept=".xlsx, .xls" placeholder='Select excel file' onChange={(e) => onChangeBatchVariables(e)} hidden />
            <input value={batchUrls.isLiveFile ? batchUrls.isLiveFile : ""} className='form-control batch-input' name="isLiveFile" placeholder='Select excel file' readOnly />
            <span style={{ position: "absolute", right: 8, top: 4 }} >
              {batchVariables.liveVariables && !error.invalidLiveFile?.message ?
                <Icon className='pe-none' fill="green" type="checkmark" /> :
                error.invalidLiveFile?.message ?
                  <Icon type="info-icon" fill='red' /> : ""
              }
            </span>
          </div>
          {error.isLiveFile && !error.invalidLiveFile &&
            <div className="text-danger">
              <small>This field is required.</small>
            </div>}
          {error.invalidLiveFile &&
            <div className="text-danger">
              <small>{error.invalidLiveFile.message}</small>
            </div>}
        </div>

        <div className="details-input batch-input d-flex flex-column">
          <label className='p-0 mb-1 strong text-primary'>Custom variables test</label>
          <div className="d-flex align-items-center gap-sm">
            <button type='button' aria-disabled="true" className="btn btn-shadow" onClick={handleClickTest}>
              Browse
            </button>
            {/* custom input */}
            <input ref={hiddenFileInputTest} name="isTestFile" type="file" accept=".xlsx, .xls" placeholder='Select excel file' onChange={(e) => onChangeBatchVariables(e)} hidden />
            <input value={batchUrls.isTestFile ? batchUrls?.isTestFile : ""} className='form-control' name="isTestFile" placeholder='Select excel file' readOnly />
            <span style={{ position: "absolute", right: 8, top: 4 }} >
              {batchVariables.testVariables && !error.invalidTestFile?.message ?
                <Icon className='pe-none' fill="green" type="checkmark" /> :
                error.invalidTestFile?.message ?
                  <Icon type="info-icon" fill='red' /> : ""
              }
            </span>
          </div>
          {error?.name &&
            <div className="text-danger">
              <small>This field is required.</small>
            </div>}
          {error.invalidTestFile &&
            <div className="text-danger">
              <small>{error.invalidTestFile.message}</small>
            </div>}
        </div>
      </form>
      <DialogActionsBar>
        <div className='d-flex'>
          <button type='submit' className="btn btn-secondary mr-2" onClick={() => onHide("Batch URL's")}>
            Cancel
          </button>
          <button disabled={error.invalidLiveFile?.message || error.invalidTestFile?.message} form='batch-form' type='submit' className="btn btn-primary">
            Continue
          </button>
        </div>
      </DialogActionsBar>
    </Dialog >
  )
}