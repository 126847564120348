import { type FormEvent, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Icon } from "../shared/Icon/Icon";
import { DropdownButton } from "../shared/DropdownButton/DropdownButton";
import type { AudienceTabsType, Markets } from "../../interfaces/auddineceAccessInterfaces/audiencesTypes";
import WidSearchModal from "./Modals/WidSearchModal";
import SupplierModal from "./Modals/SupplierModal";
import ReconciliationOverviewModal from "./Modals/ReconciliationOverviewModal";
import NewSupplierModal from "./Modals/NewSupplierModal";
import { SampleModal } from "./Modals/SampleModal";
import { editAudiencesNavigation } from "../../store/reducers/audiencesNavigationReducer/audiencesNavigationReducer";
import type { RootState } from "../../store/reducers/rootReducer";

type Props = {
  setSearchTerm: (search: string) => void;
  searchTerm: string;
  setSelectedMarket: (market: {
    name: string;
    isoCode: string;
  }) => void;
  selectedMarket: { name: string, isoCode: string }
  selectedTab: AudienceTabsType
  fetchAudiencesData: (searchTermValue: string, selectedMarketValue: string, continuationToken?: string) => void;
  markets: Markets[];
  setIsOverlayLoading: (isOverlayLoading: boolean) => void;
}

export const AudiencesHeader = ({ setSearchTerm, searchTerm, setSelectedMarket, selectedMarket, fetchAudiencesData, markets, setIsOverlayLoading }: Props) => {
  const audiencesNavigationData = useSelector((state: RootState) => state.audiencesNavigationReducer);
  const dispatch = useDispatch();

  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showSupplierModal, setShowSupplierModal] = useState(false);
  const [showNewSupplierModal, setShowNewSupplierModal] = useState(false);
  const [showReconOverviewModal, setShowReconOverviewModal] = useState(false);
  const [showCreateSampleModal, setShowCreateSampleModal] = useState(false);

  const addNewSupplier = () => {
    setShowSupplierModal(false)
    setShowNewSupplierModal(true)
  }

  const onHide = (type: string) => {
    switch (type) {
      case "Suppliers":
        setShowSupplierModal(false)
        break;
      case "Suppliers New":
        setShowNewSupplierModal(false);
        break;
      case "Reconciliation Overview":
        setShowReconOverviewModal(false);
        break;
    }
  }

  const returnItemMore = () => {
    return (
      <div className="d-flex flex-column">
        <button type="button" className="dropdown-item" onClick={() => setShowReconOverviewModal(true)}>
          Reconciliation overview
        </button>
        <button type="button" className="dropdown-item" onClick={() => setShowSupplierModal(true)}>
          Suppliers
        </button>
        <button type="button" className="dropdown-item" onClick={() => setShowSearchModal(true)}>
          Search Walr ID
        </button>
      </div>
    );
  };

  const onHandleSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = Object.fromEntries(new FormData(event.currentTarget));
    if (formData.search === "") return;

    setIsOverlayLoading(true);
    setSearchTerm(formData.search as string);
    fetchAudiencesData(formData.search as string, selectedMarket.isoCode)
  }

  const onHandleClearSearch = () => {
    setSearchTerm("");
    setIsOverlayLoading(true);
    if (!audiencesNavigationData.sampleId) {
      fetchAudiencesData("", selectedMarket.isoCode);
    }

    if (audiencesNavigationData.sampleId) {
      dispatch(editAudiencesNavigation({ projectId: undefined, sampleId: undefined, audienceId: undefined, batchId: undefined }))
    }
  }

  return (
    <Fragment>
      {
        showReconOverviewModal &&
        <ReconciliationOverviewModal onHide={onHide} />
      }

      {
        showSupplierModal &&
        <SupplierModal
          onHide={onHide}
          addNewSupplier={addNewSupplier}
        />
      }

      {
        showNewSupplierModal &&
        <NewSupplierModal onHide={onHide} />
      }

      {
        showSearchModal &&
        <WidSearchModal onHide={setShowSearchModal} />
      }

      {
        showCreateSampleModal &&
        <SampleModal setShowCreateSampleModal={setShowCreateSampleModal} type="create" setIsOverlayLoading={setIsOverlayLoading} />
      }

      <div className='d-flex justify-content-center flex-column w-100 px-6 pt-5 pb-2'>
        <h1 className='stronger h4'>Audiences</h1>
      </div>

      <div className='d-flex justify-content-between w-100 px-6 py-0'>
        <div className="d-flex gap-md">
          <button type="button" className="btn btn-primary btn-icon icon-l" onClick={() => setShowCreateSampleModal(true)}>
            <Icon type="add" className="submenu fill-white" />
            Add sample
          </button>

          <DropdownButton
            text="More"
            menuPosition="left"
            className="btn-shadow icon-r mr-3"
            items={[{ text: "More" }]}
            renderItem={returnItemMore}
            fillColor='primary'
          />
        </div>

        <div className='d-flex justify-content-between gap-md'>
          <form className="d-flex position-relative" onSubmit={onHandleSearch}>
            <input
              name='search'
              type="text"
              value={searchTerm}
              className="form-control search-input-projects bg-white"
              placeholder="Search"
              aria-label="Search"
              autoComplete="off"
              minLength={2}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ width: "13rem" }}
            />

            <button type="submit" name="submitSearch" className="btn btn-search-submit bg-white" title="Search">
              <Icon type="search" />
            </button>

            {(searchTerm && searchTerm?.length >= 2) && (
              <button type="button"
                className="btn position-absolute search-clear-button-projects"
                style={{ right: "2.5rem", zIndex: 11 }}
                onClick={onHandleClearSearch}
                title="Clear search"
              >
                <Icon type="close" />
              </button>
            )}
          </form>

          <div className='d-flex position-relative'>
            <DropdownButton
              items={markets}
              textField="name"
              className="btn-shadow icon-r overview-input"
              text={selectedMarket.name ? selectedMarket.name : "All markets"}
              onItemClick={(e) => {
                setIsOverlayLoading(true);
                setSelectedMarket({ name: e.item.name, isoCode: e.item.isoCode });
                fetchAudiencesData(searchTerm ? searchTerm : '', e.item.isoCode)
              }}
            />

            <button
              type="button"
              disabled={!!(selectedMarket.name === "All markets" || selectedMarket.name === "")}
              className='btn p-0 h-24 shadow-none btn-reset bg-white'
              onClick={() => {
                setIsOverlayLoading(true);
                setSelectedMarket({ name: "All markets", isoCode: "" })
                fetchAudiencesData(searchTerm ? searchTerm : "", "")
              }}>
              <Icon type="close" />
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  )
}