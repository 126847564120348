import { returnUpdatedNmopenData } from "./returnUpdatedNmopenData/returnUpdatedNmopenData"
import { returnUpdatedVariableData } from "./returnUpdatedVariableData/returnUpdatedVariableData"
import { returnUpdatedMultigridData } from "./returnUpdatedMultigridData/returnUpdatedMultigridData"
import { returnUpdatedMultilistData } from "./returnUpdatedMultilistData/returnUpdatedMultilistData"
import { returnUpdatedNumericListData } from "./returnUpdatedNumericListData/returnUpdatedNumericListData"

export default (result) => {
  let newData = []
  if (result.sss.survey.record.variable) {
    newData = returnUpdatedVariableData(result, newData)
  }
  if (result.sss.survey.record["miextsss:grid"]) {
    newData = returnUpdatedMultigridData(result, newData)
  }
  if (result.sss.survey.record["miextsss:multilist"]) {
    newData = returnUpdatedMultilistData(result, newData)
  }
  if (result.sss.survey.record["miextsss:nmopen"]) {
    newData = returnUpdatedNmopenData(result, newData)
  }
  if (result.sss.survey.record["miextsss:numericlist"]) {
    newData = returnUpdatedNumericListData(result, newData)
  }
  newData.sort((a, b) => (a.order > b.order) ? 1 : -1)
  return newData
}