export const returnMoveDataToChart = (state: any, action: any) => {
  const updatedChartState = { ...state.state }
  const updatedChartFirstColumn = updatedChartState.firstColumn
  const updatedChartSecondColumn = updatedChartState.scndColumn
  const updatedXtFirstColumn = JSON.parse(JSON.stringify(action.payload.firstColumn))
  const updatedXtSecondColumn = JSON.parse(JSON.stringify(action.payload.scndColumn))
  let uniqueXtFirstColumn: any = []
  let uniqueXtSecondColumn: any = []

  const isIncluded = (item: any, list: any) => {
    let i;
    for (i = 0; i < list.length; i++) {
      if (list[i].title === item.title) {
        return true;
      }
    }
    return false;
  }

  // Extract unique items from crosstab first column
  if (updatedChartFirstColumn.length > 0) {
    updatedXtFirstColumn.forEach((item: any) => {
      if (isIncluded(item, updatedChartFirstColumn) === false) {
        uniqueXtFirstColumn.push(item)
      }
    })
  } else {
    uniqueXtFirstColumn = [...updatedXtFirstColumn]
  }

  // Extract unique items from crosstab second column
  if (updatedChartSecondColumn.length > 0) {
    updatedXtSecondColumn.forEach((item: any) => {
      if (isIncluded(item, updatedChartSecondColumn) === false) {
        uniqueXtSecondColumn.push(item)
      }
    })
  } else {
    uniqueXtSecondColumn = [...updatedXtSecondColumn]
  }

  // Set all new unique items from first column to non active
  if (uniqueXtFirstColumn.length > 0 && updatedChartFirstColumn.length > 0) {
    uniqueXtFirstColumn.forEach((item: any) => {
      item.active = false
    })
  }

  const xtOptions = { ...action.payload.updatedOptions }
  let updatedOptions: any = { ...updatedChartState.questionOptions }
  let newUpdatedOptions: any = { ...updatedChartState.newQuestionOptions }

  if (xtOptions.XtUniverseCode) {
    updatedOptions.GUniverseCode = xtOptions.XtUniverseCode
    newUpdatedOptions.GUniverseCode = xtOptions.XtUniverseCode
  }
  if (xtOptions.XtFilterCode) {
    updatedOptions.GFilterCode = xtOptions.XtFilterCode
    newUpdatedOptions.GFilterCode = xtOptions.XtFilterCode
  }
  if (xtOptions.XtFilterDoc) {
    updatedOptions.GFilterDoc = xtOptions.XtFilterDoc
    newUpdatedOptions.GFilterDoc = xtOptions.XtFilterDoc
  }
  if (xtOptions.XtFilterText) {
    updatedOptions.GFilterText = xtOptions.XtFilterText
    newUpdatedOptions.GFilterText = xtOptions.XtFilterText
  }

  return {
    ...state,
    state: {
      ...updatedChartState,
      updateTable: true,
      firstColumn: updatedChartFirstColumn.concat(uniqueXtFirstColumn),
      scndColumn: updatedChartState.scndColumn.concat(uniqueXtSecondColumn),
      questionOptions: updatedOptions,
      newQuestionOptions: newUpdatedOptions
    }
  }
}