import React, { useState } from 'react';

import InvitationResponseModal from '../InvitationResponseModal/InvitationResponseModal';
import { Icon } from '../Icon/Icon';

export default ({ pendingInvitations, token }) => {
  const [invitationResponseModal, setInvitationResponseModal] = useState({ show: false, invitation: null })

  return (
    <div className='col-lg-12'>
      {
        invitationResponseModal.show &&
        <InvitationResponseModal token={token} invitation={invitationResponseModal.invitation} onHide={() => setInvitationResponseModal({ show: false })} />
      }
      {
        pendingInvitations.map((invitation, key) => (
          <div key={key} onClick={() => setInvitationResponseModal({ show: true, invitation: invitation })} className={`alert alert-walr border-0 dashboard-card-shadow p-0 ${pendingInvitations.length > 1 && key !== pendingInvitations.length - 1 ? 'mb-3' : ''}`}>
            <div className={`d-flex justify-content-between align-items-center cursor-pointer px-3 py-2`}>
              <div className="d-flex align-items-center">
                <span className="badge badge badge-walr p-2 m-1 mr-2">Invitation</span>
                <p className="ml-1 mb-0"><strong>{invitation.invitedByName}</strong> would like to share a project with you.</p>
              </div>
              <div className="d-flex align-items-center">
                <Icon type="chevron-right" />
              </div>
            </div>
          </div>
        ))
      }
    </div>
  )
}