import { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../../../../../store/reducers/rootReducer';
import { returnFileIcon } from '../../../helpers/returnFileIcon/returnFileIcon';
import { FileManagerExpandedScreenSortDetails } from '../FileManagerExpandedScreenSortDetails/FileManagerExpandedScreenSortDetails';
import { updateCheckedFiles } from '../../../../../store/reducers/fileManagerStateReducer/fileManagerStateReducer';

import FileLibraryFolderIcon from '../../../../../assets/img/FileLibrary/file-library-folder.svg';
import FileLibraryIconImage from '../../../../../assets/img/FileLibrary/file-library-image.svg';

interface Props {
  expandedState: any
  expandedDisplayType: string
  onDoubleClickRowHandler: (item: any) => void
  selectRow: (item: any, stateType: string) => void
}

export const FileManagerExpandedScreenLargeIcons = ({ expandedState, expandedDisplayType, onDoubleClickRowHandler, selectRow }: Props) => {
  const dispatch = useDispatch()
  const { checkedFiles } = useSelector((theState: RootState) => theState.fileManagerStateReducer);

  useEffect(() => {
    return () => {
      dispatch(updateCheckedFiles([]));
    }
  }, [dispatch]);

  return (
    <Fragment>
      <FileManagerExpandedScreenSortDetails
        expandedState={expandedState}
        expandedDisplayType={expandedDisplayType} />
      <div className='file-library-table overflow-auto table-body grid'>
        <div className='row m-0'>
          {
            expandedState.data.length > 0 && expandedState.data[0].contents.filter((el: any) => el.displayName !== '_empty.tmp').map((item: any, key: number) => {
              let thumbnail = null
              const fileIcon = returnFileIcon(item)
              if (fileIcon.icon === FileLibraryIconImage && item.uri) {
                thumbnail = item.uri
              }
              return (
                <div
                  key={key}
                  title={item.displayName}
                  onDoubleClick={() => onDoubleClickRowHandler(item)}
                  onClick={() => selectRow({ dataItem: item }, 'expandedState')}
                  className={`col-3 grid-item d-flex p-0`}>
                  <div style={{ position: 'absolute', left: '16px', top: '9px' }}>
                    <input
                      type="checkbox"
                      onClick={(e) => { e.stopPropagation() }}
                      onChange={() => selectRow({ dataItem: item }, 'expandedState')}
                      checked={checkedFiles.find((file: any) => file.id === item.id) ? true : false} />
                  </div>
                  <div className={`d-flex flex-column flex-grow-1 justify-content-end p-3 inner ${checkedFiles.find((file: any) => file.id === item.id) ? 'bg-light' : ''}`}>
                    <div className="d-flex flex-grow-1 justify-content-center align-items-center">
                      {
                        thumbnail ?
                          <img className="grid-img" src={thumbnail} alt={item.displayName} />
                          :
                          <img src={item.isDirectory ? FileLibraryFolderIcon : fileIcon.icon} alt={fileIcon.alt} className='mr-1 pe-none grid-icon' />
                      }
                    </div>
                    <div className="m-0 text-truncate text-muted text-center small pb-3">{item.displayName}</div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </Fragment>
  )
}