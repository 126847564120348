export const returnUpdatedAnalysisBody = (projectType, analysisType, options, firstColumn, scndColumn, reportItemId, lang, defaultLanguage) => {
  const body = {
    type: analysisType === "g" ? "json" : "html-react-2",
    language: lang
  }
  if (analysisType === 'topline') {
    body.definitions = {
      type: "topline",
      options: options,
      questions: firstColumn.filter(el => reportItemId ? el.id === reportItemId : el.active).map(el => { return { id: el.title, subqIndex: el.subqIndex, isDisabled: false } })
    }
  } else if (analysisType === 'qtable' || analysisType === "qt") {
    body.definitions = {
      type: "qt",
      options: options,
      questions: firstColumn.filter(el => reportItemId ? el.id === reportItemId : el.active).map(el => {
        return {
          id: el.title,
          subqIndex: el.subqIndex,
          isDisabled: false,
          summaryValuesOnly: el.summaryValuesOnly ? el.summaryValuesOnly : false,
          swapRowsCols: el.swapRowsCols ? el.swapRowsCols : false,
          suppressGridCols: el.subGroups?.filter(item => item.suppress).map((item) => {
            return item.code
          }),
          // editedText: lang === "en" || lang === undefined ? Array.isArray(el.content) ? el.content[0] : el.content : null,
          editedText: lang === defaultLanguage ? Array.isArray(el.content) ? el.content[0] : el.content : null,
          options: el.options ? el.options : {},
          rows: el.groups.filter(group => group.active).map((row, id) => {
            return {
              code: row.code,
              weightValue: typeof row.weightValue === 'number' ? row.weightValue : null,
              isDisabled: false,
              // "editedText": lang === "en" || lang === undefined ? row.text : null
              editedText: lang === defaultLanguage ? row.text : null
            }
          }),
          cols: el.subGroups?.filter(group => group.active).map((row, id) => {
            return {
              code: row.code,
              weightValue: typeof row.weightValue === 'number' ? row.weightValue : null,
              isDisabled: false,
              // "editedText": lang === "en" || lang === undefined ? row.text : null
              editedText: lang === defaultLanguage ? row.text : null
            }
          })
        }
      })
    }
  } else if (analysisType === 'xt') {
    body.definitions = {
      type: "xt",
      options: options,
      resultType: "html-react",
      rowQuestions: firstColumn.filter(el => reportItemId ? el.id === reportItemId : el.active).map(el => {
        return {
          options: el.options ? el.options : {},
          id: el.title,
          subqIndex: el.subqIndex,
          isDisabled: false,
          summaryValuesOnly: el.summaryValuesOnly ? el.summaryValuesOnly : false,
          swapRowsCols: el.swapRowsCols ? el.swapRowsCols : false,
          suppressGridCols: el.subGroups?.filter(item => item.suppress).map((item) => {
            return item.code
          }),
          hierarchy: el.hierarchy?.map(element => {
            return {
              id: element.id,
              subqIndex: element.subqIndex,
              isDisabled: element.isDisabled,
              editedText: lang === defaultLanguage ? Array.isArray(el.content) ? el.content[0] : el.content : null,
              rows: element.rows?.filter(el => el.active).map(row => {
                return {
                  code: row.code,
                  editedText: lang === defaultLanguage ? row.text : null
                }
              }),
              cols: element.cols?.filter(el => el.active).map(col => {
                return {
                  code: col.code,
                  editedText: lang === defaultLanguage ? col.text : null
                }
              }),
            }
          }),
          // editedText: lang === defaultLanguage ? Array.isArray(el.content) ? el.content[0] : el.content : null,
          editedText: lang === defaultLanguage ? Array.isArray(el.content) ? el.content[0] : el.content : null,
          rows: el?.groups?.filter(group => group.active)?.map((row, id) => {
            return {
              code: row.code,
              weightValue: typeof row.weightValue === 'number' ? row.weightValue : null,
              isDisabled: false,
              editedText: lang === defaultLanguage ? row.text : null
            }
          }),
          cols: el.subGroups?.filter(group => group.active).map((row, id) => {
            return {
              code: row.code,
              weightValue: typeof row.weightValue === 'number' ? row.weightValue : null,
              isDisabled: false,
              editedText: lang === defaultLanguage ? row.text : null
            }
          })
        }
      }),
      colQuestions: scndColumn.map(el => {
        return {
          options: {},
          id: el.title,
          subqIndex: el.subqIndex,
          isDisabled: false,
          summaryValuesOnly: el.summaryValuesOnly ? el.summaryValuesOnly : false,
          swapRowsCols: el.swapRowsCols ? el.swapRowsCols : false,
          hierarchy: el.hierarchy?.map(element => {
            return {
              id: element.id,
              subqIndex: element.subqIndex,
              isDisabled: element.isDisabled,
              editedText: lang === defaultLanguage ? Array.isArray(el.content) ? el.content[0] : el.content : null,
              rows: element.rows?.filter(el => el.active).map(row => {
                return {
                  code: row.code,
                  editedText: lang === defaultLanguage ? row.text : null
                }
              }),
              cols: element.cols?.filter(el => el.active).map(col => {
                return {
                  code: col.code,
                  editedText: lang === defaultLanguage ? col.text : null
                }
              }),
            }
          }),
          // editedText: lang === "en" || lang === undefined ? Array.isArray(el.content) ? el.content[0] : el.content : null,
          editedText: lang === defaultLanguage ? Array.isArray(el.content) ? el.content[0] : el.content : null,
          rows: el.groups.filter(group => group.active).map((row, id) => {
            return {
              code: row.code,
              weightValue: id + 1,
              isDisabled: false,
              editedText: lang === defaultLanguage ? row.text : null
            }
          }),
          cols: el.subGroups?.filter(group => group.active).map((row, id) => {
            return {
              code: row.code,
              weightValue: typeof row.weightValue === 'number' ? row.weightValue : null,
              isDisabled: false,
              editedText: lang === defaultLanguage ? row.text : null
            }
          })
        }
      })
    }
  } else if (analysisType === 'data') {
    body.definitions = {
      type: "data",
      options: options,
      questions: firstColumn.map(el => { return { id: el.title, subqIndex: el.subqIndex, isDisabled: false } })
    }
  }
  else if (analysisType === 'dig') {
    body.definitions = {
      type: "dig",
      options: options,
      questions: firstColumn.map(el => {
        return {
          id: el.title,
          subqIndex: el.subqIndex,
          isDisabled: false,
          summaryValuesOnly: el.summaryValuesOnly ? el.summaryValuesOnly : false,
          swapRowsCols: el.swapRowsCols ? el.swapRowsCols : false,
          editedText: lang === defaultLanguage ? Array.isArray(el.content) ? el.content[0] : el.content : null,
          rows: el.groups?.filter(group => group.active).map((row, id) => {
            return {
              code: row.code,
              weightValue: id + 1,
              isDisabled: false,
              editedText: lang === defaultLanguage ? row.text : null
            }
          }),
          cols: el.subGroups?.filter(group => group.active).map((row, id) => {
            return {
              code: row.code,
              weightValue: typeof row.weightValue === 'number' ? row.weightValue : null,
              isDisabled: false,
              editedText: lang === defaultLanguage ? row.text : null
            }
          })
        }
      })
    }
  } else if (analysisType === 'g') {
    body.definitions = {
      type: "g",
      options: options,
      rowQuestions: firstColumn.filter(el => reportItemId ? el.id === reportItemId : el.active).map(el => {
        return {
          options: el.options ? el.options : {},
          id: el.title,
          subqIndex: el.subqIndex,
          isDisabled: false,
          summaryValuesOnly: el.summaryValuesOnly ? el.summaryValuesOnly : false,
          swapRowsCols: el.swapRowsCols ? el.swapRowsCols : false,
          editedText: lang === defaultLanguage ? Array.isArray(el.content) ? el.content[0] : el.content : null,
          rows: el.groups.filter(group => group.active).map((row, id) => {
            return {
              code: row.code,
              weightValue: typeof row.weightValue === 'number' ? row.weightValue : null,
              isDisabled: false,
              editedText: lang === defaultLanguage ? row.text : null
            }
          }),
          cols: el.subGroups?.filter(group => group.active).map((row, id) => {
            return {
              code: row.code,
              weightValue: typeof row.weightValue === 'number' ? row.weightValue : null,
              isDisabled: false,
              editedText: lang === defaultLanguage ? row.text : null
            }
          })
        }
      }),
      colQuestions: scndColumn.map(el => {
        return {
          options: {},
          id: el.title,
          subqIndex: el.subqIndex,
          isDisabled: false,
          summaryValuesOnly: el.summaryValuesOnly ? el.summaryValuesOnly : false,
          swapRowsCols: el.swapRowsCols ? el.swapRowsCols : false,
          editedText: lang === defaultLanguage ? Array.isArray(el.content) ? el.content[0] : el.content : null,
          rows: el.groups.filter(group => group.active).map((row, id) => {
            return {
              code: row.code,
              weightValue: typeof row.weightValue === 'number' ? row.weightValue : null,
              isDisabled: false,
              editedText: lang === defaultLanguage ? row.text : null
            }
          }),
          cols: el.subGroups?.filter(group => group.active).map((row, id) => {
            return {
              code: row.code,
              weightValue: typeof row.weightValue === 'number' ? row.weightValue : null,
              isDisabled: false,
              editedText: lang === defaultLanguage ? row.text : null
            }
          })
        }
      })
    }
  } else {
    body.definitions = {}
  }
  return body
}