import React from 'react';
import { Switch } from '@progress/kendo-react-inputs';

import { BaseDropDownList } from "../../../../../../../shared/Inputs";

export default ({ option, dropDownValues, onChangeHandler }) => {
  let optionContent = null
  const findDropDownType = dropDownValues.filter(value => value.name === option.type)[0]
  const findDropDownValues = findDropDownType ? findDropDownType.enumValues.map(enumValue => enumValue.name) : null

  if (option.type === 'Boolean') {
    optionContent =
      <div className="d-flex align-items-center justify-content-between my-3">
        <p className="m-0 pr-5">{option.descr}</p>
        <Switch id={option.id} onChange={onChangeHandler} size="small" />
      </div>
  } else if (option.type === 'String') {
    optionContent =
      <div className="d-flex align-items-center justify-content-between my-3">
        <p className="m-0 pr-5">{option.descr}</p>
        <div>
          <input
            onChange={onChangeHandler}
            type="text"
            id={option.id}
            className="form-control"
            placeholder={option.name} />
        </div>
      </div>
  } else {
    if (Array.isArray(findDropDownValues)) {
      optionContent =
        <div className="d-flex align-items-center justify-content-between my-3">
          <p className="m-0 pr-5">{option.descr}</p>
          <BaseDropDownList
            onChange={onChangeHandler}
            id={option.id}
            style={{ width: '200px' }}
            defaultValue={findDropDownValues[0]}
            data={findDropDownValues} />
        </div>
    } else return null
  }

  return optionContent
}