export function toplineClearQuestions(theState, payload) {
  if (theState.state.firstColumn) {
    if (theState.state.firstColumn.length > 0) {
      return {
        ...theState,
        state: {
          ...theState.state,
          columns: [],
          checkedNum: [],
          filterData: [],
          firstColumn: [],
          targetGroup: [],
          universeData: [],
          secondColumnIndex: [],
          secondColumns: { value: '' },
          questionOptions: payload.values,
          newQuestionOptions: payload.newValues,
          displayTable: null,
          updateTable: payload.clearSession ? false : true,
        }
      }
    } else {
      return theState
    }
  } else {
    return theState
  }
}