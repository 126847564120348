import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";

import { Icon } from "../../../shared/Icon/Icon";
import { returnQuestionIcon } from "../../../Analysis/Analyze/components/shared/helpers/returnQuestionIcon/returnQuestionIcon";

interface Props {
  token: string
  showSidebar: { show: boolean, type: string }
  setShowSidebar: (obj: { show: boolean, type: string }) => void
  searchState: { value: string, type: string }
  setSearchState: (obj: { value: string, type: string }) => void
}

interface Filter {
  title: string
  type: string
  id: string
}

const SidebarFilterContent = ({ setShowSidebar, searchState, setSearchState }: Props) => {
  const dispatch = useDispatch();

  // const [filtersList, setFiltersList] = useState<Filter[]>([...placeholderFilters]);
  const [showFilterOptions, setShowFilterOptions] = useState<{ show: boolean, filter: Filter }>({ show: false, filter: { title: "", type: "", id: "" } })

  // placeholders, remove after full implementation
  const filtersList: Filter[] = [
    {
      title: "Filter A",
      type: returnQuestionIcon("m"),
      id: "1"
    },
    {
      title: "Filter B",
      type: returnQuestionIcon("f"),
      id: "2"
    },
    {
      title: "Filter C",
      type: returnQuestionIcon("rn"),
      id: "3"
    },
    {
      title: "Filter D",
      type: returnQuestionIcon("rm"),
      id: "4"
    },

  ]


  const filterOptionsClick = (e: any, type: string, filter: Filter) => {
    e.stopPropagation();

    switch (type) {
      case "menu":
        if (showFilterOptions.show) {
          setShowFilterOptions({ show: false, filter: filter });
        } else {
          setShowFilterOptions({ show: true, filter: filter });
        }
        break;
      case "edit":
        dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: "Needs implementation" } })
        break;
      case "delete":
        dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: "Needs implementation" } })
        break;
      default:
        break;
    }
  }

  return (
    <Fragment>
      <div className="header border-bottom flex-shrink-0">
        <span className="ml-1">Filter</span>
        <div className="d-flex">
          <button className="btn btn-transparent p-1" onClick={() => dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: "Needs implementation" } })}><Icon type="add-xl" /></button>
          <button className="btn btn-transparent p-1" onClick={() => setShowSidebar({ show: false, type: "add" })}><Icon type="close" /></button>
        </div>
      </div>
      <div>
        <i className="far fa-search text-muted sidebar-input-icon" />
        <input
          type="text"
          className="sidebar-input sidebar-search"
          value={searchState.value}
          onChange={(e) => setSearchState({ value: e.target.value, type: "filter" })}
          placeholder="Search filters"
        />
      </div>
      <div className="filter-content">
        {filtersList.map((filter, index: number) => {
          return (
            <div className="sidebar-element" key={index} onClick={() => dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: "Needs implementation" } })}>
              <div className="d-flex align-items-center">
                {/* @ts-ignore */}
                <Icon type={filter.type} className="mr-2" />
                <span>{filter.title}</span>
              </div>
              <span onClick={(e) => filterOptionsClick(e, "menu", filter)}>
                <Icon type="more" fill={"gray"} />
                {showFilterOptions.show && showFilterOptions.filter.id === filter.id &&
                  <div className="sidebar-opt-popup">
                    <div onClick={(e) => filterOptionsClick(e, "edit", filter)} className=""><Icon type="edit" className="mr-1" /> Edit</div>
                    <div onClick={(e) => filterOptionsClick(e, "delete", filter)} className="text-danger"><Icon type="delete" className="mr-1" fill={"red"} /> Delete</div>
                  </div>
                }
              </span>
            </div>
          )
        })}
      </div>
    </Fragment>
  )
}

export default SidebarFilterContent;