import type { InputChangeEvent } from '@progress/kendo-react-inputs';

import type { SubAccountFormData } from '../../../../../../interfaces/adminInterfaces/adminInterfaces';
import { SubAccountGeneralSettingsContent } from './SubAccountGeneralSettingsContent/SubAccountGeneralSettingsContent';

interface Props {
  formData: SubAccountFormData
  onChangeHandler: (e: InputChangeEvent, name: string) => void
}

export const EditSubAccountModalContent = ({ formData, onChangeHandler }: Props) => {
  return (
    <SubAccountGeneralSettingsContent
      formData={formData}
      onChangeHandler={onChangeHandler}
    />
  )
}