import React, { useEffect, useState } from 'react';

import { Icon } from '../../../../shared/Icon/Icon';
import { fetchGetJson as getSurveyCampaign } from '../../../../../services/services'
import { FullHeightSpinner } from '../../../../shared/FullHeightSpinner/FullHeightSpinner';
import CampaignEditorTabContent from './CampaignEditorTabContent/CampaignEditorTabContent';

export default ({ closeCampaignEditor, onDeleteInEditMode, id, token, projectId, surveyId, type }) => {
  const [tabSelected, setTabSelected] = useState('overview')
  const [didMount, setDidMount] = useState(true)
  const [campaign, setCampaign] = useState(null)
  const [tabs, setTabs] = useState([])
  const items = [{ text: "Overview", id: "overview" }, { text: "Compose and send", id: "composeSend" }, { text: "Options", id: "options" }]

  useEffect(() => {
    if (didMount) {
      setDidMount(false)
      getSurveyCampaign(`/projects/${projectId}/surveys/${surveyId}/campaigns/${type === "link-campaign" ? "link" : type === "email-campaign" ? "email" : "sms"}/${id}`, token)
        .then(res => {
          setCampaign(res)
          setTabs(res.type === 'link-campaign' ? items.filter(el => el.id === 'overview' || el.id === 'options') : items)
          setTabSelected('overview')
        })
    }
  }, [didMount])

  const onChangeTab = (id) => {
    if (!(id === 'composeSend' && campaign.isScheduled)) {
      setTabSelected(id)
    }
  }

  const updateCampaign = () => {
    getSurveyCampaign(`/projects/${projectId}/surveys/${surveyId}/campaigns/${type === "link-campaign" ? "link" : type === "email-campaign" ? "email" : "sms"}/${id}`, token)
      .then(res => {
        setCampaign(res)
      })
  }

  const onComposeClick = () => {
    setTabSelected('composeSend')
  }

  return (
    <div className="d-flex flex-column h-100 overflow-hidden mx-width survey-main">
      <div className="d-flex bg-white align-items-center h-56 px-3 border-bottom">
        <button className='btn btn-icon btn-transparent px-1' onClick={closeCampaignEditor} >
          <Icon type="chevron-left" />
        </button>
        <h6 className='mb-0 lh-24 ml-1 strong'>{campaign ? campaign.name : null}</h6>
      </div>
      <div className="d-flex flex-column h-100 overflow-hidden bg-white">
        <div className="d-flex justify-content-start content-tabs px-3 border-bottom w-100">
          {tabs.map((item, key) =>
            <div className={`d-flex p-3 nav-link ${item.id === 'composeSend' && campaign.isScheduled ? 'text-disabled' : 'cursor-pointer'} ${tabSelected === item.id && 'active'}`}
              key={key} onClick={() => onChangeTab(item.id)}>
              <span className='mb-0'>
                {item.text}
              </span>
            </div>)}
        </div>
        {didMount ?
          <div className="h-100 w-100">
            <FullHeightSpinner />
          </div>
          :
          <CampaignEditorTabContent tabType={tabSelected}
            token={token}
            campaign={campaign}
            updateCampaign={updateCampaign}
            onDeleteInEditMode={onDeleteInEditMode}
            onComposeClick={onComposeClick}
            updateCampaignState={() => setDidMount(true)} />
        }
      </div>
    </div>
  )
}