import { useEffect, useRef } from 'react';

import { FullHeightSpinner } from '../../../../../../shared/FullHeightSpinner/FullHeightSpinner'
import { Icon } from '../../../../../../shared/Icon/Icon';
import { allElements } from './AddNewElementDefaultTabObjects';
import { returnElementIconAddNewElementModal, returnElementNameVariable, clickedButton } from '../../returnDataHelpers/returnDataHelpers';
import type { IPropsDefaultItems, ISubelements } from '../../../../../../../interfaces/addNewElementModalInterfaces/addNewElementModalInterfaces';

export const AddNewElementDefaultTab = ({ onAddNewElement, inputValue }: IPropsDefaultItems) => {
  const ref = useRef<HTMLDivElement | null>(null)

  const tableContent = () => {
    if (allElements && allElements) {
      return allElements.filter((val: TODO) => {
        if (inputValue === "" && inputValue === undefined) {
          return val
        }
        return val?.name?.toLowerCase().includes(inputValue?.toLowerCase())
      })
    }
  }

  const ItemRenderer = ({ item }: { item: ISubelements }) => {
    const icon = returnElementIconAddNewElementModal(item);
    const text = returnElementNameVariable(item)
    return (
      <div className="d-flex justify-content-start align-items-center flex-grow-1 w-100 item gap-md">
        <Icon type={icon} />
        <p className="text-truncate m-0">{text}</p>
      </div>
    )
  };

  useEffect(() => {
    if (ref.current !== null) {
      ref.current.focus();
    }
  }, []);

  return (
    <div ref={ref} onKeyDown={(event) => clickedButton(event, allElements, onAddNewElement)} className="w-100 d-flex flex-column">
      {/* <p className='mb-1 strong'>Default</p> */}
      {!allElements ?
        <div className='elements-loading'>
          <FullHeightSpinner />
        </div>
        :
        tableContent()?.map((defaultSurveyElement: ISubelements) =>
          <div onClick={() => onAddNewElement(defaultSurveyElement)} key={defaultSurveyElement.id} className="d-flex flex-row px-2">
            <ItemRenderer item={defaultSurveyElement} />
          </div>
        )
      }
    </div>
  )
}
