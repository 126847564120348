import { submitUpdateEmailProviderHandler } from "./submitUpdateEmailProviderHandler/submitUpdateEmailProviderHandler"
import { submitCreateEmailProvidersHandler } from "./submitCreateEmailProvidersHandler/submitCreateEmailProvidersHandler"
import type { EmailProvider, EmailProviderFormData } from "../../../../interfaces/emailProviderInterfaces/emailProviderInterfaces"

export const submitEmailProvidersHandler = (formData: EmailProviderFormData, token: string, dispatch: TODO, setIsLoading: (val: boolean) => void, emailProvider?: EmailProvider | null) => {
  if (emailProvider) {
    submitUpdateEmailProviderHandler(formData, token, dispatch, setIsLoading, emailProvider)
  } else {
    submitCreateEmailProvidersHandler(formData, dispatch, token, setIsLoading)
  }
}