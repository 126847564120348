import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs"
import { Input } from "@progress/kendo-react-inputs";
import { type FormEvent, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import type { RootState } from "../../../../../../../../store/reducers/rootReducer";
import { ErrorMessage } from "../../../../../../../shared/ErrorMessage/ErrorMessage";
import { fetchDeleteJson } from "../../../../../../../../services/services";
import { useParams } from "react-router-dom";
import type { ReportFolder, Report } from "../ReportsList";
import { setReport } from "../../../../../../../../store/reducers/analysisReportsStateReducer/analysisReportsStateReducer";

type Props = {
  deleteData: { type: "reportFolder" | "report", reportFolder: ReportFolder, report?: Report } | null;
  setShowDeleteModal: (show: boolean) => void;
  setDeleteData: (item: null) => void;
  setReportFolders: (reportFolders: ReportFolder[]) => void;
  reportFolders: ReportFolder[];
}

export const DeleteModal = ({ deleteData, setShowDeleteModal, setDeleteData, setReportFolders, reportFolders }: Props) => {
  const reportsData = useSelector((theState: RootState) => theState.analysisReportsReducer);
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);

  const params: { name: string, dataset: string } = useParams();
  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState("")
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const removeItemFromList = () => {
    switch (deleteData?.type) {
      case "reportFolder": {
        const updatedReportsFolder = reportFolders?.filter((item) => item.id !== deleteData.reportFolder.id);
        const isSelectedIdInReportsData = deleteData.reportFolder.reports.some((item) => item.id === reportsData.report.id);

        if (isSelectedIdInReportsData) {
          dispatch(setReport({}));
        }
        setReportFolders(updatedReportsFolder)
        break;
      }
      case "report": {
        const updatedReports = deleteData.reportFolder.reports.filter((item) => item.id !== deleteData.report?.id);

        const updatedReportFolder = { ...deleteData.reportFolder, reports: updatedReports };

        const updatedReportsFolder = reportFolders?.map((item) => item.id === updatedReportFolder.id ? updatedReportFolder : item);

        if (deleteData.report?.id === reportsData.report.id) {
          dispatch(setReport({}));
        }

        setReportFolders(updatedReportsFolder)
        break;
      }
    }
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    if (!deleteData) return

    event.preventDefault();
    removeItemFromList();

    const url = deleteData?.type === "report" ? `an/projects/${params.name}/analysis/${params.dataset}/reportfolders/${deleteData.reportFolder.id}/reportSnapshots/${deleteData.report?.id}` : `an/projects/${params.name}/analysis/${params.dataset}/reportfolders/${deleteData.reportFolder.id}`
    setIsLoading(true);
    fetchDeleteJson(url, token)
      .then((res: TODO) => {
        setIsLoading(false);
        if (!res.error || !res.message) {
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: `The ${deleteData.type} has been deleted successfully` } })
          removeItemFromList();
          setDeleteData(null);
          setShowDeleteModal(false);
        } else if (res) {
          setErrorMessage(res.message ? res.message : res);
        }
      })
  }

  const onClose = () => {
    setDeleteData(null);
    setShowDeleteModal(false);
  }


  return (
    <Dialog width={500} height={400} title={`Delete ${deleteData?.type === "report" ? deleteData.report?.name : deleteData?.reportFolder.name}`} onClose={onClose}>
      <form className="d-flex flex-column justify-content-between h-100 gap-md px-2" onSubmit={(e) => handleSubmit(e)}>
        <div className="d-flex flex-column gap-md py-4">
          <p>
            Confirm the deletion of the {deleteData?.type === "report" ? "report" : "folder"} by typing the {deleteData?.type === "report" ? "report" : "folder"} <strong>name</strong> in the input field
          </p>
          <Input
            required
            onChange={e => setName(typeof e.target.value === "string" ? e.target.value : "")}
            value={name}
            placeholder={`Enter ${deleteData?.type === "report" ? "report" : "folder"} name`}
            name="name"
            className="w-100" />
          {
            errorMessage &&
            <div className="mt-4">
              <ErrorMessage
                type="alert"
                errorMessage={errorMessage} />
            </div>
          }
        </div>

        <DialogActionsBar>
          <button type='button' className="btn btn-secondary" onClick={onClose}>Cancel</button>
          <button
            type='submit'
            className="btn btn-primary"
            disabled={isLoading}>
            {
              isLoading &&
              <span className="spinner-border spinner-border-sm mr-2" />
            }
            Delete
          </button>
        </DialogActionsBar>
      </form>
    </Dialog>
  )
}