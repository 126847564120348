import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useSelector } from "react-redux";

import { BaseMultiSelect } from '../../../../shared/Inputs';
import { ErrorMessage } from '../../../../shared/ErrorMessage/ErrorMessage';
import { parseJwt } from '../../../../shared/helpers/decodeJWT/decodeJWT';
import { useFetchTags } from '../../../../shared/customHooks/useFetchTags';
import { fetchPutJson as updateDataset, fetchPutJson as updateSurvey, fetchPutJson as updateSample, fetchPutJson as updateWorkflow, fetchPatch, rsAppUrl } from '../../../../../services/services';

export default function EditProjectDocument({ handleClose, onUpdateData, item, itemAction }) {
  const { token } = useSelector((state) => state.tokenStateReducer);
  const params = useParams()
  const [errorMessage, setErrorMessage] = useState(null)
  const [updatedItem, setUpdatedItem] = useState({ name: item.projectName, description: item.description, tags: item.tags, dataCollectionVersion: item.dataCollectionVersion ? item.dataCollectionVersion : 1 })

  const tags = item.tags ? useFetchTags(token) : [];

  const parsedToken = parseJwt(token);
  const customerId = parsedToken[`${rsAppUrl}/customerid`];
  const showDCVersionToggle = !!(customerId === '024f7442-48b8-4b31-9b07-92421f0e602f' || customerId === '3ef11f3f-290d-4a64-925c-34ae02f863ce');

  const onUpdateDocument = (data) => {
    let body = data
    const projectId = params.name;
    if (updatedItem.name !== '') {
      if (itemAction.type.includes('datasets')) {
        updateDataset(`projects/${projectId}/${itemAction.type}/${itemAction.id}`, token, body)
          .then(res => {
            if (res.error && res.statusCode) {
              setErrorMessage(res.error)
            }
            else {
              onUpdateData(res)
              handleClose()
            }
          })
      } else if (itemAction.type === 'surveys') {
        updateSurvey(`su/projects/${projectId}/surveys/${itemAction.id}`, token, body)
          .then(res => {
            if (res.error && res.statusCode) {
              setErrorMessage(res.error)
            }
            else {
              onUpdateData()
              handleClose()
            }
          })
      } else if (itemAction.type === 'samples') {
        updateSample(`projects/${projectId}/samples/${itemAction.id}`, token, body)
          .then(res => {
            if (res.error && res.statusCode) {
              setErrorMessage(res.error)
            }
            else {
              onUpdateData()
              handleClose()
            }
          })
      } else if (itemAction.type === 'workflows') {
        body = { ...body, _etag: item._etag }
        updateWorkflow(`projects/${projectId}/workflows/${itemAction.id}`, token, body)
          .then(res => {
            if (res.error && res.statusCode) {
              setErrorMessage(res.error)
            }
            else {
              onUpdateData()
              handleClose()
            }
          })
      } else if (itemAction.type === 'pinboards') {
        body = { ...body }
        fetchPatch(`pb/projects/${projectId}/pinboards/${itemAction.id}`, token, body)
          .then(res => {
            if (res.error && res.statusCode) {
              setErrorMessage(res.error)
            }
            else {
              onUpdateData(body)
              handleClose()
            }
          })
      } else if (itemAction.type === 'files') {
        updateSurvey(`projects/${projectId}/files/${itemAction.id}`, token, body)
          .then(res => {
            if (res.error && res.statusCode) {
              setErrorMessage(res.error)
            }
            else {
              onUpdateData()
              handleClose()
            }
          })
      }
    }
    else {
      setErrorMessage('Name is required')
    }
  }
  return (
    <Dialog title={`Edit ${item.type === 'workflows' ? 'data operation' : item.type.slice(0, -1)} information`}
      onClose={handleClose}
      className="d-flex flex-column"
      width="40%">
      <div className="d-flex flex-column p-5">
        <span className="mr-2">Name</span>
        <Input className="w-100 mb-3" required value={updatedItem.name} onChange={e => setUpdatedItem({ ...updatedItem, name: e.target.value })} />
        <span className="mr-2">Description</span>
        <Input className="w-100 mb-3" value={updatedItem.description} onChange={e => setUpdatedItem({ ...updatedItem, description: e.target.value })} />
        {
          item.tags &&
          <div className="form-group">
            <label>Tags</label>
            <BaseMultiSelect
              data={tags}
              value={updatedItem.tags}
              textField="name"
              dataItemKey="id"
              allowCustom={true}
              // listNoDataRender={listNoDataRender}
              onChange={e => setUpdatedItem({ ...updatedItem, tags: e.target.value })} />
          </div>
        }
        {
          itemAction.type === 'surveys' && showDCVersionToggle &&
          <div className="d-flex justify-content-between align-items-center form-control mt-2 py-5">
            <p className="mr-3 mb-0 text-primary">Use Data Collection V2</p>
            <Switch
              disabled={true}
              checked={updatedItem.dataCollectionVersion === 2}
              onChange={(e) => setUpdatedItem({ ...updatedItem, dataCollectionVersion: e.value ? 2 : 1 })}
              size="small"
            />
          </div>
        }
        {errorMessage &&
          <ErrorMessage
            type="alert"
            errorMessage={errorMessage} />
        }
      </div>
      <DialogActionsBar>
        <button type='button' className="k-button btn btn-secondary" onClick={handleClose}>Cancel</button>
        <button type='button' className="k-button btn btn-primary" onClick={() => onUpdateDocument(updatedItem)}>
          Update {item.type === 'workflows' ? 'data operation' : item.type === 'datasetsv2' ? 'dataset' : item.type.slice(0, -1)}
        </button>
      </DialogActionsBar>
    </Dialog>
  )

}