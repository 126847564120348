import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { fetchDeleteJson as deleteBackofficeUser } from '../../../../services/services';
import type { RootState } from '../../../../store/reducers/rootReducer';

interface Props {
  editUserModalState: TODO
  handleClose: () => void,
  isBackofficeOwner: boolean,
  editOrAddModalOpen: TODO
  setEditOrAddModalOpen: TODO
}

export const DeleteUserConfirmationModal = ({ handleClose, editUserModalState, isBackofficeOwner, editOrAddModalOpen, setEditOrAddModalOpen }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const [confirmedEmail, setConfirmedEmail] = useState<string>('')

  const onDeleteHandler = () => {
    deleteBackofficeUser(`backoffice/customers/${editUserModalState.customerID}/users/${editUserModalState.userObject.id}`, token)
      .then((res: TODO) => {
        if (res && (res.error || res.message)) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : "Something went wrong" } })
        } else {
          const updatedEditOrAddModalOpen = JSON.parse(JSON.stringify(editOrAddModalOpen))
          const indexOfDeleted = updatedEditOrAddModalOpen?.body?.users?.findIndex((el: { id: string }) => el.id === editUserModalState.userObject.id)
          const updatedUsers = [...updatedEditOrAddModalOpen.body.users]
          updatedUsers.splice(indexOfDeleted, 1)
          updatedEditOrAddModalOpen.body.users = updatedUsers
          setEditOrAddModalOpen(updatedEditOrAddModalOpen)
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "The user has been deleted successfully" } })
          handleClose()
        }
      })
  }

  return (
    <Dialog
      width={600}
      onClose={handleClose}
      title={`Delete "${editUserModalState.userObject.firstName} ${editUserModalState.userObject.lastName}"`}
      style={{ zIndex: 50000 }}>
      <div className="p-4">
        <p>Confirm the deletion by typing the user's <strong>email - {editUserModalState.userObject.email}</strong> in the input field below:</p>
        <Input
          className="w-100"
          value={confirmedEmail}
          onChange={(e: TODO) => setConfirmedEmail(e.target.value)}
        />
      </div>
      <DialogActionsBar>
        <button
          type='button'
          className="k-button btn btn-secondary"
          onClick={handleClose}
        >Cancel
        </button>
        <button
          type='button'
          disabled={confirmedEmail !== editUserModalState.userObject.email || !isBackofficeOwner}
          className="k-button btn-danger"
          onClick={onDeleteHandler}
        >Delete
        </button>
      </DialogActionsBar>
    </Dialog >
  )
}