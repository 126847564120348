import React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

interface IProps {
  onHide: () => void
  setNonCompletesBody: (s: any) => void
  nonCompletesBody: any
  handleExportNonCompletesCreate: () => void
}

export const ExportNonCompletesModal: React.FC<IProps> = ({ onHide, nonCompletesBody, setNonCompletesBody, handleExportNonCompletesCreate }) => {

  const handleChange = (e: any) => {
    setNonCompletesBody({
      ...nonCompletesBody,
      includeStatus: {
        ...nonCompletesBody.includeStatus,
        [e.target.name]: !nonCompletesBody.includeStatus[e.target.name]
      }
    })
  };

  return (
    <Dialog minWidth={500} title={"Create new dataset with non completes"} onClose={onHide}>
      <div className="strong">Include the following outcome codes</div>
      <div className="d-flex flex-column py-3">
        <div className="d-flex flex-row align-items-center justify-content-start">
          <input name="complete" id='complete' checked={nonCompletesBody.includeStatus.complete} type="checkbox" className='form-control mr-2 shadow-none' onChange={(e) => handleChange(e)} />
          <label className="form-check-label flex-grow-1 ">
            Complete (default)
          </label>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-start pt-3">
          <input name="screened" id='screened' checked={nonCompletesBody.includeStatus.screened} type="checkbox" className='form-control mr-2 shadow-none' onChange={(e) => handleChange(e)} />
          <label className="form-check-label flex-grow-1 ">
            Screened out
          </label>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-start pt-3">
          <input name="quotaFull" id='quotaFull' checked={nonCompletesBody.includeStatus.quotaFull} type="checkbox" className='form-control mr-2 shadow-none' onChange={(e) => handleChange(e)} />
          <label className="form-check-label flex-grow-1 ">
            Quota full
          </label>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-start pt-3">
          <input name="qualityFail" id='qualityFail' checked={nonCompletesBody.includeStatus.qualityFail} type="checkbox" className='form-control mr-2 shadow-none' onChange={(e) => handleChange(e)} />
          <label className="form-check-label flex-grow-1 ">
            Quality fail
          </label>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-start pt-3">
          <input name="dropout" id='dropout' checked={nonCompletesBody.includeStatus.dropout} type="checkbox" className='form-control mr-2 shadow-none' onChange={(e) => handleChange(e)} />
          <label className="form-check-label flex-grow-1 ">
            Incomplete
          </label>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-start pt-3">
          <input name="archived" id='archived' checked={nonCompletesBody.includeStatus.archived} type="checkbox" className='form-control mr-2 shadow-none' onChange={(e) => handleChange(e)} />
          <label className="form-check-label flex-grow-1 ">
            Archived
          </label>
        </div>
        <div className="d-flex flex-column align-items-start justify-content-start pt-3">
          <div className="mr-2 mb-2 strong">Name of the new dataset</div>
          <input name="datasetName" id='datasetName' checked={nonCompletesBody.name} type="text" className='form-control mr-2 shadow-none' onChange={(e) => setNonCompletesBody({ ...nonCompletesBody, datasetName: e.target.value })} />
          <small className="text-muted pt-2">The export will be queued and you will receive a notification it is complete.</small>
        </div>
      </div>
      <DialogActionsBar>
        <button type="button" className="k-button btn btn-secondary" onClick={onHide}>Cancel</button>
        <button onClick={() => handleExportNonCompletesCreate()} type="submit" className="k-button btn btn-primary">Create</button>
      </DialogActionsBar>
    </Dialog>
  )
}
