import { useState } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

interface Props {
  onHide: () => void
  onSelect: (type: EmailProviderTypeId) => void
}

type EmailProviderTypeId = 'smtp' | 'sendgrid';

type EmailProviderType = {
  id: EmailProviderTypeId,
  name: 'SMTP' | 'Sendgrid',
  icon: string,
  theme: string
  disabled?: boolean
}

const emailProviderTypes: EmailProviderType[] = [
  { id: 'smtp', name: "SMTP", icon: "fa-envelope-open-text", theme: 'primary' },
  { id: 'sendgrid', name: "Sendgrid", icon: "fa-th-large", theme: 'analyze' }
];

export const EmailProviderTypeModal = ({ onHide, onSelect }: Props) => {
  const [selectedType, setSelectedType] = useState<EmailProviderTypeId>('smtp');

  return (
    <Dialog className="import-dialog" minWidth={700} title={"Choose email provider type"} onClose={onHide}>
      <div className="py-5">
        <div className="container">
          <div className="row justify-content-center">
            {
              emailProviderTypes.map((type) => (
                <div key={type.id} className="col-md-3" onClick={() => setSelectedType(type.id)}>
                  <div className={`h-100 project-card card p-3 text-center ${type.disabled ? 'disabled' : ''} ${selectedType === type.id ? `text-${type.theme} border-${type.theme}` : 'text-muted'}`}>
                    <i className={`fas fa-2x ${type.icon}`} />
                    <h4 className="mt-3 mb-0">{type.name}</h4>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>

      <DialogActionsBar>
        <button type='button' className="k-button k-button-solid-base" onClick={onHide}>Cancel</button>
        <button type='button' className="k-button k-button-solid-primary" onClick={() => onSelect(selectedType)}>Next</button>
      </DialogActionsBar>
    </Dialog >
  )
}