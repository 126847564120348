import React, { useEffect, useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { Switch } from '@progress/kendo-react-inputs';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { DropDownButton } from '@progress/kendo-react-buttons';

import { Icon } from '../../../../../../shared/Icon/Icon';
import ThemeSettings from './ThemeSettings/ThemeSettings';
import { DropdownButton } from '../../../../../../shared/DropdownButton/DropdownButton';
import { ThemeCard } from './ThemeCard/ThemeCard';
import FileManager from '../../../../../../shared/FileManager/FileManager';
import AddNewThemeModal from './AddNewThemeModal/AddNewThemeModal';
import ApplyThemeModal from './ApplyThemeModal/ApplyThemeModal';
import SaveThemeAsModal from './SaveThemeAsModal/SaveThemeAsModal';
import UpdateGalleryThemeModal from './UpdateGalleryThemeModal/UpdateGalleryThemeModal';
import ThemesAdvancedOptions from './ThemesAdvancedOptions/ThemesAdvancedOptions';
import ConfirmActionModal from '../../../../../../shared/ConfirmActionModal/ConfirmActionModal';
import CssThemeEditAlert from '../../../helpers/CssThemeEditAlert/CssThemeEditAlert';
import { fetchGetJson, fetchDelete as deleteTheme, fetchPutResOrJson as updateTheme, fetchPutJson, fetchGetJsonData } from '../../../../../../../services/services';

export default function Themes({ settings, token, fonts, dropdownValues, setShouldUpdatePreview, themes, setThemes, selectedTab, setSelectedTab }) {
  const params = useParams()
  const dispatch = useDispatch()
  const [addNewThemeModal, setAddNewThemeModal] = useState({ show: false, type: null, id: null })
  const [showFileManager, setShowFileManager] = useState({ show: false, setting: null })
  const [deleteModal, setDeleteModal] = useState({ show: false, id: null })
  const [advancedEditAlert, setAdvancedEditAlert] = useState(false)
  const [themeData, setThemeData] = useState(null)
  const [themeName, setThemeName] = useState('')
  const [didMount, setDidMount] = useState(false)
  const [showApplyModal, setShowApplyModal] = useState({ show: false, theme: null })
  const [showSaveAsModal, setShowSaveAsModal] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isMigratingTheme, setIsMigratingTheme] = useState(false)
  const [isUpdatingThemeSettings, setIsUpdatingThemeSettings] = useState(false)
  const [showUpdateGalleryThemeModal, setShowUpdateGalleryThemeModal] = useState(false)
  const [showAdvancedOptionsModal, setShowAdvancedOptionsModal] = useState({ show: false, editCSS: false })
  const { theData, themeId } = useSelector(theState => (theState.surveyInitialDataReducer));

  useEffect(() => {
    if (!didMount && themeId) {
      setDidMount(true)
      fetchGetJson(theData.dcV2 === 2 ? `su/themes/${themeId}` : `themes/${themeId}`, token)
        .then((res) => {
          if (res && !res.error && !res.message) {
            setThemeData(res)
            const basedOnTheme = themes.data.find(el => el.id === res.basedOnId)
            setThemeName(basedOnTheme ? basedOnTheme.name : 'Survey theme')
          } else if (res.error && res.error === 'Theme not found') {
            setSelectedTab('gallery')
          } else {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
          }
        })

    }
  }, [themeId, didMount, theData, token, dispatch, themes, setSelectedTab])

  const onChangeSettingsValues = (value, setting, type) => {
    let values = [...themeData.themeSettings]
    if (type === 'sizeType') {
      if (values.filter(el => el.id === setting).length === 0) {
        values = [...values, { id: setting, value: null, type: value }]
      } else {
        values.find(el => el.id === setting).type = value
      }
    }
    else if (type === 'sizeValue') {
      if (values.filter(el => el.id === setting).length === 0) {
        values = [...values, { id: setting, value: value, type: "px" }]
      } else {
        values.find(el => el.id === setting).value = value
      }
    } else {
      if (values.filter(el => el.id === setting).length === 0) {
        values = [...values, { id: setting, value: value, type: "px" }]
      } else {
        values.find(el => el.id === setting).value = value
      }
    }
    setThemeData({ ...themeData, themeSettings: values })
  }

  const onMigrateTheme = () => {
    setIsMigratingTheme(true)
    fetchPutJson(`su/projects/${params.name}/surveys/${params.survey}/theme`, token, { id: themeData.id })
      .then((res) => {
        if (res && !res.error && !res.message) {
          const basedOnTheme = themes.data.find((el) => el.id === res.theme.basedOnId)
          const updatedData = JSON.parse(JSON.stringify(theData.data))
          const updatedOriginalData = JSON.parse(JSON.stringify(theData.originalData))
          updatedOriginalData._etag = res._etag
          dispatch({ type: 'UPDATE_SURVEY_DATA', payload: { data: updatedData, originalData: updatedOriginalData } })
          setThemeData(res.theme)
          setThemeName(basedOnTheme ? basedOnTheme.name : 'Survey theme')
          dispatch({ type: "SET_THEME_ID", payload: res.theme.id })
          setIsMigratingTheme(false)
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The theme was applied to the survey successfully' } })
        } else {
          setIsMigratingTheme(false)
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
        }
      })
  }

  const onDeleteTheme = () => {
    setIsDeleting(true)
    deleteTheme(theData.dcV2 === 2 ? `su/themes/${deleteModal.id}` : `themes/${deleteModal.id}`, token)
      .then(res => {
        setIsDeleting(false)
        if (res.status === 204) {
          setDeleteModal({ show: false, id: null })
          setThemes({ ...themes, didMount: false })
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'Something went wrong. Please try again' } })
        }
      })

  }

  const onUpdateThemeSettings = (switchCustomCSS) => {
    setIsUpdatingThemeSettings(true)
    const body = { themeSettings: themeData.themeSettings }
    updateTheme(theData.dcV2 === 2 ? `su/themes/${themeId}` : `themes/${themeData.id}`, token, body)
      .then(res => {
        setIsUpdatingThemeSettings(false)
        if (res.status === 200) {
          if (switchCustomCSS) { setThemeData({ ...themeData, hasBeenEditedAsCss: !themeData.hasBeenEditedAsCss }) }
          setAdvancedEditAlert(false)
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Theme settings updated successfully' } })
          setShouldUpdatePreview(true)
        } else if (res.error || res.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
        }
      })
  }

  const onThemeDropdownItemClick = (itemId, theme) => {
    if (itemId === 'duplicate') {
      fetchPutJson(theData.dcV2 === 2 ? `su/themes/${theme.id}/duplicate` : `themes/${theme.id}/duplicate`, token)
        .then(res => {
          if (res && !res.error && !res.message) {
            const updatedThemes = JSON.parse(JSON.stringify(themes))
            updatedThemes.data = [...updatedThemes.data, res]
            setThemes(updatedThemes)
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The theme has been duplicated successfully' } })
          } else {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
          }
        })
    }

    if (itemId === 'delete') {
      setDeleteModal({ show: true, id: theme.id })
    }

    if (itemId === 'rename') {
      setAddNewThemeModal({ show: true, type: 'edit', id: theme.id })
    }
  }

  const onSelectTheme = (themeId) => {
    if (themeId !== themes.selected) {
      setThemes({ ...themes, selected: themeId })
      setShouldUpdatePreview(true)
    }
  }

  const moreDropdownItemClick = (e) => {
    if (e.item.text === 'Save as new theme') {
      setShowSaveAsModal(true)
    } else if (e.item.text === 'Update gallery theme') {
      setShowUpdateGalleryThemeModal(true)
    } else if (e.item.text === 'Advanced options') {
      setShowAdvancedOptionsModal({ show: true, editCSS: false })
    }
  }

  const moreDropdownItemRender = (item) => {
    if (item.text === 'separator') {
      return <div style={{ height: '1px' }} className="w-100 my-1 bg-light" />
    }
    return (
      <Tooltip openDelay={100} position='right' anchorElement={'target'}>
        <div title={item.disabled ? 'Only custom themes can be updated.' : ''}>
          <button
            type="button"
            className="dropdown-item px-3"
            disabled={item.disabled}
            onClick={() => moreDropdownItemClick({ item: item })}>
            <div className="d-flex align-items-center">
              <span className='pl-1'>{item.text}</span>
            </div>
          </button>
        </div>
      </Tooltip>
    )
  }

  let isBasedOnStandardTheme = false
  if (themes.data && themeData) {
    isBasedOnStandardTheme = themes.data.find(el => el.id === themeData.basedOnId)?.type === 'standard-theme' ||
      themes.data.find(el => el.id === themeData.basedOnId)?.type === 'standard-themev2'
  }

  return (
    <Fragment>
      {
        showAdvancedOptionsModal.show && themeData &&
        <ThemesAdvancedOptions
          themeData={themeData}
          setThemeData={setThemeData}
          editCSS={showAdvancedOptionsModal.editCSS}
          setShouldUpdatePreview={setShouldUpdatePreview}
          onHide={() => setShowAdvancedOptionsModal({ show: false, editCSS: false })}
        />
      }
      {
        showUpdateGalleryThemeModal &&
        <UpdateGalleryThemeModal
          themeData={themeData}
          onHide={() => setShowUpdateGalleryThemeModal(false)}
        />
      }
      {
        showSaveAsModal &&
        <SaveThemeAsModal
          themes={themes}
          setThemes={setThemes}
          onHide={() => setShowSaveAsModal(false)}
        />
      }
      {
        showApplyModal.show &&
        <ApplyThemeModal
          themes={themes}
          theme={showApplyModal.theme}
          setThemeData={setThemeData}
          setThemeName={setThemeName}
          setSelectedTab={setSelectedTab}
          onHide={() => setShowApplyModal({ show: false, theme: null })}
        />
      }
      {
        addNewThemeModal.show &&
        <AddNewThemeModal
          themes={themes}
          id={addNewThemeModal.id}
          setThemes={setThemes}
          handleClose={() => setAddNewThemeModal({ show: false, type: null, id: null })}
        />
      }
      {
        showFileManager.show &&
        <FileManager
          displayType="modal"
          type={'public'}
          onSelect={(value) => onChangeSettingsValues(value, showFileManager.setting)}
          onHide={() => setShowFileManager({ show: false, setting: null })}
          token={token}
        />
      }
      {
        deleteModal.show &&
        <ConfirmActionModal
          isDeleting={isDeleting}
          show={deleteModal.show}
          projectName={themes.data.find(el => el.id === deleteModal.id).name}
          handleClose={() => setDeleteModal({ show: false, id: null })}
          deleteItem={() => onDeleteTheme()}
          type='theme'
        />
      }
      {
        advancedEditAlert &&
        <CssThemeEditAlert
          advanced={!themeData.hasBeenEditedAsCss}
          handleClose={() => setAdvancedEditAlert(false)}
          themeData={themeData}
          setThemeData={setThemeData}
          onUpdateThemeSettings={onUpdateThemeSettings}
          setShouldUpdatePreview={setShouldUpdatePreview}
        />
      }
      {
        <div className="d-flex flex-column align-items-between justify-content-center build-tab-header">
          <div className="d-flex align-items-center justify-content-between p-3">
            <p className='mb-0 lh-24 build-tab-header-title'>Themes</p>
          </div>
        </div>
      }
      <div className='d-flex px-3 pb-3 w-100 border-bottom'>
        <div className="themes-tabs-wrapper w-100 h-32" role="group" aria-label="Theme tabs">
          <button
            type='button'
            onClick={() => setSelectedTab(themeData ? 'survey' : 'gallery')}
            className={`themes-tab-item d-flex justify-content-center align-items-center border-0 ${selectedTab === 'survey' ? 'bg-white' : ''}`}
          >
            <span>Survey</span>
            {
              !themeData &&
              <Tooltip openDelay={100} position='right' anchorElement={'target'}>
                <span title="You need to select a theme from gallery to access survey settings">
                  <Icon type="info-tooltip" className="pe-none" />
                </span>
              </Tooltip>
            }
          </button>

          <button
            type='button'
            onClick={() => setSelectedTab('gallery')}
            className={`themes-tab-item border-0 ${selectedTab === 'gallery' ? 'bg-white' : ''}`}
          >
            Gallery
          </button>
        </div>
      </div>
      {
        selectedTab === 'gallery' ?
          <div className="d-flex flex-column flex-grow-1 overflow-auto build-tab-header">
            <div className="input-group p-3">
              <input value={themes?.searchData ? themes.searchData : ''} onChange={(e) => setThemes({ ...themes, searchData: e.target.value })} type="text" className="form-control search-input" placeholder="Search themes" />
            </div>
            {
              !themes.searchData ?
                <Fragment>
                  <div className="d-flex flex-column flex-grow-1 overflow-auto">
                    <p className="strong medium px-3 pb-2 mb-1 bg-white custom-theme-title">Standard themes</p>
                    <div className="d-flex flex-wrap px-3 mb-3 border-bottom justify-content-between custom-theme-container">
                      {
                        themes.data.filter(el => el.type === 'standard-theme' || el.type === 'standard-themev2').length > 0 && themes.data.filter(el => el.type === 'standard-theme' || el.type === 'standard-themev2').map((theme) => (
                          <div key={theme.id} className="w-50 pb-4 custom-theme-item">
                            <ThemeCard
                              theme={theme}
                              themes={themes}
                              onSelectTheme={onSelectTheme}
                              onThemeDropdownItemClick={onThemeDropdownItemClick}
                              setShowApplyModal={setShowApplyModal}
                            />
                          </div>
                        ))
                      }
                    </div>

                    <p className="strong medium px-3 pb-2 mb-1 bg-white custom-theme-title">Custom themes</p>
                    <div className="d-flex flex-wrap px-3 justify-content-between custom-theme-container">
                      {
                        themes.data.filter(el => el.type === 'custom-theme' || el.type === 'custom-themev2').length > 0 && themes.data.filter(el => el.type === 'custom-theme' || el.type === 'custom-themev2').map((theme) => (
                          <div key={theme.id} className="w-50 pb-4 custom-theme-item">
                            <ThemeCard
                              theme={theme}
                              themes={themes}
                              onSelectTheme={onSelectTheme}
                              onThemeDropdownItemClick={onThemeDropdownItemClick}
                              setShowApplyModal={setShowApplyModal}
                            />
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </Fragment>
                :
                <div className="d-flex flex-wrap px-3 justify-content-between custom-theme-container">
                  {
                    themes.data.filter(el => el.name.toLowerCase().includes(themes.searchData.toLowerCase())).length > 0 ?
                      themes.data.filter(el => el.name.toLowerCase().includes(themes.searchData.toLowerCase())).map((theme) => (
                        <div key={theme.id} className="w-50 pb-4 custom-theme-item">
                          <ThemeCard
                            theme={theme}
                            themes={themes}
                            onSelectTheme={onSelectTheme}
                            onThemeDropdownItemClick={onThemeDropdownItemClick}
                            setShowApplyModal={setShowApplyModal}
                          />
                        </div>
                      ))
                      :
                      <p className="text-center text-muted w-100 mt-3">No themes were found</p>
                  }
                </div>
            }
          </div>
          :
          themeData && (themeData.type === 'survey-theme' || themeData.type === 'survey-themev2') ?
            <div className="d-flex flex-column h-100 overflow-hidden">
              <div className="d-flex align-items-center justify-content-between p-3 border-bottom medium">
                <div className="d-flex align-items-center w-75">
                  <span className='stronger text-truncate w-100' title={themeName}>
                    {themeName}
                  </span>
                </div>

                <div className='d-flex align-items-center'>
                  <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
                    <span title="Customise theme for this survey. All changes will be applied to this survey only.">
                      <Icon type="info-tooltip" className="pe-none" />
                    </span>
                  </Tooltip>

                  <DropdownButton
                    items={[{ text: 'Advanced options' }, { text: 'Update gallery theme', disabled: isBasedOnStandardTheme }, { text: 'Save as new theme' }]}
                    hideChevron={true}
                    className='btn-transparent p-0'
                    renderItem={moreDropdownItemRender}
                  />
                </div>
              </div>

              <div className="d-flex flex-column h-100 overflow-hidden customize">
                <ThemeSettings
                  settings={settings}
                  settingsValues={themeData.themeSettings}
                  fonts={fonts}
                  hasBeenEditedAsCss={themeData.hasBeenEditedAsCss}
                  dropdownValues={dropdownValues}
                  onChangeSettingsValues={onChangeSettingsValues}
                  onShowFileManager={(id) => setShowFileManager({ show: true, setting: id })}
                  type={themeData.type}
                />
              </div>

              <div className="d-flex flex-column border-bottom p-3">
                {theData.dcV2 === 1 && (
                  <div className={`d-flex justify-content-between align-items-center ${themeData.type === 'standard-theme' ? 'blur pointer-events-none' : ''}`}>
                    <span className="medium">Custom CSS</span>
                    <Switch
                      disabled={themeData.type === 'standard-theme'}
                      onChange={() => setAdvancedEditAlert(true)}
                      checked={themeData.hasBeenEditedAsCss}
                      size="small"
                    />
                  </div>
                )}
                {
                  (themeData.hasBeenEditedAsCss || theData.dcV2 === 2) &&
                  <button
                    type='button'
                    onClick={() => setShowAdvancedOptionsModal({ show: true, editCSS: true })}
                    className={`btn btn-shadow justify-content-center mt-2 ${themeData.type === 'standard-theme' ? 'blur pointer-events-none' : ''}`}
                  >
                    <strong>Edit CSS</strong>
                  </button>
                }
              </div>

              <button
                type='button'
                className={`btn btn-primary justify-content-center m-3 h-32 ${themeData.type === 'standard-theme' ? "blur pointer-events-none" : ""}`}
                onClick={() => onUpdateThemeSettings()}
                disabled={themeData.type === 'standard-theme' || themeData.hasBeenEditedAsCss || isUpdatingThemeSettings}
              >
                {isUpdatingThemeSettings && <span className="spinner-border spinner-border-sm mr-2" />}
                <span>Apply to survey</span>
              </button>
            </div>
            :
            themeData && (themeData.type !== 'survey-theme' || themeData.type !== "survey-themev2") ?
              <div className='p-3'>
                <div className='d-flex flex-column alert-message'>
                  <span className='d-flex align-items-center text-primary p-2 m-0 strong'><Icon type="alert" className="mr-1" />Migrate theme</span>
                  <p className='p-4 m-0'>You need to migrate your theme to a newer version. You can either choose a theme from the Gallery or click the button below to migrate your existing theme.</p>
                  <p className='px-4 pb-4 m-0'>
                    <button
                      type='button'
                      onClick={onMigrateTheme}
                      disabled={isMigratingTheme}
                      className="btn btn-primary">
                      {isMigratingTheme && <span className="spinner-border spinner-border-sm mr-2" />}
                      Migrate theme
                    </button>
                  </p>
                </div>
              </div>
              :
              null
      }
    </Fragment >
  )
}