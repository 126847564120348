import type React from 'react';
import { Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';

import type { RootState } from '../../../store/reducers/rootReducer';
import { InProgressOverlay } from '../InProgressOverlay/InProgressOverlay';
import { AuthenticatedRouteContent } from './AuthenticatedRouteContent/AuthenticatedRouteContent';
import { UnauthenticatedRouteContent } from './UnauthenticatedRouteContent/UnauthenticatedRouteContent';
import { fetchGetJson as getExtendedUserData, rsAppUrl } from '../../../services/services';

declare global {
  interface Window {
    heap: TODO;
  }
}

type InitialExtendedUserData = {
  planId: unknown
  role: null | string
  plan: null | string
  roleId: null | string
  customer: null | string
  isTrail: null | boolean
  createdAt: null | string
  trailEnds: null | string
  isSubAccount: null | boolean
  canCreateSubAccounts: null | boolean
  enterpriseSettings: null | {
    hasToolsAccess: boolean
    hasSurveyAccess: boolean
    hasPinboardAccess: boolean
    hasAudienceAccess: boolean
    hasGatekeeperAccess: boolean
    singleSignOnConnectionName: null | string
  }
}

const initialExtendedUserData: InitialExtendedUserData = {
  role: null,
  plan: null,
  planId: null,
  roleId: null,
  isTrail: null,
  customer: null,
  trailEnds: null,
  createdAt: null,
  isSubAccount: null,
  enterpriseSettings: null,
  canCreateSubAccounts: null
}

const returnIsTrialExpired = (isTrial: boolean, trialEnds: string) => {
  if (isTrial === true) {
    const todayDate = new Date()
    const trialEndDate = new Date(trialEnds)
    if (trialEndDate < todayDate) return true;
    return false;
  }
  return false;
}

export const ProtectedRoute = ({ component: Component, ...rest }: { component: React.ComponentType<TODO>, [key: string]: TODO }) => {
  const dispatch = useDispatch();
  const { isLoading, user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [didMount, setDidMount] = useState(true);
  const [isTrialExpired, setIsTrialExpired] = useState<null | boolean>(null);
  const [extendedUserData, setExtendedUserData] = useState(initialExtendedUserData);
  const { token }: { token: string | null } = useSelector((state: RootState) => state.tokenStateReducer);

  useEffect(() => {
    const fetchToken = async () => {
      const newToken = await getAccessTokenSilently();
      if (newToken) {
        dispatch({ type: 'UPDATE_TOKEN', payload: newToken })
      }
    }
    if (token === null && isAuthenticated) {
      fetchToken();
    }
  }, [token, isAuthenticated, dispatch, getAccessTokenSilently])

  useEffect(() => {
    if (didMount && token && user) {
      setDidMount(false)
      getExtendedUserData(`users/${user[`${rsAppUrl}/userid`]}/extended`, token)
        .then((res: TODO) => {
          if (res) {
            if (res.settings) {
              if (!res.settings.zoomLevels) {
                res.settings.zoomLevels = {
                  digZoom: 1,
                  dataZoom: 1,
                  chartZoom: 1,
                  qTableZoom: 1,
                  topLineZoom: 1,
                  crossTableZoom: 1,
                }
              }
              dispatch({ type: "UPDATE_USER_SETTINGS", payload: res.settings });
            }
            setIsTrialExpired(
              returnIsTrialExpired(res.isTrail, res.trailEnds)
            )
            setExtendedUserData({
              customer: res.customerName,
              plan: 'professional_yearly',
              planId: res.subscriptionId,
              role: res.userRole,
              roleId: res.subscriptionId,
              createdAt: res.createdUtc,
              enterpriseSettings: res.enterpriseSettings,
              canCreateSubAccounts: res.canCreateSubAccounts,
              isSubAccount: res.isSubAccount,
              isTrail: res.isTrail,
              trailEnds: res.trailEnds
            })

            // HEAP ANALYTICS
            if (user?.email && res.customerName && res.createdUtc) {
              window.heap.identify(user.email);
              window.heap.addUserProperties({ subscription_type: 'professional_yearly' });
              window.heap.addUserProperties({ company: res.customerName });
            }
          }
        })
    }
  }, [didMount, token, user, dispatch])

  return (
    <Route
      {...rest}
      render={
        (props) => {
          if (isAuthenticated && !isLoading) {
            return (
              <AuthenticatedRouteContent
                user={user}
                parentProps={props}
                Component={Component}
                isTrialExpired={isTrialExpired}
                extendedUserData={extendedUserData}
              />
            )
          }
          if (!isAuthenticated && !isLoading) {
            return <UnauthenticatedRouteContent />
          }
          if (isLoading) {
            return (
              <div className='vh-100 w-100'>
                <InProgressOverlay
                  type="fullscreen"
                  theme="primary"
                />
              </div>
            )
          }
        }
      } />
  )
}