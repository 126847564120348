import React from 'react';
import { RadioButton, Switch } from '@progress/kendo-react-inputs';

export default ({ formData, onChangeInputHandler, isShared }) => {
  return (
    <div className="px-5 py-3">
      <div className="alert alert-warning mb-5 text-center" role="alert">
        <i className="far fa-exclamation-circle mr-3"></i>
        <span>Access control options will only be applied to regular accounts.</span>
      </div>

      <h6>Access level</h6>
      <div className="d-flex justify-content-between align-items-center px-4 py-2">
        <div>
          <RadioButton
            name="group1"
            value="readonly"
            label="Read-only"
            className="p-2 mr-2"
            checked={formData.radioButton === 'readonly'}
            onChange={(e) => onChangeInputHandler(e, 'radio')} />
        </div>
        <p className="w-50 m-0 text-muted">Invited users will only be able to view data.</p>
      </div>
      <div className="d-flex justify-content-between px-4 py-2">
        <div>
          <RadioButton
            name="group1"
            className="p-2 mr-2"
            value="collaborative"
            label="Collaborative"
            checked={formData.radioButton === 'collaborative'}
            onChange={(e) => onChangeInputHandler(e, 'radio')} />
        </div>
        <p className="w-50 m-0 text-muted">Invited users can work on project(Import datasets etc.)</p>
      </div>

      <h6>External sharing</h6>
      <div className="d-flex justify-content-between align-items-center px-1 py-2">
        <div className="w-50 d-flex align-items-center">
          <div>
            <Switch disabled={isShared} onChange={(e) => onChangeInputHandler(e, 'switch')} checked={formData.checked} size="small" />
          </div>
          <label className="mx-3 my-0">Allow invited users to share within their organization</label>
        </div>
        <p className="w-50 m-0 text-muted">Invited users can give access to other people inside their organization.<br /> This setting cannot be changed.</p>
      </div>
    </div>
  )
}